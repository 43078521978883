import { createUseQueryFn } from '@motion/rpc'
import { MotionCache } from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'

import { useQueryClient } from '@tanstack/react-query'
import { request } from '~/services/apiUtils'
import { useCallback } from 'react'

async function uploadProfilePicture(userId: string, file: File) {
  const data = new FormData()
  data.append('file', file)

  return await request(
    `users/${userId}/profile/picture`,
    'POST',
    __BACKEND_HOST__,
    data,
    false
  )
}

const useCurrentUserFn = createUseQueryFn(API.usersV2.getCurrentUser)

export function useUploadProfilePicture(userId: string | undefined) {
  const getCurrentUser = useCurrentUserFn()
  const client = useQueryClient()

  return useCallback(
    async (file: File) => {
      if (!userId) {
        return
      }

      await uploadProfilePicture(userId, file)

      await getCurrentUser(undefined, { staleTime: 0 }).then((user) => {
        MotionCache.upsert(client, { queryKey: ['model-cache'] }, user)
      })
    },
    [client, getCurrentUser, userId]
  )
}
