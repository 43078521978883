import { ConditionalWrapper } from '@motion/ui/utils'
import { type FolderSchema } from '@motion/zod/client'

import { useCachedItem } from '~/global/cache'
import { type BadgeSize, FolderBadge } from '~/global/components/badges'
import { useUriByRouteId } from '~/routing'
import { useContext } from 'react'

import { LabelContext } from './context'

import { MotionLink } from '../motion-link'

export type FolderLabelProps = {
  value: FolderSchema | null
  size?: BadgeSize
  nameOnly?: boolean
  clickable?: boolean
}

export function FolderLabel({
  size,
  value,
  nameOnly = false,
  clickable = false,
}: FolderLabelProps) {
  const labelCtx = useContext(LabelContext)
  const getRouteUri = useUriByRouteId({ noDefaults: true })

  const name = value?.name || 'No folder'
  const url =
    value != null
      ? getRouteUri(
          labelCtx.shouldGoToNavigate
            ? 'workspace-folder-browse'
            : 'workspace-folder',
          {
            workspaceId: value.targetId,
            folderId: value.id,
          }
        )
      : null

  const label = (
    <span title={name} className='truncate'>
      {name}
    </span>
  )

  return (
    <ConditionalWrapper
      condition={clickable}
      wrapper={(children) =>
        url ? (
          <MotionLink url={url} className='overflow-hidden'>
            {children}
          </MotionLink>
        ) : (
          children
        )
      }
    >
      {nameOnly ? (
        label
      ) : (
        <div className='flex gap-1 items-center overflow-hidden leading-4'>
          <FolderBadge color={value?.color} size={size} />
          {label}
        </div>
      )}
    </ConditionalWrapper>
  )
}

export type ConnectedFolderLabelProps = Omit<FolderLabelProps, 'value'> & {
  id?: FolderSchema['id']
}

export function ConnectedFolderLabel({
  id,
  ...rest
}: ConnectedFolderLabelProps) {
  const folder = useCachedItem('folders', id)

  return <FolderLabel value={folder} {...rest} />
}
