import {
  type DateFilterSchema,
  type MeetingInsightsQueryRequest,
} from '@motion/rpc-types'
import { type NotetakerFilterSchema } from '@motion/ui-logic/pm/data'

export function mapToQueryFilter(
  filter: NotetakerFilterSchema
): MeetingInsightsQueryRequest['filters'] {
  return {
    attendees: filter.attendees,
    host: filter.host,
    ids: filter.ids,
    meetingBotStatus: filter.meetingBotStatus,
    startTime: filter.startTime as DateFilterSchema,
  }
}
