import { type ApiUseMutationOptions, createUseMutation } from '@motion/rpc'
import { MotionCache } from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useQueryClient } from '@tanstack/react-query'

import { projectCacheQueryFilter } from './v2'

export const useParseCancellation = createUseMutation(API.ai.parseCancellation)

export const useCreateAiView = createUseMutation(API.ai.createView)

export const useCreateAiFlows = createUseMutation(API.ai.createFlows)

const useInferProjectParametersMutation = createUseMutation(
  API.ai.inferProjectParameters
)
export const useInferProjectParameters = () =>
  useInferProjectParametersMutation({
    onMutate: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_AI_INFER_PROJECT_PARAMETERS')
    },
  })

const useGenerateSpeculativeProjectMutation = createUseMutation(
  API.ai.generateSpeculativeProject
)
export const useGenerateSpeculativeProject = () =>
  useGenerateSpeculativeProjectMutation({
    onMutate: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_AI_GENERATE_SPECULATIVE_PROJECT')
    },
  })

const useCommitProjectMutation = createUseMutation(API.ai.commitProject)
export const useCommitProject = (
  opts?: ApiUseMutationOptions<typeof API.ai.commitProject>
) => {
  const queryClient = useQueryClient()

  return useCommitProjectMutation({
    ...opts,
    onMutate: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_AI_COMMIT_PROJECT')
    },
    onSuccess: (data, variables, context) => {
      MotionCache.upsert(queryClient, projectCacheQueryFilter, data)
      opts?.onSuccess?.(data, variables, context)
    },
  })
}
