import { type NoteSchema } from '@motion/rpc-types'
import { ButtonTabs } from '@motion/ui/base'
import { addComponentName } from '@motion/ui/helpers'

import { useMemo } from 'react'

import { ThreadContainer } from './thread-container'

import { useNoteCommentsContext } from '../comments'

const NO_COMMENTS_MESSAGE = 'This doc has no comments'

export type CommentsSectionProps = {
  noteId: NoteSchema['id']
  onClose: () => void
}

export const CommentsSection = ({ noteId, onClose }: CommentsSectionProps) => {
  const { threads, setActiveTab, activeTab } = useNoteCommentsContext()

  const visibleThreads = useMemo(() => {
    if (!threads) return null
    return activeTab === 'active'
      ? threads.active
      : activeTab === 'resolved'
        ? threads.resolved
        : threads.all
  }, [activeTab, threads])

  const isEmpty = !visibleThreads?.length

  return (
    <div
      className='h-full flex flex-col'
      {...addComponentName('CommentsSection')}
    >
      <div className='p-2 flex justify-center'>
        <ButtonTabs
          activeValue={activeTab}
          items={[
            {
              content: `Active (${threads?.active.length ?? 0})`,
              onAction: () => {
                setActiveTab('active')
              },
              value: 'active',
            },
            {
              content: `Resolved (${threads?.resolved.length ?? 0})`,
              onAction: () => {
                setActiveTab('resolved')
              },
              value: 'resolved',
            },
            {
              content: `All (${(threads?.active.length ?? 0) + (threads?.resolved.length ?? 0)})`,
              onAction: () => {
                setActiveTab('all')
              },
              value: 'all',
            },
          ]}
          size='small'
        />
      </div>
      {isEmpty ? (
        <div className='py-5 text-sm text-center text-semantic-neutral-text-subtle'>
          {NO_COMMENTS_MESSAGE}
        </div>
      ) : (
        <div className='p-3 pr-4 overflow-x-hidden overflow-y-auto h-full scroll-p-3'>
          {visibleThreads.map((thread) => (
            <ThreadContainer key={thread.id} thread={thread} />
          ))}
        </div>
      )}
    </div>
  )
}
