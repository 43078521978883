import { DotsHorizontalSolid } from '@motion/icons'
import { IconButton, PopoverTrigger } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { NoteActionsList, type NoteActionsListProps } from '~/areas/notes'

export const NoteActionsMenu = (props: NoteActionsListProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <NoteActionsList {...props} close={close} />
      )}
    >
      <IconButton
        variant='muted'
        sentiment='neutral'
        size='xsmall'
        icon={DotsHorizontalSolid}
        onClick={() => {
          recordAnalyticsEvent('FOLDERS_CLICKED_SIDEBAR_BUTTON', {
            itemType: 'NOTE',
            button: 'ACTIONS',
          })
        }}
      />
    </PopoverTrigger>
  )
}
