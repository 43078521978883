import { ArrowRightOutline, XSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { API } from '@motion/rpc-definitions'
import {
  Button,
  IconButton,
  Modal,
  Tag,
  Text,
  TextHeader,
} from '@motion/ui/base'
import {
  type BillingPrices,
  INDIVIDUAL_PRICES,
  makeTeamBillingStr,
  type TierBillingPrices,
} from '@motion/ui-logic/billing'
import {
  logEvent,
  recordAnalyticsEvent,
  useOnMountAnalyticsEvent,
} from '@motion/web-base/analytics'
import { logInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'
import { useShouldShowSalesTaxMessage } from '@motion/web-billing'

import { useQueryClient } from '@tanstack/react-query'
import { useOpenPortalLink } from '~/components/Account/hooks'
import { useCurrentTeam } from '~/global/rpc/team'
import { useToggleIntercom } from '~/utils/toggleIntercom'
import { useCallback, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import api from '../../chromeApi/chromeApiContentScript'
import { Paragraph } from '../../components/Common'
import { useAppDispatch } from '../../state/hooks'
import { switchBillingCycle } from '../../state/teamSlice'
import {
  type StripeSubscription,
  upgradeToAnnualPlan,
} from '../../state/userSlice'

export type AnnualUpgradeModalProps = {
  subscription: StripeSubscription
  onClose: () => void
  teamPrices: BillingPrices
  tierPrices?: TierBillingPrices
}

// TODO: don't use deprecated StripeSubscription type
export const AnnualUpgradeModal = (props: AnnualUpgradeModalProps) => {
  const { subscription, onClose, teamPrices, tierPrices: allTierPrices } = props

  const dispatch = useAppDispatch()
  const [upgradeSuccess, setUpgradeSuccess] = useState(false)
  const [upgradeFail, setUpgradeFail] = useState(false)
  const [upgradeLoading, setUpgradeLoading] = useState(false)

  const { isPortalLoading, openPortalLink } = useOpenPortalLink({
    stripeCustomerId: subscription.customer,
  })
  const client = useQueryClient()

  const { data: team } = useCurrentTeam()
  const isOnTeamMonthly =
    team?.teamSubscription?.status !== 'canceled' &&
    team?.teamSubscription?.isMonthly
  const shouldShowSalesTaxMessage = useShouldShowSalesTaxMessage()
  const toggleIntercom = useToggleIntercom()

  const nonUSD =
    subscription.plan.currency &&
    subscription.plan.currency.toLowerCase() !== 'usd'

  const annualPlanHandler = useCallback(async () => {
    try {
      setUpgradeLoading(true)
      if (isOnTeamMonthly) {
        const result = await dispatch(switchBillingCycle(team.id))

        if (result.payload) {
          await client.invalidateQueries({
            queryKey: API.subscriptions.getIndividualAndTeamSubscription.key(),
          })
        }

        recordAnalyticsEvent('TEAM_BILLING_CONVERT_TO_ANNUAL')
      } else {
        const res = await dispatch(upgradeToAnnualPlan()).unwrap()
        if ('error' in res) {
          throw new Error(res.error)
        }
        if (res.subscription) {
          setUpgradeSuccess(true)
          await api.storage.local.set({ stripeSubscription: res.subscription })

          void logEvent('ACCOUNT_UPGRADE_ANNUAL')
        } else {
          throw new Error('Bad response')
        }
      }
      onClose()
    } catch (e) {
      logInDev('error', e)
      setUpgradeFail(true)
      Sentry.captureException(e, { tags: { position: 'annualPlanHandler' } })
    } finally {
      setUpgradeLoading(false)
    }
  }, [client, dispatch, isOnTeamMonthly, onClose, team?.id])

  useOnMountAnalyticsEvent('NON_USD_ON_ANNUAL_UPGRADE_MODAL', {
    enabled: !!nonUSD,
  })

  const prices = isOnTeamMonthly ? teamPrices : INDIVIDUAL_PRICES
  const tierPrices = isOnTeamMonthly
    ? allTierPrices?.team
    : allTierPrices?.individual
  const annualPricePerMonth =
    tierPrices?.annualPricePerMonth ?? prices.annualPricePerMonth

  const currentPrice = subscription.plan.amount / 100

  const savings =
    (currentPrice - annualPricePerMonth) * 12 * subscription.quantity

  const isTrial = (subscription.trial_end ?? 0) > Date.now() / 1000

  return (
    <Modal onClose={onClose} visible>
      <div className='flex flex-col w-[464px] items-center p-6 bg-semantic-neutral-bg-default'>
        <div className='absolute top-3 right-3'>
          <IconButton
            icon={XSolid}
            onClick={() => onClose()}
            size='small'
            variant='muted'
            sentiment='neutral'
            aria-label='Close annual upgrade modal'
          />
        </div>

        {upgradeSuccess ? (
          <div className='flex flex-col gap-3 items-center'>
            <TextHeader size='xl' className='mb-3'>
              Success
            </TextHeader>
            <Text sentiment='subtle' size='xs'>
              {templateStr(
                'Thank you for subscribing to Motion&apos;s annual plan! You can view your invoice from your {{page}} page.',
                { page: isOnTeamMonthly ? 'Team Billing' : 'Account' }
              )}
            </Text>
          </div>
        ) : upgradeFail ? (
          <div className='flex flex-col gap-3 items-center'>
            <TextHeader size='xl'>Error</TextHeader>
            <Paragraph>
              Sorry, something went wrong. Please contact support.
            </Paragraph>
            <Button
              fullWidth
              onClick={openPortalLink}
              loading={isPortalLoading}
            >
              Change payment method
            </Button>
          </div>
        ) : nonUSD ? (
          <div className='flex flex-col items-center'>
            <TextHeader size='xl' className='mb-3'>
              Switch to annual plan
            </TextHeader>
            <Text sentiment='subtle' size='xs'>
              {templateStr(
                'You are currently on a legacy monthly plan. To upgrade to an annual plan, please {{link}}.',
                {
                  link: (
                    <a
                      className='text-semantic-purple-text-default'
                      onClick={() => {
                        toggleIntercom()
                        recordAnalyticsEvent(
                          'NON_USD_ON_CONTACT_SUPPORT_CLICK',
                          {
                            location: 'annualUpgradeModal',
                          }
                        )
                      }}
                      role='button'
                    >
                      contact support
                    </a>
                  ),
                }
              )}
            </Text>
          </div>
        ) : (
          <div className='flex flex-col gap-6 w-full'>
            <div className='flex flex-col gap-1 items-center'>
              <Tag variant='subtle' color='blue'>
                Save {prices.annualSavingsPercentInteger}%
              </Tag>
              <TextHeader size='lg'>
                {templateStr('Switch to an annual plan to save ${{savings}}', {
                  savings: savings.toLocaleString(),
                })}
              </TextHeader>
            </div>
            <div className='flex items-center justify-between w-full'>
              <PlanWrapper
                price={currentPrice}
                isTeam={!!isOnTeamMonthly}
                isMuted
              />
              <Text sentiment='subtle' size='xs'>
                <ArrowRightOutline className='h-5 w-5' />
              </Text>
              <PlanWrapper
                price={annualPricePerMonth}
                isTeam={!!isOnTeamMonthly}
              />
            </div>
            <div className='flex flex-col gap-3'>
              <Button
                fullWidth
                onClick={annualPlanHandler}
                loading={upgradeLoading}
              >
                Switch to annual plan
              </Button>
              <Text sentiment='subtle' size='xs' alignment='center'>
                {isOnTeamMonthly
                  ? makeTeamBillingStr({
                      isAnnual: true,
                      quantity: subscription.quantity,
                      isSeats: true,
                      prorationTextParams: {
                        isTrial,
                      },
                      teamPrices,
                      tierTeamPrices: allTierPrices?.team,
                      shouldShowSalesTaxMessage,
                    })
                  : templateStr(
                      "You'll be charged ${{price}} billed annually{{applicableTax}}{{prorationText}}",
                      {
                        price: annualPricePerMonth * 12,
                        prorationText: isTrial
                          ? ' after your trial ends.'
                          : '. The first year amount will be pro-rated starting today.',
                        applicableTax: shouldShowSalesTaxMessage
                          ? ' (plus applicable taxes)'
                          : '',
                      }
                    )}
              </Text>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

type PlanWrapperProps = { price: number; isTeam: boolean; isMuted?: boolean }

const PlanWrapper = ({ price, isTeam, isMuted }: PlanWrapperProps) => {
  return (
    <div
      data-testid='plan-price-wrapper'
      className='flex flex-col p-4 bg-semantic-neutral-bg-active-default min-w-[160px] rounded'
    >
      <div className='mt-3 flex flex-col items-center gap-1'>
        <Paragraph
          className={twMerge(
            'text-3xl',
            isMuted
              ? 'text-semantic-neutral-text-subtle'
              : 'text-semantic-neutral-text-default'
          )}
        >
          ${price * 12}
        </Paragraph>
        <Text sentiment='subtle' size='xs' className='pb-1'>
          {isTeam ? 'per user / year' : 'per year'}
        </Text>
      </div>
    </div>
  )
}
