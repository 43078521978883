import {
  findFolderItem,
  type FindFolderItemCallback,
  type FindFolderItemReturn,
} from '@motion/ui-logic'
import { Sentry } from '@motion/web-base/sentry'
import type { FolderWithItemsSchema } from '@motion/zod/client'

import { useFolders } from '~/global/rpc/folders'
import { useCallback } from 'react'

export const useFindFolderItem = () => {
  const { data: folders } = useFolders()

  return useCallback(
    (cb: FindFolderItemCallback): FindFolderItemReturn | null => {
      if (!folders?.models.systemFolders) return null

      const { systemFolders } = folders.models

      const systemFoldersToSearch: FolderWithItemsSchema[] = [
        systemFolders.workspaces,
        systemFolders.private,
        systemFolders.shared,
      ].filter(Boolean)

      for (const systemFolder of systemFoldersToSearch) {
        try {
          const result = findFolderItem(systemFolder, cb)

          if (result) return result
        } catch (cause) {
          Sentry.captureException(
            new Error('Failed to find folder item', { cause }),
            {
              level: 'warning',
              tags: {
                position: 'useFindFolderItem',
              },
              extra: {
                systemFolder,
              },
            }
          )
        }
      }

      return null
    },
    [folders]
  )
}
