import { SearchableList } from '@motion/ui/base'
import { getStageVariant } from '@motion/ui-logic/pm/project'
import {
  type StageDefinitionSchema,
  type StageSchema,
} from '@motion/zod/client'

import { StageLabel } from '../../labels'

export type StageSearchableListProps<
  T extends Pick<StageDefinitionSchema, 'id' | 'name'> &
    Partial<Pick<StageSchema, 'canceledTime' | 'completedTime'>>,
> = {
  stages: T[]
  selectedStageId?: string
  close: () => void
  onChange: (stageDefinition: T) => void
  searchPlaceholder?: string
}

export function StageSearchableList<
  T extends Pick<StageDefinitionSchema, 'id' | 'name' | 'color'> &
    Partial<Pick<StageSchema, 'canceledTime' | 'completedTime'>>,
>({
  stages,
  selectedStageId,
  close,
  onChange,
  searchPlaceholder,
}: StageSearchableListProps<T>) {
  return (
    <SearchableList
      searchable
      items={stages}
      computeKey={(s) => s.id}
      computeSearchValue={(s) => s.name}
      computeSelected={(s) => s.id === selectedStageId}
      onSelect={(s) => {
        close()
        onChange(s)
      }}
      renderItem={(s) => <StageLabel value={s} variant={getStageVariant(s)} />}
      inputProps={{ placeholder: searchPlaceholder ?? 'Choose stage...' }}
    />
  )
}
