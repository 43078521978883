import {
  ArrowDownSolid,
  ArrowUpSolid,
  LockClosedSolid,
  LockOpenSolid,
} from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, IconButton, Tooltip } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { flexRender, type Header } from '@tanstack/react-table'
import { twMerge } from 'tailwind-merge'

import { DotsMenu } from './dots-menu'

import { useRecordAnalyticsSortEvent } from '../../analytics'
import { type SortField } from '../../fields'
import { useViewState } from '../../view-state'
import { PinnableCell } from '../components'

export type ColumnHeaderProps = {
  header: Header<any, any>
  moveToLeft?: () => void
  moveToRight?: () => void
  updateColumnVisibility: (columnId: string, visibility: boolean) => void
  isLastColumn: boolean
  sorted?: 'asc' | 'desc'
}
export const ColumnHeader = ({
  header,
  moveToLeft,
  moveToRight,
  updateColumnVisibility,
  isLastColumn,
  sorted,
}: ColumnHeaderProps) => {
  const canReorder = !header.column.columnDef.meta?.disableReordering

  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: header.id, disabled: !canReorder })

  if (transform) {
    transform.scaleX = 1
    transform.scaleY = 1
  }

  const isResizing = header.column.getIsResizing()
  const canResize = header.column.getCanResize()
  const canSort = header.column.getCanSort()
  const canHide = header.column.getCanHide()
  const isPinned = header.column.getIsPinned() === 'left'
  const canPin = header.column.getCanPin()

  const pinColumn = (pin: boolean) => {
    header.column.pin(pin ? 'left' : false)

    recordAnalyticsEvent('PROJECT_MANAGEMENT_LIST_PIN_COLUMN', {
      name: header.column.id,
      pin,
    })
  }

  const dragListeners =
    canReorder && listeners
      ? {
          ...listeners,
          onPointerDown(e: React.PointerEvent<HTMLDivElement>) {
            e.currentTarget.setPointerCapture(e.pointerId)
            listeners.onPointerDown?.(e)
          },
          onPointerUp(e: React.PointerEvent<HTMLDivElement>) {
            e.currentTarget.releasePointerCapture(e.pointerId)
            listeners.onPointerUp?.(e)
          },
        }
      : {}

  const headerStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    gridColumn: header.index + 1,
    gridRow: 1,
  }

  return (
    <HeaderCell
      key={header.id}
      ref={setNodeRef}
      {...attributes}
      style={headerStyle}
      data-sort={sorted}
      isPinned={isPinned}
      addMarginRight={isLastColumn}
    >
      <div
        ref={setActivatorNodeRef}
        className={twMerge(
          'relative flex flex-1 items-center gap-1 overflow-hidden',
          canReorder
            ? isDragging
              ? 'cursor-grabbing'
              : 'cursor-grab'
            : 'cursor-default'
        )}
        {...dragListeners}
      >
        <div className='truncate'>
          {flexRender(header.column.columnDef.header, header.getContext())}
        </div>
        {canSort && <ConnectedSortButtons name={header.column.id} />}
        <div className='flex-1' />
        {canPin && (
          <PinButton
            isPinned={isPinned}
            onPin={() => pinColumn(true)}
            onUnpin={() => pinColumn(false)}
          />
        )}
        <DotsMenu
          moveToLeft={moveToLeft}
          moveToRight={moveToRight}
          hide={
            canHide ? () => updateColumnVisibility(header.id, false) : undefined
          }
        />
        {canResize && (
          <div
            className={twMerge(
              'h-6 w-1 shrink-0 cursor-col-resize bg-semantic-primary-bg-strong-default opacity-0 hover:opacity-100',
              isResizing && 'opacity-100'
            )}
            onPointerDown={(e) => {
              e.stopPropagation()
              e.currentTarget.setPointerCapture(e.pointerId)
              header.getResizeHandler()(e)
            }}
            onPointerUp={(e) => {
              e.currentTarget.releasePointerCapture(e.pointerId)
            }}
          />
        )}
      </div>
    </HeaderCell>
  )
}

const HeaderCell = classed('div', PinnableCell, {
  base: `
    group/headercell
    flex items-center
    text-semantic-neutral-text-subtle
    text-[10px] font-semibold
    uppercase
    select-none
    bg-calendar-bg-default
    hover:bg-pivot-table-cell-bg-hover
    cursor-default

    &:nth-last-child(2)]:pr-6
  `,
  variants: {
    // add margin to prevent sticky show columns button from appearing over the reordering options
    addMarginRight: {
      true: 'mr-6',
      false: '',
    },
  },
  defaultVariants: {
    addMarginRight: false,
  },
})

type ConnectedSortButtonsProps = {
  name: string
}

const ConnectedSortButtons = (props: ConnectedSortButtonsProps) => {
  const [viewState, setViewState] = useViewState()
  const recordAnalyticsSortEvent = useRecordAnalyticsSortEvent()

  const { sortBy } = viewState

  return (
    <SortButtons
      direction={
        sortBy?.field === props.name ? (sortBy.direction ?? 'asc') : 'unset'
      }
      onChange={(dir) => {
        setViewState((prev) => ({
          ...prev,
          sortBy:
            dir === 'unset'
              ? null
              : {
                  field: props.name as SortField,
                  direction: dir,
                },
        }))

        recordAnalyticsSortEvent(props.name as SortField, dir)
      }}
    />
  )
}

type SortButtonsProps = {
  direction: 'asc' | 'desc' | 'unset'
  onChange(direction: 'asc' | 'desc' | 'unset'): void
  className?: string
}

const directionMap = {
  desc: 'unset',
  asc: 'desc',
  unset: 'asc',
} as const

const SortButtons = (props: SortButtonsProps) => {
  const { direction } = props

  const handleClick = () => {
    const nextDirection = directionMap[direction]
    props.onChange(nextDirection)
  }

  return (
    <div
      className={twMerge(
        'hidden group-hover/headercell:flex',
        props.className,
        direction !== 'unset' && 'flex'
      )}
    >
      <IconButton
        sentiment={direction === 'unset' ? 'neutral' : 'primary'}
        size='xsmall'
        variant='muted'
        icon={direction === 'desc' ? ArrowDownSolid : ArrowUpSolid}
        onClick={handleClick}
        onPointerDown={(e) => {
          e.stopPropagation()
        }}
      />
    </div>
  )
}

type PinButtonProps = {
  isPinned: boolean
  onPin: () => void
  onUnpin: () => void
}

const PinButton = ({ isPinned, onPin, onUnpin }: PinButtonProps) => {
  return (
    <div
      className={twMerge(
        'hidden group-hover/headercell:flex',
        isPinned && 'flex'
      )}
    >
      {isPinned ? (
        <div className='group/unfreezebtn'>
          <Tooltip content='Unfreeze column'>
            <Button
              sentiment='neutral'
              size='xsmall'
              variant='muted'
              iconOnly
              onClick={onUnpin}
              onPointerDown={(e) => {
                e.stopPropagation()
              }}
            >
              <span className='hidden group-hover/unfreezebtn:block'>
                <LockOpenSolid />
              </span>
              <span className='group-hover/unfreezebtn:hidden'>
                <LockClosedSolid />
              </span>
            </Button>
          </Tooltip>
        </div>
      ) : (
        <Tooltip content='Freeze column'>
          <IconButton
            sentiment='neutral'
            size='xsmall'
            variant='muted'
            icon={LockClosedSolid}
            onClick={onPin}
            onPointerDown={(e) => {
              e.stopPropagation()
            }}
          />
        </Tooltip>
      )}
    </div>
  )
}
