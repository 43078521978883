import { ChevronLeftOutline, ChevronRightOutline } from '@motion/icons'
import { type ComponentProps } from '@motion/theme'
import { formatDateRange, formatToReadableWeekDayMonth } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type ProjectWithRelations } from '~/global/proxies'
import { DateTime } from 'luxon'
import { memo, useCallback, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import { useScrollToDate } from '../../../hooks'
import { ZIndexMap } from '../../../shared-constants'
import { DEFAULT_PROJECT_LENGTH_IN_WEEKS } from '../../../utils'
import { ItemContainer } from '../../common'

export type OffscreenDateIndicatorProps = ComponentProps<
  typeof ItemContainer
> & {
  project: ProjectWithRelations
  direction: 'left' | 'right'
  showName?: boolean
}

export const OffscreenDateIndicator = memo(function OffscreenDateIndicator(
  props: OffscreenDateIndicatorProps
) {
  const { project, direction, ...rest } = props
  const scrollToDate = useScrollToDate()

  const scroll = useCallback(() => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_JUMP_TO_DATE', {
      type: 'offscreen-date-indicator',
    })
    // If the project has a start date, scroll to that
    if (project.startDate) {
      scrollToDate(project.startDate)
    }

    // Else, scroll to the due date - the DEFAULT_PROJECT_LENGTH_IN_WEEKS weeks

    if (project.dueDate) {
      const isoDate = DateTime.fromISO(project.dueDate)
        .minus({
          weeks: DEFAULT_PROJECT_LENGTH_IN_WEEKS,
        })
        .toISO()

      scrollToDate(isoDate)
    }

    return
  }, [project, scrollToDate])

  const dateText = useMemo(() => {
    if (project.startDate && project.dueDate) {
      return formatDateRange(
        DateTime.fromISO(project.startDate),
        DateTime.fromISO(project.dueDate)
      )
    } else if (project.startDate) {
      return formatToReadableWeekDayMonth(DateTime.fromISO(project.startDate))
    } else if (project.dueDate) {
      return formatToReadableWeekDayMonth(DateTime.fromISO(project.dueDate))
    }

    return 'No dates'
  }, [project])

  return (
    <ItemContainer
      style={{ zIndex: ZIndexMap.offscreenIndicator, ...rest.style }}
    >
      <div className='px-2 py-1 rounded shadow-sm bg-button-neutral-outlined-bg-default hover:bg-button-neutral-outlined-bg-hover border border-button-neutral-outlined-border-default max-w-[200px]'>
        <button
          onClick={scroll}
          className='flex items-center space-x-1 text-xs font-semibold text-button-neutral-outlined-text-default max-w-full'
        >
          {direction === 'left' && (
            <ChevronLeftOutline className='w-4 h-4 min-w-4' />
          )}
          <div className='flex flex-col gap-1 items-start max-w-[160px]'>
            {props.showName && (
              <div className='text-semantic-neutral-text-default text-xs truncate max-w-full'>
                {project.name}
              </div>
            )}
            <span
              className={twMerge(
                'text-xs truncate max-w-full',
                props.showName && 'text-semantic-neutral-text-subtle'
              )}
            >
              {dateText}
            </span>
          </div>
          {direction === 'right' && (
            <ChevronRightOutline className='w-4 h-4 min-w-4' />
          )}
        </button>
      </div>
    </ItemContainer>
  )
})
