import { StageSolid } from '@motion/icons'
import { useSharedState } from '@motion/react-core/shared-state'
import { templateStr } from '@motion/react-core/strings'
import { createNoneId, isNoneId } from '@motion/shared/identifiers'
import { classed } from '@motion/theme'
import { type Group, Tooltip } from '@motion/ui/base'
import { createNoneStageDefinition } from '@motion/ui-logic/pm/data'

import { StageLabel } from '~/global/components/labels'
import {
  AppWorkspaceContext,
  type WorkspaceStageDefinition,
} from '~/global/contexts'
import { useProjectDefinition, useWorkspaceById } from '~/global/hooks'

import { ConnectedGroupedItemDropdown } from './connected-grouped-item-dropdown'
import { FilterItem } from './filter-item/filter-item'

import { useFieldFilter } from '../../../context'
import { MultiSelectFilterValue } from '../../multi-select-filter-value'
import { type DropdownFilterProps } from '../../types'

export const ConnectedStageFilter = (props: DropdownFilterProps) => {
  const [value, setValue] = useFieldFilter(props.target, 'stageDefinitionIds')
  const [ctx] = useSharedState(AppWorkspaceContext)

  const getAllItems = () => [
    {
      ...createNoneStageDefinition(createNoneId('workspace')),
      projectDefinitionId: createNoneId('projectDefinition'),
    },
    ...ctx.stageDefinitions.all,
  ]

  return (
    <FilterItem
      label='Stages'
      canInvert
      inverted={value?.inverse}
      onInvertChanged={(invert) => {
        if (value == null) return
        setValue({ ...(value ?? {}), inverse: invert })
      }}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      onDismiss={() => setValue(null)}
      renderDropdown={({ close }) => (
        <ConnectedGroupedItemDropdown
          applyTo={props.target}
          field='stageDefinitionIds'
          getAllItems={getAllItems}
          placeholder='Choose stages...'
          renderItem={(item) => <StageItem item={item} />}
          renderHeader={(group) => <StageItemGroup group={group} />}
          close={close}
        />
      )}
    >
      <MultiSelectFilterValue
        Icon={StageSolid}
        type='stageDefinitions'
        ids={value?.value ?? null}
        getAllItems={getAllItems}
        labelSize='small'
      />
    </FilterItem>
  )
}

type StageItemProps = {
  item: WorkspaceStageDefinition
}

const StageItem = ({ item }: StageItemProps) => {
  return (
    <Container>
      <StageLabel
        value={item}
        variant={isNoneId(item.id) ? 'noStage' : 'default'}
      />
      <ConnectedItemSubTitle item={item} />
    </Container>
  )
}

type StageItemGroupProps = {
  group: Group<WorkspaceStageDefinition>
}

const StageItemGroup = ({ group }: StageItemGroupProps) => {
  const hasSingleItem = group.items.length === 1
  const firstItem = group.items[0]

  return (
    <Container>
      <StageLabel
        value={firstItem}
        variant={isNoneId(firstItem.id) ? 'noStage' : 'default'}
      />
      {hasSingleItem ? (
        <ConnectedItemSubTitle item={firstItem} />
      ) : (
        <Subtitle>
          {templateStr('({{count}} templates)', { count: group.items.length })}
        </Subtitle>
      )}
    </Container>
  )
}

type ConnectedItemSubTitleProps = {
  item: WorkspaceStageDefinition
}

const ConnectedItemSubTitle = ({ item }: ConnectedItemSubTitleProps) => {
  const workspace = useWorkspaceById(item.workspaceId)
  const projectDefinition = useProjectDefinition(item.projectDefinitionId)

  if (workspace == null || projectDefinition == null) return null

  const text = templateStr('{{projectDefinition}}, {{workspace}}', {
    projectDefinition: projectDefinition.name,
    workspace: workspace.name,
  })

  return (
    <Tooltip asChild content={text}>
      <Subtitle>({text})</Subtitle>
    </Tooltip>
  )
}

const Container = classed(
  'div',
  'grid grid-cols-[auto,minmax(0,auto)] overflow-hidden gap-x-1 items-center max-w-xs'
)

const Subtitle = classed(
  'span',
  'text-xs text-dropdown-item-text-disabled text-nowrap text-ellipsis overflow-hidden'
)
