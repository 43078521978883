import { ExclamationCircleSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { classed } from '@motion/theme'
import { Tooltip } from '@motion/ui/base'
import {
  areTimezonesOffsetsEqual,
  formatTimezoneToString,
  getZoneFromTimezone,
} from '@motion/ui-logic'

import { useTimezoneSettings } from '~/global/hooks'
import { DateTime } from 'luxon'

export const DifferentTimezoneAlert = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { defaultTimezone } = useTimezoneSettings({ useDefaults: true })
  const localTimezone = DateTime.local().zone
  const defaultTimezoneZone = getZoneFromTimezone(defaultTimezone)

  const isInDifferentTimezone = !areTimezonesOffsetsEqual(
    defaultTimezone,
    localTimezone.name
  )

  if (!isInDifferentTimezone) return children

  return (
    <Container>
      {children}

      <Tooltip
        asChild
        placement='bottom'
        content={templateStr(
          'Calendar display is in {{local}} timezone, but auto-scheduling is set to {{default}} timezone. Some tasks may not schedule correctly. You can go to settings and change your default timezone to your local timezone',
          {
            local: formatTimezoneToString(localTimezone),
            default: formatTimezoneToString(defaultTimezoneZone),
          }
        )}
      >
        <ExclamationCircleSolid className='absolute z-10 -top-2 -right-2 w-4 h-4 text-semantic-error-icon-default rounded-full' />
      </Tooltip>
    </Container>
  )
}

const Container = classed('span', 'relative inline-flex')
