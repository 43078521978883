import type {
  FlowTemplateFormTask,
  FlowTemplateStage,
} from '@motion/ui-logic/pm/project'

export function getNewIndices(
  stages: FlowTemplateStage[],
  taskId: FlowTemplateFormTask['id']
) {
  return stages.reduce<[number, number] | undefined>(
    (acc, stage, stageIndex) => {
      if (acc) return acc

      const taskIndex = stage.tasks.findIndex((task) => task.id === taskId)

      return taskIndex !== -1 ? ([stageIndex, taskIndex] as const) : undefined
    },
    undefined
  )
}
