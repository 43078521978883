import { type MeetingActionItemSchema } from '@motion/rpc-types'

import { type ReactNode, useMemo, useState } from 'react'

import {
  ActionItemContext,
  type ActionItemContextType,
} from './action-item-context'

import { useActionItemData } from '../hooks/use-action-item-data'

type ActionItemProviderProps = {
  initialActionItem: MeetingActionItemSchema
  children: ReactNode
}

export function ActionItemProvider({
  initialActionItem,
  children,
}: ActionItemProviderProps) {
  const [modalVisible, setModalVisible] = useState(false)
  const { task, taskIsLoading, notPermittedWorkspace, isRejected } =
    useActionItemData({ actionItem: initialActionItem })

  const value = useMemo<ActionItemContextType>(
    () => ({
      initialActionItem,
      modalVisible,
      setModalVisible,
      closeModal: () => setModalVisible(false),
      task,
      taskIsLoading,
      taskNotAccessible: notPermittedWorkspace,
      isRejected,
    }),
    [
      initialActionItem,
      isRejected,
      modalVisible,
      notPermittedWorkspace,
      task,
      taskIsLoading,
    ]
  )

  return (
    <ActionItemContext.Provider value={value}>
      {children}
    </ActionItemContext.Provider>
  )
}
