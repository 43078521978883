import { createFetch } from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
  type V2ResponseStoreShape,
} from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { merge } from '@motion/utils/core'

import { notifyManager } from '@tanstack/react-query'

import { type HandlerContext } from './types'
import { buildQueryContext } from './utils'

import { log } from '../log'
import { type StageDefinitionBatchOperations } from '../types'

const KEYS_TO_UPDATE = [MODEL_CACHE_KEY, API.workspacesV2.queryKeys.root]

const updateFilter = createQueryFilter(KEYS_TO_UPDATE)

export async function refreshStageDefinition(
  ctx: HandlerContext,
  mutations: StageDefinitionBatchOperations['data']
) {
  log.info('refresh-stage-definition', mutations)

  if (!mutations.update && !mutations.create && !mutations.delete) {
    log.warn('Missing mutation ids')
    return
  }

  const getStageDefinition = createFetch<typeof API.stageDefinitions.getById>(
    API.stageDefinitions.getById,
    buildQueryContext(ctx)
  )

  const stagesToFetch = Array.from(
    new Set([...mutations.update, ...mutations.create])
  )

  const stageResolutions = await Promise.allSettled(
    stagesToFetch.map((stage) => {
      return getStageDefinition({
        id: stage.id,
        workspaceId: stage.workspaceId,
      })
    })
  )
  const stageDefinitions = stageResolutions
    .filter((x) => x.status === 'fulfilled')
    .map((x) => x.value)

  const singleUpdate: V2ResponseStoreShape = {
    ids: stageDefinitions.map((p) => p.id),
    meta: {
      model: 'stageDefinitions',
    },
    models: {
      stageDefinitions: merge(
        {},
        ...stageDefinitions.map((x) => x.models.stageDefinitions)
      ),
    },
  }

  notifyManager.batch(() => {
    MotionCache.upsert(ctx.client, updateFilter, singleUpdate)

    if (mutations.delete.length) {
      MotionCache.delete(
        ctx.client,
        updateFilter,
        'stageDefinitions',
        mutations.delete
      )
    }
  })
}
