import { EventConferenceType } from '@motion/shared/common'
import {
  descriptionAddConference,
  generateCustomLocationMeetingMessage,
  generatePhoneMeetingMessage,
  stripDescriptionConference,
} from '@motion/ui-logic/calendar'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useConferenceSettingsApi } from '~/areas/settings/state'
import { useAppDispatch } from '~/state/hooks'
import { showPromptModal } from '~/state/modalsSlice'
import { useCallback } from 'react'

import { useGenerateZoomData } from './use-generate-zoom-data'

export const useGenerateConferencingData = () => {
  const dispatch = useAppDispatch()
  const { selectConferenceSettings } = useConferenceSettingsApi()
  const generateZoomData = useGenerateZoomData()
  const conferenceSettings = selectConferenceSettings()

  const getPhoneNumber = useCallback(async () => {
    return (await dispatch(
      showPromptModal({ type: 'phoneNumber' })
    ).unwrap()) as string
  }, [dispatch])

  const getCustomLocation = useCallback(async () => {
    return (await dispatch(
      showPromptModal({ type: 'customLocation' })
    ).unwrap()) as string
  }, [dispatch])

  return useCallback(
    async (
      conferenceType: EventConferenceType,
      currentDescription: string
    ): Promise<{
      location: string
      description: string
    }> => {
      const newDescription = stripDescriptionConference(currentDescription)

      if (conferenceType === EventConferenceType.zoom) {
        recordAnalyticsEvent('CALENDAR_ZOOM_CLICK')
        return generateZoomData()
      } else if (conferenceType === EventConferenceType.phone) {
        recordAnalyticsEvent('CALENDAR_PHONE_MODAL_OPEN')
        let phoneNumber = conferenceSettings?.phoneNumber
        if (!phoneNumber) {
          phoneNumber = await getPhoneNumber()
        }
        if (phoneNumber) {
          const inviteMessage = generatePhoneMeetingMessage(phoneNumber)
          const description = descriptionAddConference(
            newDescription,
            inviteMessage
          )
          return { location: phoneNumber, description }
        }
      } else if (conferenceType === EventConferenceType.customLocation) {
        recordAnalyticsEvent('CALENDAR_CUSTOM_LOCATION_OPEN')
        let customLocation = conferenceSettings?.customLocation
        if (!customLocation) {
          customLocation = await getCustomLocation()
        }
        if (customLocation) {
          const inviteMessage =
            generateCustomLocationMeetingMessage(customLocation)
          const description = descriptionAddConference(
            newDescription,
            inviteMessage
          )
          return { location: customLocation, description }
        }
      }

      return { location: '', description: newDescription }
    },
    [
      conferenceSettings?.customLocation,
      conferenceSettings?.phoneNumber,
      generateZoomData,
      getCustomLocation,
      getPhoneNumber,
    ]
  )
}
