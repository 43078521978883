import {
  convertRelativeIntervalToFormRelativeInterval,
  DEFAULT_RELATIVE_DEADLINE,
  DEFAULT_RELATIVE_START,
  type TaskDefinitionFormFields,
} from '@motion/ui-logic/pm/project'

import { useUrlTaskInitialFormData as useTaskInitialFormData } from '~/areas/tasks/modals/task-modal/hooks'

import { useTaskDefinitionModalState } from '../use-task-definition-modal-state'

export function useInitialFormData(): TaskDefinitionFormFields {
  const { initialTaskData, taskDefinition, flowFormData } =
    useTaskDefinitionModalState()

  const initialFormData = useTaskInitialFormData(initialTaskData)

  if (!taskDefinition) {
    throw new Error('Task definition is required')
  }

  if (!flowFormData) {
    throw new Error('Flow form data is required')
  }

  return {
    ...initialFormData,
    id: taskDefinition.id,
    scheduleMeetingWithinDays: taskDefinition.scheduleMeetingWithinDays,
    startRelativeInterval: convertRelativeIntervalToFormRelativeInterval(
      taskDefinition.startRelativeInterval ?? DEFAULT_RELATIVE_START
    ),
    dueRelativeInterval: convertRelativeIntervalToFormRelativeInterval(
      taskDefinition.dueRelativeInterval ?? DEFAULT_RELATIVE_DEADLINE
    ),
  }
}
