import { BlockedBySolid } from '@motion/icons'
import { wrapVariableInDelimiters } from '@motion/shared/flows'
import {
  Button,
  FieldButton,
  PopoverTrigger,
  SearchableList,
} from '@motion/ui/base'
import {
  type FlowTemplateFormTask,
  type TextVariable,
} from '@motion/ui-logic/pm/project'
import { type TaskDefinitionSchema } from '@motion/zod/client'

import { useMemo } from 'react'

import { useNonEmptyTasksAbove } from './task-definition/hooks'

import { stripVariableKeyFromText } from '../utils'

export type UnconnectedBlockedByFieldProps = {
  stageTasks: FlowTemplateFormTask[]
  task: TaskDefinitionSchema
  values: TaskDefinitionSchema[]
  onChange: (values: string[]) => void
  textVariables: TextVariable[]
}

export function UnconnectedBlockedByField(
  props: UnconnectedBlockedByFieldProps
) {
  const { task, values, onChange, textVariables, stageTasks } = props

  const nonEmptyTasksAbove = useNonEmptyTasksAbove(
    stageTasks,
    task,
    textVariables
  )

  const name = useMemo(() => {
    if (!values.length) return null

    if (values.length > 1) {
      return `${values.length} tasks`
    }

    let name = values[0].name

    textVariables.forEach((v) => {
      name = stripVariableKeyFromText(name, wrapVariableInDelimiters(v.key))
    })

    return name
  }, [values, textVariables])

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={() => (
        <>
          <SearchableList
            itemType='checkbox'
            items={nonEmptyTasksAbove}
            computeKey={(item) => item.id}
            computeSearchValue={(item) => item.name}
            computeSelected={(item) =>
              values.some((value) => value.id === item.id)
            }
            onSelect={(items) => {
              onChange(items.map((item) => item.id))
            }}
            renderItem={(task) => (
              <div className='max-w-xs truncate'>{task.name}</div>
            )}
            inputProps={{ placeholder: 'Search tasks...' }}
          />
          <div className='flex justify-between border-t border-dropdown-border px-3 py-2'>
            <Button
              variant='outlined'
              sentiment='neutral'
              size='small'
              onClick={() => onChange([])}
            >
              Clear
            </Button>
            <Button
              variant='outlined'
              sentiment='neutral'
              size='small'
              onClick={() =>
                onChange(nonEmptyTasksAbove.map((task) => task.id))
              }
            >
              Select all
            </Button>
          </div>
        </>
      )}
    >
      <FieldButton variant='muted' fullWidth size='xsmall'>
        <div className='flex items-center gap-1 overflow-hidden'>
          <BlockedBySolid className='!text-semantic-error-icon-default' />
          {name != null ? (
            <span className='text-semantic-neutral-text-default truncate'>
              {name}
            </span>
          ) : (
            <span className='text-semantic-neutral-text-subtle'>
              Blocked by
            </span>
          )}
        </div>
      </FieldButton>
    </PopoverTrigger>
  )
}
