import { FieldButton } from '@motion/ui/base'
import { formatToTaskDuration, getDurations } from '@motion/ui-logic'

import {
  DurationDropdown,
  type DurationDropdownProps,
} from '~/areas/project-management/components'
import { useTaskUpdater } from '~/areas/tasks/hooks'
import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import { useActionItemForm } from '../hooks'
import { useActionItemContext } from '../provider'

export function ConnectedInlineDurationField() {
  const { task } = useActionItemContext()
  const { form } = useActionItemForm()

  const { field } = useController({
    name: 'duration',
    control: form.control,
  })

  const updateTask = useTaskUpdater()

  const durations = useMemo(() => getDurations(), [])

  const onChange: DurationDropdownProps['onChange'] = async (duration) => {
    field.onChange(duration)

    if (task != null) {
      await updateTask(task.id, { duration })
    }
  }

  return (
    <DurationDropdown
      value={field.value}
      choices={durations}
      onChange={onChange}
    >
      <FieldButton variant='minimal' size='xsmall'>
        <span className='truncate text-[10px] font-normal'>
          {formatToTaskDuration(field.value)}
        </span>
      </FieldButton>
    </DurationDropdown>
  )
}
