import {
  AutoscheduleStarSolid,
  CalendarSolid,
  NoteSolid,
  ProjectCubeSolid,
  TaskSolid,
} from '@motion/icons'
import { classed } from '@motion/theme'
import { ActionList, Shortcut, SHORTCUT_DELIMITER } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type AnalyticEvents } from '@motion/web-base/analytics/events'
import { getWindowData } from '@motion/web-base/env'
import { Sentry } from '@motion/web-base/sentry'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { useCreateNote } from '~/areas/notes'
import { useMyTasksWorkspace } from '~/global/hooks'
import { useProjectModalUrl, useTaskModalUrl } from '~/global/navigation'
import { selectDesktopSettings } from '~/state/desktopSlice'
import { useAppSelector } from '~/state/hooks'
import { parseShortcutKey } from '~/utils/desktop'
import { useMemo } from 'react'

const DEFAULT_SHORTCUT = 'alt+space'

type CreateNewActionMenuProps = {
  close: () => void
}

export const CreateNewMenu = ({ close }: CreateNewActionMenuProps) => {
  const buildTaskModalUrl = useTaskModalUrl()
  const buildProjectModalUrl = useProjectModalUrl()
  const createNote = useCreateNote()
  const { isElectron } = getWindowData()
  const desktopSettings = useAppSelector(selectDesktopSettings)
  const modalApi = useModalApi()

  const myTasksWorkspace = useMyTasksWorkspace()

  const hasNotes = useHasTreatment('notes-in-webapp')

  const hasAIWorkflows = useHasTreatment('ai-workflows')
  const keysToUse = useMemo(() => {
    const shouldUseDesktopShortcut =
      isElectron && desktopSettings?.shortcuts?.addTask

    if (!shouldUseDesktopShortcut) return DEFAULT_SHORTCUT

    return desktopSettings.shortcuts.addTask
      .split(SHORTCUT_DELIMITER)
      .map(parseShortcutKey)
      .join(' ')
  }, [isElectron, desktopSettings])

  const handleCreateNote = () => {
    if (myTasksWorkspace == null) {
      Sentry.captureException(
        new Error('New doc: Could not find my tasks workspace')
      )
      return
    }

    createNote(
      {
        title: '',
        targetType: 'WORKSPACE',
        targetId: myTasksWorkspace?.id,
      },
      { navigate: true }
    )
  }

  return (
    <ActionList
      onActionAnyItem={close}
      sections={[
        {
          items: [
            {
              prefix: (
                <IconWrapper>
                  <TaskSolid />
                </IconWrapper>
              ),
              content: (
                <div className='flex items-center gap-2 justify-between'>
                  <span>New Task</span>
                  {isElectron && <Shortcut shortcut={keysToUse} />}
                </div>
              ),
              url: buildTaskModalUrl(),
              onAction: wrapWithAnalytics('task')(),
            },
            hasNotes && {
              prefix: (
                <IconWrapper>
                  <NoteSolid />
                </IconWrapper>
              ),
              content: 'New Doc',
              onAction: wrapWithAnalytics('doc')(handleCreateNote),
            },
            {
              prefix: (
                <IconWrapper>
                  <ProjectCubeSolid />
                </IconWrapper>
              ),
              content: 'New Project',
              url: buildProjectModalUrl(),
              onAction: wrapWithAnalytics('project')(),
            },
            {
              prefix: (
                <IconWrapper>
                  <CalendarSolid />
                </IconWrapper>
              ),
              content: 'New Meeting or Event',
              onAction: wrapWithAnalytics('event')(() => {
                modalApi.open('event-modal', {})
              }),
            },
            hasAIWorkflows && {
              prefix: (
                <IconWrapper>
                  <AutoscheduleStarSolid />
                </IconWrapper>
              ),
              content: 'Run AI Workflow',
              onAction: wrapWithAnalytics('ai-workflow')(() => {
                modalApi.open('ai-workflow-modal')
              }),
            },
          ].filter(Boolean),
        },
      ]}
    />
  )
}

const IconWrapper = classed('div', {
  base: `
    flex items-center justify-center size-6
    rounded-lg bg-semantic-neutral-surface-overlay-bg-subtlest
    border border-semantic-neutral-border-light
    text-dropdown-item-icon-default

    group-hover/row:border-semantic-neutral-border-strong

    [&>svg]:size-4
  `,
})

function wrapWithAnalytics(
  type: AnalyticEvents['PROJECT_MANAGEMENT_CLICK_PRIMARY_ADD']['type']
) {
  return (cb?: () => void) => {
    return () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_CLICK_PRIMARY_ADD', {
        type: type,
      })

      cb?.()
    }
  }
}
