import { createFetch } from '@motion/rpc'
import {
  createQueryFilter,
  log,
  MODEL_CACHE_KEY,
  MotionCache,
  type V2ResponseStoreShape,
} from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'

import { notifyManager } from '@tanstack/react-query'

import { type HandlerContext } from './types'
import { buildQueryContext } from './utils'

import { type StageEtaBatchOperations } from '../types'

const KEYS_TO_UPDATE = [MODEL_CACHE_KEY, API.workspacesV2.queryKeys.root]

const updateFilter = createQueryFilter(KEYS_TO_UPDATE)

export async function refreshStageEta(
  ctx: HandlerContext,
  mutation: StageEtaBatchOperations['data']
) {
  log.info('refresh-stage-eta', mutation)

  const { workspaceId, id, projectId, ...rest } = mutation

  if (!id || !workspaceId) {
    log.warn('Missing definitionId or workspaceId')
    return
  }

  const getProject = createFetch<typeof API.projectsV2.getById>(
    API.projectsV2.getById,
    buildQueryContext(ctx)
  )

  const projectData = await getProject({ projectId })
  const project = projectData['models']['projects'][projectId]

  if (!project) {
    log.warn('Missing project')
    return
  }

  const stageIndex = project?.stages.findIndex((s) => s.id === id)

  if (stageIndex === -1) {
    log.warn('Missing stage')
    return
  }

  // Replace the stage data with the new data
  const newStageData = {
    ...project?.stages[stageIndex],
    ...rest,
  }

  const singleUpdate: V2ResponseStoreShape = {
    ids: [projectId],
    meta: {
      model: 'projects',
    },
    models: {
      projects: {
        [projectId]: {
          ...project,
          stages: [
            ...project?.stages.slice(0, stageIndex),
            newStageData,
            ...project?.stages.slice(stageIndex + 1),
          ],
        },
      },
    },
  }

  notifyManager.batch(() => {
    MotionCache.upsert(ctx.client, updateFilter, singleUpdate)
  })
}
