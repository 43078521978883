import { getStageDateRangeColors } from '~/areas/flows'
import { useMemo } from 'react'

import { useSetupProjectForm } from './use-setup-project-form'

export function useSetupProjectDateRangeColors() {
  const {
    form: { watch },
  } = useSetupProjectForm()

  const projectStart = watch('startDate')
  const projectDeadline = watch('dueDate')
  const stages = watch('speculativeProject.stages')
  const projectDefinition = watch('projectDefinition')

  const projectInput = useMemo(
    () => ({
      startDate: projectStart,
      dueDate: projectDeadline,
      stages: stages ?? [],
      projectDefinitionId: projectDefinition?.id ?? null,
    }),
    [projectStart, projectDeadline, stages, projectDefinition?.id]
  )

  const stageDateRangeColors = useMemo(
    () =>
      getStageDateRangeColors(projectInput, projectDefinition?.stages ?? []),
    [projectInput, projectDefinition?.stages]
  )

  return stageDateRangeColors
}
