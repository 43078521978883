import { byValue } from '@motion/utils/array'
import { values } from '@motion/utils/object'

import {
  DEFAULT_STRING_SORT,
  getSortFn,
} from '~/areas/project-management/pages/pm-v3/fields'
import { useMeetingInsightsQuery } from '~/global/rpc/v2'
import { useMemo } from 'react'

import { useNotetakerViewState } from '../../state'
import { MEETING_INSIGHTS_FIELDS } from '../types'
import { mapToQueryFilter } from '../utils'

export function useMeetingInsightsData() {
  const [viewState] = useNotetakerViewState()

  const { data, isLoading } = useMeetingInsightsQuery({
    filters: mapToQueryFilter(viewState.filters),
  })

  return useMemo(() => {
    if (!data)
      return {
        filtered: [],
        data: [],
        isLoading,
      }

    const sortBy =
      getSortFn(viewState.sortBy ?? null, values(MEETING_INSIGHTS_FIELDS)) ??
      byValue(
        (meetingInsight) => meetingInsight.eventData?.title ?? null,
        DEFAULT_STRING_SORT
      )

    const meetingInsightsList = data.ids
      .map((id) => data.models.meetingInsights[id])
      .sort(sortBy)

    if (!viewState.search)
      return {
        filtered: meetingInsightsList,
        data: meetingInsightsList,
        isLoading,
      }

    const filtered = meetingInsightsList.filter((x) => {
      if (!x.eventData) return false

      const search = viewState.search.toLowerCase()

      const title = x.eventData.title.toLowerCase()
      const organizer = x.eventData.organizer?.displayName?.toLowerCase() ?? ''
      const email = x.eventData.organizer?.email?.toLowerCase() ?? ''

      return (
        title.includes(search) ||
        organizer.includes(search) ||
        email.includes(search)
      )
    })

    return {
      filtered,
      data: meetingInsightsList,
      isLoading,
    }
  }, [data, isLoading, viewState.search, viewState.sortBy])
}
