import { isNoneId, isPlaceholderId } from '@motion/shared/identifiers'
import {
  type FlowTemplateFormFields,
  type FlowTemplateStage,
  type RoleVariable,
  type TextVariable,
} from '@motion/ui-logic/pm/project'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { type StageDefinitionSchema } from '@motion/zod/client'

import { useMyTasksWorkspace, useStageDefinition } from '~/global/hooks'
import { useMemo } from 'react'

import { useStageModalUrlParams } from './use-stage-modal-url'

import { useInitialFlowStages } from '../../hooks'

type UseInitialStageFormData = {
  stageId?: string
  stage?: 'new' | 'edit'
}

export function useInitialStageFormData(
  modalParams: UseInitialStageFormData
): FlowTemplateFormFields {
  const modalMode = modalParams.stage ? 'modal-api' : 'url'

  const defaultWorkspace = useMyTasksWorkspace()

  const { workspaceId: workspaceIdParam } = useStageModalUrlParams()

  const { uid: currentUserId } = useAuthenticatedUser()
  const stageDefinition = useFormStageDefinition(modalParams)

  const workspaceId =
    stageDefinition != null && !isNoneId(stageDefinition.id)
      ? stageDefinition.workspaceId
      : (workspaceIdParam ?? defaultWorkspace?.id)

  if (workspaceId == null || workspaceId === '') {
    throw new Error('Workspace id not defined')
  }

  const isNewStage = isNoneId(stageDefinition.id)

  const stages = useInitialFlowStages({
    workspaceId,
    projectDefinition: isNewStage
      ? null
      : { stages: [stageDefinition], stageDefinitionReferences: [] },
    isCreatingFromTemplate: false,
    currentUserId,
  })

  return useMemo(() => {
    return convertToFlowTemplateForm({
      stages,
      workspaceId,
      roles: [],
      textVariables: [],
      modalMode,
    })
  }, [stages, workspaceId, modalMode])
}

type ConvertToFlowTemplateFormArgs = {
  stages: FlowTemplateStage[]
  workspaceId: string
  roles: RoleVariable[]
  textVariables: TextVariable[]
  modalMode: 'modal-api' | 'url'
}

function convertToFlowTemplateForm({
  stages,
  workspaceId,
  roles,
  textVariables,
  modalMode,
}: ConvertToFlowTemplateFormArgs): FlowTemplateFormFields {
  const stageDefinition = stages[0]

  return {
    type: 'stage',
    modalMode,
    mode: 'user',
    id: stageDefinition.id,
    workspaceId,
    name: isPlaceholderId(stageDefinition.id) ? '' : stageDefinition.name,
    color: stageDefinition.color,
    roles,
    textVariables,
    stages: [stageDefinition],
    // Unused, satisfies type
    isLoading: false,
    hasError: false,
    description: '',
    managerId: '',
    priorityLevel: 'MEDIUM',
    labelIds: [],
    stageDefinitionReferences: [],
    definitionDescription: '',
  } satisfies FlowTemplateFormFields
}

function useFormStageDefinition(
  modalParams: UseInitialStageFormData
): StageDefinitionSchema {
  const urlParams = useStageModalUrlParams()

  const stageId = modalParams.stageId ?? urlParams.stageId

  const stageDefinition = useStageDefinition(stageId ?? '')

  return stageDefinition
}
