import { useWorkspaceAvailableCustomFields } from '~/areas/custom-fields/hooks'
import { useCallback } from 'react'

import { useViewState, type WorkspaceViewState } from '../../view-state'

export type KanbanCardField = {
  id: string
  visible?: boolean
}

export function useKanbanCardFieldViewState() {
  const [viewState, setViewState] = useViewState()
  const cardFields: KanbanCardField[] = useCardFields(viewState)

  const setCardFields = useCallback(
    (
      newCardFieldsArg:
        | KanbanCardField[]
        | ((prev: KanbanCardField[]) => KanbanCardField[])
    ) => {
      const newCardFields =
        typeof newCardFieldsArg === 'function'
          ? newCardFieldsArg(cardFields)
          : newCardFieldsArg

      setViewState((prev) => {
        return {
          ...prev,
          cardFields: newCardFields,
        }
      })
    },
    [cardFields, setViewState]
  )

  return [cardFields, setCardFields] as const
}

function useCardFields(viewState: WorkspaceViewState): KanbanCardField[] {
  const availableCustomFields = useWorkspaceAvailableCustomFields()

  return viewState.cardFields.length === 0
    ? availableCustomFields.map((field) => ({
        id: field.id,
        visible: false,
      }))
    : viewState.cardFields
}
