import { classed } from '@motion/theme'
import {
  type ContextMenuPopoverProps,
  Tooltip,
  useContextMenu,
} from '@motion/ui/base'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'

import type { UniqueIdentifier } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { type PropsWithChildren, type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { DragHandle } from './drag-handle'

import { HIGHLIGHTABLE_DATA_ATTRIBUTE } from '../../../constants'
import { useSortableTreeviewItem } from '../hooks'

export type SortableTreeviewItemProps<
  Data extends Record<string, unknown> = Record<string, unknown>,
> = {
  className?: string
  disableDrag?: boolean
  id: UniqueIdentifier
  indentWidth?: number
  renderContextMenuContent?: ContextMenuPopoverProps['renderContent']
  renderOnDrag?: (children: ReactNode) => ReactNode
  sortableData: Data
  tooltip?: string
}

export const SortableTreeviewItem = <Data extends Record<string, unknown>>({
  className,
  children,
  disableDrag = false,
  id,
  indentWidth = 0,
  renderContextMenuContent,
  renderOnDrag,
  sortableData = READONLY_EMPTY_OBJECT as Data,
  tooltip,
}: PropsWithChildren<SortableTreeviewItemProps<Data>>) => {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners: handleListeners,
    setActivatorNodeRef,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortableTreeviewItem(id, sortableData)

  const { handleContextMenu, ContextMenuPopover } = useContextMenu()

  return (
    <>
      <Tooltip content={tooltip} placement='right'>
        <div
          ref={setDroppableNodeRef}
          style={{ transform: CSS.Translate.toString(transform), transition }}
          className={twMerge(
            'relative group',
            isDragging && 'z-10',
            isSorting && 'pointer-events-none'
          )}
          {...attributes}
          {...{ [HIGHLIGHTABLE_DATA_ATTRIBUTE]: id.toString() }}
        >
          <div
            ref={setDraggableNodeRef}
            onContextMenu={
              renderContextMenuContent ? handleContextMenu : undefined
            }
          >
            {isDragging && renderOnDrag ? (
              renderOnDrag(children)
            ) : (
              <Shell className={className} is-sorting={isSorting}>
                <span
                  className='opacity-0 group-hover:opacity-100 transition-all'
                  style={{ marginLeft: indentWidth }}
                >
                  <DragHandle
                    listeners={handleListeners}
                    setActivatorNodeRef={setActivatorNodeRef}
                    disabled={disableDrag || isSorting}
                  />
                </span>

                {children}
              </Shell>
            )}
          </div>
        </div>
      </Tooltip>

      {renderContextMenuContent && (
        <ContextMenuPopover renderContent={renderContextMenuContent} />
      )}
    </>
  )
}

const Shell = classed('div', {
  base: 'flex group',
  variants: {
    'is-sorting': {
      true: '',
      false: '',
    },
  },
  defaultVariants: {
    'is-sorting': false,
  },
  dataAttributes: ['is-sorting'],
})

export const DebugModeLabel = classed('div', {
  base: `
    p-0.5
    border border-dashed border-semantic-warning-border-default 
    text-semantic-warning-text-default text-[8px] leading-[8px] font-mono
  `,
  variants: {
    show: {
      true: 'block',
      false: 'hidden group-data-[is-sorting=true]:block',
    },
  },
  defaultVariants: {
    show: false,
  },
})
