import { CalendarSolid, CheckCircleSolid, ClockSolid } from '@motion/icons'
import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { formatDurationTime } from '@motion/ui-logic'

import { SimpleDateDropdown } from '~/global/components/dropdowns'
import { DateTime } from 'luxon'
import { forwardRef, useImperativeHandle, useRef } from 'react'

import { type TaskCreateObject } from './types'

import { Input } from '../../../components/Common'

const durationOptions = [5, 15, 30, 60, 60 * 2, 60 * 4]

export type CreateBulkTasksRowRef = {
  focusInput(): void
}

export type CreateBulkTasksRowProps = {
  task: TaskCreateObject
  updateTask: (task: TaskCreateObject) => void
  isStagedTask?: boolean
}

/**
 * Renders a row for creating bulk tasks.
 *
 * @param {CreateBulkTasksRowProps} props - The props for the component.
 * @param {TaskCreateObject} props.task - The task object.
 * @param {function} props.updateTask - The function to update a task.
 * @param {boolean} props.isStagedTask - Whether the task is a staged task.
 */
export const CreateTasksBulkRow = forwardRef<
  CreateBulkTasksRowRef,
  CreateBulkTasksRowProps
>(({ task, updateTask, isStagedTask }: CreateBulkTasksRowProps, ref) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useImperativeHandle(ref, () => ({
    focusInput: () => {
      inputRef.current?.focus()
    },
  }))

  return (
    <div className='flex flex-row items-center bg-white border-b border-x h-[32px] justify-between pr-3 group-last:rounded-b group-first:rounded-t group-first:border-t'>
      <div className='flex flex-row items-center px-[6px] grow'>
        {!isStagedTask && (
          <CheckCircleSolid className='h-4 w-4 mx ml-[6px] text-semantic-neutral-icon-subtle' />
        )}
        <Input
          ref={inputRef}
          maxLength={500}
          placeholder='Task name'
          value={task.title}
          onChange={(e) => {
            updateTask({
              ...task,
              title: e.target.value.trimStart(),
            })
          }}
          className='grow'
          containerClassName='border-0 h-[30px]'
        />
      </div>
      <div className='flex flex-row items-center gap-2'>
        <SimpleDateDropdown
          value={task.deadline}
          onChange={(date) => {
            date &&
              updateTask({
                ...task,
                deadline: DateTime.fromISO(date).endOf('day').toISO(),
              })
            if (inputRef.current) {
              inputRef.current.focus()
            }
          }}
        >
          <div className='flex flex-row items-center text-nowrap hover:bg-semantic-neutral-bg-hover hover:cursor-pointer px-0.5 py-1 rounded text-field-text-default w-[105px]'>
            <CalendarSolid className='h-4 w-4 mr-2 text-semantic-neutral-icon-subtle' />
            <span>Due {DateTime.fromISO(task.deadline).toFormat('LLL d')}</span>
          </div>
        </SimpleDateDropdown>
        <PopoverTrigger
          placement='bottom-start'
          renderPopover={({ close }) => (
            <>
              <SearchableList
                items={durationOptions.map((item) => ({
                  label: formatDurationTime(item),
                  value: item,
                }))}
                searchable={false}
                computeKey={(item) => String(item.value)}
                computeSearchValue={(item) => item.label}
                computeSelected={(item) => item.value === task.duration}
                onSelect={(item) => {
                  updateTask({
                    ...task,
                    duration: item.value,
                  })
                  close()
                  if (inputRef.current) {
                    inputRef.current.focus()
                  }
                }}
                renderItem={(item) => item.label}
                inputProps={{
                  placeholder: 'Choose or type a duration',
                }}
              />
            </>
          )}
        >
          <div className='flex flex-row items-center hover:bg-semantic-neutral-bg-hover hover:cursor-pointer px-0.5 py-1 rounded text-field-text-default w-[82px]'>
            <ClockSolid className='h-4 w-4 mr-2 text-semantic-neutral-icon-subtle' />
            {formatDurationTime(task.duration)}
          </div>
        </PopoverTrigger>
      </div>
    </div>
  )
})
CreateTasksBulkRow.displayName = 'CreateTasksBulkRow'
