import { templateStr } from '@motion/react-core/strings'
import {
  RichTextContent,
  unescapeTiptapOutput,
  useSingleLineRichTextEditor,
} from '@motion/ui/rte'
import { stripHtml } from '@motion/ui-logic'
import { capitalize } from '@motion/utils/string'

import { CharacterCount } from '@tiptap/extension-character-count'
import { getTextFieldRules } from '~/global/rules'
import { startTransition } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  FlowVariable,
  type FlowVariableProps,
  useSetTiptapStorage,
} from '../../tiptap-extensions'

type TaskNameFieldProps = {
  value: string
  onChange: (value: string) => void
  isError?: boolean
  variableArgs: FlowVariableProps
  stageCardType: 'event' | 'task'
  size?: 'xsmall' | 'large'
}

export const TaskNameRichInput = ({
  value,
  onChange,
  isError,
  variableArgs,
  stageCardType,
  size = 'xsmall',
}: TaskNameFieldProps) => {
  const isEvent = stageCardType === 'event'

  const taskNameHtml = templateStr(value, renderFlowVariableTextHtml)

  const editor = useSingleLineRichTextEditor({
    className: twMerge(
      size === 'xsmall' && 'text-xs font-medium leading-5',
      size === 'large' && 'text-lg font-semibold leading-8'
    ),
    placeholder: templateStr('{{type}} name ({{description}})', {
      type: capitalize(stageCardType),
      description: isEvent
        ? 'type "/" for variables'
        : 'type "/" to add a text variable',
    }),
    value: `<p>${taskNameHtml}</p>`,
    extensions: [
      FlowVariable(variableArgs),
      CharacterCount.configure({
        limit: getTextFieldRules('Task name').maxLength.value,
      }),
    ],
    onChange(value) {
      startTransition(() => {
        onChange(unescapeTiptapOutput(stripHtml(value)))
      })
    },
  })

  useSetTiptapStorage(editor, variableArgs)

  return (
    <div className='relative'>
      {isEvent && (
        <span className='absolute text-[10px] leading-[23px] text-semantic-neutral-text-subtle'>
          Schedule
        </span>
      )}
      <RichTextContent
        editor={editor}
        className={twMerge(
          'outline-0 bg-transparent w-full overflow-auto rounded border border-transparent',
          isError && 'border-semantic-error-border-active',
          isEvent && 'indent-[3.25rem]'
        )}
      />
    </div>
  )
}

function renderFlowVariableTextHtml(key: string) {
  return `<span data-flow-variable-id="${key}">{{${key}}}</span>`
}
