import { PuzzleSolid } from '@motion/icons'
import { type TemplateProjectType } from '@motion/rpc/types'

import { useSelectProjectTemplate } from '~/areas/project/hooks'

import { LegacyTemplateCard } from './styled'

export function LegacyTemplateCardButton({
  template,
}: {
  template: TemplateProjectType
}) {
  const selectTemplate = useSelectProjectTemplate({
    skipConfirm: true,
  })

  return (
    <button
      onClick={(e) => {
        e.preventDefault()
        selectTemplate(template)
      }}
    >
      <LegacyTemplateCard className='w-full'>
        <PuzzleSolid className='h-4 w-4 text-semantic-neutral-icon-default' />
        <h3 className='font-semibold text-sm truncate text-semantic-neutral-text-default'>
          {template.name}
        </h3>
      </LegacyTemplateCard>
    </button>
  )
}
