import { AddToProjectSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { Button } from '@motion/ui/base'
import { canAddToProject } from '@motion/ui-logic'

import {
  WorkspaceProjectDropdown,
  type WorkspaceProjectDropdownProps,
} from '~/global/components/dropdowns'
import { ProjectLabel } from '~/global/components/labels'
import { useProject } from '~/global/hooks'
import { showErrorToast } from '~/global/toasts'
import { useController } from 'react-hook-form'

import { useEventModalState } from '../contexts'
import { useEventForm, useFormAddEventToProject } from '../hooks'

export function ConnectedAddToProjectButton() {
  const addEventToProject = useFormAddEventToProject()
  const { initialEvent, initialSchedulingTask } = useEventModalState()
  const { form } = useEventForm()

  const { field: workspaceIdField } = useController({
    name: 'workspaceId',
    control: form.control,
  })
  const { field: projectIdField } = useController({
    name: 'projectId',
    control: form.control,
  })
  const project = useProject(projectIdField.value)

  const recurrence = form.watch('recurrence')
  const allDay = form.watch('isAllDay')

  if (
    initialSchedulingTask != null ||
    (initialEvent != null && !canAddToProject(initialEvent)) ||
    recurrence ||
    allDay
  ) {
    return null
  }

  const handleSelect: WorkspaceProjectDropdownProps['onSelect'] = (
    workspaceId,
    projectId
  ) => {
    if (initialEvent != null) {
      return addEventToProject(workspaceId, projectId)
    }

    if (projectId == null) {
      showErrorToast('The event must be within a project')
      return
    }

    workspaceIdField.onChange(workspaceId)
    projectIdField.onChange(projectId)
  }

  return (
    <WorkspaceProjectDropdown
      onSelect={handleSelect}
      selectedWorkspaceId={workspaceIdField.value}
      selectedProjectId={projectIdField.value}
      hideNoProject
      disallowSelectWorkspace
    >
      <Button sentiment='neutral' variant='outlined' size='small'>
        <AddToProjectSolid />
        {templateStr('Add to {{name}}', {
          name:
            initialEvent != null || project == null ? (
              'project'
            ) : (
              <ProjectLabel value={project} size='small' />
            ),
        })}
      </Button>
    </WorkspaceProjectDropdown>
  )
}
