import { type ProjectSchema } from '@motion/rpc-types'
import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useAddProjectStage } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export function useAddStageToProject() {
  const { mutateAsync: addProjectStage } = useAddProjectStage()
  const modalApi = useModalApi()

  return useCallback(
    async (project: ProjectSchema, indexToInsert: number) => {
      // only confirm detaching from a template if it's a flow project
      // non-flow projects can be directly converted
      if (project.projectDefinitionId) {
        const result = await modalApi.prompt(
          'confirm-detach-project-from-flow',
          {
            projectDefinitionId: project.projectDefinitionId,
            editType: 'add-stage',
          }
        )

        if (result === ModalDismissed) {
          recordAnalyticsEvent('FLOW_UNSYNC_PROJECT_TEMPLATE_CANCEL', {
            type: 'add-stage',
          })
          return
        }

        recordAnalyticsEvent('FLOW_UNSYNC_PROJECT_TEMPLATE_CONTINUE', {
          type: 'add-stage',
        })
      }

      const shouldHideDurationField =
        indexToInsert === 0 && !project.projectDefinitionId

      return modalApi.open('create-stage-from-project', {
        projectId: project.id,
        hideDurationField: shouldHideDurationField,
        onSubmit: async ({
          name,
          color,
          expectedDurationValue,
          expectedDurationUnit,
        }) => {
          recordAnalyticsEvent('FLOW_ADD_STAGE_TO_PROJECT')

          try {
            await addProjectStage({
              projectId: project.id,
              color,
              name,
              index: indexToInsert,
              duration: {
                value: expectedDurationValue,
                unit: expectedDurationUnit,
              },
            })

            showToast('success', 'Stage created')
          } catch (error) {
            Sentry.captureException(error, {
              tags: {
                position: 'use-add-stage-to-project',
              },
            })
            showErrorToast('Failed to create stage')

            throw error
          }
        },
      })
    },
    [modalApi, addProjectStage]
  )
}
