import { UnstyledModal } from '@motion/ui/base'

import { useFileUploadState } from '~/areas/attachments/contexts'

import { useCloseTaskModal, useRedirectToTask } from './hooks'
import { ConnectedTaskModalBody } from './task-modal-body'

type ConnectedTaskModalProps = {
  open: boolean
}

export function ConnectedTaskModal({ open }: ConnectedTaskModalProps) {
  const closeModal = useCloseTaskModal()
  const { cancelActiveFileUploads } = useFileUploadState()
  const redirectToTask = useRedirectToTask()

  const handleCloseModal = () => {
    cancelActiveFileUploads({ targetId: null, targetType: 'TEAM_TASK' })
    closeModal()
  }

  return (
    <UnstyledModal
      data-testid='task-modal'
      type='page'
      visible={open}
      onClose={handleCloseModal}
      withAnimation
      overlayClassName='bg-modal-overlay'
    >
      <ConnectedTaskModalBody
        close={handleCloseModal}
        onTaskCreated={(task) => {
          redirectToTask(task.id)
        }}
      />
    </UnstyledModal>
  )
}
