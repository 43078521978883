import { createQueryFilter, MODEL_CACHE_KEY } from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'

import { type QueryKey } from '@tanstack/react-query'

const KEYS_TO_UPDATE: QueryKey[] = [
  MODEL_CACHE_KEY,
  API.tasksV2.queryKeys.query,
  ['v2/scheduled-entities'],
]

export function getTaskQueryFilters(taskId?: string) {
  return createQueryFilter<any>(
    [
      ...KEYS_TO_UPDATE,
      taskId ? API.tasksV2.queryKeys.taskById(taskId) : undefined,
    ].filter(Boolean)
  )
}
