import { type MeetingInsightsSchema } from '@motion/rpc-types'
import { values } from '@motion/utils/object'

import { type FieldDefinition } from '~/areas/project-management/pages/pm-v3/fields'
import { type TreeListColumn } from '~/areas/project-management/pages/pm-v3/tree-list/columns'
import { ColumnHeaderCell } from '~/areas/project-management/pages/pm-v3/tree-list/columns/components'
import {
  maxSizeOf,
  minSizeOf,
} from '~/areas/project-management/pages/pm-v3/tree-list/columns/utils'
import { useMemo } from 'react'

import { CELLS } from '../lookup'
import { MEETING_INSIGHTS_FIELDS, type MeetingInsightsFieldId } from '../types'

export function useMeetingInsightsColumns() {
  return useMemo(() => {
    return values(MEETING_INSIGHTS_FIELDS).map((f) => convertFieldToColumn(f))
  }, [])
}

function convertFieldToColumn(
  f: FieldDefinition<MeetingInsightsSchema>
): TreeListColumn {
  return {
    id: f.id,
    header: (info) => (
      <ColumnHeaderCell>
        {info.column.columnDef.meta?.name ?? 'unknown'}
      </ColumnHeaderCell>
    ),
    minSize: minSizeOf(f),
    maxSize: maxSizeOf(f),
    accessorFn: (row) => row,
    cell: CELLS[f.id as MeetingInsightsFieldId],
    enableResizing: !f.disableResizing,
    enableSorting: f.sortFn != null,
    enableHiding: !f.disableHiding,
    enablePinning: f.enablePinning ?? false,
    meta: {
      name: f.name,
      disableReordering: f.disableReordering ?? false,
    },
  }
}
