import { classed } from '@motion/theme'
import { getWindowData } from '@motion/web-base/env'

import { DesktopHeaderBar } from '~/areas/desktop/components'
import { Banners } from '~/components/Navbar/banners'
import { type ReactNode } from 'react'
import { Outlet } from 'react-router'
import { twMerge } from 'tailwind-merge'

import { useDesktopTabs } from '../desktop/hooks'

type SidebarShellProps = {
  sidebar: ReactNode
}

export const SidebarShell = ({ sidebar }: SidebarShellProps) => {
  const { isElectron } = getWindowData()
  const { hasTabs } = useDesktopTabs()

  return (
    <PageGrid forDesktop={isElectron}>
      {isElectron && (
        <div className='col-span-2'>
          <DesktopHeaderBar />
        </div>
      )}
      <div className='relative z-10 flex min-h-0 isolate'>{sidebar}</div>
      <div
        className={twMerge(
          'relative z-0 flex flex-col min-h-0 min-w-0 isolate',
          hasTabs &&
            'border-t border-l border-semantic-neutral-border-default overflow-hidden max-w-full max-h-full rounded-md'
        )}
      >
        <Banners />
        <Outlet />
      </div>
    </PageGrid>
  )
}

const PageGrid = classed('div', {
  base: `relative z-0 grid h-full w-full grid-cols-[auto_1fr]`,
  variants: {
    forDesktop: {
      true: 'bg-semantic-neutral-surface-bg-subtle grid-rows-[auto_1fr]',
      false: 'grid-rows-[minmax(0,1fr)]',
    },
  },
  defaultVariants: {
    forDesktop: false,
  },
})
