import { PlusOutline, PlusSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, IconButton } from '@motion/ui/base'

export type StepLineSeparatorProps = {
  isInBetween?: boolean
  onClick: () => void
}

export function StepLineSeparator({
  isInBetween = false,
  onClick,
}: StepLineSeparatorProps) {
  return (
    <div className='flex flex-col items-center'>
      {isInBetween ? (
        <>
          <VerticalLine />
          <IconButton
            icon={PlusOutline}
            sentiment='neutral'
            variant='muted'
            size='small'
            onClick={onClick}
          />
          <VerticalLine pointing />
        </>
      ) : (
        <>
          <VerticalLine size='large' pointing />
          <Button
            sentiment='neutral'
            variant='outlined'
            size='small'
            onClick={onClick}
          >
            <PlusSolid />
            Add step
          </Button>
        </>
      )}
    </div>
  )
}

const VerticalLine = classed('div', {
  base: `
    relative
    m-auto
    h-[10px]
    w-0
    border border-semantic-neutral-border-strong
  `,
  variants: {
    size: {
      default: 'h-[10px]',
      large: 'h-6',
    },
    pointing: {
      true: `
        after:absolute
        after:bottom-0
        after:-left-[4px]
        after:-rotate-[45deg]
        after:w-[8px]
        after:h-[8px]
        after:border-l
        after:border-b
        after:border-semantic-neutral-border-strong
      `,
      false: ``,
    },
  },
  defaultVariants: {
    pointing: false,
    size: 'default',
  },
})
