import { LoadingSolid } from '@motion/icons'
import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { type NormalTaskSchema } from '@motion/zod/client'

import { useFetchProjectTasks } from '~/areas/task-project/hooks'
import { useBlockerTasks, useTaskBlockersUpdater } from '~/areas/tasks/hooks'
import { getAvailableBlockerOptions } from '~/areas/tasks/utils'
import { type ReactNode } from 'react'

export type BlockersTypeDropdownProps = {
  task: NormalTaskSchema
  type: 'blockedBy' | 'blocks'
  children: ReactNode
}

export function BlockersTypeDropdown(props: BlockersTypeDropdownProps) {
  const { task, type, children } = props

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <NormalTaskBlockersContent close={close} task={task} type={type} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

export type NormalTaskBlockersContentProps = Pick<
  BlockersTypeDropdownProps,
  'type'
> & {
  task: NormalTaskSchema
  close: () => void
}

export const NormalTaskBlockersContent = ({
  close,
  task,
  type,
}: NormalTaskBlockersContentProps) => {
  const { data: projectTasks } = useFetchProjectTasks({
    projectId: task.projectId,
    workspaceId: task.workspaceId,
  })
  const { isLoading, blocking, blockedBy } = useBlockerTasks({
    taskId: task.id,
  })

  const currentBlockersToCheckAgainst = type === 'blocks' ? blocking : blockedBy

  const availableBlockerOptions = getAvailableBlockerOptions(
    projectTasks,
    currentBlockersToCheckAgainst,
    task
  )

  const { addTaskBlocker } = useTaskBlockersUpdater({ type })

  if (isLoading) {
    return (
      <div className='grow gap-3 p-3 w-[180px] grid place-items-center'>
        <LoadingSolid className='animate-spin text-semantic-neutral-icon-default' />
      </div>
    )
  }

  return (
    <SearchableList
      searchable
      items={availableBlockerOptions}
      computeKey={(item) => item.id}
      computeSearchValue={(item) => item.name}
      computeSelected={() => false}
      onSelect={(item) => {
        close()
        addTaskBlocker(task, item)
      }}
      renderItem={(task) => (
        <div className='max-w-xs truncate'>{task.name}</div>
      )}
      inputProps={{ placeholder: 'Search tasks...' }}
    />
  )
}
