import { type NoteResponseSchema } from '@motion/rpc-types'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useNoteUrl } from '~/global/navigation'
import { useNavigate } from 'react-router'

import { NoteResult } from './note-result'

export interface ConnectedNoteResultProps {
  item: NoteResponseSchema
  itemIds: NoteResponseSchema['id'][]
  active: boolean
  onItemClick: () => void
}

export function ConnectedNoteResult({
  item,
  itemIds,
  active,
  onItemClick,
}: ConnectedNoteResultProps) {
  const navigate = useNavigate()
  const buildNoteUrl = useNoteUrl()

  const handleClick = () => {
    onItemClick()
    navigate(buildNoteUrl({ noteId: item.id }).noteUri)
    recordAnalyticsEvent('SEARCH_RESULT_OPENED', {
      type: 'note',
    })
  }

  // TODO
  // useShortcut('mod+l', handleCopyLink, { enabled: active })

  return <NoteResult item={item} onClick={handleClick} onCopyLink={() => {}} />
}
