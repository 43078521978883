import { CheckSolid, ReplySolid, XSolid } from '@motion/icons'
import { ButtonGroup, IconButton, Tooltip } from '@motion/ui/base'

import { useRejectActionItem, useUndoRejectedActionItem } from '~/global/rpc/v2'

import { useActionItemForm, useSubmitActionItemForm } from '../hooks'
import { useActionItemContext } from '../provider'

export function ActionItemActionButtons() {
  const { isRejected, task } = useActionItemContext()

  if (task != null) {
    return null
  }

  if (isRejected) {
    return <UndoButton />
  }

  return <ApproveRejectButtons />
}

function ApproveRejectButtons() {
  const { initialActionItem } = useActionItemContext()
  const { form, canApprove } = useActionItemForm()

  const { mutateAsync: rejectAsync, isPending: rejectIsLoading } =
    useRejectActionItem()

  const actionItemId = initialActionItem.id

  const isSubmitting = form.formState.isSubmitting
  const disabledApprove = !canApprove
  const disabledReject = rejectIsLoading

  const submitForm = useSubmitActionItemForm(form, actionItemId)

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    await submitForm()(e)
  }

  const handleReject = async () =>
    await rejectAsync({
      actionItemId,
    })

  return (
    <ButtonGroup size='small'>
      <Tooltip
        content={
          !canApprove ? 'Please select values for all fields' : 'Approve'
        }
        asChild
      >
        <IconButton
          disabled={disabledApprove}
          sentiment={disabledApprove ? 'neutral' : 'success'}
          variant='outlined'
          size='small'
          icon={CheckSolid}
          loading={isSubmitting}
          onClick={handleSubmit}
          type='submit'
        />
      </Tooltip>

      <Tooltip content='Reject' asChild>
        <IconButton
          disabled={disabledReject}
          onClick={handleReject}
          sentiment={disabledReject ? 'neutral' : 'error'}
          variant='outlined'
          size='small'
          icon={XSolid}
          loading={rejectIsLoading}
        />
      </Tooltip>
    </ButtonGroup>
  )
}

function UndoButton() {
  const { initialActionItem: actionItem } = useActionItemContext()
  const { mutateAsync: undoRejectActionItem, isPending: undoRejectIsLoading } =
    useUndoRejectedActionItem()

  return (
    <Tooltip content='Undo reject' asChild>
      <IconButton
        disabled={undoRejectIsLoading}
        onClick={() => {
          void undoRejectActionItem({
            actionItemId: actionItem.id,
          })
        }}
        sentiment='neutral'
        variant='outlined'
        size='xsmall'
        icon={ReplySolid}
        loading={undoRejectIsLoading}
      />
    </Tooltip>
  )
}
