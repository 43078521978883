import {
  type COLOR,
  findDefaultStatus,
  getRandomColor,
} from '@motion/shared/common'
import {
  convertDaysToDeadlineInterval,
  getDefaultRelativeInterval,
} from '@motion/shared/flows'
import { createPlaceholderId } from '@motion/shared/identifiers'
import { type CustomFieldFieldArrayValue } from '@motion/ui-logic'
import {
  type FlowTemplateFormTask,
  type FlowTemplateStage,
} from '@motion/ui-logic/pm/project'
import { uniqueId } from '@motion/utils/core'
import { type StatusSchema } from '@motion/zod/client'

const DEFAULT_START_RELATIVE_INTERVAL =
  getDefaultRelativeInterval('STAGE_START')
const DEFAULT_DUE_RELATIVE_INTERVAL = getDefaultRelativeInterval('STAGE_DUE')

export function getNewStageData(opts: {
  workspaceId: string
  currentUserId: string
  workspaceStatuses: StatusSchema[]
  name?: string
  color?: COLOR
  customFieldValuesFieldArray?: CustomFieldFieldArrayValue[]
}): FlowTemplateStage {
  const {
    workspaceId,
    currentUserId,
    workspaceStatuses,
    name = 'New stage',
    color = getRandomColor(),
    customFieldValuesFieldArray,
  } = opts
  return {
    id: createPlaceholderId(uniqueId('stage')),
    name,
    color,
    duration: convertDaysToDeadlineInterval(7),
    tasks: [
      getInitialStageTaskData({
        currentUserId,
        workspaceStatuses,
        defaultStatusId: findDefaultStatus(workspaceStatuses)?.id,
        customFieldValuesFieldArray,
      }),
    ],
    variables: [],
    workspaceId,
  }
}

export function getInitialStageTaskData(opts: {
  currentUserId: string
  workspaceStatuses: StatusSchema[]
  defaultStatusId?: string
  customFieldValuesFieldArray?: CustomFieldFieldArrayValue[]
  isAutoScheduled?: boolean
}) {
  const {
    currentUserId,
    workspaceStatuses,
    defaultStatusId,
    customFieldValuesFieldArray = [],
    isAutoScheduled = true,
  } = opts

  return {
    id: createPlaceholderId(uniqueId('task')),
    name: '',
    description: '',
    priorityLevel: 'MEDIUM' as const,
    labelIds: [],
    statusId: defaultStatusId ?? workspaceStatuses[0].id,
    assigneeUserId: currentUserId,
    assigneeVariableKey: null,
    duration: 30,
    isAutoScheduled,
    blockedByTaskId: null,
    blockedByTaskIds: [],
    minimumDuration: null,
    customFieldValuesFieldArray,
    scheduleMeetingWithinDays: null,
    uploadedFileIds: [],
    deadlineType: 'SOFT',
    startRelativeInterval: {
      ...DEFAULT_START_RELATIVE_INTERVAL,
      duration: {
        ...DEFAULT_START_RELATIVE_INTERVAL.duration,
        sign: 1,
      },
    },
    dueRelativeInterval: {
      ...DEFAULT_DUE_RELATIVE_INTERVAL,
      duration: {
        ...DEFAULT_DUE_RELATIVE_INTERVAL.duration,
        sign: -1,
      },
    },
  } satisfies FlowTemplateFormTask
}
