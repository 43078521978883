import * as v from 'valibot'

import { type SortField } from '../fields'

export const SortBySchema = v.object({
  field: v.custom<SortField>((val) => typeof val === 'string'),
  direction: v.picklist(['asc', 'desc']),
})

export const ColumnSchema = v.object({
  id: v.string(),
  width: v.optional(v.number()),
  visible: v.optional(v.boolean(), true),
  pinned: v.optional(v.boolean(), false),
})

export const CardFieldSchema = v.object({
  id: v.string(),
  visible: v.optional(v.boolean()),
})

export const GroupByField = v.object({
  key: v.string(),
  by: v.optional(
    v.picklist(['day', 'week', 'month', 'quarter', 'half', 'year'])
  ),
})

export const GroupByOptionsSchema = v.object({
  fields: v.array(GroupByField),
  order: v.record(v.string(), v.array(v.string())),
  hideEmpty: v.boolean(),
  stackProjects: v.optional(v.boolean(), true),
  dateRange: v.optional(v.picklist(['month', 'quarter', 'year']), 'quarter'),
})
