import {
  type CreateProjectDefinitionSchema,
  type ProjectDefinitionSchema,
} from '@motion/rpc-types'
import { findDefaultStatus } from '@motion/shared/common'
import {
  DEFAULT_INTERVAL,
  getDefaultRelativeInterval,
} from '@motion/shared/flows'
import { createPlaceholderId } from '@motion/shared/identifiers'
import { uniqueId } from '@motion/utils/core'

import { useWorkspaceStatuses } from '~/global/hooks'

export function useProjectDefinitionFromCreateSchema(
  projectDefinition: CreateProjectDefinitionSchema | undefined | null
): ProjectDefinitionSchema | null {
  const workspaceStatuses = useWorkspaceStatuses(
    projectDefinition?.workspaceId ?? ''
  )
  const defaultStatus =
    findDefaultStatus(workspaceStatuses) ?? workspaceStatuses[0]

  if (projectDefinition == null) {
    return null
  }

  return {
    ...projectDefinition,
    id: createPlaceholderId(uniqueId('project')),
    // TODO(flows-m5): Convert stage definitions to stage definition references
    stageDefinitionReferences: [],
    stages: projectDefinition.stages.map((stage) => ({
      ...stage,
      duration: stage.duration ?? DEFAULT_INTERVAL,
      id: createPlaceholderId(uniqueId('stage')),
      workspaceId: projectDefinition.workspaceId,
      // TODO(flows-m5): FE needs to support variables on stages eventually.
      // For now directly setting stage variables is ignored by the BE.
      variables: [],

      tasks: stage.tasks.map((task) => ({
        ...task,
        id: createPlaceholderId(uniqueId('task')),
        customFieldValuesFieldArray: [],
        statusId: defaultStatus.id,
        scheduleMeetingWithinDays: task.scheduleMeetingWithinDays ?? null,
        blockedByTaskIds: task.blockedByTaskIds ?? [],
        startRelativeInterval:
          task.startRelativeInterval ??
          getDefaultRelativeInterval('STAGE_START'),
        dueRelativeInterval:
          task.dueRelativeInterval ?? getDefaultRelativeInterval('STAGE_DUE'),
      })),
    })),
    variables: projectDefinition.variables.map((variable) => ({
      ...variable,
      id: createPlaceholderId(uniqueId('variable')),
    })),
    color: projectDefinition.color ?? 'gray',
    createdByUserId: createPlaceholderId(uniqueId('user')),
  } satisfies ProjectDefinitionSchema
}
