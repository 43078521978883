import { isNoneId } from '@motion/shared/identifiers'
import type { DateAdjustmentStrategy } from '@motion/shared/projects'
import { getProjectDueDateDateChangedFields } from '@motion/ui-logic/pm/project'
import { parseDate } from '@motion/utils/dates'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type ProjectSchema } from '@motion/zod/client'

import { useIsFlowProject } from '~/areas/flows'
import { useCallback } from 'react'

import { useProjectUpdater } from './use-project-updater'

import { useAdjustProjectDates } from '../actions/use-adjust-project-dates'

type UseProjectDeadlineUpdaterOptions = {
  dateAdjustmentStrategy?: DateAdjustmentStrategy
}

export function useProjectDeadlineUpdater(
  options?: UseProjectDeadlineUpdaterOptions
) {
  const { dateAdjustmentStrategy = null } = options ?? {}
  const updateProject = useProjectUpdater()
  const adjustProjectDates = useAdjustProjectDates()
  const modalApi = useModalApi()
  const isFlowProject = useIsFlowProject()

  return useCallback(
    async (project: ProjectSchema, newDeadline: ProjectSchema['dueDate']) => {
      const currentProjectDueDate = project.dueDate
      if (currentProjectDueDate === newDeadline) return true
      if (
        currentProjectDueDate &&
        newDeadline &&
        parseDate(currentProjectDueDate).equals(parseDate(newDeadline))
      ) {
        return true
      }

      const updates = {
        dueDate: newDeadline,
        ...getProjectDueDateDateChangedFields({
          ...project,
          dueDate: newDeadline,
        }),
      }

      if (newDeadline == null || isNoneId(project.id)) {
        await updateProject(project, updates)
        return true
      }

      let dateAdjustmentStrategyToUse = dateAdjustmentStrategy
      if (isFlowProject(project)) {
        const parsedNewDeadline = parseDate(newDeadline).toISODate()

        // skip the confirmation if the project has only one stage as the choice doesnt matter
        if (project.stages.length === 1 && dateAdjustmentStrategy == null) {
          dateAdjustmentStrategyToUse = 'ABSORB'
        }

        if (dateAdjustmentStrategyToUse == null) {
          const response = await modalApi.prompt('update-project-date', {
            projectId: project.id,
            newDate: newDeadline,
            dateType: 'due',
          })

          if (
            response === ModalDismissed ||
            response.dateAdjustmentStrategy === 'SHIFT'
          ) {
            return false
          }

          dateAdjustmentStrategyToUse = response.dateAdjustmentStrategy

          recordAnalyticsEvent('CHANGE_PROJECT_DATES', {
            type: 'deadline',
            behavior:
              dateAdjustmentStrategyToUse === 'ABSORB'
                ? 'resize_stage'
                : 'resize_proportional',
          })
        }

        if (dateAdjustmentStrategyToUse === 'SHIFT') {
          return false
        }

        await updateProject(project, {
          dueDate: parsedNewDeadline,
          ...getProjectDueDateDateChangedFields({
            ...project,
            dueDate: parsedNewDeadline,
          }),
          options: {
            dateAdjustmentStrategy: dateAdjustmentStrategyToUse,
          },
        })
        return true
      }

      const formattedDeadline = isFlowProject(project)
        ? parseDate(newDeadline).toISODate()
        : parseDate(newDeadline).toISO()

      return adjustProjectDates(project.id, {
        ...(updates.startDate ? { startDate: updates.startDate } : {}),
        dueDate: formattedDeadline,
      })
    },
    [
      adjustProjectDates,
      dateAdjustmentStrategy,
      isFlowProject,
      modalApi,
      updateProject,
    ]
  )
}
