import { type MeetingInsightsSchema } from '@motion/rpc-types'
import { byValue, Compare, type CompareFn } from '@motion/utils/array'

import {
  DEFAULT_STRING_SORT,
  type FieldDefinition,
} from '~/areas/project-management/pages/pm-v3/fields'
import { type GroupableField } from '~/areas/project-management/pages/pm-v3/grouping'
import { DateTime } from 'luxon'

// Fields for grouping
export const MeetingInsightsGroupableFields = [
  {
    id: 'startDate',
    label: 'Date',
  },
  {
    id: 'host',
    label: 'Host',
  },
] as const satisfies GroupableField[]

// Fields for the table columns
export const MEETING_INSIGHTS_FIELDS = {
  title: {
    id: 'title',
    name: 'Name',
    size: { min: 500, default: 500 },
    sortFn: byValue<Pick<MeetingInsightsSchema, 'eventData'>, string | null>(
      (meetingInsight) => meetingInsight.eventData?.title ?? null,
      DEFAULT_STRING_SORT
    ),
  },
  actionItems: {
    id: 'actionItems',
    name: 'Action Items',
    size: 150,
  },
  startTime: {
    id: 'startTime',
    name: 'Time',
    size: { min: 150, default: 150 },
    sortFn: byValue<MeetingInsightsSchema, string>(
      (meetingInsight) => meetingInsight.eventData?.start ?? '',
      DEFAULT_STRING_SORT
    ),
  },
  host: {
    id: 'host',
    name: 'Host',
    size: { min: 150, default: 150 },
    sortFn: byValue<MeetingInsightsSchema, string | null>(
      (meetingInsight) =>
        meetingInsight.eventData?.organizer?.displayName ?? null,
      DEFAULT_STRING_SORT
    ),
  },
  attendees: {
    id: 'attendees',
    name: 'Attendees',
    size: { min: 150, default: 150 },
    sortFn: byValue<MeetingInsightsSchema, number | null>(
      (meetingInsight) => meetingInsight.eventData?.attendeeEmails?.length ?? 0,
      Compare.numeric
    ),
  },
  startDate: {
    id: 'startDate',
    name: 'Date',
    size: { min: 150, default: 150 },
    sortFn: byValue<MeetingInsightsSchema, string>(
      (meetingInsight) =>
        DateTime.fromISO(meetingInsight.startTime ?? '').toISODate(),
      DEFAULT_STRING_SORT
    ),
  },
} satisfies Record<string, FieldDefinition<MeetingInsightsSchema>>

export type SortField = {
  [key in keyof typeof MEETING_INSIGHTS_FIELDS]: (typeof MEETING_INSIGHTS_FIELDS)[key] extends {
    sortFn: CompareFn<any>
  }
    ? key
    : never
}[keyof typeof MEETING_INSIGHTS_FIELDS]
export type MeetingInsightsFieldId = keyof typeof MEETING_INSIGHTS_FIELDS
