import { type CreateZoomMeetingDto } from '@motion/rpc-types'
import { showToast, ToasterState } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { logInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'
import { useModalApi } from '@motion/web-common/modals'

import { useMemo } from 'react'

import { usePersistedConferenceSettingsState } from './use-persisted-conference-settings-state'

import { HttpException, request } from '../../../../services/apiUtils'
import { getProxy } from '../../../../state/backgroundProxy'
import { openZoomAuthWindow } from '../../../../utils/auth'
import { isZoomReady } from '../../../../utils/booking'
import { type PersistedConferenceSettingsStateUpdate } from '../types'

const zoomService = getProxy('ZoomService')

export const useConferenceSettingsApi = () => {
  const [remoteState, updateRemoteState] = usePersistedConferenceSettingsState()
  const modalApi = useModalApi()

  return useMemo(() => {
    const selectConferenceSettings = () => remoteState.conferenceSettings
    const selectReminderSettings = () => remoteState.reminderSettings
    const selectZoomNeedsRefresh = () => remoteState.zoomNeedsRefresh

    return {
      selectConferenceSettings,
      selectReminderSettings,
      async setConferenceSettings(
        conferenceSettings: PersistedConferenceSettingsStateUpdate['conferenceSettings']
      ) {
        await updateRemoteState({ conferenceSettings })
      },
      async setReminderSettings(
        reminderSettings: PersistedConferenceSettingsStateUpdate['reminderSettings']
      ) {
        await updateRemoteState({ reminderSettings })
      },
      async createZoomMeeting(dto: CreateZoomMeetingDto) {
        try {
          // Authenticate zoom before trying to create a meeting
          if (
            !isZoomReady(selectConferenceSettings(), selectZoomNeedsRefresh())
          ) {
            logInDev('No zoom account found')
            const response = await modalApi.prompt('confirm', {
              analytics: { name: 'open-zoom-auth-window' },
              title: 'Zoom authentication required',
              description:
                'Please authenticate your Zoom account to generate a meeting link.',
              closeButtonText: 'Cancel',
              confirmButtonText: 'Authenticate',
            })
            if (response === true) {
              openZoomAuthWindow()
              showToast(
                'neutral',
                'Please try again after authenticating Zoom',
                { duration: 8000 }
              )
            } else {
              showToast('error', 'Failed to create Zoom meeting')
            }
            return { completed: false }
          }

          const toast = showToast('loading', 'Creating Zoom meeting...')
          const meetingResponse = await zoomService.createMeeting(dto)
          ToasterState.dismiss(toast)

          return { completed: true, data: meetingResponse }
        } catch (e) {
          logInDev('Failed to create zoom meeting', e)
          showToast('error', 'Failed to create Zoom meeting')
          return { completed: false }
        }
      },
      /**
       * Re-pulls metadata about the Zoom user from the Zoom api, including the
       * account email and the personal meeting url.
       */
      async refreshZoomUserData() {
        try {
          const response = await request('zoom/user', 'POST', __BACKEND_HOST__)
          if (response.needsRefresh) {
            throw new Error('Zoom data not refreshed')
          }
          await updateRemoteState({ zoomNeedsRefresh: false })

          return
        } catch (e) {
          logInDev('Error getting Zoom User Data: ', e)
          await updateRemoteState({ zoomNeedsRefresh: true })

          const extra =
            e instanceof HttpException && e.context ? e.context : undefined

          switch (extra?.message) {
            case 'No access token retrieved':
              logInDev('Could not retrieve access token')
              recordAnalyticsEvent('ZOOM_PERMISSIONS_NO_ACCESS')
              break
            default:
              Sentry.captureException(e, {
                tags: { position: 'refreshZoomUserData' },
                extra,
              })
              logInDev('Unknown error')
          }

          return
        }
      },
      /**
       * Disconnect Zoom account by removing all Zoom-related settings.
       * ancient TODO: Move this to a backend endpoint. (is this still a TODO?)
       **/
      async deleteZoomAccount() {
        const newSettings = { ...selectConferenceSettings() }
        newSettings.conferenceType = 'meet'
        newSettings.zoomLinkType = 'manual'
        newSettings.zoomPersonalLink = ''
        newSettings.zoomAccount = ''

        await updateRemoteState({
          zoomAccessToken: null,
          zoomExpiration: null,
          zoomNeedsRefresh: false,
          zoomRefreshToken: null,
          conferenceSettings: newSettings,
        })

        return { conferenceSettings: newSettings }
      },
    }
  }, [
    modalApi,
    remoteState.conferenceSettings,
    remoteState.reminderSettings,
    remoteState.zoomNeedsRefresh,
    updateRemoteState,
  ])
}
