import { getRemainingDuration } from '@motion/ui-logic/pm/task'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type TaskSchema } from '@motion/zod/client'

import { useStopTask } from '~/areas/tasks/hooks'
import { useReadTaskFn, useStartTaskMutation } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback, useMemo } from 'react'

import { getParentChunkId } from '../../utils'
import { useOngoingAndUpcomingScheduledEntities } from '../helpers'

interface Options {
  source?:
    | 'taskDropdown'
    | 'taskActionDropdown'
    | 'upcomingTaskEvent'
    | 'reminderNotification'
    | 'agendaTaskItem'
}

export const useStartTask = () => {
  const modalApi = useModalApi()
  const { mutateAsync: startTask } = useStartTaskMutation()
  const readTask = useReadTaskFn()
  const stopTask = useStopTask()

  const { ongoingEvents } = useOngoingAndUpcomingScheduledEntities()

  const ongoingTasks = useMemo(
    () =>
      ongoingEvents
        .filter((o) => o.type === 'TASK' || o.type === 'CHUNK')
        .map((o) => o.id),
    [ongoingEvents]
  )

  return useCallback(
    async (
      taskId: TaskSchema['id'],
      options: Options = READONLY_EMPTY_OBJECT
    ) => {
      const { source = 'none' } = options

      const task = await readTask(taskId)
      const parentChunkId = getParentChunkId(task)
      const parentChunkTask =
        parentChunkId != null ? await readTask(parentChunkId) : null
      const finalTask = parentChunkTask ?? task

      if (task == null || finalTask == null) return

      // Because this action is accessible from the task modal, we should suggest
      // the remaining task duration (if there is no remaining duration, suggest a reasonable default)
      const suggestedDuration =
        (task.type === 'CHUNK'
          ? task.duration
          : getRemainingDuration(finalTask)) ?? 30

      let warned = false
      // Stop modal for each going task
      for (const id of ongoingTasks) {
        if (!id) continue
        // eslint-disable-next-line no-await-in-loop
        const t = await readTask(id)
        if (t == null || t.type === 'RECURRING_TASK') continue

        if (t.manuallyStarted) {
          if (!warned) {
            // eslint-disable-next-line no-await-in-loop
            const confirmed = await modalApi.prompt('confirm', {
              analytics: {
                name: 'task-in-progress',
              },
              title: 'Task in progress',
              description:
                'Starting this task will stop the currently ongoing task, do you want to continue?',
              confirmButtonText: 'Continue',
            })
            if (confirmed !== true) return
            warned = true
          }
          // eslint-disable-next-line no-await-in-loop
          const stopped = await stopTask(t.id)
          if (!stopped) return
        }
      }
      try {
        const response = await modalApi.prompt('start-task', {
          name: finalTask.name,
          suggestedDuration,
        })

        if (response === ModalDismissed || response === undefined) return

        recordAnalyticsEvent('TASK_START', {
          startDuration: response,
          source,
        })

        await startTask({
          id: finalTask.id,
          plannedStartDuration: response,
        })
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            position: 'useStartTask',
          },
        })

        showErrorToast(e)
      }
    },
    [readTask, ongoingTasks, stopTask, modalApi, startTask]
  )
}
