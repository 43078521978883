import { Checkbox } from '@motion/ui/forms'
import {
  browserNotifications,
  emailNotifications,
  mobileNotifications,
  NotificationPreferenceId,
} from '@motion/ui-logic'
import { useHasTreatment } from '@motion/web-common/flags'

import { useInNoExternalCalendarsMode } from '~/areas/calendar/hooks'
import { SettingPageWrapper } from '~/pages/settings/shared'
import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import {
  fetchUserNotificationPreferences,
  selectUserNotificationPreferences,
  updateUserNotificationPreferences,
} from '../../../../state/projectManagementSlice'
import { Paragraph } from '../../../Common'
import { LoadingSvg } from '../../../Common/Icons/LoadingSvg'
import { BrowserNotificationSettings } from '../../Components/BrowserNotificationSettings/BrowserNotificationSettings'

export const NotificationSettings = () => {
  const hasMobilePushNotificationsFeature = useHasTreatment(
    'mobile-push-notifications-release-v3'
  )
  const hasNotesFeature = useHasTreatment('notes-in-webapp')

  const dispatch = useAppDispatch()
  const userNotificationPreferences = useAppSelector(
    selectUserNotificationPreferences
  )
  const { noExternalCalendarsMode } = useInNoExternalCalendarsMode()

  useEffect(() => {
    void dispatch(fetchUserNotificationPreferences())
  }, [dispatch])

  const sections = [
    {
      title: 'Browser/desktop notifications',
      settings: browserNotifications.filter((setting) => {
        if (
          noExternalCalendarsMode &&
          setting.id === NotificationPreferenceId.BrowserMeetingReminder
        ) {
          return false
        }
        return true
      }),
    },
    {
      title: 'Email notifications',
      settings: emailNotifications.filter((n) => {
        if (hasNotesFeature) {
          return true
        }
        return n.id !== NotificationPreferenceId.EmailMentionedInNote
      }),
    },
    hasMobilePushNotificationsFeature && {
      title: 'Mobile notifications',
      settings: mobileNotifications.filter((n) => {
        if (hasNotesFeature) {
          return true
        }
        return n.id !== NotificationPreferenceId.MobileMentionedInNote
      }),
    },
  ].filter(Boolean)

  if (!userNotificationPreferences) {
    return <LoadingSvg />
  }

  return (
    <SettingPageWrapper title='Notifications' className='gap-6'>
      {sections.map(({ title, settings }) => (
        <div key={title} className='flex flex-col gap-4'>
          <Paragraph className='text-base font-semibold'>{title}</Paragraph>
          <div className='flex flex-col gap-3'>
            {settings.map(({ id, title: preferenceTitle }) => (
              <Checkbox
                key={id}
                checked={userNotificationPreferences[id]}
                onChange={(checked) => {
                  void dispatch(
                    updateUserNotificationPreferences({ [id]: !!checked })
                  )
                }}
                label={preferenceTitle}
              />
            ))}
          </div>
        </div>
      ))}
      <BrowserNotificationSettings />
    </SettingPageWrapper>
  )
}
