import { FolderColoredIcon } from '@motion/ui/project'
import { type FolderSchema, type WorkspaceSchema } from '@motion/zod/client'

import { ConnectedFolderBreadcrumbs } from '~/areas/folders/components'
import { useLookup } from '~/global/cache'
import {
  WorkspacesTreeDropdown,
  type WorkspacesTreeDropdownProps,
} from '~/global/components/dropdowns'

import { ModalFieldButton, type ModalFieldButtonProps } from '../components'

export type FolderFieldProps = {
  folderId: FolderSchema['id'] | null | undefined
  workspaceId: WorkspaceSchema['id']
  onChange: WorkspacesTreeDropdownProps['onChange']
  computeDisabled?: WorkspacesTreeDropdownProps['computeDisabled']
  disabled?: boolean
  suffix?: ModalFieldButtonProps['suffix']
}

export function FolderField(props: FolderFieldProps) {
  const { folderId, disabled, suffix, computeDisabled, onChange, workspaceId } =
    props
  const lookup = useLookup()
  const selectedFolder = folderId != null ? lookup('folders', folderId) : null

  // - If it’s a folder of type USER, i.e. regular folder in the UI, use its own ID.
  // - Otherwise, use the target ID of the folder, right now it will be the workspace, but could be another folder in the future.
  // - If no folder is selected, default to the workspaceId which is the default parent.
  const selectedId = selectedFolder
    ? selectedFolder.type === 'USER'
      ? selectedFolder.id
      : selectedFolder.targetId
    : workspaceId

  return (
    <WorkspacesTreeDropdown
      selectedId={selectedId}
      workspaceId={workspaceId}
      onChange={onChange}
      leafNodeType='FOLDER'
      computeDisabled={computeDisabled}
      showCreateFolder
    >
      <ModalFieldButton
        size='normal'
        icon={<FolderColoredIcon color={selectedFolder?.color} />}
        disabled={disabled}
        suffix={suffix}
      >
        <ConnectedFolderBreadcrumbs
          leafNodeId={folderId ?? null}
          abbreviated='start'
          nameOnly
          omitWorkspace
        />
      </ModalFieldButton>
    </WorkspacesTreeDropdown>
  )
}
