import { Button, FormModal, Shortcut } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { isEmailValid } from '@motion/utils/string'

import { unwrapResult } from '@reduxjs/toolkit'
import { dispatch } from '~/state/proxy'
import { checkTeamEligibility } from '~/state/teamSlice'
import { useCallback, useState } from 'react'

import { type AddMembersModalProps } from '../../../types/AddMembersModalProps'
import { InviteeList } from '../../CreateTeam/InviteeList/InviteeList'

export const AddMembersModal = ({
  addMembers,
  closeModal,
}: AddMembersModalProps) => {
  const [emails, setEmails] = useState<string[]>([])
  const [currentEmail, setCurrentEmail] = useState('')
  const [ineligibleMember, setIneligibleMember] = useState(false)

  const addEmails = async () => {
    const trimmedEmail = currentEmail.trim()
    if (
      isEmailValid(trimmedEmail) &&
      !emails.some(
        (email) => trimmedEmail.toLowerCase() === email.toLowerCase()
      )
    ) {
      return dispatch(checkTeamEligibility(trimmedEmail))
        .then(unwrapResult)
        .then((res) => {
          if (res.isEligible) {
            setIneligibleMember(false)
            const newEmails = [...emails, trimmedEmail]
            setEmails(newEmails)
            setCurrentEmail('')
            return newEmails
          }
          setIneligibleMember(true)
          return emails
        })
    }
    return emails
  }

  const handleEnter = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        void addEmails()
        event.preventDefault()
      }
    },
    [currentEmail, emails, setEmails, setCurrentEmail] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onDelete = useCallback(
    (email: string) => {
      setEmails(emails.filter((e) => e !== email))
    },
    [emails, setEmails]
  )

  return (
    <FormModal
      visible
      onClose={closeModal}
      title='Invite member'
      submitAction={{
        text: 'Add members',
        onAction: async () => {
          const membersToAdd = await addEmails()
          addMembers(membersToAdd)
        },
        disabled: emails.length === 0,
      }}
    >
      <div className='flex gap-2 items-end'>
        <TextField
          label='Email addresses'
          fullWidth
          value={currentEmail}
          onChange={setCurrentEmail}
          onKeyDown={handleEnter}
          placeholder='Enter email'
          suffix={<Shortcut shortcut='Enter' />}
        />
        <Button onClick={addEmails} disabled={!currentEmail}>
          Add
        </Button>
      </div>

      {ineligibleMember && (
        <p className='my-2.5 text-semantic-error-text-default text-xs'>
          This member can’t be invited because they’re already part of an active
          team.
        </p>
      )}
      <div className='max-w-sm'>
        {emails.length > 0 && (
          <InviteeList
            emails={emails}
            onDelete={onDelete}
            className='m-0 my-2.5'
          />
        )}
      </div>
    </FormModal>
  )
}
