import { CrownSolid } from '@motion/icons'

import { ErrorBoundary } from '~/global/components'

import { ActivityUpsellSection } from './activity-upsell-section'
import { SlimCommentBox } from './comment'
import { History } from './history'

import { useActivityFeedGating, useCreateComment } from '../hooks'
import { ActivityFeedProvider } from '../providers'
import {
  type ActivityFeedType,
  type CommentSubmitPayload,
  feedTypeToRpcType,
} from '../types'

export type ActivityFeedProps = {
  workspaceId: string
  onCommentChange?: (value: string) => void
} & ActivityFeedType

/**
 * Renders the Activity Feed for a target type, such as a Task or Project.
 */
export function ActivityFeed(props: ActivityFeedProps) {
  const { type, target, onCommentChange } = props
  const createComment = useCreateComment()

  const handleSubmit = async ({
    body,
    createdByUserId,
  }: CommentSubmitPayload) => {
    createComment({
      body,
      createdByUserId,
      type: feedTypeToRpcType[type],
      targetId: target.id,
    })
  }

  const { activityWindow, onActivityFeatureGated } = useActivityFeedGating()

  const isFeatureGated = activityWindow === 0

  return (
    <ErrorBoundary errorMessage="Activity feed couldn't be loaded">
      <ActivityFeedProvider {...props}>
        <div className='flex flex-col gap-4 w-full transition-all overflow-y-hidden h-full'>
          <SlimCommentBox
            prefix={
              isFeatureGated && (
                <div className='flex items-center justify-center ml-2'>
                  <CrownSolid className='!size-4' />
                </div>
              )
            }
            onSubmit={handleSubmit}
            onChange={onCommentChange}
            onClick={isFeatureGated ? onActivityFeatureGated : undefined}
            disabled={isFeatureGated}
          />

          {isFeatureGated ? <ActivityUpsellSection /> : <History />}
        </div>
      </ActivityFeedProvider>
    </ErrorBoundary>
  )
}
