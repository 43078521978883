import { classed } from '@motion/theme'

/**
 * --modal-offset is set by the task modal to add an offset when the side panel is opened
 * --min-modal-width is set based on the number of columns used in the shell
 */
// TODO: modal-size breakpoints are not working for opt-space, why?
export const SharedFormShell = classed('form', {
  base: `
    bg-modal-bg
    rounded-lg
    shadow-lg
    border border-semantic-neutral-border-default

    w-[var(--min-modal-width)]
    max-w-[clamp(200px,calc(100vw-56px-var(--modal-offset,0px)),calc(var(--min-modal-width)+var(--modal-offset,0px)))]
    modal-sm:max-w-none
  `,
  variants: {
    columns: {
      2: `
        h-[687px]
        max-h-[100vh]
        modal-md:w-[1018px] modal-md:h-[769px]
        modal-lg:w-[1078px] modal-lg:h-[819px]
        modal-xl:w-[1279px] modal-xl:h-[80vh] modal-xl:max-h-[1415px]
      `,
      3: `
        h-[687px]
        max-h-[100vh]
        modal-md:w-[1338px] modal-md:h-[769px]
        modal-lg:w-[1458px] modal-lg:h-[819px]
        modal-xl:w-[1599px] modal-xl:h-[80vh] modal-xl:max-h-[1415px]
      `,
    },
  },
  defaultVariants: {
    columns: 2,
  },
  dataAttributes: ['columns'],
})

export const SharedGridShell = classed('div', {
  base: `
    h-full
    grid
    grid-rows-[min-content_1fr_min-content]

    grid-cols-[auto_var(--aside-width,320px)]
    [[data-columns="3"]_&]:grid-cols-[auto_var(--aside-width,320px)_var(--aside-width,320px)]
  `,
})

export const ShellVars = classed('div', {
  base: `
    contents

    setvar-[aside-width=280px]
    modal-md:setvar-[aside-width=320px]
    modal-lg:setvar-[aside-width=380px]
  `,
  variants: {
    columns: {
      2: `
        setvar-[min-modal-width=894px]
      `,
      3: `
        setvar-[min-modal-width=1174px]
      `,
    },
  },
  defaultVariants: {
    columns: 2,
  },
  dataAttributes: ['columns'],
})

export const ShellActions = classed('div', {
  base: `
    absolute
    -right-[28px]
    top-0

    flex flex-col gap-4
  `,
})

export const SidebarShellStyles = classed('div', {
  base: `
    bg-semantic-neutral-surface-raised-bg-subtlest
    border-l border-modal-border
    rounded-r-lg
  `,
})

export const SidebarShell = classed('aside', SidebarShellStyles, {
  base: `
    [grid-area:aside]
    h-full overflow-auto
  `,
})

export const SidebarContent = classed('section', {
  base: `
    flex flex-col
    overflow-auto
  `,
})

export const SidebarSection = classed('div', {
  base: `
    w-full
    flex flex-col gap-0.5
    px-3 py-2.5
    modal-lg:px-5 modal-lg:gap-1.5
  `,
})

export const SidebarWorkspaceSection = classed('div', SidebarSection, {
  base: `
    p-3 pt-4
    modal-lg:py-6 modal-lg:px-5
  `,
})
