import { isResolvedStatus } from '@motion/shared/common'

import {
  StatusDropdown,
  type StatusDropdownProps,
} from '~/global/components/dropdowns'
import { GhostStatusDescription } from '~/global/components/tooltips'

export function GhostTaskStatusDropdown({
  children,
  ...rest
}: StatusDropdownProps) {
  return (
    <StatusDropdown
      {...rest}
      iconVariant='isUnvisitedStage'
      renderContentTopSection={({ status }) =>
        !isResolvedStatus(status) ? <GhostStatusDescription size='2xs' /> : null
      }
    >
      {children}
    </StatusDropdown>
  )
}
