import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
} from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'

import { notifyManager } from '@tanstack/react-query'

import { type HandlerContext } from './types'
import { type MutationIds } from './utils'

const KEYS_TO_UPDATE = [
  MODEL_CACHE_KEY,
  API.workspacesV2.queryKeys.root,
  API.notes.queryKeys.root,
]

const updateFilter = createQueryFilter<any>(KEYS_TO_UPDATE)

export async function refreshNotes(
  ctx: HandlerContext,
  mutations: MutationIds
) {
  notifyManager.batch(() => {
    if (mutations.create.length) {
      // FIX: react-query-v5 - predicate didn't match comment
      ctx.client.invalidateQueries({ queryKey: API.notes.queryKeys.root })
    } else if (mutations.update.length) {
      mutations.update.forEach((id) => {
        ctx.client.invalidateQueries({
          queryKey: API.notes.queryKeys.noteById({ id }),
        })
      })
    }

    if (mutations.delete.length) {
      MotionCache.delete(ctx.client, updateFilter, 'notes', mutations.delete)
    }
  })
}
