import { templateStr } from '@motion/react-core/strings'
import { FormModal } from '@motion/ui/base'
import { RadioButton } from '@motion/ui/forms'
import {
  type ProjectDefinitionSchema,
  type StageDefinitionSchema,
  type StageSchema,
} from '@motion/zod/client'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { ConnectedStageLabel, StageLabel } from '~/global/components/labels'
import { useProjectDefinition } from '~/global/hooks'
import { useState } from 'react'

export type OldSkipStagesResponse = 'create-all' | 'skip'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'old-skip-stages-modal': PromptCallbacks<OldSkipStagesResponse> & {
      projectDefinitionId: ProjectDefinitionSchema['id']
      currentStageDefinitionId: StageDefinitionSchema['id']
      nextStageDefinitionId: StageDefinitionSchema['id']
      projectStages: StageSchema[]
    }
  }
}

export const OldSkipStagesModal = ({
  close,
  projectDefinitionId,
  currentStageDefinitionId,
  nextStageDefinitionId,
  projectStages,
}: ModalTriggerComponentProps<'old-skip-stages-modal'>) => {
  const [value, setValue] = useState<OldSkipStagesResponse>('create-all')
  const pd = useProjectDefinition(projectDefinitionId)
  if (pd == null) {
    throw new Error('Project definition cannot be found')
  }

  const currentStageDefIndex = pd.stages.findIndex(
    (s) => s.id === currentStageDefinitionId
  )
  const nextStageDefIndex = pd.stages.findIndex(
    (s) => s.id === nextStageDefinitionId
  )

  if (currentStageDefIndex < 0 || nextStageDefIndex < 0) {
    throw new Error('Stage definitions cannot be found', {
      cause: {
        pd,
        currentStageDefIndex,
        nextStageDefIndex,
      },
    })
  }

  const nextStageDef = pd.stages[nextStageDefIndex]
  const skipStageList = pd.stages
    .slice(currentStageDefIndex + 1, nextStageDefIndex)
    .filter(
      (s) =>
        !projectStages.find((ps) => ps.visited && ps.stageDefinitionId === s.id)
    )

  const handleSubmit = () => {
    close(value)
  }

  return (
    <FormModal
      onClose={() => close()}
      submitAction={{
        onAction: handleSubmit,
        text: 'Change stage',
      }}
      cancelAction={{
        shortcut: 'esc',
      }}
      title={templateStr('Are you sure you want to move to {{stage}}?', {
        stage: <StageLabel value={nextStageDef} />,
      })}
      visible
    >
      <div className='w-[450px] text-semantic-neutral-text-default'>
        <p className='flex gap-1 flex-wrap'>
          <span className='align-middle'>
            This will skip the following stages:
          </span>
          <span className='inline-flex gap-1 flex-wrap'>
            {skipStageList.map((s) => (
              <ConnectedStageLabel key={s.id} id={s.id} />
            ))}
          </span>
        </p>
        <p className='mt-5 mb-2'>
          What should happen to the tasks in these stages?
        </p>
        <div className='flex gap-2 flex-col'>
          <RadioButton
            name='skipValue'
            checked={value === 'create-all'}
            onChange={() => setValue('create-all')}
            label='Create and auto-schedule tasks on assignee’s calendars'
          />
          <RadioButton
            name='skipValue'
            checked={value === 'skip'}
            onChange={() => setValue('skip')}
            label='Don’t create the tasks'
          />
        </div>
      </div>
    </FormModal>
  )
}
