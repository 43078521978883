import { SearchSolid } from '@motion/icons'
import {
  useDebouncedCallback,
  useDependantState,
} from '@motion/react-core/hooks'
import { useShortcut } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { useUIContext } from '@motion/ui/providers'

import { useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

export const NameSearchButton = (props: {
  onSearch: (search: string) => void
  value: string
  placeholder?: string
}) => {
  const { hasVisibleModals } = useUIContext()
  const [isFocused, setIsFocused] = useState(false)
  const [searchValue, setSearchValue] = useDependantState(
    () => props.value,
    [props.value]
  )
  const textRef = useRef<HTMLLabelElement>(null)

  const debouncedCallback = useDebouncedCallback(props.onSearch, 400)

  useShortcut(
    'mod+f',
    () => {
      setIsFocused(true)
      textRef.current?.focus()
    },
    { enabled: !hasVisibleModals }
  )

  const isOpened = isFocused || !!searchValue

  return (
    <div
      className={twMerge(
        'transition-all flex items-center',
        isOpened ? 'w-[180px]' : 'w-[80px]'
      )}
    >
      <TextField
        ref={textRef}
        label={props.placeholder}
        labelHidden
        fullWidth
        showClearButton
        sentiment={searchValue ? 'active' : 'default'}
        variant='minimal'
        prefix={<SearchSolid />}
        placeholder={props.placeholder}
        value={searchValue}
        onChange={(searchValue) => {
          setSearchValue(searchValue)
          debouncedCallback(searchValue)
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        size='small'
      />
    </div>
  )
}
