import { makeTemplateString } from '@motion/react-core/strings'
import { type CalendarEventSchema } from '@motion/rpc-types'

import { Highlight } from './components/highlight'

export type CalendarEventSearchSchema = {
  entityType: 'event'
} & CalendarEventSchema

type TaskSearchType = {
  type: 'tasks'
  archived: boolean
  showCompleted: boolean
}

type EventSearchType = {
  type: 'events'
}

type AttachmentSearchType = {
  type: 'attachments'
}

export type SearchType = TaskSearchType | EventSearchType | AttachmentSearchType

export function isSearchingTasks(st: SearchType): st is TaskSearchType {
  return st.type === 'tasks'
}

export function isSearchingEvents(st: SearchType): st is EventSearchType {
  return st.type === 'events'
}

export function isSearchingAttachments(
  st: SearchType
): st is AttachmentSearchType {
  return st.type === 'attachments'
}

export function isValidSearch(searchQuery: string) {
  return searchQuery.length > 1
}

export const DATA_SELECTED_ATTR = 'data-selected'

const EM_TAGS_RE = /<em>(.*?)<\/em>/g

const templateHighlight = makeTemplateString(EM_TAGS_RE)

export function highlightEmText(value: string) {
  return templateHighlight(value, (word) => <Highlight>{word}</Highlight>)
}

export function stripEmTags(value: string) {
  return templateHighlight(value, (word) => word)
}
