import {
  CustomFieldTextSolid,
  ProjectCubeSolid,
  StageSolidTransparentArrow,
  type SvgIconProps,
  UserSolid,
  ViewListSolid,
} from '@motion/icons'
import { Focus } from '@motion/ui/base'
import { createLookup } from '@motion/utils/object'

import { LoadingScreen } from '~/areas/flows'
import { type ComponentType, memo, type ReactNode, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  NameStepContent,
  RolesStepContent,
  StagesStepContent,
  TextReplacementsStepContent,
} from './steps'
import { CustomFieldsStepContent } from './steps/custom-fields'
import { StepHeader } from './styled'

import {
  type SetupProjectTabOption,
  useSetupProjectForm,
  useTabOptions,
} from '../hooks'

export function SetupProjectTabs() {
  const {
    form: { watch },
  } = useSetupProjectForm()

  const activeTab = watch('step')
  const tabOptions = useTabOptions()

  return (
    <div className='flex flex-col gap-1'>
      {tabOptions.map((tab) => (
        <SetupProjectTab key={tab} tab={tab} activeTab={activeTab} />
      ))}
    </div>
  )
}

const setupProjectTabLookup = createLookup<
  Record<
    SetupProjectTabOption,
    {
      label: ReactNode
      title?: ReactNode
      description?: string
      Icon: ComponentType<SvgIconProps>
      Tab: () => ReactNode
    }
  > & { default: null }
>({
  name: {
    label: 'Project title',
    Icon: ProjectCubeSolid,
    Tab: NameStepContent,
  },
  roles: {
    label: 'Assign roles',
    title: 'Assign roles',
    description: `Choose the assignee for each role. We'll automatically assign tasks to them!`,
    Icon: UserSolid,
    Tab: RolesStepContent,
  },
  customFields: {
    label: 'Custom fields',
    title: 'Custom fields',
    Icon: ViewListSolid,
    Tab: CustomFieldsStepContent,
  },
  textVariables: {
    label: 'Text variables',
    title: 'Replace text',
    description: `Set your text variables. These will replace placeholders in this template's tasks and descriptions.`,
    Icon: CustomFieldTextSolid,
    Tab: TextReplacementsStepContent,
  },
  stages: {
    label: 'Stages & tasks',
    Icon: StageSolidTransparentArrow,
    Tab: StagesStepContent,
  },
  default: null,
})

export function SetupProjectTab({
  tab,
  activeTab,
}: {
  tab: SetupProjectTabOption
  activeTab: SetupProjectTabOption
}) {
  const isActive = tab === activeTab

  const tabConfig = setupProjectTabLookup(tab)
  if (tabConfig == null) {
    return null
  }

  const Icon = tabConfig.Icon

  return (
    <div
      className={twMerge(
        'flex items-center gap-2 px-2 py-1.5 rounded',
        isActive && 'bg-sidebar-item-bg-selected'
      )}
    >
      <Icon className='h-4 w-4 text-semantic-neutral-icon-default' />
      <label
        className={twMerge(
          'text-[14px] font-semibold',
          !isActive
            ? 'text-sidebar-item-text-default'
            : 'text-sidebar-item-text-selected'
        )}
      >
        {tabConfig.label}
      </label>
    </div>
  )
}

export const SetupProjectTabPanels = memo(
  ({ activeTab }: { activeTab: SetupProjectTabOption }) => {
    const {
      form: {
        formState: { isSubmitting },
      },
    } = useSetupProjectForm()

    const tabConfig = setupProjectTabLookup(activeTab)
    const ref = useRef<HTMLDivElement | null>(null)

    if (tabConfig == null) {
      return null
    }

    const { Tab } = tabConfig

    const hasTitle = tabConfig.title != null
    const hasDescription = tabConfig.description != null
    const hasTitleOrDescription = hasTitle || hasDescription

    return (
      <>
        {hasTitle && <StepHeader>{tabConfig.title}</StepHeader>}
        {hasDescription && (
          <p className='pt-2 text-toggle-text-default text-sm leading-[20px]'>
            {tabConfig.description}
          </p>
        )}
        <Focus contentRef={ref}>
          <div
            className={twMerge('pb-4', hasTitleOrDescription && 'pt-6')}
            ref={ref}
            key={activeTab}
          >
            {isSubmitting ? (
              <LoadingScreen
                title='Creating Project'
                message='Hang tight! This could take up to 90 seconds...'
                className='h-96'
              />
            ) : (
              <Tab />
            )}
          </div>
        </Focus>
      </>
    )
  }
)

SetupProjectTabPanels.displayName = 'SetupProjectTabPanels'
