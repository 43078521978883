import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import {
  type UpdateWorkspaceSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { useUpdateWorkspace as useUpdateWorkspaceCall } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useUpdateWorkspace = () => {
  const {
    mutateAsync: updateWorkspace,
    isPending: isLoading,
    isError,
  } = useUpdateWorkspaceCall()

  return {
    updateWorkspace: useCallback(
      async (
        data: UpdateWorkspaceSchema & { workspaceId: WorkspaceSchema['id'] }
      ) => {
        try {
          if (data.name != null && data.name.trim().length === 0) {
            showToast('error', 'Workspace name cannot be empty')
            return
          }

          if (data.name) {
            recordAnalyticsEvent('PROJECT_MANAGEMENT_RENAME_WORKSPACE')
          }
          if (data.teamId) {
            recordAnalyticsEvent('PROJECT_MANAGEMENT_CONVERT_WORKSPACE_TO_TEAM')
          }

          const response = await updateWorkspace(data)

          showToast('success', 'Workspace updated')

          return response.models[response.meta.model][response.id]
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              position: 'update-workspace',
            },
          })

          showErrorToast(e)

          return undefined
        }
      },
      [updateWorkspace]
    ),
    isLoading,
    isError,
  }
}
