import { formatToTaskDuration, getDurations } from '@motion/ui-logic'

import {
  DurationDropdown,
  type DurationDropdownProps,
} from '~/areas/project-management/components'
import { ModalFieldButton } from '~/areas/task-project/components'
import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import { useActionItemForm } from '../../hooks'

export function ControlledDurationField() {
  const { form } = useActionItemForm()

  const { field } = useController({
    name: 'duration',
    control: form.control,
  })

  const durations = useMemo(() => getDurations(), [])

  const onChange: DurationDropdownProps['onChange'] = async (duration) => {
    field.onChange(duration)
  }

  return (
    <DurationDropdown
      value={field.value}
      choices={durations}
      onChange={onChange}
    >
      <ModalFieldButton label='Duration'>
        <span className='truncate text-[10px] font-normal'>
          {formatToTaskDuration(field.value)}
        </span>
      </ModalFieldButton>
    </DurationDropdown>
  )
}
