import { isNextActiveProjectStage } from '@motion/ui-logic/pm/project'
import { Sentry } from '@motion/web-base/sentry'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
} from '@motion/zod/client'

import { useIsFlowProject } from '~/areas/flows'
import { useWorkspaceFns } from '~/global/hooks'
import { useSetProjectToStage } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useAdvanceProjectStage } from './use-advance-project-stage'
import { useSkipStagesConfirmModalFn } from './use-skip-stages-confirm-modal-fn'

export const useMoveProjectToStage = () => {
  const { mutateAsync: setProjectToStage } = useSetProjectToStage()
  const { getStageDefinition } = useWorkspaceFns()
  const skipStagesConfirmModalFn = useSkipStagesConfirmModalFn()

  const advanceProjectStage = useAdvanceProjectStage()

  const isFlowProject = useIsFlowProject()

  return useCallback(
    async (
      project: ProjectSchema,
      stageDefinitionId: StageDefinitionSchema['id'],
      onConfirmed?: () => void
    ) => {
      try {
        if (isNextActiveProjectStage(project, stageDefinitionId)) {
          return advanceProjectStage(project, stageDefinitionId, onConfirmed)
        }

        if (!isFlowProject(project)) {
          throw new Error(`Moving stages can only be done in project workflows`)
        }

        const stage = getStageDefinition(stageDefinitionId)
        if (stage == null) {
          throw new Error('Stage definition not found')
        }

        const strategy = await skipStagesConfirmModalFn(
          project,
          stageDefinitionId
        )

        if (strategy === false) return false

        await setProjectToStage({
          projectId: project.id,
          stageDefinitionId: stageDefinitionId,
          strategy,
        })

        onConfirmed?.()

        return true
      } catch (e) {
        Sentry.captureException(e, {
          tags: { position: 'move-project-to-stage' },
        })

        showErrorToast(
          e,
          'There was an error moving the project to another stage'
        )
      }

      return false
    },
    [
      advanceProjectStage,
      getStageDefinition,
      isFlowProject,
      setProjectToStage,
      skipStagesConfirmModalFn,
    ]
  )
}
