import { priorityLevels } from '@motion/rpc/types'
import { FieldButton } from '@motion/ui/base'

import { useStageTaskCardFields } from '~/areas/flows/contexts'
import { PriorityDropdown } from '~/global/components/dropdowns'
import { PriorityLabel } from '~/global/components/labels'

export function PriorityFields() {
  const {
    fields: { priority },
  } = useStageTaskCardFields()
  const [priorityLevel, onChangePriorityLevel] = priority

  const fieldSize = 'xsmall'

  return (
    <PriorityDropdown
      selectedItem={priorityLevel}
      items={priorityLevels}
      onChange={onChangePriorityLevel}
    >
      <FieldButton variant='muted' fullWidth size={fieldSize}>
        <PriorityLabel value={priorityLevel} />
      </FieldButton>
    </PriorityDropdown>
  )
}
