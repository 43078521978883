import { LoadingSpinner } from '@motion/ui/base'
import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'
import { type CreateProjectDefinitionSchema } from '@motion/zod/client'

import { type ReactNode } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useInitialFormData } from './hooks'

import { FlowFormValuesUpdater } from '../shared-form'

export type FlowTemplateFormProps = {
  children: ReactNode
  initialProjectDefinition?: CreateProjectDefinitionSchema
  mode?: 'ai-generation'
}

export const FlowTemplateInnerForm = ({
  initialFormData,
  children,
}: {
  initialFormData: FlowTemplateFormFields
  children: ReactNode
}) => {
  const form = useForm({
    defaultValues: initialFormData,
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
  })

  return (
    <FormProvider {...form}>
      <FlowFormValuesUpdater />
      {children}
    </FormProvider>
  )
}

export const FlowTemplateForm = ({
  children,
  initialProjectDefinition,
  mode,
}: FlowTemplateFormProps) => {
  const initialFormData = useInitialFormData({
    initialProjectDefinition,
    mode,
  })

  if (initialFormData.isLoading) {
    return (
      <div className='col-span-2 row-span-4 w-full h-full grid place-items-center'>
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <FlowTemplateInnerForm initialFormData={initialFormData}>
      {children}
    </FlowTemplateInnerForm>
  )
}
