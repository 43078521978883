import { type CommentsPluginHandle } from '@motion/notes'
import { type CommentSchema } from '@motion/rpc-types'

import { createContext, type MutableRefObject } from 'react'

import type { ThreadsTabType, ThreadWithComments } from './threads.types'

export type NoteCommentsContextType = {
  setActiveThreadId: (activeId: string | undefined) => void
  activeThreadId: string | undefined
  createComment: (args: {
    bodyHtml: string
    mentions: Array<string>
    threadId?: string
  }) => Promise<CommentSchema | undefined>
  deleteComment: (commentId: string, threadId?: string) => Promise<void>
  editComment: (commentId: string, bodyHtml: string) => Promise<void>
  resolveThread: (threadId: string) => Promise<void>
  threads: {
    active: ThreadWithComments[]
    resolved: ThreadWithComments[]
    all: ThreadWithComments[]
  } | null
  commentsPluginRef: MutableRefObject<CommentsPluginHandle | null>
  activeTab: ThreadsTabType
  setActiveTab: (tab: ThreadsTabType) => void
}

export const NoteCommentsContext =
  createContext<NoteCommentsContextType | null>(null)
