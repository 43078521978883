import { classed } from '@motion/theme'

export const ModalHeader = classed('header', {
  base: `
  rounded-t-lg
  bg-purple-gradient bg-opacity-[.15]

  flex flex-row justify-between items-center
  py-2 px-4
  text-xs
`,
})

export const ModalContent = classed('section', {
  base: `
  flex flex-col gap-6
  p-4
  overflow-auto
`,
})

export const ModalFooter = classed('footer', {
  base: `
  flex gap-3 items-center justify-end
  p-4
`,
})
