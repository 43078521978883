import { type TaskDefinitionSchema } from '@motion/rpc-types'
import { isUnassignedUser } from '@motion/ui-logic'

export function isAutoSchedulable(
  task: Pick<TaskDefinitionSchema, 'assigneeUserId' | 'assigneeVariableKey'>
) {
  if (task.assigneeVariableKey != null) {
    return true
  }

  return (
    task.assigneeUserId != null &&
    !isUnassignedUser({ userId: task.assigneeUserId })
  )
}
