import { type DashboardViewDefinitionV2 } from '@motion/zod/client'

import * as v from 'valibot'

import {
  CardFieldSchema,
  ColumnSchema,
  GroupByOptionsSchema,
  SortBySchema,
} from '../schema'

export const WorkspaceViewStateSchema = v.object({
  $version: v.literal(6),
  viewId: v.optional(v.nullable(v.string()), null),
  page: v.pipe(
    v.picklist(['list', 'kanban', 'gantt']),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    v.transform((data: any) => (data === 'planner' ? 'gantt' : data))
  ),

  groupBy: GroupByOptionsSchema,

  search: v.string(),
  sortBy: v.nullable(SortBySchema),

  columns: v.array(ColumnSchema),
  cardFields: v.optional(v.array(CardFieldSchema), []),
  providerIds: v.optional(v.array(v.string()), []),
  showOOOEventsByAssignee: v.optional(v.boolean(), false),
})

export const DashboardViewStateSchema = v.object({
  $version: v.literal(6),
  viewId: v.optional(v.nullable(v.string()), null),
  page: v.literal('dashboard'),
  view: v.pipe(
    v.any(),
    v.transform((input) => input as DashboardViewDefinitionV2)
  ),
})

export const ViewStateSchema = v.variant('page', [
  WorkspaceViewStateSchema,
  DashboardViewStateSchema,
])
