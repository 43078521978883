import {
  getV2SetupProjectChangedFields,
  isV2SetupProjectChangedFieldName,
  type V2SetupProjectFormFields,
} from '@motion/ui-logic/pm/project'
import { keys } from '@motion/utils/object'

import { valibotResolver } from '@hookform/resolvers/valibot'
import { type ReactNode, useEffect, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { validationSchema } from './form-schema'
import {
  useInitialFormData,
  type UseInitialFormDataArgs,
  useSetupProjectForm,
} from './hooks'
import { useSyncCustomFields } from './hooks/use-sync-custom-fields'

export type SetupProjectFormProps = {
  children: ReactNode
  formDefaults: UseInitialFormDataArgs
}

export const SetupProjectForm = ({
  children,
  formDefaults,
}: SetupProjectFormProps) => {
  const initialFormData = useInitialFormData(formDefaults)

  const form = useForm({
    defaultValues: initialFormData,
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
    resolver: valibotResolver(validationSchema),
  })

  useSyncCustomFields(form)

  return (
    <FormProvider {...form}>
      <SetupProjectFormValuesUpdater />
      {children}
    </FormProvider>
  )
}

const SetupProjectFormValuesUpdater = () => {
  const { form } = useSetupProjectForm()
  const prevFormValues = useRef<V2SetupProjectFormFields>(form.getValues())

  // Listen for the form and apply any derived values needed
  useEffect(() => {
    const subscription = form.watch((formValuesArg, { name }) => {
      if (name == null) return

      if (isV2SetupProjectChangedFieldName(name)) {
        // Type is wrong; this isn't partial
        const formValues = formValuesArg as V2SetupProjectFormFields

        const changedFields = getV2SetupProjectChangedFields(
          formValues,
          prevFormValues.current,
          name
        )

        keys(changedFields).forEach((formFieldKey) => {
          if (!(formFieldKey in formValues)) {
            throw new Error(
              `cannot set value of unknown key "${formFieldKey}" in form`
            )
          }

          const newValue = changedFields[formFieldKey] ?? null
          form.setValue(formFieldKey, newValue as any, {
            shouldDirty: true,
          })
        })
      }

      prevFormValues.current = formValuesArg as V2SetupProjectFormFields
    })
    return () => subscription.unsubscribe()
  }, [form])

  return null
}
