/* eslint-disable react-refresh/only-export-components */
import { type UploadedFileSchema } from '@motion/rpc-types'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'

import {
  createContext,
  type ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'

export const XHR_READY_STATE_UNSENT = 0
export const XHR_READY_STATE_DONE = 4

export type ActiveFileUpload = {
  tempId: string
  targetId: UploadedFileSchema['targetId'] | null
  targetType: UploadedFileSchema['targetType'] | null
  workspaceId?: UploadedFileSchema['workspaceId']
  fileName: string
  progress: number
  request?: XMLHttpRequest
}

export type ActiveFileUploadFilters = Partial<
  Omit<ActiveFileUpload, 'progress' | 'request'>
>

export interface FileUploadValue {
  activeFileUploads: ActiveFileUpload[]
  registerActiveFileUpload: (activeFileUpload: ActiveFileUpload) => void
  unregisterActiveFileUpload: (tempId: string) => void
  trackActiveFileUploadProgress: (tempId: string, progress: number) => void
  cancelActiveFileUploads: (filters?: ActiveFileUploadFilters) => void
}

const defaultValue: FileUploadValue = {
  activeFileUploads: [],
  registerActiveFileUpload: () => {},
  unregisterActiveFileUpload: () => {},
  trackActiveFileUploadProgress: () => {},
  cancelActiveFileUploads: () => {},
}

export const FileUploadContext = createContext<FileUploadValue>(defaultValue)

type FileUploadProviderProps = {
  children: ReactNode
}

export function FileUploadProvider({ children }: FileUploadProviderProps) {
  const [activeFileUploads, setActiveFileUploads] = useState<
    ActiveFileUpload[]
  >([])

  const registerActiveFileUpload = useCallback(
    (activeFileUpload: ActiveFileUpload) => {
      setActiveFileUploads((prev) => [...prev, activeFileUpload])
    },
    [setActiveFileUploads]
  )

  const unregisterActiveFileUpload = useCallback(
    (tempId: string) => {
      setActiveFileUploads((prev) =>
        prev.filter((activeFileUpload) => activeFileUpload.tempId !== tempId)
      )
    },
    [setActiveFileUploads]
  )

  const trackActiveFileUploadProgress = useCallback(
    (tempId: string, progress: number) => {
      setActiveFileUploads((prev) =>
        prev.map((activeFileUpload) =>
          activeFileUpload.tempId === tempId
            ? {
                ...activeFileUpload,
                progress,
              }
            : activeFileUpload
        )
      )
    },
    [setActiveFileUploads]
  )

  const cancelActiveFileUploads = useCallback(
    (filters: ActiveFileUploadFilters = READONLY_EMPTY_OBJECT) => {
      const filteredActiveFileUploads = activeFileUploads.filter(
        (activeFileUpload) => {
          const keys = Object.keys(filters) as (keyof ActiveFileUploadFilters)[]

          return keys.every((key) => activeFileUpload[key] === filters[key])
        }
      )

      filteredActiveFileUploads.forEach(({ request }) => {
        if (!request) return

        // Abort file upload request if it's ongoing
        if (
          ![XHR_READY_STATE_UNSENT, XHR_READY_STATE_DONE].includes(
            request.readyState
          )
        ) {
          request.abort()
        }
      })

      const filteredActiveFileUploadIds = filteredActiveFileUploads.map(
        ({ tempId }) => tempId
      )

      setActiveFileUploads((prev) =>
        prev.filter(
          ({ tempId }) => !filteredActiveFileUploadIds.includes(tempId)
        )
      )
    },
    [activeFileUploads]
  )

  const value = useMemo(
    () => ({
      activeFileUploads,
      registerActiveFileUpload,
      unregisterActiveFileUpload,
      trackActiveFileUploadProgress,
      cancelActiveFileUploads,
    }),
    [
      activeFileUploads,
      registerActiveFileUpload,
      unregisterActiveFileUpload,
      trackActiveFileUploadProgress,
      cancelActiveFileUploads,
    ]
  )

  return (
    <FileUploadContext.Provider value={value}>
      {children}
    </FileUploadContext.Provider>
  )
}
