import { templateStr } from '@motion/react-core/strings'
import { classed } from '@motion/theme'
import { FormModal } from '@motion/ui/base'
import { type FlowTemplateStage } from '@motion/ui-logic/pm/project'
import { useModalStatus } from '@motion/web-common/modals'
import { type TaskDefinitionSchema } from '@motion/zod/client'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { Label, StageLabel } from '~/global/components/labels'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'remove-template-blockers': PromptCallbacks<boolean> &
      RemoveBlockersModalProps
  }
}

type RemoveBlockersModalProps = {
  blockedByTasks: TaskDefinitionSchema[]
  blockingTasks: TaskDefinitionSchema[]
  startStage?: FlowTemplateStage
  endStage?: FlowTemplateStage
}

export const RemoveBlockersModal = (
  props: ModalTriggerComponentProps<'remove-template-blockers'>
) => {
  const status = useModalStatus('remove-template-blockers')
  if (!status.visible) return null

  const { close, blockedByTasks, blockingTasks, startStage, endStage } = props

  const isChangingStage = startStage != null && endStage != null

  return (
    <FormModal
      onClose={close}
      submitAction={{
        onAction: () => close(true),
        text: 'Move and remove blockers',
      }}
      title={
        isChangingStage
          ? templateStr('Move task from {{startStage}} to {{endStage}}', {
              startStage: <StageLabel value={startStage} />,
              endStage: <StageLabel value={endStage} />,
            })
          : 'Move task'
      }
      visible
    >
      {isChangingStage ? (
        <MoveBetweenStages startStage={startStage} endStage={endStage} />
      ) : (
        <MoveWithinStage
          blockedByTasks={blockedByTasks}
          blockingTasks={blockingTasks}
        />
      )}
    </FormModal>
  )
}

const BodyText = classed('div', {
  base: 'flex h-full w-[450px] flex-col gap-4 text-semantic-neutral-text-default',
})

function MoveWithinStage({
  blockedByTasks,
  blockingTasks,
}: {
  blockedByTasks: TaskDefinitionSchema[]
  blockingTasks: TaskDefinitionSchema[]
}) {
  return (
    <BodyText>
      <p className='text-sm'>
        To move this task, the following blockers must be removed
      </p>
      {blockedByTasks.length === 0 && blockingTasks.length === 0 && (
        <p className='text-sm text-semantic-neutral-text-subtle'>
          <Label
            key='empty'
            value={{ name: '<empty name>', color: 'gray', id: 'empty' }}
          />
        </p>
      )}
      {blockedByTasks.length > 0 && (
        <div className='flex flex-col gap-2'>
          <p className='text-sm text-semantic-neutral-text-subtle'>
            This task is blocked by:
          </p>
          <div className='flex flex-row items-center flex-wrap gap-1'>
            {blockedByTasks.map((task) => (
              <Label
                key={task.id}
                value={{ name: task.name, color: 'gray', id: task.id }}
              />
            ))}
          </div>
        </div>
      )}
      {blockingTasks.length > 0 && (
        <div className='flex flex-col gap-2'>
          <p className='text-sm text-semantic-neutral-text-subtle'>
            This task is blocking:
          </p>
          <div className='flex flex-row items-center flex-wrap gap-1'>
            {blockingTasks.map((task) => (
              <Label
                key={task.id}
                value={{ name: task.name, color: 'gray', id: task.id }}
              />
            ))}
          </div>
        </div>
      )}
    </BodyText>
  )
}

function MoveBetweenStages({
  startStage,
  endStage,
}: Required<Pick<RemoveBlockersModalProps, 'startStage' | 'endStage'>>) {
  return (
    <BodyText className='gap-3 truncate'>
      <strong className='text-sm'>
        For active projects, here’s what will happen to the task:
      </strong>
      <p className='text-sm flex items-center gap-2'>
        {templateStr(
          'If the task has been modified, it will stay in {{startStage}}',
          { startStage: <StageLabel value={startStage} /> }
        )}
      </p>
      <p className='text-sm flex items-center gap-2'>
        {templateStr(
          'If the task hasn’t been modified, it will be moved to {{endStage}}',
          { endStage: <StageLabel value={endStage} /> }
        )}
      </p>
      <p className='text-sm'>
        Moving this task across stages will also remove its blockers.
      </p>
    </BodyText>
  )
}
