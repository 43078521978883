import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
  type OptimisticUpdateValue,
} from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import {
  type ProjectSchema,
  type ProjectsV2UpdateRequestSchema,
} from '@motion/zod/client'

import { type QueryClient } from '@tanstack/react-query'
import {
  applyOptimisticFolderItemDelete,
  applyOptimisticFolderItemUpdates,
} from '~/global/cache'

export function getProjectQueryFilters(projectId: ProjectSchema['id']) {
  return createQueryFilter([
    API.workspacesV2.queryKeys.root,
    MODEL_CACHE_KEY,
    API.projectsV2.queryKeys.byId(projectId),
  ])
}

export async function applyOptimisticProjectUpdates(
  client: QueryClient,
  projectId: string,
  updates: Partial<ProjectSchema> | Partial<ProjectsV2UpdateRequestSchema>
) {
  const rollbacks: (() => void)[] = []

  const { rollback: rollbackProject } = MotionCache.patch(
    client,
    getProjectQueryFilters(projectId),
    'projects',
    {
      [projectId]: updates,
    }
  )

  rollbacks.push(rollbackProject)

  if (
    ('folderId' in updates && updates.folderId) ||
    ('folderItemOrder' in updates && updates.folderItemOrder)
  ) {
    const { rollback: rollbackFolders } =
      await applyOptimisticFolderItemUpdates(
        client,
        projectId,
        {
          parentFolderId: updates.folderId ?? undefined,
          order:
            (updates as ProjectsV2UpdateRequestSchema).folderItemOrder ??
            undefined,
        },
        'PROJECT'
      )

    rollbacks.push(rollbackFolders)
  }

  const rollback = () => void rollbacks.forEach((rollback) => rollback())

  return {
    withRollback<T>(p: Promise<T>) {
      return p.catch((ex) => {
        rollback()
        throw ex
      })
    },
    rollback,
  }
}

export async function applyOptimisticProjectDelete(
  client: QueryClient,
  projectId: ProjectSchema['id']
): Promise<OptimisticUpdateValue> {
  const rollbacks: (() => void)[] = []

  const { rollback: rollbackFolders } = await applyOptimisticFolderItemDelete(
    client,
    projectId,
    'PROJECT'
  )

  rollbacks.push(rollbackFolders)

  const { rollback: rollbackProject } = MotionCache.delete(
    client,
    getProjectQueryFilters(projectId),
    'projects',
    projectId
  )

  rollbacks.push(rollbackProject)

  const rollback = () => void rollbacks.forEach((rollback) => rollback())

  return {
    withRollback<T>(p: Promise<T>) {
      return p.catch((ex) => {
        rollback()
        throw ex
      })
    },
    rollback,
  }
}
