import { ScrollArea } from '@motion/ui/base'
import { useTitle } from '@motion/web-common/html'

import { NameSearchButton } from '~/areas/project-management/components/filters'
import { PageHeader } from '~/areas/project-management/pages/pm-v3/header/connected-header'
import { PageTabs } from '~/areas/project-management/pages/pm-v3/header/page-tabs'
import { usePageData } from '~/areas/project-management/pages/pm-v3/routes'
import { type PMPageUrlParams } from '~/areas/project-management/pages/pm-v3/routes/types'
import { ErrorPage } from '~/global/components/error-page'
import { useState } from 'react'

import { ConnectedBrowseTreeview } from './components'

export const BrowsePage = () => {
  const data = usePageData()
  const pageParams = data.params as PMPageUrlParams

  useTitle(data.title, 'Navigate')

  const [searchValue, setSearchValue] = useState('')

  return (
    <>
      <PageHeader
        page={data.page}
        title={data.title}
        workspaceId={pageParams.workspaceId}
      >
        <PageTabs showBrowseButton>
          <div className='[&>*]:whitespace-nowrap flex items-center gap-1'>
            <NameSearchButton
              value={searchValue}
              onSearch={(search) => setSearchValue(search)}
              placeholder='Search'
            />
          </div>
        </PageTabs>
      </PageHeader>
      <ErrorPage>
        <ScrollArea>
          <ConnectedBrowseTreeview searchValue={searchValue} />
        </ScrollArea>
      </ErrorPage>
    </>
  )
}
