import type { DragProjection } from './components'

export function getProjectedOrder(
  isGhost: boolean | undefined,
  projection: DragProjection | null
) {
  return isGhost && projection
    ? (projection.order ?? 'SERVERGEN').toString()
    : null
}
