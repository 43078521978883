import { getAccountOptions } from '@motion/ui-logic'

import { useEditableCalendars } from '~/areas/calendar/hooks'
import {
  selectEmailAccountsMap,
  selectMainEmailAccountId,
} from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'
import { useMemo } from 'react'

import { useEventForm } from './use-event-form'

export function useIsMainCalendar() {
  const { form } = useEventForm()

  const calendarId = form.watch('calendarId')

  const emailAccountsMap = useAppSelector(selectEmailAccountsMap)
  const mainEmailAccountId = useAppSelector(selectMainEmailAccountId)

  const editableCalendars = useEditableCalendars()
  const accountOptions = useMemo(
    () => getAccountOptions(editableCalendars, emailAccountsMap),
    [editableCalendars, emailAccountsMap]
  )

  return useMemo(() => {
    const accountOption = accountOptions.find(
      (o) => o.calendarId === calendarId
    )

    return accountOption?.emailAccountId === mainEmailAccountId
  }, [accountOptions, calendarId, mainEmailAccountId])
}
