import { AiMeetingNotesGradientSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import {
  Button,
  Text,
  TextHeader,
  Tooltip,
  UnstyledModal,
} from '@motion/ui/base'
import { RadioButton } from '@motion/ui/forms'
import { NOOP_FUNCTION } from '@motion/utils/function'
import { waitForAll } from '@motion/utils/promise'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { logInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'
import { useAuthenticatedUser, type User } from '@motion/web-common/auth'
import { useMyUserSettings } from '@motion/web-common/settings'

import { Highlight } from '~/analytics/highlight'
import { useMainCalendar } from '~/areas/calendar/hooks'
import {
  NotetakerOnboardingDetails,
  RadioButtonContainer,
  RadioGroupContainer,
  RadioSectionContainer,
} from '~/areas/notetaker'
import { YoutubeVideo } from '~/components/Common/YoutubeVideo/YoutubeVideo'
import { useHasSeenCta, useIsNotetakerEnabled } from '~/global/hooks'
import {
  useUpdateMyCtaSettings,
  useUpdateNotetakerSettings,
} from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useExperiment } from '~/localServices/experiments'
import { useEffect, useState } from 'react'

export function NotetakerOnboardingAlert({
  open,
  setOpen = NOOP_FUNCTION,
}: {
  open?: boolean
  setOpen?: (open: boolean) => void
}) {
  const notetakerOnboarding = useExperiment('notetaker-onboarding')
  const isNotetakerEnabled = useIsNotetakerEnabled()
  const { mainCalendar } = useMainCalendar()

  const [botEnabled, setBotEnabled] = useState<boolean | undefined>()
  const [sendRecapToAllAttendees, setSendRecapToAllAttendees] = useState<
    boolean | undefined
  >()

  const [loading, setLoading] = useState(false)
  const { data: userSettings } = useMyUserSettings()
  const { mutateAsync: updateCtaSettings } = useUpdateMyCtaSettings()
  const { mutateAsync: updateNotetakerSettings } = useUpdateNotetakerSettings()
  const user = useAuthenticatedUser()

  const disabled = botEnabled == null || sendRecapToAllAttendees == null

  const hasSeenCta = useHasSeenCta()
  const showCta = hasSeenCta('NOTETAKER_ONBOARDING_ALERT')

  const loadHighlight = (user: User) => {
    try {
      Highlight.identify(user.email, {
        name: user.displayName ?? '',
        uid: user.uid,
      })
      Highlight.track('NOTETAKER_ONBOARDING_ALERT_VIEW')
      Highlight.start()
    } catch (e) {
      logInDev('error starting Highlight', e)
      void e
    }
  }

  useEffect(() => {
    // If the user doesn't have a main calendar, don't show the alert
    if (!mainCalendar) return

    // If the notetaker is not enabled, don't show the alert
    if (!isNotetakerEnabled) return

    if (!showCta.loaded) return

    // If we have logged that the user has seen the release notes, don't show them again
    if (showCta.seen) return

    recordAnalyticsEvent('NOTETAKER_ONBOARDING_MODAL_SHOW')

    setOpen(true)

    loadHighlight(user)
  }, [
    mainCalendar,
    setOpen,
    showCta.loaded,
    showCta.seen,
    userSettings?.notetakerSettings,
    user,
    isNotetakerEnabled,
  ])

  async function onSubmit() {
    setLoading(true)

    try {
      await waitForAll([
        updateCtaSettings({
          ctaSettings: {
            NOTETAKER_ONBOARDING_ALERT: true,
          },
        }),
        updateNotetakerSettings({
          enableBotForAllMeetings: botEnabled,
          sendRecapToAllAttendees,
          triggerCalendarSync: true,
        }),
      ])

      recordAnalyticsEvent('NOTETAKER_ONBOARDING_SETTING', {
        botEnabled,
        sendRecapToAllAttendees,
        type: 'existing-users',
      })
    } catch (error) {
      showErrorToast(
        'Failed to update notetaker settings, proceed with default settings...'
      )
      Sentry.captureException(error, {
        extra: { botEnabled, sendRecapToAllAttendees },
      })
    }

    setLoading(false)
    setOpen(false)
  }

  return (
    <UnstyledModal
      visible={open ?? false}
      withAnimation
      disableEscapeKey
      disableOverlayClick
      onClose={() => setOpen(false)}
      overlayClassName='bg-modal-overlay'
    >
      <Shell>
        <LeftColumn>
          <header className='flex items-center gap-4'>
            <AiMeetingNotesGradientSolid className='size-4.5' />
            <TextHeader size='lg'>
              {NotetakerOnboardingDetails.Title}
            </TextHeader>
          </header>

          <Text size='sm'>{NotetakerOnboardingDetails.Description}</Text>

          <div className='relative w-full aspect-video'>
            {notetakerOnboarding.payload?.videoId && (
              <YoutubeVideo
                videoId={notetakerOnboarding.payload.videoId}
                muteOnStart
                rounded
                skipAutoPlay
                loop
              />
            )}
          </div>
        </LeftColumn>

        <RightColumn>
          <RadioSectionContainer>
            <Text as='h3' weight='semibold' size='sm'>
              {NotetakerOnboardingDetails.Settings.BotEnabled.Title}
            </Text>

            <RadioGroupContainer>
              <RadioButtonContainer>
                <RadioButton
                  label={NotetakerOnboardingDetails.Settings.BotEnabled.Yes}
                  checked={botEnabled === true}
                  onChange={() => setBotEnabled(true)}
                />
              </RadioButtonContainer>

              <RadioButtonContainer>
                <RadioButton
                  label={NotetakerOnboardingDetails.Settings.BotEnabled.No}
                  checked={botEnabled === false}
                  onChange={() => setBotEnabled(false)}
                />
              </RadioButtonContainer>
            </RadioGroupContainer>
          </RadioSectionContainer>

          <RadioSectionContainer>
            <Text as='h3' weight='semibold' size='sm'>
              {
                NotetakerOnboardingDetails.Settings.SendRecapToAllAttendees
                  .Title
              }
            </Text>

            <RadioGroupContainer>
              <RadioButtonContainer>
                <RadioButton
                  label={
                    NotetakerOnboardingDetails.Settings.SendRecapToAllAttendees
                      .Yes
                  }
                  checked={sendRecapToAllAttendees === true}
                  onChange={() => setSendRecapToAllAttendees(true)}
                />
              </RadioButtonContainer>

              <RadioButtonContainer>
                <RadioButton
                  label={
                    NotetakerOnboardingDetails.Settings.SendRecapToAllAttendees
                      .No
                  }
                  checked={sendRecapToAllAttendees === false}
                  onChange={() => setSendRecapToAllAttendees(false)}
                />
              </RadioButtonContainer>
            </RadioGroupContainer>
          </RadioSectionContainer>

          <div className='flex flex-col gap-2'>
            <Text className='text-semantic-neutral-text-subtle' size='xs'>
              This can be changed later in settings.
            </Text>

            <div className='mt-auto self-end'>
              <Tooltip
                content={disabled && 'Please select all options to continue!'}
              >
                <Button
                  loading={loading}
                  onClick={onSubmit}
                  disabled={disabled}
                >
                  Continue
                </Button>
              </Tooltip>
            </div>
          </div>
        </RightColumn>
      </Shell>
    </UnstyledModal>
  )
}

const Shell = classed('div', {
  base: `
    bg-modal-bg
    border border-semantic-neutral-border-default
    rounded-lg
    shadow-lg
    overflow-hidden

    h-[473px]
    w-[calc(100vw-56px)]
    max-w-[1100px]

    grid grid-cols-12
  `,
})

const ColumnBase = classed('div', {
  base: `
    p-6
    flex flex-col gap-4
    overflow-auto
  `,
})

const LeftColumn = classed(ColumnBase, {
  base: `
    col-span-7
    bg-semantic-neutral-surface-raised-bg-subtlest
  `,
})

const RightColumn = classed(ColumnBase, {
  base: `
    col-span-5
    gap-5
  `,
})
