import {
  type ProjectSchema,
  type StageSchema,
  type TaskSchema,
} from '@motion/rpc-types'
import { type DeadlineStatusWithReason } from '@motion/shared/common'
import {
  getExtendedProjectDeadlineStatus,
  getExtendedStageDeadlineStatus,
  getExtendedTaskDeadlineStatus,
} from '@motion/ui-logic'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'

import { useWorkspaceFns } from '~/global/hooks'
import { useMemo } from 'react'

type Args =
  | {
      entity: ProjectSchema | null
      type: 'project'
    }
  | {
      entity: TaskSchema | null
      type: 'task'
    }
  | {
      entity: StageSchema | null
      type: 'stage'
    }

export const useExtendedNoneEta = ({
  entity,
  type,
  options = READONLY_EMPTY_OBJECT,
}: Args & {
  options?: { normalizeToAtRisk?: boolean }
}): DeadlineStatusWithReason => {
  const { getWorkspaceStatuses } = useWorkspaceFns()

  return useMemo(() => {
    if (entity === null) return 'none'

    switch (type) {
      case 'project': {
        const workspaceStatuses = getWorkspaceStatuses(entity.workspaceId)
        return getExtendedProjectDeadlineStatus(entity, workspaceStatuses)
      }
      case 'stage':
        return getExtendedStageDeadlineStatus(entity, options)
      case 'task': {
        const workspaceStatuses = getWorkspaceStatuses(entity.workspaceId)
        return getExtendedTaskDeadlineStatus(entity, workspaceStatuses)
      }
    }
  }, [entity, getWorkspaceStatuses, options, type])
}
