import { templateStr } from '@motion/react-core/strings'

import { createContext, type ReactNode, useContext, useMemo } from 'react'

interface I18NContextValue {
  formatList: (list: Iterable<string>, type?: Intl.ListFormatType) => string
  pluralize: (
    count: number,
    singular: string,
    plural: string
  ) => ReturnType<typeof templateStr>
}

const NOT_IMPLEMENTED = () => {
  throw new Error('Not implemented')
}

const defaultValue: I18NContextValue = {
  formatList: NOT_IMPLEMENTED,
  pluralize: NOT_IMPLEMENTED,
}

const I18NContext = createContext<I18NContextValue>(defaultValue)

type I18NProviderProps = {
  children: ReactNode
}

export function I18NProvider({ children }: I18NProviderProps) {
  const listFormat = useMemo(() => {
    const types: Intl.ListFormatType[] = ['conjunction', 'disjunction', 'unit']
    return types.reduce(
      (acc, type) => {
        acc[type] = new Intl.ListFormat('en-US', {
          style: 'long',
          type,
        })

        return acc
      },
      {} as Record<Intl.ListFormatType, Intl.ListFormat>
    )
  }, [])
  const pluralRules = useMemo(() => {
    return new Intl.PluralRules('en-US')
  }, [])

  const value = useMemo<I18NContextValue>(() => {
    return {
      formatList: (list, type = 'unit') => {
        return listFormat[type].format(list)
      },
      pluralize: (count, singular, plural) => {
        const cardinal = pluralRules.select(count)
        if (cardinal === 'one') {
          return templateStr(singular, { count })
        }
        return templateStr(plural, { count })
      },
    }
  }, [listFormat, pluralRules])

  return <I18NContext.Provider value={value}>{children}</I18NContext.Provider>
}

/* eslint react-refresh/only-export-components: ["warn"] */
export function useI18N() {
  return useContext(I18NContext)
}
