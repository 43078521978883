import { createUseMutation, createUseQuery } from '@motion/rpc'
import { API } from '@motion/rpc-definitions'
import {
  computeSavingsPercent,
  INDIVIDUAL_MONTHLY_PRICE,
  makeBillingPrices,
} from '@motion/ui-logic/billing'
import { setAnalyticsTeamId } from '@motion/web-base/analytics'

import { useEffect, useMemo } from 'react'

import { filterActiveMembers } from '../utils'

export const useCurrentTeam = createUseQuery(API.teams.getCurrentTeam)

export const useSetAnalyticsTeamId = () => {
  const result = useCurrentTeam()
  const { data: team } = result
  const activeMemberCount = useMemo(
    () => filterActiveMembers(team?.members ?? []).length,
    [team]
  )
  useEffect(() => {
    if (!team?.id) {
      return
    }
    setAnalyticsTeamId(
      team.id,
      team.name,
      team.teamSubscription?.bucketSeats ?? activeMemberCount,
      team.teamSubscription?.featurePermissionTierId ?? undefined
    )
  }, [team?.id, team?.name, team?.teamSubscription, activeMemberCount])
  return result
}

export const useRenameTeam = createUseMutation(API.teams.renameTeam)
export const useInviteTeamMembers = createUseMutation(
  API.teams.inviteTeamMembers
)
export const useUpdateBucketSeats = createUseMutation(
  API.teams.updateBucketSeats
)
export const useResubscribeTeam = createUseMutation(API.teams.resubscribe)

export const useGetTeamPrices = createUseQuery(
  API.subscriptions.getTeamPrices,
  {
    select: (data) => {
      const teamPrices = makeBillingPrices(data.monthlyPrice, data.annualPrice)
      return {
        ...teamPrices,
        individualToTeamSavingsPercent: computeSavingsPercent(
          INDIVIDUAL_MONTHLY_PRICE,
          teamPrices.monthlyPrice,
          0
        ),
      }
    },
  }
)

export const useUpdateTeamMemberRoles = createUseMutation(
  API.teams.updateTeamMemberRoles
)

export const useChangeBillingAdmin = createUseMutation(
  API.teams.changeBillingAdmin
)
