import { EyeSolid, SearchSolid } from '@motion/icons'
import { Button, ScrollArea } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { computeSearchScore } from '@motion/ui-logic'
import { byProperty, Compare } from '@motion/utils/array'
import { useHasTreatment } from '@motion/web-common/flags'

import { useSidebarSearchContext } from '~/areas/search/hook'
import type { WorkspacesTreeviewItem } from '~/areas/treeviews/components'
import { useMemo, useState } from 'react'

import { ExpandableSection, SectionHeaderActionButton } from './components'
import { MAX_SIDEBAR_ITEM_COUNT } from './constants'
import { SharedTreeview } from './shared-treeview'
import { useSharedTreeviewItems } from './shared-treeview/hooks'

import { SidebarExpandablePanelHeader } from '../components/sidebar-expandable-panel'
import { useGlobalSidebarContext, useGlobalSidebarState } from '../hooks'

export const SharedSection = () => {
  const { openedPanel, setOpenedPanel } = useGlobalSidebarContext()
  const { sidebarState, toggleSidebarSection } = useGlobalSidebarState()
  const { hasSearch } = useSidebarSearchContext()
  const items = useSharedTreeviewItems()

  const hasNotes = useHasTreatment('notes-in-webapp')
  const hasSidebarOrganization = useHasTreatment('sidebar-organization-control')

  if (!hasNotes || items.length === 0) return null

  return (
    <ExpandableSection
      title='Shared'
      isExpanded={sidebarState.sections.shared || hasSearch}
      shouldRenderPanel={!hasSearch && openedPanel === 'shared'}
      onToggleExpand={(state) => {
        if (hasSearch) return

        toggleSidebarSection('shared', state)
      }}
      onToggleRenderPanel={(shouldOpen) =>
        void setOpenedPanel(shouldOpen ? 'shared' : null)
      }
      renderPanelContent={
        !hasSearch &&
        items.length > MAX_SIDEBAR_ITEM_COUNT &&
        (() => <AllSharedItemsPanel items={items} />)
      }
      renderButton={() =>
        !hasSearch &&
        hasSidebarOrganization && (
          <SectionHeaderActionButton
            icon={EyeSolid}
            sentiment='neutral'
            variant='outlined'
            size='xsmall'
          />
        )
      }
    >
      <SharedTreeview
        items={hasSearch ? items : items.slice(0, MAX_SIDEBAR_ITEM_COUNT)}
      />
    </ExpandableSection>
  )
}

type AllSharedItemsPanelProps = {
  items: WorkspacesTreeviewItem[]
}

const AllSharedItemsPanel = ({ items }: AllSharedItemsPanelProps) => {
  const [search, setSearch] = useState('')

  const filteredItems = useMemo(
    () =>
      items
        .reduce<
          {
            item: AllSharedItemsPanelProps['items'][0]
            score: number
          }[]
        >((acc, item) => {
          const score = computeSearchScore(item.label, search)

          if (!search || score > 0) {
            return [...acc, { item, score }]
          }

          return acc
        }, [])
        .sort(byProperty('score', Compare.numeric.desc))
        .map(({ item }) => item),
    [items, search]
  )

  return (
    <div className='w-full flex flex-col'>
      <div className='p-4 space-y-4'>
        <SidebarExpandablePanelHeader title='Shared' />

        <div>
          <TextField
            size='normal'
            rounded='default'
            prefix={<SearchSolid />}
            placeholder='Search shared...'
            value={search}
            onChange={setSearch}
            autoFocus
            showClearButton
          />
        </div>
      </div>

      <ScrollArea className='w-full'>
        <SharedTreeview items={filteredItems} />

        {filteredItems.length === 0 && (
          <div className='text-sm px-4'>
            <p className='font-semibold mb-1'>No results</p>
            <p className='mb-3'>Try a different search term.</p>
            <Button
              size='xsmall'
              sentiment='neutral'
              onClick={() => void setSearch('')}
            >
              Clear search
            </Button>
          </div>
        )}
      </ScrollArea>
    </div>
  )
}
