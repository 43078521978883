import { EXPECTED_DURATION_UNIT_DROPDOWN_ITEMS } from '@motion/shared/flows'
import { PopoverButton } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { ExpectedDurationUnitDropdown } from '~/areas/flows/components'
import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { useController } from 'react-hook-form'

export const ExpectedDurationUnitSelector = ({
  durationPath,
}: {
  durationPath: `stages.${number}.duration`
}) => {
  const {
    form: { control },
  } = useFlowTemplateForm()

  const durationUnitPath = `${durationPath}.unit` as const

  const { field: expectedDurationUnit } = useController({
    name: durationUnitPath,
    control,
  })

  const selectedItem =
    EXPECTED_DURATION_UNIT_DROPDOWN_ITEMS.find(
      (item) => item.id === expectedDurationUnit.value
    ) ?? EXPECTED_DURATION_UNIT_DROPDOWN_ITEMS[0]

  return (
    <ExpectedDurationUnitDropdown
      selectedItem={selectedItem}
      onChange={(item) => {
        expectedDurationUnit.onChange(item.id)
        recordAnalyticsEvent('FLOW_TEMPLATE_STAGE_DURATION_OPTION_SELECTED', {
          option: item.id,
        })
      }}
    >
      <PopoverButton className='w-min h-6'>
        <span className='text-semantic-neutral-text-default'>
          {selectedItem.label}
        </span>
      </PopoverButton>
    </ExpectedDurationUnitDropdown>
  )
}
