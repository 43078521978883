import { QuestionMarkCircleSolid } from '@motion/icons'
import { Toggle, Tooltip } from '@motion/ui/base'

import { ModalFieldButton } from '~/areas/task-project/components'
import { useController } from 'react-hook-form'

import { useTaskForm } from '../hooks'

export const WarnOnField = () => {
  const { form, disabledFields } = useTaskForm()

  const { control, watch } = form

  const { field } = useController({
    name: 'ignoreWarnOnPastDue',
    control,
  })

  const ignoreOnPastDue = watch('ignoreWarnOnPastDue')
  const disabled = disabledFields.has('ignoreWarnOnPastDue')

  const onChange = (value: boolean) => {
    field.onChange(value)
  }

  return (
    <ModalFieldButton
      label='Warn if past due'
      size='normal'
      variant='subtle'
      disabled={disabled}
    >
      <div className='flex items-center gap-1'>
        <Tooltip
          asChild
          content={
            disabled
              ? undefined
              : `We'll notify you if this task is scheduled after the deadline.`
          }
        >
          <QuestionMarkCircleSolid />
        </Tooltip>
        <Toggle
          checked={!ignoreOnPastDue}
          disabled={disabled}
          size='small'
          onChange={(e) => {
            onChange(!e.target.checked)
          }}
        />
      </div>
    </ModalFieldButton>
  )
}
