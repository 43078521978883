import { AutoscheduleStarGradientSolid } from '@motion/icons'
import { Text } from '@motion/ui/base'

import { Outlet } from 'react-router'

import { Header, WorkflowTabs } from '../components'

export function WorkflowsShell() {
  return (
    <>
      <Header>
        <AutoscheduleStarGradientSolid
          className='size-4 shrink-0'
          stopColors={['#839AE5', '#3D61DD']}
        />
        <Text as='h1' weight='semibold' truncate>
          AI Workflows
        </Text>
      </Header>
      <WorkflowTabs />
      <Outlet />
    </>
  )
}
