import { ExternalLinkSolid } from '@motion/icons'
import { Button, PopoverButton } from '@motion/ui/base'
import { isNotetakerCompleted } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import {
  NoteLocationDropdown,
  NoteLocationLabel,
} from '~/areas/notes/components'
import { useUriByRouteId } from '~/routing'
import { useCallback } from 'react'

import { useEventModalState } from '../../contexts'

export function NotetakerFolderField() {
  const { meetingInsights, close, recurringMeetingInsights } =
    useEventModalState()

  // Use the parent (recurring) note first if it exists for the folder field
  const folderFieldMeetingNoteId =
    recurringMeetingInsights?.noteId ?? meetingInsights?.noteId

  const linkFieldMeetingNoteId =
    meetingInsights?.noteId ?? recurringMeetingInsights?.noteId

  const getRouteUri = useUriByRouteId()

  const handleChange = useCallback(() => {
    recordAnalyticsEvent('NOTETAKER_FOLDER_EVENT_MODAL_CHANGE')
  }, [])

  const handleMeetingNoteClick = useCallback(() => {
    recordAnalyticsEvent('NOTETAKER_DOC_EVENT_MODAL_CLICK', {
      type: 'folder-field',
    })
    close()
  }, [close])

  if (
    folderFieldMeetingNoteId == null ||
    linkFieldMeetingNoteId == null ||
    isNotetakerCompleted(meetingInsights)
  ) {
    return null
  }

  const meetingNoteUrl = getRouteUri('notes-detail', {
    noteId: linkFieldMeetingNoteId,
  })

  return (
    <div className='flex flex-col mt-2 gap-1'>
      <label
        htmlFor='notes-location-button'
        className='text-2xs text-semantic-neutral-text-subtle flex flex-col gap-1'
      >
        <p className='flex items-center justify-between gap-1'>
          <span>Save notes to</span>

          <Button
            size='xsmall'
            url={meetingNoteUrl}
            variant='link'
            sentiment='neutral'
            onClick={handleMeetingNoteClick}
          >
            <ExternalLinkSolid />
            Meeting Note
          </Button>
        </p>

        <NoteLocationDropdown
          noteId={folderFieldMeetingNoteId}
          onChange={handleChange}
        >
          <PopoverButton
            aria-label='Meeting notes location'
            size='small'
            id='notes-location-button'
          >
            <span className='flex truncate grow'>
              <NoteLocationLabel noteId={folderFieldMeetingNoteId} />
            </span>
          </PopoverButton>
        </NoteLocationDropdown>
      </label>
    </div>
  )
}
