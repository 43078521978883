import { isNoneId } from '@motion/shared/identifiers'
import { getProjectStartDateChangedFields } from '@motion/ui-logic/pm/project'
import { parseDate } from '@motion/utils/dates'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type ProjectSchema } from '@motion/zod/client'

import { useIsFlowProject } from '~/areas/flows'
import { useCallback } from 'react'

import { useProjectUpdater } from './use-project-updater'

import { useShiftProjectDates } from '../actions'

type UpdateProjectStartDateOpts = {
  dateAdjustmentStrategy?: 'ABSORB' | 'DISTRIBUTE'
  skipConfirmModal?: boolean
}

export function useProjectStartDateUpdater() {
  const updateProject = useProjectUpdater()
  const shiftProjectDates = useShiftProjectDates()
  const modalApi = useModalApi()
  const isFlowProject = useIsFlowProject()

  return useCallback(
    async (
      project: ProjectSchema,
      newStartDate: ProjectSchema['startDate'],
      opts?: UpdateProjectStartDateOpts
    ) => {
      if (newStartDate === project.startDate) return true

      if (
        newStartDate == null ||
        project.startDate == null ||
        isNoneId(project.id)
      ) {
        const updates = {
          startDate: newStartDate,
          ...getProjectStartDateChangedFields({
            ...project,
            startDate: newStartDate,
          }),
        }
        await updateProject(project, updates)
        return true
      }

      const numDays = parseDate(newStartDate).diff(
        parseDate(project.startDate),
        'days'
      ).days

      if (isFlowProject(project)) {
        let dateAdjustmentStrategy: 'ABSORB' | 'DISTRIBUTE' =
          opts?.dateAdjustmentStrategy ?? 'ABSORB'

        if (!opts?.skipConfirmModal) {
          const response = await modalApi.prompt('update-project-date', {
            projectId: project.id,
            newDate: newStartDate,
            dateType: 'start',
          })

          if (response === ModalDismissed) {
            return false
          }

          if (response.dateAdjustmentStrategy === 'SHIFT') {
            recordAnalyticsEvent('CHANGE_PROJECT_DATES', {
              type: 'start',
              behavior: 'move',
            })
            return shiftProjectDates({ projectId: project.id, numDays })
          }

          dateAdjustmentStrategy = response.dateAdjustmentStrategy
        }

        recordAnalyticsEvent('CHANGE_PROJECT_DATES', {
          type: 'start',
          behavior:
            dateAdjustmentStrategy === 'ABSORB'
              ? 'resize_stage'
              : 'resize_proportional',
        })

        await updateProject(project, {
          startDate: newStartDate,
          ...getProjectStartDateChangedFields({
            ...project,
            startDate: newStartDate,
          }),
          options: {
            dateAdjustmentStrategy,
          },
        })
        return true
      }

      return shiftProjectDates({ projectId: project.id, numDays })
    },
    [isFlowProject, shiftProjectDates, updateProject, modalApi]
  )
}
