import { Text } from '@motion/ui/base'

import { type ReactNode } from 'react'

export type WorkflowSectionProps = {
  title: ReactNode
  description: ReactNode
  children: ReactNode
}

export function WorkflowSection({
  title,
  description,
  children,
}: WorkflowSectionProps) {
  return (
    <section className='flex flex-col gap-4 items-start'>
      <div className='flex flex-col gap-2'>
        <Text weight='semibold' size='md'>
          {title}
        </Text>
        <Text size='sm'>{description}</Text>
      </div>
      <div className='w-full'>{children}</div>
    </section>
  )
}
