import { Tooltip } from '@motion/ui/base'
import { isOneOf } from '@motion/utils/array'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useSidebarSearchContext } from '~/areas/search/hook'
import {
  type DragProjection,
  type SortableItemData,
  type SortableWorkspacesTreeviewItem,
  TreeviewItemActions,
  TreeviewItemContextMenu,
  TreeviewItemIcon,
  useMoveTreeviewItem,
} from '~/areas/treeviews/components'
import { getProjectedOrder } from '~/areas/treeviews/utils'
import { memo, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useGlobalSidebarContext } from '../../../hooks'
import {
  SidebarTreeviewItem,
  type SidebarTreeviewItemProps,
} from '../../components'

export type ConnectedWorkspacesTreeviewItemProps = {
  item: SortableWorkspacesTreeviewItem
  level: number
  projection: DragProjection<SortableWorkspacesTreeviewItem> | null
  isInFavorites?: boolean
} & Pick<SidebarTreeviewItemProps, 'onNavigate' | 'disableDrag' | 'isGhost'>

export const ConnectedWorkspacesTreeviewItem = memo(
  function ConnectedWorkspacesTreeviewItem({
    item: flattenedItem,
    level,
    projection,
    onNavigate,
    disableDrag,
    isGhost,
    isInFavorites = false,
  }: ConnectedWorkspacesTreeviewItemProps) {
    const { canMoveDown, canMoveUp, id, item, parentId, parentType, type } =
      flattenedItem

    const { highlightedId } = useGlobalSidebarContext()
    const { hasSearch } = useSidebarSearchContext()

    const { updateFolderState } = useGlobalSidebarContext()
    const isActive = useIsSidebarItemActive(flattenedItem, { isInFavorites })
    const handleMoveItem = useMoveTreeviewItem(flattenedItem, { isInFavorites })

    const projectedOrder = getProjectedOrder(isGhost, projection)
    const isWorkspace = isOneOf(item.type, [
      'INDIVIDUAL_WORKSPACE',
      'TEAM_WORKSPACE',
    ])

    const childCount =
      item.itemCount - Number(item.type === 'NOTE' || item.type === 'PROJECT')

    const sortableData: SortableItemData = {
      item,
      level,
      order: projectedOrder ?? item.order,
      parentId,
      parentType,
      type,
    }

    const moveActionAllowed = disableDrag
      ? undefined
      : {
          up: canMoveUp && !hasSearch,
          down: canMoveDown && !hasSearch,
        }

    const handleToggleExpand = (expanded: boolean) => {
      if (!item.isContainer) return

      recordAnalyticsEvent('FOLDERS_TOGGLED_SIDEBAR_VISIBILITY', {
        itemType: isWorkspace ? 'WORKSPACE' : 'FOLDER',
        state: expanded ? 'EXPANDED' : 'COLLAPSED',
      })

      if (isOneOf(type, ['WORKSPACE', 'FOLDER'])) {
        updateFolderState({
          folderId: item.itemId,
          expanded,
        })
      } else {
        updateFolderState({
          folderItemId: item.id,
          expanded,
        })
      }
    }

    return (
      <SidebarTreeviewItem
        sortableData={sortableData}
        disableDrag={disableDrag}
        icon={(p) => <TreeviewItemIcon item={item} {...p} />}
        isActive={isActive}
        isGhost={isGhost}
        isHighlighted={highlightedId === item.id}
        isInside={projection ? projection.parentId === id : false}
        label={
          <Label
            label={item.label}
            childCount={item.isContainer ? childCount : undefined}
          />
        }
        onNavigate={onNavigate}
        onToggleExpand={handleToggleExpand}
        renderButtons={() => (
          <TreeviewItemActions
            item={item}
            moveActionAllowed={moveActionAllowed}
            onMoveItem={handleMoveItem}
            level={level}
          />
        )}
        renderContextMenuContent={({ close }) => (
          <TreeviewItemContextMenu
            close={close}
            item={item}
            moveActionAllowed={moveActionAllowed}
            onMoveItem={handleMoveItem}
            level={level}
          />
        )}
        tooltip={item.tooltip}
      />
    )
  }
)

type UseIsSidebarItemActiveOptions = {
  isInFavorites: boolean
}

function useIsSidebarItemActive(
  treeviewItem: ConnectedWorkspacesTreeviewItemProps['item'],
  { isInFavorites }: UseIsSidebarItemActiveOptions
): boolean {
  const { item } = treeviewItem

  const {
    workspaceId = null,
    folderId = null,
    projectId = null,
    noteId = null,
    viewId = null,
  } = useParams<{
    workspaceId?: string
    folderId?: string
    projectId?: string
    noteId?: string
    viewId?: string
  }>()

  const workspaceMatches = workspaceId === treeviewItem.item.workspaceId
  const folderMatches = folderId === treeviewItem.item.itemId
  const projectMatches = projectId === treeviewItem.item.itemId
  const noteMatches = noteId === treeviewItem.item.itemId
  const viewMatches = viewId === treeviewItem.item.itemId

  switch (item.type) {
    case 'INDIVIDUAL_WORKSPACE':
    case 'TEAM_WORKSPACE':
      const isWorkspaceActive = workspaceMatches && !folderId && !projectId

      if (isInFavorites) {
        return isWorkspaceActive && !viewId
      }

      return isWorkspaceActive
    case 'FOLDER':
      const isFolderActive = workspaceMatches && folderMatches && !projectId

      if (isInFavorites) {
        return isFolderActive && !viewId
      }

      return isFolderActive
    case 'PROJECT':
      return workspaceMatches && projectMatches
    case 'NOTE':
      return noteMatches
    case 'VIEW':
      return (
        viewMatches &&
        item.workspaceId === workspaceId &&
        item.folderId === folderId &&
        item.projectId === projectId
      )
    default:
      return false
  }
}

type LabelProps = {
  label: string
  childCount?: number
}

const Label = ({ label, childCount }: LabelProps) => {
  const [isTruncated, setIsTruncated] = useState(false)
  const labelRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if (!labelRef.current) return

    const ref = labelRef.current

    const resizeObserver = new ResizeObserver(() => {
      setIsTruncated(ref.offsetWidth < ref.scrollWidth)
    })

    resizeObserver.observe(ref)

    return () => void resizeObserver.disconnect()
  }, [])

  return (
    <div className='flex h-full items-center gap-x-1.5'>
      <Tooltip
        renderContent={() => (isTruncated ? label : null)}
        placement='top-start'
        asChild
      >
        <span className='truncate' ref={labelRef}>
          {label}
        </span>
      </Tooltip>

      {typeof childCount === 'number' && (
        <span className='text-[10px] font-light text-semantic-neutral-text-disabled'>
          {childCount}
        </span>
      )}
    </div>
  )
}
