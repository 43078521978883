import { useFeatureLimit, useShouldFeatureGate } from '@motion/web-billing'
import { useModalApi } from '@motion/web-common/modals'

import { useFeedById } from '~/global/rpc/v2'
import { useMemo } from 'react'

import { type ActivityFeedType, feedTypeToRpcType } from '../types'

export function useActivityFeed(
  type: ActivityFeedType['type'],
  targetId: string
) {
  const rpcType = feedTypeToRpcType[type]

  const { data } = useFeedById(
    {
      type: rpcType,
      id: targetId,
    },
    {
      refetchInterval: false,
      // When activity feed is mounted, always refetch the data, even if it's already in the cache
      // Once mounted, WS events will keep the data up to date
      refetchOnMount: 'always',
    }
  )

  const v2Activities = data?.feed
  const activitiesMemo = useMemo(() => v2Activities ?? [], [v2Activities])
  const hasActivityOutsideWindow = !!data?.hasActivityOutsideWindow

  return {
    activityFeed: activitiesMemo,
    hasActivityOutsideWindow,
    ...useActivityFeedGating(),
  } as const
}

export const useActivityFeedGating = () => {
  const shouldFeatureGate = useShouldFeatureGate()
  const activityLimit = useFeatureLimit('projectAndTaskActivity')
  const activityWindow = shouldFeatureGate ? activityLimit : -1

  const modalApi = useModalApi()
  const onActivityFeatureGated = () => {
    modalApi.open('tier-upgrade-prompt-modal', {
      tier: activityWindow > 0 ? 'ENTERPRISE' : 'PRO',
      feature: 'projectAndTaskActivity',
    })
  }

  return { activityWindow, onActivityFeatureGated }
}
