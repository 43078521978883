import {
  type ApiTypes,
  createUseMutation,
  HttpError,
  useQueryOptionsFactory,
} from '@motion/rpc'
import {
  createQueryFilter,
  getCacheEntry,
  getCacheEntryValue,
  getModelCache,
  MODEL_CACHE_KEY,
  MotionCache,
} from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import {
  type MeetingInsightsSchema,
  type RecurringMeetingInsightsSchema,
} from '@motion/rpc-types'
import { showToast } from '@motion/ui/base'
import { parseDate } from '@motion/utils/dates'
import { values } from '@motion/utils/object'
import { Sentry } from '@motion/web-base/sentry'
import { client } from '@motion/web-common/rpc'

import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { showErrorToast } from '~/global/toasts'

export const notetakerQueryFilters = createQueryFilter([
  API.notetaker.queryKeys.root,
  MODEL_CACHE_KEY,
])

type QueryApi = ApiTypes<typeof API.notetaker.queryMeetingInsights>
export const useMeetingInsightsQuery = (
  args: QueryApi['args'],
  opts?: QueryApi['UseQueryOptions']
) => {
  const queryOptions = useQueryOptionsFactory(
    API.notetaker.queryMeetingInsights
  )
  const queryArgs = queryOptions(args, opts as any)

  return useQuery({
    notifyOnChangeProps: ['error', 'data', 'dataUpdatedAt'],
    ...queryArgs,
  })
}

type GetMeetingInsightsApi = ApiTypes<
  typeof API.notetaker.getMeetingInsightsById
>
type GetMeetingInsightsResult = {
  botEnabled: boolean
  sendRecapToAllAttendees: boolean
  noteId: string | null
  meetingInsights: MeetingInsightsSchema | null
  recurringMeetingInsights: RecurringMeetingInsightsSchema | null
}

export const useGetMeetingInsightsQuery = (
  args: GetMeetingInsightsApi['args'],
  opts?: GetMeetingInsightsApi['UseQueryOptions']
) => {
  const queryOptions = useQueryOptionsFactory(
    API.notetaker.getMeetingInsightsById
  )
  const queryArgs = queryOptions(args, opts as any)
  const modelCache = getModelCache(client)

  const cachedMeetingInsights =
    args?.meetingInsightsId != null
      ? getCacheEntry(client, 'meetingInsights', args?.meetingInsightsId)
      : null
  const cachedRecurringMeetingInsights =
    args?.recurringMeetingInsightsId != null
      ? getCacheEntry(
          client,
          'recurringMeetingInsights',
          args?.recurringMeetingInsightsId
        )
      : null
  const cachedActionItems =
    cachedMeetingInsights != null
      ? values(modelCache.meetingActionItems).filter(
          ({ value }) =>
            value.meetingInsightsId === cachedMeetingInsights.value.id
        )
      : []

  const noteId =
    cachedMeetingInsights?.value.noteId ??
    cachedRecurringMeetingInsights?.value.noteId
  const cachedNote =
    noteId != null ? getCacheEntryValue(client, 'notes', noteId) : null

  const hasData =
    args?.meetingInsightsId != null
      ? args?.meetingInsightsId != null && cachedMeetingInsights != null
      : args?.recurringMeetingInsightsId != null &&
        cachedRecurringMeetingInsights != null

  const initialData: GetMeetingInsightsApi['data'] | undefined = hasData
    ? {
        id: args?.meetingInsightsId,
        meta: {
          model: 'meetingInsights',
        },
        models: {
          meetingActionItems: cachedActionItems.reduce(
            (acc, { value }) => ({
              ...acc,
              [value.id]: value,
            }),
            {}
          ),
          notes: cachedNote ? { [cachedNote.id]: cachedNote } : {},
          meetingInsights: cachedMeetingInsights
            ? { [cachedMeetingInsights.value.id]: cachedMeetingInsights.value }
            : {},
          recurringMeetingInsights: cachedRecurringMeetingInsights
            ? {
                [cachedRecurringMeetingInsights.value.id]:
                  cachedRecurringMeetingInsights.value,
              }
            : {},
        },
      }
    : undefined

  return useQuery({
    ...queryArgs,
    notifyOnChangeProps: ['error', 'data', 'dataUpdatedAt'],
    initialData,
    initialDataUpdatedAt: hasData
      ? (cachedMeetingInsights ?? cachedRecurringMeetingInsights)?.updatedAt
      : undefined,
    placeholderData: keepPreviousData,
    select(data): GetMeetingInsightsResult {
      const recurringMeetingInsights = values(
        data.models.recurringMeetingInsights
      )[0]
      const meetingInsights =
        data.id != null ? data.models.meetingInsights[data.id] : null

      return {
        botEnabled:
          meetingInsights != null
            ? meetingInsights?.meetingBotStatus !== 'DONT_SCHEDULE'
            : recurringMeetingInsights?.botEnabled,
        sendRecapToAllAttendees:
          meetingInsights?.sendRecapToAllAttendees ??
          recurringMeetingInsights?.sendRecapToAllAttendees ??
          false,
        noteId: noteId ?? null,
        meetingInsights,
        recurringMeetingInsights,
      }
    },
  })
}

const useUpdateMeetingInsightsMutation = createUseMutation(
  API.notetaker.updateMeetingInsights
)
export const useUpdateMeetingInsights = () => {
  return useUpdateMeetingInsightsMutation({
    onSuccess: (data) => {
      MotionCache.upsert(client, notetakerQueryFilters, data)

      showToast('success', 'AI Notetaker settings updated')
    },
    onMutate: (data) => {
      const cache = getModelCache(client)

      // Upsert all meeting insights
      const meetingInsights = values(cache.meetingInsights)
      const cachedMeetingInsights = getCacheEntryValue(
        client,
        'meetingInsights',
        data.meetingInsightsId
      )

      if (cachedMeetingInsights == null) {
        return
      }

      const newMeetingInsightsData: Partial<MeetingInsightsSchema> = {}

      if (data.botEnabled != null) {
        newMeetingInsightsData.meetingBotStatus = data.botEnabled
          ? 'NEEDS_SCHEDULING'
          : 'DONT_SCHEDULE'
      }

      if (data.sendRecapToAllAttendees != null) {
        newMeetingInsightsData.sendRecapToAllAttendees =
          data.sendRecapToAllAttendees
      }

      let cacheUpdate: ReturnType<typeof MotionCache.patch> | undefined

      if (data.updateAll) {
        cacheUpdate = MotionCache.patch(
          client,
          notetakerQueryFilters,
          'meetingInsights',
          meetingInsights.reduce((acc, { value }) => {
            if (
              value.parentId === cachedMeetingInsights.parentId &&
              value.meetingBotStatus !== 'COMPLETED' &&
              parseDate(value.startTime).valueOf() >
                parseDate(cachedMeetingInsights.startTime).valueOf()
            ) {
              return {
                ...acc,
                [value.id]: {
                  ...value,
                  ...newMeetingInsightsData,
                },
              }
            }

            return acc
          }, {})
        )
      } else {
        cacheUpdate = MotionCache.patch(
          client,
          notetakerQueryFilters,
          'meetingInsights',
          {
            [cachedMeetingInsights.id]: {
              ...cachedMeetingInsights,
              ...newMeetingInsightsData,
            },
          }
        )
      }

      return cacheUpdate
    },
    onError: (error, _, ctx) => {
      // Rollback all cache updates
      const cacheUpdate = ctx as
        | ReturnType<typeof MotionCache.patch>
        | undefined
      cacheUpdate?.rollback()

      Sentry.captureException(error)
      showErrorToast('Failed to update meeting insights')
    },
  })
}

const useUpdateRecurringMeetingInsightsMutation = createUseMutation(
  API.notetaker.updateRecurringMeetingInsights
)
export const useUpdateRecurringMeetingInsights = () => {
  return useUpdateRecurringMeetingInsightsMutation({
    onSuccess: (data) => {
      MotionCache.upsert(client, notetakerQueryFilters, data)

      showToast('success', 'AI Notetaker recurring settings updated')
    },
    onMutate: (data) => {
      const cachedRecurringMeetingInsights = getCacheEntryValue(
        client,
        'recurringMeetingInsights',
        data.recurringMeetingInsightsId
      )

      if (cachedRecurringMeetingInsights == null) {
        return
      }

      const updates = MotionCache.patch(
        client,
        notetakerQueryFilters,
        'recurringMeetingInsights',
        {
          recurringMeetingInsights: {
            [cachedRecurringMeetingInsights.id]: {
              ...cachedRecurringMeetingInsights,
              botEnabled: data.botEnabled,
              sendRecapToAllAttendees: data.sendRecapToAllAttendees,
            },
          },
        }
      )

      return updates
    },
    onError: (error, _, ctx) => {
      // Rollback all cache updates
      const cacheUpdates = ctx as ReturnType<typeof MotionCache.patch>
      cacheUpdates.rollback()

      Sentry.captureException(error)
      showErrorToast('Failed to update recurring meeting insights')
    },
  })
}

const useApproveActionItemMutation = createUseMutation(
  API.notetaker.approveActionItem
)
export const useApproveActionItem = () => {
  return useApproveActionItemMutation({
    onSuccess: (data) => {
      MotionCache.upsert(client, notetakerQueryFilters, data)
    },
    onError: (error) => {
      Sentry.captureException(error)

      if (error instanceof HttpError) {
        if (error.status === 400) {
          showErrorToast(error.message)
          return
        }
      }

      showErrorToast('Failed to approve action item')
    },
  })
}

const useRejectActionItemMutation = createUseMutation(
  API.notetaker.rejectActionItem
)
export const useRejectActionItem = () => {
  return useRejectActionItemMutation({
    onSuccess: (data) => {
      MotionCache.upsert(client, notetakerQueryFilters, data)
    },
    onError: (error) => {
      Sentry.captureException(error)
      showErrorToast('Failed to reject action item')
    },
  })
}

const useUndoRejectedActionItemMutation = createUseMutation(
  API.notetaker.undoRejectedActionItem
)
export const useUndoRejectedActionItem = () => {
  return useUndoRejectedActionItemMutation({
    onSuccess: (data) => {
      MotionCache.upsert(client, notetakerQueryFilters, data)
    },
    onError: (error) => {
      Sentry.captureException(error)
      showErrorToast('Failed to undo rejected action item')
    },
  })
}

// The backend might eventually return the new meeting bot status/sub status, so we need to update the cache when that happens
const useSendBotNowMutation = createUseMutation(API.notetaker.sendBotNow)
export const useSendBotNow = () => {
  return useSendBotNowMutation({
    onError: (error) => {
      if (error instanceof HttpError) {
        // 400 indicates that it is a known error, so we can just show a toast
        if (error.status === 400) {
          showErrorToast(error.message)
          return
        }
      }

      Sentry.captureException(error)
      showErrorToast(error.message)
    },
  })
}

const useKickBotMutation = createUseMutation(API.notetaker.kickOffBot)
export const useKickBot = () => {
  return useKickBotMutation({
    onError: (error) => {
      Sentry.captureException(error)
      showErrorToast(error.message)
    },
    onMutate: () => {
      showToast('success', 'Requesting bot to leave meeting...')
    },
  })
}

type ActionItemsQueryApi = ApiTypes<typeof API.notetaker.queryActionItems>

export const useActionItemsQuery = (
  args: ActionItemsQueryApi['args'],
  opts?: ActionItemsQueryApi['UseQueryOptions']
) => {
  const queryOptions = useQueryOptionsFactory(API.notetaker.queryActionItems)
  const queryArgs = queryOptions(args, opts as any)

  return useQuery({
    ...queryArgs,
    notifyOnChangeProps: ['error', 'data', 'dataUpdatedAt'],
    select: (data) => {
      return data.ids.map((id) => data.models.meetingActionItems[id])
    },
  })
}
