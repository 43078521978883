import { SearchSolid, StarOutline } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { Button, ScrollArea } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { computeSearchScore } from '@motion/ui-logic'
import { byProperty, Compare } from '@motion/utils/array'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'

import { useSidebarSearchContext } from '~/areas/search/hook'
import { ItemCountDropdown } from '~/areas/sidebar/components'
import { type WorkspacesTreeviewItem } from '~/areas/treeviews/components'
import { useEffect, useMemo, useState } from 'react'

import { ExpandableSection } from './components'
import { FavoritesTreeview } from './favorites-treeview'
import {
  useFavoritesTreeviewItems,
  useFavoritesTreeviewSettings,
} from './favorites-treeview/hooks'

import { OnboardingDocPopover } from '../components/onboarding-doc-popover'
import { SidebarExpandablePanelHeader } from '../components/sidebar-expandable-panel'
import { useGlobalSidebarContext, useGlobalSidebarState } from '../hooks'

export const FavoritesSection = () => {
  const { openedPanel, setOpenedPanel } = useGlobalSidebarContext()
  const { sidebarState, toggleSidebarSection } = useGlobalSidebarState()
  const { hasSearch } = useSidebarSearchContext()
  const items = useFavoritesTreeviewItems()
  const hasNotes = useHasTreatment('notes-in-webapp')
  const hasSidebarOrganization = useHasTreatment('sidebar-organization-control')
  const { maxRenderedFavorites, setMaxRenderedFavorites } =
    useFavoritesTreeviewSettings()

  useEffect(() => {
    if (items.length <= maxRenderedFavorites && openedPanel === 'favorites') {
      setOpenedPanel(null)
    }
  }, [items.length, openedPanel, maxRenderedFavorites, setOpenedPanel])

  if (hasSearch && items.length === 0) return null

  return (
    <ExpandableSection
      title='Favorites'
      isExpanded={sidebarState.sections.favorites || hasSearch}
      shouldRenderPanel={!hasSearch && openedPanel === 'favorites'}
      onToggleExpand={(state) => {
        if (hasSearch) return

        toggleSidebarSection('favorites', state)
      }}
      onToggleRenderPanel={(shouldOpen) =>
        void setOpenedPanel(shouldOpen ? 'favorites' : null)
      }
      renderPanelContent={
        !hasSearch &&
        items.length > maxRenderedFavorites &&
        (() => <AllFavoritesPanel items={items} />)
      }
      renderButton={() =>
        !hasSearch &&
        hasSidebarOrganization && (
          <ItemCountDropdown
            selectedCount={maxRenderedFavorites}
            onChange={setMaxRenderedFavorites}
          />
        )
      }
      moreLabel={templateStr('{{ count }} more', {
        count: items.length - maxRenderedFavorites,
      })}
    >
      {items.length > 0 && <OnboardingDocPopover />}

      {!hasSearch && !items.length && (
        <div className='flex gap-1.5 text-xs text-sidebar-item-text-default px-4 mt-1'>
          <StarOutline className='size-4 shrink-0' />
          <div>
            <span>Right click a project, view</span>
            {hasNotes && <span>, doc</span>}
            <span>, or folder then click “Add to Favorites”</span>
          </div>
        </div>
      )}

      <FavoritesTreeview
        items={hasSearch ? items : items.slice(0, maxRenderedFavorites)}
        disableDrag={hasSearch || items.length > maxRenderedFavorites}
        onReorderItem={() => {
          recordAnalyticsEvent('FOLDERS_DRAGGED_SIDEBAR_ITEM', {
            itemType: 'FAVORITE',
          })
        }}
      />
    </ExpandableSection>
  )
}

type AllFavoritesPanelProps = {
  items: WorkspacesTreeviewItem[]
}

const AllFavoritesPanel = ({ items }: AllFavoritesPanelProps) => {
  const { hasSearch: hasSidebarSearch } = useSidebarSearchContext()
  const [search, setSearch] = useState('')

  const filteredItems = useMemo(
    () =>
      items
        .reduce<
          {
            item: WorkspacesTreeviewItem
            score: number
          }[]
        >((acc, item) => {
          const score = computeSearchScore(item.label, search)

          if (!search || score > 0) {
            return [...acc, { item, score }]
          }

          return acc
        }, [])
        .sort(byProperty('score', Compare.numeric.desc))
        .map(({ item }) => item),
    [items, search]
  )

  return (
    <div className='w-full flex flex-col'>
      <div className='p-4 space-y-4'>
        <SidebarExpandablePanelHeader title='Favorites' />

        <div>
          <TextField
            size='normal'
            rounded='default'
            prefix={<SearchSolid />}
            placeholder='Search favorites...'
            value={search}
            onChange={setSearch}
            autoFocus
            showClearButton
          />
        </div>
      </div>

      <ScrollArea className='w-full'>
        <FavoritesTreeview
          items={filteredItems}
          disableDrag={hasSidebarSearch || !!search}
          onReorderItem={() => {
            recordAnalyticsEvent('FOLDERS_DRAGGED_EXPANDED_FAVORITES_ITEM')
          }}
        />

        {filteredItems.length === 0 && (
          <div className='text-sm px-4'>
            <p className='font-semibold mb-1'>No results</p>
            <p className='mb-3'>Try a different search term.</p>
            <Button
              size='xsmall'
              sentiment='neutral'
              onClick={() => void setSearch('')}
            >
              Clear search
            </Button>
          </div>
        )}
      </ScrollArea>
    </div>
  )
}
