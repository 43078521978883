import { useDependantState } from '@motion/react-core/hooks'
import { MAX_FOLDER_DEPTH } from '@motion/shared/common'
import { isOneOf } from '@motion/utils/array'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import { useMoveProjectInFolder } from '~/areas/folders/hooks'
import { useUpdateItemInFolder } from '~/global/rpc/folders'
import { showErrorToast } from '~/global/toasts'

import {
  type SortableItemData,
  type SortableWorkspacesTreeviewItem,
} from './types'
import {
  workspaceCalculateMaxLevel,
  workspaceCalculateMinLevel,
  workspaceCollisionDetection,
  workspaceTreeviewRenderFilter,
} from './utils'

import {
  SortableTreeview,
  type SortableTreeviewProps,
} from '../sortable-treeview'

export type WorkspacesTreeviewProps = Pick<
  SortableTreeviewProps<SortableWorkspacesTreeviewItem>,
  | 'items'
  | 'renderItem'
  | 'renderGhostItem'
  | 'estimateItemSize'
  | 'insertGapBetweenItems'
  | 'renderFilter'
  | 'autoLevelToZero'
>

export const WorkspacesTreeview = ({
  items,
  renderItem,
  renderGhostItem,
  estimateItemSize,
  insertGapBetweenItems,
  renderFilter = workspaceTreeviewRenderFilter,
  autoLevelToZero,
}: WorkspacesTreeviewProps) => {
  const moveProjectInFolder = useMoveProjectInFolder()
  const { mutateAsync: updateItemInFolder } = useUpdateItemInFolder()

  const [optimisticItems, setOptimisticItems] = useDependantState(
    () => items,
    [items]
  )

  return (
    <SortableTreeview
      items={optimisticItems}
      collisionDetection={workspaceCollisionDetection}
      renderFilter={renderFilter}
      renderItem={renderItem}
      renderGhostItem={renderGhostItem}
      calculateMinimumLevel={workspaceCalculateMinLevel}
      calculateMaximumLevel={workspaceCalculateMaxLevel}
      estimateItemSize={estimateItemSize}
      insertGapBetweenItems={insertGapBetweenItems}
      autoLevelToZero={autoLevelToZero}
      onDragEnd={async ({ active, over, projection, items }) => {
        const activeItemData = active.data.current as
          | SortableItemData
          | undefined

        const activeId = active.id
        const overId = over?.id

        if (!activeItemData) {
          return void Sentry.captureException(
            new Error('handleDragEnd active item data is undefined'),
            {
              extra: {
                activeId,
                overId,
                projection,
              },
              tags: {
                position: 'WorkspacesTreeview',
              },
            }
          )
        }

        setOptimisticItems(projection.newItems)

        const itemType = activeItemData.type

        if (!projection.parentId) {
          // Folders and projects must have a parent ID to continue
          return void Sentry.captureException(
            new Error(
              'Parent folder ID was not set when attempting to move folder/project'
            ),
            {
              extra: {
                activeId,
                overId,
                projection,
              },
              tags: {
                position: 'WorkspacesTreeview',
              },
            }
          )
        }

        const { order, parentId } = projection
        const newOrder = order ? order.toString() : undefined

        if (itemType === 'WORKSPACE') {
          const parentFolderId = projection.parentId.toString()
          const itemId = activeItemData.item.id

          recordAnalyticsEvent('FOLDERS_DRAGGED_SIDEBAR_ITEM', { itemType })

          try {
            await updateItemInFolder({
              parentFolderId: projection.hasMovedParent
                ? parentFolderId
                : undefined,
              itemId,
              order: newOrder,
            })
          } catch (e) {
            Sentry.captureException(
              new Error('Failed to move workspace', { cause: e }),
              {
                extra: {
                  parentFolderId,
                  itemId,
                  order,
                },
                tags: {
                  position: 'WorkspacesTreeview',
                },
              }
            )
          }

          return
        }

        const parentFolder = items.find(
          ({ item }) => item.id === parentId.toString()
        )

        if (!parentFolder) {
          return void Sentry.captureException(
            new Error(
              'Parent folder could not be found in flattened folder tree'
            ),
            {
              extra: {
                activeId,
                overId,
                projection,
              },
              tags: {
                position: 'WorkspacesTreeview',
              },
            }
          )
        }

        const parentFolderId = parentFolder.item.itemId

        if (itemType === 'FOLDER') {
          const itemId = activeItemData.item.id

          recordAnalyticsEvent('FOLDERS_DRAGGED_SIDEBAR_ITEM', { itemType })

          try {
            await updateItemInFolder({
              parentFolderId: projection.hasMovedParent
                ? parentFolderId
                : undefined,
              itemId,
              order: newOrder,
            })
          } catch (e) {
            if (
              e instanceof Error &&
              e.message === 'Folder depth limit reached'
            ) {
              return void showErrorToast(
                `You cannot exceed a folder depth of ${MAX_FOLDER_DEPTH}`
              )
            }

            Sentry.captureException(
              new Error('Failed to update item in folder', { cause: e }),
              {
                extra: {
                  parentFolderId,
                  itemId,
                  order,
                },
                tags: {
                  position: 'WorkspacesTreeview',
                },
              }
            )

            showErrorToast(e, 'Failed to move folder')
          }

          return
        }

        if (itemType === 'PROJECT') {
          const projectId = activeItemData.item.itemId

          recordAnalyticsEvent('FOLDERS_DRAGGED_SIDEBAR_ITEM', { itemType })

          try {
            await moveProjectInFolder(projectId, parentFolderId, newOrder)
          } catch (e) {
            Sentry.captureException(
              new Error('Failed to update project in folder', { cause: e }),
              {
                extra: {
                  projectId,
                  parentFolderId,
                  order,
                },
                tags: {
                  position: 'WorkspacesTreeview',
                },
              }
            )

            showErrorToast(e, 'Failed to move project')
          }
          return
        }

        if (itemType === 'NOTE') {
          const parentFolderItemId =
            projection.hasMovedParent &&
            isOneOf(parentFolder.item.type, ['NOTE', 'PROJECT'])
              ? parentFolder.item.id
              : undefined

          const parentFolderId =
            projection.hasMovedParent &&
            isOneOf(parentFolder.item.type, [
              'FOLDER',
              'INDIVIDUAL_WORKSPACE',
              'TEAM_WORKSPACE',
            ])
              ? parentFolder.item.itemId
              : undefined

          await updateItemInFolder({
            parentFolderId,
            parentFolderItemId,
            itemId: activeItemData.item.id,
            order: newOrder,
          })
        }
      }}
    />
  )
}
