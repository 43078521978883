import { useDependantState } from '@motion/react-core/hooks'
import { templateStr } from '@motion/react-core/strings'
import { classed } from '@motion/theme'
import {
  type CalendarStartDay,
  formatToReadableWeekDayMonth,
} from '@motion/ui-logic'
import {
  processLeadingEdge,
  processTrailingEdge,
} from '@motion/ui-logic/pm/data'
import { type RelativeDateRangeFilterSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'
import { twMerge } from 'tailwind-merge'

import { ValueDropdown } from './value-dropdown'

import { FilterActionBar } from '../../action-bar'

export type RelativeDateRangeProps = {
  calendarStartDay?: CalendarStartDay

  value: RelativeDateRangeFilterSchema
  onSelect(value: RelativeDateRangeFilterSchema | null): void
  disallowNone?: boolean
}

export function RelativeDateRangePanel({
  calendarStartDay,
  value,
  onSelect,
  disallowNone = false,
}: RelativeDateRangeProps) {
  const [local, setLocal] = useDependantState(() => value, [value])

  const dateRangeCtx = { now: DateTime.now(), zone: DateTime.now().zone }

  const start = processLeadingEdge(dateRangeCtx, local.from)
  const end = processTrailingEdge(dateRangeCtx, local.to)

  const isValid = start != null && end != null ? start <= end : true

  return (
    <div className='w-72'>
      <div className='p-3 flex flex-col gap-2'>
        <Section>
          <SectionTitle>Starts</SectionTitle>
          <ValueDropdown
            mode='start'
            value={local.from}
            calendarStartDay={calendarStartDay}
            disallowNone={disallowNone}
            onChange={(v) =>
              setLocal((prev) => {
                return { ...prev, from: v }
              })
            }
          />
        </Section>
        <Section>
          <SectionTitle>Ends</SectionTitle>
          <ValueDropdown
            mode='end'
            value={local.to}
            calendarStartDay={calendarStartDay}
            disallowNone={disallowNone}
            onChange={(v) =>
              setLocal((prev) => {
                return { ...prev, to: v }
              })
            }
          />
        </Section>

        <div
          className={twMerge(
            'text-sm text-center',
            isValid
              ? 'text-semantic-neutral-text-subtle'
              : 'text-semantic-error-text-default'
          )}
        >
          {templateStr('{{start}} - {{end}}', {
            start: start != null ? formatToReadableWeekDayMonth(start) : 'None',
            end: end != null ? formatToReadableWeekDayMonth(end) : 'None',
          })}
        </div>
        {!isValid && (
          <div className='text-semantic-error-text-default text-center text-sm'>
            Start date must be on or before the end date
          </div>
        )}
      </div>

      <FilterActionBar
        disabled={!isValid}
        onApply={() => onSelect(local)}
        onClear={() => onSelect(null)}
      />
    </div>
  )
}

const Section = classed('section', {
  base: `flex flex-col gap-2`,
})

const SectionTitle = classed('h3', {
  base: `
  text-semantic-neutral-text-subtle
  text-xs
  font-semibold
`,
})
