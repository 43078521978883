import { ExternalLinkSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { isNotetakerCompleted } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useUriByRouteId } from '~/routing'
import { useCallback } from 'react'

import { useEventModalState } from '../../contexts'

export function ConnectedGoToNoteButton() {
  const { meetingInsights, recurringMeetingInsights, close } =
    useEventModalState()
  const getRouteUri = useUriByRouteId()

  const noteId = meetingInsights?.noteId || recurringMeetingInsights?.noteId

  const handleClick = useCallback(() => {
    recordAnalyticsEvent('NOTETAKER_DOC_EVENT_MODAL_CLICK', {
      type: 'header',
    })
    close()
  }, [close])

  if (noteId == null) {
    return null
  }

  const meetingNoteUrl = getRouteUri('notes-detail', {
    noteId,
  })

  return (
    <Button
      url={meetingNoteUrl}
      sentiment='neutral'
      variant='outlined'
      size='small'
      onClick={handleClick}
    >
      <ExternalLinkSolid />
      {isNotetakerCompleted(meetingInsights)
        ? 'Notes & Action Items'
        : 'Meeting Note'}
    </Button>
  )
}
