import { createPlaceholderId } from '@motion/shared/identifiers'
import { byValue, Compare } from '@motion/utils/array'
import { uniqueId } from '@motion/utils/core'
import { type ProjectDefinitionSchema } from '@motion/zod/client'

import {
  DEFAULT_TEXT_VARIABLES,
  isRoleVariable,
  isTextVariable,
} from '~/areas/flows/utils'

export function useInitialFlowVariables<
  T extends Pick<ProjectDefinitionSchema, 'variables'>,
>(
  projectDefinition: T | null,
  isCreatingFromTemplate?: boolean,
  options: { enabled: boolean } = { enabled: true }
) {
  if (!options.enabled) {
    return {
      roles: [],
      textVariables: [],
    }
  }

  let roles = projectDefinition?.variables.filter(isRoleVariable) ?? []
  const userTextVariables =
    projectDefinition?.variables.filter(isTextVariable) ?? []
  let textVariables = [
    ...DEFAULT_TEXT_VARIABLES.filter(
      (defaultVar) => !userTextVariables.some((v) => v.key === defaultVar.key)
    ),
    ...userTextVariables,
  ]

  if (isCreatingFromTemplate) {
    roles = roles.map((role) => ({
      ...role,
      id: createPlaceholderId(uniqueId('role')),
    }))
    textVariables = textVariables.map((textVariable) => ({
      ...textVariable,
      id: createPlaceholderId(uniqueId('text-variable')),
    }))
  }

  roles.sort(byValue((role) => role.name, Compare.string))
  textVariables.sort(
    byValue((textVariable) => textVariable.name, Compare.string)
  )

  return {
    roles,
    textVariables,
  }
}
