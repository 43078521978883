import { classed } from '@motion/theme'

export const ConfirmationSection = classed('div', {
  base: 'flex w-[459px] p-4 flex-col items-start gap-4 self-stretch',
})

export const ConfirmationText = classed('span', {
  base: `text-semantic-neutral-text-subtle text-xs font-semibold`,
})

export const FormBody = classed('div', {
  base: 'flex items-start self-stretch',
})

export const ProjectList = classed('div', {
  base: 'flex flex-col items-start gap-2 align-self-stretch min-w-0 w-full',
})

export const ProjectName = classed('span', {
  base: 'truncate text-semantic-neutral-text-default text-xs font-normal',
})

export const ProjectSection = classed('div', {
  base: 'flex flex-col h-full flex-grow w-[250px] p-4 items-start gap-4 bg-semantic-neutral-surface-raised-bg-subtlest min-h-[155px]',
  variants: {
    isStage: {
      true: 'min-h-[222px]',
    },
  },
})

export const TemplateList = classed('div', {
  base: 'flex items-start content-start gap-1 self-stretch flex-wrap',
})

export const TextGroup = classed('div', {
  base: 'flex flex-col gap-1.5 mt-2 w-full',
})

export const WarningText = classed('span', {
  base: `text-semantic-neutral-text-default text-sm font-normal`,
})
