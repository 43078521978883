import { useDependantState } from '@motion/react-core/hooks'
import { UnstyledModal } from '@motion/ui/base'
import { type TaskFormFields } from '@motion/ui-logic/pm/task'
import { type ProjectSchema, type TaskSchema } from '@motion/zod/client'

import { type AnimatedModalTriggerComponentProps } from '~/areas/modals'
import { ErrorBoundary } from '~/global/components'
import { useWorkspaceById } from '~/global/hooks'
import { useMemo } from 'react'

import { type InitialTaskData, useTaskInitialFormData } from './hooks'
import {
  InAppTaskModalContent,
  TaskModalBody,
  type TaskModalBodyProps,
} from './task-modal-body'
import { type FormDataSubmit } from './types'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'speculative-task-modal': PromptCallbacks<FormDataSubmit> & {
      task: TaskSchema
      project: ProjectSchema
    }
  }
}

export type SpeculativeTaskModalProps =
  AnimatedModalTriggerComponentProps<'speculative-task-modal'>

export function SpeculativeTaskModal({
  open,
  close,
  task,
  project,
}: SpeculativeTaskModalProps) {
  return (
    <UnstyledModal
      data-testid='speculative-task-modal'
      type='page'
      visible={open}
      onClose={() => close()}
      withAnimation
      overlayClassName='bg-modal-overlay'
    >
      <ErrorBoundary renderFallback={({ error }) => <div>Oops</div>}>
        <InnerSpeculativeTaskModal
          task={task}
          project={project}
          close={close}
        />
      </ErrorBoundary>
    </UnstyledModal>
  )
}

const stableModalOptions: TaskModalBodyProps['options'] = {
  disallowLocationUpdate: true,
  isSpeculative: true,
  isAutoSaving: false,
}

function InnerSpeculativeTaskModal({
  task: taskProp,
  project: projectProp,
  close,
}: Pick<SpeculativeTaskModalProps, 'task' | 'project' | 'close'>) {
  const [{ task, project }] = useDependantState(
    () => ({ task: taskProp, project: projectProp }),
    [taskProp, projectProp],
    {
      // Freezing the update when the task is null as it's going to be null when the modal closes
      freezeDependencyUpdates: taskProp == null || projectProp == null,
    }
  )

  const initialTaskData = useMemo<TaskModalBodyProps['initialTaskData']>(() => {
    return {
      isLoading: false,
      hasError: false,
      task,
      parentTask: undefined,
      project,
    }
  }, [task, project])
  const initialFormData = useSpeculativeInitialFormData(
    initialTaskData,
    project
  )

  return (
    <TaskModalBody
      initialTaskData={initialTaskData}
      initialFormData={initialFormData}
      options={stableModalOptions}
    >
      <InAppTaskModalContent
        close={close}
        onFormSubmit={(data) => {
          close(data)
        }}
      />
    </TaskModalBody>
  )
}

function useSpeculativeInitialFormData(
  initialTaskData: InitialTaskData,
  project: ProjectSchema
): TaskFormFields {
  const { task, parentTask } = initialTaskData

  if (task == null || task.type !== 'NORMAL') {
    throw new Error('Invalid task', { cause: { task } })
  }

  let workspace = useWorkspaceById(task.workspaceId)
  if (workspace == null) {
    throw new Error('Invalid workspace', {
      cause: {
        task,
      },
    })
  }

  return useTaskInitialFormData({
    workspaceId: task.workspaceId,
    task,
    parentTask,
    project,
  })
}
