import {
  DeadlineTypeSchema,
  type MeetingActionItemTaskDataSchema,
  PriorityLevelSchema,
} from '@motion/zod/client'

import * as v from 'valibot'

export const ActionItemFormSchema = v.object({
  name: v.string(),
  description: v.optional(v.pipe(v.string(), v.minLength(1))),
  workspaceId: v.string(),
  projectId: v.nullable(v.string()),
  duration: v.nullable(v.pipe(v.number(), v.integer())),
  startDate: v.pipe(
    v.string(),
    v.isoTimestamp('The start date is badly formatted.')
  ),
  dueDate: v.nullable(
    v.pipe(v.string(), v.isoTimestamp('The due date is badly formatted.'))
  ),
  assigneeUserId: v.nullable(v.string()),
  deadlineType: v.picklist(DeadlineTypeSchema),
  ignoreWarnOnPastDue: v.optional(v.boolean()),
  isAutoScheduled: v.optional(v.boolean()),
  minimumDuration: v.nullable(v.pipe(v.number(), v.integer())),
  priorityLevel: v.picklist(PriorityLevelSchema),
  scheduleId: v.optional(v.string()),
  statusId: v.optional(v.string()),
})

export type ActionItemFormFields = v.InferInput<typeof ActionItemFormSchema>

export function isFormValid(
  taskData: MeetingActionItemTaskDataSchema
): taskData is v.InferOutput<typeof ActionItemFormSchema> {
  const result = v.safeParse(ActionItemFormSchema, taskData)

  return result.success
}
