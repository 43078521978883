import { TabList, type TabListProps } from '@motion/ui/base'

import { useUriByRouteId } from '~/routing'
import { useMemo } from 'react'
import { useMatch } from 'react-router-dom'

export function WorkflowTabs() {
  const getUri = useUriByRouteId()

  const tabs = useMemo<TabListProps['items']>(
    () => [
      {
        value: 'workflows',
        content: 'AI Workflows',
        url: getUri('ai-workflows'),
      },
      {
        value: 'runs',
        content: 'Runs',
        url: getUri('ai-workflows-runs'),
      },
    ],
    [getUri]
  )

  const isOnRunsPage = Boolean(useMatch(getUri('ai-workflows-runs')))

  return (
    <div className='px-4 border-b border-tab-border-default flex flex-row gap-2 items-center'>
      <TabList activeValue={isOnRunsPage ? 'runs' : 'workflows'} items={tabs} />
    </div>
  )
}
