import { SearchSolid, XSolid } from '@motion/icons'
import { usePrevious } from '@motion/react-core/hooks'
import {
  type ProjectDefinitionSchema,
  type UploadedFileSchema,
} from '@motion/rpc-types'
import { classed } from '@motion/theme'
import { IconButton, Modal } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { Sentry } from '@motion/web-base/sentry'
import { useModalApi } from '@motion/web-common/modals'

import { Attachments, AttachmentUploadButton } from '~/areas/attachments'
import { AttachmentGroupsEmptyState } from '~/areas/attachments/components/attachment-groups/components/attachment-groups-empty-state'
import { useFilterAttachmentGroups } from '~/areas/attachments/components/attachment-groups/hooks/use-filter-attachment-groups'
import { useFileUploadState } from '~/areas/attachments/contexts'
import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useProjectDefinition } from '~/global/hooks'
import { useCallback, useMemo, useState } from 'react'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'project-definition-attachments-modal': {
      workspaceId: string
      projectDefinitionId: string
    }
  }
}

type ProjectDefinitionAttachmentModalProps =
  ModalTriggerComponentProps<'project-definition-attachments-modal'>

export const ProjectDefinitionAttachmentModal = ({
  close,
  workspaceId,
  projectDefinitionId,
}: ProjectDefinitionAttachmentModalProps) => {
  const { activeFileUploads } = useFileUploadState()
  const modalApi = useModalApi()

  const filteredActiveFileUploads = activeFileUploads.filter(
    (activeFileUpload) =>
      activeFileUpload.targetId === projectDefinitionId &&
      activeFileUpload.targetType === 'PROJECT_DEFINITION'
  )
  const prevActiveFileUploadsTargets = usePrevious(filteredActiveFileUploads)

  const projectDefinition = useProjectDefinition(projectDefinitionId)
  const attachmentIds = useMemo(
    () => projectDefinition?.uploadedFileIds ?? [],
    [projectDefinition]
  )

  const handleAttachmentClick = useCallback(
    (attachmentId: UploadedFileSchema['id']) => {
      modalApi.open('attachment-preview', {
        attachmentId,
        attachmentIds,
      })
    },
    [modalApi, attachmentIds]
  )

  if (!projectDefinition) {
    Sentry.captureException(new Error('Project definition is required'), {
      tags: {
        projectDefinitionId,
      },
    })
    close()
    return null
  }

  const attachmentsCount = attachmentIds.length

  const attachmentInProgress = filteredActiveFileUploads.length > 0

  const hasNoAttachments =
    filteredActiveFileUploads.length === 0 &&
    (!attachmentIds || attachmentIds.length === 0) &&
    (!prevActiveFileUploadsTargets || prevActiveFileUploadsTargets.length === 0)

  return (
    <Modal
      disableEscapeKey={attachmentInProgress}
      disableOverlayClick={attachmentInProgress}
      visible
      onClose={close}
    >
      <TitleBar>
        Attachments ({attachmentsCount})
        <IconButton
          disabled={attachmentInProgress}
          icon={XSolid}
          onClick={close}
          size='small'
          sentiment='neutral'
          variant='muted'
        />
      </TitleBar>
      <div className='flex h-[511px] w-[549px] overflow-y-auto overflow-x-hidden flex-col'>
        {hasNoAttachments && (
          <EmptyModalBody
            projectDefinitionId={projectDefinitionId}
            workspaceId={workspaceId}
          />
        )}
        {!hasNoAttachments && (
          <AttachmentsBody
            attachmentIds={attachmentIds}
            onAttachmentClick={handleAttachmentClick}
            projectDefinition={projectDefinition}
          />
        )}
      </div>
    </Modal>
  )
}

const EmptyModalBody = ({
  projectDefinitionId,
  workspaceId,
}: Pick<
  ProjectDefinitionAttachmentModalProps,
  'projectDefinitionId' | 'workspaceId'
>) => {
  return (
    <EmptyModalContainer>
      <span className='text-semantic-neutral-text-default text-sm font-semibold leading-5'>
        No attachments yet
      </span>
      <AttachmentUploadButton
        targetId={projectDefinitionId}
        targetType='PROJECT_DEFINITION'
        workspaceId={workspaceId}
      />
    </EmptyModalContainer>
  )
}

type AttachmentsBodyProps = {
  projectDefinition: ProjectDefinitionSchema
  attachmentIds: UploadedFileSchema['id'][]
  onAttachmentClick: (attachmentId: UploadedFileSchema['id']) => void
}
const AttachmentsBody = ({
  onAttachmentClick,
  attachmentIds,
  projectDefinition,
}: AttachmentsBodyProps) => {
  const [searchQuery, setSearchQuery] = useState('')

  const { filteredGroups } = useFilterAttachmentGroups({
    query: searchQuery,
    groups: [
      {
        targetId: projectDefinition.id,
        targetType: 'PROJECT_DEFINITION',
        uploadedFileIds: attachmentIds,
        workspaceId: projectDefinition.workspaceId,
      },
    ],
  })
  const filteredAttachments = filteredGroups[0]?.uploadedFileIds ?? []
  const hasEmptySearchResults =
    searchQuery != null &&
    searchQuery.length > 0 &&
    filteredAttachments.length === 0 &&
    attachmentIds.length

  return (
    <>
      <SearchBarContainer>
        <TextField
          rounded='default'
          prefix={<SearchSolid />}
          placeholder='Search'
          value={searchQuery}
          onChange={setSearchQuery}
          fullWidth
          showClearButton
        />

        <div className='flex flex-shrink-0'>
          <AttachmentUploadButton
            targetId={projectDefinition.id}
            targetType='PROJECT_DEFINITION'
            workspaceId={projectDefinition.workspaceId}
            label='Add attachment'
          />
        </div>
      </SearchBarContainer>
      <div className='px-2 '>
        {hasEmptySearchResults && (
          <AttachmentGroupsEmptyState
            query={searchQuery}
            onClearQuery={() => setSearchQuery('')}
            targetId={projectDefinition.id}
            targetType='PROJECT_DEFINITION'
            workspaceId={projectDefinition.workspaceId}
          />
        )}
        {!hasEmptySearchResults && (
          <Attachments
            targetId={projectDefinition.id}
            targetType='PROJECT_DEFINITION'
            attachmentIds={filteredAttachments}
            onAttachmentClick={onAttachmentClick}
          />
        )}
      </div>
    </>
  )
}
const TitleBar = classed('div', {
  base: `flex p-3 pl-4 justify-between items-center self-stretch border-b border-modal-border bg-modal-bg text-semantic-neutral-text-default`,
})

const EmptyModalContainer = classed('div', {
  base: `flex px-4 py-2 flex-col justify-center items-center flex-1 gap-4 self-stretch`,
})

const SearchBarContainer = classed('div', {
  base: `flex py-3 px-4 items-start gap-2 self-stretch`,
})
