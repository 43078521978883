import {
  AutoscheduleStarSolid,
  ChevronDownOutline,
  GlobeAltOutline,
  NoteAddSolid,
  type SvgIcon,
} from '@motion/icons'
import { classed } from '@motion/theme'
import { Text } from '@motion/ui/base'
import { type AgentWorkflowStepType } from '@motion/zod/client'

export type WorkflowStepCardProps = {
  title: string
  type: AgentWorkflowStepType
}

export function WorkflowStepCard({ title, type }: WorkflowStepCardProps) {
  return (
    <StyledCard>
      <div className='flex flex-row items-center justify-between'>
        <Text
          size='md'
          weight='semibold'
          className='text-semantic-blue-text-default rounded bg-semantic-blue-bg-default px-1.5 py-1'
        >
          @ {title}
        </Text>

        <div className='flex flex-row gap-2 items-center [&_[data-icon]]:size-4 [&_[data-icon]]:text-semantic-neutral-icon-default'>
          <WorkflowStepType type={type} />
          <ChevronDownOutline />
        </div>
      </div>

      <section className='flex flex-col gap-0.5'>
        <SkeletonLine />
        <SkeletonLine />
        <SkeletonLine className='w-[80%]' />
      </section>
    </StyledCard>
  )
}

const agentStepTypeToData: Record<
  AgentWorkflowStepType,
  { label: string; icon: SvgIcon }
> = {
  LLM_GENERATION: { label: 'Generate text', icon: AutoscheduleStarSolid },
  CREATE_DOCUMENT: { label: 'Create doc', icon: NoteAddSolid },
  WEB_RESEARCH: { label: 'Web research', icon: GlobeAltOutline },
}

function WorkflowStepType({ type }: { type: AgentWorkflowStepType }) {
  const { label, icon: Icon } = agentStepTypeToData[type]
  return (
    <div className='flex flex-row gap-1.5'>
      <Icon className='size-4 text-semantic-neutral-icon-default shrink-0' />
      <Text size='sm' truncate>
        {label}
      </Text>
    </div>
  )
}

const StyledCard = classed('section', {
  base: `
    w-full
    flex flex-col gap-3
    p-3
    rounded-lg
    border border-semantic-neutral-border-default
    bg-semantic-neutral-surface-raised-bg-default
  `,
})

const SkeletonLine = classed('div', {
  base: `
    rounded-full
    bg-label-grey-bg
    h-4
  `,
})
