import { type SvgIcon } from '@motion/icons'
import { FieldTypes, type FieldTypeSchema } from '@motion/shared/custom-fields'
import { type CustomFieldSchemaByType } from '@motion/ui-logic'
import { createLookup } from '@motion/utils/object'

import {
  type BadgeSize,
  getBadgeSizeInPixels,
} from '~/global/components/badges'
import {
  getLabelComponent,
  type TaskFieldLabelProps,
} from '~/global/components/labels'
import {
  type AppWorkspaceDataContext,
  type AppWorkspaceDataKeys,
  useAppWorkspaceContext,
} from '~/global/contexts'
import { type FC, type ReactNode } from 'react'

type MultiSelectFilterValueProps<T extends string> = {
  type: AppWorkspaceDataKeys | FieldTypeSchema
  ids: T[] | null
  Icon?: SvgIcon
  customField?: CustomFieldSchemaByType<'multiSelect' | 'select'>
  getAllItems?: () => { id: T; name: string }[]
  labelSize?: BadgeSize
  renderMultipleValues?: ({ ids }: { ids: T[] }) => ReactNode
  contextScope?: string
}

export const MultiSelectFilterValue = <T extends string>(
  props: MultiSelectFilterValueProps<T>
) => {
  const [ctx] = useAppWorkspaceContext({ scope: props.contextScope })
  if (props.ids == null) {
    return 'None'
  }

  const { type, ids, labelSize = 'normal' } = props
  const cacheKey = FieldTypes.includes(type)
    ? 'customFields'
    : (type as AppWorkspaceDataKeys)
  const cache = ctx[cacheKey]
  const allItems = props.getAllItems?.()

  if (ids.length === 1) {
    const labelType = getLabelItemType(props.type)()
    const Label = getLabelComponent(labelType) as FC<TaskFieldLabelProps<any>>
    const value = getSingleValue(cache, ids[0], type, allItems)
    if (value == null) {
      return 'None'
    }
    return <Label value={value} keyProp='' size={labelSize} />
  }

  const text =
    ids.length === 0 ||
    ids.length === cache.all.length ||
    (allItems != null && allItems.length === ids.length)
      ? 'All'
      : String(ids.length)

  if (props.renderMultipleValues != null) {
    return props.renderMultipleValues({ ids })
  }
  const iconSize = getBadgeSizeInPixels(props.labelSize)
  return (
    <div className='flex gap-1 items-center'>
      {props.Icon != null && (
        <props.Icon
          width={iconSize}
          height={iconSize}
          className='text-semantic-neutral-icon-default'
        />
      )}
      {text} values
    </div>
  )
}

const getLabelItemType = createLookup({
  workspaces: () => 'workspace',
  users: () => 'user',
  statuses: () => 'status',
  stageDefinitions: () => 'stage',
  projectDefinitions: () => 'projectDefinition',
  labels: () => 'label',
  projects: () => 'project',
  folders: () => 'folder',
  priorities: () => 'priority',
  deadlineStatuses: () => 'deadlineStatus',
  multiSelect: () => 'multiSelect',
  select: () => 'select',
  default: () => 'unknown',
})

const getSingleValue = <T extends string>(
  cache: AppWorkspaceDataContext[AppWorkspaceDataKeys],
  id: T,
  type: MultiSelectFilterValueProps<T>['type'],
  allItems?: ReturnType<
    NonNullable<MultiSelectFilterValueProps<T>['getAllItems']>
  >
) => {
  if (FieldTypes.includes(type)) {
    return allItems?.find((o) => o.id === id)
  }
  return cache.byId[id] ?? allItems?.find((o) => o.id === id)
}
