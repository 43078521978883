import { showToast } from '@motion/ui/base'
import { Sentry } from '@motion/web-base/sentry'
import {
  type ProjectDefinitionSchema,
  type UpdateProjectDefinitionRequestSchema,
} from '@motion/zod/client'

import { useUpdateProjectDefinitionFull } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export function useUpdateProjectDefinition() {
  const { mutateAsync: updateProjectDefinition } =
    useUpdateProjectDefinitionFull()

  return useCallback(
    async (
      projectDefinitionId: ProjectDefinitionSchema['id'],
      workspaceId: string,
      updates: UpdateProjectDefinitionRequestSchema
    ) => {
      const payload = {
        id: projectDefinitionId,
        workspaceId,
        definition: updates.definition,
      }

      try {
        const response = await updateProjectDefinition(payload)

        const savedProjectDefinition =
          response.models[response.meta.model][response.id]

        showToast('success', 'Project definition saved')

        return savedProjectDefinition
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            position: 'update-project-definition',
          },
        })

        showErrorToast(e)
      }
    },
    [updateProjectDefinition]
  )
}
