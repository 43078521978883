import { templateStr } from '@motion/react-core/strings'
import { FormModal, showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { copyToClipboardAsHtml } from '~/utils/window'

export const InviteTeammateSuccessModal = () => {
  const modalApi = useModalApi()

  const onClose = () => {
    modalApi.dismiss()
  }

  return (
    <FormModal
      onClose={onClose}
      submitAction={{
        onAction: () => {
          void recordAnalyticsEvent(
            'NEW_ONBOARDING_TEAM_INVITE_SUCCESS_MODAL_COPY'
          )
          void copyToClipboardAsHtml(
            document.getElementById('invite-content')?.innerHTML,
            'fallback',
            true
          )
          showToast('success', 'Copied!')
          onClose()
        },
        text: 'Copy message',
      }}
      title='Invites sent successfully!'
      visible
    >
      <p className='text-semantic-neutral-text-default mb-4 text-sm'>
        Send this message to your team members to let them know how to get
        started.
      </p>
      <div className='dark:bg-dark-1100 dark:text-dark-300 bg-light-300 text-light-1100 rounded p-3 text-xs font-normal leading-5 max-w-[566px]'>
        <span>
          Hey! I&apos;ve sent you an invite to join our team on Motion (to
          manage tasks and projects). Could you please:
          <br />
        </span>
        <ol className='list-decimal pl-5'>
          <li>
            {templateStr(
              'Visit {{motionLink}} and login with your team email. You should already have an active Motion account.',
              {
                motionLink: (
                  <a
                    className='text-inherit underline'
                    href='https://app.usemotion.com'
                  >
                    app.usemotion.com
                  </a>
                ),
              }
            )}
          </li>
          <li>
            Go through self-serve onboarding: Motion is an all-in-one time
            management platform for team projects, individual tasks,
            calendaring, and meeting scheduling. As part of our team for project
            management, you do not need to use these other functionalities,
            although you may find them useful.
          </li>
          <li>
            {templateStr(
              'You should see {{projectsLink}} at the top (alongside Calendar, Booking ...etc) - this is where we will manage projects & tasks together.',
              {
                projectsLink: (
                  <a
                    className='text-inherit underline'
                    href='https://app.usemotion.com/web/pm'
                  >
                    &quot;Projects&quot;
                  </a>
                ),
              }
            )}
          </li>
        </ol>
      </div>
      <div id='invite-content' className='hidden'>
        <span>
          Hey! I&apos;ve sent you an invite to join our team on Motion (to
          manage tasks and projects). Could you please:
          <br />
        </span>
        <ol>
          <li>
            1. Visit https://app.usemotion.com and login with your team email.
            You should already have an active Motion account.
            <br />
          </li>
          <li>
            2. Go through self-serve onboarding: Motion is an all-in-one time
            management platform for team projects, individual tasks,
            calendaring, and meeting scheduling. As part of our team for project
            management, you do not need to use these other functionalities,
            although you may find them useful.
            <br />
          </li>
          <li>
            {templateStr(
              '3. You should see {{projectsLink}} at the top (alongside Calendar, Booking ...etc) - this is where we will manage projects & tasks together.',
              {
                projectsLink: (
                  <a href='https://app.usemotion.com/web/pm'>
                    &quot;Projects&quot;
                  </a>
                ),
              }
            )}
            <br />
          </li>
        </ol>
      </div>
    </FormModal>
  )
}
