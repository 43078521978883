import {
  FieldButton,
  type FieldButtonProps,
  PopoverTrigger,
  ProgressCircle,
  SearchableList,
} from '@motion/ui/base'
import {
  createDurationsFromText,
  formatDurationTime,
  formatToTaskDuration,
} from '@motion/ui-logic'

import {
  DurationDropdown,
  type DurationDropdownProps,
} from '~/areas/project-management/components'
import { useCompletedChoices } from '~/areas/project-management/hooks'
import { ModalFieldButton } from '~/areas/task-project/components'
import { useController } from 'react-hook-form'

import { useDurations, useTaskForm } from '../hooks'

export const DurationField = () => {
  const { form, disabledFields, hiddenFields } = useTaskForm()

  const { watch } = form

  const completedDurationValue = watch('completedDuration')
  const durationValue = watch('duration')

  const progress = durationValue
    ? (completedDurationValue / durationValue) * 100
    : 0

  const disabled =
    disabledFields.has('completedDuration') && disabledFields.has('duration')

  return (
    <ModalFieldButton label='Duration' disabled={disabled} htmlFor='duration'>
      <div className='flex items-center gap-[3px]'>
        {!hiddenFields.has('completedDuration') && (
          <>
            <ProgressCircle
              value={progress}
              labelHidden
              size='xsmall'
              // @ts-expect-error - fine
              className='mr-[3px]'
            />
            <CompletedDurationPopover variant='muted' size='small' />
            <span className='text-semantic-neutral-text-subtle'>of</span>
          </>
        )}
        <DurationPopover variant='muted' size='small' />
      </div>
    </ModalFieldButton>
  )
}

type DurationPopoverProps = Pick<FieldButtonProps, 'variant' | 'size'>

function CompletedDurationPopover(buttonProps: DurationPopoverProps) {
  const { form, disabledFields } = useTaskForm()

  const isDisabled = disabledFields.has('completedDuration')

  const { control, watch } = form

  const { field: completedDurationField } = useController({
    name: 'completedDuration',
    control,
  })

  const taskCompletedDuration = completedDurationField.value
  const taskDuration = watch('duration') ?? 0

  const completedChoices = useCompletedChoices(
    taskCompletedDuration,
    taskDuration
  )

  const onChange: DurationDropdownProps['onChange'] = (value) => {
    if (value == null) return

    completedDurationField.onChange(value)
  }

  return (
    <DurationDropdown
      value={taskCompletedDuration}
      choices={completedChoices}
      onChange={onChange}
    >
      <FieldButton
        {...buttonProps}
        iconOnly
        disabled={isDisabled}
        variant='muted'
      >
        <span className='whitespace-nowrap'>
          {formatDurationTime(taskCompletedDuration)}
        </span>
      </FieldButton>
    </DurationDropdown>
  )
}

function DurationPopover(buttonProps: DurationPopoverProps) {
  const { form, disabledFields } = useTaskForm()
  const { control } = form

  const { field: durationField } = useController({
    name: 'duration',
    control,
  })

  const selectedDurationValue = durationField.value

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => <DurationPopoverContent close={close} />}
    >
      <FieldButton
        {...buttonProps}
        id='duration'
        iconOnly
        disabled={disabledFields.has('duration')}
      >
        <span className='whitespace-nowrap'>
          {formatToTaskDuration(selectedDurationValue)}
        </span>
      </FieldButton>
    </PopoverTrigger>
  )
}

function DurationPopoverContent({ close }: { close: () => void }) {
  const { form } = useTaskForm()
  const { control } = form

  const durations = useDurations()

  const { field: completedDurationField } = useController({
    name: 'completedDuration',
    control,
  })
  const { field: durationField } = useController({
    name: 'duration',
    control,
  })

  const selectedCompletionValue = completedDurationField.value
  const selectedDurationValue = durationField.value

  const onChange: DurationDropdownProps['onChange'] = (duration) => {
    durationField.onChange(duration)
    completedDurationField.onChange(
      Math.min(duration ?? 0, selectedCompletionValue ?? 0)
    )
  }

  return (
    <SearchableList
      items={durations}
      computeKey={(item) => String(item.value)}
      computeSearchValue={(item) => item.label}
      computeSelected={(item) => item.value === selectedDurationValue}
      onSelect={(item) => {
        onChange(item.value)
        close()
      }}
      renderItem={(item) => item.label}
      filter={(search) => {
        if (search) {
          return createDurationsFromText(search)
        }
        return durations
      }}
      inputProps={{ placeholder: 'Choose or type a duration' }}
    />
  )
}
