import { isNoAccess } from '@motion/rpc-cache'
import type { COLOR } from '@motion/shared/common'
import { DEFAULT_DOC_TITLE } from '@motion/ui-logic'
import { byProperty, Compare } from '@motion/utils/array'
import { Sentry } from '@motion/web-base/sentry'
import { useHasTreatment } from '@motion/web-common/flags'
import type {
  RecursiveFolderItemSchema,
  WorkspaceSchema,
} from '@motion/zod/client'

import { viewIdSlug } from '~/areas/project-management/pages/pm-v3/views/hooks/view-id-slug'
import { useCachedItems } from '~/global/cache'
import {
  useFindFolderItem,
  useGetWorkspaceByFolderId,
  type WorkspacesTreeItem,
  type WorkspacesTreeViewItem,
} from '~/global/hooks'
import { useUriByRouteId } from '~/routing'
import { useCallback } from 'react'

export type UseFormatWorkspacesTreeItemsProps = {
  preferUsingBrowsePageUrls?: boolean
  makeProjectContainers?: boolean
  treeType: 'WORKSPACES' | 'FAVORITES' | 'PRIVATE' | 'SHARED'
}

export const useFormatWorkspacesTreeItems = ({
  preferUsingBrowsePageUrls = false,
  makeProjectContainers = false,
  treeType,
}: UseFormatWorkspacesTreeItemsProps) => {
  const hasBrowseViewEnabled = useHasTreatment('browse-view')
  const getRouteUri = useUriByRouteId({ noDefaults: true })
  const getViewUri = useUriByRouteId({ noDefaults: false })
  const allWorkspaces = useCachedItems('workspaces')
  const allProjects = useCachedItems('projects')
  const allFolders = useCachedItems('folders')
  const allNotes = useCachedItems('notes')
  const allViews = useCachedItems('views')
  const findFolderItem = useFindFolderItem()
  const getWorkspaceByFolderId = useGetWorkspaceByFolderId()

  const hasNotes = useHasTreatment('notes-in-webapp')

  const formatFolderTree = useCallback(
    (item: RecursiveFolderItemSchema): WorkspacesTreeItem | null => {
      let children: WorkspacesTreeItem[] = []

      if ('items' in item && item.items.length) {
        children.push(
          ...item.items
            .map(formatFolderTree)
            .filter(Boolean)
            .sort(byProperty('order', Compare.string))
        )
      }

      const isContainer =
        item.itemType === 'FOLDER' ||
        children.length > 0 ||
        (item.itemType === 'PROJECT' && makeProjectContainers)

      // These props are the same for workspaces, folders, and projects
      const commonProps = {
        id: item.id,
        order: item.order,
        itemId: item.itemId,
        parentId: item.folderId,
        children,
        itemCount: children.reduce(
          (count, child) => count + child.itemCount,
          0
        ),
        isContainer,
      } as const

      // A "folder" can be a folder or a workspace
      if (item.itemType === 'FOLDER') {
        const folderModel = allFolders.find(({ id }) => item.itemId === id)

        if (!folderModel) {
          Sentry.captureException(
            new Error('Could not find folder model when building folder tree'),
            {
              extra: {
                item,
              },
              tags: {
                position: 'useFormatWorkspacesTreeItems',
              },
            }
          )

          return null
        }

        const workspace = getWorkspaceByFolderId(folderModel.id)
        const isNoAccessWorkspace = isNoAccess(workspace)

        if (!workspace || isNoAccessWorkspace) {
          Sentry.captureException(
            new Error(
              'Could not find workspace model when building folder tree'
            ),
            {
              extra: {
                folderModel,
                isNoAccessWorkspace,
              },
              tags: {
                position: 'useFormatWorkspacesTreeItems',
              },
            }
          )

          return null
        }

        if (folderModel.type === 'WORKSPACE') {
          // This is a workspace
          return {
            ...commonProps,
            label: workspace.name,
            workspaceId: workspace.id,
            type: `${workspace.type}_WORKSPACE`,
            workspace,
            url: getRouteUri(
              preferUsingBrowsePageUrls
                ? 'workspace-detail-browse'
                : 'workspace-detail',
              { workspaceId: workspace.id }
            ),
            meta: {},
          }
        }

        // If it's not a workspace, this is a folder
        return {
          ...commonProps,
          color: folderModel.color,
          label: folderModel.name ?? '(no name)',
          workspaceId: folderModel.targetId,
          type: 'FOLDER',
          folder: folderModel,
          url: getRouteUri(
            hasBrowseViewEnabled
              ? 'workspace-folder-browse'
              : 'workspace-folder',
            {
              workspaceId: workspace.id,
              folderId: folderModel.id,
            }
          ),
          meta: {},
        }
      }

      // This is a project
      if (item.itemType === 'PROJECT') {
        const projectModal = allProjects.find(
          (project) => item.itemId === project.id
        )

        if (!projectModal) {
          Sentry.captureException(
            new Error('Could not find project model when building folder tree'),
            {
              extra: {
                itemId: item.itemId,
              },
              tags: {
                position: 'useFormatWorkspacesTreeItems',
              },
            }
          )

          return null
        }

        const workspaceFolder = allFolders.find(
          ({ targetId, type }) =>
            targetId === projectModal.workspaceId && type === 'WORKSPACE'
        )

        if (!workspaceFolder) {
          Sentry.captureException(
            new Error(
              'Could not find workspace folder model for project when building folder tree'
            ),
            {
              extra: {
                itemId: item.itemId,
                projectModal,
              },
              tags: {
                position: 'useFormatWorkspacesTreeItems',
              },
            }
          )

          return null
        }

        return {
          ...commonProps,
          color: projectModal.color,
          label: projectModal.name ?? '(no name)',
          workspaceId: projectModal.workspaceId,
          type: 'PROJECT',
          project: projectModal,
          itemCount: commonProps.itemCount + 1,
          url: getRouteUri(
            preferUsingBrowsePageUrls
              ? 'workspace-project-browse'
              : 'workspace-project',
            {
              workspaceId: projectModal.workspaceId,
              projectId: projectModal.id,
            }
          ),
          meta: {
            workspaceFolderId: workspaceFolder.id,
            projectStatusId: projectModal.statusId,
          },
        }
      }

      // This is a note
      if (item.itemType === 'NOTE') {
        // Silently ignore the note
        if (!hasNotes) return null

        const note = allNotes.find(({ id }) => item.itemId === id)

        const color: COLOR = note?.color ?? item.color ?? 'gray'
        const label: string =
          note?.title.trim() || item.name?.trim() || DEFAULT_DOC_TITLE

        let workspaceId: WorkspaceSchema['id'] = ''

        if (item.originalFolderItemId) {
          const folderItemResult = findFolderItem(
            ({ id }) => id === item.originalFolderItemId
          )

          if (folderItemResult != null) {
            const [folderItem] = folderItemResult
            const folder = allFolders.find(
              ({ id }) => id === folderItem.folderId
            )

            if (folder?.targetType === 'WORKSPACE') {
              workspaceId = folder.targetId
            }
          }
        } else if (treeType !== 'SHARED') {
          const workspace = getWorkspaceByFolderId(item.folderId)

          if (workspace) {
            workspaceId = workspace.id
          }
        }

        if (treeType !== 'SHARED' && !workspaceId) {
          Sentry.captureException(
            new Error('Could not find workspace ID for note'),
            {
              level: 'warning',
              tags: {
                position: 'useFormatWorkspacesTreeItems',
              },
              extra: {
                item,
              },
            }
          )
        }

        return {
          ...commonProps,
          color,
          label,
          itemCount: commonProps.itemCount + 1,
          workspaceId,
          type: 'NOTE',
          note: note ?? null,
          url: getRouteUri('notes-detail', { noteId: item.itemId }),
          meta: {},
        }
      }

      if (item.itemType === 'VIEW') {
        const view = allViews.find(({ id }) => item.itemId === id)

        if (!view) {
          Sentry.captureException(
            new Error('Could not find view model when building user favorites'),
            {
              extra: {
                item,
              },
              tags: {
                position: 'useFormatWorkspacesTreeItems',
              },
            }
          )

          return null
        }

        const viewId = viewIdSlug(view.id)

        let url: WorkspacesTreeItem['url']
        let tooltipParts: string[] = []
        let workspaceId: WorkspacesTreeViewItem['workspaceId']
        let folderId: WorkspacesTreeViewItem['folderId'] = null
        let projectId: WorkspacesTreeViewItem['projectId'] = null

        if (view.type === 'workspace') {
          if (!item.metadata) {
            Sentry.captureException(
              new Error('Metadata missing from favorited view'),
              {
                level: 'warning',
                extra: {
                  item,
                  view,
                },
                tags: {
                  position: 'useFormatWorkspacesTreeItems',
                },
              }
            )

            // If the metadata is missing for some reason, we can at least infer
            // the workspace it should have pointed to.

            item.metadata = {
              workspaceId: view.targetId,
            }
          }

          ;({ workspaceId, folderId = null, projectId = null } = item.metadata)

          if (projectId) {
            url = getViewUri('workspace-project', {
              workspaceId,
              projectId,
              viewId,
            })
          } else if (folderId) {
            url = getViewUri('workspace-folder', {
              workspaceId,
              folderId,
              viewId,
            })
          } else {
            url = getViewUri('workspace-detail', {
              workspaceId,
              viewId,
            })
          }
        } else {
          workspaceId = view.type

          url = getViewUri(view.type, {
            viewId,
          })
        }

        switch (workspaceId) {
          case 'my-tasks':
            tooltipParts.push('My Tasks')
            break

          case 'all-tasks':
            tooltipParts.push('All Tasks')
            break

          case 'team-schedule':
            tooltipParts.push('Team Schedule')
            break

          default:
            const workspace = allWorkspaces.find(({ id }) => id === workspaceId)

            if (workspace && !isNoAccess(workspace)) {
              tooltipParts.push(workspace.name)
            }
        }

        if (folderId) {
          const folder = allFolders.find(({ id }) => id === folderId)

          if (folder?.name) {
            tooltipParts.push(folder.name)
          }
        }

        if (projectId) {
          const project = allProjects.find(({ id }) => id === projectId)

          if (project?.name) {
            tooltipParts.push(project.name)
          }
        }

        return {
          ...commonProps,
          label: view.name,
          type: item.itemType,
          url,
          view,
          workspaceId,
          folderId,
          projectId,
          meta: {},
          tooltip: tooltipParts.join(' / ') || undefined,
        }
      }

      Sentry.captureException(
        new Error('No item match found when building folder tree'),
        {
          extra: {
            item,
          },
          tags: {
            position: 'useFormatWorkspacesTreeItems',
          },
        }
      )

      return null
    },
    [
      allFolders,
      allNotes,
      allProjects,
      allViews,
      allWorkspaces,
      findFolderItem,
      getRouteUri,
      getViewUri,
      getWorkspaceByFolderId,
      hasBrowseViewEnabled,
      hasNotes,
      makeProjectContainers,
      preferUsingBrowsePageUrls,
      treeType,
    ]
  )

  return formatFolderTree
}
