import { LabelSolid } from '@motion/icons'
import { type COLOR, getRandomColor } from '@motion/shared/common'
import { Button, FormModal } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { getColorHueValue, PaletteProvider } from '@motion/ui/palette'
import { type RoleVariable } from '@motion/ui-logic/pm/project'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { ColorDropdown } from '~/global/components/dropdowns'
import { showErrorToast } from '~/global/toasts'
import { useState } from 'react'

import { findFirstDuplicateName } from '../../utils'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'add-flow-template-role-modal': PromptCallbacks<{
      roleName: string
      roleColor: COLOR
    }> & {
      roles: RoleVariable[]
    }
  }
}

type AddFlowTemplateRoleModalProps =
  ModalTriggerComponentProps<'add-flow-template-role-modal'>

export const AddFlowTemplateRoleModal = ({
  close,
  roles,
}: AddFlowTemplateRoleModalProps) => {
  const [rawRoleName, setRawRoleName] = useState('')
  const [roleColor, setRoleColor] = useState<COLOR>(getRandomColor())

  const roleName = rawRoleName.trim()
  const disabled = roleName === ''

  function handleSubmit() {
    const duplicateRole = findFirstDuplicateName([...roles, { name: roleName }])

    if (duplicateRole) {
      showErrorToast(`More than one role named "${duplicateRole.name}"`)
      return
    }

    close({ roleName, roleColor })
  }

  return (
    <FormModal
      title='Add role'
      visible
      onClose={close}
      submitAction={{
        onAction: handleSubmit,
        disabled,
        text: 'Add role',
      }}
    >
      {/* Reversed to fix form autofocus */}
      <div className='flex flex-row-reverse gap-3'>
        <TextField
          value={rawRoleName}
          onChange={setRawRoleName}
          fullWidth
          placeholder='Enter role name'
        />

        <ColorDropdown
          selectedColor={roleColor}
          onChange={(colorOption) => setRoleColor(colorOption)}
        >
          <Button variant='outlined' sentiment='neutral' iconOnly>
            <PaletteProvider colorHue={getColorHueValue(roleColor)}>
              <LabelSolid className='!w-4 !h-4 !text-palette-highlight-default' />
            </PaletteProvider>
          </Button>
        </ColorDropdown>
      </div>
    </FormModal>
  )
}
