import { showToast } from '@motion/ui/base'

import { ModalTrigger } from '~/areas/modals'
import { LoadingPage } from '~/areas/project-management/pages/pm-revamp/common/loading-page'
import { useValidRoute } from '~/areas/project-management/pages/pm-v3/routes'
import { ConnectedPageContext } from '~/areas/project-management/pages/pm-v3/shell/connected-page-context'
import {
  ConnectedCreateViewModal,
  ConnectedSaveViewModal,
} from '~/areas/project-management/pages/pm-v3/views/modals'
import { ErrorPage } from '~/global/components/error-page'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router'

import { PageContainer } from './styled'

export const PMShell = () => {
  const routeValidation = useValidRoute()
  const navigate = useNavigate()

  useEffect(() => {
    if (routeValidation != null) {
      showToast('error', 'The route is invalid. Navigating to the right page.')
      navigate(routeValidation, {
        replace: true,
      })
    }
  }, [navigate, routeValidation])

  if (routeValidation != null) {
    // Rendering loading while it navigates
    return <LoadingPage id='pmv4-shell-redirect' />
  }

  return (
    <ErrorPage>
      <ConnectedPageContext name='shell'>
        <PageContainer>
          <Outlet />
        </PageContainer>

        <ModalTrigger name='save-view-v2' component={ConnectedSaveViewModal} />
        <ModalTrigger
          name='create-view-v2'
          component={ConnectedCreateViewModal}
        />
      </ConnectedPageContext>
    </ErrorPage>
  )
}
