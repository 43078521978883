import { AiActionItemSolid } from '@motion/icons'
import { Button, ButtonGroup, Tooltip } from '@motion/ui/base'

import { useRejectActionItem } from '~/global/rpc/v2'

import { useActionItemForm } from '../../hooks'
import { useActionItemContext } from '../../provider'

export function Banner() {
  const { initialActionItem, closeModal } = useActionItemContext()
  const { canApprove } = useActionItemForm()
  const { mutateAsync: reject, isPending: isRejecting } = useRejectActionItem()

  const onReject = async () => {
    await reject({
      actionItemId: initialActionItem.id,
    })
    closeModal()
  }

  return (
    <div className='flex justify-center items-center gap-4 px-3 py-1'>
      <div className='flex items-center gap-1 text-sm text-semantic-purple-text-default'>
        <AiActionItemSolid className='size-3 text-semantic-purple-icon-default' />
        <span>Approve this action item to convert it to a task.</span>
      </div>

      <ButtonGroup>
        <Button
          sentiment='error'
          variant='outlined'
          size='small'
          onClick={onReject}
          loading={isRejecting}
        >
          Reject
        </Button>
        <Tooltip
          content={
            !canApprove ? 'Select a workspace and assignee to approve' : null
          }
        >
          <Button
            sentiment={!canApprove ? 'neutral' : 'success'}
            variant='outlined'
            size='small'
            type='submit'
            disabled={!canApprove}
          >
            Approve
          </Button>
        </Tooltip>
      </ButtonGroup>
    </div>
  )
}
