import { Sentry } from '@motion/web-base/sentry'
import { useModalApi } from '@motion/web-common/modals'

import { useCallback } from 'react'

import { showErrorToast } from '../toasts'

export function useOpenFlowsAiModal() {
  const modalApi = useModalApi()

  return useCallback(
    (workspaceId: string | undefined, onClose?: () => void) => {
      if (!workspaceId) {
        Sentry.captureException("No workspace ID found. Can't open AI modal.", {
          tags: {
            position: 'CreateFlowTemplateModalContent',
          },
        })

        showErrorToast("No workspace ID found. Can't open AI modal.")
        return
      }

      onClose?.()

      // NOTE: Can't open this modal immediately after closing, looks like it will cause the following modal to not be registered
      setTimeout(() => {
        modalApi.open('flows-ai-modal', {
          workspaceId,
        })
      }, 500)
    },
    [modalApi]
  )
}
