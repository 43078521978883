import { useShortcut } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { getWindowData } from '@motion/web-base/env'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { useNavigate } from 'react-router'

import { useTaskModalUrl } from '../navigation'

export const useGlobalKeyboardShortcuts = () => {
  const modalApi = useModalApi()
  const buildTaskModalUrl = useTaskModalUrl()
  const navigate = useNavigate()

  const { isElectron, isMac } = getWindowData()

  if (!__IS_PROD__) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const devPanelEnabled = useHasTreatment('development-panel')
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useShortcut(
      'mod+/',
      () => {
        modalApi.open('motion-dev-modal')
      },
      {
        enabled: devPanelEnabled,
      }
    )
  }

  useShortcut(
    isMac ? 'Alt+Space' : 'Control+Space',
    () => {
      recordAnalyticsEvent('SHORTCUT_OPT_SPACE', {
        domain: urlToSite(window.location.href),
      })
      navigate(buildTaskModalUrl({ task: 'new' }))
    },
    {
      enabled: !isElectron,
    }
  )

  useShortcut('mod+k', () => modalApi.open('search', {}))
}

// full=false strips everything from the first "/" onwards, remove trailing slash
const urlToSite = (url: string, full = false) => {
  if (!url) {
    return null
  }
  let result = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
  result = full ? result : result.split('/')[0]
  return result.endsWith('/') ? result.slice(0, -1) : result
}
