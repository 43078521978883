import { templateStr } from '@motion/react-core/strings'
import { useTheme } from '@motion/theme/dom'
import { Text } from '@motion/ui/base'

import { twMerge } from 'tailwind-merge'

export type LoadingScreenProps = {
  /**
   * Title to display below the animation
   */
  title: string
  /**
   * Custom message to display below the title
   */
  message?: string
  /**
   * Additional classes to apply to the container
   */
  className?: string
}

export function LoadingScreen({
  title,
  message,
  className,
}: LoadingScreenProps) {
  const { theme } = useTheme()

  return (
    <div
      className={twMerge(
        'flex flex-col gap-6 items-center justify-center text-center',
        className
      )}
    >
      <video
        muted
        autoPlay
        loop
        playsInline
        className='w-full max-w-[350px] h-[132px]'
      >
        <source
          src={templateStr(
            '/images/animations/ai-pwt-animation-{{theme}}.webm',
            {
              theme,
            }
          )}
          type='video/webm'
        />
        {/* Fallback in case browser doesn't support webm */}
        <source
          src={templateStr(
            '/images/animations/ai-pwt-animation-{{theme}}.mp4',
            {
              theme,
            }
          )}
          type='video/mp4'
        />
      </video>
      <h2 className='text-base font-semibold text-center text-semantic-neutral-text-subtle'>
        {title}
      </h2>
      {message && (
        <Text weight='medium' size='sm' sentiment='subtle' alignment='center'>
          {message}
        </Text>
      )}
    </div>
  )
}
