import { getCurrentUser } from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { client } from '@motion/web-common/rpc'

import { ConnectedAPISettings } from '~/components/Settings/Pages/APISettings'
import { BookingSettings } from '~/components/Settings/Pages/BookingSettings/BookingSettings'
import { CalendarSettings } from '~/components/Settings/Pages/calendar-settings'
import { ConferenceSettings } from '~/components/Settings/Pages/ConferenceSettings/ConferenceSettings'
import { ConnectedDesktopSettings } from '~/components/Settings/Pages/DesktopSettings'
import { IntegrationSettings } from '~/components/Settings/Pages/IntegrationSettings'
import { NotificationSettings } from '~/components/Settings/Pages/NotificationSettings/NotificationSettings'
import { Referrals } from '~/components/Settings/Pages/referrals/referrals'
import { ConnectedScheduleSettings } from '~/components/Settings/Pages/ScheduleSettings'
import { StorageSettings } from '~/components/Settings/Pages/StorageSettings/storage-settings'
import { TaskManagerSettings } from '~/components/Settings/Pages/TaskManagerSettings/TaskManagerSettings'
import { TimezoneSettings } from '~/components/Settings/Pages/TimezoneSettings/TimezoneSettings'
import { ViewSettings } from '~/components/Settings/Pages/ViewSettings/ViewSettings'
import { ConnectedTeamLandingPage } from '~/components/Team'
import { ConnectedCreateTeamPage } from '~/components/Team/connected-create-team-page'
import { ConnectedTeamMembersPage } from '~/components/Team/connected-team-members-page'
import { type MotionRoute } from '~/routing/api'
import { redirect } from 'react-router'

import {
  AccountSettingsPage,
  BillingSettingsPage,
  PrivacySettingsPage,
  TaskDefaultsSettingsPage,
  workspaceRoutes,
} from './pages'
import { SettingsPage } from './settings-page'

declare module '~/routing/hooks/navigate-by-id-params' {
  interface NavigateByIdParams {
    settings: undefined
    'settings-calendar': undefined
  }
}

export const settingsRoutes: MotionRoute[] = [
  {
    id: 'settings',
    path: 'settings',
    element: <SettingsPage />,
    loader: async (args) => {
      if (
        args.request.url.endsWith('/scheduler') ||
        args.request.url.endsWith('/scheduler/')
      ) {
        return redirect('/web/booking')
      }

      if (
        args.request.url.endsWith('/web/settings') ||
        args.request.url.endsWith('/web/settings/')
      ) {
        return redirect('/web/settings/calendar')
      }

      const ensureFeaturePermissionsDataLoaded =
        API.usersV2.featurePermissionsLoader(client)

      await ensureFeaturePermissionsDataLoaded()

      return {}
    },
    children: [
      {
        id: 'settings-calendar',
        path: 'calendar',
        element: <CalendarSettings />,
      },
      {
        path: 'api',
        element: <ConnectedAPISettings />,
        loader: async () => {
          const ensureFeaturePermissionsDataLoaded =
            API.usersV2.featurePermissionsLoader(client)

          const featurePermissions = await ensureFeaturePermissionsDataLoaded()

          const userId = getCurrentUser(client)?.id ?? null
          const hasFeature =
            userId != null &&
            !!featurePermissions?.models?.userFeaturePermissions?.[userId]?.[
              'api'
            ]

          if (!hasFeature) {
            return redirect('/web/settings')
          }

          return {}
        },
      },
      { path: 'privacy', element: <PrivacySettingsPage /> },
      { path: 'view', element: <ViewSettings /> },
      { path: 'conference', element: <ConferenceSettings /> },
      { path: 'timezone', element: <TimezoneSettings /> },
      { path: 'schedule', element: <ConnectedScheduleSettings /> },
      { path: 'desktop', element: <ConnectedDesktopSettings /> },
      {
        path: 'integration',
        element: <IntegrationSettings />,
        metric: 'INTEGRATION_SETTINGS_PAGE_VIEWED',
      },
      {
        path: 'booking',
        element: <BookingSettings />,
        loader: async () => {
          const ensureFeaturePermissionsDataLoaded =
            API.usersV2.featurePermissionsLoader(client)

          const featurePermissions = await ensureFeaturePermissionsDataLoaded()

          const userId = getCurrentUser(client)?.id ?? null
          const hasFeature =
            userId != null &&
            !!featurePermissions?.models?.userFeaturePermissions?.[userId]?.[
              'bookingLinks'
            ]

          if (!hasFeature) {
            return redirect('/web/settings')
          }

          return {}
        },
      },
      { path: 'task', element: <TaskManagerSettings /> },
      { path: 'notifications', element: <NotificationSettings /> },
      {
        path: 'account',
        element: <AccountSettingsPage />,
        metric: 'ACCOUNT_SETTINGS_PAGE_VIEWED',
      },
      {
        path: 'team',
        element: <ConnectedTeamLandingPage />,
      },
      {
        path: 'team/members',
        element: <ConnectedTeamMembersPage />,
      },
      { path: 'team/create', element: <ConnectedCreateTeamPage /> },
      ...workspaceRoutes,
      { path: 'referrals', element: <Referrals /> },
      {
        path: 'billing',
        element: <BillingSettingsPage />,
        metric: 'BILLING_SETTINGS_PAGE_VIEWED',
      },
      {
        path: 'task-defaults',
        element: <TaskDefaultsSettingsPage />,
        metric: 'TASK_DEFAULT_SETTINGS_PAGE_VIEWED',
      },
      {
        path: 'storage',
        element: <StorageSettings />,
        metric: 'STORAGE_SETTINGS_PAGE_VIEWED',
      },
    ],
    errorElement: <div>error</div>,
  },
]
