import { useStageTaskCardFields } from '~/areas/flows/contexts'

import {
  TaskDefinitionRelativeDeadlineField,
  TaskDefinitionRelativeStartField,
} from './relative-dates'

export function RelativeDateFields() {
  const {
    stageColor,
    stageDuration,
    fields: { relativeStart, relativeDeadline, duration },
  } = useStageTaskCardFields()

  const fieldSize = 'xsmall'

  return (
    <>
      <TaskDefinitionRelativeStartField
        {...relativeStart}
        color={stageColor}
        size={fieldSize}
        stageDuration={stageDuration}
      />
      <TaskDefinitionRelativeDeadlineField
        {...relativeDeadline}
        color={stageColor}
        size={fieldSize}
        stageDuration={stageDuration}
      />

      {/* add a placeholder to take up the whole row as reminder tasks dont show start date */}
      {duration[0] === 0 && <div className='relative-date-placeholder' />}
    </>
  )
}
