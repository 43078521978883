import { CrownSolid } from '@motion/icons'

import { TieredPricingUpsellButton } from '~/areas/tiered-pricing'

export function ActivityUpsellSection() {
  return (
    <div className='p-3 rounded-sm bg-semantic-warning-bg-default flex flex-col items-center gap-2'>
      <CrownSolid className='size-4' />
      <span className='text-semantic-neutral-text-default'>
        Upgrade to add comments and see activity updates for tasks, projects and
        notes.
      </span>
      <span>
        <TieredPricingUpsellButton
          tier='PRO'
          feature='projectAndTaskActivity'
          featureLocked
          hideIcon
          fullWidth={false}
        >
          Upgrade to Motion Pro
        </TieredPricingUpsellButton>
      </span>
    </div>
  )
}
