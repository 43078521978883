import { type FolderSchema, type NoteSchema } from '@motion/rpc-types'

import { useFindFolder, useFindFolderItem } from '~/global/hooks'
import { useNoteById } from '~/global/rpc/v2'
import { useMemo } from 'react'

type ParentId = string | null
type ParentType = 'FOLDER' | 'PROJECT' | 'WORKSPACE' | 'NOTE' | 'USER' | null

export type UseNoteLocationParams = {
  noteId: NoteSchema['id']
}

export function useNoteLocation({
  noteId,
}: UseNoteLocationParams): [ParentId, ParentType] {
  const { data: note } = useNoteById({ id: noteId })

  const findFolderItem = useFindFolderItem()
  const findFolder = useFindFolder()

  return useMemo(() => {
    if (note == null) {
      return [null, null]
    }

    const [folderItem, parentFolderItem] =
      findFolderItem(({ itemId }) => itemId === note.id) || []

    if (parentFolderItem != null) {
      if (parentFolderItem.itemType === 'NOTE') {
        return [parentFolderItem.itemId, 'NOTE']
      }

      if (parentFolderItem.itemType === 'PROJECT') {
        return [parentFolderItem.itemId, 'PROJECT']
      }
    }

    if (folderItem?.folderId != null) {
      const folder = findFolder((folder) => folder.id === folderItem.folderId)

      if (
        folder != null &&
        isAllowedFolderTargetType(folder.targetType) &&
        isAllowedFolderType(folder.type)
      ) {
        return [folder.targetId, folder.targetType]
      }

      return [folderItem.folderId, 'FOLDER']
    }

    if (note.targetType === 'WORKSPACE') {
      return [note.targetId, 'WORKSPACE']
    }

    return [null, null]
  }, [findFolderItem, findFolder, note])
}

function isAllowedFolderTargetType(
  folderTargetType: FolderSchema['targetType']
): folderTargetType is Exclude<
  FolderSchema['targetType'],
  'TEAM' | 'VIEW' | 'USER'
> {
  return folderTargetType !== 'TEAM' && folderTargetType !== 'VIEW'
}

function isAllowedFolderType(
  folderType: FolderSchema['type']
): folderType is Exclude<
  FolderSchema['type'],
  'USER' | 'SHARED' | 'MEETING_INSIGHTS'
> {
  return (
    folderType !== 'USER' &&
    folderType !== 'SHARED' &&
    folderType !== 'MEETING_INSIGHTS'
  )
}
