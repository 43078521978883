import { ChevronDownOutline } from '@motion/icons'
import { NOOP_FUNCTION } from '@motion/utils/function'

import { twMerge } from 'tailwind-merge'

import {
  chevronClasses,
  inputContainerClasses,
  inputDisabledClasses,
  inputFocusClasses,
  inputIconColorClasses,
  inputIconSizeClasses,
  inputTextClasses,
  inputUnselectedClasses,
} from '../GeneralComponentStyles'

export interface SelectOption {
  labelIcon?: React.ReactNode
  label: string
  customRender?: React.ReactNode
  value: any
  disabled?: boolean
  key?: string
}

// TODO: Delete this component once it's no longer used
export const DefaultSelectTrigger = ({
  selectedLabel,
  selectedLabelIcon,
  placeholder = '',
  disabled = false,
  className = '',
  onClick = NOOP_FUNCTION,
  ...props
}: {
  selectedLabel: string
  selectedLabelIcon?: React.ReactNode
  placeholder?: string
  disabled?: boolean
  className?: string
  onClick?: () => void
}) => {
  return (
    <button
      {...props}
      className={twMerge(
        'relative h-8 w-full cursor-pointer rounded border pl-3 pr-10 text-left',
        inputContainerClasses,
        inputFocusClasses,
        disabled && inputDisabledClasses,
        className
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <span
        className={`flex items-center gap-2 truncate ${
          selectedLabel ? inputTextClasses : inputUnselectedClasses
        }`}
      >
        {selectedLabelIcon && (
          <div className={twMerge(inputIconSizeClasses, inputIconColorClasses)}>
            {selectedLabelIcon}
          </div>
        )}
        {selectedLabel || placeholder}
      </span>
      <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
        <ChevronDownOutline className={chevronClasses} aria-hidden='true' />
      </span>
    </button>
  )
}
