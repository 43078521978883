import { TrashSolid } from '@motion/icons'
import { useDependantState } from '@motion/react-core/hooks'
import { isNoneId } from '@motion/shared/identifiers'
import { Button, ButtonGroup, Text } from '@motion/ui/base'
import { Select, TextField } from '@motion/ui/forms'
import { pick } from '@motion/utils/core'
import { type AgentVariableSchema, AgentVariableType } from '@motion/zod/client'

import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { agentVariableTypeToData } from './shared'

export type WorkflowInputDropdownContentProps = {
  close: (inputValue: AgentVariableSchema | null) => void
  onDelete?: (id: string) => void
  agentVariable?: AgentVariableSchema
}

export function WorkflowInputDropdownContent({
  close,
  onDelete,
  agentVariable = createDefaultTextVariable(),
}: WorkflowInputDropdownContentProps) {
  const [isDirty, setIsDirty] = useState(false)
  const [internalVariable, setInternalVariable] = useDependantState(
    () => agentVariable,
    [agentVariable],
    { freezeDependencyUpdates: isDirty }
  )

  const canBeDeleted = !isNoneId(agentVariable.id) && onDelete != null

  return (
    <form
      className='w-64'
      onSubmit={(ev) => {
        ev.preventDefault()
        close(internalVariable)
      }}
    >
      <section className='w-full p-3 flex flex-col gap-4'>
        <TextField
          label='Name'
          placeholder='Input name'
          value={internalVariable.label}
          onChange={(v) => {
            setIsDirty(true)
            setInternalVariable((prev) => ({ ...prev, label: v }))
          }}
        />
        <TextField
          label='Description'
          placeholder='Explain how this input should be used'
          multiline
          autoSize={{ minRows: 2, maxRows: 5 }}
          value={internalVariable.description}
          onChange={(v) => {
            setIsDirty(true)
            setInternalVariable((prev) => ({ ...prev, description: v }))
          }}
        />
        <Select
          label='Type'
          value={internalVariable.type}
          onChange={(v) => {
            setIsDirty(true)
            setInternalVariable((prev) => convertInputTo(prev, v))
          }}
          options={AgentVariableType.map((variable) => {
            const { label, icon: Icon } = agentVariableTypeToData[variable]
            return {
              content: (
                <div className='flex flex-row gap-1.5'>
                  <Icon className='size-4 text-semantic-neutral-icon-default shrink-0' />
                  <Text size='sm' truncate>
                    {label}
                  </Text>
                </div>
              ),
              value: variable,
            }
          })}
        />
      </section>
      <footer
        className={twMerge(
          'p-3 border-t border-semantic-neutral-border-default',
          canBeDeleted && 'flex flex-row justify-between'
        )}
      >
        {canBeDeleted && (
          <Button
            sentiment='neutral'
            variant='muted'
            size='small'
            onClick={() => {
              onDelete(agentVariable.id)
            }}
          >
            <TrashSolid />
            Delete
          </Button>
        )}
        <ButtonGroup alignment='end'>
          <Button
            sentiment='neutral'
            variant='muted'
            size='small'
            onClick={() => {
              close(null)
            }}
          >
            Cancel
          </Button>
          <Button type='submit' sentiment='primary' size='small'>
            Save
          </Button>
        </ButtonGroup>
      </footer>
    </form>
  )
}

function createDefaultTextVariable(): AgentVariableSchema {
  return {
    id: '',
    label: '',
    description: '',
    type: 'TEXT',
  }
}

function convertInputTo(
  agentVariable: AgentVariableSchema,
  type: AgentVariableType
): AgentVariableSchema {
  const common = pick(agentVariable, ['id', 'label', 'description'])

  if (type === 'DOCUMENTS') {
    return {
      type,
      ...common,
    }
  }

  return {
    type,
    ...common,
  }
}
