import {
  convertDateIntervalToDays,
  type DeadlineInterval,
} from '@motion/shared/flows'
import type { TaskDefinitionFormRelativeInterval } from '@motion/ui-logic/pm/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { calculateAdjustedRelativeDurations } from '~/areas/flows/shared-form/utils'
import { useCallback } from 'react'

import { useTaskDefinitionForm } from '../use-task-definition-form'

export function useTaskDefinitionModalRelativeDateUpdater<
  T extends TaskDefinitionFormRelativeInterval,
>(
  onChange: (v: T) => void,
  oldValue: T,
  stageDuration: DeadlineInterval | undefined,
  type: 'start' | 'due'
): (valueOrFn: T | ((prev: T) => T)) => void {
  const {
    form: { getValues, setValue },
  } = useTaskDefinitionForm()

  return useCallback(
    (valueOrFn) => {
      const newValue =
        typeof valueOrFn === 'function' ? valueOrFn(oldValue) : valueOrFn

      onChange(newValue)

      const stageDurationInDays = convertDateIntervalToDays(
        stageDuration ?? { value: 0, unit: 'DAYS' }
      )

      const { duration: newDuration, referenceType: newReferenceType } =
        newValue
      const { duration: otherDuration, referenceType: otherReferenceType } =
        getValues(`${type === 'start' ? 'due' : 'start'}RelativeInterval`)

      recordAnalyticsEvent('TASK_DEFINITION_RELATIVE_DATE_CHANGED', {
        taskValue: type === 'start' ? 'startDate' : 'deadline',
        target: newReferenceType,
        relative: newDuration.sign === 1 ? 'plus' : 'minus',
        type: newDuration.unit,
        value: newDuration.value,
      })

      const startDuration = type === 'start' ? newDuration : otherDuration
      const startReferenceType =
        type === 'start' ? newReferenceType : otherReferenceType
      const dueDuration = type === 'start' ? otherDuration : newDuration
      const dueReferenceType =
        type === 'start' ? otherReferenceType : newReferenceType

      const adjustedDates = calculateAdjustedRelativeDurations(
        stageDurationInDays,
        {
          startDuration,
          startReferenceType,
          dueDuration,
          dueReferenceType,
          lock: type,
        }
      )

      if (adjustedDates) {
        if (adjustedDates.start) {
          setValue(`startRelativeInterval.duration`, {
            ...startDuration,
            ...adjustedDates.start,
          })
        }

        if (adjustedDates.due) {
          setValue(`dueRelativeInterval.duration`, {
            ...dueDuration,
            ...adjustedDates.due,
          })
        }
      }
    },
    [oldValue, type, onChange, stageDuration, getValues, setValue]
  )
}
