import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'

export const AddStageButton = classed('button', {
  base: `
    flex flex-col items-center justify-center gap-6
    w-full h-full
    p-4
    text-semantic-neutral-text-subtle
    bg-semantic-neutral-surface-raised-bg-subtlest
    rounded-[4px]
    hover:bg-semantic-neutral-surface-raised-bg-subtle
    focus-visible:ring focus-visible:ring-semantic-neutral-ring-default
  `,
})

export const InlineHint = classed('div', {
  base: `
    flex flex-col text-left p-2 gap-2.5
    text-semantic-neutral-text-subtle text-2xs leading-4
  `,
  variants: {
    withBackground: {
      true: `
        rounded border border-semantic-neutral-border-default bg-semantic-neutral-bg-subtle shadow-sm
      `,
    },
  },
})

export const DashedStageButton = classed(Button, {
  base: `
    flex items-center gap-1.5 p-2.5
    border-dashed border-semantic-neutral-border-default border rounded-sm
    text-semantic-neutral-text-default
    cursor-pointer

    hover:text-semantic-neutral-text-default
    hover:border-solid
    hover:border-semantic-neutral-border-hover hover:bg-semantic-neutral-bg-hover
  `,
})
