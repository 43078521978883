import {
  type UpsertStageSchema,
  type VariableDefinitionSchema,
} from '@motion/rpc-types'
import {
  getProjectInitialDueDate,
  mapCustomFieldToFieldArrayWithValue,
} from '@motion/ui-logic'
import {
  type V2SetupProjectFormFields,
  type VariableArg,
} from '@motion/ui-logic/pm/project'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import {
  DEFAULT_TEXT_VARIABLE_KEYS,
  isRoleVariable,
  isTextVariable,
} from '~/areas/flows/utils'
import {
  useProjectDefinition,
  useProjectDefinitionWithStages,
} from '~/global/hooks'
import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'

export type UseInitialFormDataArgs = Pick<
  V2SetupProjectFormFields,
  | 'workspaceId'
  | 'name'
  | 'description'
  | 'uploadedFileIds'
  | 'prompt'
  | 'nameReason'
  | 'initialPersonVariables'
  | 'initialTextVariables'
> & {
  initialStages: UpsertStageSchema[]
  projectDefinitionId: string
}

export function useInitialFormData(
  formDefaults: UseInitialFormDataArgs
): V2SetupProjectFormFields {
  const projectDefinition = useProjectDefinition(
    formDefaults.projectDefinitionId
  )
  const projectDefinitionWithStages =
    useProjectDefinitionWithStages(projectDefinition)

  // const forProject = useProject(forProjectId) // TODO after M1

  if (projectDefinition == null || projectDefinitionWithStages == null) {
    throw new Error('Project definition not found')
  }

  const workspaceId = formDefaults.workspaceId
  if (workspaceId == null) {
    throw new Error('Workspace id not defined')
  }
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(workspaceId)

  // Prefill the setup project form with some data from a task, if provided
  // const taskData = useInternalTaskPrefill(forTaskId) // TODO after M1

  const today = DateTime.now().startOf('day')

  const startDateTime = today

  const getInitialVariables = useCallback(
    (type: 'role' | 'text'): VariableArg[] => {
      const filterVars =
        type === 'role'
          ? isRoleVariable
          : (variable: VariableDefinitionSchema) =>
              isTextVariable(variable) &&
              !DEFAULT_TEXT_VARIABLE_KEYS.includes(variable.key)

      const defaultValue = type === 'role' ? null : ''

      const variables: (VariableArg & { name: string })[] =
        projectDefinitionWithStages.stages.flatMap((stage) =>
          stage.variables.filter(filterVars).map((variable) => ({
            variableId: variable.id,
            value: defaultValue,
            stageId: stage.id,
            name: variable.name,
          }))
        )

      const initialVariables =
        type === 'role'
          ? formDefaults.initialPersonVariables
          : formDefaults.initialTextVariables

      if (initialVariables != null) {
        return variables.map((variable) => {
          const matchingInitial = initialVariables.find(
            (initial) => initial.name === variable.name
          )

          if (matchingInitial != null) {
            variable.value = matchingInitial.value
            variable.hasInitialValue = true
          }

          return variable
        })
      }

      return variables
    },
    [
      formDefaults.initialPersonVariables,
      formDefaults.initialTextVariables,
      projectDefinitionWithStages.stages,
    ]
  )

  return useMemo(() => {
    const roleAssignees = getInitialVariables('role')
    const textReplacements = getInitialVariables('text')

    const customFieldValuesFieldArray = workspaceCustomFields.map((field) =>
      mapCustomFieldToFieldArrayWithValue(
        field,
        projectDefinitionWithStages?.customFieldValues ?? {}
      )
    )

    return {
      projectDefinition: projectDefinitionWithStages,
      workspaceId,
      name: formDefaults.name ?? '',
      description: formDefaults.description ?? '',
      uploadedFileIds: formDefaults.uploadedFileIds ?? [],
      startDate: startDateTime.toISODate(),
      dueDate:
        formDefaults.initialStages.at(-1)?.dueDate ??
        getProjectInitialDueDate(),
      roleAssignees,
      textReplacements,
      initialPersonVariables: formDefaults.initialPersonVariables ?? [],
      initialTextVariables: formDefaults.initialTextVariables ?? [],
      initialStages: formDefaults.initialStages ?? [],
      customFieldValuesFieldArray,
      customFieldSyncInstanceIds: [],
      step: 'name',
      prompt: formDefaults.prompt,
      nameReason: formDefaults.nameReason,
      mode: formDefaults.prompt != null ? 'ai' : 'manual',
    }
  }, [
    formDefaults,
    getInitialVariables,
    projectDefinitionWithStages,
    startDateTime,
    workspaceCustomFields,
    workspaceId,
  ])
}
