import { XSolid } from '@motion/icons'
import { AlertModal, IconButton, Modal } from '@motion/ui/base'
import { isEnterpriseEmail } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { WorkEmailModal } from '@motion/web-billing'

import Cal, { getCalApi } from '@calcom/embed-react'
import { useEffect, useState } from 'react'

type BookDemoModalProps = {
  isOpen: boolean
  userEmail: string
  onClose: () => void
}

export const BookDemoCallModal = ({
  isOpen,
  userEmail,
  onClose,
}: BookDemoModalProps) => {
  const [workEmail, setWorkEmail] = useState(userEmail)
  const [showBookingModal, setShowBookingModal] = useState(false)

  const [showWorkEmailModal, setShowWorkEmailModal] = useState(false)
  const [calFormUrl, setCalFormUrl] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!isEnterpriseEmail(workEmail)) {
      return setCalFormUrl(undefined)
    }

    const params = new URLSearchParams({ email: workEmail }).toString()
    setCalFormUrl('forms/be0e1763-b207-4bb8-aae4-4c7b272643c8?' + params)
  }, [workEmail])

  useEffect(() => {
    async function initializeCal() {
      const cal = await getCalApi()
      cal('ui', {
        theme: 'light',
        styles: {
          branding: {
            brandColor: '#3C61DD',
          },
        },
        layout: 'month_view',
      })
      cal('on', {
        action: 'routed',
        callback: (e) => {
          const detailData = e.detail.data
          if ('actionType' in detailData) {
            if (detailData.actionType === 'externalRedirectUrl') {
              // Case when the user gets redirected back to the page
              document.cookie = 'onboarding_survey_submitted=true'
            } else if (detailData.actionType === 'eventTypeRedirectUrl') {
              // Case when the user qualifies and goes to booking page
              document.cookie = 'onboarding_survey_qualified=true'
            }
          }
        },
      })
      cal('on', {
        action: 'bookingSuccessfulV2',
        callback: () => {
          document.cookie = 'onboarding_survey_submitted=true'
          onClose()
        },
      })
    }
    initializeCal()
  }, [onClose])

  if (showWorkEmailModal) {
    return (
      <WorkEmailModal
        isOpen={showWorkEmailModal}
        onClose={() => setShowWorkEmailModal(false)}
        onAction={(newEmail, onError) => {
          if (isEnterpriseEmail(newEmail)) {
            setWorkEmail(newEmail)
            setShowWorkEmailModal(false)
            setShowBookingModal(true)

            recordAnalyticsEvent('ENTERPRISE_WORK_EMAIL_SUBMITTED', {
              email: newEmail,
              location: 'onboardingKycSurvey',
            })
          } else {
            onError()
          }
        }}
      />
    )
  }

  if (showBookingModal && calFormUrl) {
    return (
      <Modal visible onClose={() => {}}>
        <div className='min-w-[800px]'>
          <div className='flex flex-row justify-end p-2'>
            <IconButton
              icon={XSolid}
              size='small'
              sentiment='neutral'
              variant='muted'
              onClick={() => {
                onClose()
                recordAnalyticsEvent('KYC_ONBOARDING_BOOK_MODAL_SUBMIT', {
                  action: 'decline_questionnaire',
                })
              }}
            />
          </div>
          <Cal calLink={calFormUrl} />
        </div>
      </Modal>
    )
  }

  return (
    <AlertModal
      visible={isOpen}
      onClose={() => {}}
      blocking
      title='Book a demo call to better evaluate Motion for your business'
      actions={[
        {
          label: 'Book demo',
          onAction: () => {
            if (!isEnterpriseEmail(userEmail)) {
              setShowWorkEmailModal(true)
            } else {
              recordAnalyticsEvent('KYC_ONBOARDING_BOOK_MODAL_SUBMIT', {
                action: 'book',
              })
              recordAnalyticsEvent('ENTERPRISE_WORK_EMAIL_SUBMITTED', {
                email: userEmail,
                location: 'onboardingKycSurvey',
              })
              setShowBookingModal(true)
            }
          },
          sentiment: 'primary',
        },
        {
          label: 'Not right now',
          onAction: () => {
            recordAnalyticsEvent('KYC_ONBOARDING_BOOK_MODAL_SUBMIT', {
              action: 'decline',
            })
            onClose()
          },
          sentiment: 'neutral',
          variant: 'muted',
        },
      ]}
    >
      <p className='text-semantic-neutral-text-subtle'>
        Discover how Motion can streamline your business processes, enhance team
        collaboration, and make your team an average of 30% more productive.
        Schedule a personalized demo to explore tailored solutions and see
        Motion in action.
      </p>
    </AlertModal>
  )
}
