import { XSolid } from '@motion/icons'
import { NOOP_FUNCTION } from '@motion/utils/function'

import { type ReactNode, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { TextButton } from '../Button/TextButton/TextButton'
import { WidgetModal } from '../WidgetModal'

type Size = 'small' | 'medium' | 'large'
type SizeClassMap = { [key in Size]: string }
export interface DialogProps {
  isOpen: boolean
  children: ReactNode
  title?: string
  onClose?: () => void
  showClose?: boolean
  padded?: boolean
  className?: string
  size?: Size
  navbarClickable?: boolean
  header?: React.ReactElement<unknown>
  frameStyle?: React.CSSProperties
}

const sizeClasses: SizeClassMap = {
  small: 'max-w-sm',
  medium: 'max-w-md',
  large: 'max-w-max',
}

export const Dialog = ({
  children,
  title,
  isOpen,
  onClose = NOOP_FUNCTION,
  showClose = false,
  padded = true,
  className = '',
  size = 'small',
  navbarClickable = false,
  header,
  frameStyle,
}: DialogProps) => {
  const frameRef = useRef<HTMLDivElement>(null)

  if (!isOpen) {
    return <div />
  }

  return (
    <div
      id='motion-task-input-iframe'
      className='motion-iframe'
      style={{
        // In order to place underneath other elements
        position: 'fixed',
        height: navbarClickable ? 'calc(100% - 60px)' : '100%',
        width: '100%',
        display: 'flex',
        zIndex: 20,
        top: navbarClickable ? '60px' : '0',
        ...frameStyle,
      }}
    >
      <WidgetModal
        backgroundClickDismiss
        stopPropagation
        visibilityHandler={onClose}
        className={twMerge(
          'fixed inset-0 !bg-black/80',
          navbarClickable && 'top-[60px]'
        )}
        ref={frameRef}
      >
        <div
          className={twMerge(
            'bg-light-100 dark:bg-dark-1000 border-light-500 w-full transform overflow-hidden rounded border text-left align-middle shadow-xl transition-all dark:border-transparent',
            padded && 'p-4',
            sizeClasses[size],
            className
          )}
        >
          {header}
          <div
            className={twMerge(
              'flex items-center justify-between',
              !padded && (title || showClose) && 'py-3 px-4'
            )}
          >
            {title && (
              <div className='text-light-1200 dark:text-dark-100 text-base font-medium leading-6'>
                {title}
              </div>
            )}
            {showClose && (
              <TextButton icon={XSolid} onClick={onClose} className='ml-auto' />
            )}
          </div>

          {children}
        </div>
      </WidgetModal>
    </div>
  )
}
