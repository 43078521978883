import { entries, READONLY_EMPTY_OBJECT } from '@motion/utils/object'

import { useSearchParams } from '~/routing'
import { useCallback } from 'react'
import { useLocation, useParams } from 'react-router-dom'

export type StageModalUrlProps = {
  workspaceId: string
}

export type StageUrlSearchParams = {
  pathName?: string
  stage?: 'new' | 'edit'
  stageId?: string
  forWorkspace?: string
}

export function useStageModalUrl() {
  const { workspaceId: workspaceIdParam } = useParams<StageModalUrlProps>()
  const { pathname: currentPath, search } = useLocation()

  return useCallback(
    (params: StageUrlSearchParams = READONLY_EMPTY_OBJECT) => {
      const { pathName, stage, stageId, ...rest } = params
      const finalPath = pathName ?? currentPath

      const urlParams = new URLSearchParams(search)

      const newParams: StageUrlSearchParams = {
        ...rest,
        // replace params based on the current route params
        forWorkspace:
          params.forWorkspace === workspaceIdParam
            ? undefined
            : params.forWorkspace,
        stage: stageId ? 'edit' : (stage ?? 'new'),
        stageId,
      }

      for (const [key, value] of entries(newParams)) {
        if (value) {
          urlParams.set(key, value)
        }
      }

      return `${finalPath}?${urlParams.toString()}`
    },
    [currentPath, search, workspaceIdParam]
  )
}

export const useStageModalUrlParams = () => {
  const { workspaceId: workspaceIdParam } = useParams<StageModalUrlProps>()
  const { stage, stageId, forWorkspace } =
    useSearchParams<StageUrlSearchParams>()

  return {
    workspaceId: forWorkspace ?? workspaceIdParam,
    stage,
    stageId,
  }
}
