import { priorityLevels } from '@motion/rpc/types'
import {
  type PriorityLevelSchema,
  type ProjectSchema,
} from '@motion/zod/client'

import { useProjectUpdater } from '~/areas/project/hooks'
import { useTaskPriorityData } from '~/areas/tasks/hooks'
import { PriorityDropdown } from '~/global/components/dropdowns'
import { PriorityLabel } from '~/global/components/labels'
import { type TaskWithRelations } from '~/global/proxies'

import { CellAction, EmptyCell } from './components'

type TaskPriorityCellProps = {
  task: TaskWithRelations
}
export const TaskPriorityCell = ({ task }: TaskPriorityCellProps) => {
  const { onChange, priorities, disabled, hasValidData } =
    useTaskPriorityData(task)

  if (!hasValidData) {
    return <EmptyCell />
  }

  return (
    <PriorityCell
      priorities={priorities}
      value={task.priorityLevel}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

type ProjectPriorityCellProps = {
  project: ProjectSchema
}
export const ProjectPriorityCell = ({ project }: ProjectPriorityCellProps) => {
  const updateProject = useProjectUpdater()

  return (
    <PriorityCell
      priorities={priorityLevels}
      value={project.priorityLevel}
      onChange={(priorityLevel) => updateProject(project, { priorityLevel })}
    />
  )
}

type PriorityCellProps = {
  priorities: PriorityLevelSchema[]
  value: PriorityLevelSchema
  onChange: (priority: PriorityLevelSchema) => void
  disabled?: boolean
}
const PriorityCell = ({
  priorities,
  value,
  onChange,
  disabled = false,
}: PriorityCellProps) => {
  const button = (
    <CellAction disabled={disabled}>
      <PriorityLabel value={value} />
    </CellAction>
  )

  if (disabled) return button

  return (
    <PriorityDropdown
      items={priorities}
      selectedItem={value}
      onChange={onChange}
    >
      {button}
    </PriorityDropdown>
  )
}
