import { type MeetingInsightsSchema } from '@motion/rpc-types'
import { LoadingSpinner } from '@motion/ui/base'
import { time } from '@motion/utils/debug'

import {
  buildTreeGroup,
  type GroupByTypes,
  type GroupDefinition,
  type GroupedNode,
} from '~/areas/project-management/pages/pm-v3/grouping'
import {
  TreeList,
  type TreeListRowValueType,
} from '~/areas/project-management/pages/pm-v3/tree-list'
import { type ViewStateColumn } from '~/areas/project-management/pages/pm-v3/view-state'
import { type ReactNode, useCallback, useMemo } from 'react'

import { useNotetakerViewState } from '../../state'
import {
  useAvailableGroups,
  useMeetingInsightsColumns,
  useMeetingInsightsData,
  useTreeGroupByOverride,
} from '../hooks'

export function ConnectedMeetingInsightsList() {
  const [viewState] = useNotetakerViewState()
  const { all: groups } = useAvailableGroups()

  const {
    data: meetingInsightsList,
    filtered,
    isLoading,
  } = useMeetingInsightsData()

  const groupDefinitions = useMemo(() => {
    return viewState.groupBy.fields
      .map((g) => groups.find((def) => def.type === g.key))
      .filter(Boolean)
  }, [groups, viewState.groupBy.fields])

  if (isLoading) {
    return (
      <CenteredPage>
        <LoadingSpinner size={36} />
      </CenteredPage>
    )
  }

  if (meetingInsightsList.length === 0) {
    return (
      <CenteredPage>
        <span className='text-semantic-neutral-text-default font-semibold text-center'>
          No meeting notes found
        </span>
      </CenteredPage>
    )
  }

  return (
    <MeetingInsightsList
      groupBy={groupDefinitions}
      meetingInsights={filtered}
    />
  )
}

type MeetingInsightsListProps = {
  groupBy: GroupDefinition<MeetingInsightsSchema>[]
  meetingInsights: MeetingInsightsSchema[]
}

function MeetingInsightsList(props: MeetingInsightsListProps) {
  const [viewState, setViewState] = useNotetakerViewState()
  const overrides = useTreeGroupByOverride()
  const { byType } = useAvailableGroups()

  const onColumnStateChange = useCallback(
    (columns: ViewStateColumn[]) => {
      setViewState((state) => ({ ...state, columns }))
    },
    [setViewState]
  )

  const sortGroupsByFns = useMemo(() => {
    return viewState.groupBy.fields.reduce(
      (acc, group) => {
        const key = group.key as GroupByTypes
        const groupSortFn = byType[key]?.sortBy

        if (groupSortFn) {
          acc[key] = (l, r) => {
            if (viewState.groupBy.order.by === 'desc') {
              return groupSortFn(r.value, l.value)
            }

            return groupSortFn(l.value, r.value)
          }
        }

        return acc
      },
      {} as Record<GroupByTypes, (l: GroupedNode, r: GroupedNode) => number>
    )
  }, [byType, viewState.groupBy.fields, viewState.groupBy.order.by])

  const grouped = useMemo(() => {
    return time('build-tree-group.total', () => {
      return buildTreeGroup(props.groupBy, overrides)
        .add('meetingInsights', props.meetingInsights)
        .buildTree<GroupedNode<TreeListRowValueType>>({
          hideEmptyGroups: true,
          sortGroupFns: sortGroupsByFns,
        })
    })
  }, [overrides, props.groupBy, props.meetingInsights, sortGroupsByFns])

  const columns = useMeetingInsightsColumns()

  return (
    <TreeList
      columns={columns}
      tree={grouped}
      // TODO: Figure out how to type this
      sortBy={viewState.sortBy as any}
      columnState={viewState.columns}
      onColumnStateChange={onColumnStateChange}
    />
  )
}

function CenteredPage({ children }: { children: ReactNode }) {
  return <div className='grid place-items-center w-full h-full'>{children}</div>
}
