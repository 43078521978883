import { type NormalTaskSchema } from '@motion/zod/client'

import { TaskRow } from './task-row'

import { useSetupProjectForm } from '../../../hooks'

export type StageTasksProps = {
  tasks: NormalTaskSchema[]
  modifications: Record<string, unknown>
  disabled: boolean
}

export const StageTasks = ({
  tasks,
  modifications,
  disabled,
}: StageTasksProps) => {
  const {
    form: { watch },
  } = useSetupProjectForm()

  const speculativeProject = watch('speculativeProject')

  if (speculativeProject == null) {
    throw new Error('Speculative project is not set and should have been set!')
  }

  return (
    <div className='flex flex-col gap-1 pl-2 pb-3'>
      {tasks.map((task) => (
        <TaskRow
          key={task.id}
          task={task}
          project={speculativeProject}
          isAiModified={modifications?.[task.id] != null}
          disabled={disabled}
        />
      ))}
    </div>
  )
}
