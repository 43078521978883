import {
  type CustomFieldSchema,
  type CustomFieldValuesSchema,
} from '@motion/shared/custom-fields'
import { type CustomFieldFieldArrayValue } from '@motion/ui-logic'

import { useFieldArray, type UseFormReturn } from 'react-hook-form'

import { CustomFieldsSidebar } from './custom-fields-sidebar'

export type ControlledCustomFieldsSidebarProps = {
  form: UseFormReturn<any, any, undefined>
  onChange?: (
    customFieldId: CustomFieldSchema['id'],
    valueArg: CustomFieldValuesSchema['value']
  ) => void
  workspaceId: string
  disabled?: boolean
}

export function ControlledCustomFieldsSidebar({
  form,
  workspaceId,
  disabled = false,
  onChange,
}: ControlledCustomFieldsSidebarProps) {
  const { fields } = useFieldArray<{
    customFieldValuesFieldArray: CustomFieldFieldArrayValue[]
  }>({
    control: form.control,
    name: 'customFieldValuesFieldArray',
  })

  return (
    <CustomFieldsSidebar
      fields={fields}
      workspaceId={workspaceId}
      disabled={disabled}
      onChange={(customField, value, index) => {
        if (onChange != null) {
          return onChange(
            customField.instanceId,
            value as CustomFieldValuesSchema['value']
          )
        }

        // setting the value within the form instead of using update/replace from useFieldArray to make RHF put the form in a real dirty state
        form.setValue(
          'customFieldValuesFieldArray',
          fields.map((f) => {
            if (f.instanceId === customField.instanceId) {
              return { ...f, ...customField, value: value as any }
            }
            return f
          }),
          { shouldDirty: true }
        )
      }}
    />
  )
}
