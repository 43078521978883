import { isNoneId } from '@motion/shared/identifiers'
import { ConditionalWrapper } from '@motion/ui/utils'
import { type RecurringTaskSchema, type TaskSchema } from '@motion/zod/client'

import { MotionLink } from '~/global/components'
import { useWorkspaceStatusById } from '~/global/hooks'
import { useTaskModalUrl } from '~/global/navigation'
import { useTaskByIdV2 } from '~/global/rpc/v2'

import { type BadgeSize, StatusBadge } from '../badges'

export type TaskLabelProps = {
  value: TaskSchema | RecurringTaskSchema | null
  size?: BadgeSize
  nameOnly?: boolean
  clickable?: boolean
}

export const TaskLabel = ({
  size,
  value,
  nameOnly = false,
  clickable = false,
}: TaskLabelProps) => {
  const buildTaskModalUrl = useTaskModalUrl()
  const name = value == null ? 'No task' : value.name

  const status = useWorkspaceStatusById(value?.statusId)

  const label = (
    <span title={name} className='truncate'>
      {name}
    </span>
  )

  if (nameOnly) {
    return label
  }

  return (
    <ConditionalWrapper
      condition={clickable && value != null && !isNoneId(value.id)}
      wrapper={(children) => (
        <MotionLink
          url={buildTaskModalUrl({
            // @ts-expect-error the ConditionalWrapper condition ensures that value is not null
            task: value.id,
          })}
          className='overflow-hidden'
        >
          {children}
        </MotionLink>
      )}
    >
      <div className='flex gap-1 items-center overflow-hidden'>
        <StatusBadge value={status} size={size} />
        {label}
      </div>
    </ConditionalWrapper>
  )
}

export type ConnectedTaskLabelProps = Omit<TaskLabelProps, 'value'> & {
  id: TaskSchema['id'] | null
}

export function ConnectedTaskLabel({ id, ...rest }: ConnectedTaskLabelProps) {
  const { data: task } = useTaskByIdV2({ id })

  if (task == null) {
    return null
  }

  return <TaskLabel value={task} {...rest} />
}
