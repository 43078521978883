import { classed } from '@motion/theme'

import { StageAutoScheduleField } from './fields/stage-auto-schedule'
import { ConnectedStageDebugInfo } from './fields/stage-debug-info'
import { ConnectedStageDuration } from './stage-duration'

import { StageTitleField } from '../../fields'

export type StageColumnHeaderProps = {
  index: number
  onRemove: () => void
}
export function StageColumnHeader({ index, onRemove }: StageColumnHeaderProps) {
  return (
    <HeaderContainer extraPadding>
      <StageTitleField
        stagePath={`stages.${index}`}
        onRemove={onRemove}
        size='small'
      />

      <ConnectedStageDuration stagePath={`stages.${index}`} />
      <StageAutoScheduleField tasksPath={`stages.${index}.tasks`} />
      <ConnectedStageDebugInfo stagePath={`stages.${index}`} />
    </HeaderContainer>
  )
}

const HeaderContainer = classed('div', {
  base: `flex flex-col gap-3 self-stretch`,
  variants: {
    extraPadding: {
      true: 'pb-3 px-1 pt-1',
    },
  },
})
