import {
  ArrowDownSolid,
  ArrowUpSolid,
  DotsHorizontalSolid,
  DuplicateOutline,
  ExternalLinkSolid,
  PencilSolid,
  StarCrossOutOutline,
  StarOutline,
  TrashOutline,
} from '@motion/icons'
import { type COLOR } from '@motion/shared/common'
import {
  ActionList,
  IconButton,
  PopoverTrigger,
  showToast,
} from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { useModalApi } from '@motion/web-common/modals'
import { type FolderSchema } from '@motion/zod/client'

import { useDesktopTabs } from '~/areas/desktop/hooks'
import { useDeleteFolder, useUserFavoritedItem } from '~/areas/folders/hooks'
import { DEFAULT_VIEW_SLUG } from '~/areas/project-management/utils'
import { useCachedItem } from '~/global/cache'
import { ColorDropdownContent } from '~/global/components/dropdowns'
import { useCopyHtmlLinkToClipboard } from '~/global/hooks'
import { useUpdateFolder } from '~/global/rpc/folders'
import { showErrorToast } from '~/global/toasts'
import { useUriByRouteId } from '~/routing'

type FolderActionsMenuProps = {
  folderId: FolderSchema['id']
  moveActionAllowed?: {
    up: boolean
    down: boolean
  }
  onMoveItem?: (moveBy: 1 | -1) => void
}

export const FolderActionsMenu = (props: FolderActionsMenuProps) => {
  return (
    <PopoverTrigger
      renderPopover={({ close }) => (
        <FolderActionsPopoverContents close={close} {...props} />
      )}
    >
      <IconButton
        variant='muted'
        sentiment='neutral'
        size='xsmall'
        icon={DotsHorizontalSolid}
        onClick={() => {
          recordAnalyticsEvent('FOLDERS_CLICKED_SIDEBAR_BUTTON', {
            itemType: 'FOLDER',
            button: 'ACTIONS',
          })
        }}
      />
    </PopoverTrigger>
  )
}

type FolderActionsPopoverContentsProps = FolderActionsMenuProps & {
  close: () => void
}

export const FolderActionsPopoverContents = ({
  close,
  folderId,
  moveActionAllowed,
  onMoveItem,
}: FolderActionsPopoverContentsProps) => {
  const getRouteUri = useUriByRouteId()
  const modalApi = useModalApi()
  const copyLink = useCopyHtmlLinkToClipboard()
  const { hasTabs, openTab, canOpenTab, tabTooltip } = useDesktopTabs()

  const folder = useCachedItem('folders', folderId)

  const { mutateAsync: updateFolder } = useUpdateFolder()
  const deleteFolder = useDeleteFolder()

  const { isFavorited, toggleFavorite } = useUserFavoritedItem(
    'FOLDER',
    folder?.id
  )

  if (!folder) return null

  const handleUpdateFolderColor = async (color: COLOR) => {
    recordAnalyticsEvent('FOLDERS_UPDATED_FOLDER', {
      updated: 'COLOR',
      location: 'SIDEBAR',
    })

    try {
      await updateFolder({ folderId, color })

      showToast('success', 'Folder color updated')
    } catch (e) {
      showErrorToast(e, 'Could not update folder color')

      Sentry.captureException(
        new Error('Failed to update folder color', { cause: e }),
        {
          extra: {
            folderId,
            color,
          },
          tags: {
            position: 'FolderActionsMenu',
          },
        }
      )
    }
  }

  const handleDeleteFolder = async () => {
    recordAnalyticsEvent('FOLDERS_DELETED_FOLDER', {
      location: 'SIDEBAR',
    })

    try {
      await deleteFolder(folderId)
    } catch (e) {
      Sentry.captureException(
        new Error('Failed to delete folder', { cause: e }),
        {
          extra: {
            folderId,
          },
          tags: {
            position: 'FolderActionsMenu',
          },
        }
      )
    }
  }

  const handleEditFolder = () => {
    modalApi.open('edit-folder', {
      folderId,
    })
  }

  const handleCopyLink = () => {
    recordAnalyticsEvent('FOLDERS_COPIED_LINK', {
      location: 'SIDEBAR',
    })

    const folderUrl = new URL(
      getRouteUri('workspace-folder', {
        workspaceId: folder.targetId,
        folderId,
        viewId: DEFAULT_VIEW_SLUG,
      }),
      window.location.origin
    ).toString()

    copyLink(folderUrl, folder.name ?? 'Untitled folder')
  }

  const handleOpenInNewTab = () => {
    openTab(
      getRouteUri('workspace-folder', {
        workspaceId: folder.targetId,
        folderId,
      }),
      'FOLDER'
    )
  }

  const handleToggleFavorite = () => {
    recordAnalyticsEvent('TOGGLE_FAVORITE', {
      itemType: 'FOLDER',
      location: 'CONTEXT_MENU',
      state: isFavorited ? 'REMOVED' : 'ADDED',
    })

    void toggleFavorite()
  }

  return (
    <div className='scrollbar-none w-full scroll-py-1 overflow-y-auto overflow-x-hidden'>
      <div className='p-2 border-b border-dropdown-border space-y-2'>
        <ColorDropdownContent
          selectedColor={folder.color as COLOR}
          onChange={(color) => {
            void handleUpdateFolderColor(color)
          }}
          close={close}
        />
      </div>

      <ActionList
        onActionAnyItem={close}
        sections={[
          {
            items: [
              {
                prefix: <PencilSolid />,
                content: 'Edit',
                onAction: handleEditFolder,
              },
              hasTabs && {
                prefix: <ExternalLinkSolid />,
                content: 'Open in new tab',
                onAction: handleOpenInNewTab,
                disabled: !canOpenTab,
                tooltip: tabTooltip,
              },
              {
                prefix: <DuplicateOutline />,
                content: 'Copy link',
                onAction: handleCopyLink,
              },
              {
                prefix: isFavorited ? <StarCrossOutOutline /> : <StarOutline />,
                content: isFavorited
                  ? 'Remove from Favorites'
                  : 'Add to Favorites',
                onAction: handleToggleFavorite,
              },
              // {
              //   prefix: <ArrowRightOutline />,
              //   content: 'Move to',
              //   onAction: () => {
              //     //
              //   },
              // },
            ],
          },
          onMoveItem &&
            moveActionAllowed && {
              items: [
                {
                  prefix: <ArrowUpSolid />,
                  content: 'Move up',
                  disabled: !moveActionAllowed.up,
                  onAction: () => onMoveItem(-1),
                },
                {
                  prefix: <ArrowDownSolid />,
                  content: 'Move down',
                  disabled: !moveActionAllowed.down,
                  onAction: () => onMoveItem(1),
                },
              ],
            },
          {
            items: [
              {
                prefix: <TrashOutline />,
                content: 'Delete',
                destructive: true,
                onAction: handleDeleteFolder,
              },
            ],
          },
        ].filter(Boolean)}
      />
    </div>
  )
}
