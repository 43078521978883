import { useMoveProjectToStage } from '~/areas/project/hooks'
import { useTaskStageData } from '~/areas/tasks/hooks'
import { StageDropdown } from '~/global/components/dropdowns'
import { StageLabel } from '~/global/components/labels'
import { useStageDefinition } from '~/global/hooks'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'

import { CellAction, CellText } from './components'

type TaskStageCellProps = {
  task: TaskWithRelations
}
export const TaskStageCell = ({ task }: TaskStageCellProps) => {
  const { onChange, disabled } = useTaskStageData(task)

  if (
    task.type !== 'NORMAL' ||
    !task.stageDefinitionId ||
    !task.stageDefinition
  ) {
    return <CellText>No stage</CellText>
  }

  return (
    <StageDropdown
      onChange={onChange}
      selectedStageId={task.stageDefinitionId}
      projectId={task.projectId}
    >
      <CellAction disabled={disabled}>
        <StageLabel value={task.stageDefinition} />
      </CellAction>
    </StageDropdown>
  )
}

type ProjectStageCellProps = {
  project: ProjectWithRelations
}
export const ProjectStageCell = ({ project }: ProjectStageCellProps) => {
  const moveProjectToStage = useMoveProjectToStage()

  const stageDefinitionV2 = useStageDefinition(project.activeStageDefinitionId)

  const stageDefinition = stageDefinitionV2

  if (!project.activeStageDefinitionId || !stageDefinition) {
    return <CellText>No stage</CellText>
  }

  return (
    <StageDropdown
      onChange={(stage) => moveProjectToStage(project, stage.id)}
      selectedStageId={project.activeStageDefinitionId}
      projectId={project.id}
    >
      <CellAction>
        <StageLabel value={stageDefinition} />
      </CellAction>
    </StageDropdown>
  )
}
