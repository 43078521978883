import { type CellContext } from '@tanstack/react-table'
import { DateGroupCurrentTag } from '~/areas/project-management/pages/pm-v3/components'
import {
  type GroupedNode,
  isDateGroup,
} from '~/areas/project-management/pages/pm-v3/grouping'
import { type TreeListRowValueType } from '~/areas/project-management/pages/pm-v3/tree-list'
import { ExpandButton } from '~/areas/project-management/pages/pm-v3/tree-list/cells/components'
import { type ReactNode } from 'react'

type ExpandableHeaderProps = {
  cell: ReactNode
  info: CellContext<GroupedNode<TreeListRowValueType>, unknown>
}
export function ExpandableHeader({ cell, info }: ExpandableHeaderProps) {
  const item = info.row.original.value

  return (
    <div className='overflow-hidden flex gap-1 items-center relative px-2.5 w-full'>
      <div className='pl-[var(--indent)] flex gap-3 items-center relative overflow-hidden w-full'>
        <ExpandButton row={info.row} />
        <div className='overflow-hidden flex items-center'>{cell}</div>
        {isDateGroup(item) && <DateGroupCurrentTag item={item} />}
        <div className='text-semantic-neutral-text-subtle'>
          {info.row.original.count}
        </div>
      </div>
    </div>
  )
}
