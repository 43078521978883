import { EXPECTED_DURATION_UNIT_DROPDOWN_ITEMS } from '@motion/shared/flows'
import { SearchableDropdown } from '@motion/ui/base'

export const ExpectedDurationUnitDropdown = ({
  selectedItem,
  onChange,
  children,
}: {
  selectedItem: (typeof EXPECTED_DURATION_UNIT_DROPDOWN_ITEMS)[number]
  onChange: (
    item: (typeof EXPECTED_DURATION_UNIT_DROPDOWN_ITEMS)[number]
  ) => void
  children: React.ReactNode
}) => {
  return (
    <SearchableDropdown
      items={EXPECTED_DURATION_UNIT_DROPDOWN_ITEMS}
      selectedItem={selectedItem}
      renderItem={(item) => <span>{item.label}</span>}
      onChange={onChange}
    >
      {children}
    </SearchableDropdown>
  )
}
