import {
  AdjustmentsOutline,
  ExternalLinkSolid,
  PlusSolid,
  SearchSolid,
} from '@motion/icons'
import { Button, ScrollArea } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { useHasTreatment } from '@motion/web-common/flags'

import {
  SidebarSearchContext,
  type SidebarSearchContextApi,
  useSidebarSearchContext,
} from '~/areas/search/hook'
import { SidebarWorkspacesTreeview } from '~/areas/sidebar/treeviews/workspaces-treeview'
import { WorkspaceCreateMenu } from '~/areas/treeviews/components'
import { MotionLink } from '~/global/components'
import { useFolders } from '~/global/rpc/folders'
import { useUriByRouteId } from '~/routing'
import { useMemo, useState } from 'react'

import { ExpandableSection, SectionHeaderActionButton } from './components'
import { MAX_SIDEBAR_ITEM_COUNT } from './constants'
import { usePrivateTreeviewItems } from './private-treeview/hooks'

import { SidebarExpandablePanelHeader } from '../components/sidebar-expandable-panel'
import { useGlobalSidebarContext, useGlobalSidebarState } from '../hooks'

export const PrivateSection = () => {
  const { data: folderData } = useFolders()
  const getRouteUri = useUriByRouteId({ noDefaults: true })
  const { openedPanel, setOpenedPanel } = useGlobalSidebarContext()
  const { sidebarState, toggleSidebarSection } = useGlobalSidebarState()
  const { hasSearch } = useSidebarSearchContext()
  const items = usePrivateTreeviewItems()
  const hasNotes = useHasTreatment('notes-in-webapp')
  const hasSidebarOrganization = useHasTreatment('sidebar-organization-control')

  const workspaceId = folderData?.models.systemFolders.private.targetId

  return (
    <ExpandableSection
      title='Private'
      isExpanded={sidebarState.sections.private || hasSearch}
      shouldRenderPanel={!hasSearch && openedPanel === 'private'}
      onToggleExpand={(state) => {
        if (hasSearch) return

        toggleSidebarSection('private', state)
      }}
      onToggleRenderPanel={(shouldOpen) =>
        void setOpenedPanel(shouldOpen ? 'private' : null)
      }
      renderPanelContent={
        !hasSearch &&
        items.length > MAX_SIDEBAR_ITEM_COUNT &&
        (() => <AllPrivateItemsPanel />)
      }
      renderButton={() =>
        !hasSearch &&
        workspaceId && (
          <>
            <MotionLink
              url={getRouteUri('workspace-detail', {
                workspaceId: workspaceId,
              })}
            >
              <SectionHeaderActionButton
                icon={ExternalLinkSolid}
                sentiment='neutral'
                variant='muted'
                size={hasSidebarOrganization ? 'xsmall' : 'small'}
              />
            </MotionLink>

            <WorkspaceCreateMenu
              workspaceId={workspaceId}
              allowNewFolder
              allowNewNote={hasNotes}
            >
              <SectionHeaderActionButton
                icon={PlusSolid}
                sentiment='neutral'
                variant='outlined'
                size={hasSidebarOrganization ? 'xsmall' : 'small'}
              />
            </WorkspaceCreateMenu>

            {hasSidebarOrganization && (
              <SectionHeaderActionButton
                icon={AdjustmentsOutline}
                sentiment='neutral'
                variant='outlined'
                size='xsmall'
              />
            )}
          </>
        )
      }
    >
      <SidebarWorkspacesTreeview
        items={hasSearch ? items : items.slice(0, MAX_SIDEBAR_ITEM_COUNT)}
      />
    </ExpandableSection>
  )
}

const AllPrivateItemsPanel = () => {
  const [searchQuery, setSearchQuery] = useState('')

  const contextValue = useMemo<SidebarSearchContextApi>(
    () => ({
      hasSearch: searchQuery !== '',
      searchQuery,
      setSearchQuery,
    }),
    [searchQuery]
  )

  return (
    <div className='w-full flex flex-col'>
      <div className='p-4 space-y-4'>
        <SidebarExpandablePanelHeader title='Private' />

        <div>
          <TextField
            size='normal'
            rounded='default'
            prefix={<SearchSolid />}
            placeholder='Search Private...'
            value={searchQuery}
            onChange={setSearchQuery}
            autoFocus
            showClearButton
          />
        </div>
      </div>

      <ScrollArea className='w-full'>
        <SidebarSearchContext.Provider value={contextValue}>
          <FilteredTreeview />
        </SidebarSearchContext.Provider>
      </ScrollArea>
    </div>
  )
}

const FilteredTreeview = () => {
  const items = usePrivateTreeviewItems()
  const { setSearchQuery } = useSidebarSearchContext()

  return (
    <>
      <SidebarWorkspacesTreeview items={items} />

      {items.length === 0 && (
        <div className='text-sm px-4'>
          <p className='font-semibold mb-1'>No results</p>
          <p className='mb-3'>Try a different search term.</p>
          <Button
            size='xsmall'
            sentiment='neutral'
            onClick={() => void setSearchQuery('')}
          >
            Clear search
          </Button>
        </div>
      )}
    </>
  )
}
