import { showToast } from '@motion/ui/base'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type FolderSchema } from '@motion/zod/client'

import { useLookup } from '~/global/cache'
import { useDeleteFolderMutation } from '~/global/rpc/folders'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useDeleteFolder = () => {
  const { mutateAsync: deleteFolder } = useDeleteFolderMutation()
  const modalApi = useModalApi()
  const lookup = useLookup()

  return useCallback(
    async (input: FolderSchema | FolderSchema['id']) => {
      let folder: FolderSchema | null

      if (typeof input === 'string') {
        folder = lookup('folders', input) ?? null
      } else {
        folder = input
      }

      if (folder == null) {
        throw new Error('Folder not found')
      }

      const folderName = folder.name ? ` "${folder.name}"` : null

      const response = await modalApi.prompt('confirm', {
        title: `Are you sure you want to delete the folder${folderName}?`,
        description: 'Projects inside this folder will not be deleted.',
        confirmButtonText: 'Delete',
        closeButtonText: 'Cancel',
        destructive: true,
        analytics: {
          name: 'delete-folder',
        },
      })

      if (response === ModalDismissed) return ModalDismissed

      try {
        await deleteFolder({
          folderId: folder.id,
        })

        showToast('success', 'Folder deleted')
      } catch (e) {
        showErrorToast(e, 'Could not delete folder')
        throw e
      }
    },
    [deleteFolder, lookup, modalApi]
  )
}
