import {
  SUPPORTED_IMAGE_MIME_TYPES,
  SUPPORTED_VIDEO_MIME_TYPES,
} from '@motion/shared/files'

export function buildAttachmentUrl(attachmentId: string) {
  return `/attachments/${attachmentId}` // ToDo: Implement this thing
}

export function fileIsPreviewable(mimeType: string) {
  return (
    SUPPORTED_IMAGE_MIME_TYPES.includes(mimeType) ||
    SUPPORTED_VIDEO_MIME_TYPES.includes(mimeType)
  )
}
