import { PlusOutline } from '@motion/icons'
import { classed } from '@motion/theme'

export function StepLineSeparator() {
  return (
    <div className='flex flex-col items-center'>
      <VerticalLine />
      <PlusOutline className='size-4 m-2 text-semantic-neutral-icon-default' />
      <VerticalLine pointing />
    </div>
  )
}

const VerticalLine = classed('div', {
  base: `
    relative
    m-auto
    h-[10px]
    w-0
    border border-semantic-neutral-border-strong
  `,
  variants: {
    pointing: {
      true: `
        after:absolute
        after:bottom-0
        after:-left-[4px]
        after:-rotate-[45deg]
        after:w-[8px]
        after:h-[8px]
        after:border-l
        after:border-b
        after:border-semantic-neutral-border-strong
      `,
      false: ``,
    },
  },
  defaultVariants: {
    pointing: false,
  },
})
