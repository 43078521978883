import { useSendToDesktop } from '@motion/desktop-ipc/hooks'
import { useMemoSyncExternalStore } from '@motion/react-core/hooks'
import { getCurrentUser } from '@motion/rpc-cache'
import { isActiveStatus } from '@motion/shared/billing'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { logInDev } from '@motion/web-base/logging'
import { featureFlags, useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'
import { useMyUserSettingsQuery } from '@motion/web-common/settings'
import { isTeamSubscription } from '@motion/web-common/subscriptions'

import { useQueryClient } from '@tanstack/react-query'
import { Events } from '~/analyticsEvents'
import { useOnboardingApi } from '~/areas/onboarding/state/use-onboarding-api'
import { type StorageChangeResult } from '~/chromeApi/chromeApiTypes'
import { useHasSeenCta } from '~/global/hooks'
import { useIsTeamExpired } from '~/global/hooks/team'
import { useSetAnalyticsTeamId } from '~/global/rpc/team'
import { useUpdateMyCtaSettings } from '~/global/rpc/v2'
import { useAuth } from '~/localServices/auth'
import { routeFromHash, usePage } from '~/routing'
import { AppRouter } from '~/routing/components/app-router'
import { selectDesktopSettings } from '~/state/desktopSlice'
import { useAppDispatch, useAppSelector, useTheme } from '~/state/hooks'
import { fetchTeam } from '~/state/projectManagement/teamThunks'
import { selectTeam } from '~/state/team'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'

import api from '../../chromeApi/chromeApiContentScript'
import * as mainActions from '../../state/mainSlice'
import { AlertManager } from '../Alerts/AlertsContext/AlertManager'

export const MotionTab = memo(function MotionTab() {
  const [team, setTeam] = useState<any>({})

  const getTeamRef = useRef(false)
  const frameRef = useRef(undefined)
  const dispatch = useAppDispatch()
  const teamId = useAppSelector((state) => state.projectManagement.teamId)
  const page = usePage()
  const modalApi = useModalApi()

  const onboardingActions = useOnboardingApi()
  const onboarding = onboardingActions.selectIsOnboarding()
  const onboardingPage = onboardingActions.selectPage()
  const reduxTeam = useAppSelector(selectTeam)
  const { mutate: updateCtaSettings } = useUpdateMyCtaSettings()
  const hasSeenCta = useHasSeenCta()
  const isExpiredTeam = useIsTeamExpired()

  const showCta = hasSeenCta(`DISMISSED_TEAM_TRIAL_ENDED_MODAL`)

  const desktopSettings = useAppSelector(selectDesktopSettings)

  const preventNotifications = useAppSelector(
    mainActions.selectIsDesktopSubWindow
  )
  const sendToDesktop = useSendToDesktop()

  const theme = useTheme()
  const navigate = useNavigate()
  const auth = useAuth()

  const shouldUseTieredPricing = useHasTreatment('tiered-pricing-v1')

  const { data: userSettings } = useMyUserSettingsQuery(undefined, {
    meta: {
      source: `motion-tab`,
    },
  })

  useEffect(
    function setDesktopUserSettings() {
      if (userSettings != null) {
        sendToDesktop('updateUserSettings', userSettings)
      }
    },
    [userSettings, sendToDesktop]
  )

  const client = useQueryClient()
  const currentUser = getCurrentUser(client)

  useEffect(
    function setDesktopUser() {
      if (currentUser) {
        sendToDesktop('setCurrentUser', currentUser)
      }
    },
    [currentUser, sendToDesktop]
  )

  // Run this on initial page load to determine whether or not to showProjectManagement and showMyTasksTab
  // Changes to the legacy team made through the teams page should cause PM team details
  // to refetch (primarily for new/removed members).
  useEffect(
    function loadTeam() {
      void dispatch(fetchTeam())
      // TODO: Move this to dispatch once refreshTeamSync is removed
      void api.runtime.sendMessage({ event: 'getTeams' })
    },
    [dispatch]
  )

  useSetAnalyticsTeamId()

  const setPage = useCallback(
    (newPage: string) => {
      logInDev('setPage', newPage)
      if (onboarding) {
        logInDev('prevent page change due to onboarding')
        return
      }

      navigate(`/web/${newPage}`)
      onboardingActions.setPage(null)
    },
    [navigate, onboarding, onboardingActions]
  )

  useEffect(() => {
    const checkIsPro = async () => {
      const { team } = await api.storage.local.get(['team'])

      setTeam(team)
    }
    void checkIsPro()
    const listener = async (changes: StorageChangeResult) => {
      if ('team' in changes) {
        void checkIsPro()
      }
    }
    api.storage.onChanged.addListener(listener)
    return () => api.storage.onChanged.removeListener(listener)
  }, [onboarding])

  useEffect(() => {
    window.addEventListener('message', async (event) => {
      if (event.data === 'motion_widget_calendar') {
        navigate('/web/calendar')
      } else if (event.data === 'motion_widget_tasks') {
        setPage('tasks')
      } else if (event.data === 'motion_widget_tasks_schedule') {
        setPage('tasksSchedule')
      } else if (event.data === 'motion_widget_scheduler') {
        navigate('/web/booking')
      } else if (event.data === 'motion_webapp_settings') {
        navigate('/web/settings')
      }
    })
  }, [navigate, setPage])

  const hideCalendarTimerRef = useRef<NodeJS.Timeout | undefined>(undefined)
  const loggedEventRef = useRef(undefined)

  useEffect(() => {
    clearTimeout(hideCalendarTimerRef.current)
    const logEventOnce = (event: any) => {
      if (loggedEventRef.current !== event) {
        loggedEventRef.current = event
        recordAnalyticsEvent(event)
      }
    }

    if (page === 'account') {
      void api.runtime.sendMessage({ event: 'checkStripeSubscription' })
      logEventOnce(Events.MOTIONTAB_ACCOUNT_SHOW)
      navigate('/web/account')
    }
    if (!auth.isPro) {
      hideCalendarTimerRef.current = setTimeout(
        () => window.postMessage('motiontab_calendar_hide'),
        50
      )
      setTimeout(() => window.postMessage('motiontab_scheduler_hide'), 50)
      setTimeout(() => window.postMessage('motiontab_tasks_hide'), 50)
      return
    }
    if (page === 'calendar' && !onboarding && !onboardingPage) {
      logEventOnce(Events.MOTIONTAB_CALENDAR_SHOW)
      setTimeout(() => window.postMessage('motiontab_calendar_show'), 10)
    } else {
      window.postMessage('motiontab_calendar_hide')
    }
    if (page === 'booking') {
      logEventOnce(Events.MOTIONTAB_SCHEDULER_SHOW)
      const route = routeFromHash(window.location.hash)
      if (route.startsWith('scheduler:') || route.startsWith('booking:')) {
        const [, id, team] = route.split(':')
        setTimeout(
          () =>
            window.postMessage({
              event: 'motiontab_scheduler_edit',
              id,
              team: team === 'true',
            }),
          10
        )
      } else {
        setTimeout(() => window.postMessage('motiontab_scheduler_show'), 10)
      }
    } else {
      window.postMessage('motiontab_scheduler_hide')
    }
    if (page === 'tasksSchedule') {
      logEventOnce(Events.MOTIONTAB_TASKS_SHOW)
    } else {
      window.postMessage('motiontab_tasks_hide')
    }

    if (page === 'team' && !getTeamRef.current) {
      getTeamRef.current = true
    }
    if (page === 'bookingSettings') {
      navigate('/web/settings/booking')
    }
  }, [onboarding, onboardingPage, page, teamId, navigate, auth.isPro])

  useEffect(() => {
    window.postMessage('motionTabRendered')
  }, [])

  useEffect(
    function loadUserEmailDesktop() {
      if (!auth.user?.email) return

      // Ensure desktop app has user email address once loaded
      if (!preventNotifications) {
        sendToDesktop('userReady', {
          userEmail: auth.user?.email,
        })
      }
      sendToDesktop('requestUpdateAvailable')
    },
    [auth.user?.email, preventNotifications, sendToDesktop]
  )

  useEffect(
    function updateDesktopSettings() {
      if (desktopSettings && !preventNotifications) {
        // @ts-expect-error: This is expected to fail for now until we align the desktop settings types
        sendToDesktop('updateSettings', desktopSettings)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      desktopSettings?.shortcuts,
      desktopSettings?.openAtLaunch,
      preventNotifications,
    ]
  )

  useEffect(
    function updateDesktopTheme() {
      sendToDesktop('updateTheme', theme)
    },
    [theme, sendToDesktop]
  )
  /* END Desktop Event Handlers */

  useEffect(
    function checkExpiredTeamSub() {
      const subscription =
        auth.subscription.status === 'success'
          ? auth.subscription.value.stripe
          : null
      if (!subscription) {
        return
      }
      const validIndividualSubscription =
        !isTeamSubscription(subscription) && isActiveStatus(subscription.status)
      if (
        isExpiredTeam &&
        validIndividualSubscription &&
        showCta.loaded &&
        !showCta.seen &&
        !modalApi.status('team-trial-ended').visible
      ) {
        modalApi.open('team-trial-ended', {
          onStartTeamPlan: !shouldUseTieredPricing
            ? () => {
                if (auth.user) {
                  modalApi.open('pay-team-form', {
                    userEmail: auth.user.email,
                    team: team?.id ? team : reduxTeam,
                  })
                }
              }
            : undefined,
          doNotShowAgain: () => {
            void updateCtaSettings({
              ctaSettings: {
                DISMISSED_TEAM_TRIAL_ENDED_MODAL: true,
              },
            })
          },
        })
      }
    },
    [
      updateCtaSettings,
      auth.subscription,
      auth.subscription.status,
      modalApi,
      auth.user,
      reduxTeam,
      team,
      showCta.loaded,
      showCta.seen,
      isExpiredTeam,
      shouldUseTieredPricing,
    ]
  )

  useEffect(() => {
    if (theme === 'light') {
      document.body.classList.remove('dark')
    } else {
      document.body.classList.add('dark')
    }
  }, [theme])

  const flags = useMemoSyncExternalStore(
    'flags',
    featureFlags.changed.subscribe,
    () => featureFlags.all()
  )

  return (
    <>
      <div
        className='fill'
        id='motion-web-root'
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          overflow: 'clip',
        }}
        // @ts-expect-error - ref typing is hard
        ref={frameRef}
      >
        <AppRouter featureFlags={flags} />
        {page !== 'desktop' && <AlertManager />}
      </div>
    </>
  )
})
