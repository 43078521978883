import {
  DescriptionField,
  type DescriptionFieldProps,
} from '~/areas/task-project/fields'
import { useTaskUpdater } from '~/areas/tasks/hooks'
import { useController } from 'react-hook-form'

import { useTaskModalState } from '../contexts'
import { useTaskForm } from '../hooks'

export const ControlledDescriptionField = () => {
  const { form, disabledFields } = useTaskForm()
  const {
    options: { isAutoSaving },
  } = useTaskModalState()
  const { control, watch, setValue } = form
  const updateTask = useTaskUpdater()

  const isFieldDisabled = disabledFields.has('description')

  const { field } = useController({
    name: 'description',
    control,
  })

  const workspaceId = watch('workspaceId')
  const taskId = watch('id')
  const taskType = watch('type')

  const onChange: DescriptionFieldProps['onChange'] = async (value) => {
    if (isAutoSaving && taskId != null) {
      setValue('description', value)
      return await updateTask(taskId, { description: value })
    }
    field.onChange(value)
  }

  return (
    <DescriptionField
      value={field.value}
      onChange={onChange}
      triggerOnChangeOnBlur={isAutoSaving && taskId != null}
      workspaceId={workspaceId}
      taskId={taskId}
      taskType={taskType}
      disabled={isFieldDisabled}
    />
  )
}
