import { useDependantState } from '@motion/react-core/hooks'
import { byProperty, ordered } from '@motion/utils/array'
import { createLookupBy, createLookupByKey } from '@motion/utils/object'

import {
  type ColumnDef,
  type ColumnOrderState,
  type ColumnPinningState,
  type ColumnSizingState,
  type Updater,
  type VisibilityState,
} from '@tanstack/react-table'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import { type ViewStateColumn } from '../../view-state'

type useTableViewStateProps<T> = {
  columnState: ViewStateColumn[]
  allColumns: ColumnDef<T>[]
  onColumnStateChange: (state: ViewStateColumn[]) => void
}

export function useTableViewState<T>({
  columnState,
  allColumns,
  onColumnStateChange,
}: useTableViewStateProps<T>) {
  const definitionLookup = useMemo(
    () => createLookupByKey(allColumns, 'id'),
    [allColumns]
  )

  const columnLookup = useMemo<Record<string, ViewStateColumn | undefined>>(
    () => createLookupByKey(columnState, 'id'),
    [columnState]
  )

  const allColumnIds = useMemo(
    () => allColumns.map((col) => col.id).filter(Boolean),
    [allColumns]
  )

  const getColumnVisibility = useCallback(
    (id: string) => columnLookup[id]?.visible ?? true,
    [columnLookup]
  )

  const columns = useMemo(() => {
    const columnIds =
      columnState.length === 0
        ? allColumns.map((col) => col.id).filter(Boolean)
        : columnState.map((col) => col.id)

    const missingColumnIds = allColumns
      .filter((def) => def.id && !columnLookup[def.id])
      .map((x) => x.id)
      .filter(Boolean)

    // Add the missing columns based on their default indexes
    // That way they don't end up at the end of the view state column order list
    const finalColumns = missingColumnIds.reduce(
      (acc, missingId) => {
        const foundIndex = allColumns.findIndex((c) => c.id === missingId)

        if (foundIndex === -1) {
          acc.push(missingId)
        } else {
          acc.splice(foundIndex, 0, missingId)
        }

        return acc
      },
      [...columnIds]
    )

    return finalColumns.map((id) => {
      const found = columnLookup[id]

      return {
        id: id,
        pinned: found?.pinned ?? false,
        visible: getColumnVisibility(id),
        width: found?.width,
      } satisfies ViewStateColumn
    })
  }, [allColumns, columnLookup, columnState, getColumnVisibility])

  const columnIds = useMemo(() => columns.map((x) => x.id), [columns])

  useEffect(() => {
    if (columnState.length === 0) {
      onColumnStateChange(
        allColumnIds.map<ViewStateColumn>((id) => ({
          id,
          visible: true,
          width: definitionLookup[id].size ?? 100,
          pinned: false,
        }))
      )
    }
  }, [allColumnIds, columnState.length, definitionLookup, onColumnStateChange])

  const [columnOrder, setColumnOrderCore] =
    useDependantState<ColumnOrderState>(() => {
      return [...columnIds]
    }, [columnIds])

  const [columnSizing, setColumnSizingCore] =
    useDependantState<ColumnSizingState>(() => {
      return createLookupBy(
        allColumnIds,
        (id) => id,
        (id) => columnLookup[id]?.width ?? definitionLookup[id].size ?? 100
      )
    }, [allColumnIds, columnLookup, definitionLookup])

  const [columnVisibility, setColumnVisibilityCore] =
    useDependantState<VisibilityState>(() => {
      return createLookupBy(
        allColumnIds,
        (id) => id,
        (id) => getColumnVisibility(id)
      )
    }, [allColumnIds, getColumnVisibility])

  const [columnPinning, setColumnPinningCore] =
    useDependantState<ColumnPinningState>(() => {
      return {
        left: columnState.reduce<string[]>((acc, col) => {
          if (col.pinned) {
            acc.push(col.id)
          }
          return acc
        }, []),
      }
    }, [columnState])

  const ref = useRef({
    columnOrder,
    columnVisibility,
    columnSizing,
    columnPinning,
    columns,
  })

  ref.current = {
    columnOrder,
    columnVisibility,
    columnSizing,
    columnPinning,
    columns,
  }

  const {
    setColumnOrder,
    setColumnVisibility,
    setColumnSizing,
    setColumnPinning,
  } = useMemo(() => {
    function setColumnOrder(setter: Updater<ColumnOrderState>) {
      const value =
        typeof setter === 'function' ? setter(ref.current.columnOrder) : setter

      setColumnOrderCore(value)

      onColumnStateChange(
        [...ref.current.columns].sort(byProperty('id', ordered(value)))
      )
    }

    function setColumnVisibility(setter: Updater<VisibilityState>) {
      const value =
        typeof setter === 'function'
          ? setter(ref.current.columnVisibility)
          : setter

      setColumnVisibilityCore(value)

      const newlyVisible = Object.keys(value).filter(
        (id) => !ref.current.columns.some((x) => x.id === id)
      )

      onColumnStateChange(
        ref.current.columns
          .map((col) => ({
            ...col,
            visible: value[col.id] ?? col.visible,
          }))
          .concat(
            newlyVisible.map((id) => ({
              id,
              visible: value[id] ?? false,
              pinned: ref.current.columnPinning.left?.includes(id) ?? false,
              width:
                ref.current.columnSizing[id] ??
                definitionLookup[id].size ??
                100,
            }))
          )
      )
    }

    function setColumnSizing(setter: Updater<ColumnSizingState>) {
      const value =
        typeof setter === 'function' ? setter(ref.current.columnSizing) : setter

      setColumnSizingCore(value)
      onColumnStateChange(
        ref.current.columns.map((col) => ({
          ...col,
          width: value[col.id] ?? col.width ?? 100,
        }))
      )
    }

    function setColumnPinning(setter: Updater<ColumnPinningState>) {
      const value =
        typeof setter === 'function'
          ? setter(ref.current.columnPinning)
          : setter

      setColumnPinningCore(value)

      onColumnStateChange(
        ref.current.columns.map((col) => ({
          ...col,
          pinned: value.left?.includes(col.id) ?? false,
        }))
      )
    }

    return {
      setColumnOrder,
      setColumnVisibility,
      setColumnSizing,
      setColumnPinning,
    }
  }, [
    setColumnOrderCore,
    onColumnStateChange,
    setColumnVisibilityCore,
    definitionLookup,
    setColumnSizingCore,
    setColumnPinningCore,
  ])

  return {
    columnOrder,
    setColumnOrder,
    columnVisibility,
    setColumnVisibility,
    columnSizing,
    setColumnSizing,
    columnPinning,
    setColumnPinning,
  }
}
