import { templateStr } from '@motion/react-core/strings'
import { showToast } from '@motion/ui/base'
import { sleep } from '@motion/utils/promise'
import { Sentry } from '@motion/web-base/sentry'
import { useModalApi } from '@motion/web-common/modals'
import { type TaskSchema } from '@motion/zod/client'

import { useWorkspaceFns } from '~/global/hooks'
import { useReadTaskFn } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useTaskUpdater } from '../updaters'

export const useMoveTaskToWorkspaceProject = () => {
  const modalApi = useModalApi()
  const updateTask = useTaskUpdater()
  const readTask = useReadTaskFn()
  const { getWorkspaceById } = useWorkspaceFns()

  return useCallback(
    async (
      taskId: TaskSchema['id'],
      newWorkspaceId: TaskSchema['workspaceId'],
      newProjectId: TaskSchema['projectId']
    ) => {
      try {
        const task = await readTask(taskId)
        if (task == null) {
          throw new Error('Task not found')
        }

        if (
          task.type === 'NORMAL' &&
          (task.blockingTaskIds.length > 0 || task.blockedByTaskIds.length > 0)
        ) {
          showToast(
            'error',
            'This task has dependencies on other tasks in the project. Please remove blockers before moving.'
          )
          return
        }

        if (newWorkspaceId !== task.workspaceId) {
          const currentWorkspace = getWorkspaceById(task.workspaceId)
          const workspace = getWorkspaceById(newWorkspaceId)

          // Sleeping a bit to make sure any dropdown gets closed before prompting the modal to keep the focus within the modal
          await sleep(1)

          const confirmResponse = await modalApi.prompt('confirm', {
            analytics: {
              name: 'move-task-workspace',
            },
            title: templateStr(
              'Are you sure you want to move this task from {{ oldWorkspace }} to {{ newWorkspace }}?',
              {
                oldWorkspace: currentWorkspace?.name,
                newWorkspace: workspace?.name,
              }
            ),
            description:
              'Assignees, labels and statuses may be reset if they are not available in the other workspace.',
            confirmButtonText: 'Move task',
            closeButtonText: 'Cancel',
          })

          if (confirmResponse !== true) return
        }

        const updatedTask = await updateTask(task, {
          workspaceId: newWorkspaceId,
          projectId: newProjectId,
        })

        return updatedTask
      } catch (e) {
        Sentry.captureException(e)
        showErrorToast(
          e,
          'There was an error in moving your task. Please try again'
        )

        throw e
      }
    },
    [getWorkspaceById, modalApi, readTask, updateTask]
  )
}
