import { useOnDesktopEvent, useSendToDesktop } from '@motion/desktop-ipc/hooks'
import { ArrowLeftSolid, ArrowRightSolid } from '@motion/icons'
import { IconButton, Tooltip, useShortcut } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { getWindowData } from '@motion/web-base/env'

import React, { useState } from 'react'

type NavigationButtonsProps = {
  inPortal: boolean
}

export const HeaderNavigationButtons = ({
  inPortal,
}: NavigationButtonsProps) => {
  const { isMac } = getWindowData()
  const sendToDesktop = useSendToDesktop()

  const [canNavigate, setCanNavigate] = useState({
    canGoBack: false,
    canGoForward: false,
  })

  const onNavigate = (direction: 'backward' | 'forward') => {
    if (direction === 'backward') {
      recordAnalyticsEvent('DESKTOP_NAVIGATE_BACK')
    } else {
      recordAnalyticsEvent('DESKTOP_NAVIGATE_FORWARD')
    }

    sendToDesktop('navigate', { direction })
  }

  useOnDesktopEvent('canNavigate', ({ canGoForward, canGoBack }) => {
    setCanNavigate({ canGoForward, canGoBack })
  })

  useShortcut(
    'mod+[',
    () => {
      onNavigate('backward')
    },
    { enabled: canNavigate.canGoBack }
  )

  useShortcut(
    'mod+]',
    () => {
      onNavigate('forward')
    },
    { enabled: canNavigate.canGoForward }
  )

  return (
    <div className='-space-x-px [&>button:first-of-type]:rounded-r-none [&>button:last-of-type]:rounded-l-none shrink-0'>
      <Tooltip
        asChild
        renderContent={() => {
          return (
            <div>
              <div>Go Back</div>
              <div>{isMac ? '⌘' : 'Ctrl'} + [</div>
            </div>
          )
        }}
      >
        <IconButton
          icon={ArrowLeftSolid}
          sentiment='neutral'
          disabled={!canNavigate.canGoBack}
          onClick={() => onNavigate('backward')}
          size='small'
          variant={inPortal ? 'outlined' : 'muted'}
          aria-label='Go back'
        />
      </Tooltip>

      <Tooltip
        asChild
        renderContent={() => {
          return (
            <div>
              <div>Go Forward</div>
              <div>{isMac ? '⌘' : 'Ctrl'} + ]</div>
            </div>
          )
        }}
      >
        <IconButton
          icon={ArrowRightSolid}
          sentiment='neutral'
          disabled={!canNavigate.canGoForward}
          onClick={() => onNavigate('forward')}
          size='small'
          variant={inPortal ? 'outlined' : 'muted'}
          aria-label='Go forward'
        />
      </Tooltip>
    </div>
  )
}
