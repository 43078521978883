import { StageField, type StageFieldProps } from '~/areas/task-project/fields'
import { useTaskStageUpdater } from '~/areas/tasks/hooks'
import { useController } from 'react-hook-form'

import { useTaskForm } from '../hooks'

export function ControlledStageField() {
  const { form, disabledFields } = useTaskForm()
  const { control, watch } = form
  const updateTaskStage = useTaskStageUpdater()

  const taskId = watch('id')

  const { field: projectIdField } = useController({
    name: 'projectId',
    control,
  })
  const { field: stageIdField } = useController({
    name: 'stageDefinitionId',
    control,
  })

  const onStageChange: StageFieldProps['onChange'] = (stage) => {
    if (taskId != null) {
      return updateTaskStage(taskId, stage)
    }

    stageIdField.onChange(stage.id)
  }

  if (stageIdField.value == null) return null

  return (
    <StageField
      projectId={projectIdField.value}
      stageDefinitionId={stageIdField.value}
      onChange={onStageChange}
      disabled={disabledFields.has('stageDefinitionId')}
      showLabel
    />
  )
}
