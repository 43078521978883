import { wrapVariableInDelimiters } from '@motion/shared/flows'
import { createPlaceholderId } from '@motion/shared/identifiers'
import { type VariableDefinitionSchema } from '@motion/zod/client'

import { type NodeViewProps } from '@tiptap/core'
import {
  type FlowVariableProps,
  NodeVariableLabelView,
} from '~/areas/flows/components'
import { useTaskNameVariableUpdater } from '~/areas/flows/flow-template-modal/fields'
import { createNewTextVariable } from '~/areas/flows/utils'

import { ConnectedFlowVariableSearchableList } from './connected-flow-variable-searchable-list'

type ConnectedNodeVariableLabelAttrs = {
  id: string
  taskId: string
  size?: 'xsmall' | 'small'
}

export const ConnectedNodeVariableLabel = ({
  node: { attrs },
  editor,
}: NodeViewProps) => {
  const updater = useTaskNameVariableUpdater()

  const {
    id: variableKey,
    taskId,
    size,
  } = attrs as ConnectedNodeVariableLabelAttrs
  const { stages, textVariables } = editor.storage.flowVariable as Omit<
    FlowVariableProps,
    'taskId'
  >

  if (
    variableKey == null ||
    taskId == null ||
    stages == null ||
    textVariables == null
  ) {
    return null
  }

  const idWithPlaceholder = createPlaceholderId(taskId)
  const stageIndex = stages.findIndex((s) =>
    s.tasks.some((t) => t.id === taskId || t.id === idWithPlaceholder)
  )
  if (stageIndex === -1) {
    return null
  }

  const taskIndex = stages[stageIndex].tasks.findIndex(
    (t) => t.id === taskId || t.id === idWithPlaceholder
  )
  if (taskIndex === -1) {
    return null
  }

  const variable =
    textVariables.find((v) => v.key === variableKey) ??
    createNewTextVariable({
      name: 'Unknown',
    })

  const handleReplace = (item: VariableDefinitionSchema) => {
    const wrappedKey = wrapVariableInDelimiters(variable.key)
    const replacementKey = wrapVariableInDelimiters(item.key)
    updater({
      wrappedKey,
      replacementKey,
      operation: 'replace',
      stageIndex,
      taskIndex,
    })
  }

  return (
    <NodeVariableLabelView
      variable={variable}
      size={size}
      renderPopover={({ close }) => (
        <ConnectedFlowVariableSearchableList
          items={textVariables}
          close={close}
          onChange={handleReplace}
          selectedItem={variable}
        />
      )}
    />
  )
}
