import { PopoverTrigger, type PopoverTriggerProps } from '@motion/ui/base'
import {
  type RecursiveFolderItemSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { type PropsWithChildren, type ReactNode } from 'react'

import { type LeafNodeType } from './hooks'
import { WorkspacesSearchableTree } from './workspaces-searchable-tree'

export type WorkspacesTreeDropdownProps = PropsWithChildren<{
  selectedId: string | null
  workspaceId?: WorkspaceSchema['id']
  onChange: (selected: {
    noteId: string | null
    projectId: string | null
    folderId: string | null
    workspaceId: string
  }) => void
  placement?: PopoverTriggerProps['placement']
  leafNodeType?: LeafNodeType
  computeDisabled?: (props: {
    item: RecursiveFolderItemSchema
    workspace: WorkspaceSchema
  }) => boolean
  hideNoProject?: boolean
  includeNotes?: boolean
  allowSelectAnyNode?: boolean
  showCreateFolder?: boolean
  renderHeader?: () => ReactNode
  onClose?: () => void
}>

export function WorkspacesTreeDropdown({
  children,
  placement = 'bottom-start',
  selectedId,
  workspaceId,
  onChange,
  leafNodeType,
  computeDisabled,
  hideNoProject,
  includeNotes,
  allowSelectAnyNode = false,
  showCreateFolder = false,
  renderHeader = () => null,
  onClose,
}: WorkspacesTreeDropdownProps) {
  return (
    <PopoverTrigger
      placement={placement}
      onClose={onClose}
      renderPopover={({ close }) => (
        <>
          {renderHeader()}
          <WorkspacesSearchableTree
            selectedId={selectedId}
            workspaceId={workspaceId}
            leafNodeType={leafNodeType}
            onChange={(selected) => {
              close()
              onChange(selected)
            }}
            computeDisabled={computeDisabled}
            hideNoProject={hideNoProject}
            includeNotes={includeNotes}
            allowSelectAnyNode={allowSelectAnyNode}
            showCreateFolder={showCreateFolder}
            closePopover={close}
          />
        </>
      )}
    >
      {children}
    </PopoverTrigger>
  )
}
