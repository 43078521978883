import { wrapVariableInDelimiters } from '@motion/shared/flows'
import { SearchableList } from '@motion/ui/base'

import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { type TaskTextVariableUpdater } from '~/areas/flows/shared-form/utils'
import { StageLabel } from '~/global/components/labels'

type AddVariableToStageTasksPopoverProps = {
  type: 'prepend' | 'append'
  close: () => void
  variableKey: string
  updater: TaskTextVariableUpdater
}

export const AddVariableToStageTasksPopoverContent = ({
  type,
  variableKey,
  close,
  updater,
}: AddVariableToStageTasksPopoverProps) => {
  const {
    form: { watch },
  } = useFlowTemplateForm()
  const stages = watch('stages')

  const stageItems = stages
    .filter((s) => s.name)
    .map((stage) => ({
      id: stage.id,
      name: stage.name,
      content: <StageLabel value={stage} />,
    }))

  return (
    <SearchableList
      items={stageItems}
      inputProps={{
        placeholder: `${type === 'prepend' ? 'Prepend' : 'Append'} to all tasks in`,
      }}
      computeKey={(item) => item.id}
      renderItem={(item) => item.content}
      computeSelected={() => false}
      computeSearchValue={(item) => item.name}
      itemType='select'
      onSelect={(item) => {
        const foundStageIndex = stages.findIndex((s) => s.id === item.id)
        if (foundStageIndex !== -1) {
          const wrappedKey = wrapVariableInDelimiters(variableKey)

          updater({ wrappedKey, operation: type, stageIndex: foundStageIndex })
        }

        close()
      }}
    />
  )
}
