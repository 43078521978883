import { FolderOpenSolid, FolderSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'

import { useMatchedRouteData, useUriByRouteId } from '~/routing'

export function BrowseButton() {
  const getUri = useUriByRouteId({ noDefaults: true })
  const routeData = useMatchedRouteData()

  const isOnBrowsePage = routeData.data.variant === 'browse'

  const parentUri = getUri('parent', routeData.params)

  return (
    <>
      <StyledBrowseButton
        url={`${parentUri}/browse`}
        sentiment={isOnBrowsePage ? 'primary' : 'neutral'}
        variant='outlined'
        size='small'
      >
        {isOnBrowsePage ? <FolderOpenSolid /> : <FolderSolid />} Navigate
      </StyledBrowseButton>
      <Separator />
    </>
  )
}

const StyledBrowseButton = classed(Button, {
  base: `
    rounded-full
  `,
})

const Separator = classed('div', {
  base: `
    h-5 border-l border-semantic-neutral-border-subtle
  `,
})
