import { XSolid } from '@motion/icons'
import { type Schedule, type ScheduleByDow } from '@motion/rpc-types'
import {
  BaseModal,
  Button,
  IconButton,
  PopoverButton,
  Text,
} from '@motion/ui/base'
import { AvailabilityEvent } from '@motion/ui/calendar'
import { TextField } from '@motion/ui/forms'
import { ConditionalWrapper } from '@motion/ui/utils'
import { convertScheduleIntoEvents } from '@motion/ui-logic/calendar'
import { byValue, Compare } from '@motion/utils/array'
import { parseDate } from '@motion/utils/dates'
import { NOOP_FUNCTION } from '@motion/utils/function'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { logInDev } from '@motion/web-base/logging'

import {
  type DateSelectArg,
  type EventContentArg,
  type EventDropArg,
  type EventMountArg,
} from '@fullcalendar/core'
import interactionPlugin, {
  type EventResizeDoneArg,
} from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import { type OpenTimezoneModalProps } from '~/areas/calendar/components/calendar-header/timezone-group/types'
import { getTzAbbr } from '~/utils/time'
import { DateTime } from 'luxon'
import { nanoid } from 'nanoid'
import { useCallback, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { useUpdateSchedules } from '../../../localServices/firebase'
import {
  type DayRangeType,
  type SlotType,
} from '../../Booking/components/Modals/AvailabilityTimePickerModal/AvailabilityTimePickerModal'
import { TimePickerCopyTimesDropdown } from '../../Booking/components/Modals/AvailabilityTimePickerModal/TimePickerCopyTimesDropdown/TimePickerCopyTimesDropdown'
import { FullCalendarThemeSelector } from '../../Common/FullCalendarThemeSelector/FullCalendarThemeSelector'

const defaultSchedule: ScheduleByDow = {
  Friday: [],
  Monday: [],
  Saturday: [],
  Sunday: [],
  Thursday: [],
  Tuesday: [],
  Wednesday: [],
}

export interface ScheduleModalProps {
  onClose: (scheduleId?: string) => void
  schedules: {
    [key: string]: Schedule
  }
  onChange: (schedules: { [key: string]: Schedule }) => void
  scheduleId?: string | null
  defaultTimezone?: string
  isOnboarding?: boolean
  isNewOnboarding?: boolean
  openTimezoneModal?: (props: OpenTimezoneModalProps) => void
}

export const ScheduleModal = ({
  onClose,
  schedules,
  onChange: schedulesHandler,
  scheduleId: selectedId,
  defaultTimezone,
  isOnboarding = false,
  isNewOnboarding,
  openTimezoneModal = NOOP_FUNCTION,
}: ScheduleModalProps) => {
  const [slots, setSlots] = useState<SlotType[]>([])
  const [visibleDropdown, setVisibleDropdown] = useState('')

  const [titleInput, setTitleInput] = useState('')
  const [timezone, setTimezone] = useState('default')
  const calendarTempRef = useRef<FullCalendar | undefined>(undefined)
  const updateSchedules = useUpdateSchedules()

  const calendarRef = useCallback((calendar: FullCalendar) => {
    if (calendar) {
      calendarTempRef.current = calendar
    } else {
    }
  }, [])
  const availabilityChangedRef = useRef(false)
  const frameRef = useRef(undefined)

  const processDays = useCallback((daysInput: ScheduleByDow) => {
    const newSlots: SlotType[] = convertScheduleIntoEvents(daysInput, {
      startingDay: 'sunday',
    }).map((event) => ({
      ...event,
      preferred: false,
      editable: true,
      id: nanoid(),
    }))

    setSlots(newSlots)
  }, [])

  useEffect(
    function onAvailabilityChanged() {
      if (!availabilityChangedRef.current) {
        if (selectedId && schedules[selectedId]) {
          const selectedDays = schedules[selectedId]
          if (selectedDays && selectedDays.schedule) {
            processDays(selectedDays.schedule)
            setTitleInput(selectedDays.title)
            setTimezone(selectedDays.timezone || 'default')
          }
        } else {
          processDays(defaultSchedule)
          setTitleInput('')
          setTimezone('default')
        }
      }
    },
    [processDays, selectedId, schedules]
  )

  const formatDaysOutput = useCallback((slotsArr: SlotType[]) => {
    const tempDays: {
      [key: string]: DayRangeType[]
    } = {
      Friday: [],
      Monday: [],
      Saturday: [],
      Sunday: [],
      Thursday: [],
      Tuesday: [],
      Wednesday: [],
    }
    slotsArr.forEach((slot) => {
      const day = parseDate(slot.start).toFormat('cccc')
      tempDays[day].push({
        preferred: false,
        range: [
          shortTime(parseDate(slot.start)),
          shortTime(parseDate(slot.end)),
        ].join('-'),
      })
    })
    return tempDays
  }, [])

  const submitHandler = useCallback(async () => {
    if (!titleInput) {
      // TODO: show error message
      return
    }
    // process selected schedule before saving
    const tempSchedule = formatDaysOutput(slots)
    const tempSchedules = { ...schedules }
    const scheduleId = selectedId || nanoid(5)
    tempSchedules[scheduleId] = {
      // @ts-expect-error - ignored
      schedule: tempSchedule,

      timezone,

      title: titleInput,
    }
    schedulesHandler(tempSchedules)
    await updateSchedules(tempSchedules)

    onClose(scheduleId)
    recordAnalyticsEvent('SCHEDULE_SAVE')
  }, [
    onClose,
    slots,
    formatDaysOutput,
    selectedId,
    schedules,
    schedulesHandler,
    updateSchedules,
    titleInput,
    timezone,
  ])

  const processEndTime = useCallback((end: string) => {
    if (parseDate(end).equals(parseDate(end).startOf('day'))) {
      return parseDate(end).minus({ minutes: 1 }).toISO()
    }
    return end
  }, [])

  const onAvailabilitySelect = useCallback(
    (info: DateSelectArg) => {
      if (!info || !info.jsEvent) {
        logInDev('dragging info not found', info)
        return
      }

      const id = nanoid()
      const tempList = [...slots]
      info.endStr = processEndTime(info.endStr)
      tempList.push({
        preferred: false,
        editable: true,
        end: info.endStr,
        id,
        start: info.startStr,
      })

      tempList.sort(byValue((v) => parseDate(v.start), Compare.dateTime))
      setSlots(tempList)

      calendarTempRef?.current?.getApi().unselect()
      availabilityChangedRef.current = true
    },
    [slots, availabilityChangedRef, processEndTime]
  )

  const deleteEvent = useCallback((info: EventMountArg | EventContentArg) => {
    setSlots((prev) => {
      const newItems = prev.filter((item) => item.id !== info.event.id)
      if (prev.length !== newItems.length) {
        availabilityChangedRef.current = true
      }
      return newItems
    })
  }, [])

  const changeAvailabilitySlot = useCallback(
    (info: EventDropArg | EventResizeDoneArg) => {
      if (!info.event) {
        return
      }

      const tempList = [...slots]
      const slotIndex = tempList.findIndex((slot) => slot.id === info.event.id)
      if (slotIndex !== -1 && info.event.start) {
        tempList[slotIndex].start = info.event.start.toISOString()
        tempList[slotIndex].end = processEndTime(info.event.endStr)
        tempList.sort(byValue((v) => parseDate(v.start), Compare.dateTime))
        setSlots(tempList)
        availabilityChangedRef.current = true
      }
    },
    [slots, availabilityChangedRef, processEndTime]
  )

  useEffect(() => {
    const dropdownDismissHandler = (e: any) => {
      if (e.target.closest('.ant-dropdown')) {
        return
      }
      setVisibleDropdown('')
    }

    const container = frameRef.current
    visibleDropdown &&
      container &&
      // @ts-expect-error - ignored
      container.addEventListener('mouseup', dropdownDismissHandler, true)

    return () => {
      container &&
        // @ts-expect-error - ignored
        container.removeEventListener('mouseup', dropdownDismissHandler, true)
    }
  }, [visibleDropdown])

  const onTimezoneChange = useCallback(async (value: string | null) => {
    setTimezone(value ?? 'default')
  }, [])

  return (
    <ConditionalWrapper
      condition={!isOnboarding}
      wrapper={(children) => (
        <BaseModal fullScreen onClose={() => onClose()} visible>
          <div className='my-auto flex h-[95vh] md:h-[85vh] w-[95vw] min-w-[95vw] flex-col overflow-hidden'>
            {children}
          </div>
        </BaseModal>
      )}
    >
      <div
        className={twMerge(
          'flex h-full w-full flex-col',
          isOnboarding && !isNewOnboarding && 'h-[calc(100vh-300px)]'
        )}
        // @ts-expect-error - ignored
        ref={frameRef}
      >
        {!isOnboarding && (
          <div className='border-semantic-neutral-border-default flex items-center justify-between border-b p-4'>
            <Text weight='semibold'>
              {selectedId ? 'Edit Schedule' : 'New Schedule'}
            </Text>
            <IconButton
              sentiment='neutral'
              variant='muted'
              onClick={() => onClose()}
              icon={XSolid}
            />
          </div>
        )}
        <div className='flex h-full w-full'>
          {!isOnboarding && (
            <div className='border-semantic-neutral-border-default flex w-[280px] flex-col gap-4 border-r p-4'>
              <div className='flex flex-col gap-1'>
                <Text sentiment='subtle' size='xs' weight='semibold'>
                  Schedule name
                </Text>
                <TextField
                  label='Schedule name'
                  labelHidden
                  value={titleInput}
                  onChange={(value) => {
                    setTitleInput(value)
                  }}
                  placeholder='Schedule name'
                  autoFocus={!selectedId}
                />
              </div>
              <div className='flex flex-col gap-1'>
                <Text sentiment='subtle' size='xs' weight='semibold'>
                  Schedule Timezone
                </Text>
                <PopoverButton
                  onClick={() => {
                    openTimezoneModal({
                      changeHandler: onTimezoneChange,
                      showDefault: true,
                    })
                  }}
                >
                  {timezone !== 'default'
                    ? getTzAbbr(timezone)
                    : `Default (${getTzAbbr(defaultTimezone)})`}
                </PopoverButton>
              </div>
            </div>
          )}
          <div className='h-full w-full'>
            <div
              className='calendar-main-container calendar-weekly'
              style={{ height: 'calc(100% - 65px)' }}
            >
              <FullCalendarThemeSelector
                className={twMerge(
                  '[&_.fc-scrollgrid-liquid]:!border-transparent dark:[&_.fc-scrollgrid-liquid]:!border-transparent',
                  '[&_.fc-col-header-cell-cushion]:!border-r-transparent dark:[&_.fc-col-header-cell-cushion]:!border-r-transparent'
                )}
              >
                <FullCalendar
                  ref={calendarRef}
                  plugins={[interactionPlugin, timeGridPlugin]}
                  initialView='timeGridWeek'
                  events={slots}
                  nowIndicator={false}
                  height='100%'
                  scrollTime='09:00:00'
                  allDaySlot
                  allDayContent={() => <div />} // hack for chrome 91 rendering
                  dayHeaders
                  dayHeaderContent={(info) => {
                    if (info) {
                      const day = DateTime.fromJSDate(info.date).toFormat(
                        'cccc'
                      )
                      return (
                        <div className='flex w-full items-center justify-between gap-1'>
                          <Text sentiment='subtle' size='sm'>
                            {day.slice(0, 3)}
                          </Text>
                          <TimePickerCopyTimesDropdown
                            currentDay={day}
                            slots={slots}
                            setSlotsHandler={setSlots}
                          />
                        </div>
                      )
                    }
                  }}
                  dragScroll={false}
                  selectable
                  select={onAvailabilitySelect}
                  droppable={false}
                  selectMirror
                  editable
                  eventResizableFromStart
                  eventDurationEditable
                  slotDuration='00:15'
                  slotLabelInterval='01:00'
                  slotLabelContent={(info) => {
                    return (
                      <div className='w-10'>
                        <div className='absolute right-2 -top-1.5 text-semantic-neutral-text-subtle text-[10px]'>
                          {info.text}
                        </div>
                      </div>
                    )
                  }}
                  eventDrop={changeAvailabilitySlot}
                  eventResize={changeAvailabilitySlot}
                  eventContent={(event) => {
                    const { start, end } = event.event
                    if (!start) return null
                    return (
                      <AvailabilityEvent
                        startTime={start}
                        endTime={end ?? start}
                        onRemove={() => deleteEvent(event)}
                      />
                    )
                  }}
                  eventTimeFormat={{
                    hour: 'numeric',
                    meridiem: false,
                    minute: '2-digit',
                    omitZeroMinute: true,
                  }}
                  headerToolbar={false}
                />
              </FullCalendarThemeSelector>
            </div>

            <div className='dark:border-dark-1000 border-light-500 flex w-full items-center border-t p-4'>
              {!isOnboarding && <Text size='sm'>Drag to select times.</Text>}
              <div className='ml-auto flex gap-2'>
                {isOnboarding ? null : (
                  <Button
                    sentiment='neutral'
                    variant='outlined'
                    onClick={() => onClose()}
                  >
                    Cancel
                  </Button>
                )}
                <Button sentiment='primary' onClick={submitHandler}>
                  {isOnboarding ? 'Next' : 'Save changes'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConditionalWrapper>
  )
}

function shortTime(value: DateTime) {
  return value.toFormat('h:mma').toLowerCase()
}
