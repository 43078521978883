import { templateStr } from '@motion/react-core/strings'
import { Banner, ProgressBar } from '@motion/ui/base'
import { getTierTitle } from '@motion/ui-logic/tiered-pricing'
import { useAccountFeatureUsage, useFeatureLimit } from '@motion/web-billing'

import { getNextTier, TieredPricingUpsellButton } from '~/areas/tiered-pricing'
import { useCurrentTier } from '~/areas/tiered-pricing/hooks'
import { SettingPageWrapper } from '~/pages/settings/shared'

import storagePageImage from './storage-page-image.svg'

export const StorageSettings = () => {
  const currentTier = useCurrentTier()
  const nextTier = getNextTier(currentTier)

  const storageLimit = useFeatureLimit('attachmentStorage')
  const storageUsage = useAccountFeatureUsage('attachmentStorage') ?? 0
  const usagePercent = storageLimit ? (storageUsage / storageLimit) * 100 : 0
  const progressBarValue = Math.min(usagePercent, 100)
  return (
    <SettingPageWrapper
      title='Storage'
      fullWidth
      banner={
        nextTier && (
          <Banner data-testid='upsell-banner'>
            {templateStr(
              'Upgrade to {{tierName}} to get {{nextStorage}} storage.',
              {
                tierName: getTierTitle(nextTier),
                nextStorage:
                  nextTier === 'ENTERPRISE' ? 'unlimited' : '50 GB of',
              }
            )}
            <TieredPricingUpsellButton
              size='small'
              tier={nextTier}
              feature='attachmentStorage'
              featureLocked
            >
              Upgrade
            </TieredPricingUpsellButton>
          </Banner>
        )
      }
    >
      <div className='w-full flex justify-center'>
        <div className='w-[409px] p-6 border border-semantic-neutral-border-subtle rounded flex flex-col items-center gap-4'>
          <img src={storagePageImage} className='h-[65px]' />
          {storageLimit <= 0 ? (
            <h1 className='text-semantic-neutral-text-default font-medium text-sm'>
              {templateStr('{{gbUsed}} GB of storage used', {
                gbUsed: storageUsage.toFixed(1),
              })}
            </h1>
          ) : (
            <>
              <h1 className='text-semantic-neutral-text-default font-medium text-sm'>
                {templateStr(
                  '{{usagePercent}}% of storage used ({{gbUsed}} GB/{{gbLimit}} GB)',
                  {
                    usagePercent: usagePercent.toFixed(0),
                    gbUsed: storageUsage.toFixed(1),
                    gbLimit: storageLimit,
                  }
                )}
              </h1>
              <div className='w-full'>
                <ProgressBar
                  value={progressBarValue}
                  sentiment={
                    progressBarValue < 75
                      ? 'success'
                      : progressBarValue < 85
                        ? 'warning'
                        : 'error'
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </SettingPageWrapper>
  )
}
