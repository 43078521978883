import { useClosure, useDependantState } from '@motion/react-core/hooks'
import {
  type ProjectSchema,
  type TaskSchema,
  type TaskType,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { type FlowVariableProps } from '~/areas/flows'
import { useState } from 'react'

import { DescriptionEditor, type DescriptionEditorProps } from '../components'

export type DescriptionFieldProps = {
  projectId?: ProjectSchema['id'] | undefined
  taskId?: TaskSchema['id'] | undefined
  taskType?: TaskType | undefined
  workspaceId: WorkspaceSchema['id']
  value: TaskSchema['description']
  onChange: (value: TaskSchema['description']) => void
  disabled?: boolean
  variableArgs?: FlowVariableProps
  triggerOnChangeOnBlur?: boolean
}

export const DescriptionField = ({
  projectId,
  taskId,
  taskType,
  workspaceId,
  value,
  onChange,
  disabled = false,
  variableArgs,
  triggerOnChangeOnBlur = false,
}: DescriptionFieldProps) => {
  const [isDirty, setIsDirty] = useState(false)
  const [internalDescription, setInternalDescription] = useDependantState(
    () => value ?? '',
    [value],
    { freezeDependencyUpdates: isDirty }
  )

  const internalOnChange: DescriptionEditorProps['onChange'] = async (
    value
  ) => {
    if (triggerOnChangeOnBlur) {
      setInternalDescription(value)
      setIsDirty(true)
    } else {
      onChange(value)
    }
  }

  const submitChange = async (value: string, { force = false } = {}) => {
    if (triggerOnChangeOnBlur) {
      if (!isDirty && !force) return

      setIsDirty(false)
      onChange(value)
    }
  }

  // We need to make this function a closure because it's used within the TipTap editor
  // And tiptap doesn't refresh its editor when the configuration changes, so this allows us
  // to make sure to use the right "submitChange" function for the right task/project
  const shortcutSubmitChange = useClosure(({ editor }) => {
    submitChange(editor.getHTML(), { force: true })
    return true
  })

  return (
    <DescriptionEditor
      variableArgs={variableArgs}
      key={projectId ?? taskId}
      value={internalDescription}
      onChange={internalOnChange}
      onBlur={(value) => submitChange(value)}
      workspaceId={workspaceId}
      projectId={projectId}
      taskId={taskId}
      taskType={taskType}
      autoFocus={false}
      disabled={disabled}
      shortcuts={{
        'Mod-s': shortcutSubmitChange,
      }}
    />
  )
}
