import { Button } from '@motion/ui/base'
import { NOOP_FUNCTION } from '@motion/utils/function'
import { useAuthenticatedUser } from '@motion/web-common/auth'

interface OnboardingDesktopDisabledProps {
  onLogout?: () => void
}

export function OnboardingDesktopDisabled({
  onLogout = NOOP_FUNCTION,
}: OnboardingDesktopDisabledProps) {
  const user = useAuthenticatedUser()

  return (
    <div className='electron-drag flex h-screen w-screen flex-grow flex-col items-center justify-center bg-semantic-neutral-bg-default'>
      <div className='electron-no-drag mb-3 flex flex-col items-center gap-2'>
        <h1 className='text-semantic-neutral-text-strong-default text-3xl font-semibold'>
          Onboarding can currently only be completed on the website
        </h1>
        <h2 className='text-semantic-neutral-text-subtle text-lg font-normal'>
          Click below to quit the desktop app, open the website in the browser
          and complete onboarding
        </h2>
      </div>
      <div className='flex flex-col items-center gap-2'>
        <Button
          variant='solid'
          sentiment='primary'
          onClick={() => {
            window.open(
              `${window.location.protocol}//${window.location.host}/onboarding`,
              '_blank'
            )
          }}
        >
          Continue Onboarding
        </Button>
        <Button variant='muted' sentiment='neutral' onClick={onLogout}>
          Logout
        </Button>
      </div>
      <div className='electron-no-drag flex flex-col items-center mt-2'>
        <p className='text-semantic-neutral-text-subtle text-sm'>
          Currently logged in as{' '}
          <span className='underline'>{user?.email}</span>
        </p>
      </div>
    </div>
  )
}
