import { type AutoScheduleToggleProps } from '@motion/ui/base'
import { isUnassignedUser } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { isAutoSchedulable } from '~/areas/flows/utils'
import { StageAutoscheduleToggle } from '~/areas/task-project/components/side-panel/header'
import { useFieldArray } from 'react-hook-form'

export type StageAutoScheduleFieldProps = {
  tasksPath: `stages.${number}.tasks`
  hideLabel?: boolean
  size?: AutoScheduleToggleProps['size']
}
export function StageAutoScheduleField({
  tasksPath,
  hideLabel = false,
  size,
}: StageAutoScheduleFieldProps) {
  const {
    form: { control, watch },
  } = useFlowTemplateForm()

  const { update } = useFieldArray({
    control,
    name: tasksPath,
    keyName: 'key',
  })
  const tasks = watch(tasksPath)

  const isAutoScheduled = tasks.some((task) => task.isAutoScheduled)

  const allTasksAreUnassigned = tasks.every((task) => {
    if (task.assigneeVariableKey != null) {
      return false
    }

    return (
      isUnassignedUser({
        userId: task.assigneeUserId ?? '',
      }) || task.assigneeUserId == null
    )
  })

  function onAutoScheduleStage(isAutoScheduled: boolean) {
    recordAnalyticsEvent('STAGE_AUTO_SCHEDULE_TOGGLE', {
      numTasks: tasks.filter(
        (task) =>
          task.isAutoScheduled !== isAutoScheduled && isAutoSchedulable(task)
      ).length,
      autoScheduled: isAutoScheduled,
    })

    tasks.forEach((task, index) => {
      const isAutoSchedulableTask = isAutoSchedulable(task)

      if (!isAutoSchedulableTask) return

      update(index, {
        ...task,
        isAutoScheduled,
      })
    })
  }

  return (
    <StageAutoscheduleToggle
      size={size}
      isAutoScheduled={isAutoScheduled}
      disabled={allTasksAreUnassigned}
      label={hideLabel ? undefined : 'Auto-schedule all tasks in this stage'}
      onChange={onAutoScheduleStage}
      customTooltipContent={
        isAutoScheduled
          ? "Don't auto-schedule tasks until entering their stage. (Motion recommends always auto-scheduling future stages as we can help you calculate the Project's ETA)"
          : 'Enable auto-scheduling for all tasks in this stage'
      }
    />
  )
}
