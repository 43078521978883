import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'
import { useModalApi } from '@motion/web-common/modals'

import { useCallback } from 'react'

type UnsavedChangesPromptProps = {
  title?: string
  description?: string
  closeButtonText?: string
  confirmButtonText?: string
}

export function useUnsavedChangesPrompt() {
  const modalApi = useModalApi()

  return useCallback(
    async (props: UnsavedChangesPromptProps = READONLY_EMPTY_OBJECT) => {
      const response = await modalApi.prompt('confirm', {
        ...props,
        analytics: { name: 'before-leaving' },
        title: props.title ?? 'Your changes aren’t saved',
        closeButtonText: props.closeButtonText ?? 'Go back',
        confirmButtonText: props.confirmButtonText ?? 'Close without saving',
      })

      return response === true
    },
    [modalApi]
  )
}
