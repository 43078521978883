import { AutoscheduleStarSolid } from '@motion/icons'
import { useOnceWhen } from '@motion/react-core/hooks'
import { useShortcut } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useWorkspaceById } from '~/global/hooks'
import { NAME_FIELD_RULE } from '~/global/rules'
import { useController } from 'react-hook-form'

import { useSetupProjectFormNavigationContext } from '../../hooks/ctx'
import { useSetupProjectForm } from '../../hooks/use-setup-project-form'
import { UsingDefinitionInWorkspaceLabel } from '../using-definition-in-workspace-label'

export const NameStepContent = () => {
  const {
    form: { control, watch },
  } = useSetupProjectForm()

  const { field, fieldState } = useController({
    control,
    name: 'name',
    defaultValue: '',
    rules: NAME_FIELD_RULE,
  })

  const projectDefinition = watch('projectDefinition')
  const workspaceId = watch('workspaceId')
  const mode = watch('mode')
  const prompt = watch('prompt')
  const workspace = useWorkspaceById(workspaceId)
  if (workspace == null) {
    throw new Error('Workspace not found')
  }

  useOnceWhen(mode === 'ai' && fieldState.isDirty, () => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_AI_CHANGE_SUGGESTED_PROJECT_NAME')
  })

  const { goForward } = useSetupProjectFormNavigationContext()

  useShortcut('Enter', (e) => {
    if (field.value.trim().length > 0) {
      goForward(e)
    }
  })

  return (
    <div className='flex flex-col gap-3'>
      <div className='flex items-end gap-3 [&>label_span]:h-auto'>
        <TextField
          autoFocus
          fullWidth
          {...field}
          label='Name'
          placeholder='Enter project title'
        />

        {Boolean(prompt) && (
          <AutoscheduleStarSolid className='size-4 mb-2 text-semantic-neutral-icon-disabled' />
        )}
      </div>

      <UsingDefinitionInWorkspaceLabel
        projectDefinition={projectDefinition}
        workspace={workspace}
      />
    </div>
  )
}
