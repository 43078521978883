import {
  ArrowDownSolid,
  ArrowRightSolid,
  ArrowUpSolid,
  ExternalLinkSolid,
  LinkSolid,
  PuzzleSolid,
  StarCrossOutOutline,
  StarOutline,
  StatusCanceledSolid,
  StatusCompletedSolid,
  TrashSolid,
} from '@motion/icons'
import {
  type COLOR,
  isCanceledStatus,
  isCompletedStatus,
} from '@motion/shared/common'
import { type ActionSection } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type ProjectSchema } from '@motion/zod/client'

import { useDesktopTabs } from '~/areas/desktop/hooks'
import { useIsFlowProject } from '~/areas/flows'
import { useUserFavoritedItem } from '~/areas/folders/hooks'
import {
  useCancelProject,
  useCompleteProject,
  useDeleteProject,
} from '~/areas/project/hooks'
import { MoveProjectToPopover } from '~/areas/sidebar/treeviews/workspaces-treeview/components'
import {
  useCopyProjectModalLinkToClipboard,
  useWorkspaceFns,
  useWorkspaceStatusById,
} from '~/global/hooks'
import { useProjectModalUrl } from '~/global/navigation'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { ProjectColorPicker } from '../../components/project-color-picker'

export type ProjectActionSectionOptions = {
  hideEdit?: boolean
  showMoves?: boolean
  disabledMoveActions?: { up: boolean; down: boolean }

  allowProjectResolution?: boolean
  showColors?: boolean

  copyShortcutLabel?: string
  onDeleteConfirmed?: () => void
  onMoveItem?: (moveBy: -1 | 1) => void
}

/**
 * Create a list of action sections. Typically used in a popover or dropdown.
 * @param project
 * @param options
 * @returns
 */
export function useProjectActionSections(
  project: ProjectSchema,
  options: ProjectActionSectionOptions
) {
  const {
    showMoves = false,
    copyShortcutLabel,
    onDeleteConfirmed,
    disabledMoveActions,
    allowProjectResolution = false,
    hideEdit = false,
    showColors = false,
    onMoveItem,
  } = options

  const buildProjectModalUrl = useProjectModalUrl()
  const deleteProject = useDeleteProject()
  const copyProjectLink = useCopyProjectModalLinkToClipboard()
  const navigate = useNavigate()
  const completeProject = useCompleteProject()
  const cancelProject = useCancelProject()
  const isFlowProject = useIsFlowProject()
  const { hasTabs, openTab, canOpenTab, tabTooltip } = useDesktopTabs()

  const { getWorkspaceCanceledStatus } = useWorkspaceFns()

  const { isFavorited, toggleFavorite } = useUserFavoritedItem(
    'PROJECT',
    project
  )

  const handleToggleFavorite = useCallback(() => {
    recordAnalyticsEvent('TOGGLE_FAVORITE', {
      state: isFavorited ? 'REMOVED' : 'ADDED',
      itemType: 'PROJECT',
      location: 'CONTEXT_MENU',
    })

    void toggleFavorite()
  }, [isFavorited, toggleFavorite])

  const handleOpenInNewTab = useCallback(() => {
    openTab(
      buildProjectModalUrl({
        project: project.id,
        forWorkspace: project.workspaceId,
      }),
      'PROJECT'
    )
  }, [buildProjectModalUrl, openTab, project.id, project.workspaceId])

  const projectStatus = useWorkspaceStatusById(project.statusId)

  const shouldShowCompleteProject =
    allowProjectResolution && !isCompletedStatus(projectStatus)

  const shouldShowCancelProject =
    allowProjectResolution && !isCanceledStatus(projectStatus)

  return useMemo<ActionSection[]>(
    () => [
      showColors && {
        items: [
          {
            content: (
              <ProjectColorPicker
                project={project}
                selected={project.color as COLOR}
              />
            ),
            isNested: true,
          },
        ],
      },
      {
        items: [
          !hideEdit && {
            prefix: <ExternalLinkSolid />,
            content: 'Open project',
            onAction: () => {
              navigate(
                buildProjectModalUrl({
                  project: project.id,
                  forWorkspace: project.workspaceId,
                })
              )
            },
          },
          shouldShowCompleteProject && {
            prefix: <StatusCompletedSolid />,
            content: 'Complete project',
            onAction: () => {
              void completeProject(project.id)
            },
          },
          shouldShowCancelProject && {
            prefix: <StatusCanceledSolid />,
            content: 'Cancel project',
            onAction: () => {
              void cancelProject(
                project.id,
                getWorkspaceCanceledStatus(project.workspaceId).id
              )
            },
          },
          {
            prefix: <LinkSolid />,
            content: 'Copy link',
            shortcut: copyShortcutLabel,
            onAction: () => {
              if (!project.id) return
              copyProjectLink({
                workspaceId: project.workspaceId,
                projectName: project.name,
                projectId: project.id,
              })
            },
          },
          hasTabs && {
            prefix: <ExternalLinkSolid />,
            content: 'Open in new tab',
            onAction: handleOpenInNewTab,
            disabled: !canOpenTab,
            tooltip: tabTooltip,
          },
          {
            prefix: isFavorited ? <StarCrossOutOutline /> : <StarOutline />,
            content: isFavorited ? 'Remove from Favorites' : 'Add to Favorites',
            onAction: handleToggleFavorite,
          },
          !isFlowProject(project) && {
            prefix: <PuzzleSolid />,
            content: 'Save as template',
            onAction: async () => {
              navigate(
                buildProjectModalUrl({ project: project.id, template: 'new' })
              )
            },
          },
          showMoves && {
            renderPopover: ({ close: nestedClose }) => (
              <MoveProjectToPopover onClose={nestedClose} project={project} />
            ),
            prefix: <ArrowRightSolid />,
            content: 'Move to',
          },
        ],
      },
      showMoves &&
        !!onMoveItem && {
          items: [
            {
              prefix: <ArrowUpSolid />,
              content: 'Move up',
              disabled: disabledMoveActions?.up,
              onAction: () => onMoveItem(-1),
            },
            {
              prefix: <ArrowDownSolid />,
              content: 'Move down',
              disabled: disabledMoveActions?.down,
              onAction: () => onMoveItem(1),
            },
          ],
        },
      {
        items: [
          {
            prefix: <TrashSolid />,
            content: 'Delete',
            destructive: true,
            onAction: () =>
              project.id && void deleteProject(project, onDeleteConfirmed),
          },
        ],
      },
    ],
    [
      showColors,
      project,
      hideEdit,
      shouldShowCompleteProject,
      shouldShowCancelProject,
      copyShortcutLabel,
      hasTabs,
      handleOpenInNewTab,
      canOpenTab,
      tabTooltip,
      isFavorited,
      handleToggleFavorite,
      isFlowProject,
      showMoves,
      onMoveItem,
      disabledMoveActions?.up,
      disabledMoveActions?.down,
      navigate,
      buildProjectModalUrl,
      completeProject,
      cancelProject,
      getWorkspaceCanceledStatus,
      copyProjectLink,
      deleteProject,
      onDeleteConfirmed,
    ]
  )
}
