import { InformationCircleSolid } from '@motion/icons'
import { Button, showToast } from '@motion/ui/base'

import { useState } from 'react'

import { detachCalendars } from '../../../../state/calendar/calendarSlice'
import { useAppDispatch } from '../../../../state/hooks'
import { Dialog } from '../../../Common'

interface DetachCalendarModalProps {
  visible?: boolean
  close: () => void
}
export const DetachCalendarModal = ({
  visible,
  close = () => null,
}: DetachCalendarModalProps) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  const detach = async () => {
    setLoading(true)
    const res = await dispatch(detachCalendars()).unwrap()
    if (res.success) {
      showToast(
        'success',
        'Your calendar is being detached. This process may take a few minutes. If there are still tasks on your calendar several minutes after running this job, please try re-running it.',
        { duration: 10_000 }
      )
    } else {
      showToast(
        'error',
        `There was an error detaching your calendars. If the issue persists, please contact support`,
        { duration: 10_000 }
      )
    }
    setLoading(false)
    close()
  }

  return (
    <Dialog
      isOpen={visible === undefined ? true : visible}
      onClose={close}
      title='Remove Motion tasks'
      header={<WarningBanner />}
    >
      <div className='text-light-1200 dark:text-dark-300 my-4'>
        <p>
          All Motion task events will be removed from your external calendar.
          Tasks will remain scheduled within Motion.
        </p>
      </div>
      <div className='flex flex-col gap-1'>
        <Button sentiment='error' onClick={detach} loading={loading}>
          Remove Motion Tasks
        </Button>
        <Button sentiment='neutral' variant='muted' onClick={close}>
          Cancel
        </Button>
      </div>
    </Dialog>
  )
}

function WarningBanner() {
  return (
    <div className='dark:bg-dark-900 mb-4 flex flex-row items-center gap-2 rounded-lg bg-yellow-100 px-2 py-2'>
      <InformationCircleSolid className='h-3 w-3 text-yellow-800 dark:text-yellow-400' />
      <p className='text-xs text-yellow-800 dark:text-yellow-400'>
        Note: this does not cancel your subscription.
      </p>
    </div>
  )
}
