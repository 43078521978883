import { API } from '@motion/rpc-definitions'
import { debounce } from '@motion/utils/core'
import { client } from '@motion/web-common/rpc'

import { type MutationIds } from '../handlers/utils'
import {
  type BulkTaskOperations,
  type CalendarsOperations,
  type FeedUpdates,
  type FolderBatchOperations,
  type MeetingInsightsBatchOperations,
  type MeetingInsightsUpdate,
  type NoteBatchOperations,
  type PastDueBatchOperations,
  type ProjectBatchOperations,
  type ProjectDefinitionBatchOperations,
  type ProjectEtaBatchOperations,
  type ProjectEtaUpdate,
  type ScheduledEntitiesBatchOperations,
  type StageDefinitionBatchOperations,
  type StageEtaBatchOperations,
  type StageEtaUpdate,
  type StatusBatchOperations,
  type TaskBatchOperations,
  type WorkspaceDefinitionUpdates,
} from '../types'

export function taskRefresh(
  mutation: Partial<MutationIds>
): TaskBatchOperations {
  return { type: 'refresh-tasks', data: mutation }
}

export function projectRefresh(
  mutation: Partial<MutationIds>
): ProjectBatchOperations {
  return { type: 'refresh-projects', data: mutation }
}

export function foldersRefresh(
  mutation: Partial<MutationIds>
): FolderBatchOperations {
  return { type: 'refresh-folders', data: mutation }
}

export function notesRefresh(
  mutation: Partial<MutationIds>
): NoteBatchOperations {
  return { type: 'refresh-notes', data: mutation }
}

export function statusRefresh(
  mutation: Partial<MutationIds> & { workspaces: string[] }
): StatusBatchOperations {
  return { type: 'refresh-statuses', data: mutation }
}

export function feedRefresh(
  feedUpdates: Partial<{ tasks: string[]; project: string[] }>
): FeedUpdates {
  return { type: 'refresh-feed', data: feedUpdates }
}

export function refreshCalendars(): CalendarsOperations {
  return { type: 'refresh-calendars' }
}

export function refreshScheduledEntities(
  scheduledEntityUpdate: Partial<{ workspaceId: string }> = {}
): ScheduledEntitiesBatchOperations {
  return { type: 'refresh-scheduled-entities', data: scheduledEntityUpdate }
}

export function refreshProjectEta(
  data: ProjectEtaUpdate
): ProjectEtaBatchOperations {
  return { type: 'refresh-project-eta', data }
}

export function refreshStageEta(data: StageEtaUpdate): StageEtaBatchOperations {
  return { type: 'refresh-stage-eta', data }
}

export function refreshPastDueTasks(): PastDueBatchOperations {
  return { type: 'refresh-past-due-tasks' }
}

export function refreshStageDefinition(
  data: WorkspaceDefinitionUpdates
): StageDefinitionBatchOperations {
  return {
    type: 'refresh-stage-definition',
    data,
  }
}

export function refreshProjectDefinition(
  data: WorkspaceDefinitionUpdates
): ProjectDefinitionBatchOperations {
  return {
    type: 'refresh-project-definition',
    data,
  }
}

export function bulkTaskOperations(
  data: BulkTaskOperations['data'],
  operation: BulkTaskOperations['operation']
): BulkTaskOperations {
  return {
    type: 'bulk-task-operations',
    operation,
    data,
  }
}

export function meetingInsightsRefresh(
  data: MeetingInsightsUpdate
): MeetingInsightsBatchOperations {
  return {
    type: 'refresh-meeting-insights',
    data: {
      meetingInsightsId: data.meetingInsights.id,
    },
  }
}

export const invalidateWorkspacesV2 = debounce(
  () => {
    void client.invalidateQueries({ queryKey: API.workspacesV2.queryKeys.root })
  },
  5_000,
  { leading: true, trailing: true, maxWait: 10_000 }
)

export const invalidateFolders = debounce(
  () => {
    void client.invalidateQueries({ queryKey: API.folders.queryKeys.getAll })
  },
  5_000,
  { leading: true, trailing: true, maxWait: 10_000 }
)

export const invalidateUserSettings = debounce(
  () => {
    void client.invalidateQueries({ queryKey: API.userSettings.queryKeys.root })
  },
  5_000,
  { leading: true, trailing: true, maxWait: 10_000 }
)

export const invalidateFeaturePermissions = debounce(
  () => {
    void client.invalidateQueries({
      queryKey: API.usersV2.queryKeys.featurePermissions(),
    })
  },
  5_000,
  { leading: true, trailing: true, maxWait: 10_000 }
)

export const invalidateAccessPermissions = debounce(
  (type: string, id: string) => {
    void client.invalidateQueries({
      queryKey: API.access.queryKeys.query({
        resourceType: type,
        resourceId: id,
      }),
    })
    void client.invalidateQueries({
      queryKey: API.share.queryKeys.byId(type, id),
    })
  },
  5_000,
  { leading: true, trailing: true, maxWait: 10_000 }
)
