import { templateStr } from '@motion/react-core/strings'
import { type ProjectDefinitionSchema } from '@motion/rpc-types'
import { ConfirmationModal, Text, Tooltip } from '@motion/ui/base'
import { type PromptCallbacks } from '@motion/web-common/modals/definitions'

import { type ModalTriggerComponentProps } from '~/areas/modals/modal-trigger/modal-trigger'
import { ProjectDefinitionLabel } from '~/global/components/labels'
import { useProjectDefinitionWithStagesById } from '~/global/hooks'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'confirm-detach-project-from-flow': PromptCallbacks<{
      confirmedDetach: boolean
    }> & {
      projectDefinitionId: ProjectDefinitionSchema['id']
      editType: 'add-stage' | 'reorder-stage'
    }
  }
}

export function ConfirmDetachProjectFromFlowModal({
  projectDefinitionId,
  close,
  editType,
}: ModalTriggerComponentProps<'confirm-detach-project-from-flow'>) {
  const projectDefinition =
    useProjectDefinitionWithStagesById(projectDefinitionId)

  const modalTitle =
    editType === 'add-stage'
      ? 'Adding a new stage will unsync this project from the template'
      : 'Reordering stages will unsync this project from the template'

  const modalWarning =
    editType === 'add-stage'
      ? `By adding a new stage, this project will be disconnected from the template.
      Meaning any new changes made to the project template will no longer apply to this project.`
      : `By reordering stages, this project will no longer be using the template.
      Any changes made to the project template will no longer apply to this project.`

  return (
    <ConfirmationModal
      action={{
        label: 'Continue',
        onAction: () => {
          close({ confirmedDetach: true })
        },
      }}
      closeLabel='Cancel'
      visible
      onClose={close}
      title={modalTitle}
      description={
        <div className='flex flex-col gap-3 text-semantic-neutral-text-subtle text-sm leading-5 min-w-0'>
          <div className='flex items-center gap-2 truncate'>
            {templateStr(
              'This project is using a template: {{templateLabel}}',
              {
                templateLabel: (
                  <Tooltip asChild content={projectDefinition?.name}>
                    <div className='inline-flex align-middle truncate'>
                      <ProjectDefinitionLabel
                        size='normal'
                        value={projectDefinition}
                      />
                    </div>
                  </Tooltip>
                ),
              }
            )}
          </div>
          <div className='flex flex-col gap-6'>
            <Text sentiment='subtle' size='sm'>
              {modalWarning}
            </Text>
          </div>
        </div>
      }
    />
  )
}
