import { useOnceWhen, useOnValueChange } from '@motion/react-core/hooks'
import { type NoteSchema } from '@motion/rpc-types'
import { classed } from '@motion/theme'
import { replay } from '@motion/web-base/sentry'
import { useTitle } from '@motion/web-common/html'

import { useHasSeenCta } from '~/global/hooks'
import { useTrackEntityOpen } from '~/global/hooks/use-track-entity-open'
import {
  useCheckAccess,
  useNoteWithMetadata,
  useUpdateMyCtaSettings,
} from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useEffect } from 'react'

import { useNoteCommentsContext } from './comments'
import { NoteHeader } from './components/note-header'
import { NoteSidebar } from './components/note-sidebar'
import { ConnectedEditor } from './connected-editor'
import { NoteProviders } from './context'
import { useNoteEditorAPI } from './context/note-editor-context-provider'
import { NoteNotFoundScreen } from './error-page/note-not-found-screen'
import { useNoteParams } from './hooks'

function hasStatus(error: unknown): error is { status: number } {
  return (
    typeof error === 'object' &&
    error !== null &&
    'status' in error &&
    typeof (error as { status?: unknown }).status === 'number'
  )
}

export function Note() {
  const { noteId, motionId, threadId, commentId } = useNoteParams()

  const { data, error } = useNoteWithMetadata(
    { id: noteId ?? '' },
    {
      enabled: !!noteId,
      retry: (failureCount, error) => {
        if (hasStatus(error)) {
          if (error.status === 404 || error.status === 403) {
            return false
          }
        }
        return failureCount < 3
      },
    }
  )

  const { data: updateAccess } = useCheckAccess({
    resourceType: 'NOTE',
    resourceId: noteId,
    action: 'update',
  })

  const note = data ? data.models.notes[data.id] : null

  useTitle(note?.title)

  if (error) {
    return (
      <NoteNotFoundScreen noAccess={hasStatus(error) && error.status === 403} />
    )
  }

  if (!data || !note) {
    return null
  }

  return (
    <NoteProviders data={data}>
      <UnconnectedNote
        note={note}
        motionId={motionId}
        threadId={threadId}
        commentId={commentId}
        readonly={!updateAccess?.canAccess}
      />
    </NoteProviders>
  )
}

function UnconnectedNote(props: {
  note: NoteSchema
  motionId?: string
  commentId?: string
  threadId?: string
  readonly: boolean
}) {
  const { note, motionId, threadId, readonly } = props
  const { isEditorInitialized, navigateToNodeByMotionId } = useNoteEditorAPI()

  const { setActiveThreadId } = useNoteCommentsContext()

  useEffect(() => {
    replay.start()

    const handleError = (event: ErrorEvent) => {
      // When a lexical related error occurs, we flush the replay to send it to Sentry
      if (event.message.toLowerCase().includes('lexical')) {
        replay.flush()
      }
    }

    window.addEventListener('error', handleError)

    return () => {
      window.removeEventListener('error', handleError)
      replay.stop()
    }
  }, [])

  useTrackEntityOpen({ id: note.id, type: 'NOTE' })

  useOnValueChange(isEditorInitialized, () => {
    if (!isEditorInitialized) {
      return
    }

    if (motionId != null) {
      try {
        navigateToNodeByMotionId(motionId)
      } catch (error) {
        showErrorToast('Failed to navigate to node')
      }
    } else if (threadId != null) {
      setActiveThreadId(threadId)
    }
  })

  const hasSeenCta = useHasSeenCta()
  const cta = hasSeenCta('ONBOARDING_DOC_POPOVER')

  const { mutate: updateCtaSettings } = useUpdateMyCtaSettings()

  // Dismiss the popover that appears after onboarding that points to the
  // onboarding doc when viewed.
  useOnceWhen(typeof note.metadata === 'object', () => {
    if (
      !cta.seen &&
      cta.loaded &&
      note.metadata &&
      'isOnboardingDoc' in note.metadata &&
      note.metadata.isOnboardingDoc
    ) {
      updateCtaSettings({
        ctaSettings: {
          ONBOARDING_DOC_POPOVER: true,
        },
      })
    }
  })

  return (
    <PageWrapper>
      <NoteHeader noteId={note.id} />
      <PageContent>
        <ConnectedEditor
          key={note.id}
          noteId={note.id}
          workspaceId={note.workspaceId}
          readonly={readonly}
        />
        <NoteSidebar noteId={note.id} />
      </PageContent>
    </PageWrapper>
  )
}

const PageWrapper = classed('div', {
  base: 'h-full w-full flex flex-col overflow-hidden bg-semantic-neutral-bg-default',
})

const PageContent = classed('div', {
  base: 'grid min-h-0 flex-grow grid-cols-[1fr_auto]',
})
