import { Text } from '@motion/ui/base'

import { LoadingScreen, StageTimeline } from '~/areas/flows'

import { AiStageRows } from './ai-stage-rows'

import {
  useGenerateSpeculativeProject,
  useSetupProjectForm,
} from '../../../hooks'
import { StepHeader } from '../../styled'
import {
  ControlledAiProjectDeadlineField,
  ControlledAiProjectStartDateField,
} from '../fields'

{
  /* TODO: manual needs different fields here */
}
export const StagesStepContent = () => {
  const {
    form: { watch },
  } = useSetupProjectForm()
  const mode = watch('mode')

  const { isSuccess } = useGenerateSpeculativeProject({
    mode,
  })

  if (!isSuccess) {
    return (
      <LoadingScreen
        title='Generating project'
        message='Hang tight! This could take up to 90 seconds...'
        className='h-96'
      />
    )
  }

  const speculativeProject = watch('speculativeProject')

  return (
    <div className='flex flex-col gap-6'>
      <div className='flex flex-col gap-1'>
        <div className='flex flex-row items-center justify-between'>
          <StepHeader>Project start date & deadline</StepHeader>

          <div className='flex flex-row items-center gap-2'>
            {mode === 'ai' ? <ControlledAiProjectStartDateField /> : null}

            <Text sentiment='subtle' size='sm'>
              -
            </Text>

            {mode === 'ai' ? <ControlledAiProjectDeadlineField /> : null}
          </div>
        </div>

        {speculativeProject && (
          <StageTimeline
            project={{
              ...speculativeProject,
              activeStageDefinitionId: null, // remove active stage styling
            }}
            stages={speculativeProject.stages}
            interactive={false}
          />
        )}
      </div>

      <div className='flex flex-col gap-3'>
        <StepHeader>Stages & tasks</StepHeader>

        {/* TODO: probably want to render same component for both and control deeper for same UI */}
        {mode === 'ai' ? <AiStageRows /> : null}
      </div>
    </div>
  )
}
