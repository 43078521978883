import { PlusSolid } from '@motion/icons'
import { IconButton, Tooltip } from '@motion/ui/base'

import { useCalendarTimezones } from '~/areas/calendar/hooks'
import { selectDragTimeslotsVisible } from '~/state/calendar/calendarSlice'
import { useAppSelector } from '~/state/hooks'
import { twMerge } from 'tailwind-merge'

import { DifferentTimezoneAlert } from './components/different-timezone-alert'
import { TimezoneButton } from './components/timezone-button'

interface TimezoneGroupProps {
  localTimezone: string
}

export const TimezoneGroup = ({ localTimezone }: TimezoneGroupProps) => {
  const { secondaryTimezone, addTimezone, editTimezone, removeTimezone } =
    useCalendarTimezones(localTimezone)

  const dragTimeslotsVisible = useAppSelector(selectDragTimeslotsVisible)

  return (
    <div
      className={twMerge(
        'z-50 flex items-center justify-center gap-2',
        'absolute ml-3.5',
        'left-0 -bottom-[33px] text-semantic-neutral-text-subtle'
      )}
    >
      <DifferentTimezoneAlert>
        <TimezoneButton timezone={localTimezone} size='xsmall' />
      </DifferentTimezoneAlert>

      {secondaryTimezone ? (
        <TimezoneButton
          timezone={secondaryTimezone}
          size='xsmall'
          clickable={!dragTimeslotsVisible}
          onEdit={() => editTimezone(0)}
          onRemove={() => void removeTimezone(0)}
        />
      ) : (
        <Tooltip content='Add a timezone' asChild>
          <IconButton
            icon={PlusSolid}
            size='xsmall'
            sentiment='neutral'
            variant='muted'
            onClick={addTimezone}
          />
        </Tooltip>
      )}
    </div>
  )
}
