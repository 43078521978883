import { type ProjectSchema } from '@motion/rpc-types'
import { isCanceledStatus } from '@motion/shared/common'
import { useContextMenu } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { AttachmentCountButton } from '~/areas/attachments/components'
import { useGetProjectAttachmentsCount } from '~/areas/attachments/hooks'
import { ConnectedProjectEtaBadge } from '~/areas/eta'
import { ProjectColorTag } from '~/areas/project/components'
import { ProjectActionList } from '~/areas/project/components/project-action-list'
import { useWorkspaceStatusById } from '~/global/hooks'
import { useProjectModalUrl } from '~/global/navigation'
import { useNavigate } from 'react-router'

import {
  ProjectAssigneeField,
  ProjectDeadlineField,
  ProjectLabelsField,
  ProjectPriorityField,
  ProjectStatusField,
} from '../../mention-card/fields'
import { MentionCard } from '../../mention-card/mention-card'
import { MentionCardInfoRow } from '../../mention-card/mention-card-info-row'
import { MentionCardTitle } from '../../mention-card/mention-card-title'

export type ProjectMentionHoverCardProps = {
  project: ProjectSchema
  dragging?: boolean
}
export const ProjectMentionHoverCard = ({
  project,
  dragging,
}: ProjectMentionHoverCardProps) => {
  const buildProjectModalUrl = useProjectModalUrl()
  const navigate = useNavigate()
  const { handleContextMenu, ContextMenuPopover } = useContextMenu({
    onOpen: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_KANBAN_RIGHT_CLICK', {
        type: 'project',
      })
    },
  })

  const projectStatus = useWorkspaceStatusById(project.statusId)

  const { projectAttachmentCount } = useGetProjectAttachmentsCount({
    projectId: project.id,
  })

  return (
    <>
      <MentionCard
        onClick={() => navigate(buildProjectModalUrl({ project: project.id }))}
        draggable='false'
        onContextMenu={handleContextMenu}
      >
        <MentionCardInfoRow className='gap-1'>
          <ProjectColorTag
            projectId={project.id}
            color={project.color}
            size='small'
            square
          />
          <ProjectPriorityField project={project} />
          <ProjectStatusField project={project} />
          <div className='ml-auto -mt-2'>
            <ConnectedProjectEtaBadge project={project} source='kanban' />
          </div>
        </MentionCardInfoRow>
        <MentionCardTitle canceled={isCanceledStatus(projectStatus)}>
          {project.name}
        </MentionCardTitle>
        <MentionCardInfoRow spaceBetween>
          <ProjectDeadlineField project={project} />
          <ProjectAssigneeField project={project} />
        </MentionCardInfoRow>
        <MentionCardInfoRow spaceBetween>
          <ProjectLabelsField project={project} />
          {project.type === 'NORMAL' && (
            <AttachmentCountButton
              targetId={project.id}
              targetType='PROJECT'
              count={projectAttachmentCount}
            />
          )}
        </MentionCardInfoRow>
      </MentionCard>
      <ContextMenuPopover
        renderContent={({ close }) => (
          <ProjectActionList close={close} project={project} />
        )}
      />
    </>
  )
}
