import { normalizeTaskDeadlineStatus } from '@motion/ui-logic'
import { isMeetingTask } from '@motion/ui-logic/pm/task'
import { type TaskSchema } from '@motion/zod/client'

export function useTaskETAData(task: TaskSchema) {
  return {
    hasETA: !isMeetingTask(task),
    normalizedDeadlineStatus: normalizeTaskDeadlineStatus(task),
  }
}
