import {
  type ApiTypes,
  createUseQueryFn,
  useQueryOptionsFactory,
} from '@motion/rpc'
import { API } from '@motion/rpc-definitions'

import { useQuery } from '@tanstack/react-query'

export type CheckAccessApi = ApiTypes<typeof API.access.checkAccess>
export type CheckAccessApiArgs = Partial<CheckAccessApi['args']>

export const useCheckAccess = (args: CheckAccessApiArgs) => {
  const enabled = Boolean(args.resourceType && args.resourceId && args.action)
  const queryOptionsOf = useQueryOptionsFactory(API.access.checkAccess)
  const queryArgs = queryOptionsOf(
    {
      ...args,
      resourceType: args.resourceType ?? '',
      resourceId: args.resourceId ?? '',
      action: args.action ?? '',
      userId: args.userId ?? '',
    },
    { enabled }
  )

  return useQuery({
    ...queryArgs,
    queryFn: async (ctx) => {
      return queryArgs.queryFn(ctx)
    },
    enabled,
  })
}

export const useCheckAccessFn = createUseQueryFn(API.access.checkAccess)
