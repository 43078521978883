import type {
  DashboardViewBarChartSchema,
  DashboardViewCellSchema,
  DashboardViewChartSchema,
  DashboardViewLineChartSchema,
  DashboardViewNumberChartSchema,
  DashboardViewPieChartSchema,
} from '@motion/zod/client'

import { type ViewEntityCtx } from './defaults'

// Base filter configurations
const emptyFilters = {
  ordered: [],
  filters: {},
}
export const baseTaskFilters = {
  tasks: emptyFilters,
  projects: emptyFilters,
  workspaces: emptyFilters,
}

type BaseChartNoFilters<T extends DashboardViewChartSchema> = Omit<T, 'filters'>

// Base chart type configurations
const baseBarChart = {
  type: 'bar' as const,
  item: 'tasks' as const,
  layout: 'stacked' as const,
  orientation: 'vertical' as const,
  legend: { position: 'auto' as const },
  groupBy: [],
  aggregate: {
    type: 'count' as const,
  },
} satisfies BaseChartNoFilters<DashboardViewBarChartSchema>

const baseLineChart = {
  type: 'line' as const,
  item: 'tasks' as const,
  legend: { position: 'auto' as const },
  groupBy: [],
  aggregate: {
    type: 'count' as const,
  },
  interpolation: 'linear' as const,
} satisfies BaseChartNoFilters<DashboardViewLineChartSchema>

const basePieChart = {
  type: 'pie' as const,
  item: 'tasks' as const,
  donut: false,
  legend: { position: 'auto' as const },
  groupBy: [],
  aggregate: {
    type: 'count' as const,
  },
} satisfies BaseChartNoFilters<DashboardViewPieChartSchema>

export const baseNumberChart = {
  type: 'number' as const,
  item: 'tasks' as const,
  aggregate: { type: 'count' as const },
} satisfies BaseChartNoFilters<DashboardViewNumberChartSchema>

// Specialized chart configurations
export const baseTasksBarChart = {
  ...baseBarChart,
  item: 'tasks' as const,
  filters: {
    ...baseTaskFilters,
    tasks: {
      ordered: [],
      filters: {
        completed: 'include' as const,
        archived: 'exclude' as const,
      },
    },
  },
} satisfies DashboardViewChartSchema

export const baseCompletedHoursChart = {
  ...baseLineChart,
  aggregate: {
    type: 'sum' as const,
    field: 'duration',
  },
  filters: {
    ...baseTaskFilters,
    tasks: {
      ordered: [],
      filters: {},
    },
  },
} satisfies DashboardViewChartSchema

export const baseTasksPieChart = {
  ...basePieChart,
  item: 'tasks' as const,
  aggregate: {
    type: 'count' as const,
  },
  filters: {
    ...baseTaskFilters,
    tasks: {
      ordered: [],
      filters: {
        completed: 'include',
      },
    },
  },
} satisfies DashboardViewChartSchema

export const baseProjectsPieChart = {
  ...basePieChart,
  item: 'projects' as const,
  aggregate: {
    type: 'count' as const,
  },
  filters: {
    ...baseTaskFilters,
    projects: {
      ordered: [],
      filters: {
        completed: 'include',
      },
    },
  },
} satisfies DashboardViewChartSchema

// Top row chart configurations
export const getTopRowCharts = (
  ctx: ViewEntityCtx
): Pick<DashboardViewCellSchema, 'title' | 'chart'>[] => [
  {
    title: 'Tasks assigned to me',
    chart: {
      ...baseNumberChart,
      color: {
        type: 'static' as const,
        value: 'sky' as const,
      },
      filters: {
        ...baseTaskFilters,
        tasks: {
          ordered: ['assigneeUserIds', 'statusIds'],
          filters: {
            completed: 'include' as const,
            assigneeUserIds: {
              operator: 'in' as const,
              value: ['@me'],
            },
            statusIds: {
              operator: 'in' as const,
              value: ctx.completedStatuses
                .map((status) => status.id)
                .concat(ctx.cancelledStatuses.map((status) => status.id)),
              inverse: true,
            },
          },
        },
      },
    },
  },
  {
    title: 'Tasks scheduled this week',
    chart: {
      ...baseNumberChart,
      filters: {
        ...baseTaskFilters,
        tasks: {
          ordered: ['scheduledStart'],
          filters: {
            completed: 'include' as const,
            scheduledStart: {
              operator: 'defined-relative' as const,
              name: 'this-week' as const,
            },
          },
        },
      },
    },
  },
  {
    title: 'Task hours scheduled this week',
    chart: {
      ...baseNumberChart,
      aggregate: { type: 'sum' as const, field: 'duration' },
      filters: {
        ...baseTaskFilters,
        tasks: {
          ordered: ['scheduledStart'],
          filters: {
            completed: 'include' as const,
            scheduledStart: {
              operator: 'defined-relative' as const,
              name: 'this-week' as const,
            },
          },
        },
      },
    },
  },
  {
    title: 'Past deadline tasks',
    chart: {
      ...baseNumberChart,
      color: {
        type: 'static' as const,
        value: 'red' as const,
      },
      filters: {
        ...baseTaskFilters,
        tasks: {
          ordered: ['deadlineStatuses'],
          filters: {
            completed: 'exclude',
            deadlineStatuses: {
              operator: 'in' as const,
              value: ['missed-deadline', 'scheduled-past-deadline', 'at-risk'],
            },
          },
        },
      },
    },
  },
  {
    title: 'Tasks completed this week',
    chart: {
      ...baseNumberChart,
      color: {
        type: 'static' as const,
        value: 'green' as const,
      },
      filters: {
        ...baseTaskFilters,
        tasks: {
          ordered: ['completedTime'],
          filters: {
            completed: 'only' as const,
            completedTime: {
              operator: 'defined-relative' as const,
              name: 'this-week' as const,
            },
          },
        },
      },
    },
  },
  {
    title: 'Completed task hours this week',
    chart: {
      ...baseNumberChart,
      color: {
        type: 'static' as const,
        value: 'green' as const,
      },
      aggregate: { type: 'sum' as const, field: 'duration' },
      filters: {
        ...baseTaskFilters,
        tasks: {
          ordered: ['completedTime'],
          filters: {
            completed: 'only' as const,
            completedTime: {
              operator: 'defined-relative' as const,
              name: 'this-week' as const,
            },
          },
        },
      },
    },
  },
]
