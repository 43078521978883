import { CommentInputBox, type CommentInputBoxProps } from '@motion/notes'

import {
  defaultMentionNodeRenderFns,
  MentionNodeRenderContext,
} from '~/areas/notes/components'
import {
  ConnectedProjectMention,
  ConnectedTaskMention,
  type MentionRenderFnValue,
  WebMentionsPlugin,
} from '~/areas/notes/components/editor-plugins'

const mentionsRenderFn: MentionRenderFnValue = {
  ...defaultMentionNodeRenderFns,
  task: ({ entityId }) => <ConnectedTaskMention id={entityId} />,
  project: ({ entityId }) => <ConnectedProjectMention id={entityId} />,
}

export const ConnectedCommentInputBox = (props: CommentInputBoxProps) => {
  return (
    <MentionNodeRenderContext.Provider value={mentionsRenderFn}>
      <CommentInputBox
        {...props}
        nodes={[WebMentionsPlugin.Node]}
        plugins={<WebMentionsPlugin />}
      />
    </MentionNodeRenderContext.Provider>
  )
}
