import type { CommentSchema } from '@motion/rpc-types'
import { addComponentName } from '@motion/ui/helpers'
import { Sentry } from '@motion/web-base/sentry'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { ActivityTimestamp } from '~/areas/activity-feed/components'
import { ConnectedUserLabel } from '~/global/components/labels'
import { useState } from 'react'

import { CommentActionMenu } from './comment-action-menu'
import { ConnectedCommentInputBox } from './connected-comment-input-box'

import { useOnCancel } from '../comments'

type CommentContainerProps = {
  onDelete: (commentId: string, threadId?: string) => void
  onEdit: (commentId: string, bodyHtml: string) => Promise<void>
  isResolved?: boolean
  onResolve?: () => void
  comment: CommentSchema
}
export const CommentContainer = (props: CommentContainerProps) => {
  const { comment, onDelete, onEdit, onResolve, isResolved } = props
  const [editable, setEditable] = useState(false)
  const { uid: userId } = useAuthenticatedUser()

  const handleSubmit = async (bodyHtml: string) => {
    try {
      await onEdit(comment.id, bodyHtml)
      setEditable(false)
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          position: 'CommentContainer',
        },
      })
      throw e
    }
  }

  const onCancel = useOnCancel(setEditable)

  return (
    <div className='relative' {...addComponentName('CommentContainer')}>
      <div className='flex items-center gap-2'>
        <div className='flex items-center gap-2 flex-grow truncate'>
          <ConnectedUserLabel userId={comment.createdByUserId} />
          <div className='text-xs text-semantic-neutral-text-subtle whitespace-nowrap'>
            <ActivityTimestamp timestamp={comment.createdTime} />
          </div>
        </div>

        <div className='shrink-0'>
          <CommentActionMenu
            comment={comment}
            onDelete={() => onDelete(comment.id, comment.threadId ?? undefined)}
            onEdit={() => setEditable(true)}
            onResolve={!isResolved ? onResolve : undefined}
            isOwner={comment.createdByUserId === userId}
          />
        </div>
      </div>

      <ConnectedCommentInputBox
        initialHtml={comment.body}
        editable={editable}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        autoFocus={editable}
      />
    </div>
  )
}
