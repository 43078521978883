import { templateStr } from '@motion/react-core/strings'
import { classed } from '@motion/theme'
import { PopoverButton, SearchableDropdown, UserAvatar } from '@motion/ui/base'
import { FieldLabel, TextField } from '@motion/ui/forms'
import { type NormalTaskSchema } from '@motion/zod/client'

import { AssigneeDropdown } from '~/areas/project-management/components'
import { PrettyAssignees } from '~/global/components/labels'
import { getTextFieldRules } from '~/global/rules'
import { Fragment } from 'react'
import { useController, useFormContext } from 'react-hook-form'

import { type TaskBulkDuplicateFormValues } from '../task-bulk-duplicate-modal'

const MAX_DUPLICATES = 20

export const DuplicationConfigForm = ({ task }: { task: NormalTaskSchema }) => {
  const form = useFormContext<TaskBulkDuplicateFormValues>()

  const { type } = form.getValues()

  const { field: titleField } = useController({
    name: 'title',
    control: form.control,
    rules: getTextFieldRules('Title'),
  })
  const titleFieldError = form.formState.errors.title?.message

  const { field: numberOfCopiesField } = useController({
    name: 'numberOfCopies',
    control: form.control,
  })

  const { field: selectedAssigneesField } = useController({
    name: 'selectedAssigneeIds',
    control: form.control,
    rules: {
      validate: (value, formValues) => {
        if (formValues.type === 'assignees' && !value.length) {
          return 'Assignees are required'
        }
        return true
      },
    },
  })
  const selectedAssigneesError =
    form.formState.errors.selectedAssigneeIds?.message

  return (
    <div className='flex flex-col gap-4'>
      <div>
        <TextField
          value={titleField.value}
          onBlur={titleField.onBlur}
          onChange={titleField.onChange}
          label='Tasks titles'
        />
        {titleFieldError && <ErrorMessage>{titleFieldError}</ErrorMessage>}
      </div>
      {type === 'number' ? (
        <FieldLabel label='Number of copies'>
          <SearchableDropdown
            items={Array.from({ length: MAX_DUPLICATES }, (_, i) => i + 1)}
            selectedItem={numberOfCopiesField.value}
            renderItem={(item) => <Fragment key={item}>{item}</Fragment>}
            onChange={numberOfCopiesField.onChange}
          >
            <PopoverButton>{numberOfCopiesField.value}</PopoverButton>
          </SearchableDropdown>
        </FieldLabel>
      ) : (
        <div>
          <FieldLabel
            label={templateStr('Assignees{{assignees}}', {
              assignees:
                selectedAssigneesField.value.length > 0
                  ? ` (${selectedAssigneesField.value.length})`
                  : '',
            })}
          >
            <AssigneeDropdown
              type='multi'
              workspaceId={task.workspaceId}
              selectedUserIds={selectedAssigneesField.value}
              onChange={selectedAssigneesField.onChange}
            >
              <PopoverButton>
                {selectedAssigneesField.value.length ? (
                  <div className='flex flex-1 gap-1'>
                    {selectedAssigneesField.value.includes(null) && (
                      <UserAvatar />
                    )}
                    <PrettyAssignees
                      userIds={
                        selectedAssigneesField.value.filter(
                          (id) => id
                        ) as string[]
                      }
                      size='normal'
                    />
                  </div>
                ) : (
                  'Select assignees'
                )}
              </PopoverButton>
            </AssigneeDropdown>
          </FieldLabel>
          {selectedAssigneesError && (
            <ErrorMessage>{selectedAssigneesError}</ErrorMessage>
          )}
        </div>
      )}
    </div>
  )
}

const ErrorMessage = classed(
  'span',
  'text-[12px] text-semantic-error-text-default'
)
