import { templateStr } from '@motion/react-core/strings'
import { Banner } from '@motion/ui/base'

export const DirtyBanner = () => {
  return (
    <Banner sentiment='warning' size='small'>
      <span className='text-sm'>
        {templateStr(
          '{{first}} Updates to this task will be applied to the template once the template is saved',
          {
            first: <span className='font-semibold'>Task modified:</span>,
          }
        )}
      </span>
    </Banner>
  )
}
