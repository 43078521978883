import { type ProjectSchema, type TaskSchema } from '@motion/rpc-types'

import { useProjectDeadlineData } from '~/areas/project/hooks/data'
import {
  DeadlineDropdown,
  DeadlineToggle,
} from '~/areas/project-management/components'
import { TaskDeadlineDropdown } from '~/areas/tasks/components'
import { useTaskDeadlineData } from '~/areas/tasks/hooks'

import { MentionCardButton } from '../mention-card-button'

export type TaskDeadlineFieldProps = {
  task: TaskSchema
}
export const TaskDeadlineField = ({ task }: TaskDeadlineFieldProps) => {
  const {
    onChange,
    onChangeDeadlineType,
    formattedValue,
    disabled,
    isValidDateOption,
  } = useTaskDeadlineData(task)

  return (
    <TaskDeadlineDropdown
      contextProps={{ taskId: task.id }}
      value={task.dueDate}
      onChange={onChange}
      isValidDateOption={isValidDateOption}
      renderCalendarFooter={() => (
        <DeadlineToggle
          onChange={onChangeDeadlineType}
          deadlineType={task.deadlineType}
        />
      )}
    >
      <MentionCardButton disabled={disabled}>
        <div className='truncate'>{formattedValue}</div>
      </MentionCardButton>
    </TaskDeadlineDropdown>
  )
}

export type ProjectDeadlineFieldProps = { project: ProjectSchema }
export const ProjectDeadlineField = ({
  project,
  ...props
}: ProjectDeadlineFieldProps) => {
  const { onChange, hideTimeOptions, formattedValue } =
    useProjectDeadlineData(project)

  return (
    <DeadlineDropdown
      value={project.dueDate}
      onChange={onChange}
      hideTimeOptions={hideTimeOptions}
      contextProps={{ projectId: project.id }}
      dropdownTarget='project'
    >
      <MentionCardButton>
        <div className='truncate'>{formattedValue}</div>
      </MentionCardButton>
    </DeadlineDropdown>
  )
}
