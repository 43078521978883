import { isCompletedStatus } from '@motion/shared/common'
import { type StatusSchema } from '@motion/zod/client'

import { useProjectStatusUpdater } from '~/areas/project/hooks'
import { TaskStatusDropdown } from '~/areas/tasks/components'
import { useTaskStatusData } from '~/areas/tasks/hooks'
import { StatusDropdown } from '~/global/components/dropdowns'
import { StatusLabel } from '~/global/components/labels'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'
import { type ReactNode } from 'react'

import { CardButton } from '../components'

export type TaskStatusFieldProps = {
  task: TaskWithRelations
}
export const TaskStatusField = ({ task }: TaskStatusFieldProps) => {
  const { onChange, disabled, statusTaskVariant, shouldExcludeResolved } =
    useTaskStatusData(task)

  if (statusTaskVariant === 'meeting') {
    return (
      <CardButton disabled={disabled}>
        <StatusLabel
          value={{
            ...task.status,
            name: isCompletedStatus(task.status) ? 'Complete' : 'Event',
          }}
          taskVariant={statusTaskVariant}
        />
      </CardButton>
    )
  }

  return (
    <TaskStatusDropdown
      isUnvisitedStage={task.isUnvisitedStage}
      selectedStatusId={task.statusId}
      workspaceId={task.workspaceId}
      onChange={onChange}
      taskVariant={statusTaskVariant}
      excludeResolved={shouldExcludeResolved}
    >
      <CardButton disabled={disabled}>
        <StatusLabel
          value={task.status}
          iconVariant={task.isUnvisitedStage ? 'isUnvisitedStage' : undefined}
          taskVariant={statusTaskVariant}
        />
      </CardButton>
    </TaskStatusDropdown>
  )
}

export type ProjectStatusFieldProps = {
  project: ProjectWithRelations
}
export const ProjectStatusField = ({ project }: ProjectStatusFieldProps) => {
  const updateProjectStatus = useProjectStatusUpdater()

  return (
    <StatusField
      selectedStatusId={project.statusId}
      workspaceId={project.workspaceId}
      onChange={(statusId) => updateProjectStatus(project, statusId)}
    >
      <StatusLabel value={project.status} />
    </StatusField>
  )
}

type StatusFieldProps = {
  selectedStatusId: StatusSchema['id']
  workspaceId: StatusSchema['workspaceId']
  onChange: (statusId: StatusSchema['id']) => void
  children: ReactNode
}
const StatusField = ({
  selectedStatusId,
  workspaceId,
  onChange,
  children,
}: StatusFieldProps) => {
  return (
    <StatusDropdown
      selectedStatusId={selectedStatusId}
      workspaceId={workspaceId}
      onChange={onChange}
    >
      <CardButton>{children}</CardButton>
    </StatusDropdown>
  )
}
