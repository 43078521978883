import { type Group } from '@motion/ui/base'
import {
  byProperty,
  byValue,
  cascade,
  Compare,
  groupBy,
  ordered,
} from '@motion/utils/array'
import { createLookup, createLookupByKey } from '@motion/utils/object'
import {
  type ProjectDefinitionSchema,
  type ProjectSchema,
  type StageDefinitionSchema,
  type StatusSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { useIsFlowsM5cEnabled } from '~/areas/flows'
import {
  useProject,
  useProjectDefinition,
  useStageDefinitionsByReferences,
  useWorkspaceFns,
} from '~/global/hooks'
import { type NormalTaskWithRelations } from '~/global/proxies'
import { useMemo } from 'react'

import { useSidebarTaskContext } from '../components/side-panel/context'
import { type SortBy } from '../utils'

type UseSortedGroupedTasksArgs<SK extends keyof typeof SortBy> = {
  tasks: NormalTaskWithRelations[]
  sort: SK
  projectDefinitionId: ProjectDefinitionSchema['id'] | null
  projectId: ProjectSchema['id'] | null
}

export const useSortedGroupedTasks = <SK extends keyof typeof SortBy>({
  tasks,
  sort,
  projectDefinitionId,
  projectId,
}: UseSortedGroupedTasksArgs<SK>): SidebarTasksGroup[] => {
  const { workspaceId } = useSidebarTaskContext()

  const { getWorkspaceStatuses } = useWorkspaceFns()

  const isFlowsM5cEnabled = useIsFlowsM5cEnabled()

  const project = useProject(projectId)

  const projectDefinition = useProjectDefinition(projectDefinitionId)

  const stageDefinitionReferences = isFlowsM5cEnabled
    ? (projectDefinition?.stageDefinitionReferences ?? project?.stages)
    : projectDefinition?.stageDefinitionReferences

  const stages = useStageDefinitionsByReferences(stageDefinitionReferences)

  return useMemo(() => {
    const sortLookup = lookupBySort(sort) as (
      args: LookupArgs
    ) => SidebarTasksGroup[]
    return sortLookup({
      tasks,
      workspaceId,
      stages: stages ?? [],
      getStatuses: getWorkspaceStatuses,
    })
  }, [sort, tasks, workspaceId, getWorkspaceStatuses, stages])
}

type LookupArgs = {
  tasks: NormalTaskWithRelations[]
  workspaceId: WorkspaceSchema['id']
  stages: StageDefinitionSchema[]
  getStatuses: (workspaceId: WorkspaceSchema['id']) => StatusSchema[]
}

export type SidebarTasksGroup = Group<NormalTaskWithRelations>

const lookupBySort = createLookup<
  {
    [SK in keyof typeof SortBy]: (args: LookupArgs) => SidebarTasksGroup[]
  } & {
    default: () => never
  }
>({
  STATUS: ({ tasks, workspaceId, stages, getStatuses }) => {
    const orderedStageDefIds = stages.map((s) => s.id)
    const orderedTaskDefIdsPerStageId = createLookupByKey(stages, 'id', (s) =>
      s.tasks.map((t) => t.id)
    )

    const taskGroups = groupBy(tasks, (task) => task.statusId)

    return getStatuses(workspaceId).map((status) => {
      const items = (taskGroups[status.id] ?? []).sort(
        cascade(
          byProperty(
            'stageDefinitionId',
            ordered<string | null>(
              orderedStageDefIds,
              Compare.string.with({ null: 'at-end', empty: 'at-end' })
            )
          ),
          byValue(
            (t) => {
              if (t.stageDefinitionId == null) return null
              if (t.taskDefinitionId == null) return null

              const index =
                orderedTaskDefIdsPerStageId[t.stageDefinitionId]?.indexOf(
                  t.taskDefinitionId
                ) ?? -1
              return index === -1 ? null : index
            },
            Compare.numeric.with({ null: 'at-end' })
          ),
          byProperty('createdTime', Compare.string)
        )
      )

      return {
        key: status.id,
        items,
      }
    })
  },
  STAGES: ({ tasks, stages }) => {
    const taskGroups = groupBy(
      tasks,
      (task) => task.stageDefinitionId ?? 'none'
    )

    return stages.map((stage) => {
      const orderedTaskDefIds = stage.tasks.map((t) => t.id)

      const items = (taskGroups[stage.id] ?? []).sort(
        cascade(
          byProperty(
            'taskDefinitionId',
            ordered<string | null>(
              orderedTaskDefIds,
              Compare.string.with({ null: 'at-end', empty: 'at-end' })
            )
          ),
          byProperty('createdTime', Compare.string)
        )
      )

      return {
        key: stage.id,
        items,
      }
    })
  },
  NO_STAGE: ({ tasks }) => {
    return [
      {
        key: 'NO_STAGE',
        items: tasks,
      },
    ]
  },
  default: () => {
    throw new Error(`Unknown sort type`)
  },
})
