import { Button, ButtonGroup, useShortcut } from '@motion/ui/base'

import { MainButtonFooter, ShellFooter } from './styled'

import { FlowTutorialButton } from '../../flow-template-modal'

type StageTemplateFooterProps = {
  isSubmitDisabled: boolean
  isSubmitting: boolean
  close: () => void
}

export function StageTemplateFooter({
  isSubmitDisabled,
  isSubmitting,
  close,
}: StageTemplateFooterProps) {
  const handleCancel = async () => {
    close()
  }

  useShortcut('escape', () => handleCancel())

  return (
    <ShellFooter>
      <ButtonGroup>
        <FlowTutorialButton />
      </ButtonGroup>

      <MainButtonFooter>
        <Button
          sentiment='neutral'
          variant='muted'
          shortcut='esc'
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          sentiment='primary'
          variant='solid'
          shortcut='mod+s'
          disabled={isSubmitDisabled}
          loading={isSubmitting}
        >
          Save
        </Button>
      </MainButtonFooter>
    </ShellFooter>
  )
}
