import { XSolid } from '@motion/icons'
import { type StageDefinitionSchema } from '@motion/rpc-types'
import {
  IconButton,
  showToast,
  UnstyledModal,
  useShortcut,
} from '@motion/ui/base'
import {
  recordAnalyticsEvent,
  useOnMountAnalyticsEvent,
} from '@motion/web-base/analytics'
import { useModalTitle } from '@motion/web-common/html'
import { useModalStatus } from '@motion/web-common/modals'

import {
  StageColumnsDnd,
  StageTasks,
  useFlowTemplateForm,
} from '~/areas/flows/shared-form'
import { type ModalTriggerComponentProps } from '~/areas/modals'
import { StageLabel } from '~/global/components/labels'
import { useRouteConfirmationPromptBeforeLeaving } from '~/global/navigation'
import { type ComponentRef, type FormEvent, useMemo, useRef } from 'react'
import { useFieldArray } from 'react-hook-form'

import { StageTemplateFooter } from './stage-template-footer'
import { StageTemplateForm } from './stage-template-form'
import { StagesSidebar } from './stages-sidebar'
import { FormShell, GridShell, StageContent, TasksContent } from './styled'

import { FlowsModalStateProvider } from '../../contexts'
import { getFlowTemplateFormErrorMessage } from '../../utils'
import { useCloseStageModal, useSubmitStageForm } from '../hooks'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'stage-modal': PromptCallbacks<StageDefinitionSchema> & {
      stage?: 'new' | 'edit'
      stageId?: string
    }
  }
}

type ConnectedStageModalProps = {
  open: boolean
}

export function ConnectedStageModal({ open }: ConnectedStageModalProps) {
  const closeModal = useCloseStageModal()

  if (!open) {
    return null
  }

  return <StageModal close={closeModal} />
}

export type StageModalProps = Omit<
  ModalTriggerComponentProps<'stage-modal'>,
  'onValue' | 'onDismiss'
>

export function StageModal({ close, ...modalProps }: StageModalProps) {
  return (
    <UnstyledModal
      data-testid='stage-template-modal'
      type='page'
      visible
      onClose={close}
      withAnimation
      disableEscapeKey
      overlayClassName='bg-modal-overlay'
      modalClassName='w-[638px] h-[90%] overflow-hidden'
    >
      <StageTemplateForm {...modalProps}>
        <FlowsModalStateProvider>
          <div className='w-full h-full flex gap-1 items-start'>
            <StageModalContent close={close} />

            <IconButton
              icon={XSolid}
              sentiment='neutral'
              size='small'
              variant='muted'
              onClick={close}
            />
          </div>
        </FlowsModalStateProvider>
      </StageTemplateForm>
    </UnstyledModal>
  )
}

const StageModalContent = ({ close }: Pick<StageModalProps, 'close'>) => {
  const { form } = useFlowTemplateForm()
  const submitForm = useSubmitStageForm(close)

  const {
    watch,
    control,
    formState: { isDirty, isSubmitting },
  } = form

  const stageId = watch('id')
  const stageName = watch('name')
  const stageColor = watch('color')

  const taskDefinitionModalStatus = useModalStatus('task-definition-modal')
  const hasDirtyFormFields =
    isDirty && !isSubmitting && !taskDefinitionModalStatus.visible

  useModalTitle(stageId ? stageName : 'New stage')
  useRouteConfirmationPromptBeforeLeaving({
    when: hasDirtyFormFields,
  })

  useOnMountAnalyticsEvent('PROJECT_MANAGEMENT_VIEW_STAGE_TEMPLATE', {
    enabled: stageId != null,
  })
  useShortcut('mod+s', () => {
    // Task name RTE only processes HTML and updates state on blur
    // Required to catch any validation errors
    const activeElement = document.activeElement as HTMLInputElement
    activeElement?.blur?.()
    onSubmit()
  })

  const isSubmitDisabled = useMemo((): boolean => {
    if (!isDirty) return true

    return false
  }, [isDirty])

  const onSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    if (isSubmitDisabled) return

    await form.handleSubmit(submitForm, (validationErrors) => {
      const errors = Object.values(validationErrors).flat().filter(Boolean)
      if (errors.length < 1) return

      const firstError = errors[0]
      const message = getFlowTemplateFormErrorMessage(
        firstError as Record<string, any> | undefined
      )

      if (!message) return

      recordAnalyticsEvent('STAGE_MODAL_SUBMIT_ERROR', {
        message,
      })
      showToast('error', message)
    })(e)
  }

  const stage = useMemo(
    () => ({ name: stageName || 'Untitled', color: stageColor }),
    [stageName, stageColor]
  )

  const columnRef = useRef<ComponentRef<typeof TasksContent>>(null)

  const fieldArray = useFieldArray({
    control,
    name: 'stages',
  })

  return (
    <FormShell onSubmit={onSubmit}>
      <GridShell>
        <StagesSidebar />

        <StageContent>
          <StageLabel value={stage} />

          <StageColumnsDnd fieldArray={fieldArray}>
            <TasksContent ref={columnRef}>
              <StageTasks
                tasksPath='stages.0.tasks'
                stageColumnRef={columnRef}
              />
            </TasksContent>
          </StageColumnsDnd>
        </StageContent>

        <StageTemplateFooter
          isSubmitDisabled={isSubmitDisabled}
          isSubmitting={isSubmitting}
          close={close}
        />
      </GridShell>
    </FormShell>
  )
}
