import { useStateObject } from '@motion/react-core/hooks'
import { type Calendar, type EmailAccount } from '@motion/rpc/types'
import { type CalendarSchema } from '@motion/zod/client'

import { twMerge } from 'tailwind-merge'

import { EmailAccountTreeNode } from './email-account-tree-node'
import { EmailAccountTreeNodeWithError } from './email-account-tree-node-with-error'

import { type CheckboxMode } from '../calendar-list-pickers-types'
import { FlatCalendarListPicker } from '../flat-calendar-list-picker/flat-calendar-list-picker'

type Props = {
  calendars: Calendar[] | CalendarSchema[]
  checkboxMode?: CheckboxMode
  colorizeCheckbox?: boolean
  emailAccounts: EmailAccount[]
  mainEmailAccountId?: string
  isInModal?: boolean
  resolveCheckedState: (calendar: Calendar | CalendarSchema) => boolean
  onCalendarChecked: (
    calendar: Calendar | CalendarSchema,
    checked: boolean
  ) => void
  onCalendarUpdated: (
    item: Calendar | CalendarSchema,
    changes: Partial<Calendar>
  ) => void
  onRemoveEmailAccount: (emailAccount: EmailAccount) => void
  onStartEmailAuth: () => void
}

/**
 * Renders a list of calendars, grouped by their associated email account.
 */
export const GroupedCalendarListPicker = ({
  calendars,
  checkboxMode,
  colorizeCheckbox,
  emailAccounts,
  isInModal,
  mainEmailAccountId,
  onCalendarChecked,
  onCalendarUpdated,
  onRemoveEmailAccount,
  onStartEmailAuth,
  resolveCheckedState,
}: Props) => {
  // Track whether certain email nodes are expanded or collapsed. By default,
  // all nodes are expanded
  const [expandedItems, setExpandedItems] = useStateObject<
    Record<string, boolean>
  >({})

  const onNodeExpanded = (emailAccount: EmailAccount, expanded: boolean) => {
    setExpandedItems(emailAccount.id, expanded)
  }

  return (
    <>
      {emailAccounts.map((emailAccount) => {
        const hasError = emailAccount.status !== 'OK'
        const isExpanded =
          emailAccount.id in expandedItems
            ? expandedItems[emailAccount.id]
            : true

        const props = {
          emailAccount,
          isExpanded,
          onNodeExpanded,
          onStartEmailAuth,
        }

        return (
          <div className='w-full' key={emailAccount.id}>
            {hasError && (
              <EmailAccountTreeNodeWithError
                onRemoveEmailAccount={onRemoveEmailAccount}
                {...props}
              />
            )}
            {!hasError && <EmailAccountTreeNode {...props} />}
            {isExpanded && !hasError && (
              <div className={twMerge('pl-6', !isInModal && 'pl-5')}>
                <FlatCalendarListPicker
                  colorizeCheckbox={colorizeCheckbox}
                  checkboxMode={checkboxMode ?? 'normal'}
                  items={(calendars as CalendarSchema[]).filter(
                    (c) => c.emailAccountId === emailAccount.id
                  )}
                  mainEmailAccountId={mainEmailAccountId}
                  onCalendarChecked={onCalendarChecked}
                  onCalendarUpdated={onCalendarUpdated}
                  resolveCheckedState={resolveCheckedState}
                />
              </div>
            )}
          </div>
        )
      })}
    </>
  )
}
