import { CrownSolid } from '@motion/icons'
import { RadioButton } from '@motion/ui/forms'
import { logEvent } from '@motion/web-base/analytics'
import { useHasFeaturePermission } from '@motion/web-billing'
import { useModalApi } from '@motion/web-common/modals'

import { useInNoExternalCalendarsMode } from '~/areas/calendar/hooks'
import { useAutoScheduleSettings } from '~/global/hooks'
import { useUpdateMyAutoScheduleSettingsMutation } from '~/global/rpc/v2'
import { twMerge } from 'tailwind-merge'

import { Events } from '../../../../../analyticsEvents'
import { selectMainEmailAccount } from '../../../../../state/email-accounts/email-accounts-slice'
import { useAppSelector } from '../../../../../state/hooks'

type RadioButtonOption = { label: string; value: string }

const DoNotTimeBlockOption: RadioButtonOption = {
  label: 'Only show tasks on Motion Calendar',
  value: 'no-events',
}

const TimeBlockWithFreeEventsOption: RadioButtonOption = {
  label:
    'Show Motion on your external calendar (Main Calendar Email), but keep tasks as free',
  value: 'free',
}

const DefaultTimeBlockOption: RadioButtonOption = {
  label:
    'Show Motion on your external calendar (Main Calendar Email); tasks that are at risk of missing deadline are marked as busy',
  value: 'default',
}

const AllOptions: RadioButtonOption[] = [
  DoNotTimeBlockOption,
  TimeBlockWithFreeEventsOption,
  DefaultTimeBlockOption,
]

export const TimeBlockSettings = () => {
  const selectedValue = useAutoScheduleSettings().taskTimeBlockMode
  const mainEmailAccount = useAppSelector(selectMainEmailAccount)
  const { noExternalCalendarsMode } = useInNoExternalCalendarsMode()
  const { mutate: updateAutoScheduleSettings } =
    useUpdateMyAutoScheduleSettingsMutation()

  const modalApi = useModalApi()

  const tasksInExternalCalendarEnabled = useHasFeaturePermission(
    'tasksInExternalCalendar'
  )

  const handleChange = async (value: string) => {
    switch (value) {
      case 'default':
        void logEvent(Events.TIME_BLOCK_SETTINGS_SET_DEFAULT)
        break
      case 'free':
        void logEvent(Events.TIME_BLOCK_SETTINGS_SET_FREE)
        break
      case 'no-events':
        void logEvent(Events.TIME_BLOCK_SETTINGS_SET_NO_EVENTS)
        break
      default:
        return
    }

    if (!tasksInExternalCalendarEnabled && value !== 'no-events') {
      return modalApi.open('tier-upgrade-prompt-modal', {
        tier: 'PRO',
        feature: 'tasksInExternalCalendar',
      })
    }

    void updateAutoScheduleSettings({ taskTimeBlockMode: value })
  }

  const availableOptions =
    noExternalCalendarsMode || mainEmailAccount?.providerType === 'APPLE'
      ? [DoNotTimeBlockOption]
      : AllOptions

  return (
    <div className='flex flex-col gap-3'>
      <p className='pb-1 text-base font-semibold'>
        Show Motion tasks on your external main calendar (Google, Outlook or
        iCloud)
      </p>
      {mainEmailAccount?.providerType === 'APPLE' && (
        <p className='text-sm text-semantic-neutral-text-subtle'>
          Motion does not support showing tasks on iCloud calendar yet.
        </p>
      )}
      {availableOptions.map((option) => {
        const showCrown =
          !tasksInExternalCalendarEnabled && option.value !== 'no-events'
        return (
          <div
            className={twMerge('flex flex-row gap-2', showCrown && '-ml-6')}
            key={option.value}
          >
            {showCrown && <CrownSolid className='size-4 shrink-0' />}
            <RadioButton
              checked={selectedValue === option.value}
              onChange={() => handleChange(option.value)}
              align='start'
              renderLabel={() => <span>{option.label}</span>}
              variant='checkmark'
            />
          </div>
        )
      })}
    </div>
  )
}
