import { DotsVerticalSolid, PlusSolid } from '@motion/icons'
import { createUseMutation, createUseQuery } from '@motion/rpc'
import { API } from '@motion/rpc-definitions'
import { type ApiKeyResponse } from '@motion/rpc-types'
import {
  ActionDropdown,
  Button,
  ButtonTabs,
  FormModal,
  IconButton,
  Text,
  Tooltip,
} from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { Sentry } from '@motion/web-base/sentry'
import { useModalApi } from '@motion/web-common/modals'
import { type ApiKeyReadWriteSchema } from '@motion/zod/client'

import { showErrorToast } from '~/global/toasts'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { SettingList } from '../../Components/SettingList/SettingList'

const useGetApiKeys = createUseQuery(API.apiKeys.get)
const useUpdateApiKey = createUseMutation(API.apiKeys.update)
const useDeleteApiKey = createUseMutation(API.apiKeys.deleteKey)
const useCreateApiKey = createUseMutation(API.apiKeys.create)

type ApiKeyAccess = Record<string, ApiKeyReadWriteSchema>

interface CreateKeyModal {
  visible: boolean
  onClose: () => void
}

const CreateAPIKeyModal = ({ visible, onClose }: CreateKeyModal) => {
  const [newClientId, setNewClientId] = useState<string>('')
  const { mutateAsync: createApiKey, data: newKey } = useCreateApiKey()

  const createHandler = async () => {
    try {
      await createApiKey({ clientId: newClientId })
      setNewClientId('')
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          position: 'create-api-key',
        },
      })

      showErrorToast(e)
    }
  }

  const copyHandler = async () => {
    if (newKey) {
      await navigator.clipboard.writeText(newKey.apiKey)
    }
  }

  const title = newKey
    ? 'Key Created. You will not be able to get it after this window closes.'
    : 'Create new API key'

  const dialogContent = newKey ? (
    <>
      <p
        className='whitespace-normal break-words text-sm'
        data-testid='new-api-key'
      >
        {newKey.apiKey}
      </p>
    </>
  ) : (
    <div className='w-[350px] py-2'>
      <TextField
        label='Key name'
        labelHidden
        placeholder='Key Name'
        value={newClientId}
        onChange={(value) => setNewClientId(value)}
      />
    </div>
  )

  return (
    <FormModal
      visible={visible}
      onClose={onClose}
      submitAction={{
        text: newKey ? 'Copy' : 'Create',
        disabled: !newClientId && !newKey,
        onAction: () => {
          if (newKey) {
            return copyHandler()
          }

          return createHandler()
        },
      }}
      cancelAction={{ text: newKey ? 'Close' : 'Cancel' }}
      title={title}
    >
      {dialogContent}
    </FormModal>
  )
}

type EditAccessRowProps = {
  accessKey: string
  access: 'NONE' | 'READ' | 'WRITE'
  setUpdateAccess: (value: (prevState: ApiKeyAccess) => ApiKeyAccess) => void
}
const EditAccessRow = ({
  accessKey,
  access,
  setUpdateAccess,
}: EditAccessRowProps) => {
  const rowType = accessKey.split('A')[0]
  const rowTitle = rowType.charAt(0).toUpperCase() + rowType.slice(1) + 's'
  return (
    <div
      className='flex w-full items-center justify-between p-4'
      key={accessKey}
    >
      <Text className='w-4/6'>{rowTitle}</Text>
      <div className='flex w-2/6 items-center justify-center'>
        <ButtonTabs
          size='small'
          activeValue={access}
          items={[
            {
              content: 'NONE',
              value: 'NONE',
              onAction: () => {
                setUpdateAccess((curr) => {
                  return { ...curr, [accessKey]: 'NONE' }
                })
              },
            },
            {
              content: 'READ',
              value: 'READ',
              onAction: () => {
                setUpdateAccess((curr) => {
                  return { ...curr, [accessKey]: 'READ' }
                })
              },
            },
            {
              content: 'WRITE',
              value: 'WRITE',
              onAction: () => {
                setUpdateAccess((curr) => {
                  return { ...curr, [accessKey]: 'WRITE' }
                })
              },
            },
          ]}
        ></ButtonTabs>
      </div>
    </div>
  )
}

interface EditKeyModal {
  apiKey: ApiKeyResponse
  onClose: () => void
}
const EditAPIKeyModal = ({ apiKey, onClose }: EditKeyModal) => {
  const { mutateAsync: updateKey } = useUpdateApiKey()

  const [updateAccess, setUpdateAccess] = useState<ApiKeyAccess>({
    commentAccess: apiKey?.commentAccess,
    projectAccess: apiKey?.projectAccess,
    taskAccess: apiKey?.taskAccess,
    userAccess: apiKey?.userAccess,
    workspaceAccess: apiKey?.workspaceAccess,
  })

  useEffect(() => {
    setUpdateAccess({
      commentAccess: apiKey?.commentAccess,
      projectAccess: apiKey?.projectAccess,
      taskAccess: apiKey?.taskAccess,
      userAccess: apiKey?.userAccess,
      workspaceAccess: apiKey?.workspaceAccess,
    })
  }, [apiKey])

  const updateKeyHandler = useCallback(async () => {
    await updateKey({
      clientId: apiKey.clientId,
      ...updateAccess,
    })

    onClose()
  }, [apiKey.clientId, onClose, updateAccess, updateKey])

  const dialogRows = useMemo(() => {
    return Object.entries(updateAccess).map(([accessKey, access]) => (
      <EditAccessRow
        accessKey={accessKey}
        access={access}
        key={accessKey}
        setUpdateAccess={setUpdateAccess}
      />
    ))
  }, [updateAccess])

  return (
    <FormModal
      visible={!!apiKey}
      onClose={onClose}
      submitAction={{
        text: 'Update',
        onAction: async () => {
          await updateKeyHandler()
          onClose()
        },
      }}
      cancelAction={{ text: 'Close' }}
      title='Update API Key Access'
    >
      <div className='w-[600px]'>
        <SettingList items={dialogRows} />
      </div>
    </FormModal>
  )
}

const APIKeyRow = ({
  apiKey,
  editAPIKeyPermissions,
  deleteAPIKey,
  toggleAPIKey,
}: {
  apiKey: ApiKeyResponse
  editAPIKeyPermissions: (apiKey: ApiKeyResponse) => void
  deleteAPIKey: (apiKey: ApiKeyResponse) => void
  toggleAPIKey: (apiKey: ApiKeyResponse) => void
}) => {
  return (
    <div
      className='flex w-full items-center justify-between p-4'
      key={apiKey.clientId}
    >
      <Text className='w-5/6'>{apiKey.clientId}</Text>
      {apiKey.isDisabled && (
        <p className='text-alert-500 rounded-full bg-red-100 px-2 py-0.5 text-xs font-semibold'>
          Disabled
        </p>
      )}
      <div className='flex w-1/6 items-center justify-end'>
        <ActionDropdown
          placement='bottom-end'
          items={[
            {
              content: 'Edit API key permissions',
              onAction: () => {
                editAPIKeyPermissions(apiKey)
              },
            },
            {
              content: apiKey.isDisabled
                ? 'Re-enable API key'
                : 'Disable API key',
              onAction: () => {
                toggleAPIKey(apiKey)
              },
            },
            {
              content: 'Delete API key',
              onAction: () => {
                deleteAPIKey(apiKey)
              },
              destructive: true,
            },
          ]}
        >
          <IconButton
            icon={DotsVerticalSolid}
            size='small'
            sentiment='neutral'
            variant='muted'
          />
        </ActionDropdown>
      </div>
    </div>
  )
}

export const APIKeySettings = () => {
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [editAPIKey, setEditAPIKey] = useState<ApiKeyResponse | undefined>(
    undefined
  )

  const modalApi = useModalApi()
  const { data: keys = [], isLoading } = useGetApiKeys()
  const { mutate: updateApiKey } = useUpdateApiKey()
  const { mutateAsync: deleteKey } = useDeleteApiKey()

  const handleDeleteAPIKey = useCallback(
    async (apiKey: ApiKeyResponse) => {
      const confirmed = await modalApi.prompt('confirm', {
        analytics: {
          name: 'delete-api-key',
        },
        title: `Confirm API Key Delete`,
        description:
          'Any API integrations using this key will no longer work after deleting this key!',
        destructive: true,
        confirmButtonText: 'Delete',
      })
      if (confirmed !== true) return

      await deleteKey({ clientId: apiKey.clientId })
    },
    [deleteKey, modalApi]
  )

  const apiKeyRows = useMemo(() => {
    if (!keys) return []

    return keys.map((key) => (
      <APIKeyRow
        key={key.clientId}
        apiKey={key}
        editAPIKeyPermissions={setEditAPIKey}
        deleteAPIKey={handleDeleteAPIKey}
        toggleAPIKey={() => {
          updateApiKey({
            clientId: key.clientId,
            isDisabled: !key.isDisabled,
          })
        }}
      />
    ))
  }, [keys, updateApiKey, handleDeleteAPIKey])

  return (
    <div className='h-full overflow-y-auto'>
      <div className='w-4/6'>
        <div className='py flex w-full justify-between pb-4'>
          <Text weight='semibold'>API Keys</Text>
          <Tooltip
            asChild
            content={keys.length >= 5 ? 'Limit of 5 API keys reached' : ''}
          >
            <Button
              size='small'
              variant='outlined'
              sentiment='neutral'
              disabled={keys.length >= 5}
              onClick={() => {
                setShowCreateModal(true)
              }}
            >
              <PlusSolid />
              Create API Key
            </Button>
          </Tooltip>
        </div>
        {isLoading ? (
          <div className='text-center'>Loading...</div>
        ) : (
          <SettingList items={apiKeyRows} />
        )}
        {showCreateModal && (
          <CreateAPIKeyModal
            visible
            onClose={() => setShowCreateModal(false)}
          />
        )}
        {editAPIKey && (
          <EditAPIKeyModal
            apiKey={editAPIKey}
            onClose={() => {
              setEditAPIKey(undefined)
            }}
          />
        )}
      </div>
    </div>
  )
}
