import { PencilSolid, TrashSolid } from '@motion/icons'
import { ButtonGroup, IconButton, PopoverTrigger } from '@motion/ui/base'
import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import { type ControllerRenderProps, useController } from 'react-hook-form'

import { EditVariablePopover } from './edit-variable-popover'
import { RoleLabel } from './labels/role'

import { useFlowTemplateForm } from '../shared-form'

export interface RoleFieldSwitchProps {
  index: number
  onRemove: () => void
}

export const RoleFieldWrapper = ({ index, onRemove }: RoleFieldSwitchProps) => {
  const {
    form: { control },
  } = useFlowTemplateForm()

  const { field: nameField } = useController({
    name: `roles.${index}.name`,
    control,
  })

  const { field: colorField } = useController({
    name: `roles.${index}.color`,
    control,
  })

  return (
    <RoleField
      nameField={nameField}
      colorField={colorField}
      index={index}
      onRemove={onRemove}
    />
  )
}

export type RoleFieldProps = {
  nameField: ControllerRenderProps<
    FlowTemplateFormFields,
    `roles.${number}.name`
  >
  colorField: ControllerRenderProps<
    FlowTemplateFormFields,
    `roles.${number}.color`
  >
  index: number
  onRemove: () => void
}

function RoleField({ nameField, colorField, index, onRemove }: RoleFieldProps) {
  const { value: name } = nameField
  const { value: color } = colorField

  return (
    <div className='flex flex-row items-center gap-2 justify-between'>
      <RoleLabel role={{ name, color }} />

      <ButtonGroup size='xsmall'>
        <PopoverTrigger
          placement='bottom-start'
          renderPopover={({ close }) => {
            return (
              <EditVariablePopover
                type='person'
                nameField={nameField}
                colorField={colorField}
                index={index}
                close={close}
              />
            )
          }}
        >
          <IconButton
            icon={PencilSolid}
            variant='muted'
            sentiment='neutral'
            size='small'
          />
        </PopoverTrigger>

        <IconButton
          icon={TrashSolid}
          variant='muted'
          sentiment='neutral'
          size='small'
          onClick={onRemove}
        />
      </ButtonGroup>
    </div>
  )
}
