import { QuestionMarkCircleSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'

import { useProjectAIForm } from '../hooks'

type ErrorScreenProps = {
  reset: () => void
}

export function ErrorScreen({ reset }: ErrorScreenProps) {
  const form = useProjectAIForm()

  const handleOnBack = () => {
    form.clearErrors()
    reset()
  }

  return (
    <div className='flex flex-col items-center justify-center gap-6 p-20 text-semantic-neutral-text-subtle'>
      <QuestionMarkCircleSolid className='size-14' />
      <h2 className='text-base font-medium text-center'>
        Hmmm, something went wrong while setting up your project. Please try
        refreshing again or contact support.
      </h2>
      <Button sentiment='primary' variant='solid' onClick={handleOnBack}>
        Try again
      </Button>
    </div>
  )
}
