import {
  useGetTutorialPayload,
  useGetUserTutorialCompletion,
} from '~/components/Settings/Pages/tutorials/rpc-hooks'
import { useMemo } from 'react'

export function useCompletedTutorialPercentage() {
  const { data: tutorialData } = useGetTutorialPayload()
  const { data: userCompletionData } = useGetUserTutorialCompletion()

  return useMemo(() => {
    const activeLessonIds =
      tutorialData?.sections.flatMap((section) =>
        section.lessons.map((lesson) => lesson.id)
      ) ?? []
    const completedLessonIds =
      userCompletionData?.completedTutorialIds?.filter((lessonId) =>
        activeLessonIds.includes(lessonId)
      ) ?? []

    if (activeLessonIds.length === 0) {
      return 0
    }

    return Math.round(
      (completedLessonIds.length / activeLessonIds.length) * 100
    )
  }, [userCompletionData, tutorialData])
}
