import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useLookup } from '~/global/cache'
import {
  useUpdateMeetingInsights,
  useUpdateRecurringMeetingInsights,
} from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

type UpdateNotetakerArgs = {
  recurringMeetingInsightsId?: string
  meetingInsightsId?: string
} & { botEnabled?: boolean; sendRecapToAllAttendees?: boolean }

export function useUpdateNotetakerFields() {
  const modalApi = useModalApi()
  const lookup = useLookup()

  const { mutateAsync: updateMeetingInsights } = useUpdateMeetingInsights()
  const { mutateAsync: updateRecurringMeetingInsights } =
    useUpdateRecurringMeetingInsights()

  return useCallback(
    async (args: UpdateNotetakerArgs) => {
      const { meetingInsightsId, recurringMeetingInsightsId } = args

      if (recurringMeetingInsightsId == null && meetingInsightsId == null) {
        Sentry.captureException(
          'Attempted to update notetaker fields without a meeting insights id',
          {
            tags: {
              position: 'useUpdateNotetakerFields',
            },
            extra: {
              args,
            },
          }
        )
        return
      }

      if ('botEnabled' in args || 'sendRecapToAllAttendees' in args) {
        if (meetingInsightsId != null) {
          const hasParent =
            lookup('meetingInsights', meetingInsightsId)?.parentId != null

          const updateAll = hasParent
            ? await modalApi.prompt('action', {
                title: 'Update recurring event',
                children:
                  'Do you want to update all future meetings with the same settings?',
                actions: [
                  {
                    label: 'Update all future meetings',
                    value: 'true',
                    sentiment: 'primary',
                  },
                  {
                    label: 'Update this meeting only',
                    value: 'false',
                    sentiment: 'neutral',
                  },
                ],
              })
            : false

          if (updateAll === ModalDismissed) {
            return
          }

          return updateMeetingInsights({
            meetingInsightsId,
            updateAll: updateAll === 'true',
            botEnabled: args.botEnabled,
            sendRecapToAllAttendees: args.sendRecapToAllAttendees,
          })
        }

        if (recurringMeetingInsightsId != null) {
          return updateRecurringMeetingInsights({
            recurringMeetingInsightsId,
            botEnabled: args.botEnabled,
            sendRecapToAllAttendees: args.sendRecapToAllAttendees,
          })
        }
      }

      const error = new Error('No valid update field provided')
      Sentry.captureException(error, {
        tags: {
          position: 'useUpdateNotetakerFields',
        },
        extra: {
          args,
        },
      })

      showErrorToast('An error occurred while updating the notetaker fields')
    },
    [lookup, modalApi, updateMeetingInsights, updateRecurringMeetingInsights]
  )
}
