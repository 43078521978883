import { valibotResolver } from '@hookform/resolvers/valibot'
import { type ReactNode, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as v from 'valibot'

import { type FlowsAIFormFields } from './utils'

type FlowsAIFormProps = {
  children: ReactNode
  workspaceId: string
}

const validationSchema = v.object({
  message: v.pipe(
    v.string(),
    v.minLength(1, 'Project description is required')
  ),
  workspaceId: v.string(),
  companyUrl: v.optional(
    v.pipe(v.string(), v.url('Company URL must be a valid URL'))
  ),
  uploadedFileIds: v.optional(v.array(v.string())),
})

export function FlowsAIForm({
  children,
  workspaceId: initialWorkspaceId,
}: FlowsAIFormProps) {
  const initialFormData: FlowsAIFormFields = useMemo(() => {
    return {
      message: '',
      workspaceId: initialWorkspaceId,
      uploadedFileIds: [],
    }
  }, [initialWorkspaceId])

  const form = useForm({
    defaultValues: initialFormData,
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
    resolver: valibotResolver(validationSchema),
  })

  return <FormProvider {...form}>{children}</FormProvider>
}
