import { showToast } from '@motion/ui/base'
import { Sentry } from '@motion/web-base/sentry'
import type {
  FolderSchema,
  ProjectSchema,
  VersionedViewV2,
  ViewFolderItemMetadataSchema,
} from '@motion/zod/client'

import { useFavoriteTypeLookup } from '~/areas/folders/hooks/use-favorite-type-lookup'
import { useRemoveFavorite } from '~/areas/folders/hooks/use-remove-favorite'
import type { PMPageUrlParams } from '~/areas/project-management/pages/pm-v3/routes/types'
import { useFindFolderItem } from '~/global/hooks'
import { useAddItemToFolder, useFavoriteFolderItem } from '~/global/rpc/folders'
import { showErrorToast } from '~/global/toasts'
import { useCallback, useMemo } from 'react'
import { useMatch, useParams } from 'react-router-dom'

import { useUserFavorites } from './use-user-favorites'

import { type UserFavoriteType } from '../types'

export type UseUserFavoritedItemReturnType = {
  isFavorited: boolean
  toggleFavorite: () => Promise<void>
}

export const useUserFavoritedItem = (
  itemType: UserFavoriteType,
  schemaOrId?: VersionedViewV2 | FolderSchema | ProjectSchema | string
): UseUserFavoritedItemReturnType => {
  const { mutateAsync: mutateAddViewAsFavorite } = useAddItemToFolder()
  const { mutateAsync: mutateAddItemAsFavorite } = useFavoriteFolderItem()
  const removeFavorite = useRemoveFavorite()
  const userFavorites = useUserFavorites()
  const findFolderItem = useFindFolderItem()
  const favoriteTypeLookup = useFavoriteTypeLookup()
  const viewMetadata = useViewMetadata()

  const itemId = useMemo(() => {
    if (!schemaOrId) return null

    if (typeof schemaOrId === 'string') {
      return schemaOrId
    }

    return schemaOrId.id
  }, [schemaOrId])

  const folderItem = useMemo(() => {
    if (!schemaOrId || itemType === 'VIEW') return null

    const result = findFolderItem((item) => {
      return item.itemType === itemType && item.itemId === itemId
    })

    return result ? result[0] : null
  }, [findFolderItem, itemId, itemType, schemaOrId])

  const favoritedItem = useMemo(() => {
    if (!itemId) return null

    return (
      userFavorites.items.find((favorite) => {
        switch (favorite.type) {
          case 'VIEW':
            return (
              favorite.view.id === itemId &&
              favorite.workspaceId === viewMetadata.workspaceId &&
              favorite.folderId === viewMetadata.folderId &&
              favorite.projectId === viewMetadata.projectId
            )
          case 'FOLDER':
            return favorite.folder.id === itemId
          case 'PROJECT':
            return favorite.project.id === itemId
          case 'NOTE':
            return favorite.itemId === itemId
          default:
            return false
        }
      }) ?? null
    )
  }, [itemId, userFavorites.items, viewMetadata])

  const toggleFavorite = useCallback(async () => {
    const isAdding = !favoritedItem

    const errorExtra = {
      level: 'error' as const,
      tags: {
        position: 'useUserFavoritedItem',
      },
      extra: {
        itemId,
        matchedFavoritedItemId: favoritedItem?.id ?? null,
        matchedFolderItemId: folderItem?.id ?? null,
        newState: isAdding ? 'ADDING' : 'REMOVING',
        viewMetadata,
      },
    }

    if (!itemId) {
      Sentry.captureException(
        new Error('Could not locate item to toggle favorite'),
        errorExtra
      )

      return void showErrorToast('Failed to modify your favorites')
    }

    if (!userFavorites.folder) {
      Sentry.captureException(
        new Error('Could not locate user favorites system folder'),
        errorExtra
      )

      return void showErrorToast('Failed to modify your favorites')
    }

    if (!isAdding) {
      return void (await removeFavorite(favoritedItem.id))
    }

    const model = favoriteTypeLookup(itemType, itemId)

    let modelName: string | null = null

    if (model && 'title' in model) {
      modelName = (model.title ?? '').trim()
    } else if (model && 'name' in model) {
      modelName = (model.name ?? '').trim()
    }

    modelName = modelName ? `'${modelName}'` : 'Item'

    // Add Favorite
    try {
      if (itemType === 'VIEW') {
        const view = model as VersionedViewV2

        let metadata: ViewFolderItemMetadataSchema | undefined

        if (view.type === 'workspace') {
          metadata = {
            workspaceId: viewMetadata.workspaceId ?? view.targetId,
            folderId: viewMetadata.folderId,
            projectId: viewMetadata.projectId,
          }
        }

        await mutateAddViewAsFavorite({
          itemType: 'VIEW',
          folderId: userFavorites.folder.id,
          itemId: view.id,
          metadata,
        })
      } else {
        if (!folderItem) {
          Sentry.captureException(
            new Error('Failed to match folder item to add as favorite'),
            errorExtra
          )

          return void showErrorToast('Failed to modify your favorites')
        }

        await mutateAddItemAsFavorite({
          folderId: userFavorites.folder.id,
          itemId: folderItem.id,
        })
      }

      showToast('success', `${modelName} has been added to your favorites`)
    } catch (e) {
      Sentry.captureException(e, errorExtra)

      showErrorToast(e, `Failed to add item to your favorites`)
    }
  }, [
    favoritedItem,
    folderItem,
    itemType,
    itemId,
    mutateAddItemAsFavorite,
    mutateAddViewAsFavorite,
    removeFavorite,
    userFavorites.folder,
    viewMetadata,
    favoriteTypeLookup,
  ])

  return {
    isFavorited: !!favoritedItem,
    toggleFavorite,
  }
}

const useViewMetadata = (): PMPageUrlParams => {
  const isOnAllProjects = Boolean(useMatch('/web/pm/all-projects/*'))
  const isOnAllTasks = Boolean(useMatch('/web/pm/all-tasks/*'))
  const isOnMyTasks = Boolean(useMatch('/web/pm/my-tasks/*'))
  const isOnTeamSchedule = Boolean(useMatch('/web/pm/team-schedule/*'))

  const {
    workspaceId: viewingWorkspaceId,
    folderId,
    projectId,
  } = useParams<PMPageUrlParams>()

  const workspaceId = useMemo(() => {
    if (isOnAllTasks) {
      return 'all-tasks'
    }

    if (isOnMyTasks) {
      return 'my-tasks'
    }

    if (isOnTeamSchedule) {
      return 'team-schedule'
    }

    if (isOnAllProjects) {
      return 'all-projects'
    }

    return viewingWorkspaceId
  }, [
    isOnAllProjects,
    isOnAllTasks,
    isOnMyTasks,
    isOnTeamSchedule,
    viewingWorkspaceId,
  ])

  return {
    workspaceId,
    folderId,
    projectId,
  }
}
