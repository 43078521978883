import { useClosure } from '@motion/react-core/hooks'
import { isRecurringTaskParent } from '@motion/ui-logic'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type TaskSchema } from '@motion/zod/client'

import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { useTaskModalUrl } from '~/global/navigation'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'

export type Options = {
  onNavigateAway?: () => Promise<void>
}

const noop = async () => {}

export function useDuplicateTask(options: Options = READONLY_EMPTY_OBJECT) {
  const { onNavigateAway = noop } = options

  const context = useRouteAnalyticsMetadata()

  const navigate = useNavigate()
  const buildTaskModalUrl = useTaskModalUrl()

  const onNavigateAwayClosure = useClosure(onNavigateAway)

  return useCallback(
    async (task: TaskSchema) => {
      const isRecurring = isRecurringTaskParent(task)
      const isRecurringInstance = task.type === 'RECURRING_INSTANCE'

      if (isRecurring || isRecurringInstance) return

      recordAnalyticsEvent('PROJECT_MANAGEMENT_DUPLICATE_TASK', {
        autoScheduled: task.isAutoScheduled,
        taskDuration: task.duration,
        ...context,
      })

      await onNavigateAwayClosure()
      navigate(buildTaskModalUrl({ task: 'new', forTaskId: task.id }))
    },
    [buildTaskModalUrl, context, navigate, onNavigateAwayClosure]
  )
}
