import { type Tier } from '@motion/ui-logic/tiered-pricing'
import {
  getSegmentAnalytics,
  getTrackingCookies,
  logEvent,
} from '@motion/web-base/analytics'

import api from '~/chromeApi/chromeApiContentScript'
import { DateTime } from 'luxon'
import { useEffect } from 'react'

import { Highlight } from '../../../../analytics/highlight'
import { Events } from '../../../../analyticsEvents'
import { segmentScriptText } from '../../../../utils/tracking'
import { type OnboardingScreen, type OnboardingType } from '../types'

export const onboardingEventsByScreen: Record<OnboardingScreen, string> = {
  '1_min_video': Events.NEW_ONBOARDING_1_MIN_VIDEO,
  billing: Events.NEW_ONBOARDING_BILLING,
  choose_theme: Events.NEW_ONBOARDING_CHOOSE_THEME,
  choose_work_hours: Events.NEW_ONBOARDING_CHOOSE_WORK_HOURS,
  connect_calendar: Events.NEW_ONBOARDING_CONNECT_CALENDAR,
  create_first_project: Events.NEW_ONBOARDING_CREATE_FIRST_PROJECT,
  create_first_tasks: Events.NEW_ONBOARDING_CREATE_FIRST_TASKS,
  create_first_workspace: Events.NEW_ONBOARDING_CREATE_FIRST_WORKSPACE,
  create_recurring_tasks: Events.NEW_ONBOARDING_CREATE_RECURRING_TASKS,
  create_team: Events.NEW_ONBOARDING_CREATE_TEAM,
  notetaker_settings: Events.NEW_ONBOARDING_NOTETAKER_SETTINGS,
  individual_or_team_prompt: Events.NEW_ONBOARDING_INDIVIDUAL_OR_TEAM_PROMPT,
  mobile_billing: Events.NEW_ONBOARDING_MOBILE_BILLING,
  pm_tm_video: Events.NEW_ONBOARDING_PM_TM_VIDEO,
  select_main_calendar: Events.NEW_ONBOARDING_SELECT_MAIN_CALENDAR,
  select_my_calendars: Events.NEW_ONBOARDING_SELECT_MY_CALENDARS,
  billing_interception: Events.NEW_ONBOARDING_TEAM_BILLING,
  team_interception: Events.NEW_ONBOARDING_TEAM_INTERCEPTION,
  kyc_company_questionnaire: 'KYC_COMPANY_QUESTIONNAIRE',
  verify_email: Events.NEW_ONBOARDING_VERIFY_EMAIL,
}

const onboardingEventsByType: Record<OnboardingType, string> = {
  individual: Events.NEW_ONBOARDING_SELECT_INDIVIDUAL,
  individual_no_card: Events.NEW_ONBOARDING_SELECT_INDIVIDUAL_NO_CARD,
  team: Events.NEW_ONBOARDING_SELECT_TEAM,
  team_invitee: Events.NEW_ONBOARDING_SELECT_TEAM_INVITEE,
  team_with_card: Events.NEW_ONBOARDING_SELECT_TEAM_WITH_CARD,
}

export function useOnboardingAnalytics(
  screen: OnboardingScreen,
  onboardingType?: OnboardingType
) {
  useEffect(function loadHighlight() {
    const contentListener = (request: any) => {
      if (request.event === 'identifyUser') {
        try {
          if (__IS_PROD__) {
            Highlight.identify(request.user.email, {
              name: request.user.displayName,
              uid: request.user.uid,
            })
            Highlight.start()
          }
        } catch (e) {
          void e
        }

        // following the same pattern as the listener in WebappWrapper
        try {
          window.Intercom('boot', {
            api_base: 'https://api-iam.intercom.io',
            app_id: 'onbboe5h',
            created_at: DateTime.fromISO(
              request.user.dateCreated
            ).toUnixInteger(),
            email: request.user.email,
            hide_default_launcher: true,
            name: request.user.displayName,
          })
        } catch (e) {
          void e
        }
      }
    }
    api.runtime.onMessage.addListener(contentListener)
    return () => api.runtime.onMessage.removeListener(contentListener)
  }, [])

  useEffect(() => {
    const event = onboardingEventsByScreen[screen]
    if (event) {
      logEvent(event)
    }
  }, [screen])

  useEffect(() => {
    if (onboardingType) {
      const event = onboardingEventsByType[onboardingType]
      if (event) {
        Highlight.track(event)
        logEvent(event)
      }
    }
  }, [onboardingType])
}

export function useTrackingScripts() {
  useEffect(function addTrackingScripts() {
    const existingSegmentScript = document.getElementById('segmentScript')
    if (!existingSegmentScript) {
      const segmentScript = document.createElement('script')
      segmentScript.id = 'segmentScript'
      segmentScript.innerText = segmentScriptText
      document.head.appendChild(segmentScript)
    }
  }, [])
}

export function useSegmentUser(userEmail: string, userDisplayName: string) {
  useEffect(
    function logSegmentUser() {
      setTimeout(() => {
        getSegmentAnalytics()?.identify(userEmail, {
          email: userEmail,
          name: userDisplayName,
        })
        getSegmentAnalytics()?.track('add_to_cart', {
          category: 'checkout',
          url: window.location.href,
        })
      }, 3000) // delay because segment script might not be loaded yet
    },
    [userEmail] // eslint-disable-line react-hooks/exhaustive-deps
  )
}

export function trackCreateTeam(
  teamName: string,
  emails: string[],
  userEmail: string,
  userDisplayName: string,
  tier?: Tier
) {
  const properties = {
    count: emails.length,
    email: userEmail,
    name: teamName,
    value: (emails.length + 1) * 144,
    currency: 'usd',
    ...getTrackingCookies(),
    tier,
  }
  const traits = {
    traits: {
      email: userEmail,
      firstName: userDisplayName?.split(' ')[0]?.toLowerCase(),
      lastName: userDisplayName?.split(' ')[1]?.toLowerCase(),
    },
  }
  // maps to Segment StartTrial event
  getSegmentAnalytics()?.track('ONBOARD_TEAM_CREATE', properties, traits)
  // maps to Segment Purchase event
  getSegmentAnalytics()?.track('ONBOARD_TEAM_CREATE_NEW', properties, traits)
}

export function trackMissingWorkspaceError() {
  return logEvent(Events.NEW_ONBOARDING_RECURRING_TASKS_MISSING_WORKSPACE_ERROR)
}

export function trackTeamSurveyOption(option: string) {
  getSegmentAnalytics()?.track('team_survey', {
    value: option,
    ...getTrackingCookies(),
  })
}

export function trackCheckoutSurveyOption(option: string) {
  getSegmentAnalytics()?.track('checkout_survey', {
    value: option,
    ...getTrackingCookies(),
  })
}

export function trackNoCcOnboardingComplete(userEmail: string) {
  const cookieData = getTrackingCookies()
  const traitData = {
    traits: {
      email: userEmail,
    },
  }
  getSegmentAnalytics()?.track(
    'onboarding_complete_no_cc',
    { email: userEmail, ...cookieData },
    traitData
  )
}
