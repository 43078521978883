import { templateStr } from '@motion/react-core/strings'
import { createNoneId } from '@motion/shared/identifiers'
import { groupInto } from '@motion/utils/array'
import { Sentry } from '@motion/web-base/sentry'

import { useProjectDefinitionForTask } from '~/areas/flows'
import { useLookup } from '~/global/cache'
import { type ReactNode, useCallback, useMemo } from 'react'

import { BulkOpsGroupsContext } from './bulk-ops-groups-context'
import { type GetBulkOpsGroupByFn } from './types'
import { isBulkOpsProjectItem, isBulkOpsTaskItem } from './utils'

import { useBulkOpsState } from '../bulk-ops'

export type BulkOpsGroupsProviderProps = {
  children: ReactNode
}

export function BulkOpsGroupsProvider({
  children,
}: BulkOpsGroupsProviderProps) {
  const { target, selectedIds } = useBulkOpsState()
  const lookup = useLookup()
  const getTaskProjectDefinition = useProjectDefinitionForTask()

  const selectedItems = useMemo(
    () =>
      selectedIds
        .map((id) => {
          const value = lookup(target, id)

          if (value) return value

          Sentry.captureMessage(
            templateStr("{{entity}} doesn't exist in lookup", {
              entity: target === 'tasks' ? 'Task' : 'Project',
            }),
            {
              extra: {
                id,
              },
            }
          )
        })
        .filter(Boolean),
    [target, lookup, selectedIds]
  )

  const groupItems = useCallback(
    (by: GetBulkOpsGroupByFn) => groupInto(selectedItems, by),
    [selectedItems]
  )

  const workspaceGroups = useMemo(
    () => groupItems((item) => item.workspaceId),
    [groupItems]
  )

  const projectDefinitionGroups = useMemo(
    () =>
      groupItems((item) => {
        let projectDefinitionId

        if (isBulkOpsTaskItem(item, target)) {
          projectDefinitionId = getTaskProjectDefinition(item)?.id
        }

        if (isBulkOpsProjectItem(item, target)) {
          projectDefinitionId = item.projectDefinitionId
        }

        return projectDefinitionId ?? createNoneId()
      }),
    [target, groupItems, getTaskProjectDefinition]
  )

  const value = useMemo(() => {
    return {
      target,
      workspaceGroups,
      projectDefinitionGroups,
    }
  }, [target, workspaceGroups, projectDefinitionGroups])

  return (
    <BulkOpsGroupsContext.Provider value={value}>
      {children}
    </BulkOpsGroupsContext.Provider>
  )
}
