import { findStageForTask } from '@motion/ui-logic/pm/project'

import { StageTaskCardFields } from '~/areas/flows/components'
import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { useMemo } from 'react'

import { StageTaskNameField, useStageTaskField } from '../fields'
import {
  useFlowTemplateRoles,
  useStageTaskAssigneeField,
  useStageTaskRelativeIntervalField,
} from '../fields/hooks'

type ConnectedStageTaskCardFieldsProps = {
  taskBasePath: `stages.${number}.tasks.${number}`
  stageTasksPath: `stages.${number}.tasks`
}

export function ConnectedStageTaskCardFields({
  taskBasePath,
  stageTasksPath,
}: ConnectedStageTaskCardFieldsProps) {
  const {
    form: { watch },
  } = useFlowTemplateForm()

  const stagePath = stageTasksPath
    .split('.')
    .slice(0, 2)
    .join('.') as `stages.${number}`
  const stageDuration = watch(`${stagePath}.duration`)

  const statusIdField = useStageTaskField(`${taskBasePath}.statusId`)
  const durationField = useStageTaskField(`${taskBasePath}.duration`)
  const minimumDurationField = useStageTaskField(
    `${taskBasePath}.minimumDuration`
  )
  const priorityLevelField = useStageTaskField(`${taskBasePath}.priorityLevel`)
  const labelIdsField = useStageTaskField(`${taskBasePath}.labelIds`)
  /** @deprecated */
  const blockedByTaskIdField = useStageTaskField(
    `${taskBasePath}.blockedByTaskId`
  )
  const blockedByTaskIdsField = useStageTaskField(
    `${taskBasePath}.blockedByTaskIds`
  )
  const assigneeIdField = useStageTaskAssigneeField(taskBasePath)
  const relativeStartField = useStageTaskRelativeIntervalField(
    taskBasePath,
    'start'
  )
  const relativeDeadlineField = useStageTaskRelativeIntervalField(
    taskBasePath,
    'due'
  )

  const stages = watch('stages')
  const stageTasks = watch(stageTasksPath)
  const task = useMemo(() => watch(taskBasePath), [taskBasePath, watch])
  const textVariables = watch('textVariables')
  const workspaceId = watch('workspaceId')
  const stageForTask = useMemo(
    () => findStageForTask(stages, task.id),
    [stages, task.id]
  )

  if (stageForTask == null) {
    throw new Error('Stage for task not found')
  }

  const { flowTemplateRoles, selectedRole } = useFlowTemplateRoles(
    taskBasePath,
    stageForTask.id
  )

  const memoFields = useMemo(
    () => ({
      status: statusIdField,
      duration: durationField,
      minimumDuration: minimumDurationField,
      priority: priorityLevelField,
      assignee: assigneeIdField,
      labels: labelIdsField,
      /** @deprecated */
      blockedByTaskId: blockedByTaskIdField,
      blockedByTaskIds: blockedByTaskIdsField,
      relativeStart: relativeStartField,
      relativeDeadline: relativeDeadlineField,
    }),
    [
      statusIdField,
      durationField,
      minimumDurationField,
      priorityLevelField,
      assigneeIdField,
      labelIdsField,
      blockedByTaskIdField,
      blockedByTaskIdsField,
      relativeStartField,
      relativeDeadlineField,
    ]
  )

  if (workspaceId == null || stageTasks.length === 0 || stageForTask == null) {
    return null
  }

  return (
    <>
      <div className='pt-2 px-2 truncate'>
        <StageTaskNameField taskBasePath={taskBasePath} />
      </div>
      <StageTaskCardFields
        stageDefinitionId={stageForTask.id}
        stageTasks={stageTasks}
        task={task}
        workspaceId={workspaceId}
        stageDuration={stageDuration}
        stageColor={stageForTask.color}
        textVariables={textVariables}
        flowTemplateRoles={flowTemplateRoles}
        selectedRole={selectedRole}
        fields={memoFields}
      />
    </>
  )
}
