import { valibotResolver } from '@hookform/resolvers/valibot'
import { type ReactNode, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as v from 'valibot'

import { type CreateContentWithAiFormFields } from './types'

type FormProps = {
  noteId: string
  children: ReactNode
}

const validationSchema = v.object({
  userInstructions: v.optional(v.string()),
  noteId: v.string(),
})

export function CreateContentWithAiForm({ children, noteId }: FormProps) {
  const initialFormData: CreateContentWithAiFormFields = useMemo(() => {
    return {
      userInstructions: '',
      noteId,
    }
  }, [noteId])

  const form = useForm({
    defaultValues: initialFormData,
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
    resolver: valibotResolver(validationSchema),
  })

  return <FormProvider {...form}>{children}</FormProvider>
}
