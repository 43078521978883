import {
  Button,
  type ButtonProps,
  GradientButton,
  type GradientButtonProps,
} from '@motion/ui/base'
import { type Tier } from '@motion/ui-logic/tiered-pricing'
import { type Feature, getTierIcon } from '@motion/web-billing'
import { useModalApi } from '@motion/web-common/modals'

import { forwardRef } from 'react'

import { useCurrentTier } from '../hooks'
import { getNextTier } from '../utils'

type TieredPricingUpsellProps = {
  tier?: Exclude<Tier, 'BASIC'>
  feature: Feature
  featureLocked: boolean
  hideIcon?: boolean
  iconPosition?: 'left' | 'right'
}

type TieredPricingUpsellButtonProps = ButtonProps & TieredPricingUpsellProps

export const TieredPricingUpsellButton = forwardRef<
  HTMLButtonElement,
  TieredPricingUpsellButtonProps
>(function TieredPricingUpsellButton(
  {
    tier,
    feature,
    featureLocked,
    hideIcon,
    iconPosition = 'right',
    ...props
  }: TieredPricingUpsellButtonProps,
  ref
) {
  const currentTier = useCurrentTier()
  const nextTier = getNextTier(currentTier) ?? 'PRO'

  const modalApi = useModalApi()
  const onClick = featureLocked
    ? (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        modalApi.open('tier-upgrade-prompt-modal', {
          tier: tier ?? nextTier,
          feature,
        })
      }
    : props.onClick

  const TierIcon = getTierIcon(tier ?? nextTier)
  const icon = featureLocked && !hideIcon && TierIcon != null && <TierIcon />

  return (
    <Button {...props} onClick={onClick} ref={ref}>
      {iconPosition === 'left' && icon}
      {props.children}
      {iconPosition === 'right' && icon}
    </Button>
  )
})

type TieredPricingUpsellGradientButtonProps = GradientButtonProps &
  TieredPricingUpsellProps

export const TieredPricingUpsellGradientButton = forwardRef<
  HTMLButtonElement,
  TieredPricingUpsellGradientButtonProps
>(function TieredPricingUpsellGradientButton(
  { tier, feature, featureLocked, hideIcon, iconPosition = 'right', ...props },
  ref
) {
  const currentTier = useCurrentTier()
  const nextTier = getNextTier(currentTier) ?? 'PRO'

  const modalApi = useModalApi()
  const onClick = featureLocked
    ? (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        modalApi.open('tier-upgrade-prompt-modal', {
          tier: tier ?? nextTier,
          feature,
        })
      }
    : props.onClick

  const TierIcon = getTierIcon(tier ?? nextTier)

  const icon = featureLocked && !hideIcon && TierIcon != null && <TierIcon />

  return (
    <GradientButton {...props} onClick={onClick} ref={ref}>
      {iconPosition === 'left' && icon}
      {props.children}
      {iconPosition === 'right' && icon}
    </GradientButton>
  )
})
