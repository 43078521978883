import { templateStr } from '@motion/react-core/strings'
import { TeamMemberRole } from '@motion/rpc/types'
import { GradientButton, Tooltip } from '@motion/ui/base'
import {
  type BillingPrices,
  getMinimumBucket,
  makeTeamBillingStr,
} from '@motion/ui-logic/billing'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import {
  useGetTierPrices,
  useShouldShowSalesTaxMessage,
} from '@motion/web-billing'
import { useModalApi } from '@motion/web-common/modals'

import { useCurrentTier } from '~/areas/tiered-pricing/hooks'
import {
  useActiveMemberCount,
  useIsTeamExpired,
  useTeamMembers,
} from '~/global/hooks/team'
import { useGetTeamPrices, useInviteTeamMembers } from '~/global/rpc/team'
import { useAppSelector } from '~/state/hooks'
import { fetchTeam } from '~/state/projectManagement/teamThunks'
import { dispatch } from '~/state/proxy'
import { selectTeam } from '~/state/team'

type Props = {
  userId?: string
}

/**
 * Send out an invite for a placeholder member. This will open a resize team
 * modal if needed.
 * @param user the placeholder user to invite
 * @returns
 */

function InvitePlaceholderTeammateButton({
  userId,
}: Props & { teamPrices: BillingPrices }) {
  const teamMembers = useTeamMembers()
  const user = teamMembers.find((member) => member.userId === userId)?.user
  const modalApi = useModalApi()
  const team = useAppSelector(selectTeam)
  const isExpiredTeam = useIsTeamExpired()
  const shouldShowSalesTaxMessage = useShouldShowSalesTaxMessage()
  const tier = useCurrentTier()

  const { mutateAsync: inviteTeamMembers } = useInviteTeamMembers()

  const { data: teamPrices } = useGetTeamPrices()
  const { data: tierPrices, isLoading: isPriceLoading } = useGetTierPrices(tier)

  const activeMemberCount = useActiveMemberCount()

  if (!teamPrices || !activeMemberCount) return null

  const onInvite = async () => {
    if (!team || !user) return

    const teamHasBucketPricing =
      team?.hasBucketPricing && team.pmTeamSubscription?.bucketSeats

    const needsResize =
      teamHasBucketPricing &&
      team.pmTeamSubscription.bucketSeats &&
      team.pmTeamSubscription.bucketSeats < activeMemberCount + 1

    let seats
    if (needsResize && team.pmTeamSubscription) {
      const pmTeamSubscription = team.pmTeamSubscription
      const newTeamSize = activeMemberCount + 1
      const minBucket = getMinimumBucket(newTeamSize)
      const currentSeats = pmTeamSubscription.bucketSeats
      const currentUserCount = activeMemberCount

      modalApi.open('manage-team-seats', {
        title: `Switch to ${minBucket} seats to invite more members`,
        makeSubtext: (seats: number) => (
          <p className='text-semantic-neutral-text-subtle text-xs'>
            {templateStr(
              'Your team currently has {{currentSeats}} seats & {{currentUserCount}} {{currentMember}}. To invite more members, switch to {{minBucket}} seats.',
              {
                currentSeats,
                currentUserCount,
                currentMember: currentUserCount > 1 ? 'members' : 'member',
                minBucket,
              }
            )}
            <br />
            {templateStr('{{billingStr}}', {
              billingStr: makeTeamBillingStr({
                isAnnual: !pmTeamSubscription.isMonthly,
                quantity: seats,
                isSeats: true,
                prorationTextParams: {
                  isTrial: pmTeamSubscription.status === 'trialing',
                },
                teamPrices,
                tierTeamPrices: tierPrices?.team,
                shouldShowSalesTaxMessage,
              }),
            })}
          </p>
        ),
        cancelText: 'Go back',
        teamSize: newTeamSize,
        isAnnual: !pmTeamSubscription.isMonthly,
        onSubmit: async (updatedSeats) => {
          recordAnalyticsEvent('TEAM_BILLING_UPDATE_SEATS', {
            old_seats: currentUserCount,
            num_seats: updatedSeats,
            action: updatedSeats > currentUserCount ? 'upgrade' : 'downgrade',
          })
          if (updatedSeats > currentUserCount) {
            seats = updatedSeats
            await inviteTeamMembers({
              id: team.id,
              invitees: [
                {
                  email: user.email,
                  role: TeamMemberRole.MEMBER,
                  // We can leave this empty because as a placeholder user,
                  // they have already been invited to some workspaces
                  workspaces: [],
                },
              ],
              seats,
            })
            await dispatch(fetchTeam())
          }
        },
        initialSelection: minBucket,
        minSeats: minBucket,
      })
    }
  }

  if (!user?.isPlaceholder) return null

  if (!team || isExpiredTeam) {
    return null
  }

  return (
    <Tooltip
      asChild
      content='This user will get an email invite, and up on signing in, their tasks will be auto-scheduled on their motion calendar'
    >
      <GradientButton
        disabled={!user.isPlaceholder}
        onClick={onInvite}
        sentiment='promotion'
        loading={isPriceLoading}
        size='small'
      >
        <span className='text-[14px]'>Invite</span>
      </GradientButton>
    </Tooltip>
  )
}

export function ConnectedInvitePlaceholderTeammateButton({ userId }: Props) {
  const { data: teamPrices } = useGetTeamPrices()

  if (!teamPrices) return null

  return (
    <InvitePlaceholderTeammateButton userId={userId} teamPrices={teamPrices} />
  )
}
