import { templateStr } from '@motion/react-core/strings'
import { getTaskStaticURL, isRecurringTaskParent } from '@motion/ui-logic'
import {
  type ProjectsV2CreateFromTaskRequestSchema,
  type TaskSchema,
} from '@motion/zod/client'

import { useReadTaskFn } from '~/global/rpc/v2'
import { useCallback } from 'react'

export function useTaskPrefillData() {
  const readTask = useReadTaskFn()

  return useCallback(
    async (taskId: TaskSchema['id']) => {
      const task = await readTask(taskId)

      if (!task || isRecurringTaskParent(task) || task.type !== 'NORMAL') {
        return null
      }

      return {
        taskId: task.id,
        description: generateProjectDescription(task, task.workspaceId),
        workspaceId: task.workspaceId,
        managerId: task.assigneeUserId,
        priorityLevel: task.priorityLevel,
        name: task.name,
        startDate: task.startDate,
        dueDate: task.dueDate,
        statusId: task.statusId,
        labelIds: task.labelIds,
        customFieldValues: task.customFieldValues,
      } satisfies ProjectsV2CreateFromTaskRequestSchema
    },
    [readTask]
  )
}

function generateProjectDescription(task: TaskSchema, workspaceId: string) {
  return templateStr(
    `{{description}}<p>Project created from: <a href="{{url}}" target="_blank" rel="noopener noreferrer nofollow">{{taskName}}</a></p>`,
    {
      url: getTaskStaticURL({
        taskId: task.id,
        workspaceId,
      }),
      taskName: task.name,
      description: task.description,
    }
  )
}
