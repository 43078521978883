import { useMemo } from 'react'

import { useProjectAIForm } from './use-project-ai-form'

export function useProjectAIFormError() {
  const form = useProjectAIForm()
  return useMemo(() => {
    const { errors } = form.formState
    return errors.projectDefinitionId && errors.prompt
      ? 'Select a template and add instructions to continue'
      : errors.projectDefinitionId
        ? 'Select a template to continue'
        : errors.prompt
          ? 'Add instructions to continue'
          : Object.keys(errors).length > 0
            ? 'An unknown error occurred'
            : ''
  }, [form.formState])
}
