import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useWorkspaceFns } from '~/global/hooks'
import { useCallback } from 'react'

export function useStageUpdateConfirmation() {
  const modalApi = useModalApi()
  const { getWorkspaceProjects } = useWorkspaceFns()

  return useCallback(
    async (stageId: string, workspaceId: string) => {
      const workspaceProjects = getWorkspaceProjects(workspaceId)
      const projectsUsingStage = workspaceProjects.filter((project) => {
        return project.stages.some(
          (stage) => stage.stageDefinitionId === stageId
        )
      })

      if (projectsUsingStage.length > 0) {
        const response = await modalApi.prompt(
          'confirm-update-stage-template',
          {
            stageId,
          }
        )

        return response !== ModalDismissed
      }

      return true
    },
    [modalApi, getWorkspaceProjects]
  )
}
