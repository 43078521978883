import { type FlowTemplateFormTask } from '@motion/ui-logic/pm/project'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import {
  createContext,
  type Dispatch,
  type SetStateAction,
  useContext,
} from 'react'

export type FlowsModalState = {
  dirtyTasksMap: Record<string, boolean>
  setDirtyTasksMap: Dispatch<SetStateAction<Record<string, boolean>>>
  originalTasksMap: Record<string, FlowTemplateFormTask>
  setOriginalTasksMap: Dispatch<
    SetStateAction<Record<string, FlowTemplateFormTask>>
  >
  originalTaskIndicesMap: Record<string, readonly [number, number]>
  setOriginalTaskIndicesMap: Dispatch<
    SetStateAction<Record<string, readonly [number, number]>>
  >
  resetDirtyState: () => void

  /**
   * The mode of the modal. If undefined, it is the default mode.
   */
  mode?: ModalTriggerComponentProps<'flows-template-modal'>['mode']
}

const defaultValue: FlowsModalState = {
  dirtyTasksMap: {},
  setDirtyTasksMap: () => {},
  originalTasksMap: {},
  setOriginalTasksMap: () => {},
  originalTaskIndicesMap: {},
  setOriginalTaskIndicesMap: () => {},
  resetDirtyState: () => {},
}

export const FlowsModalStateContext = createContext(defaultValue)

export function useFlowsModalState() {
  return useContext(FlowsModalStateContext)
}
