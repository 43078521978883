import { type EventFormFields } from '@motion/ui-logic'
import { pick } from '@motion/utils/core'
import { entries } from '@motion/utils/object'

import { type ReactNode, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import {
  type InitialFormOptions,
  type InitialValues,
  useInitialFormData,
} from './hooks'

export type EventFormProps = InitialFormOptions & {
  children: ReactNode
  initialValues?: InitialValues
}

export const EventForm = ({
  children,
  initialValues,
  ...rest
}: EventFormProps) => {
  const initialFormData = useInitialFormData(initialValues, rest)

  const isFromSchedulingTask = rest.schedulingTask != null
  // Setting an empty title first allows for RHF to be dirty by default since we'll reset with the real values in the effect
  const defaultValues = isFromSchedulingTask
    ? { ...initialFormData, title: '' }
    : initialFormData

  const form = useForm({
    defaultValues,
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
  })

  const { isDirty } = form.formState

  useEffect(() => {
    if (!isDirty) {
      return void form.reset(initialFormData, {
        keepDefaultValues: !initialFormData.isLoading && isFromSchedulingTask,
      })
    }

    // When the form is dirty, it's alright to update the "task" fields because these fields are autosaved
    // For some reasons, I can't make `form.reset()` to really work the way I want
    // and it seems to clear the dirty state even with `keepDirtyValues`
    if (initialFormData.id != null) {
      entries(getTaskFields(initialFormData)).forEach((entry) => {
        form.setValue(entry[0], entry[1])
      })
    }
  }, [form, initialFormData, isDirty, isFromSchedulingTask])

  return <FormProvider {...form}>{children}</FormProvider>
}

function getTaskFields(fields: EventFormFields) {
  return pick(fields, [
    'workspaceId',
    'projectId',
    'stageDefinitionId',
    'labelIds',
    'customFieldValuesFieldArray',
  ])
}
