import { CrownSolid } from '@motion/icons'
import { useTheme } from '@motion/theme/dom'
import { Button } from '@motion/ui/base'
import { featureToMotionProText } from '@motion/ui-logic/tiered-pricing'
import { type Feature } from '@motion/web-billing'
import { useModalApi } from '@motion/web-common/modals'

import {
  useExistingSubDefaultBucketSeats,
  useUpsellHiddenTiers,
} from '~/areas/tiered-pricing/hooks'
import { type ReactNode } from 'react'

export type UpgradeStateProps = {
  feature: Feature
}

export const UpgradeState = ({ feature }: UpgradeStateProps) => {
  const { theme } = useTheme()
  const modalApi = useModalApi()
  const initialBucket = useExistingSubDefaultBucketSeats()
  const hiddenTiers = useUpsellHiddenTiers()

  return (
    <div className='size-full grid place-items-center bg-semantic-neutral-surface-bg-default [&>*]:col-span-full [&>*]:row-span-full'>
      <img
        src={`/images/tiered-pricing/blurred-${feature}-${theme}.png`}
        alt={feature}
        className='size-full object-cover object-left-top'
      />
      <UpgradePromptContainer>
        <div className='bg-semantic-warning-bg-default rounded-md p-2'>
          <CrownSolid className='size-6 text-semantic-primary-text-default' />
        </div>

        <p className='text-semantic-neutral-text-default font-medium'>
          Upgrade to Motion Pro to {featureToMotionProText[feature]}
        </p>
        <Button
          onClick={() =>
            modalApi.open('choose-feature-tier-modal', {
              initialBucket,
              hiddenTiers,
              onSelectFeatureTier: (selectedTier, term, bucket) => {
                modalApi.open('tiered-checkout-modal', {
                  tier: selectedTier,
                  selectedBucket: bucket,
                  selectedTerm: term,
                  onBillingComplete: () => modalApi.dismissAll(),
                })
              },
              mode: 'upsell',
            })
          }
        >
          Upgrade to Motion Pro
          <CrownSolid />
        </Button>
      </UpgradePromptContainer>
    </div>
  )
}

const UpgradePromptContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className='bg-semantic-neutral-surface-raised-bg-default rounded-md border border-semantic-warning-border-default p-4 flex flex-col gap-4 items-center w-[400px]'>
      {children}
    </div>
  )
}
