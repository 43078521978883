import { GiftSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { Button } from '@motion/ui/base'
import { useOnMountAnalyticsEvent } from '@motion/web-base/analytics'

import { type CancelScreenProps } from './types'

import { discountPriceString } from '../../Account/utils'

type ExtraDiscountProps = Pick<CancelScreenProps, 'onNextClick'> & {
  handleClaimDiscount: (couponId: string) => void
  discount: number
  couponId: string
  isAnnual: boolean
  loading: boolean
  monthlyPrice: number
}

export const ExtraDiscount = ({
  handleClaimDiscount,
  onNextClick,
  discount,
  couponId,
  isAnnual,
  loading,
  monthlyPrice,
}: ExtraDiscountProps) => {
  useOnMountAnalyticsEvent('CANCELLATION_INTERCEPTION_EXTRA_DISCOUNT')

  const discountStr = discount.toFixed()

  return (
    <div className='flex flex-col p-4 gap-4 w-[350px] text-semantic-neutral-text-default'>
      <h2 className='font-semibold'>
        Our best offer... take ${discountStr}% off
      </h2>
      <div className='flex flex-row items-center gap-4'>
        <div className='flex flex-row items-center justify-center w-10 h-10 bg-semantic-success-bg-default rounded-lg'>
          <GiftSolid className='text-semantic-success-icon-default' />
        </div>
        <div className='flex flex-col'>
          <div className='flex gap-1 items-baseline'>
            <h1 className='text-xl font-semibold text-semantic-success-text-default'>
              {templateStr('{{amount}}/month', {
                amount: discountPriceString(monthlyPrice, discount),
              })}
            </h1>
            {isAnnual && (
              <p className='text-xs font-semibold text-semantic-neutral-text-subtle'>
                (billed annually)
              </p>
            )}
          </div>
          <p className='line-through'>${monthlyPrice}/month</p>
        </div>
      </div>
      <div className='w-full flex flex-row items-center justify-end gap-2'>
        <Button onClick={onNextClick} variant='muted' sentiment='neutral'>
          No thanks
        </Button>
        <Button onClick={() => handleClaimDiscount(couponId)} loading={loading}>
          Claim Discount
        </Button>
      </div>
    </div>
  )
}
