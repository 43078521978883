import { classed, type ComponentProps } from '@motion/theme'

export function MentionCard({
  children,
  ...props
}: ComponentProps<typeof StyledMentionCard>) {
  return (
    <StyledMentionCardWrapper>
      <StyledMentionCard {...props}>{children}</StyledMentionCard>
    </StyledMentionCardWrapper>
  )
}

export const StyledMentionCardWrapper = classed('div', {
  base: `
      absolute bottom-0 left-[-1px] translate-y-full z-10 
  `,
})

export const StyledMentionCard = classed('div', {
  base: `
      w-[330px]
      flex flex-col
      rounded-md
      cursor-pointer
      text-semantic-neutral-text-default
      text-[11px]
      leading-4
      mt-2 shadow-lg

      border overflow-hidden
      bg-kanban-card-bg-default border-kanban-card-border-default
      hover:border-kanban-card-border-hover

      select-none
  `,
  variants: {
    padded: { true: 'p-2 gap-2', false: '' },
    isGhost: {
      true: 'border-semantic-neutral-border-light bg-semantic-neutral-surface-bg-subtlest',
      false: '',
    },
  },
  defaultVariants: {
    padded: true,
    isGhost: false,
  },
})
