import { XSolid } from '@motion/icons'
import { Button, ButtonGroup, IconButton, Text } from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'

import { useState } from 'react'

export type OpenInDesktopAppResult = {
  openInDesktopApp: boolean
  persist: boolean
}

export type OpenInDesktopAppProps = {
  onResult: (result: OpenInDesktopAppResult) => void
}

export const OpenInDesktopApp = ({ onResult }: OpenInDesktopAppProps) => {
  const [rememberChoice, setRememberChoice] = useState(false)

  return (
    <div
      data-motion-desktop-deeplink-popup
      className='bg-semantic-neutral-surface-bg-default border border-semantic-neutral-border-default flex w-[450px] flex-col gap-5 rounded-lg p-4'
    >
      <div className='flex w-full justify-between'>
        <Text weight='semibold'>Open in desktop app?</Text>
        <IconButton
          variant='muted'
          sentiment='neutral'
          icon={XSolid}
          onClick={() => {
            onResult({
              openInDesktopApp: false,
              persist: false,
            })
          }}
        />
      </div>

      <div className='flex w-full items-center justify-between'>
        <Checkbox
          checked={rememberChoice}
          onChange={(checked) => setRememberChoice(!!checked)}
          label='Remember choice'
        />
        <ButtonGroup size='medium'>
          <Button
            variant='muted'
            sentiment='neutral'
            onClick={() => {
              onResult({
                openInDesktopApp: false,
                persist: rememberChoice,
              })
            }}
          >
            No thanks
          </Button>
          <Button
            sentiment='primary'
            onClick={() => {
              onResult({
                openInDesktopApp: true,
                persist: rememberChoice,
              })
            }}
          >
            Open in app
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}
