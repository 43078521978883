import { type StageDefinition } from '@motion/shared/flows'
import { createPlaceholderId } from '@motion/shared/identifiers'
import { reduceCustomFieldsValuesFieldArrayToRecord } from '@motion/ui-logic/pm/project'
import { createTemporaryNormalTask } from '@motion/ui-logic/pm/task'
import { uniqueId } from '@motion/utils/core'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { type NormalTaskSchema, type ProjectSchema } from '@motion/zod/client'

import { getTaskDefaultDatesWithErrorLogging } from '~/areas/tasks/utils'
import { useWorkspaceFns } from '~/global/hooks'
import { useCallback } from 'react'

import { useSetupProjectForm } from './use-setup-project-form'

export function useCreateDefaultStageTask() {
  const { getWorkspaceDefaultStatus } = useWorkspaceFns()
  const { uid: currentUserId } = useAuthenticatedUser()
  const {
    form: { getValues },
  } = useSetupProjectForm()

  return useCallback(
    (
      project: ProjectSchema,
      stageDefinitionId: StageDefinition['id']
    ): NormalTaskSchema => {
      const { startDate, dueDate } = getTaskDefaultDatesWithErrorLogging({
        project,
        stageDefinitionId,
      })

      const defaultStatus = getWorkspaceDefaultStatus(project.workspaceId)

      const customFieldSyncInstanceIds = getValues('customFieldSyncInstanceIds')
      const customFieldValuesForTasks = getValues(
        'customFieldValuesFieldArray'
      ).filter((cf) => customFieldSyncInstanceIds.includes(cf.instanceId))
      const customFieldValues = reduceCustomFieldsValuesFieldArrayToRecord(
        customFieldValuesForTasks
      )

      return createTemporaryNormalTask({
        id: createPlaceholderId(uniqueId('setup-project-')),
        isAutoScheduled: true,
        assigneeUserId: currentUserId,
        createdByUserId: currentUserId,
        workspaceId: project.workspaceId,
        projectId: project.id,
        statusId: defaultStatus?.id ?? '',
        startDate,
        dueDate,
        stageDefinitionId,
        customFieldValues,
      })
    },
    [currentUserId, getValues, getWorkspaceDefaultStatus]
  )
}
