import { useProjects } from './hooks/use-projects'
import { ProjectKanbanBoard } from './project-kanban'

import { LoadingPage } from '../../pm-revamp/common/loading-page'
import { useProjectGroupBy, useViewState } from '../view-state'

export const ConnectedProjectKanban = () => {
  const groupDefinitions = useProjectGroupBy()
  const [viewState] = useViewState()
  const projects = useProjects()

  if (projects == null) {
    return <LoadingPage id='connected-project-kanban' />
  }

  return (
    <ProjectKanbanBoard
      projects={projects}
      groupBy={groupDefinitions}
      hideEmptyGroups={viewState.groupBy.hideEmpty}
    />
  )
}
