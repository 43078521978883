import { templateStr } from '@motion/react-core/strings'
import { ConfirmationModal } from '@motion/ui/base'
import { titleCase } from '@motion/utils/string'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useI18N } from '~/global/contexts'
import { useState } from 'react'

import {
  type TeamMembersTableWorkspace,
  WorkspaceSelector,
} from '../components'

export type EditMemberWorkspaceModalProps = {
  mode: 'add' | 'remove'
  selectedEmails: string[]
  teamWorkspaces: TeamMembersTableWorkspace[]
  onEditWorkspaces: (workspaces: TeamMembersTableWorkspace[]) => void
}

export const EditMemberWorkspaceModal = ({
  close,
  selectedEmails,
  mode,
  teamWorkspaces,
  onEditWorkspaces,
}: ModalTriggerComponentProps<'edit-member-workspace'>) => {
  const { pluralize } = useI18N()

  const title = templateStr('{{mode}} {{users}} {{preposition}} workspace', {
    mode: titleCase(mode),
    users: pluralize(selectedEmails.length, 'user', 'users'),
    preposition: mode === 'add' ? 'to' : 'from',
  })

  const [bulkWorkspaces, setBulkWorkspaces] = useState<
    TeamMembersTableWorkspace[]
  >([])

  return (
    <ConfirmationModal
      action={{
        label:
          mode === 'add'
            ? 'Add users to workspaces'
            : 'Remove users from workspaces',
        onAction: () => {
          onEditWorkspaces(bulkWorkspaces)
          close()
        },
        disabled: bulkWorkspaces.length === 0,
      }}
      destructive={mode === 'remove'}
      description={
        <div className='flex flex-col gap-4 mt-2'>
          <div className='flex items-center gap-4'>
            <span className='text-semantic-neutral-text-subtle font-semibold'>
              Workspaces
            </span>
            <div className='h-8 overflow-hidden'>
              <WorkspaceSelector
                workspaces={bulkWorkspaces}
                teamWorkspaces={teamWorkspaces}
                onSelect={(items) => {
                  setBulkWorkspaces(items)
                }}
              />
            </div>
          </div>
          {bulkWorkspaces.length > 0 ? (
            <p className='text-semantic-neutral-text-subtle'>
              {templateStr(
                'The following users {{mode}} to the selected workspaces: {{users}}',
                {
                  mode:
                    mode === 'remove' ? (
                      <b>will no longer have access</b>
                    ) : (
                      <b>will now have access</b>
                    ),
                  users: selectedEmails.join(', '),
                }
              )}
            </p>
          ) : (
            <p className='text-semantic-neutral-text-subtle'>
              {templateStr(
                'This operation will apply to the following users: {{users}}',
                {
                  users: selectedEmails.join(', '),
                }
              )}
            </p>
          )}
        </div>
      }
      title={title}
      onClose={close}
      visible
    />
  )
}
