import { classed } from '@motion/theme'

export const CustomAttachmentTagContainer = classed('div', {
  base: `
    flex gap-2 rounded p-1 w-60 h-[42px] items-start relative
    bg-semantic-neutral-surface-raised-bg-subtlest
    border border-semantic-neutral-border-default select-none
  `,
  variants: {
    isPreview: {
      true: 'p-0 w-auto h-full overflow-hidden bg-transparent border-none',
      false: 'cursor-pointer',
    },
  },
})

export const CustomAttachmentErrorTagContainer = classed(
  CustomAttachmentTagContainer,
  {
    base: `
      items-center
      bg-semantic-error-bg-default
      border-semantic-error-border-default
      text-semantic-error-text-default
    `,
  }
)

export const ElementLoader = classed('div', {
  base: `
    animate-pulse rounded
    bg-semantic-neutral-bg-active-default
  `,
})
