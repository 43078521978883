import type { AllAvailableCustomFieldSchema } from '@motion/ui-logic'

import { useAllCustomFields } from '~/areas/custom-fields/hooks'
import { useWorkspaceFilter } from '~/areas/project-management/filters/utils'
import { useMemo } from 'react'

export const useWorkspaceAvailableCustomFields =
  (): AllAvailableCustomFieldSchema[] => {
    const workspaceFilter = useWorkspaceFilter()
    const customFields = useAllCustomFields()

    const availableCustomFields: AllAvailableCustomFieldSchema[] = useMemo(
      () => customFields.filter(workspaceFilter),
      [customFields, workspaceFilter]
    )

    return availableCustomFields
  }
