import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { useEditNoteComment as useEditNoteCommentMutation } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export type EditNoteCommentPayload = {
  commentId: string
  targetId: string
  bodyHtml: string
}

export const useEditNoteComment = () => {
  const context = useRouteAnalyticsMetadata()

  const { mutateAsync: editNoteComment } = useEditNoteCommentMutation()

  return useCallback(
    async ({ commentId, targetId, bodyHtml }: EditNoteCommentPayload) => {
      try {
        if (!commentId || !targetId || !bodyHtml) {
          throw new Error('CommentId, targetId and bodyHtml are required.')
        }
        await editNoteComment({
          commentId,
          targetId,
          bodyHtml,
        })

        recordAnalyticsEvent('PROJECT_MANAGEMENT_EDIT_NOTE_COMMENT', context)
      } catch (e) {
        const error = e instanceof Error ? e : new Error(String(e))
        Sentry.captureException(error, {
          tags: {
            position: 'useEditNoteComment',
          },
        })

        showErrorToast(error)
        throw error
      }
    },
    [editNoteComment, context]
  )
}
