import { templateStr } from '@motion/react-core/strings'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useI18N } from '~/global/contexts'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useBulkUpdateSelection } from './use-bulk-update-selection'

import { useBulkOpsState } from '../../contexts'

export function useBulkArchiveTasks() {
  const { bulkUpdateTaskSelection } = useBulkUpdateSelection()
  const { pluralize } = useI18N()
  const { selectedIds } = useBulkOpsState()
  const modalApi = useModalApi()

  return useCallback(async () => {
    try {
      const response = await modalApi.prompt('confirm', {
        analytics: {
          name: 'bulk-archive-tasks',
        },
        destructive: true,
        title: templateStr('Are you sure you want to archive {{item}}?', {
          item: pluralize(
            selectedIds.length,
            'this {{count}} task',
            'these {{count}} tasks'
          ),
        }),
        confirmButtonText: pluralize(
          selectedIds.length,
          'Archive {{count}} task',
          'Archive {{count}} tasks'
        ),
        closeButtonText: 'Cancel',
      })

      if (response === ModalDismissed) return undefined

      await bulkUpdateTaskSelection({
        type: 'bulk-archive',
      })
    } catch (e) {
      Sentry.captureException(e)

      showErrorToast(e)
    }
  }, [bulkUpdateTaskSelection, modalApi, pluralize, selectedIds.length])
}
