import { type ReactNode, useMemo } from 'react'

import { StageTimelineContext } from './context'
import { type StageTimelineContextType } from './types'

type StageTimelineProviderProps = StageTimelineContextType & {
  children: ReactNode
}

export function StageTimelineProvider({
  children,
  project,
  interactive,
}: StageTimelineProviderProps) {
  const memoizedContextValue = useMemo(
    () => ({
      project,
      interactive,
    }),
    [project, interactive]
  )

  return (
    <StageTimelineContext.Provider value={memoizedContextValue}>
      {children}
    </StageTimelineContext.Provider>
  )
}
