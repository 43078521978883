import {
  createStateKey,
  type SharedStateSetterValue,
  useSharedState,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'
import { type CalendarEventAttendeeSchemaV2 } from '@motion/rpc-types'
import { type DeepValuePartial } from '@motion/utils/types'

import { NEW_EVENT_ID, type NewEventType } from '~/areas/calendar/utils'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { defaultNewEvent } from '../constants'

export type ScheduleAssistantCalendarState = {
  selectedCalendarEvent: {
    id: string
    new?: NewEventType
  } | null
  // The currently selected date (shifting calendar will adjust by 1 week)
  selectedDate: DateTime
  // To prevent rendering of the modal content until the proper "mode" is set
  loadingMode: boolean
  syncSessionRange?: {
    start: DateTime
    end: DateTime
  }
} & (
  | {
      mode: 'create-new'
      attendees: CalendarEventAttendeeSchemaV2[]
      hiddenGuestUserIds: string[]
      hostEmail: string | null
    }
  | {
      mode: 'select-existing'
    }
)

export const ScheduleAssistantCalendarStateKey =
  createStateKey<ScheduleAssistantCalendarState>(
    'scheduleAssistantCalendarState',
    {
      defaultValue: {
        loadingMode: true,
        selectedDate: DateTime.now().startOf('day'),
        selectedCalendarEvent: null,
        mode: 'create-new',
        attendees: [],
        hiddenGuestUserIds: [],
        hostEmail: null,
      },
    }
  )

export const useScheduleAssistantCalendarContext = () =>
  useSharedState(ScheduleAssistantCalendarStateKey)

export function useSendScheduleAssistantCalendarContext() {
  const setState = useSharedStateSendOnly(ScheduleAssistantCalendarStateKey)

  return useCallback(
    (
      changes:
        | Partial<ScheduleAssistantCalendarState>
        | SharedStateSetterValue<ScheduleAssistantCalendarState>
    ) => {
      if (typeof changes === 'function') {
        setState(changes)
        return
      }

      setState(
        (prev) =>
          ({
            ...prev,
            ...changes,
          }) as ScheduleAssistantCalendarState
      )
    },
    [setState]
  )
}

export function useUpdateSelectedCalendarEvent() {
  const [{ selectedCalendarEvent }] = useScheduleAssistantCalendarContext()
  const sendCalendarUpdate = useSendScheduleAssistantCalendarContext()

  return useCallback(
    (
      updates: DeepValuePartial<
        ScheduleAssistantCalendarState['selectedCalendarEvent']
      >
    ) => {
      if (updates === null) {
        sendCalendarUpdate({
          selectedCalendarEvent: null,
        })
        return
      }

      if (updates?.new != null) {
        const updatedNewEvent: NewEventType = {
          ...(selectedCalendarEvent?.new ?? defaultNewEvent),
          ...updates?.new,
        }

        sendCalendarUpdate({
          selectedCalendarEvent: {
            new: updatedNewEvent,
            id: updates.id ?? selectedCalendarEvent?.id ?? NEW_EVENT_ID,
          },
        })

        return
      } else if (updates?.id != null) {
        sendCalendarUpdate({
          selectedCalendarEvent: {
            new: selectedCalendarEvent?.new,
            id: updates.id,
          },
        })
        return
      }
    },
    [selectedCalendarEvent, sendCalendarUpdate]
  )
}
