import type { ProjectDefinitionSchema } from '@motion/rpc-types'
import {
  calculateProjectStageDueDates,
  type StageArg,
  type VariableArg,
} from '@motion/ui-logic/pm/project'

import {
  DEFAULT_TEXT_VARIABLE_KEYS,
  isRoleVariable,
  isTextVariable,
} from '~/areas/flows'
import { useWorkspaceFns } from '~/global/hooks'
import { type DateTime } from 'luxon'
import { useCallback } from 'react'

type ProjectDefinitionDerivedFormData = {
  stageDueDates: StageArg[]
  roleAssignees: VariableArg[]
  textReplacements: VariableArg[]
  dueDate: string | undefined
}

export const useProjectDefinitionDerivedFormDataFn = (): ((
  projectDefinition: ProjectDefinitionSchema,
  startDateTime: DateTime,
  maybeDeadlineDateTime: DateTime | undefined
) => ProjectDefinitionDerivedFormData) => {
  const { getWorkspaceById } = useWorkspaceFns()

  return useCallback(
    (
      projectDefinition: ProjectDefinitionSchema,
      startDateTime: DateTime,
      maybeDeadlineDateTime: DateTime | undefined
    ) => {
      const workspaceId = projectDefinition.workspaceId
      const workspace = getWorkspaceById(workspaceId)
      if (workspace == null) {
        throw new Error('Cannot find workspace')
      }

      const stages = projectDefinition?.stages ?? []

      const stageDueDates = calculateProjectStageDueDates(
        stages,
        startDateTime,
        maybeDeadlineDateTime
      ).map((stage) => ({
        ...stage,
        skipped: false,
      }))

      const roleAssignees: VariableArg[] = (
        projectDefinition?.stages ?? []
      ).flatMap((stage) =>
        stage.variables.filter(isRoleVariable).map((variable) => ({
          variableId: variable.id,
          value: null,
          stageId: stage.id,
        }))
      )

      const textReplacements: VariableArg[] = (
        projectDefinition?.stages ?? []
      ).flatMap((stage) =>
        stage.variables
          .filter(isTextVariable)
          .filter(
            (variable) => !DEFAULT_TEXT_VARIABLE_KEYS.includes(variable.key)
          )
          .map((variable) => ({
            variableId: variable.id,
            value: '',
            stageId: stage.id,
          }))
      )

      const dueDate: string | undefined =
        stageDueDates[stageDueDates.length - 1]?.dueDate

      return {
        stageDueDates,
        roleAssignees,
        textReplacements,
        dueDate,
      }
    },
    [getWorkspaceById]
  )
}
