import { useCurrentTeam } from '~/global/rpc/team'
import { useState } from 'react'

import { CreateTeam } from './components/CreateTeam/create-team'
import { TeamPageWithTabs } from './team-page-with-tabs'

export const ConnectedTeamLandingPage = () => {
  const { data: team, isFetching: isTeamFetching } = useCurrentTeam()
  const [isInProgress, setIsInProgress] = useState(false)

  if (isTeamFetching && !isInProgress) {
    return null
  }

  if (team?.id && !isInProgress) {
    return <TeamPageWithTabs />
  }
  return <CreateTeam setIsInProgress={setIsInProgress} />
}
