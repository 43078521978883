import { XSolid } from '@motion/icons'
import { IconButton, LoadingSpinner, UnstyledModal } from '@motion/ui/base'
import { type Term } from '@motion/ui-logic/billing'
import { getTierDelta, type Tier } from '@motion/ui-logic/tiered-pricing'
import { useModalApi } from '@motion/web-common/modals'

import { BillingContainer } from '~/areas/billing'
import { type ModalTriggerComponentProps } from '~/areas/modals'
import {
  useGetSubscriptionPaymentMethod,
  useTeamSubscription,
  useUtilizedSubscription,
} from '~/global/rpc'

export interface TieredCheckoutModalProps {
  tier: Tier
  currentTier?: Tier
  selectedBucket: number
  selectedTerm: Term
  onBillingComplete: () => void
  isResubscribe?: boolean
  onChangeEmail?: () => void
}

export function TieredCheckoutModal({
  close,
  tier,
  currentTier,
  selectedBucket,
  selectedTerm,
  onBillingComplete,
  isResubscribe,
  onChangeEmail,
}: ModalTriggerComponentProps<'tiered-checkout-modal'>) {
  const utilizedSubscription = useUtilizedSubscription()
  const teamSubscription = useTeamSubscription()
  const modalApi = useModalApi()

  // In the resubscribe flow the team subscription will be canceled, and
  // utilizedSubscription will be null
  const { data: paymentMethod, isFetching: isFetchingPaymentMethod } =
    useGetSubscriptionPaymentMethod(
      {
        subscriptionId: utilizedSubscription?.id ?? teamSubscription?.id ?? '',
      },
      { enabled: !!(utilizedSubscription?.id ?? teamSubscription?.id) }
    )

  if (
    !!(utilizedSubscription?.id ?? teamSubscription?.id) &&
    isFetchingPaymentMethod
  ) {
    return (
      <div className='h-full w-full flex items-center justify-center'>
        <LoadingSpinner />
      </div>
    )
  }

  const downgrade =
    !!currentTier && getTierDelta(currentTier, tier) === 'downgrade'

  return (
    <UnstyledModal
      type='page'
      withAnimation
      overlayClassName='bg-modal-overlay'
      visible
      disableOverlayClick
      onClose={close}
      modalClassName='border border-semantic-neutral-border-default rounded-lg'
    >
      <div className='button-neutral-muted-icon-default absolute top-1 -right-6'>
        <IconButton
          icon={XSolid}
          onClick={close}
          size='small'
          variant='muted'
          sentiment='neutral'
        />
      </div>
      <div className='flex flex-col bg-semantic-neutral-bg-subtle w-[75vw] max-w-[1040px] max-h-[1000px] lg:max-h-[724px] rounded-lg'>
        <BillingContainer
          onComplete={() => {
            modalApi.dismissAll()
            onBillingComplete()
          }}
          isTeam={selectedBucket > 1}
          onBack={close}
          tier={tier}
          initialBucket={selectedBucket}
          initialTerm={selectedTerm}
          checkoutType={
            isResubscribe
              ? 'resubscribe'
              : paymentMethod
                ? 'add-payment-method'
                : 'initial-purchase'
          }
          isDowngrade={downgrade}
          onChangeEmail={onChangeEmail}
        />
      </div>
    </UnstyledModal>
  )
}
