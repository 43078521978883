import { CheckSolid } from '@motion/icons'
import { ActionDropdown, Button } from '@motion/ui/base'
import { CalendarColorIcon, CalendarTitle } from '@motion/ui/calendar'
import { FieldLabel, TextField } from '@motion/ui/forms'
import { parseColorId } from '@motion/ui-logic'

import { useEditableCalendars } from '~/areas/calendar/hooks'
import { selectEmailAccountsMap } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'
import { useContext } from 'react'

import { BookingTemplateContext } from '../../../booking-template-context'
import { SectionRow } from '../../../components/section-row'

type EventHostRowProps = {
  hostRef: React.MutableRefObject<HTMLDivElement | null>
}

export const EventHostRow = ({ hostRef }: EventHostRowProps) => {
  const editableCalendars = useEditableCalendars()
  const emailAccountsMap = useAppSelector(selectEmailAccountsMap)

  const {
    hostCalendar,
    template: {
      state: { hostDisplayName },
      setters: { setHostDisplayName },
      onHostCalendarChanged,
    },
  } = useContext(BookingTemplateContext)

  return (
    <SectionRow title='Host' subtitle='Host of the calendar event'>
      <div className='flex w-full flex-col gap-4'>
        <div className='flex w-full flex-col gap-1' ref={hostRef}>
          <FieldLabel label='Calendar'>
            <div>
              <ActionDropdown
                items={editableCalendars.map((calendar) => ({
                  content: (
                    <div className='flex items-center gap-2'>
                      <CalendarTitle
                        calendar={calendar}
                        emailAccountsMap={emailAccountsMap}
                      />
                    </div>
                  ),
                  prefix: (
                    <CalendarColorIcon
                      colorId={parseColorId(calendar.colorId)}
                    />
                  ),
                  suffix: (
                    <CheckSolid
                      className={
                        hostCalendar?.id === calendar.id
                          ? 'visible'
                          : 'invisible'
                      }
                    />
                  ),
                  onAction: () => void onHostCalendarChanged(calendar.id),
                }))}
              >
                <Button alignment='left' sentiment='neutral'>
                  {hostCalendar ? (
                    <CalendarTitle
                      calendar={hostCalendar}
                      emailAccountsMap={emailAccountsMap}
                    />
                  ) : (
                    'Missing host'
                  )}
                </Button>
              </ActionDropdown>
            </div>
          </FieldLabel>
        </div>

        <div className='flex w-full flex-col gap-1'>
          <FieldLabel label='Host name'>
            <TextField
              value={hostDisplayName}
              placeholder="Enter host's name"
              onChange={(value) => {
                setHostDisplayName(value)
              }}
            />
          </FieldLabel>
        </div>
      </div>
    </SectionRow>
  )
}
