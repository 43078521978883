import {
  type ProjectSchema,
  type StageDefinitionSchema,
} from '@motion/rpc-types'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useRemoveProjectStage } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export function useRemoveStage() {
  const { mutateAsync: removeProjectStage } = useRemoveProjectStage()
  const modalApi = useModalApi()

  return useCallback(
    async (
      project: ProjectSchema,
      stageDefinitionId: StageDefinitionSchema['id']
    ) => {
      const confirmResponse = await modalApi.prompt(
        'confirm-remove-stage-from-project',
        {
          project,
          stageDefinitionId,
        }
      )

      if (confirmResponse === ModalDismissed) {
        recordAnalyticsEvent('FLOW_UNSYNC_PROJECT_TEMPLATE_CANCEL', {
          type: 'remove-stage',
        })
        return
      }

      recordAnalyticsEvent('FLOW_UNSYNC_PROJECT_TEMPLATE_CONTINUE', {
        type: 'remove-stage',
      })

      const stageId = project.stages.find(
        (stage) => stage.stageDefinitionId === stageDefinitionId
      )?.id

      if (!stageId) {
        showErrorToast('Stage not found')
        return
      }

      recordAnalyticsEvent('FLOW_REMOVE_STAGE_FROM_PROJECT', {
        taskUpdate: confirmResponse.removeOption,
      })

      try {
        await removeProjectStage({
          projectId: project.id,
          stageId,
          taskAction: confirmResponse.removeOption,
        })
      } catch (error) {
        Sentry.captureException(error)
        showErrorToast('Failed to remove stage')
      }
    },
    [modalApi, removeProjectStage]
  )
}
