import { Text } from '@motion/ui/base'

import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import {
  enableBrowserNotifications,
  selectIsBrowserNotificationsEnabled,
} from '../../../../state/mainSlice'
import { Paragraph, PrimaryButton } from '../../../Common'

export const BrowserNotificationSettings = () => {
  const dispatch = useAppDispatch()

  const isBrowserNotificationsEnabled = useAppSelector(
    selectIsBrowserNotificationsEnabled
  )

  const enable = () => {
    void dispatch(enableBrowserNotifications())
  }

  return (
    <div className='flex w-full flex-col gap-3'>
      <Paragraph className='pb-1 text-base font-semibold'>
        Browser Notifications
      </Paragraph>
      {isBrowserNotificationsEnabled && (
        <Text sentiment='subtle' size='sm'>
          Browser notifications are enabled.
        </Text>
      )}
      {!isBrowserNotificationsEnabled && (
        <PrimaryButton onClick={enable} className='w-max'>
          Enable Browser Notifications
        </PrimaryButton>
      )}
    </div>
  )
}
