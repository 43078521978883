import { FieldButton } from '@motion/ui/base'
import {
  getDeadlineText,
  isValidTaskDeadlineDateOption,
} from '@motion/ui-logic/pm/task'

import { TaskDeadlineDropdown } from '~/areas/tasks/components'
import { useTaskUpdater } from '~/areas/tasks/hooks'
import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import { useActionItemForm } from '../hooks'
import { useActionItemContext } from '../provider'

export function ConnectedInlineDeadlineField() {
  const { task } = useActionItemContext()
  const { form } = useActionItemForm()

  const { control } = form

  const { field } = useController({
    name: 'dueDate',
    control,
  })
  const updateTask = useTaskUpdater()

  const startDate = form.watch('startDate')
  const projectId = form.watch('projectId')
  const priorityLevel = form.watch('priorityLevel')

  const formattedValue = useMemo(
    () =>
      getDeadlineText({
        priorityLevel,
        dueDate: field.value,
      }),
    [field.value, priorityLevel]
  )

  const handleOnChange = (value: string) => {
    field.onChange(value)

    if (task != null) {
      updateTask(task.id, { dueDate: value })
    }
  }

  return (
    <TaskDeadlineDropdown
      contextProps={{
        projectId,
      }}
      value={field.value}
      onChange={handleOnChange}
      isValidDateOption={(date) =>
        isValidTaskDeadlineDateOption({ type: 'NORMAL', startDate }, date)
      }
    >
      <FieldButton variant='minimal' size='xsmall'>
        {formattedValue}
      </FieldButton>
    </TaskDeadlineDropdown>
  )
}
