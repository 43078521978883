import {
  findStageForTask,
  type FlowTemplateStage,
} from '@motion/ui-logic/pm/project'
import { Sentry } from '@motion/web-base/sentry'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'
import { type ModalApi } from '@motion/web-common/modals'
import { type ModalDefinitions } from '@motion/web-common/modals/definitions'
import type { TaskDefinitionSchema } from '@motion/zod/client'

import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { stripVariableKeysFromTaskDefinition } from '~/areas/flows/utils'
import { useCallback } from 'react'

import {
  type UseDeprecatedTemplateTaskReturn,
  useDeprecatedTemplateTasksFn,
  type UseTemplateTaskReturn,
  useTemplateTasksFn,
} from '../../../hooks'
import { deprecatedStripBlockers, stripBlockers } from '../utils'

export type UseWarnAndRemoveTemplateBlockersProps = {
  stages: FlowTemplateStage[]
  previousStages: FlowTemplateStage[]
  activeTaskId: string
  replace: (stages: FlowTemplateStage[]) => void
}

export const useWarnAndRemoveTemplateBlockersFn = () => {
  const modalApi = useModalApi()
  const {
    form: { watch },
  } = useFlowTemplateForm()
  const variableKeys = watch('textVariables').map((v) => v.key)

  const isMultipleBlockersEnabled = useHasTreatment('flows-multiple-blockers')
  const getTemplateTasks = useTemplateTasksFn()
  const getDeprecatedTemplateTasks = useDeprecatedTemplateTasksFn()

  return useCallback(
    async ({
      stages,
      previousStages,
      activeTaskId,
      replace,
    }: UseWarnAndRemoveTemplateBlockersProps) =>
      isMultipleBlockersEnabled
        ? warnAndRemoveTemplateBlockers({
            stages,
            previousStages,
            activeTaskId,
            templateTasks: getTemplateTasks(stages),
            modalApi,
            replace,
            variableKeys,
          })
        : deprecatedWarnAndRemoveTemplateBlockers({
            stages,
            previousStages,
            activeTaskId,
            templateTasks: getDeprecatedTemplateTasks(stages),
            modalApi,
            replace,
            variableKeys,
          }),
    [
      isMultipleBlockersEnabled,
      getTemplateTasks,
      getDeprecatedTemplateTasks,
      modalApi,
      variableKeys,
    ]
  )
}

type WarnAndRemoveTemplateBlockersProps =
  UseWarnAndRemoveTemplateBlockersProps & {
    templateTasks: UseTemplateTaskReturn
    modalApi: ModalApi<ModalDefinitions>
    variableKeys: string[]
  }

type WarnAndRemoveTemplateBlockersResult = {
  moved: boolean
  isChangingStage: boolean
}

async function warnAndRemoveTemplateBlockers({
  stages,
  previousStages,
  activeTaskId,
  templateTasks,
  modalApi,
  replace,
  variableKeys,
}: WarnAndRemoveTemplateBlockersProps): Promise<WarnAndRemoveTemplateBlockersResult> {
  const previousAllTasks = previousStages.flatMap((stage) => stage.tasks)

  const startTaskIndex = previousAllTasks.findIndex(
    (task) => task.id === activeTaskId
  )
  const endTaskIndex = templateTasks.all.findIndex(
    (task) => task.id === activeTaskId
  )

  if (startTaskIndex === -1 || endTaskIndex === -1) {
    Sentry.captureException(
      new Error('Task index not found in warnAndRemoveTemplateBlockers'),
      {
        extra: {
          previousAllTasks,
          templateTasks: templateTasks.all,
          activeTaskId,
        },
      }
    )
    return { moved: false, isChangingStage: false }
  }

  const startStage = findStageForTask(
    previousStages,
    previousAllTasks[startTaskIndex].id
  )
  const endStage = findStageForTask(stages, templateTasks.all[endTaskIndex].id)

  const isDroppedToOriginalIndex =
    startTaskIndex === endTaskIndex && startStage?.id === endStage?.id
  if (isDroppedToOriginalIndex) {
    return { moved: false, isChangingStage: false }
  }

  const blockedByIds = templateTasks.blockedById[activeTaskId] ?? []
  const blockingIds = templateTasks.blockingById[activeTaskId] ?? []

  let blockedByTasks: TaskDefinitionSchema[] = []
  let blockingTasks: TaskDefinitionSchema[] = []

  const isChangingStage =
    startStage != null && endStage != null && startStage.id !== endStage.id
  // If the task is being moved to a different stage, we include all blockers
  if (isChangingStage) {
    blockedByTasks = blockedByIds.map((id) => templateTasks.byId[id])
    blockingTasks = blockingIds.map((id) => templateTasks.byId[id])
  } else {
    // If the task is being moved within the same stage, we only include blockedBy above the target index
    // and blocking below the target index
    const blockedByTasksAboveIndexes = blockedByIds
      ?.map((id) =>
        previousAllTasks.findIndex(
          (task, index) => task.id === id && index >= endTaskIndex
        )
      )
      .filter((i) => i !== -1)

    const blockingTasksBelowIndexes = blockingIds
      .map((id) =>
        previousAllTasks.findIndex(
          (task, index) => task.id === id && index <= endTaskIndex
        )
      )
      .filter((i) => i !== -1)

    if (blockedByTasksAboveIndexes.length || blockingTasksBelowIndexes.length) {
      blockedByTasks = blockedByTasksAboveIndexes.map(
        (index) => previousAllTasks[index]
      )

      blockingTasks = blockingTasksBelowIndexes.map(
        (index) => previousAllTasks[index]
      )
    }
  }

  if (blockedByTasks.length || blockingTasks.length) {
    const strippedBlockedByTasks = blockedByTasks
      .map((t) => stripVariableKeysFromTaskDefinition(t, variableKeys))
      .filter((t) => t.name !== '')
    const strippedBlockingTasks = blockingTasks
      .map((t) => stripVariableKeysFromTaskDefinition(t, variableKeys))
      .filter((t) => t.name !== '')

    const result = await modalApi.prompt('remove-template-blockers', {
      startStage: isChangingStage ? startStage : undefined,
      endStage: isChangingStage ? endStage : undefined,
      blockedByTasks: strippedBlockedByTasks,
      blockingTasks: strippedBlockingTasks,
      onValue(value) {
        if (value) {
          const newStages = stripBlockers(
            activeTaskId,
            stages,
            blockedByTasks,
            blockingTasks
          )
          replace(newStages)
          return true
        }

        return { moved: false, isChangingStage }
      },
      onDismiss() {
        if (previousStages == null) return

        replace(previousStages)
      },
    })

    return { moved: result === true, isChangingStage }
  }

  return { moved: true, isChangingStage }
}

type DeprecatedWarnAndRemoveTemplateBlockersProps =
  UseWarnAndRemoveTemplateBlockersProps & {
    templateTasks: UseDeprecatedTemplateTaskReturn
    modalApi: ModalApi<ModalDefinitions>
    variableKeys: string[]
  }

async function deprecatedWarnAndRemoveTemplateBlockers({
  stages,
  previousStages,
  activeTaskId,
  templateTasks,
  modalApi,
  replace,
  variableKeys,
}: DeprecatedWarnAndRemoveTemplateBlockersProps): Promise<WarnAndRemoveTemplateBlockersResult> {
  const previousAllTasks = previousStages.flatMap((stage) => stage.tasks)

  const startTaskIndex = previousAllTasks.findIndex(
    (task) => task.id === activeTaskId
  )
  const endTaskIndex = templateTasks.all.findIndex(
    (task) => task.id === activeTaskId
  )

  if (startTaskIndex === -1 || endTaskIndex === -1) {
    Sentry.captureException(
      new Error(
        'Task index not found in deprecatedWarnAndRemoveTemplateBlockers'
      ),
      {
        extra: {
          previousAllTasks,
          templateTasks: templateTasks.all,
          activeTaskId,
        },
      }
    )
    return { moved: false, isChangingStage: false }
  }

  const startStage = findStageForTask(
    previousStages,
    previousAllTasks[startTaskIndex].id
  )
  const endStage = findStageForTask(stages, templateTasks.all[endTaskIndex].id)

  const isDroppedToOriginalIndex =
    startTaskIndex === endTaskIndex && startStage?.id === endStage?.id
  if (isDroppedToOriginalIndex) {
    return { moved: false, isChangingStage: false }
  }

  const blockedById = templateTasks.blockedById[activeTaskId] as
    | string
    | undefined
  const blockingIds = templateTasks.blockingById[activeTaskId] ?? []

  let blockedByTask: TaskDefinitionSchema | undefined
  let blockingTasks: TaskDefinitionSchema[] = []

  const isChangingStage =
    startStage != null && endStage != null && startStage.id !== endStage.id
  // If the task is being moved to a different stage, we include all blockers
  if (isChangingStage) {
    blockedByTask =
      blockedById != null ? templateTasks.byId[blockedById] : undefined
    blockingTasks = blockingIds.map((id) => templateTasks.byId[id])
  } else {
    // If the task is being moved within the same stage, we only include blockedBy above the target index
    // and blocking below the target index
    const blockedByTaskAboveIndex = previousAllTasks.findIndex(
      (task, index) => task.id === blockedById && index >= endTaskIndex
    )

    const blockingTasksBelowIndexes = blockingIds
      .map((id) =>
        previousAllTasks.findIndex(
          (task, index) => task.id === id && index <= endTaskIndex
        )
      )
      .filter((i) => i !== -1)

    if (blockedByTaskAboveIndex !== -1 || blockingTasksBelowIndexes.length) {
      blockedByTask =
        blockedByTaskAboveIndex !== -1
          ? previousAllTasks[blockedByTaskAboveIndex]
          : undefined

      blockingTasks = blockingTasksBelowIndexes.map(
        (index) => previousAllTasks[index]
      )
    }
  }

  if (blockedByTask != null || blockingTasks.length) {
    const strippedBlockedByTask = stripVariableKeysFromTaskDefinition(
      blockedByTask,
      variableKeys
    )
    const strippedBlockingTasks = blockingTasks
      .map((t) => stripVariableKeysFromTaskDefinition(t, variableKeys))
      .filter((t) => t.name !== '')

    const result = await modalApi.prompt(
      'deprecated-remove-template-blockers',
      {
        startStage: isChangingStage ? startStage : undefined,
        endStage: isChangingStage ? endStage : undefined,
        blockedByTask: strippedBlockedByTask,
        blockingTasks: strippedBlockingTasks,
        onValue(value) {
          if (value) {
            const newStages = deprecatedStripBlockers(
              activeTaskId,
              stages,
              blockedByTask,
              blockingTasks
            )
            replace(newStages)
            return true
          }

          return false
        },
        onDismiss() {
          if (previousStages == null) return

          replace(previousStages)
        },
      }
    )

    return { moved: result === true, isChangingStage }
  }

  return { moved: true, isChangingStage }
}
