import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import {
  type V2CreateTaskStatusSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { useCreateStatus as useCreateStatusCall } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useCreateStatus = () => {
  const {
    mutateAsync: createWorkspaceStatus,
    isPending: isLoading,
    isError,
  } = useCreateStatusCall()
  const routeCtx = useRouteAnalyticsMetadata()

  return {
    createStatus: useCallback(
      async (
        data: V2CreateTaskStatusSchema & { workspaceId: WorkspaceSchema['id'] }
      ) => {
        try {
          recordAnalyticsEvent(
            'PROJECT_MANAGEMENT_CREATE_WORKSPACE_STATUS',
            routeCtx
          )

          const response = await createWorkspaceStatus(data)

          showToast('success', 'Status created')

          return response.models[response.meta.model][response.id]
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              position: 'create-status',
            },
          })

          showErrorToast(e)
        }
      },
      [createWorkspaceStatus, routeCtx]
    ),
    isLoading,
    isError,
  }
}
