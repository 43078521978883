import { LinkSolid } from '@motion/icons'
import { Button, showToast, Text, Toggle, Tooltip } from '@motion/ui/base'

import { useQueryClient } from '@tanstack/react-query'
import { usePublishResourceMutation } from '~/global/rpc'
import { showErrorToast } from '~/global/toasts'
import { type ChangeEvent, useContext } from 'react'

import { ShareItemModalContext } from '../context/context'

export const PublishSection = () => {
  const { itemId, itemType, canPublish, isPublished, publishedUrl } =
    useContext(ShareItemModalContext)

  const queryClient = useQueryClient()
  const { mutateAsync: publishResource } = usePublishResourceMutation()

  // If we can't toggle AND there's no published URL, don't render anything
  if (!canPublish && !publishedUrl) return null

  const handleToggleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!canPublish) return

    const publish = event.target.checked

    try {
      // Call the mutation
      const response = await publishResource({
        resourceId: itemId,
        resourceType: itemType,
        publish,
      })

      // Explicitly update the query cache with the response data
      const publishStatusQueryKey = [
        'publishStatus',
        { resourceId: itemId, resourceType: itemType },
      ]
      queryClient.setQueryData(publishStatusQueryKey, response)
    } catch (error) {
      showErrorToast(error, 'Failed to update publish status')
    }
  }

  return (
    <div className='border-t border-semantic-neutral-border-default mt-2 pt-3 pb-4 px-4'>
      {/* Only show the toggle if user can publish */}
      {canPublish && (
        <div className='flex items-center justify-between mb-2'>
          <Text>Publish</Text>
          <Toggle checked={isPublished} onChange={handleToggleChange} />
        </div>
      )}

      {/* Show the URL if it exists, regardless of canPublish */}
      {isPublished && publishedUrl && (
        <div className='mt-2 flex items-center gap-2 p-2 mb-2 bg-semantic-neutral-surface-raised-bg-default rounded border border-semantic-neutral-border-default'>
          <Text className='flex-1 truncate'>{publishedUrl}</Text>
          <Tooltip content='Copy URL'>
            <Button
              sentiment='neutral'
              variant='muted'
              onClick={() => {
                navigator.clipboard.writeText(publishedUrl)
                showToast('success', 'Copied public URL to clipboard')
              }}
            >
              <LinkSolid className='!size-4' />
            </Button>
          </Tooltip>
        </div>
      )}
    </div>
  )
}
