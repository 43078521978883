import { getPrioritiesForTask } from '@motion/ui-logic'

import {
  PriorityField,
  type PriorityFieldProps,
} from '~/areas/task-project/fields'
import { useController } from 'react-hook-form'

import { useTaskForm } from '../hooks'

export const ControlledPriorityField = () => {
  const { form, disabledFields } = useTaskForm()

  const { control, watch } = form
  const type = watch('type')

  const { field } = useController({
    name: 'priorityLevel',
    control,
  })

  const onChange: PriorityFieldProps['onChange'] = (priorityLevel) => {
    field.onChange(priorityLevel)
  }

  return (
    <PriorityField
      list={getPrioritiesForTask({ type })}
      value={field.value}
      onChange={onChange}
      disabled={disabledFields.has('priorityLevel')}
    />
  )
}
