import { createContext } from 'react'

import { type NoteDialogIds, type NoteDialogOptions } from '../../dialogs'

export type NoteDialogContextValue = {
  openedDialog: NoteDialogIds | null
  setOpenedDialog: (dialogId: NoteDialogIds | null) => void
  selectedText: NoteDialogOptions['selectedText']
  setSelectedText: (text: NoteDialogOptions['selectedText']) => void
}

export const NoteDialogContext = createContext<NoteDialogContextValue>({
  openedDialog: null,
  setOpenedDialog: () => void 0,
  selectedText: null,
  setSelectedText: () => void 0,
})
