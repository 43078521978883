import { createStateKey, useSharedState } from '@motion/react-core/shared-state'

import {
  flattenTreeItems,
  type SortableTreeviewProps,
  type SortableWorkspacesTreeviewItem,
  useWorkspacesTree,
} from '~/areas/treeviews/components'
import { workspaceTreeviewRenderFilter } from '~/areas/treeviews/components/workspace-treeview/utils'
import { useFilteredTreeview } from '~/areas/treeviews/hooks/use-filtered-treeview'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

export type BrowseTreeViewItemsOptions = {
  searchValue: string
}

export type BrowseTreeViewItemsReturn<
  T extends SortableWorkspacesTreeviewItem = SortableWorkspacesTreeviewItem,
> = {
  items: T[]
  renderFilter: NonNullable<SortableTreeviewProps<T>['renderFilter']>
  toggleExpandState: (id: string) => void
  tree: T[]
  treeRoot: T | null
}

export const useBrowseTreeViewItems = ({
  searchValue,
}: BrowseTreeViewItemsOptions): BrowseTreeViewItemsReturn => {
  const params = useParams<{
    workspaceId: string
    folderId: string
    projectId: string
  }>()

  const subTreeId = params.projectId ?? params.folderId ?? params.workspaceId

  const items = useWorkspacesTree({
    preferUsingBrowsePageUrls: true,
    makeProjectContainers: true,
  })

  const [folderState, setFolderState] = useSharedState(BrowseTreeviewStateKey)

  const filteredItems = useFilteredTreeview({
    items,
    computeSearchValue: ({ label }) => label,
    searchQuery: searchValue,
    computeFolderStateKey: ({ id }) => id,
    folderState,
  })

  const flattenedItems = useMemo(
    () =>
      flattenTreeItems(filteredItems, {
        injectPlaceholderInAllLevels: true,
      }),
    [filteredItems]
  )

  const { tree, treeRoot } = useMemo(() => {
    if (subTreeId == null) {
      return {
        tree: flattenedItems,
        treeRoot: null,
      }
    }

    const start = flattenedItems.find((item) => {
      if (params.projectId || params.folderId) {
        return item.item.itemId === subTreeId && item.type !== 'PLACEHOLDER'
      }

      if (params.workspaceId) {
        return item.type === 'WORKSPACE' && item.item.workspaceId === subTreeId
      }

      return false
    })

    if (!start) {
      // TODO warn
      return {
        tree: [],
        treeRoot: null,
      }
    }

    const tree = flattenedItems.slice(flattenedItems.indexOf(start))

    const end = tree.find((_, idx, allItems) => {
      const nextItem = allItems[idx + 1] as
        | SortableWorkspacesTreeviewItem
        | undefined

      return nextItem && nextItem.level <= start.level
    })

    if (end) {
      tree.splice(tree.indexOf(end) + 1)
    }

    const treeRoot = tree.shift() ?? null

    return {
      tree,
      treeRoot,
    }
  }, [
    flattenedItems,
    params.folderId,
    params.projectId,
    params.workspaceId,
    subTreeId,
  ])

  const renderFilter = useCallback<BrowseTreeViewItemsReturn['renderFilter']>(
    (_, args) => workspaceTreeviewRenderFilter(tree, args),
    [tree]
  )

  const toggleExpandState = useCallback<
    BrowseTreeViewItemsReturn['toggleExpandState']
  >(
    (id) =>
      setFolderState((prev) => ({
        ...prev,
        [id]: !prev[id],
      })),
    [setFolderState]
  )

  return useMemo(
    () => ({
      items: flattenedItems,
      renderFilter,
      toggleExpandState,
      tree,
      treeRoot,
    }),
    [flattenedItems, renderFilter, toggleExpandState, tree, treeRoot]
  )
}

const BrowseTreeviewStateKey = createStateKey<Record<string, boolean>>(
  'browseTreeviewState',
  {
    defaultValue: {},
  }
)
