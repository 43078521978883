import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { createUnknownStage } from '@motion/rpc-cache'
import {
  type ProjectDefinitionSchema,
  type ProjectSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { AppWorkspaceContext } from '~/global/contexts'

export const useAllProjectDefinitions = (): ProjectDefinitionSchema[] => {
  return useSharedStateQuery(
    AppWorkspaceContext,
    (state) => state.projectDefinitions.all
  )
}

export const useProjectDefinitions = (
  workspaceId: WorkspaceSchema['id'] | undefined | null
): ProjectDefinitionSchema[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    state.projectDefinitions.all.filter((p) => p.workspaceId === workspaceId)
  )
}

export const useWorkspaceProjectDefinitionsWithStages = (
  workspaceId: WorkspaceSchema['id']
): ProjectDefinitionSchema[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    state.projectDefinitions.all
      .filter((p) => p.workspaceId === workspaceId)
      .map((p) => ({
        ...p,
        stages: p.stageDefinitionReferences.map(
          (s) => state.stageDefinitionsV2.byId[s.stageDefinitionId]
        ),
      }))
  )
}

export const useProjectDefinition = (
  id: ProjectDefinitionSchema['id'] | null | undefined
): ProjectDefinitionSchema | null => {
  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    id == null ? null : (state.projectDefinitions.byId[id] ?? null)
  )
}

export type ProjectDefinitionWithProjectInfo = ProjectDefinitionSchema & {
  projects: ProjectSchema[]
}

export const useProjectDefinitionWithProjectInfo = (
  id: ProjectDefinitionSchema['id'] | null | undefined
): ProjectDefinitionWithProjectInfo | null => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    if (id == null) return null

    const projectDefinition = state.projectDefinitions.byId[id]

    if (projectDefinition == null) return null

    const projects = state.projects.all.filter(
      (project) => project.projectDefinitionId === projectDefinition.id
    )

    return { ...projectDefinition, projects }
  })
}

export const useProjectDefinitionsWithProjectInfo = (
  workspaceId: WorkspaceSchema['id']
): ProjectDefinitionWithProjectInfo[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    const projectDefinitions = state.projectDefinitions.all.filter(
      (projectDefinition) => projectDefinition.workspaceId === workspaceId
    )
    const projects = state.projects.all.filter(
      (project) => project.workspaceId === workspaceId
    )

    return projectDefinitions.map((projectDefinition) => {
      const projectsForDefinition = projects.filter(
        (project) => project.projectDefinitionId === projectDefinition.id
      )
      return { ...projectDefinition, projects: projectsForDefinition }
    })
  })
}

export const useProjectDefinitionWithStagesById = (
  id: ProjectDefinitionSchema['id'] | null | undefined
): ProjectDefinitionSchema | null => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    if (id == null) return null

    const projectDefinition = state.projectDefinitions.byId[id]

    if (projectDefinition == null) return null

    return {
      ...projectDefinition,
      stages: projectDefinition.stageDefinitionReferences.map(
        (stageDefinitionReference) => {
          return (
            state.stageDefinitionsV2.byId[
              stageDefinitionReference.stageDefinitionId
            ] ?? createUnknownStage(stageDefinitionReference.stageDefinitionId)
          )
        }
      ),
    }
  })
}
