import { UserSolid } from '@motion/icons'
import { type FlowTemplateRoleAssignee } from '@motion/ui-logic/pm/project'

import { VariableLabelBase } from './variable'

export function RoleLabel({
  role,
}: {
  role: Pick<FlowTemplateRoleAssignee, 'name' | 'color'>
}) {
  return (
    <VariableLabelBase color={role.color} size='xsmall'>
      <UserSolid className='!w-3 !h-3 !text-palette-highlight-default shrink-0' />
      <span className='truncate'>{role.name}</span>
    </VariableLabelBase>
  )
}
