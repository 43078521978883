import {
  type SortableWorkspacesTreeviewItem,
  type WorkspacesTreeviewItemType,
} from '~/areas/treeviews/components'
import { useMemo } from 'react'

import { EmptyState } from './components/empty-state'
import { EmptyStateWithNoteCheck } from './components/empty-state-with-note-check'

type ConnectedEmptyStateProps = {
  items: SortableWorkspacesTreeviewItem[]
  viewType?: WorkspacesTreeviewItemType
}

export function ConnectedEmptyState({
  items,
  viewType,
}: ConnectedEmptyStateProps) {
  const filteredItems = useMemo(
    () => items.filter((item) => item.type !== 'PLACEHOLDER'),
    [items]
  )

  const firstItem = useMemo(() => {
    if (filteredItems.length === 0) {
      return null
    }

    return filteredItems[0]
  }, [filteredItems])

  // There're no items, show the empty state
  if (filteredItems.length === 0) {
    return <EmptyState viewType={viewType} />
  }

  // We need to fetch the doc and check if it's the onboarding doc
  if (filteredItems.length === 1) {
    return (
      <EmptyStateWithNoteCheck
        noteId={firstItem?.item.itemId}
        viewType={viewType}
      />
    )
  }

  return null
}
