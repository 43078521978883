import { templateStr } from '@motion/react-core/strings'
import { type TaskSchema } from '@motion/zod/client'

import { useI18N } from '~/global/contexts'
import { useWorkspaceFns } from '~/global/hooks'
import { type ReactNode } from 'react'

import { useBlockerTasks } from './use-blocker-tasks'

import { TOOLTIP_MESSAGES, UnfitTaskTooltip } from '../../components/tooltips'
import {
  getCanceledBlockers,
  getPastDueBlockers,
  getScheduledTypeInfo,
  getUnscheduledBlockers,
  PAST_DUE_TYPES,
  UNFIT_TYPES,
} from '../../utils/blocker-utils'

export function useTaskOverrideTooltip(
  task: TaskSchema | undefined
): ReactNode {
  const { pluralize } = useI18N()
  const { getStatusById } = useWorkspaceFns()

  const { blockedBy: blockedByTasks, isLoading } = useBlockerTasks({
    taskId: task?.id,
  })

  if (task == null || isLoading) {
    return undefined
  }

  const scheduleDateInfo = getScheduledTypeInfo(task)

  // If task unfit, check if special tooltip should be shown
  if (UNFIT_TYPES.includes(scheduleDateInfo.type)) {
    return <UnfitTaskTooltip task={task} />
  }

  // Check canceled blockers
  if (PAST_DUE_TYPES.includes(scheduleDateInfo.type)) {
    const canceledBlockers = getCanceledBlockers(blockedByTasks, getStatusById)
    if (canceledBlockers.length > 0) {
      return templateStr(TOOLTIP_MESSAGES.CANCELED_BLOCKERS, {
        blockerText: pluralize(canceledBlockers.length, 'blocker', 'blockers'),
        linkingVerb: pluralize(canceledBlockers.length, 'is', 'are'),
      })
    }
  }

  // Check unscheduled blockers
  const unscheduledBlockers = getUnscheduledBlockers(blockedByTasks)
  if (unscheduledBlockers.length > 0) {
    return templateStr(TOOLTIP_MESSAGES.UNSCHEDULED_BLOCKERS, {
      blockerText: pluralize(unscheduledBlockers.length, 'blocker', 'blockers'),
      linkingVerb: pluralize(unscheduledBlockers.length, 'is', 'are'),
    })
  }

  // Check past due blockers
  const pastDueBlockers = getPastDueBlockers(blockedByTasks)
  if (pastDueBlockers.length > 0) {
    return templateStr(TOOLTIP_MESSAGES.PAST_DUE_BLOCKERS, {
      blockerText: pluralize(pastDueBlockers.length, 'blocker', 'blockers'),
      linkingVerb: pluralize(pastDueBlockers.length, 'is', 'are'),
    })
  }

  return undefined
}
