import { byProperty, ordered } from '@motion/utils/array'

import { type FC } from 'react'

import { useStageTimelineContext } from './context'
import { StageTimelineBadge } from './stage-timeline-badge'
import { type StageGroup } from './utils'

type StageTimelineBadgesProps = {
  stageGroup: StageGroup
}

export const StageTimelineBadges: FC<StageTimelineBadgesProps> = ({
  stageGroup,
}) => {
  const { project } = useStageTimelineContext()
  // Sort stages to ensure active stage is first (will be rendered on top)
  const sortedStages = [...stageGroup.stages].toSorted(
    byProperty('stageDefinitionId', ordered([project.activeStageDefinitionId]))
  )

  return sortedStages.map((stage, index) => {
    return (
      <StageTimelineBadge
        key={stage.id}
        projectStage={stage}
        isStacked
        stackIndex={index}
      />
    )
  })
}
