import { templateStr } from '@motion/react-core/strings'
import { Button } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import {
  useTeamSubscription,
  useUtilizedSubscription,
} from '~/global/rpc/subscriptions'
import { DateTime } from 'luxon'

import { type CancelScreenProps } from '../components/types'
import { useUncancelSubscriptionAndUpdate } from '../rpc-hooks'

export const CancellationConfirmation = ({
  onCloseModal,
}: CancelScreenProps) => {
  const utilizedSubscription = useUtilizedSubscription()
  const teamSubscription = useTeamSubscription()

  const { isUncancelingSubscription, doUncancelSubscription } =
    useUncancelSubscriptionAndUpdate()

  const handleUncancelSubscription = async () => {
    await doUncancelSubscription()
    recordAnalyticsEvent(
      teamSubscription
        ? 'TEAM_BILLING_REACTIVATE'
        : 'INDIVIDUAL_BILLING_REACTIVATE'
    )
    onCloseModal()
  }

  return (
    <div className='w-[471px] h-[379px] rounded-lg border border-dropdown-border flex flex-col p-8 gap-8 items-center'>
      <div className='flex flex-col gap-3 text-center'>
        <h1 className='text-lg font-semibold text-semantic-neutral-text-default'>
          Your subscription is cancelled.
        </h1>
        {utilizedSubscription && (
          <p className='text-sm text-semantic-neutral-text-subtle'>
            {templateStr(
              "Your subscription will stay active until {{expiration}}. You won't be charged again after that.",
              {
                expiration: DateTime.fromSeconds(
                  utilizedSubscription.current_period_end
                ).toFormat('MMMM d, y'),
              }
            )}
          </p>
        )}
        <p className='text-sm text-semantic-error-text-default'>
          Your account including all tasks and projects will be permanently
          deleted in 90 days unless you subscribe again.
        </p>
      </div>
      <div>
        <Button variant='outlined' sentiment='neutral' onClick={onCloseModal}>
          Close
        </Button>
      </div>
      <div className='w-[407px] border-t border-semantic-neutral-border-subtle'></div>
      <div className='flex flex-col gap-3 text-center'>
        <p className='text-xs text-semantic-neutral-text-subtle'>
          Didn&apos;t mean to cancel?
        </p>
        <Button
          variant='muted'
          onClick={handleUncancelSubscription}
          loading={isUncancelingSubscription}
        >
          Reactivate subscription
        </Button>
      </div>
    </div>
  )
}
