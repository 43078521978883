import { createUseMutation, createUseQuery } from '@motion/rpc'
import { API } from '@motion/rpc-definitions'

export const useStartEmailVerification = createUseMutation(
  API.emailVerification.startEmailVerification
)

export const useVerifyEmailVerificationCode = createUseMutation(
  API.emailVerification.verifyEmailVerification
)

export const useGetEmailVerificationStatus = createUseQuery(
  API.emailVerification.getEmailVerificationStatus
)
