import { useOnce } from '@motion/react-core/hooks'
import { type NoteSchema } from '@motion/rpc-types'
import { Button } from '@motion/ui/base'
import { Sentry } from '@motion/web-base/sentry'

import { NoteSnapshotRollback } from './components/error-page-rollback'
import { VERSION_ERROR_MESSAGE } from './constants'

type NotesErrorPageProps = {
  noteId: NoteSchema['id']
  error: Error
  reset: () => void
}
export const NotesErrorPage = (props: NotesErrorPageProps) => {
  const { noteId, error, reset } = props

  const handleReload = () => {
    window.location.reload()
  }

  useOnce(() => {
    // eslint-disable-next-line no-console
    console.error(error)
    Sentry.captureException(error, {
      tags: { position: 'NotesErrorPage' },
    })
  })

  const isVersionError = error.message === VERSION_ERROR_MESSAGE
  return (
    <div className='h-full w-full flex items-center justify-center flex-col'>
      {isVersionError ? (
        <div>Your editor is out of date.</div>
      ) : (
        <div>An unexpected error occurred.</div>
      )}
      <div>Please reload to avoid collaboration issues.</div>

      <div className='mt-4 flex gap-2'>
        <Button onClick={handleReload}>Reload</Button>

        {__IS_DEV__ && !isVersionError && (
          <Button onClick={reset} sentiment='neutral'>
            Dismiss
          </Button>
        )}
      </div>

      {__IS_DEV__ && !isVersionError && (
        <div className='flex flex-col gap-2 mt-2 justify-center items-center'>
          <span className='text-semantic-neutral-text-disabled'>OR</span>
          <NoteSnapshotRollback noteId={noteId} />
        </div>
      )}
    </div>
  )
}
