import { ChevronDownOutline } from '@motion/icons'

import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  chevronClasses,
  inputContainerClasses,
  inputDisabledClasses,
  inputFocusClasses,
  inputMutedContainerClasses,
  inputTextClasses,
  inputUnselectedClasses,
} from '../../GeneralComponentStyles'

interface SelectButtonProps {
  disabled?: boolean
  children?: React.ReactNode
  placeholder?: string
  className?: string
  showChevron?: boolean
  muted?: boolean
  as?: 'button' | 'div' | React.ComponentType<any>
  onClick?: () => void
}

/**
 * @deprecated Use `PopoverButton` instead
 */
export const SelectButton =
  // eslint-disable-next-line react/display-name
  forwardRef<
    HTMLButtonElement,
    SelectButtonProps & Omit<JSX.IntrinsicElements['button'], 'onClick'>
  >(
    (
      {
        className = '',
        disabled = false,
        placeholder = '',
        children,
        onClick,
        muted = false,
        showChevron = true,
        as: Tag = 'button',
        ...props
      },
      ref
    ) => {
      return (
        <Tag
          ref={ref}
          {...props}
          className={twMerge(
            'relative h-8 w-full cursor-pointer rounded border pl-2 text-left',
            muted ? inputMutedContainerClasses : inputContainerClasses,
            disabled ? inputDisabledClasses : inputFocusClasses,
            showChevron ? 'pr-8' : 'pr-2',
            typeof Tag === 'string' && Tag === 'div' && 'flex',
            className
          )}
          onClick={onClick}
        >
          <span
            className={`flex items-center gap-2 truncate ${
              children ? inputTextClasses : inputUnselectedClasses
            }`}
          >
            {children || placeholder}
          </span>
          {showChevron && (
            <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
              <ChevronDownOutline
                className={chevronClasses}
                aria-hidden='true'
              />
            </span>
          )}
        </Tag>
      )
    }
  )
