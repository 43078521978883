import { type NoteSchema } from '@motion/rpc-types'
import { Sentry } from '@motion/web-base/sentry'

import { useDeleteNoteMentionsMutation } from '~/global/rpc/v2'
import { useCallback } from 'react'

export const useDeleteNoteMention = () => {
  const { mutateAsync: deleteNoteMentions } = useDeleteNoteMentionsMutation()

  return useCallback(
    async ({
      noteId,
      mentionId,
    }: {
      noteId: NoteSchema['id']
      mentionId: string
    }) => {
      if (!noteId || !mentionId) {
        throw new Error('Doc id and mention id are required.')
      }

      try {
        await deleteNoteMentions({
          id: noteId,
          keys: [mentionId],
        })
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            position: 'useDeleteNoteMention',
          },
        })
      }
    },
    [deleteNoteMentions]
  )
}
