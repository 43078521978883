import { useHasTreatment } from '@motion/web-common/flags'

import { useCurrentTier } from '~/areas/tiered-pricing/hooks'

export const useFooterTierUpsellData = () => {
  const isTieredPricingEnabled = useHasTreatment('tiered-pricing-v1')
  const currentTier = useCurrentTier()
  const showTierUpsell = isTieredPricingEnabled && currentTier === 'BASIC'
  return {
    isTieredPricingEnabled,
    currentTier,
    showTierUpsell,
  }
}
