import { WorkspaceSolid } from '@motion/icons'
import { type COLOR } from '@motion/shared/common'
import { ConfirmationModal, PopoverButton } from '@motion/ui/base'
import { ProjectPalette } from '@motion/ui/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import { useStageModalUrl } from '~/areas/flows/stage-modal/hooks'
import { type ModalTriggerComponentProps } from '~/areas/modals'
import { WorkspaceDropdown } from '~/global/components/dropdowns'
import { StageLabel } from '~/global/components/labels'
import { useStageDefinition, useWorkspaceById } from '~/global/hooks'
import { useCopyStageDefinition } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useUriByRouteId } from '~/routing'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'duplicate-stage-modal': {
      stageId: string
      workspaceId: string
    }
  }
}

export function DuplicateStageModal({
  close,
  stageId,
  workspaceId,
}: ModalTriggerComponentProps<'duplicate-stage-modal'>) {
  const stageDefinition = useStageDefinition(stageId)
  const initialWorkspaceId = stageDefinition?.workspaceId ?? workspaceId

  const navigate = useNavigate()
  const buildStageModalUrl = useStageModalUrl()

  const [currentWorkspaceId, setCurrentWorkspaceId] =
    useState(initialWorkspaceId)

  const currentWorkspace = useWorkspaceById(currentWorkspaceId)

  const getRouteUri = useUriByRouteId()

  const { mutateAsync: duplicateStageMutation, isPending: isLoading } =
    useCopyStageDefinition()

  const duplicateStage = async () => {
    if (!stageDefinition) {
      showErrorToast('Stage not found')
      return
    }

    recordAnalyticsEvent('COPY_STAGE_TEMPLATE', {
      numTasks: stageDefinition.tasks.length,
    })

    try {
      const newStage = await duplicateStageMutation({
        id: stageId,
        workspaceId: initialWorkspaceId,
        destinationWorkspaceId: currentWorkspaceId,
      })
      close()

      navigate(
        buildStageModalUrl({
          stage: 'edit',
          forWorkspace: currentWorkspaceId,
          stageId: newStage.id,
          pathName: getRouteUri('workspace-template-settings', {
            workspaceId: currentWorkspaceId,
          }),
        })
      )
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : 'An unexpected error occurred'

      showErrorToast(
        `There was an error duplicating the stage: ${errorMessage}`
      )

      Sentry.captureException(e, {
        tags: { position: 'duplicate-stage-modal' },
      })
    }
  }

  if (!currentWorkspace || !stageDefinition) {
    showErrorToast('Workspace or stage not found')
    close()
    return null
  }

  return (
    <ConfirmationModal
      action={{
        label: 'Duplicate stage',
        onAction: async () => {
          await duplicateStage()
        },
      }}
      closeLabel='Cancel'
      description={
        <div>
          <StageLabel value={stageDefinition} />
          <div className='flex flex-col items-start gap-4 self-stretch'>
            <WorkspaceDropdown
              onChange={(workspace) => {
                setCurrentWorkspaceId(workspace.id)
              }}
              selectedWorkspaceId={currentWorkspaceId}
            >
              <div className='w-full mt-5 mb-1'>
                <PopoverButton
                  icon={
                    <ProjectPalette
                      color={
                        'color' in currentWorkspace
                          ? (currentWorkspace.color as COLOR)
                          : 'gray'
                      }
                    >
                      <WorkspaceSolid />
                    </ProjectPalette>
                  }
                >
                  {currentWorkspace.name}
                </PopoverButton>
              </div>
            </WorkspaceDropdown>
          </div>
        </div>
      }
      loading={isLoading}
      onClose={close}
      title='Duplicate stage'
      visible
    />
  )
}
