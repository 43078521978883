import {
  type MeetingActionItemSchema,
  type TaskSchema,
} from '@motion/rpc-types'

import { createContext, useContext } from 'react'

export type ActionItemContextType = {
  initialActionItem: MeetingActionItemSchema
  modalVisible: boolean
  setModalVisible: (visible: boolean) => void
  closeModal: () => void

  task: TaskSchema | null
  taskIsLoading: boolean
  /**
   * Whether the task is not accessible to the user, due to the user not having access to the workspace
   */
  taskNotAccessible: boolean
  isRejected: boolean
}

export const ActionItemContext = createContext<
  ActionItemContextType | undefined
>(undefined)

export function useActionItemContext() {
  const context = useContext(ActionItemContext)
  if (!context) {
    throw new Error(
      'useActionItemContext must be used within an ActionItemProvider'
    )
  }

  return context
}
