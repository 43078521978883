import { PopoverTrigger, type PopoverTriggerProps } from '@motion/ui/base'
import { type TextVariable } from '@motion/ui-logic/pm/project'

import { NodeViewWrapper } from '@tiptap/react'

import { VariableLabel } from '../components/labels'
import { useFlowTemplateForm } from '../shared-form'

type NodeVariableLabelViewProps = {
  variable: TextVariable
  size?: 'xsmall' | 'small'
  renderPopover: PopoverTriggerProps['renderPopover']
}

export const NodeVariableLabelView = ({
  variable,
  size,
  renderPopover,
}: NodeVariableLabelViewProps) => {
  const {
    form: { watch },
  } = useFlowTemplateForm()
  const variableAbbreviations = watch('variableAbbreviations')
  return (
    <PopoverTrigger placement='bottom-start' renderPopover={renderPopover}>
      <NodeViewWrapper as='button' className='inline-flex align-middle'>
        <VariableLabel
          value={variable}
          size={size}
          truncateLabel
          abbreviation={variableAbbreviations?.get(variable.name)}
        />
      </NodeViewWrapper>
    </PopoverTrigger>
  )
}
