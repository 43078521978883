import { Tooltip } from '@motion/ui/base'

import { AssigneeDropdown } from '~/areas/project-management/components'
import { ModalFieldButton } from '~/areas/task-project/components'
import { UserBadge } from '~/global/components/badges'
import { UserLabel } from '~/global/components/labels'
import { useMember, useWorkspaceById } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { useActionItemForm } from '../../hooks'

export function ControlledAssigneeField() {
  const { form } = useActionItemForm()

  const { control, watch } = form

  const { field } = useController({
    name: 'assigneeUserId',
    control,
  })
  const workspaceId = watch('workspaceId')
  const selectedAssignee = useMember(field.value)
  const selectedAssigneeUser = selectedAssignee?.user ?? null

  const workspace = useWorkspaceById(workspaceId)

  const workspaceInaccessible = workspace == null

  return (
    <AssigneeDropdown
      selectedUserId={field.value}
      onChange={(assigneeId) => {
        field.onChange(assigneeId)
      }}
      workspaceId={workspaceId}
    >
      <Tooltip
        content={
          workspaceInaccessible
            ? 'Please choose a workspace that you have access to for updating the assignee.'
            : null
        }
        asChild
      >
        <ModalFieldButton disabled={workspaceInaccessible} label='Assignee'>
          <UserBadge value={selectedAssigneeUser} />
          <UserLabel value={selectedAssigneeUser} nameOnly />
        </ModalFieldButton>
      </Tooltip>
    </AssigneeDropdown>
  )
}
