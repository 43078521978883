import { type MeetingActionItemSchema } from '@motion/rpc-types'

import { ErrorBoundary } from '~/global/components'

import { ActionItemForm } from './action-item-form'
import {
  ActionItemActionButtons,
  ActionItemInlineSection,
  ActionItemTitleSection,
} from './components'
import { useActionItemForm } from './hooks'
import { ConnectedActionItemModal } from './modal'
import { ActionItemProvider, useActionItemContext } from './provider'

type ActionItemProps = {
  actionItem: MeetingActionItemSchema
}

export function ActionItem({ actionItem }: ActionItemProps) {
  return (
    <ErrorBoundary errorMessage='Error rendering action item'>
      <ActionItemProvider initialActionItem={actionItem}>
        <ActionItemForm>
          <ConnectedActionItemModal />
          <ActionItemBody />
        </ActionItemForm>
      </ActionItemProvider>
    </ErrorBoundary>
  )
}

function ActionItemBody() {
  return (
    <form className='flex justify-between items-center overflow-hidden gap-2'>
      <div className='flex flex-col gap-1 overflow-hidden flex-1 py-1'>
        <ActionItemTitleSection />
        <ActionItemInlineSection />
        <DirtyTag />
      </div>

      <ActionItemActionButtons />
    </form>
  )
}

function DirtyTag() {
  const { task } = useActionItemContext()
  const { form } = useActionItemForm()

  const isDirty = form.formState.isDirty

  // Don't show dirty tag if task is present or form is not dirty
  if (!isDirty || task) return null

  return (
    <span className='self-start ml-4 px-1.5 py-0.5 rounded-md text-[10px] text-semantic-neutral-text-subtle bg-semantic-neutral-bg-subtle'>
      Unsaved changes
    </span>
  )
}
