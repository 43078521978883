import {
  isSchedulingTask,
  isUnscheduledSchedulingTask,
} from '@motion/ui-logic/pm/task'

import { StatusField, type StatusFieldProps } from '~/areas/task-project/fields'
import { useController } from 'react-hook-form'

import { useTaskModalState } from '../contexts'
import { useTaskForm } from '../hooks'

export const ControlledStatusField = () => {
  const { form, disabledFields } = useTaskForm()
  const {
    initialTaskData: { task: initialTask },
  } = useTaskModalState()

  const { control, watch } = form

  const { field } = useController({
    name: 'statusId',
    control,
  })
  const workspaceId = watch('workspaceId')
  const isUnvisitedStage = watch('isUnvisitedStage')

  const isSchedulingTaskType = isSchedulingTask(initialTask)
  const shouldExcludeResolved = isUnscheduledSchedulingTask(initialTask)

  const onChange: StatusFieldProps['onChange'] = (statusId) => {
    field.onChange(statusId)
  }

  return (
    <StatusField
      value={field.value}
      onChange={onChange}
      workspaceId={workspaceId}
      disabled={disabledFields.has('statusId')}
      isUnvisitedStage={isUnvisitedStage}
      isScheduledTask={isSchedulingTaskType}
      shouldExcludeResolved={shouldExcludeResolved}
    />
  )
}
