import { FolderSolid } from '@motion/icons'
import { useSharedState } from '@motion/react-core/shared-state'
import { type IdFilterSchema } from '@motion/rpc-types'
import { groupInto } from '@motion/utils/array'

import { WorkspacesSearchableTreeMultiSelect } from '~/global/components/dropdowns'
import { AppWorkspaceContext } from '~/global/contexts'
import { useMemo } from 'react'

import { FilterItem } from './filter-item/filter-item'

import { useFieldFilter } from '../../../context'
import { buildIdFilter } from '../../../utils'
import { MultiSelectFilterValue } from '../../multi-select-filter-value'
import { type DropdownFilterProps } from '../../types'

export const ConnectedFolderFilter = (props: DropdownFilterProps) => {
  const [filter, setFilter] = useFieldFilter(props.target, 'folderIds')
  const ids = filter?.value ?? []
  const [ctx] = useSharedState(AppWorkspaceContext)

  const items = useMemo(() => {
    const items = ctx.folders.all.map((x) => ({
      ...x,
      workspaceId: x.targetId,
    }))

    const groups = groupInto(items, (p) => p.targetId).map((g) => ({
      key: g.key,
      items: g.items,
    }))

    return {
      all: items,
      groups,
    }
  }, [ctx.folders.all])

  const onSelect = (values: string[]) => {
    const selected = values
      .map((id) => items.all.find((u) => id === u.id))
      .filter(Boolean)

    setFilter(buildIdFilter(selected, filter as IdFilterSchema))
  }

  return (
    <FilterItem
      label='Folder'
      canInvert
      inverted={filter?.inverse}
      onInvertChanged={(invert) => {
        if (filter == null) return
        setFilter({ ...filter, inverse: invert })
      }}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      onDismiss={() => setFilter(null)}
      renderDropdown={({ close }) => {
        return (
          <WorkspacesSearchableTreeMultiSelect
            selectedIds={ids}
            hideNoProject
            leafNodeType='FOLDER'
            onChange={onSelect}
            closePopover={close}
            showExplicitSelectionOnly
          />
        )
      }}
    >
      <MultiSelectFilterValue
        Icon={FolderSolid}
        type='folders'
        ids={ids}
        labelSize='normal'
      />
    </FilterItem>
  )
}
