import { useContext } from 'react'

import { StageTaskCardFieldsContext } from './stage-task-card-fields-context'

export function useStageTaskCardFields() {
  const context = useContext(StageTaskCardFieldsContext)
  if (!context) {
    throw new Error(
      'useStageTaskCardFields must be used within a StageTaskCardFieldsProvider'
    )
  }
  return context
}
