import { AiActionItemSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { type MeetingActionItemSchema } from '@motion/rpc-types'
import { PopoverTrigger, Tooltip } from '@motion/ui/base'
import { groupInto } from '@motion/utils/array'
import { values } from '@motion/utils/object'
import { Sentry } from '@motion/web-base/sentry'

import { ErrorBoundary } from '~/global/components'
import { useMeetingInsightsQuery } from '~/global/rpc/v2'
import { forwardRef, type ReactNode } from 'react'

import { ActionItem } from './action-item'

type AIMeetingNotesLinkRowProps = {
  hasSearch: boolean
  children: ReactNode
}

export const AIMeetingNotesLinkRow = forwardRef<
  HTMLDivElement,
  AIMeetingNotesLinkRowProps
>(function AIMeetingNotesLinkRow({ hasSearch, children }, ref) {
  return (
    <ErrorBoundary errorMessage='Failed to load AI Notetaker tab item.'>
      <div
        ref={ref}
        className='flex items-center [&>a:first-child]:flex-1 gap-1'
      >
        {children}

        {!hasSearch && <UntriagedActionItemsButton />}
      </div>
    </ErrorBoundary>
  )
})

function UntriagedActionItemsButton() {
  const { data } = useMeetingInsightsQuery({
    filters: {
      startTime: {
        operator: 'defined-relative',
        // We only care about the last 7 days
        name: 'last-7-days',
      },
      host: {
        operator: 'in',
        value: ['@me'],
      },
      meetingBotStatus: {
        operator: 'in',
        value: ['COMPLETED'],
      },
    },
  })

  if (!data) return null

  const groupedActionItems = groupInto(
    values(data.models.meetingActionItems) ?? [],
    (actionItem) => actionItem.meetingInsightsId
  ).flatMap((group) => {
    const meetingInsights = data.models.meetingInsights[group.key]

    if (!meetingInsights || !meetingInsights.eventData) {
      Sentry.captureException(
        'Error with the meeting insights when trying to group action items',
        {
          extra: {
            group: JSON.stringify(group),
            data: JSON.stringify(data),
          },
          tags: {
            position: 'ActionItemsContainer',
          },
        }
      )

      return []
    }

    const untriagedActionItems = group.items.filter(
      (item) =>
        item.triagedByUserId == null && item.potentialDuplicateTaskId == null
    )

    if (untriagedActionItems.length === 0) {
      return []
    }

    return {
      meetingInsightsId: group.key,
      title: meetingInsights.eventData.title,
      actionItems: untriagedActionItems,
    }
  })

  const totalUntriagedActionItems = groupedActionItems.flatMap(
    (g) => g.actionItems
  ).length

  if (totalUntriagedActionItems === 0) {
    return null
  }

  return (
    <Tooltip
      renderContent={() => (
        <p>
          {templateStr('You have {{count}} untriaged action {{text}}', {
            count: totalUntriagedActionItems,
            text: totalUntriagedActionItems === 1 ? 'item' : 'items',
          })}
        </p>
      )}
    >
      <PopoverTrigger
        placement='right-start'
        offset={20}
        renderPopover={() => (
          <UntriagedActionItemsList actionItemsGroup={groupedActionItems} />
        )}
      >
        <button
          onClick={(e) => e.stopPropagation()}
          className='border-button-warning-outlined-border-default border-2 rounded-md bg-tag-yellow-subtle-bg flex gap-1 items-center ml-auto pr-1.5 pl-1 py-0.5'
        >
          <AiActionItemSolid className='size-3.5 text-semantic-warning-icon-default' />

          <span className='text-semantic-warning-text-default font-light text-xs'>
            {totalUntriagedActionItems}
          </span>
        </button>
      </PopoverTrigger>
    </Tooltip>
  )
}

type UntriagedActionItemsListProps = {
  actionItemsGroup: {
    meetingInsightsId: string
    title: string
    actionItems: MeetingActionItemSchema[]
  }[]
}

function UntriagedActionItemsList({
  actionItemsGroup,
}: UntriagedActionItemsListProps) {
  return (
    <div className='w-screen max-w-2xl shadow-sm rounded-lg overflow-hidden flex flex-col bg-semantic-neutral-surface-raised-bg-default max-h-[600px]'>
      <div className='flex items-center rounded-t-lg border-b border-semantic-neutral-border-subtle px-4 py-3'>
        <h3 className='text-semantic-neutral-text-default font-semibold'>
          Pending Action Items
        </h3>
      </div>

      <div className='flex flex-col gap-6 p-4 rounded-b-lg overflow-y-auto'>
        {actionItemsGroup.map((group) => {
          return (
            <div key={group.meetingInsightsId} className='flex flex-col gap-4'>
              <h4 className='text-semantic-neutral-text-default font-semibold'>
                {group.title}
              </h4>

              <div className='flex flex-col gap-2 min-w-0'>
                {group.actionItems
                  .filter((item) => !item.triagedByUserId)
                  .map((actionItem) => (
                    <ActionItem key={actionItem.id} actionItem={actionItem} />
                  ))}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
