import { isPending } from '@motion/rpc-cache'
import type { NormalTaskSchema } from '@motion/rpc-types'

import { useWorkspaceFns } from '~/global/hooks'
import { useCallback, useRef } from 'react'

import { useSetupProjectForm } from './use-setup-project-form'
import { useUpdateTasksFn } from './use-update-tasks-fn'

/**
 * Used to cancel all tasks in a stage (which then cancels the stage in reconciliation)
 * or restore the original status of tasks
 */
export const useCancelTasksFn = () => {
  const { getWorkspaceCanceledStatus } = useWorkspaceFns()
  const {
    form: { getValues },
  } = useSetupProjectForm()
  const updateTasks = useUpdateTasksFn()

  // Store original status IDs for each task
  const originalStatusIds = useRef<Record<string, string>>({})

  const cancelTasks = useCallback(
    (tasks: NormalTaskSchema[]) => {
      const workspaceId = getValues('workspaceId')
      const canceledStatus = getWorkspaceCanceledStatus(workspaceId)
      if (isPending(canceledStatus)) {
        return
      }

      // Store original status IDs before canceling
      tasks.forEach((task) => {
        originalStatusIds.current[task.id] = task.statusId
      })

      updateTasks(tasks, () => ({
        statusId: canceledStatus.id,
      }))
    },
    [getValues, getWorkspaceCanceledStatus, updateTasks]
  )

  const uncancelTasks = useCallback(
    (tasks: NormalTaskSchema[]) => {
      // Only update tasks that have a stored original status
      const tasksToUpdate = tasks.filter(
        (task) => originalStatusIds.current[task.id] != null
      )

      if (tasksToUpdate.length > 0) {
        updateTasks(tasksToUpdate, (task) => ({
          statusId: originalStatusIds.current[task.id],
        }))
      }
    },
    [updateTasks]
  )

  return {
    cancelTasks,
    uncancelTasks,
  }
}
