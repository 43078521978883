import { Button, TextHeader } from '@motion/ui/base'

export type OpenDesktopAppPageProps = {
  imgUrl: string
  onDismiss: () => void
}

export const OpenDesktopAppPage = ({
  imgUrl,
  onDismiss,
}: OpenDesktopAppPageProps) => {
  return (
    <div className='bg-semantic-neutral-surface-bg-default flex flex-col items-center justify-center gap-12 fixed top-0 left-0 w-screen h-screen'>
      <img src={imgUrl} className='h-[160px] w-[160px]' alt='Motion logo' />
      <div className='flex flex-col gap-5'>
        <TextHeader size='2xl'>Open in desktop app</TextHeader>
        <Button variant='link' sentiment='primary' onClick={onDismiss}>
          Open here instead
        </Button>
      </div>
    </div>
  )
}
