import { NOOP_FUNCTION } from '@motion/utils/function'

import React, { useCallback, useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

type WidgetModalProps = {
  children: React.ReactNode
  visibilityHandler?: (value: boolean) => void
  backgroundClickDismiss?: boolean
  ignoreEscapeDismiss?: boolean
  warnBeforeDismiss?: boolean
  className?: string
  stopPropagation?: boolean
}

export const WidgetModal = React.forwardRef<HTMLDivElement, WidgetModalProps>(
  (
    {
      children,
      visibilityHandler = NOOP_FUNCTION,
      backgroundClickDismiss = false,
      ignoreEscapeDismiss = false,
      warnBeforeDismiss = false,
      className = '',
      stopPropagation = false,
    },
    ref
  ) => {
    const hasMouseDownRef = useRef<boolean>(false)

    const mouseDownHandler = useCallback(
      (e: React.MouseEvent) => {
        const targetClass = (e.target as HTMLElement).className
        if (
          backgroundClickDismiss &&
          targetClass &&
          targetClass.toString().includes('widget-modal-click-handler')
        ) {
          hasMouseDownRef.current = true
        }
      },
      [backgroundClickDismiss]
    )

    const mouseUpHandler = useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const targetClass = (e.target as HTMLElement).className
        if (
          backgroundClickDismiss &&
          targetClass &&
          targetClass.toString().includes('widget-modal-click-handler') &&
          hasMouseDownRef.current
        ) {
          if (
            warnBeforeDismiss &&
            !window.confirm('Are you sure you want to close this?')
          ) {
            return
          }
          if (stopPropagation) {
            e.preventDefault()
            e.stopPropagation()
          }
          visibilityHandler(false)
        }
        hasMouseDownRef.current = false
      },
      [
        backgroundClickDismiss,
        visibilityHandler,
        warnBeforeDismiss,
        stopPropagation,
      ]
    )

    const keyHandler = useCallback(
      (e: React.KeyboardEvent) => {
        if (e.key === 'Escape') {
          if (
            ignoreEscapeDismiss ||
            (warnBeforeDismiss &&
              !window.confirm('Are you sure you want to close this?'))
          ) {
            return
          }
          e.preventDefault()
          visibilityHandler(false)
        }
      },
      [visibilityHandler, warnBeforeDismiss, ignoreEscapeDismiss]
    )

    useEffect(() => {
      // @ts-expect-error - The type of keyHandler is fine
      window.addEventListener('keydown', keyHandler, true)
      // @ts-expect-error - The type of keyHandler is fine
      return () => window.removeEventListener('keydown', keyHandler, true)
    }, [keyHandler])

    return (
      <div
        className={twMerge(
          'widget-modal-click-handler flex h-full w-full items-center justify-center',
          className
        )}
        onMouseUp={mouseUpHandler}
        onKeyDown={keyHandler}
        ref={ref}
        onMouseDown={mouseDownHandler}
      >
        {children}
      </div>
    )
  }
)
WidgetModal.displayName = 'WidgetModal'
