import { StartDateSolid } from '@motion/icons'
import { type StageDefinitionSchema } from '@motion/rpc-types'
import { type DeadlineInterval } from '@motion/shared/flows'

import {
  DurationText,
  RelativeDateDropdown,
  RelativeDateLabel,
} from '~/areas/flows/components'
import {
  DateTriggerButton,
  ModalFieldButton,
} from '~/areas/task-project/components'
import { useController } from 'react-hook-form'

import { useTaskDefinitionModalRelativeDateUpdater } from '../hooks'
import { useTaskDefinitionForm } from '../use-task-definition-form'

type ControlledStartDateFieldProps = {
  color: StageDefinitionSchema['color']
  stageDuration: DeadlineInterval | undefined
}

export const ControlledStartDateField = ({
  color,
  stageDuration,
}: ControlledStartDateFieldProps) => {
  const {
    form: { control, watch },
  } = useTaskDefinitionForm()

  const { field: startRelativeIntervalField } = useController({
    name: 'startRelativeInterval',
    control,
  })
  const taskDuration = watch('duration')

  const { onChange } = startRelativeIntervalField
  const value = watch('startRelativeInterval') // value from field not reactive

  const changeHandler = useTaskDefinitionModalRelativeDateUpdater(
    onChange,
    value,
    stageDuration,
    'start'
  )

  const { referenceType, duration } = value

  if (taskDuration === 0) {
    return null
  }

  if (!stageDuration) {
    return <FallbackStartDateField />
  }

  return (
    <RelativeDateDropdown
      color={color}
      type='start'
      value={value}
      onChange={changeHandler}
      stageDuration={stageDuration}
    >
      <ModalFieldButton label='Start date'>
        <StartDateSolid />
        <RelativeDateLabel value={referenceType} size='small' color={color} />
        <DurationText duration={duration} />
      </ModalFieldButton>
    </RelativeDateDropdown>
  )
}

const FallbackStartDateField = () => {
  return (
    <DateTriggerButton
      label='Start date'
      icon={<StartDateSolid />}
      disabled
      date={null}
      placeholder='Stage Start'
    />
  )
}
