import { useSharedStateSendOnly } from '@motion/react-core/shared-state'
import { API } from '@motion/rpc-definitions'
import { matches as deepMatch } from '@motion/utils/object'
import { makeLog } from '@motion/web-base/logging'
import {
  buildSubscriptionState,
  SubscriptionStateKey,
} from '@motion/web-common/subscriptions'
import { useOnWebsocketEvent } from '@motion/web-common/websockets'
import { type CombinedSubscriptionSchema } from '@motion/zod/client'

import { useQueryClient } from '@tanstack/react-query'
import api from '~/chromeApi/chromeApiContentScript'
import { useIndividualAndTeamSubscription } from '~/global/rpc'
import { useOnLocalStorageChange } from '~/localServices/storage/useOnLocalStorageChange'
import { type MotionLocalStorageResult } from 'local-storage'
import { useEffect } from 'react'

const log = makeLog('sync-subscription')

export const SyncSubscription = () => {
  const subscriptionFetch = useIndividualAndTeamSubscription(undefined, {
    notifyOnChangeProps: ['data', 'dataUpdatedAt', 'status'],
  })
  const setSubscription = useSharedStateSendOnly(SubscriptionStateKey)
  const client = useQueryClient()

  log.debug('render', subscriptionFetch.status, subscriptionFetch.data)
  useEffect(() => {
    log.debug('data-changed', {
      status: subscriptionFetch.status,
      data: subscriptionFetch.data,
    })
    if (subscriptionFetch.status !== 'success') return

    const data = subscriptionFetch.data
    const state = buildSubscriptionState(data)

    setSubscription(state)
    void onIndividualAndTeamSubscriptionChanged(data)
  }, [setSubscription, subscriptionFetch.data, subscriptionFetch.status])

  useOnWebsocketEvent('user.subscription.refresh', (d) => {
    void client.invalidateQueries({
      queryKey: API.subscriptions.getIndividualAndTeamSubscription.key(),
    })
  })

  useOnLocalStorageChange(
    ['stripeSubscription', 'subscriptionType', 'subscriptionFetchError'],
    (data) => {
      const current = client.getQueryData<CombinedSubscriptionSchema>(
        API.subscriptions.getIndividualAndTeamSubscription.key()
      )

      if (!shouldInvalidate(data, current)) return

      log.debug('invalidate', { data })
      void client.invalidateQueries({
        queryKey: API.subscriptions.queryKeys.individualAndTeam,
      })
    }
  )

  return null
}

function shouldInvalidate(
  data: MotionLocalStorageResult<'stripeSubscription' | 'subscriptionType'>,
  current: CombinedSubscriptionSchema | undefined
) {
  if (data.subscriptionType === undefined) return false
  if (current == null) return true

  const combined = current.combined?.subscription ?? null
  const legacy = data.stripeSubscription

  return !deepMatch(legacy, combined)
}

async function onIndividualAndTeamSubscriptionChanged(
  subscriptionsResponse: CombinedSubscriptionSchema
) {
  const teamSub = subscriptionsResponse.team
  if (teamSub) {
    const isTeamSubActive = teamSub.active
    const teamSubDetails = teamSub.details

    if (teamSubDetails && isTeamSubActive && 'subscription' in teamSubDetails) {
      await api.storage.local.set({
        teamSubscription: teamSubDetails.subscription,
      })
    }
  }

  const individualSub = subscriptionsResponse.individual
  if (individualSub) {
    const isIndividualSubActive = individualSub.active
    const individualSubDetails = individualSub.details
    if (
      individualSubDetails &&
      isIndividualSubActive &&
      'subscription' in individualSubDetails
    ) {
      await api.storage.local.set({
        individualSubscription: individualSubDetails.subscription,
      })
    }
  }
}
