import { NotesColorIcon } from '@motion/ui/base'
import { FolderColoredIcon, WorkspaceColoredIcon } from '@motion/ui/project'

import { getViewIcon } from '~/areas/project-management/pages/pm-v3/components'
import { ConnectedProjectBadge } from '~/global/components/badges'

import { type SortableWorkspacesTreeviewItem } from '../types'

export type TreeviewItemIconProps = {
  item: SortableWorkspacesTreeviewItem['item']
  className?: string
}

export function TreeviewItemIcon({ item, className }: TreeviewItemIconProps) {
  switch (item.type) {
    case 'INDIVIDUAL_WORKSPACE':
    case 'TEAM_WORKSPACE':
      return <WorkspaceColoredIcon color={item.color} className={className} />

    case 'FOLDER':
      return (
        <FolderColoredIcon
          color={item.color}
          open={item.expanded}
          className={className}
        />
      )

    case 'PROJECT':
      return (
        <ConnectedProjectBadge
          id={item.itemId}
          hasChildren={item.isContainer && item.itemCount > 1}
          className={className}
          hideTooltip
        />
      )

    case 'NOTE':
      return (
        <NotesColorIcon
          color={item.color}
          multiple={item.isContainer}
          className={className}
        />
      )

    case 'VIEW':
      const Icon = getViewIcon(item.view)

      return <Icon className={className} />

    default:
      return null
  }
}
