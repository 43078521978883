import { AiActionItemSolid } from '@motion/icons'

export function ActionItemHeader() {
  return (
    <header className='flex gap-1 text-semantic-purple-text-default items-center'>
      <AiActionItemSolid className='size-3 text-semantic-purple-icon-default' />
      <p>Action Item</p>
    </header>
  )
}
