import { StageSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { type StageContext } from '@motion/shared/activity-feed'
import { type StageDefinitionSchema } from '@motion/zod/client'

import { StageLabel } from '~/global/components/labels'

const textMapping = {
  add: 'added stage {{stageTo}}',
  remove: 'removed stage {{stageFrom}}',
  update: 'changed stage from {{stageFrom}} to {{stageTo}}',
}

export function handleStageDefinition(
  oldContext: StageContext | undefined,
  newContext: StageContext | undefined
) {
  // newContext will be undefined when a task moves from a flow project to a normal project
  // oldContext will be undefined when a task moves from a normal project to a flow project
  const textKey: keyof typeof textMapping =
    oldContext == null && newContext != null
      ? 'add'
      : oldContext != null && newContext == null
        ? 'remove'
        : 'update'
  const text = textMapping[textKey]

  return {
    icon: <StageSolid />,
    children: templateStr(text, {
      stageFrom:
        oldContext != null ? (
          <StageLabel
            value={{
              ...oldContext,
              color: oldContext.color as StageDefinitionSchema['color'],
            }}
            nameOnly
            size='small'
          />
        ) : (
          'none'
        ),
      stageTo:
        newContext != null ? (
          <StageLabel
            value={{
              ...newContext,
              color: newContext.color as StageDefinitionSchema['color'],
            }}
            nameOnly
            size='small'
          />
        ) : (
          'none'
        ),
    }),
  }
}
