import { merge } from '@motion/utils/core'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'
import { Sentry } from '@motion/web-base/sentry'

import { useCallback } from 'react'
import {
  generatePath,
  matchPath,
  type NavigateOptions,
  useMatches,
  useNavigate,
} from 'react-router'

import { type NavigateByIdParams } from './navigate-by-id-params'
import { useMatchedRouteDataFn, useMatchesFn } from './use-matched-route-data'

import { useRoutes } from '../components/context'

const DEFAULT_OPTIONS = { noDefaults: false }

type OptionalNavigateByIdParamsKeys = {
  [TKey in keyof NavigateByIdParams as undefined extends NavigateByIdParams[TKey]
    ? TKey
    : never]: string
}

type NavigateByRouteIdFn = {
  <TRouteId extends keyof OptionalNavigateByIdParamsKeys>(
    id: TRouteId,
    params?: NavigateByIdParams[TRouteId],
    opts?: NavigateOptions
  ): void

  <TRouteId extends keyof NavigateByIdParams>(
    id: TRouteId,
    params: NavigateByIdParams[TRouteId],
    opts?: NavigateOptions
  ): void
  (id: string, params: Record<string, string>, opts?: NavigateOptions): void
}

export const useNavigateByRouteId = (
  opts: Options = DEFAULT_OPTIONS
): NavigateByRouteIdFn => {
  const getUri = useUriByRouteId(opts)
  const navigate = useNavigate()
  return useCallback(
    (
      id: string,
      params: Record<string, string> = READONLY_EMPTY_OBJECT,
      opts?: NavigateOptions
    ) => {
      const uri = getUri(id, params)
      if (uri == null) return
      navigate(uri, opts)
    },
    [getUri, navigate]
  )
}

export type UriByRouteIdFn = {
  <TRouteId extends keyof OptionalNavigateByIdParamsKeys>(
    id: TRouteId,
    params?: NavigateByIdParams[TRouteId],
    query?: Record<string, string>
  ): string

  <TRouteId extends keyof NavigateByIdParams>(
    id: TRouteId,
    params: NavigateByIdParams[TRouteId],
    query?: Record<string, string>
  ): string
  (
    id: string,
    params: Record<string, string>,
    query?: Record<string, string>
  ): string
}

type Options = {
  noDefaults?: boolean
}

export const useUriByRouteId = (
  opts: Options = DEFAULT_OPTIONS
): UriByRouteIdFn => {
  const getMatchedRouteData = useMatchedRouteDataFn()
  const findRoute = useRoute()

  return useCallback(
    (
      id: string,
      params: Record<string, string> = READONLY_EMPTY_OBJECT,
      query?: Record<string, string>
    ) => {
      const target = findRoute(id)
      if (target == null) {
        Sentry.captureEvent({
          level: 'warning',
          message: 'Invalid route id',
          extra: {
            routeId: id,
            params,
            query,
          },
        })
        return `/web/404`
      }

      const template =
        (opts.noDefaults
          ? target.handle.template
          : target?.handle?.routing?.template) ?? target?.handle.template

      const localParams = matchPath(
        template.replace(/\?/g, ''),
        window.location.pathname
      )

      const data = getMatchedRouteData()

      const allParams = opts.noDefaults
        ? params
        : merge(
            {},
            opts.noDefaults ? {} : target?.handle?.routing?.defaults,
            localParams?.params,
            data.params,
            params
          )

      return buildUrlWithQuery(generatePath(template, allParams), query)
    },
    [findRoute, getMatchedRouteData, opts.noDefaults]
  )
}

function buildUrlWithQuery(
  pathname: string,
  search?: Record<string, string>
): string {
  if (!search) return pathname

  const searchParams = new URLSearchParams(search)

  return searchParams.size > 0
    ? `${pathname}?${searchParams.toString()}`
    : pathname
}

export const useRoute = () => {
  const routes = useRoutes()
  const getMatches = useMatchesFn()

  return useCallback(
    (id: string) => {
      const routeId =
        id === 'parent'
          ? // @ts-expect-error - will be fine
            getMatches().findLast((m) => m.handle?.routing?.relative === true)
              ?.id
          : id
      if (!routeId) return null

      const route = routes.find((x) => x.id === routeId)
      if (route == null) return null
      return route
    },
    [getMatches, routes]
  )
}

export const useMatchId = (): string | undefined => {
  const matches = useMatches()

  // @ts-expect-error - will be fine
  return matches.findLast((m) => m.handle?.routing?.relative === true)?.id
}
