import { findDefaultStatus } from '@motion/shared/common'
import { type Tier } from '@motion/ui-logic/tiered-pricing'
import { parseDate } from '@motion/utils/dates'
import { Sentry } from '@motion/web-base/sentry'
import {
  type PersistedOnboardingState,
  type PMOnboardingExtraData,
} from '@motion/web-common/onboarding'
import {
  type DaysOfWeekSchema,
  type ProjectSchema,
  type TasksV2CreateSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { useCreateTask } from '~/areas/tasks/hooks'
import {
  useCreateWorkspace,
  useCreateWorkspaceMember,
  useUpdateWorkspace,
  useWorkspaceFns,
} from '~/global/hooks'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import api from '../../../../chromeApi/chromeApiContentScript'
import {
  createTeam,
  fetchTeam,
} from '../../../../state/projectManagement/teamThunks'
import { store } from '../../../../state/proxy'
import { checkTeamEligibility } from '../../../../state/teamSlice'
import { trackMissingWorkspaceError } from '../../v2/shared/onboardingAnalytics'
import {
  IndividualOrTeam,
  type OnboardingRecurringTask,
  type OnboardingScreen,
  type OnboardingType,
  type Workspace,
} from '../../v2/types'
import { type LegacyOnboardingState } from '../types'

export async function checkGuestEligibility(email: string) {
  return await store.dispatch(checkTeamEligibility(email)).unwrap()
}

export function useCreateTeamWithInvites() {
  const { createWorkspaceMember } = useCreateWorkspaceMember()

  return useCallback(
    async ({
      teamName,
      emails,
      isMonthly,
      workspaceId,
      seats,
      tier,
    }: {
      teamName: string
      emails: string[]
      isMonthly: boolean
      workspaceId?: string
      seats: number
      tier?: Tier
    }) => {
      const response = await store
        .dispatch(
          createTeam({
            invited: emails,
            name: teamName,
            seats,
            isMonthly,
            tier,
          })
        )
        .unwrap()
      const userId = store.getState().user.id
      const { team } = await store.dispatch(fetchTeam()).unwrap()

      const inviteeIds =
        team.members
          ?.filter((member) => member.user.id !== userId)
          .map((member) => member.userId) ?? []

      if (workspaceId) {
        inviteeIds.forEach((inviteeId) => {
          createWorkspaceMember({
            userId: inviteeId,
            workspaceId,
          })
        })
      }

      return response
    },
    [createWorkspaceMember]
  )
}

export function useCreateOrUpdateWorkspace() {
  const { createWorkspace } = useCreateWorkspace()
  const { updateWorkspace } = useUpdateWorkspace()

  return useCallback(
    async (workspace: Workspace) => {
      if (!workspace.workspaceName) {
        throw new Error(`Please provide a workspace name.`)
      }

      // Update existing workspace instead of creating a new one
      if (workspace.workspaceId) {
        return await updateWorkspace({
          name: workspace.workspaceName,
          workspaceId: workspace.workspaceId,
        })
      }

      return createWorkspace({
        name: workspace.workspaceName,
        // TODO - confirm this is the right default
        type: IndividualOrTeam.INDIVIDUAL,
        teamId: null,
      })
    },
    [createWorkspace, updateWorkspace]
  )
}

export function useCreateOrUpdateTasks() {
  const createTask = useCreateTask()
  const { getWorkspaceStatuses } = useWorkspaceFns()

  return useCallback(
    async (workspace: Workspace) => {
      const tasks = workspace.tasks.filter((task) => task.description)

      if (tasks.length === 0) {
        throw new Error('Please create at least one task for your project.')
      }

      const workspaceId = workspace.workspaceId

      if (!workspaceId) {
        throw new Error(
          'Something went wrong when creating your workspace. Please go back and create a new workspace.'
        )
      }

      const taskStatuses = getWorkspaceStatuses(workspaceId)
      const defaultStatus = findDefaultStatus(taskStatuses)
      const userId = store.getState().user.id

      const response = await Promise.all(
        tasks.map(async (task) => {
          try {
            const taskToCreate: TasksV2CreateSchema['data'] = {
              ...task,
              type: 'NORMAL',
              assigneeUserId: userId,
              deadlineType: 'SOFT',
              description: '',
              dueDate: parseDate(task.deadlineISO).endOf('day').toISO(),
              duration: task.durationMinutes,
              name: task.description,
              priorityLevel: 'MEDIUM',
              projectId: workspace.projectId,
              scheduleId: 'work',
              startDate: DateTime.now().toISODate(),
              statusId: defaultStatus?.id,
              isAutoScheduled: true,
              workspaceId: workspace.workspaceId ?? '',
            }

            const teamTask = await createTask(taskToCreate)

            return teamTask
          } catch (error) {
            Sentry.captureException(error, {
              tags: {
                type: 'onboarding',
                position: 'createOrUpdateTasks',
                task: task.description,
                taskDuration: task.durationMinutes,
                workspaceId,
                userId,
              },
            })
            throw error
          }
        })
      )
      return response
    },
    [createTask, getWorkspaceStatuses]
  )
}

export function useCreateRecurringTasks() {
  const createTask = useCreateTask()

  const { getMyTasksWorkspace, getWorkspaceStatuses } = useWorkspaceFns()

  return useCallback(
    async (tasks: OnboardingRecurringTask[]) => {
      const workspaceId = getMyTasksWorkspace()?.id

      if (!workspaceId) {
        void trackMissingWorkspaceError()
        throw new Error(
          `Something went wrong - please refresh the page or try again later`
        )
      }

      const userId = store.getState().user.id
      const taskStatuses = getWorkspaceStatuses(workspaceId)

      const statusId = findDefaultStatus(taskStatuses)?.id

      if (!statusId) {
        void trackMissingWorkspaceError()
        throw new Error(
          `Something went wrong - please refresh the page or try again later`
        )
      }

      const tomorrow = DateTime.now().plus({ days: 1 })
      const response = await Promise.all(
        tasks.map(async (task) => {
          try {
            const createdTask = await createTask({
              ...task,
              type: 'RECURRING_TASK',
              assigneeUserId: userId,
              priorityLevel: 'MEDIUM',
              recurrenceMeta: 'specific_day',
              scheduleId: 'custom',
              startingOn: tomorrow.toISO(),
              statusId,
              isAutoScheduled: true,
              workspaceId,
              days: task.days as DaysOfWeekSchema[],
              ignoreWarnOnPastDue: true,
            })

            return createdTask
          } catch (error) {
            Sentry.captureException(error, {
              tags: {
                type: 'onboarding',
                position: 'createRecurringTasks',
                step: 'createRecurringTask',
                taskName: task?.name,
                taskDuration: task?.duration,
              },
            })
            throw error
          }
        })
      )

      return response
    },
    [createTask, getMyTasksWorkspace, getWorkspaceStatuses]
  )
}

const onboardingLatestScreenKey = 'onboardingLatestScreen'
const onboardingTypeKey = 'onboardingType'
const onboardingExtraDataKey = 'onboardingExtraData'
const stripeSubscriptionKey = 'stripeSubscription'

export function calculateInitialOnboardingScreen(
  state: Record<string, any>,
  stripeSubscription: unknown
): OnboardingScreen | undefined {
  const screen = state[onboardingLatestScreenKey]
  if (screen) return screen as OnboardingScreen
  if (stripeSubscription) return '1_min_video'
  return undefined
}

export function useCreateGetOnboardingState() {
  const { getWorkspaceById, getWorkspaceProjectById } = useWorkspaceFns()

  return useCallback(
    (getState: () => Promise<PersistedOnboardingState>) => () =>
      getOnboardingState(getState, getWorkspaceById, getWorkspaceProjectById),
    [getWorkspaceById, getWorkspaceProjectById]
  )
}

async function getOnboardingState(
  getState: () => Promise<PersistedOnboardingState>,
  getWorkspaceById: (workspaceId: string) => WorkspaceSchema | undefined,
  getWorkspaceProjectById: (
    projectId: ProjectSchema['id'] | null | undefined
  ) => ProjectSchema | undefined
): Promise<LegacyOnboardingState> {
  const { stripeSubscription } = await api.storage.local.get(
    stripeSubscriptionKey
  )

  const onboardingState = await getState()

  const initialWorkspace: Omit<Workspace, 'tasks'> = {
    projectId: '',
    projectName: '',
    workspaceId: '',
    workspaceName: '',
  }

  const onboardingType = onboardingState[onboardingTypeKey] as
    | OnboardingType
    | undefined

  const onboardingScreen = calculateInitialOnboardingScreen(
    onboardingState,
    stripeSubscription
  )

  const onboardingExtraData = onboardingState[onboardingExtraDataKey] as
    | PMOnboardingExtraData
    | undefined

  if (onboardingExtraData?.workspaceId) {
    const workspace = getWorkspaceById(onboardingExtraData.workspaceId)

    if (workspace == null) {
      Sentry.captureMessage('Workspace not found', {
        tags: {
          workspaceId: onboardingExtraData.workspaceId,
          position: 'getWorkspaceById',
          type: 'onboarding',
        },
      })
      throw new Error('Workspace not found')
    }

    initialWorkspace.workspaceId = workspace.id
    initialWorkspace.workspaceName = workspace.name

    if (onboardingExtraData.projectId) {
      const project = getWorkspaceProjectById(onboardingExtraData.projectId)

      if (project) {
        initialWorkspace.projectId = project.id
        initialWorkspace.projectName = project.name
      }
    }
  }

  return {
    initialWorkspace,
    onboardingScreen,
    onboardingType,
    stripeSubscription,
  }
}
