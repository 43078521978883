import {
  unassignedMember,
  type WorkspaceMemberWithUser,
} from '@motion/ui-logic'

import { useMembersIndex } from '~/global/hooks'
import { useMemo } from 'react'

import { AssigneeBadge, UserBadge } from '../badges'

type AssigneeLabelProps = {
  value: WorkspaceMemberWithUser[] | WorkspaceMemberWithUser
}

export function AssigneeLabel({ value }: AssigneeLabelProps) {
  const members = Array.isArray(value) ? value : [value]
  return (
    <div className='flex gap-2 items-center'>
      {members.length > 1 ? (
        <>
          <div className='flex'>
            {members.map((member, index) => (
              <div
                key={member.id}
                className='rounded-full border -ml-1.5 first:m-0 border-button-neutral-outlined-bg-default'
                style={{ zIndex: members.length - index }}
              >
                <AssigneeBadge value={member} size='small' />
              </div>
            ))}
          </div>
          <div className='text-sm font-normal'>
            {members.length} assignments
          </div>
        </>
      ) : (
        <>
          <UserBadge value={members[0].user} size='small' />
          <div className='truncate max-w-[150px] text-sm font-normal'>
            {members[0].user.name}
          </div>
        </>
      )}
    </div>
  )
}

type ConnectedAssigneeLabelProps = {
  value: (string | null)[] | string | null
}

export function ConnectedAssigneeLabel({ value }: ConnectedAssigneeLabelProps) {
  const membersIndex = useMembersIndex()

  const members = useMemo(
    () =>
      (Array.isArray(value) ? value : [value]).map(
        (id) => (id != null && membersIndex[id]) || unassignedMember
      ),
    [membersIndex, value]
  )

  return <AssigneeLabel value={members} />
}
