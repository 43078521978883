import {
  useWorkspacesTree,
  type WorkspacesTreeviewItem,
} from '~/areas/treeviews/components'
import { type FilteredTreeviewItem } from '~/areas/treeviews/types'

import { useFilteredSidebarTreeview } from '../../../hooks'

export const useWorkspacesTreeviewItems =
  (): FilteredTreeviewItem<WorkspacesTreeviewItem>[] => {
    const items = useWorkspacesTree()

    return useFilteredSidebarTreeview({
      items,
      computeFolderStateKey: (item) => {
        if (
          item.type === 'TEAM_WORKSPACE' ||
          item.type === 'INDIVIDUAL_WORKSPACE' ||
          item.type === 'FOLDER'
        ) {
          return item.itemId
        }

        return item.id
      },
      computeSearchValue: (item) => item.label,
    })
  }
