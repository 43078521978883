import {
  StatusCanceledSolid,
  StatusCompletedSolid,
  TrashSolid,
} from '@motion/icons'
import { Button, ButtonGroup, IconButton, Tooltip } from '@motion/ui/base'
import { formatToCompactReadableWeekDayMonthNoYear } from '@motion/ui-logic'
import {
  getPreviousEnabledStage,
  getStageStartDate,
  getStageTense,
  type StageVariant,
} from '@motion/ui-logic/pm/project'
import { safeParseDate } from '@motion/utils/dates'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { HasExperiment } from '@motion/web-common/flags'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
  type StageSchema,
} from '@motion/zod/client'

import { ConnectedStageEtaBadge } from '~/areas/eta'
import {
  useCancelStage,
  useCompleteStage,
  useRemoveStage,
  useUpdateProjectStageDueDate,
} from '~/areas/project/hooks'
import { DeadlineDropdown } from '~/areas/project-management/components'
import { twMerge } from 'tailwind-merge'

import { GoToStageButton } from './go-to-stage-button'
import { ConnectedStageAutoscheduleToggle } from './stage-auto-schedule-toggle'
import { StageHeaderDebugInfo } from './stage-header-debug-info'
import { EndContentContainer } from './styled'

export type StageHeaderEndContentProps = {
  projectStage: StageSchema
  project: ProjectSchema
  stageDefinition: StageDefinitionSchema
  stageVariant: StageVariant
}
export function StageHeaderEndContent({
  projectStage,
  project,
  stageDefinition,
  stageVariant,
}: StageHeaderEndContentProps) {
  const updateStageDueDate = useUpdateProjectStageDueDate()

  const previousStage = getPreviousEnabledStage(project, stageDefinition.id)
  const projectStartDate = safeParseDate(project.startDate ?? '')?.plus({
    days: 1,
  })
  const stageStartDate = projectStartDate
    ? getStageStartDate(projectStartDate, previousStage?.dueDate)
    : null
  const completeProjectStage = useCompleteStage()
  const cancelProjectStage = useCancelStage()
  const removeProjectStage = useRemoveStage()
  const isStageIncomplete = stageVariant === 'default'

  const showGoToStage = getStageTense(project, stageDefinition.id) === 'future'

  const shouldShowEtaBadge =
    projectStage.deadlineStatus === 'missed-deadline' ||
    projectStage.deadlineStatus === 'scheduled-past-deadline'

  if (stageVariant === 'skipped') {
    return (
      <EndContentContainer>
        {!__IS_PROD__ && <StageHeaderDebugInfo projectStage={projectStage} />}
        <em className='text-semantic-neutral-text-subtle text-2xs'>Canceled</em>
      </EndContentContainer>
    )
  }

  return (
    <EndContentContainer>
      <div className='group-hover/stage-header:block hidden'>
        <ButtonGroup size='small'>
          {showGoToStage && (
            <GoToStageButton
              project={project}
              stageDefinition={stageDefinition}
            />
          )}
          {isStageIncomplete && (
            <>
              <div className={twMerge(!showGoToStage && 'pl-1')}>
                <ConnectedStageAutoscheduleToggle
                  projectStage={projectStage}
                  project={project}
                />
              </div>
              <Tooltip asChild content='Complete stage'>
                <IconButton
                  icon={StatusCompletedSolid}
                  onClick={() =>
                    completeProjectStage(project, stageDefinition.id)
                  }
                  size='xsmall'
                  variant='muted'
                  sentiment='neutral'
                  aria-label='Complete stage'
                />
              </Tooltip>

              <Tooltip asChild content='Cancel stage'>
                <IconButton
                  icon={StatusCanceledSolid}
                  size='xsmall'
                  variant='muted'
                  sentiment='neutral'
                  onClick={() =>
                    cancelProjectStage(project, stageDefinition.id)
                  }
                  aria-label='Cancel stage'
                />
              </Tooltip>
              <HasExperiment name='flows-m5c-ad-hoc-stages'>
                <Tooltip asChild content='Remove stage'>
                  <IconButton
                    icon={TrashSolid}
                    size='xsmall'
                    variant='muted'
                    sentiment='error'
                    onClick={() => {
                      recordAnalyticsEvent('FLOW_REMOVE_STAGE_INITIATED')

                      removeProjectStage(project, stageDefinition.id)
                    }}
                    aria-label='Remove stage'
                  />
                </Tooltip>
              </HasExperiment>
            </>
          )}
        </ButtonGroup>
      </div>

      {shouldShowEtaBadge && (
        <ConnectedStageEtaBadge
          size='xxsmall'
          stage={projectStage}
          source='modal-list'
        />
      )}
      <div className='-mt-0.5'>
        {stageStartDate && (
          <span className='whitespace-nowrap text-button-neutral-muted-text-disabled text-[10px] leading-4 pl-0.5'>
            {formatToCompactReadableWeekDayMonthNoYear(stageStartDate)} -
          </span>
        )}
        <DeadlineDropdown
          hideTimeOptions
          disableClearing
          value={projectStage.dueDate}
          onChange={(dueDate) => {
            if (dueDate == null) return
            updateStageDueDate(project.id, stageDefinition.id, dueDate)
          }}
          isValidDateOption={() => true}
          contextProps={{
            projectId: project.id,
            stageDefinitionId: stageDefinition.id,
          }}
          dropdownTarget='stage'
        >
          <Button size='xsmall' variant='muted' sentiment='neutral'>
            <span className='whitespace-nowrap'>
              {formatToCompactReadableWeekDayMonthNoYear(projectStage.dueDate)}
            </span>
          </Button>
        </DeadlineDropdown>
      </div>
    </EndContentContainer>
  )
}
