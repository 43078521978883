import { ArrowDownSolid, ArrowUpSolid } from '@motion/icons'
import {
  Button,
  ButtonGroup,
  IconButton,
  PopoverButton,
  PopoverTrigger,
  SearchableList,
} from '@motion/ui/base'
import { truncateAtSpace } from '@motion/ui-logic'

import {
  type NotetakerGroupByOptions,
  useNotetakerViewState,
} from '../../state'

export function ConnectedGroupSortButton() {
  const [viewState, setViewState] = useNotetakerViewState()

  const disabled = viewState.groupBy.fields.length === 0

  // Only one type of 'order' right now, which is direction based
  const order = viewState.groupBy.order.by

  function handleOnChange(value: NotetakerGroupByOptions['order']) {
    setViewState((prev) => ({
      ...prev,
      groupBy: {
        ...prev.groupBy,
        order: value,
      },
    }))
  }

  function handleToggle() {
    handleOnChange({
      ...viewState.groupBy.order,
      by: viewState.groupBy.order.by === 'asc' ? 'desc' : 'asc',
    })
  }

  return (
    <ButtonGroup segmented>
      <PopoverTrigger
        placement='bottom-start'
        renderPopover={() => (
          <GroupByButtonPopover
            order={viewState.groupBy.order}
            onChange={handleOnChange}
          />
        )}
      >
        <Button
          disabled={disabled}
          sentiment={disabled ? 'neutral' : 'primary'}
          variant='outlined'
          size='small'
        >
          Sort Group
        </Button>
      </PopoverTrigger>
      <IconButton
        onClick={handleToggle}
        icon={order === 'asc' ? ArrowUpSolid : ArrowDownSolid}
        variant='outlined'
        size='small'
        disabled={disabled}
        sentiment={disabled ? 'neutral' : 'primary'}
      />
    </ButtonGroup>
  )
}

const OPTIONS: { id: string; label: string }[] = [
  {
    id: 'asc',
    label: 'Ascending',
  },
  {
    id: 'desc',
    label: 'Descending',
  },
]

type GroupByButtonPopoverProps = {
  order: NotetakerGroupByOptions['order']
  onChange: (value: NotetakerGroupByOptions['order']) => void
}

function GroupByButtonPopover({ onChange, order }: GroupByButtonPopoverProps) {
  return (
    <div className='bg-modal-bg text-semantic-neutral-text-default w-[240px] flex flex-col p-3 gap-3'>
      <span className='text-sm'>Sort group</span>

      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <SearchableList
            items={OPTIONS}
            computeKey={(item) => item.id}
            computeSelected={(item) => item.id === order.by}
            computeSearchValue={(item) => item.label}
            onSelect={(item) => {
              onChange({ ...order, by: item.id as 'asc' | 'desc' })
              close()
            }}
            renderItem={(item) => (
              <div className='truncate max-w-[200px]'>
                {truncateAtSpace(item.label)}
              </div>
            )}
          />
        )}
      >
        <PopoverButton>
          <div className='truncate max-w-[200px]'>
            {truncateAtSpace(
              OPTIONS.find((o) => o.id === order.by)?.label ?? ''
            )}
          </div>
        </PopoverButton>
      </PopoverTrigger>
    </div>
  )
}
