import { DesktopComputerSolid } from '@motion/icons'
import { Button, ButtonGroup, Text } from '@motion/ui/base'
import { NOOP_FUNCTION } from '@motion/utils/function'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { getWindowData } from '@motion/web-base/env'

import { useHasSeenCta } from '~/global/hooks'
import { useUpdateMyCtaSettings } from '~/global/rpc/v2'
import { useCallback, useEffect } from 'react'

type DownloadDesktopPopupProps = {
  open?: string
  setOpen?: (open: boolean) => void
}
interface DownloadDesktopPopupContentProps {
  onDismiss?: () => void
}

export const DownloadDesktopPopup = ({
  open,
  setOpen = NOOP_FUNCTION,
}: DownloadDesktopPopupProps) => {
  const { isElectron } = getWindowData()
  const { mutate: updateCtaSettings } = useUpdateMyCtaSettings()

  const hasSeenCta = useHasSeenCta()
  const showCta = hasSeenCta(`DISMISSED_DESKTOP_APP_DOWNLOAD_PROMPT`)

  const updateSettings = useCallback(() => {
    void updateCtaSettings({
      ctaSettings: {
        DISMISSED_DESKTOP_APP_DOWNLOAD_PROMPT: true,
      },
    })
    setOpen(false)
  }, [updateCtaSettings, setOpen])

  useEffect(
    function checkShouldShowDownloadDesktop() {
      if (!showCta.loaded) return
      if (showCta.seen) return

      if (isElectron) {
        recordAnalyticsEvent('DESKTOP_DOWNLOAD_CTA_AUTO_DISMISSED')
        updateSettings()
        return
      }

      setOpen(true)
    },
    [updateSettings, isElectron, setOpen, showCta.loaded, showCta.seen]
  )

  if (!open) return null
  return (
    <DownloadDesktopPopupContent
      onDismiss={() => {
        updateSettings()
      }}
    />
  )
}

const DownloadDesktopPopupContent = ({
  onDismiss = NOOP_FUNCTION,
}: DownloadDesktopPopupContentProps) => {
  useEffect(() => {
    recordAnalyticsEvent('DESKTOP_DOWNLOAD_CTA_SHOWN')
  }, [])

  return (
    <div className='bg-semantic-neutral-surface-bg-default border border-semantic-neutral-border-default flex w-[323px] flex-col gap-4 rounded-lg p-4 drop-shadow'>
      <div className='bg-semantic-neutral-bg-subtle h-9 w-9 rounded-lg p-2'>
        <DesktopComputerSolid className='text-semantic-neutral-icon-default text-base size-5' />
      </div>
      <div className='flex flex-col gap-2'>
        <Text weight='semibold'>Download our desktop app</Text>
        <Text sentiment='subtle'>
          Manage your calendar, projects and tasks without needing to open the
          browser.
        </Text>
      </div>
      <ButtonGroup>
        <Button
          sentiment='primary'
          data-testid='desktop-download-cta'
          onClick={() => {
            recordAnalyticsEvent('DESKTOP_DOWNLOAD_CTA_CLICKED', {
              view: 'download_popup',
            })
            onDismiss()
            window.open('https://www.usemotion.com/desktop-app', '_blank')
          }}
        >
          Download desktop app
        </Button>
        <Button
          sentiment='neutral'
          variant='muted'
          data-testid='desktop-download-close'
          onClick={() => {
            recordAnalyticsEvent('DESKTOP_DOWNLOAD_CTA_DISMISSED')
            onDismiss()
          }}
        >
          Not now
        </Button>
      </ButtonGroup>
    </div>
  )
}
