import { CrownSolid } from '@motion/icons'
import { ButtonTabs } from '@motion/ui/base'

import { type ReactNode, useMemo } from 'react'

import { usePageData } from '../routes'

type PageType = 'list' | 'kanban' | 'gantt' | 'dashboard'

export type PageSelectorProps = {
  value: PageType
  onChange(value: PageType): void
  canUseGantt: boolean
}

export const PageSelector = (props: PageSelectorProps) => {
  const data = usePageData()

  const pageOptions = useMemo(() => {
    const options: {
      value: PageType
      content: ReactNode
    }[] = [
      {
        value: 'list',
        content: 'List',
      },
      {
        value: 'kanban',
        content: 'Kanban',
      },
      {
        value: 'gantt',
        content: (
          <div className='flex items-center gap-1'>
            <span>Gantt</span>
            {!props.canUseGantt && <CrownSolid />}
          </div>
        ),
      },
    ]

    const filteredOptions = !data.lock.projectId
      ? [...options]
      : options.filter((option) => option.value !== 'gantt')

    return filteredOptions
  }, [data.lock.projectId, props.canUseGantt])

  return (
    <ButtonTabs
      size='small'
      activeValue={props.value}
      items={pageOptions.map((opt) => ({
        value: opt.value,
        content: opt.content,
        onAction: () => props.onChange(opt.value),
      }))}
    />
  )
}
