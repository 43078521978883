import {
  AttachmentLexicalWrapper,
  AttachmentNode,
  AttachmentsPlugin,
  SelectableNodeWrapper,
} from '@motion/notes'
import { type NoteSchema } from '@motion/rpc-types'
import { SUPPORTED_MIME_TYPES } from '@motion/shared/files'
import { isFileTooLarge, maxUploadFileSizeMB } from '@motion/ui-logic'

import { useUploadAttachments } from '~/areas/attachments/hooks'
import { showErrorToast } from '~/global/toasts'

import { ConnectedCustomAttachmentTag } from './components/custom-attachment-tag'
import { buildAttachmentUrl, fileIsPreviewable } from './utils'

AttachmentNode.prototype.render = (
  nodeKey: string,
  attachmentId: string,
  isUploading: boolean,
  isPreview: boolean,
  width: number | null,
  height: number | null
) => {
  return (
    <SelectableNodeWrapper nodeKey={nodeKey} key={nodeKey}>
      <AttachmentLexicalWrapper
        nodeKey={nodeKey}
        Component={ConnectedCustomAttachmentTag}
        attachmentId={attachmentId}
        isUploading={isUploading}
        isPreview={isPreview}
        width={width}
        height={height}
      />
    </SelectableNodeWrapper>
  )
}

AttachmentNode.prototype.getAttachmentUrl = (attachmentId: string) => {
  return buildAttachmentUrl(attachmentId)
}

type CustomAttachmentsPluginProps = {
  noteId: NoteSchema['id']
}

function CustomAttachmentsPlugin({ noteId }: CustomAttachmentsPluginProps) {
  const uploadAttachments = useUploadAttachments({
    targetId: noteId,
    targetType: 'NOTE',
  })

  const uploadFile = async (file: File) => {
    if (isFileTooLarge(file.size)) {
      showErrorToast(
        `${file.name} exceeds the ${maxUploadFileSizeMB()}MB limit.`
      )

      throw new Error('File is too large')
    }

    if (!SUPPORTED_MIME_TYPES.includes(file.type)) {
      showErrorToast(`Unsupported file type: ${file.name}`)
      throw new Error('Unsupported file type')
    }

    // Upload attachments
    const { fileUploads } = await uploadAttachments([file])

    if (fileUploads.length === 0) {
      showErrorToast('Failed to upload file')
      throw new Error('Failed to upload file')
    }

    const uploadedFile = fileUploads[0]

    return uploadedFile.id
  }

  return (
    <AttachmentsPlugin
      uploadFile={uploadFile}
      fileIsPreviewable={fileIsPreviewable}
      supportedMimeTypes={SUPPORTED_MIME_TYPES}
    />
  )
}

export { AttachmentNode as CustomAttachmentNode, CustomAttachmentsPlugin }
