import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'
import { isEqual, omit } from '@motion/utils/core'
import { keys } from '@motion/utils/object'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { FLOW_TEMPLATE_PRESETS } from './flow-presets'

export function recordPresetAnalytics(
  fromPresetId: string,
  fields: FlowTemplateFormFields
) {
  const preset = FLOW_TEMPLATE_PRESETS.find(
    (preset) => preset.id === fromPresetId
  )

  if (!preset) {
    throw new Error('Preset not found')
  }

  const { numChanges, changedFields } = getPresetChanges(fields, preset)

  recordAnalyticsEvent('FLOW_TEMPLATE_MODAL_PRESET_CREATE', {
    name: preset.name,
    numChanges,
    changedFields,
  })
}

function getPresetChanges(
  fields: FlowTemplateFormFields,
  { template }: (typeof FLOW_TEMPLATE_PRESETS)[number]
) {
  const changedFields: string[] = []

  const numChanges = keys(fields).reduce((count, key) => {
    if (
      key === 'id' ||
      key === 'workspaceId' ||
      key === 'isLoading' ||
      key === 'hasError' ||
      key === 'type' ||
      key === 'mode' ||
      key === 'variableAbbreviations' ||
      key === 'modalMode'
    )
      return count // Ignore these fields
    if (key === 'stages') {
      return fields[key].reduce((c1, stage, stageIndex) => {
        // Consider tasks separately
        if (
          !isEqual(
            omit(stage, 'tasks'),
            omit(template()[key][stageIndex], 'tasks')
          )
        ) {
          changedFields.push(`${key}[${stageIndex}]`)
          return c1 + 1
        }

        return stage.tasks.reduce((c2, task, taskIndex) => {
          // Status id is not included in the template
          if (
            !isEqual(
              omit(task, 'statusId'),
              template()[key][stageIndex].tasks[taskIndex]
            )
          ) {
            changedFields.push(`${key}.tasks[${taskIndex}]`)
            return c2 + 1
          }
          return c2
        }, c1)
      }, count)
    }
    if (key === 'textVariables') {
      const templateTextVariables = template()['variables'].filter(
        (variable) => variable.type === 'text'
      )

      if (!isEqual(fields[key], templateTextVariables)) {
        changedFields.push(key)
        return count + 1
      }

      return count
    }
    if (key === 'roles') {
      const templateRoleVariables = template()['variables'].filter(
        (variable) => variable.type === 'person'
      )

      if (!isEqual(fields[key], templateRoleVariables)) {
        changedFields.push(key)
        return count + 1
      }

      return count
    }

    if (!isEqual(fields[key], template()[key])) {
      changedFields.push(key)
      return count + 1
    }
    return count
  }, 0)

  return { numChanges, changedFields }
}
