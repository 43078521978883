import { Text, Toggle } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useAutoScheduleSettings } from '~/global/hooks'
import { useUpdateMyAutoScheduleSettingsMutation } from '~/global/rpc/v2'

import { Paragraph } from '../../../../Common'

export const PublicTaskNamesSettings = () => {
  const publicTaskNames = useAutoScheduleSettings().showTaskNamesInEvents
  const { mutate: updateAutoScheduleSettings } =
    useUpdateMyAutoScheduleSettingsMutation()

  const onPublicNameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const enabled = e.target.checked
    void updateAutoScheduleSettings({ showTaskNamesInEvents: enabled })
    recordAnalyticsEvent('TASK_NAME_SETTINGS_UNHIDE', { enabled })
  }
  return (
    <div className='flex flex-col gap-2'>
      <Paragraph className='pb-1 text-base font-semibold'>
        Display task names
      </Paragraph>
      <Toggle checked={publicTaskNames} onChange={onPublicNameChange}>
        <Paragraph className='-mt-0.5'>Display task names</Paragraph>
        <Text sentiment='subtle' size='sm'>
          Your teammates may see your task names if this setting is toggled on.
        </Text>
      </Toggle>
    </div>
  )
}
