import { DotsHorizontalSolid } from '@motion/icons'
import {
  type ActionItem,
  ActionList,
  IconButton,
  PopoverTrigger,
} from '@motion/ui/base'
import { isBotKickable, isBotMutable } from '@motion/ui-logic'
import { Sentry } from '@motion/web-base/sentry'

import { useKickBot } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback, useMemo } from 'react'

import { useEventModalState } from '../../contexts'
import { useEventForm } from '../../hooks'

export function NotetakerActionMenu() {
  const actionItems = useActionMenuItems()

  if (actionItems.length === 0) return null

  return (
    <PopoverTrigger
      placement='bottom-end'
      renderPopover={({ close }) => (
        <ActionList onActionAnyItem={close} items={actionItems} />
      )}
    >
      <IconButton
        icon={DotsHorizontalSolid}
        size='xsmall'
        variant='muted'
        sentiment='neutral'
      />
    </PopoverTrigger>
  )
}

function useActionMenuItems() {
  const { form } = useEventForm()
  const { meetingInsights } = useEventModalState()
  const isDirty = form.formState.isDirty

  const { mutateAsync: kickBot } = useKickBot()

  const handleKickBotFromMeeting = useCallback(async () => {
    if (meetingInsights == null) {
      Sentry.captureMessage(
        'Meeting insights not found when trying to kick bot',
        {
          tags: {
            position: 'NotetakerActionMenu',
          },
        }
      )
      showErrorToast('Unable to kick bot from meeting')
      return
    }

    await kickBot({
      meetingInsightsId: meetingInsights.id,
    })
  }, [kickBot, meetingInsights])

  return useMemo<ActionItem[]>(() => {
    const items: ActionItem[] = []

    if (
      !isDirty &&
      isBotMutable(meetingInsights) &&
      isBotKickable(meetingInsights)
    ) {
      items.push({
        prefix: '🤖',
        content: 'Kick bot from meeting',
        onAction: handleKickBotFromMeeting,
      })
    }

    return items
  }, [handleKickBotFromMeeting, isDirty, meetingInsights])
}
