export function isBlurringWithKeyboard(
  e: React.KeyboardEvent<HTMLInputElement>
) {
  return (
    (e.key === 'Backspace' && e.currentTarget.selectionStart === 0) ||
    (e.key === 'ArrowLeft' && e.currentTarget.selectionStart === 0) ||
    (e.key === 'ArrowRight' &&
      e.currentTarget.selectionStart === e.currentTarget.value.length) ||
    e.key === 'Escape'
  )
}
