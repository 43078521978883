import { AutoscheduleStarGradientSolid } from '@motion/icons'
import {
  Button,
  ButtonGroup,
  Tooltip,
  UnstyledModal,
  useShortcut,
} from '@motion/ui/base'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useUnsavedChangesPrompt } from '~/global/navigation'
import { useCallback } from 'react'

import {
  AIChangesSidebar,
  AIGradientOverlay,
  FormShell,
  GridShell,
  LeftPanel,
  MainContent,
  MainContentFooter,
  MainContentScrollable,
  RightPanel,
  RightPanelScrollable,
  SetupProjectTabPanels,
  SetupProjectTabs,
  ShellVars,
} from './components'
import {
  SetupProjectFormNavigationProvider,
  type UseInitialFormDataArgs,
  useSetupProjectForm,
  useSetupProjectFormNavigationContext,
  useSetupProjectModalSubmitHandler,
} from './hooks'
import { SetupProjectForm } from './setup-project-form'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'v2-setup-project': UseInitialFormDataArgs
  }
}

export function ConnectedSetupProjectModal({
  close,
  ...formDefaults
}: ModalTriggerComponentProps<'v2-setup-project'>) {
  return (
    <SetupProjectForm formDefaults={formDefaults}>
      <SetupProjectModalBody close={close} />
    </SetupProjectForm>
  )
}

type SetupProjectModalBodyProps = { close: () => void }

function SetupProjectModalBody({ close }: SetupProjectModalBodyProps) {
  const {
    form: {
      getValues,
      formState: { isSubmitting, isDirty },
    },
  } = useSetupProjectForm()
  const showUnsavedPrompt = useUnsavedChangesPrompt()

  const onClose = useCallback(async () => {
    if (isSubmitting) {
      return
    }

    // Always show unsaved prompt on close for AI projects
    if (isDirty || getValues('mode') === 'ai') {
      const proceed = await showUnsavedPrompt()
      if (!proceed) {
        return
      }
    }

    close()
  }, [close, getValues, isDirty, isSubmitting, showUnsavedPrompt])

  return (
    <SetupProjectFormNavigationProvider close={close}>
      <UnstyledModal
        data-testid='v2-setup-project-modal'
        type='page'
        visible
        onClose={onClose}
        withAnimation
        overlayClassName='bg-modal-overlay'
      >
        <SetupProjectModalContent close={onClose} />
      </UnstyledModal>
    </SetupProjectFormNavigationProvider>
  )
}

function SetupProjectModalContent({ close }: SetupProjectModalBodyProps) {
  const onSubmit = useSetupProjectModalSubmitHandler(close)
  const { isDisabled, disabledMessage, goForward, goBack } =
    useSetupProjectFormNavigationContext()

  const {
    form: { watch },
  } = useSetupProjectForm()
  const mode = watch('mode')

  useShortcut('mod+s', goForward)

  const columns = watch('mode') === 'ai' ? 3 : 2

  return (
    <ShellVars columns={columns}>
      <FormShell onSubmit={onSubmit} columns={columns}>
        <GridShell>
          <LeftPanel>
            <h2 className='font-semibold text-semantic-neutral-text-subtle text-[14px] leading-[20px]'>
              Set up your project
            </h2>
            <SetupProjectTabs />
          </LeftPanel>

          <MainContent>
            <MainContentScrollable>
              <SetupProjectTabPanels activeTab={watch('step')} />
            </MainContentScrollable>
          </MainContent>

          {mode === 'ai' && (
            <RightPanel>
              <RightPanelScrollable>
                <AIGradientOverlay />
                <div className='sticky top-0 z-10 pt-3 px-3 pb-6 bg-gradient-to-b from-modal-bg from-50%'>
                  <h2 className='flex items-center gap-2 text-sm font-semibold text-gradient bg-purple-gradient bg-opacity-100'>
                    <AutoscheduleStarGradientSolid
                      className='size-4'
                      stopColors={['#BC6BFC', '#F147EA']}
                    />
                    AI changes
                  </h2>
                </div>
                <AIChangesSidebar />
              </RightPanelScrollable>
            </RightPanel>
          )}

          <MainContentFooter>
            {goBack != null && (
              <Button sentiment='neutral' variant='outlined' onClick={goBack}>
                Back
              </Button>
            )}
            <div className='flex flex-1 justify-end'>
              <ButtonGroup size='medium'>
                <Button
                  sentiment='neutral'
                  variant='muted'
                  shortcut='esc'
                  onClick={close}
                >
                  Cancel
                </Button>

                <Tooltip content={disabledMessage} asChild>
                  <Button
                    sentiment='primary'
                    variant='solid'
                    shortcut='mod+s'
                    disabled={isDisabled}
                    onClick={() => goForward()}
                  >
                    Continue
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </div>
          </MainContentFooter>
        </GridShell>
      </FormShell>
    </ShellVars>
  )
}
