import { CogSolid, PlusSolid } from '@motion/icons'
import { MAX_FOLDER_DEPTH } from '@motion/shared/common'
import { ButtonGroup, IconButton } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'

import { ViewActionsMenu } from '~/areas/sidebar/treeviews/components/view-actions-menu'
import { useUriByRouteId } from '~/routing'

import {
  FolderActionsMenu,
  NoteActionsMenu,
  ProjectActionsMenu,
  WorkspaceActionMenu,
  WorkspaceCreateMenu,
} from './actions'

import { type SortableWorkspacesTreeviewItem } from '../types'

type MoveActionAllowed = {
  up: boolean
  down: boolean
}

export type TreeviewItemActionsProps = {
  item: SortableWorkspacesTreeviewItem['item']
  moveActionAllowed?: MoveActionAllowed
  onMoveItem: (moveBy: -1 | 1) => void | Promise<void>
  level: number
  onlyActionMenu?: boolean
}

export function TreeviewItemActions(props: TreeviewItemActionsProps) {
  const hasNotes = useHasTreatment('notes-in-webapp')

  switch (props.item.type) {
    case 'INDIVIDUAL_WORKSPACE':
    case 'TEAM_WORKSPACE':
      return <WorkspaceButtons {...props} />

    case 'FOLDER':
      return <FolderButtons {...props} />

    case 'PROJECT':
      return <ProjectButtons {...props} />

    case 'NOTE':
      return (
        hasNotes && (
          <NoteActionsMenu
            noteId={props.item.itemId}
            moveActionAllowed={props.moveActionAllowed}
            onMoveItem={props.onMoveItem}
            location='SIDEBAR'
          />
        )
      )

    case 'VIEW':
      return (
        <ViewActionsMenu
          itemId={props.item.id}
          moveActionAllowed={props.moveActionAllowed}
          onMoveItem={props.onMoveItem}
          url={props.item.url}
        />
      )

    default:
      return null
  }
}

function WorkspaceButtons({
  item,
  moveActionAllowed,
  onMoveItem,
  onlyActionMenu = false,
}: TreeviewItemActionsProps) {
  const getRouteUri = useUriByRouteId({ noDefaults: true })
  const hasNotes = useHasTreatment('notes-in-webapp')

  return (
    <ButtonGroup size='small' nowrap>
      {!onlyActionMenu && (
        <IconButton
          icon={CogSolid}
          size='xsmall'
          sentiment='neutral'
          variant='muted'
          url={getRouteUri('workspace-settings', {
            workspaceId: item.workspaceId,
          })}
          onClick={() => {
            recordAnalyticsEvent('FOLDERS_CLICKED_SIDEBAR_BUTTON', {
              itemType: 'WORKSPACE',
              button: 'WORKSPACE_SETTINGS',
            })
          }}
        />
      )}

      <WorkspaceActionMenu
        workspaceId={item.workspaceId}
        moveActionAllowed={moveActionAllowed}
        onMoveItem={onMoveItem}
      />

      {!onlyActionMenu && (
        <WorkspaceCreateMenu
          workspaceId={item.workspaceId}
          allowNewNote={hasNotes}
        >
          <IconButton
            variant='muted'
            sentiment='neutral'
            size='xsmall'
            icon={PlusSolid}
            onClick={() => {
              recordAnalyticsEvent('FOLDERS_CLICKED_SIDEBAR_BUTTON', {
                itemType: 'WORKSPACE',
                button: 'CREATE',
              })
            }}
          />
        </WorkspaceCreateMenu>
      )}
    </ButtonGroup>
  )
}

function FolderButtons({
  item,
  moveActionAllowed,
  onMoveItem,
  level,
  onlyActionMenu = false,
}: TreeviewItemActionsProps) {
  const hasNotes = useHasTreatment('notes-in-webapp')

  return (
    <ButtonGroup size='small' nowrap>
      <FolderActionsMenu
        folderId={item.itemId}
        moveActionAllowed={moveActionAllowed}
        onMoveItem={onMoveItem}
      />

      {!onlyActionMenu && (
        <WorkspaceCreateMenu
          workspaceId={item.workspaceId}
          folderId={item.itemId}
          allowNewFolder={level < MAX_FOLDER_DEPTH}
          allowNewNote={hasNotes}
        >
          <IconButton
            variant='muted'
            sentiment='neutral'
            size='xsmall'
            icon={PlusSolid}
            onClick={() => {
              recordAnalyticsEvent('FOLDERS_CLICKED_SIDEBAR_BUTTON', {
                itemType: 'FOLDER',
                button: 'CREATE',
              })
            }}
          />
        </WorkspaceCreateMenu>
      )}
    </ButtonGroup>
  )
}

function ProjectButtons({
  item,
  moveActionAllowed,
  onMoveItem,
  onlyActionMenu = false,
}: TreeviewItemActionsProps) {
  const hasNotes = useHasTreatment('notes-in-webapp')

  return (
    <ButtonGroup size='small' nowrap>
      <ProjectActionsMenu
        projectId={item.itemId}
        moveActionAllowed={moveActionAllowed}
        onMoveItem={onMoveItem}
      />

      {!onlyActionMenu && hasNotes && (
        <WorkspaceCreateMenu
          workspaceId={item.workspaceId}
          projectId={item.itemId}
          allowNewFolder={false}
          allowNewProject={false}
          allowNewNote={hasNotes}
        >
          <IconButton
            variant='muted'
            sentiment='neutral'
            size='xsmall'
            icon={PlusSolid}
            onClick={() => {
              recordAnalyticsEvent('FOLDERS_CLICKED_SIDEBAR_BUTTON', {
                itemType: 'PROJECT',
                button: 'CREATE',
              })
            }}
          />
        </WorkspaceCreateMenu>
      )}
    </ButtonGroup>
  )
}
