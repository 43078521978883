import {
  getPreviousStage,
  getStageStartDate,
  getStageVariant,
  isStageActive,
} from '@motion/ui-logic/pm/project'
import { parseDate } from '@motion/utils/dates'
import { type StageSchema } from '@motion/zod/client'

import { StageTooltip } from '~/areas/flows'
import { StageBadge } from '~/global/components/badges'
import { useStageDefinition } from '~/global/hooks'
import { forwardRef, type ReactNode } from 'react'

import { useStageTimelineContext } from './context'
import { StageProgressCircle } from './stage-progress-circle'
import { MultiStageBadgeWrapper } from './styled'

type StageTimelineBadgeProps = {
  projectStage: StageSchema
  isStacked?: boolean
  stackIndex?: number
}

export const StageTimelineBadge = forwardRef<
  HTMLButtonElement,
  StageTimelineBadgeProps
>(function StageTimelineBadge({ projectStage, isStacked, stackIndex }, ref) {
  const { project, interactive } = useStageTimelineContext()
  const stageDefinition = useStageDefinition(projectStage.stageDefinitionId)

  const isActive = isStageActive(project, stageDefinition.id)
  const variant = getStageVariant(projectStage)

  const previousStage = getPreviousStage(project, stageDefinition.id)
  const stageStartDate =
    project.startDate != null
      ? getStageStartDate(
          parseDate(project.startDate),
          previousStage?.dueDate
        ).toISODate()
      : null

  let badge: ReactNode = (
    <StageBadge value={stageDefinition} variant={variant} hideTooltip />
  )

  if (isActive) {
    badge = (
      <StageProgressCircle
        stageDefinition={stageDefinition}
        percentageComplete={projectStage.completion}
        variant={variant}
      />
    )
  }

  return (
    <StageTooltip
      stage={projectStage}
      start={stageStartDate}
      end={projectStage.dueDate}
      hideTooltip={!interactive}
    >
      <MultiStageBadgeWrapper
        isStacked={isStacked}
        stackIndex={stackIndex as any}
        variant={isActive ? 'active' : undefined}
      >
        {badge}
      </MultiStageBadgeWrapper>
    </StageTooltip>
  )
})
