import { useOnce } from '@motion/react-core/hooks'
import { templateStr } from '@motion/react-core/strings'
import type { PMTaskType } from '@motion/rpc/types'
import { SHORT_DURATION_THRESHOLD } from '@motion/ui/calendar'
import { formatTime, prettyDateDay } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type TaskWithRelations } from '~/global/proxies'
import { type DateTime } from 'luxon'
import { type ReactNode } from 'react'

import { ChunkInfoTooltipContent } from './chunk-info-tooltip-content'

import { TOOLTIP_MESSAGES } from '../components'
import { useTaskOverrideTooltip } from '../hooks'
import {
  PAST_DUE_TASK_MESSAGE,
  SCHEDULE_OVERRIDDEN_AND_PAST_DUE_TASK_MESSAGE,
  SCHEDULE_OVERRIDDEN_TASK_MESSAGE,
} from '../utils'

type IconTooltipContentProps = {
  task: TaskWithRelations
  isRecurringInstance: boolean
  isUnfit: boolean
  isPastDue: boolean
  isScheduleOverridden: boolean | undefined
  snoozeUntil: DateTime | undefined
  chunkTotal: number
  chunks: PMTaskType[]
}

export function IconTooltipContent({
  task,
  isRecurringInstance,
  isUnfit,
  isPastDue,
  isScheduleOverridden,
  snoozeUntil,
  chunkTotal,
}: IconTooltipContentProps) {
  useOnce(() => {
    recordAnalyticsEvent('CALENDAR_TASK_DEADLINE_WARNING_VIEWED', {
      isUnfit,
    })
  })

  const parentChunkTaskId =
    'parentChunkTaskId' in task ? task.parentChunkTaskId : undefined

  const taskOverrideTooltip = useTaskOverrideTooltip(task)

  const parts: ReactNode[] = []

  const isUnfitRecurringInstance =
    isRecurringInstance &&
    isUnfit &&
    task.duration &&
    task.duration >= SHORT_DURATION_THRESHOLD

  const hasBlockers = task.type === 'NORMAL' && task.blockedByTasks.length > 0

  if (isUnfitRecurringInstance) {
    parts.push('This recurring task could not fit')
  } else {
    if (isRecurringInstance) {
      parts.push('Recurring task')
    }

    if (taskOverrideTooltip != null) {
      parts.push(taskOverrideTooltip)
    } else if (isPastDue && isScheduleOverridden) {
      parts.push(SCHEDULE_OVERRIDDEN_AND_PAST_DUE_TASK_MESSAGE)
    } else if (isPastDue) {
      parts.push(PAST_DUE_TASK_MESSAGE)
    } else if (isScheduleOverridden) {
      parts.push(SCHEDULE_OVERRIDDEN_TASK_MESSAGE)
    }
  }

  if (hasBlockers) {
    parts.push(TOOLTIP_MESSAGES.CALENDAR_HOVER_HINT)
  }

  if (snoozeUntil) {
    parts.push(
      templateStr(
        'This task is snoozed until after {{date}} at {{time}}. Click the moon to un-snooze.',
        {
          date: prettyDateDay(snoozeUntil, {
            lowercase: true,
          }),
          time: formatTime(snoozeUntil),
        }
      )
    )
  }

  if (task.isFixedTimeTask) {
    parts.push('This task is locked')
  }

  if (parts.length === 0 && chunkTotal === 0) return

  return (
    <div className='flex flex-col gap-2 text-left'>
      {parts.map((p) => (
        <p key={String(p)}>{p}</p>
      ))}

      <ChunkInfoTooltipContent
        currentTaskId={task.id}
        parentChunkTaskId={parentChunkTaskId}
      />
    </div>
  )
}
