import { templateStr } from '@motion/react-core/strings'
import { type LabelContext } from '@motion/shared/activity-feed'

import { Label, type LabelProps } from '~/global/components/labels'
import { useI18N } from '~/global/contexts'
import { Fragment, type ReactNode } from 'react'

type ActivityLabelItemProps = {
  oldLabelContext: LabelContext[] | undefined
  newLabelContext: LabelContext[] | undefined
}

export function ActivityLabelItem({
  oldLabelContext,
  newLabelContext,
}: ActivityLabelItemProps) {
  const { pluralize } = useI18N()
  if (!oldLabelContext || !newLabelContext) return null

  const addedLabels = newLabelContext.filter(
    (label) => !oldLabelContext.some((l) => l.id === label.id)
  )

  const removedLabels = oldLabelContext.filter(
    (label) => !newLabelContext.some((l) => l.id === label.id)
  )

  return templateStr('{{addedLabels}}{{and}}{{removedLabels}}', {
    and: addedLabels.length > 0 && removedLabels.length > 0 ? ' and ' : '',
    addedLabels:
      addedLabels.length > 0 ? (
        <>
          {`added ${pluralize(addedLabels.length, 'label', 'labels')} `}
          <LabelListItems labels={addedLabels} />
        </>
      ) : null,
    removedLabels:
      removedLabels.length > 0 ? (
        <>
          {`removed ${pluralize(removedLabels.length, 'label', 'labels')} `}
          <LabelListItems labels={removedLabels} />
        </>
      ) : null,
  })
}

type LabelListItemsProps = {
  labels: LabelProps['value'][]
}

export function LabelListItems({ labels }: LabelListItemsProps) {
  return labels.map<ReactNode>((label, index) => (
    <Fragment key={label.id}>
      {index > 0 && ' '}
      <Label key={label.id} value={label} size='small' />
    </Fragment>
  ))
}
