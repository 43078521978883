import { PlusSolid } from '@motion/icons'
import { type StageDefinitionSchema } from '@motion/rpc-types'
import { classed } from '@motion/theme'
import { Button, type ButtonProps } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import { useAddStageToProject } from '~/areas/project/hooks'
import { useProject } from '~/global/hooks'
import { useMemo } from 'react'

import { useSidebarTaskContext } from '../../context'

export type InlineAddButtonProps = {
  onClick: ButtonProps['onClick']
}
export function InlineAddTaskButton({ onClick }: InlineAddButtonProps) {
  return (
    <div className='pl-[18px] mt-1 h-5'>
      <Button
        size='xsmall'
        variant='muted'
        sentiment='neutral'
        onClick={onClick}
      >
        <PlusSolid />
        Add task
      </Button>
    </div>
  )
}

export type InlineAddStageButtonProps = {
  stageDefinitionId: StageDefinitionSchema['id']
}

export function InlineAddStageButton({
  stageDefinitionId,
}: InlineAddStageButtonProps) {
  const { projectId } = useSidebarTaskContext()
  const project = useProject(projectId)
  const addStage = useAddStageToProject()

  const { indexToInsert } = useMemo(() => {
    return {
      // 0 is an invalid index because you can't add stages before visited stages so it must always be >= 1
      indexToInsert:
        (project?.stages?.findIndex(
          (stage) => stage.stageDefinitionId === stageDefinitionId
        ) ?? -1) + 1,
    }
  }, [project, stageDefinitionId])

  if (project == null || indexToInsert === 0) {
    Sentry.captureException(new Error('Project or indexToInsert is null'), {
      extra: {
        projectId,
        stageDefinitionId,
        indexToInsert,
      },
    })
    throw new Error('Invalid project or stage')
  }

  return (
    <AddStageWrapper>
      <Button
        size='xsmall'
        variant='muted'
        sentiment='neutral'
        onClick={async () => {
          recordAnalyticsEvent('FLOW_ADD_STAGE_INITIATED')
          await addStage(project, indexToInsert)
        }}
      >
        <PlusSolid />
        Add stage
      </Button>
    </AddStageWrapper>
  )
}

const AddStageWrapper = classed('div', {
  base: `
    flex items-center h-5 w-full gap-1 px-2
    before:content-[''] before:h-[1px] before:flex-1 before:bg-semantic-neutral-border-strong
    after:content-[''] after:h-[1px] after:flex-1 after:bg-semantic-neutral-border-strong
  `,
})
