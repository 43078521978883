import {
  ActionDropdown,
  type ActionItem,
  useContextMenu,
} from '@motion/ui/base'
import { formatMonthDay } from '@motion/ui-logic'

import { useUpdateProjectStageDueDate } from '~/areas/project/hooks'
import {
  DeadlineDateDropdownContent,
  DeadlineDropdown,
} from '~/areas/project-management/components'
import { StageLabel } from '~/global/components/labels'
import { useStageDefinition, useWorkspaceFns } from '~/global/hooks'
import { type FC } from 'react'

import { useStageTimelineContext } from './context'
import { StageTimelineBadge } from './stage-timeline-badge'
import { StageTimelineBadges } from './stage-timeline-badges'
import {
  StageDate,
  StageDateSpacer,
  StageItem,
  StageTimelineBadgeButton,
} from './styled'
import { type StageGroup } from './utils'

import { StageHeaderContextMenuContent } from '../stage-header-context-menu'

type StageTimelineItemProps = {
  stageGroup: StageGroup
}

export const StageTimelineItem: FC<StageTimelineItemProps> = ({
  stageGroup,
}) => {
  const { project, interactive } = useStageTimelineContext()
  const stackedCount = Math.min(stageGroup.stages.length, 5)
  const isStacked = stackedCount > 1

  const { handleContextMenu, ContextMenuPopover } = useContextMenu()
  const updateStageDueDate = useUpdateProjectStageDueDate()
  const { getStageDefinition } = useWorkspaceFns()

  const selectedStage = stageGroup.stages[0]

  const stageDefinition = useStageDefinition(selectedStage.stageDefinitionId)

  const items: ActionItem[] = stageGroup.stages.map((stage) => ({
    content: <StageLabel value={getStageDefinition(stage.stageDefinitionId)} />,
    renderPopover: ({ close }) => (
      <DeadlineDateDropdownContent
        value={stage.dueDate}
        onChange={(dueDate) => {
          if (dueDate == null || !interactive) return
          updateStageDueDate(project.id, stage.stageDefinitionId, dueDate)
          close()
        }}
        hideTimeOptions
        contextProps={{
          projectId: project.id,
          stageDefinitionId: stage.stageDefinitionId,
        }}
        dropdownTarget='stage'
        isValidDateOption={() => true}
      />
    ),
  }))

  return (
    <StageItem stackedCount={stackedCount as any}>
      {isStacked ? (
        <ActionDropdown items={items}>
          <StageTimelineBadgeButton disabled={!interactive}>
            <StageTimelineBadges stageGroup={stageGroup} />
          </StageTimelineBadgeButton>
        </ActionDropdown>
      ) : (
        <DeadlineDropdown
          value={selectedStage.dueDate}
          onChange={(dueDate) => {
            if (dueDate == null || !interactive) return
            updateStageDueDate(
              project.id,
              selectedStage.stageDefinitionId,
              dueDate
            )
          }}
          isValidDateOption={() => true}
          hideTimeOptions
          contextProps={{
            projectId: project.id,
            stageDefinitionId: selectedStage.stageDefinitionId,
          }}
          dropdownTarget='stage'
        >
          <StageTimelineBadgeButton
            onContextMenu={interactive ? handleContextMenu : undefined}
            disabled={!interactive}
          >
            <StageTimelineBadge projectStage={selectedStage} />
          </StageTimelineBadgeButton>
        </DeadlineDropdown>
      )}

      {isStacked && <StageDateSpacer />}

      <StageDate>
        {formatMonthDay(stageGroup.dueDateKey, {
          numeric: true,
          year: '2-digit',
        })}
      </StageDate>

      {interactive && (
        <ContextMenuPopover
          renderContent={({ close }) => (
            <StageHeaderContextMenuContent
              close={close}
              project={project}
              projectStage={selectedStage}
              stageDefinition={stageDefinition}
            />
          )}
        />
      )}
    </StageItem>
  )
}
