import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { AssigneeField } from '~/areas/task-project/fields'
import { useWorkspaceMember } from '~/global/hooks'
import { useController } from 'react-hook-form'

export const ControlledAssigneeField = () => {
  const { form } = useFlowTemplateForm()

  const { control, watch } = form

  const { field } = useController({
    name: 'managerId',
    control,
  })
  const workspaceId = watch('workspaceId')
  const selectedAssignee = useWorkspaceMember(workspaceId, field.value)

  return (
    <AssigneeField
      value={selectedAssignee?.userId ?? null}
      onChange={(assigneeId) => {
        field.onChange(assigneeId)
      }}
      workspaceId={workspaceId}
    />
  )
}
