import { classed } from '@motion/theme'
import { type Group } from '@motion/ui/base'
import { isStageCanceled, isStageCompleted } from '@motion/ui-logic/pm/project'
import { createLookupByKey } from '@motion/utils/object'
import { type StageSchema } from '@motion/zod/client'

import {
  type ExpandedState,
  type ExpandedStateList,
  getCoreRowModel,
  getExpandedRowModel,
  type Row,
  useReactTable,
} from '@tanstack/react-table'
import { type Range, useVirtualizer } from '@tanstack/react-virtual'
import { StageTimeline, useIsFlowsM5cEnabled } from '~/areas/flows'
import { useProject } from '~/global/hooks'
import { type NormalTaskWithRelations } from '~/global/proxies'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useSidebarTaskContext } from './context'
import {
  HEADER_GROUP_HEIGHT,
  INLINE_ADD_STAGE_BUTTON_HEIGHT,
  INLINE_ADD_TASK_BUTTON_HEIGHT,
  INLINE_ADD_TASK_HEIGHT,
  isTaskRow,
  SPACER_HEIGHT,
  TASK_LINE_HEIGHT,
  useShouldShowAddButtonsFn,
} from './utils'
import { VirtualSidebarTaskItem } from './virtual-sidebar-item'

import { type SidebarTasksGroup } from '../../hooks'
import { type SortBy } from '../../utils'

type Props<SK extends keyof typeof SortBy> = {
  groupedTasks: SidebarTasksGroup[]
  sort: SK
  projectId: string
}

function getIsGroupDefaultExpanded<SK extends keyof typeof SortBy>(
  projectStages: StageSchema[] | undefined,
  sort: SK
): ((value: SidebarTasksGroup) => boolean) | undefined {
  return (item) => {
    if (sort === 'STAGES') {
      const thisStage = projectStages?.find(
        (stage) => stage.stageDefinitionId === item.key
      )

      if (thisStage == null) {
        return true
      }

      const shouldBeCollapsed =
        isStageCompleted(thisStage) || isStageCanceled(thisStage)

      return !shouldBeCollapsed
    }

    return true
  }
}

export function SidebarTasks<SK extends keyof typeof SortBy>({
  groupedTasks,
  projectId,
  sort,
}: Props<SK>) {
  const project = useProject(projectId)
  const stages = project?.stages

  const [groupExpanded, setGroupExpanded] = useState<ExpandedState>(
    createLookupByKey(
      groupedTasks,
      'key',
      getIsGroupDefaultExpanded(stages, sort)
    )
  )
  const { enableInlineAdd } = useSidebarTaskContext()
  const hasFlowsM5c = useIsFlowsM5cEnabled()

  useEffect(() => {
    setGroupExpanded(
      createLookupByKey(
        groupedTasks,
        'key',
        getIsGroupDefaultExpanded(stages, sort)
      )
    )
    // We want to reset the expanded state if the project or sort changes only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.id, sort])

  const [addTaskExpanded, setAddTaskExpanded] = useState<ExpandedStateList>(
    createLookupByKey(groupedTasks, 'key', () => false)
  )

  const table = useReactTable({
    data: groupedTasks,
    columns: [
      {
        accessorFn: (row) => row,
        header: 'Default',
      },
    ],
    state: {
      expanded: groupExpanded,
    },
    filterFromLeafRows: true,
    getRowId: (row) => row.key,
    onExpandedChange: setGroupExpanded,
    getCoreRowModel: getCoreRowModel(),
    // @ts-expect-error - fine
    getSubRows: (row) => {
      return row.items
    },
    getExpandedRowModel: getExpandedRowModel(),
  })

  const { rows } = table.getRowModel()

  const usingGroups = !['DEFAULT_NONE', 'BLOCKERS'].includes(sort)
  const rowsToUse = usingGroups ? rows : (rows[0]?.subRows ?? [])

  const tableContainerRef = useRef<HTMLTableElement>(null)

  const rangeExtractor = useCallback((range: Range) => {
    // Full page of rows
    return Array.from(
      { length: Math.min(range.endIndex + range.overscan, range.count) },
      (_, i) => i
    )
  }, [])

  const shouldShowAddButtons = useShouldShowAddButtonsFn(project)

  function getRowHeight(row: Row<Group<NormalTaskWithRelations>>): number {
    const isGroupHeader = row.getParentRow() == null
    const hasM5cStagesAddTask =
      hasFlowsM5c && sort === 'STAGES' && row.subRows.length === 0

    const showAddTaskForm =
      (sort === 'STATUS' || hasM5cStagesAddTask) && addTaskExpanded[row.id]

    return isGroupHeader
      ? HEADER_GROUP_HEIGHT +
          SPACER_HEIGHT +
          (showAddTaskForm ? INLINE_ADD_TASK_HEIGHT : 0)
      : TASK_LINE_HEIGHT
  }

  const rowVirtualizer = useVirtualizer({
    count: rowsToUse.length,
    estimateSize: (index) => {
      const row = rowsToUse[index]
      return getRowHeight(row)
    },
    getScrollElement: () => tableContainerRef.current,
    getItemKey: (index) => rowsToUse[index].id,
    measureElement(element, entry, instance) {
      const index = instance.indexFromElement(element)
      const row = rowsToUse[index]
      let baseHeight = getRowHeight(row)

      const { showAddTaskButton, parentRow, showAddStageButton } =
        shouldShowAddButtons({
          index,
          virtualItems,
          rowsToUse,
          row,
          sort,
          enableInlineAdd,
          hasFlowsM5c,
        })

      if (isTaskRow(row) && virtualItems) {
        if (showAddStageButton) {
          baseHeight += INLINE_ADD_STAGE_BUTTON_HEIGHT
        }

        if (parentRow && showAddTaskButton && addTaskExpanded[parentRow.id]) {
          baseHeight += INLINE_ADD_TASK_HEIGHT
        }
        if (showAddTaskButton) {
          baseHeight += INLINE_ADD_TASK_BUTTON_HEIGHT
        }
      } else if (!isTaskRow(row)) {
        if (showAddStageButton) {
          baseHeight += INLINE_ADD_STAGE_BUTTON_HEIGHT
        }

        if (showAddTaskButton) {
          baseHeight += INLINE_ADD_TASK_BUTTON_HEIGHT
        }
      }

      return baseHeight
    },
    rangeExtractor,
    overscan: 10,
  })

  if (rowsToUse.length === 0) {
    return null
  }

  const virtualItems = rowVirtualizer.getVirtualItems()
  const range = {
    startIndex: rowVirtualizer.range?.startIndex ?? 0,
    endIndex: rowVirtualizer.range?.endIndex ?? 0,
    overscan: rowVirtualizer.options.overscan,
    count: rowVirtualizer.options.count,
  }

  const showStageTimeline =
    sort === 'STAGES' && stages != null && stages.length > 0 && project != null

  const initialStartDate = project?.startDate
    ? {
        startDate: project.startDate,
      }
    : undefined

  return (
    <>
      {showStageTimeline && (
        <StageTimelineContainer>
          <StageTimeline project={project} stages={stages} />
        </StageTimelineContainer>
      )}
      <TableContainer
        ref={tableContainerRef}
        className='scrollbar-gutter-stable'
        sort={sort}
      >
        <div
          className='flex flex-col whitespace-nowrap text-ellipsis group/tasks-sidebar-group'
          style={{
            height: rowVirtualizer.getTotalSize(),
          }}
        >
          {virtualItems.map((virtual) => {
            const row = rowsToUse[virtual.index]

            return (
              <VirtualSidebarTaskItem
                key={virtual.key}
                virtual={virtual}
                virtualItems={virtualItems}
                row={row}
                rowVirtualizer={rowVirtualizer}
                range={range}
                rowHeight={getRowHeight(row)}
                sort={sort}
                initialStartDate={initialStartDate}
                addTaskExpanded={addTaskExpanded}
                setAddTaskExpanded={setAddTaskExpanded}
                rowsToUse={rowsToUse}
              />
            )
          })}
        </div>
      </TableContainer>
    </>
  )
}

const TableContainer = classed(
  'div',
  'h-full overflow-y-auto overflow-x-hidden',
  {
    variants: {
      sort: {
        STAGES: 'pl-1',
        STATUS: '',
        NO_STAGE: '',
      },
    },
  }
)

const StageTimelineContainer = classed('div', {
  base: 'bg-semantic-neutral-bg-active-hover',
})
