import { type ThreadSchema } from '@motion/rpc-types'
import { Sentry } from '@motion/web-base/sentry'

import { useUpdateNoteThread as useUpdateNoteThreadMutation } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useResolveNoteComment = () => {
  const { mutateAsync: updateNoteThread } = useUpdateNoteThreadMutation()

  return useCallback(
    async (threadId: ThreadSchema['id']) => {
      try {
        await updateNoteThread({
          id: threadId,
          isResolved: true,
        })
      } catch (e) {
        const error = e instanceof Error ? e : new Error(String(e))
        Sentry.captureException(error, {
          tags: {
            position: 'useResolveNoteComment',
          },
        })

        showErrorToast('An error occurred. Please try again.')
        throw error
      }
    },
    [updateNoteThread]
  )
}
