import { type NoteSchema, type NoteSnapshotSchema } from '@motion/rpc-types'
import {
  Button,
  FieldButton,
  SearchableDropdown,
  showToast,
} from '@motion/ui/base'
import { formatDateTimeDetailed } from '@motion/ui-logic'

import { ConnectedUserLabel } from '~/global/components/labels'
import { useApplyNoteSnapshot, useNoteSnapshots } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useEffect, useState } from 'react'

type NoteSnapshotRollbackProps = {
  noteId: NoteSchema['id']
}

const RollbackToastContent = () => {
  const [seconds, setSeconds] = useState(3)

  useEffect(() => {
    if (seconds >= 0) {
      setTimeout(() => {
        setSeconds((prev) => prev - 1)
      }, 1000)
    }
  }, [seconds])

  return <>Rollback applied, will reload in {seconds}s...</>
}

export const NoteSnapshotRollback = ({ noteId }: NoteSnapshotRollbackProps) => {
  const [selectedSnapshotId, setSelectedSnapshotId] = useState<
    NoteSnapshotSchema['id'] | null
  >(null)

  const { data: noteSnapshots } = useNoteSnapshots({ id: noteId })

  const { mutate: applySnapshot } = useApplyNoteSnapshot()

  const handleRollback = () => {
    if (selectedSnapshotId == null) {
      return showErrorToast('Please select a snapshot to rollback to')
    }

    applySnapshot(
      { id: noteId, snapshotId: selectedSnapshotId },
      {
        onSuccess: () => {
          showToast('success', <RollbackToastContent />)
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        },
        onError: (error) => {
          showErrorToast(error)
        },
      }
    )
  }

  const renderSnapshotLabel = (id: string) => {
    const noteSnapshot = noteSnapshots?.models.noteSnapshots[
      id as unknown as keyof typeof noteSnapshots.models.noteSnapshots
    ] as NoteSnapshotSchema

    if (noteSnapshot == null) {
      return <>No snapshot</>
    }

    return (
      <span className='flex justify-between gap-1 w-[330px]'>
        {formatDateTimeDetailed(noteSnapshot.createdTime)}
        <ConnectedUserLabel userId={noteSnapshot.createdByUserId} />
      </span>
    )
  }

  return (
    <div className='flex flex-col gap-2 p-3 w-[400px] rounded border border-semantic-warning-border-default bg-semantic-warning-bg-default text-semantic-warning-text-default'>
      <span className='font-bold'>Rollback to a previous snapshot</span>
      <span>
        We should only ever roll back if reloading or dismissing the error
        screen did not work.
      </span>
      <SearchableDropdown
        items={Object.keys(noteSnapshots?.models.noteSnapshots || {})}
        renderItem={renderSnapshotLabel}
        onChange={(id) => setSelectedSnapshotId(id)}
      >
        <FieldButton fullWidth>
          {selectedSnapshotId
            ? renderSnapshotLabel(selectedSnapshotId)
            : 'Select a snapshot'}
        </FieldButton>
      </SearchableDropdown>

      <div className='flex justify-end'>
        <Button onClick={handleRollback} sentiment='warning'>
          Rollback
        </Button>
      </div>
    </div>
  )
}
