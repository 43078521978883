import { API } from '@motion/rpc-definitions'
import {
  byProperty,
  byValue,
  cascade,
  Compare,
  ordered,
} from '@motion/utils/array'
import {
  type NormalTaskSchema,
  type ProjectSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { useLookup } from '~/global/cache'
import { createTaskProxy, type NormalTaskWithRelations } from '~/global/proxies'
import { useTasksV2 } from '~/global/rpc/v2'
import { useMemo } from 'react'

export interface ProjectTasksOptions {
  workspaceId?: WorkspaceSchema['id'] | null
  projectId?: ProjectSchema['id'] | null
  completed?: 'include' | 'exclude' | 'only'
  canceled?: 'include' | 'exclude' | 'only'
}

/**
 * This queries for tasks in a project.
 * Use with caution - do not use in loops.
 *
 * If you only need the tasks currently in the cache for the project,
 * use `useCachedProjectTasks`
 */
export function useFetchProjectTasks(options: ProjectTasksOptions): {
  data: NormalTaskWithRelations[]
  isLoading: boolean
} {
  const {
    workspaceId,
    projectId,
    completed = 'include',
    canceled = 'include',
  } = options

  const lookup = useLookup()

  const { data, isPending: isLoading } = useTasksV2<NormalTaskSchema>(
    {
      $version: 2,
      filters: [
        {
          workspaceIds: [workspaceId ?? ''],
          type: ['NORMAL'],
          projectIds: { operator: 'in', value: [projectId ?? ''] },
          completed,
          canceled,
        },
      ],
      include: API.tasksV2.taskAllIncludes,
    },
    { enabled: !!workspaceId && !!projectId }
  )

  return useMemo(() => {
    if (!data)
      return {
        data: [] as NormalTaskWithRelations[],
        isLoading,
      }

    const taskProxies = data.map((task) =>
      createTaskProxy(task, lookup)
    ) as NormalTaskWithRelations[]

    return {
      data: taskProxies.sort(
        cascade(
          byProperty('rank', Compare.exists),
          byProperty('rank', Compare.string),
          byValue(
            (item) => lookup('statuses', item.statusId)?.sortPosition,
            Compare.string
          ),
          byProperty('priorityLevel', ordered(['ASAP'])),
          byProperty('dueDate', Compare.exists),
          byProperty('dueDate', Compare.string),
          byProperty('name', Compare.string)
        )
      ),
      isLoading,
    }
  }, [data, lookup, isLoading])
}
