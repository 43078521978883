import { type StageDefinitionSchema } from '@motion/rpc-types'
import {
  type DeadlineInterval,
  type RelativeIntervalUnit,
} from '@motion/shared/flows'
import { PopoverTrigger } from '@motion/ui/base'
import { type TaskDefinitionFormRelativeInterval } from '@motion/ui-logic/pm/project'

import { type ReactNode, useCallback } from 'react'

import { DurationUnitDropdown } from './duration-unit-dropdown'
import { DurationValueDropdown } from './duration-value-dropdown'
import { PlusMinusDropdown } from './plus-minus-dropdown'
import { RelativeIntervalReferenceTypeDropdown } from './relative-interval-reference-type-dropdown'
import { Container, Row, Title } from './styled'

export type RelativeDateDropdownProps = {
  color: StageDefinitionSchema['color']
  children: ReactNode
  type: 'start' | 'deadline'
  value: TaskDefinitionFormRelativeInterval
  onChange: (
    valueOrFn:
      | TaskDefinitionFormRelativeInterval
      | ((
          prev: TaskDefinitionFormRelativeInterval
        ) => TaskDefinitionFormRelativeInterval)
  ) => void
  stageDuration: DeadlineInterval
}

export const RelativeDateDropdown = ({
  color,
  type,
  children,
  value,
  onChange,
  stageDuration,
}: RelativeDateDropdownProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={() => (
        <RelativeDateDropdownContent
          color={color}
          type={type}
          value={value}
          onChange={onChange}
          stageDuration={stageDuration}
        />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

const RelativeDateDropdownContent = ({
  color,
  type,
  value,
  onChange,
  stageDuration,
}: Omit<RelativeDateDropdownProps, 'children'>) => {
  const { duration } = value

  const onDurationChange = useCallback(
    (newDuration: typeof duration) =>
      onChange((newRelativeInterval: TaskDefinitionFormRelativeInterval) => ({
        ...newRelativeInterval,
        duration: newDuration,
      })),
    [onChange]
  )

  const onSignChange = useCallback(
    (sign: -1 | 1) =>
      onChange((newRelativeInterval: TaskDefinitionFormRelativeInterval) => ({
        ...newRelativeInterval,
        duration: { ...duration, sign },
      })),
    [onChange, duration]
  )

  const onDurationValueChange = useCallback(
    (value: number) => onDurationChange({ ...duration, value }),
    [onDurationChange, duration]
  )

  const onDurationUnitChange = useCallback(
    (unit: RelativeIntervalUnit) => onDurationChange({ ...duration, unit }),
    [onDurationChange, duration]
  )

  return (
    <Container>
      <Title>{type === 'start' ? 'Start Date' : 'Deadline'}</Title>

      <Row>
        <RelativeIntervalReferenceTypeDropdown
          color={color}
          relativeInterval={value}
          onChange={onChange}
        />
        <PlusMinusDropdown
          value={value.duration.sign}
          onChange={onSignChange}
        />
        <DurationValueDropdown
          duration={duration}
          onChange={onDurationValueChange}
          stageDuration={stageDuration}
        />
        <DurationUnitDropdown
          duration={duration}
          onChange={onDurationUnitChange}
          stageDuration={stageDuration}
        />
      </Row>
    </Container>
  )
}
