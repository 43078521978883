import { CheckSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { Button, UnstyledModal } from '@motion/ui/base'
import {
  featureToMotionEnterpriseText,
  featureToMotionProText,
  getTierTitle,
  type Tier,
} from '@motion/ui-logic/tiered-pricing'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import {
  type Feature,
  getTierIcon,
  useEnterpriseLink,
} from '@motion/web-billing'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useModalApi } from '@motion/web-common/modals'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  useExistingSubDefaultBucketSeats,
  useUpsellHiddenTiers,
} from '../hooks'

export interface TierUpgradePromptModalProps {
  feature?: Feature
  tier: Exclude<Tier, 'BASIC'>
}

declare module '@motion/web-common/modals/definitions' {
  export interface ModalDefinitions {
    'tier-upgrade-prompt-modal': TierUpgradePromptModalProps
  }
}

export function TierUpgradePromptModal({
  feature,
  tier,
  close,
}: ModalTriggerComponentProps<'tier-upgrade-prompt-modal'>) {
  const initialBucket = useExistingSubDefaultBucketSeats()
  const hiddenTiers = useUpsellHiddenTiers()
  const user = useAuthenticatedUser()
  const openEnterpriseLink = useEnterpriseLink()

  const modalApi = useModalApi()
  const openChooseFeatureTierModal = () => {
    close()

    if (isEnterprise) {
      openEnterpriseLink(
        'tier-upgrade-prompt-modal',
        user.email,
        user.email,
        user.displayName
      )
      return
    }

    modalApi.open('choose-feature-tier-modal', {
      mode: 'upsell',
      initialBucket,
      hiddenTiers,
      onSelectFeatureTier: (selectedTier, term, bucket) => {
        modalApi.open('tiered-checkout-modal', {
          tier: selectedTier,
          selectedBucket: bucket,
          selectedTerm: term,
          onBillingComplete: () => modalApi.dismissAll(),
        })
      },
    })

    recordAnalyticsEvent('TIERED_PRICING_UPSELL_MODAL_ACTION', {
      action: 'upgrade',
    })
  }

  const handleDismiss = () => {
    recordAnalyticsEvent('TIERED_PRICING_UPSELL_MODAL_ACTION', {
      action: 'dismiss',
    })
    close()
  }

  const isAi = tier === 'PROAI'
  const isEnterprise = tier === 'ENTERPRISE'
  let includes: string[] = []
  switch (tier) {
    case 'PRO':
      includes = proIncludes
      break
    case 'PROAI':
      includes = proAiIncludes
      break
    case 'ENTERPRISE':
      includes = enterpriseIncludes
      break
  }

  const tierTitle = 'Motion ' + getTierTitle(tier)
  const TierIcon = getTierIcon(tier)

  const offerTrial = false
  const buttonText = isEnterprise
    ? 'Contact us'
    : offerTrial
      ? `Try ${tierTitle} for free`
      : 'Continue to Upgrade'

  useEffect(() => {
    if (tier && feature) {
      recordAnalyticsEvent('TIER_UPGRADE_PROMPT_MODAL_OPENED', {
        tier,
        feature,
      })
    }
  }, [tier, feature])

  const tierIcon = TierIcon && <TierIcon />

  return (
    <UnstyledModal
      type='page'
      withAnimation
      overlayClassName='bg-modal-overlay'
      visible
      onClose={close}
    >
      <div
        className={twMerge(
          'flex flex-col p-6 w-[600px] border border-semantic-neutral-border-default rounded-lg text-semantic-neutral-text-default',
          isAi ? 'bg-pro-ai-gradient' : 'bg-pro-gradient'
        )}
      >
        <div className='text-lg flex'>
          {tierIcon}
          <h1 className='ml-1 font-semibold'>
            {templateStr('Upgrade to {{tierTitle}}{{featureStr}}', {
              tierTitle,
              featureStr: feature
                ? ' to ' +
                  (tier === 'ENTERPRISE'
                    ? featureToMotionEnterpriseText[feature]
                    : featureToMotionProText[feature])
                : '',
            })}
          </h1>
        </div>
        <div className='mt-6 text-sm/5'>{tierTitle} includes:</div>
        <ul>
          {includes.map((includeLine) => (
            <li className='text-xs flex mt-2' key={includeLine}>
              <CheckSolid className='text-semantic-primary-icon-default h-4 w-4 shrink-0' />
              <div className='ml-2'>{includeLine}</div>
            </li>
          ))}
        </ul>
        <div className='mt-6'>
          <Button onClick={openChooseFeatureTierModal} fullWidth>
            {buttonText} {tierIcon}
          </Button>
        </div>
        <div className='mt-3'>
          <Button
            sentiment='neutral'
            variant='muted'
            onClick={handleDismiss}
            fullWidth
          >
            Cancel
          </Button>
        </div>
      </div>
    </UnstyledModal>
  )
}

// TODO: Move these to ui-logic
const proIncludes = [
  'Auto-schedule tasks up to 3 months in advance',
  'Unlimited task and project templates',
  'Unlimited custom fields',
  '50 GB attachments storage',
  'Task & project activity/comments (90 day history)',
  'Gantt charts to see your projects’ timelines',
  'Charts and dashboards',
  'Create and send your own booking links',
  'Zapier, API & Slack integration',
]

const proAiIncludes = [
  'AI Project Creation',
  'AI Notetaker - record meetings, AI generated meeting notes & tasks',
  'AI Email',
  ...proIncludes,
]

const enterpriseIncludes = [
  'Unlimited attachments storage',
  'Role Based Access Control (RBAC)',
  'Single Sign On',
  '2 Factor Authentication',
  'Guest users',
  'Task & project activity/comments (unlimited history)',
  'Ongoing dedicated project management expert for customer success',
]
