import { AutoscheduleStarSolid } from '@motion/icons'
import { isCanceledStatus } from '@motion/shared/common'
import { classed } from '@motion/theme'
import { Text } from '@motion/ui/base'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type NormalTaskSchema, type ProjectSchema } from '@motion/zod/client'

import { useWorkspaceStatusById } from '~/global/hooks'
import { twMerge } from 'tailwind-merge'

import { TaskRowStatus } from './task-row-status'

import { useUpdateTasksFn } from '../../../hooks'

export type TaskRowProps = {
  task: NormalTaskSchema
  isAiModified: boolean
  project: ProjectSchema
  disabled: boolean
}

export const TaskRow = ({
  task,
  project,
  isAiModified,
  disabled,
}: TaskRowProps) => {
  const status = useWorkspaceStatusById(task.statusId)
  const modalApi = useModalApi()
  const updateTasks = useUpdateTasksFn()

  const onClickRow = async () => {
    const response = await modalApi.prompt('speculative-task-modal', {
      task,
      project,
    })
    if (response === ModalDismissed) return
    if (response.type !== 'update') return

    updateTasks([task], () => response.data)
  }

  return (
    <RowButton type='button' onClick={onClickRow} disabled={disabled}>
      {isAiModified ? (
        <AutoscheduleStarSolid className='size-3 text-semantic-purple-icon-disabled' />
      ) : (
        <div className='size-3' />
      )}

      <TaskRowStatus task={task} disabled={disabled} />

      <Text
        size='xs'
        truncate
        className={twMerge(isCanceledStatus(status) && 'line-through')}
        sentiment={isCanceledStatus(status) ? 'subtle' : 'default'}
      >
        {task.name}
      </Text>
    </RowButton>
  )
}

const RowButton = classed('button', {
  base: `
  flex flex-row items-center gap-2
  px-1 py-1.5 h-full
  rounded

  hover:bg-semantic-neutral-surface-bg-subtle
  `,
})
