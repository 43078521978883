import {
  type FolderSchema,
  type NoteSchema,
  type ProjectSchema,
  type WorkspaceSchema,
} from '@motion/rpc-types'
import { classed } from '@motion/theme'
import { useHasTreatment } from '@motion/web-common/flags'

import { type ReactNode, useMemo } from 'react'

import { LabelContextProvider } from './context'
import { ConnectedFolderLabel } from './folder'
import { ConnectedNoteLabel } from './note'
import { ConnectedProjectLabel } from './project'
import { ConnectedWorkspaceLabel } from './workspace'

import { type BadgeSize } from '../badges'

export type BreadWrapperPiece =
  | {
      type: 'workspace'
      value: WorkspaceSchema['id']
    }
  | {
      type: 'project'
      value: ProjectSchema['id']
    }
  | {
      type: 'folder'
      value?: FolderSchema['id']
    }
  | {
      type: 'note'
      value?: NoteSchema['id']
    }
  | {
      type: 'custom'
      value: ReactNode
    }
  | {
      type: 'text'
      value: string
    }

export type BreadcrumbsLabelProps = {
  crumbs: (BreadWrapperPiece | false)[]
  size?: BadgeSize
  clickable?: boolean
  nameOnly?: boolean
}

export function BreadcrumbsLabel({
  crumbs,
  size,
  clickable = false,
  nameOnly = false,
}: BreadcrumbsLabelProps) {
  const hasBrowseViewEnabled = useHasTreatment('browse-view')
  const labelCtxValue = useMemo(
    () => ({
      shouldGoToNavigate: hasBrowseViewEnabled,
    }),
    [hasBrowseViewEnabled]
  )

  return (
    <LabelContextProvider value={labelCtxValue}>
      <Wrapper>
        {crumbs.filter(Boolean).map(({ type, value }, index) => {
          let label: ReactNode

          switch (type) {
            case 'workspace': {
              label = (
                <ConnectedWorkspaceLabel
                  id={value}
                  size={size}
                  clickable={clickable}
                  nameOnly={nameOnly}
                />
              )
              break
            }
            case 'project': {
              label = (
                <ConnectedProjectLabel
                  id={value}
                  size={size}
                  clickable={clickable}
                  nameOnly={nameOnly}
                />
              )
              break
            }
            case 'folder': {
              label = (
                <ConnectedFolderLabel
                  id={value}
                  size={size}
                  clickable={clickable}
                  nameOnly={nameOnly}
                />
              )
              break
            }
            case 'note': {
              label = (
                <ConnectedNoteLabel
                  id={value}
                  size={size}
                  clickable={clickable}
                  nameOnly={nameOnly}
                />
              )
              break
            }
            case 'custom':
            case 'text': {
              label = value
              break
            }
            default:
              return null
          }

          return <Crumb key={`${type}-${value}-${index}`}>{label}</Crumb>
        })}
      </Wrapper>
    </LabelContextProvider>
  )
}

const Wrapper = classed('ul', {
  base: `
    inline-flex items-center gap-1
    overflow-hidden
  `,
})

const Crumb = classed('li', {
  base: `
    flex gap-1 overflow-hidden

    max-w-[120px]
    after:content-['/']

    last:max-w-[200px]
    last:after:hidden
  `,
})
