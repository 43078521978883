import { UnstyledModal } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { BillingContainer } from '~/areas/billing'
import { type ModalTriggerComponentProps } from '~/areas/modals'

export interface ConnectedStartBillingModalProps {
  teamSelected: boolean
}

export function StartBillingModal({
  teamSelected,
  close,
}: ModalTriggerComponentProps<'start-billing-modal'>) {
  const modalApi = useModalApi()

  return (
    <UnstyledModal
      type='page'
      withAnimation
      overlayClassName='bg-modal-overlay'
      visible
      onClose={close}
    >
      <div className='flex flex-col bg-semantic-neutral-bg-subtle border border-semantic-neutral-border-default w-[95vw] max-w-[1364px] max-h-[1000px] lg:max-h-[724px] rounded-lg'>
        <BillingContainer
          onComplete={() => {
            modalApi.dismiss('start-billing-modal')
            modalApi.dismiss('choose-plan-modal')
          }}
          isTeam={teamSelected}
          onBack={() => {
            modalApi.dismiss('start-billing-modal')
            modalApi.open('choose-plan-modal')
          }}
          checkoutType='add-payment-method'
        />
      </div>
    </UnstyledModal>
  )
}
