import { AlertDialog } from '@motion/ui/base'
import { NOOP_FUNCTION } from '@motion/utils/function'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useOnboardingApi } from '~/areas/onboarding/state/use-onboarding-api'
import { useHasSeenCta } from '~/global/hooks'
import { useUpdateMyCtaSettings } from '~/global/rpc/v2'
import { DateTime } from 'luxon'
import { useEffect } from 'react'

type DownloadMobilePopupProps = {
  open?: boolean
  setOpen?: (open: boolean) => void
}
interface DownloadMobilePopupContentProps {
  onDismiss?: () => void
}

export const DownloadMobilePopup = ({
  open,
  setOpen = NOOP_FUNCTION,
}: DownloadMobilePopupProps) => {
  const { selectLastOnboardingTimestamp } = useOnboardingApi()
  const lastOnboardTimestamp = selectLastOnboardingTimestamp()

  const hasSeenCta = useHasSeenCta()
  const { mutate: updateCtaSettings } = useUpdateMyCtaSettings()

  const showCta = hasSeenCta(`DISMISSED_MOBILE_APP_DOWNLOAD_PROMPT`)

  useEffect(
    function checkShouldShowDownloadMobile() {
      if (!showCta.loaded) return
      if (showCta.seen) {
        return
      }
      const hoursSinceOnboard = lastOnboardTimestamp
        ? DateTime.now().diff(
            DateTime.fromMillis(lastOnboardTimestamp.valueOf()),
            'hours'
          ).hours
        : 0
      if (hoursSinceOnboard > 24 && hoursSinceOnboard < 96) {
        setOpen(true)
      }
    },
    [lastOnboardTimestamp, setOpen, showCta.loaded, showCta.seen]
  )

  if (!open) return null
  return (
    <DownloadMobilePopupContent
      onDismiss={() => {
        recordAnalyticsEvent('MOBILE_DOWNLOAD_CTA_DISMISSED')
        void updateCtaSettings({
          ctaSettings: {
            DISMISSED_MOBILE_APP_DOWNLOAD_PROMPT: true,
          },
        })
        setOpen(false)
      }}
    />
  )
}

const DownloadMobilePopupContent = ({
  onDismiss = NOOP_FUNCTION,
}: DownloadMobilePopupContentProps) => {
  useEffect(() => {
    recordAnalyticsEvent('MOBILE_DOWNLOAD_CTA_SHOWN')
  }, [])

  return (
    <AlertDialog
      visible
      title='Use Motion on the go with our mobile app'
      description='Manage your calendar and tasks with our mobile app.'
      dismissible
      onClose={() => {
        onDismiss()
      }}
      actions={[
        {
          label: 'Download mobile app',
          onClick: () => {
            recordAnalyticsEvent('MOBILE_DOWNLOAD_CTA_CLICKED', {
              view: 'download_popup',
            })
            onDismiss()
            window.open('https://www.usemotion.com/mobile', '_blank')
          },
          value: '',
        },
        {
          label: 'Maybe later',
          sentiment: 'neutral',
          onClick: () => onDismiss(),
          value: 'remindMeLater',
        },
      ]}
    />
  )
}
