import { Portal } from '@motion/ui/base'
import { useHasTreatment } from '@motion/web-common/flags'

import {
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'
import {
  ConnectedStageTaskCard,
  type StageColumnsProps,
} from '~/areas/flows/flow-template-modal'
import {
  useDeprecatedTemplateTasks,
  useTemplateTasks,
} from '~/areas/flows/flow-template-modal/hooks'
import { useFlowTemplateForm } from '~/areas/flows/shared-form'

import { DraggableStageTaskWrapper } from './draggable-stage-task-card'

import { useTemplateActiveTask, useTemplateDndHandlerHooks } from '../hooks'

export const StageColumnsDnd = ({
  fieldArray,
  children,
}: Pick<StageColumnsProps, 'fieldArray'> & {
  children: React.ReactNode
}) => {
  const {
    form: { watch },
  } = useFlowTemplateForm()

  const stages = watch('stages')

  const templateTasks = useTemplateTasks()
  const deprecatedTemplateTasks = useDeprecatedTemplateTasks()
  const isMultipleBlockersEnabled = useHasTreatment('flows-multiple-blockers')
  const currentTemplateTasks = isMultipleBlockersEnabled
    ? templateTasks
    : deprecatedTemplateTasks

  const { activeTask, setActiveId, stageTasksPath, activeTaskIndex } =
    useTemplateActiveTask(stages, currentTemplateTasks.all)

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 0 } })
  )

  const handlers = useTemplateDndHandlerHooks({
    setActiveId,
    replace: fieldArray.replace,
  })

  return (
    <DndContext sensors={sensors} {...handlers}>
      <SortableContext items={currentTemplateTasks.ids}>
        {children}
      </SortableContext>
      <Portal>
        <DragOverlay
          dropAnimation={{
            duration: 0,
          }}
        >
          {activeTask != null &&
            activeTaskIndex != null &&
            stageTasksPath != null && (
              <DraggableStageTaskWrapper isDragging>
                <ConnectedStageTaskCard
                  task={activeTask}
                  stageTasksPath={stageTasksPath}
                  taskBasePath={`${stageTasksPath}.${activeTaskIndex}` as const}
                />
              </DraggableStageTaskWrapper>
            )}
        </DragOverlay>
      </Portal>
    </DndContext>
  )
}
