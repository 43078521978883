import { NoteSolid, ProjectCubeSolid, StatusSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { LoadingSpinner } from '@motion/ui/base'
import { capitalize } from '@motion/utils/string'
import { Sentry } from '@motion/web-base/sentry'

import { useCreateNoteMention } from '~/global/hooks/notes/use-create-note-mention'
import { showErrorToast } from '~/global/toasts'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { twMerge } from 'tailwind-merge'
import { v4 } from 'uuid'

import { useInlineCreate } from './use-inline-create'
import { isBlurringWithKeyboard } from './utils'

export type InlineCreateTagProps = {
  entityType: string
  onBlur: () => void
  onCreated: (args: {
    entityId: string
    entityLabel: string
    entityType: string
  }) => void
}

export function InlineCreateTag({
  entityType,
  onBlur,
  onCreated,
}: InlineCreateTagProps) {
  const { noteId } = useParams()

  const inputRef = useRef<HTMLInputElement>(null)

  // Fix for Safari blurring the input on key down
  const canBlurRef = useRef<boolean>(true)

  const [name, setName] = useState('')

  const createNoteMention = useCreateNoteMention()

  const { create, isCreating } = useInlineCreate()

  useEffect(() => {
    if (inputRef.current == null) {
      return
    }

    const input = inputRef.current

    function updateInputWidth() {
      if (input != null) {
        input.style.width = '0px'
        input.style.width = input.scrollWidth + 'px'
      }
    }

    input.addEventListener('input', updateInputWidth)

    return () => {
      if (input != null) {
        input.removeEventListener('input', updateInputWidth)
      }
    }
  }, [])

  const handleBlur = () => {
    if (canBlurRef.current === false) return
    if (!name.trim()) {
      onBlur()
    } else {
      handleCreate()
    }
  }

  const handleCreate = async () => {
    const trimmedName = name.trim()
    if (!trimmedName) {
      showErrorToast('Name is required')
      return
    }
    canBlurRef.current = false

    try {
      const { entityId, entityLabel } = await create(entityType, trimmedName)

      if (noteId != null) {
        createNoteMention({
          noteId,
          mentionId: v4(),
          targetId: entityId,
          targetType: entityType,
        })
      }

      onCreated({ entityId, entityLabel, entityType })
    } catch (error) {
      showErrorToast(error)

      Sentry.captureException(
        new Error(`Docs: Failed to inline create ${entityType}`),
        {
          extra: {
            name: trimmedName,
            entityType,
            error,
          },
        }
      )
    } finally {
      canBlurRef.current = true
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isBlurringWithKeyboard(e)) {
      e.preventDefault()
      e.stopPropagation()

      return onBlur()
    }

    if (e.key === 'Enter') {
      handleCreate()
    }
  }

  return (
    <Wrapper>
      {isCreating ? (
        <LoadingSpinner size={16} />
      ) : entityType === 'task' ? (
        <StatusSolid className='size-4 text-semantic-neutral-icon-default' />
      ) : entityType === 'project' ? (
        <ProjectCubeSolid className='size-4 text-semantic-neutral-icon-default' />
      ) : entityType === 'note' ? (
        <NoteSolid className='size-4 text-semantic-neutral-icon-default' />
      ) : null}
      <Input
        ref={inputRef}
        placeholder={`${entityType !== 'note' ? capitalize(entityType) : 'Doc'} name`}
        value={name}
        onChange={(e) => setName(e.target.value)}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        disabled={isCreating}
        className={twMerge(
          entityType === 'task' && 'w-[73px] min-w-[73px]',
          entityType === 'project' && 'w-[90px] min-w-[90px]',
          entityType === 'note' && 'w-[74px] min-w-[74px]'
        )}
        autoFocus
      />
    </Wrapper>
  )
}

const Wrapper = classed('span', {
  base: `
    bg-semantic-neutral-surface-bg-subtle h-[20px]
    inline-flex align-text-bottom items-center gap-1 px-1 py-0.5 rounded
  `,
})

const Input = classed('input', {
  base: `
    bg-transparent border-none outline-none
    text-semantic-neutral-text-default w-24 min-w-24
  `,
})
