import { classed } from '@motion/theme'
import { useTitle } from '@motion/web-common/html'

import React from 'react'
import { Outlet } from 'react-router'

export const CalendarShell = () => {
  useTitle('Calendar')

  return (
    <ShellContainer>
      <Outlet />
    </ShellContainer>
  )
}

const ShellContainer = classed('div', {
  base: 'relative z-0 grid h-full min-h-0 w-full grid-cols-[1fr_auto]',
})
