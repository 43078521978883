import { createUseMutation, createUseQuery } from '@motion/rpc'
import { API } from '@motion/rpc-definitions'

export const useIndividualAndTeamSubscription = createUseQuery(
  API.subscriptions.getIndividualAndTeamSubscription
)

export const useIndividualSubscription = () => {
  const { data } = useIndividualAndTeamSubscription()
  return data?.individual.details?.subscription
}

export const useTeamSubscription = () => {
  const { data } = useIndividualAndTeamSubscription()
  return data?.team.details?.subscription
}

export const useUtilizedSubscription = () => {
  const { data } = useIndividualAndTeamSubscription()
  return data?.combined?.subscription
}

export const useGetSubscriptionPaymentMethod = createUseQuery(
  API.subscriptions.getSubscriptionPaymentMethod
)

export const useUpdateDefaultPaymentMethod = createUseMutation(
  API.subscriptions.updateDefaultPaymentMethod
)

export const useUpdateSubscriptionWithTier = createUseMutation(
  API.subscriptions.updateSubscriptionFeatureTier
)
