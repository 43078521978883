import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { MotionLink } from '~/global/components'
import { useUriByGlobalModalId } from '~/global/navigation'
import { useUriByRouteId } from '~/routing'
import { type ReactNode } from 'react'

import { useActivityFeedContext } from '../../../providers'

type ActivityLinkProps = {
  type: 'task' | 'project' | 'workspace' | 'attachment' | 'folder'
  targetId?: string | null
  /**
   * Label for the link
   */
  children?: ReactNode
}

export function ActivityLink({ type, targetId, children }: ActivityLinkProps) {
  const getModalUri = useUriByGlobalModalId()
  const getRouteUri = useUriByRouteId()

  const { workspaceId } = useActivityFeedContext()

  if (!targetId) {
    return (
      <span className='text-semantic-neutral-text-default'>{children}</span>
    )
  }

  let url: string
  switch (type) {
    case 'task':
      url = getModalUri('task', {
        task: targetId,
      })
      break
    case 'project':
      url = getModalUri('project', {
        project: targetId,
      })
      break
    case 'workspace':
      url = getRouteUri('workspace-detail', {
        workspaceId: targetId,
      })
      break
    case 'attachment':
      url = getRouteUri('attachment-preview', {
        attachmentId: targetId,
      })
      break
    case 'folder':
      url = getRouteUri('workspace-folder', {
        workspaceId,
        folderId: targetId,
      })
      break
  }

  const onClick = () => {
    recordAnalyticsEvent('ACTIVITY_FEED_LINK_CLICK', {
      url,
      type,
    })
  }

  return (
    <MotionLink
      url={url}
      onClick={onClick}
      className='underline text-semantic-primary-text-default underline-offset-2'
    >
      {children}
    </MotionLink>
  )
}
