import {
  isEnabledStage,
  isValidStageDeadline,
} from '@motion/ui-logic/pm/project'

import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { SetupProjectFormNavigationContext } from './setup-project-form-navigation-context'

import { useSetupProjectForm } from '../use-setup-project-form'
import { useSetupProjectModalSubmitHandler } from '../use-setup-project-modal-submit-handler'
import { useTabOptions } from '../use-tab-options'

export const SetupProjectFormNavigationProvider = ({
  children,
  close,
}: {
  children: React.ReactNode
  close: () => void
}) => {
  const value = useSetupProjectFormNavigation(close)

  return (
    <SetupProjectFormNavigationContext.Provider value={value}>
      {children}
    </SetupProjectFormNavigationContext.Provider>
  )
}

export type SetupProjectFormNavigationContextType = ReturnType<
  typeof useSetupProjectFormNavigation
>

const useSetupProjectFormNavigation = (close: () => void) => {
  const {
    form: {
      watch,
      formState: { isSubmitting },
      setValue,
    },
  } = useSetupProjectForm()
  const onSubmit = useSetupProjectModalSubmitHandler(close)

  const activeTab = watch('step')
  const tabOptions = useTabOptions()

  const name = watch('name')
  const textReplacements = watch('textReplacements')
  const stages = watch('speculativeProject.stages')
  const startDate = watch('startDate')
  const dueDate = watch('dueDate')

  let disabledMessage = null

  const isNameTabDisabled =
    activeTab === 'name' && (!name || name.trim().length === 0)

  const isTextVariablesTabDisabled =
    activeTab === 'textVariables' &&
    !textReplacements.every((textReplacement) => textReplacement.value)

  const isStagesTabDisabled =
    activeTab === 'stages' &&
    (stages == null ||
      !stages.filter(isEnabledStage).every((s) =>
        isValidStageDeadline(DateTime.fromISO(s.dueDate), s.stageDefinitionId, {
          startDate,
          dueDate,
          stages: stages.map((s) => ({
            id: s.stageDefinitionId,
            dueDate: s.dueDate,
          })),
        })
      ))

  if (isStagesTabDisabled) {
    disabledMessage = 'Fix the invalid stage deadlines to continue'
  }

  const isDisabled =
    isSubmitting ||
    isNameTabDisabled ||
    isTextVariablesTabDisabled ||
    isStagesTabDisabled

  const currentTabIndex = tabOptions.indexOf(activeTab)
  const nextTab = tabOptions[currentTabIndex + 1]
  const prevTab = tabOptions[currentTabIndex - 1]

  const goForward = useCallback(
    async (e?: KeyboardEvent) => {
      if (nextTab != null) {
        setValue('step', nextTab)
      } else {
        onSubmit(e as any)
      }
    },
    [nextTab, setValue, onSubmit]
  )

  const goBack = useCallback(async () => {
    if (prevTab != null) {
      setValue('step', prevTab)
    }
  }, [setValue, prevTab])

  return {
    isDisabled,
    disabledMessage,
    goForward,
    // Don't allow going back on stages tab
    goBack: prevTab != null && activeTab !== 'stages' ? goBack : null,
  }
}
