import { PencilSolid } from '@motion/icons'
import {
  useDebouncedCallback,
  useDependantState,
} from '@motion/react-core/hooks'
import { classed } from '@motion/theme'
import {
  addSchemeIfNeeded,
  getDisplayableLink,
  type TextCustomField,
} from '@motion/ui-logic'

import { canParseCustomFieldUrlValue } from '~/areas/custom-fields/utils'
import {
  CustomField,
  type CustomFieldInputProps,
} from '~/areas/project-management/custom-fields'
import { type ControlledCustomFieldProps } from '~/areas/project-management/pages/pm-v3/tree-list/cells'
import { type FC, useState } from 'react'
import { twMerge } from 'tailwind-merge'

export const ControlledUrl: FC<ControlledCustomFieldProps<TextCustomField>> = ({
  value,
  onSubmit: submitHandler,
  customField,
  disabled,
}) => {
  const [internalValue, setInternalValue] = useDependantState(
    () => value,
    [value]
  )
  const [isEditing, setIsEditing] = useState(internalValue ? false : true)

  const onChange = (v: string) => {
    setInternalValue(v)
  }

  const onSubmit = useDebouncedCallback(async () => {
    if (internalValue === value) {
      setIsEditing(!value)
      return
    }

    const parsedValue = addSchemeIfNeeded(internalValue)

    try {
      await submitHandler(parsedValue)
      setIsEditing(false)
    } catch (error) {
      setInternalValue(value)
    }
  }, 400)

  const handleKeyDown: CustomFieldInputProps['onKeyDown'] = (evt) => {
    const { key } = evt

    if (key === 'Enter') {
      return onSubmit()
    }
  }

  if (!isEditing) {
    const isUrl = canParseCustomFieldUrlValue(internalValue ?? '')

    const DisplayTag = isUrl ? 'a' : 'div'

    return (
      <Container>
        <DisplayTag
          className={twMerge(
            `text-2xs truncate
          hover:text-semantic-primary-text-default text-semantic-neutral-text-default`,
            !internalValue &&
              'hover:text-semantic-neutral-text-subtle text-semantic-neutral-text-subtle'
          )}
          href={isUrl && internalValue ? internalValue : undefined}
          target={isUrl ? '_blank' : undefined}
          rel={isUrl ? 'noopener noreferrer' : undefined}
        >
          {internalValue ? getDisplayableLink(internalValue) : 'None'}
        </DisplayTag>
        <PencilSolid
          className='text-semantic-neutral-icon-subtle cursor-pointer'
          height={16}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setIsEditing(true)
          }}
        />
      </Container>
    )
  }

  const hasValue = internalValue !== ''

  return (
    <Container>
      <CustomField.Url
        name={customField.definition.name}
        value={internalValue ?? ''}
        onChange={onChange}
        onBlur={onSubmit}
        disabled={disabled}
        onKeyDown={handleKeyDown}
        size='xsmall'
        hideIcon={hasValue}
      />
    </Container>
  )
}

const Container = classed('div', 'flex items-center gap-2')
