import { StageAutoScheduleField } from '~/areas/flows/flow-template-modal/components/stages/fields/stage-auto-schedule'
import { ModalFieldButton } from '~/areas/task-project/components'

export const ControlledAutoScheduleField = () => {
  return (
    <div className='[&_label>div]:max-w-[75%]'>
      <ModalFieldButton label='Auto-schedule all tasks'>
        <StageAutoScheduleField
          hideLabel
          size='small'
          tasksPath={`stages.${0}.tasks`}
        />
      </ModalFieldButton>
    </div>
  )
}
