import { getDefaultChunkDuration } from '@motion/ui-logic'
import type { FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import { type UseFormReturn } from 'react-hook-form'

import { getDataFromFieldName } from '../../utils'

export const handleTaskDurationUpdate = (
  form: UseFormReturn<FlowTemplateFormFields>,
  name: `stages.${number}.tasks.${number}.duration`
) => {
  const { stageIndex, taskIndex } = getDataFromFieldName(name)
  if (stageIndex == null || taskIndex == null) return

  const duration = form.getValues(name)

  form.setValue(
    `stages.${stageIndex}.tasks.${taskIndex}.minimumDuration`,
    getDefaultChunkDuration(duration)
  )
}
