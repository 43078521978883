import { PlusSolid } from '@motion/icons'
import { Button, FormModal } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { showErrorToast } from '~/global/toasts'
import { useCallback, useEffect, useRef, useState } from 'react'

import {
  AvailabilityQuestion,
  type AvailabilityQuestionType,
} from './AvailabilityQuestion/AvailabilityQuestion'

export type AvailabilityQuestionEditorModalProps = {
  visible: boolean
  questions?: AvailabilityQuestionType[]
  visibleHandler: (visible: boolean) => void
  saveHandler: (questions: AvailabilityQuestionType[]) => void
}

export const AvailabilityQuestionEditorModal = ({
  visible,
  questions,
  saveHandler,
  visibleHandler,
}: AvailabilityQuestionEditorModalProps) => {
  // Set default questions
  const [questionList, setQuestionList] = useState<AvailabilityQuestionType[]>([
    {
      required: false,
      text: "Anything else you'd like to share for our meeting?",
      type: 'text-multiple',
    },
  ])

  useEffect(
    function updateQuestionData() {
      if (questions && questions.length) {
        setQuestionList(questions)
      }
    },
    [questions]
  )

  const questionContainerRef = useRef<HTMLDivElement | null>(null)

  const questionSaveHandler = useCallback(() => {
    if (questionList.filter((x) => !x.text).length) {
      showErrorToast('Please make sure to fill in every question')
      return
    }
    const mcQuestions = questionList.filter((x) => x.type === 'multiple-choice')
    if (
      !mcQuestions.every(
        (question) => (question.choices ?? []).filter((x) => x).length > 1
      )
    ) {
      showErrorToast(
        'Please make sure all multiple choice questions have multiple options'
      )
      return
    }
    saveHandler(questionList)
    recordAnalyticsEvent('CALENDAR_AVAILABILITIES_QUESTIONS_SAVE')
    visibleHandler(false)
  }, [questionList, saveHandler, visibleHandler])

  return (
    <FormModal
      visible={visible}
      title='Invitee questions'
      onClose={() => visibleHandler(false)}
      submitAction={{
        text: 'Save',
        onAction: questionSaveHandler,
      }}
    >
      <div className='flex h-full min-h-[min(50vh,650px)] max-h-full min-w-[50vw] flex-col justify-between gap-2'>
        <div className='flex flex-1 flex-col gap-2' ref={questionContainerRef}>
          {questionList.map((question, idx) => (
            <AvailabilityQuestion
              key={`question-${idx}`}
              idx={idx}
              question={question}
              questionList={questionList}
              setQuestionList={setQuestionList}
            />
          ))}
        </div>
        <div>
          <Button
            sentiment='neutral'
            variant='muted'
            onClick={() => {
              setQuestionList((prev) => [
                ...prev,
                { required: false, text: '', type: 'text-multiple' },
              ])
              setTimeout(
                () =>
                  questionContainerRef.current?.scrollTo({
                    behavior: 'smooth',
                    top: (questionList.length + 1) * 135,
                  }),
                300
              )
            }}
          >
            <PlusSolid />
            Add Question
          </Button>
        </div>
      </div>
    </FormModal>
  )
}
