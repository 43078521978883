import { MAX_FOLDER_DEPTH } from '@motion/shared/common'
import { isOneOf } from '@motion/utils/array'
import { Sentry } from '@motion/web-base/sentry'

import { closestCenter, type DroppableContainer } from '@dnd-kit/core'
import {
  type SortableItemData,
  type SortableTreeviewCollisionDetection,
} from '~/areas/treeviews/components'

import { type SortableWorkspacesTreeviewItem } from '../types'

export const workspaceCollisionDetection: SortableTreeviewCollisionDetection<
  SortableWorkspacesTreeviewItem
> =
  ({ activeItem, dragDirection, renderableItems }) =>
  (args) => {
    if (!activeItem || !dragDirection) return []

    const activeItemType = activeItem.type
    const activeItemWorkspaceId = activeItem.item.workspaceId

    const filterContainers = (container: DroppableContainer) => {
      // Do not use containerData for collision detection. Use item, itemAbove, and itemBelow only
      const containerData = container.data.current as SortableItemData

      const matchedItemIdx = renderableItems.findIndex(
        (item) => item.id === containerData.item.id
      )

      if (matchedItemIdx === -1) {
        // Putting this here until I can collect enough data to determine
        // how/why this might happen
        Sentry.captureException(
          new Error('Could not find item in renderableItems'),
          {
            level: 'warning',
            tags: {
              position: 'workspaceCollisionDetection',
            },
            extra: {
              containerData,
              activeItem,
              dragDirection,
            },
          }
        )

        return false
      }

      const item = renderableItems[matchedItemIdx]
      const _itemAbove = renderableItems[
        matchedItemIdx - Number(dragDirection === 'UP')
      ] as SortableWorkspacesTreeviewItem | undefined
      const itemBelow = renderableItems[
        matchedItemIdx + Number(dragDirection === 'DOWN')
      ] as SortableWorkspacesTreeviewItem | undefined

      /**
       * You may be wondering why we're doing all of this ☝️ instead of just
       * using the containerData we get from the collision detection. When you
       * drag an item DOWN from its original position, the collision detection
       * is determining if you can drop the item BELOW the `container`, and when
       * dragging UP from its original position, if you can drop the item ABOVE
       * the `container`. This makes things difficult because we always want to
       * check if we can drop the item BELOW the item we're evaluating. So all
       * of this just makes it more consistent so you don't need separate logic
       * for up vs. down.
       */

      if (activeItemType === 'PLACEHOLDER') {
        // You should never be able to drag placeholders
        return false
      }

      if (activeItemType === 'WORKSPACE') {
        // Allowed in root only and the end of a workspace only
        return item.type === 'WORKSPACE'
      }

      if (
        item.item.workspaceId !== activeItemWorkspaceId ||
        item.type === 'WORKSPACE'
      ) {
        // We do not support moving folders/projects between workspaces at this time
        return false
      }

      if (
        activeItemType === 'PROJECT' &&
        isOneOf(itemBelow?.parentType, ['PROJECT', 'NOTE'])
      ) {
        // You can't nest a project inside a project or note
        return false
      }

      if (activeItemType !== 'NOTE' && itemBelow?.parentType === 'NOTE') {
        // You can't nest non-notes under a note
        return false
      }

      if (containerData.type === 'PLACEHOLDER' && !itemBelow) {
        // Prevent placing an item below a placeholder if it's the last item
        return false
      }

      if (activeItemType === 'FOLDER') {
        if (!isOneOf(itemBelow?.parentType, ['WORKSPACE', 'FOLDER'])) {
          // Folders can only be in workspaces and other folders
          return false
        }

        return item.level <= MAX_FOLDER_DEPTH
      }

      return true
    }

    return closestCenter({
      ...args,
      droppableContainers: args.droppableContainers.filter(filterContainers),
    })
  }
