import { useMemoDeep } from '@motion/react-core/hooks'
import {
  createStateKey,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'
import { createQuery, type DataFilters } from '@motion/ui-logic/pm/data'
import { byProperty, Compare, type CompareFn } from '@motion/utils/array'
import { type OrNullable } from '@motion/utils/object'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import {
  type GetTasksV2FilterWithOperatorsSchema,
  type TaskSchema,
  type TasksV2QueryResponseSchema,
} from '@motion/zod/client'

import { useLookup } from '~/global/cache'
import { useAppDataContext } from '~/global/contexts'
import { createTaskProxy, type TaskWithRelations } from '~/global/proxies'
import { useTasksV2 } from '~/global/rpc/v2/tasks'
import { useEffect, useMemo } from 'react'

export const PageTasksContextKey = createStateKey<
  TaskWithRelations[] | undefined
>('page-tasks')

const EMPTY_DATA: TasksV2QueryResponseSchema = {
  ids: [],
  meta: { model: 'tasks' },
  models: {
    tasks: {},
    chunks: {},
    recurringTasks: {},
    uploadedFiles: {},
    calendarEvents: {},
  },
}

export type TaskLoaderProps = {
  filter: DataFilters

  sortBy?: CompareFn<TaskWithRelations>
  queryOverrides?: OrNullable<GetTasksV2FilterWithOperatorsSchema>

  postProcess?: (tasks: TaskSchema[]) => TaskSchema[]
}

const TWO_MINUTES = 2 * 60 * 1000

export const useTaskLoader = (props: TaskLoaderProps) => {
  const { queryOverrides, filter, sortBy, postProcess } = props
  const ctx = useAppDataContext()
  const setPageTasks = useSharedStateSendOnly(PageTasksContextKey)
  const lookup = useLookup()
  const { uid: userId } = useAuthenticatedUser()
  const memoedFilter = useMemoDeep(filter)

  const query = useMemo(
    () => createQuery(ctx, memoedFilter, queryOverrides, { userId }),
    [ctx, memoedFilter, queryOverrides, userId]
  )

  const response = useTasksV2(query ?? { filters: [] }, {
    enabled: Boolean(ctx.loaded && query?.filters.length),
    initialData: query == null ? EMPTY_DATA : undefined,
    staleTime: TWO_MINUTES,
  })

  useEffect(() => {
    if (response.data == null) return

    const data = response.data.filter(Boolean)
    const processedData = postProcess ? postProcess(data) : data
    const proxies = processedData.map((item) => createTaskProxy(item, lookup))
    const sortedData = sortBy
      ? proxies.sort(sortBy)
      : proxies.sort(byProperty('rank', Compare.caseInsensitive))

    setPageTasks(sortedData)
  }, [response.data, setPageTasks, lookup, postProcess, sortBy])

  return response
}
