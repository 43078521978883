import {
  AddToProjectSolid,
  ArchiveSolid,
  AutoscheduleSolid,
  CalendarSolid,
  CustomFieldTextSolid,
  FolderSolid,
  HashtagSolid,
  LinkSolid,
  PencilSolid,
  ProjectCubeSolid,
  StartDateSolid,
  TagSolid,
  UsersSolid,
  WorkspaceSolid,
} from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { FileIcon } from '@motion/ui/base'
import { formatToReadableWeekDayMonth, priorityLabels } from '@motion/ui-logic'
import { parseDate } from '@motion/utils/dates'
import { createLookup } from '@motion/utils/object'
import { Sentry } from '@motion/web-base/sentry'
import { type AmplitudeExperimentName } from '@motion/web-common/flags'
import { type PriorityLevelSchema } from '@motion/zod/client'

import { SelectTriggerValues } from '~/areas/project-management/custom-fields'
import { ConnectedStatusBadge, PriorityBadge } from '~/global/components/badges'
import { type ReactNode } from 'react'

import {
  ActivityAssigneeName,
  ActivityDate,
  ActivityEventCreate,
  ActivityLabelItem,
  ActivityLink,
  ActivityMultiPerson,
  ActivityMultiSelect,
  ActivityNumber,
  ActivityUrl,
  ActivityValue,
} from './components'
import {
  handleAssignee,
  handleBlocks,
  handleDuration,
  handleScheduledDate,
  handleStageDefinition,
} from './handlers'
import { handleStageDueDate } from './handlers/stage-due-date'
import { isCreationActivity } from './is-activity-type'

import {
  type ActivityEntryViewSchema,
  ActivityFeedMetadataError,
  type ActivityFeedType,
  type ActivityItemConfigLookupType,
  type CustomFieldItemConfigLookupType,
} from '../../types'

const activityItemLookupConfig: ActivityItemConfigLookupType = {
  created: ({ defaultIcon, type, targetId }) => {
    if (type === 'event') {
      return {
        icon: <AddToProjectSolid />,
        children: <ActivityEventCreate meetingTaskId={targetId} />,
      }
    }

    return {
      icon: defaultIcon,
      children: templateStr('created this {{type}}', {
        type,
      }),
    }
  },
  description: () => {
    return {
      icon: <PencilSolid />,
      children: 'updated the description',
    }
  },
  archivedTime: ({ metadata }) => {
    return {
      icon: <ArchiveSolid />,
      children: templateStr('{{type}} this task', {
        type: metadata.newValue ? 'archived' : 'unarchived',
      }),
    }
  },
  name: ({ metadata }) => {
    return {
      icon: <PencilSolid />,
      children: templateStr(
        `changed the title from {{oldName}} to {{newName}}`,
        {
          oldName: <ActivityValue>{metadata.oldValue}</ActivityValue>,
          newName: <ActivityValue>{metadata.newValue}</ActivityValue>,
        }
      ),
    }
  },
  isAutoScheduled: ({ metadata, type }) => {
    if (type === 'event') return null

    return {
      icon: <AutoscheduleSolid className='text-semantic-purple-icon-default' />,
      children: templateStr(`turned {{state}} auto-scheduling`, {
        state: (
          <ActivityValue>{metadata.newValue ? 'on' : 'off'}</ActivityValue>
        ),
      }),
    }
  },
  workspaceId: ({ metadata, type }) => {
    return {
      icon: <WorkspaceSolid />,
      children: templateStr(
        `moved this {{type}} from {{workspaceFrom}} to {{workspaceTo}}`,
        {
          type,
          workspaceFrom: (
            <ActivityLink type='workspace' targetId={metadata.oldValue}>
              {metadata.oldContext?.name}
            </ActivityLink>
          ),
          workspaceTo: (
            <ActivityLink type='workspace' targetId={metadata.newValue}>
              {metadata.newContext?.name}
            </ActivityLink>
          ),
        }
      ),
    }
  },
  projectId: ({ metadata, type }) => {
    return {
      icon: <ProjectCubeSolid />,
      children: templateStr(
        `moved this {{type}} from {{projectFrom}} to {{projectTo}}`,
        {
          type,
          projectFrom: (
            <ActivityLink type='project' targetId={metadata.oldValue}>
              {metadata.oldContext?.name ?? 'No project'}
            </ActivityLink>
          ),
          projectTo: (
            <ActivityLink type='project' targetId={metadata.newValue}>
              {metadata.newContext?.name ?? 'No project'}
            </ActivityLink>
          ),
        }
      ),
    }
  },
  activeStageDefinitionId: ({ metadata }) =>
    handleStageDefinition(metadata.oldContext, metadata.newContext),
  stageDefinitionId: ({ metadata }) =>
    handleStageDefinition(metadata.oldContext, metadata.newContext),
  statusId: ({ metadata, type }) => {
    if (type === 'event') {
      return null
    }

    if (metadata.newContext?.id == null) {
      throw new ActivityFeedMetadataError(metadata)
    }

    return {
      icon: <ConnectedStatusBadge hideTooltip id={metadata.newContext.id} />,
      children: templateStr(
        `changed status from {{statusFrom}} to {{statusTo}}`,
        {
          statusFrom: (
            <ActivityValue>{metadata.oldContext?.name}</ActivityValue>
          ),
          statusTo: <ActivityValue>{metadata.newContext?.name}</ActivityValue>,
        }
      ),
    }
  },
  priorityLevel: ({ metadata }) => {
    if (!metadata.newValue || !metadata.oldValue) {
      throw new ActivityFeedMetadataError(metadata)
    }

    return {
      icon: <PriorityBadge value={metadata.newValue as PriorityLevelSchema} />,
      children: templateStr(
        `changed priority from {{priorityFrom}} to {{priorityTo}}`,
        {
          priorityFrom: (
            <ActivityValue>
              {priorityLabels.get(metadata.oldValue as PriorityLevelSchema)}
            </ActivityValue>
          ),
          priorityTo: (
            <ActivityValue>
              {priorityLabels.get(metadata.newValue as PriorityLevelSchema)}
            </ActivityValue>
          ),
        }
      ),
    }
  },
  assignees: ({ metadata, defaultIcon }) => {
    return {
      icon: defaultIcon,
      children: handleAssignee(metadata.oldValue[0], metadata.newValue[0]),
    }
  },
  managerId: ({ metadata, defaultIcon }) => {
    return {
      icon: defaultIcon,
      children: handleAssignee(metadata.oldValue, metadata.newValue),
    }
  },
  dueDate: ({ metadata, type, targetId }) => {
    if (type === 'event') return null

    return {
      icon: <CalendarSolid />,
      children: (
        <ActivityDate
          targetId={targetId}
          targetType={type}
          newTimestamp={metadata.newValue}
          oldTimestamp={metadata.oldValue}
          type='due'
        />
      ),
    }
  },
  startDate: ({ metadata, type, targetId }) => {
    if (type === 'event') return null

    return {
      icon: <StartDateSolid />,
      children: (
        <ActivityDate
          targetId={targetId}
          targetType={type}
          newTimestamp={metadata.newValue}
          oldTimestamp={metadata.oldValue}
          type='start'
        />
      ),
    }
  },
  duration: ({ metadata, type }) => {
    if (type === 'event') return null

    return handleDuration('duration', metadata.oldValue, metadata.newValue)
  },
  completedDuration: ({ metadata, type }) => {
    if (type === 'event') return null

    return handleDuration(
      'completed-time',
      metadata.oldValue,
      metadata.newValue
    )
  },
  labels: ({ metadata }) => {
    return {
      icon: <TagSolid />,
      children: (
        <ActivityLabelItem
          oldLabelContext={metadata.oldContext}
          newLabelContext={metadata.newContext}
        />
      ),
    }
  },
  blockedBy: ({ metadata }) => {
    return handleBlocks(
      'blockedBy',
      metadata.newBlockedBy,
      metadata.noLongerBlockedBy
    )
  },
  blocking: ({ metadata }) => {
    return handleBlocks(
      'blocking',
      metadata.newBlocking,
      metadata.noLongerBlocking
    )
  },
  customFieldValue: ({
    metadata,
    defaultIcon,
    workspaceId,
    sourceId,
    sourceType,
  }) => {
    return (
      customFieldValueLookup(metadata.type)?.({
        defaultIcon,
        workspaceId,
        name: metadata.name,
        instanceId: metadata.instanceId,
        oldValue: metadata.oldValue as never,
        newValue: metadata.newValue as never,
        sourceType,
        sourceId,
      }) ?? null
    )
  },
  scheduledStart: ({ metadata }) => {
    if (metadata.newValue == null) {
      throw new ActivityFeedMetadataError(metadata)
    }

    return handleScheduledDate(metadata.oldValue, metadata.newValue, 'start')
  },
  scheduledEnd: ({ metadata, createdTime }) => {
    if (metadata.newValue == null) {
      // Only throw error if the createdTime of the entry is after 2024-11-19 because that's when we stop adding feed entries with this state
      if (parseDate(createdTime) > parseDate('2024-11-19')) {
        throw new ActivityFeedMetadataError(metadata)
      }

      // If the createdTime is before 2024-11-19, we don't throw an error and just render nothing
      return null
    }

    return handleScheduledDate(metadata.oldValue, metadata.newValue, 'end')
  },
  isSyncingWithDefinition: ({ metadata, sourceType, defaultIcon }) => {
    // This flag is whether this task will sync changes from its task definition back
    // We only show this entry when we go from true -> false (syncinc is disabled)
    if (metadata.newValue === false && metadata.oldValue === true) {
      return {
        icon: defaultIcon,
        children:
          sourceType === 'SYSTEM'
            ? templateStr(
                "kicked off this task's stage. This task is no longer syncing with its {{parentType}}.",
                {
                  parentType: 'Project Workflow Template',
                }
              )
            : templateStr(
                'edited this task. This task is no longer syncing with its {{parentType}}.',
                {
                  parentType: 'Project Workflow Template',
                }
              ),
      }
    }

    return null
  },
  attachmentCreated: ({ metadata }) => {
    return {
      icon: <FileIcon mimeType={metadata.mimeType} />,
      children: templateStr('added an attachment: {{name}}', {
        name: (
          <ActivityLink type='attachment' targetId={metadata.uploadedFileId}>
            {metadata.fileName}
          </ActivityLink>
        ),
      }),
    }
  },
  attachmentDeleted: ({ metadata }) => {
    return {
      icon: <FileIcon mimeType={metadata.mimeType} />,
      children: templateStr('deleted an attachment: {{name}}', {
        name: metadata.fileName,
      }),
    }
  },
  stageDueDate: ({ metadata }) => {
    const { oldValue, newValue, context } = metadata
    if (!oldValue || !newValue || oldValue === newValue) {
      throw new ActivityFeedMetadataError(metadata)
    }

    return handleStageDueDate({
      oldValue,
      newValue,
      context,
    })
  },
  folderId: ({ metadata, type }) => {
    return {
      icon: <FolderSolid />,
      children: templateStr(
        `moved this {{objectType}} from {{srcFolder}} to {{destFolder}}`,
        {
          objectType: type,
          srcFolder: (
            <ActivityLink type='folder' targetId={metadata.oldValue}>
              {metadata.oldContext?.name ?? 'No folder'}
            </ActivityLink>
          ),
          destFolder: (
            <ActivityLink type='folder' targetId={metadata.newValue}>
              {metadata.newContext?.name ?? 'No folder'}
            </ActivityLink>
          ),
        }
      ),
    }
  },
  default: () => null,
}

const activityItemConfigLookup = createLookup<ActivityItemConfigLookupType>(
  activityItemLookupConfig
)

export type GetActivityItemConfigArgs = {
  workspaceId: string
  type: ActivityFeedType['type']
  feedEntry: ActivityEntryViewSchema
  defaultIcon: ReactNode
  options?: {
    featureFlags?: {
      [key in AmplitudeExperimentName]?: boolean
    }
  }
}
export function getActivityItemConfig({
  workspaceId,
  type,
  feedEntry,
  defaultIcon,
  options,
}: GetActivityItemConfigArgs) {
  const key = isCreationActivity(feedEntry)
    ? 'created'
    : feedEntry.activityType === 'ATTACHMENT_CREATED'
      ? 'attachmentCreated'
      : feedEntry.activityType === 'ATTACHMENT_DELETED'
        ? 'attachmentDeleted'
        : feedEntry.metadata.field

  try {
    const lookup = activityItemConfigLookup(key)

    if (lookup == null) {
      Sentry.captureMessage(
        'Activity feed entry has no matching lookup function',
        {
          extra: {
            key,
            type,
            taskId: feedEntry.targetId,
            activityId: feedEntry.id,
            metadata: feedEntry.metadata,
          },
        }
      )
    }

    const lookupResult = lookup?.({
      workspaceId,
      defaultIcon,
      type,
      metadata: feedEntry.metadata as never,
      targetId: feedEntry.targetId,
      sourceType: feedEntry.sourceType,
      sourceId: feedEntry.sourceId,
      createdTime: feedEntry.createdTime,
      options,
    })

    return lookupResult
  } catch (error) {
    if (error instanceof ActivityFeedMetadataError) {
      Sentry.captureException(error, {
        extra: error.toJSON(),
      })
    } else if (error instanceof Error) {
      Sentry.captureException(error)
    }
  }
}

/**
 * CUSTOM FIELDS
 */

const customFieldLookupConfig: CustomFieldItemConfigLookupType = {
  select: ({ instanceId, oldValue, newValue, name }) => {
    return {
      icon: <TagSolid />,
      children: templateStr(
        `changed {{name}} from {{oldValue}} to {{newValue}}`,
        {
          name: <ActivityValue>{name}</ActivityValue>,
          oldValue: (
            <SelectTriggerValues
              selectedItemIds={oldValue?.value != null ? [oldValue.value] : []}
              customFieldId={instanceId}
              hideIcon
              usePrettyLabels={false}
              size='small'
              type='select'
            />
          ),
          newValue: (
            <SelectTriggerValues
              selectedItemIds={newValue?.value != null ? [newValue.value] : []}
              customFieldId={instanceId}
              hideIcon
              usePrettyLabels={false}
              size='small'
              type='select'
            />
          ),
        }
      ),
    }
  },
  multiSelect: ({ instanceId, oldValue, newValue, name }) => {
    const oldValues = oldValue?.value ?? []
    const newValues = newValue?.value ?? []

    return {
      icon: <TagSolid />,
      children: (
        <ActivityMultiSelect
          oldValues={oldValues}
          newValues={newValues}
          customFieldId={instanceId}
          name={name}
        />
      ),
    }
  },
  text: ({ oldValue, newValue, name }) => {
    return {
      icon: <CustomFieldTextSolid />,
      children: templateStr(
        `changed {{name}} from {{oldValue}} to {{newValue}}`,
        {
          name: <ActivityValue>{name}</ActivityValue>,
          oldValue: <ActivityValue>{oldValue?.value ?? 'None'}</ActivityValue>,
          newValue: <ActivityValue>{newValue?.value ?? 'None'}</ActivityValue>,
        }
      ),
    }
  },
  number: ({ oldValue, newValue, name, instanceId }) => {
    return {
      icon: <HashtagSolid />,
      children: templateStr(
        `changed {{name}} from {{oldValue}} to {{newValue}}`,
        {
          name: <ActivityValue>{name}</ActivityValue>,
          oldValue: (
            <ActivityNumber value={oldValue?.value} instanceId={instanceId} />
          ),
          newValue: (
            <ActivityNumber value={newValue?.value} instanceId={instanceId} />
          ),
        }
      ),
    }
  },
  date: ({ oldValue, newValue, name }) => {
    let dateFrom = oldValue?.value
      ? formatToReadableWeekDayMonth(oldValue.value)
      : 'None'
    let dateTo = newValue?.value
      ? formatToReadableWeekDayMonth(newValue.value)
      : 'None'

    return {
      icon: <CalendarSolid />,
      children: templateStr(
        `changed {{name}} from {{oldValue}} to {{newValue}}`,
        {
          name: <ActivityValue>{name}</ActivityValue>,
          oldValue: <ActivityValue>{dateFrom}</ActivityValue>,
          newValue: <ActivityValue>{dateTo}</ActivityValue>,
        }
      ),
    }
  },
  person: ({ oldValue, newValue, name, defaultIcon }) => {
    return {
      icon: defaultIcon,
      children: templateStr(
        `changed {{name}} from {{oldValue}} to {{newValue}}`,
        {
          name: <ActivityValue>{name}</ActivityValue>,
          oldValue: (
            <ActivityValue>
              <ActivityAssigneeName assigneeId={oldValue?.value} />
            </ActivityValue>
          ),
          newValue: (
            <ActivityValue>
              <ActivityAssigneeName assigneeId={newValue?.value} />
            </ActivityValue>
          ),
        }
      ),
    }
  },
  multiPerson: ({ workspaceId, oldValue, newValue, name }) => {
    return {
      icon: <UsersSolid />,
      children: (
        <ActivityMultiPerson
          oldValues={oldValue?.value ?? []}
          newValues={newValue?.value ?? []}
          name={name}
          workspaceId={workspaceId}
        />
      ),
    }
  },
  url: ({ oldValue, newValue, name }) => {
    return {
      icon: <LinkSolid />,
      children: templateStr(
        `changed {{name}} from {{oldValue}} to {{newValue}}`,
        {
          name: <ActivityValue>{name}</ActivityValue>,
          oldValue: <ActivityUrl url={oldValue?.value} />,
          newValue: <ActivityUrl url={newValue?.value} />,
        }
      ),
    }
  },
  default: () => null,
}

const customFieldValueLookup = createLookup<CustomFieldItemConfigLookupType>(
  customFieldLookupConfig
)
