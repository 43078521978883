import { useContext } from 'react'

import { useNoteParams } from './use-note-params'

import { NoteDialogContext } from '../context/note-dialog-provider/note-dialog-context'

export const useNoteDialogContext = () => {
  const context = useContext(NoteDialogContext)
  const { noteId } = useNoteParams()

  if (!noteId) {
    throw new Error('noteId cannot be empty')
  }

  return {
    ...context,
    noteId,
  }
}
