import { QuestionMarkCircleSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { Button, LoadingSpinner, Text, TextHeader } from '@motion/ui/base'
import {
  getMinimumBucket,
  TEAM_DEFAULT_BUCKET_SEATS,
  Term,
} from '@motion/ui-logic/billing'
import { type Tier } from '@motion/ui-logic/tiered-pricing'
import {
  BillingPlanToggle,
  BucketSeatsDropdown,
  EnterpriseBillingPlanCard,
  FeatureTierCards,
  IndividualBillingPlanCard,
  TeamBillingPlanCard,
} from '@motion/web-billing'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { useCurrentTier } from '~/areas/tiered-pricing/hooks'
import { useActiveMemberCount } from '~/global/hooks/team'
import { useGetTeamPrices } from '~/global/rpc/team'
import { useEffect, useState } from 'react'

import { HorizontalDivider } from '../../../components/Common/Divider/HorizontalDivider'
import { DetachCalendarModal } from '../../../components/Settings/Modals/DetachCalendarModal/DetachCalendarModal'
import { useToggleIntercom } from '../../../utils/toggleIntercom'

interface TeamSubscriptionExpiredProps {
  email: string
  teamSubscribe: () => void
  individualSubscribe: () => void
  signOut: () => void
}

export const TeamSubscriptionExpired = ({
  email,
  teamSubscribe,
  individualSubscribe,
  signOut,
}: TeamSubscriptionExpiredProps) => {
  const toggleIntercom = useToggleIntercom()
  const activeMemberCount = useActiveMemberCount()
  const shouldUseTieredPricingV1 = useHasTreatment('tiered-pricing-v1')
  const modalApi = useModalApi()

  const [showDetachCalendarModal, setShowDetachCalendarModal] = useState(false)
  const [bucket, setBucket] = useState<number>(TEAM_DEFAULT_BUCKET_SEATS)
  const [term, setTerm] = useState<Term>(Term.Annual)

  const { data: teamPrices } = useGetTeamPrices()
  const tier = useCurrentTier()
  const activeTeamMembers = useActiveMemberCount()

  useEffect(() => {
    if (activeMemberCount) {
      setBucket(
        Math.max(getMinimumBucket(activeMemberCount), TEAM_DEFAULT_BUCKET_SEATS)
      )
    }
  }, [activeMemberCount])

  if (!teamPrices) {
    return (
      <div className='flex h-screen w-screen items-center justify-center'>
        <LoadingSpinner />
      </div>
    )
  }

  function onSelectFeatureTier(tier: Tier, term: Term, bucket: number) {
    modalApi.open('tiered-checkout-modal', {
      tier: tier,
      selectedBucket: bucket,
      selectedTerm: term,
      isResubscribe: true,
      onBillingComplete: () => {
        modalApi.dismissAll()
      },
    })
  }

  return (
    <div className='h-screen w-screen overflow-y-auto'>
      <div className='bg-semantic-neutral-bg-subtle relative flex items-center justify-center py-20'>
        <div className='absolute top-12 right-12'>
          <Button onClick={() => toggleIntercom()} sentiment='neutral'>
            <QuestionMarkCircleSolid className='mr-2.5 h-4 w-4' />
            Support
          </Button>
        </div>

        <div className='flex w-full flex-col justify-center'>
          <TextHeader alignment='center' className='mb-5'>
            Your team&apos;s subscription has ended
          </TextHeader>
          <Text as='h2' sentiment='subtle' alignment='center' className='mb-1'>
            Want to continue using Motion?
          </Text>
          <Text
            sentiment='subtle'
            alignment='center'
            weight='semibold'
            className='mb-2.5'
          >
            You won't be charged until you verify your payment information on
            the next page.
          </Text>

          {shouldUseTieredPricingV1 ? (
            <div className='py-4 px-8'>
              <div className='flex flex-row justify-center gap-6 py-6'>
                <div className='flex flex-row gap-2 items-center'>
                  <p className='text-semantic-neutral-text-default'>
                    Choose team size:
                  </p>
                  <BucketSeatsDropdown
                    selectedBucket={bucket}
                    onChange={setBucket}
                    allowUpsell
                    disableUnderMemberCount={activeTeamMembers}
                    minSeats={1}
                  />
                </div>
                <BillingPlanToggle
                  term={term}
                  setTerm={setTerm}
                  savingsPercent={40}
                />
              </div>
              <FeatureTierCards
                mode='resubscribe'
                bucket={bucket}
                term={term}
                currentTier={tier}
                onSelectFeatureTier={onSelectFeatureTier}
              />
            </div>
          ) : (
            <div className='mb-8 flex flex-row items-center justify-center gap-x-3 mx-8'>
              <div className='flex justify-center gap-4 items-stretch flex-wrap'>
                <IndividualBillingPlanCard
                  onClick={individualSubscribe}
                  buttonText='Subscribe to Individual Plan'
                  isSecondary
                />
                <TeamBillingPlanCard
                  onClick={teamSubscribe}
                  price={teamPrices.annualPricePerMonth}
                  buttonText='Re-subscribe to Team Plan'
                  savings={teamPrices.individualToTeamSavingsPercent}
                />
                <EnterpriseBillingPlanCard
                  location='teamSubscriptionExpired'
                  email={email}
                />
              </div>
            </div>
          )}

          <div className='flex flex-col justify-center items-center'>
            <p className='max-w-[500px] mb-3 text-center font-medium text-semantic-neutral-text-subtle'>
              {templateStr(
                "If you think you have an active trial or subscription, it seems you might be using the wrong email with {{email}}. Please try logging in with the right one or reach out to our friendly support team at {{link}}. We're here to help!",
                {
                  email: <span className='underline'>{email}</span>,
                  link: (
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://help.usemotion.com'
                    >
                      help.usemotion.com
                    </a>
                  ),
                }
              )}
            </p>

            <Button onClick={signOut} sentiment='neutral'>
              Sign in with another account
            </Button>
          </div>

          <HorizontalDivider />

          <div className='flex flex-col justify-center'>
            <Text sentiment='subtle' size='xs' alignment='center'>
              Not using Motion anymore?
            </Text>
            <div className='flex flex-row items-center justify-center mt-4'>
              <Button
                size='small'
                sentiment='error'
                onClick={() => setShowDetachCalendarModal(true)}
              >
                Delete Motion Tasks
              </Button>
            </div>
          </div>
        </div>
        {showDetachCalendarModal && (
          <DetachCalendarModal
            visible={showDetachCalendarModal}
            close={() => {
              setShowDetachCalendarModal(false)
            }}
          />
        )}
      </div>
    </div>
  )
}
