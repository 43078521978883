import { type FlowTemplateFormTask } from '@motion/ui-logic/pm/project'

import { useFlowsModalState } from '~/areas/flows/contexts'
import { useCallback } from 'react'

export type OriginalTaskDefinitionState = {
  originalTask: FlowTemplateFormTask | undefined
  originalIndices: readonly [number, number]
}

export function useGetOriginalTaskDefinitionState() {
  const { originalTasksMap, originalTaskIndicesMap } = useFlowsModalState()

  return useCallback(
    (id: string): OriginalTaskDefinitionState => {
      return {
        originalTask: originalTasksMap[id],
        originalIndices: originalTaskIndicesMap[id] ?? ([-1, -1] as const),
      }
    },
    [originalTasksMap, originalTaskIndicesMap]
  )
}
