import { PlusSolid } from '@motion/icons'

import {
  type SortableWorkspacesTreeviewItem,
  WorkspaceTreeviewPlaceholder,
} from '~/areas/treeviews/components'
import { LexoRank } from 'lexorank'

import { treeviewIndentationWidth, TreeviewItem } from './treeview-item'

export type AddNewTreeviewItemProps = {
  item: SortableWorkspacesTreeviewItem
  level: number
}

export const AddNewTreeviewItem = ({
  item: flattenedItem,
  level,
}: AddNewTreeviewItemProps) => {
  const { item, id, type } = flattenedItem

  return (
    <WorkspaceTreeviewPlaceholder
      item={flattenedItem}
      indent={64 + treeviewIndentationWidth * level}
    >
      {(label) => (
        <div>
          <TreeviewItem
            icon={() => (
              <PlusSolid className='text-semantic-neutral-icon-default size-4' />
            )}
            label={
              <span className='text-semantic-neutral-text-subtle'>{label}</span>
            }
            sortableData={{
              item: {
                ...item,
                url: '',
              },
              order: LexoRank.middle().toString(),
              parentId: id,
              parentType: type,
              type: 'PLACEHOLDER',
              level,
            }}
            disableDrag
          />
        </div>
      )}
    </WorkspaceTreeviewPlaceholder>
  )
}
