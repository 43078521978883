import { computeSearchScore } from '@motion/ui-logic'
import {
  type AgentVariableSchema,
  type AgentWorkflowStepSchema,
} from '@motion/zod/client'

import { type Reference } from '~/areas/notes/components'
import { useCallback } from 'react'

import { WorkflowStepCard } from './workflow-step-card'

import { convertStepType } from '../utils'

export type ConnectedWorkflowStepProps = {
  step: AgentWorkflowStepSchema
  inputVariables: AgentVariableSchema[]
  onChange: (updatedStep: AgentWorkflowStepSchema) => void
}

export function ConnectedWorkflowStep({
  step,
  inputVariables,
  onChange,
}: ConnectedWorkflowStepProps) {
  const queryVariable = useCallback<Reference['query']>(
    (query) => {
      return [
        ...maybeFilterVariables(inputVariables, query).map((v) => {
          return {
            id: v.id,
            label: v.label,
            meta: { type: 'variable', data: v },
          }
        }),
      ]
    },
    [inputVariables]
  )

  return (
    <WorkflowStepCard
      placeholderTitle='Step name'
      placeholderContent='Describe what text you want the AI to write'
      title={step.name}
      type={step.type}
      content={step.userPrompt}
      variables={step.variables}
      onQueryVariable={queryVariable}
      onChangeTitle={(value) => {
        onChange({ ...step, name: value })
      }}
      onChangeContent={(value) => {
        onChange({ ...step, userPrompt: value })
      }}
      onChangeType={(value) => {
        onChange(convertStepType(step, value))
      }}
    />
  )
}

function maybeFilterVariables(variables: AgentVariableSchema[], query: string) {
  if (query.length < 1) return variables

  return variables.filter((v) => {
    const score = computeSearchScore(v.label, query)
    return score > 0
  })
}
