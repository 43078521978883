import {
  type FlowTemplateFormTask,
  type FlowTemplateStage,
} from '@motion/ui-logic/pm/project'
import { createLookupById, createLookupByKey } from '@motion/utils/object'

import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { useCallback, useMemo } from 'react'

export type UseTemplateTaskReturn = {
  all: FlowTemplateFormTask[]
  byId: Record<string, FlowTemplateFormTask>
  ids: string[]
  blockedById: Record<string, string[]>
  blockingById: Record<string, string[]>
}

export const useTemplateTasks = (): UseTemplateTaskReturn => {
  const {
    form: { watch },
  } = useFlowTemplateForm()

  const getTemplateTasks = useTemplateTasksFn()
  const stages = watch('stages')

  return useMemo(() => getTemplateTasks(stages), [getTemplateTasks, stages])
}

export const useTemplateTasksFn = (): ((
  stages: FlowTemplateStage[]
) => UseTemplateTaskReturn) => {
  return useCallback((stages) => {
    const allTasks = stages.flatMap((stage) => stage.tasks)
    const allTaskIds = allTasks.map((task) => task.id)

    const onlyBlocked = allTasks.filter(
      (task) => task.blockedByTaskId != null || task.blockedByTaskIds.length > 0
    )
    const blockedById = createLookupByKey(onlyBlocked, 'id', (task) =>
      // We include `blockedByTaskId` for backward compatibility
      Array.from(
        new Set(
          [task.blockedByTaskId, ...task.blockedByTaskIds].filter(Boolean)
        )
      )
    )

    const blockingById = Object.entries(blockedById).reduce(
      (result, [blockedId, blockingIds]) => {
        blockingIds.forEach((id) => {
          result[id] ||= []
          result[id].push(blockedId)
        })
        return result
      },
      {} as Record<string, string[]>
    )

    const byId = createLookupById(allTasks)

    return {
      all: allTasks,
      byId,
      ids: allTaskIds,
      blockedById,
      blockingById,
    }
  }, [])
}
