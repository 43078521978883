import { PlusSolid } from '@motion/icons'
import {
  type LabelIdSchema,
  type NormalTaskSchema,
  type ProjectSchema,
  type TaskSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { useProjectUpdater } from '~/areas/project/hooks'
import { useTaskLabelsData } from '~/areas/tasks/hooks'
import { LabelDropdown } from '~/global/components/dropdowns'
import { PrettyLabels } from '~/global/components/labels'
import { useWorkspaceLabels } from '~/global/hooks'
import { type ReactNode } from 'react'

import { MentionCardButton } from '../mention-card-button'

export type TaskLabelsFieldProps = {
  task: TaskSchema
}
export const TaskLabelsField = ({ task }: TaskLabelsFieldProps) => {
  const { onChange, disabled } = useTaskLabelsData(task)

  if (task.type === 'CHUNK') return null

  return (
    <LabelsField
      selectedLabelIds={task.labelIds}
      workspaceId={task.workspaceId}
      onChange={onChange}
      disabled={disabled}
    >
      <InlineLabels labelIds={task.labelIds} workspaceId={task.workspaceId} />
    </LabelsField>
  )
}

export type ProjectLabelsFieldProps = {
  project: ProjectSchema
}
export const ProjectLabelsField = ({ project }: ProjectLabelsFieldProps) => {
  const updateProject = useProjectUpdater()

  return (
    <LabelsField
      selectedLabelIds={project.labelIds}
      workspaceId={project.workspaceId}
      onChange={(labelIds) => updateProject(project, { labelIds })}
    >
      <InlineLabels
        labelIds={project.labelIds}
        workspaceId={project.workspaceId}
      />
    </LabelsField>
  )
}

type LabelsFieldProps = {
  selectedLabelIds: NormalTaskSchema['labelIds']
  workspaceId: NormalTaskSchema['workspaceId']
  onChange: (labelIds: NormalTaskSchema['labelIds']) => void
  disabled?: boolean
  children: ReactNode
}
const LabelsField = ({
  selectedLabelIds,
  workspaceId,
  onChange,
  disabled,
  children,
}: LabelsFieldProps) => {
  return (
    <LabelDropdown
      selectedLabelIds={selectedLabelIds}
      workspaceId={workspaceId}
      onChange={onChange}
    >
      <MentionCardButton
        fullWidth={selectedLabelIds.length > 0}
        disabled={disabled}
      >
        {children}
      </MentionCardButton>
    </LabelDropdown>
  )
}

type InlineLabelsProps = {
  labelIds: LabelIdSchema['labelId'][]
  workspaceId: WorkspaceSchema['id']
}

const InlineLabels = ({ labelIds, workspaceId }: InlineLabelsProps) => {
  const workspaceLabels = useWorkspaceLabels(workspaceId)
  const labels = workspaceLabels.filter((label) => labelIds.includes(label.id))

  return labels.length === 0 ? (
    <div className='flex flex-row items-center gap-1 text-semantic-neutral-text-disabled'>
      <PlusSolid className='w-3.5 h-3.5 text-semantic-neutral-icon-subtle' />
      Add label
    </div>
  ) : (
    <PrettyLabels values={labels} />
  )
}
