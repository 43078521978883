import { makeLog } from '@motion/web-base/logging'
import {
  type AuthState,
  type AuthStateAuthenticated,
  useAuth,
  type User,
} from '@motion/web-common/auth'
import { useHasTreatment } from '@motion/web-common/flags'
import {
  type SubscriptionState,
  type SubscriptionStateInactive,
  useSubscriptionState,
} from '@motion/web-common/subscriptions'

import { useAppDispatch, useAppSelector } from '~/state/hooks'
import { fetchTeam } from '~/state/projectManagement/teamThunks'
import { selectTeam } from '~/state/team'
import { type Team } from '~/state/TeamTypes'
import { type ReactNode, useEffect, useState } from 'react'

import { ConnectedInactiveTeamSubscription } from './components/connected-team-subscription-expired'
import { NoActiveSubscription } from './components/NoActiveSubscription'
import { TieredNoActiveSubscription } from './components/tiered-no-active-subscription'

import { LoadingPage } from '../../components/LoadingPage'
import { MaintenanceModePageV2 } from '../shell/maintenance-mode'

const log = makeLog('ensure-valid-subscription')

type ConnectedEnsureValidSubscriptionProps = {
  children: ReactNode
}
export const ConnectedEnsureValidSubscription = (
  props: ConnectedEnsureValidSubscriptionProps
) => {
  const subscription = useSubscriptionState()
  const auth = useAuth()

  log('render', subscription)

  if (auth.auth.state !== 'authenticated') {
    return <LoadingPage id='ensure-valid-subscription' />
  }

  return (
    <EnsureSubscription
      user={auth.auth.user}
      subscription={subscription}
      signOut={auth.signOut}
    >
      {props.children}
    </EnsureSubscription>
  )
}

type EnsureSubscriptionProps = {
  user: AuthStateAuthenticated['user']
  signOut: AuthState['signOut']
  subscription: SubscriptionState
  children: ReactNode
}

export const EnsureSubscription = (props: EnsureSubscriptionProps) => {
  const shouldUseTieredPricingV1 = useHasTreatment('tiered-pricing-v1')
  const { children, user, signOut, subscription } = props

  if (subscription.state === 'unset') {
    return <LoadingPage id='loading-subscription' />
  }

  if (subscription.state === 'error') {
    return <MaintenanceModePageV2 subtitle='Thank you for your patience!' />
  }

  if (subscription.state === 'none') {
    return shouldUseTieredPricingV1 ? (
      <TieredNoActiveSubscription email={user.email} signOut={signOut} />
    ) : (
      <NoActiveSubscription
        user={{ email: user.email ?? 'unknown' }}
        logOutHandler={signOut}
      />
    )
  }

  if (subscription.state === 'inactive') {
    return (
      <ConnectedInvalidSubscription
        user={user}
        subscription={subscription.value}
        logout={signOut}
      />
    )
  }

  return children
}

type ConnectedInvalidSubscriptionProps = {
  user: User
  subscription: SubscriptionStateInactive['value']
  logout(): Promise<void>
}

const ConnectedInvalidSubscription = (
  props: ConnectedInvalidSubscriptionProps
) => {
  const team = useAppSelector(selectTeam)
  const [loading, setLoading] = useState(!hasTeam(team))
  const dispatch = useAppDispatch()
  const shouldUseTieredPricingV1 = useHasTreatment('tiered-pricing-v1')

  const { subscription, user, logout } = props

  useEffect(() => {
    if (loading === false) return

    void dispatch(fetchTeam()).finally(() => setLoading(false))
  }, [dispatch, loading])

  if (loading) {
    return <LoadingPage id='loading-subscription-team' />
  }

  if (hasTeam(team)) {
    return (
      <ConnectedInactiveTeamSubscription
        user={user}
        subscription={subscription}
        team={team}
        signOut={logout}
      />
    )
  }

  return shouldUseTieredPricingV1 ? (
    <TieredNoActiveSubscription email={user.email} signOut={logout} />
  ) : (
    <NoActiveSubscription user={user} logOutHandler={logout} />
  )
}

function hasTeam(team: Team | null): team is Team {
  return team != null && !!team.id
}
