import { PencilSolid } from '@motion/icons'
import { Button, ButtonGroup, PopoverTrigger } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'

import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { ModalFieldButton } from '~/areas/task-project/components'
import { useState } from 'react'
import { useController } from 'react-hook-form'

export const ControlledDefinitionDescriptionField = () => {
  const { form } = useFlowTemplateForm()
  const { control, watch } = form
  const initialDescription = watch('definitionDescription')

  const [currentDescription, setCurrentDescription] =
    useState(initialDescription)

  const { field } = useController({
    name: 'definitionDescription',
    control,
  })

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <div className='flex flex-col items-start gap-1.5 w-[292px] h-[112px] self-stretch rounded [&_label]:h-[72px]'>
          <TextField
            fullWidth
            multiline
            placeholder='Enter description (100 characters max)'
            onChange={(val) => {
              setCurrentDescription(val.slice(0, 100))
            }}
            value={currentDescription}
          />
          <div className='flex py-2 px-3 justify-end self-stretch w-full'>
            <ButtonGroup>
              <Button
                sentiment='neutral'
                size='small'
                variant='muted'
                onClick={close}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  field.onChange(currentDescription)
                  close()
                }}
                size='small'
              >
                Save
              </Button>
            </ButtonGroup>
          </div>
        </div>
      )}
    >
      <ModalFieldButton label='Description'>
        <PencilSolid />
        Edit
      </ModalFieldButton>
    </PopoverTrigger>
  )
}
