import { BlockedBySolid, BlocksSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { Tooltip } from '@motion/ui/base'

import {
  BlockedByBlockerDropdown,
  BlockingTasksDropdown,
} from '~/areas/tasks/components/dropdowns'
import { type NormalTaskWithRelations } from '~/global/proxies'
import { twMerge } from 'tailwind-merge'

import { CardButton } from '../components'

export type TaskBlockersFieldProps = {
  task: NormalTaskWithRelations
  type: 'blocks' | 'blockedBy'
}
export const TaskBlockersField = ({ type, task }: TaskBlockersFieldProps) => {
  const { count, Dropdown, Icon, iconColor, tooltipContent } = getInfo(
    type,
    task
  )

  return (
    <Dropdown task={task}>
      <Tooltip asChild content={tooltipContent}>
        <CardButton noOverflow>
          <div className='flex items-center flex-nowrap gap-1'>
            <Icon className={twMerge('shrink-0 w-3 h-3', iconColor)} />
            <span className='text-semantic-neutral-text-default'>{count}</span>
          </div>
        </CardButton>
      </Tooltip>
    </Dropdown>
  )
}

function getInfo(
  type: TaskBlockersFieldProps['type'],
  task: NormalTaskWithRelations
) {
  if (type === 'blocks') {
    const count = task.blockingTaskIds.length
    return {
      count,
      Dropdown: BlockingTasksDropdown,
      Icon: BlocksSolid,
      iconColor: 'text-semantic-warning-icon-default',
      tooltipContent: templateStr('Blocks {{count}} {{item}}', {
        count,
        item: count > 1 ? 'tasks' : 'task',
      }),
    }
  }

  const count = task.blockedByTaskIds.length
  return {
    count,
    Dropdown: BlockedByBlockerDropdown,
    Icon: BlockedBySolid,
    iconColor: 'text-semantic-error-icon-default',
    tooltipContent: templateStr('Blocked by {{count}} {{item}}', {
      count,
      item: count > 1 ? 'tasks' : 'task',
    }),
  }
}
