import { createAsyncThunk } from '@reduxjs/toolkit'

import { getProxy } from './backgroundProxy'

export type SubscriptionBody = {
  isMonthly: boolean
  setupIntentId?: string
  tier?: string
} & ({ trialLength: number } | { lowCostTrialPriceId: string })

export interface SetupIntentResponse {
  id: string
  clientSecret: string
}

export interface SubscriptionResponse {
  error?: string
  id?: string
  clientSecret?: string
  subscription?: {
    id: string
  }
}

const corrilyServiceHandler = getProxy('CorrilyService')

export const createSetupIntent = createAsyncThunk<
  SetupIntentResponse | undefined,
  void
>('corrily/createSetupIntent', () => {
  return corrilyServiceHandler.createSetupIntent()
})

export const createSubscription = createAsyncThunk<
  SubscriptionResponse,
  SubscriptionBody
>('corrily/createSubscription', (body) => {
  return corrilyServiceHandler.createSubscription(body)
})
