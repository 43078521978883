import { type DeadlineType } from '@motion/rpc/types'

import { useController } from 'react-hook-form'

import {
  HardDeadlineModalFieldButton,
  SubItemIndentMarker,
} from '../components'
import { useTaskForm } from '../hooks'

export const DeadlineToggle = () => {
  const { form, disabledFields, hiddenFields } = useTaskForm()

  const { control } = form

  const { field: deadlineTypeField } = useController({
    name: 'deadlineType',
    control,
  })

  const deadlineType = deadlineTypeField.value
  const disabled = disabledFields.has('deadlineType')
  const shouldIndent = !hiddenFields.has('dueDate')

  const onChange = (value: DeadlineType) => {
    deadlineTypeField.onChange(value)
  }

  const hardDeadlineField = (
    <HardDeadlineModalFieldButton
      deadlineType={deadlineType}
      onChange={onChange}
      disabled={disabled}
    />
  )

  if (!shouldIndent) return hardDeadlineField

  return (
    <div className='flex flex-row items-center gap-0.5'>
      <SubItemIndentMarker />
      {hardDeadlineField}
    </div>
  )
}
