import {
  type CreateNoteRequestSchema,
  type ProjectsV2CreateRequest,
  type TasksV2CreateSchema,
} from '@motion/rpc-types'
import { findDefaultStatus } from '@motion/shared/common'
import { getEnabledStagesWithDates } from '@motion/ui-logic/pm/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useExperiment } from '@motion/web-common/flags'

import { useCreateNote } from '~/areas/notes'
import { useFindFolderItem, useWorkspaceFns } from '~/global/hooks'
import {
  useCreateProject,
  useCreateTask,
  useEstimateTaskProps,
  useNoteById,
} from '~/global/rpc/v2'
import { useResolveWorkspaceId } from '~/pages/pm/notes/hooks'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useParams } from 'react-router'

const DEFAULT_TASK_DURATION_MINUTES = 30

export type CreateEntityResult = { entityId: string; entityLabel: string }

export function useInlineCreate() {
  const [isCreating, setIsCreating] = useState(false)

  let { noteId } = useParams()

  const { data: note } = useNoteById({ id: noteId })

  const user = useAuthenticatedUser()

  const findFolderItem = useFindFolderItem()

  const resolveWorkspaceId = useResolveWorkspaceId()

  const { getWorkspaceStatuses, getWorkspaceProjectById } = useWorkspaceFns()

  const { mutateAsync: createTaskMutation } = useCreateTask()
  const { mutateAsync: estimateTaskPropsMutation } = useEstimateTaskProps()
  const docsAiOnToolbar = useExperiment('docs-ai-on-toolbar')
  const hasDocsAiOnToolbar =
    docsAiOnToolbar.value && docsAiOnToolbar.value !== 'off'

  const { mutateAsync: createProjectMutation } = useCreateProject()

  const createNoteMutation = useCreateNote()

  const createTask = async (name: string) => {
    if (note == null) {
      throw new Error('Doc not found')
    }

    const workspaceId = resolveWorkspaceId(note)

    if (workspaceId == null) {
      throw new Error('Workspace not found')
    }

    const workspaceStatuses = getWorkspaceStatuses(workspaceId)
    const defaultStatus = findDefaultStatus(workspaceStatuses)

    // Fallback
    let startDate = DateTime.now()
    let dueDate =
      // If start date is Thursday, set due date to next Friday
      // Otherwise, set due date to this Friday
      startDate.weekday === 4
        ? startDate.plus({ days: 8 })
        : startDate.plus({ days: 5 - startDate.weekday })

    const folderItem = findFolderItem((item) => item.itemId === note.id)
    const projectId = folderItem?.find(
      (item) => item.itemType === 'PROJECT'
    )?.itemId
    const project = getWorkspaceProjectById(projectId)

    if (project != null) {
      const stagesWithDates = getEnabledStagesWithDates(project.stages, {
        start: project.startDate,
        due: project.dueDate,
      })

      const {
        stage: currentProjectStage,
        start: currentStageStartDateStr,
        due: currentStageDueDateStr,
      } = stagesWithDates.find(
        (stage) =>
          stage.stage.stageDefinitionId === project.activeStageDefinitionId
      ) || {}

      // If the projects has stages, use the current stage dates
      if (
        currentProjectStage != null &&
        currentStageStartDateStr != null &&
        currentStageDueDateStr != null
      ) {
        startDate = DateTime.fromISO(currentStageStartDateStr)
        dueDate = DateTime.fromISO(currentStageDueDateStr)
      }
      // If the project has no stages, use the project dates
      else if (project.startDate != null && project.dueDate != null) {
        startDate = DateTime.fromISO(project.startDate)
        dueDate = DateTime.fromISO(project.dueDate)
      }
    }

    const taskToCreate: TasksV2CreateSchema['data'] = {
      type: 'NORMAL',
      assigneeUserId: user.uid,
      deadlineType: 'SOFT',
      description: '',
      dueDate: dueDate.endOf('day').toISO(),
      duration: DEFAULT_TASK_DURATION_MINUTES,
      name: name.trim(),
      priorityLevel: 'MEDIUM',
      scheduleId: 'work',
      startDate: startDate.endOf('day').toISO(),
      statusId: defaultStatus?.id,
      isAutoScheduled: true,
      workspaceId,
      projectId: project?.id,
    }

    try {
      const data = await createTaskMutation({ data: taskToCreate })

      setIsCreating(false)

      const entityId = data.id
      const entityLabel = data.models.tasks[entityId].name

      if (hasDocsAiOnToolbar) {
        estimateTaskPropsMutation({
          noteId: note.id,
          taskId: entityId,
        })
      }

      return {
        entityId,
        entityLabel,
      }
    } catch (error) {
      setIsCreating(false)
      throw error
    }
  }

  const createProject = async (name: string) => {
    if (note == null) {
      throw new Error('Doc not found')
    }

    const workspaceId = resolveWorkspaceId(note)
    if (workspaceId == null) {
      throw new Error('Workspace not found')
    }

    const projectToCreate: ProjectsV2CreateRequest = {
      name: name.trim(),
      workspaceId,
      startDate: DateTime.now().toISODate(),
      dueDate: DateTime.now().plus({ days: 14 }).toISODate(),
      folderId: undefined, // ToDo: Add folderId
      priorityLevel: 'MEDIUM',
      managerId: user.uid,
    }

    try {
      const data = await createProjectMutation(projectToCreate)

      setIsCreating(false)

      const entityId = data.id
      const entityLabel = data.models.projects[entityId].name

      return {
        entityId,
        entityLabel,
      }
    } catch (error) {
      setIsCreating(false)
      throw error
    }
  }

  const createNote = async (name: string) => {
    if (note == null) {
      throw new Error('Doc not found')
    }

    const noteToCreate: CreateNoteRequestSchema = {
      title: name.trim(),
      targetId: note.targetId,
      targetType: note.targetType,
      parentNoteId: note.id,
    }

    const data = await createNoteMutation(noteToCreate)

    if (data == null) {
      throw new Error('Failed to create doc')
    }

    setIsCreating(false)

    const entityId = data.id
    const entityLabel = data.models.notes[entityId].title

    return {
      entityId,
      entityLabel,
    }
  }

  const create = async (
    type: string,
    name: string
  ): Promise<CreateEntityResult> => {
    setIsCreating(true)

    recordAnalyticsEvent('PROJECT_MANAGEMENT_NOTE_INLINE_CREATE', {
      type,
    })

    switch (type) {
      case 'task':
        return await createTask(name)
      case 'project':
        return await createProject(name)
      case 'note':
        return await createNote(name)
      default:
        throw new Error('Invalid type')
    }
  }

  return {
    create,
    isCreating,
  }
}
