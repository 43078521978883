import { type NoteSchema } from '@motion/zod/client'

import { WebMentionsPlugin } from '~/areas/notes/components/editor-plugins'
import {
  useCreateNoteMention,
  useDeleteNoteMention,
} from '~/global/hooks/notes'
import { useState } from 'react'

import { UninvitedUserToast } from '../../components/uninvited-user-tooltip'

export type DocsMentionsPluginProps = {
  noteId?: NoteSchema['id']
}

export function DocsMentionsPlugin({ noteId }: DocsMentionsPluginProps) {
  const [uninvitedUserId, setUninvitedUserId] = useState<string | null>(null)

  const createNoteMention = useCreateNoteMention()
  const deleteNoteMention = useDeleteNoteMention()

  const handleMentionCreate = async (
    motionId: string,
    entityId: string,
    entityType: string,
    silent?: boolean
  ) => {
    if (noteId != null) {
      const result = await createNoteMention({
        noteId,
        mentionId: motionId,
        targetId: entityId,
        targetType: entityType,
        silent,
      })

      result?.mentions.forEach(({ targetId, canAccess }) => {
        if (!canAccess) {
          setUninvitedUserId(targetId)
        }
      })
    }
  }

  const handleMentionDeleted = (motionId: string) => {
    if (noteId != null) {
      deleteNoteMention({ noteId, mentionId: motionId })
    }
  }

  return (
    <>
      <WebMentionsPlugin
        onMentionCreated={handleMentionCreate}
        onMentionDeleted={handleMentionDeleted}
      />

      <UninvitedUserToast
        noteId={noteId}
        userId={uninvitedUserId}
        onClose={() => setUninvitedUserId(null)}
      />
    </>
  )
}

DocsMentionsPlugin.Node = WebMentionsPlugin.Node
