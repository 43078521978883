import { type NoteSchema } from '@motion/rpc-types'

import { WorkspacesSearchableTree } from '~/global/components/dropdowns'

import { useNoteLocation, useUpdateNoteLocation } from '../hooks'

export type MoveNoteToPopoverProps = {
  noteId: NoteSchema['id']
  onClose: () => void
}

export function MoveNoteToPopover({ noteId, onClose }: MoveNoteToPopoverProps) {
  const updateNoteLocation = useUpdateNoteLocation({ noteId })

  const [parentId] = useNoteLocation({
    noteId,
  })

  return (
    <WorkspacesSearchableTree
      selectedId={parentId ?? null}
      onChange={(selected) => {
        updateNoteLocation(selected)
        onClose()
      }}
      closePopover={onClose}
      allowSelectAnyNode
      hideNoProject
      includeNotes
    />
  )
}
