import {
  SharedStateProvider,
  type StateKey,
  useSharedStateContext,
} from '@motion/react-core/shared-state'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'
import { useTitle } from '@motion/web-common/html'
import { DB, SharedStatePersister } from '@motion/web-common/storage'

import { usePageData } from '~/areas/project-management/pages/pm-v3/routes'
import { type PageParams } from '~/areas/project-management/pages/pm-v3/routes/types'
import { ViewStateKey } from '~/areas/project-management/pages/pm-v3/view-state'
import { useSelectedView } from '~/areas/project-management/pages/pm-v3/views'
import { ErrorPage } from '~/global/components/error-page'
import { useEffect } from 'react'
import { Outlet } from 'react-router'

const PERSIST_KEYS = [ActiveFilterKey, ViewStateKey]

const persistKeysFilter = (key: StateKey<any>) => PERSIST_KEYS.includes(key)

export const PageTypeShellWithContext = () => {
  const route = usePageData()

  useShellTitle(route)

  return (
    <ErrorPage>
      <SharedStateProvider
        name='filter'
        initialValues={route.state}
        batchTime={0}
      >
        <SharedStatePersister
          key={route.stateKey}
          prefix={route.stateKey}
          store={DB.state}
          keyFilter={persistKeysFilter}
        />

        <SyncRoute route={route} />

        <Outlet />
      </SharedStateProvider>
    </ErrorPage>
  )
}

function useShellTitle(route: PageParams) {
  const [selectedView] = useSelectedView()
  useTitle(route.title, selectedView?.name)
}

type SetRouteParamsProps = {
  route: PageParams
}

function SyncRoute(props: SetRouteParamsProps) {
  const ctx = useSharedStateContext()
  useEffect(() => {
    props.route.state.forEach((value, key) => {
      ctx.set(key, value)
    })
    // We only want to reset these values when the route changes
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx, props.route.stateKey])
  return null
}
