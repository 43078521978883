import { classed } from '@motion/theme'

import { Outlet } from 'react-router-dom'

import { ProjectTemplateTable } from './components'

export function ProjectTemplatesSettingsPage() {
  return (
    <Section>
      <ProjectTemplateTable />

      <Outlet />
    </Section>
  )
}

const Section = classed('section', {
  base: 'flex flex-col gap-8 w-full pb-8',
})
