import { SYNC_SESSION_DAYS_SPAN } from '@motion/ui-logic'
import { useFeatureLimit, useShouldFeatureGate } from '@motion/web-billing'
import { useExperiment } from '@motion/web-common/flags'

export const useAutoScheduleRange = () => {
  const featureFlagAutoScheduleRange = useExperiment('auto-schedule-range')
    .payload?.daysSpan

  const shouldFeatureGate = useShouldFeatureGate()
  const autoScheduleRange = useFeatureLimit('autoScheduleWindow')
  if (shouldFeatureGate && autoScheduleRange) {
    return autoScheduleRange
  }

  return featureFlagAutoScheduleRange ?? SYNC_SESSION_DAYS_SPAN.after
}
