import { PencilSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { IconButton, Tooltip } from '@motion/ui/base'

import { useState } from 'react'

import { ActionItemExistingTaskTag } from './action-item-existing-task-tag'
import { ActionItemStatus } from './action-item-status'
import { ActionItemTitle } from './action-item-title'

import { useActionItemContext } from '../provider'

export function ActionItemTitleSection() {
  const [inEditMode, setInEditMode] = useState(false)
  const { isRejected } = useActionItemContext()

  return (
    <TaskTitleContainer>
      <div className='shrink-0'>
        <ActionItemStatus />
      </div>
      <div className='flex gap-1.5 min-w-0 flex-1'>
        <div className='flex gap-2 items-center min-w-0 flex-1'>
          <ActionItemTitle editMode={inEditMode} setEditMode={setInEditMode} />
          {!inEditMode && !isRejected && (
            <>
              <Tooltip asChild content='Edit title'>
                <IconButton
                  onClick={() => setInEditMode(true)}
                  icon={PencilSolid}
                  sentiment='neutral'
                  variant='mutedBg'
                  size='xsmall'
                />
              </Tooltip>
              <ActionItemExistingTaskTag />
            </>
          )}
        </div>
      </div>
    </TaskTitleContainer>
  )
}

const TaskTitleContainer = classed('span', {
  base: 'min-w-0 w-full text-[13px] leading-[16px] font-normal flex items-center gap-1.5',
})
