import {
  RichTextContent,
  RichTextToolbar,
  useRichTextEditor,
} from '@motion/ui/rte'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useController } from 'react-hook-form'

import { useActionItemForm } from '../../hooks'

export const ControlledDescriptionField = () => {
  const { form } = useActionItemForm()
  const { control } = form

  const { field } = useController({
    name: 'description',
    control,
  })

  const modalApi = useModalApi()

  const editor = useRichTextEditor({
    className: 'h-full',
    placeholder: 'Enter message',
    value: field.value ?? '',
    disableImageUpload: true,
    onChange(v) {
      field.onChange(v)
    },
  })

  return (
    <div className='flex-1 flex flex-col'>
      <div className='py-1 sticky top-0 z-[1] bg-modal-bg'>
        <RichTextToolbar
          editor={editor}
          disableImageUpload
          size='normal'
          onLinkClick={async (initialLink: string = '') => {
            const response = await modalApi.prompt('link-modal', {
              initialLink,
            })

            if (response !== ModalDismissed) {
              return response
            }
            return null
          }}
        />
      </div>

      <div className='flex gap-2 p-2 flex-grow shrink-0'>
        <RichTextContent editor={editor} className='flex-grow' />
      </div>
    </div>
  )
}
