import { CrownSolid, InformationCircleSolid, PlusSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import { TutorialButtonLink } from '~/global/components'
import { useWorkspaceById } from '~/global/hooks'
import { useParams } from 'react-router-dom'

import { CustomFieldsTable } from './custom-fields-table'

import {
  SettingContainer,
  SettingRow,
  SettingTitle,
} from '../../components/setting-table'
import { AddCustomFieldButton } from '../../custom-fields/add-custom-field-button'
import { useFeatureGatedAddCustomField } from '../../custom-fields/hooks'
import { EmptyContainer } from '../empty-container'

export const WorkspaceCustomFieldSettings = () => {
  const { workspaceId = '' } = useParams<{ workspaceId: string }>()
  const workspace = useWorkspaceById(workspaceId)
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(workspaceId)

  const modalApi = useModalApi()

  const [canAddCustomField, onClickAddCustomField] =
    useFeatureGatedAddCustomField(workspace, () => {
      if (!workspace) {
        return
      }
      modalApi.prompt('add-custom-field', { workspace })
    })

  if (!workspace) {
    return null
  }

  const showAddButton = !!workspaceCustomFields.length

  const emptyStateButtonText = 'Add custom field'
  const emptyStateButtonChildren = canAddCustomField ? (
    emptyStateButtonText
  ) : (
    <div className='flex flex-nowrap'>
      {emptyStateButtonText}&nbsp;{!canAddCustomField && <CrownSolid />}
    </div>
  )

  return (
    <div className='flex flex-col gap-3'>
      <HeadingContainer>
        <SettingTitle largeFont>Custom Fields</SettingTitle>
        <TutorialButtonLink
          lesson='motion-organization'
          onClick={() => {
            recordAnalyticsEvent('CUSTOM_FIELD_TUTORIAL_CLICKED')
          }}
          text='Watch Tutorial'
          rounded
        />
      </HeadingContainer>

      {workspaceCustomFields.length > 0 ? (
        <CustomFieldsTable fields={workspaceCustomFields} />
      ) : (
        <SettingContainer>
          <SettingRow>
            <EmptyContainer
              title='No custom fields yet'
              description='Use custom fields to add more information to your tasks and projects!'
              icon={InformationCircleSolid}
              action={{
                icon: PlusSolid,
                children: emptyStateButtonChildren,
                onClick: onClickAddCustomField,
              }}
            />
          </SettingRow>
        </SettingContainer>
      )}

      <div>
        {showAddButton && <AddCustomFieldButton workspaceId={workspaceId} />}
      </div>
    </div>
  )
}

const HeadingContainer = classed('div', {
  base: 'flex items-center justify-between',
})
