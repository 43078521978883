import {
  ChevronDownOutline,
  PlusSolid,
  PuzzleSolid,
  SearchSolid,
  XSolid,
} from '@motion/icons'
import { Button, IconButton, UnstyledModal } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { omit } from '@motion/utils/core'
import { Sentry } from '@motion/web-base/sentry'
import { useWillExceedWorkspaceFeatureCap } from '@motion/web-billing'
import { HasExperiment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'
import { type FolderSchema } from '@motion/zod/client'

import {
  FlowsAICard,
  FlowTemplateCardLink,
  LegacyTemplateCardButton,
  useFlowTemplateModalUrl,
} from '~/areas/flows'
import { useTaskPrefillData } from '~/areas/project/modals/project-modal/hooks'
import { usePageData } from '~/areas/project-management/pages/pm-v3/routes'
import { ShellVars } from '~/areas/task-project/components'
import { getNextTier, TieredPricingUpsellButton } from '~/areas/tiered-pricing'
import { useCurrentTier } from '~/areas/tiered-pricing/hooks'
import { useLookup } from '~/global/cache'
import { WorkspaceBadge } from '~/global/components/badges'
import { ALL_WORKSPACES_ID } from '~/global/components/dropdowns'
import {
  useAllLegacyProjectTemplates,
  useAllProjectDefinitions,
  useMyTasksWorkspace,
  useProjectDefinitions,
  useWorkspaceById,
} from '~/global/hooks'
import { useProjectModalUrl } from '~/global/navigation'
import { useSearchParams } from '~/routing'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  CreateProjectWorkspaceDropdown,
  FormShell,
  Header,
  HeaderText,
  NewFlowTemplateCardLink,
  NewFlowTemplateLink,
  NewLegacyTemplateCardLink,
  NewLegacyTemplateLink,
  ProjectAICard,
  ProjectScratchCard,
  TemplateSearchResults,
} from './components'
import { useCloseCreateProjectModal } from './hooks'
import { type CreateProjectSearchParams } from './utils'

import { useCreateProject } from '../../hooks'
import { useSetupProjectModalUrl } from '../setup-project-modal'

type CreateProjectModalProps = {
  open: boolean
}

export function ConnectedCreateProjectModal({ open }: CreateProjectModalProps) {
  const closeModal = useCloseCreateProjectModal()

  return (
    <UnstyledModal
      data-testid='create-project-modal'
      type='page'
      visible={open}
      onClose={closeModal}
      withAnimation
      overlayClassName='bg-modal-overlay'
    >
      <CreateProjectModalContent onClose={closeModal} />
    </UnstyledModal>
  )
}

function CreateProjectModalContent({ onClose }: { onClose: () => void }) {
  const { workspaceId: workspaceIdParam, folderId: folderIdParam } = useParams<{
    workspaceId?: string
    folderId?: string
  }>()

  const { forTaskId, forWorkspace, forFolder } =
    useSearchParams<CreateProjectSearchParams>()

  const navigate = useNavigate()
  const modalApi = useModalApi()

  const createProject = useCreateProject()
  const getTaskData = useTaskPrefillData()

  const buildProjectModalUrl = useProjectModalUrl()
  const buildSetupProjectModalUrl = useSetupProjectModalUrl()
  const buildFlowTemplateModalUrl = useFlowTemplateModalUrl()
  const route = usePageData()

  const myTasksWorkspace = useMyTasksWorkspace()

  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(
    forWorkspace ??
      (route.page === 'my-tasks'
        ? (myTasksWorkspace?.id ?? ALL_WORKSPACES_ID)
        : (workspaceIdParam ?? ALL_WORKSPACES_ID))
  )

  // If "all workspaces" is selected, default to my tasks workspace's id
  const validSelectedWorkspaceId =
    selectedWorkspaceId === ALL_WORKSPACES_ID
      ? (myTasksWorkspace?.id ?? null)
      : selectedWorkspaceId

  const selectedWorkspace = useWorkspaceById(selectedWorkspaceId)
  const lookup = useLookup()

  const allTemplates = useAllLegacyProjectTemplates()
  const selectedTemplates = useMemo(() => {
    // If we're creating a project from a task, we don't want to show any templates
    if (forTaskId) return []

    return selectedWorkspaceId === ALL_WORKSPACES_ID
      ? allTemplates
      : allTemplates.filter(
          (template) => template.workspaceId === selectedWorkspaceId
        )
  }, [forTaskId, selectedWorkspaceId, allTemplates])

  const workspaceProjectDefinitions = useProjectDefinitions(
    selectedWorkspace?.id
  )
  const allProjectDefinitions = useAllProjectDefinitions()

  const projectDefinitions = selectedWorkspace?.id
    ? workspaceProjectDefinitions
    : allProjectDefinitions

  const [search, setSearch] = useState('')

  const currentTier = useCurrentTier()
  const nextTier = getNextTier(currentTier)
  const canCreateFlowTemplate = !useWillExceedWorkspaceFeatureCap(
    'projectDefinitions',
    selectedWorkspace?.id,
    1
  )

  const onCreateProjectFromScratch = async () => {
    let projectId = 'new'

    // If creating project from task, create the project directly and redirect to project
    if (forTaskId != null) {
      const taskData = await getTaskData(forTaskId)
      if (taskData == null) {
        return
      }

      // Default to my tasks workspace if all workspaces is selected
      if (validSelectedWorkspaceId == null) {
        Sentry.captureMessage(
          'No workspace found when creating project from task',
          {
            extra: {
              forTaskId,
              selectedWorkspaceId,
            },
          }
        )
        return
      }

      // If the workspace is different from the task's workspace, omit fields that are not allowed
      const taskDataFields =
        validSelectedWorkspaceId !== taskData.workspaceId
          ? omit(taskData, ['customFieldValues', 'statusId', 'labelIds'])
          : taskData

      const project = await createProject({
        ...taskDataFields,
        workspaceId: validSelectedWorkspaceId,
        taskId: forTaskId,
      })

      if (project == null) {
        return
      }

      projectId = project.id
    }

    const url = buildProjectModalUrl({
      project: projectId,
      forWorkspace,
      forFolder,
    })

    navigate(url)
  }

  let targetFolderId: FolderSchema['id'] | null =
    forFolder ?? folderIdParam ?? null

  if (targetFolderId != null) {
    const matchedFolder = lookup('folders', targetFolderId)

    // Prevent creating a project in a folder that does not belong to the target workspace
    if (!matchedFolder || matchedFolder.targetId !== targetFolderId) {
      targetFolderId = null
    }
  }

  return (
    <ShellVars>
      <FormShell>
        <div className='flex flex-col h-full'>
          <Header>
            <HeaderText>Create project</HeaderText>
            <div className='flex gap-2'>
              <IconButton
                icon={XSolid}
                sentiment='neutral'
                variant='muted'
                onClick={onClose}
              />
            </div>
          </Header>

          <div className='flex flex-row items-center gap-2 bg-semantic-neutral-surface-raised-bg-subtlest p-4'>
            <TextField
              showClearButton
              sentiment={search ? 'active' : 'default'}
              prefix={<SearchSolid />}
              placeholder='Search templates'
              value={search}
              onChange={(searchValue) => {
                setSearch(searchValue)
              }}
            />

            <CreateProjectWorkspaceDropdown
              onChange={(workspace) => setSelectedWorkspaceId(workspace.id)}
              selectedWorkspaceId={selectedWorkspaceId}
            >
              <Button variant='outlined' sentiment='neutral' size='small'>
                <div className='flex flex-row items-center justify-between gap-2 px-[5px] py-1'>
                  <WorkspaceBadge />
                  <span className='text-semantic-neutral-text-default text-sm'>
                    {selectedWorkspace?.name ?? 'All workspaces'}
                  </span>
                  <ChevronDownOutline />
                </div>
              </Button>
            </CreateProjectWorkspaceDropdown>
          </div>

          {search !== '' ? (
            <TemplateSearchResults
              search={search}
              clearSearch={() => setSearch('')}
              legacyTemplates={selectedTemplates}
              flowTemplates={projectDefinitions}
            />
          ) : (
            <div className='flex flex-col overflow-auto'>
              <div className='gap-6 flex flex-col px-4 py-6'>
                <div className='flex gap-4 mb-2'>
                  <div className='flex-1'>
                    <FlowsAICard
                      workspaceId={validSelectedWorkspaceId ?? undefined}
                      onClose={onClose}
                    />
                  </div>
                  <HasExperiment name='ai-project-creation-m1'>
                    <div className='flex-1'>
                      <ProjectAICard
                        workspaceId={validSelectedWorkspaceId ?? undefined}
                        onClose={onClose}
                      />
                    </div>
                  </HasExperiment>
                  <div className='flex-1'>
                    <ProjectScratchCard onClick={onCreateProjectFromScratch} />
                  </div>
                </div>

                <div className='flex flex-row items-center justify-between'>
                  <div className='flex items-center gap-2'>
                    <PuzzleSolid className='size-4 text-semantic-neutral-icon-default' />
                    <HeaderText>Create from Template</HeaderText>
                  </div>

                  <NewFlowTemplateLink
                    workspaceId={selectedWorkspace?.id}
                    onClose={onClose}
                  >
                    <TieredPricingUpsellButton
                      feature='projectDefinitions'
                      featureLocked={!canCreateFlowTemplate}
                      variant='muted'
                      sentiment='neutral'
                      size='small'
                    >
                      <PlusSolid />
                      New project template
                    </TieredPricingUpsellButton>
                  </NewFlowTemplateLink>
                </div>
                <div className='grid grid-cols-3 gap-3 self-stretch'>
                  {projectDefinitions.map((definition) => (
                    <FlowTemplateCardLink
                      key={definition.id}
                      id={definition.id}
                      name={definition.name}
                      stages={definition.stages}
                      url={buildSetupProjectModalUrl({
                        flowTemplateId: definition.id,
                        forWorkspace: definition.workspaceId,
                        forFolder:
                          targetFolderId ?? definition.folderId ?? undefined,
                      })}
                      editTemplateUrl={buildFlowTemplateModalUrl({
                        forWorkspace: definition.workspaceId,
                        template: 'edit',
                        templateId: definition.id,
                      })}
                    />
                  ))}
                  <NewFlowTemplateCardLink
                    workspaceId={selectedWorkspace?.id}
                    onFeatureGated={(e) => {
                      if (!canCreateFlowTemplate) {
                        e.preventDefault()
                        modalApi.open('tier-upgrade-prompt-modal', {
                          tier: nextTier ?? 'PRO',
                          feature: 'projectDefinitions',
                        })
                      }
                    }}
                  />
                </div>

                {!forTaskId && (
                  <>
                    <div className='flex flex-row items-center justify-between'>
                      <HeaderText>Regular Templates</HeaderText>

                      <NewLegacyTemplateLink
                        workspaceId={selectedWorkspace?.id}
                      >
                        <Button
                          variant='muted'
                          sentiment='neutral'
                          size='small'
                        >
                          <PlusSolid />
                          New regular template
                        </Button>
                      </NewLegacyTemplateLink>
                    </div>
                    <div className='grid grid-cols-3 gap-3 self-stretch'>
                      {selectedTemplates.map((template) => {
                        return (
                          <LegacyTemplateCardButton
                            key={template.id}
                            template={template}
                          />
                        )
                      })}
                      <NewLegacyTemplateCardLink
                        workspaceId={selectedWorkspace?.id}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </FormShell>
    </ShellVars>
  )
}
