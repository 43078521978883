import { UploadSolid } from '@motion/icons'
import { SUPPORTED_AI_MIME_TYPES } from '@motion/shared/files'
import { Button } from '@motion/ui/base'
import { type UploadedFileSchema } from '@motion/zod/client'

import { AttachmentUploadShell } from '~/areas/attachments'
import { AttachmentItemPlaceholder } from '~/areas/attachments/components'
import { useFileUploadState } from '~/areas/attachments/contexts'
import { useController } from 'react-hook-form'

import { AttachmentItem } from './attachment-item'

import { useFlowsAIForm } from '../hooks'

const MAX_ATTACHMENTS = 3

export function Attachments() {
  const { watch, control } = useFlowsAIForm()
  const {
    field: { value, onChange },
  } = useController({
    control: control,
    name: 'uploadedFileIds',
  })

  const workspaceId = watch('workspaceId')

  const { activeFileUploads } = useFileUploadState()

  const filteredActiveFileUploads = activeFileUploads.filter(
    (activeFileUpload) =>
      activeFileUpload.targetId === null && activeFileUpload.targetType === null
  )

  const handleUploadSettled = (uploadedFile: UploadedFileSchema) => {
    onChange([...(value ?? []), uploadedFile.id])
  }

  const handleDelete = (attachmentId: UploadedFileSchema['id']) => {
    onChange(value?.filter((id) => id !== attachmentId))
  }

  return (
    <div className='flex flex-col gap-3'>
      <div className='flex flex-col'>
        {filteredActiveFileUploads.map(({ tempId, fileName, progress }) => (
          <AttachmentItemPlaceholder
            key={tempId}
            fileName={fileName}
            variant='default'
            progress={progress}
          />
        ))}
        {value?.map((attachmentId) => (
          <AttachmentItem
            key={attachmentId}
            attachmentId={attachmentId}
            onDelete={handleDelete}
          />
        ))}
      </div>

      <div className='self-start'>
        <AttachmentUploadShell
          workspaceId={workspaceId}
          onUploadSettled={handleUploadSettled}
          supportedMimeTypes={SUPPORTED_AI_MIME_TYPES}
        >
          {({ trigger }) => (
            <Button
              onClick={trigger}
              variant='outlined'
              sentiment='neutral'
              aria-label='Upload attachment'
              disabled={(value?.length ?? 0) >= MAX_ATTACHMENTS}
            >
              <UploadSolid />
              Upload
            </Button>
          )}
        </AttachmentUploadShell>
      </div>
    </div>
  )
}
