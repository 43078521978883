import { FilledChevronRightSolid } from '@motion/icons'
import { type EmailAccount } from '@motion/rpc/types'
import { classed } from '@motion/theme'
import { Text } from '@motion/ui/base'

import { type MouseEventHandler } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  emailAccount: EmailAccount
  isExpanded: boolean
  onNodeExpanded: (emailAccount: EmailAccount, expanded: boolean) => void
  onStartEmailAuth: () => void
}

/**
 * Renders the Email Account Tree node, which will always have child nodes
 * containing the associated calendar items. The node can be expanded and
 * collapsed to show/hide the nested calendars.
 *
 * @param param0
 * @returns
 */
export const EmailAccountTreeNode = ({
  emailAccount,
  isExpanded,
  onNodeExpanded,
}: Props) => {
  return (
    <button
      className={twMerge(
        'group relative flex w-full items-center border-none bg-transparent py-2 group'
      )}
      onClick={() => onNodeExpanded(emailAccount, !isExpanded)}
    >
      <div
        className='cursor-pointer h-full flex items-center px-0.5 text-semantic-neutral-icon-subtle group-hover:text-semantic-neutral-icon-default transition-colors'
        onClick={(e) => {
          preventBubble(e)
          onNodeExpanded(emailAccount, !isExpanded)
        }}
      >
        <ExpandIcon expanded={isExpanded} />
      </div>
      <div className='flex h-full items-center overflow-hidden'>
        <Text truncate size='sm' className='mr-2'>
          {emailAccount.email}
        </Text>
      </div>
    </button>
  )
}

const ExpandIcon = classed(FilledChevronRightSolid, {
  base: 'transition-transform w-4 h-4 mr-1 ',
  variants: {
    expanded: {
      true: 'rotate-90',
    },
  },
})

const preventBubble: MouseEventHandler<HTMLElement> = (e: React.MouseEvent) => {
  e.preventDefault()
  e.stopPropagation()
}
