import { MotionCache } from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { type SocketEvent } from '@motion/shared/websockets'
import { Sentry } from '@motion/web-base/sentry'
import { client } from '@motion/web-common/rpc'
import { type CustomFieldSchema } from '@motion/zod/client'

import { getCustomFieldQueryFilters } from '~/global/cache'
import { websocketsService } from '~/services/websockets-service'

import {
  bulkTaskOperations,
  feedRefresh,
  foldersRefresh,
  invalidateAccessPermissions,
  invalidateFeaturePermissions,
  invalidateUserSettings,
  invalidateWorkspacesV2,
  meetingInsightsRefresh,
  notesRefresh,
  projectRefresh,
  refreshPastDueTasks,
  refreshProjectDefinition,
  refreshProjectEta,
  refreshScheduledEntities,
  refreshStageDefinition,
  refreshStageEta,
  statusRefresh,
  taskRefresh,
} from './listeners'
import { type Context, type ExtractSocketEvent } from './types'
import { formatWSEventData } from './utils'

import {
  cacheUpdateOnProjectStatsUpdate,
  cacheUpdateOnStageUpdate,
} from '../handlers'
import { log } from '../log'
import { type ProjectEtaUpdate, type StageEtaUpdate } from '../types'

export function handlePmWebsocketEvent(ctx: Context, event: SocketEvent) {
  Sentry.addBreadcrumb({
    category: 'websocket',
    message: event.type,
    level: 'info',
    data: formatWSEventData(event),
  })

  log.info('received pm event', event)
  switch (event.type) {
    case 'workspace.created':
      return void websocketsService.refreshRooms()
    case 'workspace.task.update':
    case 'workspace.recurringTask.update':
      return ctx.batch.append(taskRefresh({ update: [event.data.task.id] }))
    case 'workspace.task.create':
    case 'workspace.recurringTask.create':
      return ctx.batch.append(taskRefresh({ create: [event.data.task.id] }))
    case 'workspace.task.delete':
    case 'workspace.recurringTask.delete':
      return ctx.batch.append(taskRefresh({ delete: [event.data.task.id] }))
    case 'workspace.tasksScheduled':
      ctx.batch.append(
        refreshScheduledEntities({ workspaceId: event.data.workspaceId })
      )
      ctx.batch.append(refreshPastDueTasks())
      return ctx.batch.append(taskRefresh(event.data.mutations))
    case 'workspace.deleted':
      invalidateWorkspacesV2()
      invalidateUserSettings()
      break
    case 'feed.updated':
      return ctx.batch.append(
        feedRefresh({
          [event.data.modelType === 'task' ? 'tasks' : 'projects']: [
            event.data.id,
          ],
        })
      )
    case 'workspace.stage.visited':
      return ctx.batch.append(taskRefresh({ update: event.data.taskIds }))
    case 'workspace.tasks.bulk.updated':
      ctx.batch.append(bulkTaskOperations(event.data, 'update'))

      return ctx.batch.append(
        taskRefresh({
          update: event.data.taskIds,
        })
      )
    case 'workspace.tasks.bulk.deleted':
      ctx.batch.append(bulkTaskOperations(event.data, 'delete'))

      return ctx.batch.append(
        taskRefresh({
          delete: event.data.taskIds,
        })
      )

    case 'team.memberJoined':
      invalidateWorkspacesV2()
      void client.invalidateQueries({
        queryKey: API.teams.queryKeys.currentTeam,
      })
      break
    case 'workspace.customField.create':
    case 'workspace.customField.update': {
      const { customField } = event.data as { customField: CustomFieldSchema }

      MotionCache.upsert(client, getCustomFieldQueryFilters(), {
        models: { customFields: { [customField.id]: customField } },
      })
      break
    }
    case 'workspace.customField.delete': {
      MotionCache.delete(
        client,
        getCustomFieldQueryFilters(),
        'customFields',
        event.data.customField
      )
      break
    }

    case 'workspace.status.create':
      return ctx.batch.append(
        statusRefresh({
          create: [event.data.status.id],
          workspaces: [event.data.status.workspaceId],
        })
      )
    case 'workspace.status.update':
      return ctx.batch.append(
        statusRefresh({
          update: [event.data.status.id],
          workspaces: [event.data.status.workspaceId],
        })
      )
    case 'workspace.status.delete':
      return ctx.batch.append(
        statusRefresh({
          delete: [event.data.status.id],
          workspaces: [event.data.status.workspaceId],
        })
      )

    case 'workspace.label.create':
    case 'workspace.label.update':
    case 'workspace.label.delete':
      invalidateWorkspacesV2()
      break
    case 'workspace.project.create':
      return ctx.batch.append(
        projectRefresh({
          create: [event.data.project.id],
          update: [],
          delete: [],
        })
      )

    case 'workspace.project.update':
      return ctx.batch.append(
        projectRefresh({
          create: [],
          update: [event.data.project.id],
          delete: [],
        })
      )

    case 'workspace.project.delete':
      return ctx.batch.append(
        projectRefresh({
          create: [],
          update: [],
          delete: [event.data.project.id],
        })
      )
    case 'workspace.project.stats.update': {
      const success = cacheUpdateOnProjectStatsUpdate(
        ctx.client,
        event.data.project
      )
      /**
       * If the cache update failed, we need to refresh the project
       */
      if (!success) {
        return ctx.batch.append(
          projectRefresh({
            create: [],
            update: [event.data.project.id],
            delete: [],
          })
        )
      }
      break
    }
    case 'workspace.project.eta.update':
      return ctx.batch.append(
        refreshProjectEta(event.data.project as ProjectEtaUpdate)
      )
    /**
     * workspace.stage.* events are emitted for individual project stages,
     * not stage definitions
     */
    case 'workspace.stage.create':
      return ctx.batch.append(
        projectRefresh({
          create: [],
          update: [event.data.stage.projectId],
          delete: [],
        })
      )
    case 'workspace.stage.update': {
      const success = cacheUpdateOnStageUpdate(ctx.client, event.data.stage)
      /**
       * If the cache update failed, we need to refresh the project
       */
      if (!success) {
        return ctx.batch.append(
          projectRefresh({
            create: [],
            update: [event.data.stage.projectId],
            delete: [],
          })
        )
      }
      break
    }
    case 'workspace.stage.eta.update':
      return ctx.batch.append(
        refreshStageEta(event.data.stage as StageEtaUpdate)
      )
    case 'workspace.note.created':
      return ctx.batch.append(
        notesRefresh({
          create: [event.data.note.id],
        })
      )

    case 'workspace.note.updated':
      return ctx.batch.append(
        notesRefresh({
          update: [event.data.note.id],
        })
      )

    case 'workspace.note.deleted':
      return ctx.batch.append(
        notesRefresh({
          delete: [event.data.note.id],
        })
      )
    case 'folder.created':
      return ctx.batch.append(
        foldersRefresh({
          create: [event.data.folder.id],
        })
      )

    case 'folder.updated':
      return ctx.batch.append(
        foldersRefresh({
          update: [event.data.folder.id],
        })
      )

    case 'folder.deleted':
      return ctx.batch.append(
        foldersRefresh({
          delete: [event.data.folder.id],
        })
      )
    case 'folder.item.created':
    case 'folder.item.updated':
    case 'folder.item.deleted':
      return ctx.batch.append(
        foldersRefresh({
          update: [event.data.folderItem.folderId],
        })
      )
    case 'workspace.project.definition.created':
      return ctx.batch.append(
        refreshProjectDefinition({
          create: [
            {
              workspaceId: event.data.workspaceId,
              id: event.data.id,
            },
          ],
          update: [],
          delete: [],
        })
      )
    case 'workspace.project.definition.updated':
      return ctx.batch.append(
        refreshProjectDefinition({
          create: [],
          update: [
            {
              workspaceId: event.data.workspaceId,
              id: event.data.id,
            },
          ],
          delete: [],
        })
      )
    case 'workspace.project.definition.deleted':
      return ctx.batch.append(
        refreshProjectDefinition({
          create: [],
          update: [],
          delete: [event.data.id],
        })
      )
    case 'workspace.stage.definition.created':
      return ctx.batch.append(
        refreshStageDefinition({
          create: [
            {
              id: event.data.id,
              workspaceId: event.data.workspaceId,
            },
          ],
          update: [],
          delete: [],
        })
      )
    case 'workspace.stage.definition.updated':
      return ctx.batch.append(
        refreshStageDefinition({
          update: [
            {
              id: event.data.id,
              workspaceId: event.data.workspaceId,
            },
          ],
          create: [],
          delete: [],
        })
      )
    case 'workspace.stage.definition.deleted':
      const {
        data: { referencingProjectDefinitionIds },
      } = event as ExtractSocketEvent<'workspace.stage.definition.deleted'>

      referencingProjectDefinitionIds.forEach((definitionId) => {
        ctx.batch.append(
          refreshProjectDefinition({
            delete: [],
            create: [],
            update: [
              {
                workspaceId: event.data.workspaceId,
                id: definitionId,
              },
            ],
          })
        )
      })

      return ctx.batch.append(
        refreshStageDefinition({
          delete: [event.data.id],
          create: [],
          update: [],
        })
      )
    case 'workspace.project.definition.reconciled':
      const {
        data: {
          updatedProjectIds,
          createdTaskIds,
          updatedTaskIds,
          deletedTaskIds,
        },
      } = event as ExtractSocketEvent<'workspace.project.definition.reconciled'>

      ctx.batch.append(projectRefresh({ update: updatedProjectIds }))

      return ctx.batch.append(
        taskRefresh({
          update: updatedTaskIds,
          create: createdTaskIds,
          delete: deletedTaskIds,
        })
      )
    case 'meetingInsights.created':
    case 'meetingInsights.updated':
    case 'meetingInsights.deleted':
      return ctx.batch.append(meetingInsightsRefresh(event.data))
    case 'featurePermissionsUsage.updated':
    case 'featurePermissionsTier.updated': {
      invalidateFeaturePermissions()
      break
    }
    case 'permission.resource.shared': {
      invalidateAccessPermissions(
        event.data.sharedResource.type,
        event.data.sharedResource.id
      )
      break
    }
    default:
      break
  }
}
