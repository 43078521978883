import { type UploadedFileSchema } from '@motion/rpc-types'
import { ActionList } from '@motion/ui/base'

import { useAttachmentActionItems } from './hooks'

export type AttachmentActionListProps = {
  attachment: UploadedFileSchema
  isPreview: boolean
  close: () => void
  onRename?: () => void
  onDelete: () => void
  onTogglePreview: () => void
}

export const AttachmentActionList = ({
  attachment,
  isPreview,
  close,
  onRename,
  onDelete,
  onTogglePreview,
}: AttachmentActionListProps) => {
  const { sections } = useAttachmentActionItems(
    attachment,
    { isPreview },
    { onRename, onDelete, onTogglePreview }
  )

  return <ActionList sections={sections} onActionAnyItem={close} />
}
