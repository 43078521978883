import { type CalendarEventSchemaV2 } from '@motion/rpc-types'
import { useHasTreatment } from '@motion/web-common/flags'

import { useGetMeetingInsightsQuery } from '../../rpc/v2/notetaker'

export function useMeetingInsightsByEvent(
  event: CalendarEventSchemaV2 | null | undefined
) {
  const notetakerEnabled = useHasTreatment('notetaker-event-modal')

  const { data, isLoading } = useGetMeetingInsightsQuery(
    {
      meetingInsightsId: event?.meetingInsightsId,
      recurringMeetingInsightsId: event?.recurringMeetingInsightsId,
    },
    {
      // Enable if any of the meeting insights id are present and notetaker is enabled
      enabled:
        (event?.recurringMeetingInsightsId ?? event?.meetingInsightsId) !=
          null && notetakerEnabled,
    }
  )

  return {
    data,
    isLoading,
  }
}
