import { findDefaultStatus } from '@motion/shared/common'
import {
  getProjectInitialDueDate,
  isValidPriority,
  mapCustomFieldToFieldArrayWithValue,
} from '@motion/ui-logic'
import { type ProjectFormFields } from '@motion/ui-logic/pm/project'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import {
  useFindFolder,
  useMyTasksWorkspace,
  useProject,
  useWorkspaceById,
  useWorkspaceStatuses,
} from '~/global/hooks'
import { useSearchParams } from '~/routing'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useParams } from 'react-router'

import { type ProjectUrlParams, type ProjectUrlSearchParams } from '../utils'

export function useInitialFormData(): ProjectFormFields {
  const {
    project: projectParam,
    forWorkspace: forWorkspaceParam,
    forManager: forManagerParam,
    forStatus: forStatusParam,
    forPriority: forPriorityParam,
    forLabel: forLabelParam,
    forCustomField: forCustomFieldParam,
    forStartDate: forStartDateParam,
    forDueDate: forDueDateParam,
    forFolder: forFolderParam,
  } = useSearchParams<ProjectUrlSearchParams>()
  const {
    workspaceId: workspaceIdParam,
    projectId: projectIdParam,
    folderId: folderIdParam,
  } = useParams<ProjectUrlParams>()

  const defaultWorkspace = useMyTasksWorkspace()
  const { uid: currentUserId } = useAuthenticatedUser()

  const projectParamId =
    // `projectParam` can be 'create' in here when moving from the project modal to the create project modal for a bit. (When clicking on "Use template")
    // This is because the route updates, the modal disappears slowly, so there are a few frames with the `create` param
    projectParam === 'new' || projectParam === 'create'
      ? undefined
      : projectParam === 'edit'
        ? projectIdParam
        : projectParam

  const project = useProject(projectParamId)

  // when you're in the project view of another workspace, disregard the projectIdParam
  // as the folderId is invalid anyway. same for the folderIdParam
  const bypassProjectFromUrlParam =
    forWorkspaceParam != null &&
    workspaceIdParam != null &&
    forWorkspaceParam !== workspaceIdParam

  const findFolder = useFindFolder()
  const folderFromUrlParam = findFolder((folder) => folder.id === folderIdParam)
  const parsedFolderIdParam =
    forWorkspaceParam != null &&
    folderFromUrlParam?.targetType === 'WORKSPACE' &&
    folderFromUrlParam?.targetId !== forWorkspaceParam
      ? null
      : folderIdParam

  // The project in the route if any, needed to create a project from a project page inside a folder.
  const projectFromUrlParam = useProject(projectIdParam)
  const folderIdFromUrlParam = bypassProjectFromUrlParam
    ? null
    : (projectFromUrlParam?.folderId ?? null)

  const usingProjectData = projectParamId != null && project != null

  const workspaceId = usingProjectData
    ? project.workspaceId
    : (forWorkspaceParam ?? workspaceIdParam ?? defaultWorkspace?.id)

  const assigneeUserId = usingProjectData
    ? project.managerId
    : forManagerParam === 'unassigned'
      ? null
      : (forManagerParam ?? currentUserId)

  const workspace = useWorkspaceById(workspaceId)
  const workspaceStatuses = useWorkspaceStatuses(workspaceId)
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(workspaceId)

  if (workspaceId == null) {
    throw new Error('Workspace id not defined')
  }

  if (forPriorityParam != null && !isValidPriority(forPriorityParam)) {
    throw new Error('Priority unknown', {
      cause: {
        forPriorityParam,
      },
    })
  }

  const statusId = usingProjectData ? project.statusId : forStatusParam
  const status =
    workspaceStatuses.find((s) => s.id === statusId) ??
    findDefaultStatus(workspaceStatuses)

  const folderId = usingProjectData
    ? project.folderId
    : (forFolderParam ?? parsedFolderIdParam ?? folderIdFromUrlParam)
  // Workspace can be null if the user is within a modal with a workspace that has been deleted or is simply invalid
  // Let's show an error message instead of throwing
  const hasError =
    (projectParamId != null && project == null) ||
    workspace == null ||
    status == null

  return useMemo(() => {
    return {
      isLoading: false,
      hasError,
      id: usingProjectData ? project.id : undefined,
      projectDefinitionId: usingProjectData
        ? project.projectDefinitionId
        : null,
      activeStageDefinitionId: usingProjectData
        ? project.activeStageDefinitionId
        : null,
      workspaceId,
      statusId: status?.id ?? '', // fallback on empty string is ok because we check the validity of status for the error state
      managerId: assigneeUserId,
      labelIds: usingProjectData
        ? project.labelIds
        : forLabelParam != null
          ? [forLabelParam]
          : [],
      priorityLevel: usingProjectData
        ? project.priorityLevel
        : (forPriorityParam ?? 'MEDIUM'),
      name: project?.name ?? '',
      description: project?.description ?? '',
      startDate: usingProjectData
        ? project.startDate
        : (forStartDateParam ?? DateTime.now().toISODate()),
      dueDate: usingProjectData
        ? project.dueDate
        : (forDueDateParam ?? getProjectInitialDueDate()),
      customFieldValuesFieldArray: workspaceCustomFields.map((field) =>
        mapCustomFieldToFieldArrayWithValue(field, {
          ...forCustomFieldParam,
          ...(usingProjectData ? project.customFieldValues : {}),
        })
      ),
      color: usingProjectData ? project.color : 'gray',
      folderId,
    }
  }, [
    hasError,
    usingProjectData,
    project?.id,
    project?.projectDefinitionId,
    project?.activeStageDefinitionId,
    project?.labelIds,
    project?.priorityLevel,
    project?.name,
    project?.description,
    project?.startDate,
    project?.dueDate,
    project?.color,
    project?.customFieldValues,
    workspaceId,
    folderId,
    status?.id,
    assigneeUserId,
    forLabelParam,
    forPriorityParam,
    forStartDateParam,
    forDueDateParam,
    workspaceCustomFields,
    forCustomFieldParam,
  ])
}
