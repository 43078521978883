import {
  DuplicateOutline,
  PencilSolid,
  type SvgIcon,
  TrashSolid,
} from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, ButtonGroup, IconButton } from '@motion/ui/base'

import { twMerge } from 'tailwind-merge'

export type SettingTableProps = {
  emptyContainer?: React.ReactNode
  items: SettingItemProps[]
  title?: React.ReactNode
  headerEndContent?: React.ReactNode
}

export const SettingTableV2 = ({
  emptyContainer,
  items,
  title,
  headerEndContent,
}: SettingTableProps) => {
  const hasItems = items.length > 0
  const showEmptyContainer = !hasItems && !!emptyContainer

  return (
    <div className={twMerge('flex flex-col gap-4 ', !hasItems && 'mb-8')}>
      {title && (
        <SectionHeaderRow>
          <SettingTitle largeFont>{title}</SettingTitle>
          {headerEndContent != null && <div>{headerEndContent}</div>}
        </SectionHeaderRow>
      )}
      <SettingContainer>
        {showEmptyContainer && (
          <SettingRowsContainer>{emptyContainer}</SettingRowsContainer>
        )}
        {items.map((item) => (
          <SettingItem {...item} key={item.id} />
        ))}
      </SettingContainer>
    </div>
  )
}

type SettingItemProps = {
  id: string
  onDelete?: () => void
  onDuplicate?: () => void
  onEdit?: () => void
  onEditUrl?: string
  icon?: SvgIcon
  customDuplicateIcon?: SvgIcon
  label: React.ReactNode
  description?: React.ReactNode
  bottomSection?: React.ReactNode
}

function SettingItem(item: SettingItemProps) {
  const renderButtonGroup = !!item.onEdit || !!item.onDelete
  const hasEdit = !!item.onEditUrl || item.onEdit

  const Icon = item.icon
  const DuplicateIcon = item.customDuplicateIcon ?? DuplicateOutline

  return (
    <SettingRowsContainer>
      <SettingRow>
        <div className='flex flex-row items-center flex-grow gap-2'>
          {Icon != null && (
            <Icon className='w-4 h-4 text-semantic-neutral-icon-default shrink-0' />
          )}
          <div className='text-semantic-neutral-text-default font-semibold'>
            {item.label}
          </div>
        </div>
        {renderButtonGroup && (
          <ButtonGroup size='small'>
            {hasEdit && (
              <Button
                onClick={item.onEdit ?? undefined}
                url={item.onEditUrl ?? undefined}
                sentiment='neutral'
                size='small'
                variant='muted'
              >
                <PencilSolid />
                Edit
              </Button>
            )}
            {item.onDuplicate && (
              <Button
                onClick={item.onDuplicate}
                sentiment='neutral'
                size='small'
                variant='muted'
              >
                <DuplicateIcon />
                Duplicate
              </Button>
            )}
            {item.onDelete && (
              <IconButton
                icon={TrashSolid}
                variant='muted'
                sentiment='neutral'
                size='small'
                onClick={item.onDelete}
              />
            )}
          </ButtonGroup>
        )}
      </SettingRow>
      {item.bottomSection != null && item.bottomSection}
    </SettingRowsContainer>
  )
}

const SectionHeaderRow = classed('div', {
  base: `
    flex
    w-full items-center justify-between
    `,
})

const SettingTitle = classed('div', {
  base: `text-semantic-neutral-text-default font-semibold text-base`,
  variants: {
    largeFont: {
      true: 'text-xl',
    },
  },
  defaultVariants: {
    largeFont: false,
  },
})

const SettingContainer = classed('div', {
  base: `border rounded border-b-0 border-semantic-neutral-border-default bg-semantic-neutral-bg-default w-full`,
})

const SettingRowsContainer = classed('div', {
  base: 'flex flex-col gap-3 py-5 px-4 border-b border-b-semantic-neutral-border-default self-stretch text-field-text-default',
})

const SettingRow = classed('div', {
  base: 'flex justify-between items-center self-stretch gap-3',
})
