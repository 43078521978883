import { type UniqueIdentifier } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'

export function useSortableTreeviewItem<T extends Record<string, any>>(
  id: UniqueIdentifier,
  sortableData: T
) {
  return useSortable({
    id,
    data: sortableData,
    transition: {
      duration: 100,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  })
}
