import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { DB } from '@motion/web-common/storage'

import { type RouteData } from '~/areas/project-management/pages/pm-v3/routes/types'
import { type MotionRoute } from '~/routing/api'
import { type NavigateByIdParams } from '~/routing/hooks/navigate-by-id-params'

import { BrowsePage } from './page'

import {
  getLastViewPrefix,
  getPageTypeFromLoader,
  getViewsFilter,
} from '../routing/loaders'

declare module '@motion/web-common/flags/definitions' {
  interface AmplitudeExperiments {
    'browse-view': FeatureFlag
  }
}

type BrowseKeys = {
  [K in keyof NavigateByIdParams]: K extends `${string}-browse` ? K : never
}[keyof NavigateByIdParams]

export function getBrowseRoute(routeId: BrowseKeys): MotionRoute {
  return {
    id: routeId,
    path: 'browse',
    featureFlag: 'browse-view',
    Component: BrowsePage,
    async loader(args, ctx) {
      const pageType = getPageTypeFromLoader(args)

      recordAnalyticsEvent('PM_PAGE_VIEW', {
        context: pageType,
        layout: 'browse',
      })

      await DB.open()
      await DB.state.set(getLastViewPrefix(pageType, args.params), 'browse')

      return {
        page: pageType,
        variant: 'browse',
        filter: {
          views: getViewsFilter(pageType),
          workspaceId: args.params.workspaceId,
          projectId: args.params.projectId,
          folderId: args.params.folderId,
          viewId: args.params.viewId,
        },
      } satisfies Partial<RouteData>
    },
  }
}
