import { useTitle } from '@motion/web-common/html'

import {
  ConnectedHeader,
  ConnectedMeetingInsightsList,
} from '~/areas/notetaker'

export function NotetakerViewPage() {
  useTitle('AI Meeting Notes')

  return (
    <div className='grid grid-rows-[auto_1fr] overflow-hidden h-full'>
      <ConnectedHeader />

      <div className='min-w-0 grid min-h-0'>
        <ConnectedMeetingInsightsList />
      </div>
    </div>
  )
}
