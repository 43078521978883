import type { NOTE_ROLE } from '@motion/shared/notes'
import type {
  ShareTargetResponseSchema,
  ShareWithSchema,
} from '@motion/zod/client'

import { createContext } from 'react'

import { type ItemBeingShared } from '../hooks/use-item-being-shared'
import { type ShareItemType } from '../types'

export interface ShareItemModalContext {
  itemId: string
  itemType: ShareItemType
  searchActive: boolean
  searchQuery: string
  selectedRole: NOTE_ROLE
  selectedTargets: Omit<ShareWithSchema, 'role'>[]
  setSearchActive: (searchActive: boolean) => void
  setSearchQuery: (searchQuery: string) => void
  setSelectedRole: (selectedRole: NOTE_ROLE) => void
  setSelectedTargets: (
    targets: ShareItemModalContext['selectedTargets']
  ) => void
  usersWithAccess: ShareTargetResponseSchema[]
  workspacesWithAccess: ShareTargetResponseSchema[]
  itemBeingShared: ItemBeingShared | null
  canShare: boolean
  isUnlinked: boolean
  publishedUrl: string | null
  canPublish: boolean
  isPublished: boolean
}

export const ShareItemModalContext = createContext<ShareItemModalContext>({
  itemId: '',
  itemType: 'NOTE',
  searchActive: false,
  searchQuery: '',
  selectedRole: 'full-access',
  selectedTargets: [],
  setSearchActive: () => void 0,
  setSearchQuery: () => void 0,
  setSelectedRole: () => void 0,
  setSelectedTargets: () => void 0,
  usersWithAccess: [],
  workspacesWithAccess: [],
  itemBeingShared: null,
  canShare: false,
  isUnlinked: false,
  publishedUrl: null,
  canPublish: false,
  isPublished: false,
})
