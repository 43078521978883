import { AiMeetingNotesGradientSolid } from '@motion/icons'
import { Button, Text, TextHeader, Tooltip } from '@motion/ui/base'
import { RadioButton } from '@motion/ui/forms'
import { waitForAll } from '@motion/utils/promise'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { logInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { Highlight } from '~/analytics/highlight'
import {
  NotetakerOnboardingDetails,
  RadioButtonContainer,
  RadioGroupContainer,
  RadioSectionContainer,
} from '~/areas/notetaker'
import { YoutubeVideo } from '~/components/Common/YoutubeVideo/YoutubeVideo'
import {
  useUpdateMyCtaSettings,
  useUpdateNotetakerSettings,
} from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useExperiment } from '~/localServices/experiments'
import { useEffect, useState } from 'react'

type NotetakerScreenProps = {
  goToNextPage: () => void
}

export function NotetakerScreen({ goToNextPage }: NotetakerScreenProps) {
  const notetakerOnboarding = useExperiment('notetaker-onboarding')
  const [botEnabled, setBotEnabled] = useState<boolean | undefined>()
  const { mutateAsync: updateCtaSettings } = useUpdateMyCtaSettings()

  const [sendRecapToAllAttendees, setSendRecapToAllAttendees] = useState<
    boolean | undefined
  >()

  const [loading, setLoading] = useState(false)
  const { mutateAsync: updateNotetakerSettings } = useUpdateNotetakerSettings()
  const user = useAuthenticatedUser()

  const disabled = botEnabled == null || sendRecapToAllAttendees == null

  async function onSubmit() {
    setLoading(true)

    try {
      await waitForAll([
        updateNotetakerSettings({
          enableBotForAllMeetings: botEnabled,
          sendRecapToAllAttendees,
          triggerCalendarSync: true,
        }),
        updateCtaSettings({
          ctaSettings: {
            NOTETAKER_ONBOARDING_ALERT: true,
          },
        }),
      ])

      recordAnalyticsEvent('NOTETAKER_ONBOARDING_SETTING', {
        botEnabled,
        sendRecapToAllAttendees,
        type: 'new-users',
      })
    } catch (error) {
      showErrorToast(
        'Failed to update notetaker settings, proceeding with default settings...'
      )
      Sentry.captureException(error, {
        extra: {
          botEnabled,
          sendRecapToAllAttendees,
        },
      })
    }

    setLoading(false)
    goToNextPage()
  }

  useEffect(
    function loadHighlight() {
      try {
        Highlight.identify(user.email, {
          name: user.displayName ?? '',
          uid: user.uid,
        })
        Highlight.track('NOTETAKER_ONBOARDING_ALERT_VIEW')
      } catch (e) {
        logInDev('error starting Highlight', e)
        void e
      }
    },
    [user]
  )

  return (
    <div className='grid grid-cols-1 lg:grid-cols-12 h-full w-full max-w-[1680px] mx-auto'>
      <div className='lg:col-span-7 flex flex-col gap-5 p-12'>
        <header className='flex items-center gap-4'>
          <AiMeetingNotesGradientSolid className='size-8' />
          <TextHeader>{NotetakerOnboardingDetails.Title}</TextHeader>
        </header>

        <Text>{NotetakerOnboardingDetails.Description}</Text>

        <div className='relative w-full aspect-video rounded-md'>
          {notetakerOnboarding.payload?.videoId && (
            <YoutubeVideo
              videoId={notetakerOnboarding.payload.videoId}
              rounded
              muteOnStart={false}
              loop
            />
          )}
        </div>

        <RadioSectionContainer>
          <Text as='h3' weight='semibold'>
            {NotetakerOnboardingDetails.Settings.BotEnabled.Title}
          </Text>

          <RadioGroupContainer>
            <RadioButtonContainer>
              <RadioButton
                label={NotetakerOnboardingDetails.Settings.BotEnabled.Yes}
                checked={botEnabled === true}
                onChange={() => setBotEnabled(true)}
              />
            </RadioButtonContainer>

            <RadioButtonContainer>
              <RadioButton
                label={NotetakerOnboardingDetails.Settings.BotEnabled.No}
                checked={botEnabled === false}
                onChange={() => setBotEnabled(false)}
              />
            </RadioButtonContainer>
          </RadioGroupContainer>
        </RadioSectionContainer>

        <RadioSectionContainer>
          <Text as='h3' weight='semibold'>
            {NotetakerOnboardingDetails.Settings.SendRecapToAllAttendees.Title}
          </Text>

          <RadioGroupContainer>
            <RadioButtonContainer>
              <RadioButton
                label={
                  NotetakerOnboardingDetails.Settings.SendRecapToAllAttendees
                    .Yes
                }
                checked={sendRecapToAllAttendees === true}
                onChange={() => setSendRecapToAllAttendees(true)}
              />
            </RadioButtonContainer>

            <RadioButtonContainer>
              <RadioButton
                label={
                  NotetakerOnboardingDetails.Settings.SendRecapToAllAttendees.No
                }
                checked={sendRecapToAllAttendees === false}
                onChange={() => setSendRecapToAllAttendees(false)}
              />
            </RadioButtonContainer>
          </RadioGroupContainer>
        </RadioSectionContainer>

        <div className='flex flex-col gap-4 items-center'>
          <Text className='text-semantic-neutral-text-subtle' size='xs'>
            This can be changed later in settings.
          </Text>

          <Tooltip
            content={disabled && 'Please select all options to continue!'}
          >
            <Button loading={loading} onClick={onSubmit} disabled={disabled}>
              Continue
            </Button>
          </Tooltip>
        </div>
      </div>

      {/* Right Column */}
      <div className='hidden lg:flex lg:col-span-5 px-6 py-20 items-start'>
        <img
          src='/images/onboarding/notetaker-screen.png'
          alt='AI Notetaker interface preview'
          className='max-w-full object-contain'
        />
      </div>
    </div>
  )
}
