import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

export type CustomAttachmentImagePreviewProps = {
  fileUrl: string
  fileName: string
}

export function CustomAttachmentImagePreview({
  fileUrl,
  fileName,
}: CustomAttachmentImagePreviewProps) {
  const [imageError, setImageError] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    setImageError(false)
    setImageLoaded(false)
  }, [fileUrl])

  const handleImageLoad = () => {
    setImageLoaded(true)
  }

  const handleImageError = () => {
    setImageError(true)
  }

  if (imageError)
    return (
      <span className='text-semantic-error-text-default'>
        An error occurred while loading the image.
      </span>
    )

  return (
    <div className='flex relative w-full h-full'>
      {!imageLoaded && (
        <div className='absolute inset-0 bg-semantic-neutral-bg-disabled animate-pulse' />
      )}
      <img
        src={fileUrl}
        alt={fileName}
        className={twMerge(
          'object-cover h-full w-full transition-opacity duration-200',
          imageLoaded ? 'flex opacity-100' : 'opacity-0'
        )}
        onLoad={handleImageLoad}
        onError={handleImageError}
      />
    </div>
  )
}
