import { getTaskStaticURL } from '@motion/ui-logic'
import type { TaskSchema } from '@motion/zod/client'

import { useClipboard } from '~/localServices/clipboard'
import { useCallback } from 'react'

export const useCopyAllTasks = () => {
  const clipboard = useClipboard()

  return useCallback(
    (tasks: TaskSchema[]) => {
      const tasksWithUrl = tasks.map((task) => {
        const url = getTaskStaticURL({
          workspaceId: task.workspaceId,
          taskId: task.id,
        })

        return { ...task, url }
      })

      void clipboard.write(
        {
          text: tasksWithUrl.map((task) => task.url).join('\n'),
          html: tasksWithUrl
            .map(({ name, url }) => `<div><a href="${url}">${name}</a></div>`)
            .join(''),
        },
        {
          notificationText: 'Links copied successfully',
        }
      )
    },
    [clipboard]
  )
}
