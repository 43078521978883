import { type AmplitudeExperimentName } from '@motion/web-common/flags'

const permanentExperiments = [
  'release-notes',
  'auto-schedule-range',
  'new_maintenance_mode',
  'feature_flagged_banner',
] as const

export const AmplitudeExperimentNames = [
  ...permanentExperiments,
  'cancellation-interception-discount',
  'mobile-push-notifications-release-v3',
  'onboarding-video',
  'email-signin-checkout',
  'onboarding-iframes',
  'no-cc-combined-trial',
  'skip-firebase-connection-error',
  'cookie-alert',
  'team-pricing',
  'change-email',
  'no-cc-b2b-everywhere',
  'verify-email',
  // Flows
  'flows-m5-reusable-stages',
  'team-trial-for-individual',
  'b2b-on-mobile',
  'shared-space',
  'private-workspace-as-section',
  'docs-ai-on-toolbar',
] as const

export type { AmplitudeExperimentName }

export type AmplitudeExperiments = Record<
  AmplitudeExperimentName,
  string | undefined
>
