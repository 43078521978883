import { TextVariableFieldsList } from '~/areas/flows/components'
import { useFlowTemplateForm } from '~/areas/flows/shared-form'

import { useLegacyClearTaskNameVariable } from './use-legacy-clear-task-name-variable'

export const ControlledTextVariableFields = () => {
  const { form } = useFlowTemplateForm()
  const { watch } = form
  const clearTextVariable = useLegacyClearTaskNameVariable()

  const textVariables = watch('textVariables')

  return (
    <TextVariableFieldsList
      variables={textVariables}
      form={form}
      deleteAnalyticsName='template-delete-variable'
      onRemoveVariable={clearTextVariable}
    />
  )
}
