import {
  type CreateTeamDto,
  type TeamMemberSerializer,
} from '@motion/rpc-types'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { websocketsService } from '~/services/websockets-service'

import { type PMTeamType } from '../../components/ProjectManagement/types'
import {
  type FirebaseTeam,
  type MutationResponse,
  type TeamResponse,
} from '../../services/teamsService'
import { getProxy } from '../backgroundProxy'
import { type RootState } from '../proxy'
import { selectTeam } from '../team'
import { type TeamMemberInvite } from '../TeamTypes'

const THUNK_PREFIX = 'team'

const teamMembersService = getProxy('TeamMembersService')
const teamsServiceHandler = getProxy('TeamService')

interface UpdateTeamMemberProps {
  teamMember: Partial<TeamMemberSerializer>
  teamId: PMTeamType['id']
}

export const createTeam = createAsyncThunk(
  `${THUNK_PREFIX}/create`,
  async (createTeamDto: CreateTeamDto) => {
    const team = await teamsServiceHandler.createTeam(createTeamDto)
    void websocketsService.refreshRooms()
    return team
  }
)

export const fetchTeam = createAsyncThunk(
  `${THUNK_PREFIX}/fetch`,
  async (): Promise<TeamResponse> => {
    const res = await teamsServiceHandler.getPmTeam()
    return res
  }
)

export const inviteTeamMembers = createAsyncThunk<
  FirebaseTeam | undefined,
  TeamMemberInvite[],
  { state: RootState }
>(`${THUNK_PREFIX}/teamMembers/invite`, async (teamMemberInvites, thunkAPI) => {
  const team = selectTeam(thunkAPI.getState())
  if (!team?.id) {
    throw new Error('Could not find team')
  }

  const { joinedTeam, success, error } =
    await teamMembersService.inviteTeamMembers(teamMemberInvites, team.id)

  if (!success) {
    throw new Error(error ?? 'Could not invite team members')
  }

  // To keep PM State in sync when members change
  const pmTeamId = thunkAPI.getState().projectManagement.teamId
  if (pmTeamId) {
    void thunkAPI.dispatch(fetchTeam())
  }

  return joinedTeam
})

export const removeTeamMember = createAsyncThunk<
  FirebaseTeam | undefined,
  string,
  { state: RootState }
>(`${THUNK_PREFIX}/teamMembers/remove`, async (userId, thunkAPI) => {
  const team = selectTeam(thunkAPI.getState())
  if (!team?.id) {
    throw new Error('Could not find team')
  }

  const { joinedTeam, success, error } = await teamMembersService.remove(
    userId,
    team.id
  )

  if (!success) {
    throw new Error(error ?? 'Could not remove team member')
  }

  // To keep PM State in sync when members change
  const pmTeamId = thunkAPI.getState().projectManagement.teamId
  if (pmTeamId) {
    void thunkAPI.dispatch(fetchTeam())
  }

  return joinedTeam
})

export const updateTeamMember = createAsyncThunk(
  `${THUNK_PREFIX}/teamMembers/update`,
  async ({ teamMember, teamId }: UpdateTeamMemberProps) => {
    return teamMembersService.update(teamMember, teamId)
  }
)

export const updateTeam = createAsyncThunk(
  `${THUNK_PREFIX}/update`,
  async ({
    teamId,
    name,
  }: {
    teamId: string
    name: string
  }): Promise<MutationResponse> => {
    return teamsServiceHandler.rename(teamId, name)
  }
)
