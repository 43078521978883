import { useCalendarEvents } from '~/global/rpc'
import { selectMainEmailAccount } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'

import { ConnectedCalendarRow } from './calendar-row'

import { ColumnNameContainer } from '../../container'
import { useSidebarSize, useValidProviderIds } from '../../hooks'
import {
  BASE_SIDEBAR_WIDTH,
  ONE_HOUR,
  OOO_CALENDAR_ROW_HEIGHT,
  ZIndexMap,
} from '../../shared-constants'

export function CalendarRows() {
  const mainEmailAccount = useAppSelector(selectMainEmailAccount)
  const sidebarSize = useSidebarSize()
  const validProviderIds = useValidProviderIds()

  const { data: calendarEvents } = useCalendarEvents(
    { providerIds: validProviderIds },
    {
      enabled: validProviderIds.length > 0 && !!mainEmailAccount,
      gcTime: ONE_HOUR,
      staleTime: ONE_HOUR,
    }
  )

  if (!validProviderIds.length) return null

  return (
    <div
      className='overflow-y-hidden w-full overflow-x-hidden flex-shrink-0'
      style={{ height: OOO_CALENDAR_ROW_HEIGHT * validProviderIds.length }}
    >
      <ColumnNameContainer
        style={{
          width: sidebarSize,
          minWidth: BASE_SIDEBAR_WIDTH,
          zIndex: ZIndexMap.nameBackgroundContainer,
        }}
      />
      <div className='flex flex-col absolute w-full'>
        {validProviderIds.map((providerId, index) => (
          <ConnectedCalendarRow
            events={calendarEvents?.[providerId] ?? []}
            providerId={providerId}
            key={`${providerId}-${index}`}
          />
        ))}
      </div>
    </div>
  )
}
