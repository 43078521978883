import { ChevronDownOutline, PlusSolid } from '@motion/icons'
import { IndividualOrTeam, type TeamMemberSerializer } from '@motion/rpc-types'
import {
  ActionList,
  Button,
  FormModal,
  PopoverButton,
  PopoverTrigger,
  SearchableList,
  Toggle,
} from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useModalApi } from '@motion/web-common/modals'
import { type WorkspaceSchema } from '@motion/zod/client'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { WorkspaceDropdown } from '~/global/components/dropdowns'
import { PrettyAssignees } from '~/global/components/labels'
import { useCreateWorkspace } from '~/global/hooks'
import { useAppSelector } from '~/state/hooks'
import { selectTeam } from '~/state/team'
import { useState } from 'react'

type TeamMemberDropdownProps = {
  workspaceMembersIds: string[]
  setWorkspaceMembersIds: (ids: string[]) => void
  userId: string
  teamMembers?: TeamMemberSerializer[]
}

const TeamMemberDropdown = ({
  workspaceMembersIds,
  setWorkspaceMembersIds,
  userId,
  teamMembers,
}: TeamMemberDropdownProps) => {
  const modalApi = useModalApi()

  return (
    <PopoverTrigger
      renderPopover={({ close }) => (
        <>
          <SearchableList
            computeKey={(item) => item.id}
            computeSearchValue={(item) => item.label}
            computeSelected={(item) => workspaceMembersIds.includes(item.id)}
            onSelect={(items) => {
              setWorkspaceMembersIds([userId, ...items.map((item) => item.id)])
            }}
            itemType='checkbox'
            renderItem={(item) => <div className='truncate'>{item.label}</div>}
            items={
              teamMembers
                ? teamMembers
                    .filter((member) => member.userId !== userId)
                    .map((member) => ({
                      id: member.userId,
                      label: member.user.name,
                      value: member,
                    }))
                : []
            }
            inputProps={{ placeholder: 'Choose member...' }}
          />
          <div className='border-t border-dropdown-border'>
            <ActionList
              items={[
                {
                  prefix: <PlusSolid />,
                  content: 'Invite new team member',
                  onAction: () => {
                    close()
                    void modalApi.open('invite-teammate', {})
                  },
                },
              ]}
              onActionAnyItem={close}
            />
          </div>
        </>
      )}
    >
      <PopoverButton>
        <PrettyAssignees userIds={workspaceMembersIds} size='normal' />
      </PopoverButton>
    </PopoverTrigger>
  )
}

export const ConnectedAddWorkspaceModal = ({
  close,
}: ModalTriggerComponentProps<'add-workspace'>) => {
  const { createWorkspace } = useCreateWorkspace()

  const [workspaceToCopy, setWorkspaceToCopy] = useState<WorkspaceSchema>()
  const [workspaceName, setWorkspaceName] = useState('')
  const [showWorkspaces, setShowWorkspaces] = useState(false)
  const team = useAppSelector(selectTeam)
  const teamId = team?.id ?? null
  const { uid: userId } = useAuthenticatedUser()
  const [workspaceMembersIds, setWorkspaceMembersIds] = useState([userId])

  const onSwitchCopySettings = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked
    setShowWorkspaces(newValue)

    if (!newValue) {
      setWorkspaceToCopy(undefined)
    }
  }

  const onAction = async () => {
    const createdWorkspace = await createWorkspace({
      name: workspaceName,
      teamId,
      type: teamId ? IndividualOrTeam.TEAM : IndividualOrTeam.INDIVIDUAL,
      workspaceIdToCopy: workspaceToCopy?.id,
      userIds: !workspaceToCopy?.id ? workspaceMembersIds : undefined,
    })

    if (createdWorkspace != null) {
      setWorkspaceName('')
      close(createdWorkspace.id)
    }
  }

  return (
    <FormModal
      onClose={() => close()}
      submitAction={{
        disabled: !workspaceName.length,
        onAction,
      }}
      title='Add workspace'
      visible
    >
      <div className='flex h-full w-[450px] flex-col gap-4'>
        <TextField
          autoFocus
          placeholder='Workspace name'
          onChange={setWorkspaceName}
          value={workspaceName}
        />
        <div className='flex gap-2'>
          <Toggle checked={showWorkspaces} onChange={onSwitchCopySettings}>
            Copy settings from other workspace
          </Toggle>
        </div>
        {showWorkspaces && (
          <WorkspaceDropdown
            selectedWorkspaceId={workspaceToCopy?.id ?? ''}
            onChange={(workspace) => setWorkspaceToCopy(workspace)}
          >
            <Button variant='outlined' sentiment='neutral'>
              <div className='flex justify-between w-full'>
                {workspaceToCopy?.name ?? 'Select workspace'}
                <ChevronDownOutline />
              </div>
            </Button>
          </WorkspaceDropdown>
        )}
        {team && !showWorkspaces && (
          <div className='flex flex-col gap-2'>
            <p className='font-semibold text-semantic-neutral-text-subtle text-xs'>
              Members
            </p>
            <TeamMemberDropdown
              workspaceMembersIds={workspaceMembersIds}
              setWorkspaceMembersIds={setWorkspaceMembersIds}
              userId={userId}
              teamMembers={team.members}
            />
          </div>
        )}
      </div>
    </FormModal>
  )
}
