import { XSolid } from '@motion/icons'
import { isActiveStatus } from '@motion/shared/billing'
import { IconButton, UnstyledModal } from '@motion/ui/base'
import { Term } from '@motion/ui-logic/billing'
import {
  getTierAnnualPercentSavings,
  proTier,
  type Tier,
  type TierDisplayMode,
} from '@motion/ui-logic/tiered-pricing'
import {
  BillingPlanDropdown,
  BillingPlanToggle,
  BucketSeatsDropdown,
  FeatureTierCards,
  useGetOrderedTiers,
  useGetTierAnnualDollarSavings,
  useLowCostTrialExperiment,
} from '@motion/web-billing'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useActiveMemberCount } from '~/global/hooks/team'
import { useCurrentTeam } from '~/global/rpc/team'
import { useState } from 'react'

export interface ChooseFeatureTierModalProps {
  mode: TierDisplayMode
  initialBucket: number
  onSelectFeatureTier: (tier: Tier, term: Term, bucket: number) => void
  currentTier?: Tier
  currentBucket?: number
  currentTerm?: Term
  hiddenTiers?: Tier[]
}

export const ChooseFeatureTierModal = ({
  mode,
  close,
  initialBucket,
  onSelectFeatureTier,
  currentTier,
  currentBucket,
  currentTerm,
  hiddenTiers,
}: ModalTriggerComponentProps<'choose-feature-tier-modal'>) => {
  const [bucket, setBucket] = useState<number>(initialBucket)
  const [term, setTerm] = useState<Term>(Term.Annual)
  const { data: team } = useCurrentTeam()
  const isTeamSubscriptionActive = isActiveStatus(
    team?.teamSubscription?.status
  )
  const teamMemberCount = useActiveMemberCount()
  const filteredOrderedTiers = useGetOrderedTiers()

  const isTeam = bucket > 1

  const {
    lowCostTrialPriceId,
    lowCostAmount,
    annualTrialLength,
    monthlyTrialLength,
  } = useLowCostTrialExperiment(term, setTerm, isTeam, mode === 'onboarding')

  const mostExpensiveNonEnterpriseTier = filteredOrderedTiers
    .toReversed()
    .find((tier) => tier !== 'ENTERPRISE' && !hiddenTiers?.includes(tier))
  const tierAnnualDollarSavings = useGetTierAnnualDollarSavings(
    mostExpensiveNonEnterpriseTier,
    isTeam
  )
  const maxAnnualDollarSavings = mostExpensiveNonEnterpriseTier
    ? tierAnnualDollarSavings
    : 0

  return (
    <UnstyledModal
      data-testid='choose-feature-tier-modal'
      type='page'
      withAnimation
      overlayClassName='bg-modal-overlay'
      visible
      disableOverlayClick
      onClose={close}
    >
      <div className='button-neutral-muted-icon-default absolute top-1 -right-6'>
        <IconButton
          icon={XSolid}
          onClick={close}
          size='small'
          variant='muted'
          sentiment='neutral'
        />
      </div>
      <div className='flex flex-col bg-semantic-neutral-surface-bg-subtlest border border-semantic-neutral-border-default w-[95vw] max-w-[1280px] gap-4 rounded-lg'>
        <div className='flex flex-row items-center justify-between w-full h-14 px-10 border-b border-b-semantic-neutral-border-subtle bg-modal-bg rounded-t-lg'>
          <p className='text-lg text-semantic-neutral-text-default font-semibold'>
            Choose a plan
          </p>

          <div className='flex flex-row gap-6'>
            <div className='flex flex-row gap-2 items-center'>
              <p className='text-semantic-neutral-text-default'>
                Choose team size:
              </p>
              <BucketSeatsDropdown
                minSeats={!isTeamSubscriptionActive ? 1 : undefined}
                selectedBucket={bucket}
                onChange={setBucket}
                allowUpsell
                disableUnderMemberCount={teamMemberCount}
              />
            </div>
            <div className='min-w-[200px] flex flex-row items-center'>
              {lowCostTrialPriceId ? (
                <BillingPlanDropdown
                  term={term}
                  setTerm={setTerm}
                  annualTrialLength={annualTrialLength}
                  monthlyTrialLength={monthlyTrialLength}
                  annualDollarSavings={maxAnnualDollarSavings}
                  isAnnualSavingsUpTo
                  isTeam={isTeam}
                  popoverButtonStyle='standard'
                />
              ) : (
                <BillingPlanToggle
                  term={term}
                  setTerm={setTerm}
                  savingsPercent={getTierAnnualPercentSavings(proTier, isTeam)}
                />
              )}
            </div>
          </div>
        </div>
        <div className='overflow-y-scroll max-h-[80vh]'>
          <FeatureTierCards
            mode={mode}
            bucket={bucket}
            term={term}
            lowCostAmount={mode === 'onboarding' ? lowCostAmount : 0}
            annualTrialLength={mode === 'onboarding' ? annualTrialLength : 0}
            monthlyTrialLength={mode === 'onboarding' ? monthlyTrialLength : 0}
            onSelectFeatureTier={onSelectFeatureTier}
            currentTier={currentTier}
            currentBucket={currentBucket}
            currentTerm={currentTerm}
            hiddenTiers={hiddenTiers}
          />
        </div>
      </div>
    </UnstyledModal>
  )
}
