import { valibotResolver } from '@hookform/resolvers/valibot'
import { type ReactNode } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as v from 'valibot'

const validationSchema = v.object({
  workspaceId: v.pipe(v.string(), v.nonEmpty()),
  projectDefinitionId: v.pipe(v.string(), v.nonEmpty()),
  prompt: v.pipe(v.string(), v.nonEmpty()),
  uploadedFileIds: v.array(v.string()),
})

export type ProjectAIFormFields = v.InferInput<typeof validationSchema>

type ProjectAIFormProps = {
  children: ReactNode
  workspaceId?: string
}

export function ProjectAIForm({ children, workspaceId }: ProjectAIFormProps) {
  const form = useForm({
    defaultValues: {
      workspaceId: workspaceId ?? '',
      projectDefinitionId: '',
      prompt: '',
      uploadedFileIds: [],
    },
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
    resolver: valibotResolver(validationSchema),
  })

  return <FormProvider {...form}>{children}</FormProvider>
}
