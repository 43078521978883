import { type ColorTagProps } from '@motion/ui/base'
import { DEFAULT_DOC_TITLE } from '@motion/ui-logic'
import { type NoteSchema } from '@motion/zod/client'

import {
  MentionComponentBase,
  MentionComponentLink,
} from '~/areas/notes/components'
import { NoteBadge } from '~/global/components/badges'
import { useNoteById } from '~/global/rpc/v2'
import { useUriByRouteId } from '~/routing'
import { useMemo } from 'react'

type ProjectNoteColorLabelProps = Omit<ColorTagProps, 'color' | 'icon'> & {
  noteId?: NoteSchema['id']
}

export function ProjectNoteColorLabel({ noteId }: ProjectNoteColorLabelProps) {
  const { data: note } = useNoteById({ id: noteId })

  const getRouteUri = useUriByRouteId({ noDefaults: true })

  const noteUri = useMemo(() => {
    if (noteId == null) return ''

    return getRouteUri('notes-detail', { noteId })
  }, [noteId, getRouteUri])

  if (noteId == null) return null

  return (
    <MentionComponentLink url={noteUri} className='mr-1 py-1'>
      <MentionComponentBase
        badge={<NoteBadge value={note} hideTooltip />}
        label={note?.title || DEFAULT_DOC_TITLE}
      />
    </MentionComponentLink>
  )
}
