import { SearchSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Shortcut } from '@motion/ui/base'

import { useTheme } from '~/state/hooks'
import { type ComponentProps } from 'react'

export function SearchButton(props: ComponentProps<typeof StyledSearchButton>) {
  const theme = useTheme()

  return (
    <StyledSearchButton {...props}>
      <SearchSolid />
      <span className='flex-1 text-left'>Search</span>
      <span className='mr-1 inline-flex'>
        <Shortcut
          shortcut='mod+K'
          sentiment={theme === 'dark' ? 'onDark' : 'default'}
        />
      </span>
    </StyledSearchButton>
  )
}

const StyledSearchButton = classed('button', {
  base: `
  flex flex-1 items-center justify-between 
  w-full h-full gap-2 px-2 py-1.5 text-sm text-[#9ca3af]
  border border-field-border-default rounded-full
  bg-field-bg-default shadow

  [&_[data-icon]]:text-semantic-neutral-icon-subtle
  [&_[data-icon]]:shrink-0 
  [&_[data-icon]]:size-[18px]
  [&>[data-icon]]:mt-px 
  
  hover:border-field-border-hover 
  active:border-field-border-focus
  `,
})
