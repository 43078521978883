import { HasExperiment, useHasTreatment } from '@motion/web-common/flags'

import {
  AttachmentPreviewModal,
  AttachmentsModal,
} from '~/areas/attachments/modals'
import { ChoosePlanModal, StartBillingModal } from '~/areas/billing'
import { ContactsPermissionModal } from '~/areas/calendar/components/contacts-permissions-modal'
import { ConnectedEventModal } from '~/areas/event/modals/event-modal'
import { ConnectedScheduleAssistantModal } from '~/areas/event/modals/schedule-assistant'
import { ConnectedFlowTemplateModal } from '~/areas/flows'
import {
  ConfirmDetachProjectFromFlowModal,
  ConfirmRemoveStageFromProjectModal,
  CreateStageFromProjectModal,
} from '~/areas/flows/components/modals'
import {
  AddFlowTemplateRoleModal,
  AddFlowTemplateTextVariableModal,
  ConnectedUpdateProjectDateModal,
  DescriptionModal,
  TaskDefinitionModal,
  UpdateStageDeadlineModal,
} from '~/areas/flows/flow-template-modal/modals'
import { ProjectDefinitionAttachmentModal } from '~/areas/flows/flow-template-modal/modals/attachment-modal'
import { ConnectedFlowsAIModal } from '~/areas/flows/flows-ai-modal'
import {
  DeprecatedRemoveBlockersModal,
  RemoveBlockersModal,
} from '~/areas/flows/shared-form'
import { StageModal } from '~/areas/flows/stage-modal'
import {
  AnimatedModalTrigger,
  ConnectedActionModal,
  ConnectedAddTeammateModal,
  ConnectedConfirmationModal,
  ConnectedConfirmDeleteModal,
  ConnectedSearchModal,
  EditFolderModal,
  InviteTeammateSuccessModal,
  ModalTrigger,
  NewFolderModal,
  PastDueModal,
} from '~/areas/modals'
import { AIWorkflowModal } from '~/areas/modals/ai-workflow-modal'
import { ConnectedAutoChangeTimezoneModal } from '~/areas/modals/auto-change-timezone-modal'
import { ConnectedLinkCalendarsModal } from '~/areas/modals/calendars/link-calendars-modal'
import { LegacyConnectedSearchModal } from '~/areas/modals/legacy-global-search-modal'
import { ConnectedManageTeamSeatsModal } from '~/areas/modals/manage-team-seats-modal'
import { ConnectedNewCustomFieldSelectOptionModal } from '~/areas/modals/new-custom-field-select-option-modal'
import { ConnectedPayTeamFormModal } from '~/areas/modals/pay-team-form-modal'
import { PayTeamFormSuccessModal } from '~/areas/modals/pay-team-form-modal/pay-team-form-success-modal'
import { ShareItemModal } from '~/areas/modals/share-modal'
import { TeamTrialEndedModal } from '~/areas/modals/team-trial-ended-modal'
import { TutorialModal } from '~/areas/modals/tutorial-modal'
import {
  CompleteProjectModal,
  OldSkipStagesModal,
  OptimizeProjectModal,
  ResolveProjectModal,
  ReviewProjectDatesModal,
  SkipStagesModal,
} from '~/areas/project/modals'
import { ConnectedProjectAIModal } from '~/areas/project/modals/project-ai-modal'
import { ConnectedSetupProjectModal } from '~/areas/project/modals/v2-setup-project-modal'
import {
  CancelTeamPlanModal,
  ConnectedChangeEmailModal,
  ConnectedChangePasswordModal,
  ConnectedChangePaymentMethodModal,
  ConnectedDeleteAccountModal,
  DisconnectCalendarsModal,
} from '~/areas/settings/modals'
import { ChangeRoleModal } from '~/areas/settings/modals/change-role'
import { EditMemberWorkspaceModal } from '~/areas/settings/modals/edit-member-workspace'
import {
  CompleteTaskWithBlockersModal,
  SpeculativeTaskModal,
  TaskBulkDuplicateModal,
} from '~/areas/tasks/modals'
import {
  ChooseFeatureTierModal,
  TierUpgradePromptModal,
} from '~/areas/tiered-pricing'
import { EmbedBookingModal } from '~/components/Booking/components/Modals/EmbedBookingModal/EmbedBookingModal'
import { BookingOneOffModal } from '~/components/Modals/BookingOneOffModal/BookingOneOffModal'
import { TimezonePickerModal } from '~/components/Modals/TimezonePickerModal/TimezonePickerModal'
import { MotionDevModal } from '~/localServices/motion-dev-tools/motion-dev-modal'
import {
  ConnectedConfirmUpdateStageModal,
  ConnectedDeleteStageTemplateModal,
  DuplicateFlowModal,
  DuplicateStageModal,
} from '~/pages/settings/pages/workspace/pages/project-templates/modals'

import { CalendarTurnIntoFixedTimeTaskHandler } from './CalendarTurnIntoFixedTimeTaskHandler'
import { ChangeMainCalendarModalHandler } from './change-main-calendar-modal-handler'
import { CompleteChunkedTaskModal } from './CompleteChunkedTaskModalHandler'
import { CustomLocationModalHandler } from './CustomLocationModalHandler'
import { EmailAccountAddedModalHandler } from './email-account-added-modal-handler'
import { PhoneNumberModalHandler } from './PhoneNumberModalHandler'

import {
  BlockOutHoursModal,
  DoTaskLaterModal,
  StartTaskModal,
  StartTasksLaterModal,
  StopTaskModal,
  StopTasksEarlyModal,
  UpdateRecurringEventConfirmationModal,
} from '../../areas/calendar/modals'
import { CustomFieldModalTriggers } from '../../areas/project-management/custom-fields/modals'
import { DeleteTaskConfirmationModal } from '../../areas/project-management/modals'
import { ConnectedAddWorkspaceModal } from '../../areas/project-management/modals/add-workspace-modal'
import { ConnectedAnnualUpgradeModal } from '../../modals/annual-upgrade-modal'
import { CancelAccountModal } from '../cancel-account-modal/cancel-account-modal'
import { AddICloudCalendarModalContainer } from '../Modals/add-icloud-calendar-modal'
import { ConnectedDoTaskASAPModal } from '../Modals/DoTaskASAPModal'
import { ConnectedLinkModal } from '../Modals/LinkModal/ConnectedLinkModal'
import { ConnectedNewLabelModal } from '../Modals/new-label-modal'
import { ConnectedNewStatusModal } from '../Modals/new-status-modal'
import { ConnectedScheduleModal } from '../Modals/ScheduleModal/ConnectedScheduleModal'
import { TutorialVideoModal } from '../Modals/tutorial-video-modal'
import { DetachCalendarModal } from '../Settings/Modals/DetachCalendarModal/DetachCalendarModal'
import { EmailIntegrationModal } from '../Settings/Pages/IntegrationSettings/email-integration-modal'

/**
 * Container component for rendering modals. This should be the preferred place
 * to render new modals, to avoid adding to MotionRouter.
 *
 * Note that the order of the modals matters, for example the DoTaskAsap modal can be open on
 * top of the ScheduledAfterDeadline modal.
 */
export const ModalContainer = () => {
  const devPanelEnabled = useHasTreatment('development-panel')

  return (
    <>
      {!__IS_PROD__ && devPanelEnabled && (
        <ModalTrigger name='motion-dev-modal' component={MotionDevModal} />
      )}

      <CalendarTurnIntoFixedTimeTaskHandler />
      <PhoneNumberModalHandler />
      <CustomLocationModalHandler />
      <ModalTrigger name='timezone-picker' component={TimezonePickerModal} />
      <ModalTrigger
        name='complete-chunked-task'
        component={CompleteChunkedTaskModal}
      />
      <ModalTrigger name='resolve-project' component={ResolveProjectModal} />
      <ModalTrigger name='optimize-project' component={OptimizeProjectModal} />
      <ModalTrigger
        name='review-project-dates'
        component={ReviewProjectDatesModal}
      />
      <ModalTrigger name='complete-project' component={CompleteProjectModal} />
      <ModalTrigger
        name='schedule-assistant'
        component={ConnectedScheduleAssistantModal}
      />
      <ModalTrigger
        name='task-delete'
        component={DeleteTaskConfirmationModal}
      />
      <ModalTrigger
        name='complete-task-with-blockers'
        component={CompleteTaskWithBlockersModal}
      />
      <ModalTrigger
        name='task-bulk-duplicate'
        component={TaskBulkDuplicateModal}
      />
      <EmailAccountAddedModalHandler />
      <ChangeMainCalendarModalHandler />
      <ModalTrigger name='confirm' component={ConnectedConfirmationModal} />
      <ConnectedAutoChangeTimezoneModal />
      <ConnectedLinkModal />
      <ModalTrigger
        name='add-workspace'
        component={ConnectedAddWorkspaceModal}
      />
      <ConnectedScheduleModal />
      <ConnectedConfirmDeleteModal />
      <AddICloudCalendarModalContainer />
      <ConnectedDoTaskASAPModal />
      <ModalTrigger
        name='email-integration'
        component={EmailIntegrationModal}
      />
      <ModalTrigger
        name='add-custom-field-select-option'
        component={ConnectedNewCustomFieldSelectOptionModal}
      />
      <ModalTrigger name='add-label' component={ConnectedNewLabelModal} />
      <ModalTrigger name='add-status' component={ConnectedNewStatusModal} />
      <ModalTrigger name='start-task' component={StartTaskModal} />
      <ModalTrigger name='stop-task' component={StopTaskModal} />
      <ModalTrigger name='start-tasks-later' component={StartTasksLaterModal} />
      <ModalTrigger name='stop-tasks-early' component={StopTasksEarlyModal} />
      <ModalTrigger name='block-out-hours' component={BlockOutHoursModal} />
      <ModalTrigger name='do-task-later' component={DoTaskLaterModal} />
      <ModalTrigger name='tutorial-video' component={TutorialVideoModal} />
      <ModalTrigger
        name='confirm-delete-stage-template'
        component={ConnectedDeleteStageTemplateModal}
      />
      <ModalTrigger name='action' component={ConnectedActionModal} />
      <ModalTrigger
        name='change-password'
        component={ConnectedChangePasswordModal}
      />
      <ConnectedSearchModal />
      <LegacyConnectedSearchModal />
      <ModalTrigger
        name='link-calendars'
        component={ConnectedLinkCalendarsModal}
      />
      <ModalTrigger
        name='annual-upgrade-modal'
        component={ConnectedAnnualUpgradeModal}
      />
      <CustomFieldModalTriggers />
      <ModalTrigger name='change-email' component={ConnectedChangeEmailModal} />
      <ModalTrigger name='detach-calendar' component={DetachCalendarModal} />
      <ModalTrigger
        name='disconnect-calendars'
        component={DisconnectCalendarsModal}
      />
      <ModalTrigger
        name='delete-account'
        component={ConnectedDeleteAccountModal}
      />
      <ModalTrigger
        name='manage-team-seats'
        component={ConnectedManageTeamSeatsModal}
      />
      <ModalTrigger
        name='invite-teammate'
        component={ConnectedAddTeammateModal}
      />
      <ModalTrigger
        name='invite-teammate-success'
        component={InviteTeammateSuccessModal}
      />
      <ModalTrigger
        name='change-payment-method'
        component={ConnectedChangePaymentMethodModal}
      />
      <ModalTrigger name='cancel-team-plan' component={CancelTeamPlanModal} />
      <ModalTrigger name='team-trial-ended' component={TeamTrialEndedModal} />
      <ModalTrigger
        name='pay-team-form'
        component={ConnectedPayTeamFormModal}
      />
      <ModalTrigger
        name='pay-team-form-success'
        component={PayTeamFormSuccessModal}
      />
      <ModalTrigger
        name='cancel-account-modal'
        component={CancelAccountModal}
      />
      <ModalTrigger
        name='contact-permissions-modal'
        component={ContactsPermissionModal}
      />
      <ModalTrigger
        name='add-flow-template-role-modal'
        component={AddFlowTemplateRoleModal}
      />
      <ModalTrigger
        name='add-flow-template-text-variable-modal'
        component={AddFlowTemplateTextVariableModal}
      />
      <ModalTrigger name='description-modal' component={DescriptionModal} />
      <ModalTrigger
        name='task-definition-modal'
        component={TaskDefinitionModal}
      />
      <HasExperiment
        name='flows-multiple-blockers'
        fallback={
          <ModalTrigger
            name='deprecated-remove-template-blockers'
            component={DeprecatedRemoveBlockersModal}
          />
        }
      >
        <ModalTrigger
          name='remove-template-blockers'
          component={RemoveBlockersModal}
        />
      </HasExperiment>
      <ModalTrigger
        name='recurring-event-update'
        component={UpdateRecurringEventConfirmationModal}
      />
      <ModalTrigger name='skip-stages-modal' component={SkipStagesModal} />
      <ModalTrigger
        name='old-skip-stages-modal'
        component={OldSkipStagesModal}
      />
      <ModalTrigger name='choose-plan-modal' component={ChoosePlanModal} />
      <ModalTrigger
        name='tier-upgrade-prompt-modal'
        component={TierUpgradePromptModal}
      />
      <ModalTrigger
        name='choose-feature-tier-modal'
        component={ChooseFeatureTierModal}
      />
      <ModalTrigger name='start-billing-modal' component={StartBillingModal} />
      <ModalTrigger name='create-new-folder' component={NewFolderModal} />
      <ModalTrigger name='edit-folder' component={EditFolderModal} />
      <ModalTrigger name='booking-oneoff' component={BookingOneOffModal} />
      <ModalTrigger name='embed-booking' component={EmbedBookingModal} />

      <ModalTrigger name='change-role' component={ChangeRoleModal} />
      <ModalTrigger
        name='duplicate-stage-modal'
        component={DuplicateStageModal}
      />
      <ModalTrigger name='duplicate-flow' component={DuplicateFlowModal} />
      <ModalTrigger
        name='confirm-update-stage-template'
        component={ConnectedConfirmUpdateStageModal}
      />
      <ModalTrigger
        name='confirm-detach-project-from-flow'
        component={ConfirmDetachProjectFromFlowModal}
      />
      <ModalTrigger
        name='project-definition-attachments-modal'
        component={ProjectDefinitionAttachmentModal}
      />
      <ModalTrigger
        name='edit-member-workspace'
        component={EditMemberWorkspaceModal}
      />
      <ModalTrigger
        name='update-stage-deadline'
        component={UpdateStageDeadlineModal}
      />
      <ModalTrigger
        name='update-project-date'
        component={ConnectedUpdateProjectDateModal}
      />
      <ModalTrigger
        name='create-stage-from-project'
        component={CreateStageFromProjectModal}
      />
      <ModalTrigger
        name='confirm-remove-stage-from-project'
        component={ConfirmRemoveStageFromProjectModal}
      />
      <AnimatedModalTrigger
        name='speculative-task-modal'
        component={SpeculativeTaskModal}
      />
      <AnimatedModalTrigger
        name='event-modal'
        component={ConnectedEventModal}
      />
      <ModalTrigger name='past-due-modal' component={PastDueModal} />
      <ModalTrigger name='tutorial-modal' component={TutorialModal} />
      <ModalTrigger name='attachments' component={AttachmentsModal} />
      <ModalTrigger
        name='attachment-preview'
        component={AttachmentPreviewModal}
      />
      <ModalTrigger
        name='flows-template-modal'
        component={ConnectedFlowTemplateModal}
      />

      <ModalTrigger name='stage-modal' component={StageModal} />

      <HasExperiment name='shared-space'>
        <ModalTrigger name='share-item' component={ShareItemModal} />
      </HasExperiment>

      {/* AI Related Modals */}
      <ModalTrigger name='flows-ai-modal' component={ConnectedFlowsAIModal} />
      <ModalTrigger name='ai-workflow-modal' component={AIWorkflowModal} />
      <HasExperiment name='ai-project-creation-m1'>
        <ModalTrigger
          name='project-ai-modal'
          component={ConnectedProjectAIModal}
        />
        <ModalTrigger
          name='v2-setup-project'
          component={ConnectedSetupProjectModal}
        />
      </HasExperiment>
    </>
  )
}
