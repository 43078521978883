import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import { stripVariableKeyFromText } from '~/areas/flows/utils'
import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'

export const useLegacyClearTaskNameVariable = () => {
  const { watch, setValue } = useFormContext<FlowTemplateFormFields>()
  const stages = watch('stages')

  const clear = useCallback(
    (wrappedKey: string, stageIndex?: number, taskIndex?: number) => {
      for (const [currentStageIndex, stage] of stages.entries()) {
        if (stageIndex != null && currentStageIndex !== stageIndex) continue
        for (const [currentTaskIndex, task] of stage.tasks.entries()) {
          if (taskIndex != null && currentTaskIndex !== taskIndex) continue
          // Replace with leading and trailing spaces first,
          // so that variables in the middle of text get converted to
          // one space.
          const name = stripVariableKeyFromText(task.name, wrappedKey)

          if (name !== task.name) {
            setValue(
              `stages.${currentStageIndex}.tasks.${currentTaskIndex}.name`,
              name,
              {
                shouldDirty: true,
                shouldValidate: true,
              }
            )
          }
        }
      }
    },
    [stages, setValue]
  )

  return clear
}
