import { PlusSolid } from '@motion/icons'
import { API } from '@motion/rpc-definitions'
import { type ProviderType } from '@motion/shared/common'
import { classed } from '@motion/theme'
import {
  Button,
  CompanyLogo,
  LoadingSpinner,
  UserAvatar,
} from '@motion/ui/base'
import {
  isCalendarRemovableFromAccount,
  isCalendarRemovableFromMyCalendars,
} from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import { AgendaVirtualizedList } from '~/areas/calendar/agenda/virtualized-agenda'
import {
  useAllCalendars,
  useCalendarActions,
  useCalendarSidebarState,
  useFrequentlyMetCalendars,
  useInNoExternalCalendarsMode,
  useMyCalendars,
  useSelectTeammates,
} from '~/areas/calendar/hooks'
import { AddAccountDropdown } from '~/components/Account/add-account-dropdown'
import { ContactsAutocomplete } from '~/components/contacts-autocomplete'
import { StatefulSidebar } from '~/global/components/stateful-sidebar'
import { setCalendarListPickerModal } from '~/state/calendar-list/calendar-list-slice'
import type { CalendarListPickerSectionType } from '~/state/calendar-list/calendar-list-types'
import {
  selectEmailAccounts,
  selectMainEmailAccountId,
} from '~/state/email-accounts/email-accounts-slice'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import { addAccount } from '~/utils/auth'
import { twMerge } from 'tailwind-merge'

import {
  Blade,
  CalendarList,
  CalendarListHeader,
  MiniCalendar,
} from './components'

export const RightSidebar = () => {
  const dispatch = useAppDispatch()
  const modalApi = useModalApi()
  const myCalendars = useMyCalendars()
  const { calendars: allCalendars } = useAllCalendars()

  const ongoingScheduleEntitiesFetchCount = useIsFetching({
    queryKey: API.scheduledEntities.queryKeys.root,
  })
  const ongoingCalendarsMutationCount = useIsMutating({
    mutationKey: API.calendars.queryKeys.root,
  })

  const emailAccounts = useAppSelector(selectEmailAccounts)
  const mainEmailAccountId = useAppSelector(selectMainEmailAccountId)
  const frequentlyMetCalendars = useFrequentlyMetCalendars()
  const { noExternalCalendarsMode } = useInNoExternalCalendarsMode()
  const [selectedTeammates, handleSelectedTeammates] = useSelectTeammates()
  const { sidebarState, setSidebarState, toggleSidebarSection } =
    useCalendarSidebarState()
  const {
    updateCalendar,
    toggleCalendar,
    removeCalendar,
    addToMyCalendars,
    removeFromMyCalendars,
    removeFromFrequentlyMet,
  } = useCalendarActions()

  const hasRemoveAgendaSidebar = useHasTreatment('remove-agenda-sidebar')

  const openCalListPickerModal = (section: CalendarListPickerSectionType) => {
    dispatch(setCalendarListPickerModal({ section, visible: true }))
    void recordAnalyticsEvent('CALENDAR_GROUP_MODAL_OPEN', { section })
  }

  const onAddAccount = (provider: ProviderType) => {
    addAccount(provider)
    recordAnalyticsEvent('CALENDAR_ADD_ACCOUNT_CLICK')
  }

  return (
    <StatefulSidebar
      sidebarKey='calendar'
      hideCloseButton
      closedWidth={0}
      resizeDirection='left'
      minWidth={220}
      maxWidth={350}
    >
      <SidebarContentWrapper>
        <div className='flex justify-center w-full py-2'>
          <MiniCalendar />
        </div>

        <BladeWrapper>
          <Blade
            title='Calendars'
            scrollable={sidebarState.blades.agenda !== 'expanded'}
            actions={
              <>
                {(ongoingScheduleEntitiesFetchCount > 0 ||
                  ongoingCalendarsMutationCount > 0) && (
                  <LoadingSpinner
                    size={12}
                    className='text-semantic-primary-icon-default'
                  />
                )}
                <AddAccountDropdown
                  onAddAccount={onAddAccount}
                  placement='bottom-end'
                >
                  <Button size='xsmall' sentiment='neutral' variant='muted'>
                    <PlusSolid /> Add calendar
                  </Button>
                </AddAccountDropdown>
              </>
            }
          >
            <div
              className={twMerge(
                'px-3 pt-1 pb-4 bg-semantic-neutral-bg-disabled z-[2]',
                selectedTeammates.length === 0 && 'sticky top-0'
              )}
            >
              {noExternalCalendarsMode ? (
                <Button
                  fullWidth
                  sentiment='neutral'
                  variant='outlined'
                  onClick={() => {
                    modalApi.open('link-calendars')
                    recordAnalyticsEvent('LINK_CALENDARS_MODAL_OPEN', {
                      source: 'calendar-right-sidebar',
                    })
                  }}
                >
                  Link a calendar
                </Button>
              ) : (
                <ContactsAutocomplete
                  teamValues={selectedTeammates}
                  contactsSource='team'
                  teamSelectHandler={handleSelectedTeammates}
                  placeholder='Search teammates'
                  dropdownAlign='right'
                  inputClassName={
                    selectedTeammates && selectedTeammates.length > 0
                      ? 'rounded'
                      : 'rounded-full'
                  }
                  onFocus={(focused) => {
                    if (focused && sidebarState.blades.agenda === 'expanded') {
                      setSidebarState({
                        blades: {
                          agenda: 'peek',
                        },
                      })
                    }
                  }}
                  allowClearSelection
                />
              )}
            </div>

            {!noExternalCalendarsMode && (
              <div className='px-2 space-y-4 pb-4'>
                <CalendarList
                  title='My calendars'
                  calendars={myCalendars}
                  isExpanded={sidebarState.sections.mine}
                  onToggleExpand={(expanded) =>
                    void toggleSidebarSection('mine', expanded)
                  }
                  onEditCalendars={() => openCalListPickerModal('mine')}
                  onUpdateCalendar={updateCalendar}
                  isMyCalendarList
                  menuCustomOptions={(calendar) => {
                    const isRemovable = isCalendarRemovableFromMyCalendars(
                      calendar,
                      mainEmailAccountId
                    )

                    return [
                      isRemovable && {
                        text: 'Remove from "My calendars"',
                        onClick: () => void removeFromMyCalendars(calendar),
                      },
                    ].filter(Boolean)
                  }}
                />

                <CalendarList
                  title='Frequently met with'
                  calendars={frequentlyMetCalendars}
                  isExpanded={sidebarState.sections.frequent}
                  onToggleExpand={(expanded) =>
                    void toggleSidebarSection('frequent', expanded)
                  }
                  onEditCalendars={() => openCalListPickerModal('frequent')}
                  onCalendarChecked={toggleCalendar}
                  onUpdateCalendar={updateCalendar}
                  menuCustomOptions={(calendar) => [
                    {
                      text: 'Remove from "Frequently met with"',
                      onClick: () => void removeFromFrequentlyMet(calendar),
                    },
                  ]}
                />

                <div>
                  <CalendarListHeader
                    title={`Accounts (${emailAccounts.length})`}
                    isExpanded={sidebarState.sections.accounts}
                    onToggleExpand={(expanded) =>
                      void toggleSidebarSection('accounts', expanded)
                    }
                    actionButton={{
                      icon: PlusSolid,
                      wrapper: (button) => (
                        <AddAccountDropdown
                          onAddAccount={onAddAccount}
                          placement='bottom-end'
                        >
                          {button}
                        </AddAccountDropdown>
                      ),
                    }}
                  />

                  {sidebarState.sections.accounts && (
                    <div className='mt-2 space-y-3'>
                      {emailAccounts.map((account) => (
                        <CalendarList
                          key={account.id}
                          title={account.email}
                          isExpanded={sidebarState.sections[account.id] ?? true}
                          onToggleExpand={(expanded) =>
                            void toggleSidebarSection(account.id, expanded)
                          }
                          onUpdateCalendar={updateCalendar}
                          onCalendarChecked={toggleCalendar}
                          isAccount
                          icon={
                            <UserAvatar
                              bottomRight={
                                <CompanyLogo provider={account.providerType} />
                              }
                              id={account.id}
                              name={account.name ?? undefined}
                              size={20}
                            />
                          }
                          calendars={allCalendars.filter(
                            (calendar) => calendar.emailAccountId === account.id
                          )}
                          menuCustomOptions={(calendar) =>
                            [
                              !calendar.isInMyCalendars && {
                                onClick: () => void addToMyCalendars(calendar),
                                text: 'Add to my calendars',
                              },
                              isCalendarRemovableFromAccount(calendar) && {
                                text: 'Delete calendar from Motion',
                                onClick: () => void removeCalendar(calendar),
                              },
                            ].filter(Boolean)
                          }
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </Blade>

          {!hasRemoveAgendaSidebar && (
            <Blade
              title='Agenda'
              expandedMargin={52}
              state={
                noExternalCalendarsMode
                  ? 'expanded'
                  : (sidebarState.blades?.agenda ?? 'peek')
              }
              onStateChange={
                noExternalCalendarsMode
                  ? undefined
                  : (newState) =>
                      void setSidebarState((state) => ({
                        blades: {
                          ...state.blades,
                          agenda: newState,
                        },
                      }))
              }
            >
              <div className='flex w-full h-full overflow-auto'>
                <AgendaVirtualizedList />
              </div>
            </Blade>
          )}
        </BladeWrapper>
      </SidebarContentWrapper>
    </StatefulSidebar>
  )
}

const SidebarContentWrapper = classed(
  'div',
  `inline-flex flex-col items-start flex-shrink-0 h-full
  bg-semantic-neutral-surface-bg-subtle
  border-l border-semantic-neutral-border-default`
)

const BladeWrapper = classed(
  'div',
  'w-full grow relative flex flex-col h-full min-h-0'
)
