import { isDefaultStatus } from '@motion/shared/common'

import { useMoveTaskToWorkspaceProject } from '~/areas/tasks/hooks'
import { useWorkspaceFns } from '~/global/hooks'
import { useCallback } from 'react'

import { useActionItemForm } from './use-action-item-form'

import { useActionItemContext } from '../provider'

/**
 * Hook to update the workspace and project fields in the form
 * NOTE: This hook coupled to the action item form
 */
export function useWorkspaceProjectUpdater() {
  const { task } = useActionItemContext()
  const { form } = useActionItemForm()
  const { getWorkspaceMemberById, getWorkspaceStatuses } = useWorkspaceFns()
  const moveTaskTo = useMoveTaskToWorkspaceProject()

  const workspaceId = form.watch('workspaceId')
  const projectId = form.watch('projectId')
  const assigneeUserId = form.watch('assigneeUserId')

  return useCallback(
    async (newWorkspaceId: string, newProjectId: string | null) => {
      if (newWorkspaceId === workspaceId && newProjectId === projectId) {
        return
      }

      if (task != null) {
        return moveTaskTo(task.id, newWorkspaceId, newProjectId)
      }

      if (newWorkspaceId !== workspaceId) {
        form.setValue('workspaceId', newWorkspaceId, {
          shouldDirty: true,
        })

        if (getWorkspaceMemberById(newWorkspaceId, assigneeUserId) == null) {
          form.setValue('assigneeUserId', null)
        }

        // Set the default status when the workspace changes
        const defaultStatus =
          getWorkspaceStatuses(newWorkspaceId).find(isDefaultStatus)

        if (defaultStatus) {
          form.setValue('statusId', defaultStatus.id)
        }
      }
      if (newProjectId !== projectId) {
        form.setValue('projectId', newProjectId, {
          shouldDirty: true,
        })
      }

      return
    },
    [
      assigneeUserId,
      form,
      getWorkspaceMemberById,
      getWorkspaceStatuses,
      moveTaskTo,
      projectId,
      task,
      workspaceId,
    ]
  )
}
