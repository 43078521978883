import { classed } from '@motion/theme'

export const GridFormShell = classed('form', {
  base: `
    h-screen w-screen
    grid
    bg-modal-bg
    border border-semantic-neutral-border-default
    shadow-lg
    rounded-lg
    overflow-hidden

    setvar-[container-padding=64px]
    max-w-[min(1200px,calc(100vw-var(--container-padding)))]
    max-h-[min(900px,calc(100vh-var(--container-padding)))]

    grid-cols-[260px_auto]
    grid-rows-[1fr]
  `,
})

export const Sidebar = classed('aside', {
  base: `
    overflow-auto
    bg-semantic-neutral-surface-raised-bg-subtlest
    border-r border-modal-border

    col-span-1
  `,
})

export const SidebarSection = classed('div', {
  base: `
    flex flex-col
    p-3
  `,
  variants: {
    gap: {
      sm: 'gap-3',
      base: 'gap-4',
      lg: 'gap-5',
    },
    hasBottomBorder: {
      true: `
        border-b border-modal-border
      `,
    },
  },
  defaultVariants: {
    hasBottomBorder: false,
    gap: 'base',
  },
})

export const CalendarShell = classed('div', {
  base: `
    grid
    col-start-2

    grid-cols-[1fr]
    grid-rows-[1fr_min-content]
  `,
})

export const Footer = classed('footer', {
  base: `
    p-3
    
    flex items-center justify-end
     border-t border-modal-border
  `,
})
