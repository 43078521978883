import { getInitialFormData } from '@motion/ui-logic/pm/task'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { useSetting } from '@motion/web-common/settings'
import {
  type TaskSchema,
  type TasksV2NormalCreateSchema,
} from '@motion/zod/client'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import {
  useWorkspaceById,
  useWorkspaceFns,
  useWorkspaceLabels,
  useWorkspaceStatuses,
} from '~/global/hooks'
import { useCreateTask as useCreateTaskCall } from '~/global/rpc/v2'
import { useCallback } from 'react'

export type BulkDuplicateTaskType = 'assignees' | 'number'

export type BulkDuplicateTaskResult =
  | {
      status: 'success'
      taskIds: string[]
    }
  | {
      status: 'error'
      taskIds: string[]
      failedAssigneeIds: (string | null)[]
    }

export const useBulkDuplicateTask = (task: TaskSchema) => {
  const { mutateAsync: createTask } = useCreateTaskCall()

  const workspace = useWorkspaceById(task.workspaceId)
  const workspaceStatuses = useWorkspaceStatuses(task.workspaceId)
  const workspaceLabels = useWorkspaceLabels(task.workspaceId)
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(task.workspaceId)
  const [userSchedules = READONLY_EMPTY_OBJECT] = useSetting('schedules', {})

  const { getWorkspaceProjectById } = useWorkspaceFns()

  return useCallback(
    async (
      assignees: (string | null)[],
      options: { title?: string; type: BulkDuplicateTaskType }
    ): Promise<BulkDuplicateTaskResult> => {
      if (workspace == null) {
        throw new Error('Workspace is not defined', {
          cause: {
            taskId: task.id,
            workspaceId: task.workspaceId,
          },
        })
      }

      const project = getWorkspaceProjectById(task.projectId)

      recordAnalyticsEvent('PROJECT_MANAGEMENT_BULK_DUPLICATE_TASKS', {
        num_dups: assignees.length,
        type: options.type,
      })

      const results = await Promise.allSettled(
        assignees.map((assigneeId) => {
          const { customFieldValuesFieldArray, ...formData } =
            getInitialFormData({
              task,
              taskTitle: options.title,
              project,
              searchParams: {
                forTaskId: task.id,
              },
              currentUserId: '',
              workspaceId: workspace.id,
              workspaceStatuses,
              workspaceLabels,
              workspaceCustomFields,
              schedules: userSchedules,
            })

          const taskData: TasksV2NormalCreateSchema = {
            ...formData,
            type: 'NORMAL',
            assigneeUserId: assigneeId,
            customFieldValues: {},
          }

          customFieldValuesFieldArray.forEach((customField) => {
            if (taskData.customFieldValues && customField.value !== null) {
              taskData.customFieldValues[customField.instanceId] = customField
            }
          })

          return createTask({ data: taskData })
        })
      )

      const taskIds = results
        .filter((result) => result.status === 'fulfilled')
        .map((result) => result.value.id)

      const failedAssigneeIds = results
        .map((result, index) =>
          result.status === 'rejected' ? assignees[index] : null
        )
        .filter((id) => id)

      results.forEach((result, index) => {
        if (result.status === 'rejected') {
          const assigneeId = assignees[index]

          Sentry.captureException(
            `Failed to bulk duplicate task: ${result.reason}`,
            {
              extra: {
                taskId: task.id,
                assigneeId,
              },
            }
          )
        }
      })

      return {
        status: taskIds.length === assignees.length ? 'success' : 'error',
        taskIds,
        failedAssigneeIds,
      }
    },
    [
      workspace,
      getWorkspaceProjectById,
      task,
      workspaceStatuses,
      workspaceLabels,
      workspaceCustomFields,
      userSchedules,
      createTask,
    ]
  )
}
