import { AutoScheduleToggle } from '@motion/ui/base'

import { useTaskUpdater } from '~/areas/tasks/hooks'

import { useActionItemContext } from '../provider'

export function ConnectedInlineAutoscheduleField() {
  const { task } = useActionItemContext()

  const updateTask = useTaskUpdater()

  if (task == null) {
    return null
  }

  const isAutoScheduled = task.isAutoScheduled

  return (
    <div className='px-1 flex items-center'>
      <AutoScheduleToggle
        size='xsmall'
        checked={isAutoScheduled}
        onChange={(e) => {
          const checked = e.target.checked

          updateTask(task.id, {
            isAutoScheduled: checked,
          })
        }}
      />
    </div>
  )
}
