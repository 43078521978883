import { classed } from '@motion/theme'

export const EmptyStateContainer = classed('div', {
  base: `
    flex gap-2 pl-9 pt-2 text-semantic-neutral-text-subtle
  `,
})

export const DefaultViewCallout = classed('span', {
  base: `
    px-1 py-0.5 rounded shadow-sm
    bg-semantic-neutral-surface-bg-subtlest
    border border-semantic-neutral-border-subtle
  `,
})
