import {
  type SortableWorkspacesTreeviewItem,
  WorkspacesTreeview,
  type WorkspacesTreeviewItem,
} from '~/areas/treeviews/components'
import { flattenTreeItems } from '~/areas/treeviews/components'
import { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  ConnectedWorkspacesTreeviewItem,
  ProjectPlaceholder,
} from './components'

import { sidebarItemHeight } from '../components'

type WorkspacesTreeviewProps = {
  items: WorkspacesTreeviewItem[]
}

export const SidebarWorkspacesTreeview = ({
  items,
}: WorkspacesTreeviewProps) => {
  const flattenedItems = useMemo(() => flattenTreeItems(items), [items])

  return (
    <WorkspacesTreeview
      items={flattenedItems}
      estimateItemSize={() => sidebarItemHeight}
      insertGapBetweenItems
      renderItem={({ item, level, projection, items }) => {
        const nextItemIdx = items.indexOf(item)
        const nextItem: SortableWorkspacesTreeviewItem | undefined =
          nextItemIdx >= 0 ? items[nextItemIdx + 1] : undefined
        const isLastChildOfExpandedWorkspace =
          nextItem && level > 0 && nextItem.level === 0

        return (
          <div className={twMerge(isLastChildOfExpandedWorkspace && 'pb-3')}>
            {item.type === 'PLACEHOLDER' ? (
              <ProjectPlaceholder item={item} />
            ) : (
              <ConnectedWorkspacesTreeviewItem
                item={item}
                level={level}
                projection={projection}
              />
            )}
          </div>
        )
      }}
      renderGhostItem={({ item, level, projection }) => (
        <ConnectedWorkspacesTreeviewItem
          item={item}
          level={item.level}
          projection={projection}
          disableDrag
          isGhost
        />
      )}
    />
  )
}
