import { useOnValueChange } from '@motion/react-core/hooks'
import { type UploadedFileSchema } from '@motion/rpc-types'
import { FilePreviewModal } from '@motion/ui/base'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useLocation, useRoute } from '~/routing'
import { matchPath } from 'react-router'

import { AttachmentErrorModal } from './components/attachment-preview-error-modal'
import { useAttachmentsPreview } from './hooks/use-attachments-preview'

export type AttachmentPreviewModalProps = {
  attachmentId: UploadedFileSchema['id']
  attachmentIds?: UploadedFileSchema['id'][]
  onClose?: () => void
  onDelete?: () => void
}

export function AttachmentPreviewModal({
  attachmentId,
  attachmentIds = [],
  onClose,
  close,
  onDelete,
}: ModalTriggerComponentProps<'attachment-preview'>) {
  const {
    currentAttachment,
    currentAttachmentUrl,
    hasPrevAttachment,
    hasNextAttachment,
    isLoadingCurrentAttachment,
    errorFetchingCurrentAttachment,
    goToPrevAttachment,
    goToNextAttachment,
    deleteAttachment,
    downloadAttachment,
    copyAttachmentLink,
  } = useAttachmentsPreview({
    initialAttachmentId: attachmentId,
    attachmentIds,
    onClose: close,
  })

  const findRoute = useRoute()

  const location = useLocation()

  useOnValueChange(location, () => {
    const route = findRoute('attachment-preview')

    if (route == null || route.path == null) {
      return
    }

    // If the new pathname is not the attachment preview route, close the modal
    if (!matchPath(route.path, window.location.pathname)) {
      close()
    }
  })

  const handleClose = () => {
    close()

    if (onClose) {
      onClose()
    }
  }

  const handleDelete = async () => {
    const { deleted } = await deleteAttachment()

    if (deleted && onDelete) {
      onDelete()
    }
  }

  if (errorFetchingCurrentAttachment)
    return (
      <AttachmentErrorModal
        error={errorFetchingCurrentAttachment}
        closeModal={handleClose}
      />
    )

  if (!currentAttachment || isLoadingCurrentAttachment) return null

  return (
    <FilePreviewModal
      onClose={handleClose}
      fileUrl={currentAttachmentUrl}
      fileName={currentAttachment.fileName || ''}
      mimeType={currentAttachment.mimeType || ''}
      hasPrevFile={hasPrevAttachment}
      hasNextFile={hasNextAttachment}
      onPrevFileButtonClick={goToPrevAttachment}
      onNextFileButtonClick={goToNextAttachment}
      onDelete={handleDelete}
      onDownload={downloadAttachment}
      onCopyLink={copyAttachmentLink}
      visible
    />
  )
}
