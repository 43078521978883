import * as v from 'valibot'

export const validationSchema = v.object({
  workspaceId: v.pipe(v.string(), v.nonEmpty()),
  name: v.pipe(v.string(), v.nonEmpty()),
  description: v.pipe(v.string(), v.nonEmpty()),
  uploadedFileIds: v.array(v.pipe(v.string(), v.nonEmpty())),

  startDate: v.pipe(v.string(), v.nonEmpty()),
  dueDate: v.pipe(v.string(), v.nonEmpty()),

  roleAssignees: v.array(
    v.object({
      variableId: v.string(),
      value: v.nullable(v.string()),
      stageId: v.optional(v.string()),
    })
  ),
  textReplacements: v.array(
    v.object({
      variableId: v.string(),
      value: v.nullable(v.string()),
      stageId: v.optional(v.string()),
    })
  ),

  customFieldValuesFieldArray: v.array(v.unknown()),
  customFieldSyncInstanceIds: v.array(v.string()),

  mode: v.union([v.literal('manual'), v.literal('ai')]),

  /**
   * Create project with AI
   */
  prompt: v.optional(v.string()),
  speculativeProject: v.optional(v.unknown()),
  speculativeTasksByStageDefinitionId: v.optional(
    v.record(v.string(), v.array(v.unknown()))
  ),
  modifications: v.optional(v.record(v.string(), v.unknown())),
})
