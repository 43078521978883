import {
  useCreateTaskFromSelection,
  useCreateTasksFromSelection,
} from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { type CreateTaskWithAiFormFields } from '../types'

export function useSubmitCreateTask(createMultiple: boolean) {
  const { mutateAsync: createTasks } = useCreateTasksFromSelection()
  const { mutateAsync: createTask } = useCreateTaskFromSelection()

  return useCallback(
    async (data: CreateTaskWithAiFormFields) => {
      try {
        if (createMultiple) {
          return await createTasks(data)
        }
        return await createTask(data)
      } catch (e) {
        if (e instanceof Error) {
          showErrorToast(e)

          throw new Error('Failed to submit create task from selection', {
            cause: e,
          })
        }
      }
    },
    [createMultiple, createTask, createTasks]
  )
}
