import { PlusSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { type COLOR } from '@motion/shared/common'
import { classed } from '@motion/theme'
import { IconButton, UnstyledTooltip, useShortcut } from '@motion/ui/base'
import { ProjectPalette } from '@motion/ui/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useCallback, useEffect, useRef, useState } from 'react'

import { usePlannerProps, useScrollDelta } from '../../context'
import { useCursorPosition, useGetDateFromPixel } from '../../hooks'

type SelectableProjectDatesRowProps = {
  color?: COLOR
  onSelectDates: (startDate: string, endDate: string) => void
  stacked: boolean
}

export const SelectableProjectDatesRow = (
  props: SelectableProjectDatesRowProps
) => {
  const { onSelectDates, stacked } = props

  const [plannerProps] = usePlannerProps()
  const cursorPosition = useCursorPosition()

  const [isHovering, setIsHovering] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const initialPixelSpot = useRef(-1)

  const cursorRelativePosX = Math.floor(
    cursorPosition.x - (plannerProps.containerRect?.left || 0)
  )

  const reset = () => {
    recordAnalyticsEvent(
      'PROJECT_MANAGEMENT_PLANNER_CREATE_PROJECT_DRAG_CANCEL',
      {
        stacked,
      }
    )
    setIsAdding(false)
    initialPixelSpot.current = -1
  }

  useShortcut('escape', () => reset(), { enabled: isAdding })

  const beginDragInSpot = useCallback(
    (e: React.MouseEvent) => {
      // Ignore right click
      if (e.button !== 0) return

      recordAnalyticsEvent(
        'PROJECT_MANAGEMENT_PLANNER_CREATE_PROJECT_DRAG_START',
        {
          stacked,
        }
      )
      setIsAdding(true)
      initialPixelSpot.current = cursorRelativePosX
    },
    [cursorRelativePosX, stacked]
  )

  return (
    <>
      <div
        className='absolute w-full h-full inset-0 bg-transparent group/project-lane z-1'
        onMouseLeave={() => setIsHovering(false)}
        onMouseEnter={() => !plannerProps.resizingId && setIsHovering(true)}
      >
        {!isAdding && isHovering && (
          <AddProjectButton
            onMouseDown={beginDragInSpot}
            cursorPosition={cursorRelativePosX}
          />
        )}
      </div>
      {isAdding && (
        <CreateProjectPlaceholder
          stacked={stacked}
          onSelectDates={onSelectDates}
          color={props.color}
          initialPixelSpot={initialPixelSpot.current}
          reset={reset}
          cursorPosition={cursorRelativePosX}
        />
      )}
    </>
  )
}

type AddProjectButtonProps = {
  onMouseDown: (e: React.MouseEvent) => void
  cursorPosition: number
}
const AddProjectButton = (props: AddProjectButtonProps) => {
  const { onMouseDown, cursorPosition } = props
  const getDateFromPixel = useGetDateFromPixel()

  return (
    <div
      style={{
        transform: `translate3D(${cursorPosition - 12}px,0px,0)`,
      }}
      data-inline-add-button
      className='absolute hidden group-hover/project-lane:flex items-center h-full z-[2]'
      onMouseDown={onMouseDown}
    >
      <UnstyledTooltip
        placement='top-start'
        renderContent={() => (
          <DateTooltipContainer className='-translate-x-2'>
            {getDateFromPixel(cursorPosition).toFormat('ccc LLL dd')}
          </DateTooltipContainer>
        )}
      >
        <IconButton
          icon={PlusSolid}
          size='small'
          sentiment='neutral'
          variant='solid'
        />
      </UnstyledTooltip>
    </div>
  )
}

type CreateProjectPlaceholderProps = {
  stacked: SelectableProjectDatesRowProps['stacked']
  onSelectDates: SelectableProjectDatesRowProps['onSelectDates']
  color?: SelectableProjectDatesRowProps['color']
  initialPixelSpot: number
  reset: () => void
  cursorPosition: number
}

const CreateProjectPlaceholder = (props: CreateProjectPlaceholderProps) => {
  const {
    stacked,
    onSelectDates,
    initialPixelSpot = -1,
    reset,
    cursorPosition,
  } = props
  const [scrollDelta] = useScrollDelta()
  const getDateFromPixel = useGetDateFromPixel()

  let leftPos = initialPixelSpot - scrollDelta
  let rightPos = cursorPosition
  if (rightPos < leftPos) {
    leftPos = cursorPosition
    rightPos = initialPixelSpot - scrollDelta
  }

  const stopDragging = useCallback(() => {
    if (initialPixelSpot === -1) return

    // If the initial pixel spot and the cursor relative position are same, then it's a click
    recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_CREATE_PROJECT_DRAG_END', {
      stacked,
    })
    if (leftPos === rightPos) {
      // Set to single start date with the WEEKS offset
      const startDate = getDateFromPixel(leftPos)

      const endDate = startDate.plus({ weeks: 1 }).toISO()

      onSelectDates(startDate.toISO(), endDate)
      return
    }

    const startDate = getDateFromPixel(leftPos).toISO()
    const endDate = getDateFromPixel(rightPos).toISO()

    onSelectDates(startDate, endDate)
    reset()
  }, [
    initialPixelSpot,
    stacked,
    leftPos,
    rightPos,
    getDateFromPixel,
    onSelectDates,
    reset,
  ])

  useEffect(() => {
    if (!document) return

    document.addEventListener('mouseup', stopDragging)

    return () => {
      if (!document) return

      document.removeEventListener('mouseup', stopDragging)
    }
  }, [stopDragging])

  const startDate = getDateFromPixel(leftPos)
  const endDate = getDateFromPixel(rightPos)

  return (
    <ProjectPalette color={props.color ?? 'gray'}>
      <DraggableContainer style={{ left: leftPos, width: rightPos - leftPos }}>
        <div className='w-full bg-transparent flex items-center justify-end'>
          <DateTooltipContainer className='-translate-y-5'>
            {templateStr('{{startDate}} - {{endDate}}', {
              startDate: startDate.toFormat('ccc LLL dd'),
              endDate: endDate.toFormat('ccc LLL dd'),
            })}
          </DateTooltipContainer>
        </div>
      </DraggableContainer>
    </ProjectPalette>
  )
}

const DraggableContainer = classed('div', {
  base: `
    rounded
    h-[calc(100%-16px)]
    z-[2]
    top-2
    absolute

    border
    border-dashed
    border-palette-highlight-default

    bg-transparent

    text-palette-text-default

    hover:bg-palette-highlight-strong
  `,
})

const DateTooltipContainer = classed('div', {
  base: `
    px-0.5 text-2xs

    bg-semantic-primary-bg-strong-default text-semantic-primary-text-onDark
    rounded-sm

    whitespace-nowrap min-w-max
  `,
})
