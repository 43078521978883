import { NameField, type NameFieldProps } from '~/areas/task-project/fields'
import { NAME_FIELD_RULE } from '~/global/rules'
import { useController } from 'react-hook-form'

import { useTaskModalState } from '../contexts'
import { useTaskForm } from '../hooks'

export const ControlledNameField = () => {
  const {
    options: { isAutoSaving },
  } = useTaskModalState()
  const { form, disabledFields } = useTaskForm()
  const { control, watch } = form

  const {
    field,
    formState: { errors },
  } = useController({
    name: 'name',
    control,
    rules: NAME_FIELD_RULE,
  })
  const id = watch('id')

  const onChange: NameFieldProps['onChange'] = async (value) => {
    field.onChange(value)
  }

  return (
    <NameField
      triggerOnChangeOnBlur={isAutoSaving && id != null}
      error={errors.name != null}
      placeholder='Task name'
      label='Task name'
      value={field.value}
      disabled={disabledFields.has('name')}
      onChange={onChange}
    />
  )
}
