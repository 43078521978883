import { useHasTreatment } from '@motion/web-common/flags'
import { type DateFilterSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'

import { FilterPageFormat } from './format'
import { DateFilterQueryPicker } from './modal'
import { RelativeDateRangePanel } from './relative-date-range/relative-date-range'

import { type DropdownFilterProps } from '../../../types'
import { FilterItem } from '../filter-item'

declare module '@motion/web-common/flags/definitions' {
  interface AmplitudeExperiments {
    'date-range-v2': FeatureFlag
  }
}

export type DateFilterProps = Pick<
  DropdownFilterProps,
  'onBlur' | 'openOnMount'
> & {
  label: string
  value: DateFilterSchema | null
  onChanged(value: DateFilterSchema | null): void
}

export const DateFilter = (props: DateFilterProps) => {
  const hasDateRangeV2Enabled = useHasTreatment('date-range-v2')

  const value =
    props.value ??
    (hasDateRangeV2Enabled
      ? {
          operator: 'relative-date-range',
          inverse: false,
          from: DateTime.now().toISO(),
          to: DateTime.now().endOf('week').toISO(),
        }
      : {
          operator: 'defined-relative',
          name: 'this-week',
        })

  return (
    <FilterItem
      label={props.label}
      canInvert
      inverted={value?.inverse}
      onInvertChanged={(invert) => {
        if (value == null) return
        props.onChanged({ ...value, inverse: invert })
      }}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      onDismiss={() => props.onChanged(null)}
      renderDropdown={({ close }) => {
        return value.operator === 'relative-date-range' ? (
          <RelativeDateRangePanel
            value={value}
            onSelect={(newValue) => {
              props.onChanged(newValue)
              close()
            }}
          />
        ) : (
          <DateFilterQueryPicker
            value={value}
            onSelect={(newValue) => {
              props.onChanged(newValue)
              close()
            }}
          />
        )
      }}
    >
      <div className='flex items-center w-full truncate gap-1'>
        <FilterPageFormat value={value} />
      </div>
    </FilterItem>
  )
}
