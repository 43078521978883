import { templateStr } from '@motion/react-core/strings'
import { FormModal, Tooltip } from '@motion/ui/base'
import { RadioButton } from '@motion/ui/forms'
import { calendarProviderTypeToName } from '@motion/ui-logic'
import { isSameDay } from '@motion/utils/dates'
import { titleCase } from '@motion/utils/string'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import {
  type AllModelsSchema,
  type RecurrenceUpdateType,
} from '@motion/zod/client'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { MotionLink } from '~/global/components'
import { selectEmailAccounts } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'
import { useState } from 'react'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'recurring-event-update': PromptCallbacks<RecurrenceUpdateType> & {
      calendarEvent: AllModelsSchema['calendarEvents'][0]
      updates: { start?: string }
      operation: 'update' | 'delete'
    }
  }
}

export const UpdateRecurringEventConfirmationModal = (
  props: ModalTriggerComponentProps<'recurring-event-update'>
) => {
  const { calendarEvent, updates, operation, close } = props
  const [selected, setSelected] = useState<RecurrenceUpdateType>('this')

  const emailAccounts = useAppSelector(selectEmailAccounts)
  const emailAccount = emailAccounts.find(
    (emailAccount) => emailAccount.email === calendarEvent.email
  )

  // When updating a recurring event, remove the option to apply to "All events"
  // if the provider is Google AND the event is being moved to a different day.
  // Gcal doesn't support applying date changes to all instances of a recurring
  // event - the side effect is that the first instance gets duplicated
  const hasSameStartDay = updates.start
    ? isSameDay(calendarEvent.start, updates.start)
    : true
  const shouldShowAllEventsOption =
    emailAccount?.providerType === 'MICROSOFT' || hasSameStartDay
  const shouldShowClickHereLink = Boolean(
    calendarEvent.url && emailAccount?.providerType
  )

  return (
    <FormModal
      title={`${titleCase(operation)} recurring event`}
      visible
      onClose={close}
      submitAction={{
        onAction: () => close(selected),
        text: `${titleCase(operation)} event`,
      }}
    >
      <div className='flex flex-col gap-2 p-1'>
        <RadioButton
          label='This event'
          checked={selected === 'this'}
          onChange={() => setSelected('this')}
        />
        {emailAccount?.providerType && (
          <Tooltip
            renderContent={() =>
              templateStr(
                `This operation is not supported in Motion. {{clickHereLink}}{{clickHerePrompt}}{{clickHereProvider}}`,
                {
                  provider:
                    calendarProviderTypeToName[emailAccount?.providerType],
                  clickHereLink:
                    shouldShowClickHereLink && calendarEvent.url ? (
                      <MotionLink
                        url={calendarEvent.url}
                        className='underline text-semantic-primary-text-default underline-offset-2'
                        onClick={() => {
                          recordAnalyticsEvent(
                            'CALENDAR_EVENT_RECURRING_UPDATE_FOLLOWING_EXTERNAL_CLICK',
                            {
                              calendarEventProvider: emailAccount?.providerType,
                            }
                          )
                        }}
                      >
                        Click here
                      </MotionLink>
                    ) : null,
                  clickHerePrompt: shouldShowClickHereLink
                    ? ' to perform this action through '
                    : null,
                  clickHereProvider: shouldShowClickHereLink
                    ? calendarProviderTypeToName[emailAccount?.providerType]
                    : null,
                }
              )
            }
            interactive
          >
            <RadioButton
              label='This and following events'
              checked={selected === 'following'}
              onChange={() => setSelected('following')}
              disabled
            />
          </Tooltip>
        )}
        {shouldShowAllEventsOption && (
          <RadioButton
            label='All events'
            checked={selected === 'all'}
            onChange={() => setSelected('all')}
          />
        )}
      </div>
    </FormModal>
  )
}
