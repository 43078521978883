import {
  findStageForTask,
  type FlowTemplateFormTask,
  type FlowTemplateStage,
  moveTaskInStages,
} from '@motion/ui-logic/pm/project'

import { useFlowsModalState } from '~/areas/flows/contexts'
import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { useCallback } from 'react'

import { useGetOriginalTaskDefinitionState } from './use-get-original-task-definition-state'
import { useWarnAndRemoveTemplateBlockersFn } from './use-warn-and-remove-template-blockers-fn'

import { areIndiciesDirty, getNewIndices } from '../utils'

type Direction = 'up' | 'down' | 'top' | 'bottom' | 'new-stage'

export const useHandleMoveTask = () => {
  const warnAndRemoveTemplateBlockers = useWarnAndRemoveTemplateBlockersFn()
  const {
    form: { watch, setValue },
  } = useFlowTemplateForm()
  const { setDirtyTasksMap } = useFlowsModalState()
  const getOriginalTaskDefinitionState = useGetOriginalTaskDefinitionState()

  const stages = watch('stages')

  return useCallback(
    async (
      direction: Direction,
      task: FlowTemplateFormTask,
      newStage?: FlowTemplateStage
    ) => {
      const currentStage = findStageForTask(stages, task.id)
      if (currentStage == null) {
        return
      }
      const toTaskIndex = getToTaskIndex(direction, currentStage, task)
      const toStageIndex = getToStageIndex(direction, stages, newStage)
      const newStages = moveTaskInStages(
        task,
        stages,
        toTaskIndex,
        toStageIndex
      )
      setValue('stages', newStages, { shouldDirty: true })

      const { moved } = await warnAndRemoveTemplateBlockers({
        previousStages: stages,
        activeTaskId: task.id,
        stages: newStages,
        replace: (s) => setValue('stages', s),
      })

      if (moved) {
        const { originalIndices } = getOriginalTaskDefinitionState(task.id)
        const newIndices = getNewIndices(newStages, task.id)
        if (newIndices) {
          const isDirty = areIndiciesDirty(originalIndices, newIndices)

          setDirtyTasksMap((dirtyTasksMap) => ({
            ...dirtyTasksMap,
            [task.id]: isDirty,
          }))
        }
      }
    },
    [
      stages,
      setValue,
      warnAndRemoveTemplateBlockers,
      getOriginalTaskDefinitionState,
      setDirtyTasksMap,
    ]
  )
}

const getToTaskIndex = (
  direction: Direction,
  currentStage: FlowTemplateStage,
  task: FlowTemplateFormTask
): number => {
  if (direction === 'top' || direction === 'new-stage') {
    return 0
  }
  if (direction === 'bottom') {
    return currentStage.tasks.length - 1
  }
  if (direction === 'up') {
    return currentStage.tasks.indexOf(task) !== 0
      ? currentStage.tasks.indexOf(task) - 1
      : 0
  }

  return currentStage.tasks.indexOf(task) !== currentStage.tasks.length - 1
    ? currentStage.tasks.indexOf(task) + 1
    : currentStage.tasks.length - 1
}

const getToStageIndex = (
  direction: Direction,
  stages: FlowTemplateStage[],
  stage: FlowTemplateStage | undefined
): number | undefined => {
  if (direction !== 'new-stage' || stage == null) {
    return undefined
  }

  return stages.indexOf(stage)
}
