import { StageSolidTransparentArrow } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
} from '@motion/rpc-types'
import {
  convertDateIntervalToDays,
  EXPECTED_DURATION_UNIT_DROPDOWN_ITEMS,
  type RelativeIntervalUnit,
} from '@motion/shared/flows'
import { FormModal, IconButton, PopoverButton, Text } from '@motion/ui/base'
import { NumberField, TextField } from '@motion/ui/forms'
import { ProjectPalette } from '@motion/ui/project'
import { safeParseDate } from '@motion/utils/dates'

import { type ModalTriggerComponentProps } from '~/areas/modals/modal-trigger/modal-trigger'
import { ColorDropdown } from '~/global/components/dropdowns'
import { useProject, useWorkspaceById } from '~/global/hooks'
import { useState } from 'react'

import { ExpectedDurationUnitDropdown } from '../expected-duration-unit-dropdown'

type SubmitHandlerArgs = {
  name: string
  color: StageDefinitionSchema['color']
  expectedDurationValue: number
  expectedDurationUnit: RelativeIntervalUnit
}
declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'create-stage-from-project': {
      projectId: ProjectSchema['id']
      hideDurationField?: boolean
      onSubmit: ({
        name,
        color,
        expectedDurationValue,
        expectedDurationUnit,
      }: SubmitHandlerArgs) => void
    }
  }
}

export function CreateStageFromProjectModal({
  projectId,
  onSubmit,
  close,
  hideDurationField = false,
}: ModalTriggerComponentProps<'create-stage-from-project'>) {
  const project = useProject(projectId)
  const projectDueDate = safeParseDate(project?.dueDate)
  const projectStartDate = safeParseDate(project?.startDate)
  const originalDayDuration =
    projectDueDate && projectStartDate
      ? Math.floor(Math.abs(projectDueDate.diff(projectStartDate, 'days').days))
      : 7
  const defaultDurationValue = hideDurationField ? originalDayDuration : 7

  const [stageName, setStageName] = useState('')
  const [color, setColor] = useState<StageDefinitionSchema['color']>('gray')
  const [expectedDurationValue, setExpectedDurationValue] =
    useState<number>(defaultDurationValue)
  const [expectedDurationUnit, setExpectedDurationUnit] =
    useState<RelativeIntervalUnit>('DAYS')

  const workspace = useWorkspaceById(project?.workspaceId)

  if (!project || !workspace) {
    close()
    return null
  }

  const expectedDurationUnitDropdownItem =
    EXPECTED_DURATION_UNIT_DROPDOWN_ITEMS.find(
      (item) => item.id === expectedDurationUnit
    ) ?? EXPECTED_DURATION_UNIT_DROPDOWN_ITEMS[0]

  const disabledSubmit = stageName.trim().length === 0

  const durationInDays = convertDateIntervalToDays({
    unit: expectedDurationUnit,
    value: Number.isNaN(expectedDurationValue) ? 0 : expectedDurationValue,
  })

  return (
    <FormModal
      onClose={close}
      submitAction={{
        text: 'Create new stage',
        disabled: disabledSubmit,
        onAction: async () => {
          await onSubmit({
            name: stageName,
            color,
            expectedDurationValue,
            expectedDurationUnit,
          })

          close()
        },
      }}
      title='Create new stage'
      visible
    >
      <div className='flex flex-col items-start gap-4 self-stretch min-w-0 max-w-[418px]'>
        <div className='flex gap-2 self-stretch items-start w-full'>
          <ProjectPalette color={color}>
            <div className='[&_[data-icon]]:!text-palette-highlight-default'>
              <ColorDropdown
                onChange={(color) => setColor(color)}
                selectedColor={color}
              >
                <IconButton
                  icon={StageSolidTransparentArrow}
                  sentiment='neutral'
                />
              </ColorDropdown>
            </div>
          </ProjectPalette>

          <TextField
            autoFocus
            fullWidth
            size='normal'
            value={stageName}
            onChange={(val) => setStageName(val)}
            placeholder='Stage name'
          />
        </div>
        {!hideDurationField && (
          <>
            <div className='flex px-1 items-center gap-1.5 self-stretch'>
              <Text className='flex-shrink-0' sentiment='subtle' size='xs'>
                Expected duration:
              </Text>
              <NumberField
                formatOptions={{ type: 'integer', grouped: true }}
                maxLength={2}
                onChange={(val) => setExpectedDurationValue(val)}
                value={expectedDurationValue}
              />
              <ExpectedDurationUnitDropdown
                selectedItem={expectedDurationUnitDropdownItem}
                onChange={(item) => setExpectedDurationUnit(item.id)}
              >
                <PopoverButton>
                  {expectedDurationUnitDropdownItem.label}
                </PopoverButton>
              </ExpectedDurationUnitDropdown>
            </div>
            <Text className='flex-wrap' size='xs'>
              {templateStr(
                'This will increase your project deadline by {{durationInDays}} days from {{projectDueDate}} to {{newDueDate}}',
                {
                  durationInDays,
                  projectDueDate: projectDueDate?.toFormat('cccc MMM d'),
                  newDueDate: projectDueDate
                    ?.plus({
                      days: durationInDays,
                    })
                    .toFormat('cccc MMM d'),
                }
              )}
            </Text>
          </>
        )}
      </div>
    </FormModal>
  )
}
