import { byProperty, Compare } from '@motion/utils/array'
import type { FolderWithItemsSchema } from '@motion/zod/client'

import { useFormatWorkspacesTreeItems } from '~/areas/treeviews/hooks'
import { type WorkspacesTreeItem } from '~/global/hooks'
import { useFolders } from '~/global/rpc/folders'
import { useMemo } from 'react'

export type UseUserFavoritesReturnType = {
  items: WorkspacesTreeItem[]
  folder: FolderWithItemsSchema | null
}

export const useUserFavorites = (): UseUserFavoritesReturnType => {
  const { data: folders } = useFolders()
  const formatWorkspacesTreeItems = useFormatWorkspacesTreeItems({
    treeType: 'FAVORITES',
  })

  const folder = useMemo<UseUserFavoritesReturnType['folder']>(
    () => folders?.models.systemFolders['userFavorites'] ?? null,
    [folders]
  )

  const items = useMemo(() => {
    if (!folder) return []

    return folder.items
      .map(formatWorkspacesTreeItems)
      .filter(Boolean)
      .sort(byProperty('order', Compare.string))
  }, [folder, formatWorkspacesTreeItems])

  return { items, folder }
}
