import { FieldTypes } from '@motion/shared/custom-fields'
import { createNoneId, isNoneId } from '@motion/shared/identifiers'
import { getPrefixFromMaybeCustomFieldKey } from '@motion/ui-logic'
import {
  type CustomFieldFilters,
  DEFAULT_CUSTOM_FIELD_FILTERS,
  DEFAULT_PROJECT_FILTERS,
  DEFAULT_TASK_FILTERS,
  DEFAULT_WORKSPACE_FILTERS,
  type EntityFilterState,
  formatInclusionFilterToBooleanFilter,
  type ProjectFilter,
  type TaskFilter,
  type WorkspaceFilterKey,
} from '@motion/ui-logic/pm/data'
import { cloneDeep, isEqual, omit, pick, toMerged } from '@motion/utils/core'
import { diff, entries, stripUndefined } from '@motion/utils/object'
import { type RequiredKeys } from '@motion/utils/types'
import { makeLog } from '@motion/web-base/logging'
import {
  type DashboardViewDefinitionV2,
  type DateFilterSchema,
  type IdNullableFilterSchema,
  type Inclusion,
  type VersionedViewV2,
  type ViewDefinitionFiltersSchema,
  type ViewDefinitionTaskFilterV2,
  type ViewDefinitionV2,
  type WorkspaceViewDefinitionV2,
  WorkspaceViewTypeV2,
} from '@motion/zod/client'

import { DateTime } from 'luxon'

import { type SortField } from '../../fields'
import { type ViewState } from '../../view-state'
import { type LocalView } from '../types'

const log = makeLog('views')

export function isLocalWorkspaceView(
  view: LocalView
): view is LocalView & { definition: WorkspaceViewDefinitionV2 } {
  return WorkspaceViewTypeV2.includes(view.type)
}

export function isWorkspaceViewDefinition(
  view: ViewDefinitionV2
): view is WorkspaceViewDefinitionV2 {
  return view.type !== 'team-schedule'
}

export const areViewsEqual = (
  left: ViewDefinitionV2,
  right: ViewDefinitionV2
) => {
  if (!left || !right) return false
  if (isEqual(left, right)) return true
  if (!isWorkspaceViewDefinition(left) || !isWorkspaceViewDefinition(right))
    return false

  const changes = diff(left, right)

  if (left.type !== right.type) {
    return false
  }

  if ('columns' in left && left.columns.length === 0) {
    const withoutColumnChanges = changes.filter(
      (x) => !x.path.startsWith('/columns')
    )
    if (withoutColumnChanges.length === 0) return true
  }

  log.debug('diff', changes)
  return changes.length === 0
}

type ViewTaskFilter = WorkspaceViewDefinitionV2['filters']['tasks']['filters']
type ViewProjectFilter =
  WorkspaceViewDefinitionV2['filters']['projects']['filters']
type ViewWorkspaceFilter =
  WorkspaceViewDefinitionV2['filters']['workspaces']['filters']

type ExactFromViewTaskFilter = ExactToViewTaskFilter & {
  customFields: ViewTaskFilter['customFields']
}

type ExactFromViewProjectFilter = Omit<
  ExactToViewProjectFilter,
  'stageDefinitionIds'
> & {
  customFields: ViewProjectFilter['customFields']
  activeStageDefinitionIds: ViewProjectFilter['activeStageDefinitionIds']
}

export function toViewDefinition(
  type: 'workspace' | 'all-tasks' | 'my-tasks',
  filterState: EntityFilterState,
  viewState: ViewState
): WorkspaceViewDefinitionV2 | DashboardViewDefinitionV2 {
  if (viewState.page === 'dashboard') {
    return viewState.view
  }

  return {
    $version: 2,
    type,
    layout: viewState.page,
    itemType: filterState.target,
    cardFields: viewState.cardFields,
    providerIds: viewState.providerIds,
    showOOOEventsByAssignee: viewState.showOOOEventsByAssignee,
    filters: toViewDefinitionFilters(filterState),

    sort:
      viewState.sortBy == null
        ? []
        : [
            {
              field: viewState.sortBy.field,
              direction: viewState.sortBy.direction.toUpperCase() as
                | 'ASC'
                | 'DESC',
            },
          ],
    columns: viewState.columns.map((c) =>
      stripUndefined({
        id: c.id,
        visible: c.visible ?? true,
        width: c.width,
        pinned: c.pinned ?? false,
      })
    ),

    grouping: {
      fields: viewState.groupBy.fields,
      order: stripUndefined(viewState.groupBy.order),
      hideEmptyGroups: viewState.groupBy.hideEmpty,
      stackProjects: viewState.groupBy.stackProjects,
      dateRange: viewState.groupBy.dateRange,
    },
  }
}

export function toViewDefinitionFilters(
  filterState: EntityFilterState
): ViewDefinitionFiltersSchema {
  const { tasks, projects, workspaces } = filterState
  return {
    tasks: {
      ordered: tasks.ordered,
      filters: stripUndefined<ExactFromViewTaskFilter>({
        assigneeUserIds: normalizeNoneIdToNull(tasks.filters.assigneeUserIds),
        createdByUserIds: normalizeNoneIdToNull(tasks.filters.createdByUserIds),

        statusIds: normalize(tasks.filters.statusIds),
        labelIds: normalize(tasks.filters.labelIds),

        priorities: normalize(tasks.filters.priorities),
        deadlineStatuses: normalize(tasks.filters.deadlineStatuses),
        deadlineStatusWithReason: normalize(
          tasks.filters.deadlineStatusWithReason
        ),

        dueDate: dateFilterToUtc(tasks.filters.dueDate),
        scheduledStart: dateFilterToUtc(tasks.filters.scheduledStart),
        startDate: dateFilterToUtc(tasks.filters.startDate),

        createdTime: dateFilterToUtc(tasks.filters.createdTime),
        completedTime: dateFilterToUtc(tasks.filters.completedTime),
        updatedTime: dateFilterToUtc(tasks.filters.updatedTime),
        lastInteractedTime: dateFilterToUtc(tasks.filters.lastInteractedTime),
        recurring: tasks.filters.recurring ?? undefined,

        completed: tasks.filters.completed ?? undefined,
        canceled: tasks.filters.canceled ?? undefined,
        archived: tasks.filters.archived ?? undefined,
        scheduledStatus: tasks.filters.scheduledStatus ?? undefined,
        isAutoScheduled: formatInclusionFilterToBooleanFilter(
          tasks.filters.autoScheduled
        ),
        isBlocked: formatInclusionFilterToBooleanFilter(
          tasks.filters.isBlocked
        ),
        isBlocking: formatInclusionFilterToBooleanFilter(
          tasks.filters.isBlocking
        ),
        type: tasks.filters.type ?? undefined,

        customFields: customFieldFiltersToViewDefinition(tasks.filters),

        stageDefinitionIds: normalize(tasks.filters.stageDefinitionIds),
        estimatedCompletionTime: dateFilterToUtc(
          tasks.filters.estimatedCompletionTime
        ),

        isUnvisitedStage: tasks.filters.isUnvisitedStage ?? undefined,
        folderIds: tasks.filters.folderIds ?? undefined,
        endDate: dateFilterToUtc(tasks.filters.endDate),
        scheduledEnd: dateFilterToUtc(tasks.filters.scheduledEnd),
        completedOrEstimatedTime: dateFilterToUtc(
          tasks.filters.completedOrEstimatedTime
        ),

        hasAttachments: formatInclusionFilterToBooleanFilter(
          tasks.filters.hasAttachments
        ),
      }),
    },
    projects: {
      ordered: projects.ordered,
      filters: stripUndefined<ExactFromViewProjectFilter>({
        ids: normalize(projects.filters.ids),
        labelIds: normalize(projects.filters.labelIds),
        priorities: normalize(projects.filters.priorities),
        deadlineStatuses: normalize(projects.filters.deadlineStatuses),
        color: normalize(projects.filters.color),
        statusIds: normalize(projects.filters.statusIds),
        managerIds: normalizeNoneIdToNull(projects.filters.managerIds),

        startDate: projects.filters.startDate ?? undefined,
        dueDate: projects.filters.dueDate ?? undefined,
        createdTime: dateFilterToUtc(projects.filters.createdTime),
        updatedTime: dateFilterToUtc(projects.filters.updatedTime),
        completed: projects.filters.completed ?? undefined,

        customFields: customFieldFiltersToViewDefinition(projects.filters),

        activeStageDefinitionIds: normalize(
          projects.filters.stageDefinitionIds
        ),
        projectDefinitionIds: normalize(projects.filters.projectDefinitionIds),
        folderIds: normalize(projects.filters.folderIds),

        createdByUserIds: normalize(projects.filters.createdByUserIds),

        canceledDuration: projects.filters.canceledDuration ?? undefined,
        canceledTaskCount: projects.filters.canceledTaskCount ?? undefined,
        completedDuration: projects.filters.completedDuration ?? undefined,
        taskCount: projects.filters.taskCount ?? undefined,

        completedTime: projects.filters.completedTime ?? undefined,
        estimatedCompletionTime:
          projects.filters.estimatedCompletionTime ?? undefined,

        name: projects.filters.name ?? undefined,
      }),
    },
    workspaces: {
      ordered: workspaces.ordered as WorkspaceFilterKey[],
      filters: stripUndefined<ViewWorkspaceFilter>({
        ids: normalize(workspaces.filters.ids),
      }),
    },
  }
}

function dateFilterToUtc(
  value: DateFilterSchema | null
): DateFilterSchema | undefined {
  if (value == null) return undefined
  switch (value.operator) {
    case 'equals':
    case 'gt':
    case 'gte':
    case 'lt':
    case 'lte':
      return {
        ...value,
        value: DateTime.fromISO(value.value).setZone('utc').toISO(),
      }
    case 'range': {
      return {
        ...value,
        value: {
          from: DateTime.fromISO(value.value.from).setZone('utc').toISO(),
          to: DateTime.fromISO(value.value.to).setZone('utc').toISO(),
        },
      }
    }

    default:
      return value
  }
}

function normalizeNullToNoneUser<T extends { value: (string | null)[] }>(
  filter: T | undefined | null
): T | undefined {
  if (filter == null) return undefined
  const ids = filter.value
  return ids.length === 0
    ? undefined
    : {
        ...filter,
        value: ids.map((x) => (x == null ? createNoneId('user') : x)),
      }
}

function normalizeNoneIdToNull<T extends { value: string[] }>(
  filter: T | undefined | null
): T | undefined {
  if (filter == null) return undefined
  const ids = filter.value
  return ids.length === 0
    ? undefined
    : {
        ...filter,
        value: ids.map((x) => (isNoneId(x) ? null : x)),
      }
}

function normalize<T extends { value: any[] }>(
  filter: T | undefined | null
): T | undefined {
  if (filter == null) return undefined
  const ids = filter.value
  return ids.length === 0 ? undefined : filter
}

export function fromViewDefinition(view: VersionedViewV2): {
  filter: EntityFilterState
  view: ViewState
} {
  const def = view.definition

  if (def.type === 'dashboard') {
    return {
      filter: fromViewDefinitionFiltersToFilterState('tasks', {
        tasks: {
          ordered: [],
          filters: {},
        },
        projects: {
          ordered: [],
          filters: {},
        },
        workspaces: {
          ordered: [],
          filters: {},
        },
      }),
      view: {
        $version: 6,
        viewId: view.id,
        page: 'dashboard',
        view: def,
      },
    }
  }
  if (def.type === 'team-schedule') {
    throw new Error('invalid')
  }

  const filterState: EntityFilterState = fromViewDefinitionFiltersToFilterState(
    def.itemType,
    def.filters
  )
  const viewState: ViewState = {
    $version: 6,
    columns: def.columns.map((x) =>
      stripUndefined({
        id: x.id,
        visible: x.visible ?? true,
        width: x.width,
        pinned: x.pinned ?? false,
      })
    ),
    cardFields: def.cardFields ?? [],
    providerIds: def.providerIds ?? [],
    showOOOEventsByAssignee: def.showOOOEventsByAssignee ?? false,
    page: def.layout === 'planner' ? 'gantt' : def.layout,
    search: '',
    sortBy:
      def.sort.length === 0
        ? null
        : {
            field: def.sort[0].field as SortField,
            direction: def.sort[0].direction.toLowerCase() as 'asc' | 'desc',
          },
    groupBy: {
      order: stripUndefined(def.grouping.order),
      fields: def.grouping.fields,
      hideEmpty: def.grouping.hideEmptyGroups,
      stackProjects: def.grouping.stackProjects ?? true,
      dateRange: def.grouping.dateRange ?? 'quarter',
    },
    viewId: view.id,
  }

  return {
    filter: filterState,
    view: viewState,
  }
}

type Options = {
  addMissingFiltersToOrderedArray?: boolean
  noDefaultValues?: boolean
}
export function fromViewDefinitionFiltersToFilterState(
  target: WorkspaceViewDefinitionV2['itemType'],
  filters: ViewDefinitionFiltersSchema,
  opts: Options = {}
): EntityFilterState {
  const { tasks, projects, workspaces } = filters
  const { addMissingFiltersToOrderedArray = false, noDefaultValues = false } =
    opts

  const taskFilters = fromViewTaskFilter(tasks.filters, { noDefaultValues })
  const projectFilters = fromViewProjectFilter(projects.filters, {
    noDefaultValues,
  })

  return {
    $version: 9,
    target: target,
    tasks: {
      ordered: remapFields(
        TASK_FIELDS,
        addMissingFiltersToOrderedArray
          ? addMissingEntries(tasks.ordered, Object.keys(taskFilters))
          : tasks.ordered
      ),
      filters: taskFilters,
    },
    projects: {
      ordered: remapFields(
        PROJECT_FIELDS,
        addMissingFiltersToOrderedArray
          ? addMissingEntries(projects.ordered, Object.keys(projectFilters))
          : projects.ordered
      ),
      filters: projectFilters,
    },
    workspaces: {
      ordered: workspaces.ordered,
      filters: Object.assign(
        cloneDeep(DEFAULT_WORKSPACE_FILTERS),
        stripUndefined({
          ids: workspaces.filters.ids,
        })
      ),
    },
  }
}

function addMissingEntries(ordered: string[], keys: string[]) {
  const existing = new Set(ordered)

  const result = [...ordered]
  for (const key of keys) {
    if (!existing.has(key)) {
      result.push(key)
      existing.add(key)
    }
  }

  return result
}

type ExactToViewProjectFilter = RequiredKeys<
  Omit<ViewProjectFilter, 'customFields' | 'activeStageDefinitionIds'> & {
    stageDefinitionIds: IdNullableFilterSchema | undefined
  }
>

function fromViewProjectFilter(
  filters: ViewProjectFilter,
  { noDefaultValues = false }: FromViewFilterOption
): ProjectFilter {
  return toMerged(
    DEFAULT_PROJECT_FILTERS,
    noDefaultValues ? { completed: null } : {},
    stripUndefined<ExactToViewProjectFilter>({
      ids: filters.ids,
      labelIds: filters.labelIds,
      priorities: filters.priorities,
      deadlineStatuses: filters.deadlineStatuses,
      color: filters.color,
      statusIds: filters.statusIds,

      managerIds: normalizeNullToNoneUser(filters.managerIds),
      dueDate: filters.dueDate,
      createdTime: filters.createdTime,
      updatedTime: filters.updatedTime,
      completed: filters.completed,
      startDate: filters.startDate,

      ...viewDefinitionToCustomFieldFilters(filters.customFields),

      stageDefinitionIds: filters.activeStageDefinitionIds,
      projectDefinitionIds: filters.projectDefinitionIds,
      folderIds: filters.folderIds,

      createdByUserIds: filters.createdByUserIds,

      taskCount: filters.taskCount,
      canceledDuration: filters.canceledTaskCount,
      canceledTaskCount: filters.canceledTaskCount,
      completedDuration: filters.completedDuration,

      completedTime: filters.completedTime,
      estimatedCompletionTime: filters.estimatedCompletionTime,

      name: filters.name,
    })
  )
}

type ExactToViewTaskFilter = RequiredKeys<
  Omit<ViewTaskFilter, 'ids' | 'workspaceIds' | 'customFields' | 'projectIds'>
>

type FromViewFilterOption = {
  noDefaultValues?: boolean
}

function fromViewTaskFilter(
  filters: ViewTaskFilter,
  { noDefaultValues = false }: FromViewFilterOption
): TaskFilter {
  const filterUpdates = stripUndefined<ExactToViewTaskFilter>({
    assigneeUserIds: normalizeNullToNoneUser(filters.assigneeUserIds),
    createdByUserIds: normalizeNullToNoneUser(filters.createdByUserIds),

    statusIds: filters.statusIds,
    labelIds: filters.labelIds,

    priorities: filters.priorities,
    deadlineStatuses: filters.deadlineStatuses,
    deadlineStatusWithReason: filters.deadlineStatusWithReason,

    dueDate: filters.dueDate,
    scheduledStart: filters.scheduledStart,
    startDate: filters.startDate,
    scheduledStatus: filters.scheduledStatus,

    createdTime: filters.createdTime,
    completedTime: filters.completedTime,
    updatedTime: filters.updatedTime,
    lastInteractedTime: filters.lastInteractedTime,

    archived: filters.archived,
    completed: filters.completed,
    canceled: filters.canceled,

    isAutoScheduled: filters.isAutoScheduled,
    isBlocked: filters.isBlocked,
    isBlocking: filters.isBlocking,
    recurring: filters.recurring,
    hasAttachments: filters.hasAttachments,
    type: filters.type,

    ...viewDefinitionToCustomFieldFilters(filters.customFields),

    stageDefinitionIds: filters.stageDefinitionIds,
    estimatedCompletionTime: filters.estimatedCompletionTime,
    isUnvisitedStage: filters.isUnvisitedStage,
    folderIds: filters.folderIds,
    endDate: filters.endDate,
    scheduledEnd: filters.scheduledEnd,
    completedOrEstimatedTime: filters.completedOrEstimatedTime,
  })

  const mappedFilterUpdates = {
    ...omit(
      filterUpdates,
      'isAutoScheduled',
      'isBlocked',
      'isBlocking',
      'hasAttachments'
    ),
    autoScheduled: booleanToInclusion(filters.isAutoScheduled?.value),
    isBlocked: booleanToInclusion(filters.isBlocked?.value),
    isBlocking: booleanToInclusion(filters.isBlocking?.value),
    hasAttachments: booleanToInclusion(filters.hasAttachments?.value),
  }

  const newFilters = toMerged(
    DEFAULT_TASK_FILTERS,
    noDefaultValues ? { completed: null, archived: null, canceled: null } : {},
    mappedFilterUpdates
  ) satisfies TaskFilter

  return newFilters
}

function booleanToInclusion(value: boolean | undefined): Inclusion | null {
  if (value == null) return null

  return value ? 'include' : 'exclude'
}

export function customFieldFiltersToViewDefinition(
  filters: TaskFilter | ProjectFilter
): ViewDefinitionTaskFilterV2['customFields'] {
  const cfFilters = Object.values(
    // @ts-expect-error - fine
    pick(filters, FieldTypes) as CustomFieldFilters
    // @ts-expect-error - fine
  ).reduce((acc, value) => {
    if (value == null || typeof value !== 'object') {
      return acc
    }
    return { ...acc, ...value }
  }, {}) as NonNullable<ViewDefinitionTaskFilterV2['customFields']>

  if (Object.keys(cfFilters).length === 0) {
    return undefined
  }

  return cfFilters
}

export function viewDefinitionToCustomFieldFilters(
  definition: ViewDefinitionTaskFilterV2['customFields']
): CustomFieldFilters {
  if (definition == null) {
    return DEFAULT_CUSTOM_FIELD_FILTERS
  }

  return entries(definition).reduce<CustomFieldFilters>(
    (acc, [typeKey, idsToFilters]) => {
      const customFieldType = getPrefixFromMaybeCustomFieldKey(typeKey)
      if (customFieldType == null) {
        return acc
      }

      return {
        ...acc,
        [customFieldType]: {
          ...acc[customFieldType as keyof CustomFieldFilters],
          [typeKey]: idsToFilters,
        },
      }
    },
    DEFAULT_CUSTOM_FIELD_FILTERS
  )
}

function remapFields(
  fieldMap: Record<string, string>,
  ordered: string[]
): string[] {
  return ordered.map((x) => fieldMap[x] ?? x)
}

const TASK_FIELDS: Record<string, string> = {
  statuses: 'statusIds',
  users: 'assigneeUserIds',
  labels: 'labelIds',
}

const PROJECT_FIELDS: Record<string, string> = {
  statuses: 'statusIds',
  users: 'managerIds',
  labels: 'labelIds',
}
