import { CheckSolid, ChevronDownOutline } from '@motion/icons'

// eslint-disable-next-line no-restricted-imports
import { Combobox, Transition } from '@headlessui/react'
import { useContacts } from '~/areas/event/hooks'
import { Fragment, useRef } from 'react'

import { LoadingSvg } from '../../../../components/Common/Icons/LoadingSvg'

type EmailAutoCompleteProps<T extends { email: string }> = {
  selected: T | undefined
  setSelected: (selected: T, lastKeyPress?: string) => void
  inputValue: string
  setInputValue: (inputValue: string) => void
  placeholder: string
}

export function EmailAutoComplete<T extends { email: string }>({
  selected,
  setSelected,
  inputValue = '',
  setInputValue,
  placeholder = '',
}: EmailAutoCompleteProps<T>) {
  const {
    sortedContacts: options,
    onSearch,
    loadingState,
  } = useContacts({
    contactsSource: 'general',
  })
  const filteredOptions =
    inputValue === ''
      ? options
      : options.filter((option) =>
          option.email
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(inputValue.toLowerCase().replace(/\s+/g, ''))
        )

  const lastKeyPress = useRef('')

  function onChange(value: any) {
    // Prevent input blur events from setting selected value
    if (lastKeyPress.current !== 'Tab') {
      setSelected(value)
    }
  }

  return (
    <div className='relative w-full'>
      <Combobox value={selected} onChange={onChange} nullable>
        <div className='relative'>
          <div className='relative w-full cursor-default overflow-hidden rounded bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm'>
            <Combobox.Input
              className='text-light-1200 border-light-500 w-full rounded border py-2 pl-3 pr-10 leading-5 focus:ring-0 text-base lg:text-sm'
              placeholder={placeholder}
              displayValue={(option: T) => option?.email || inputValue}
              onChange={(event) => {
                onSearch(event.target.value)
                setInputValue(event.target.value)
              }}
              // @ts-expect-error onKeyDown does not exists as an intrinsic attribute.
              onKeyDown={(event: any) => {
                lastKeyPress.current = event.key
                if (event.key === 'Enter' && selected?.email) {
                  onChange(selected)
                }
              }}
            />
            {loadingState === 'loading' && (
              <div className='absolute top-2.5 right-8 flex items-center justify-center'>
                <LoadingSvg />
              </div>
            )}
            <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
              <ChevronDownOutline
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
            afterEnter={() => (lastKeyPress.current = '')}
          >
            <Combobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
              {inputValue !== '' && filteredOptions.length === 0 && (
                <Combobox.Option
                  value={{ email: inputValue, isNewContact: true }}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-primary-300 text-white' : 'text-gray-900'
                    }`
                  }
                >
                  <span className='block truncate font-normal'>
                    Invite {inputValue}
                  </span>
                </Combobox.Option>
              )}

              {filteredOptions.map((option) => (
                <Combobox.Option
                  key={option.email}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-primary-300 text-white' : 'text-gray-900'
                    }`
                  }
                  value={option}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {option.email}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? 'text-white' : 'text-teal-600'
                          }`}
                        >
                          <CheckSolid className='h-5 w-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}
