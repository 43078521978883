import { type FlowTemplateStage } from '@motion/ui-logic/pm/project'
import { type TaskDefinitionSchema } from '@motion/zod/client'

import { type UniqueIdentifier } from '@dnd-kit/core'

export function deprecatedStripBlockers(
  activeTaskId: UniqueIdentifier,
  stages: FlowTemplateStage[],
  blockedByTask: TaskDefinitionSchema | undefined,
  blockingTasks: TaskDefinitionSchema[]
): FlowTemplateStage[] {
  return stages.map((stage) => ({
    ...stage,
    tasks: stage.tasks.map((task) => {
      if (
        (task.id === activeTaskId &&
          task.blockedByTaskId === blockedByTask?.id) ||
        blockingTasks.find((b) => b.id === task.id)
      ) {
        return {
          ...task,
          blockedByTaskId: null,
        }
      }

      return task
    }),
  }))
}
