import { useOnValueChange } from '@motion/react-core/hooks'
import { type UploadedFileSchema } from '@motion/rpc-types'
import { fileIsPreviewable } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import {
  useAttachmentActions,
  useDownloadAttachment,
} from '~/areas/attachments/hooks'
import { useFileById } from '~/global/rpc/v2/files'

import { useAttachmentsNavigation } from './use-attachments-navigation'

type UseAttachmentsPreviewParams = {
  attachmentIds: UploadedFileSchema['id'][]
  initialAttachmentId: UploadedFileSchema['id']
  onClose: () => void
}

export function useAttachmentsPreview({
  attachmentIds,
  initialAttachmentId,
  onClose,
}: UseAttachmentsPreviewParams) {
  const {
    currentAttachmentId,
    hasPrevAttachment,
    hasNextAttachment,
    goToPrevAttachment,
    goToNextAttachment,
  } = useAttachmentsNavigation({
    attachmentIds,
    initialAttachmentId,
  })

  const {
    data: currentAttachment,
    isLoading: isLoadingCurrentAttachment,
    error: errorFetchingCurrentAttachment,
  } = useFileById({
    id: currentAttachmentId,
  })

  useOnValueChange(
    currentAttachment,
    (newAttachment, oldAttachment) => {
      if (newAttachment && oldAttachment) {
        recordAnalyticsEvent('ATTACHMENT_FILE_VIEWED', {
          file_size: newAttachment.fileSize,
          mime_type: newAttachment.mimeType,
          target_type: newAttachment.targetType ?? 'none',
        })
      }
    },
    { triggerOnFirstRender: true }
  )

  const { getFileUrl } = useDownloadAttachment()

  const attachmentActions = useAttachmentActions()

  const currentAttachmentUrl = getFileUrl(currentAttachmentId) || ''

  const currentAttachmentIsPreviewable = fileIsPreviewable(
    currentAttachment?.mimeType
  )

  const deleteAttachment = async () => {
    if (!currentAttachment) return { deleted: false }

    const { deleted } =
      await attachmentActions.deleteAttachment(currentAttachment)

    if (deleted) {
      onClose()
    }

    return { deleted }
  }

  const downloadAttachment = async () => {
    if (!currentAttachment) return

    attachmentActions.downloadAttachment(currentAttachment)
  }

  const copyAttachmentLink = () => {
    if (!currentAttachment) return

    attachmentActions.copyAttachmentLink(currentAttachment)
  }

  return {
    currentAttachment,
    currentAttachmentUrl,
    currentAttachmentIsPreviewable,
    isLoadingCurrentAttachment,
    errorFetchingCurrentAttachment,
    hasPrevAttachment,
    hasNextAttachment,
    goToPrevAttachment,
    goToNextAttachment,
    deleteAttachment,
    downloadAttachment,
    copyAttachmentLink,
  }
}
