import { type NowRelativeDateSchema } from '@motion/zod/client'

export type DateRangeOption =
  | {
      key: 'preset' | 'advanced'
      label: string
      value: NowRelativeDateSchema | null
    }
  | { key: 'select'; label: string; value?: never }
  | { key: 'separator'; label?: never; value?: never }

export const DATE_RANGE_OPTIONS: DateRangeOption[] = [
  {
    key: 'preset',
    label: 'Today',
    value: { unit: 'day', offset: 0, mode: 'strict' },
  },
  {
    key: 'preset',
    label: '{{StartOrEnd}} of this week',
    value: { unit: 'week', offset: 0, mode: 'edge' },
  },
  {
    key: 'preset',
    label: 'Yesterday',
    value: { unit: 'day', offset: -1, mode: 'strict' },
  },
  {
    key: 'preset',
    label: 'Tomorrow',
    value: { unit: 'day', offset: 1, mode: 'strict' },
  },
  { key: 'separator' },
  {
    key: 'preset',
    label: '7 days from now',
    value: { unit: 'day', offset: 7, mode: 'strict' },
  },
  {
    key: 'preset',
    label: '14 days from now',
    value: { unit: 'day', offset: 14, mode: 'strict' },
  },
  {
    key: 'preset',
    label: '{{StartOrEnd}} of next week',
    value: { unit: 'week', offset: 1, mode: 'edge' },
  },
  {
    key: 'preset',
    label: '{{StartOrEnd}} of this month',
    value: { unit: 'month', offset: 0, mode: 'edge' },
  },
  {
    key: 'preset',
    label: '{{StartOrEnd}} of next month',
    value: { unit: 'month', offset: 1, mode: 'edge' },
  },
  { key: 'separator' },
  {
    key: 'preset',
    label: '7 days ago',
    value: { unit: 'day', offset: -7, mode: 'strict' },
  },
  {
    key: 'preset',
    label: '14 days ago',
    value: { unit: 'day', offset: -14, mode: 'strict' },
  },
  {
    key: 'preset',
    label: '{{StartOrEnd}} of last week',
    value: { unit: 'week', offset: -1, mode: 'edge' },
  },
  {
    key: 'preset',
    label: '{{StartOrEnd}} of last month',
    value: { unit: 'month', offset: -1, mode: 'edge' },
  },
  { key: 'separator' },
  {
    key: 'advanced',
    label: 'Advanced',
    value: { unit: 'day', offset: 0, mode: 'strict' },
  },
  { key: 'select', label: 'Select date' },
  {
    key: 'preset',
    label: 'None',
    value: null,
  },
]
