import { templateStr } from '@motion/react-core/strings'
import { showToast } from '@motion/ui/base'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type FolderSchema, type WorkspaceSchema } from '@motion/zod/client'

import { ConnectedFolderBreadcrumbs } from '~/areas/folders/components'
import { useLookup } from '~/global/cache'
import { BreadcrumbsLabel } from '~/global/components/labels'
import { useI18N } from '~/global/contexts'
import { useCallback } from 'react'

import { useBulkUpdateSelection } from './use-bulk-update-selection'

import { useBulkOpsState } from '../../contexts'

type BulkMoveProjectsParams = {
  folderId: FolderSchema['id'] | null
  workspaceId: WorkspaceSchema['id']
}

export function useBulkMoveProjects() {
  const { bulkUpdateProjectSelection } = useBulkUpdateSelection()
  const { pluralize } = useI18N()
  const { selectedIds } = useBulkOpsState()
  const modalApi = useModalApi()
  const lookup = useLookup()

  return useCallback(
    async ({ folderId, workspaceId }: BulkMoveProjectsParams) => {
      try {
        const folder = folderId ? lookup('folders', folderId) : null

        if (folderId != null && folder == null) {
          throw new Error('Folder cannot be found')
        }

        const response = await modalApi.prompt('confirm', {
          analytics: {
            name: 'bulk-move-projects',
          },
          title: templateStr('Are you sure you want to move {{item}}?', {
            item: pluralize(
              selectedIds.length,
              'this project',
              'these {{count}} projects'
            ),
          }),
          description: (
            <div className='flex flex-col gap-2'>
              <p>
                {`${pluralize(
                  selectedIds.length,
                  'This project',
                  'These {{count}} projects'
                )} will be moved to:`}
              </p>
              {!folderId ? (
                <BreadcrumbsLabel
                  crumbs={[
                    {
                      type: 'workspace',
                      value: workspaceId,
                    },
                    {
                      type: 'folder',
                    },
                  ]}
                />
              ) : (
                <ConnectedFolderBreadcrumbs
                  leafNodeId={folderId}
                  abbreviated='middle'
                />
              )}
            </div>
          ),
          confirmButtonText: pluralize(
            selectedIds.length,
            'Move project',
            'Move {{count}} projects'
          ),
          closeButtonText: 'Cancel',
        })

        if (response === ModalDismissed) return undefined

        await bulkUpdateProjectSelection({
          type: 'bulk-project-location-update',
          folderId: folderId ?? null,
        })
      } catch (e) {
        Sentry.captureException(e, {
          extra: {
            selectedIds,
            folderId,
          },
        })

        showToast('error', 'Failed to move projects. Please try again.')
      }
    },
    [bulkUpdateProjectSelection, lookup, modalApi, pluralize, selectedIds]
  )
}
