import { createUseMutation } from '@motion/rpc'
import { API } from '@motion/rpc-definitions'

import { useCallback } from 'react'

const useCreatePortalLink = createUseMutation(
  API.subscriptions.createStripePortalLink
)

type useOpenPortalLinkParams = {
  stripeCustomerId?: string
  urlSuffix?: string
}

export function useOpenPortalLink({
  stripeCustomerId,
  urlSuffix,
}: useOpenPortalLinkParams) {
  const { mutateAsync: createPortalLink, isPending: isPortalLoading } =
    useCreatePortalLink()

  const openPortalLink = useCallback(async () => {
    if (!stripeCustomerId) return
    const portalLink = await createPortalLink({ stripeCustomerId })
    if (!portalLink) return
    const popup = window.open(
      urlSuffix ? portalLink + urlSuffix : portalLink,
      'noopener'
    )
    if (!popup) {
      window.alert(
        'The popup window has been blocked. Please open the popup in the URL bar.'
      )
    }
  }, [createPortalLink, stripeCustomerId, urlSuffix])

  return { openPortalLink, isPortalLoading }
}
