import { convertDateIntervalToDays } from '@motion/shared/flows'
import type { FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import {
  calculateAdjustedRelativeDurations,
  getDataFromFieldName,
} from '~/areas/flows/shared-form/utils'
import { type UseFormReturn } from 'react-hook-form'

export const handleStageExpectedDurationUpdate = (
  form: UseFormReturn<FlowTemplateFormFields>,
  name: `stages.${number}.duration`
) => {
  const { stageIndex } = getDataFromFieldName(name)
  if (stageIndex == null) return

  // If the value or unit changes, update the relative dates
  if (name.endsWith('value') || name.endsWith('unit')) {
    const stageDuration = form.getValues(`stages.${stageIndex}.duration`)

    if (
      stageDuration == null ||
      stageDuration.value == null ||
      Number.isNaN(stageDuration.value)
    ) {
      return
    }

    const stageDurationInDays = convertDateIntervalToDays(stageDuration)

    const tasks = form.getValues(`stages.${stageIndex}.tasks`)
    // If the stage expected duration changes, check if need to limit relative dates
    tasks.forEach(
      (
        {
          startRelativeInterval: { duration: startDuration },
          dueRelativeInterval: { duration: dueDuration },
        },
        i
      ) => {
        const adjustedDates = calculateAdjustedRelativeDurations(
          stageDurationInDays,
          { startDuration, dueDuration }
        )

        if (adjustedDates) {
          if (adjustedDates.start) {
            form.setValue(
              `stages.${stageIndex}.tasks.${i}.startRelativeInterval.duration`,
              {
                ...startDuration,
                ...adjustedDates.start,
              }
            )
          }

          if (adjustedDates.due) {
            form.setValue(
              `stages.${stageIndex}.tasks.${i}.dueRelativeInterval.duration`,
              {
                ...dueDuration,
                ...adjustedDates.due,
              }
            )
          }
        }
      }
    )
  }
}
