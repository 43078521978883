import { NameSearchButton } from '~/areas/project-management/components/filters'

import { useViewState } from '../view-state'

export const ConnectedNameSearchButton = () => {
  const [viewState, setViewState] = useViewState()

  return (
    <NameSearchButton
      value={viewState.search ?? ''}
      onSearch={(search) => setViewState((prev) => ({ ...prev, search }))}
      placeholder='Search'
    />
  )
}
