import { useDependantState } from '@motion/react-core/hooks'
import { Sentry } from '@motion/web-base/sentry'

import { useUserFavorites } from '~/areas/folders/hooks'
import { useGlobalSidebarContext } from '~/areas/sidebar/hooks'
import { sidebarItemHeight } from '~/areas/sidebar/treeviews/components'
import {
  flattenTreeItems,
  SortableTreeview,
  type WorkspacesTreeviewItem,
} from '~/areas/treeviews/components'
import { workspaceTreeviewRenderFilter } from '~/areas/treeviews/components/workspace-treeview/utils'
import { useUpdateItemInFolder } from '~/global/rpc/folders'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { ConnectedWorkspacesTreeviewItem } from '../workspaces-treeview/components'

type FavoritesTreeviewProps = {
  items: WorkspacesTreeviewItem[]
  disableDrag?: boolean
  onReorderItem?: () => void
}

export const FavoritesTreeview = ({
  items: ogItems,
  disableDrag = false,
  onReorderItem,
}: FavoritesTreeviewProps) => {
  const { setOpenedPanel } = useGlobalSidebarContext()
  const { mutateAsync: updateItemInFolder } = useUpdateItemInFolder()
  const favorites = useUserFavorites()

  const [items, setOptimisticItems] = useDependantState(
    () =>
      flattenTreeItems(ogItems, { injectPlaceholderInEmptyContainers: false }),
    [ogItems]
  )

  const onNavigate = useCallback(() => {
    setOpenedPanel(null)
  }, [setOpenedPanel])

  return (
    <SortableTreeview
      items={items}
      estimateItemSize={() => sidebarItemHeight}
      renderFilter={workspaceTreeviewRenderFilter}
      insertGapBetweenItems
      renderItem={({ item, projection, level }) => (
        <ConnectedWorkspacesTreeviewItem
          item={item}
          level={level}
          projection={projection}
          onNavigate={onNavigate}
          disableDrag={disableDrag || level > 0}
          isInFavorites
        />
      )}
      renderGhostItem={({ item, projection, level }) => (
        <ConnectedWorkspacesTreeviewItem
          item={item}
          level={level}
          projection={projection}
          onNavigate={onNavigate}
          disableDrag
          isGhost
          isInFavorites
        />
      )}
      calculateMinimumLevel={() => 0}
      calculateMaximumLevel={() => 0}
      onDragEnd={async ({ active, projection }) => {
        const activeId = active.id
        const { order, newItems, hasMoved } = projection

        if (!hasMoved) return

        if (!favorites.folder) {
          return void Sentry.captureException(
            new Error('Could not find user favorites folder'),
            {
              extra: {
                activeId,
                projection,
              },
              tags: {
                position: 'FavoritesTreeview',
              },
            }
          )
        }

        const prevItems = [...items]

        onReorderItem?.()
        setOptimisticItems(newItems)

        const args = {
          itemId: activeId.toString(),
          order: order ? order.toString() : undefined,
        }

        try {
          await updateItemInFolder(args)
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              position: 'FavoritesTreeview',
            },
            extra: {
              ...args,
            },
          })

          setOptimisticItems(prevItems)
          showErrorToast(e, 'There was a problem reordering your favorites')
        }
      }}
    />
  )
}
