import { AutoscheduleStarSolid } from '@motion/icons'
import { type TaskSchema } from '@motion/rpc-types'
import { Button, useShortcut } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { values } from '@motion/utils/object'
import { makeLog } from '@motion/web-base/logging'

import { FieldWrapper } from '~/areas/flows/flows-ai-modal/components'
import { showErrorToast } from '~/global/toasts'
import { type FormEventHandler, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { TaskRow } from './components/task-row'
import { CreateTaskWithAiForm } from './create-task-with-ai-form'
import { useCopyAllTasks } from './hooks/use-copy-all-tasks'
import { useSubmitCreateTask } from './hooks/use-submit-create-task'
import { type CreateTaskWithAiFormFields } from './types'

import { useNoteDialogContext } from '../../hooks'
import { BaseDialog, BaseDialogFooter, ProgressHeader } from '../components'
import { type NoteDialogProps } from '../types'

const log = makeLog('create-task-with-ai-dialog')
const SUBMIT_SHORTCUT = 'mod+enter' as const

export type CreateTaskWithAiDialogProps = NoteDialogProps<{
  createMultiple: boolean
}>

export function CreateTaskWithAiDialog({
  createMultiple,
  close,
}: CreateTaskWithAiDialogProps) {
  const { noteId, selectedText } = useNoteDialogContext()
  const [isLoading, setIsLoading] = useState(false)
  const [tasks, setTasks] = useState<TaskSchema[]>([])
  const copyAllTasks = useCopyAllTasks()

  const showForm = !isLoading && tasks.length === 0

  const taskStr =
    tasks.length > 1 || (tasks.length === 0 && createMultiple)
      ? 'tasks'
      : 'task'

  return (
    <CreateTaskWithAiForm noteId={noteId} selectedText={selectedText ?? ''}>
      <BaseDialog
        headerContent={
          showForm && (
            <>
              <AutoscheduleStarSolid />
              <span>Create {taskStr} from selection</span>
            </>
          )
        }
      >
        {showForm && (
          <InnerModal
            close={close}
            onLoadingChange={setIsLoading}
            createMultiple={createMultiple}
            setTasks={setTasks}
          />
        )}

        {isLoading && (
          <div>
            <ProgressHeader text={`Creating ${taskStr}...`} />
          </div>
        )}

        {!isLoading && tasks.length > 0 && (
          <div>
            <div className='mb-5'>
              <ProgressHeader
                loaded
                text={`Created ${taskStr}`}
                close={close}
              />
            </div>

            <div className='space-y-2'>
              {tasks.map((task, idx) => (
                <TaskRow
                  key={task.id}
                  task={task}
                  onDelete={() => {
                    if (tasks.length === 1) {
                      return void close()
                    }

                    setTasks((allTasks) => allTasks.toSpliced(idx, 1))
                  }}
                />
              ))}
            </div>

            {createMultiple && (
              <div className='mt-5'>
                <Button
                  sentiment='neutral'
                  variant='outlined'
                  onClick={() => void copyAllTasks(tasks)}
                >
                  Copy all
                </Button>
              </div>
            )}
          </div>
        )}
      </BaseDialog>
    </CreateTaskWithAiForm>
  )
}

type InnerModalProps = {
  close: () => void
  onLoadingChange: (loading: boolean) => void
  createMultiple: boolean
  setTasks: (tasks: TaskSchema[]) => void
}

function InnerModal({
  close,
  onLoadingChange,
  createMultiple,
  setTasks,
}: InnerModalProps) {
  const formRef = useRef<HTMLFormElement>(null)
  const form = useFormContext<CreateTaskWithAiFormFields>()
  const submitForm = useSubmitCreateTask(createMultiple)

  useShortcut(SUBMIT_SHORTCUT, () => {
    formRef.current?.requestSubmit()
  })

  const { errors } = form.formState

  if (errors.root?.submitError != null) {
    log.error('Error submitting create task from selection', { errors })
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    form.handleSubmit(
      async (data) => {
        try {
          onLoadingChange(true)

          const res = await submitForm(data)

          // @ts-expect-error this is temporary
          setTasks(Object.values(res?.models.tasks))
        } catch (e) {
          if (e instanceof Error) {
            form.setError('root.submitError', {
              message: e.message,
            })
          }
        } finally {
          onLoadingChange(false)
        }
      },
      (validationErrors) => {
        const firstError = values(validationErrors)[0]

        if (!firstError) {
          return
        }

        if (firstError.type === 'required') {
          showErrorToast('Please fill out the required fields.')
        }
      }
    )(e)
  }

  const taskStr = (single: string, plural: string) =>
    createMultiple ? plural : single

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <p className='text-semantic-neutral-text-disabled leading-5 mb-7'>
        Motion will automatically create {taskStr('a task', 'tasks')} from your
        selection. It will also figure out the correct duration, assignee,
        project and deadline and auto-schedule it on the assignee&apos;s
        calendar.
      </p>

      <div className='mb-3'>
        <p className='font-semibold mb-1'>
          Do you have any further instructions?
        </p>

        {/* <p> */}
        {/*  Type &quot;@&quot; to reference any documents, tasks, projects or */}
        {/*  people */}
        {/* </p> */}
      </div>

      <Controller
        name='userInstructions'
        control={form.control}
        render={({ field }) => (
          <FieldWrapper field='message'>
            <TextField
              value={field.value}
              onChange={field.onChange}
              multiline
              noResize
              autoFocus
              autoSize={{ minRows: 5 }}
              label='Instructions'
              labelHidden
              placeholder='Example: Create a design task from this and assign them to Gareth'
              sentiment={errors.userInstructions ? 'error' : 'default'}
            />
          </FieldWrapper>
        )}
      />

      <BaseDialogFooter>
        <Button
          sentiment='neutral'
          variant='muted'
          onClick={close}
          shortcut='esc'
        >
          Cancel
        </Button>

        <Button type='submit' sentiment='primary' shortcut={SUBMIT_SHORTCUT}>
          Create {taskStr('Task', 'Tasks')}
        </Button>
      </BaseDialogFooter>
    </form>
  )
}
