import {
  type FlowProject,
  isFlowProject,
  isLegacyFlowProject,
  type LegacyFlowProject,
} from '@motion/ui-logic/pm/project'
import { type ProjectSchema } from '@motion/zod/client'

import { useCallback } from 'react'

import { useIsFlowsM5cEnabled } from './use-flows-m5c-enabled'

export const useIsFlowProject = (): ((
  project: ProjectSchema | null | undefined
) => project is LegacyFlowProject | FlowProject) => {
  const isFlowsM5cEnabled = useIsFlowsM5cEnabled()

  return useCallback(
    (
      project: ProjectSchema | null | undefined
    ): project is LegacyFlowProject | FlowProject => {
      if (!isFlowsM5cEnabled) {
        return isLegacyFlowProject(project)
      }

      return isFlowProject(project)
    },
    [isFlowsM5cEnabled]
  )
}
