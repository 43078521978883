import { twMerge } from 'tailwind-merge'

type MentionComponentBaseProps = {
  badge: React.ReactNode
  label: string
}

export const MentionComponentBase = (props: MentionComponentBaseProps) => {
  const { badge, label } = props

  const firstSpaceIndex = label.indexOf(' ')

  // To avoid the icon getting cut off on its own when the mention is on the right-end
  // of the line, we group the icon together with the first word of the label and disable
  // wrapping between them.
  const labelPartOne =
    firstSpaceIndex >= 0 ? label.slice(0, firstSpaceIndex) + ' ' : label
  const labelPartTwo =
    firstSpaceIndex >= 0 ? label.slice(firstSpaceIndex + 1) : null

  return (
    <>
      <span className='whitespace-nowrap'>
        <span
          className={twMerge(
            'inline-flex items-center mr-1 align-text-bottom -top-px relative'
          )}
        >
          {badge}
        </span>
        {labelPartOne}
      </span>
      {labelPartTwo && <span>{labelPartTwo}</span>}
    </>
  )
}
