import { classed } from '@motion/theme'

import { StatefulSidebar } from '~/global/components/stateful-sidebar'

import { AgendaSidebarCalendar } from './components'

import { useAgendaSidebarState } from '../hooks'

export function RightSidebar() {
  const { initialWidth, minWidth, maxWidth } = getSidebarWidth()
  const { sidebarState } = useAgendaSidebarState()

  return (
    <StatefulSidebar
      sidebarKey='agenda'
      allowClose={false}
      hideCloseButton
      resizeDirection='left'
      initialWidth={sidebarState.width ?? initialWidth}
      minWidth={minWidth}
      maxWidth={maxWidth}
    >
      <SidebarContentWrapper id='agenda-right-sidebar'>
        <AgendaSidebarCalendar />
      </SidebarContentWrapper>
    </StatefulSidebar>
  )
}

const LEFT_SIDE_BAR_WIDTH = 283
const INITIAL_WIDTH_PERCENTAGE = 0.29
const MIN_WIDTH_PERCENTAGE = 0.2
const MAX_WIDTH_PERCENTAGE = 0.5
const ABSOLUTE_MIN_WIDTH = 220

function getSidebarWidth() {
  const columnWidth = window.innerWidth - LEFT_SIDE_BAR_WIDTH
  const initialWidth = columnWidth * INITIAL_WIDTH_PERCENTAGE
  const minWidth = Math.max(
    columnWidth * MIN_WIDTH_PERCENTAGE,
    ABSOLUTE_MIN_WIDTH
  )
  const maxWidth = columnWidth * MAX_WIDTH_PERCENTAGE

  return { initialWidth, minWidth, maxWidth }
}

const SidebarContentWrapper = classed(
  'div',
  `inline-flex flex-col items-start flex-shrink-0 h-full
  bg-semantic-neutral-surface-bg-subtle
  border-l border-semantic-neutral-border-default`
)
