import { PlusSolid } from '@motion/icons'
import { MAX_NUMBER_PWT_VARIABLES } from '@motion/shared/flows'
import { Button, Tooltip } from '@motion/ui/base'
import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useFieldArray, type UseFormReturn } from 'react-hook-form'

import { RoleFieldWrapper } from './role-field'

import { createNewRole, getNewVariableName } from '../utils'

export type RoleFieldsListProps = {
  roles: FlowTemplateFormFields['roles']
  form: UseFormReturn<FlowTemplateFormFields>
  deleteAnalyticsName: string
}

export const RoleFieldsList = ({
  roles,
  form,
  deleteAnalyticsName,
}: RoleFieldsListProps) => {
  const modalApi = useModalApi()

  const { control } = form
  const { append, remove } = useFieldArray({
    control,
    name: 'roles',
  })

  const onRemove = async (index: number) => {
    const res = await modalApi.prompt('confirm', {
      analytics: {
        name: deleteAnalyticsName,
      },
      title: 'Are you sure you want to delete this role?',
      description:
        'All tasks assigned to this role will be marked as Unassigned.',
      closeButtonText: 'Cancel',
      confirmButtonText: 'Delete role',
    })
    if (res === ModalDismissed) return
    remove(index)
  }

  function addNewRole() {
    const newRole = createNewRole({
      name: getNewVariableName(roles, 'person'),
    })

    append(newRole)
  }

  return (
    <>
      <div className='flex flex-col gap-1.5 pl-1 pb-2'>
        {roles.map((field, i) => (
          <RoleFieldWrapper
            key={`${field.name}-${field.color}-${field.id}-${i}`}
            index={i}
            onRemove={() => onRemove(i)}
          />
        ))}
      </div>

      <Tooltip
        content={
          roles.length >= MAX_NUMBER_PWT_VARIABLES
            ? `You can only add up to ${MAX_NUMBER_PWT_VARIABLES} roles`
            : undefined
        }
      >
        <Button
          variant='muted'
          sentiment='neutral'
          alignment='left'
          size='small'
          disabled={roles.length >= MAX_NUMBER_PWT_VARIABLES}
          onClick={() => {
            addNewRole()
          }}
        >
          <PlusSolid />
          Add role
        </Button>
      </Tooltip>
    </>
  )
}
