import {
  ArrowDownSolid,
  ArrowRightSolid,
  ArrowUpSolid,
  DuplicateOutline,
  ExternalLinkSolid,
  LinkSolid,
  ShareSolid,
  StarCrossOutOutline,
  StarOutline,
  TrashOutline,
} from '@motion/icons'
import { type NoteSchema } from '@motion/rpc-types'
import { ActionList } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type AnalyticEvents } from '@motion/web-base/analytics/events'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { useCopyHtmlLinkToClipboard } from '~/global/hooks'
import { useNoteUrl } from '~/global/navigation'
import { useNoteById } from '~/global/rpc/v2'

import { NoteColorPicker } from './components'
import { MoveNoteToPopover } from './components/note-move-location-popover'
import { useCopyNote, useDeleteNote } from './hooks'

import { useDesktopTabs } from '../desktop/hooks'
import { useUserFavoritedItem } from '../folders/hooks'

export type NoteActionsListProps = {
  location: AnalyticEvents['CLICKED_SHARE_ITEM']['location']
  noteId: NoteSchema['id']
  hideColorPicker?: boolean
  moveActionAllowed?: {
    up: boolean
    down: boolean
  }
  onMoveItem?: (moveBy: -1 | 1) => void
  close?: () => void
}

export const NoteActionsList = (props: NoteActionsListProps) => {
  const {
    noteId,
    moveActionAllowed,
    hideColorPicker = false,
    onMoveItem,
    close,
    location,
  } = props

  const modalApi = useModalApi()
  const getNoteUrl = useNoteUrl()
  const copyLink = useCopyHtmlLinkToClipboard()
  const deleteNote = useDeleteNote()
  const duplicateNote = useCopyNote()
  const hasSharedSpace = useHasTreatment('shared-space')
  const { isFavorited, toggleFavorite } = useUserFavoritedItem('NOTE', noteId)
  const { hasTabs, openTab, canOpenTab, tabTooltip } = useDesktopTabs()

  const { data: note } = useNoteById({ id: noteId })

  const handleCopyLink = () => {
    const { noteUrl } = getNoteUrl({ noteId })
    copyLink(noteUrl, note?.title ?? 'Motion Doc')
  }

  const handleToggleFavorite = () => {
    recordAnalyticsEvent('TOGGLE_FAVORITE', {
      itemType: 'NOTE',
      location: 'CONTEXT_MENU',
      state: isFavorited ? 'REMOVED' : 'ADDED',
    })

    void toggleFavorite()
  }

  const handleShare = () => {
    recordAnalyticsEvent('CLICKED_SHARE_ITEM', {
      itemType: 'NOTE',
      location,
    })

    modalApi.open('share-item', {
      itemType: 'NOTE',
      itemId: noteId,
    })
  }

  const handleOpenInNewTab = () => {
    openTab(getNoteUrl({ noteId }).noteUrl, 'NOTE')
  }

  const handleDuplicate = () => {
    duplicateNote(noteId)
  }

  return (
    <ActionList
      onActionAnyItem={close}
      sections={[
        !hideColorPicker && {
          items: [
            {
              content: (
                <NoteColorPicker
                  noteId={noteId}
                  selected={note?.color ?? 'gray'}
                />
              ),
              isNested: true,
            },
          ],
        },
        {
          items: [
            {
              prefix: <LinkSolid />,
              content: 'Copy link',
              onAction: handleCopyLink,
            },
            {
              prefix: isFavorited ? <StarCrossOutOutline /> : <StarOutline />,
              content: isFavorited
                ? 'Remove from Favorites'
                : 'Add to Favorites',
              onAction: handleToggleFavorite,
            },
            hasSharedSpace && {
              prefix: <ShareSolid />,
              content: 'Share',
              onAction: handleShare,
            },
            hasTabs && {
              prefix: <ExternalLinkSolid />,
              content: 'Open in new tab',
              onAction: handleOpenInNewTab,
              disabled: !canOpenTab,
              tooltip: tabTooltip,
            },
            {
              prefix: <DuplicateOutline />,
              content: 'Duplicate',
              onAction: handleDuplicate,
            },
          ],
        },
        moveActionAllowed && {
          items: [
            {
              renderPopover: ({
                close: nestedClose,
              }: {
                close: () => void
              }) => <MoveNoteToPopover noteId={noteId} onClose={nestedClose} />,
              prefix: <ArrowRightSolid />,
              content: 'Move to',
            },
            onMoveItem != null && {
              prefix: <ArrowUpSolid />,
              content: 'Move up',
              disabled: !moveActionAllowed.up,
              onAction: () => onMoveItem(-1),
            },
            onMoveItem != null && {
              prefix: <ArrowDownSolid />,
              content: 'Move down',
              disabled: !moveActionAllowed.down,
              onAction: () => onMoveItem(1),
            },
          ],
        },
        {
          items: [
            {
              prefix: <TrashOutline />,
              content: 'Delete',
              destructive: true,
              onAction: () => {
                deleteNote(noteId)
              },
            },
          ],
        },
      ].filter(Boolean)}
    />
  )
}
