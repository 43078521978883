import { FunnelOutline } from '@motion/icons'
import { TextField } from '@motion/ui/forms'

import { forwardRef } from 'react'

type SearchInputProps = {
  value: string
  onChange?: (value: string) => void
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
}

export const SearchInput = forwardRef<HTMLLabelElement, SearchInputProps>(
  function SearchInput({ value, onKeyDown, onChange }: SearchInputProps, ref) {
    return (
      <TextField
        ref={ref}
        value={value}
        placeholder='Filter sidebar'
        prefix={
          <FunnelOutline className='!text-semantic-neutral-icon-subtle' />
        }
        onChange={onChange}
        onKeyDown={onKeyDown}
        size='normal'
        rounded='full'
        sentiment='active'
        fullWidth
        autoFocus
        raised
      />
    )
  }
)
