import { StackSolid } from '@motion/icons'
import {
  Button,
  PopoverButton,
  PopoverTrigger,
  SearchableList,
} from '@motion/ui/base'
import { truncateAtSpace } from '@motion/ui-logic'
import { type GROUP_BY_DATE_OPTIONS } from '@motion/zod/client'

import {
  type GroupableField,
  isViewStateGroupByDateKey,
  noneField,
} from '~/areas/project-management/pages/pm-v3/grouping'
import { DateGroupOptionsSelect } from '~/areas/project-management/pages/pm-v3/header/group-by/date-group-options-select'
import { GroupByPopoverContext } from '~/areas/project-management/pages/pm-v3/header/group-by/group-by-popover-context'
import { type ViewStateGroupByField } from '~/areas/project-management/pages/pm-v3/view-state'
import { useCallback, useMemo } from 'react'

import { useNotetakerViewState } from '../../state'
import { MeetingInsightsGroupableFields } from '../types'

const groupableFields = [noneField, ...MeetingInsightsGroupableFields]

export function ConnectedGroupingButton() {
  const [viewState, setViewState] = useNotetakerViewState()

  const handleOnChange = (value: FieldWithNone) => {
    setViewState((prev) => ({
      ...prev,
      groupBy: {
        ...prev.groupBy,
        fields: isNoneField(value)
          ? []
          : [
              {
                key: value.id,
                by: value.by,
              },
            ],
      },
    }))
  }

  const value = useMemo(() => {
    if (viewState.groupBy.fields.length === 0) {
      return noneField
    }

    const groupableField = groupableFields.find(
      (field) => field.id === viewState.groupBy.fields[0].key
    )

    if (!groupableField) {
      return noneField
    }

    return {
      ...groupableField,
      by: viewState.groupBy.fields[0].by,
    }
  }, [viewState.groupBy.fields])

  return <GroupByButton value={value} onChange={handleOnChange} />
}

type SelectedGroupByField = GroupableField & Pick<ViewStateGroupByField, 'by'>
export type FieldWithNone = SelectedGroupByField | typeof noneField

type GroupByButtonProps = {
  value: FieldWithNone
  onChange: (value: FieldWithNone) => void
}

function GroupByButton(props: GroupByButtonProps) {
  const isActive = !isNoneField(props.value)

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <GroupByButtonPopover {...props} close={close} />
      )}
    >
      <Button
        variant='outlined'
        sentiment={isActive ? 'primary' : 'neutral'}
        size='small'
      >
        <StackSolid />
        <div className='font-medium whitespace-nowrap max-w-xs'>
          {isActive ? `Group by: ${props.value.label}` : `Group by: None`}
        </div>
      </Button>
    </PopoverTrigger>
  )
}

type GroupByButtonPopoverProps = {
  value: FieldWithNone
  onChange: (value: FieldWithNone) => void
  close: () => void
}

function GroupByButtonPopover({ value, onChange }: GroupByButtonPopoverProps) {
  const setFieldOption = useCallback(
    (_: string, by: GROUP_BY_DATE_OPTIONS) => {
      onChange({
        ...value,
        by,
      })
    },
    [onChange, value]
  )

  const ctx = useMemo(
    () => ({
      fields: [value],
      setFieldOption,
    }),
    [setFieldOption, value]
  )

  return (
    <GroupByPopoverContext.Provider value={ctx}>
      <div className='bg-modal-bg text-semantic-neutral-text-default w-[320px] flex flex-col p-3 gap-3'>
        <div className='flex justify-between items-center'>
          <span className='text-sm'>Groups</span>
          <Button
            onClick={() => onChange(noneField)}
            sentiment='neutral'
            variant='muted'
            size='small'
          >
            Reset
          </Button>
        </div>
        <div className='flex flex-col gap-2'>
          <div className='flex gap-1 justify-between'>
            <PopoverTrigger
              placement='bottom-start'
              renderPopover={({ close }) => (
                <SearchableList
                  items={groupableFields}
                  computeKey={(item) => item.id}
                  computeSelected={(item) => item.id === value.id}
                  computeSearchValue={(item) => item.label}
                  onSelect={(item) => {
                    onChange(item)
                    close()
                  }}
                  renderItem={(item) => (
                    <div className='truncate max-w-[200px]'>
                      {truncateAtSpace(item.label)}
                    </div>
                  )}
                />
              )}
            >
              <PopoverButton>
                <div className='truncate max-w-[200px]'>
                  {truncateAtSpace(value.label)}
                </div>
              </PopoverButton>
            </PopoverTrigger>
            {isViewStateGroupByDateKey(value.id) && (
              <DateGroupOptionsSelect id={value.id} />
            )}
          </div>
        </div>
      </div>
    </GroupByPopoverContext.Provider>
  )
}

function isNoneField(field: FieldWithNone): field is typeof noneField {
  return field.id === noneField.id
}
