import { type MotionRoute } from '~/routing/api'

import { CreateEditWorkflowPage, RunsPage } from './pages'
import { WorkflowsShell } from './shell'
import { WorkflowsPage } from './workflows-page'

declare module '~/routing/hooks/navigate-by-id-params' {
  interface NavigateByIdParams {
    'ai-workflows': void
    'ai-workflows-create': void
    'ai-workflows-edit': { workflowId: string }
    'ai-workflows-runs': void
    'ai-workflows-runs-view': { runId: string }
  }
}

declare module '@motion/web-common/flags/definitions' {
  interface AmplitudeExperiments {
    'ai-workflows': FeatureFlag
  }
}

export const workflowsRoutes: MotionRoute[] = [
  {
    featureFlag: 'ai-workflows',
    path: 'workflows',
    children: [
      {
        element: <WorkflowsShell />,
        children: [
          {
            id: 'ai-workflows',
            index: true,
            element: <WorkflowsPage />,
          },
          {
            id: 'ai-workflows-runs',
            path: 'runs',
            element: <RunsPage />,
            children: [
              {
                id: 'ai-workflows-runs-view',
                path: ':runId',
                element: <div>View run</div>,
              },
            ],
          },
        ],
      },
      {
        id: 'ai-workflows-create',
        path: 'create',
        element: <CreateEditWorkflowPage />,
      },
      {
        id: 'ai-workflows-edit',
        path: 'edit/:workflowId',
        element: <CreateEditWorkflowPage />,
      },
    ],
  },
]
