import { QuestionMarkCircleSolid } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'

import { ControlledAssigneeField } from './assignee-field'
import { ControlledAttachmentsField } from './attachments-field'
import { ControlledColorField } from './color-field'
import { ControlledDefinitionDescriptionField } from './definition-description-field'
import { ControlledDescriptionField } from './description-field'
import { ControlledFolderField } from './folder-field'
import { ControlledLabelsField } from './labels-field'
import { ControlledNameField } from './name-field'
import { ControlledPriorityField } from './priority-field'
import { ControlledRoleFields } from './role-fields'
import { ControlledTextVariableFields } from './text-variables'

import {
  Sidebar,
  SidebarSection,
  SidebarSectionFields,
  SidebarTitle,
} from '../components'

export function TemplateModalSidebar() {
  return (
    <Sidebar largeGap>
      <SidebarSection>
        <SidebarTitle>Template info</SidebarTitle>
        <SidebarSectionFields>
          <ControlledNameField />
          <ControlledDefinitionDescriptionField />
        </SidebarSectionFields>
      </SidebarSection>

      <SidebarSection>
        <SidebarTitle>Project defaults</SidebarTitle>
        <SidebarSectionFields>
          <ControlledColorField />
          <ControlledFolderField />
          <ControlledAssigneeField />
          <ControlledLabelsField />
          <ControlledPriorityField />
          <ControlledDescriptionField />
          <ControlledAttachmentsField />
        </SidebarSectionFields>
      </SidebarSection>

      <SidebarSection>
        <SidebarTitle>
          Roles
          <Tooltip content='Roles are placeholders that can be assigned to tasks inside project workflow templates. When using the template, you’ll be able to choose an assignee for each role.'>
            <QuestionMarkCircleSolid className='!w-3 !h-3 !text-semantic-neutral-icon-subtle shrink-0' />
          </Tooltip>
        </SidebarTitle>

        <SidebarSectionFields>
          <ControlledRoleFields />
        </SidebarSectionFields>
      </SidebarSection>

      <SidebarSection>
        <SidebarTitle>
          Text variables
          <Tooltip content='Text variables enable you to include specific information into task names and descriptions.'>
            <QuestionMarkCircleSolid className='!w-3 !h-3 !text-semantic-neutral-icon-subtle shrink-0' />
          </Tooltip>
        </SidebarTitle>

        <SidebarSectionFields>
          <ControlledTextVariableFields />
        </SidebarSectionFields>
      </SidebarSection>
    </Sidebar>
  )
}
