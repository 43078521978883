import { CheckOutline, FilledChevronDownSolid, LinkSolid } from '@motion/icons'
import {
  NOTE_ROLE,
  NOTE_ROLE_DESCRIPTIONS,
  NOTE_ROLE_LABELS,
} from '@motion/shared/notes'
import { ActionList, Button, PopoverTrigger, Tooltip } from '@motion/ui/base'

type BaseNoteRoleButtonProps = {
  selectedRole: NOTE_ROLE
  isLinked?: boolean
  linkedTooltip?: string
  disabled?: boolean
}

type NoteRoleButtonWithoutRemove = BaseNoteRoleButtonProps & {
  onSelectRole: (role: NOTE_ROLE) => void
  allowRemove?: false
}

type NoteRoleButtonWithRemove = BaseNoteRoleButtonProps & {
  onSelectRole: (role: NOTE_ROLE | null) => void
  allowRemove: true
}

type NoteRoleButtonProps =
  | NoteRoleButtonWithRemove
  | NoteRoleButtonWithoutRemove

export const NoteRoleButton = ({
  selectedRole,
  onSelectRole,
  allowRemove = false,
  isLinked,
  linkedTooltip,
  disabled = false,
}: NoteRoleButtonProps) => {
  const selectedRoleLabel = NOTE_ROLE_LABELS[selectedRole]

  if (disabled) {
    return <span className='text-sm'>{selectedRoleLabel}</span>
  }

  return (
    <div className='inline-flex items-center gap-1'>
      {isLinked && (
        <Tooltip content={linkedTooltip}>
          <LinkSolid className='size-4 text-semantic-neutral-icon-default' />
        </Tooltip>
      )}

      <PopoverTrigger
        placement='bottom-end'
        renderPopover={({ close }) => (
          <ActionList
            onActionAnyItem={close}
            sections={[
              {
                items:
                  // The 'comment' role is currently broken because comment highlighting
                  // needs to be written on the doc and requires edit ACL.
                  // Tracked at: https://dev-app.usemotion.com/web/pm/workspaces/u7_D-Iy8jdmn3IAvEkdfR/views/default?task=g04tHUjWKp6GjVtOi6qMy
                  NOTE_ROLE.filter((role) => role !== 'comment').map(
                    (role) => ({
                      content: NOTE_ROLE_LABELS[role],
                      description: NOTE_ROLE_DESCRIPTIONS[role],
                      suffix: role === selectedRole && <CheckOutline />,
                      onAction: () => onSelectRole(role),
                    })
                  ),
              },
              allowRemove && {
                items: [
                  {
                    content: 'Remove',
                    onAction: () =>
                      (
                        onSelectRole as NoteRoleButtonWithRemove['onSelectRole']
                      )(null),
                  },
                ],
              },
            ]}
          />
        )}
      >
        <Button
          variant='muted'
          size='xsmall'
          sentiment='neutral'
          disabled={disabled}
        >
          <span className='text-sm contents text-semantic-neutral-text-default font-normal'>
            {selectedRoleLabel}
            <FilledChevronDownSolid className='[&_[data-icon]]:fill-current !size-4' />
          </span>
        </Button>
      </PopoverTrigger>
    </div>
  )
}
