import { MagicWandSolid } from '@motion/icons'

import { FlowCard } from '~/areas/flows'
import { TieredPricingAiFlowButton } from '~/areas/tiered-pricing/components'
import { useParams } from 'react-router-dom'

export function LegacySettingsFlowsAICard() {
  const { workspaceId = '' } = useParams<{ workspaceId: string }>()

  return (
    <div className='py-5 px-4 flex gap-2 items-center justify-between border border-semantic-purple-border-default rounded-lg shadow-[0_0_30px_-20px_rgba(0,0,0,0.3)] shadow-semantic-pink-border-active'>
      <div className='flex flex-col gap-2'>
        <h4 className='text-sm font-semibold text-semantic-neutral-text-default'>
          Upload your SOP or Project Guidelines
        </h4>
        <p className='text-sm text-semantic-neutral-text-default'>
          Motion will automatically create the stages and tasks required for the
          project.
        </p>
      </div>

      <TieredPricingAiFlowButton workspaceId={workspaceId} />
    </div>
  )
}

export function SettingsFlowsAICard() {
  const { workspaceId = '' } = useParams<{ workspaceId: string }>()

  return (
    <FlowCard createWithAI>
      <div className='p-1 flex items-center gap-2 size-5 rounded bg-palette-pink-bg-light text-palette-pink-highlight'>
        <MagicWandSolid />
      </div>

      <div className='flex flex-col gap-6'>
        <h4 className='text-sm font-semibold leading-7 text-semantic-neutral-text-default'>
          Upload your SOP or Project Guidelines
        </h4>
        <p className='text-sm text-semantic-neutral-text-default leading-5'>
          Motion will automatically create the stages and tasks required for the
          project.
        </p>
      </div>

      <TieredPricingAiFlowButton workspaceId={workspaceId} />
    </FlowCard>
  )
}
