import { type ProjectSchema } from '@motion/rpc-types'
import { isResolvedStatus } from '@motion/shared/common'
import { getStageVariant } from '@motion/ui-logic/pm/project'

import { type Row } from '@tanstack/react-table'
import { type VirtualItem } from '@tanstack/react-virtual'
import { useWorkspaceFns } from '~/global/hooks'
import { type NormalTaskWithRelations } from '~/global/proxies'
import { useCallback } from 'react'

import { type SidebarTasksGroup } from '../../hooks'
import { type SortBy } from '../../utils'

export const HEADER_GROUP_HEIGHT = 36
export const SPACER_HEIGHT = 16

export const TASK_LINE_HEIGHT = 30
export const INLINE_ADD_TASK_HEIGHT = 142

export const INLINE_ADD_TASK_BUTTON_HEIGHT = 24

export const INLINE_ADD_STAGE_BUTTON_HEIGHT = 20

export type ShouldShowAddButtonsArgs = {
  index: number
  virtualItems: VirtualItem[]
  rowsToUse: Row<SidebarTasksGroup>[]
  row: Row<SidebarTasksGroup>
  sort: keyof typeof SortBy
  enableInlineAdd: boolean
  hasFlowsM5c: boolean
}

export type ShouldShowAddButtonsFn = (
  args: ShouldShowAddButtonsArgs
) => ShouldShowAddButtonsResult

export type ShouldShowAddButtonsResult = {
  showAddTaskButton: boolean
  parentRow: Row<any> | undefined
  showAddStageButton: boolean
}

export function useShouldShowAddButtonsFn(
  project: ProjectSchema | null
): ShouldShowAddButtonsFn {
  const { getStatusById } = useWorkspaceFns()

  return useCallback(
    ({
      index,
      virtualItems,
      rowsToUse,
      row,
      sort,
      enableInlineAdd,
      hasFlowsM5c,
    }) => {
      if (sort !== 'STAGES' || !enableInlineAdd || project == null) {
        return {
          showAddTaskButton: false,
          parentRow: undefined,
          showAddStageButton: false,
        }
      }

      const nextVirtual = virtualItems[index + 1]
      const nextRow = nextVirtual ? rowsToUse[nextVirtual.index] : null
      const parentRow = row.getParentRow()

      let showAddTaskButton = Boolean(
        parentRow &&
          ((nextRow && !isTaskRow(nextRow)) ||
            index === virtualItems.length - 1)
      )

      let showAddStageButton = false
      if (hasFlowsM5c) {
        const projectStatus = getStatusById(project.statusId)

        const isRightRowType =
          (!isTaskRow(row) && !row.getIsExpanded()) ||
          (!isTaskRow(row) && !nextRow) ||
          (isTaskRow(row) && nextRow && !isTaskRow(nextRow)) ||
          (isTaskRow(row) && index === virtualItems.length - 1)

        showAddStageButton =
          isRightRowType &&
          !isAddingStageDisabled(row, parentRow, project) &&
          !isResolvedStatus(projectStatus)

        showAddTaskButton =
          showAddTaskButton || (!isTaskRow(row) && row.subRows.length === 0)
      }

      return {
        showAddTaskButton,
        showAddStageButton,
        parentRow,
      }
    },
    [getStatusById, project]
  )
}

export const isTaskRow = (row: Row<any>): row is Row<NormalTaskWithRelations> =>
  row.getParentRow() != null

function isAddingStageDisabled(
  row: Row<any>,
  parentRow: Row<any> | undefined,
  project: ProjectSchema
) {
  const stageDefinitionId =
    isTaskRow(row) && parentRow ? parentRow.original.key : row.original.key

  const activeStageDefinitionIndex = project.stages.findIndex(
    (stage) => stage.stageDefinitionId === project.activeStageDefinitionId
  )

  const currentIndex = project.stages.findIndex(
    (stage) => stage.stageDefinitionId === stageDefinitionId
  )

  if (activeStageDefinitionIndex > currentIndex) {
    return true
  }

  const currentStage = project.stages[currentIndex]
  const nextStage = project.stages[currentIndex + 1]

  if (currentStage == null || nextStage == null) {
    return false
  }

  const currentStageVariant = getStageVariant(currentStage)
  const nextStageVariant = getStageVariant(nextStage)

  return (
    (currentStageVariant === 'completed' ||
      currentStageVariant === 'skipped') &&
    (nextStageVariant === 'completed' || nextStageVariant === 'skipped')
  )
}
