import { AttachmentOutline } from '@motion/icons'
import { type FlowTemplateFormTask } from '@motion/ui-logic/pm/project'

import {
  BaseTaskCard,
  TaskCardFooter,
  TaskCardHeader,
  TaskTemplateDebugInfo,
} from '~/areas/flows/components'
import { useTaskDefinitionDirtyState } from '~/areas/flows/hooks'
import { useFlowTemplateForm } from '~/areas/flows/shared-form'

import { ConnectedStageTaskCardFields } from './connected-stage-task-card-fields'
import { EditTaskDefinitionButton } from './edit-task-definition-button'
import { StageEventDetailsSection } from './stage-event-details-section'
import { StageTaskOptionMenu } from './stage-task-option-menu'

import { useStageEventField, useStageTaskField } from '../fields'

export type ConnectedStageTaskCardProps = {
  stageTasksPath: `stages.${number}.tasks`
  taskBasePath: `stages.${number}.tasks.${number}`
  task: FlowTemplateFormTask
  onRemove?: () => void
}

export const ConnectedStageTaskCard = ({
  stageTasksPath,
  taskBasePath,
  task,
  onRemove,
}: ConnectedStageTaskCardProps) => {
  const {
    form: { watch },
  } = useFlowTemplateForm()

  const [stageCardType, onChangeCardStageType] =
    useStageEventField(taskBasePath)
  const [autoScheduled, onChangeAutoScheduled] = useStageTaskField(
    `${taskBasePath}.isAutoScheduled`
  )
  const assigneeId =
    watch(`${taskBasePath}.assigneeUserId`) ??
    watch(`${taskBasePath}.assigneeVariableKey`)

  const taskId = watch(`${taskBasePath}.id`)
  const isTaskDirty = useTaskDefinitionDirtyState(taskId)

  const attachmentsCount = task.uploadedFileIds?.length ?? 0

  return (
    <BaseTaskCard
      isDirty={isTaskDirty}
      header={
        <TaskCardHeader
          value={stageCardType}
          onChange={onChangeCardStageType}
          suffix={<StageTaskOptionMenu task={task} onRemove={onRemove} />}
          isTaskDirty={isTaskDirty}
        />
      }
      fields={
        <ConnectedStageTaskCardFields
          taskBasePath={taskBasePath}
          stageTasksPath={stageTasksPath}
        />
      }
      footer={
        <TaskCardFooter
          autoScheduled={autoScheduled}
          onAutoScheduledChange={(e) => onChangeAutoScheduled(e.target.checked)}
          assigneeId={assigneeId}
          renderEditTaskDefinitionButton={() => (
            <>
              {attachmentsCount > 0 && (
                <EditTaskDefinitionButton
                  icon={<AttachmentOutline />}
                  text={attachmentsCount.toString()}
                  taskBasePath={taskBasePath}
                  variant='muted'
                />
              )}
              <EditTaskDefinitionButton taskBasePath={taskBasePath} />
            </>
          )}
        />
      }
    >
      {!__IS_PROD__ && <TaskTemplateDebugInfo task={task} />}
      {stageCardType === 'event' && (
        <StageEventDetailsSection taskBasePath={taskBasePath} />
      )}
    </BaseTaskCard>
  )
}
