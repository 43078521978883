import { type Tier } from '@motion/ui-logic/tiered-pricing'
import { type Feature } from '@motion/web-billing'

// TODO: Move to ui-logic
export const getNextTier = (
  currentTier: Tier,
  feature?: Feature
): Exclude<Tier, 'BASIC'> | undefined => {
  let nextTier: Tier | undefined = undefined

  if (feature === 'autoScheduleWindow') {
    switch (currentTier) {
      case 'BASIC':
        nextTier = 'PRO'
        break
      case 'PRO':
      case 'PROPLUS':
      case 'PROAI':
      case 'ENTERPRISE':
        nextTier = undefined
        break
    }
  } else {
    switch (currentTier) {
      case 'BASIC':
        nextTier = 'PRO'
        break
      case 'PRO':
      case 'PROPLUS':
      case 'PROAI':
        nextTier = 'ENTERPRISE'
        break
      case 'ENTERPRISE':
        nextTier = undefined
        break
    }
  }

  return nextTier
}
