import { templateStr } from '@motion/react-core/strings'

import { useAutoScheduleRange } from '~/areas/project-management/hooks/experiments'

import { formatAutoScheduleDuration } from '../../utils'

export function UnfitTaskTooltipHeader() {
  const autoScheduleDuration = useAutoScheduleRange()

  return (
    <span className='font-bold text-tooltip-text'>
      {templateStr("This task can't be scheduled in the next {{duration}}.", {
        duration: formatAutoScheduleDuration(autoScheduleDuration),
      })}
    </span>
  )
}
