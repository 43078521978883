import { type SvgIconProps } from '@motion/icons'
import { ProjectColoredIcon } from '@motion/ui/project'

import { type ComponentRef, forwardRef } from 'react'

import { useProject, useWorkspaceStatusById } from '../hooks'

type ProjectIconProps = SvgIconProps & {
  projectId: string | null
  withNotes?: boolean
}

export const ConnectedProjectIcon = forwardRef<
  ComponentRef<typeof ProjectColoredIcon>,
  ProjectIconProps
>(function ProjectIcon({ projectId, ...rest }: ProjectIconProps, ref) {
  const project = useProject(projectId)
  const status = useWorkspaceStatusById(project?.statusId)

  return (
    <ProjectColoredIcon
      {...rest}
      ref={ref}
      color={project?.color}
      variant={status.type ?? 'DEFAULT'}
      noProject={project == null}
    />
  )
})
