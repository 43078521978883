import { Tooltip } from '@motion/ui/base'
import { formatTime, formatTimeRange } from '@motion/ui-logic'

import { CellText } from '~/areas/project-management/pages/pm-v3/tree-list/cells/components'

type TimeRangeCellProps = {
  startTime: string
  endTime: string
}

export const TimeRangeCell = ({ startTime, endTime }: TimeRangeCellProps) => {
  const formatted = formatTimeRange(startTime, endTime)

  const tooltip = `${formatTime(startTime)} - ${formatTime(endTime)}`

  return (
    <CellText>
      <Tooltip content={tooltip}>
        <span className='truncate'>{formatted}</span>
      </Tooltip>
    </CellText>
  )
}
