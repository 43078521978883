import { EmojiSadSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { Button } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import {
  useTeamSubscription,
  useUtilizedSubscription,
} from '~/global/rpc/subscriptions'
import { DateTime } from 'luxon'
import { useMemo } from 'react'

import { formatAndFilterWorkspaceSummary } from '../../Account/utils'
import { CancelModalListItem } from '../components/cancel-modal-list-item'
import { ProductSpecialistSection } from '../components/product-specialist-section'
import { type CancelScreenProps } from '../components/types'
import { useCancelSubscriptionAndUpdate } from '../rpc-hooks'

const BASE_LIST_ITEMS = [
  'Task management',
  'Project management',
  'Intelligent schedule builder',
  'Calendar management',
  'Meeting booking pages',
  'Meeting booking templates',
  'Free upgrades and new features',
]

export const FinalInterception = ({
  setNextScreen,
  onCloseModal,
  workspaceSummary,
}: CancelScreenProps) => {
  const utilizedSubscription = useUtilizedSubscription()
  const teamSubscription = useTeamSubscription()

  const listItems = useMemo(() => {
    const summaryItems = formatAndFilterWorkspaceSummary(workspaceSummary)
    return summaryItems.concat(BASE_LIST_ITEMS)
  }, [workspaceSummary])

  const { isCancelingSubscription, doCancelSubscription } =
    useCancelSubscriptionAndUpdate()

  const handleCancelSubscription = async () => {
    // Once we fix https://dev-app.usemotion.com/web/pm/workspaces/u7_D-Iy8jdmn3IAvEkdfR?task=JAvJwTysxcH7Rg60EEGyz we can remove the onCloseModal here
    onCloseModal()
    await doCancelSubscription()
    recordAnalyticsEvent(
      teamSubscription
        ? 'TEAM_BILLING_CANCEL_SUB'
        : 'INDIVIDUAL_BILLING_CANCEL_SUB'
    )
    // Uncomment this once the above is fixed
    // setNextScreen('cancellation_confirmation')
  }

  return (
    <div className='w-[760px] h-[425px] flex items-start text-semantic-neutral-text-default'>
      <div className='w-[519px]'>
        <div className='flex flex-col p-6 gap-4'>
          <h1 className='font-semantic-neutral-text-default font-semibold text-base'>
            Before you go...
          </h1>
          <div className='flex flex-col gap-3'>
            <ProductSpecialistSection />
            <div className='flex flex-col gap-4 p-4 bg-semantic-neutral-bg-disabled'>
              <div className='flex flex-row items-center gap-2'>
                <EmojiSadSolid className='w-4 h-4 text-semantic-neutral-icon-default' />
                <h1 className='font-semibold'>If you cancel...</h1>
              </div>
              {utilizedSubscription && (
                <p className='text-xs'>
                  {templateStr(
                    "Your subscription will stay active until {{expiration}}. You won't be charged again after that.",
                    {
                      expiration: DateTime.fromSeconds(
                        utilizedSubscription.current_period_end
                      ).toFormat('MMMM d, y'),
                    }
                  )}
                </p>
              )}
              <p className='text-xs text-semantic-error-text-default'>
                Your account including all tasks and projects will be
                permanently deleted 90 days after subscription ends.
              </p>
              <div className='flex flex-row items-center gap-3'>
                <Button
                  size='small'
                  sentiment='error'
                  onClick={handleCancelSubscription}
                  loading={isCancelingSubscription}
                >
                  Cancel Subscription
                </Button>
                <Button
                  size='small'
                  sentiment='neutral'
                  variant='muted'
                  onClick={onCloseModal}
                >
                  Nevermind, don&apos;t cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col h-full w-[241px] p-4 gap-2 border-l border-modal-border'>
        <h2 className='font-semibold text-semantic-neutral-text-default'>
          What you&apos;ll lose:
        </h2>
        <div className='flex flex-col gap-2'>
          {listItems.map((item) => (
            <CancelModalListItem key={item} text={item} small />
          ))}
        </div>
      </div>
    </div>
  )
}
