import { Button } from '@motion/ui/base'
import { createNoneStageDefinition } from '@motion/ui-logic/pm/data'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import { useAddStageToProject } from '~/areas/project/hooks'
import { StageLabel } from '~/global/components/labels'
import { useProject } from '~/global/hooks'

import { useSidebarTaskContext } from '../context'

export function NoStageHeader() {
  const { projectId, workspaceId } = useSidebarTaskContext()
  const addStage = useAddStageToProject()
  const project = useProject(projectId)

  if (project == null) {
    Sentry.captureException(new Error('Project is null'), {
      tags: {
        position: 'NoStageHeader',
        projectId,
      },
    })
    return null
  }

  return (
    <div className='flex w-full justify-between items-center self-stretch'>
      <StageLabel value={createNoneStageDefinition(workspaceId)} />

      <Button
        onClick={async () => {
          recordAnalyticsEvent('FLOW_CONVERT_PROJECT')
          await addStage(project, 0)
        }}
        sentiment='neutral'
        size='xsmall'
        variant='outlined'
      >
        Convert to stage
      </Button>
    </div>
  )
}
