import { templateStr } from '@motion/react-core/strings'

import { useAutoScheduleRange } from '~/areas/project-management/hooks/experiments'

import { UnfitTaskTooltipHeader } from './unfit-task-tooltip-header'

import { formatAutoScheduleDuration } from '../../utils'

export const UnfitStageTooltip = () => {
  const autoScheduleDuration = useAutoScheduleRange()

  return (
    <div className='flex flex-col gap-2 text-sm leading-5 py-0.5 px-1 text-left'>
      <UnfitTaskTooltipHeader />

      <span className='text-tooltip-text-subtle italic'>
        {templateStr(
          "This task is blocked by the completion of the previous stage, whose tasks can't be scheduled in the next {{duration}}.",
          {
            duration: formatAutoScheduleDuration(autoScheduleDuration),
          }
        )}
      </span>
    </div>
  )
}
