import { Tooltip } from '@motion/ui/base'
import { useHasFeaturePermission } from '@motion/web-billing'
import { useModalApi } from '@motion/web-common/modals'

import { useAllProjectDefinitions } from '~/global/hooks'

import { TieredPricingUpsellButton } from './tiered-pricing-upsell-button'

export type TieredPricingAiProjectButtonProps = {
  onClose: () => void
  workspaceId?: string
}
export function TieredPricingAiProjectButton({
  onClose,
  workspaceId,
}: TieredPricingAiProjectButtonProps) {
  const modalApi = useModalApi()
  const canUseAiProjectCreation = useHasFeaturePermission('aiProjectCreation')

  const allProjectDefinitions = useAllProjectDefinitions()
  const isDisabled = allProjectDefinitions.length === 0

  const handleClick = () => {
    onClose()

    // Note: The timeout seems to be required to register the modal properly
    setTimeout(() => {
      modalApi.open('project-ai-modal', { workspaceId })
    }, 500)
  }

  return (
    <Tooltip
      asChild
      content={
        isDisabled ? 'Create Project with AI requires a Project Template' : null
      }
    >
      <TieredPricingUpsellButton
        tier='PROAI'
        variant='outlined'
        sentiment='neutral'
        onClick={handleClick}
        feature='aiProjectCreation'
        featureLocked={!canUseAiProjectCreation}
        disabled={isDisabled}
      >
        Create Project
      </TieredPricingUpsellButton>
    </Tooltip>
  )
}
