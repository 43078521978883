import { RoleFieldsList } from '~/areas/flows/components'
import { useFlowTemplateForm } from '~/areas/flows/shared-form'

export const ControlledRoleFields = () => {
  const { form } = useFlowTemplateForm()
  const { watch } = form

  const roles = watch('roles')

  return (
    <RoleFieldsList
      roles={roles}
      form={form}
      deleteAnalyticsName='template-delete-role'
    />
  )
}
