import {
  ExclamationCircleSolid,
  PencilSolid,
  PriorityASAPSolid,
  StageSolid,
  StatusCanceledSolid,
  StatusCompletedSolid,
} from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { type TaskSchema } from '@motion/rpc-types'
import { type ActionItem } from '@motion/ui/base'
import {
  shouldAllowASAP,
  shouldAllowPromoteToHardDeadline,
} from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type ETA_SOURCE } from '~/@types/analytics'
import { HardDeadlineTooltipIcon } from '~/areas/project-management/components'
import {
  useDoTaskASAP,
  useResolveTask,
  useTaskStageUpdater,
  useTaskUpdater,
} from '~/areas/tasks/hooks'
import { ConnectedStageLabel } from '~/global/components/labels'
import { useNavigateByGlobalModalId } from '~/global/navigation'
import { useMemo } from 'react'

import { type ActionOptions } from './types'
import { useExtendedNoneEta } from './use-extended-none-eta'
import { useNextTaskStage } from './use-next-task-stage'

export const useSingleTaskActions = (
  task: TaskSchema,
  actionList: ActionOptions[] = [],
  onClose: () => void,
  source: ETA_SOURCE
) => {
  const { completeTask, cancelTask } = useResolveTask()
  const navigateToModal = useNavigateByGlobalModalId()
  const updateTask = useTaskUpdater()
  const doTaskAsap = useDoTaskASAP()
  const stageUpdater = useTaskStageUpdater()
  const stageDefinition = useNextTaskStage(task)
  const deadlineStatus = useExtendedNoneEta({ entity: task, type: 'task' })

  return useMemo<ActionItem[]>(() => {
    return actionList
      .map((action) => {
        switch (action) {
          case 'moveToNextStage': {
            if (stageDefinition) {
              return {
                content: templateStr(
                  'Move task to next stage: {{stageLabel}}',
                  {
                    stageLabel: <ConnectedStageLabel id={stageDefinition.id} />,
                  }
                ),
                prefix: <StageSolid />,
                onAction: () => {
                  recordAnalyticsEvent('ETA_ACTION', {
                    action: 'moveToNextStage',
                    type: 'task',
                    source,
                    eta: deadlineStatus,
                  })
                  stageUpdater(task.id, stageDefinition)
                  onClose?.()
                },
              }
            }

            return undefined
          }
          case 'doASAP':
            if (!shouldAllowASAP(task)) return undefined

            return {
              prefix: (
                <PriorityASAPSolid className='text-dropdown-item-icon-default' />
              ),
              content: 'Do ASAP',
              onAction: () => {
                recordAnalyticsEvent('ETA_ACTION', {
                  action: 'doAsap',
                  type: 'task',
                  source,
                  eta: deadlineStatus,
                })
                doTaskAsap(task.id)
                onClose?.()
              },
            }
          case 'hardDeadline':
            if (!shouldAllowPromoteToHardDeadline(task)) return undefined

            return {
              // TODO: Fix this color issue
              prefix: (
                <ExclamationCircleSolid className='text-dropdown-item-icon-default' />
              ),
              content: 'Turn on hard deadline',
              suffix: <HardDeadlineTooltipIcon />,
              onAction: () => {
                recordAnalyticsEvent('ETA_ACTION', {
                  action: 'hardDeadline',
                  type: 'task',
                  source,
                  eta: deadlineStatus,
                })
                updateTask(task, { deadlineType: 'HARD' })
                onClose?.()
              },
            }
          case 'complete':
            return {
              content: 'Complete task',
              prefix: <StatusCompletedSolid />,
              onAction: () => {
                recordAnalyticsEvent('ETA_ACTION', {
                  action: 'complete',
                  type: 'task',
                  source,
                  eta: deadlineStatus,
                })
                completeTask(task.id)
                onClose?.()
              },
            }
          case 'cancel':
            return {
              content: 'Cancel task',
              prefix: (
                <StatusCanceledSolid className='!text-semantic-error-icon-default' />
              ),
              onAction: () => {
                recordAnalyticsEvent('ETA_ACTION', {
                  action: 'cancel',
                  type: 'task',
                  source,
                  eta: deadlineStatus,
                })
                cancelTask(task.id)
                onClose?.()
              },
            }
          case 'edit':
            return {
              content: 'Edit task',
              prefix: <PencilSolid />,
              onAction: () => {
                recordAnalyticsEvent('ETA_ACTION', {
                  action: 'view',
                  type: 'task',
                  source,
                  eta: deadlineStatus,
                })
                navigateToModal('task', { task: task.id })
                onClose?.()
              },
            }
          default:
            return null
        }
      })
      .filter(Boolean) as ActionItem[]
  }, [
    actionList,
    cancelTask,
    completeTask,
    deadlineStatus,
    doTaskAsap,
    navigateToModal,
    onClose,
    source,
    stageDefinition,
    stageUpdater,
    task,
    updateTask,
  ])
}
