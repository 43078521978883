import { CalendarPlusSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { classed } from '@motion/theme'
import { AutoScheduleToggle, IconButton, Tooltip } from '@motion/ui/base'
import {
  formatDurationToShort,
  formatToShortTaskDuration,
} from '@motion/ui-logic'
import { isGhostTask } from '@motion/ui-logic/pm/project'
import {
  isAutoScheduledToggleEnabled,
  isMeetingTask,
  isSchedulingTask,
} from '@motion/ui-logic/pm/task'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { ConnectedTaskEtaBadge } from '~/areas/eta'
import {
  AssigneeDropdown,
  DurationDropdown,
} from '~/areas/project-management/components'
import { TaskStatusDropdown } from '~/areas/tasks/components'
import { useTaskDurationData } from '~/areas/tasks/hooks'
import { AutoScheduleTooltip } from '~/areas/tasks/tooltips'
import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { ConnectedAssigneeBadge, StatusBadge } from '~/global/components/badges'
import { useWorkspaceStatusById } from '~/global/hooks'
import { useTaskModalUrl } from '~/global/navigation'
import { type NormalTaskWithRelations } from '~/global/proxies'

import { TaskName } from './task-name'

import {
  useAssigneeDropdown,
  useAutoSchedule,
  useStatusDropdown,
} from '../../../hooks'
import { SchedulingEventDropdown } from '../../events'
import { useSidebarTaskContext } from '../context'
import { TaskLineMenu } from '../task-line-menu'

type TaskPanelTaskLineProps = {
  className?: string
  task: NormalTaskWithRelations
}

export function TaskPanelTaskLine({ className, task }: TaskPanelTaskLineProps) {
  const { currentTaskId } = useSidebarTaskContext()

  const {
    onChange: onChangeStatus,
    value: statusDropdownValue,
    disabled: statusDisabled,
    iconVariant: statusIconVariant,
    taskVariant: statusTaskVariant,
    shouldExcludeResolved,
  } = useStatusDropdown(task)
  const {
    onChange: onChangeAssignee,
    removeUnassignedOption,
    selectedAssignee,
    disabled: assigneeDisabled,
  } = useAssigneeDropdown(task)

  const { onChange: onChangeDuration } = useTaskDurationData(task)

  const {
    isAutoScheduled,
    formattedScheduledDate,
    scheduledDateInfo,
    toggleChange: toggleChangeAutoSchedule,
    isError,
  } = useAutoSchedule(task)

  const status = useWorkspaceStatusById(task.statusId)

  const { assigneeUserId } = task

  const isGhost = isGhostTask(task)

  const scheduledDate =
    formattedScheduledDate ??
    (['unfit', 'unfitPastDue'].includes(scheduledDateInfo.type)
      ? scheduledDateInfo.shortText
      : undefined)

  const { disabled: isAutoScheduleDisabled } = isAutoScheduledToggleEnabled({
    status,
    isAutoScheduled,
  })

  const taskDateTextState =
    isError || task.deadlineStatus === 'missed-deadline'
      ? 'error'
      : task.deadlineStatus === 'scheduled-past-deadline'
        ? 'warning'
        : 'none'

  const isMeeting = isMeetingTask(task)
  const isSchedulingTaskType = isSchedulingTask(task)

  const context = useRouteAnalyticsMetadata()

  const buildTaskModalUrl = useTaskModalUrl()

  const taskLink = buildTaskModalUrl({
    task: task.id,
    isMeeting,
  })

  const onLinkClick = () => {
    recordAnalyticsEvent('TASK_SIDEBAR_LINE_CLICK', {
      ...context,
      isGhost,
      type: isMeeting
        ? 'meeting'
        : isSchedulingTaskType
          ? 'scheduling'
          : 'task',
    })
  }

  const shouldShowSchedulingWarning =
    task.deadlineStatus === 'scheduled-past-deadline' ||
    task.deadlineStatus === 'missed-deadline'

  return (
    <TaskLineContainer isGhostTask={isGhost} className={className}>
      <TaskStatusDropdown
        isUnvisitedStage={isGhost}
        selectedStatusId={task.statusId}
        onChange={onChangeStatus}
        workspaceId={task.workspaceId}
        taskVariant={statusTaskVariant}
        excludeResolved={shouldExcludeResolved}
      >
        <button type='button' className='flex' disabled={statusDisabled}>
          <StatusBadge
            value={statusDropdownValue}
            size='small'
            iconVariant={statusIconVariant}
            taskVariant={statusTaskVariant}
          />
        </button>
      </TaskStatusDropdown>
      <div className='flex flex-row items-center gap-1 grow truncate relative'>
        <TaskName
          currentTaskId={currentTaskId}
          task={task}
          taskLink={taskLink}
          onLinkClick={onLinkClick}
        />

        <SchedulingEventDropdown taskId={task.id}>
          <Tooltip asChild content='Schedule event'>
            <IconButton
              icon={CalendarPlusSolid}
              variant='muted'
              sentiment='primary'
              size='xsmall'
              aria-label='Schedule event'
            />
          </Tooltip>
        </SchedulingEventDropdown>

        {shouldShowSchedulingWarning && scheduledDate && (
          <>
            <ConnectedTaskEtaBadge
              task={task}
              size='xsmall'
              source='modal-list'
            />
          </>
        )}

        <DurationDropdown value={task.duration} onChange={onChangeDuration}>
          <Tooltip
            content={templateStr('Duration: {{duration}}', {
              duration: formatToShortTaskDuration(task.duration ?? 0),
            })}
            asChild
          >
            <span className='text-semantic-neutral-text-subtle text-[11px] leading-3 whitespace-nowrap'>
              {formatDurationToShort(task.duration ?? 0)}
            </span>
          </Tooltip>
        </DurationDropdown>

        {scheduledDate && (
          <Tooltip
            renderContent={() => (
              <AutoScheduleTooltip info={scheduledDateInfo} task={task} />
            )}
            asChild
          >
            <TaskDateText state={taskDateTextState}>
              {scheduledDate}
            </TaskDateText>
          </Tooltip>
        )}
      </div>

      {!isMeetingTask(task) && (
        <AutoScheduleToggle
          checked={isAutoScheduled}
          onChange={toggleChangeAutoSchedule}
          labelHidden
          size='xsmall'
          disabled={isAutoScheduleDisabled}
        />
      )}

      <AssigneeDropdown
        onChange={onChangeAssignee}
        removeUnassignedOption={removeUnassignedOption}
        selectedUserId={selectedAssignee.userId}
        workspaceId={task.workspaceId}
      >
        <button type='button' disabled={assigneeDisabled}>
          <ConnectedAssigneeBadge
            userId={assigneeUserId ?? null}
            size='small'
          />
        </button>
      </AssigneeDropdown>

      <TaskLineMenu task={task} />
    </TaskLineContainer>
  )
}

const TaskDateText = classed('span', 'text-2xs', {
  variants: {
    state: {
      error: 'text-semantic-error-text-default',
      warning: 'text-semantic-warning-text-default',
      none: ' text-semantic-neutral-text-subtle text-nowrap',
    },
  },
  defaultVariants: {
    state: 'none',
  },
})

const TaskLineContainer = classed(
  'div',
  'group/task-line flex flex-row items-center grow gap-1.5',
  'truncate h-[30px] pl-2',
  'relative',
  {
    variants: {
      isGhostTask: {
        true: 'opacity-50',
        false: '',
      },
    },
    defaultVariants: {
      isGhostTask: false,
    },
  }
)
