import { CheckSolid, LinkSolid, PencilSolid, TrashOutline } from '@motion/icons'
import { type CommentSchema } from '@motion/rpc-types'
import { ActionList } from '@motion/ui/base'
import { addComponentName } from '@motion/ui/helpers'
import { Sentry } from '@motion/web-base/sentry'

import { useCopyHtmlLinkToClipboard } from '~/global/hooks'
import { useNoteUrl } from '~/global/navigation'

export type CommentActionListProps = {
  comment: CommentSchema
  isOwner: boolean
  onEdit?: () => void
  onDelete?: () => void
  onResolve?: () => void
  close?: () => void
}

export const CommentActionList = (props: CommentActionListProps) => {
  const { comment, isOwner } = props

  const copyLink = useCopyHtmlLinkToClipboard()

  const getNoteUrl = useNoteUrl()

  const handleCopyLink = () => {
    if (comment.targetType !== 'NOTE') {
      Sentry.captureException(new Error('Comment target type is not NOTE'), {
        extra: {
          commentId: comment.id,
          targetId: comment.targetId,
          targetType: comment.targetType,
        },
      })
      return
    }

    if (comment.threadId == null) {
      Sentry.captureException(new Error('Comment threadId is null'), {
        extra: {
          commentId: comment.id,
          targetId: comment.targetId,
          targetType: comment.targetType,
        },
      })
      return
    }

    const noteId = comment.targetId

    const { noteUrl: noteUrlStr } = getNoteUrl({ noteId })

    try {
      const noteUrl = new URL(noteUrlStr)

      noteUrl.searchParams.set('threadId', comment.threadId)

      copyLink(noteUrl.toString(), 'Motion Doc comment')
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          noteId,
          noteUrl: noteUrlStr,
          commentId: comment.id,
          threadId: comment.threadId,
        },
      })
    }
  }

  return (
    <div className='min-w-36' {...addComponentName('CommentActionList')}>
      <ActionList
        onActionAnyItem={props.close}
        sections={[
          {
            items: [
              isOwner && {
                prefix: <PencilSolid />,
                content: 'Edit',
                onAction: props.onEdit,
              },
              {
                prefix: <LinkSolid />,
                content: 'Copy link to thread',
                onAction: handleCopyLink,
              },
              props.onResolve && {
                prefix: <CheckSolid />,
                content: 'Resolve thread',
                onAction: props.onResolve,
              },
            ].filter(Boolean),
          },

          isOwner && {
            items: [
              {
                prefix: <TrashOutline />,
                content: 'Delete',
                onAction: props.onDelete,
              },
            ],
          },
        ].filter(Boolean)}
      />
    </div>
  )
}
