import { type StageDefinitionSchema } from '@motion/rpc-types'

import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { clearStageTemplateSearchParams } from '../utils'

export function useCloseStageModal() {
  const [, setSearchParams] = useSearchParams()

  return useCallback(
    (maybeStageDefinition?: StageDefinitionSchema | null) => {
      setSearchParams((prev) => {
        clearStageTemplateSearchParams(prev)

        return prev
      })

      return maybeStageDefinition
    },
    [setSearchParams]
  )
}
