import { CalendarSolid } from '@motion/icons'
import { StageAdjusterStrategy } from '@motion/shared/flows'
import { Button } from '@motion/ui/base'
import { stageAdjusterFn, type StageArg } from '@motion/ui-logic/pm/project'
import { getDateButtonText } from '@motion/ui-logic/pm/task'
import { parseDate } from '@motion/utils/dates'

import { DeadlineDropdown } from '~/areas/project-management/components'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import {
  useSetupProjectDateRangeColors,
  useSetupProjectForm,
} from '../../../hooks'

export type Props = {
  index: number
  isError?: boolean
}

export const ControlledStageDeadlineField = ({
  index,
  isError: isErrorProp,
}: Props) => {
  const { form } = useSetupProjectForm()

  const { control, watch, getValues, setValue } = form

  const { field } = useController({
    name: `speculativeProject.stages.${index}.dueDate`,
    control,
  })

  const stages = watch(`speculativeProject.stages`)
  const stageAtIndex = watch(`speculativeProject.stages.${index}`)
  const { dueDate: currentValue, stageDefinitionId } = stageAtIndex

  const dateButtonString = getDateButtonText(currentValue, {
    placeholder: 'None',
  })

  const isError = isErrorProp || doesLaterStageHaveEarlierDueDate(stages, index)

  const projectStart = watch('startDate')
  const projectDeadline = watch('dueDate')

  const additionalQuickActions = useMemo(
    () => [
      {
        label: 'Project start',
        value: DateTime.fromISO(projectStart),
      },
      {
        label: 'Project deadline',
        value: DateTime.fromISO(projectDeadline),
      },
    ],
    [projectDeadline, projectStart]
  )

  const stageDateRangeColors = useSetupProjectDateRangeColors()

  return (
    <DeadlineDropdown
      value={currentValue}
      onChange={(value) => {
        if (value == null) return
        const date = parseDate(value).toISODate()
        stageAdjusterFn({
          params: {
            startDate: getValues('startDate'),
            dueDate: getValues('dueDate'),
            stageDueDates: getValues('speculativeProject.stages'),
          },
          onAction: (adjuster) => {
            adjuster.prepareStageAdjustment({
              strategy: {
                before: StageAdjusterStrategy.ACCORDION,
                after: StageAdjusterStrategy.SHIFT,
              },
              stageDefinitionId,
              value,
            })
          },
          onResult: ({ startDate, dueDate, stageDueDates }) => {
            const newStages = getValues('speculativeProject.stages').map(
              (stage) => {
                const matchingStageDueDate = stageDueDates.find(
                  (stageDueDate) =>
                    stage.stageDefinitionId === stageDueDate.stageDefinitionId
                )

                if (matchingStageDueDate) {
                  return {
                    ...stage,
                    dueDate: matchingStageDueDate.dueDate,
                  }
                }

                return stage
              }
            )

            setValue('startDate', startDate)
            setValue('dueDate', dueDate)
            setValue('speculativeProject.stages', newStages)

            field.onChange(date)
          },
        })
      }}
      hideTimeOptions
      disableClearing
      dropdownTarget='stage'
      contextProps={{
        projectId: null,
        stageDefinitionId,
      }}
      additionalQuickActions={additionalQuickActions}
      overrides={{
        stageDateRangeColors,
      }}
    >
      <Button
        variant='outlined'
        sentiment={isError ? 'error' : 'neutral'}
        size='small'
      >
        <div className='text-nowrap text-left font-normal flex flex-row items-center gap-[6px] min-w-[90px]'>
          <CalendarSolid />
          {dateButtonString}
        </div>
      </Button>
    </DeadlineDropdown>
  )
}

function doesLaterStageHaveEarlierDueDate(
  stageDueDates: StageArg[],
  index: number
): boolean {
  return stageDueDates.some(
    (stage, i) =>
      i > index &&
      stage.dueDate != null &&
      stage.dueDate < stageDueDates[index].dueDate
  )
}
