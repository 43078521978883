import { type SortableWorkspacesTreeviewItem } from '../types'

export function workspaceTreeviewRenderFilter(
  items: SortableWorkspacesTreeviewItem[],
  {
    activeItem,
  }: {
    activeItem: SortableWorkspacesTreeviewItem | null
    activeItemIdx: number | null
  }
): SortableWorkspacesTreeviewItem[] {
  if (activeItem?.level === 0) {
    return items.filter(({ level }) => level === 0)
  }

  let maxLevel: number | null = null

  return items.reduce<typeof items>((acc, item, i) => {
    if (maxLevel != null) {
      if (item.level > maxLevel) {
        // A parent folder is collapsed and this item should not be rendered
        return acc
      }

      // The level is now the same or less than the collapsed folder, reset the max level
      maxLevel = null
    }

    if (
      item.isContainer &&
      (!item.isContainerExpanded || item === activeItem)
    ) {
      maxLevel = item.level
    }

    return [...acc, item]
  }, [])
}
