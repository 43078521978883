import { templateStr } from '@motion/react-core/strings'

export function getTextFieldRules(
  fieldName: string,
  options?: {
    required?: boolean
    maxLength?: number
  }
) {
  const { required = true, maxLength = NAME_FIELD_RULE.maxLength.value } =
    options || {}

  return {
    maxLength: {
      value: maxLength,
      message: templateStr(
        '{{fieldName}} must be less than {{maxLength}} characters',
        {
          fieldName,
          maxLength,
        }
      ),
    },
    required: {
      value: required,
      message: templateStr('{{fieldName}} is required and cannot be empty', {
        fieldName,
      }),
    },
  } as const
}

export const NAME_FIELD_RULE = {
  required: {
    value: true,
    message: 'The name is required and cannot be empty',
  },
  maxLength: {
    value: 500,
    message: 'The name should contain at most 500 characters',
  },
}
type TEXT_RULE = typeof NAME_FIELD_RULE

export function checkTextRuleErrors(value: string, rule: TEXT_RULE) {
  const trimValue = value.trim()
  if (trimValue.length === 0 && rule.required.value) {
    return rule.required.message
  }

  if (trimValue.length > rule.maxLength.value) {
    return rule.maxLength.message
  }

  return
}

export const NUMBER_FIELD_RULE = {
  required: {
    value: true,
    message: 'The number is required and cannot be empty',
  },
  minValue: {
    value: 0,
    message: 'The number cannot be less than 0',
  },
  isInteger: {
    value: true,
    message: 'The number must be an integer',
  },
  isPositive: {
    value: true,
    message: 'The number must be a positive number',
  },
}

type NUMBER_RULE = Partial<typeof NUMBER_FIELD_RULE>

export function checkNumberRuleErrors(value: number, rule: NUMBER_RULE) {
  if (rule.minValue && value < rule.minValue.value) {
    return rule.minValue.message
  }

  if (rule.required && (value == null || Number.isNaN(value)))
    return rule.required.message
  if (rule.isInteger && !Number.isInteger(value)) return rule.isInteger.message
  if (rule.isPositive && value < 0) return rule.isPositive.message

  return null
}
