import { XSolid } from '@motion/icons'
import { IconButton, UnstyledModal } from '@motion/ui/base'

import { ErrorBoundary } from '~/global/components'
import { type FormEvent } from 'react'

import { ActionItemHeader } from './action-item-header'
import {
  AutoscheduleToggle,
  Banner,
  ControlledAssigneeField,
  ControlledDeadlineField,
  ControlledDeadlineToggle,
  ControlledDescriptionField,
  ControlledDurationField,
  ControlledMinChunkField,
  ControlledNameField,
  ControlledPriorityField,
  ControlledScheduleField,
  ControlledStartDateField,
  ControlledStatusField,
  ControlledWorkspaceFolderProjectField,
} from './components'
import {
  BannerShell,
  FormShell,
  GridShell,
  MainHeader,
  MainScrollableContent,
  ShellActions,
  SidebarSection,
  SidebarShell,
  SidebarWorkspaceSection,
} from './styled'

import { useActionItemForm, useSubmitActionItemForm } from '../hooks'
import { useActionItemContext } from '../provider'

export function ConnectedActionItemModal() {
  const { modalVisible, closeModal } = useActionItemContext()

  return (
    <ErrorBoundary errorMessage='Error rendering action item modal'>
      <UnstyledModal
        data-testid='action-item-modal'
        type='page'
        visible={modalVisible}
        onClose={closeModal}
        withAnimation
        overlayClassName='bg-modal-overlay'
      >
        <ActionItemModalBody />
      </UnstyledModal>
    </ErrorBoundary>
  )
}

function ActionItemModalBody() {
  const { initialActionItem, closeModal } = useActionItemContext()
  const actionItemId = initialActionItem.id

  const { form } = useActionItemForm()
  const submitForm = useSubmitActionItemForm(form, actionItemId, true)

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await submitForm()(e)

    closeModal()
  }

  return (
    <FormShell onSubmit={handleSubmit}>
      <ShellActions>
        <IconButton
          icon={XSolid}
          sentiment='neutral'
          size='small'
          variant='muted'
          onClick={closeModal}
        />
      </ShellActions>

      <GridShell>
        <BannerShell>
          <Banner />
        </BannerShell>

        <MainHeader>
          <ActionItemHeader />
          <ControlledNameField />
        </MainHeader>

        <MainScrollableContent>
          <ControlledDescriptionField />
        </MainScrollableContent>

        <SidebarShell>
          <SidebarWorkspaceSection>
            <ControlledWorkspaceFolderProjectField />
          </SidebarWorkspaceSection>

          <AutoscheduleToggle checked />

          <SidebarSection className='pt-3 modal-lg:pt-5'>
            <ControlledAssigneeField />
            <ControlledStatusField />
            <ControlledPriorityField />
          </SidebarSection>

          <SidebarSection>
            <ControlledDurationField />
            <ControlledMinChunkField />
            <ControlledStartDateField />
            <ControlledDeadlineField />
            <ControlledDeadlineToggle />
            <ControlledScheduleField />
          </SidebarSection>
        </SidebarShell>
      </GridShell>
    </FormShell>
  )
}
