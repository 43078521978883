import { templateStr } from '@motion/react-core/strings'
import { getCacheEntry } from '@motion/rpc-cache'
import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { useModalApi } from '@motion/web-common/modals'
import { type WorkspaceSchema } from '@motion/zod/client'

import { useQueryClient } from '@tanstack/react-query'
import { useI18N } from '~/global/contexts'
import { useDeleteWorkspace as useDeleteWorkspaceCall } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useDeleteWorkspace = () => {
  const {
    mutateAsync: deleteWorkspace,
    isPending: isLoading,
    isError,
  } = useDeleteWorkspaceCall()
  const modalApi = useModalApi()
  const queryClient = useQueryClient()
  const { pluralize } = useI18N()

  return {
    deleteWorkspace: useCallback(
      async (workspace: WorkspaceSchema): Promise<boolean> => {
        try {
          const projectCount = workspace.projectIds.length
          let taskCount = 0
          workspace.projectIds.forEach((projectId) => {
            const project = getCacheEntry(queryClient, 'projects', projectId)
            taskCount += project?.value.taskCount ?? 0
          })

          const confirmResponse = await modalApi.prompt('confirm-delete-item', {
            analytics: {
              name: 'delete-workspace',
            },
            titleValue: 'this workspace',
            description: templateStr(
              `This workspace and {{taskString}}, {{projectString}}, task default settings, and all other information will be deleted permanently.`,
              {
                taskString: pluralize(taskCount, '1 task', '{{count}} tasks'),
                projectString: pluralize(
                  projectCount,
                  '1 project',
                  '{{count}} projects'
                ),
              }
            ),
            deleteMessage: workspace.name,
            deleteButtonLabelValue: 'workspace',
          })

          if (confirmResponse === true) {
            recordAnalyticsEvent('PROJECT_MANAGEMENT_DELETE_WORKSPACE')

            await deleteWorkspace({
              workspaceId: workspace.id,
            })

            showToast('success', 'Workspace removed')

            return true
          }
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              position: 'delete-workspace',
            },
          })

          showErrorToast(e)
        }

        return false
      },
      [deleteWorkspace, modalApi, pluralize, queryClient]
    ),
    isLoading,
    isError,
  }
}
