import { classed } from '@motion/theme'
import { Tabs, TextHeader } from '@motion/ui/base'

import { useState } from 'react'

interface TabbedSettingsPageProps {
  title: React.ReactNode
  children: React.ReactNode
  initialTabValue?: string
}

export const TabbedSettingsPage = (props: TabbedSettingsPageProps) => {
  const [value, setValue] = useState(props.initialTabValue ?? '')

  return (
    <Container>
      <TextHeader size='lg' className='px-12 py-3.5 bg-calendar-bg-default'>
        {props.title}
      </TextHeader>
      <Tabs outerPadding='large' value={value} onChange={setValue}>
        {props.children}
      </Tabs>
    </Container>
  )
}

const Container = classed('div', {
  base: `
    flex-1
    divide-light-500 dark:divide-dark-1000 divide-y
    grid grid-rows-[auto_1fr]
    self-stretch
    overflow-hidden
    bg-calendar-bg-default
  `,
})
