import { classed } from '@motion/theme'
import { findStageForTask } from '@motion/ui-logic/pm/project'

import { TaskNameRichInput } from '~/areas/flows/components'
import { NAME_FIELD_RULE } from '~/global/rules'
import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import { ConnectedNodeVariableLabel } from './connected-node-variable-label'
import { ConnectedSuggestionDropdown } from './connected-suggestion-dropdown'

import { useTaskDefinitionForm } from '../../use-task-definition-form'
import { useTaskDefinitionModalState } from '../../use-task-definition-modal-state'

export const ControlledNameField = () => {
  const { form } = useTaskDefinitionForm()
  const { flowFormData } = useTaskDefinitionModalState()
  if (!flowFormData) {
    throw new Error('flowFormData is required')
  }

  const { control, watch } = form

  const {
    field,
    formState: {
      errors: { name: nameError },
    },
  } = useController({
    name: 'name',
    control,
    rules: NAME_FIELD_RULE,
  })

  const taskId = watch('id')
  const { stages, textVariables } = flowFormData
  const stage = findStageForTask(stages, taskId)
  if (stage == null) {
    throw new Error('Stage not found')
  }

  const variableArgs = useMemo(
    () => ({
      stages,
      textVariables,
      taskId,
      labelSize: 'small' as const,
      type: 'name' as const,
      as: ConnectedNodeVariableLabel,
      dropdownComponent: ConnectedSuggestionDropdown,
    }),
    [stages, textVariables, taskId]
  )

  return (
    <NameContainer>
      <TaskNameRichInput
        value={field.value}
        onChange={(value) => field.onChange(value)}
        isError={nameError != null}
        variableArgs={variableArgs}
        stageCardType='task'
        size='large'
      />
    </NameContainer>
  )
}

const NameContainer = classed('div', 'py-1.5')
