import { Text, TextHeader } from '@motion/ui/base'

export const GuestPage = ({ teamName }: { teamName: string }) => {
  return (
    <div className='bg-light-100 dark:bg-dark-1100 flex h-full w-full justify-center py-12'>
      <div className='bg-light-100 border-light-300 dark:bg-dark-1100 dark:border-dark-1000 flex h-[88px] w-full max-w-2xl items-center justify-between border px-4'>
        <div className='flex flex-col gap-2'>
          <TextHeader size='lg'>{teamName}</TextHeader>
          <Text sentiment='subtle' size='sm'>
            You are an external guest on this team.
          </Text>
        </div>
      </div>
    </div>
  )
}
