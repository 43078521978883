import { classed } from '@motion/theme'

export const Section = classed('section', {
  base: 'flex flex-col items-start gap-3',
})

export const SectionTitle = classed('h3', {
  base: 'text-sm font-semibold text-semantic-neutral-text-default',
})

export const SectionText = classed('p', {
  base: 'text-sm text-semantic-neutral-text-default',
})
