import {
  type RoleVariable,
  type TextVariable,
} from '@motion/ui-logic/pm/project'
import { type VariableDefinitionSchema } from '@motion/zod/client'

export function isTextVariable(
  field: VariableDefinitionSchema
): field is TextVariable {
  return field.type === 'text'
}

export function isRoleVariable(
  field: VariableDefinitionSchema
): field is RoleVariable {
  return field.type === 'person'
}
