import { InformationCircleSolid, XSolid } from '@motion/icons'
import { IconButton, Tooltip } from '@motion/ui/base'
import {
  recordAnalyticsEvent,
  useOnMountAnalyticsEvent,
} from '@motion/web-base/analytics'

import { useShowProjectSidebarStateSendOnly } from '~/areas/project-management/states'
import { useProject } from '~/global/hooks'
import { useProjectModalUrl } from '~/global/navigation'

import {
  BaseProjectPanel,
  type ProjectPanelTab,
} from './components/base-project-panel'
import { ActivityTab, DescriptionTab, FieldsTab } from './tabs'

export type ProjectSidePanelProps = {
  projectId: string
}

export function ProjectSidePanel({ projectId }: ProjectSidePanelProps) {
  const project = useProject(projectId)
  const buildProjectModalUrl = useProjectModalUrl()

  const setSidebarState = useShowProjectSidebarStateSendOnly()

  useOnMountAnalyticsEvent('PROJECT_MANAGEMENT_PROJECT_SIDEPANEL_VIEW')

  /**
   * User might have moved the project to another workspace while being in task list view.
   * In which case, the project will temporarily be unavailable since we're navigating to the new project url.
   */
  if (project == null) {
    return null
  }

  const onChangeAnyField = (fieldName: string) => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_PROJECT_SIDEPANEL_VALUE_CHANGE', {
      fieldName,
    })
  }

  const onOpenButtonClick = () => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_PROJECT_SIDEPANEL_HEADER_BUTTON', {
      operation: 'open-project',
    })
  }

  const onTabChange = (tab: TabValue) => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_PROJECT_SIDEPANEL_TAB_CHANGE', {
      tab,
    })
  }

  const tabs: ProjectSidePanelTab[] = [
    {
      title: 'Description',
      value: 'description',
      content: <DescriptionTab projectId={projectId} />,
    },
    {
      title: 'Activity',
      value: 'activity',
      content: (
        <ActivityTab projectId={projectId} workspaceId={project.workspaceId} />
      ),
    },
    {
      title: 'Fields',
      value: 'fields',
      content: (
        <FieldsTab
          projectId={projectId}
          showHeader={false}
          onChangeAnyField={onChangeAnyField}
        />
      ),
    },
  ]

  return (
    <BaseProjectPanel
      projectId={projectId}
      variant='side-panel'
      header={
        <>
          <Tooltip asChild content='Open project'>
            <IconButton
              icon={InformationCircleSolid}
              sentiment='neutral'
              variant='muted'
              size='small'
              onClick={onOpenButtonClick}
              url={buildProjectModalUrl({
                project: projectId,
              })}
            />
          </Tooltip>

          <Tooltip asChild content='Close panel'>
            <IconButton
              icon={XSolid}
              sentiment='neutral'
              variant='muted'
              size='small'
              aria-label='Close project side panel'
              onClick={() => {
                setSidebarState((state) => ({
                  ...state,
                  open: false,
                }))
              }}
            />
          </Tooltip>
        </>
      }
      tabs={tabs}
      onTabChange={onTabChange}
    />
  )
}

// eslint-disable-next-line unused-imports/no-unused-vars
const tabValues = ['description', 'activity', 'fields'] as const

type TabValue = (typeof tabValues)[number]

type ProjectSidePanelTab = Omit<ProjectPanelTab, 'value'> & {
  value: TabValue
}
