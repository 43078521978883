import { CrownSolid } from '@motion/icons'
import { type AllAvailableCustomFieldSchema } from '@motion/ui-logic'
import { useModalApi } from '@motion/web-common/modals'

import { type WorkspaceCustomField } from '~/areas/custom-fields/types'
import { getCustomFieldIcon } from '~/areas/custom-fields/utils'
import { useWorkspaceById } from '~/global/hooks'
import { useParams } from 'react-router-dom'

import { SettingTableV2 } from '../../components'
import { useFeatureGatedAddCustomField } from '../../custom-fields/hooks'
import { useDeleteCustomField } from '../../hooks'

export type CustomFieldsTableProps = {
  fields: WorkspaceCustomField[]
}

export const CustomFieldsTable = ({ fields }: CustomFieldsTableProps) => {
  const { workspaceId } = useParams<{ workspaceId: string }>()
  const workspace = useWorkspaceById(workspaceId)
  const deleteField = useDeleteCustomField()

  const modalApi = useModalApi()

  const [canAddCustomField, onDuplicateCustomField] =
    useFeatureGatedAddCustomField(
      workspace,
      (field: AllAvailableCustomFieldSchema) => {
        if (!workspaceId) {
          return
        }
        modalApi.open('duplicate-custom-field', {
          workspaceId,
          customField: field,
        })
      }
    )

  if (!workspaceId) {
    throw new Error('No workspaceId')
  }

  return (
    <SettingTableV2
      items={fields.map((field) => ({
        id: field.id,
        label: field.name,
        icon: getCustomFieldIcon(field.type),
        customDuplicateIcon: canAddCustomField ? undefined : CrownSolid,
        onDuplicate: () => {
          onDuplicateCustomField(field)
        },
        onDelete: () =>
          deleteField({
            workspaceId,
            customField: field,
          }),
        onEdit: () =>
          modalApi.open('edit-custom-field', {
            workspaceId,
            customField: field,
          }),
      }))}
    />
  )
}
