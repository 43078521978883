import { PencilSolid } from '@motion/icons'
import { useDependantState } from '@motion/react-core/hooks'
import { Button } from '@motion/ui/base'
import { addSchemeIfNeeded, getDisplayableLink } from '@motion/ui-logic'
import { getWindowData } from '@motion/web-base/env'

import { canParseCustomFieldUrlValue } from '~/areas/custom-fields/utils'
import {
  CustomField,
  type CustomFieldInputProps,
} from '~/areas/project-management/custom-fields'
import { ModalFieldButton } from '~/areas/task-project/components'
import { forwardRef, useState } from 'react'

export const Url = forwardRef<
  HTMLLabelElement | HTMLDivElement,
  CustomFieldInputProps
>((props, ref) => {
  const { value, onChange: submitHandler, size, ...rest } = props
  const { isMac } = getWindowData()

  const [internalValue, setInternalValue] = useDependantState(
    () => value,
    [value]
  )
  const [isEditing, setIsEditing] = useState(internalValue ? false : true)

  function handleExitFocus() {
    const parsedValue = addSchemeIfNeeded(internalValue)
    if (parsedValue) {
      submitHandler(parsedValue)
    }

    setIsEditing(false)
  }

  if (isEditing) {
    return (
      <ModalFieldButton
        label={props.name}
        disabled={props.disabled}
        size={size}
      >
        <CustomField.Url
          {...rest}
          ref={ref as React.RefObject<HTMLLabelElement>}
          value={internalValue}
          onChange={(v) => setInternalValue(v)}
          onBlur={handleExitFocus}
          onKeyDown={(e) => {
            if (['Enter', 'Escape'].includes(e.key)) {
              e.preventDefault()
              handleExitFocus()
            }

            if (
              (isMac && e.metaKey && e.key === 's') ||
              (!isMac && e.ctrlKey && e.key === 's')
            ) {
              e.preventDefault()
              handleExitFocus()
            }
          }}
        />
      </ModalFieldButton>
    )
  }

  const isUrl = canParseCustomFieldUrlValue(value)

  const DisplayTag = isUrl ? 'a' : 'div'

  return (
    <ModalFieldButton label={props.name} disabled={props.disabled} size={size}>
      <div
        className='flex items-center gap-2 min-w-0 w-full cursor-pointer'
        ref={ref as React.RefObject<HTMLDivElement>}
      >
        <DisplayTag
          className='hover:text-semantic-primary-text-default text-xs font-normal truncate full-width flex flex-grow'
          href={isUrl && value ? value : undefined}
          target={isUrl ? '_blank' : undefined}
          rel={isUrl ? 'noopener noreferrer' : undefined}
        >
          {value ? getDisplayableLink(value) : 'None'}
        </DisplayTag>
        <Button
          iconOnly
          sentiment='neutral'
          variant='muted'
          size={size ?? 'small'}
          onClick={(e) => {
            e.preventDefault()
            setIsEditing(true)
          }}
          disabled={props.disabled}
        >
          <PencilSolid height={16} />
        </Button>
      </div>
    </ModalFieldButton>
  )
})

Url.displayName = 'TaskCustomFieldUrl'
