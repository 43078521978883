import { ChevronDownOutline, PuzzleSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { ProjectPalette } from '@motion/ui/project'

import { WorkspaceTemplateDropdown } from '~/global/components/dropdowns'
import { useProjectDefinition } from '~/global/hooks'
import { useUnsavedChangesPrompt } from '~/global/navigation'
import { useController } from 'react-hook-form'

import { useProjectAIForm } from '../hooks'

type TemplateDropdownProps = {
  onCreateNewTemplate: () => void
}

export function TemplateDropdown({
  onCreateNewTemplate,
}: TemplateDropdownProps) {
  const form = useProjectAIForm()
  const workspace = useController({
    control: form.control,
    name: 'workspaceId',
  })
  const template = useController({
    control: form.control,
    name: 'projectDefinitionId',
  })

  const selectedDefinition = useProjectDefinition(template.field.value)
  const showUnsavedPrompt = useUnsavedChangesPrompt()

  const createNewTemplate = async () => {
    const shouldNavigate =
      !form.formState.isDirty ||
      (await showUnsavedPrompt({
        title: 'Are you sure you want to leave?',
        description:
          'You will lose any details entered here about the project.',
        confirmButtonText: 'Leave to create new project template',
      }))

    if (shouldNavigate) {
      onCreateNewTemplate()
    }
  }

  return (
    <WorkspaceTemplateDropdown
      selectedProjectDefinitionId={template.field.value}
      onChange={(definition) => {
        workspace.field.onChange(definition.workspaceId)
        template.field.onChange(definition.id)
      }}
      onCreateNewTemplate={createNewTemplate}
    >
      <Button
        variant='outlined'
        sentiment={template.fieldState.error ? 'error' : 'neutral'}
        size='small'
      >
        <div className='w-64 flex items-center justify-between gap-4 px-[5px] py-1'>
          <div className='flex gap-2 overflow-hidden'>
            <ProjectPalette color={selectedDefinition?.color ?? 'gray'}>
              <PuzzleSolid className='!text-palette-highlight-default' />
            </ProjectPalette>
            <span className='text-sm text-semantic-neutral-text-default truncate'>
              {selectedDefinition?.name || 'None'}
            </span>
          </div>
          <ChevronDownOutline />
        </div>
      </Button>
    </WorkspaceTemplateDropdown>
  )
}
