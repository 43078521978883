import { keepPreviousData } from '@tanstack/react-query'
import { useGetScheduledEntitiesWithProxies } from '~/global/rpc'

import { useWeekOfSelectedDay } from './use-week-of-selected-day'

const SCH_ENT_STALE_TIME = 1000 * 60 * 2

export const useAgendaEntities = () => {
  const weekOfSelectedDay = useWeekOfSelectedDay()

  return useGetScheduledEntitiesWithProxies(
    {
      include: ['task', 'event'],
      filters: {
        completed: 'include',
        myCalendarsOnly: true,
        scheduled: {
          from: weekOfSelectedDay.start,
          to: weekOfSelectedDay.end,
        },
      },
    },
    {
      // For some reason invalidating query when not on page and coming back to it does not re-grab latest data
      // This is a workaround for that
      refetchOnMount: 'always',
      placeholderData: keepPreviousData,
      staleTime: SCH_ENT_STALE_TIME,
    }
  )
}
