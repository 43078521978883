import { FieldButton } from '@motion/ui/base'

import { WorkspaceSectionContainer } from '~/areas/task-project/components'
import { WorkspacesTreeDropdown } from '~/global/components/dropdowns'
import { useProject, useWorkspaceById } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { FullPathLabel } from './full-path-label'

import { useActionItemForm, useWorkspaceProjectUpdater } from '../hooks'

export function ConnectedInlineWorkspaceProjectField() {
  const { form } = useActionItemForm()
  const { control } = form

  const { field: workspaceIdField } = useController({
    name: 'workspaceId',
    control,
  })
  const { field: projectIdField } = useController({
    name: 'projectId',
    control,
  })

  const selectedWorkspace = useWorkspaceById(workspaceIdField.value)
  const selectedProject = useProject(projectIdField.value)

  const updateWorkspaceProject = useWorkspaceProjectUpdater()

  return (
    <WorkspaceSectionContainer>
      <WorkspacesTreeDropdown
        selectedId={projectIdField.value ?? workspaceIdField.value}
        workspaceId={workspaceIdField.value}
        onChange={(selected) => {
          updateWorkspaceProject(selected.workspaceId, selected.projectId)
        }}
        hideNoProject
      >
        <FieldButton variant='minimal' size='xsmall'>
          <FullPathLabel
            project={selectedProject}
            workspace={selectedWorkspace}
          />
        </FieldButton>
      </WorkspacesTreeDropdown>
    </WorkspaceSectionContainer>
  )
}
