import { SearchSolid, XSolid } from '@motion/icons'
import { useOnValueChange } from '@motion/react-core/hooks'
import { templateStr } from '@motion/react-core/strings'
import { TeamMemberRole } from '@motion/rpc/types'
import { classed } from '@motion/theme'
import {
  ActionList,
  Button,
  PopoverButton,
  PopoverTrigger,
  SearchableList,
  Tag,
  Tooltip,
  UserAvatar,
} from '@motion/ui/base'
import { Checkbox, TextField } from '@motion/ui/forms'
import { uniqueBy } from '@motion/utils/array'
import { titleCase } from '@motion/utils/string'
import { useModalApi } from '@motion/web-common/modals'

import { PrettyLabels } from '~/global/components/labels'
import { useI18N } from '~/global/contexts'
import { useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'

export type TeamMembersTableWorkspace = {
  label: string
  value: string
}

export type TeamMembersTableUser = {
  key: string
  email: string
  role: TeamMemberRole
  editableRole: boolean
  workspaces: TeamMembersTableWorkspace[]

  name?: string
  dateAdded?: string
  notAccepted?: boolean
  profileUrl?: string
}

export type TeamMembersTableEditType = 'role' | 'workspace'

type Email = string

type TeamMembersTableProps = {
  users: TeamMembersTableUser[]
  teamWorkspaces: TeamMembersTableWorkspace[]
  onSelectionChange?: (selection: string[]) => void
  onTeamMembersUpdate: (
    users: Record<Email, TeamMembersTableUser>,
    type: TeamMembersTableEditType,
    isBulk: boolean
  ) => void
  onRemove: (email: string) => void
  isBulkMode: boolean
  showRemoveButton?: boolean
  selectedEmails: string[]
  onSetSelectedEmails: (emails: string[]) => void
}

const TeamMembersTable = ({
  users,
  isBulkMode,
  teamWorkspaces,
  selectedEmails,
  showRemoveButton = true,
  onSetSelectedEmails,
  onTeamMembersUpdate,
  onRemove,
}: TeamMembersTableProps) => {
  const modalApi = useModalApi()

  const { pluralize } = useI18N()

  const hasSelectedEditableUsers = useMemo(
    () =>
      selectedEmails.some((email) =>
        users.find((user) => user.email === email && user.editableRole)
      ),
    [selectedEmails, users]
  )

  const hasDateAdded = users.some((user) => user.dateAdded)

  const handleRoleChange = () => {
    modalApi.open('change-role', {
      selectedEmails,
      onChangeRole: (newRole) => {
        onTeamMembersUpdate(
          users.reduce<Record<Email, TeamMembersTableUser>>((acc, user) => {
            if (selectedEmails.includes(user.email) && user.editableRole) {
              acc[user.email] = { ...user, role: newRole }
            }
            return acc
          }, {}),
          'role',
          isBulkMode
        )
      },
    })
  }

  const handleEditWorkspaces = (mode: 'add' | 'remove') => {
    let displayedWorkspaces = teamWorkspaces
    if (mode === 'remove') {
      const selectedUsers = users.filter((user) =>
        selectedEmails.includes(user.email)
      )

      displayedWorkspaces = uniqueBy(
        selectedUsers.flatMap((user) => user.workspaces),
        (workspace) => workspace.value
      )
    }

    modalApi.open('edit-member-workspace', {
      mode,
      selectedEmails,
      teamWorkspaces: displayedWorkspaces,
      onEditWorkspaces: (bulkWorkspaces) => {
        onTeamMembersUpdate(
          users.reduce<Record<Email, TeamMembersTableUser>>((acc, user) => {
            if (selectedEmails.includes(user.email)) {
              let newWorkspaces = user.workspaces

              if (mode === 'add') {
                newWorkspaces = uniqueBy(
                  [...newWorkspaces, ...bulkWorkspaces],
                  (workspace) => workspace.value
                )
              } else {
                const workspacesToRemove = bulkWorkspaces.map((b) => b.value)

                newWorkspaces = newWorkspaces.filter(
                  (workspace) => !workspacesToRemove.includes(workspace.value)
                )
              }

              acc[user.email] = {
                ...user,
                workspaces: newWorkspaces,
              }
            }
            return acc
          }, {}),
          'workspace',
          isBulkMode
        )
      },
    })
  }

  return (
    <div
      className={twMerge(
        'grid',
        hasDateAdded && !isBulkMode
          ? 'grid-cols-[32px,minmax(0,1fr),125px,125px,150px,135px]'
          : 'grid-cols-[32px,minmax(0,1fr),125px,175px,135px]'
      )}
    >
      {
        // Header
        isBulkMode ? (
          <div className='grid grid-cols-[auto,1fr,auto] col-span-full h-10 items-center bg-semantic-neutral-bg-subtle px-2'>
            <Checkbox
              onChange={(isChecked) => {
                if (isChecked) {
                  onSetSelectedEmails(users.map(({ email }) => email))
                } else {
                  onSetSelectedEmails([])
                }
              }}
              checked={selectedEmails.length > 0}
            />
            <TableHeading>
              {templateStr('{{selectCount}} {{user}} selected', {
                selectCount: selectedEmails.length,
                user: pluralize(selectedEmails.length, 'user', 'users'),
              })}
            </TableHeading>

            <div className='flex gap-3'>
              <Button
                size='small'
                sentiment='neutral'
                onClick={handleRoleChange}
                disabled={
                  selectedEmails.length === 0 || !hasSelectedEditableUsers
                }
              >
                Change Role
              </Button>
              <Button
                size='small'
                sentiment='neutral'
                onClick={() => handleEditWorkspaces('add')}
                disabled={selectedEmails.length === 0}
              >
                Add to workspace(s)
              </Button>
              <Button
                size='small'
                sentiment='neutral'
                onClick={() => handleEditWorkspaces('remove')}
                disabled={selectedEmails.length === 0}
              >
                Remove from workspace(s)
              </Button>
            </div>
          </div>
        ) : (
          <div className='grid grid-cols-subgrid col-span-full border-b border-semantic-neutral-border-hover h-10 items-center px-2'>
            <div></div>
            <TableHeading>Name</TableHeading>
            <TableHeading>Role</TableHeading>
            {hasDateAdded && <TableHeading>Date Added</TableHeading>}
            <TableHeading>Workspaces</TableHeading>
          </div>
        )
      }
      {users.length === 0 && (
        <div className='col-span-full h-10 flex items-center px-2 text-semantic-neutral-text-subtle'>
          No team members found
        </div>
      )}
      {users.map(
        ({
          email,
          workspaces,
          role,
          editableRole,
          name,
          dateAdded,
          notAccepted,
          profileUrl,
        }) => (
          // Table row
          <div
            key={email}
            className='grid grid-cols-subgrid col-span-full h-10 border-semantic-neutral-border-default border-b items-center px-2'
          >
            <div>
              {isBulkMode && (
                <Checkbox
                  checked={selectedEmails.includes(email)}
                  onChange={(isChecked) => {
                    if (isChecked) {
                      onSetSelectedEmails([...selectedEmails, email])
                    } else {
                      onSetSelectedEmails(
                        selectedEmails.filter((e) => e !== email)
                      )
                    }
                  }}
                />
              )}
            </div>
            <div className='flex items-center gap-2'>
              <UserAvatar
                id={email}
                name={name ?? email}
                profileUrl={profileUrl}
                size={20}
              />
              <p className='text-sm truncate'>
                {name && name.trim().toLowerCase() !== email.toLowerCase() ? (
                  <>
                    <b>{name}</b> ({email})
                  </>
                ) : (
                  email
                )}
              </p>
              {notAccepted && (
                <div className='shrink-0 mr-1'>
                  <Tag size='small' color='yellow' variant='subtle'>
                    Not Accepted
                  </Tag>
                </div>
              )}
            </div>
            <div>
              <RoleSelector
                disabled={!editableRole}
                onAction={(roleOption) => {
                  onTeamMembersUpdate(
                    users.reduce<Record<string, TeamMembersTableUser>>(
                      (acc, user) => {
                        if (user.email === email) {
                          acc[user.email] = {
                            ...user,
                            role: roleOption as TeamMemberRole,
                          }
                        }
                        return acc
                      },
                      {}
                    ),
                    'role',
                    false
                  )
                }}
                role={role}
              />
            </div>
            {!isBulkMode && dateAdded && (
              <div>
                <p className='text-sm'>{dateAdded}</p>
              </div>
            )}
            <div>
              <WorkspaceSelector
                workspaces={workspaces}
                teamWorkspaces={teamWorkspaces}
                onSelect={(item) => {
                  onTeamMembersUpdate(
                    users.reduce<Record<string, TeamMembersTableUser>>(
                      (acc, user) => {
                        if (user.email === email) {
                          acc[user.email] = {
                            ...user,
                            workspaces: item,
                          }
                        }
                        return acc
                      },
                      {}
                    ),
                    'workspace',
                    false
                  )
                }}
              />
            </div>
            {showRemoveButton && (
              <div className='justify-self-end'>
                <Button
                  size='xsmall'
                  sentiment='neutral'
                  variant='muted'
                  onClick={() => onRemove(email)}
                >
                  Remove from team
                </Button>
              </div>
            )}
          </div>
        )
      )}
    </div>
  )
}

type ConnectedTeamMembersTableProps = Omit<
  TeamMembersTableProps,
  'isBulkMode' | 'selectedEmails' | 'onSetSelectedEmails'
>

export const ConnectedTeamMembersTable = ({
  onSelectionChange,
  onRemove,
  users,
  onTeamMembersUpdate,
  ...props
}: ConnectedTeamMembersTableProps) => {
  const [isBulkMode, setIsBulkMode] = useState(false)
  const [isSearchMode, setIsSearchMode] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedEmails, setSelectedEmails] = useState<string[]>([])

  useOnValueChange(selectedEmails, () => {
    if (onSelectionChange) {
      onSelectionChange(selectedEmails)
    }
  })

  const sortedUsers = useMemo(() => {
    return [...users].sort((a, b) => {
      if (a.notAccepted && !b.notAccepted) {
        return 1
      } else if (!a.notAccepted && b.notAccepted) {
        return -1
      }

      if (a.name && b.name) {
        return a.name.localeCompare(b.name)
      } else if (a.name) {
        return -1
      } else if (b.name) {
        return 1
      }

      return a.email.localeCompare(b.email)
    })
  }, [users])

  const filteredUsers = useMemo(() => {
    if (!searchQuery) {
      return sortedUsers
    }

    return sortedUsers.filter(
      (user) =>
        user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.name?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  }, [sortedUsers, searchQuery])

  const handleRemove = (email: string) => {
    setSelectedEmails(selectedEmails.filter((e) => e !== email))
    onRemove(email)
  }

  return (
    <div className='flex flex-col gap-[10px]'>
      <div className='flex gap-2 h-7'>
        {isBulkMode ? (
          <Button
            sentiment='neutral'
            size='small'
            onClick={() => {
              setIsBulkMode(false)
              setSelectedEmails([])
            }}
          >
            Cancel
          </Button>
        ) : (
          <Button
            sentiment='neutral'
            size='small'
            onClick={() => {
              setIsBulkMode(true)
            }}
          >
            Bulk edit role and workspaces
          </Button>
        )}
        {!isSearchMode ? (
          <Button
            sentiment='neutral'
            size='small'
            onClick={() => {
              setIsSearchMode(true)
            }}
          >
            <SearchSolid />
            Filter list
          </Button>
        ) : (
          <div className='w-64'>
            <TextField
              autoFocus
              size='small'
              placeholder='Type a name or email to filter'
              value={searchQuery}
              onChange={setSearchQuery}
              suffix={
                <XSolid
                  role='button'
                  onClick={() => {
                    setIsSearchMode(false)
                    setSearchQuery('')
                  }}
                  aria-label='Clear search'
                />
              }
            />
          </div>
        )}
      </div>
      <TeamMembersTable
        isBulkMode={isBulkMode}
        selectedEmails={selectedEmails}
        onSetSelectedEmails={setSelectedEmails}
        onRemove={handleRemove}
        users={filteredUsers}
        onTeamMembersUpdate={(users, type, isBulk) => {
          onTeamMembersUpdate(users, type, isBulk)

          if (isBulk) {
            setSelectedEmails([])
          }
        }}
        {...props}
      />
    </div>
  )
}

type RoleSelectorProps = {
  onAction: (value: TeamMemberRole) => void
  role?: TeamMemberRole
  disabled?: boolean
}

export const RoleSelector = ({
  onAction,
  role,
  disabled,
}: RoleSelectorProps) => {
  return (
    <PopoverTrigger
      placement='bottom'
      renderPopover={({ close }) => (
        <ActionList
          sections={[
            {
              items: [TeamMemberRole.ADMIN, TeamMemberRole.MEMBER].map(
                (roleOption) => ({
                  content: titleCase(roleOption),
                  onAction: () => {
                    onAction(roleOption)
                    close()
                  },
                })
              ),
            },
          ]}
        />
      )}
    >
      <PopoverButton
        size='small'
        className='min-w-24 w-fit px-2 h-full'
        disabled={disabled}
      >
        {role ? titleCase(role) : 'Select role'}
      </PopoverButton>
    </PopoverTrigger>
  )
}

type WorkspaceSelectorProps = {
  workspaces: TeamMembersTableWorkspace[]
  teamWorkspaces: TeamMembersTableWorkspace[]
  onSelect: (items: TeamMembersTableWorkspace[]) => void
}

export const WorkspaceSelector = ({
  workspaces,
  teamWorkspaces,
  onSelect,
}: WorkspaceSelectorProps) => {
  return (
    <PopoverTrigger
      placement='bottom'
      renderPopover={() => (
        <>
          <SearchableList
            itemType='checkbox'
            items={teamWorkspaces}
            computeKey={(item) => item.label}
            renderItem={(item) => (
              <Tooltip
                renderContent={() => {
                  if (item.label.length > 20) {
                    return <div>{item.label}</div>
                  }
                }}
              >
                <div className='max-w-[120px] truncate'>{item.label}</div>
              </Tooltip>
            )}
            computeSelected={(item) =>
              workspaces.filter((workspace) => workspace.value === item.value)
                .length > 0
            }
            onSelect={onSelect}
            inputProps={{ placeholder: 'Choose workspaces...' }}
          />
          <div className='border-t border-semantic-neutral-border-subtle flex justify-between px-3 py-2'>
            <Button
              size='small'
              sentiment='neutral'
              onClick={() => onSelect([])}
            >
              Clear
            </Button>
            <Button
              size='small'
              sentiment='neutral'
              onClick={() => onSelect(teamWorkspaces)}
            >
              Select all
            </Button>
          </div>
        </>
      )}
    >
      <PopoverButton size='small' className='h-full'>
        {workspaces.length ? (
          <div className='flex flex-grow overflow-hidden'>
            <PrettyLabels
              values={workspaces.map((item) => ({
                id: item.label,
                name: item.label,
                color: 'red',
              }))}
            />
          </div>
        ) : (
          'Select a workspace'
        )}
      </PopoverButton>
    </PopoverTrigger>
  )
}

const TableHeading = classed(
  'h1',
  'text-sm font-medium text-semantic-neutral-text-default'
)
