import { getTypedEntries, values } from '@motion/utils/object'
import {
  type DashboardViewDefinitionV2,
  type DateFilterSchema,
  type DefinedFilterSchema,
  type DefinedRelativeDateFilterSchema,
  type EmptyFilterSchema,
  type NowRelativeDateSchema,
  type RelativeDateRangeFilterSchema,
  type ViewDefinitionFiltersSchema,
} from '@motion/zod/client'

import {
  parseRelativeDate,
  type RelativeDateInfo,
} from '~/areas/project-management/filters/components/v2/buttons/date-filter/utils'
import { DateTime } from 'luxon'

export function migrateViewDateOperatorsForDashboardView(
  view: DashboardViewDefinitionV2
) {
  view.cells.forEach((cell) => {
    if ('groupBy' in cell.chart) {
      cell.chart.groupBy
        .filter((g) => g.range != null)
        .forEach((g) => {
          const range = migrateDateOperator(g.range)
          if (range == null) return
          if (range.operator === 'relative-date-range') {
            g.range = range
          }
        })
    }

    migrateFilters(cell.chart.filters.tasks.filters)
    migrateCustomFields(cell.chart.filters.tasks.filters.customFields)

    migrateFilters(cell.chart.filters.projects.filters)
    migrateCustomFields(cell.chart.filters.projects.filters.customFields)
  })

  return view
}

function migrateCustomFields<
  T extends ViewDefinitionFiltersSchema['tasks']['filters']['customFields'],
>(obj: T | undefined) {
  if (obj == null) return

  for (const filter of values(obj)) {
    migrateFilters(filter)
  }
}

type Filters =
  | ViewDefinitionFiltersSchema['tasks']['filters']
  | ViewDefinitionFiltersSchema['projects']['filters']
  | NonNullable<
      ViewDefinitionFiltersSchema['tasks']['filters']['customFields']
    >[string]

function migrateFilters<T extends Filters>(obj: T) {
  getTypedEntries(obj).forEach((e) => {
    const filter = e.value
    if (filter == null || typeof filter !== 'object' || !('operator' in filter))
      return
    if (isDateFilterSchema(filter)) {
      const migrated = migrateDateOperator(filter)
      if (migrated == null) return
      migrated.inverse = filter.inverse ?? false
      // @ts-expect-error - dynamic
      obj[e.key] = migrated
    }
  })
}

const EXCLUSIVE_DATE_OPERATORS = [
  'defined-relative',
  'relative',
  'relative-date-range',
]
const LOGICAL_OPERATORS = ['gt', 'gte', 'lt', 'lte', 'equals']
function isDateFilterSchema(filter: any): filter is DateFilterSchema {
  if (filter == null) return false
  if (typeof filter !== 'object') return false
  if (!('operator' in filter)) return false
  if (EXCLUSIVE_DATE_OPERATORS.includes(filter.operator as string)) return true

  if (LOGICAL_OPERATORS.includes(filter.operator as string)) {
    return 'value' in filter && typeof filter.value === 'string'
  }

  if (filter.operator === 'range' && typeof filter.value?.from === 'string')
    return true

  return false
}

function migrateDateOperator(
  filter: DateFilterSchema | undefined
):
  | DefinedFilterSchema
  | EmptyFilterSchema
  | RelativeDateRangeFilterSchema
  | undefined {
  if (filter == null) return undefined

  if (filter.operator === 'defined') return filter
  if (filter.operator === 'empty') return filter
  if (filter.operator === 'relative-date-range') return filter
  if (filter.operator === 'range' && typeof filter.value.from !== 'string')
    return undefined

  if (filter.operator === 'range') {
    return {
      operator: 'relative-date-range',
      inverse: filter.inverse ?? false,
      from: filter.value.from,
      to: filter.value.to,
    }
  }

  if (filter.operator === 'relative') {
    const info = parseRelativeDate(filter)
    const value = parseInt(info.value)
    const rangeEnd: NowRelativeDateSchema = {
      unit: convertUnit(info.unit),
      mode: 'edge',
      offset: value,
    }

    if (value > 0) {
      return {
        operator: 'relative-date-range',
        inverse: filter.inverse ?? false,
        from: { mode: 'edge', unit: 'day', offset: 0 },
        to: rangeEnd,
      }
    }

    return {
      operator: 'relative-date-range',
      inverse: filter.inverse ?? false,
      to: { mode: 'edge', unit: 'day', offset: 0 },
      from: rangeEnd,
    }
  }

  if (filter.operator === 'equals') {
    return {
      operator: 'relative-date-range',
      from: filter.value,
      to: filter.value,
      inverse: filter.inverse ?? false,
    }
  }

  if (filter.operator === 'gt') {
    return {
      operator: 'relative-date-range',
      from: DateTime.fromISO(filter.value).plus({ day: 1 }).toISO(),
      to: null,
      inverse: filter.inverse ?? false,
    }
  }

  if (filter.operator === 'gte') {
    return {
      operator: 'relative-date-range',
      from: DateTime.fromISO(filter.value).toISO(),
      to: null,
      inverse: filter.inverse ?? false,
    }
  }

  if (filter.operator === 'lt') {
    return {
      operator: 'relative-date-range',
      from: null,
      to: DateTime.fromISO(filter.value).minus({ day: 1 }).toISO(),
      inverse: filter.inverse ?? false,
    }
  }

  if (filter.operator === 'lte') {
    return {
      operator: 'relative-date-range',
      from: null,
      to: DateTime.fromISO(filter.value).toISO(),
      inverse: filter.inverse ?? false,
    }
  }

  if (filter.operator === 'defined-relative') {
    return convertDefinedRelative(filter)
  }

  return undefined
}

function convertUnit(
  unit: RelativeDateInfo['unit']
): NowRelativeDateSchema['unit'] {
  switch (unit) {
    case 'weeks':
      return 'week'
    case 'months':
      return 'month'
    default:
      return 'day'
  }
}

function convertDefinedRelative(
  filter: DefinedRelativeDateFilterSchema
): RelativeDateRangeFilterSchema {
  switch (filter.name) {
    case 'today':
      return generate('day', 0, 0)
    case 'yesterday':
      return generate('day', -1, -1)
    case 'tomorrow':
      return generate('day', 1, 1)
    case 'next-7-days':
      return generate('day', 0, 6)
    case 'next-14-days':
      return generate('day', 0, 13)
    case 'next-30-days':
      return generate('day', 0, 29)
    case 'last-7-days':
      return generate('day', -6, 0)
    case 'last-14-days':
      return generate('day', -13, 0)
    case 'last-30-days':
      return generate('day', -29, 0)
    case 'last-60-days':
      return generate('day', -59, 0)
    case 'this-week':
      return generate('week', 0, 0)
    case 'last-week':
      return generate('week', -1, -1)
    case 'next-week':
      return generate('week', 1, 1)
    case 'this-month':
      return generate('month', 0, 0)
    case 'last-month':
      return generate('month', -1, -1)
    case 'next-month':
      return generate('month', 1, 1)
  }
}

function generate(
  unit: NowRelativeDateSchema['unit'],
  fromOffset: number,
  toOffset: number
): RelativeDateRangeFilterSchema {
  return {
    operator: 'relative-date-range',
    inverse: false,
    from: {
      unit,
      offset: fromOffset,
      mode: 'edge',
    },
    to: {
      unit,
      offset: toOffset,
      mode: 'edge',
    },
  }
}
