import { getColorName } from '@motion/ui/palette'

import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { ModalFieldButton } from '~/areas/task-project/components'
import { StageBadge } from '~/global/components/badges'
import { ColorDropdown } from '~/global/components/dropdowns'
import { useController } from 'react-hook-form'

export const ControlledColorField = () => {
  const { form } = useFlowTemplateForm()
  const { control } = form

  const { field, fieldState } = useController({
    name: 'color',
    control,
  })

  const { field: stageColorField } = useController({
    name: 'stages.0.color',
    control,
  })

  return (
    <ColorDropdown
      selectedColor={field.value}
      onChange={(color) => {
        field.onChange(color)

        stageColorField.onChange(color)
      }}
      sentiment={fieldState.error ? 'error' : 'neutral'}
    >
      <ModalFieldButton label='Color'>
        <StageBadge value={{ color: field.value }} />
        {getColorName(field.value)}
      </ModalFieldButton>
    </ColorDropdown>
  )
}
