import { type TeamSub } from '@motion/rpc-types'

export const useIsTeamTrialSetToCancel = (
  teamSubscription?: TeamSub | null
): boolean => {
  return (
    (teamSubscription?.subscription as any)?.status === 'trialing' &&
    (teamSubscription?.subscription as any)?.cancel_at_period_end &&
    teamSubscription?.status !== 'canceled'
  )
}
