import { AutoscheduleStarGradientSolid, XSolid } from '@motion/icons'
import {
  Button,
  GradientButton,
  IconButton,
  Text,
  UnstyledModal,
} from '@motion/ui/base'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { ErrorBoundary } from '~/global/components'

import { ModalContent, ModalFooter, ModalHeader } from './components'
import { BlogPostWorkflowFieldset, WorkflowField } from './fields'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'ai-workflow-modal': never
  }
}

type AIWorkflowModalProps = ModalTriggerComponentProps<'ai-workflow-modal'>

export function AIWorkflowModal({ ...rest }: AIWorkflowModalProps) {
  return (
    <UnstyledModal
      data-testid='ai-workflow-modal'
      visible
      onClose={() => rest.close()}
      withAnimation
      modalClassName='max-h-full bg-modal-bg border border-semantic-neutral-border-default rounded-lg shadow-lg w-[700px] max-w-[calc(100vw-56px)]'
      overlayClassName='bg-modal-overlay'
    >
      <ErrorBoundary renderFallback={({ error }) => <div>oops</div>}>
        <InnerAIWorkflowModal {...rest} />
      </ErrorBoundary>
    </UnstyledModal>
  )
}

function InnerAIWorkflowModal({ close }: Omit<AIWorkflowModalProps, 'open'>) {
  return (
    <form
      className='flex flex-col gap-1 max-h-screen'
      onSubmit={(e) => {
        e.preventDefault()
        close()

        // TODO
      }}
    >
      <ModalHeader>
        <Text
          as='h1'
          weight='semibold'
          size='sm'
          className='flex items-center gap-2 text-gradient bg-purple-gradient bg-opacity-100'
        >
          Run AI Workflow
          <AutoscheduleStarGradientSolid
            className='size-4'
            stopColors={['#BC6BFC', '#F147EA']}
          />
        </Text>
        <IconButton
          icon={XSolid}
          sentiment='neutral'
          variant='muted'
          onClick={close}
        />
      </ModalHeader>

      <ModalContent>
        <WorkflowField />
        <BlogPostWorkflowFieldset />
      </ModalContent>

      <ModalFooter>
        <Button
          sentiment='neutral'
          variant='muted'
          onClick={close}
          shortcut='esc'
        >
          Cancel
        </Button>

        <GradientButton type='submit' sentiment='purple' shortcut='mod+s'>
          Run workflow
        </GradientButton>
      </ModalFooter>
    </form>
  )
}
