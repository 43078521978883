import { templateStr } from '@motion/react-core/strings'
import { FormModal } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { isEmailValid } from '@motion/utils/string'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useSendEmailVerification } from '~/global/rpc/user'
import { useState } from 'react'

import { SuccessModal } from './success-modal'

export function ConnectedChangeEmailModal({
  close,
}: ModalTriggerComponentProps<'change-email'>) {
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [email, setEmail] = useState('')
  const { mutateAsync: onChangeEmail } = useSendEmailVerification()

  if (showSuccessModal) {
    return (
      <SuccessModal
        description='Click the link in the email to continue changing your email.'
        title={
          <span className='whitespace-pre'>
            {templateStr('We’ve sent an email to \n{{email}}', {
              email,
            })}
          </span>
        }
        onClose={close}
        visible
      />
    )
  }

  return (
    <ChangeEmailModal
      close={close}
      email={email}
      onChangeEmail={setEmail}
      onSubmit={async () => {
        recordAnalyticsEvent('ACCOUNT_SETTINGS_CHANGE_EMAIL')
        await onChangeEmail({ email })
        setShowSuccessModal(true)
      }}
    />
  )
}

export type ChangeEmailModalProps =
  ModalTriggerComponentProps<'change-email'> & {
    email: string
    onChangeEmail: (email: string) => void
    onSubmit: () => Promise<void>
  }
export function ChangeEmailModal({
  close,
  email,
  onChangeEmail,
  onSubmit,
}: ChangeEmailModalProps) {
  const [emailWithError, setEmailWithError] = useState<null | string>(null)

  return (
    <FormModal
      onClose={close}
      submitAction={{
        text: 'Continue',
        disabled: !isEmailValid(email),
        onAction: async () => {
          setEmailWithError(null)
          try {
            await onSubmit()
          } catch (err) {
            setEmailWithError(email)
          }
        },
      }}
      title='Change email'
      visible
    >
      <div className='flex flex-col items-start gap-4 self-stretch font-normal w-[418px]'>
        <span className='text-semantic-neutral-text-default align-justify text-sm'>
          Enter new email
        </span>
        <div className='flex flex-col gap-3 w-full'>
          <TextField
            fullWidth
            onChange={onChangeEmail}
            placeholder='Email'
            value={email}
          />
          <span className='text-semantic-neutral-text-subtle text-[12px]'>
            We’ll send a verification link to your email.
          </span>
          {emailWithError && (
            <span className='text-[12px] font-normal text-semantic-error-text-default'>
              {emailWithError} already has an existing account. If this is your
              account, log in and delete the account before switching your
              email.
            </span>
          )}
        </div>
      </div>
    </FormModal>
  )
}
