import { useDebouncedCallback } from '@motion/react-core/hooks'

import {
  MentionComponentBase,
  MentionComponentLink,
} from '~/areas/notes/components'
import { ProjectBadge } from '~/global/components/badges'
import { useProject } from '~/global/hooks'
import { useProjectModalUrl } from '~/global/navigation'
import { useRef, useState } from 'react'

import { ProjectMentionHoverCard } from './components/project-mention-hover-card'

type ConnectedProjectMentionProps = {
  id: string
  withHoverCard?: boolean
}

export const ConnectedProjectMention = ({
  id,
  withHoverCard = false,
}: ConnectedProjectMentionProps) => {
  const mentionRef = useRef<HTMLAnchorElement>(null)

  const [isHovered, setIsHovered] = useState(false)

  const debouncedSetIsHovered = useDebouncedCallback(setIsHovered, 350)

  const project = useProject(id)
  const buildProjectModalUrl = useProjectModalUrl()

  const handleMouseEnter = () => {
    debouncedSetIsHovered(true)
  }

  const handleMouseLeave = () => {
    debouncedSetIsHovered.flush()
    setIsHovered(false)
  }

  return (
    <MentionComponentLink
      ref={mentionRef}
      url={buildProjectModalUrl({ project: id })}
      onMouseEnter={withHoverCard ? handleMouseEnter : undefined}
      onMouseLeave={withHoverCard ? handleMouseLeave : undefined}
    >
      <MentionComponentBase
        badge={<ProjectBadge value={project} hideTooltip />}
        label={project?.name ?? 'No project'}
      />
      {isHovered && project != null && (
        <ProjectMentionHoverCard project={project} />
      )}
    </MentionComponentLink>
  )
}
