import { ProjectCubeSolid, PuzzleSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import {
  type ProjectDefinitionSchema,
  type ProjectSchema,
} from '@motion/rpc-types'
import { ActionList, HoverCard } from '@motion/ui/base'
import { ProjectPalette } from '@motion/ui/project'
import { capitalize } from '@motion/utils/string'

import { ProjectCountBadge } from '~/areas/flows/components'
import { ConnectedProjectIcon } from '~/global/components'
import { useProjectModalUrl } from '~/global/navigation'
import { useMemo } from 'react'

import { useFlowTemplateModalUrl } from '../flow-template-modal'

export type ProjectTemplateCountHoverCardProps = {
  items: (ProjectSchema | ProjectDefinitionSchema)[]
  type: 'project' | 'template'
  parentType: 'stage' | 'template'
  truncateLabel?: boolean
}

export function ProjectTemplateCountHoverCard({
  items,
  type,
  parentType,
  truncateLabel = false,
}: ProjectTemplateCountHoverCardProps) {
  const Icon = type === 'project' ? ProjectCubeSolid : PuzzleSolid

  const buildProjectUrl = useProjectModalUrl()
  const buildTemplateUrl = useFlowTemplateModalUrl()

  const actionItems = useMemo(
    () =>
      items.map((item) => ({
        prefix:
          type === 'project' ? (
            <ConnectedProjectIcon className='size-4' projectId={item.id} />
          ) : (
            <ProjectPalette color={item.color} key={item.id}>
              <PuzzleSolid className='size-4 text-palette-highlight-default' />
            </ProjectPalette>
          ),
        content: item.name,
        url:
          type === 'project'
            ? buildProjectUrl({
                project: item.id,
              })
            : buildTemplateUrl({
                templateId: item.id,
                template: 'edit',
              }),
      })),
    [items, type, buildProjectUrl, buildTemplateUrl]
  )

  const fullLabel = templateStr('{{itemCount}} {{itemLabel}}', {
    itemCount: items.length,
    itemLabel: type === 'project' ? 'Active projects' : 'templates',
  })

  const truncatedLabel = items.length

  return (
    <HoverCard
      placement='bottom-start'
      renderContent={() => {
        const title = templateStr(
          '{{parentType}} used in {{itemCount}} {{itemLabel}}',
          {
            parentType: capitalize(parentType),
            itemCount: items.length,
            itemLabel: type === 'project' ? 'active projects' : 'templates',
          }
        )
        if (items.length === 0) {
          return (
            <div className='text-semantic-neutral-text-subtle text-2xs font-medium px-2 py-3'>
              {title}
            </div>
          )
        }
        return (
          <ActionList
            sections={[
              {
                title: (
                  <span className='text-semantic-neutral-text-subtle text-2xs !font-medium'>
                    {title}
                  </span>
                ),
                items: actionItems,
              },
            ]}
          />
        )
      }}
    >
      <ProjectCountBadge>
        <Icon className='size-3' />
        {truncateLabel ? truncatedLabel : fullLabel}
      </ProjectCountBadge>
    </HoverCard>
  )
}
