import {
  type StageDefinitionSchema,
  type UpdateStageDefinitionRequestSchema,
} from '@motion/rpc-types'
import { showToast } from '@motion/ui/base'

import { useUpdateStageDefinition as useUpdateStageDefinitionCall } from '~/global/rpc/v2'
import { useCallback } from 'react'

export function useUpdateStageDefinition() {
  const { mutateAsync: updateStageDefinition } = useUpdateStageDefinitionCall()

  return useCallback(
    async (
      stageDefinitionId: StageDefinitionSchema['id'],
      workspaceId: string,
      updates: UpdateStageDefinitionRequestSchema
    ) => {
      const response = await updateStageDefinition({
        id: stageDefinitionId,
        workspaceId,
        definition: updates.definition,
      })

      const savedStageDefinition =
        response.models[response.meta.model][response.id]

      showToast('success', 'Stage updated')

      return savedStageDefinition
    },
    [updateStageDefinition]
  )
}
