import { type ShareWithSchema } from '@motion/zod/client'

import { ConnectedUserLabel, UserLabel } from '~/global/components/labels'
import { useTeamMembers } from '~/global/hooks/team'

type TeamMemberLabelProps = {
  targetId: ShareWithSchema['targetId']
}

export const TeamMemberLabel = ({ targetId }: TeamMemberLabelProps) => {
  const teamMembers = useTeamMembers()

  const teamMember = teamMembers.find(
    (teamMember) => teamMember.userId === targetId
  )

  if (!teamMember) {
    return <ConnectedUserLabel userId={targetId} />
  }

  return (
    <UserLabel
      value={{
        ...teamMember.user,
        deleted: teamMember.user.deletedTime != null,
      }}
    />
  )
}
