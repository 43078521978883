import {
  type ApiTypes,
  type ApiUseMutationOptions,
  createUseMutation,
  createUseQuery,
  useQueryOptionsFactory,
} from '@motion/rpc'
import {
  getCacheEntry,
  getCacheEntryValue,
  type ModelCache,
  MotionCache,
  type OptimisticUpdateValue,
} from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import {
  type NormalTaskSchema,
  type RecurringTaskSchema,
  type TaskSchema,
} from '@motion/zod/client'

import {
  type QueryClient,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query'
import {
  applyOptimisticScheduleEntityUpdateForTask,
  applyOptimisticTaskCreate,
  applyOptimisticTaskDelete,
  applyTaskCreateToCaches,
  getTaskQueryFilters,
} from '~/global/cache'
import { DateTime } from 'luxon'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { getBulkOptimisticTaskUpdates } from './internals'

type QueryApi = ApiTypes<typeof API.tasksV2.queryTasks>
export const useTasksV2 = <TOut = TaskSchema>(
  args: QueryApi['args'],
  opts: Omit<
    UseQueryOptions<QueryApi['queryFnData'], Error, TOut[]>,
    'queryKey'
  >
) => {
  const queryOptionsOf = useQueryOptionsFactory(API.tasksV2.queryTasks)
  const queryArgs = queryOptionsOf(args, opts as any)

  return useQuery({
    ...queryArgs,
    notifyOnChangeProps: ['error', 'data', 'dataUpdatedAt'],
    select: (data: QueryApi['queryFnData']) => {
      return data.ids.map((id) => data.models.tasks[id])
    },
  })
}

export function getAllTaskRelationIds(task: TaskSchema | RecurringTaskSchema) {
  const ids: string[] = []

  if ('parentRecurringTaskId' in task) {
    ids.push(task.parentRecurringTaskId)
  }
  if ('parentChunkTaskId' in task) {
    ids.push(task.parentChunkTaskId)
  }

  if ('chunkIds' in task && Array.isArray(task.chunkIds)) {
    ids.push(...task.chunkIds)
  }

  if ('blockedByTaskIds' in task && Array.isArray(task.blockedByTaskIds)) {
    ids.push(...task.blockedByTaskIds)
  }

  if ('blockingTaskIds' in task && Array.isArray(task.blockingTaskIds)) {
    ids.push(...task.blockingTaskIds)
  }

  return ids.filter(Boolean)
}

function hasAllRelationsInCache(
  client: QueryClient,
  task: TaskSchema | RecurringTaskSchema | undefined
) {
  const ids = task ? getAllTaskRelationIds(task) : []
  return ids.every(
    (id) =>
      getCacheEntry(client, 'tasks', id) ??
      getCacheEntry(client, 'recurringTasks', id)
  )
}

export type TaskByIdApi = ApiTypes<typeof API.tasksV2.getTaskById>
type TaskByIdApiArgs = Omit<TaskByIdApi['args'], 'include' | 'id'> & {
  id: TaskByIdApi['args']['id'] | null | undefined
}

export const useTaskByIdV2 = (
  args: TaskByIdApiArgs,
  opts?: TaskByIdApi['UseQueryOptions']
): UseQueryResult<TaskSchema | RecurringTaskSchema | undefined> => {
  const { id } = args
  const client = useQueryClient()

  const taskId = id ?? ''

  const cachedTask = getCacheEntry(client, 'tasks', taskId)
  const cachedRecurring = getCacheEntry(client, 'recurringTasks', taskId)
  const cached = cachedTask ?? cachedRecurring

  const queryOptionsOf = useQueryOptionsFactory(API.tasksV2.getTaskById)
  const queryArgs = queryOptionsOf(
    {
      ...args,
      id: taskId,
      include: API.tasksV2.taskAllIncludes,
    },
    { enabled: Boolean(taskId), ...opts }
  )

  const hasAllDataInCache = hasAllRelationsInCache(client, cached?.value)

  const initialData: TaskByIdApi['data'] | undefined =
    hasAllDataInCache && cached
      ? {
          id: taskId,
          meta: {
            model: cachedTask ? 'tasks' : 'recurringTasks',
          },
          models: {
            tasks: cachedTask
              ? {
                  [taskId]: cachedTask.value,
                }
              : {},
            chunks: {},
            recurringTasks: cachedRecurring
              ? {
                  [taskId]: cachedRecurring.value,
                }
              : {},
            calendarEvents: {},
            uploadedFiles: {},
          },
        }
      : undefined

  const fetchResult = useQuery({
    ...queryArgs,
    initialData: initialData as unknown as TaskByIdApi['queryFnData'],
    initialDataUpdatedAt: initialData ? cached?.updatedAt : undefined,
    enabled: queryArgs.enabled,
    gcTime: 0,
    notifyOnChangeProps: ['error', 'data', 'dataUpdatedAt'],
    select: (data: TaskByIdApi['queryFnData']) => {
      return data.models[data.meta.model][data.id]
    },
  })

  return fetchResult
}

export const useFeedById = createUseQuery(API.feedV2.getFeedById, {
  select: (data) => ({
    feed: Object.values(data.models.feedEntries).reverse(),
    hasActivityOutsideWindow: data.meta.hasActivityOutsideWindow,
  }),
})

export type TaskV2QueryRequest = ApiTypes<typeof API.tasksV2.queryTasks>['args']
export type TaskV2Filter = TaskV2QueryRequest['filters'][number]

type LazyByIdApi = ApiTypes<typeof API.tasksV2.getLazyTaskById>
export const useReadTaskFn = () => {
  const client = useQueryClient()
  const queryOptionsOf = useQueryOptionsFactory(API.tasksV2.getLazyTaskById)

  return useCallback(
    async (
      id: LazyByIdApi['args']['id'],
      opts?: LazyByIdApi['UseQueryOptions']
    ): Promise<TaskSchema | RecurringTaskSchema | undefined> => {
      const cached =
        getCacheEntryValue(client, 'tasks', id) ??
        getCacheEntryValue(client, 'recurringTasks', id)

      const hasAllDataInCache = hasAllRelationsInCache(client, cached)

      if (cached && hasAllDataInCache) return cached

      const queryArgs = queryOptionsOf(
        {
          id,
          include: API.tasksV2.taskAllIncludes,
        },
        opts
      )

      const response = await client.fetchQuery(queryArgs)
      return response.models[response.meta.model][response.id]
    },
    [client, queryOptionsOf]
  )
}

export const useReadTasksFn = () => {
  const client = useQueryClient()
  const queryOptionsOf = useQueryOptionsFactory(API.tasksV2.queryTasks)

  return useCallback(
    async (args: QueryApi['args'], opts?: QueryApi['UseQueryOptions']) => {
      const queryArgs = queryOptionsOf(
        {
          ...args,
          include: API.tasksV2.taskAllIncludes,
        },
        opts
      )

      const response = await client.fetchQuery(queryArgs)
      return Object.entries(response.models[response.meta.model]).map(
        ([_, val]) => val
      )
    },
    [client, queryOptionsOf]
  )
}

type PastDueApi = ApiTypes<typeof API.tasksV2.getPastDeadlineTasks>
export const usePastDeadlineTasks = <TOut = TaskSchema>(
  args: Omit<PastDueApi['args'], 'include'> = READONLY_EMPTY_OBJECT,
  opts?: Omit<
    UseQueryOptions<PastDueApi['queryFnData'], Error, TOut[]>,
    'queryKey'
  >
) => {
  const queryOptionsOf = useQueryOptionsFactory(
    API.tasksV2.getPastDeadlineTasks
  )
  const queryArgs = queryOptionsOf(
    {
      ...args,
      include: API.tasksV2.taskAllIncludes,
    } as any,
    opts as any
  )

  return useQuery({
    ...queryArgs,
    notifyOnChangeProps: ['error', 'data', 'dataUpdatedAt'],
    select: (data: PastDueApi['queryFnData']) => {
      return data.ids.map((id) => data.models.tasks[id])
    },
  })
}

const useCreateTaskMutation = createUseMutation(API.tasksV2.createTask)
export const useCreateTask = () => {
  const client = useQueryClient()
  const { uid: currentUserId } = useAuthenticatedUser()

  return useCreateTaskMutation({
    onMutate: ({ data }) => {
      const cacheUpdates = applyOptimisticTaskCreate(
        client,
        currentUserId,
        data
      )

      return { cacheUpdates }
    },
    onSuccess: ({ models, meta, id }, _, context) => {
      // Remove temp task
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }
      cacheUpdates?.rollback()
      const task = models[meta.model][id]
      applyTaskCreateToCaches(client, task, currentUserId)
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }

      cacheUpdates?.rollback()
    },
  })
}

const useEstimateTaskPropsMutation = createUseMutation(
  API.notes.estimateTaskProps
)
export const useEstimateTaskProps = () => {
  const client = useQueryClient()

  return useEstimateTaskPropsMutation({
    onSuccess: (data) => {
      MotionCache.upsert(client, getTaskQueryFilters(data.id), data)
    },
  })
}

const useDeleteTaskMutation = createUseMutation(API.tasksV2.deleteTask)
export const useDeleteTask = () => {
  const client = useQueryClient()
  const [params] = useSearchParams()
  const isModalOpen = Boolean(params.get('task') || params.get('project'))

  return useDeleteTaskMutation({
    onMutate: ({ id }) => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_DELETE_TASK')

      if (isModalOpen) {
        // skip optimistic updates if modal is open
        return {}
      }

      const cacheUpdates = applyOptimisticTaskDelete(client, id)

      return { cacheUpdates }
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }

      cacheUpdates?.rollback()
    },
  })
}

type TaskCacheKey = keyof Pick<ModelCache, 'tasks' | 'recurringTasks'>
const useUpdateTaskMutation = createUseMutation(API.tasksV2.updateTask)
export const useUpdateTask = (
  opts?: ApiUseMutationOptions<typeof API.tasksV2.updateTask>
) => {
  const client = useQueryClient()

  return useUpdateTaskMutation({
    onSuccess: (data) => {
      MotionCache.upsert(client, getTaskQueryFilters(data.id), data)
    },
    onMutate: async ({ id, data }) => {
      const cachedTask = getCacheEntryValue(client, 'tasks', id)
      const cachedRecurringTask = getCacheEntry(client, 'recurringTasks', id)

      const cached = cachedTask ?? cachedRecurringTask
      const model: TaskCacheKey | null = cachedTask
        ? 'tasks'
        : cachedRecurringTask
          ? 'recurringTasks'
          : null

      let cacheUpdates: ReturnType<typeof MotionCache.patch> | undefined
      if (cached != null && model != null) {
        // Update internal caches
        cacheUpdates = MotionCache.patch(
          client,
          getTaskQueryFilters(id),
          'tasks',
          {
            [id]: data,
          }
        )
      }

      let scheduledEntityCacheUpdates
      if (data.type !== 'RECURRING_TASK') {
        scheduledEntityCacheUpdates =
          applyOptimisticScheduleEntityUpdateForTask(client, id, data)
      }

      return { cacheUpdates, scheduledEntityCacheUpdates }
    },
    onError: (err, _, context) => {
      const { cacheUpdates, scheduledEntityCacheUpdates } = context as {
        cacheUpdates: ReturnType<typeof MotionCache.patch>
        scheduledEntityCacheUpdates: OptimisticUpdateValue | undefined
      }

      cacheUpdates?.rollback()
      scheduledEntityCacheUpdates?.rollback()
    },
    ...opts,
  })
}

export const useStopTaskMutation = createUseMutation(API.tasksV2.stopTask)

export const useStartTaskMutation = createUseMutation(API.tasksV2.startTask)

const useBulkUpdateTasksMutation = createUseMutation(
  API.tasksV2.bulkUpdateTasks
)
export const useBulkUpdateTasks = () => {
  const client = useQueryClient()

  return useBulkUpdateTasksMutation({
    onMutate: async ({ bulkUpdateTasksInWorkspaces }) => {
      const cacheUpdates = bulkUpdateTasksInWorkspaces.flatMap(
        ({ taskIds, update }) => {
          const taskUpdates = taskIds
            .map((taskId) => {
              const cachedTask = getCacheEntryValue(client, 'tasks', taskId)
              if (!cachedTask) return null

              if (update.type === 'bulk-delete') {
                return applyOptimisticTaskDelete(client, taskId)
              }

              let taskUpdates: Partial<NormalTaskSchema> | undefined

              if (update.type === 'bulk-archive') {
                taskUpdates = {
                  archivedTime: DateTime.now().toISO(),
                }
              }

              if (update.type === 'bulk-field-update') {
                taskUpdates = getBulkOptimisticTaskUpdates(update, cachedTask)
              }

              if (taskUpdates != null) {
                return MotionCache.patch(
                  client,
                  getTaskQueryFilters(taskId),
                  'tasks',
                  {
                    [taskId]: taskUpdates,
                  }
                )
              }
            })
            .filter(Boolean)

          return taskUpdates
        }
      )

      return { cacheUpdates }
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue[]
      }

      cacheUpdates.forEach((cache) => cache.rollback())
    },
  })
}
