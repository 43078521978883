import { type TaskDefinitionSchema } from '@motion/rpc-types'

import { useFetchProjectTasks } from '~/areas/task-project/hooks/use-fetch-project-tasks'
import { useProject, useProjectDefinition } from '~/global/hooks'
import { useMemo } from 'react'

import { type AttachmentGroup } from '../components'
import { sortAttachmentIds } from '../utils/sorting'

// get all attachments for a project including tasks, task definitions, and project definition
export function useGetProjectAttachmentGroups({
  projectId,
}: {
  projectId: string
}) {
  const project = useProject(projectId)
  const { data: tasks } = useFetchProjectTasks({
    projectId: project?.id,
    workspaceId: project?.workspaceId,
  })

  const projectDefinition = useProjectDefinition(project?.projectDefinitionId)

  return useMemo(() => {
    if (project) {
      const projectUploadedFiles = project.uploadedFileIds

      const taskDefinitionsMap =
        projectDefinition?.stages
          .flatMap((stage) => stage.tasks)
          .reduce(
            (acc, task) => {
              acc[task.id] = task
              return acc
            },
            {} as Record<string, TaskDefinitionSchema>
          ) ?? {}

      const taskAttachments = tasks.map((task) => {
        const definitionUploadIds =
          taskDefinitionsMap[task.taskDefinitionId ?? '']?.uploadedFileIds ?? []
        return {
          targetId: task.id,
          targetType: 'TEAM_TASK' as const,
          workspaceId: task.workspaceId,
          uploadedFileIds: [
            ...sortAttachmentIds(task.uploadedFileIds ?? []),
            ...sortAttachmentIds(definitionUploadIds),
          ],
        }
      })

      if (projectDefinition) {
        const projectDefinitionAttachments =
          projectDefinition.uploadedFileIds ?? []

        return [
          {
            targetId: project.id,
            targetType: 'PROJECT' as const,
            workspaceId: project.workspaceId,
            uploadedFileIds: [
              ...sortAttachmentIds(projectUploadedFiles),
              ...sortAttachmentIds(projectDefinitionAttachments),
            ],
          },
          ...taskAttachments,
        ] satisfies AttachmentGroup[]
      }

      return [
        {
          targetId: project.id,
          targetType: 'PROJECT' as const,
          workspaceId: project.workspaceId,
          uploadedFileIds: sortAttachmentIds(projectUploadedFiles),
        },
        ...taskAttachments,
      ] satisfies AttachmentGroup[]
    }

    return []
  }, [project, projectDefinition, tasks])
}
