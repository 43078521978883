import { type ProjectSchema } from '@motion/rpc-types'
import { Button, Tooltip } from '@motion/ui/base'
import { getEtaLabel, normalizeProjectDeadlineStatus } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type ETA_SOURCE } from '~/@types/analytics'
import { type BadgeSize, EtaBadge } from '~/global/components/badges'

import { BadgeTooltip } from './badge-tooltip'

import { useExtendedNoneEta } from '../../hooks'
import { ProjectEtaPopover } from '../popover'

type ConnectedProjectEtaBadgeProps = {
  project: ProjectSchema
  size?: BadgeSize
  hideTooltip?: boolean
  source: ETA_SOURCE
}

export const ConnectedProjectEtaBadge = ({
  project,
  size = 'small',
  hideTooltip = false,
  source,
}: ConnectedProjectEtaBadgeProps) => {
  const deadlineStatus = normalizeProjectDeadlineStatus(project)
  const resolvedDeadlineStatus = useExtendedNoneEta({
    entity: project,
    type: 'project',
  })

  return (
    <ProjectEtaPopover entity={project} source={source}>
      <Tooltip
        renderContent={() =>
          hideTooltip ? undefined : (
            <BadgeTooltip entity={project} type='project' source={source} />
          )
        }
      >
        <Button
          aria-label={getEtaLabel(
            resolvedDeadlineStatus,
            'project',
            project.scheduledStatus
          )}
          onClick={() => {
            recordAnalyticsEvent('ETA_OPEN_POPOVER', {
              source,
              eta: resolvedDeadlineStatus,
              type: 'project',
            })
          }}
          sentiment='neutral'
          variant='muted'
          disabled={deadlineStatus === 'none'}
          size='xsmall'
          iconOnly
        >
          <EtaBadge value={resolvedDeadlineStatus} size={size} />
        </Button>
      </Tooltip>
    </ProjectEtaPopover>
  )
}
