import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import {
  useMoveFavoriteFolderItemInDirection,
  useMoveWorkspacesItemInDirection,
} from '~/areas/folders/hooks'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { type SortableWorkspacesTreeviewItem } from '../types'

type UseMoveTreeviewItemOptions = {
  isInFavorites: boolean
}

export function useMoveTreeviewItem(
  { type, item }: SortableWorkspacesTreeviewItem,
  {
    isInFavorites = false,
  }: Partial<UseMoveTreeviewItemOptions> = READONLY_EMPTY_OBJECT
) {
  const moveWorkspacesItemInDirection = useMoveWorkspacesItemInDirection()
  const moveFavoriteFolderItemInDirection =
    useMoveFavoriteFolderItemInDirection()

  return useCallback(
    async (moveBy: -1 | 1) => {
      // Just here to make TS happy for itemType below
      if (type === 'PLACEHOLDER') return

      recordAnalyticsEvent('FOLDERS_SHIFT_SIDEBAR_ITEM', {
        itemType: type,
        direction: moveBy === 1 ? 'DOWN' : 'UP',
      })

      try {
        if (isInFavorites) {
          return await moveFavoriteFolderItemInDirection(item.id, moveBy)
        }

        return await moveWorkspacesItemInDirection(item.id, moveBy)
      } catch (cause) {
        Sentry.captureException(
          new Error('Failed to move folder item up/down in treeview', {
            cause,
          }),
          {
            tags: {
              position: 'useMoveTreeviewItem',
            },
            extra: {
              itemId: item.id,
              moveBy,
            },
          }
        )

        showErrorToast(cause, 'Failed to move item')
      }
    },
    [
      isInFavorites,
      item.id,
      moveFavoriteFolderItemInDirection,
      moveWorkspacesItemInDirection,
      type,
    ]
  )
}
