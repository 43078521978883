import { ModalTrigger } from '~/areas/modals'
import { TieredCheckoutModal } from '~/areas/tiered-pricing'

export const GlobalModalContainer = () => {
  return (
    <>
      <ModalTrigger
        name='tiered-checkout-modal'
        component={TieredCheckoutModal}
      />
    </>
  )
}
