import { AutoscheduleStarSolid, FilledChevronRightSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import {
  Button,
  ButtonGroup,
  Text,
  Tooltip,
  UnstyledCollapsableContainer,
} from '@motion/ui/base'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import type { NormalTaskSchema, StageSchema } from '@motion/zod/client'

import { StageLabel } from '~/global/components/labels'
import { useMemo, useRef, useState } from 'react'

import { SkipStageButton } from './skip-stage-button'
import { StageTasks } from './stage-tasks'

import {
  useAddTaskFn,
  useCreateDefaultStageTask,
  useSetupProjectForm,
} from '../../../hooks'
import { ControlledStageDeadlineField } from '../fields'

type StageRowProps = {
  stage: StageSchema
  index: number
  tasks: NormalTaskSchema[]
}

export const StageRow = ({ stage, index, tasks }: StageRowProps) => {
  const {
    form: { watch, getValues },
  } = useSetupProjectForm()
  const modalApi = useModalApi()
  const addTask = useAddTaskFn()
  const createDefaultStageTask = useCreateDefaultStageTask()

  const isSkippingStage = Boolean(
    watch(`speculativeProject.stages.${index}.canceledTime`)
  )
  const speculativeProject = watch('speculativeProject')

  const originalModifications = useRef(getValues('modifications'))
  const hasAiModifications = useMemo(
    () => tasks.some((task) => originalModifications.current?.[task.id]),
    [tasks]
  )

  const [expanded, setExpanded] = useState(index === 0)

  if (speculativeProject == null) {
    throw new Error('Speculative project is not set and should have been set')
  }

  const onClickAddTask = async () => {
    const stage = speculativeProject.stages[index]
    if (stage == null) {
      throw new Error('Stage cannot be found within the project')
    }

    const task = createDefaultStageTask(
      speculativeProject,
      stage.stageDefinitionId
    )
    const response = await modalApi.prompt('speculative-task-modal', {
      task,
      project: speculativeProject,
    })
    if (response === ModalDismissed) return
    if (response.type !== 'update') return

    addTask({ ...task, ...response.data })
  }

  return (
    <UnstyledCollapsableContainer
      expanded={expanded}
      toggle={() => setExpanded(!expanded)}
      renderHeader={() => (
        <div className='flex flex-row items-center justify-between py-3 gap-2'>
          <div className='flex flex-row items-center gap-2'>
            <Button
              size='xsmall'
              sentiment='neutral'
              variant='muted'
              iconOnly
              onClick={(e) => {
                setExpanded(!expanded)
              }}
            >
              <ExpandIcon expanded={expanded} />
            </Button>

            <Tooltip content={stage.name}>
              <LabelContainer>
                <StageLabel
                  value={{
                    name: stage.name ?? 'No stage',
                    color: stage.color ?? 'gray',
                  }}
                  variant={isSkippingStage ? 'skipped' : 'default'}
                />
              </LabelContainer>
            </Tooltip>

            {hasAiModifications && (
              <AutoscheduleStarSolid className='size-3 text-semantic-neutral-icon-disabled' />
            )}

            {isSkippingStage && (
              <Text sentiment='disabled' size='2xs'>
                <em>Stage will be skipped & tasks canceled</em>
              </Text>
            )}
          </div>

          <div className='flex flex-row items-center gap-2'>
            {!isSkippingStage && <ControlledStageDeadlineField index={index} />}

            <ButtonGroup size='xsmall'>
              {!isSkippingStage && (
                <Button
                  variant='muted'
                  sentiment='neutral'
                  size='small'
                  onClick={onClickAddTask}
                >
                  Add task
                </Button>
              )}
              <SkipStageButton index={index} tasks={tasks} />
            </ButtonGroup>
          </div>
        </div>
      )}
    >
      <StageTasks
        tasks={tasks}
        modifications={originalModifications.current ?? {}}
        disabled={isSkippingStage}
      />
    </UnstyledCollapsableContainer>
  )
}

const ExpandIcon = classed(FilledChevronRightSolid, {
  base: 'transition-transform !size-4',
  variants: {
    expanded: {
      true: 'rotate-90',
    },
  },
})

const LabelContainer = classed('div', {
  base: 'max-w-[150px]',
})
