import { CollapsibleListPlugin } from '@motion/notes'
import { Sentry } from '@motion/web-base/sentry'
import { DB } from '@motion/web-common/storage'

import { useCallback } from 'react'

function getToggleStateKey(motionId: string) {
  return `collapsible-list-item-${motionId}`
}

export function CustomCollapsibleListsPlugin() {
  const getToggleState = useCallback(
    async (motionId: string): Promise<boolean> => {
      await DB.open()

      if (!DB.initialized) {
        Sentry.captureException(
          new Error('Docs: List toggled while DB not initialized')
        )
        return true
      }

      const key = getToggleStateKey(motionId)
      const item = await DB.state.get(key)

      // We want lists to be expanded by default
      if (item == null) {
        return true
      }

      return JSON.parse(item)
    },
    []
  )

  const setToggleState = useCallback(
    async (motionId: string, open: boolean) => {
      await DB.open()

      const key = getToggleStateKey(motionId)
      DB.state.set(key, JSON.stringify(open))
    },
    []
  )

  return (
    <CollapsibleListPlugin
      getToggleState={getToggleState}
      setToggleState={setToggleState}
    />
  )
}
