import { useStageTaskCardFields } from '~/areas/flows/contexts'
import { useMemo } from 'react'

import { DeprecatedUnconnectedBlockedByField } from '../../deprecated-unconnected-blocked-by-field'

export function DeprecatedBlockedByField() {
  const { stageTasks, task, textVariables, fields } = useStageTaskCardFields()

  const [blockedByTaskId, onChangeBlockedByTaskId] = fields.blockedByTaskId
  const [, onChangeBlockedByTaskIds] = fields.blockedByTaskIds
  const blockedByTask = useMemo(
    () => stageTasks.find((t) => t.id === blockedByTaskId) ?? null,
    [blockedByTaskId, stageTasks]
  )

  const onChange = (value: string | null) => {
    onChangeBlockedByTaskId(value)
    onChangeBlockedByTaskIds(value ? [value] : [])
  }

  return (
    <DeprecatedUnconnectedBlockedByField
      stageTasks={stageTasks}
      task={task}
      value={blockedByTask}
      textVariables={textVariables}
      onChange={onChange}
      size='xsmall'
    />
  )
}
