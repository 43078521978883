import { XSolid } from '@motion/icons'
import { IconButton, TextHeader, UnstyledModal } from '@motion/ui/base'
import { useWillExceedWorkspaceFeatureCap } from '@motion/web-billing'

import { SharedFormShell, ShellVars } from '~/areas/task-project/components'
import {
  TieredPricingAiFlowButton,
  TieredPricingUpsellButton,
} from '~/areas/tiered-pricing/components'
import { useAllProjectDefinitions, useProjectDefinitions } from '~/global/hooks'
import { useRef } from 'react'

import { FlowTemplateCardLink } from './components'
import {
  useCloseFlowTemplateModal,
  useFlowTemplateModalUrl,
  useFlowTemplateModalUrlParams,
} from './hooks'

type CreateFlowTemplateModalProps = {
  open: boolean
}

export function ConnectedCreateFlowTemplateModal({
  open,
}: CreateFlowTemplateModalProps) {
  const closeModal = useCloseFlowTemplateModal()

  return (
    <UnstyledModal
      data-testid='create-flow-template-modal'
      type='page'
      visible={open}
      onClose={closeModal}
      withAnimation
      overlayClassName='bg-modal-overlay'
    >
      <CreateFlowTemplateModalContent onClose={closeModal} />
    </UnstyledModal>
  )
}

function CreateFlowTemplateModalContent({ onClose }: { onClose: () => void }) {
  const { workspaceId: workspaceIdParam } = useFlowTemplateModalUrlParams()
  const buildFlowTemplateModalUrl = useFlowTemplateModalUrl()

  // Use same workspaceId for the duration of the modal.
  // Prevents flash of unscoped content during transition (when param removed).
  const workspaceIdRef = useRef(workspaceIdParam)
  const workspaceId = workspaceIdRef.current

  const workspaceProjectDefinitions = useProjectDefinitions(workspaceId)
  const allProjectDefinitions = useAllProjectDefinitions()

  const projectDefinitions = workspaceId
    ? workspaceProjectDefinitions
    : allProjectDefinitions

  const canCreateFlowTemplate = !useWillExceedWorkspaceFeatureCap(
    'projectDefinitions',
    workspaceId,
    1
  )
  const title = 'Create from existing template'

  return (
    <ShellVars>
      <SharedFormShell className='p-6'>
        <div className='gap-6 flex flex-col h-full'>
          <div className='flex justify-between items-center'>
            <TextHeader size='lg' weight='bold'>
              {title}
            </TextHeader>
            <div className='flex gap-2'>
              {workspaceId != null && (
                <TieredPricingAiFlowButton
                  onClose={onClose}
                  workspaceId={workspaceId}
                />
              )}

              <TieredPricingUpsellButton
                feature='projectDefinitions'
                featureLocked={!canCreateFlowTemplate}
                variant='outlined'
                sentiment='neutral'
                url={buildFlowTemplateModalUrl({ template: 'new' })}
              >
                Create from scratch
              </TieredPricingUpsellButton>

              <IconButton
                icon={XSolid}
                sentiment='neutral'
                variant='muted'
                onClick={onClose}
              />
            </div>
          </div>

          <div className='gap-6 flex flex-col overflow-auto'>
            <div className='gap-3 flex flex-col'>
              <div className='grid grid-cols-3 gap-3 self-stretch'>
                {projectDefinitions.map((definition) => {
                  return (
                    <FlowTemplateCardLink
                      key={definition.id}
                      id={definition.id}
                      name={definition.name}
                      stages={definition.stages}
                      url={buildFlowTemplateModalUrl({
                        template: 'new',
                        templateId: definition.id,
                      })}
                      editTemplateUrl={buildFlowTemplateModalUrl({
                        template: 'edit',
                        templateId: definition.id,
                      })}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </SharedFormShell>
    </ShellVars>
  )
}
