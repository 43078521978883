import { DocumentLinkSolid, VideoCameraSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { IconButton } from '@motion/ui/base'
import { CalendarPalette } from '@motion/ui/calendar'
import {
  formatDurationTime,
  formatTimeRange,
  getConferenceTypeFromConferenceLink,
  parseColorId,
} from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { HasExperiment } from '@motion/web-common/flags'

import { useEventModal } from '~/areas/event/modals/hooks'
import { useCachedItem } from '~/global/cache'
import { useMeetingInsightsByEvent } from '~/global/hooks'
import { type ScheduledEventWithRelation } from '~/global/proxies'
import { useUriByRouteId } from '~/routing'
import { DateTime, type Duration } from 'luxon'
import { useEffect, useState } from 'react'

import {
  EventConferenceContainer,
  EventContainer,
  EventContentContainer,
  EventHeader,
  EventMessage,
  EventOptionsContainer,
} from './common'

type UpcomingCalendarEventProps = {
  entity: ScheduledEventWithRelation
}

export const UpcomingCalendarEvent = ({
  entity,
}: UpcomingCalendarEventProps) => {
  const { event } = entity

  const [eventMessage, setEventMessage] = useState('')
  const openEventModal = useEventModal()

  const calendar = useCachedItem('calendars', event?.calendarId)

  const { data: meetingInsightsData } = useMeetingInsightsByEvent(event)

  useEffect(() => {
    const getEventMessage = () => {
      if (!event) return ''

      const eventDuration = getDuration(entity.schedule.start)
      const eventRange = formatTimeRange(
        entity.schedule.start,
        entity.schedule.end
      )

      if (eventDuration.minutes > 1) {
        return templateStr('{{eventRange}} (in {{minutes}})', {
          eventRange,
          minutes: formatDurationTime(Math.floor(eventDuration.minutes)),
        })
      }

      if (eventDuration.minutes > 0) {
        return templateStr('{{eventRange}} (in <1 min)', {
          eventRange,
        })
      }

      return templateStr('{{eventRange}} (Now)', {
        eventRange,
      })
    }

    setEventMessage(getEventMessage())

    if (!event) return

    const interval = setInterval(() => {
      setEventMessage(getEventMessage())
    }, 30_000)

    return () => void clearInterval(interval)
  }, [event, entity.schedule.start, entity.schedule.end])

  if (!event) return null

  const hasConference = !!event.conferenceLink
  const callType = getCallType(event.conferenceLink)
  const calendarColorId = parseColorId(calendar?.colorId)

  const onClick = () => {
    openEventModal(event)
  }

  return (
    <CalendarPalette colorId={calendarColorId}>
      <EventContainer eventType='calendar'>
        <EventContentContainer onClick={onClick}>
          <EventHeader eventType='calendar'>{event.title}</EventHeader>
          {eventMessage && (
            <EventMessage eventType='calendar'>{eventMessage}</EventMessage>
          )}
        </EventContentContainer>

        <EventOptionsContainer>
          <HasExperiment name='notetaker-event-modal'>
            {!!meetingInsightsData?.noteId && (
              <EventMeetingNotesContainer
                meetingNoteId={meetingInsightsData.noteId}
              />
            )}
          </HasExperiment>

          {hasConference && (
            <EventConferenceContainer callType={callType}>
              <IconButton
                icon={VideoCameraSolid}
                size='small'
                url={event.conferenceLink ?? undefined}
                external
                onClick={() => {
                  recordAnalyticsEvent('CALENDAR_JOIN_CALL_CLICK')
                }}
              />
            </EventConferenceContainer>
          )}
        </EventOptionsContainer>
      </EventContainer>
    </CalendarPalette>
  )
}

const getDuration = (eventStart: string): Duration => {
  return DateTime.fromISO(eventStart).diff(DateTime.local(), 'minutes')
}

const getCallType = (conferenceLink?: string | null) => {
  if (!conferenceLink) return 'none'

  const type = getConferenceTypeFromConferenceLink({
    conferenceLink,
  })

  if (type === 'meet' || type === 'hangoutsMeet' || type === 'teamsForBusiness')
    return type

  return 'none'
}

type EventMeetingNotesContainerProps = {
  meetingNoteId: string
}

function EventMeetingNotesContainer({
  meetingNoteId,
}: EventMeetingNotesContainerProps) {
  const buildUrl = useUriByRouteId()

  const meetingNoteUrl = buildUrl('notes-detail', {
    noteId: meetingNoteId,
  })

  const onClick = () => {
    recordAnalyticsEvent('NOTETAKER_DOC_EVENT_MODAL_CLICK', {
      type: 'upcoming-event',
    })
  }

  return (
    <IconButton
      sentiment='neutral'
      icon={DocumentLinkSolid}
      size='small'
      url={meetingNoteUrl}
      onClick={onClick}
    />
  )
}
