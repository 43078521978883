import { classed } from '@motion/theme'
import {
  type RenderItem,
  type SortableItem,
  SortableTreeviewItem,
  SortableTreeviewVirtualizedDnd,
  type SortableTreeviewVirtualizedDndProps,
} from '@motion/ui/base'
import { createLookup } from '@motion/utils/object'

import { DayLoadingItem } from '~/areas/agenda/components/day-loading-item/day-loading-item'
import { memo, type ReactNode, useCallback } from 'react'

import { type AgendaTreeItem, type AgendaTreeItemType } from './types'
import { useAgendaTree } from './use-agenda-tree'

import { useAgendaTasksByDate, useUpdateAgendaOnDayChange } from '../../hooks'
import { AddTaskRow } from '../add-task-row'
import { AgendaDayHeader } from '../agenda-day-header'
import { CompletedTasksItem } from '../completed-tasks-item'
import { EmptyDayItem } from '../empty-day-item'
import { TaskItem } from '../task-item'

const getRowComponent = createLookup<
  Record<AgendaTreeItemType, (props: { item: AgendaTreeItem }) => ReactNode> & {
    default: () => ReactNode | null
  }
>({
  DATE: ({ item: { date } }) => <AgendaDayHeader date={date} />,
  TASK: ({ item: { task } }) =>
    task != null && <TaskItem scheduledTask={task} />,
  ADD_TASK: ({ item: { date } }) => <AddTaskRow date={date} />,
  EMPTY_DATE: ({ item: { date } }) => <EmptyDayItem date={date} />,
  COMPLETED_TASKS: ({ item: { numCompletedTasks, numTasks } }) =>
    numCompletedTasks != null &&
    numTasks != null && (
      <CompletedTasksItem
        numTasks={numTasks}
        numCompletedTasks={numCompletedTasks}
      />
    ),
  LOADING: () => <DayLoadingItem />,
  default: () => null,
})

export const AgendaTreeView = memo(function AgendaTreeView() {
  const { tasksByDate, isLoading } = useAgendaTasksByDate()
  const treeItems = useAgendaTree({ tasksByDate, isLoading })

  useUpdateAgendaOnDayChange()

  const renderItem: SortableTreeviewVirtualizedDndProps<
    (typeof treeItems)[number]
  >['renderItem'] = useCallback((item, activeId, onClick, hovering) => {
    const Row = getRowComponent(item.type)
    return (
      <SortableTreeviewItem
        item={item}
        activeId={activeId}
        expanded={item.expanded}
        onClick={onClick}
        hovering={hovering}
        icon={item.icon}
        selected={false}
        withSpacer={item.isContainer}
        fullWidth
        showExpander={false}
      >
        <Row item={item} />
      </SortableTreeviewItem>
    )
  }, [])

  return (
    <TreeviewContainer>
      <SortableTreeviewVirtualizedDnd
        items={treeItems}
        onDrop={() => false}
        onClickExpand={() => {}}
        itemHeight={74}
        renderItem={renderItem as RenderItem<SortableItem>}
        renderPlaceholder={(item: SortableItem) => null}
        disableDrag
      />
    </TreeviewContainer>
  )
})

const TreeviewContainer = classed('div', {
  base: 'flex min-h-0 w-full overflow-y-auto gap-10 pt-6 pr-4 pb-8',
})
