import { createUseMutation, createUseQuery } from '@motion/rpc'
import { API } from '@motion/rpc-definitions'

export const useSharesQuery = createUseQuery(API.share.getShares)

export const useShareItemMutation = createUseMutation(API.share.shareItem)

export const usePublishStatusQuery = createUseQuery(API.share.getPublishStatus)

export const usePublishResourceMutation = createUseMutation(
  API.share.publishResource
)
