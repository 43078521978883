import { templateStr } from '@motion/react-core/strings'
import { Button, TextHeader } from '@motion/ui/base'
import {
  type BillingPrices,
  makeTeamTrialOnIndividualBillingTermsStr,
  Term,
} from '@motion/ui-logic/billing'
import {
  BillingCardHoldWarning,
  BillingFooter,
  BillingPlanSelector,
  SafeCheckoutGuarantee,
} from '@motion/web-billing'

import { PaymentElement } from '@stripe/react-stripe-js'
import { type StripePaymentElementChangeEvent } from '@stripe/stripe-js'
import { useOpenPortalLink } from '~/components/Account/hooks'
import { useIsNoCardTrialing } from '~/global/hooks'
import { useIndividualSubscription } from '~/global/rpc/subscriptions'
import { getSubscription } from '~/state/userSlice'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { dispatch } from '../../../../state/proxy'
import { type ProcessPaymentProps } from '../../types/process-payment-props'

type PaymentSectionWithSeatsProps = {
  isAnnual: boolean
  setIsAnnual: (isAnnual: boolean) => void
  bucket: number
  setBucket: (bucket: number) => void
  isSubmitting: boolean
  processPayment: (props: ProcessPaymentProps) => Promise<boolean>
  goToNextScreen: () => void
  teamPrices: BillingPrices
  stripeCustomerId?: string
  shouldCreateNewTeamCustomer: boolean
  trialDays?: number
  error?: string
}

export const PaymentSectionWithSeats = ({
  isAnnual,
  setIsAnnual,
  bucket,
  setBucket,
  isSubmitting,
  processPayment,
  goToNextScreen,
  teamPrices,
  stripeCustomerId,
  shouldCreateNewTeamCustomer,
  trialDays = 0,
  error,
}: PaymentSectionWithSeatsProps) => {
  const [hasSelectedCard, setHasSelectedCard] = useState(true)
  const [isPaymentFilledOut, setIsPaymentFilledOut] = useState(false)
  const [useDefaultPaymentMethod, setUseDefaultPaymentMethod] = useState(
    !shouldCreateNewTeamCustomer && !error
  )
  const isNoCardTrialing = useIsNoCardTrialing()

  const handlePaymentElementChange = (
    event: StripePaymentElementChangeEvent
  ) => {
    setHasSelectedCard(event.value.type === 'card')
    setIsPaymentFilledOut(event.complete)
  }

  const existingSubscription = useIndividualSubscription()

  const { openPortalLink } = useOpenPortalLink({
    stripeCustomerId,
  })

  const term = isAnnual ? Term.Annual : Term.Monthly
  const setTerm = (term: Term) => setIsAnnual(term === Term.Annual)
  const price = isAnnual
    ? teamPrices.annualPricePerMonth
    : teamPrices.monthlyPrice

  return (
    <div className='max-w-[840px] flex flex-col gap-10 pl-5 pt-5'>
      <BillingPlanSelector
        allowLowCostTrial={false}
        term={term}
        setTerm={setTerm}
        savingsPercent={teamPrices.annualSavingsPercentInteger}
        title={
          shouldCreateNewTeamCustomer && trialDays
            ? `Start free ${trialDays} day trial (Team Plan)`
            : 'Switch to Team Plan'
        }
        text='Team Plan'
        subText={isAnnual ? 'Billed yearly' : 'Billed monthly'}
        price={price}
        annualTrialLength={trialDays}
        monthlyTrialLength={trialDays}
        chosenTrialLength={trialDays}
        isTeam
        setBucket={setBucket}
        bucket={bucket}
      />
      {!isNoCardTrialing && (
        <div className='flex flex-col gap-4'>
          <TextHeader size='xl' weight='bold'>
            Payment information
          </TextHeader>
          {!shouldCreateNewTeamCustomer && (
            <div
              className={twMerge(
                'flex flex-row items-center',
                useDefaultPaymentMethod ? 'justify-between' : 'justify-start'
              )}
            >
              {useDefaultPaymentMethod && (
                <div className='text-sm text-semantic-neutral-text-default'>
                  {templateStr(
                    'The {{linkToCard}} will be charged ${{price}} billed {{interval}}{{prorationText}}',
                    {
                      linkToCard: (
                        <a
                          onClick={openPortalLink}
                          className='text-semantic-primary-text-default underline'
                        >
                          card on file
                        </a>
                      ),
                      price:
                        (isAnnual
                          ? teamPrices.annualPrice
                          : teamPrices.monthlyPrice) * bucket,
                      interval: isAnnual ? 'annually' : 'monthly',
                      prorationText:
                        existingSubscription?.status === 'trialing'
                          ? ' after your trial ends.'
                          : `. The first ${
                              isAnnual ? 'year' : 'month'
                            } amount will be pro-rated starting today.`,
                    }
                  )}
                </div>
              )}
              <Button
                onClick={() => setUseDefaultPaymentMethod((prev) => !prev)}
                sentiment='neutral'
                size='small'
              >
                {useDefaultPaymentMethod
                  ? 'Change Payment Method'
                  : 'Use Existing Payment Method'}
              </Button>
            </div>
          )}
          {!useDefaultPaymentMethod && (
            <>
              <PaymentElement onChange={handlePaymentElementChange} />
              <BillingCardHoldWarning visible={!hasSelectedCard} />
              {error && (
                <p className='text-semantic-error-text-default'>{error}</p>
              )}
              <SafeCheckoutGuarantee />
            </>
          )}
        </div>
      )}
      {shouldCreateNewTeamCustomer && (
        <div>
          <p>
            {makeTeamTrialOnIndividualBillingTermsStr(
              (isAnnual ? teamPrices.annualPrice : teamPrices.monthlyPrice) *
                bucket,
              isAnnual,
              DateTime.now().plus({ days: trialDays }).toFormat('MMM d, yyyy'),
              trialDays || 0
            )}
          </p>
        </div>
      )}
      <div className='flex flex-col gap-4 items-center'>
        <Button
          disabled={!useDefaultPaymentMethod && !isPaymentFilledOut}
          loading={isSubmitting}
          onClick={async () => {
            const success = await processPayment({
              isAnnual,
              bucketSeats: bucket,
              useExistingCard: useDefaultPaymentMethod,
            })
            if (success) {
              await dispatch(getSubscription())
              goToNextScreen()
            }
          }}
          fullWidth
        >
          {shouldCreateNewTeamCustomer && trialDays
            ? `Start ${trialDays} day free team trial`
            : ' Start team plan'}
        </Button>
        {(!shouldCreateNewTeamCustomer || !trialDays) && (
          <BillingFooter term={term} price={price} bucket={bucket} isTeam />
        )}
      </div>
    </div>
  )
}
