import {
  type ProjectSchema,
  type StageSchema,
  type TaskSchema,
} from '@motion/rpc-types'
import {
  getExtendedProjectDeadlineStatus,
  getExtendedStageDeadlineStatus,
  getExtendedTaskDeadlineStatus,
  getProjectEtaTooltip,
  getStageEtaTooltip,
  getTaskEtaTooltip,
} from '@motion/ui-logic'
import { useOnMountAnalyticsEvent } from '@motion/web-base/analytics'

import { type ETA_SOURCE } from '~/@types/analytics'
import { useAutoScheduleRange } from '~/areas/project-management/hooks/experiments'
import { useI18N } from '~/global/contexts'
import { useWorkspaceFns } from '~/global/hooks'
import { useMemo } from 'react'

import { useProjectStageToShow } from '../../hooks'

type EtaBadgeTooltipProps =
  | {
      entity: TaskSchema
      type: 'task'
    }
  | {
      entity: StageSchema
      type: 'stage'
    }
  | {
      entity: ProjectSchema
      type: 'project'
    }

export const BadgeTooltip = ({
  entity,
  type,
  source,
}: EtaBadgeTooltipProps & { source: ETA_SOURCE }) => {
  const { pluralize } = useI18N()

  const stageToShow = useProjectStageToShow(type === 'project' ? entity : null)
  const { getWorkspaceStatuses } = useWorkspaceFns()
  const autoScheduleRange = useAutoScheduleRange()

  const tooltip = useMemo(() => {
    switch (type) {
      case 'task':
        return getTaskEtaTooltip(
          entity,
          getWorkspaceStatuses(entity.workspaceId),
          pluralize,
          autoScheduleRange
        )
      case 'stage':
        return getStageEtaTooltip(entity, pluralize)
      case 'project':
        if (stageToShow) {
          return getStageEtaTooltip(stageToShow, pluralize)
        }

        return getProjectEtaTooltip(
          entity,
          getWorkspaceStatuses(entity.workspaceId),
          pluralize,
          autoScheduleRange
        )
    }
  }, [
    type,
    entity,
    getWorkspaceStatuses,
    pluralize,
    autoScheduleRange,
    stageToShow,
  ])

  const deadlineStatus = useMemo(() => {
    switch (type) {
      case 'task':
        return getExtendedTaskDeadlineStatus(entity)
      case 'stage':
        return getExtendedStageDeadlineStatus(entity)
      case 'project':
        return getExtendedProjectDeadlineStatus(entity)
    }
  }, [entity, type])

  useOnMountAnalyticsEvent('ETA_HOVER_BADGE', {
    properties: {
      source,
      type: type,
      eta: deadlineStatus,
    },
  })

  if (!tooltip) return null

  return (
    <div className='flex flex-col items-center text-sm'>
      <div className='font-medium'>{tooltip.title}</div>
      {tooltip.action && <div>{tooltip.action}</div>}
      {tooltip.etaText && <div>{tooltip.etaText}</div>}
    </div>
  )
}
