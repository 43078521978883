import { usePrevious } from '@motion/react-core/hooks'
import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import { useSearchParams } from '~/routing'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { type StageModalProps } from './stage-modal'

import { FlowFormValuesUpdater } from '../../shared-form/flow-form-values-updater'
import { type StageUrlSearchParams, useInitialStageFormData } from '../hooks'

/**
 * The stage form uses the same form schema as the flow template form,
 * but with only one stage.
 *
 * This allows reuse of the same form assets for both the stage modal and the flow template modal.
 */
function useStageForm({ stageId, stage }: Omit<StageModalProps, 'close'>) {
  const initialFormData = useInitialStageFormData({ stageId, stage })

  const form = useForm<FlowTemplateFormFields>({
    defaultValues: initialFormData,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    mode: 'onSubmit',
  })

  const { reset } = form
  const { stage: stageUrlParam } = useSearchParams<StageUrlSearchParams>()

  /**
   * When we create a stage, we redirect by swapping out the stage url param and adding the id.
   *
   * Resets the form to the new correct initial state.
   */
  const previousStageUrlParam = usePrevious(stageUrlParam)
  useEffect(() => {
    if (stageUrlParam == null) return

    if (previousStageUrlParam !== stageUrlParam) {
      reset(initialFormData)
    }
  }, [previousStageUrlParam, reset, initialFormData, stageUrlParam])

  return form
}

type StageTemplateFormProps = {
  children: React.ReactNode
} & Omit<StageModalProps, 'close'>

export function StageTemplateForm({
  children,
  ...modalProps
}: StageTemplateFormProps) {
  const form = useStageForm(modalProps)

  return (
    <FormProvider {...form}>
      <FlowFormValuesUpdater />
      {children}
    </FormProvider>
  )
}
