import { TextSelectionToolbarPlugin } from '@motion/notes'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useExperiment } from '@motion/web-common/flags'

import { useFeatureGatedCallback } from '~/areas/tiered-pricing/hooks'
import { useCreateTaskFromSelection } from '~/global/rpc/v2'

import { useNoteDialogApi } from '../../hooks'

export function CustomTextSelectionToolbarPlugin({
  noteId,
}: {
  noteId: string
}) {
  const dialogApi = useNoteDialogApi()
  const docsAiOnToolbar = useExperiment('docs-ai-on-toolbar')
  const hasDocsAiOnToolbar =
    docsAiOnToolbar.value && docsAiOnToolbar.value !== 'off'
  const { mutateAsync: createTask } = useCreateTaskFromSelection()

  const handleToolbarSelectOption = (option: string) => {
    recordAnalyticsEvent(
      'PROJECT_MANAGEMENT_NOTE_SELECTION_TOOLBAR_MENU_OPTION_SELECTED',
      {
        option,
      }
    )
  }

  const handleOpenCreateTaskWithAiModal = useFeatureGatedCallback(
    'aiDocs',
    (selectedText: string, createMultiple: boolean) =>
      dialogApi.openDialog(
        createMultiple ? 'create-tasks-with-ai' : 'create-task-with-ai',
        {
          selectedText,
        }
      )
  )

  const handleOpenSummarizeSelectionModal = useFeatureGatedCallback(
    'aiDocs',
    (selectedText: string) =>
      dialogApi.openDialog('summarize-with-ai', { selectedText })
  )

  const handleOpenRewriteSelectionModal = useFeatureGatedCallback(
    'aiDocs',
    (selectedText: string) =>
      dialogApi.openDialog('rewrite-with-ai', { selectedText })
  )

  const handleCreateTaskWithAI = useFeatureGatedCallback(
    'aiDocs',
    (selectedText: string) =>
      createTask({
        noteId,
        selectedText,
      })
  )

  return (
    <TextSelectionToolbarPlugin
      onSelectOption={handleToolbarSelectOption}
      hasDocsAiOnToolbar={hasDocsAiOnToolbar}
      onOpenCreateTaskWithAiModal={handleOpenCreateTaskWithAiModal}
      onCreateTaskWithAI={handleCreateTaskWithAI}
      onOpenSummarizeWithAiModal={handleOpenSummarizeSelectionModal}
      onOpenRewriteModal={handleOpenRewriteSelectionModal}
    />
  )
}
