import { LoadingSpinner } from '@motion/ui/base'
import { type Term } from '@motion/ui-logic/billing'
import { type Tier } from '@motion/ui-logic/tiered-pricing'
import { errorInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'
import { type CheckoutType } from '@motion/web-billing'

import { type Stripe } from '@stripe/stripe-js'
import { Billing } from '~/areas/billing'
import { useCurrentTeam } from '~/global/rpc/team'
import { reusableSetupIntent } from '~/state/teamSlice'
import { useEffect, useRef, useState } from 'react'

import { ElementsWrapper } from '../../components/Common/Stripe/ElementsWrapper'
import { createSetupIntent } from '../../state/corrilySlice'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { selectEmail, selectStripeSubscription } from '../../state/userSlice'
import { getStripe } from '../../utils/stripe'

export type CompleteSubscriptionArgs = {
  newSubscription: boolean
}

interface BillingContainerProps {
  onChangeEmail?: () => void
  onComplete: (args: CompleteSubscriptionArgs) => void
  isTeam: boolean
  onBack?: () => void
  isDowngrade?: boolean
  tier?: Tier
  initialBucket?: number
  initialTerm?: Term
  checkoutType: CheckoutType
}

export function BillingContainer({
  onChangeEmail,
  onComplete,
  isTeam,
  onBack,
  isDowngrade,
  tier,
  initialBucket,
  initialTerm,
  checkoutType,
}: BillingContainerProps) {
  const [stripe, setStripe] = useState<Stripe | null>(null)
  const [clientSecret, setClientSecret] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const dispatch = useAppDispatch()
  const userEmail = useAppSelector(selectEmail)
  const stripeSubscription = useAppSelector(selectStripeSubscription)
  const { data: team } = useCurrentTeam()

  const hasSubmittedBilling = useRef(false)

  useEffect(function prepStripe() {
    const load = async () => {
      const stripe = await getStripe()
      setStripe(stripe)
    }

    load().catch(errorInDev)
  }, [])

  useEffect(function prepareCorrily() {
    const fetchPrices = async () => {
      try {
        if (stripeSubscription && team) {
          const paymentIntent = await dispatch(
            reusableSetupIntent(team.id)
          ).unwrap()
          setClientSecret(paymentIntent.clientSecret)
          return
        }
        const clientSecret = await dispatch(createSetupIntent()).unwrap()
        if (!clientSecret) {
          throw new Error('Payment intent generate failed')
        }
        setClientSecret(clientSecret.clientSecret)
      } catch (e) {
        Sentry.captureException(e, { tags: { position: 'fetchPrices' } })
        throw e
      }
    }

    void fetchPrices()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    function checkIfAlreadySubscribed() {
      if (
        checkoutType === 'add-payment-method' ||
        checkoutType === 'resubscribe'
      ) {
        return
      }
      if (
        stripeSubscription?.status === 'trialing' ||
        stripeSubscription?.status === 'active' ||
        stripeSubscription?.status === 'past_due'
      ) {
        if (hasSubmittedBilling.current) {
          return
        }
        hasSubmittedBilling.current = true

        // Go to the next page if they already have a stripe subscription
        onComplete({ newSubscription: false })
      }
    },
    [stripeSubscription, onComplete, checkoutType]
  )

  if (!stripe || !clientSecret) {
    return (
      <div className='flex h-full w-full items-center justify-center py-4'>
        <LoadingSpinner />
      </div>
    )
  }

  if (stripeSubscription != null && checkoutType === 'initial-purchase') {
    return (
      <div className='flex h-full w-full items-center justify-center py-4'>
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <ElementsWrapper
      key={clientSecret}
      stripe={stripe}
      clientSecret={clientSecret}
    >
      <Billing
        key={clientSecret}
        error={error}
        userEmail={userEmail}
        onSubmit={() => {
          hasSubmittedBilling.current = true
        }}
        onComplete={() => {
          onComplete({ newSubscription: true })
        }}
        onChangeEmail={onChangeEmail}
        onRerender={(clientSecret, error) => {
          setError(error)
          setClientSecret(clientSecret)
        }}
        isTeam={isTeam}
        onBack={onBack}
        checkoutType={checkoutType}
        tier={tier}
        initialBucketSeats={initialBucket}
        initialTerm={initialTerm}
        isDowngrade={isDowngrade}
      />
    </ElementsWrapper>
  )
}
