import { PopoverButton } from '@motion/ui/base'
import { StyledField } from '@motion/ui/forms'

import { ConnectedFolderBreadcrumbs } from '~/areas/folders/components'
import { ReferenceEditor } from '~/areas/notes/components'
import { WorkspacesTreeDropdown } from '~/global/components/dropdowns'

import { SectionField } from '../components'

export function BlogPostWorkflowFieldset() {
  const workspaceId = 'todo'
  const targetId = 'todo'

  return (
    <>
      <SectionField title='This workflow generates a document, where do you want to save it?'>
        <WorkspacesTreeDropdown
          selectedId={targetId}
          workspaceId={workspaceId}
          onChange={(selected) => {}}
          allowSelectAnyNode
        >
          <PopoverButton>
            {/* {targetType === 'WORKSPACE' ? (
              <ConnectedWorkspaceLabel id={targetId} />
            ) : ( */}
            <ConnectedFolderBreadcrumbs leafNodeId={targetId} />
            {/* )} */}
          </PopoverButton>
        </WorkspacesTreeDropdown>
      </SectionField>

      <SectionField
        title='Spec Document'
        subTitle='Provide the spec document to generate this workflow'
      >
        <WorkspacesTreeDropdown
          selectedId={targetId}
          workspaceId={workspaceId ?? 'invalid'}
          onChange={(selected) => {}}
          allowSelectAnyNode
          leafNodeType='NOTE'
        >
          <PopoverButton>Pick a note</PopoverButton>
        </WorkspacesTreeDropdown>
      </SectionField>

      <SectionField
        title='Instructions'
        subTitle='Any specific instructions for generating this blog post'
      >
        <StyledField>
          <ReferenceEditor
            name='prompt-editor'
            placeholder=''
            onChange={(editorState, editor) => {}}
          />
        </StyledField>
      </SectionField>
    </>
  )
}
