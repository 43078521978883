import { isFlowVariableKey } from '@motion/shared/flows'
import { findStageForTask } from '@motion/ui-logic/pm/project'

import {
  getSelectedRole,
  roleToFlowTemplateAssignee,
} from '~/areas/flows/shared-form/utils'
import { ModalFieldButton } from '~/areas/task-project/components'
import { ConnectedUserLabel } from '~/global/components/labels'
import { useController } from 'react-hook-form'

import { RoleLabel } from '../../../../components/labels/role'
import { StageTaskAssigneeDropdown } from '../../../components/stages/fields'
import { useTaskDefinitionForm } from '../use-task-definition-form'
import { useTaskDefinitionModalState } from '../use-task-definition-modal-state'

export const ControlledAssigneeField = () => {
  const { form } = useTaskDefinitionForm()
  const { flowFormData } = useTaskDefinitionModalState()

  if (flowFormData == null) {
    throw new Error('flowFormData is required')
  }

  const { control, watch } = form

  const { field } = useController({
    name: 'assigneeUserId',
    control,
  })

  const taskId = watch('id')
  const workspaceId = watch('workspaceId')

  const { roles, stages } = flowFormData
  const stageForTask = findStageForTask(stages, taskId)
  if (stageForTask == null) {
    throw new Error('Stage for task not found')
  }

  const flowTemplateRoles = roles.map(roleToFlowTemplateAssignee)

  const selectedRole = getSelectedRole(flowTemplateRoles, field.value)

  return (
    <StageTaskAssigneeDropdown
      selectedUserId={field.value}
      onChange={field.onChange}
      workspaceId={workspaceId}
      dropdownRoles={flowTemplateRoles}
      hideAddRole
    >
      <ModalFieldButton label='Assignee'>
        {isFlowVariableKey(field.value) && selectedRole != null ? (
          <RoleLabel role={selectedRole} />
        ) : (
          <ConnectedUserLabel userId={field.value} />
        )}
      </ModalFieldButton>
    </StageTaskAssigneeDropdown>
  )
}
