import { canParseUrl } from '@motion/utils/string'

/**
 * Validate that a url can be parsed.
 * This util acts as a ponyfill for URL.canParse
 * separate custom fields logic to check strings like 'https://Global Penguin Society' which would otherwise return true
 *
 *
 * https://caniuse.com/mdn-api_url_canparse_static
 * Inspired by https://github.com/zloirock/core-js/blob/master/packages/core-js/modules/web.url.can-parse.js
 *
 * @param url
 * @param base
 * @returns boolean
 */
export const canParseCustomFieldUrlValue = (url: string, base?: string) => {
  if (url.includes(' ')) return false

  return canParseUrl(url, base)
}
