import { UserTag } from '@motion/ui/base'

import { TeamMemberLabel } from '~/areas/modals/share-modal/components/team-member-label'
import { ConnectedWorkspaceLabel } from '~/global/components/labels'
import { useContext, useMemo } from 'react'

import { ShareItemModalContext } from '../context/context'

type ShareItemProps = {
  target:
    | ShareItemModalContext['selectedTargets'][0]
    | ShareItemModalContext['usersWithAccess'][0]
    | ShareItemModalContext['workspacesWithAccess'][0]
  asTag?: boolean
}

export const ShareTargetItem = ({ target, asTag = false }: ShareItemProps) => {
  const { selectedTargets, setSelectedTargets } = useContext(
    ShareItemModalContext
  )

  const label = useMemo(() => {
    switch (target.targetType) {
      case 'USER':
        return <TeamMemberLabel targetId={target.targetId} />
      case 'WORKSPACE':
        return <ConnectedWorkspaceLabel id={target.targetId} />
    }
  }, [target])

  if (asTag) {
    return (
      <UserTag
        variant='subtle'
        onRemove={() => {
          setSelectedTargets(
            selectedTargets.toSpliced(selectedTargets.indexOf(target), 1)
          )
        }}
      >
        {label}
      </UserTag>
    )
  }

  return label
}
