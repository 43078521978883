import { DotsHorizontalSolid } from '@motion/icons'
import { type AttachmentComponentProps } from '@motion/notes'
import { type UploadedFileSchema } from '@motion/rpc-types'
import {
  SUPPORTED_IMAGE_MIME_TYPES,
  SUPPORTED_VIDEO_MIME_TYPES,
} from '@motion/shared/files'
import { FileIcon, IconButton, PopoverTrigger } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { useDownloadAttachment } from '~/areas/attachments/hooks'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  CustomAttachmentErrorTagContainer,
  CustomAttachmentTagContainer,
  ElementLoader,
} from './styled'

import { fileIsPreviewable } from '../../utils'
import { AttachmentActionList } from '../attachment-action-list'
import { CustomAttachmentImagePreview } from '../custom-attachment-image-preview'
import { CustomAttachmentVideoPreview } from '../custom-attachment-video-preview'

const KIBIBYTE_IN_BYTES = 1024

function formatFileSize(fileSize: number) {
  // If the file size is less than 1 MB, display it in KB
  if (fileSize < KIBIBYTE_IN_BYTES * KIBIBYTE_IN_BYTES) {
    return (fileSize / KIBIBYTE_IN_BYTES).toFixed(1) + ' KB'
  }

  // Display the file size in MB
  return (fileSize / KIBIBYTE_IN_BYTES / KIBIBYTE_IN_BYTES).toFixed(1) + ' MB'
}

export type CustomAttachmentTagProps = {
  attachment: UploadedFileSchema | undefined
  isLoading: boolean
  isError: boolean
} & Pick<AttachmentComponentProps, 'isPreview' | 'onDelete' | 'onTogglePreview'>

export function CustomAttachmentTag({
  attachment,
  isLoading,
  isError,
  isPreview,
  onDelete,
  onTogglePreview,
}: CustomAttachmentTagProps) {
  const modalApi = useModalApi()
  const { getFileUrl } = useDownloadAttachment()

  const [showActionsButton, setShowActionsButton] = useState(false)

  if (isError) {
    return (
      <CustomAttachmentErrorTagContainer>
        <span className='text-2xs'>
          An error occurred while loading the attachment.
        </span>
      </CustomAttachmentErrorTagContainer>
    )
  }

  if (isLoading || attachment == null) {
    return (
      <CustomAttachmentTagContainer>
        <ElementLoader className='size-5' />
        <div className='flex flex-col flex-1 gap-0.5'>
          <ElementLoader className='w-1/2 h-3 my-1' />
          <ElementLoader className='w-1/4 h-2' />
        </div>
        <div className='size-4' />
      </CustomAttachmentTagContainer>
    )
  }

  const handleClick = () =>
    modalApi.open('attachment-preview', {
      attachmentId: attachment.id,
      onDelete,
    })

  const handleMouseEnter = () => setShowActionsButton(true)

  const handleMouseLeave = () => setShowActionsButton(false)

  const handleActionMenuClose = () => setShowActionsButton(false)

  const showPreview = isPreview && fileIsPreviewable(attachment.mimeType)

  const isImage = SUPPORTED_IMAGE_MIME_TYPES.includes(attachment.mimeType)
  const isVideo = SUPPORTED_VIDEO_MIME_TYPES.includes(attachment.mimeType)

  const previewUrl = getFileUrl(attachment.id) || ''

  return (
    <CustomAttachmentTagContainer
      role='button'
      aria-label={`View ${attachment.fileName}`}
      onClick={isPreview ? undefined : handleClick}
      onDoubleClick={isPreview ? handleClick : undefined}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      isPreview={showPreview}
    >
      {!showPreview ? (
        <>
          <FileIcon mimeType={attachment.mimeType} className='size-5' />
          <div className='flex flex-col flex-1 gap-0.5 truncate'>
            <span className='text-sm text-semantic-neutral-text-default truncate'>
              {attachment.fileName}
            </span>
            <span className='text-2xs text-semantic-neutral-text-subtle'>
              {formatFileSize(attachment.fileSize)}
            </span>
          </div>
        </>
      ) : isImage ? (
        <CustomAttachmentImagePreview
          fileUrl={previewUrl}
          fileName={attachment.fileName}
        />
      ) : isVideo ? (
        <CustomAttachmentVideoPreview
          fileUrl={previewUrl}
          fileName={attachment.fileName}
        />
      ) : null}
      <PopoverTrigger
        placement='bottom-end'
        renderPopover={({ close }) => (
          <AttachmentActionList
            attachment={attachment}
            close={close}
            isPreview={showPreview}
            onDelete={onDelete}
            onTogglePreview={onTogglePreview}
          />
        )}
        onClose={handleActionMenuClose}
      >
        <div
          className={twMerge(
            'flex transition-all opacity-0',
            showActionsButton && 'opacity-100',
            showPreview && 'absolute top-1 right-1'
          )}
        >
          <IconButton
            icon={DotsHorizontalSolid}
            sentiment='neutral'
            variant='outlined'
            size='xsmall'
            aria-label='Attachment options'
          />
        </div>
      </PopoverTrigger>
    </CustomAttachmentTagContainer>
  )
}
