export type SkipCreateTeamReasons =
  | 'will_try_motion_with_team_in_future'
  | 'never_use_motion_with_team'
  | 'no_team'

export type Position =
  | 'ceo/executive'
  | 'director/vp'
  | 'manager_of_managers'
  | 'manager_of_large_team'
  | 'manager_of_small_team'
  | 'individual_contributor'

export const POSITION_LABELS: (Record<'label', string> &
  Record<'value', Position>)[] = [
  {
    label: 'CEO or Executive',
    value: 'ceo/executive',
  },
  {
    label: 'Director or VP',
    value: 'director/vp',
  },
  {
    label: 'Manager of Managers',
    value: 'manager_of_managers',
  },
  {
    label: 'Manager of a large team',
    value: 'manager_of_large_team',
  },
  {
    label: 'Manager of a small team',
    value: 'manager_of_small_team',
  },
  {
    label: 'Individual Contributor',
    value: 'individual_contributor',
  },
]

export const ORGANIZATION_SIZES = [
  '1 (Just me)',
  'Between 2 and 14',
  'Between 15 and 24',
  'Between 25 and 49',
  'Between 50 and 99',
  'Between 100 and 499',
  '500 or greater',
] as const
export type OrganizationSize = (typeof ORGANIZATION_SIZES)[number]

export const INVITE_MEMBER_SIZES = [
  '1 (Just me)',
  'Between 2 and 14',
  'Between 15 and 24',
  'Between 25 and 49',
  '50 or greater',
] as const

export type InviteMemberSize = (typeof INVITE_MEMBER_SIZES)[number]

export type IndustryType =
  | 'creative_and_design'
  | 'education'
  | 'engineering_and_product'
  | 'finance_and_accounting'
  | 'healthcare_and_medical'
  | 'information_technology_and_technology'
  | 'legal_and_professional_services'
  | 'marketing_and_sales'
  | 'operations_and_project_management'
  | 'personal_use'
  | 'retail_and_ecommerce'
export const INDUSTRY_TYPE_OPTIONS: (Record<'label', string> &
  Record<'id', IndustryType>)[] = [
  {
    label: 'Creative & Design',
    id: 'creative_and_design',
  },
  {
    label: 'Education',
    id: 'education',
  },
  {
    label: 'Engineering & Product',
    id: 'engineering_and_product',
  },
  {
    label: 'Finance & Accounting',
    id: 'finance_and_accounting',
  },
  {
    label: 'Healthcare & Medical',
    id: 'healthcare_and_medical',
  },
  {
    label: 'IT & Technology',
    id: 'information_technology_and_technology',
  },
  {
    label: 'Legal & Professional Services',
    id: 'legal_and_professional_services',
  },
  {
    label: 'Marketing & Sales',
    id: 'marketing_and_sales',
  },
  {
    label: 'Operations & Project Management',
    id: 'operations_and_project_management',
  },
  {
    label: 'Retail & E-Commerce',
    id: 'retail_and_ecommerce',
  },
  {
    label: 'None, personal use',
    id: 'personal_use',
  },
]

export type TeamType =
  | 'administrative_and_support'
  | 'engineering_and_product'
  | 'marketing_and_sales'
  | 'operations_and_supply_chain'
  | 'finance_and_legal'

export const TEAM_TYPE_OPTIONS: (Record<'label', string> &
  Record<'id', TeamType>)[] = [
  {
    label: 'Administrative & Support',
    id: 'administrative_and_support',
  },
  {
    label: 'Engineering & Product',
    id: 'engineering_and_product',
  },
  {
    label: 'Marketing & Sales',
    id: 'marketing_and_sales',
  },
  {
    label: 'Operations & Supply Chain',
    id: 'operations_and_supply_chain',
  },
  {
    label: 'Finance & Legal',
    id: 'finance_and_legal',
  },
]

export type ExistingTool =
  | 'no_tools_manual_email_chat_text'
  | 'no_tools_manual_paper_or_sticky_notes'
  | 'no_tools_whiteboard_or_in_person'
  | 'asana'
  | 'monday'
  | 'jira'
  | 'clickup'
  | 'trello'
  | 'notion'
  | 'excel_or_google_sheet'
  | 'google_tasks'
  | 'microsoft_tools'
  | 'confluence'
  | 'todoist'
  | 'basecamp'
  | 'wrike'

type ToolRecord = Record<'label', string> & Record<'id', ExistingTool>

export const NO_TOOLS_OPTIONS: ToolRecord[] = [
  {
    label: 'Manually done through email, chat or texts',
    id: 'no_tools_manual_email_chat_text',
  },
  {
    label: 'Manually done on paper or sticky notes',
    id: 'no_tools_manual_paper_or_sticky_notes',
  },
  {
    label: 'Manually done on a whiteboard or in person',
    id: 'no_tools_whiteboard_or_in_person',
  },
]

export const TEAM_TOOL_OPTIONS: ToolRecord[] = [
  {
    label: 'Asana',
    id: 'asana',
  },
  {
    label: 'Monday.com',
    id: 'monday',
  },
  {
    label: 'Jira',
    id: 'jira',
  },
  {
    label: 'ClickUp',
    id: 'clickup',
  },
  {
    label: 'Trello',
    id: 'trello',
  },
  {
    label: 'Notion',
    id: 'notion',
  },
  {
    label: 'Excel / Google Sheets',
    id: 'excel_or_google_sheet',
  },
]

export const INDIVIDUAL_TOOL_OPTIONS: ToolRecord[] = [
  {
    label: 'Google Tasks',
    id: 'google_tasks',
  },
  {
    label: 'Microsoft Tools (Planner, To Do, etc.)',
    id: 'microsoft_tools',
  },
  {
    label: 'Confluence',
    id: 'confluence',
  },
  {
    label: 'Todoist',
    id: 'todoist',
  },
  {
    label: 'Basecamp',
    id: 'basecamp',
  },
  {
    label: 'Wrike',
    id: 'wrike',
  },
]

export const DEFAULT_BOOKING_CSM = 'bishop'

export const TOOL_OPTIONS: ToolRecord[] = [
  ...NO_TOOLS_OPTIONS,
  ...TEAM_TOOL_OPTIONS,
  ...INDIVIDUAL_TOOL_OPTIONS,
]
