import { QuestionMarkCircleSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { Button, Text, TextHeader } from '@motion/ui/base'
import { useOnMountAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'

import { useOnboardingApi } from '~/areas/onboarding/state/use-onboarding-api'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { HorizontalDivider } from '../../../components/Common/Divider/HorizontalDivider'
import { DetachCalendarModal } from '../../../components/Settings/Modals/DetachCalendarModal/DetachCalendarModal'
import { useToggleIntercom } from '../../../utils/toggleIntercom'

const navigateToPaymentPage = () => {
  window.location.href = '/addPayment'
}

interface User {
  email: string | null
}

interface NoActiveSubscriptionProps {
  user: User
  logOutHandler: () => void
}

export const NoActiveSubscription = ({
  user,
  logOutHandler,
}: NoActiveSubscriptionProps) => {
  const [showDetachCalendarModal, setShowDetachCalendarModal] = useState(false)
  const { selectIsOnboardingComplete } = useOnboardingApi()
  const toggleIntercom = useToggleIntercom()
  const shouldUseNoCcTrial = useHasTreatment('no-cc-combined-trial')
  const hasCompletedOnboarding = selectIsOnboardingComplete()
  const showExpiredCopy = shouldUseNoCcTrial && hasCompletedOnboarding

  useOnMountAnalyticsEvent('ACCOUNT_NO_SUBSCRIPTION')

  return (
    <div
      className={twMerge(
        'motiontab-background-fill',
        'dark:bg-dark-1100 bg-light-100 relative'
      )}
    >
      <div className='absolute top-12 right-12'>
        <Button onClick={() => toggleIntercom()} sentiment='neutral'>
          <QuestionMarkCircleSolid className='mr-2.5 h-4 w-4' />
          Support
        </Button>
      </div>
      <div className='w-full max-h-full overflow-y-auto'>
        <div className='motiontab-body-col ml-auto mr-auto !static'>
          {!showExpiredCopy && (
            <Text sentiment='subtle' size='xs'>
              Did you log in with the right account?
            </Text>
          )}
          <div className='my-8 flex flex-col gap-4 text-center'>
            <TextHeader>
              {showExpiredCopy
                ? `Your trial or subscription has expired ${user.email}`
                : `There's no active trial or subscription for ${user.email}`}
            </TextHeader>

            <Text sentiment='subtle' size='sm' weight='medium'>
              {templateStr(
                "If you think you have an active trial or subscription, it seems you might be using the wrong email with {{email}}. Please try logging in with the right one or reach out to our friendly support team at {{link}}. We're here to help!",
                {
                  email: <span className='underline'>{user.email}</span>,
                  link: (
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://help.usemotion.com'
                    >
                      help.usemotion.com
                    </a>
                  ),
                }
              )}
            </Text>
          </div>

          <div className='flex gap-2'>
            <Button onClick={logOutHandler}>
              Sign in with another account
            </Button>
            <Button
              variant='outlined'
              sentiment='neutral'
              onClick={navigateToPaymentPage}
            >
              Subscribe with {user.email}
            </Button>
          </div>

          <HorizontalDivider />
          <div className='flex flex-col gap-4 items-center'>
            <Text sentiment='subtle' size='xs'>
              Not using Motion anymore?
            </Text>

            <Button
              size='small'
              sentiment='error'
              onClick={() => setShowDetachCalendarModal(true)}
            >
              Delete Motion Tasks
            </Button>

            <Button
              size='small'
              sentiment='neutral'
              variant='muted'
              onClick={() => location.reload()}
            >
              Refresh
            </Button>
          </div>
        </div>
      </div>

      {showDetachCalendarModal && (
        <DetachCalendarModal
          visible={showDetachCalendarModal}
          close={() => {
            setShowDetachCalendarModal(false)
          }}
        />
      )}
    </div>
  )
}
