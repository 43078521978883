import { isUserNotOnboarded } from '@motion/ui-logic'

import {
  AssigneeField,
  type AssigneeFieldProps,
} from '~/areas/task-project/fields'
import { useController } from 'react-hook-form'

import { useTaskForm } from '../hooks'

export const ControlledAssigneeField = () => {
  const { form, disabledFields } = useTaskForm()

  const { control, watch } = form

  const { field } = useController({
    name: 'assigneeUserId',
    control,
  })
  const type = watch('type')
  const workspaceId = watch('workspaceId')

  const onChange: AssigneeFieldProps['onChange'] = (assigneeId) => {
    field.onChange(assigneeId)
  }

  const isRecurringTask = type === 'RECURRING_TASK'

  return (
    <AssigneeField
      value={field.value}
      onChange={onChange}
      workspaceId={workspaceId}
      disabled={disabledFields.has('assigneeUserId')}
      removeUnassignedOption={isRecurringTask}
      disableOptionFn={
        isRecurringTask
          ? (option) => isUserNotOnboarded(option.user)
          : undefined
      }
      disabledOptionTooltip={
        isRecurringTask
          ? 'This user can’t be selected because they have not yet accepted their invite to join this Motion team'
          : undefined
      }
    />
  )
}
