import { ChevronDoubleLeftSolid, MenuSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { IconButton, Portal, Tooltip } from '@motion/ui/base'
import { useUIContext } from '@motion/ui/providers'
import { getWindowData } from '@motion/web-base/env'

import {
  useDesktopTabs,
  useHasMinimumDesktopVersion,
} from '~/areas/desktop/hooks'
import logo from '~/assets/logo_1024.webp'
import { useSidebarState } from '~/global/hooks'
import { usePage } from '~/routing'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { HeaderNavigationButtons } from './header-navigation-buttons'
import { HeaderTabs } from './header-tabs'
import { HeaderSection } from './shared-components'

export const DesktopHeaderBar = () => {
  const { hasVisibleModals } = useUIContext()
  const { hasTabs } = useDesktopTabs()

  if (hasTabs) {
    return null
  }

  return (
    <NavigationWrapper>
      <NavigationSection />

      <HeaderTabs />

      {hasVisibleModals && (
        <Portal>
          <NavigationSection
            className='absolute text-white top-0 left-0 z-10 border-transparent'
            inPortal
          />
        </Portal>
      )}
    </NavigationWrapper>
  )
}

type NavigationSectionProps = {
  className?: string
  inPortal?: boolean
  style?: React.CSSProperties
}

const NavigationSection = ({
  style,
  className,
  inPortal = false,
}: NavigationSectionProps) => {
  const slim = useHasMinimumDesktopVersion()('0.83.4')
  const { isMac } = getWindowData()
  const [sidebarState, , toggleSidebar] = useSidebarState('global')
  const pageData = usePage()
  const isOnSettings = pageData === 'settings'

  return (
    <NavigationHeaderSection
      slim={!isMac || slim}
      isMac={isMac}
      className={className}
      sidebarOpen={sidebarState.open}
      style={style}
    >
      <div
        className={twMerge(
          'flex pr-3',
          sidebarState.open && 'w-[165px]',
          !sidebarState.open && 'gap-2'
        )}
      >
        <img
          src={logo}
          className='size-6 electron-no-drag'
          alt='Motion logo'
          draggable={false}
        />
      </div>

      {!isOnSettings && (
        <span className='inline-flex ml-auto mr-2'>
          <Tooltip
            key={sidebarState.open ? 'close' : 'open'} // Switch the key to prevent the tooltip from sticking open
            content={`${sidebarState.open ? 'Close' : 'Open'} the sidebar`}
            asChild
          >
            <IconButton
              icon={sidebarState.open ? ChevronDoubleLeftSolid : MenuSolid}
              onClick={toggleSidebar}
              sentiment='neutral'
              size='small'
              variant='muted'
              aria-label={sidebarState.open ? 'Close sidebar' : 'Open sidebar'}
            />
          </Tooltip>
        </span>
      )}

      <HeaderNavigationButtons inPortal={inPortal} />
    </NavigationHeaderSection>
  )
}

const NavigationHeaderSection = classed('div', HeaderSection, {
  base: 'flex items-center justify-between pr-3',
  variants: {
    slim: {
      true: 'h-10 max-h-10',
      false: 'h-14 max-h-14',
    },
    isMac: {
      false: 'pl-3',
    },
    sidebarOpen: {
      true: 'w-[320px]',
      false: '',
    },
  },
  compoundVariants: [
    {
      slim: true,
      isMac: true,
      class: 'pl-20',
    },
    {
      slim: false,
      isMac: true,
      class: 'pl-24',
    },
  ],
  defaultVariants: {
    slim: false,
    isMac: false,
    sidebarOpen: false,
  },
})

const NavigationWrapper = classed('div', {
  base: 'w-full flex electron-drag border-b border-semantic-neutral-border-default',
})
