import { templateStr } from '@motion/react-core/strings'

import { ActivityAssigneeName, ActivityValue } from '../components'

export function handleAssignee(
  oldUserId?: string | null,
  newUserId?: string | null
) {
  return templateStr(
    `changed assignee from {{assigneeFrom}} to {{assigneeTo}}`,
    {
      assigneeFrom: (
        <ActivityValue>
          <ActivityAssigneeName assigneeId={oldUserId} />
        </ActivityValue>
      ),
      assigneeTo: (
        <ActivityValue>
          <ActivityAssigneeName assigneeId={newUserId} />
        </ActivityValue>
      ),
    }
  )
}
