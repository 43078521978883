import { StartDateSolid } from '@motion/icons'
import { FieldButton, Tooltip } from '@motion/ui/base'
import { type TaskDefinitionFormRelativeInterval } from '@motion/ui-logic/pm/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useI18N } from '~/global/contexts'
import { useCallback, useMemo } from 'react'

import {
  DurationText,
  getRelativeDateTooltipContent,
  RelativeDateDropdown,
  RelativeDateLabel,
} from './components'
import { type TaskDefinitionRelativeFieldProps } from './types'

export function TaskDefinitionRelativeStartField({
  size,
  stageDuration,
  color,
  value,
  onChange,
  onRelativeIntervalUpdate,
  taskDuration,
}: TaskDefinitionRelativeFieldProps & {
  taskDuration: number | null
}) {
  const { pluralize } = useI18N()
  const { duration, referenceType } = value

  const tooltipContent = useMemo(
    () =>
      getRelativeDateTooltipContent({
        referenceType,
        duration,
        pluralize,
        startOrEnd: 'Start date',
      }),
    [referenceType, duration, pluralize]
  )

  const handleChange = useCallback(
    (
      valueOrFn:
        | TaskDefinitionFormRelativeInterval
        | ((
            prev: TaskDefinitionFormRelativeInterval
          ) => TaskDefinitionFormRelativeInterval)
    ) => {
      const newValue =
        typeof valueOrFn === 'function' ? valueOrFn(value) : valueOrFn
      onChange(newValue)

      onRelativeIntervalUpdate('start')

      recordAnalyticsEvent('TASK_DEFINITION_RELATIVE_DATE_CHANGED', {
        taskValue: 'startDate',
        target: newValue.referenceType,
        relative: newValue.duration.sign === 1 ? 'plus' : 'minus',
        type: newValue.duration.unit,
        value: newValue.duration.value,
      })
    },
    [onChange, onRelativeIntervalUpdate, value]
  )

  if (taskDuration === 0) {
    return null
  }

  return (
    <RelativeDateDropdown
      color={color}
      type='start'
      value={value}
      onChange={handleChange}
      stageDuration={stageDuration}
    >
      <Tooltip asChild renderContent={() => tooltipContent}>
        <FieldButton variant='muted' fullWidth size={size}>
          <StartDateSolid />
          <RelativeDateLabel color={color} value={referenceType} size={size} />
          <DurationText duration={duration} />
        </FieldButton>
      </Tooltip>
    </RelativeDateDropdown>
  )
}
