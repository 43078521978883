import { buildFetchRequest, useRpcContext } from '@motion/rpc'
import { API } from '@motion/rpc-definitions'
import { type UploadedFileSchema } from '@motion/rpc-types'

import { useCallback } from 'react'

export type DownloadAttachmentParams = {
  fileId: UploadedFileSchema['id']
  fileName: UploadedFileSchema['fileName']
  mimeType: UploadedFileSchema['mimeType']
}

export const downloadFileUsingAnchor = (file: File) => {
  const url = URL.createObjectURL(file)
  const a = document.createElement('a')
  a.href = url
  a.download = file.name
  a.click()
  URL.revokeObjectURL(url)
}

export const useDownloadAttachment = () => {
  const rpcContext = useRpcContext()

  const getFileUrl = useCallback(
    (fileId: UploadedFileSchema['id']) => {
      if (typeof API.files.downloadFile.uri === 'function') {
        const uri = API.files.downloadFile.uri({ id: fileId })
        return `${rpcContext.baseUri}${uri}`
      }
    },
    [rpcContext]
  )

  const fetchFile = useCallback(
    async ({ fileId, fileName, mimeType }: DownloadAttachmentParams) => {
      const fetchParams = await buildFetchRequest(
        API.files.downloadFile,
        { id: fileId },
        {
          baseUri: rpcContext.baseUri,
          token: rpcContext.token,
          headers: rpcContext.headers,
        }
      )

      const blob = await fetch(...fetchParams).then((response) =>
        response.blob()
      )

      return new File([blob], fileName, { type: mimeType })
    },
    [rpcContext]
  )

  const downloadFile = useCallback(
    async ({ fileId, fileName, mimeType }: DownloadAttachmentParams) => {
      const file = await fetchFile({ fileId, fileName, mimeType })
      downloadFileUsingAnchor(file)
    },
    [fetchFile]
  )

  return {
    getFileUrl,
    fetchFile,
    downloadFile,
  }
}
