import { Popover } from '@motion/ui/base'
import { useHasTreatment } from '@motion/web-common/flags'

import { useOnboardingApi } from '~/areas/onboarding/state/use-onboarding-api'
import { useHasSeenCta } from '~/global/hooks'
import { useUpdateMyCtaSettings } from '~/global/rpc/v2'
import { useEffect, useRef, useState } from 'react'

export const OnboardingDocPopover = () => {
  const triggerRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const { selectLastOnboardingTimestamp } = useOnboardingApi()
  const lastOnboardTimestamp = selectLastOnboardingTimestamp()
  const enabled = useHasTreatment('onboarding-welcome-doc')

  const { mutate: updateCtaSettings } = useUpdateMyCtaSettings()

  const hasSeenCta = useHasSeenCta()
  const showCta = hasSeenCta('ONBOARDING_DOC_POPOVER')

  useEffect(
    function () {
      if (!showCta.loaded) return
      if (showCta.seen || !lastOnboardTimestamp) {
        setIsOpen(false)
        return
      }

      if (!isOpen) {
        setIsOpen(true)
      }
    },
    [lastOnboardTimestamp, setIsOpen, isOpen, showCta.loaded, showCta.seen]
  )

  const handleClose = () => {
    updateCtaSettings({
      ctaSettings: {
        ONBOARDING_DOC_POPOVER: true,
      },
    })
    setIsOpen(false)
  }

  if (!enabled || (showCta.loaded && showCta.seen)) return null

  return (
    <>
      <div ref={triggerRef} className='h-0 top-3 relative right-0' />
      <Popover
        triggerRef={triggerRef}
        placement='right'
        showArrow
        arrowClassName='fill-semantic-warning-bg-active-default'
        onClose={handleClose}
        enableOutsideInteractions
        disableFocusManager
        offset={14}
      >
        <div className='w-[220px] h-[36px] rounded overflow-hidden bg-semantic-warning-bg-active-default'>
          <div className='flex flex-col gap-4 items-center justify-center pt-1.5'>
            <div>Open this doc to get started!</div>
          </div>
        </div>
      </Popover>
    </>
  )
}
