import { isPlaceholderId } from '@motion/shared/identifiers'
import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { type StageDefinitionSchema } from '@motion/zod/client'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import { showErrorToast } from '~/global/toasts'

import { useRedirectToStageTemplate } from './use-redirect-to-stage-template'
import { useSaveStage } from './use-save-stage'

import {
  updateFormWithSavedStage,
  useFlowTemplateForm,
} from '../../shared-form'

export function useSubmitStageForm(
  onSuccess?: (stageDefinition: StageDefinitionSchema) => void
) {
  const saveStage = useSaveStage()
  const redirectToStageTemplate = useRedirectToStageTemplate()

  const {
    form: { watch, getValues, reset },
  } = useFlowTemplateForm()

  const modalMode = watch('modalMode')
  const workspaceId = watch('workspaceId')
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(workspaceId)

  return async (data: FlowTemplateFormFields) => {
    try {
      const stageId = data.id
      if (!stageId) {
        throw new Error('Stage id not found')
      }

      recordAnalyticsEvent('STAGE_MODAL_SUBMIT', {
        type: isPlaceholderId(stageId) ? 'create' : 'update',
      })

      const savedStage = await saveStage(data)
      if (!savedStage) {
        return false
      }

      const updatedFormValues = updateFormWithSavedStage(
        { ...getValues(), id: savedStage.id }, // Update main form id with saved stage id
        savedStage,
        stageId,
        workspaceCustomFields
      )
      reset(updatedFormValues)

      if (modalMode === 'url') {
        return redirectToStageTemplate(savedStage.id)
      }

      return onSuccess?.(savedStage)
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          position: 'useSubmitStageForm',
        },
      })

      showErrorToast(e)
    }
  }
}
