import { LoadingOutline } from '@motion/icons'
import { logInDev, makeLog } from '@motion/web-base/logging'
import { stats } from '@motion/web-common/performance'

import { useEffect } from 'react'

const log = makeLog('loading-page')

type LoadingPageProps = {
  id: string
}

export const LoadingPage = (props: LoadingPageProps) => {
  useEffect(() => {
    const startTime = stats.mark(`loading-page-show-${props.id}`)
    return () => {
      const stat = stats.span({
        start: startTime,
        end: performance.now(),
        name: 'loading-page',
        tags: [`page:${props.id}`],
      })

      if (stat == null) return
      logInDev(
        `Time showing preparing motion "${props.id}": ${stat.duration.toFixed(2)}ms`
      )
    }
  }, [props.id])

  log('visible', props.id)

  return (
    <div className='flex h-full w-full items-center justify-center'>
      <LoadingOutline className='text-semantic-neutral-icon-default animate-spin' />
    </div>
  )
}
