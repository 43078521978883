import {
  SharedStateProvider,
  type StateKey,
  useSharedStateContext,
} from '@motion/react-core/shared-state'
import { DB, SharedStatePersister } from '@motion/web-common/storage'

import { NotetakerViewStateKey } from '~/areas/notetaker/state'
import { usePageData } from '~/areas/project-management/pages/pm-v3/routes'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

const persistKeysFilter = (key: StateKey<any>) =>
  [NotetakerViewStateKey].includes(key)

export function NotetakerShell() {
  const route = usePageData()
  const ctx = useSharedStateContext()

  useEffect(() => {
    route.state.forEach((value, key) => {
      ctx.set(key, value)
    })

    // We only want to reset these values when the route changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx, route.stateKey])

  return (
    <SharedStateProvider
      name='notetaker-view'
      key={route.stateKey}
      batchTime={0}
      initialValues={route.state}
    >
      <SharedStatePersister
        key={route.stateKey}
        prefix={route.stateKey}
        store={DB.state}
        keyFilter={persistKeysFilter}
      />

      <Outlet />
    </SharedStateProvider>
  )
}
