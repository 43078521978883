import { showToast } from '@motion/ui/base'

import { checkTextRuleErrors, getTextFieldRules } from '~/global/rules'
import { type FormEvent, useCallback } from 'react'

import { useSetupProjectForm } from './use-setup-project-form'
import { useSubmitSetupProjectForm } from './use-submit-setup-project-form'

export function useSetupProjectModalSubmitHandler(close: () => void) {
  const { form } = useSetupProjectForm()
  const submitForm = useSubmitSetupProjectForm(close)
  const activeTab = form.watch('step')

  return useCallback(
    async (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault()
      if (activeTab === 'name') return

      if (__IS_DEV__) {
        const values = form.getValues()
        // eslint-disable-next-line no-console
        console.log('🚀 ~ form values:', values)
      }

      await form.handleSubmit(
        (values) => {
          const nameError = checkTextRuleErrors(
            values.name,
            getTextFieldRules('Project name')
          )
          if (nameError) {
            showToast('error', nameError)
            return
          }

          return submitForm(values)
        },
        (validationErrors) => {
          const errors = Object.values(validationErrors)
          if (__IS_DEV__) {
            // eslint-disable-next-line no-console
            console.log('🚀 ~ form errors:', errors)
          }
          if (errors.length < 1) return

          const message = errors[0].message
          if (typeof message !== 'string' || !message) return

          showToast('error', message)
        }
      )(e)
    },
    [form, submitForm, activeTab]
  )
}
