import { isStageCanceled } from '@motion/ui-logic/pm/project'
import { Sentry } from '@motion/web-base/sentry'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
  type StageSchema,
} from '@motion/zod/client'

import type { WorkspaceStageDefinition } from '~/global/contexts'
import { useStageDefinitionsByReferences } from '~/global/hooks'
import { useMemo } from 'react'

type Options = {
  includeCancelledStages?: boolean
}

export type StageWithDefinition = StageSchema & {
  stageDefinition: WorkspaceStageDefinition | StageDefinitionSchema
}

export function useProjectStagesWithDefinitions(
  project: Pick<ProjectSchema, 'stages'>,
  options: Options = { includeCancelledStages: true }
): StageWithDefinition[] {
  const v2StageDefinitions = useStageDefinitionsByReferences(
    project.stages.map((s) => ({
      stageDefinitionId: s.stageDefinitionId,
    }))
  )

  const stageDefinitions = v2StageDefinitions

  return useMemo(() => {
    const stages = options.includeCancelledStages
      ? project.stages
      : project.stages.filter((s) => !isStageCanceled(s))

    const stageDefinitionsById = stageDefinitions.reduce(
      (acc, sd) => {
        acc[sd.id] = sd
        return acc
      },
      {} as Record<string, WorkspaceStageDefinition | StageDefinitionSchema>
    )

    return stages
      .map((stage) => {
        const stageDefinition = stageDefinitionsById[stage.stageDefinitionId]

        if (stageDefinition == null) {
          Sentry.captureException(
            new Error('Stage definition for stage not found'),
            {
              extra: {
                stageId: stage.id,
                stageDefinitionId: stage.stageDefinitionId,
              },
              tags: {
                position: 'useProjectStagesWithDefinitions',
              },
            }
          )
          return null
        }

        return {
          ...stage,
          stageDefinition,
        }
      })
      .filter(Boolean)
  }, [options.includeCancelledStages, project.stages, stageDefinitions])
}
