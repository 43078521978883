import { type ProjectSchema } from '@motion/zod/client'

import { type LookupFn } from '~/global/cache'

import { createCustomFieldProxy } from './create-custom-fields-proxy'
import {
  getOrCreateLookupProxy,
  isProxied,
  type ProxyDef,
} from './create-proxy'
import { type ProjectWithRelations } from './types'

export const PROJECT_RELATIONS = {
  status: {
    key: 'statuses',
    accessor: (project) => project.statusId,
  },
  workspace: {
    key: 'workspaces',
    accessor: (project) => project.workspaceId,
  },
  labels: {
    key: 'labels',
    accessor: (project) => ('labelIds' in project ? project.labelIds : []),
  },
  manager: {
    key: 'users',
    accessor: (project) => project.managerId,
  },
  createdByUser: {
    key: 'users',
    accessor: (project) => project.createdByUserId,
  },
  customFields: {
    accessor: (project) => project.customFieldValues,
    get(target, prop, lookup) {
      return createCustomFieldProxy(target, lookup)
    },
  },
  activeStageDefinition: {
    accessor: (project) => project.activeStageDefinitionId,
    get(target, prop, lookup) {
      const stageDefinition = lookup(
        'stageDefinitions',
        target.activeStageDefinitionId
      )

      return stageDefinition
    },
  },
  projectDefinition: {
    key: 'projectDefinitions',
    accessor: (project) => project.projectDefinitionId,
  },
  folder: { key: 'folders', accessor: (project) => project.folderId },
} satisfies Record<string | symbol, ProxyDef<ProjectSchema>>

export function createProjectProxy(
  project: ProjectSchema,
  lookup: LookupFn
): ProjectWithRelations {
  if (isProxied(project)) return project
  return getOrCreateLookupProxy(project, PROJECT_RELATIONS, lookup)
}
