import { ShareSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, Text } from '@motion/ui/base'

import { MotionLink } from '~/global/components'

import { default as EmptyStateArrow } from './empty-state-arrow.svg?react'

export const NoteNotFoundScreen = ({
  noAccess = false,
}: {
  noAccess?: boolean
}) => {
  return (
    <div className='flex flex-col h-full w-full items-center justify-center px-3'>
      <div className='bg-semantic-neutral-bg-subtle rounded-xl px-9 pt-10 w-full max-w-xl overflow-hidden'>
        <div className='flex flex-col gap-3 p-8 bg-container-bg rounded-t-2xl pb-20 shadow-sm border-t border-x border-semantic-neutral-border-default'>
          {noAccess ? (
            <div className='relative flex justify-between items-center'>
              <Text className='text-2xl font-bold'>Document Title</Text>
              {/* @ts-expect-error - className not allowed but it's fine here for presentational use only*/}
              <Button sentiment='neutral' className='pointer-events-none'>
                <ShareSolid /> Share
              </Button>
              <EmptyStateArrow className='size-20 ml-0.5 text-semantic-neutral-border-subtle absolute right-10 -top-[70px] rotate-[160deg]' />
            </div>
          ) : (
            <Text className='text-2xl font-bold line-through'>Document</Text>
          )}
          <Skeleton className='w-1/2 h-8 mt-5' />
          <Skeleton className='w-11/12 h-4' />
          <Skeleton className='w-full h-4' />
        </div>
      </div>
      <div className='flex flex-col items-center gap-4 py-4 px-8 w-full max-w-xl'>
        {noAccess ? (
          <>
            <Text className='text-center font-bold'>
              You don&apos;t have access to this document
            </Text>
            <Text className='text-center text-sm text-semantic-neutral-text-subtle'>
              Please ask the person who shared this link with you to give you
              access by sharing the document through the share control.
            </Text>
          </>
        ) : (
          <>
            <Text className='text-center font-bold'>
              This document doesn&apos;t exist
            </Text>
            <Text className='text-center text-sm text-semantic-neutral-text-subtle'>
              It may have been deleted.
            </Text>
          </>
        )}

        <div className='flex flex-col gap-1 mt-2'>
          <MotionLink url='/web/calendar'>
            <Button sentiment='neutral'>Go to Calendar</Button>
          </MotionLink>
          <MotionLink url='https://help.usemotion.com' external>
            <Button variant='muted' sentiment='neutral'>
              Contact Support
            </Button>
          </MotionLink>
        </div>
      </div>
    </div>
  )
}

const Skeleton = classed('div', 'rounded bg-semantic-neutral-bg-active-default')
