import {
  type VirtualizedTreeNode,
  type VirtualizedTreeRootNode,
} from '@motion/ui/base'
import { DEFAULT_DOC_TITLE } from '@motion/ui-logic'
import { byProperty, cascade, Compare } from '@motion/utils/array'
import {
  type NoteSchema,
  type RecursiveFolderItemSchema,
} from '@motion/zod/client'

import { useCachedItems, useLookup } from '~/global/cache'
import { type WorkspacesTreeDropdownProps } from '~/global/components/dropdowns'
import { useAllProjectsById, useGetWorkspaceByFolderId } from '~/global/hooks'
import { useFolders } from '~/global/rpc/folders'
import { useCallback, useMemo } from 'react'

export type LeafNodeType = 'WORKSPACE' | 'FOLDER' | 'PROJECT' | 'NOTE'

interface UseWorkspacesDropdownTreeArgs {
  leafNodeType: LeafNodeType
  noneItemLabel?: string
  computeDisabled?: WorkspacesTreeDropdownProps['computeDisabled']
  hideNoProject: boolean
  includeNotes?: boolean
}

const hasChildren = (item: RecursiveFolderItemSchema) => 'items' in item
const ROOT_ID = '[ROOT]'

export const useWorkspacesDropdownTree = ({
  leafNodeType,
  noneItemLabel,
  computeDisabled,
  hideNoProject,
  includeNotes = false,
}: UseWorkspacesDropdownTreeArgs) => {
  const cachedNotes = useCachedItems('notes')

  const notes = useMemo(() => {
    return cachedNotes.reduce(
      (acc, note) => {
        acc[note.id] = note
        return acc
      },
      {} as Record<string, NoteSchema>
    )
  }, [cachedNotes])

  const { data: folders } = useFolders()

  const lookup = useLookup()
  const getWorkspaceByFolderId = useGetWorkspaceByFolderId()
  const allProjectsById = useAllProjectsById()

  const formatFolderTree = useCallback(
    (item: RecursiveFolderItemSchema): VirtualizedTreeNode | null => {
      const formatChildren = () =>
        hasChildren(item)
          ? item.items
              .sort(cascade(byProperty('order', Compare.string)))
              .map((child) => formatFolderTree(child))
              .filter(Boolean)
          : []

      const id = item.itemId
      if (item.itemType === 'FOLDER') {
        const folderModel = lookup('folders', item.itemId)
        if (!folderModel) return null

        const workspaceModel = getWorkspaceByFolderId(folderModel.id)
        if (!workspaceModel) return null

        const disabled =
          computeDisabled?.({ item, workspace: workspaceModel }) ?? false

        if (folderModel.type === 'WORKSPACE') {
          const children =
            noneItemLabel && !hideNoProject
              ? [
                  {
                    id: workspaceModel.id,
                    label: noneItemLabel,
                  },
                  ...formatChildren(),
                ]
              : formatChildren()

          return {
            id,
            label: workspaceModel.name,
            disabled,
            children:
              leafNodeType === 'WORKSPACE' && children.length === 0
                ? undefined
                : children,
          }
        }

        if (leafNodeType === 'WORKSPACE') return null

        const children = formatChildren()

        return {
          id,
          label: folderModel.name ?? '(no name)',
          disabled,
          children:
            leafNodeType === 'FOLDER' && children.length === 0
              ? undefined
              : children,
        }
      }

      if (item.itemType === 'NOTE') {
        if (!includeNotes) return null

        const noteModel = notes[item.itemId]

        const children = formatChildren()

        return {
          id: item.itemId,
          label: noteModel?.title || item.name || DEFAULT_DOC_TITLE,
          children: children.length === 0 ? undefined : children,
          color: noteModel?.color || item.color,
          type: 'NOTE',
        }
      }

      if (leafNodeType !== 'PROJECT') return null

      if (item.itemType === 'PROJECT') {
        const projectModel = allProjectsById[item.itemId]
        if (!projectModel) return null

        return {
          id,
          label: projectModel.name,
        }
      }

      return null
    },
    [
      notes,
      leafNodeType,
      lookup,
      getWorkspaceByFolderId,
      noneItemLabel,
      hideNoProject,
      includeNotes,
      computeDisabled,
      allProjectsById,
    ]
  )

  return useMemo(() => {
    if (!folders?.models.systemFolders.workspaces) {
      return {
        rootNode: {
          id: ROOT_ID,
          label: ROOT_ID,
          children: [],
        } satisfies VirtualizedTreeRootNode,
        models: { folders: {}, projects: {}, notes: {} },
      }
    }

    const rootNode: VirtualizedTreeRootNode = {
      id: ROOT_ID,
      label: ROOT_ID,
      children: folders.models.systemFolders.workspaces.items
        .sort(cascade(byProperty('order', Compare.string)))
        .map((item) => formatFolderTree(item))
        .filter(Boolean),
    }
    return {
      rootNode,
      models: {
        folders: folders.models.folders,
        projects: allProjectsById,
        notes: notes ?? {},
      },
    }
  }, [
    allProjectsById,
    folders?.models.folders,
    folders?.models.systemFolders.workspaces,
    formatFolderTree,
    notes,
  ])
}
