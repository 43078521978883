import {
  clearProjectForParams,
  type ForProjectSearchParams,
} from '../../project-modal/utils'
import { type SetupProjectTabOption } from '../hooks'

const setupProjectSearchParams = [
  'flowTemplateId',
  'forProjectId',
  'forTaskId',
  'forFolder',
  'forWorkspace',
] as const

export type SetupProjectSearchParams = ForProjectSearchParams & {
  [K in (typeof setupProjectSearchParams)[number]]?: string
} & {
  project: 'create'
  activeTab?: SetupProjectTabOption
}

export type SetupProjectUrlParams = {
  workspaceId?: string
  folderId?: string
  projectId?: string
}

export const clearSetupProjectParams = (searchParams: URLSearchParams) => {
  setupProjectSearchParams.forEach((param) => searchParams.delete(param))
  clearProjectForParams(searchParams)
  searchParams.delete('project')
  searchParams.delete('template')
  searchParams.delete('activeTab')
  return searchParams
}
