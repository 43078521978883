import { WorkspacesTreeview } from '~/areas/treeviews/components'

import { AddNewTreeviewItem, ConnectedTreeviewItem } from './components'
import { ConnectedEmptyState } from './components/empty-state'
import { treeviewItemHeight } from './components/treeview-item'
import { useBrowseTreeViewItems } from './hooks'

export type ConnectedBrowseTreeviewProps = {
  searchValue: string
}

export function ConnectedBrowseTreeview({
  searchValue,
}: ConnectedBrowseTreeviewProps) {
  const { items, renderFilter, toggleExpandState, tree, treeRoot } =
    useBrowseTreeViewItems({
      searchValue,
    })

  return (
    <>
      <WorkspacesTreeview
        items={items}
        estimateItemSize={() => treeviewItemHeight}
        renderFilter={renderFilter}
        renderItem={({ item, level, projection }) => {
          return item.type === 'PLACEHOLDER' ? (
            <AddNewTreeviewItem item={item} level={level} />
          ) : (
            <ConnectedTreeviewItem
              item={item}
              level={level}
              projection={projection}
              toggleExpandState={toggleExpandState}
              disableDrag={!!searchValue}
            />
          )
        }}
        renderGhostItem={({ item, level, projection }) => (
          <ConnectedTreeviewItem
            item={item}
            level={level}
            projection={projection}
            disableDrag
            isGhost
          />
        )}
        autoLevelToZero
      />

      <ConnectedEmptyState items={tree} viewType={treeRoot?.type} />
    </>
  )
}
