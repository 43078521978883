import { useThrottledCallback } from '@motion/react-core/hooks'
import {
  type FlowTemplateFormFields,
  type FlowTemplateStage,
  moveTaskInStages,
} from '@motion/ui-logic/pm/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import {
  type DragOverEvent,
  type DragStartEvent,
  type UniqueIdentifier,
} from '@dnd-kit/core'
import { useFlowsModalState } from '~/areas/flows/contexts'
import {
  useGetOriginalTaskDefinitionState,
  useWarnAndRemoveTemplateBlockersFn,
} from '~/areas/flows/flow-template-modal/components/stages/hooks'
import {
  areIndiciesDirty,
  getNewIndices,
} from '~/areas/flows/flow-template-modal/components/stages/utils'
import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { useCallback, useMemo, useRef } from 'react'
import { type UseFieldArrayReplace } from 'react-hook-form'

type TemplateDndHooksArgs = {
  setActiveId: (id: UniqueIdentifier | null) => void
  replace: UseFieldArrayReplace<FlowTemplateFormFields, 'stages'>
}

export const useTemplateDndHandlerHooks = ({
  setActiveId,
  replace,
}: TemplateDndHooksArgs) => {
  const warnAndRemoveTemplateBlockers = useWarnAndRemoveTemplateBlockersFn()
  const { setDirtyTasksMap } = useFlowsModalState()
  const getOriginalTaskDefinitionState = useGetOriginalTaskDefinitionState()
  const {
    form: { watch, clearErrors },
  } = useFlowTemplateForm()

  const stages = watch('stages')
  const previousStages = useRef<FlowTemplateStage[] | null>(null)

  const onDragStart = useCallback(
    (event: DragStartEvent) => {
      if (previousStages.current == null) {
        previousStages.current = stages
      }
      setActiveId(event.active.id)
      clearErrors('stages')
    },
    [setActiveId, clearErrors, stages]
  )

  const onDragOver = useThrottledCallback(
    ({ active, over }: DragOverEvent) => {
      if (!over?.id) return

      const activeId = active.id as string
      const overId = over.id as string

      const fromIndices = getNewIndices(stages, activeId)
      if (!fromIndices) return

      const [fromStageIndex, fromTaskIndex] = fromIndices
      const toIndices = getNewIndices(stages, overId)
      const toStageIndex = toIndices?.[0] ?? stages.length - 1
      const toTaskIndex = toIndices?.[1] ?? stages[toStageIndex].tasks.length

      const activeTask = stages[fromStageIndex].tasks[fromTaskIndex]
      const updatedStages = moveTaskInStages(
        activeTask,
        stages,
        toTaskIndex,
        toStageIndex
      )

      replace(updatedStages)
    },
    [replace, stages],
    100
  )

  const onDragCancel = useCallback(() => {
    setActiveId(null)
    if (previousStages.current == null) return

    replace(previousStages.current)
    previousStages.current = null
  }, [replace, setActiveId])

  const updateTaskState = useCallback(
    (activeId: string, stages: FlowTemplateStage[]) => {
      const newIndices = getNewIndices(stages, activeId)
      const { originalIndices } = getOriginalTaskDefinitionState(activeId)

      if (!newIndices) {
        Sentry.captureException(
          new Error('New task/stage indices not found in onDragEnd')
        )
        return
      }

      const isDirty = areIndiciesDirty(originalIndices, newIndices)
      setDirtyTasksMap((prev) => ({
        ...prev,
        [activeId]: isDirty,
      }))
    },
    [getOriginalTaskDefinitionState, setDirtyTasksMap]
  )

  const onDragEnd = useCallback(
    async ({ active }: DragOverEvent) => {
      const activeId = active.id as string
      const { moved } = await warnAndRemoveTemplateBlockers({
        previousStages: previousStages.current ?? [],
        activeTaskId: activeId,
        stages,
        replace,
      })

      if (moved) {
        recordAnalyticsEvent('FLOW_TEMPLATE_TASK_MODIFIED', {
          type: 'move',
          method: 'drag',
        })

        updateTaskState(activeId, stages)
      }

      previousStages.current = null

      if ('requestIdleCallback' in window) {
        requestIdleCallback(() => setActiveId(null), { timeout: 300 })
      } else {
        requestAnimationFrame(() => setActiveId(null))
      }
    },
    [
      replace,
      setActiveId,
      stages,
      updateTaskState,
      warnAndRemoveTemplateBlockers,
    ]
  )

  return useMemo(
    () => ({
      onDragStart,
      onDragOver,
      onDragCancel,
      onDragEnd,
    }),
    [onDragStart, onDragOver, onDragCancel, onDragEnd]
  )
}
