import { type NoteSchema } from '@motion/rpc-types'

import { useNoteLocation } from '~/areas/notes'
import {
  ConnectedFolderBadge,
  ConnectedNoteBadge,
  ConnectedProjectBadge,
  WorkspaceBadge,
} from '~/global/components/badges'

export type NoteLocationIconProps = {
  noteId: NoteSchema['id']
}

export function NoteLocationIcon({ noteId }: NoteLocationIconProps) {
  const [parentId, parentType] = useNoteLocation({
    noteId,
  })

  return parentId != null && parentType === 'PROJECT' ? (
    <ConnectedProjectBadge id={parentId} />
  ) : parentId != null && parentType === 'NOTE' ? (
    <ConnectedNoteBadge id={parentId} />
  ) : parentId != null && parentType === 'WORKSPACE' ? (
    <WorkspaceBadge />
  ) : (
    <ConnectedFolderBadge id={parentId ?? ''} />
  )
}
