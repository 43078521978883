import { byProperty, ordered } from '@motion/utils/array'
import { pick } from '@motion/utils/core'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useWorkspaceAvailableCustomFields } from '~/areas/custom-fields/hooks'
import { useWorkspaceFns } from '~/global/hooks'
import { useCallback, useMemo } from 'react'

import { useKanbanCardFieldViewState } from './use-kanban-card-field-view-state'

import { type VizField } from '../../components/visibility-dropdown-content'

export function useKanbanCardCustomFields() {
  const [fields, setFields] = useKanbanCardFieldViewState()

  const availableCustomFields = useWorkspaceAvailableCustomFields()
  const { getWorkspaceById } = useWorkspaceFns()

  const items: VizField[] = useMemo(
    () =>
      availableCustomFields
        .map((field) => {
          const isVisible =
            fields.find(({ id }) => id === field.id)?.visible ?? false

          const workspace = getWorkspaceById(field.workspaceId)

          return {
            id: field.id,
            visible: isVisible,
            name: field.name,
            workspaceName: workspace?.name,
          }
        })
        .sort(byProperty('id', ordered(fields.map(({ id }) => id)))),
    [availableCustomFields, fields, getWorkspaceById]
  )

  const toggleFieldVisibility = useCallback(
    (fieldId: string, isVisible: boolean) => {
      setFields((prev) =>
        prev.map((item) => {
          const pickedFields = pick(item, ['id', 'visible'])
          return item.id === fieldId
            ? { ...pickedFields, visible: isVisible }
            : pickedFields
        })
      )
      recordAnalyticsEvent('PROJECT_MANAGEMENT_FIELD_VIZ_TOGGLE', {
        type: 'kanban',
        visible: isVisible,
      })
    },
    [setFields]
  )

  const onOrderChange = useCallback(
    (newItems: typeof items) => {
      const newFields = newItems.map((item) => pick(item, ['id', 'visible']))

      setFields(newFields)

      recordAnalyticsEvent('PROJECT_MANAGEMENT_FIELD_VIZ_REORDER', {
        type: 'kanban',
      })
    },
    [setFields]
  )

  return { items, onOrderChange, toggleFieldVisibility }
}
