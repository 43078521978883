import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useStageTaskCardFields } from '~/areas/flows/contexts'
import { useMemo } from 'react'

import { UnconnectedBlockedByField } from '../../unconnected-blocked-by-field'

export function BlockedByField() {
  const { stageTasks, task, textVariables, fields } = useStageTaskCardFields()

  const [blockedByTaskId, onChangeBlockedByTaskId] = fields.blockedByTaskId
  const [blockedByTaskIds, onChangeBlockedByTaskIds] = fields.blockedByTaskIds

  const blockedByTasks = useMemo(() => {
    // We include `blockedByTaskId` for backward compatibility
    const allBlockedTaskIds = new Set(
      [blockedByTaskId, ...blockedByTaskIds].filter(Boolean)
    )
    return stageTasks.filter((t) => allBlockedTaskIds.has(t.id))
  }, [blockedByTaskId, blockedByTaskIds, stageTasks])

  const onChange = (values: string[]) => {
    onChangeBlockedByTaskId(values.length ? values[0] : null)
    onChangeBlockedByTaskIds(values)
    recordAnalyticsEvent('FLOW_TASK_DEFINITION_MULTIPLE_BLOCKERS_MODIFIED', {
      numberOfBlockers: values.length,
    })
  }

  return (
    <UnconnectedBlockedByField
      stageTasks={stageTasks}
      task={task}
      values={blockedByTasks}
      textVariables={textVariables}
      onChange={onChange}
    />
  )
}
