import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import {
  type CalendarEventSchemaV2,
  type RecurrenceUpdateType,
  type UpdateCalendarEventSchema,
} from '@motion/zod/client'

import { useLookup } from '~/global/cache'
import { selectEmailAccounts } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'
import { useCallback } from 'react'

export const useUpdateEventPrompts = () => {
  const modalApi = useModalApi()
  const lookup = useLookup()
  const emailAccounts = useAppSelector(selectEmailAccounts)

  return useCallback(
    async (
      id: CalendarEventSchemaV2['id'],
      updates?: Pick<UpdateCalendarEventSchema, 'start' | 'sendUpdates'>,
      operation: 'update' | 'delete' = 'update'
    ) => {
      const calendarEvent = lookup('calendarEvents', id)

      const emailAccount = emailAccounts.find(
        (emailAccount) => emailAccount.email === calendarEvent?.email
      )

      if (!calendarEvent || !emailAccount) {
        throw new Error('Calendar event could not be updated', {
          cause: {
            calendarEventId: id,
            calendarEventEmail: calendarEvent?.email,
            emailAccounts: emailAccounts.map((ea) => ea.email),
          },
        })
      }

      let recurrenceUpdateType: RecurrenceUpdateType | undefined
      if (calendarEvent.recurringEventId != null) {
        const modalResponse = await modalApi.prompt('recurring-event-update', {
          calendarEvent,
          updates: { start: updates?.start },
          operation,
        })

        if (modalResponse === ModalDismissed) {
          return
        }

        recurrenceUpdateType = modalResponse
      }

      let sendUpdates = updates?.sendUpdates
      if (
        sendUpdates == null &&
        // Outlook always sends an email to guests
        emailAccount.providerType !== 'MICROSOFT' &&
        // Apple calendars do not support sending emails on behalf of the user
        emailAccount.providerType !== 'APPLE' &&
        calendarEvent.attendees &&
        calendarEvent.attendees.length > 0
      ) {
        const modalResponse = await modalApi.prompt('action', {
          title: 'Would you like to send update emails to guests?',
          children: null,
          blocking: true,
          actions: [
            { label: 'Back to editing', value: 'cancel', sentiment: 'neutral' },
            { label: "Don't send", value: 'no', sentiment: 'neutral' },
            { label: 'Send', value: 'yes' },
          ],
        })
        if (modalResponse === ModalDismissed || modalResponse === 'cancel') {
          return
        }
        sendUpdates = modalResponse === 'yes'
      }

      return { sendUpdates, recurrenceUpdateType }
    },
    [lookup, emailAccounts, modalApi]
  )
}
