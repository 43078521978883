import { classed } from '@motion/theme'
import { GradientButton, Text } from '@motion/ui/base'

import { StepLineSeparator, WorkflowStepCard } from './components'

export function AIWorkflowsSettingsPage() {
  return (
    <Section>
      <div className='flex flex-col gap-2'>
        <Text as='h1' size='xl' weight='semibold'>
          Introducing Motion AI Workflows
        </Text>
        <Text size='sm' weight='bold'>
          Let AI handle your routine tasks while you focus on what matters most
        </Text>
      </div>
      <Text size='sm'>
        AI Workflows work alongside your team to automate your project tasks -
        drafting emails, creating documents, and more. Every workflow requires
        your review and approval, keeping you in complete control.
      </Text>

      <section className='h-[250px] overflow-hidden'>
        <WorkflowStepCard title='Get Topics' type='LLM_GENERATION' />
        <StepLineSeparator />
        <WorkflowStepCard title='Research' type='WEB_RESEARCH' />
      </section>

      <section className='flex flex-col items-start gap-3'>
        <Text size='sm' weight='bold'>
          Be among the first to transform how your Team works!
        </Text>
        <GradientButton
          sentiment='promotion'
          url='https://forms.gle/St5VZyhAucvT1iGV6'
          external
        >
          Sign up for early access!
        </GradientButton>
      </section>
    </Section>
  )
}

const Section = classed('section', {
  base: 'flex flex-col gap-4 w-full max-w-[600px] pb-8',
})
