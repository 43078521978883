import {
  type ApiTypes,
  type ApiUseMutationOptions,
  createUseMutation,
  useQueryOptionsFactory,
} from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
  type OptimisticUpdateValue,
} from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { type StageDefinitionSchema } from '@motion/rpc-types'
import { createLookupById } from '@motion/utils/object'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { applyOptimisticProjectDefinitionUpdates } from '~/global/cache'

const CACHE_KEYS_TO_UPDATE = [API.workspacesV2.queryKeys.root, MODEL_CACHE_KEY]

export const useCreateProjectDefinition = () => {
  const queryClient = useQueryClient()
  return createUseMutation(API.projectDefinitions.create)({
    onSuccess: (data) => {
      MotionCache.upsert(
        queryClient,
        createQueryFilter(CACHE_KEYS_TO_UPDATE),
        data
      )
    },
  })
}

const useUpdateProjectDefinitionMutation = createUseMutation(
  API.projectDefinitions.update
)
export const useUpdateProjectDefinition = (
  opts?: ApiUseMutationOptions<typeof API.projectDefinitions.update>
) => {
  const queryClient = useQueryClient()

  return useUpdateProjectDefinitionMutation({
    onMutate: async (updatedProjectDefinition) => {
      const cacheUpdates = applyOptimisticProjectDefinitionUpdates(
        queryClient,
        updatedProjectDefinition.id,
        {
          id: updatedProjectDefinition.id,
          ...updatedProjectDefinition.definition,
        }
      )

      return { cacheUpdates }
    },
    onSuccess: (data) => {
      MotionCache.upsert(
        queryClient,
        createQueryFilter(CACHE_KEYS_TO_UPDATE),
        data
      )
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }

      cacheUpdates?.rollback()
    },

    ...opts,
  })
}
const useDeleteProjectDefinitionMutation = createUseMutation(
  API.projectDefinitions.deleteProjectDefinition
)
export const useDeleteProjectDefinition = () => {
  const queryClient = useQueryClient()

  return useDeleteProjectDefinitionMutation({
    onSuccess: (_, { id }) => {
      MotionCache.delete(
        queryClient,
        createQueryFilter(CACHE_KEYS_TO_UPDATE),
        'projectDefinitions',
        id
      )
    },
  })
}

const useCopyProjectDefinitionMutation = createUseMutation(
  API.projectDefinitions.copyProjectDefinition
)
export const useCopyProjectDefinition = () => {
  const queryClient = useQueryClient()

  return useCopyProjectDefinitionMutation({
    onSuccess: (data) => {
      const stageDefinitions = Object.values(data.models.projectDefinitions)
        .flatMap((project) => project.stages)
        .reduce(
          (acc, stage) => {
            acc[stage.id] = {
              ...stage,
            }
            return acc
          },
          {} as Record<string, StageDefinitionSchema>
        )

      const parsedData = {
        ...data,
        models: {
          ...data.models,
          stageDefinitions,
        },
      }

      MotionCache.upsert(
        queryClient,
        createQueryFilter(CACHE_KEYS_TO_UPDATE),
        parsedData
      )
    },
  })
}

export const useCreateLegacyProjectShimMutation = createUseMutation(
  API.projectDefinitions.legacyCreateProjectShim
)

export const useCreateLegacyProjectShim = () => {
  const queryClient = useQueryClient()

  return useCreateLegacyProjectShimMutation({
    onSuccess: (data) => {
      const stageDefinitions = data.projectDefinition.stages.reduce(
        (acc, stage) => {
          acc[stage.id] = {
            ...stage,
          }
          return acc
        },
        {} as Record<string, StageDefinitionSchema>
      )

      const parsedData = {
        models: {
          projectDefinitions: {
            [data.projectDefinition.id]: data.projectDefinition,
          },
          stageDefinitions,
        },
      }

      MotionCache.upsert(
        queryClient,
        createQueryFilter(CACHE_KEYS_TO_UPDATE),
        parsedData
      )
    },
  })
}

export const useCreateProjectDefinitionFullMutation = createUseMutation(
  API.projectDefinitions.createProjectDefinitionFull
)

export const useCreateProjectDefinitionFull = () => {
  const queryClient = useQueryClient()

  return useCreateProjectDefinitionFullMutation({
    onSuccess: (data) => {
      MotionCache.upsert(
        queryClient,
        createQueryFilter(CACHE_KEYS_TO_UPDATE),
        data
      )

      const stageDefinitions = Object.values(
        data.models.projectDefinitions
      ).flatMap((project) => project.stages)

      const stageDefinitionsMap = createLookupById(stageDefinitions)

      const stageDefinitionUpdate = {
        ids: stageDefinitions.map((s) => s.id),
        meta: {
          model: 'stageDefinitions',
        },
        models: {
          stageDefinitions: stageDefinitionsMap,
        },
      }

      MotionCache.upsert(
        queryClient,
        createQueryFilter(CACHE_KEYS_TO_UPDATE),
        stageDefinitionUpdate
      )
    },
  })
}

export const useUpdateProjectDefinitionFullMutation = createUseMutation(
  API.projectDefinitions.updateProjectDefinitionFull
)

export const useUpdateProjectDefinitionFull = () => {
  const queryClient = useQueryClient()

  return useUpdateProjectDefinitionFullMutation({
    onSuccess: (data) => {
      MotionCache.upsert(
        queryClient,
        createQueryFilter([
          ...CACHE_KEYS_TO_UPDATE,
          API.projectDefinitions.queryKeys.queryFullById(data),
        ]),
        data
      )

      // TODO: extract this logic into a function
      const stageDefinitions = Object.values(
        data.models.projectDefinitions
      ).flatMap((project) => project.stages)

      const stageDefinitionsMap = createLookupById(stageDefinitions)

      const stageDefinitionUpdate = {
        ids: stageDefinitions.map((s) => s.id),
        meta: {
          model: 'stageDefinitions',
        },
        models: {
          stageDefinitions: stageDefinitionsMap,
        },
      }

      MotionCache.upsert(
        queryClient,
        createQueryFilter(CACHE_KEYS_TO_UPDATE),
        stageDefinitionUpdate
      )
    },
  })
}

type GetProjectDefinitionFullApi = ApiTypes<
  typeof API.projectDefinitions.getProjectDefinitionFull
>

export const useGetProjectDefinitionFull = (
  args: GetProjectDefinitionFullApi['args'],
  opts?: GetProjectDefinitionFullApi['UseQueryOptions']
) => {
  const client = useQueryClient()
  const queryOptionsOf = useQueryOptionsFactory(
    API.projectDefinitions.getProjectDefinitionFull
  )
  const queryArgs = queryOptionsOf(args, opts)

  return useQuery({
    ...queryArgs,
    async queryFn(ctx) {
      const value = (await queryArgs.queryFn(
        ctx
      )) as GetProjectDefinitionFullApi['data']
      MotionCache.upsert(client, createQueryFilter(CACHE_KEYS_TO_UPDATE), value)
      return value
    },
  })
}
