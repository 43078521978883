import { templateStr } from '@motion/react-core/strings'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type AnalyticEvents } from '@motion/web-base/analytics/events'

import { useCallback, useMemo } from 'react'

import { useDesktopState } from './use-desktop-state'

export const useDesktopTabs = () => {
  // Sync with the desktop thread to ensure its never out of sync
  const [
    {
      tabs: {
        hasNativeDesktopTabs,
        maxNumberOfTabs = 1,
        currentNumberOfTabs = 1,
      },
    },
  ] = useDesktopState()

  const openTab = useCallback(
    (url: string, type: AnalyticEvents['DESKTOP_TAB_OPEN']['type']) => {
      if (!hasNativeDesktopTabs) return

      recordAnalyticsEvent('DESKTOP_TAB_OPEN', { type })

      // For now, we can just open the tab with a blank target, we may want to
      // optimize this in the future
      window.open(url, '_blank')
    },
    [hasNativeDesktopTabs]
  )

  const canOpenTab = useMemo(() => {
    if (!hasNativeDesktopTabs) return false

    return currentNumberOfTabs < (maxNumberOfTabs ?? 1)
  }, [hasNativeDesktopTabs, maxNumberOfTabs, currentNumberOfTabs])

  const tabTooltip = useMemo(() => {
    if (!hasNativeDesktopTabs || canOpenTab) return ''

    return templateStr('Max of {{maxTabs}} tabs are supported right now', {
      maxTabs: maxNumberOfTabs,
    })
  }, [hasNativeDesktopTabs, canOpenTab, maxNumberOfTabs])

  return {
    hasTabs: hasNativeDesktopTabs ?? false,
    openTab,
    canOpenTab,
    tabTooltip,
  }
}
