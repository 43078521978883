import { useModalApi } from '@motion/web-common/modals'

import { useCallback } from 'react'

export const useOnCancel = (setEditable?: (editable: boolean) => void) => {
  const modalApi = useModalApi()
  return useCallback(
    (hasChanged: boolean, cb: (discard: boolean) => void) => {
      if (!hasChanged) {
        cb(true)
        setEditable?.(false)
        return
      }
      void modalApi.prompt('confirm', {
        analytics: {
          name: 'discard-note-comment',
        },
        title: 'Discard changes?',
        description: 'Confirm that you want to discard your changes.',
        destructive: true,
        confirmButtonText: 'Discard',
        onValue: (shouldDiscard) => {
          cb(shouldDiscard)
          if (shouldDiscard) {
            setEditable?.(false)
          }
        },
      })
    },
    [modalApi, setEditable]
  )
}
