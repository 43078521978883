import { getInitialFormData, type TaskDefaults } from '@motion/ui-logic/pm/task'
import { merge } from '@motion/utils/core'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useMyUserSettings, useSetting } from '@motion/web-common/settings'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import {
  useMyTasksWorkspace,
  useProject,
  useWorkspaceById,
  useWorkspaceLabels,
  useWorkspaceStatuses,
} from '~/global/hooks'
import { type ScheduleCollection } from '~/types/settingsTypes'
import { useMemo } from 'react'

// TODO fix the return type of useSetting
// https://app.graphite.dev/github/pr/usemotion/motion/22070/chore(motion-extension)-Replace-empty-objects-and-noop-functions?utm_source=gt-slack-notif&panel=timeline#comment-PRRC_kwDOL_0cvs54udCO
const DEFAULT_SCHEDULES = READONLY_EMPTY_OBJECT as ScheduleCollection

export const useTaskDefaults = (): {
  global: TaskDefaults
  byWorkspaceId: Record<string, TaskDefaults>
} => {
  const { data } = useMyUserSettings(undefined, {
    meta: { source: 'useTaskDefaults' },
  })
  const { uid: currentUserId } = useAuthenticatedUser()

  const userDefinedTaskDefaults = data?.taskDefaultSettings
  const globalDefault = userDefinedTaskDefaults?.global

  const defaultWorkspace = useMyTasksWorkspace()
  const globalDefaultWorkspace =
    useWorkspaceById(globalDefault?.workspaceId) ?? defaultWorkspace
  const globalDefaultWorkspaceId = globalDefaultWorkspace?.id

  const workspaceStatuses = useWorkspaceStatuses(globalDefaultWorkspaceId)
  const workspaceLabels = useWorkspaceLabels(globalDefaultWorkspaceId)
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(
    globalDefaultWorkspaceId
  )

  const [userSchedules = DEFAULT_SCHEDULES] = useSetting(
    'schedules',
    DEFAULT_SCHEDULES
  )

  if (!globalDefaultWorkspace) {
    throw new Error('Default workspace not found', {
      cause: {
        currentUserId,
      },
    })
  }

  const projectId = globalDefault?.projectId
  let globalDefaultProject = useProject(projectId)
  // If the project does not belong to the workspace, clear it
  if (
    projectId != null &&
    globalDefaultProject != null &&
    globalDefaultProject.workspaceId !== globalDefaultWorkspaceId
  ) {
    globalDefaultProject = null
  }

  const globalTaskDefaults = getInitialFormData({
    userDefinedTaskDefaults,
    workspaceId: globalDefaultWorkspace.id,
    project: globalDefaultProject,
    workspaceStatuses,
    workspaceLabels,
    workspaceCustomFields,
    currentUserId,
    searchParams: {},
    schedules: userSchedules,
  })

  const relativeStartOn = globalDefault?.relativeStartOn ?? ('today' as const)
  const relativeDueDate =
    globalDefault?.relativeDueDate ?? ('tomorrow' as const)

  return useMemo(
    () => ({
      global: merge(
        {
          relativeStartOn,
          relativeDueDate,
        },
        globalTaskDefaults
      ),
      byWorkspaceId: {},
    }),
    [globalTaskDefaults, relativeStartOn, relativeDueDate]
  )
}
