import { templateStr } from '@motion/react-core/strings'
import { type HttpConnectionError } from '@motion/rpc'

import type { ReactNode } from 'react'

type NetworkIssueToastProps = {
  error: HttpConnectionError
}

export function NetworkIssueToast({
  error,
}: NetworkIssueToastProps): ReactNode {
  return (
    <div className='flex flex-col gap-2'>
      <span>We are detecting some network issues, please try again</span>

      <span className='text-2xs text-semantic-neutral-text-disabled break-all'>
        {templateStr('{{ method }} {{ uri }}', {
          method: error.method,
          uri: error.uri,
        })}
      </span>
    </div>
  )
}
