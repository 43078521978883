import {
  BaseEditor,
  type BaseEditorProps,
  LexicalTabIndentationPlugin,
  LIST_TRANSFORMERS,
  ListItemNode,
  ListNode,
  ListPlugin,
  MarkdownShortcutPlugin,
} from '@motion/notes'
import { toMerged } from '@motion/utils/core'
import { createLookupByKey } from '@motion/utils/object'
import { type DeepPartial } from '@motion/utils/types'

import { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  defaultMentionNodeRenderFns,
  MentionNodeRenderContext,
  type Reference,
  type ReferencesPluginProps,
} from './editor-plugins'
import { ReferencesPlugin } from './editor-plugins/references-plugin'

type ReferenceEditorOptions = {
  mentions: {
    excludeMotionMentions?: ReferencesPluginProps['excludeMotionMentions']
    list: Reference[]
  }
}

const defaultOptions: ReferenceEditorOptions = {
  mentions: {
    excludeMotionMentions: false,
    list: [],
  },
}

export type ReferenceEditorProps = BaseEditorProps & {
  options?: DeepPartial<ReferenceEditorOptions>
}

export const ReferenceEditor = ({
  nodes = [],
  options,
  className,
  ...props
}: ReferenceEditorProps) => {
  const opts = useMemo<ReferenceEditorOptions>(
    () => toMerged(defaultOptions, options),
    [options]
  )

  const mentionsNodeRenderFns = useMemo(() => {
    return {
      ...(opts.mentions.excludeMotionMentions
        ? {}
        : defaultMentionNodeRenderFns),
      ...createLookupByKey(
        opts.mentions.list,
        'type',
        (v) => v.renderLexicalNode
      ),
    }
  }, [opts.mentions.excludeMotionMentions, opts.mentions.list])

  return (
    <MentionNodeRenderContext.Provider value={mentionsNodeRenderFns}>
      <BaseEditor
        {...props}
        editableClassName={twMerge('motion-docs-editor', className)}
        name={props.name ?? 'reference-editor'}
        nodes={[ReferencesPlugin.Node, ListItemNode, ListNode, ...nodes]}
        plugins={
          <>
            <ReferencesPlugin
              excludeMotionMentions={opts.mentions.excludeMotionMentions}
              references={opts.mentions.list}
            />
            {/* Markdown is required for typing lists `- foobar` */}
            <MarkdownShortcutPlugin transformers={LIST_TRANSFORMERS} />
            <ListPlugin />
            {/* LexicalTabIndentationPlugin is required to be able to do nested lists */}
            <LexicalTabIndentationPlugin />
            {props.plugins}
          </>
        }
      />
    </MentionNodeRenderContext.Provider>
  )
}
