import { CalendarSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { formatDateTime } from '@motion/ui-logic'

import { ActivityValue } from '../components'

export function handleScheduledDate(
  oldDate: string | null,
  newDate: string,
  type: 'start' | 'end'
) {
  const children =
    oldDate == null
      ? templateStr("rescheduled the event's {{type}} to {{newDate}}.", {
          type: type === 'start' ? 'start date' : 'end date',
          newDate: (
            <ActivityValue>{formatDateTime(newDate, ', ')}</ActivityValue>
          ),
        })
      : templateStr(
          "rescheduled the event's {{type}} from {{oldDate}} to {{newDate}}.",
          {
            type: type === 'start' ? 'start date' : 'end date',
            oldDate: (
              <ActivityValue>{formatDateTime(oldDate, ', ')}</ActivityValue>
            ),
            newDate: (
              <ActivityValue>{formatDateTime(newDate, ', ')}</ActivityValue>
            ),
          }
        )

  return {
    icon: <CalendarSolid />,
    children,
  }
}
