import { type CellRenderFn } from '~/areas/project-management/pages/pm-v3/tree-list/cells'
import { DateTimeCell } from '~/areas/project-management/pages/pm-v3/tree-list/cells/common-cells'
import { CellText } from '~/areas/project-management/pages/pm-v3/tree-list/cells/components'
import { DateGroupHeader } from '~/areas/project-management/pages/pm-v3/tree-list/cells/group-headers'
import { TimeRangeCell } from '~/areas/project-management/pages/pm-v3/tree-list/cells/time-range-cell'

import {
  ActionItemsCell,
  AttendeesCell,
  ExpandableHeader,
  HostCell,
  NameCell,
} from './components/cells'
import { type MeetingInsightsFieldId } from './types'

export const CELLS: Record<MeetingInsightsFieldId, CellRenderFn> = {
  title(info) {
    const item = info.row.original.value
    const counter = info.row.index + 1

    let cell = <CellText>-</CellText>
    const canExpand = info.row.getCanExpand()

    if (item.type === 'meetingInsights') {
      cell = <NameCell counter={counter} meetingInsights={item.value} />
    } else if (item.type === 'startDate') {
      cell = <DateGroupHeader item={item} fieldName='Date' />
    } else if (item.type === 'host') {
      cell = <CellText>{item.value}</CellText>
    }

    if (canExpand) {
      return <ExpandableHeader cell={cell} info={info} />
    }

    return cell
  },
  actionItems(info) {
    const item = info.row.original.value

    let cell = <CellText>-</CellText>

    if (item.type === 'meetingInsights') {
      cell = <ActionItemsCell meetingInsights={item.value} />
    }

    return cell
  },
  startDate(info) {
    const item = info.row.original.value
    let cell = <CellText>-</CellText>

    if (item.type === 'meetingInsights') {
      cell = <DateTimeCell dateTime={item.value.startTime} />
    }

    return cell
  },
  startTime(info) {
    const item = info.row.original.value

    let cell = <CellText>-</CellText>

    if (item.type === 'meetingInsights' && item.value.eventData) {
      cell = (
        <TimeRangeCell
          startTime={item.value.eventData.start}
          endTime={item.value.eventData.end}
        />
      )
    }

    return cell
  },
  host(info) {
    const item = info.row.original.value

    let cell = <CellText>-</CellText>

    if (
      item.type === 'meetingInsights' &&
      item.value.eventData?.organizer != null
    ) {
      cell = <HostCell organizer={item.value.eventData.organizer} />
    }

    return cell
  },
  attendees(info) {
    const item = info.row.original.value

    let cell = <CellText>-</CellText>

    if (
      item.type === 'meetingInsights' &&
      item.value.eventData?.attendeeEmails != null
    ) {
      cell = (
        <AttendeesCell attendeeEmails={item.value.eventData.attendeeEmails} />
      )
    }

    return cell
  },
}
