import { UserCard } from '@motion/ui/base'

import { getBadgeSizeInPixels } from '~/global/components/badges'
import {
  RoundNumOverflowBadge,
  useCalculatePrettyLabels,
} from '~/global/components/labels'
import { twMerge } from 'tailwind-merge'

import { AttendeeBadge, type AttendeeBadgeProps } from './attendee-badge'

export type PrettyAttendeesProps = {
  attendees: AttendeeBadgeProps['value'][]
} & Omit<AttendeeBadgeProps, 'value'>

export function PrettyAttendees({
  attendees,
  ...badgeProps
}: PrettyAttendeesProps) {
  const labelWidth = getBadgeSizeInPixels(badgeProps.size)

  const { usedLabels, nbOverflow, setContainer } = useCalculatePrettyLabels({
    values: attendees.map((attendee) => ({ id: attendee.email })),
    labelWidth,
  })

  const isMoreThanOne = attendees.length > 1

  if (attendees.length === 0) return null

  const usedLabelAttendees = usedLabels
    .map((value) => attendees.find((attendee) => attendee.email === value.id))
    .filter(Boolean)

  return (
    <div
      className={twMerge(
        'flex flex-row items-center gap-1 w-full',
        !isMoreThanOne && `truncate`
      )}
      ref={setContainer}
    >
      {isMoreThanOne ? (
        usedLabelAttendees.map((value) => (
          <AttendeeBadge key={value.email} value={value} {...badgeProps} />
        ))
      ) : (
        <UserCard
          name={
            usedLabelAttendees[0].displayName ?? usedLabelAttendees[0].email
          }
          icon={<AttendeeBadge value={usedLabelAttendees[0]} {...badgeProps} />}
        />
      )}
      {nbOverflow > 0 && (
        <RoundNumOverflowBadge
          style={{ width: labelWidth, height: labelWidth }}
        >
          +{nbOverflow}
        </RoundNumOverflowBadge>
      )}
    </div>
  )
}
