import { CustomFieldTextSolid } from '@motion/icons'
import { type COLOR } from '@motion/shared/common'
import { classed } from '@motion/theme'
import { Tooltip } from '@motion/ui/base'
import { Label, type LabelProps } from '@motion/ui/pm'
import { ProjectPalette } from '@motion/ui/project'
import { type VariableDefinitionSchema } from '@motion/zod/client'

import { generateVariableAbbreviation } from '~/areas/flows/utils'
import React, { forwardRef, type Ref } from 'react'
import { twMerge } from 'tailwind-merge'

export type VariableLabelProps = {
  value: Pick<VariableDefinitionSchema, 'name' | 'color'>
  size?: 'xsmall' | 'small'
  truncateLabel?: boolean
  highlightText?: boolean
  abbreviation?: string
}

export const VariableLabel = ({
  truncateLabel = false,
  highlightText = false,
  value,
  size = 'xsmall',
  abbreviation,
}: VariableLabelProps) => {
  const parsedName = truncateLabel
    ? (abbreviation ?? generateVariableAbbreviation(value.name))
    : value.name

  return (
    <VariableLabelBase
      color={value.color}
      size={size}
      shape={truncateLabel ? 'rectangle' : 'oval'}
    >
      {!truncateLabel && (
        <CustomFieldTextSolid className='w-3 h-3 text-semantic-blue-icon-default shrink-0' />
      )}
      <Tooltip asChild content={truncateLabel ? value.name : undefined}>
        <span
          className={twMerge(
            'text-semantic-blue-text-strong truncate font-medium',
            highlightText && 'text-semantic-blue-text-default'
          )}
        >
          {parsedName}
        </span>
      </Tooltip>
    </VariableLabelBase>
  )
}

export type VariableLabelBaseProps = Omit<LabelProps, 'color'> & {
  color: COLOR
  shape?: 'oval' | 'rectangle'
}

export const VariableLabelBase = forwardRef(function VariableLabelBase(
  { children, color, shape = 'oval', ...rest }: VariableLabelBaseProps,
  ref: Ref<HTMLSpanElement>
) {
  return (
    <ProjectPalette color={color}>
      {/* @ts-expect-error - fine */}
      <StyledLabel ref={ref} shape={shape} {...rest}>
        {children}
      </StyledLabel>
    </ProjectPalette>
  )
})

const StyledLabel = classed(Label, {
  base: `
    h-4
    inline-flex flex-row items-center shrink-0
    gap-1
    !text-palette-text-default !bg-palette-bg-default
  `,
  variants: {
    shape: {
      rectangle: 'rounded-sm px-[3px]',
      oval: 'px-1.5 rounded-full',
    },
  },
  defaultVariants: {
    shape: 'oval',
  },
})
