import { useSendToDesktop } from '@motion/desktop-ipc/hooks'
import { templateStr } from '@motion/react-core/strings'
import { formatTimeRange, getConferenceDataForType } from '@motion/ui-logic'
import { getWindowData } from '@motion/web-base/env'
import { logInDev } from '@motion/web-base/logging'

import { type ScheduledEntityWithRelations } from '~/global/proxies'
import { getProxy } from '~/state/backgroundProxy'
import { useCallback, useState } from 'react'

import motionIconUrl from '../../../../assets/favicon128.webp'

// TODO: Move this out of a redux class and into a shared context or hook
const browserNotificationsHandler = getProxy('BrowserNotifications')

export const useGenerateBrowserNotification = () => {
  const [lastNotification, setLastNotification] = useState<Notification | null>(
    null
  )
  const { isElectron } = getWindowData()
  const sendToDesktop = useSendToDesktop()

  return useCallback(
    async (entity: ScheduledEntityWithRelations) => {
      // Ensure we have permission to show browser notifications
      if (!(await browserNotificationsHandler.shouldShowNotifications())) {
        logInDev('Browser notifications are disabled', entity)
        return
      }

      // Close any active notifications
      if (lastNotification) {
        lastNotification.close()
        setLastNotification(null)
      }

      let entityTitle = 'Event'
      let conferenceLink = null

      if (entity.type === 'EVENT' && entity.event) {
        entityTitle = entity.event.title
        conferenceLink = entity.event.conferenceLink
      } else if (
        (entity.type === 'TASK' || entity.type === 'CHUNK') &&
        entity.task
      ) {
        entityTitle = entity.task.name
      }

      const time = formatTimeRange(entity.schedule.start, entity.schedule.end)

      const eventBody = templateStr('{{time}}{{conferenceMessage}}', {
        time,
        conferenceMessage: conferenceLink ? '. Click to join call.' : '',
      })

      if (isElectron) {
        // TODO: We may need a `showNotificationV2` IPC call that has better typing (handles things based on TYPE rather than ID)
        // Will need to ensure this is also versioned correctly
        sendToDesktop('showNotification', {
          ...entity,
          body: eventBody,
          title: entityTitle,
        })

        return
      }

      let icon = motionIconUrl
      if (entity.type === 'EVENT' && entity.conferenceType) {
        const link = getConferenceDataForType(entity.conferenceType).link
        if (link) {
          icon = link
        }
      }

      const eventOnClick = () => {
        if (conferenceLink) {
          window.open(conferenceLink)
        } else {
          window.focus()
        }
        lastNotification?.close()
      }

      let lastBrowserNotification = new Notification(entityTitle, {
        body: eventBody,
        icon: icon,
        // The notifications stays visible until the user interacts with the
        // notification
        requireInteraction: true,
      })

      // Clicking on the notification will open the conference link (if applicable)
      // and/or close the notification
      lastBrowserNotification.onclick = eventOnClick

      setLastNotification(lastBrowserNotification)
    },
    [isElectron, lastNotification, sendToDesktop]
  )
}
