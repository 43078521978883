import { TextVariableFieldsList } from '~/areas/flows/components'
import { useLegacyClearTaskNameVariable } from '~/areas/flows/flow-template-modal/fields/text-variables/use-legacy-clear-task-name-variable'
import { useFlowTemplateForm } from '~/areas/flows/shared-form'

export const ControlledTextVariableFields = () => {
  const { form } = useFlowTemplateForm()
  const { watch } = form
  const clearTextVariable = useLegacyClearTaskNameVariable()

  const textVariables = watch('textVariables')

  return (
    <TextVariableFieldsList
      variables={textVariables}
      form={form}
      deleteAnalyticsName='stage-delete-variable'
      onRemoveVariable={clearTextVariable}
    />
  )
}
