import { DATE_FORMATTERS_BY_UNIT, isCustomFieldKey } from '@motion/ui-logic'
import { type FilterTarget } from '@motion/ui-logic/pm/data'
import { safeParseDate } from '@motion/utils/dates'
import { type GROUP_BY_DATE_OPTIONS } from '@motion/zod/client'

import {
  type GroupByTypes,
  type InitialValue,
  isViewStateGroupByDateKey,
  useTreeGroupInitialValues,
} from '../../grouping'
import {
  CUSTOM_FIELD_NO_VALUE,
  CUSTOM_FIELD_NONE_ID,
} from '../../grouping/custom-fields'
import { getGroupByDateValueOverride } from '../../grouping/utils'
import { useViewState, type ViewStateGroupByField } from '../../view-state'

export type TreeOverrides = {
  initialValues: InitialValue
  groupByOverrides: {
    [key in GroupByTypes]?: {
      keyOf?: (value: any) => string
      valueOf?: (value: any) => string
      by?: GROUP_BY_DATE_OPTIONS
    }
  }
}

export function useTreeGroupOverrides(type: FilterTarget) {
  const [viewState] = useViewState()
  const initialValues = useTreeGroupInitialValues(type)

  const groupByOverrides = viewState.groupBy.fields.reduce<
    TreeOverrides['groupByOverrides']
  >((acc, group) => {
    if (group.by == null || !isViewStateGroupByDateKey(group.key)) {
      return acc
    }

    return {
      ...acc,
      [group.key]: {
        keyOf: getGroupByDateKeyOverride(group),
        valueOf: getGroupByDateValueOverride(group),
        ...(group.by ? { by: group.by } : {}),
      },
    }
  }, {})

  return {
    initialValues,
    groupByOverrides,
  }
}

function getGroupByDateKeyOverride(group: ViewStateGroupByField) {
  return (item: string) => {
    const date = safeParseDate(item)

    if (date == null || group.by == null) {
      return isCustomFieldKey(group.key)
        ? CUSTOM_FIELD_NONE_ID
        : CUSTOM_FIELD_NO_VALUE
    }

    return DATE_FORMATTERS_BY_UNIT[group.by](date)
  }
}
