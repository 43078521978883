import { LogoutSolid1 } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'

import { WorkspaceProjectDropdown } from '~/global/components/dropdowns'

import { BulkFolderDropdown, FieldButton } from '../components'
import {
  AllWorkspacesContext,
  isBulkOpsTaskGroups,
  useBulkOpsState,
} from '../contexts'
import {
  useBulkFieldValidation,
  useBulkMoveProjects,
  useBulkMoveTasks,
} from '../hooks'

export function MoveField() {
  const { target } = useBulkOpsState()

  return (
    <AllWorkspacesContext name='all-workspaces'>
      {target === 'tasks' ? (
        <ScopedMoveTasksField />
      ) : (
        <ScopedMoveProjectsField />
      )}
    </AllWorkspacesContext>
  )
}

function ScopedMoveTasksField() {
  const bulkMoveTasks = useBulkMoveTasks()

  const { isValid: canMoveTasks, errMsg } = useBulkFieldValidation([
    ({ target, groups }) => ({
      isValid: isBulkOpsTaskGroups(groups, target)
        ? groups.every((group) =>
            group.items.every((item) => !item.isUnvisitedStage)
          )
        : true,
      errMsg: "Can't move tasks with unvisited stages",
    }),
  ])

  return (
    <Tooltip content={errMsg}>
      <WorkspaceProjectDropdown
        onSelect={(workspaceId, projectId) => {
          bulkMoveTasks(workspaceId, projectId)
        }}
        selectedWorkspaceId=''
        selectedProjectId={null}
      >
        <FieldButton disabled={!canMoveTasks} analyticsName='move'>
          <LogoutSolid1 />
          Move
        </FieldButton>
      </WorkspaceProjectDropdown>
    </Tooltip>
  )
}

function ScopedMoveProjectsField() {
  const bulkMoveProjects = useBulkMoveProjects()

  return (
    <BulkFolderDropdown onSelect={bulkMoveProjects}>
      <FieldButton analyticsName='move'>
        <LogoutSolid1 />
        Move
      </FieldButton>
    </BulkFolderDropdown>
  )
}
