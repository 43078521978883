import { RocketSolid } from '@motion/icons'
import { GradientButton } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { getNextTier } from '~/areas/tiered-pricing'
import { useCurrentTier } from '~/areas/tiered-pricing/hooks'

export function TierUpsellButton() {
  const modalApi = useModalApi()
  const currentTier = useCurrentTier()
  const nextTier = getNextTier(currentTier)

  if (!nextTier) return null

  return (
    <div className='w-full h-full bg-semantic-neutral-bg-disabled'>
      <GradientButton
        size='small'
        fullWidth
        onClick={() => {
          modalApi.open('tier-upgrade-prompt-modal', { tier: nextTier })
          recordAnalyticsEvent('TIER_UPSELL_BUTTON_CLICKED')
        }}
        sentiment='promotion'
      >
        <RocketSolid />
        Upgrade
      </GradientButton>
    </div>
  )
}
