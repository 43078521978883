import { StageSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { type StageContext } from '@motion/shared/activity-feed'
import { type StageDefinitionSchema } from '@motion/zod/client'

import { formatActivityMonthDay } from '~/areas/activity-feed/utils'
import { StageLabel } from '~/global/components/labels'

import { ActivityValue } from '../components'

export function handleStageDueDate({
  oldValue,
  newValue,
  context,
}: {
  oldValue: string
  newValue: string
  context: StageContext
}) {
  return {
    icon: <StageSolid />,
    children: templateStr(
      'changed {{stage}} deadline from {{oldValue}} to {{newValue}}',
      {
        stage: (
          <StageLabel
            value={{
              ...context,
              color: context.color as StageDefinitionSchema['color'],
            }}
            nameOnly
            size='small'
          />
        ),
        oldValue: (
          <ActivityValue>{formatActivityMonthDay(oldValue)}</ActivityValue>
        ),
        newValue: (
          <ActivityValue>{formatActivityMonthDay(newValue)}</ActivityValue>
        ),
      }
    ),
  }
}
