import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'
import {
  type TreatmentsOf,
  useExperiment as useExperimentCore,
} from '@motion/web-common/flags'

import { type AmplitudeExperimentName } from '../../services/amplitudeFeatureFlagsService'

export function useHasTreatments<TName extends AmplitudeExperimentName>(
  name: TName,
  treatments: TreatmentsOf<TName>[],
  options: {
    doNotTrack?: boolean
  } = READONLY_EMPTY_OBJECT
) {
  const value = useExperiment(name, { doNotTrack: options.doNotTrack })

  return treatments.includes(value.value)
}

export const useExperiment = <T extends AmplitudeExperimentName>(
  name: T,
  { doNotTrack }: { doNotTrack?: boolean } = READONLY_EMPTY_OBJECT
) => {
  return useExperimentCore(name, { track: !doNotTrack })
}
