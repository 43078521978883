import { useUserFavorites } from '~/areas/folders/hooks'
import { useFilteredSidebarTreeview } from '~/areas/sidebar/hooks'
import { type WorkspacesTreeviewItem } from '~/areas/treeviews/components'

export const useFavoritesTreeviewItems = (): WorkspacesTreeviewItem[] => {
  const { items } = useUserFavorites()

  return useFilteredSidebarTreeview({
    items,
    computeSearchValue: (item) => item.label,
    computeFolderStateKey: (item) => {
      if (
        item.type === 'TEAM_WORKSPACE' ||
        item.type === 'INDIVIDUAL_WORKSPACE' ||
        item.type === 'FOLDER'
      ) {
        return item.itemId
      }

      return item.id
    },
  })
}
