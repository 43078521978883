import { XSolid } from '@motion/icons'
import { type Contact } from '@motion/rpc/types'
import { Button, Portal, Tag, Text } from '@motion/ui/base'
import { NOOP_FUNCTION } from '@motion/utils/function'

// eslint-disable-next-line no-restricted-imports
import { Combobox as Base, Transition } from '@headlessui/react'
import { MissingContactsPermissionsWarning } from '~/areas/event/components'
import { Fragment, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { twMerge } from 'tailwind-merge'

import { Paragraph } from '../Common'
import {
  dropdownContainerClasses,
  dropdownContainerColorClasses,
  generalDropdownItemClasses,
  inputContainerClasses,
  inputContainerDisabledClasses,
  inputDisabledClasses,
  inputFocusClasses,
  inputIconColorClasses,
  inputIconSizeClasses,
  inputMutedContainerClasses,
  inputPlaceholderClasses,
  inputTextClasses,
  selectedDropdownItemClasses,
} from '../Common/GeneralComponentStyles'
import { LoadingSvg } from '../Common/Icons/LoadingSvg'

export interface ContactsAutocompleteComboboxProps {
  options: Contact[]
  values?: string[]
  disabled?: boolean
  placeholder?: string
  onChange?: (values: string[]) => void
  className?: string
  noResultsText?: string
  allowSelectQuery?: boolean
  onQuery?: (query: string) => void
  onFocus?: (focused?: boolean) => void
  multiple?: boolean
  muted?: boolean
  /**
   * Show loading symbol and do not allow selecting while loading
   */
  isLoadingOptions?: boolean
  /**
   * Controls the alignment of the dropdown
   */
  dropdownAlign?: 'left' | 'right'
  /**
   * Control the width of the dropdown. When set to 'normal' (the default value),
   * the dropdown will be the same width as the control. Setting to 'large' will
   * fix the dropdown width.
   */
  dropdownWidth?: 'normal' | 'large'
  /**
   * Optional icon to display in the input box
   */
  icon?: React.ElementType
  allowBrowserAutocomplete?: string
  showPermissionWarning?: boolean
  dropdownContainerClassName?: string
  onContactSelected?: (email: string) => void
  inputClassName?: string
  allowClearSelection?: boolean
}

/*
 * Custom implementation of the Combobox component specifically for TeamSearchInput
 * Allows user to search through contacts and add or remove them from the input, displays selected options as tags
 */
export function ContactsAutocompleteCombobox(
  props: ContactsAutocompleteComboboxProps
) {
  const {
    options = [],
    values = [],
    onChange = NOOP_FUNCTION,
    onQuery = NOOP_FUNCTION,
    onFocus = NOOP_FUNCTION,
    allowSelectQuery = false,
    isLoadingOptions = false,
    icon,
    multiple = true,
    muted = false,
    placeholder = '',
    noResultsText = 'No results found',
    dropdownAlign = 'left',
    dropdownWidth,
    className = '',
    disabled = false,
    allowBrowserAutocomplete = 'off',
    dropdownContainerClassName = '',
    onContactSelected = NOOP_FUNCTION,
    inputClassName = '',
    allowClearSelection = false,
  } = props
  const popperElRef = useRef(null)
  const [targetElement, setTargetElement] = useState<HTMLButtonElement | null>(
    null
  )
  const [popperElement, setPopperElement] = useState(null)
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
    placement: 'bottom',
  })

  const [query, setQuery] = useState<string>('')
  const Icon = icon

  // Filter results based on input text provided by user
  const filteredOptions = options
    .filter((option) => {
      if (!option) return false
      return (
        (option.email?.toLowerCase().includes(query?.toLowerCase()) ||
          option.displayName?.toLowerCase().includes(query?.toLowerCase())) &&
        !values.find((val) => val === option.email)
      )
    })
    .slice(0, 5)

  const renderInput = () => {
    if (query.trim().length > 0) {
      return query
    } else if (!multiple && values.length > 0) {
      return values[0]
    }
    return ''
  }

  const popperStyle = {
    ...styles.popper,
    opacity: popperElement ? 1 : 0,
  }

  return (
    <div className={twMerge('w-full', className)}>
      <Base
        value={values}
        onChange={(val: string[]) => {
          const newValues = Array.isArray(val) ? val : [val]
          onChange(newValues)
          setQuery('')
          onQuery('')
          if (newValues.length && newValues.length > values.length) {
            onContactSelected(newValues[newValues.length - 1])
          }
        }}
        disabled={disabled}
        // @ts-expect-error this type seems wrong. it multiple => true. Should be boolean.
        multiple={multiple}
      >
        {({ open }) => (
          <div className='relative'>
            <Base.Button
              as='div'
              ref={setTargetElement}
              className={twMerge(
                muted ? inputMutedContainerClasses : inputContainerClasses,
                inputFocusClasses,
                'min-h-8 relative flex w-full cursor-pointer gap-1.5 border py-1.5 px-2 text-left rounded',
                disabled && inputContainerDisabledClasses,
                inputClassName
              )}
            >
              {!open && values.length === 0 && Icon && (
                <Icon
                  className={twMerge(
                    'mt-1 min-h-[16px] min-w-[16px]',
                    inputIconColorClasses,
                    inputIconSizeClasses
                  )}
                />
              )}

              <div className='flex w-min flex-1 flex-wrap gap-1.5 max-w-full'>
                {allowClearSelection && multiple && values.length > 1 && (
                  <div className='flex w-full justify-end mb-1'>
                    <Button
                      size='xsmall'
                      sentiment='neutral'
                      variant='outlined'
                      onClick={(e) => {
                        e.preventDefault()
                        onChange([])
                      }}
                    >
                      Clear selection <XSolid />
                    </Button>
                  </div>
                )}

                {multiple &&
                  values.map((val) => {
                    return (
                      <Tag
                        key={`selected-${val}`}
                        onRemove={() => {
                          onChange(values.filter((value) => value !== val))
                        }}
                      >
                        <span className='truncate'>{val}</span>
                      </Tag>
                    )
                  })}

                <Base.Input
                  autoComplete={allowBrowserAutocomplete}
                  onFocus={() => onFocus(true)}
                  onBlur={() => onFocus(false)}
                  className={twMerge(
                    'auto relative flex w-full min-w-min cursor-pointer bg-inherit focus:outline-none',
                    inputTextClasses,
                    inputPlaceholderClasses,
                    disabled && inputDisabledClasses
                  )}
                  displayValue={renderInput}
                  onChange={(event) => {
                    setQuery(event.target.value)
                    onQuery(event.target.value)
                  }}
                  placeholder={values.length === 0 ? placeholder : ''}
                  disabled={disabled}
                />
              </div>

              {open && isLoadingOptions && (
                <div
                  className={twMerge(
                    'flex items-center justify-center',
                    inputTextClasses
                  )}
                >
                  <LoadingSvg />
                </div>
              )}
            </Base.Button>

            <Portal>
              <div
                className='z-[20]'
                ref={popperElRef}
                style={popperStyle}
                {...attributes.popper}
              >
                <Transition
                  as={Fragment}
                  leave='transition ease-in duration-100'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                  afterLeave={() => {
                    setPopperElement(null)
                  }}
                  beforeEnter={() => setPopperElement(popperElRef.current)}
                >
                  <Base.Options
                    className={twMerge(
                      dropdownContainerClasses,
                      dropdownContainerColorClasses,
                      dropdownAlign === 'right' ? 'right-0' : 'left-0',
                      dropdownWidth === 'large' ? 'w-64' : 'w-full',
                      dropdownContainerClassName,
                      'min-w-[330px]'
                    )}
                    style={
                      targetElement
                        ? {
                            [dropdownAlign]: -targetElement?.offsetWidth / 2,
                            width: targetElement?.offsetWidth,
                          }
                        : {}
                    }
                  >
                    <div className='pt-1'>
                      <MissingContactsPermissionsWarning />
                    </div>

                    {filteredOptions.map((option, optionIdx) => (
                      <Base.Option
                        key={optionIdx}
                        className={({ active }) =>
                          twMerge(
                            generalDropdownItemClasses,
                            active && selectedDropdownItemClasses
                          )
                        }
                        value={option.email}
                      >
                        {() => (
                          <div className='flex w-full items-center justify-between gap-2 truncate'>
                            <Paragraph className='max-w-[48%] truncate'>
                              {option.displayName}
                            </Paragraph>
                            <Text
                              sentiment='subtle'
                              size='xs'
                              truncate
                              className='max-w-[48%]'
                            >
                              {option.email}
                            </Text>
                          </div>
                        )}
                      </Base.Option>
                    ))}
                    {allowSelectQuery && query.length > 0 && (
                      <Base.Option
                        className={({ active }) =>
                          twMerge(
                            generalDropdownItemClasses,
                            active && selectedDropdownItemClasses
                          )
                        }
                        value={query}
                      >
                        {() => (
                          <div className='flex w-full items-center justify-between gap-2 truncate'>
                            <Paragraph className='max-w-full truncate'>
                              {query}
                            </Paragraph>
                          </div>
                        )}
                      </Base.Option>
                    )}

                    {((filteredOptions.length === 0 && !allowSelectQuery) ||
                      (allowSelectQuery &&
                        filteredOptions.length === 0 &&
                        query.length === 0)) && (
                      <>
                        {isLoadingOptions ? (
                          <Base.Option
                            className={twMerge(
                              'flex h-8 items-center justify-center',
                              inputTextClasses
                            )}
                            disabled
                            value={0}
                          >
                            <LoadingSvg />
                          </Base.Option>
                        ) : (
                          <Base.Option
                            key={0}
                            className={() =>
                              twMerge(
                                generalDropdownItemClasses,
                                'flex items-center justify-center p-4'
                              )
                            }
                            disabled
                            value='disabled'
                          >
                            <Text
                              sentiment='subtle'
                              size='xs'
                              alignment='center'
                            >
                              {noResultsText}
                            </Text>
                          </Base.Option>
                        )}
                      </>
                    )}
                  </Base.Options>
                </Transition>
              </div>
            </Portal>
          </div>
        )}
      </Base>
    </div>
  )
}
