/**
 * Generates a two-character abbreviation for a variable name
 */
export function generateVariableAbbreviation(
  name: string,
  usedAbbreviations?: Set<string>
): string {
  // Remove all emoji sequences including ZWJ sequences
  const cleanName = name
    .replace(/[\p{Emoji}\p{Emoji_Modifier}\p{Emoji_Component}\u200d]+/gu, '')
    .trim()
  const words = cleanName.split(/\s+/).filter((w) => w.length > 0)

  // Handle empty string after emoji removal
  if (!cleanName.replace(/\s+/g, '')) {
    return 'EM' // Default for emoji-only names
  }

  if (words.length === 1) {
    return generateSingleWordAbbreviation(words[0], usedAbbreviations)
  }

  return generateMultiWordAbbreviation(words, usedAbbreviations)
}

/**
 * Generates abbreviation for single word variables following rules:
 * 1. First two letters
 * 2. First letter + next unique letter
 * 3. First and last letter
 * 4. First and third letter
 */
function generateSingleWordAbbreviation(
  word: string,
  usedAbbreviations?: Set<string>
): string {
  word = word.toUpperCase()
  const firstLetter = word[0]

  // Try each rule in order until we find an unused abbreviation
  const attempts: string[] = []

  // Rule 1: First two letters
  if (word.length >= 2) {
    attempts.push(word.slice(0, 2))
  }

  // Rule 2: First letter + next unique letter
  for (let i = 1; i < word.length; i++) {
    if (!attempts[0].includes(word[i])) {
      attempts.push(firstLetter + word[i])
    }
  }

  // Rule 3: First and last letter
  if (word.length > 2) {
    attempts.push(firstLetter + word[word.length - 1])
  }

  // Rule 4: First and third letter
  if (word.length >= 3) {
    attempts.push(firstLetter + word[2])
  }

  // Fallback for single letter words
  if (word.length === 1) {
    attempts.push(word[0] + 'X')
  }

  // Return the first unused abbreviation, or the first one if no usedAbbreviations provided
  if (usedAbbreviations) {
    for (const attempt of attempts) {
      if (!usedAbbreviations.has(attempt)) {
        return attempt
      }
    }
  }

  return attempts[0]
}

/**
 * Generates abbreviation for multi-word variables following rules:
 * 1. First letter of first two words
 * 2. First letter + next unique letter from second word
 * 3. First letter + consonant/vowel from second word
 * 4. First letter of first and third word
 */
function generateMultiWordAbbreviation(
  words: string[],
  usedAbbreviations?: Set<string>
): string {
  const firstWord = words[0].toUpperCase()
  const secondWord = words[1]?.toUpperCase()
  const thirdWord = words[2]?.toUpperCase()

  if (!secondWord) {
    return generateSingleWordAbbreviation(firstWord, usedAbbreviations)
  }

  const firstLetter = firstWord[0]
  const attempts: string[] = []

  // Rule 1: First letter of first two words
  attempts.push(firstLetter + secondWord[0])

  // Rule 2: First letter + next unique letter from second word
  for (let i = 1; i < secondWord.length; i++) {
    const abbr = firstLetter + secondWord[i]
    if (!attempts[0].includes(secondWord[i])) {
      attempts.push(abbr)
    }
  }

  // Rule 3: First letter + consonant/vowel from second word
  const consonants = secondWord
    .split('')
    .filter((c) => /[bcdfghjklmnpqrstvwxyz]/i.test(c))
  const vowels = secondWord.split('').filter((c) => /[aeiou]/i.test(c))

  if (consonants.length > 1) {
    attempts.push(firstLetter + consonants[1])
  }
  if (vowels.length > 1) {
    attempts.push(firstLetter + vowels[1])
  }

  // Rule 4: First letter of first and third word
  if (thirdWord) {
    attempts.push(firstLetter + thirdWord[0])
  }

  // Return the first unused abbreviation, or the first one if no usedAbbreviations provided
  if (usedAbbreviations) {
    for (const attempt of attempts) {
      if (!usedAbbreviations.has(attempt)) {
        return attempt
      }
    }
  }

  return attempts[0]
}

/**
 * Resolves conflicts by using the abbreviation rules and adds numbering if needed
 */
export function resolveAbbreviationConflicts(
  variableNames: string[],
  existingAbbreviations: Set<string> = new Set()
): Map<string, string> {
  const result = new Map<string, string>()
  const usedAbbreviations = new Set(existingAbbreviations)
  const abbreviationCounts = new Map<string, number>()

  // Generate abbreviations for all names
  for (const variableName of variableNames) {
    let abbr = generateVariableAbbreviation(variableName, usedAbbreviations)

    // If the abbreviation is already used, add numbering
    if (usedAbbreviations.has(abbr)) {
      // Get the current count for this abbreviation
      let count = (abbreviationCounts.get(abbr) || 1) + 1
      abbreviationCounts.set(abbr, count)
      // Add the number to the abbreviation and keep incrementing until unused
      while (true) {
        const numberedAbbr = `${abbr}${count}`
        if (!usedAbbreviations.has(numberedAbbr)) {
          abbr = numberedAbbr
          break
        }
        count++
      }
    }

    result.set(variableName, abbr)
    usedAbbreviations.add(abbr)
  }

  return result
}
