import {
  CREATE_CONTENT_SLASH_COMMAND_OPTION,
  SlashCommandPlugin,
} from '@motion/notes'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useExperiment } from '@motion/web-common/flags'

import { useFeatureGatedCallback } from '~/areas/tiered-pricing/hooks'

import { useNoteDialogApi } from '../../hooks'

export function CustomSlashMenuPlugin() {
  const dialogApi = useNoteDialogApi()
  const docsAiOnToolbar = useExperiment('docs-ai-on-toolbar')
  const hasDocsAiOnToolbar =
    docsAiOnToolbar.value && docsAiOnToolbar.value !== 'off'

  const handleOpenCreateContentWithAiDialog = useFeatureGatedCallback(
    'aiDocs',
    () =>
      setTimeout(() => void dialogApi.openDialog('create-content-with-ai'), 10)
  )

  const handleSlashMenuSelectOption = (option: string) => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_NOTE_SLASH_MENU_OPTION_SELECTED', {
      option,
    })

    switch (option) {
      case CREATE_CONTENT_SLASH_COMMAND_OPTION:
        handleOpenCreateContentWithAiDialog()
        break
    }
  }

  return (
    <SlashCommandPlugin
      onSelectOption={handleSlashMenuSelectOption}
      enableAI={hasDocsAiOnToolbar}
    />
  )
}
