import { templateStr } from '@motion/react-core/strings'
import { Button, Text, TextHeader } from '@motion/ui/base'
import {
  type BillingPrices,
  TEAM_MINIMUM_BUCKET_SEATS,
  Term,
} from '@motion/ui-logic/billing'
import { logEvent } from '@motion/web-base/analytics'
import {
  BillingPlanSelector,
  TEAM_DEFAULT_TRIAL_LENGTH,
} from '@motion/web-billing'

import { PaymentElement } from '@stripe/react-stripe-js'
import { type StripePaymentElementChangeEvent } from '@stripe/stripe-js'
import { useGetTeamPrices } from '~/global/rpc/team'
import { useCallback, useEffect, useState } from 'react'

import { PaymentCard } from './PaymentCard'

import { Events } from '../../../../analyticsEvents'
import { fetchTeam } from '../../../../state/projectManagement/teamThunks'
import { dispatch } from '../../../../state/proxy'
import { Paragraph } from '../../../Common'
import { PaymentInterval } from '../../types/PaymentInterval'
import { type ProcessPaymentProps } from '../../types/process-payment-props'
import { MembersSummary } from '../CreateTeam/MembersSummary'
import { AddMembersModal } from '../Modals/AddMembersModal/AddMembersModal'

interface PaymentSectionProps {
  buttonText: string
  emails?: string[]
  isSubmitting: boolean
  processPayment: (props: ProcessPaymentProps) => Promise<void>
  stripeError: undefined | string
  userEmail: string
  onComplete?: () => void
  initialSeats: number
  isTrial?: boolean
}

export const PaymentSection = ({
  buttonText,
  emails = [],
  isSubmitting,
  processPayment,
  stripeError,
  userEmail,
  onComplete,
  initialSeats,
  isTrial,
  teamPrices,
}: PaymentSectionProps & { teamPrices: BillingPrices }) => {
  const [selectedEmails, setSelectedEmails] = useState<string[]>(emails ?? [])
  const [isShowingModal, setIsShowingModal] = useState(false)
  const [selectedAnnual, setSelectedAnnual] = useState(true)
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false)
  const [isPaymentFilledOut, setIsPaymentFilledOut] = useState(false)
  const [bucketSeats, setBucketSeats] = useState(initialSeats)

  const { annualPricePerMonth, monthlyPrice, annualSavingsPercentInteger } =
    teamPrices

  const newTeamSize = selectedEmails.length + 1
  const multiplier = bucketSeats ?? newTeamSize
  const annualTotal = annualPricePerMonth * 12 * multiplier
  const monthlyTotal = monthlyPrice * multiplier
  const hasInsufficientSeats = newTeamSize > (bucketSeats ?? Infinity)

  const addMembers = useCallback(
    async (emails: string[]) => {
      const uniqueEmails = new Set([...selectedEmails, ...emails])
      const uniqueArray = Array.from(uniqueEmails)
      setSelectedEmails(uniqueArray)
      setIsShowingModal(false)
      void logEvent(Events.TEAM_BILLING_INVITE, {
        count: uniqueArray.length,
      })
    },
    [selectedEmails, setSelectedEmails]
  )

  const deleteMember = useCallback(
    async (email: string) => {
      const newEmails = selectedEmails.filter((e) => e !== email)
      setSelectedEmails(newEmails)
    },
    [selectedEmails, setSelectedEmails]
  )

  useEffect(
    function isPaymentFormFilledOut() {
      setIsSubmitEnabled(
        isPaymentFilledOut && (selectedEmails.length > 0 || !!bucketSeats)
      )
    },
    [bucketSeats, isPaymentFilledOut, selectedEmails, setIsSubmitEnabled]
  )

  const handlePaymentElementChange = (
    event: StripePaymentElementChangeEvent
  ) => {
    setIsPaymentFilledOut(event.complete)
  }

  const trialLength = isTrial ? TEAM_DEFAULT_TRIAL_LENGTH : 0

  return (
    <div className='flex h-full max-h-screen w-full flex-col items-center gap-y-10 overflow-y-scroll py-10'>
      {isShowingModal && (
        <AddMembersModal
          addMembers={addMembers}
          closeModal={() => setIsShowingModal(false)}
        />
      )}
      <div className='dark:bg-dark-1000 bg-light-100 border-light-400 flex w-[900px] justify-center rounded-lg border dark:border-transparent'>
        <div className='flex w-[600px] grow flex-col gap-2 p-6'>
          {bucketSeats ? (
            <div className='flex flex-col gap-4'>
              <BillingPlanSelector
                allowLowCostTrial={false}
                term={selectedAnnual ? Term.Annual : Term.Monthly}
                minSeats={TEAM_MINIMUM_BUCKET_SEATS}
                setTerm={(term) => setSelectedAnnual(term === Term.Annual)}
                annualTrialLength={trialLength}
                monthlyTrialLength={trialLength}
                chosenTrialLength={trialLength}
                savingsPercent={annualSavingsPercentInteger}
                title='Start Team Plan'
                text=''
                price={selectedAnnual ? annualPricePerMonth : monthlyPrice}
                bucket={bucketSeats}
                isTeam
                setBucket={setBucketSeats}
              />
            </div>
          ) : (
            <>
              <TextHeader size='2xl' weight='bold'>
                Plan
              </TextHeader>
              <PaymentCard
                costPerUser={annualPricePerMonth}
                interval={PaymentInterval.Annually}
                isSelected={selectedAnnual}
                onClick={() => {
                  setSelectedAnnual(true)
                }}
                total={annualTotal}
              />
              <PaymentCard
                costPerUser={monthlyPrice}
                interval={PaymentInterval.Monthly}
                isSelected={!selectedAnnual}
                onClick={() => {
                  setSelectedAnnual(false)
                }}
                total={monthlyTotal}
              />
            </>
          )}

          <Paragraph className='my-1.5 !mb-1 font-semibold'>
            Payment Information
          </Paragraph>
          <PaymentElement onChange={handlePaymentElementChange} />
          <Text sentiment='subtle' size='sm' className='mt-6'>
            {templateStr(
              'You will be charged ${{amount}} {{paymentDate}} and your subscription will be renewed automatically {{interval}}. If any member is paying for Motion individually, their last payment will be refunded.',
              {
                amount: selectedAnnual ? annualTotal : monthlyTotal,
                paymentDate: isTrial
                  ? 'at the end of your trial'
                  : 'immediately',
                interval: selectedAnnual ? 'annually' : 'monthly',
              }
            )}
          </Text>
          {stripeError && (
            <p className='text-alert-400 mt-4 mb-0'>{stripeError}</p>
          )}

          <div className='gap-3 mt-6 self-center'>
            <Button
              loading={isSubmitting}
              disabled={!isSubmitEnabled || hasInsufficientSeats}
              onClick={async () => {
                await processPayment({
                  isAnnual: selectedAnnual,
                  selectedEmails,
                  bucketSeats,
                  useExistingCard: false,
                })
                await dispatch(fetchTeam)
                if (onComplete) {
                  onComplete()
                }
              }}
            >
              {buttonText}
            </Button>
          </div>
        </div>
        <MembersSummary
          memberEmails={selectedEmails}
          onDelete={deleteMember}
          showModal={() => setIsShowingModal(true)}
          userEmail={userEmail}
          seats={bucketSeats ?? undefined}
          changeSeats={setBucketSeats}
        />
      </div>
    </div>
  )
}

export const ConnectedPaymentSection = (props: PaymentSectionProps) => {
  const { data: teamPrices } = useGetTeamPrices()
  if (!teamPrices) return null

  return <PaymentSection {...props} teamPrices={teamPrices} />
}
