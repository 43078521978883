import { templateStr } from '@motion/react-core/strings'
import { type ProjectSchema, type StageSchema } from '@motion/rpc-types'
import { type VariantProps } from '@motion/theme'
import {
  getEtaLabel,
  getProjectNoEtaReason,
  getStageEtaTitle,
} from '@motion/ui-logic'

import { useExtendedNoneEta } from '~/areas/eta/hooks'
import { EtaBadge } from '~/global/components/badges'
import { ConnectedStageLabel } from '~/global/components/labels'
import { useWorkspaceFns } from '~/global/hooks'
import { type ReactNode, useMemo } from 'react'

import { type RibbonContainer } from '../styled'

type StageDeadlineStatus =
  | 'stage-missed-deadline'
  | 'stage-scheduled-past-deadline'
  | 'multiple-stages-need-attention'

export const useGetRibbonDetails = (project: ProjectSchema | null) => {
  const deadlineStatus = useExtendedNoneEta({
    entity: project,
    type: 'project',
  })
  const { getWorkspaceStatuses } = useWorkspaceFns()

  const [stageDeadlineStatus, stagesNeedAttention] = useMemo<
    [StageDeadlineStatus | null, StageSchema[]]
  >(() => {
    if (project == null) {
      return [null, []]
    }

    const stagesThatNeedAttention = project.stages.filter(
      (stage) =>
        stage.deadlineStatus === 'missed-deadline' ||
        stage.deadlineStatus === 'scheduled-past-deadline'
    )

    if (stagesThatNeedAttention.length > 1) {
      return ['multiple-stages-need-attention', stagesThatNeedAttention]
    }

    const stageThatNeedsAttention = stagesThatNeedAttention[0]

    if (stageThatNeedsAttention == null) {
      return [null, stagesThatNeedAttention]
    }

    if (stageThatNeedsAttention.deadlineStatus === 'missed-deadline') {
      return ['stage-missed-deadline', stagesThatNeedAttention]
    }

    if (stageThatNeedsAttention.deadlineStatus === 'scheduled-past-deadline') {
      return ['stage-scheduled-past-deadline', stagesThatNeedAttention]
    }

    return [null, stagesThatNeedAttention]
  }, [project])

  const details = useMemo<{
    sentiment: VariantProps<typeof RibbonContainer>['sentiment'] | null
    label: string | ReactNode | null
    badge: ReactNode | null
  }>(() => {
    if (deadlineStatus === 'missed-deadline') {
      return {
        sentiment: 'error',
        label: getEtaLabel(deadlineStatus, 'project'),
        badge: <EtaBadge value={deadlineStatus} size='normal' />,
      }
    }

    if (deadlineStatus === 'scheduled-past-deadline') {
      return {
        sentiment: 'warning',
        label: getEtaLabel(deadlineStatus, 'project', project?.scheduledStatus),
        badge: <EtaBadge value={deadlineStatus} size='normal' />,
      }
    }

    if (stageDeadlineStatus === 'multiple-stages-need-attention') {
      const label = templateStr('{{numStages}} stages need attention', {
        numStages: stagesNeedAttention.length,
      })
      return {
        sentiment: 'notice',
        label,
        badge: <EtaBadge value='at-risk' size='normal' />,
      }
    }

    if (stageDeadlineStatus === 'stage-missed-deadline') {
      return {
        sentiment: 'notice',
        label: (
          <div className='inline-block' aria-label='Stage deadline information'>
            {templateStr('{{stageLabel}} {{stageEtaTitle}}', {
              stageLabel: (
                <div className='inline-block align-middle'>
                  <ConnectedStageLabel
                    size='small'
                    id={stagesNeedAttention[0].stageDefinitionId}
                  />
                </div>
              ),
              stageEtaTitle: getStageEtaTitle(
                stagesNeedAttention[0]
              ).toLowerCase(),
            })}
          </div>
        ),
        badge: <EtaBadge value='scheduled-past-deadline' size='normal' />,
      }
    }

    if (stageDeadlineStatus === 'stage-scheduled-past-deadline') {
      return {
        sentiment: 'notice',
        label: (
          <div className='inline-block' aria-label='Stage deadline information'>
            {templateStr('{{stageLabel}} {{stageEtaTitle}}', {
              stageLabel: (
                <div className='inline-block align-middle'>
                  <ConnectedStageLabel
                    size='small'
                    id={stagesNeedAttention[0].stageDefinitionId}
                  />
                </div>
              ),
              stageEtaTitle: getStageEtaTitle(
                stagesNeedAttention[0]
              ).toLowerCase(),
            })}
          </div>
        ),
        badge: <EtaBadge value='at-risk' size='normal' />,
      }
    }

    if (deadlineStatus === 'on-track') {
      return {
        sentiment: 'success',
        label: getEtaLabel(deadlineStatus, 'project'),
        badge: <EtaBadge value={deadlineStatus} size='normal' />,
      }
    }

    if (deadlineStatus === 'ahead-of-schedule') {
      return {
        sentiment: 'primary',
        label: getEtaLabel(deadlineStatus, 'project'),
        badge: <EtaBadge value={deadlineStatus} size='normal' />,
      }
    }

    if (
      (deadlineStatus === 'none' ||
        deadlineStatus === 'completed' ||
        deadlineStatus === 'canceled') &&
      project != null
    ) {
      const workspaceStatuses = getWorkspaceStatuses(project.workspaceId)
      const sentiment = deadlineStatus === 'none' ? 'info' : deadlineStatus

      return {
        sentiment: sentiment,
        label: getProjectNoEtaReason(project, workspaceStatuses),
        badge: <EtaBadge value={deadlineStatus} size='normal' />,
      }
    }

    return {
      sentiment: null,
      label: null,
      badge: null,
    }
  }, [
    deadlineStatus,
    getWorkspaceStatuses,
    project,
    stageDeadlineStatus,
    stagesNeedAttention,
  ])

  return {
    ...details,
    stagesNeedAttention,
  }
}
