import { ExclamationTriangleSolid } from '@motion/icons'
import { type WorkspaceSchema } from '@motion/rpc-types'

import {
  ModalFieldButton,
  WorkspaceSectionContainer,
  WorkspaceSectionTitle,
} from '~/areas/task-project/components'
import { ProjectBadge, WorkspaceBadge } from '~/global/components/badges'
import {
  WorkspaceDropdown,
  WorkspacesTreeDropdown,
} from '~/global/components/dropdowns'
import { ProjectLabel } from '~/global/components/labels'
import { useProject, useWorkspaceById } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { useActionItemForm, useWorkspaceProjectUpdater } from '../../hooks'

export function ControlledWorkspaceFolderProjectField() {
  const { form } = useActionItemForm()
  const updateWorkspaceProject = useWorkspaceProjectUpdater()

  const { field: workspaceIdField } = useController({
    name: 'workspaceId',
    control: form.control,
  })
  const { field: projectIdField } = useController({
    name: 'projectId',
    control: form.control,
  })

  const selectedWorkspace = useWorkspaceById(workspaceIdField.value)
  const selectedProject = useProject(projectIdField.value)

  const onWorkspaceChange = (newWorkspace: WorkspaceSchema) =>
    updateWorkspaceProject(newWorkspace.id, null)

  const inaccessibleWorkspace = selectedWorkspace == null

  return (
    <WorkspaceSectionContainer>
      <WorkspaceSectionTitle showProject />
      <WorkspaceDropdown
        selectedWorkspaceId={workspaceIdField.value}
        onChange={onWorkspaceChange}
      >
        <ModalFieldButton
          size='normal'
          icon={
            inaccessibleWorkspace ? (
              <ExclamationTriangleSolid className='size-4 !text-semantic-warning-text-default' />
            ) : (
              <WorkspaceBadge />
            )
          }
        >
          <span
            className={
              inaccessibleWorkspace
                ? 'text-semantic-warning-text-default'
                : 'text-semantic-neutral-text-default'
            }
          >
            {selectedWorkspace?.name ?? 'No access to workspace'}
          </span>
        </ModalFieldButton>
      </WorkspaceDropdown>
      <WorkspacesTreeDropdown
        selectedId={projectIdField.value ?? workspaceIdField.value}
        workspaceId={workspaceIdField.value}
        onChange={(selected) => {
          updateWorkspaceProject(selected.workspaceId, selected.projectId)
        }}
      >
        <ModalFieldButton
          size='normal'
          icon={<ProjectBadge value={selectedProject} />}
        >
          <ProjectLabel value={selectedProject} nameOnly />
        </ModalFieldButton>
      </WorkspacesTreeDropdown>
    </WorkspaceSectionContainer>
  )
}
