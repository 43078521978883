import { type AttachmentComponentProps } from '@motion/notes'

import { useFileById } from '~/global/rpc/v2/files'

import { CustomAttachmentTag } from './custom-attachment-tag'

export type ConnectedCustomAttachmentTagProps = AttachmentComponentProps

export function ConnectedCustomAttachmentTag({
  attachmentId,
  isUploading,
  isPreview,
  onDelete,
  onTogglePreview,
}: ConnectedCustomAttachmentTagProps) {
  const {
    data: attachment,
    isLoading: isAttachmentLoading,
    isError: isAttachmentError,
  } = useFileById(
    {
      id: attachmentId ?? '',
    },
    {
      enabled: !isUploading && attachmentId != null,
    }
  )

  return (
    <CustomAttachmentTag
      attachment={attachment}
      isLoading={isUploading || isAttachmentLoading}
      isError={isAttachmentError}
      isPreview={isPreview}
      onDelete={onDelete}
      onTogglePreview={onTogglePreview}
    />
  )
}
