import { useHasTreatment } from '@motion/web-common/flags'

import { ActionItem } from '~/areas/notetaker'
import { useActionItemsQuery } from '~/global/rpc/v2'

type ConnectedActionItemBlockProps = {
  actionItemId: string
}
export function ConnectedActionItemBlock({
  actionItemId,
}: ConnectedActionItemBlockProps) {
  const enableNotetaker = useHasTreatment('notetaker-event-modal')

  const { data: result } = useActionItemsQuery(
    {
      filters: {
        ids: [actionItemId],
      },
    },
    {
      enabled: enableNotetaker,
    }
  )

  if (result == null || result.length === 0) {
    return null
  }

  return <ActionItem actionItem={result[0]} />
}
