import {
  type ApiTypes,
  type ApiUseMutationOptions,
  createUseMutation,
  createUseQuery,
  createUseQueryFn,
  useQueryOptionsFactory,
} from '@motion/rpc'
import {
  createQueryFilter,
  getCacheEntry,
  MODEL_CACHE_KEY,
  MotionCache,
  type OptimisticUpdateValue,
  useReplaceModelInCachesFn,
} from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { type NoteSchema } from '@motion/rpc-types'
import { showToast } from '@motion/ui/base'
import { Sentry } from '@motion/web-base/sentry'

import {
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from '@tanstack/react-query'
import { applyOptimisticFolderItemUpdates } from '~/global/cache'

export const useGetNoteById = createUseQueryFn(API.notes.getNoteById)

type NotesQueryApi = ApiTypes<typeof API.notes.queryNotes>

export const useQueryNotes = (
  args: NotesQueryApi['args'],
  opts?: NotesQueryApi['UseQueryOptions']
) => {
  const replaceModelInCaches = useReplaceModelInCachesFn()
  const queryOptionsOf = useQueryOptionsFactory(API.notes.queryNotes)
  const queryArgs = queryOptionsOf(args, opts)

  return useQuery({
    ...queryArgs,
    async queryFn(ctx) {
      const value = (await queryArgs.queryFn(ctx)) as NotesQueryApi['data']
      replaceModelInCaches(value.models)
      return value
    },
  })
}

export const useUpdateNoteMutation = createUseMutation(API.notes.updateNote)
export const useCreateNoteMutation = createUseMutation(API.notes.createNote)
export const useDeleteNoteMutation = createUseMutation(API.notes.deleteNote)
export const useCopyNoteMutation = createUseMutation(API.notes.copyNote)

export const useCreateNoteMentionsMutation = createUseMutation(
  API.notes.createNoteMentions
)
export const useDeleteNoteMentionsMutation = createUseMutation(
  API.notes.deleteNoteMentions
)

export type NoteByIdApi = ApiTypes<typeof API.notes.getNoteById>
export type NoteByIdApiArgs = Partial<NoteByIdApi['args']>

export const useNoteById = (
  args: NoteByIdApiArgs,
  opts?: NoteByIdApi['UseQueryOptions']
): UseQueryResult<NoteSchema | undefined> => {
  const { id } = args
  const client = useQueryClient()

  const noteId = id ?? ''

  const cachedNote = getCacheEntry(client, 'notes', noteId)

  const queryOptionsOf = useQueryOptionsFactory(API.notes.getNoteById)
  const queryArgs = queryOptionsOf(
    {
      ...args,
      id: noteId,
    },
    { enabled: Boolean(noteId), ...opts }
  )

  const initialData: NoteByIdApi['data'] | undefined = cachedNote
    ? {
        id: noteId,
        meta: {
          model: 'notes',
        },
        models: {
          notes: cachedNote
            ? {
                [noteId]: cachedNote.value,
              }
            : {},
          uploadedFiles: {},
          feedEntries: {},
          threads: {},
          tasks: {},
        },
      }
    : undefined

  return useQuery({
    ...queryArgs,
    initialData: initialData as unknown as NoteByIdApi['queryFnData'],
    initialDataUpdatedAt: initialData ? cachedNote?.updatedAt : undefined,
    enabled: queryArgs.enabled,
    gcTime: 0,
    notifyOnChangeProps: ['error', 'data', 'dataUpdatedAt'],
    select: (data: NoteByIdApi['queryFnData']) => {
      return data.models[data.meta.model][data.id]
    },
  })
}

export const useNoteWithMetadata = (
  args: NoteByIdApiArgs,
  opts?: NoteByIdApi['UseQueryOptions']
) => {
  const { id } = args

  const noteId = id ?? ''

  const queryOptionsOf = useQueryOptionsFactory(API.notes.getNoteById)
  const queryArgs = queryOptionsOf(
    {
      ...args,
      id: noteId,
    },
    { enabled: Boolean(noteId), ...opts }
  )

  return useQuery({
    ...queryArgs,
    queryKey: [...queryArgs.queryKey, 'with-meta'],
    enabled: queryArgs.enabled,
    notifyOnChangeProps: ['error', 'data', 'dataUpdatedAt'],
  })
}

export const useNoteSnapshots = createUseQuery(API.notes.queryNoteSnapshots)

export const useApplyNoteSnapshot = createUseMutation(API.notes.applySnapshot)

const notesCacheQueryFilter = createQueryFilter([
  API.notes.queryKeys.root,
  MODEL_CACHE_KEY,
])

export const useUpdateNote = (
  opts?: ApiUseMutationOptions<typeof API.notes.updateNote>
) => {
  const queryClient = useQueryClient()

  return useUpdateNoteMutation({
    onMutate: async (variables) => {
      if (variables.folderId != null || variables.parentFolderItemId != null) {
        const cacheUpdates = await applyOptimisticFolderItemUpdates(
          queryClient,
          variables.id,
          {
            parentFolderId: variables.folderId,
            parentFolderItemId: variables.parentFolderItemId,
          },
          'NOTE'
        )

        return { cacheUpdates }
      }
    },
    onError: (error, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }
      cacheUpdates?.rollback()
      Sentry.captureException(error)
      showToast('error', 'Failed to update doc')
    },
    onSuccess: (data, vars) => {
      MotionCache.upsert(queryClient, notesCacheQueryFilter, data)
    },
    ...opts,
  })
}

export const useCreateNoteCommentMutation = createUseMutation(
  API.notes.createNoteComment
)

export const useCreateNoteComment = (
  opts?: ApiUseMutationOptions<typeof API.notes.createNoteComment>
) => {
  const queryClient = useQueryClient()

  return useCreateNoteCommentMutation({
    ...opts,
    onSuccess: (data, variables, context) => {
      MotionCache.upsert(
        queryClient,
        createQueryFilter([
          API.notes.queryKeys.noteById({ id: variables.targetId }),
          MODEL_CACHE_KEY,
        ]),
        data
      )
      opts?.onSuccess?.(data, variables, context)
    },
  })
}

export const useDeleteNoteCommentMutation = createUseMutation(
  API.notes.deleteNoteComment
)

export const useDeleteNoteComment = (
  opts?: ApiUseMutationOptions<typeof API.notes.deleteNoteComment>
) => {
  const queryClient = useQueryClient()

  return useDeleteNoteCommentMutation({
    ...opts,
    onSuccess: (data, variables, context) => {
      MotionCache.delete(
        queryClient,
        createQueryFilter([
          API.notes.queryKeys.noteById({ id: variables.targetId }),
          MODEL_CACHE_KEY,
        ]),
        'feedEntries',
        [variables.commentId]
      )
      opts?.onSuccess?.(data, variables, context)
    },
  })
}

export const useEditNoteCommentMutation = createUseMutation(
  API.notes.editNoteComment
)

export const useEditNoteComment = (
  opts?: ApiUseMutationOptions<typeof API.notes.editNoteComment>
) => {
  const queryClient = useQueryClient()

  return useEditNoteCommentMutation({
    ...opts,
    onSuccess: (data, variables, context) => {
      MotionCache.upsert(
        queryClient,
        createQueryFilter([
          API.notes.queryKeys.noteById({ id: variables.targetId }),
          MODEL_CACHE_KEY,
        ]),
        data
      )
      opts?.onSuccess?.(data, variables, context)
    },
  })
}

export const useUpdateNoteThreadMutation = createUseMutation(
  API.threads.updateThread
)

export const useUpdateNoteThread = (
  opts?: ApiUseMutationOptions<typeof API.threads.updateThread>
) => {
  const queryClient = useQueryClient()

  return useUpdateNoteThreadMutation({
    ...opts,
    onSuccess: (data, variables, context) => {
      const targetId = data.models.threads[data.id].targetId

      MotionCache.upsert(
        queryClient,
        createQueryFilter([
          API.notes.queryKeys.noteById({
            id: targetId,
          }),
          MODEL_CACHE_KEY,
        ]),
        data
      )
      opts?.onSuccess?.(data, variables, context)
    },
  })
}

export const useCreateTaskFromSelection = createUseMutation(
  API.notes.createTaskFromSelection
)

export const useCreateTasksFromSelection = createUseMutation(
  API.notes.createTasksFromSelection
)

export const useSummarizeText = createUseMutation(API.notes.summarizeText)

export const useRewriteText = createUseMutation(API.notes.rewriteText)

export const useCreateContent = createUseMutation(API.notes.createContent)
