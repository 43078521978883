import { type COLOR } from '@motion/shared/common'
import { ProjectPalette } from '@motion/ui/project'
import { clamp } from '@motion/utils/math'

import { useStageDefinition } from '~/global/hooks'

import { StageConnectorBar, StageProgress } from './styled'
import { type StageGroup } from './utils'

export type ConnectedStageConnectorProps = {
  children?: React.ReactNode
  groupedStages?: StageGroup
  type: 'full' | 'firstHalf' | 'secondHalf'
}
export function ConnectedStageConnector({
  children,
  groupedStages,
  type,
}: ConnectedStageConnectorProps) {
  const progress = groupedStages?.stages[0]?.completion ?? 0
  const stageDefinitionId = groupedStages?.stages[0]?.stageDefinitionId

  const stageDefinition = useStageDefinition(stageDefinitionId)

  if (
    groupedStages == null ||
    groupedStages.stages.length === 0 ||
    !stageDefinition
  )
    return null

  const isHalf = type === 'firstHalf' || type === 'secondHalf'

  const parsedProgress = type === 'secondHalf' ? progress - 50 : progress
  return (
    <StageConnector
      color={stageDefinition.color}
      progress={isHalf ? parsedProgress * 2 : parsedProgress}
    >
      {children}
    </StageConnector>
  )
}
export type StageConnectorProps = {
  children?: React.ReactNode
  color: COLOR
  progress: number
}
export function StageConnector({
  children,
  color,
  progress,
}: StageConnectorProps) {
  const width = clamp(progress, 0, 100)
  return (
    <ProjectPalette color={color}>
      <StageConnectorBar>
        <StageProgress
          style={{
            // set width floor and ceiling at 0 and 100
            width: `${width}%`,
          }}
        />
        {children}
      </StageConnectorBar>
    </ProjectPalette>
  )
}
