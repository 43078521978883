import { SearchableList } from '@motion/ui/base'
import { byValue, Compare } from '@motion/utils/array'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { ShareTargetItem } from '~/areas/modals/share-modal/components/share-target-item'
import { useAllWorkspaces } from '~/global/hooks'
import { useTeamMembers } from '~/global/hooks/team'
import { useContext, useMemo } from 'react'

import { ShareItemModalContext } from '../context/context'

type TeamMemberSearchableListProps = {
  containerRef: React.RefObject<HTMLElement | null>
}

export const TeamMemberSearchableList = ({
  containerRef,
}: TeamMemberSearchableListProps) => {
  const {
    searchQuery,
    setSearchQuery,
    setSelectedTargets,
    selectedTargets,
    usersWithAccess,
    workspacesWithAccess,
  } = useContext(ShareItemModalContext)
  const currentUser = useAuthenticatedUser()
  const teamMembers = useTeamMembers()
  const workspaces = useAllWorkspaces()

  const items = useMemo(() => {
    const filteredTeamMembers = teamMembers.filter(
      (user) =>
        user.userId !== currentUser.uid &&
        !usersWithAccess.some(({ targetId }) => targetId === user.userId)
    )

    const filteredWorkspaces = workspaces.filter(
      ({ id }) => !workspacesWithAccess.some(({ targetId }) => targetId === id)
    )

    return [...filteredTeamMembers, ...filteredWorkspaces].sort(
      byValue((v) => ('user' in v ? v.user.name : v.name), Compare.string.asc)
    )
  }, [
    currentUser.uid,
    teamMembers,
    usersWithAccess,
    workspaces,
    workspacesWithAccess,
  ])

  return (
    <SearchableList
      itemType='checkbox'
      items={items}
      search={searchQuery}
      onSearchChange={setSearchQuery}
      computeSearchValue={(item) =>
        'user' in item ? item.user.name : item.name
      }
      onSelect={(items) => {
        setSelectedTargets(
          items.map((item) =>
            'user' in item
              ? {
                  targetType: 'USER',
                  targetId: item.userId,
                }
              : {
                  targetType: 'WORKSPACE',
                  targetId: item.id,
                }
          )
        )
        setSearchQuery('')
      }}
      renderItem={(item) => (
        <ShareTargetItem
          target={{
            targetType: 'user' in item ? 'USER' : 'WORKSPACE',
            targetId: 'user' in item ? item.userId : item.id,
          }}
        />
      )}
      computeKey={(item) => item.id}
      computeSelected={(item) =>
        selectedTargets.some(({ targetType, targetId }) => {
          if ('user' in item) {
            return targetType === 'USER' && targetId === item.userId
          }

          return targetType === 'WORKSPACE' && targetId === item.id
        })
      }
      searchable={false} // Hide built-in search
      keyboardControlRef={containerRef}
    />
  )
}
