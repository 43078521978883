import { useDebouncedCallback } from '@motion/react-core/hooks'
import { type UpdateCalendarDto } from '@motion/rpc/types'
import { API } from '@motion/rpc-definitions'

import { useQueryClient } from '@tanstack/react-query'
import { useUpdateCalendars } from '~/global/rpc/v2'

/**
 * Custom hook for handling calendar changes. This hook will handle debouncing
 * multiple changes, as well as optimistically updating the redux state
 * (where applicable)
 * @returns
 */
export function useCalendarChanges() {
  const { mutateAsync: updateCalendars } = useUpdateCalendars()
  const queryClient = useQueryClient()

  const debounceRefreshscheduledEntities = useDebouncedCallback(
    async function () {
      queryClient.invalidateQueries({
        queryKey: API.scheduledEntities.queryKeys.root,
      })
    },
    1_000,
    {
      leading: true,
    }
  )

  return {
    setCalendarChanges: async (
      changes: UpdateCalendarDto,
      refreshScheduleEntities: boolean = true
    ) => {
      await updateCalendars({ calendars: [changes] })

      if (refreshScheduleEntities) {
        void debounceRefreshscheduledEntities()
      }
    },
  }
}
