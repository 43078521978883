import { Button, Tooltip } from '@motion/ui/base'
import { isBotSendable } from '@motion/ui-logic'
import { useWillExceedAccountFeatureCap } from '@motion/web-billing'

import { useSendBotNow } from '~/global/rpc/v2'
import { useState } from 'react'

import { useEventModalState } from '../../contexts'
import { useEventForm } from '../../hooks'

export function ConnectedSendBotNowButton() {
  const { form } = useEventForm()
  const { meetingInsights } = useEventModalState()
  const {
    formState: { isDirty },
  } = form

  const [buttonClicked, setButtonClicked] = useState(false)

  const isNotetakerLimitReached = useWillExceedAccountFeatureCap(
    'aiNotetakerLimit',
    1
  )

  const { mutateAsync: sendBotNow } = useSendBotNow()

  if (isDirty || !isBotSendable(meetingInsights) || isNotetakerLimitReached)
    return null

  async function handleSendBotNow() {
    if (meetingInsights == null) return
    setButtonClicked(true)

    const res = await sendBotNow({
      meetingInsightsId: meetingInsights.id,
    })

    if (!res.result) {
      // if it errored, let the user try again
      setButtonClicked(false)
    }
  }

  return (
    <Tooltip
      content={
        buttonClicked
          ? 'Your bot is on the way!'
          : 'Your bot will join the meeting immediately, regardless of when your meeting is scheduled'
      }
    >
      <Button
        sentiment='neutral'
        variant='mutedBg'
        size='xsmall'
        onClick={handleSendBotNow}
        disabled={buttonClicked}
      >
        Send notetaker now
      </Button>
    </Tooltip>
  )
}
