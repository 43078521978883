import { templateStr } from '@motion/react-core/strings'
import { type StageDefinitionSchema } from '@motion/zod/client'

import { type ReactNode } from 'react'

const BASE_GHOST_TOOLTIP =
  'This task is part of a future stage, and Motion blocks tasks in {{ taskStage }} until all tasks in previous stages are completed. To start this task earlier, please advance this project to the {{ taskStage }} stage.'

export type GhostAutoScheduleTooltipVars = {
  taskStage: StageDefinitionSchema
}

export function composeGhostAutoScheduleTooltipMessage(
  tooltip: ReactNode,
  isUnvisitedStage: boolean | undefined,
  tooltipVars: GhostAutoScheduleTooltipVars | undefined
): ReactNode {
  if (tooltip == null || !isUnvisitedStage || tooltipVars == null)
    return tooltip

  const ghostTooltip = templateStr(BASE_GHOST_TOOLTIP, {
    taskStage: (
      <span className='font-medium not-italic'>
        {tooltipVars.taskStage.name}
      </span>
    ),
  })

  return (
    <div className='flex flex-col gap-2'>
      {tooltip}
      <em>{ghostTooltip}</em>
    </div>
  )
}
