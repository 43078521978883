import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type TaskSchema } from '@motion/zod/client'

import {
  useDeleteTask as useDeleteTaskCall,
  useReadTaskFn,
} from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { showRecalculatingTasksToast } from '../../utils'

export type DeleteTaskOptions = {
  onConfirmation: () => void
  skipPrompt: boolean
}

export const useDeleteTask = () => {
  const modalApi = useModalApi()

  const { mutateAsync: deleteTask } = useDeleteTaskCall()
  const readTask = useReadTaskFn()

  return useCallback(
    async (
      taskId: TaskSchema['id'],
      options: Partial<DeleteTaskOptions> = READONLY_EMPTY_OBJECT
    ) => {
      const { onConfirmation, skipPrompt = false } = options

      try {
        // We always delete the parent chunk when it's a chunk task
        const task = await readTask(taskId)
        const parentChunkTask =
          task?.type === 'CHUNK' ? await readTask(task.parentChunkTaskId) : null
        const finalTask = parentChunkTask ?? task

        if (finalTask == null) {
          throw new Error('Task not found')
        }

        const modalResponse = !skipPrompt
          ? await modalApi.prompt('task-delete', {
              task: finalTask,
            })
          : finalTask.id

        if (modalResponse !== ModalDismissed) {
          const taskIdToRemove = modalResponse
          const taskToRemove = await readTask(taskIdToRemove)
          if (taskToRemove == null) {
            throw new Error('Task to remove not found')
          }

          onConfirmation?.()

          const wasAutoscheduled = taskToRemove.isAutoScheduled

          await deleteTask({
            id: taskIdToRemove,
          })

          if (wasAutoscheduled) {
            showRecalculatingTasksToast()
          }
        }

        return modalResponse
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            position: 'useDeleteTask',
          },
        })
        showErrorToast(
          e,
          'There was an error in deleting your task. Please try again'
        )

        throw e
      }
    },
    [deleteTask, modalApi, readTask]
  )
}
