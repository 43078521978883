export const NotetakerOnboardingDetails = {
  Title: 'AI Meeting Notes & Action Items',
  Description:
    'Our AI Notetaker will join your Google Meet, Microsoft Teams, and Zoom meetings and take notes for you.',
  Settings: {
    BotEnabled: {
      Title: 'Would you like to add AI Notetaker to all your future meetings?',
      Yes: 'Yes, turn on AI Notetaker for all meetings with Conferencing',
      No: "No, don't turn on AI Notetaker for meetings",
    },
    SendRecapToAllAttendees: {
      Title:
        'Would you like to send an email meeting recap to external attendees not on your team?',
      Yes: 'Yes, send email meeting recap to external attendees not on your team',
      No: "No, don't send email meeting recap to external attendees not on your team",
    },
  },
}
