import { templateStr } from '@motion/react-core/strings'
import { useHasTreatment } from '@motion/web-common/flags'

import { type WorkspacesTreeviewItemType } from '~/areas/treeviews/components'
import { useI18N } from '~/global/contexts'

import { default as EmptyStateArrow } from './empty-state-arrow.svg?react'
import { DefaultViewCallout, EmptyStateContainer } from './styled'

type EmptyStateProps = {
  viewType?: WorkspacesTreeviewItemType
}

export function EmptyState({ viewType }: EmptyStateProps) {
  const { formatList } = useI18N()

  const hasNotes = useHasTreatment('notes-in-webapp')

  const canCreateFolder = viewType === 'FOLDER' || viewType === 'WORKSPACE'

  const canCreateNotes =
    hasNotes &&
    (viewType === 'FOLDER' ||
      viewType === 'WORKSPACE' ||
      viewType === 'NOTE' ||
      viewType === 'PROJECT')

  const canCreateProject = viewType === 'FOLDER' || viewType === 'WORKSPACE'

  if (
    viewType == null ||
    !['WORKSPACE', 'FOLDER', 'PROJECT'].includes(viewType)
  ) {
    return null
  }

  const listOfItemsToCreate = [
    canCreateFolder && 'folder',
    canCreateProject && 'project',
    canCreateNotes && 'doc',
  ].filter(Boolean)

  const addMessage = templateStr('Add a {{items}} by clicking here.', {
    items: formatList(listOfItemsToCreate, 'disjunction'),
  })

  return (
    <EmptyStateContainer>
      <EmptyStateArrow className='size-20 ml-0.5 text-semantic-neutral-border-subtle' />
      <div className='flex gap-1 mt-16'>
        <span>💡</span>
        <div className='flex flex-col gap-2'>
          <span>{addMessage}</span>
          <span>
            To view all tasks, click on{' '}
            <DefaultViewCallout>Task List</DefaultViewCallout> above.
          </span>
        </div>
      </div>
    </EmptyStateContainer>
  )
}
