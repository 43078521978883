import {
  resolveFormTaskId,
  type TaskModalLocationState,
  type TaskUrlSearchParams,
} from '@motion/ui-logic/pm/task'
import {
  type ProjectSchema,
  type RecurringTaskSchema,
  type TaskSchema,
} from '@motion/zod/client'

import { useParentTask } from '~/areas/tasks/hooks'
import { useProject } from '~/global/hooks'
import { useTaskByIdV2 } from '~/global/rpc/v2'
import { useSearchParams } from '~/routing'
import { useMemo } from 'react'
import { type Location, useLocation } from 'react-router'

export type InitialTaskData = {
  isLoading: boolean
  hasError: boolean
  task: TaskSchema | RecurringTaskSchema | undefined
  parentTask: TaskSchema | RecurringTaskSchema | undefined
  project: ProjectSchema | null
}

export function useInitialTaskDataFromLocation(): InitialTaskData {
  const location = useLocation() as Location<TaskModalLocationState | undefined>
  const searchParams = useSearchParams<TaskUrlSearchParams>()
  const taskParamId = resolveFormTaskId({ searchParams, state: location.state })

  const enabled = taskParamId != null

  const { data: task, isInitialLoading } = useTaskByIdV2(
    {
      id: taskParamId ?? '',
    },
    {
      enabled,
      placeholderData: undefined,
    }
  )

  // First level of parent is either a chunk parent or a recurring task parent
  // If the task is a chunk, it will always be a chunk parent
  const { data: parentTask, isLoading: isLoadingParentTask } = useParentTask(
    task,
    { placeholderData: undefined }
  )

  // When we deal with recurring chunk,
  // we need to read the parent of the parent chunk, which is a recurring instance
  const { data: parentTask2, isLoading: isLoadingParentTask2 } = useParentTask(
    parentTask,
    { placeholderData: undefined }
  )

  const editableTask = task?.type === 'CHUNK' ? parentTask : task
  const rootTask = parentTask2 ?? parentTask
  const reallyLoading =
    isInitialLoading || isLoadingParentTask || isLoadingParentTask2

  const project = useProject(
    editableTask && 'projectId' in editableTask ? editableTask.projectId : null
  )

  return useMemo(
    () => ({
      isLoading: taskParamId != null && reallyLoading,
      hasError: !reallyLoading && taskParamId != null && task == null,
      task: editableTask,
      parentTask: editableTask !== rootTask ? rootTask : undefined,
      project,
    }),
    [editableTask, project, reallyLoading, rootTask, task, taskParamId]
  )
}
