import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'
import { isEqual } from '@motion/utils/core'

import { useFlowsModalState } from '~/areas/flows/contexts'
import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { getDataFromFieldName } from '~/areas/flows/shared-form/utils'
import { useCallback } from 'react'
import { type FieldPath, type FieldPathValue } from 'react-hook-form'

import { useGetOriginalTaskDefinitionState } from '../../hooks'

export function useTaskDefinitionDirtyStateSetter<
  T extends Extract<
    FieldPath<FlowTemplateFormFields>,
    `stages.${number}.tasks.${number}`
  >,
  F extends Extract<
    FieldPath<FlowTemplateFormFields>,
    `stages.${number}.tasks.${number}.${string}`
  >,
>(taskPath: T, fieldPath: F) {
  const {
    form: { watch },
  } = useFlowTemplateForm()
  const { setDirtyTasksMap } = useFlowsModalState()
  const { fieldName } = getDataFromFieldName(fieldPath)
  const task = watch(taskPath)

  const getOriginalTaskDefinitionState = useGetOriginalTaskDefinitionState()

  return useCallback(
    (value: FieldPathValue<FlowTemplateFormFields, F>) => {
      if (!fieldName) return

      const { originalTask } = getOriginalTaskDefinitionState(task.id)

      const isDirty = !isEqual(originalTask, { ...task, [fieldName]: value })

      setDirtyTasksMap((prev) => {
        if (prev[task.id] !== isDirty) {
          return { ...prev, [task.id]: isDirty }
        }
        return prev
      })
    },
    [fieldName, getOriginalTaskDefinitionState, setDirtyTasksMap, task]
  )
}
