import { type MeetingInsightsSchema } from '@motion/rpc-types'
import { DATE_FORMATTERS_BY_UNIT } from '@motion/ui-logic'
import { byValue, Compare } from '@motion/utils/array'
import { safeParseDate } from '@motion/utils/dates'

import { type GroupDefinition } from '~/areas/project-management/pages/pm-v3/grouping'

const GROUPING_DATE_SORT = Compare.date.with({
  null: 'at-end',
})

export const groupByDate = <
  TItem extends MeetingInsightsSchema,
>(): GroupDefinition<TItem, string | null> => {
  return {
    type: 'startDate',
    name: 'Date',
    groupType: 'date',
    isBounded: false,
    accessor: (item) => item.startTime ?? null,
    keyOf: (item) =>
      item == null ? 'No Value' : DATE_FORMATTERS_BY_UNIT.day(item),
    initialValues: [
      {
        key: 'No Value',
        value: null,
      },
    ],
    sortBy: byValue((x) => safeParseDate(x.value ?? ''), GROUPING_DATE_SORT),
  }
}

export const groupByHost = <
  TItem extends MeetingInsightsSchema,
>(): GroupDefinition<TItem, string | null> => {
  return {
    type: 'host',
    name: 'Host',
    groupType: 'text',
    isBounded: false,
    accessor: (item) =>
      item.eventData?.organizer?.displayName ??
      item.eventData?.organizer?.email ??
      null,
    keyOf: (item) => item ?? 'No Value',
    initialValues: [
      {
        key: 'No Value',
        value: null,
      },
    ],
    sortBy: byValue((x) => x.value, Compare.string),
  }
}
