import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { useCallback, useMemo } from 'react'
import {
  type FieldPath,
  useController,
  type UseControllerProps,
} from 'react-hook-form'

import { useTaskDefinitionDirtyStateSetter } from './use-task-definition-dirty-state-setter'

import { getTaskPathFromStageTaskFieldPath } from '../utils'

export const useStageTaskField = <
  T extends Extract<
    FieldPath<FlowTemplateFormFields>,
    `stages.${number}.tasks.${number}.${string}`
  >,
>(
  path: T,
  rules?: UseControllerProps<FlowTemplateFormFields, T>['rules']
) => {
  const {
    form: { control, watch },
  } = useFlowTemplateForm()

  const { field } = useController({
    control,
    name: path,
    rules,
  })

  const { onChange } = field

  const value = watch(path)

  const taskPath = getTaskPathFromStageTaskFieldPath(path)

  const checkAndSetTaskDefinitionDirty = useTaskDefinitionDirtyStateSetter(
    taskPath,
    path
  )

  const stableOnChange = useCallback(
    (newValueOrFn: typeof value | ((prev: typeof value) => typeof value)) => {
      const newValue =
        typeof newValueOrFn === 'function' ? newValueOrFn(value) : newValueOrFn

      onChange(newValue)
      checkAndSetTaskDefinitionDirty(newValue)
    },
    [onChange, checkAndSetTaskDefinitionDirty, value]
  )

  return useMemo(
    () => [value, stableOnChange] as const,
    [value, stableOnChange]
  )
}
