import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { PopoverTrigger, UserCard } from '@motion/ui/base'
import { createLookupBy } from '@motion/utils/object'

import {
  AttendeeBadge,
  type AttendeeBadgeValue,
  PrettyAttendees,
} from '~/areas/event/components'
import {
  CellAction,
  CellText,
} from '~/areas/project-management/pages/pm-v3/tree-list/cells/components'
import { AppWorkspaceContext } from '~/global/contexts'
import { useMemo } from 'react'

type AttendeesCellProps = {
  attendeeEmails: string[]
}

export function AttendeesCell({ attendeeEmails }: AttendeesCellProps) {
  const userLookupByEmail = useSharedStateQuery(
    AppWorkspaceContext,
    (state) => {
      return createLookupBy(
        state.users.all.filter((user) =>
          attendeeEmails.some((attendeeEmail) => attendeeEmail === user.email)
        ),
        (item) => item.email
      )
    }
  )

  const attendeeBadgeValues: AttendeeBadgeValue[] = useMemo(
    () =>
      attendeeEmails.map((attendeeEmail) => {
        const teamMember = userLookupByEmail[attendeeEmail]
        const copy: AttendeeBadgeValue = {
          email: attendeeEmail,
          isOptional: false,
          isOrganizer: false,
          status: 'accepted',
        }

        if (teamMember != null) {
          copy.displayName = teamMember.name
          copy.profileUrl = teamMember.picture
        }

        return copy
      }),
    [attendeeEmails, userLookupByEmail]
  )

  if (attendeeEmails.length === 0) {
    return (
      <CellText>
        <span className='text-semantic-neutral-text-subtle'>None</span>
      </CellText>
    )
  }

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={() => (
        <AttendeesDropdownContent attendees={attendeeBadgeValues} />
      )}
    >
      <CellAction>
        <PrettyAttendees
          attendees={attendeeBadgeValues}
          size='normal'
          hideRsvpStatus
        />
      </CellAction>
    </PopoverTrigger>
  )
}

type AttendeesDropdownContentProps = {
  attendees: AttendeeBadgeValue[]
}

function AttendeesDropdownContent({
  attendees,
}: AttendeesDropdownContentProps) {
  return (
    <div className='min-w-[180px] max-h-[400px] px-3 py-2 flex flex-col gap-3 overflow-auto'>
      {attendees.map((attendee) => {
        const name = attendee.displayName

        return (
          <div className='shrink-0' key={attendee.email}>
            <UserCard
              name={name != null ? name : attendee.email}
              icon={<AttendeeBadge hideRsvpStatus value={attendee} />}
              tag={attendee.isOrganizer ? 'Host' : null}
            />
          </div>
        )
      })}
    </div>
  )
}
