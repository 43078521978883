import { AutoscheduleSolid, BellSolid } from '@motion/icons'
import { FieldButton, PopoverTrigger } from '@motion/ui/base'
import { isValidTaskDeadlineDateOption } from '@motion/ui-logic/pm/task'

import { WarnOnToggle } from '~/areas/project-management/components/warn-on-toggle'
import { DateTriggerButton } from '~/areas/task-project/components'
import { TaskDeadlineDropdown } from '~/areas/tasks/components'
import { useController } from 'react-hook-form'

import { useActionItemForm } from '../../hooks'

export function ControlledDeadlineField() {
  const { form } = useActionItemForm()

  const { control } = form

  const { field } = useController({
    name: 'dueDate',
    control,
  })

  const startDate = form.watch('startDate')
  const projectId = form.watch('projectId')
  const priorityLevel = form.watch('priorityLevel')
  const isASAP = priorityLevel === 'ASAP'

  return (
    <TaskDeadlineDropdown
      contextProps={{
        projectId,
      }}
      value={field.value}
      onChange={field.onChange}
      isValidDateOption={(date) =>
        isValidTaskDeadlineDateOption({ type: 'NORMAL', startDate }, date)
      }
    >
      <DateTriggerButton
        label='Deadline'
        icon={<AutoscheduleSolid />}
        onClear={() => field.onChange(null)}
        date={isASAP ? null : field.value}
        placeholder={isASAP ? 'ASAP' : 'No deadline'}
        clearTooltipContent='Remove deadline'
        endButton={<DeadlineWarnButton />}
      />
    </TaskDeadlineDropdown>
  )
}

export function DeadlineWarnButton() {
  return (
    <PopoverTrigger
      renderPopover={() => (
        <div className='p-2'>
          <FormWarnOnToggle />
        </div>
      )}
    >
      <FieldButton size='xsmall' variant='outlined' sentiment='ai' iconOnly>
        <div className='p-0.5'>
          <BellSolid />
        </div>
      </FieldButton>
    </PopoverTrigger>
  )
}

const FormWarnOnToggle = () => {
  const { form } = useActionItemForm()

  const { control, watch } = form

  const { field } = useController({
    name: 'ignoreWarnOnPastDue',
    control,
  })

  const ignoreOnPastDue = watch('ignoreWarnOnPastDue')

  const onChange = (value: boolean) => {
    field.onChange(value)
  }

  return (
    <WarnOnToggle
      checked={!ignoreOnPastDue}
      onChange={(checked) => onChange(!checked)}
    />
  )
}
