import { Text } from '@motion/ui/base'

import { useGetTeamPrices } from '~/global/rpc/team'
import { twMerge } from 'tailwind-merge'

import { AnnualSavings } from './AnnualSavings'

import { Paragraph } from '../../../Common'
import { PaymentInterval } from '../../types/PaymentInterval'

interface PaymentCardProps {
  costPerUser?: number | null | undefined
  interval: PaymentInterval
  isSelected: boolean
  total: number
  onClick: () => void
}

export const PaymentCard = ({
  costPerUser,
  interval,
  isSelected,
  onClick,
  total,
}: PaymentCardProps) => {
  const { data: teamPrices } = useGetTeamPrices()
  if (!teamPrices) return null
  const isAnnual = interval === PaymentInterval.Annually
  const finalCostPerUser =
    costPerUser ??
    (isAnnual ? teamPrices.annualPricePerMonth : teamPrices.monthlyPrice)

  const subtitle = `$${total} USD/${
    isAnnual ? 'year' : 'month'
  } ($${finalCostPerUser}/mo per user)`

  return (
    <div
      onClick={onClick}
      style={{ width: '465px' }}
      className={twMerge(
        'flex cursor-pointer items-center rounded border-solid py-4 px-4',
        isSelected
          ? 'bg-light-100 dark:bg-dark-900 dark:border-dark-100 border-primary-400 border-2'
          : 'bg-light-100 dark:bg-dark-800 dark:border-dark-700 dark:hover:bg-dark-900 border-light-400 hover:bg-light-300 border',
        isAnnual ? 'justify-between' : 'justify-start'
      )}
    >
      <div className='flex flex-row items-center'>
        <input
          className='mr-2.5 mt-1 h-4 w-4 rounded'
          type='checkbox'
          checked={isSelected}
        />
        <div className='flex flex-col items-start justify-between'>
          <Paragraph className='text-small !mb-1 font-semibold'>
            Billed {isAnnual ? 'annually' : 'monthly'}
          </Paragraph>
          <Text sentiment='subtle' size='xs'>
            {subtitle}
          </Text>
        </div>
      </div>
      {isAnnual && <AnnualSavings />}
    </div>
  )
}
