import { shuffle } from '@motion/utils/array'

import { useState } from 'react'

import { PrimaryButton } from '../../../components/Common'

export type CheckoutSurveyProps = {
  message?: string
  onSurveySubmit: (surveyResult: string) => void
  onComplete: () => void
}

const surveyOptions = [
  'Facebook or Instagram',
  'Friend or co-worker',
  'Article or newsletter',
  'Google',
  'Pinterest',
  'Twitter',
  'YouTube',
  'TikTok',
  'LinkedIn',
  'Banner Ad',
]

const randomizedSurveyOptions = [...shuffle(surveyOptions), 'Other']

type RadioButtonProps = {
  checked: boolean
  option: string
  onChange: (value: string) => void
}

const RadioButton = ({ checked, option, onChange }: RadioButtonProps) => (
  <label className='mt-1 flex items-center gap-x-2.5 cursor-pointer'>
    <input
      type='radio'
      checked={checked}
      onChange={(value) => onChange(value.target.value)}
      value={option}
    />
    {option}
  </label>
)

export const CheckoutSurvey = ({
  message,
  onComplete,
  onSurveySubmit,
}: CheckoutSurveyProps) => {
  const [surveyResult, setSurveyResult] = useState<undefined | any>(undefined)
  const [surveySubmitting, setSurveySubmitting] = useState(false)

  return (
    <div className='fixed inset-0 z-50  backdrop-blur-sm'>
      <div
        style={{ maxWidth: '95%', transform: 'translate(-50%, -50%)' }}
        className='fixed top-1/2 left-1/2 z-50 w-[500px] overflow-hidden rounded-xl drop-shadow-xl'
      >
        <div
          style={{ background: '#2c77e7' }}
          className='flex items-center justify-center p-6 text-lg font-bold text-white'
        >
          {message || 'Your trial has been started successfully!'}
        </div>
        <div className='flex flex-col items-start bg-white p-6'>
          <div style={{ color: '#1f2937' }} className='text-base font-bold'>
            How did you hear about Motion?
          </div>
          <div className='mt-2.5 flex flex-col'>
            {randomizedSurveyOptions.map((option) => (
              <RadioButton
                checked={surveyResult === option}
                key={option}
                onChange={(value) => setSurveyResult(value)}
                option={option}
              />
            ))}
          </div>
          <PrimaryButton
            data-testid='checkout-survey-submit'
            className='self-center'
            loading={surveySubmitting}
            disabled={!surveyResult}
            onClick={async () => {
              setSurveySubmitting(true)
              onSurveySubmit(surveyResult)
              onComplete()
            }}
          >
            Continue
          </PrimaryButton>
        </div>
      </div>
    </div>
  )
}
