import { type FolderSchema } from '@motion/zod/client'

import { useLookup } from '~/global/cache'
import { useCallback } from 'react'

export const useGetWorkspaceByFolderId = () => {
  const lookup = useLookup()

  return useCallback(
    (folderId: FolderSchema['id'] | null) => {
      const folder = lookup('folders', folderId)

      if (!folder || folder.targetType !== 'WORKSPACE') return null

      return lookup('workspaces', folder.targetId) ?? null
    },
    [lookup]
  )
}
