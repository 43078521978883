import { PlusSolid } from '@motion/icons'
import {
  MAX_NUMBER_PWT_VARIABLES,
  wrapVariableInDelimiters,
} from '@motion/shared/flows'
import { Button, Tooltip } from '@motion/ui/base'
import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { checkTextRuleErrors, getTextFieldRules } from '~/global/rules'
import { useEffect, useMemo } from 'react'
import { useFieldArray, type UseFormReturn } from 'react-hook-form'

import { TextVariableFieldWrapper } from './text-variable-field'

import {
  createNewTextVariable,
  findFirstDuplicateName,
  getNewVariableName,
  resolveAbbreviationConflicts,
} from '../utils'

export type TextVariableFieldsListProps = {
  variables: FlowTemplateFormFields['textVariables']
  form: UseFormReturn<FlowTemplateFormFields>
  deleteAnalyticsName: string
  onRemoveVariable?: (variableKey: string) => void
}

export const TextVariableFieldsList = ({
  variables,
  form,
  deleteAnalyticsName,
  onRemoveVariable,
}: TextVariableFieldsListProps) => {
  const modalApi = useModalApi()

  const { control, getValues, clearErrors, setValue } = form
  const { append, remove } = useFieldArray({
    control,
    name: 'textVariables',
  })

  const variableNames = variables.map((v) => v.name)
  const variableNamesString = variableNames.join(',')
  const resolvedAbbreviations = useMemo(() => {
    return resolveAbbreviationConflicts(variableNames)
    // Required
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variableNamesString])

  useEffect(() => {
    setValue('variableAbbreviations', resolvedAbbreviations)
  }, [resolvedAbbreviations, setValue])

  const onRemove = async (index: number) => {
    const res = await modalApi.prompt('confirm', {
      analytics: {
        name: deleteAnalyticsName,
      },
      title: 'Are you sure you want to delete this text variable?',
      description: `It will be removed anywhere it's used.`,
      closeButtonText: 'Cancel',
      confirmButtonText: 'Delete text variable',
    })
    if (res === ModalDismissed) return

    const variableKey = getValues(`textVariables.${index}.key`)
    if (onRemoveVariable) {
      const wrappedKey = wrapVariableInDelimiters(variableKey)
      onRemoveVariable(wrappedKey)
    }
    remove(index)
  }

  function addNewVariable() {
    const newVariable = createNewTextVariable({
      name: getNewVariableName(variables, 'text'),
    })

    append(newVariable)
  }

  return (
    <>
      <div className='flex flex-col gap-1.5 pl-1 pb-2'>
        {variables.map((field, i) => (
          <TextVariableFieldWrapper
            key={`${field.name}-${field.id}-${i}`}
            index={i}
            onRemove={() => onRemove(i)}
            abbreviation={resolvedAbbreviations.get(field.name)}
            rules={{
              validate: () => {
                clearErrors(`textVariables.${i}.name`)
                const variables = getValues('textVariables')
                const value = getValues(`textVariables.${i}.name`)

                const errorMessage = checkTextRuleErrors(
                  value,
                  getTextFieldRules('Variable name')
                )

                if (errorMessage) {
                  return errorMessage
                }

                const duplicateName = findFirstDuplicateName(variables)
                if (duplicateName) {
                  return `More than one variable named "${duplicateName.name}"`
                }
              },
            }}
          />
        ))}
      </div>

      <Tooltip
        content={
          variables.length >= MAX_NUMBER_PWT_VARIABLES
            ? `You can only add up to ${MAX_NUMBER_PWT_VARIABLES} variables`
            : undefined
        }
      >
        <Button
          variant='muted'
          sentiment='neutral'
          alignment='left'
          size='small'
          disabled={variables.length >= MAX_NUMBER_PWT_VARIABLES}
          onClick={() => {
            addNewVariable()
          }}
        >
          <PlusSolid />
          Add text variable
        </Button>
      </Tooltip>
    </>
  )
}
