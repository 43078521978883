import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { YoutubeVideo } from '~/components/Common/YoutubeVideo/YoutubeVideo'

type TutorialPlayer = {
  youtubeId: string
  lessonId: string
}

export const TutorialPlayer = ({ youtubeId, lessonId }: TutorialPlayer) => {
  return (
    <YoutubeVideo
      videoId={youtubeId}
      muteOnStart={false}
      onPlay={() => {
        recordAnalyticsEvent('TUTORIALS_VIDEO_PLAYED', {
          lessonId,
        })
      }}
      onEnd={() => {
        recordAnalyticsEvent('TUTORIALS_VIDEO_COMPLETED_WATCHING', {
          lessonId,
        })
      }}
      hideCc
      skipAutoPlay
    />
  )
}
