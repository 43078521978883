import {
  AutoscheduleStarSolid,
  ChevronDownOutline,
  ChevronUpOutline,
} from '@motion/icons'
import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { AssigneeDropdown } from '~/areas/project-management/components'
import { StageBadge } from '~/global/components/badges'
import { ConnectedAssigneeLabel, StageLabel } from '~/global/components/labels'
import { useStageDefinitionsByReferences } from '~/global/hooks'
import { Fragment, useState } from 'react'
import { useFieldArray } from 'react-hook-form'

import { useSetupProjectForm } from '../../../hooks'

export type ControlledRoleAssigneeFieldProps = {
  groupByName: string
  groupIndices: number[]
  groupStages: string[]
}
export const ControlledRoleAssigneeField = ({
  groupIndices,
  groupByName,
  groupStages,
}: ControlledRoleAssigneeFieldProps) => {
  const { form } = useSetupProjectForm()

  const { control, watch } = form

  const [isExpanded, setIsExpanded] = useState(false)
  const workspaceId = watch('workspaceId')
  const { fields, update } = useFieldArray({
    control,
    name: 'roleAssignees',
  })

  const stageDefinitionReferences = watch(
    'projectDefinition.stageDefinitionReferences'
  )

  const stagesToUse = useStageDefinitionsByReferences(
    stageDefinitionReferences
  ).filter((stage) => groupStages.includes(stage.id))

  const rolesToGroup = fields.filter((_, idx) => groupIndices.includes(idx))
  const isAiModified = rolesToGroup.some((role) => role.hasInitialValue)

  const selectedUserIds = Array.from(
    new Set(rolesToGroup.map((role) => role.value))
  )

  return (
    <RoleContainer>
      <RoleHeader>
        <RoleTitle>{groupByName}</RoleTitle>

        <div className='flex flex-row items-center gap-1.5'>
          {isAiModified && (
            <AutoscheduleStarSolid className='size-3 text-semantic-neutral-icon-disabled' />
          )}
          <AssigneeDropdown
            selectedUserId={
              selectedUserIds.length === 1 ? selectedUserIds[0] : undefined
            }
            onChange={(value) => {
              groupIndices.forEach((idx) => {
                update(idx, {
                  variableId: fields[idx].variableId,
                  stageId: fields[idx].stageId,
                  value,
                })
              })
              if (isAiModified) {
                recordAnalyticsEvent(
                  'PROJECT_MANAGEMENT_AI_CHANGE_SUGGESTED_PROJECT_ROLE'
                )
              }
            }}
            workspaceId={workspaceId}
          >
            <FieldButton size='small' variant='outlined' sentiment='neutral'>
              <div className='flex gap-2 items-center'>
                <ConnectedAssigneeLabel value={selectedUserIds} />
                <ChevronDownOutline />
              </div>
            </FieldButton>
          </AssigneeDropdown>
        </div>
      </RoleHeader>
      <StageRoleContainer>
        <StageRow>
          <Button
            onClick={() => setIsExpanded(!isExpanded)}
            sentiment='neutral'
            variant='muted'
            size='xsmall'
          >
            Choose assignee for each stage{' '}
            {isExpanded ? <ChevronUpOutline /> : <ChevronDownOutline />}
          </Button>
          <StageIconRow>
            {stagesToUse.map((stage) => (
              <StageBadge
                key={`${groupByName}-${stage.id}`}
                value={stage}
                size='xsmall'
              />
            ))}
          </StageIconRow>
        </StageRow>
        {isExpanded && (
          <StageList>
            {stagesToUse.map((stage, stageIdx) => {
              const idxToUpdate = fields.findIndex(
                (elem, idx) =>
                  elem.stageId === stage.id && groupIndices.includes(idx)
              )

              return (
                <Fragment key={`${groupByName}-${stageIdx}-${stage.id}`}>
                  <StageRow>
                    <StageLabel size='small' value={stage} />
                    <AssigneeDropdown
                      selectedUserId={fields[idxToUpdate]?.value}
                      onChange={(value) => {
                        update(idxToUpdate, {
                          variableId: fields[idxToUpdate].variableId,
                          stageId: fields[idxToUpdate].stageId,
                          value,
                        })
                        if (fields[idxToUpdate].hasInitialValue) {
                          recordAnalyticsEvent(
                            'PROJECT_MANAGEMENT_AI_CHANGE_SUGGESTED_PROJECT_ROLE'
                          )
                        }
                      }}
                      workspaceId={workspaceId}
                    >
                      <FieldButton
                        size='small'
                        variant='muted'
                        sentiment='neutral'
                      >
                        <div className='flex gap-2 items-center'>
                          <ConnectedAssigneeLabel
                            value={fields[idxToUpdate]?.value}
                          />
                          <ChevronDownOutline />
                        </div>
                      </FieldButton>
                    </AssigneeDropdown>
                  </StageRow>
                  {stageIdx < stagesToUse.length - 1 && <StageBorder />}
                </Fragment>
              )
            })}
          </StageList>
        )}
      </StageRoleContainer>
    </RoleContainer>
  )
}

const RoleContainer = classed('div', {
  base: `flex flex-col items-start self-stretch`,
})

const RoleHeader = classed('div', {
  base: `flex p-2 pl-3 justify-between items-center self-stretch
    rounded border border-semantic-neutral-border-subtle
    bg-button-neutral-outlined-bg-default
  `,
})

const RoleTitle = classed('span', {
  base: `text-semantic-neutral-text-default text-xs font-medium`,
})

const StageIconRow = classed('div', {
  base: `flex items-center gap-0.5`,
})

const FieldButton = classed(Button, {
  base: `
    justify-start
  `,
})

const StageRoleContainer = classed('div', {
  base: `flex flex-col justify-between items-center self-stretch p-1 pr-2 bg-semantic-neutral-bg-disabled
    rounded-b border border-semantic-neutral-border-light gap-2
  `,
})

const StageRow = classed('div', {
  base: `flex self-stretch justify-between items-center`,
})

const StageList = classed('div', {
  base: 'flex pl-1.5 flex-col items-start gap-1 self-stretch',
})

const StageBorder = classed('div', {
  base: `h-px self-stretch bg-semantic-neutral-border-subtle`,
})
