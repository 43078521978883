import {
  type EntryValue,
  getModelCache,
  getModelCacheRoot,
  getUnknown,
  MODEL_CACHE_KEY,
  type ModelCache,
  type ModelCacheRoot,
  type ModelId,
} from '@motion/rpc-cache'
import { READONLY_EMPTY_OBJECT, values } from '@motion/utils/object'
import { Sentry } from '@motion/web-base/sentry'
import {
  type LabelSchema,
  type ProjectSchema,
  type StageDefinitionSchema,
  type StatusSchema,
  type TaskSchema,
  type UserInfoSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { type ScopeContext } from '@sentry/core'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createProjectProxy,
  createTaskProxy,
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'
import { useCallback } from 'react'

export type AllowableModelCacheKeys = Exclude<
  keyof ModelCache,
  'scheduledEntities'
>

export interface ProxyLookupFn extends LookupFn {
  (type: 'tasks', id: string): TaskWithRelations | undefined
  (type: 'projects', id: string): ProjectWithRelations | undefined
  <T extends AllowableModelCacheKeys>(
    type: T,
    id: string
  ): EntryValue<T> | undefined
}

export function useProxyLookup(): ProxyLookupFn {
  const lookup = useLookup()
  // @ts-expect-error - typed above
  return useCallback(
    <T extends AllowableModelCacheKeys>(type: T, id: string) => {
      const rawValue = lookup(type, id)
      if (!rawValue) return undefined

      if (type === 'projects') {
        return createProjectProxy(rawValue as unknown as ProjectSchema, lookup)
      } else if (type === 'tasks') {
        return createTaskProxy(rawValue as unknown as TaskSchema, lookup)
      }
      return rawValue
    },
    [lookup]
  )
}
export interface LookupFn {
  (
    type: 'workspaces',
    idOrByFn: string | ((item: WorkspaceSchema) => boolean)
  ): WorkspaceSchema
  (
    type: 'labels',
    idOrByFn: string | ((item: LabelSchema) => boolean)
  ): LabelSchema
  (
    type: 'statuses',
    idOrByFn: string | ((item: StatusSchema) => boolean)
  ): StatusSchema
  (
    type: 'users',
    idOrByFn: string | ((item: UserInfoSchema) => boolean)
  ): UserInfoSchema
  (
    type: 'stageDefinitions',
    idOrByFn: string | ((item: StageDefinitionSchema) => boolean)
  ): StageDefinitionSchema
  <T extends AllowableModelCacheKeys>(
    type: T,
    idOrByFn: ModelId | null | ((item: EntryValue<T>) => boolean)
  ): EntryValue<T> | undefined
  <T extends AllowableModelCacheKeys>(type: T): EntryValue<T>[]
}

export type LookupFnT<T extends AllowableModelCacheKeys> = (
  type: T,
  idOrByFn: string | ((item: EntryValue<T>) => boolean)
) => EntryValue<T> | undefined

export function useLookup(): LookupFn {
  const client = useQueryClient()

  // @ts-expect-error - externally typed
  return useCallback(
    <T extends AllowableModelCacheKeys>(
      type: T,
      idOrByFn?: ModelId | null | ((item: EntryValue<T>) => boolean)
    ) => {
      const data = getModelCache(client)

      if (idOrByFn === undefined) {
        if (data == null || data[type] == null) return []
        const values = Object.keys(data[type]).map((id) => data[type][id].value)

        return filterFalsyAndReport(values, {
          data: {
            extra: {
              type,
              models: JSON.stringify(data[type], null, 2),
              values,
            },
          },
        })
      }

      if (data == null || data[type] == null) {
        return undefined
      }

      if (idOrByFn == null) return undefined

      const entry =
        typeof idOrByFn === 'string'
          ? data[type][idOrByFn]
          : values(data[type]).find((entry) => idOrByFn(entry.value))

      if (entry == null) {
        return getUnknown(
          type,
          typeof idOrByFn === 'string' ? idOrByFn : 'unknown'
        )
      }

      return entry.value
    },
    [client]
  )
}

export function useModelCacheQuerySelect<T>(
  select: (data: ModelCacheRoot) => T,
  { enabled = true }: { enabled?: boolean } = READONLY_EMPTY_OBJECT
) {
  const { data } = useQuery({
    queryKey: MODEL_CACHE_KEY,
    queryFn: (ctx) => getModelCacheRoot(ctx.client),
    select,
    enabled,
    staleTime: Infinity,
    notifyOnChangeProps: ['data', 'dataUpdatedAt'],
  })

  return data as T
}

export function useCachedItem<T extends AllowableModelCacheKeys>(
  type: T,
  id: ModelId | undefined | null
): EntryValue<T> | null
export function useCachedItem<T extends AllowableModelCacheKeys>(
  type: T,
  id: ModelId[]
): EntryValue<T>[]
export function useCachedItem<T extends AllowableModelCacheKeys>(
  type: T,
  id: ModelId | ModelId[] | undefined | null
): EntryValue<T> | EntryValue<T>[] | null {
  const data = useModelCacheQuerySelect(
    (data) => {
      if (id == null) return null

      if (Array.isArray(id)) {
        return id
          .map((itemId) => data.models[type][itemId]?.value ?? null)
          .filter(Boolean)
      }

      return data.models[type][id]?.value ?? null
    },
    { enabled: !!id }
  )

  return data
}

export function useCachedItems<
  T extends AllowableModelCacheKeys,
  U = EntryValue<T>[],
>(type: T, selector?: (data: EntryValue<T>[]) => U): U {
  const data = useModelCacheQuerySelect((data) => {
    const entryValues = values(data.models[type]).reduce<EntryValue<T>[]>(
      (prev, entry) => {
        if (entry.value) {
          prev.push(entry.value)
        }

        return prev
      },
      []
    )

    return selector ? selector(entryValues) : entryValues
  })

  return data as U
}

function filterFalsyAndReport<T>(
  values: (T | null | undefined)[],
  logMeta: {
    errorMessage?: string
    data?: Partial<ScopeContext>
  } = {}
): T[] {
  const { errorMessage = 'Undefined value in model cache', data } = logMeta
  const type = data?.extra?.type?.toString() ?? 'unknown'
  const message = type ? `[${type}] ${errorMessage}` : errorMessage

  if (values.some((v) => !v)) {
    Sentry.captureException(message, {
      level: 'warning',
      ...data,
      fingerprint: [
        'model-cache-lookup',
        'undefined-value-in-model-cache',
        type,
      ],
    })
  }

  return values.filter(Boolean)
}
