import { CrownSparkleSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { type UserNotetakerSettingsSchema } from '@motion/rpc-types'
import { showToast, Text, Toggle, Tooltip } from '@motion/ui/base'
import { Sentry } from '@motion/web-base/sentry'
import {
  useAccountFeatureUsage,
  useFeatureLimit,
  useIsFeatureLimitUnlimited,
} from '@motion/web-billing'
import { useModalApi } from '@motion/web-common/modals'
import { useMyUserSettings } from '@motion/web-common/settings'

import { TieredPricingUpsellGradientButton } from '~/areas/tiered-pricing'
import { useIsAdmin } from '~/global/hooks'
import { useCurrentTeam } from '~/global/rpc/team'
import {
  useTeamSettings,
  useUpdateNotetakerSettings,
  useUpdateNotetakerTeamSettings,
} from '~/global/rpc/v2'

export function NotetakerConferenceSettings() {
  const { mutateAsync: updateNotetakerTeamSettings } =
    useUpdateNotetakerTeamSettings()
  const { mutateAsync: updateUserNotetakerSettings } =
    useUpdateNotetakerSettings()

  const isNotetakerLimitUnlimited =
    useIsFeatureLimitUnlimited('aiNotetakerLimit')

  const notetakerLimit = useFeatureLimit('aiNotetakerLimit')
  const notetakerUsage = useAccountFeatureUsage('aiNotetakerLimit') ?? 0

  const disabled =
    !isNotetakerLimitUnlimited && notetakerUsage >= notetakerLimit

  const modalApi = useModalApi()
  const isAdmin = useIsAdmin()
  const { data: team } = useCurrentTeam()
  const { data: userSettings } = useMyUserSettings()
  const { data: teamSettings } = useTeamSettings(
    {
      teamId: team?.id ?? '',
    },
    {
      enabled: team != null,
    }
  )

  const userNotetakerSettings: UserNotetakerSettingsSchema =
    userSettings?.notetakerSettings ?? {
      enableBotForAllMeetings: false,
      sendRecapToAllAttendees: false,
    }

  async function handleCalendarAccess() {
    if (team == null) {
      Sentry.captureException(new Error('Team not found'), {
        tags: {
          position: 'NotetakerConferenceSettings',
        },
      })
      return
    }

    await updateNotetakerTeamSettings({
      teamId: team.id,
      allowCalendarEditorAccess:
        !teamSettings?.notetakerSettings?.allowCalendarEditorAccess,
    })
  }

  async function handleSendRecapToAllAttendees(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const newValue = e.target.checked

    const prompt = await modalApi.prompt('confirm', {
      title: 'Are you sure?',
      description:
        'This setting will apply to all of your future meetings. You can toggle this setting on or off per meeting if needed.',
      confirmButtonText: 'Confirm',
      closeButtonText: 'Cancel',
      analytics: {
        name: 'sendRecapToAllAttendees',
      },
    })

    if (prompt !== true) {
      return
    }

    await updateUserNotetakerSettings({
      sendRecapToAllAttendees: newValue,
    })

    showToast('success', 'AI Notetaker settings updated')
  }

  async function handleEnableBotForAllMeetings(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const newValue = e.target.checked

    const prompt = await modalApi.prompt('confirm', {
      title: templateStr(
        `Are you sure you want to turn {{toggle}} AI Notetaker?`,
        {
          toggle: newValue ? 'on' : 'off',
        }
      ),
      description:
        'This setting will apply to all of your future meetings. You can toggle this setting on or off per meeting if needed.',
      confirmButtonText: 'Confirm',
      closeButtonText: 'Cancel',
      analytics: {
        name: 'enableBotForAllMeetings',
      },
    })

    if (prompt !== true) {
      return
    }

    await updateUserNotetakerSettings({
      enableBotForAllMeetings: newValue,
    })

    showToast('success', 'AI Notetaker settings updated')
  }

  return (
    <div className='flex flex-col gap-4'>
      <Text weight='semibold'>AI Notetaker</Text>

      <NotetakerUpsellButton />

      <Text size='sm' className='text-semantic-neutral-text-subtle'>
        These settings apply to your meetings and events
      </Text>

      <Toggle
        checked={userNotetakerSettings.enableBotForAllMeetings}
        onChange={handleEnableBotForAllMeetings}
        disabled={disabled}
      >
        <p className='text-semantic-neutral-text-default'>
          Turn on AI Notetaker for all meetings with Conferencing{' '}
          <span className='text-semantic-neutral-text-subtle'>
            (can be changed per meeting)
          </span>
        </p>
      </Toggle>

      <Toggle
        checked={userNotetakerSettings.sendRecapToAllAttendees}
        onChange={handleSendRecapToAllAttendees}
        disabled={disabled}
      >
        <p className='text-semantic-neutral-text-default'>
          Automatically email external attendees with meeting notes when the
          meeting ends{' '}
          <span className='text-semantic-neutral-text-subtle'>
            (can be changed per meeting)
          </span>
        </p>
      </Toggle>

      {team != null && (
        <>
          <Text size='sm' className='mt-4 text-semantic-neutral-text-subtle'>
            This setting applies to all team members
          </Text>

          <Tooltip
            content={!isAdmin && 'Only team admins can change this setting'}
          >
            <Toggle
              checked={
                teamSettings?.notetakerSettings?.allowCalendarEditorAccess ??
                false
              }
              onChange={handleCalendarAccess}
              disabled={!isAdmin}
            >
              Allow all calendar editors to see recordings for that
              calendar&apos;s events, even when they are not invited to the
              meeting
            </Toggle>
          </Tooltip>
        </>
      )}
    </div>
  )
}

function NotetakerUpsellButton() {
  const isNotetakerLimitUnlimited =
    useIsFeatureLimitUnlimited('aiNotetakerLimit')

  if (isNotetakerLimitUnlimited) return null

  return (
    <div className='flex items-center gap-2 rounded-md bg-semantic-purple-bg-default p-3 self-start'>
      <CrownSparkleSolid className='size-4' />
      <Text size='sm'>
        Upgrade to{' '}
        <Text as='strong' size='sm' weight='semibold'>
          Pro AI
        </Text>{' '}
        to get AI Notetaker in all your meetings.
      </Text>
      <TieredPricingUpsellGradientButton
        feature='aiNotetakerLimit'
        featureLocked
        hideIcon
        tier='PROAI'
        sentiment='purple'
        size='small'
      >
        Upgrade
      </TieredPricingUpsellGradientButton>
    </div>
  )
}
