import { ChevronDoubleRightSolid } from '@motion/icons'
import { type NoteSchema } from '@motion/rpc-types'
import { IconButton } from '@motion/ui/base'

import {
  NoteLocationDropdown,
  NoteLocationLabel,
} from '~/areas/notes/components'
import { ModalFieldButton } from '~/areas/task-project/components'

import { NoteLocationIcon } from './note-location-icon'

export type NoteWorkspaceProjectFolderSectionProps = {
  noteId: NoteSchema['id']
  onClose: () => void
}

export function NoteWorkspaceProjectFolderSection({
  noteId,
  onClose,
}: NoteWorkspaceProjectFolderSectionProps) {
  return (
    <div className='flex flex-col gap-3 p-4'>
      <div className='flex items-center justify-between'>
        <span className='text-sm font-semibold text-semantic-neutral-text-default'>
          Location
        </span>
        <div className='-my-1'>
          <IconButton
            icon={ChevronDoubleRightSolid}
            variant='muted'
            sentiment='neutral'
            size='small'
            onClick={onClose}
          />
        </div>
      </div>
      <div>
        <NoteLocationDropdown noteId={noteId}>
          <ModalFieldButton icon={<NoteLocationIcon noteId={noteId} />}>
            <NoteLocationLabel noteId={noteId} />
          </ModalFieldButton>
        </NoteLocationDropdown>
      </div>
    </div>
  )
}
