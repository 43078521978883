import { Text } from '@motion/ui/base'

import { type ReactNode } from 'react'

export type SectionFieldProps = {
  title: ReactNode
  subTitle?: ReactNode
  children: ReactNode
}

export function SectionField({ title, subTitle, children }: SectionFieldProps) {
  return (
    <section>
      <Text as='label' size='sm' weight='semibold'>
        {title}

        {subTitle && (
          <Text size='xs' className='mt-1'>
            {subTitle}
          </Text>
        )}
        <div className='mt-2'>{children}</div>
      </Text>
    </section>
  )
}
