import { type ReactNode, useMemo } from 'react'

import {
  StageTaskCardFieldsContext,
  type StageTaskCardFieldsContextValue,
} from './stage-task-card-fields-context'

/**
 * Memoize `fields` in caller, as it is an object.
 */
export function StageTaskCardFieldsProvider({
  children,
  value: {
    stageTasks,
    task,
    stageDefinitionId,
    workspaceId,
    stageDuration,
    stageColor,
    textVariables,
    flowTemplateRoles,
    selectedRole,
    fields,
  },
}: {
  children: ReactNode
  value: StageTaskCardFieldsContextValue
}) {
  const memoizedValue = useMemo(
    () => ({
      stageTasks,
      task,
      stageDefinitionId,
      workspaceId,
      stageDuration,
      stageColor,
      textVariables,
      flowTemplateRoles,
      selectedRole,
      fields,
    }),
    [
      stageTasks,
      task,
      stageDefinitionId,
      workspaceId,
      stageDuration,
      stageColor,
      textVariables,
      flowTemplateRoles,
      selectedRole,
      fields,
    ]
  )

  return (
    <StageTaskCardFieldsContext.Provider value={memoizedValue}>
      {children}
    </StageTaskCardFieldsContext.Provider>
  )
}
