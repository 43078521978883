import { templateStr } from '@motion/react-core/strings'
import { Text } from '@motion/ui/base'

import { useInNoExternalCalendarsMode } from '~/areas/calendar/hooks'
import { MotionLink } from '~/global/components'
import { useAutoScheduleSettings } from '~/global/hooks'
import { SettingPageWrapper } from '~/pages/settings/shared'
import { useUriByRouteId } from '~/routing'

import { PublicTaskNamesSettings } from './PublicTaskNamesSettings/PublicTaskNamesSettings'
import { TaskBreakSettings } from './TaskBreakSettings/TaskBreakSettings'
import { TimeBlockSettings } from './TimeBlockSettings/TimeBlockSettings'

export const TaskManagerSettings = () => {
  const selectedTaskTimeBlockMode = useAutoScheduleSettings().taskTimeBlockMode
  const { noExternalCalendarsMode } = useInNoExternalCalendarsMode()

  const getRouteUri = useUriByRouteId()

  return (
    <SettingPageWrapper title='Auto-scheduling' className='gap-6'>
      <Text sentiment='subtle' size='sm'>
        {templateStr(
          'The task manager checks for conflicts from your "My Calendars" group. To modify this group, go to the {{calendarPageLink}} page in settings.',
          {
            calendarPageLink: (
              <MotionLink
                url={getRouteUri('settings-calendar')}
                className='font-semibold underline'
              >
                Calendars
              </MotionLink>
            ),
          }
        )}
      </Text>
      <TimeBlockSettings />
      {selectedTaskTimeBlockMode !== 'no-events' &&
        !noExternalCalendarsMode && <PublicTaskNamesSettings />}
      <TaskBreakSettings />
    </SettingPageWrapper>
  )
}
