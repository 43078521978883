import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useIsFlowProject } from '~/areas/flows'
import { useWorkspaceFns } from '~/global/hooks'
import { useReadTasksFn, useResolveProject } from '~/global/rpc/v2'
import { useCallback } from 'react'

import { useProjectUpdater } from '../updaters/use-project-updater'

/**
 * Hook to update a project's status as canceled.
 * If flow project, will prompt the user to confirm the action regarding its tasks' statuses before proceeding.
 * @returns a function that initiates the process of marking a project as canceled
 */
export function useCancelProject() {
  const updateProject = useProjectUpdater()

  const { mutateAsync: resolveProject } = useResolveProject()

  const modalApi = useModalApi()
  const { getWorkspaceProjectById } = useWorkspaceFns()

  const readTasks = useReadTasksFn()

  const isFlowProjectFn = useIsFlowProject()

  return useCallback(
    /**
     * @param projectId
     * @returns {(Promise<boolean>)} Whether the user confirmed the action or not
     */
    async (projectId: string, statusId: string) => {
      const project = getWorkspaceProjectById(projectId)
      const isFlowProject = isFlowProjectFn(project)

      if (!project) {
        throw new Error(`Project with id ${projectId} not found`)
      }

      if (!isFlowProject) {
        await updateProject(projectId, {
          statusId,
        })
        return true
      }

      const incompleteTasks = await readTasks({
        filters: [
          {
            workspaceIds: [project.workspaceId],
            projectIds: {
              operator: 'in',
              value: [project.id],
            },
            completed: 'exclude',
          },
        ],
      })

      if (incompleteTasks.length === 0) {
        await resolveProject({
          projectId,
          statusId,
          status: 'canceled',
          tasks: 'noop',
        })
        return true
      }

      try {
        recordAnalyticsEvent('PROJECT_MANAGEMENT_RESOLVE_PROJECT_MODAL', {
          state: 'open',
          type: 'cancel',
        })

        const modalResponse = await modalApi.prompt('resolve-project', {
          type: 'cancel',
          onDismiss: () =>
            recordAnalyticsEvent('PROJECT_MANAGEMENT_RESOLVE_PROJECT_MODAL', {
              state: 'dismissed',
              type: 'cancel',
            }),
        })

        if (modalResponse === ModalDismissed) {
          return false
        }

        await resolveProject({
          projectId,
          statusId,
          status: 'canceled',
          tasks: modalResponse,
        })
      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            position: 'useCancelProject',
          },
        })
        return false
      }

      return true
    },
    [
      getWorkspaceProjectById,
      isFlowProjectFn,
      modalApi,
      readTasks,
      resolveProject,
      updateProject,
    ]
  )
}
