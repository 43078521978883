import {
  AutoscheduleSolid,
  CalendarSolid,
  ClockSolid,
  XSolid,
} from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { type PMTaskType } from '@motion/rpc/types'
import { Button, Tooltip } from '@motion/ui/base'
import { prettyDateDay } from '@motion/ui-logic'
import { parseDate } from '@motion/utils/dates'
import { type NormalTaskSchema } from '@motion/zod/client'

import { TaskDeadlineDropdown } from '~/areas/tasks/components'
import {
  ConnectedUserBadge,
  PriorityBadge,
  StatusBadge,
} from '~/global/components/badges'
import { PriorityDropdown, StatusDropdown } from '~/global/components/dropdowns'
import { useTaskModalUrl } from '~/global/navigation'
import { useSearchParams } from '~/routing'
import { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import {
  useTemplateTaskAssigneeDropdown,
  useTemplateTaskDeadlinePicker,
  useTemplateTaskDurationDropdown,
  useTemplateTaskPriorityDropdown,
  useTemplateTaskStatusDropdown,
} from '../../hooks'
// import specificity needed to break circular dependency
import { AssigneeDropdown } from '../dropdowns/assignee-dropdown'
import { DurationDropdown } from '../dropdowns/duration-dropdown'

type TemplateTaskLineProps<T extends PMTaskType | NormalTaskSchema> = {
  task: T
  onRemove?: (blocker: T) => void
  onClickTask?: () => void
  size?: 'small' | 'xsmall'
}

/**
 * @deprecated - Only used for legacy templates. Use `SidebarTaskLine` instead
 */
export function TemplateTaskLine<T extends PMTaskType | NormalTaskSchema>(
  props: TemplateTaskLineProps<T>
) {
  const { task: projectTask, onRemove, onClickTask, size = 'small' } = props

  const { templateProjectId } = useParams<{ templateProjectId: string }>()
  const { template } = useSearchParams<{ template: string }>()
  const buildTaskModalUrl = useTaskModalUrl()
  const isCreatingNewTemplate = template === 'new'

  const task = useMemo(() => {
    if (templateProjectId != null) {
      return { ...projectTask, templateProjectId }
    }
    return projectTask
  }, [projectTask, templateProjectId])

  const { onChange: onChangeStatus, dropdownValue: statusDropdownValue } =
    useTemplateTaskStatusDropdown(task)
  const {
    onChange: onChangePriority,
    dropdownValue: priorityValue,
    priorities,
  } = useTemplateTaskPriorityDropdown(task)
  const {
    onChange: onChangeDuration,
    dropdownValue: durationValue,
    value: durationDisplayValue,
  } = useTemplateTaskDurationDropdown(task)
  const {
    onChange: onChangeAssignee,
    removeUnassignedOption,
    selectedAssignee,
  } = useTemplateTaskAssigneeDropdown(task)
  const { value: deadlineValue, onChange: onChangeDeadline } =
    useTemplateTaskDeadlinePicker(task)

  const { name, priorityLevel, assigneeUserId } = task
  const isAutoScheduled = task.isAutoScheduled

  return (
    <div className='group/task-line flex flex-row items-center grow gap-2 truncate'>
      {statusDropdownValue && (
        <StatusDropdown
          selectedStatusId={task.statusId}
          onChange={onChangeStatus}
          workspaceId={task.workspaceId}
        >
          <button
            type='button'
            className='flex'
            disabled={isCreatingNewTemplate}
          >
            <StatusBadge value={statusDropdownValue} size={size} />
          </button>
        </StatusDropdown>
      )}
      {isCreatingNewTemplate ? (
        <span
          className={twMerge(
            'truncate grow text-semantic-neutral-text-default',
            size === 'small' && 'text-sm',
            size === 'xsmall' && 'text-xs'
          )}
        >
          {name}
        </span>
      ) : (
        <Link
          onClick={() => onClickTask?.()}
          to={
            templateProjectId
              ? `./tasks/${task.id}`
              : buildTaskModalUrl({
                  forWorkspace: task.workspaceId,
                  forProject: task.projectId ?? undefined,
                  task: task.id,
                })
          }
          className={twMerge(
            'truncate grow text-semantic-neutral-text-default',
            size === 'small' && 'text-sm',
            size === 'xsmall' && 'text-xs'
          )}
        >
          {name}
        </Link>
      )}
      <div className='flex flex-row items-center gap-1'>
        <DurationDropdown value={durationValue} onChange={onChangeDuration}>
          <button type='button' disabled={isCreatingNewTemplate}>
            <Tooltip
              content={templateStr('Duration: {{ value }}', {
                value: durationDisplayValue,
              })}
            >
              <ClockSolid
                className={twMerge(
                  'text-semantic-neutral-icon-subtle',
                  size === 'small' && 'w-4',
                  size === 'xsmall' && 'w-3'
                )}
              />
            </Tooltip>
          </button>
        </DurationDropdown>
        <TaskDeadlineDropdown
          value={deadlineValue}
          onChange={onChangeDeadline}
          contextProps={{ taskId: task.id }}
        >
          <button type='button' disabled={isCreatingNewTemplate}>
            <Tooltip
              content={templateStr('Deadline: {{ value }}', {
                value:
                  priorityLevel === 'ASAP'
                    ? 'ASAP'
                    : deadlineValue
                      ? prettyDateDay(parseDate(deadlineValue))
                      : 'No deadline',
              })}
            >
              {isAutoScheduled ? (
                <AutoscheduleSolid
                  className={twMerge(
                    'text-semantic-purple-icon-default',
                    size === 'small' && 'w-4',
                    size === 'xsmall' && 'w-3'
                  )}
                />
              ) : (
                <CalendarSolid
                  className={twMerge(
                    'text-semantic-neutral-icon-subtle',
                    size === 'small' && 'w-4',
                    size === 'xsmall' && 'w-3'
                  )}
                />
              )}
            </Tooltip>
          </button>
        </TaskDeadlineDropdown>
        <PriorityDropdown
          items={priorities}
          selectedItem={priorityValue}
          onChange={onChangePriority}
        >
          <button type='button' disabled={isCreatingNewTemplate}>
            <PriorityBadge value={priorityLevel} size={size} />
          </button>
        </PriorityDropdown>

        <AssigneeDropdown
          onChange={(userId) => onChangeAssignee(userId)}
          removeUnassignedOption={removeUnassignedOption}
          selectedUserId={selectedAssignee?.userId ?? null}
          workspaceId={task.workspaceId}
        >
          <button type='button' disabled={isCreatingNewTemplate}>
            <Tooltip
              content={templateStr('Assignee: {{ name }}', {
                name: selectedAssignee?.user.name ?? 'Unassigned',
              })}
            >
              <ConnectedUserBadge userId={assigneeUserId ?? null} size={size} />
            </Tooltip>
          </button>
        </AssigneeDropdown>
        {onRemove && (
          <div className='invisible group-hover/task-line:visible'>
            <Button
              iconOnly
              variant='muted'
              sentiment='neutral'
              size='xsmall'
              onClick={() => onRemove(task)}
              disabled={isCreatingNewTemplate}
            >
              <XSolid />
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
