import { useOnDesktopEvent, useSendToDesktop } from '@motion/desktop-ipc/hooks'
import { setAnalyticsUserProperties } from '@motion/web-base/analytics'

import { useCalendarDateNavigation } from '~/areas/calendar/hooks'
import { persistSettings } from '~/localServices/firebase/background'
import { selectDesktopSettings, setDesktopVersion } from '~/state/desktopSlice'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import { useEffect } from 'react'
import { useMatch, useNavigate } from 'react-router'

import { useSendDesktopState } from './use-desktop-state'

export const useDesktopGlobalEvents = () => {
  const navigate = useNavigate()
  const isOnCalendar = Boolean(useMatch('/web/calendar/*'))
  const calendarNavigation = useCalendarDateNavigation()
  const desktopSettings = useAppSelector(selectDesktopSettings)
  const dispatch = useAppDispatch()
  const sendDesktopEvent = useSendToDesktop()
  const setDesktopState = useSendDesktopState()

  useEffect(
    function requestInitialData() {
      // @ts-expect-error: This is for legacy desktop users
      sendDesktopEvent('getVersion')
      sendDesktopEvent('getInitialData')
    },
    [sendDesktopEvent]
  )

  useOnDesktopEvent('appVersion', (version) => {
    // TODO: Remove this later once more users update to the latest version
    dispatch(setDesktopVersion(version))

    setDesktopState({
      desktopVersion: version,
    })

    setAnalyticsUserProperties({
      desktop_version: version,
    })

    // We need this still for the first time users
    // otherwise we won't be storing the version in firebase
    // TODO: Move this to pg database once we have it
    void persistSettings({
      desktop: {
        ...desktopSettings,
        hasDesktopApp: version,
      },
    })
  })

  useOnDesktopEvent(
    'hasNativeDesktopTabs',
    (hasNativeDesktopTabs, maxNumberOfTabs, currentNumberOfTabs) => {
      setDesktopState({
        tabs: {
          hasNativeDesktopTabs,
          maxNumberOfTabs,
          currentNumberOfTabs,
        },
      })
    }
  )

  useOnDesktopEvent('desktopDistribution', (distribution) => {
    setAnalyticsUserProperties({
      desktop_distribution: distribution,
    })
  })

  // Navigate in the main window
  useOnDesktopEvent('navigateInApp', (url) => {
    if (!url.startsWith('/web')) {
      return
    }

    navigate(url)
  })

  /* Desktop Event Handlers */
  // Intercept navigation from electron app to change URL in browser
  useOnDesktopEvent('loadCalendar', () => {
    if (isOnCalendar) {
      calendarNavigation.today()
    } else {
      navigate('calendar')
    }
  })

  useOnDesktopEvent('openTask', (taskPath: string) => {
    const parsedTaskPath = taskPath.replace('/web/', '')
    navigate(parsedTaskPath)
  })
}
