import { CheckSolid, TrashSolid } from '@motion/icons'
import { Button, Text } from '@motion/ui/base'
import {
  getMinimumBucket,
  MAX_PRO_TEAM_INITIAL_SIZE,
  teamBulletsShort,
} from '@motion/ui-logic/billing'
import { NOOP_FUNCTION } from '@motion/utils/function'
import { useEnterpriseLink } from '@motion/web-billing'

import { twMerge } from 'tailwind-merge'

import { useAppSelector } from '../../../../state/hooks'
import { selectTeam } from '../../../../state/team'
import { getTeamAdmins } from '../../../../utils/teamUtils'
import { Paragraph, SecondaryButton, TextButton } from '../../../Common'
import { type MembersSummaryProps } from '../../types/MembersSummaryProps'

interface MemberRowProps {
  email: string
  allowDelete?: boolean
  onDelete: (email: string) => void
}

const MemberRow = ({ email, onDelete, allowDelete = true }: MemberRowProps) => {
  return (
    <div className='flex flex-row items-center justify-between'>
      <Paragraph className='!my-1 truncate text-sm'>{email}</Paragraph>
      {allowDelete && (
        <TextButton icon={TrashSolid} onClick={() => onDelete(email)} />
      )}
    </div>
  )
}

interface MemberListProps {
  userEmail: string
  memberEmails: string[]
  allowDelete?: boolean
  onDelete: (email: string) => void
}

const MemberList = ({
  userEmail,
  memberEmails,
  onDelete,
  allowDelete = true,
}: MemberListProps) => {
  const team = useAppSelector(selectTeam)
  const adminsSet = getTeamAdmins(team?.members || [])
  return (
    <div className='flex flex-col gap-1'>
      <MemberRow email={userEmail} allowDelete={false} onDelete={() => null} />
      {memberEmails.map((email) => (
        <MemberRow
          key={email}
          email={email}
          allowDelete={allowDelete && !adminsSet.has(email)}
          onDelete={() => onDelete(email)}
        />
      ))}
    </div>
  )
}

const Upsells = () => (
  <div className='flex flex-col gap-1'>
    {teamBulletsShort.map((item) => (
      <div key={item} className='flex gap-2 place-content-start'>
        <CheckSolid className='text-semantic-primary-icon-default h-4 w-4 shrink-0 mt-[2px]' />
        <p className='text-semantic-neutral-text-subtle mb-0 text-sm font-normal'>
          {item}
        </p>
      </div>
    ))}
  </div>
)

export const MembersSummary = ({
  memberEmails,
  allowChangeMembers = true,
  onDelete = NOOP_FUNCTION,
  showModal,
  userEmail,
  seats,
  changeSeats,
}: MembersSummaryProps) => {
  const hasInsufficientSeats = memberEmails.length + 1 > (seats ?? Infinity)
  const newBucket = getMinimumBucket(memberEmails.length + 1)
  const openEnterpriseLink = useEnterpriseLink()

  return (
    <div className='border-semantic-neutral-border-subtle flex w-full lg:w-[300px] flex-col border-l py-6 px-4'>
      <div className='mb-4 flex items-center justify-between'>
        {(!!memberEmails.length || !allowChangeMembers) && (
          <Text
            sentiment={hasInsufficientSeats ? 'error' : 'subtle'}
            weight='medium'
            size='xs'
          >
            {seats
              ? `${memberEmails.length + 1}/${seats} seats used`
              : `${memberEmails.length + 1} members`}
          </Text>
        )}
        {allowChangeMembers && (
          <SecondaryButton
            className={twMerge(
              '!m-0 h-auto rounded !px-2.5 py-1',
              memberEmails.length ? 'w-auto' : 'w-full'
            )}
            onClick={showModal}
          >
            <p className='!m-0 text-[10px] font-medium'>+ Add members</p>
          </SecondaryButton>
        )}
      </div>
      {hasInsufficientSeats && (
        <div className='bg-semantic-error-bg-default p-2 rounded flex flex-col gap-2'>
          {newBucket > MAX_PRO_TEAM_INITIAL_SIZE ? (
            <>
              <p className='text-semantic-error-text-default text-xs text-center'>
                Only teams of up to {MAX_PRO_TEAM_INITIAL_SIZE} members are
                supported on our Pro plan. Please reach out to our sales team to
                invite {memberEmails.length + 1} members.
              </p>
              <Button
                size='small'
                onClick={() =>
                  openEnterpriseLink('teamResubscribe', userEmail, userEmail)
                }
              >
                Book demo
              </Button>
            </>
          ) : (
            <>
              <p className='text-semantic-error-text-default text-xs'>
                Switch to {newBucket} seats to invite {memberEmails.length}{' '}
                members
              </p>
              <Button size='small' onClick={() => changeSeats(newBucket ?? 0)}>
                Switch to {newBucket} seats
              </Button>
            </>
          )}
        </div>
      )}
      {memberEmails.length ? (
        <MemberList
          userEmail={userEmail}
          memberEmails={memberEmails}
          allowDelete={allowChangeMembers}
          onDelete={onDelete}
        />
      ) : (
        <Upsells />
      )}
    </div>
  )
}
