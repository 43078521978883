import { createLookupBy } from '@motion/utils/object'

import { useMemo } from 'react'

import { groupByDate, groupByHost } from '../definitions'

export function useAvailableGroups() {
  return useMemo(() => {
    return {
      all: [...groups],
      byType: createLookupBy(groups, (group) => group.type),
    }
  }, [])
}

const groups = [groupByDate(), groupByHost()]
