import { type NoteSchema } from '@motion/rpc-types'

import { useNoteLocation } from '~/areas/notes'
import {
  ConnectedFolderLabel,
  ConnectedNoteLabel,
  ConnectedProjectLabel,
  ConnectedWorkspaceLabel,
} from '~/global/components/labels'

export type NoteLocationLabelProps = {
  noteId: NoteSchema['id']
}

export function NoteLocationLabel({ noteId }: NoteLocationLabelProps) {
  const [parentId, parentType] = useNoteLocation({
    noteId,
  })

  return parentId != null && parentType === 'PROJECT' ? (
    <ConnectedProjectLabel id={parentId} nameOnly />
  ) : parentId != null && parentType === 'NOTE' ? (
    <ConnectedNoteLabel id={parentId} nameOnly />
  ) : parentId != null && parentType === 'WORKSPACE' ? (
    <ConnectedWorkspaceLabel id={parentId} nameOnly />
  ) : (
    <ConnectedFolderLabel id={parentId ?? undefined} nameOnly />
  )
}
