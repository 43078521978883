import { API } from '@motion/rpc-definitions'
import { LoadingSpinner } from '@motion/ui/base'
import {
  getMinimumBucket,
  INDIVIDUAL_ANNUAL_PRICE,
  INDIVIDUAL_MONTHLY_PRICE,
  INDIVIDUAL_PRICES,
  LOW_COST_TRIAL_PRICE,
  TEAM_DEFAULT_BUCKET_SEATS,
  TEAM_MINIMUM_BUCKET_SEATS,
  Term,
} from '@motion/ui-logic/billing'
import {
  getExperimentalTierBullets,
  getTierBulletHeader,
  getTierTitle,
  type Tier,
} from '@motion/ui-logic/tiered-pricing'
import {
  getSegmentAnalytics,
  getTrackingCookies,
  logEvent,
  recordAnalyticsEvent,
} from '@motion/web-base/analytics'
import { isMobileExperience } from '@motion/web-base/env'
import { errorInDev } from '@motion/web-base/logging'
import {
  BillingFeatures,
  BillingPaymentPage,
  type CheckoutType,
  useGetOrderedTiers,
  useGetTierPrice,
  useLowCostTrialExperiment,
} from '@motion/web-billing'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useExperiment } from '@motion/web-common/flags'

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
  type StripeElements,
  type StripePaymentElementChangeEvent,
} from '@stripe/stripe-js'
import { useQueryClient } from '@tanstack/react-query'
import { useUncancelSubscriptionAndUpdate } from '~/components/cancel-account-modal/rpc-hooks'
import { MembersSummary } from '~/components/Team/components/CreateTeam/MembersSummary'
import { AddMembersModal } from '~/components/Team/components/Modals/AddMembersModal/AddMembersModal'
import { useActiveMemberCount, useActiveTeamMembers } from '~/global/hooks/team'
import {
  useIndividualSubscription,
  useTeamSubscription,
  useUpdateDefaultPaymentMethod,
  useUpdateSubscriptionWithTier,
} from '~/global/rpc'
import {
  useCurrentTeam,
  useGetTeamPrices,
  useResubscribeTeam,
} from '~/global/rpc/team'
import { createTeam, fetchTeam } from '~/state/projectManagement/teamThunks'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router'
import { twMerge } from 'tailwind-merge'

import { Events } from '../../analyticsEvents'
import { createSubscription } from '../../state/corrilySlice'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { selectDisplayName, setStripeSubscription } from '../../state/userSlice'
import { trackCreateTeam } from '../onboarding/v2/shared/onboardingAnalytics'
import { useTrackAffiliate } from '../onboarding/v2/shared/rpc-hooks'
import { useCurrentTier } from '../tiered-pricing/hooks'

const isMobile = isMobileExperience()

interface BillingProps {
  error: string | null
  userEmail: string
  onChangeEmail?: () => void
  onSubmit: () => void
  onComplete: () => void
  onRerender: (clientSecret: string, error: string) => void
  isTeam: boolean
  onBack?: () => void
  isDowngrade?: boolean
  tier?: Tier
  initialBucketSeats?: number
  initialTerm?: Term
  checkoutType: CheckoutType
}

export function Billing({
  error,
  userEmail,
  onChangeEmail,
  onSubmit,
  onComplete,
  onRerender,
  isTeam,
  onBack,
  isDowngrade,
  tier,
  initialBucketSeats,
  initialTerm,
  checkoutType,
}: BillingProps) {
  const [term, setTerm] = useState<Term>(initialTerm ?? Term.Annual)
  const isMonthly = term === Term.Monthly
  const isLowCostTrial = term === Term.LowCostTrial
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isPaymentFilledOut, setIsPaymentFilledOut] = useState(false)
  const [errorMessage, setErrorMessage] = useState<null | string>(error)
  const [hasSelectedCard, setHasSelectedCard] = useState(true)
  const dispatch = useAppDispatch()
  const stripe = useStripe()
  const navigate = useNavigate()
  const elements = useElements()
  const { data: teamPrices } = useGetTeamPrices()
  const { data: team } = useCurrentTeam()
  const activeMemberCount = useActiveMemberCount()
  const currentTier = useCurrentTier()
  const activeTeamMembers = useActiveTeamMembers()
  const teamSubscription = useTeamSubscription()
  const individualSubscription = useIndividualSubscription()
  const { uid: userId } = useAuthenticatedUser()
  const queryClient = useQueryClient()
  initialBucketSeats =
    initialBucketSeats ??
    (isTeam
      ? Math.max(getMinimumBucket(activeMemberCount), TEAM_DEFAULT_BUCKET_SEATS)
      : 1)
  const [bucket, setBucket] = useState(initialBucketSeats)
  const [memberEmails, setMemberEmails] = useState<string[]>(
    activeTeamMembers
      .map((m) => m.user.email)
      .filter((email) => email !== userEmail)
  )
  const [isAddingMember, setIsAddingMember] = useState(false)

  const userDisplayName = useAppSelector(selectDisplayName)
  const { doUncancelSubscription } = useUncancelSubscriptionAndUpdate()
  const { mutateAsync: updateDefaultPaymentMethodAsync } =
    useUpdateDefaultPaymentMethod({
      onError: (error) => {
        recordAnalyticsEvent('TEAM_BILLING_ADD_CARD_ERROR')
        errorInDev(error)
        setErrorMessage(error.message ?? '')
        return
      },
    })
  const { mutateAsync: updateSubscriptionFeatureTier } =
    useUpdateSubscriptionWithTier()
  const { mutateAsync: resubscribeTeam } = useResubscribeTeam()

  const { mutateAsync: trackAffiliateLink } = useTrackAffiliate()

  const { price: tierPrice, isLoading: isPriceLoading } = useGetTierPrice(
    bucket ?? 0,
    term ?? Term.Monthly,
    tier
  )

  const tieredPricingExpValue = useExperiment('tiered-pricing-exp').value

  const orderedTiers = useGetOrderedTiers()

  const isAddPaymentMethod = checkoutType === 'add-payment-method'
  const isResubscribe = checkoutType === 'resubscribe'

  const {
    lowCostTrialPriceId,
    annualTrialLength,
    monthlyTrialLength,
    chosenTrialLength,
  } = useLowCostTrialExperiment(
    term,
    setTerm,
    isTeam,
    checkoutType === 'initial-purchase'
  )

  const hidePaymentMethodElements = tier && isAddPaymentMethod && !isResubscribe

  const getPrice = () => {
    if (tierPrice) {
      return tierPrice
    }

    const prices = isTeam ? teamPrices : INDIVIDUAL_PRICES
    return isMonthly ? prices?.monthlyPrice : prices?.annualPricePerMonth
  }

  const addMembers = useCallback(
    async (emails: string[]) => {
      const uniqueEmails = Array.from(new Set([...memberEmails, ...emails]))
      setMemberEmails(uniqueEmails)
      setIsAddingMember(false)
    },
    [memberEmails, setMemberEmails]
  )

  const logPurchaseEvent = (
    subscriptionId: string,
    cookieData: Record<string, string | undefined>
  ) => {
    try {
      const eventData = {
        action_source: 'website',
        category: 'checkout',
        currency: 'usd',
        customer_id: userId,
        email: userEmail,
        isTeam,
        label: isMonthly ? 'motion_monthly' : 'motion_annual',
        messageId: `${subscriptionId}-trialStart`,
        subscription_id: subscriptionId,
        trialLength: chosenTrialLength,
        ...(isLowCostTrial && lowCostTrialPriceId && { lowCostTrialPriceId }),
        url: window.location.href,
        value:
          term === Term.LowCostTrial
            ? LOW_COST_TRIAL_PRICE
            : isMonthly
              ? INDIVIDUAL_MONTHLY_PRICE
              : INDIVIDUAL_ANNUAL_PRICE,
        ...cookieData,
        tier,
      }
      const traitData = {
        traits: {
          email: userEmail,
          firstName: userDisplayName?.split(' ')[0]?.toLowerCase(),
          lastName: userDisplayName?.split(' ')[1]?.toLowerCase(),
        },
      }
      // maps to Segment StartTrial event
      getSegmentAnalytics()?.track('success_purchase', eventData, traitData)
      // maps to Segment Purchase event
      getSegmentAnalytics()?.track('success_purchase_new', eventData, traitData)

      if (isTeam) {
        getSegmentAnalytics()?.track(
          'b2b_trial',
          {
            ...eventData,
            value: bucket,
          },
          traitData
        )
      }
      if (!hasSelectedCard) {
        void logEvent(Events.TRIAL_START_WALLET)
      }
    } catch (e) {
      errorInDev(e)
    }
  }

  const processTierUpgradeOnly = async () => {
    if (!tier) {
      return
    }
    // Special case: Individual upgrading tier, switching to team
    if (isTeam && !team && initialBucketSeats > 1) {
      await dispatch(
        createTeam({
          invited: [],
          isMonthly,
          name: 'My team',
          seats: bucket,
          tier: tier,
          hasTrial: chosenTrialLength > 0,
        })
      )
      return
    }

    await updateSubscriptionFeatureTier({
      tier,
      isMonthly: term === Term.Monthly,
      teamId: isTeam ? team?.id : undefined,
      bucketSeats: isTeam && team ? bucket : undefined,
    })

    // Invalidate the feature permissions query
    void queryClient.invalidateQueries({
      queryKey: API.usersV2.queryKeys.featurePermissions(),
    })

    recordAnalyticsEvent('TIER_CHANGE', {
      previousTier: currentTier,
      newTier: tier,
      previousSeats: getMinimumBucket(activeMemberCount),
      newSeats: bucket,
      term: term,
      isDowngrade: isDowngrade ?? false,
    })
  }

  // TODO: Separate re-subscribe flows entirely from card adds
  const processAddPaymentMethodOrResubscribe = async () => {
    setIsSubmitting(true)

    // Just updating their tier from settings
    if (!isPaymentFilledOut && tier) {
      await processTierUpgradeOnly()
      onComplete()
      return
    }

    if (!stripe || !elements) {
      return
    }

    const result = await stripe?.confirmSetup({
      elements,
      redirect: 'if_required',
    })

    if (result?.error) {
      setErrorMessage(result?.error?.message || 'Error')
      setIsSubmitting(false)
      return
    }

    // Stripe types seem to think this is impossible
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const setupIntent: { id: string } = result.setupIntent!

    // Case 1: Individual adding a payment method staying individual
    if ((!isTeam && !team) || bucket === 1) {
      // If no subscription or team, handle individual resubscribe
      if (tier && !individualSubscription) {
        await processIndividualResubscribe(setupIntent.id)
        void queryClient.invalidateQueries({
          queryKey: API.subscriptions.getIndividualAndTeamSubscription.key(),
        })
        onComplete()
        return
      }
      await updateDefaultPaymentMethodAsync({ setupIntentId: setupIntent.id })
      recordAnalyticsEvent('INDIVIDUAL_BILLING_ADD_CARD_SUCCESS')
    }
    // Case 2: Individual adding a payment method switching to team
    else if (isTeam && !team) {
      await dispatch(
        createTeam({
          invited: [],
          isMonthly,
          name: 'My team',
          setupIntentId: setupIntent.id,
          seats: bucket,
          tier: tier,
          hasTrial: chosenTrialLength > 0,
        })
      )
      recordAnalyticsEvent('INDIVIDUAL_TO_TEAM_BILLING_ADD_CARD_SUCCESS')

      // Redirect to the team page
      navigate('/web/settings/team')
    }
    // Case 3: Team adding payment method
    else if (isTeam && team) {
      // If the team is canceled, and we're adding a card, we're in the tiered resubscribe flow
      if (tier && teamSubscription?.status === 'canceled') {
        await resubscribeTeam({
          isMonthly: isMonthly,
          memberEmails,
          setupIntentId: setupIntent.id,
          teamId: team.id,
          seats: bucket,
        })
        onComplete()
        await updateSubscriptionFeatureTier({
          tier,
          isMonthly: term === Term.Monthly,
          teamId: team.id,
          bucketSeats: bucket,
        })

        return
      }

      await updateDefaultPaymentMethodAsync({
        setupIntentId: setupIntent.id,
      })

      const fetchedTeam = await dispatch(fetchTeam()).unwrap()
      if (fetchedTeam?.team?.teamSubscription?.subscription) {
        dispatch(
          setStripeSubscription(
            fetchedTeam?.team?.teamSubscription?.subscription
          )
        )
      }
      recordAnalyticsEvent('TEAM_BILLING_ADD_CARD_SUCCESS')
    }
    await doUncancelSubscription()

    // Only do this if a team wasn't just newly created
    if (tier && (team || !isTeam)) {
      await updateSubscriptionFeatureTier({
        tier,
        isMonthly: term === Term.Monthly,
        teamId: isTeam ? team?.id : undefined,
        bucketSeats: isTeam && team ? bucket : undefined,
      })
    } else {
      void queryClient.invalidateQueries({
        queryKey: API.subscriptions.getIndividualAndTeamSubscription.key(),
      })
    }
    onComplete()
  }

  const processIndividualResubscribe = async (setupIntentId: string) => {
    try {
      let subscriptionId: string

      const response = await dispatch(
        createSubscription({
          isMonthly,
          setupIntentId: setupIntentId,
          trialLength: 0,
          tier,
        })
      ).unwrap()
      if (response.error || !response.subscription) {
        const errorMessage = response.error || 'Unable to create subscription'
        setErrorMessage(errorMessage)
        setIsSubmitting(false)
        if (response.clientSecret) {
          onRerender(response.clientSecret, errorMessage)
        }
        return
      }
      subscriptionId = response.subscription.id

      const cookieData = getTrackingCookies()
      logPurchaseEvent(subscriptionId, cookieData)
    } catch (e: any) {
      setIsSubmitting(false)
      setErrorMessage(e?.message)
    }
  }

  const processPayment = async () => {
    onSubmit()
    setIsSubmitting(true)
    setErrorMessage(null)

    try {
      // @ts-expect-error stripe may be null. Did not touch it to avoid breaking anything in billing while turning on strict mode.
      const result = await stripe.confirmSetup({
        elements: elements as StripeElements,
        redirect: 'if_required',
      })

      if (result?.error) {
        setErrorMessage(result?.error?.message || 'Error')
        setIsSubmitting(false)
        return
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const setupIntent: { id: string } = result.setupIntent!
      let subscriptionId: string
      if (isTeam) {
        const teamName = 'My team'
        const response = await dispatch(
          createTeam({
            name: teamName,
            setupIntentId: setupIntent.id,
            invited: [],
            isMonthly: isLowCostTrial || isMonthly,
            seats: bucket,
            hasTrial: chosenTrialLength > 0,
            tier: tier,
            lowCostTrialPriceId: isLowCostTrial
              ? lowCostTrialPriceId
              : undefined,
          })
        ).unwrap()

        if ('error' in response || !response.pmTeamSubscription) {
          const errorMessage =
            'message' in response && typeof response.message === 'string'
              ? response.message
              : 'Unable to create subscription'
          setErrorMessage(errorMessage)
          setIsSubmitting(false)

          if (
            'clientSecret' in response &&
            typeof response.clientSecret === 'string'
          ) {
            onRerender(response.clientSecret, errorMessage)
          }

          return
        }
        trackCreateTeam(teamName, [], userEmail, userDisplayName)
        subscriptionId = response.pmTeamSubscription.id
      } else {
        const response = await dispatch(
          createSubscription({
            // For BE creation, the low cost trials need to be treated as monthly.
            // But for all other purposes here (FE copy, analytics) they should be
            // treated as annual.
            isMonthly: isLowCostTrial ? true : isMonthly,
            setupIntentId: setupIntent.id,
            ...(isLowCostTrial && lowCostTrialPriceId
              ? { lowCostTrialPriceId }
              : { trialLength: chosenTrialLength }),
            tier: tier,
          })
        ).unwrap()
        if (response.error || !response.subscription) {
          const errorMessage = response.error || 'Unable to create subscription'
          setErrorMessage(errorMessage)
          setIsSubmitting(false)
          if (response.clientSecret) {
            onRerender(response.clientSecret, errorMessage)
          }
          return
        }
        subscriptionId = response.subscription.id
      }

      const cookieData = getTrackingCookies()

      if (cookieData.ps_xid) {
        void trackAffiliateLink({
          xid: cookieData.ps_xid,
          email: userEmail,
          name: userDisplayName ?? userEmail,
        })
      }

      logPurchaseEvent(subscriptionId, cookieData)
      void queryClient.invalidateQueries({
        queryKey: API.subscriptions.getIndividualAndTeamSubscription.key(),
      })
      onComplete()
    } catch (e: any) {
      setIsSubmitting(false)
      setErrorMessage(e?.message)
    }
  }

  const handlePaymentElementChange = (
    event: StripePaymentElementChangeEvent
  ) => {
    setHasSelectedCard(event.value.type === 'card')
    setIsPaymentFilledOut(event.complete)
  }

  if (!stripe || !elements || !teamPrices) {
    return null
  }

  const teamProps = isTeam
    ? { bucket, setBucket, isTeam, teamPrices }
    : { isTeam }
  const bucketMinSeats = Math.max(
    memberEmails.length + 1,
    TEAM_MINIMUM_BUCKET_SEATS
  )

  if (isPriceLoading) {
    return <LoadingSpinner />
  }

  return (
    <div className='flex flex-col w-full min-h-full lg:flex-row'>
      {isAddingMember && (
        <AddMembersModal
          addMembers={addMembers}
          closeModal={() => setIsAddingMember(false)}
        />
      )}

      <div
        className={twMerge(
          'flex grow justify-center gap-y-6',
          !tier && 'py-6 px-3'
        )}
      >
        <BillingPaymentPage
          paymentElement={
            <PaymentElement onChange={handlePaymentElementChange} />
          }
          monthlyTrialLength={monthlyTrialLength}
          annualTrialLength={annualTrialLength}
          chosenTrialLength={chosenTrialLength}
          term={term}
          setTerm={setTerm}
          lowCostTrialPriceId={lowCostTrialPriceId}
          isMobile={isMobile}
          userEmail={userEmail}
          onChangeEmail={onChangeEmail}
          hasSelectedCard={hasSelectedCard}
          error={errorMessage}
          isPaymentFilledOut={isPaymentFilledOut}
          isSubmitting={isSubmitting}
          handleSubmit={
            isAddPaymentMethod || isResubscribe
              ? processAddPaymentMethodOrResubscribe
              : processPayment
          }
          onBack={onBack}
          minSeats={bucketMinSeats}
          {...teamProps}
          checkoutType={checkoutType}
          isDowngrade={isDowngrade}
          canHidePaymentMethodElements={hidePaymentMethodElements}
          tier={tier}
        />
      </div>
      {isTeam && team && isResubscribe ? (
        <div className='overflow-y-auto'>
          <MembersSummary
            changeSeats={setBucket}
            memberEmails={memberEmails}
            userEmail={userEmail}
            seats={bucket}
            allowChangeMembers
            onDelete={(email) => {
              setMemberEmails(memberEmails.filter((m) => m !== email))
            }}
            showModal={() => {
              setIsAddingMember(true)
            }}
          />
        </div>
      ) : (
        <BillingFeatures
          term={term}
          allowLowCostTrial={!!lowCostTrialPriceId}
          trialLength={chosenTrialLength}
          hideTimeline={isAddPaymentMethod || isResubscribe}
          price={getPrice()}
          tierTitle={tier && getTierTitle(tier)}
          tierBulletHeader={tier && getTierBulletHeader(tier, orderedTiers)}
          tierBullets={
            tier && getExperimentalTierBullets(tier, tieredPricingExpValue)
          }
          bucket={bucket}
        />
      )}
    </div>
  )
}
