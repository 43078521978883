import { ClockSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import {
  formatDurationToShort,
  formatToShortTaskDuration,
} from '@motion/ui-logic'

import { ActivityValue } from '../components'

export function handleDuration(
  type: 'duration' | 'completed-time',
  oldValue: number | null = null,
  newValue: number | null = null
) {
  const formatFn =
    type === 'duration' ? formatToShortTaskDuration : formatDurationToShort

  return {
    icon: <ClockSolid />,
    children: templateStr(
      `changed {{type}} from {{durationFrom}} to {{durationTo}}`,
      {
        type: type === 'duration' ? 'duration' : 'completed time',
        durationFrom: <ActivityValue>{formatFn(oldValue)}</ActivityValue>,
        durationTo: <ActivityValue>{formatFn(newValue)}</ActivityValue>,
      }
    ),
  }
}
