import { type NormalTaskSchema } from '@motion/rpc-types'
import { Button, Tooltip } from '@motion/ui/base'
import { isEnabledStage } from '@motion/ui-logic/pm/project'

import { DateTime } from 'luxon'
import { useCallback } from 'react'
import { useController } from 'react-hook-form'

import { useCancelTasksFn, useSetupProjectForm } from '../../../hooks'

export type SkipStageButtonProps = {
  index: number
  tasks: NormalTaskSchema[]
}

export const SkipStageButton = ({ index, tasks }: SkipStageButtonProps) => {
  const {
    form: { control, watch },
  } = useSetupProjectForm()

  const { cancelTasks, uncancelTasks } = useCancelTasksFn()

  const stages = watch('speculativeProject.stages')

  const { field: canceledTimeField } = useController({
    name: `speculativeProject.stages.${index}.canceledTime`,
    control,
  })

  const isLastRemaining =
    stages.filter(isEnabledStage).length === 1 && !canceledTimeField.value

  const handleClick = useCallback(() => {
    const isCanceled = canceledTimeField.value
    if (isCanceled) {
      uncancelTasks(tasks)
      canceledTimeField.onChange(null)
    } else {
      cancelTasks(tasks)
      canceledTimeField.onChange(DateTime.now().toISO())
    }
  }, [canceledTimeField, cancelTasks, tasks, uncancelTasks])

  return (
    <Tooltip
      content={
        isLastRemaining ? 'At least one stage must be active' : undefined
      }
      asChild
    >
      <Button
        variant='muted'
        sentiment='neutral'
        size='small'
        onClick={handleClick}
        disabled={isLastRemaining}
      >
        {canceledTimeField.value ? 'Re-add stage' : 'Remove stage'}
      </Button>
    </Tooltip>
  )
}
