import { isPlaceholderId } from '@motion/shared/identifiers'
import { type AllAvailableCustomFieldSchema } from '@motion/ui-logic'
import {
  convertStageDefinitionToFormStage,
  type FlowTemplateFormFields,
  type FlowTemplateStage,
} from '@motion/ui-logic/pm/project'
import { byValue, Compare } from '@motion/utils/array'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { useModalApi } from '@motion/web-common/modals'
import { type StageDefinitionSchema } from '@motion/zod/client'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import { useFlowsModalState } from '~/areas/flows/contexts'
import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { useSetupProjectModalUrl } from '~/areas/project/modals/setup-project-modal'
import { showErrorToast } from '~/global/toasts'
import { useNavigate } from 'react-router-dom'

import { useRedirectToFlowTemplate } from './use-redirect-to-flow-template'
import { useSaveFlow } from './use-save-flow'

import {
  DEFAULT_TEXT_VARIABLES,
  isRoleVariable,
  isTextVariable,
} from '../../utils'

export function useSubmitTemplateForm() {
  const saveFlow = useSaveFlow()
  const redirectToFlowTemplate = useRedirectToFlowTemplate()
  const buildSetupProjectModalUrl = useSetupProjectModalUrl()
  const navigate = useNavigate()

  const { form } = useFlowTemplateForm()
  const modalState = useFlowsModalState()
  const modalApi = useModalApi()

  const workspaceId = form.watch('workspaceId')
  const flowTemplateId = form.watch('id')
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(workspaceId)

  return async (data: FlowTemplateFormFields) => {
    try {
      recordAnalyticsEvent('FLOW_TEMPLATE_MODAL_SUBMIT', {
        type: isPlaceholderId(flowTemplateId) ? 'create' : 'update',
      })

      const savedFlowTemplate = await saveFlow(data)

      if (savedFlowTemplate == null) {
        throw new Error('Failed to save flow template')
      }

      const currentFormValues = form.getValues()

      const updatedVars = {
        roles: savedFlowTemplate.variables
          .filter(isRoleVariable)
          .sort(byValue((role) => role.name, Compare.string)),
        textVariables: [
          ...DEFAULT_TEXT_VARIABLES,
          ...savedFlowTemplate.variables
            .filter(isTextVariable)
            .sort(byValue((textVariable) => textVariable.name, Compare.string)),
        ],
      }

      const updatedStageDefinitionReferences = {
        stageDefinitionReferences: savedFlowTemplate.stageDefinitionReferences,
      }

      const updatedFormValues = {
        ...currentFormValues,
        ...updatedVars,
        ...updatedStageDefinitionReferences,
        id: savedFlowTemplate.id,
        stages: updateStagesWithNewTasks({
          currentStages: currentFormValues.stages,
          updatedStages: savedFlowTemplate.stages,
          workspaceCustomFields,
        }),
      }

      // Reset form with updated values
      form.reset(updatedFormValues)

      // Dismiss current flows template modal and AI modal
      if (modalState.mode === 'ai-generation') {
        modalApi.dismiss('flows-template-modal')
        modalApi.dismiss('flows-ai-modal')
      }

      // If it's a new flow template, ask the user if they want to create a new project from the template
      if (flowTemplateId == null) {
        const modalResponse = await modalApi.prompt('action', {
          title: 'Your project workflow template has been created!',
          children:
            "You'll be able to use it when creating a new project in this workspace.",
          actions: [
            {
              label: 'Create project with this template',
              value: 'create',
              sentiment: 'neutral',
            },
            { label: 'Ok got it', value: 'skip' },
          ],
        })

        if (modalResponse === 'create') {
          recordAnalyticsEvent(
            'FLOW_TEMPLATE_CREATE_PROJECT_FROM_TEMPLATE_CLICKED'
          )

          return navigate(
            buildSetupProjectModalUrl({
              flowTemplateId: savedFlowTemplate.id,
            })
          )
        }
      }

      redirectToFlowTemplate(savedFlowTemplate.id)
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          position: 'useSubmitTemplateForm',
        },
      })

      showErrorToast(e)
    }
  }
}

type UpdateStagesWithNewTasksParams = {
  currentStages: FlowTemplateStage[]
  updatedStages: StageDefinitionSchema[]
  workspaceCustomFields: AllAvailableCustomFieldSchema[]
}
/**
 * Required to replace task placeholder ids and stage placeholder ids
 */
function updateStagesWithNewTasks({
  currentStages,
  updatedStages,
  workspaceCustomFields,
}: UpdateStagesWithNewTasksParams): FlowTemplateStage[] {
  return currentStages.map((stage) => {
    // replace placeholder stages with real ids by matching on name field
    const updatedStage = updatedStages.find((s) =>
      isPlaceholderId(stage.id) ? s.name === stage.name : s.id === stage.id
    )

    const updatedTasks = updatedStage
      ? convertStageDefinitionToFormStage({
          stage: updatedStage,
          workspaceCustomFields,
        }).tasks
      : stage.tasks

    const updatedVariables = updatedStage?.variables ?? stage.variables

    return {
      ...stage,
      variables: updatedVariables,
      tasks: updatedTasks,
      id:
        isPlaceholderId(stage.id) && updatedStage ? updatedStage.id : stage.id,
    }
  })
}
