import { ChevronDownOutline } from '@motion/icons'
import { Button, Text } from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'
import { NOOP_FUNCTION } from '@motion/utils/function'

import { twMerge } from 'tailwind-merge'

interface ListPickerHeaderProps {
  title: string
  isExpanded: boolean
  setExpanded: (expanded: boolean) => void
  showCheckbox?: boolean
  checked?: boolean
  setChecked?: (checked: boolean) => void
}

export const ListPickerHeader = ({
  title,
  isExpanded = false,
  setExpanded = NOOP_FUNCTION,
  showCheckbox = false,
  checked = false,
  setChecked = NOOP_FUNCTION,
}: ListPickerHeaderProps) => {
  return (
    <div className='border-semantic-neutral-border-strong bg-semantic-neutral-surface-bg-subtle flex w-full justify-between border-b px-3 py-1.5'>
      <div className='flex items-center gap-2'>
        {showCheckbox && (
          <Checkbox
            checked={checked}
            onChange={setChecked}
            label={title}
            labelHidden
          />
        )}
        <Text size='sm'>{title}</Text>
      </div>
      <div className='flex items-center gap-2'>
        <Button
          iconOnly
          sentiment='neutral'
          variant='muted'
          size='small'
          onClick={() => setExpanded(!isExpanded)}
          aria-label={isExpanded ? 'Collapse section' : 'Expand section'}
        >
          <ChevronDownOutline
            className={twMerge(
              'transition-transform duration-300',
              isExpanded && 'rotate-180'
            )}
          />
        </Button>
      </div>
    </div>
  )
}
