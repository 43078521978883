import { templateStr } from '@motion/react-core/strings'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
} from '@motion/rpc-types'
import { classed } from '@motion/theme'
import { FormModal, Text } from '@motion/ui/base'
import { RadioButton } from '@motion/ui/forms'
import { Sentry } from '@motion/web-base/sentry'

import { type ModalTriggerComponentProps } from '~/areas/modals/modal-trigger/modal-trigger'
import { ProjectDefinitionLabel } from '~/global/components/labels'
import { useProjectDefinitionWithStagesById } from '~/global/hooks'
import { useState } from 'react'

export type RemoveStageFromProjectOption =
  | 'delete'
  | 'next_stage'
  | 'previous_stage'
  | 'no_stage'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'confirm-remove-stage-from-project': PromptCallbacks<{
      removeOption: RemoveStageFromProjectOption
    }> & {
      project: ProjectSchema
      stageDefinitionId: StageDefinitionSchema['id']
    }
  }
}

export function ConfirmRemoveStageFromProjectModal({
  project,
  stageDefinitionId,
  close,
}: ModalTriggerComponentProps<'confirm-remove-stage-from-project'>) {
  const stageDefinitionIndex = project.stages.findIndex(
    (stage) => stage.stageDefinitionId === stageDefinitionId
  )
  const projectDefinition = useProjectDefinitionWithStagesById(
    project.projectDefinitionId
  )
  const showDetachWarning = project.projectDefinitionId != null

  const [removeOption, setRemoveOption] =
    useState<RemoveStageFromProjectOption | null>(null)

  if (!project || stageDefinitionIndex === -1) {
    Sentry.captureException(
      new Error('Stage or project definition not found'),
      {
        tags: {
          position: 'confirm-remove-stage-from-project-modal',
          stageDefinitionId,
        },
      }
    )
    close()
    return null
  }

  const disabledSubmit = removeOption == null
  const hasNextStage = project.stages.length > stageDefinitionIndex + 1
  const hasPreviousStage = stageDefinitionIndex > 0
  const isOnlyStage = project.stages.length === 1

  const title = showDetachWarning
    ? 'Removing a stage will unsync this project from the template'
    : 'What do you want to do for tasks in this stage?'

  return (
    <FormModal
      onClose={close}
      submitAction={{
        text: 'Continue',
        disabled: disabledSubmit,
        destructive: true,
        onAction: async () => {
          if (removeOption == null) {
            return
          }

          close({ removeOption })
        },
      }}
      title={<Text className='max-w-[370px] text-wrap'>{title}</Text>}
      visible
    >
      <FormBody>
        {showDetachWarning && (
          <div className='flex flex-col gap-2'>
            <Text className='flex gap-2' size='sm' sentiment='subtle'>
              {templateStr(
                'This project is using a template: {{templateLabel}}',
                {
                  templateLabel: (
                    <ProjectDefinitionLabel value={projectDefinition} />
                  ),
                }
              )}
            </Text>
            <Text size='sm'>
              By removing a stage, this project will no longer be using the
              template. Any changes made to the project template will no longer
              apply to this project.
            </Text>
          </div>
        )}
        <div className='flex flex-col gap-4'>
          {showDetachWarning && (
            <Text size='sm' weight='bold'>
              What do you want to do for the tasks in this stage?
            </Text>
          )}
          <RadioButton
            checked={removeOption === 'delete'}
            onChange={() => setRemoveOption('delete')}
            renderLabel={() => (
              <Text size='sm'>Remove stage and delete all tasks</Text>
            )}
          />
          {hasNextStage && (
            <RadioButton
              checked={removeOption === 'next_stage'}
              onChange={() => setRemoveOption('next_stage')}
              renderLabel={() => (
                <Text size='sm'>Remove stage and move tasks to next stage</Text>
              )}
            />
          )}
          {hasPreviousStage && (
            <RadioButton
              checked={removeOption === 'previous_stage'}
              onChange={() => setRemoveOption('previous_stage')}
              renderLabel={() => (
                <Text size='sm'>
                  Remove stage and move tasks to previous stage
                </Text>
              )}
            />
          )}
          {isOnlyStage && (
            <RadioButton
              checked={removeOption === 'no_stage'}
              onChange={() => setRemoveOption('no_stage')}
              renderLabel={() => (
                <Text size='sm'>Remove stage and move tasks to “No Stage”</Text>
              )}
            />
          )}
        </div>
      </FormBody>
    </FormModal>
  )
}

const FormBody = classed('div', {
  base: `flex flex-col items-start gap-8 self-stretch min-w-0 max-w-[402px] pr-3`,
})
