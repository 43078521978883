import { ArrowRightSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { FormModal } from '@motion/ui/base'
import {
  type ProjectsV2AdvanceStageRequestSchema,
  type ProjectsV2SetStageRequestSchema,
  type StageDefinitionSchema,
} from '@motion/zod/client'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { StageLabel } from '~/global/components/labels'
import { useState } from 'react'

import { MultiStageSkipContent } from './multi-stage-skip-content'
import { NextStageSkipContent } from './next-stage-skip-content'

type SharedProps = {
  currentStageDefinition: StageDefinitionSchema
  newStageDefinition: StageDefinitionSchema
}

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'skip-stages-modal':
      | (PromptCallbacks<ProjectsV2AdvanceStageRequestSchema['strategy']> &
          SharedProps & {
            isNextEnabledStage: true
          })
      | (PromptCallbacks<ProjectsV2SetStageRequestSchema['strategy']> &
          SharedProps & {
            isNextEnabledStage: false
          })
  }
}

export const SkipStagesModal = (
  props: ModalTriggerComponentProps<'skip-stages-modal'>
) => {
  if (props.isNextEnabledStage) {
    return <NextStageSkipModal {...props} />
  }

  return <MultiStageSkipModal {...props} />
}

const NextStageSkipModal = ({
  close,
  currentStageDefinition,
  newStageDefinition,
}: ModalTriggerComponentProps<'skip-stages-modal'>) => {
  const [value, setValue] =
    useState<ProjectsV2AdvanceStageRequestSchema['strategy']>('NOOP')

  return (
    <SkipStageFormModal
      close={close}
      onSubmit={() => close(value)}
      currentStageDefinition={currentStageDefinition}
      newStageDefinition={newStageDefinition}
    >
      <NextStageSkipContent value={value} setValue={setValue} />
    </SkipStageFormModal>
  )
}

const MultiStageSkipModal = ({
  close,
  currentStageDefinition,
  newStageDefinition,
}: ModalTriggerComponentProps<'skip-stages-modal'>) => {
  const [value, setValue] =
    useState<ProjectsV2SetStageRequestSchema['strategy']>('VISIT')

  return (
    <SkipStageFormModal
      close={close}
      onSubmit={() => close(value)}
      currentStageDefinition={currentStageDefinition}
      newStageDefinition={newStageDefinition}
    >
      <MultiStageSkipContent value={value} setValue={setValue} />
    </SkipStageFormModal>
  )
}

const SkipStageFormModal = ({
  close,
  onSubmit,
  currentStageDefinition,
  newStageDefinition,
  children,
}: SharedProps & {
  close: () => void
  children: React.ReactNode
  onSubmit: () => void
}) => {
  return (
    <FormModal
      visible
      onClose={close}
      submitAction={{
        onAction: onSubmit,
        text: 'Continue',
      }}
      cancelAction={{
        shortcut: 'esc',
      }}
      title={
        <div className='flex items-start flex-col gap-2'>
          <span>Advance project from</span>
          <div className='flex gap-2'>
            {templateStr('{{stage}} {{ arrow }} {{newStage}}', {
              stage: <StageLabel value={currentStageDefinition} />,
              arrow: <ArrowRightSolid className='size-[18px]' />,
              newStage: <StageLabel value={newStageDefinition} />,
            })}
          </div>
        </div>
      }
    >
      {children}
    </FormModal>
  )
}
