import { type TemplateTaskType } from '@motion/rpc/types'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'

import { useMemo } from 'react'

import { useTemplatesByWorkspaceId } from '../../../../global/rpc'

type Params = {
  id?: string
  templateProjectId?: string
  workspaceId?: string
}

interface Options {
  enabled?: boolean
}

export function useTemplateTask(
  params: Params,
  options: Options = READONLY_EMPTY_OBJECT
) {
  const { id, templateProjectId, workspaceId } = params
  const { enabled = true } = options

  const { data: templates, isInitialLoading } = useTemplatesByWorkspaceId(
    { workspaceId: workspaceId ?? '' },
    { enabled }
  )

  return useMemo(() => {
    if (templateProjectId != null) {
      const projectTasks = templates?.templateProjects.find(
        (p) => p.id === templateProjectId
      )?.project.tasks

      const task = projectTasks?.find((t) => t.id === id)

      return {
        isLoading: isInitialLoading,
        // A task in a project template doesn't have the shape of a template task, so we're converting the task into one
        templateTask: task
          ? ({
              id: '',
              name: '',
              workspaceId,
              task,
            } as TemplateTaskType)
          : undefined,
      }
    }

    return {
      isLoading: isInitialLoading,
      templateTask: templates?.templateTasks.find((t) => t.id === id),
    }
  }, [
    id,
    isInitialLoading,
    templateProjectId,
    templates?.templateProjects,
    templates?.templateTasks,
    workspaceId,
  ])
}
