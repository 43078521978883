import { UsersSolid } from '@motion/icons'
import { type Contact } from '@motion/rpc/types'
import { PopoverButton } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type CalendarEventAttendeeSchemaV2 } from '@motion/zod/client'

import {
  ContactsDropdown,
  type ContactsDropdownProps,
} from '~/areas/event/components/dropdowns/contacts-dropdown'

import { ExternalGuestItem } from './external-guest-item'
import { TeamMemberGuestItem } from './team-member-guest-item'

import {
  useScheduleAssistantCalendarContext,
  useSendScheduleAssistantCalendarContext,
  useStateGetScheduleAssistantEvents,
  useTransformEmailsToUserIds,
} from '../../../hooks'

export function GuestSection() {
  const [calendarState] = useScheduleAssistantCalendarContext()
  const updateCalendarState = useSendScheduleAssistantCalendarContext()

  const { userIds, unmatchedEmails } = useTransformEmailsToUserIds(
    calendarState.mode === 'create-new'
      ? calendarState.attendees.map((a) => a.email)
      : []
  )
  const { data: userIdToScheduleInfo } = useStateGetScheduleAssistantEvents(
    userIds,
    // FIX: react-query-v5
    // @ts-expect-error - boolean is fine
    {
      enabled: calendarState.mode === 'create-new',
    }
  )

  if (calendarState.mode !== 'create-new') {
    return null
  }

  const { attendees } = calendarState
  const attendeeEmails = attendees.map((a) => a.email)

  const onChange: ContactsDropdownProps['onChange'] = (contact: Contact) => {
    const attendee: CalendarEventAttendeeSchemaV2 = {
      email: contact.email,
      displayName: contact.displayName ?? contact.email,
      isOptional: false,
      isOrganizer: false,
      status: 'needsAction',
    }

    const alreadyGuest = attendees.some(
      (attendee) => attendee.email === contact.email
    )

    if (alreadyGuest) {
      // Remove the guest if they are already in the list
      updateCalendarState({
        attendees: attendees.filter((a) => a.email !== contact.email),
      })
    } else {
      // Add the guest if they are not in the list
      updateCalendarState({
        attendees: [...attendees, attendee],
      })
    }

    recordAnalyticsEvent('SCHEDULE_ASSISTANT_UPDATE_GUEST', {
      action: alreadyGuest ? 'remove' : 'add',
    })
  }

  return (
    <>
      <ContactsDropdown selectedEmails={attendeeEmails} onChange={onChange}>
        <PopoverButton size='small' icon={<UsersSolid />}>
          Add guests...
        </PopoverButton>
      </ContactsDropdown>

      <div className='flex flex-col gap-1'>
        {userIds.map((userId) => (
          <TeamMemberGuestItem
            key={userId}
            userId={userId}
            scheduleInfo={userIdToScheduleInfo?.[userId]}
          />
        ))}
        {unmatchedEmails.map((email) => (
          <ExternalGuestItem key={email} email={email} />
        ))}
      </div>
    </>
  )
}
