import type { OnOffFeature } from '@motion/shared/feature-tiers'
import { TEAM_DEFAULT_BUCKET_SEATS } from '@motion/ui-logic/billing'
import { type Tier } from '@motion/ui-logic/tiered-pricing'
import {
  useGetOrderedTiers,
  useHasFeaturePermission,
} from '@motion/web-billing'
import { useModalApi } from '@motion/web-common/modals'

import { getNextTier } from '~/areas/tiered-pricing/utils'
import { useIsTeamExpired } from '~/global/hooks/team'
import { useIndividualAndTeamSubscription } from '~/global/rpc'
import { useCurrentTeam } from '~/global/rpc/team'
import { useCallback } from 'react'

export const useExistingSubDefaultBucketSeats = () => {
  const { data: team } = useCurrentTeam()
  const isTeamExpired = useIsTeamExpired()
  return team && !isTeamExpired
    ? Math.max(
        TEAM_DEFAULT_BUCKET_SEATS,
        team?.teamSubscription?.bucketSeats ?? 0
      )
    : 1
}

export const useCurrentTier = () => {
  const { data: subData } = useIndividualAndTeamSubscription()
  const currentTier =
    subData?.combined?.featurePermissionTier?.tier ??
    subData?.team?.details?.featurePermissionTier?.tier ??
    'BASIC'
  return currentTier as Tier
}

export const useUpsellHiddenTiers = () => {
  const currentTier = useCurrentTier()
  const orderedTiers = useGetOrderedTiers()

  return filterHiddenTiers(orderedTiers, currentTier)
}

export type FeatureGatedCallback<T extends any[], R> = (
  ...args: T
) => R extends Promise<any> ? Promise<Awaited<R> | void> : R | void

export const useFeatureGatedCallback = <T extends any[], R>(
  feature: OnOffFeature,
  callback: (...args: T) => R
): FeatureGatedCallback<T, R> => {
  const hasFeature = useHasFeaturePermission(feature)
  const modalApi = useModalApi()
  const currentTier = useCurrentTier()
  const nextTier = getNextTier(currentTier) ?? 'PRO'

  return useCallback(
    (...args) => {
      if (hasFeature) {
        return callback(...args)
      }

      return void modalApi.open('tier-upgrade-prompt-modal', {
        feature,
        tier: nextTier,
      })
    },
    [callback, feature, hasFeature, modalApi, nextTier]
  ) as FeatureGatedCallback<T, R>
}

const filterHiddenTiers = (orderedTiers: Tier[], currentTier?: string) => {
  if (!currentTier) {
    return []
  }

  const idx = orderedTiers.findIndex((tier) => tier === currentTier)
  return orderedTiers.slice(0, idx + 1)
}
