import { templateStr } from '@motion/react-core/strings'
import { ConfirmationModal, FormModal, LoadingSpinner } from '@motion/ui/base'
import {
  type BillingPrices,
  makeTeamBillingStr,
  type TierBillingPrices,
} from '@motion/ui-logic/billing'
import { MAX_PRO_TEAM_INITIAL_SIZE } from '@motion/ui-logic/billing'
import {
  BucketSeatsDropdown,
  useEnterpriseLink,
  useGetTierPrices,
  useShouldShowSalesTaxMessage,
} from '@motion/web-billing'
import { useModalApi } from '@motion/web-common/modals'

import { useCurrentTier } from '~/areas/tiered-pricing/hooks'
import { useGetTeamPrices } from '~/global/rpc/team'
import { type ReactNode, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

type ManageTeamSeatsModalProps = {
  title: string
  makeSubtext?: (selectedSeats: number) => ReactNode
  actionText?: string
  cancelText?: string
  teamSize?: number
  isAnnual: boolean
  onSubmit: (newSeats: number) => void
  initialSelection?: number
  minSeats?: number
  disableIfClean?: boolean
  showSeatsUsed?: boolean
  onCancel?: () => void
  isTrial?: boolean
  overrideMaxSeats?: number
}

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'manage-team-seats': ManageTeamSeatsModalProps
  }
}

export const ManageTeamSeatsModal = ({
  title,
  actionText,
  cancelText,
  onCancel,
  teamSize,
  isAnnual,
  onSubmit,
  initialSelection = 5,
  makeSubtext,
  minSeats,
  disableIfClean,
  showSeatsUsed,
  isTrial = false,
  teamPrices,
  tierPrices,
  overrideMaxSeats,
}: ManageTeamSeatsModalProps & { teamPrices: BillingPrices } & {
  tierPrices?: TierBillingPrices
}) => {
  const maxSeats = overrideMaxSeats ?? MAX_PRO_TEAM_INITIAL_SIZE
  const openEnterpriseLink = useEnterpriseLink()

  const modalApi = useModalApi()

  const [selectedBucket, setSelectedBucket] = useState(initialSelection)
  const shouldShowSalesTaxMessage = useShouldShowSalesTaxMessage()

  useEffect(() => {
    setSelectedBucket(initialSelection)
  }, [initialSelection])

  const hasError = selectedBucket < (teamSize ?? 0)
  const isDirty = !teamSize || initialSelection !== selectedBucket

  const onClose = () => {
    modalApi.dismiss()
  }

  const tieredPrice = isAnnual
    ? tierPrices?.team.annualPricePerMonth
    : tierPrices?.team.monthlyPrice
  const price = isAnnual
    ? teamPrices.annualPricePerMonth
    : teamPrices.monthlyPrice

  if (minSeats && minSeats > maxSeats) {
    return (
      <ConfirmationModal
        visible
        title='Upgrade your plan'
        description={templateStr(
          'Only teams up to size {{maxSize}} are supported on our Business Standard Plan. To support {{teamSize}} users, please contact our sales team.',
          {
            maxSize: maxSeats,
            teamSize,
          }
        )}
        onClose={onClose}
        action={{
          label: 'Book a call',
          onAction: () => openEnterpriseLink('teamResizeModal'),
        }}
        closeLabel='Not now'
      />
    )
  }

  return (
    <FormModal
      visible
      onClose={() => {
        if (onCancel) {
          onCancel()
          return
        }
        onClose()
      }}
      title={title}
      submitAction={{
        text: actionText ?? `Switch to ${selectedBucket} seats`,
        onAction: async () => {
          await onSubmit(selectedBucket)
          onClose()
        },
        disabled: (disableIfClean && !isDirty) || hasError,
      }}
      cancelAction={{ text: cancelText ?? 'Cancel' }}
    >
      <div className='flex flex-col gap-4 w-[600px]'>
        <p className='text-sm text-semantic-neutral-text-subtle'>
          Pick your team size
        </p>
        <div className='flex flex-row items-center gap-3'>
          <BucketSeatsDropdown
            selectedBucket={selectedBucket}
            onChange={setSelectedBucket}
            minSeats={minSeats}
            overrideMaxSeats={maxSeats}
          />
          <p
            className={twMerge(
              'text-sm',
              hasError && showSeatsUsed
                ? 'text-semantic-error-text-default'
                : 'text-semantic-neutral-text-subtle'
            )}
          >
            {(isDirty && !hasError) || !showSeatsUsed
              ? templateStr('${{price}}/mo per seat', {
                  price: tieredPrice ?? price,
                })
              : templateStr('{{teamSize}}/{{selectedBucket}} seats used', {
                  teamSize: teamSize,
                  selectedBucket: selectedBucket,
                })}
          </p>
        </div>
        {makeSubtext ? (
          makeSubtext(selectedBucket)
        ) : (
          <p className='text-xs text-semantic-neutral-text-subtle'>
            {makeTeamBillingStr({
              isAnnual: isAnnual,
              quantity: selectedBucket,
              isSeats: true,
              prorationTextParams: { isTrial },
              teamPrices,
              tierTeamPrices: tierPrices?.team,
              shouldShowSalesTaxMessage,
            })}
          </p>
        )}
      </div>
    </FormModal>
  )
}

export const ConnectedManageTeamSeatsModal = (
  props: ManageTeamSeatsModalProps
) => {
  const tier = useCurrentTier()
  const { data: teamPrices } = useGetTeamPrices()
  const { data: tierPrices, isLoading: isTierPricesLoading } =
    useGetTierPrices(tier)

  if (isTierPricesLoading) {
    return <LoadingSpinner />
  }

  if (!teamPrices) return null

  return (
    <ManageTeamSeatsModal
      teamPrices={teamPrices}
      tierPrices={tierPrices}
      {...props}
    />
  )
}
