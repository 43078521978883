import {
  type MeetingActionItemTaskDataSchema,
  type TaskSchema,
} from '@motion/rpc-types'
import { getDefaultChunkDuration } from '@motion/ui-logic'
import { DEFAULT_SCHEDULE_ID } from '@motion/ui-logic/pm/task'

import { useMemo } from 'react'

import { type ActionItemFormFields } from '../types'

type InitialFormData = {
  initialData: MeetingActionItemTaskDataSchema
  task: TaskSchema | null
}

export function useInitialFormData({ initialData, task }: InitialFormData) {
  return useMemo(() => {
    if (task != null && task.type === 'NORMAL') {
      return {
        ...task,
        startDate: task.startDate ?? new Date().toISOString(),
        scheduleId: task.scheduleId ?? DEFAULT_SCHEDULE_ID,
      } satisfies ActionItemFormFields
    }

    const duration = initialData.duration ?? 30

    return {
      ...initialData,
      description: initialData.description ?? '',
      priorityLevel: initialData.priorityLevel ?? 'MEDIUM',
      duration,
      minimumDuration:
        initialData.minimumDuration ?? getDefaultChunkDuration(duration),
      startDate: initialData.startDate ?? new Date().toISOString(),
      assigneeUserId: initialData.assigneeUserId ?? null,
      workspaceId: initialData.workspaceId,
      projectId: initialData.projectId,
      ignoreWarnOnPastDue: initialData.ignoreWarnOnPastDue,
      scheduleId: DEFAULT_SCHEDULE_ID,
      deadlineType: initialData.deadlineType ?? 'SOFT',
      isAutoScheduled: true,
    } satisfies ActionItemFormFields
  }, [initialData, task])
}
