import { type FlowTemplateFormTask } from '@motion/ui-logic/pm/project'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { type ReactNode, useCallback, useMemo, useState } from 'react'

import { FlowsModalStateContext } from './flows-modal-context'

import { useFlowTemplateForm } from '../../shared-form/use-flow-template-form'

export type FlowsModalStateProviderProps = {
  children: ReactNode
  mode?: ModalTriggerComponentProps<'flows-template-modal'>['mode']
}

export function FlowsModalStateProvider({
  children,
  mode,
}: FlowsModalStateProviderProps) {
  const {
    form: { getValues },
  } = useFlowTemplateForm()

  const [dirtyTasksMap, setDirtyTasksMap] = useState<Record<string, boolean>>(
    {}
  )

  const getNewOriginalTasksMap = useCallback(() => {
    const stages = getValues('stages')

    return Object.fromEntries(
      stages.flatMap((stage) => stage.tasks.map((task) => [task.id, task]))
    )
  }, [getValues])

  const getNewOriginalTaskIndicesMap = useCallback(() => {
    const stages = getValues('stages')

    return Object.fromEntries(
      stages.flatMap((stage, stageIndex) =>
        stage.tasks.map((task, taskIndex) => [
          task.id,
          [stageIndex, taskIndex] as const,
        ])
      )
    )
  }, [getValues])

  const [originalTasksMap, setOriginalTasksMap] = useState<
    Record<string, FlowTemplateFormTask>
  >(getNewOriginalTasksMap())

  const [originalTaskIndicesMap, setOriginalTaskIndicesMap] = useState<
    Record<string, readonly [number, number]>
  >(getNewOriginalTaskIndicesMap())

  const resetDirtyState = useCallback(() => {
    setDirtyTasksMap({})
    setOriginalTasksMap(getNewOriginalTasksMap())
    setOriginalTaskIndicesMap(getNewOriginalTaskIndicesMap())
  }, [getNewOriginalTasksMap, getNewOriginalTaskIndicesMap])

  const value = useMemo(() => {
    return {
      mode,
      dirtyTasksMap,
      setDirtyTasksMap,
      originalTasksMap,
      setOriginalTasksMap,
      originalTaskIndicesMap,
      setOriginalTaskIndicesMap,
      resetDirtyState,
    }
  }, [
    mode,
    dirtyTasksMap,
    originalTasksMap,
    originalTaskIndicesMap,
    resetDirtyState,
  ])

  return (
    <FlowsModalStateContext.Provider value={value}>
      {children}
    </FlowsModalStateContext.Provider>
  )
}
