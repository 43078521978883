import type {
  NormalTaskSchema,
  TasksV2NormalUpdateSchema,
} from '@motion/rpc-types'
import { groupByKey } from '@motion/utils/array'

import { useCallback } from 'react'

import { useSetupProjectForm } from './use-setup-project-form'

const DEFAULT_TASK_DATA = {
  type: 'NORMAL' as const,
} satisfies TasksV2NormalUpdateSchema

export const useUpdateTasksFn = () => {
  const {
    form: { getValues, setValue },
  } = useSetupProjectForm()

  return useCallback(
    <T extends NormalTaskSchema>(
      tasks: T[],
      updateFn: (task: T) => Partial<TasksV2NormalUpdateSchema>
    ) => {
      const modifications = getValues('modifications')
      const speculativeTasksByStageDefinitionId = getValues(
        'speculativeTasksByStageDefinitionId'
      )

      if (
        modifications == null ||
        speculativeTasksByStageDefinitionId == null
      ) {
        throw new Error(
          'Modifications or speculative tasks by stage id are missing'
        )
      }

      const taskIds = tasks.map((task) => task.id)
      const speculativeTasks = Object.values(
        speculativeTasksByStageDefinitionId
      ).flat()

      const { newSpeculativeTasks, newModifications } = speculativeTasks.reduce(
        (acc, task) => {
          if (taskIds.includes(task.id)) {
            const updates = updateFn(task as T)
            acc.newSpeculativeTasks.push({
              ...task,
              ...updates,
            })
            acc.newModifications[task.id] = {
              ...(modifications[task.id] ?? {
                data: DEFAULT_TASK_DATA,
              }),
              data: {
                ...(modifications[task.id]?.data ?? DEFAULT_TASK_DATA),
                ...updates,
              } as TasksV2NormalUpdateSchema,
            }
          } else {
            acc.newSpeculativeTasks.push(task)
          }
          return acc
        },
        {
          newSpeculativeTasks: [] as typeof speculativeTasks,
          newModifications: { ...modifications },
        }
      )

      const newSpeculativeTasksByStageDefinitionId = groupByKey(
        newSpeculativeTasks,
        'stageDefinitionId'
      )

      setValue('modifications', newModifications)
      setValue(
        'speculativeTasksByStageDefinitionId',
        newSpeculativeTasksByStageDefinitionId
      )
    },
    [getValues, setValue]
  )
}
