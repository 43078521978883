import { getCacheEntryValue } from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { isFlowProject } from '@motion/ui-logic/pm/project'
import { cloneDeep } from '@motion/utils/core'
import { client } from '@motion/web-common/rpc'
import { DB } from '@motion/web-common/storage'

import { type RouteData } from '~/areas/project-management/pages/pm-v3/routes/types'
import { getDefaultView } from '~/areas/project-management/pages/pm-v3/views/defaults'
import { type MotionRoute } from '~/routing/api'
import { type LoaderFunctionArgs, Outlet } from 'react-router'

import {
  getLastViewPrefix,
  getPageTypeFromLoader,
  getStatePrefix,
  loadState,
  pageLoader,
  persistView,
} from './loaders'
import { log } from './log'

import {
  loggedRedirect,
  redirectLoader,
  relativeRedirect,
} from '../../../areas/project-management/pages/pm-v3/routes/utils/redirect'
import { getBrowseRoute } from '../browse/route'
import { PageTypeShellWithContext } from '../shell/page-type-shell'
import { PMShell } from '../shell/pm-shell'
import { ViewShell } from '../shell/view-shell'

declare module '~/routing/hooks/navigate-by-id-params' {
  interface NavigateByIdParams {
    parent: {
      type?: 'tasks' | 'projects'
      variant?: 'list' | 'kanban' | 'gantt'
    }
    'all-tasks': undefined
    'my-tasks': undefined
    'all-projects': undefined
    'my-tasks-archive': undefined
    'workspace-detail': {
      workspaceId: string
    }
    'workspace-project': {
      workspaceId: string
      projectId: string
    }
    'workspace-archive': {
      workspaceId: string
    }
    'project-tasks': {
      workspaceId: string
      projectId: string
    }
    'workspace-folder': { workspaceId: string; folderId: string }
    'notes-detail': { noteId: string }

    'all-tasks-browse': undefined
    'all-projects-browse': undefined
    'my-tasks-browse': undefined
    'workspace-folder-browse': { workspaceId: string; folderId: string }
    'workspace-detail-browse': {
      workspaceId: string
    }
    'workspace-project-browse': {
      workspaceId: string
      projectId: string
    }
  }
}

let redirectCount = 0

function getViewFromUrlQuery(uri: string) {
  const parsed = new URL(uri)
  return parsed.searchParams.get('view')
}

function legacyLoader() {
  return (args: LoaderFunctionArgs) => {
    const viewId = getViewFromUrlQuery(args.request.url) ?? 'default'
    return relativeRedirect(args, `../../views/${viewId}`, { removeView: true })
  }
}

const DEFAULT_VIEW_ID = '<default!view>'

const pages: MotionRoute[] = [
  {
    path: '',
    index: true,
    async loader(args) {
      const pageType = getPageTypeFromLoader(args)
      const stateKey = getLastViewPrefix(pageType, args.params)
      await DB.open()

      // Load the last selected view
      let viewId = await DB.state.get(stateKey)
      viewId = viewId === DEFAULT_VIEW_ID ? 'default' : viewId

      if (args.featureFlags['browse-view']?.value === 'on') {
        const isEverythingPage =
          pageType === 'all-projects' ||
          pageType === 'all-tasks' ||
          pageType === 'my-tasks'

        if (!isEverythingPage && (viewId == null || viewId === 'browse')) {
          return redirectLoader(`./browse`)(args)
        }
      }

      if (viewId == null) {
        viewId = 'default'
      }

      return redirectLoader(`./views/${viewId}`)(args)
    },
  },
  {
    path: 'views/:viewId',
    Component: ViewShell,
    async loader(args, ctx) {
      const pageType = getPageTypeFromLoader(args)
      return pageLoader(pageType)(args)
    },
  },
  {
    path: 'tasks/*',
    loader: (args) => {
      if (++redirectCount > 2) {
        redirectCount = 0
        throw new Error('too many redirects')
      }
      return redirectLoader('../../views/default')(args)
    },
  },
  {
    path: 'projects/list',
    loader: legacyLoader(),
  },
  {
    path: 'projects/kanban',
    loader: legacyLoader(),
  },
  {
    path: 'projects/gantt',
    loader: legacyLoader(),
  },
  {
    path: 'tasks/list',
    loader: legacyLoader(),
  },
  {
    path: 'tasks/kanban',
    loader: legacyLoader(),
  },
  {
    path: 'tasks/gantt',
    loader: legacyLoader(),
  },
  {
    path: 'projects/*',
    loader: (args) => {
      if (++redirectCount > 2) {
        redirectCount = 0
        throw new Error('too many redirects')
      }
      return redirectLoader('../../views/default')(args)
    },
  },
  {
    path: '*',
    loader(args) {
      if (++redirectCount > 2) {
        redirectCount = 0
        throw new Error('too many redirects')
      }

      log('load.*', args)

      return loggedRedirect(args, '/web/pm/all-tasks/views/default')
    },
  },
]

export const v4Routes: MotionRoute[] = [
  {
    id: 'pm-v4-root',
    path: '',
    Component: PMShell,
    children: [
      {
        id: 'all-tasks',
        path: 'all-tasks',
        routing: {
          // allows navigating to a view route from within a child route
          relative: true,
          template: 'views/:viewId',
          defaults: {
            viewId: 'default',
          },
        },
        Component: Outlet,
        children: [
          getBrowseRoute('all-tasks-browse'),
          {
            Component: PageTypeShellWithContext,
            children: pages,
          },
        ],
      },
      {
        id: 'all-projects',
        path: 'all-projects',
        routing: {
          relative: true,
          template: 'views/:viewId',
          defaults: {
            viewId: 'default',
          },
        },
        Component: Outlet,
        children: [
          getBrowseRoute('all-projects-browse'),
          {
            Component: PageTypeShellWithContext,
            children: pages,
          },
        ],
      },
      {
        id: 'my-tasks',
        path: 'my-tasks',
        routing: {
          relative: true,
          template: 'views/:viewId',
          defaults: {
            viewId: 'default',
          },
        },
        Component: Outlet,
        children: [
          getBrowseRoute('my-tasks-browse'),
          {
            Component: PageTypeShellWithContext,
            children: [
              ...pages,
              {
                id: 'my-tasks-archive',
                path: 'archive',
                Component: ViewShell,
                loader: async (args) => {
                  return {
                    page: 'my-tasks',
                    variant: 'archive',
                    key: 'my-tasks-archive',
                    type: 'tasks',
                    filter: {
                      views: 'my-tasks',
                      archive: true,
                    },
                    state: await loadState(
                      'my-tasks',
                      'my-tasks_archived',
                      args
                    ),
                  } satisfies Partial<RouteData>
                },
              },
            ],
          },
        ],
      },
      {
        id: 'workspace-project',
        path: 'workspaces/:workspaceId/projects/:projectId',
        routing: {
          relative: true,
          template: 'views/:viewId',
          defaults: {
            viewId: 'default',
          },
        },
        Component: Outlet,
        children: [
          getBrowseRoute('workspace-project-browse'),
          {
            Component: PageTypeShellWithContext,
            children: [
              ...pages,
              {
                id: 'project-tasks',
                path: 'task-list',
                async loader(args) {
                  const prefixKey = getStatePrefix('project', {
                    projectId: args.params.projectId,
                  })

                  await DB.open()
                  const defaultView = cloneDeep(
                    getDefaultView('project', {
                      isFlowProject: isFlowProject(
                        getCacheEntryValue(
                          client,
                          'projects',
                          args.params.projectId ?? ''
                        )
                      ),
                    })
                  )
                  defaultView.definition.itemType = 'tasks'
                  defaultView.definition.layout = 'list'
                  await persistView(prefixKey, defaultView)

                  return relativeRedirect(args, '../views/default')
                },
              },
            ],
          },
        ],
      },
      {
        id: 'workspace-folder',
        path: 'workspaces/:workspaceId/folders/:folderId',
        routing: {
          relative: true,
          template: 'views/:viewId',
          defaults: {
            viewId: 'default',
          },
        },
        Component: Outlet,
        children: [
          getBrowseRoute('workspace-folder-browse'),
          {
            Component: PageTypeShellWithContext,
            children: pages,
          },
        ],
      },
      {
        id: 'workspace-detail',
        path: 'workspaces/:workspaceId',
        routing: {
          relative: true,
          template: 'views/:viewId',
          defaults: {
            viewId: 'default',
          },
        },
        Component: Outlet,
        children: [
          getBrowseRoute('workspace-detail-browse'),
          {
            Component: PageTypeShellWithContext,
            children: [
              ...pages,
              {
                id: 'workspace-archive',
                path: 'archive',
                Component: ViewShell,
                loader: async (args) => {
                  return {
                    page: 'workspace',
                    variant: 'archive',
                    type: 'tasks',
                    key: `workspace_${args.params.workspaceId}_archived`,
                    filter: {
                      views: 'workspace',
                      workspaceId: args.params.workspaceId,
                      archive: true,
                    },
                    state: await loadState(
                      'workspace',
                      `workspace_${args.params.workspaceId}_archived`,
                      args
                    ),
                  } satisfies Partial<RouteData>
                },
              },
            ],
          },
        ],
      },
    ],
    loader: async () => {
      const ensureFeaturePermissionsDataLoaded =
        API.usersV2.featurePermissionsLoader(client)

      await ensureFeaturePermissionsDataLoaded()
      return {}
    },
  },
]
