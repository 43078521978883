import {
  type FlowTemplateFormTask,
  type FlowTemplateStage,
} from '@motion/ui-logic/pm/project'
import { createLookupById, createLookupByKey } from '@motion/utils/object'

import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { useCallback, useMemo } from 'react'

export type UseDeprecatedTemplateTaskReturn = {
  all: FlowTemplateFormTask[]
  byId: Record<string, FlowTemplateFormTask>
  ids: string[]
  blockedById: Record<string, string>
  blockingById: Record<string, string[]>
}

export const useDeprecatedTemplateTasks =
  (): UseDeprecatedTemplateTaskReturn => {
    const {
      form: { watch },
    } = useFlowTemplateForm()

    const getTemplateTasks = useDeprecatedTemplateTasksFn()
    const stages = watch('stages')

    return useMemo(() => getTemplateTasks(stages), [getTemplateTasks, stages])
  }

export const useDeprecatedTemplateTasksFn = (): ((
  stages: FlowTemplateStage[]
) => UseDeprecatedTemplateTaskReturn) => {
  return useCallback((stages) => {
    const allTasks = stages.flatMap((stage) => stage.tasks)
    const allTaskIds = allTasks.map((task) => task.id)

    const onlyBlocked = allTasks.filter((task) => task.blockedByTaskId != null)
    const blockedById = createLookupByKey(
      onlyBlocked,
      'id',
      (task) => task.blockedByTaskId as string
    )

    const blockingById = Object.entries(blockedById).reduce(
      (result, [blockedId, blockingId]) => {
        result[blockingId] ||= []
        result[blockingId].push(blockedId)
        return result
      },
      {} as Record<string, string[]>
    )

    const byId = createLookupById(allTasks)

    return {
      all: allTasks,
      byId,
      ids: allTaskIds,
      blockedById,
      blockingById,
    }
  }, [])
}
