import { TaskSolid } from '@motion/icons'
import { SYSTEM_FLOW_VARIABLE_OBJECTS } from '@motion/shared/flows'
import { ActionList, Button, HoverCard } from '@motion/ui/base'
import {
  type StageDefinitionSchema,
  type VariableDefinitionSchema,
} from '@motion/zod/client'

import { useMemo } from 'react'

import { TaskDefinitionNameRow } from './task-definition-name-row'

import { resolveAbbreviationConflicts } from '../../utils'

export type TaskDefinitionListDropdownProps = {
  stage: StageDefinitionSchema
  variables: VariableDefinitionSchema[]
}

export function TaskDefinitionListDropdown({
  stage,
  variables,
}: TaskDefinitionListDropdownProps) {
  const { tasks, workspaceId } = stage

  const variablesWithAbbreviations = useMemo(() => {
    const allVariables = variables.concat(SYSTEM_FLOW_VARIABLE_OBJECTS)

    const variableNames = allVariables.map((v) => v.name)
    const resolvedAbbreviations = resolveAbbreviationConflicts(variableNames)

    return allVariables.map((v) => ({
      ...v,
      abbreviation: resolvedAbbreviations.get(v.name),
    }))
  }, [variables])

  return (
    <HoverCard
      placement='bottom-end'
      renderContent={() => (
        <ActionList
          items={tasks.map((task) => ({
            content: (
              <TaskDefinitionNameRow
                task={task}
                variables={variablesWithAbbreviations}
                workspaceId={workspaceId}
              />
            ),
          }))}
        />
      )}
    >
      <Button sentiment='neutral' variant='muted' size='xsmall'>
        <TaskSolid />
        {tasks.length}
      </Button>
    </HoverCard>
  )
}
