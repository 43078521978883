import { FieldButton, Tooltip } from '@motion/ui/base'

import { AssigneeDropdown } from '~/areas/project-management/components'
import { useTaskUpdater } from '~/areas/tasks/hooks'
import { UserBadge } from '~/global/components/badges'
import { UserLabel } from '~/global/components/labels'
import { useMember, useWorkspaceById } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { useActionItemForm } from '../hooks'
import { useActionItemContext } from '../provider'

export function ConnectedInlineAssigneeField() {
  const { task } = useActionItemContext()
  const { form } = useActionItemForm()

  const { control, watch } = form

  const { field } = useController({
    name: 'assigneeUserId',
    control,
  })
  const workspaceId = watch('workspaceId')
  const selectedAssignee = useMember(field.value)
  const selectedAssigneeUser = selectedAssignee?.user ?? null
  const updateTask = useTaskUpdater()
  const workspace = useWorkspaceById(workspaceId)

  const workspaceInaccessible = workspace == null

  const handleChange = async (assigneeId: string | null) => {
    field.onChange(assigneeId)

    if (task != null) {
      await updateTask(task.id, { assigneeUserId: assigneeId })
    }
  }

  return (
    <AssigneeDropdown
      selectedUserId={field.value}
      onChange={handleChange}
      workspaceId={workspaceId}
    >
      <Tooltip
        content={
          workspaceInaccessible
            ? 'Please choose a workspace that you have access to for updating the assignee.'
            : null
        }
        asChild
      >
        <FieldButton
          disabled={workspaceInaccessible}
          variant='minimal'
          size='xsmall'
        >
          <UserBadge value={selectedAssigneeUser} />
          <UserLabel value={selectedAssigneeUser} nameOnly />
        </FieldButton>
      </Tooltip>
    </AssigneeDropdown>
  )
}
