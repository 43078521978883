import { ArrowRightSolid } from '@motion/icons'
import { IconButton, Tooltip } from '@motion/ui/base'
import { getNextActiveStage } from '@motion/ui-logic/pm/project'
import { sleep } from '@motion/utils/promise'

import { useMoveProjectToStage } from '~/areas/project/hooks'
import { StageField } from '~/areas/task-project/fields'
import { type StageDropdownProps } from '~/global/components/dropdowns'
import { useProject } from '~/global/hooks'

export type ProjectActiveStageFieldProps = {
  projectId: string
}

export function ProjectActiveStageField({
  projectId,
}: ProjectActiveStageFieldProps) {
  const moveProjectToStage = useMoveProjectToStage()
  const project = useProject(projectId)

  if (project == null) {
    throw new Error('Project is required')
  }

  if (project.activeStageDefinitionId == null) return null

  const onStageSelect: StageDropdownProps['onChange'] = async (
    stageDefinition
  ) => {
    if (project.activeStageDefinitionId === stageDefinition.id) return

    // Sleeping a bit to make sure the dropdown gets closed before prompting the modal to keep the focus within the modal
    await sleep(1)

    moveProjectToStage(project, stageDefinition.id)
  }

  const nextStage = getNextActiveStage(project)

  return (
    <StageField
      projectId={project.id}
      stageDefinitionId={project.activeStageDefinitionId}
      onChange={onStageSelect}
      suffix={
        nextStage != null ? (
          <Tooltip asChild content='Move to next stage'>
            <IconButton
              icon={ArrowRightSolid}
              size='xsmall'
              variant='outlined'
              sentiment='neutral'
              onClick={() => {
                moveProjectToStage(project, nextStage.stageDefinitionId)
              }}
            />
          </Tooltip>
        ) : undefined
      }
    />
  )
}
