import { type TabItem, TabList } from '@motion/ui/base'

import { useNavigateByRouteId } from '~/routing'
import { type ReactNode, useMemo } from 'react'

import { useNotetakerViewState } from '../../state'

type NotetakerTabsProps = {
  children?: ReactNode
}

export function ConnectedNotetakerTabs({ children }: NotetakerTabsProps) {
  const navigate = useNavigateByRouteId()
  const [viewState] = useNotetakerViewState()

  const tabs = useMemo((): TabItem[] => {
    return [
      {
        name: 'My Calls',
        value: 'my-calls',
        content: 'My Calls',
        onAction: () => {
          navigate('notetaker-list', { type: 'my-calls' })
        },
      },
      {
        name: 'Shared with me',
        value: 'shared-with-me',
        content: 'Shared with me',
        onAction: () => {
          navigate('notetaker-list', { type: 'shared-with-me' })
        },
      },
      {
        name: 'All Calls',
        value: 'all-calls',
        content: 'All Calls',
        onAction: () => {
          navigate('notetaker-list', { type: 'all-calls' })
        },
      },
      {
        name: 'My Future Calls',
        value: 'future',
        content: 'My Future Calls',
        onAction: () => {
          navigate('notetaker-list', { type: 'future' })
        },
      },
    ]
  }, [navigate])

  return (
    <div className='px-4 border-b border-solid border-tab-border-default flex gap-2 items-center'>
      <div className='flex-1 h-9 overflow-hidden [&>[role="tablist"]>[role="tab"]]:h-9'>
        <TabList items={tabs} activeValue={viewState.type} />
      </div>
      {children}
    </div>
  )
}
