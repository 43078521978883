import { isActiveStatus } from '@motion/shared/billing'
import { Term } from '@motion/ui-logic/billing'
import {
  getTierAnnualPercentSavings,
  proTier,
  type Tier,
  type TierDisplayMode,
} from '@motion/ui-logic/tiered-pricing'
import {
  BillingPlanDropdown,
  BillingPlanToggle,
  BucketSeatsDropdown,
  FeatureTierCards,
  useGetOrderedTiers,
  useGetTierAnnualDollarSavings,
  useLowCostTrialExperiment,
} from '@motion/web-billing'
import { markExposure } from '@motion/web-common/flags'

import { useActiveMemberCount } from '~/global/hooks/team'
import { useCurrentTeam } from '~/global/rpc/team'
import { useEffect, useState } from 'react'

export interface ChooseFeatureTierPageProps {
  mode: TierDisplayMode
  initialBucket: number
  onSelectFeatureTier: (tier: Tier, term: Term, bucket: number) => void
  currentTier?: Tier
  currentBucket?: number
  currentTerm?: Term
  hiddenTiers?: Tier[]
}

export function ChooseFeatureTierPage({
  mode,
  initialBucket,
  onSelectFeatureTier,
  currentTier,
  currentBucket,
  currentTerm,
  hiddenTiers,
}: ChooseFeatureTierPageProps) {
  const [bucket, setBucket] = useState<number>(initialBucket)
  const [term, setTerm] = useState<Term>(Term.Annual)
  const { data: team } = useCurrentTeam()
  const isTeamSubscriptionActive = isActiveStatus(
    team?.teamSubscription?.status
  )
  const teamMemberCount = useActiveMemberCount()
  const filteredOrderedTiers = useGetOrderedTiers()

  const isTeam = bucket > 1

  const {
    lowCostTrialPriceId,
    lowCostAmount,
    annualTrialLength,
    monthlyTrialLength,
  } = useLowCostTrialExperiment(term, setTerm, isTeam, mode === 'onboarding')

  const mostExpensiveNonEnterpriseTier = filteredOrderedTiers
    .toReversed()
    .find((tier) => tier !== 'ENTERPRISE' && !hiddenTiers?.includes(tier))
  const tierAnnualDollarSavings = useGetTierAnnualDollarSavings(
    mostExpensiveNonEnterpriseTier,
    isTeam
  )
  const maxAnnualDollarSavings = mostExpensiveNonEnterpriseTier
    ? tierAnnualDollarSavings
    : 0

  useEffect(function trackExposure() {
    markExposure('no-cc-combined-trial')
    markExposure('b2c-cc-combined-with-no-cc')
    markExposure('verify-email')
    markExposure('tiered-pricing-exp')
    markExposure('tiered-pricing-pro-ai')
  }, [])

  return (
    <div className='flex flex-col w-full h-full bg-semantic-neutral-surface-bg-subtlest gap-4'>
      <div className='flex flex-col w-full max-w-[1140px] mx-auto'>
        <div className='flex flex-row items-center justify-between h-20 px-10 pt-4'>
          <p className='text-lg text-semantic-neutral-text-default font-semibold'>
            Choose a plan
          </p>

          <div className='flex flex-row gap-6'>
            <div className='flex flex-row gap-2 items-center'>
              <p className='text-semantic-neutral-text-default'>
                Choose team size:
              </p>
              <BucketSeatsDropdown
                minSeats={!isTeamSubscriptionActive ? 1 : undefined}
                selectedBucket={bucket}
                onChange={setBucket}
                allowUpsell
                disableUnderMemberCount={teamMemberCount}
              />
            </div>
            <div className='min-w-[200px] flex flex-row items-center'>
              {lowCostTrialPriceId ? (
                <BillingPlanDropdown
                  term={term}
                  setTerm={setTerm}
                  annualTrialLength={annualTrialLength}
                  monthlyTrialLength={monthlyTrialLength}
                  annualDollarSavings={maxAnnualDollarSavings}
                  isAnnualSavingsUpTo
                  isTeam={isTeam}
                  popoverButtonStyle='standard'
                />
              ) : (
                <BillingPlanToggle
                  term={term}
                  setTerm={setTerm}
                  savingsPercent={getTierAnnualPercentSavings(proTier, isTeam)}
                />
              )}
            </div>
          </div>
        </div>

        <FeatureTierCards
          mode={mode}
          bucket={bucket}
          term={term}
          lowCostAmount={mode === 'onboarding' ? lowCostAmount : 0}
          annualTrialLength={mode === 'onboarding' ? annualTrialLength : 0}
          monthlyTrialLength={mode === 'onboarding' ? monthlyTrialLength : 0}
          onSelectFeatureTier={onSelectFeatureTier}
          currentTier={currentTier}
          currentBucket={currentBucket}
          currentTerm={currentTerm}
          hiddenTiers={hiddenTiers}
        />
      </div>
    </div>
  )
}
