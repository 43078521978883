export type CustomAttachmentVideoPreviewProps = {
  fileUrl: string
  fileName: string
}

export function CustomAttachmentVideoPreview({
  fileUrl,
  fileName,
}: CustomAttachmentVideoPreviewProps) {
  return (
    <video src={fileUrl} aria-label={fileName} controls className='w-full' />
  )
}
