import { type RoleVariable } from '@motion/ui-logic/pm/project'

import { isRoleVariable } from '~/areas/flows'
import { useMemo } from 'react'

import { ControlledRoleAssigneeField } from './fields'

import { useSetupProjectForm } from '../../hooks'

type StageRoleNameMap = Record<string, { indices: number[]; stages: string[] }>

export const RolesStepContent = () => {
  const { form } = useSetupProjectForm()
  const roles = form.watch('roleAssignees')

  const skippedStagesIndices = form
    .watch('stageDueDates')
    .map((stage, index) => (stage.skipped ? index : null))
    .filter((idx) => idx != null)

  const stages = form
    .watch('projectDefinition.stages')
    .filter((_, index) => !skippedStagesIndices.includes(index))

  const stageRoles = stages
    .flatMap((stage) => stage.variables)
    .filter(isRoleVariable)

  const groupIndicesByRoleName = useMemo(() => {
    const stageRolesMap = stageRoles.reduce(
      (acc, role) => {
        acc[role.id] = role
        return acc
      },
      {} as Record<string, RoleVariable>
    )

    return roles.reduce((acc, role, idx) => {
      const stageVariable = stageRolesMap[role.variableId]
      if (!stageVariable) {
        return acc
      }

      if (acc[stageVariable.name] == null) {
        acc[stageVariable.name] = {
          indices: [],
          stages: [],
        }
      }

      acc[stageVariable.name].indices.push(idx)
      acc[stageVariable.name].stages.push(role.stageId ?? '')
      return acc
    }, {} as StageRoleNameMap)
  }, [roles, stageRoles])

  return (
    <div className='flex flex-col gap-3'>
      {Object.keys(groupIndicesByRoleName).map((roleName) => (
        <ControlledRoleAssigneeField
          key={roleName}
          groupByName={roleName}
          groupIndices={groupIndicesByRoleName[roleName].indices}
          groupStages={groupIndicesByRoleName[roleName].stages}
        />
      ))}
    </div>
  )
}
