import { BackendApiService } from './BackendApiService'

export class OnboardingService extends BackendApiService {
  static id = 'OnboardingService' as const

  async completeOnboarding(): Promise<{
    success: boolean
    refresh?: boolean
    redirect?: string
  }> {
    return await this.request('users/onboard', 'POST', {})
  }
}

export const instance = new OnboardingService()
