import { templateStr } from '@motion/react-core/strings'
import { createPendingStatus } from '@motion/rpc-cache'
import { type TaskDefinitionSchema } from '@motion/rpc-types'

import { StatusBadge, type StatusBadgeProps } from '~/global/components/badges'
import { useWorkspaceStatuses } from '~/global/hooks'
import { twMerge } from 'tailwind-merge'

import { VariableLabel } from '../labels'
import { type VariableWithAbbreviation } from '../types'

export type TaskDefinitionNameRowProps = {
  task: TaskDefinitionSchema
  variables: VariableWithAbbreviation[]
  hideStatus?: boolean
  workspaceId?: string | null
  size?: StatusBadgeProps['size']
}

export function TaskDefinitionNameRow({
  task,
  variables,
  workspaceId,
  size = 'small',
  hideStatus = false,
}: TaskDefinitionNameRowProps) {
  const statuses = useWorkspaceStatuses(workspaceId)

  const taskNameHtml = templateStr(task.name, (key) => {
    const variable = variables.find((v) => v.key === key)

    return variable ? (
      <div className='inline-block align-middle'>
        <VariableLabel
          highlightText
          truncateLabel
          abbreviation={variable.abbreviation}
          value={variable}
          size='xsmall'
        />
      </div>
    ) : (
      <span>{key}</span>
    )
  })

  // TODO: extend this to different sizes as needed
  const fontSize = size === 'small' ? 'text-2xs' : 'text-xs'

  return (
    <div className='flex flex-row items-center gap-1'>
      {!hideStatus && (
        <div
          className={twMerge(
            'flex items-center',
            size === 'small'
              ? '[&_[data-icon]]:!size-3'
              : '[&_[data-icon]]:!size-4'
          )}
        >
          <StatusBadge
            value={
              statuses.find((status) => status.id === task.statusId) ??
              createPendingStatus('Placeholder')
            }
            size='small'
            taskVariant={
              task.scheduleMeetingWithinDays != null ? 'meeting' : 'default'
            }
          />
        </div>
      )}
      <div
        className={twMerge(
          'font-normal text-semantic-neutral-text-default flex items-center',
          fontSize
        )}
      >
        <span className='line-clamp-2'>{taskNameHtml}</span>
      </div>
    </div>
  )
}
