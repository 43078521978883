import { useProjectUpdater } from '~/areas/project/hooks'
import { AssigneeDropdown } from '~/areas/project-management/components'
import { useTaskAssigneeData } from '~/areas/tasks/hooks'
import { UserLabel } from '~/global/components/labels'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'

import { CellAction } from './components'

type TaskAssigneeCellProps = {
  task: TaskWithRelations
}
export const TaskAssigneeCell = ({ task }: TaskAssigneeCellProps) => {
  const { onChange, disabled } = useTaskAssigneeData(task)

  return (
    <AssigneeCell
      disabled={disabled}
      value={task.assignee}
      workspaceId={task.workspaceId}
      onChange={onChange}
    />
  )
}

type ProjectAssigneeCellProps = {
  project: ProjectWithRelations
}
export const ProjectAssigneeCell = ({ project }: ProjectAssigneeCellProps) => {
  const updateProject = useProjectUpdater()

  const onChange: AssigneeCellProps['onChange'] = (userId) => {
    void updateProject(project, {
      managerId: userId,
    })
  }

  return (
    <AssigneeCell
      value={project.manager}
      workspaceId={project.workspaceId}
      onChange={onChange}
    />
  )
}

type AssigneeCellProps = {
  disabled?: boolean
  value: TaskWithRelations['assignee']
  workspaceId: TaskWithRelations['workspaceId']
  onChange: (userId: TaskWithRelations['assigneeUserId']) => void
}
export const AssigneeCell = ({
  disabled = false,
  value,
  workspaceId,
  onChange,
}: AssigneeCellProps) => {
  return (
    <AssigneeDropdown
      selectedUserId={value?.id ?? null}
      workspaceId={workspaceId}
      onChange={onChange}
    >
      <CellAction disabled={disabled}>
        <UserLabel value={value} />
      </CellAction>
    </AssigneeDropdown>
  )
}
