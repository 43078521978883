import { PuzzleSolid } from '@motion/icons'
import { type ProjectDefinitionSchema } from '@motion/rpc-types'
import { ProjectPalette } from '@motion/ui/project'

import { type BadgeSize, getBadgeSizeInPixels } from './utils'

type ProjectDefinitionBadgeProps = {
  value: ProjectDefinitionSchema | null
  size?: BadgeSize
}
export function ProjectDefinitionBadge({
  value,
  size = 'normal',
}: ProjectDefinitionBadgeProps) {
  const pixelSize = getBadgeSizeInPixels(size)

  return (
    <ProjectPalette color={value?.color ?? 'gray'}>
      <PuzzleSolid
        className='text-palette-highlight-default shrink-0'
        height={pixelSize}
        width={pixelSize}
      />
    </ProjectPalette>
  )
}
