import { ActionDropdown, type ActionItem, Button } from '@motion/ui/base'
import { type Tier } from '@motion/ui-logic/tiered-pricing'

import { useCallback } from 'react'

interface ManagePlanDropdownProps {
  isAnnual: boolean
  isCanceled: boolean
  isTrial: boolean
  onCancel: () => Promise<void>
  onResubscribe: () => Promise<void>
  onSubscribe: () => void
  onSwitchPlan: () => Promise<void>
  annualSavings: number
  tier?: Tier
  onSwitchPlanTiered: () => void
}

export const ManagePlanDropdown = ({
  isAnnual,
  isCanceled,
  isTrial,
  onCancel,
  onResubscribe,
  onSubscribe,
  onSwitchPlan,
  annualSavings,
  onSwitchPlanTiered,
  tier,
}: ManagePlanDropdownProps) => {
  const generateDropdown = useCallback(() => {
    const options: ActionItem[] = []

    if (tier) {
      options.push({
        content: 'Change plan',
        onAction: onSwitchPlanTiered,
      })
    }

    if (!isAnnual && !isCanceled) {
      options.push({
        content: `Switch to annual plan (Save ${annualSavings}%)`,
        onAction: onSwitchPlan,
      })
    }
    if (isTrial && isCanceled) {
      options.push({
        content: 'Subscribe',
        onAction: onSubscribe,
      })
    } else if (isCanceled) {
      options.push({
        content: (
          <span className='text-semantic-success-text-default'>
            Resubscribe
          </span>
        ),
        onAction: onResubscribe,
      })
    } else {
      options.push({
        content: (
          <span className='text-semantic-error-text-default'>Cancel plan</span>
        ),
        onAction: onCancel,
      })
    }

    return options
  }, [
    annualSavings,
    isAnnual,
    isCanceled,
    isTrial,
    onCancel,
    onResubscribe,
    onSubscribe,
    onSwitchPlan,
    onSwitchPlanTiered,
    tier,
  ])

  return (
    <div className='max-w-sm px-4'>
      <ActionDropdown items={generateDropdown()}>
        <Button sentiment='neutral'>Manage plan</Button>
      </ActionDropdown>
    </div>
  )
}
