import { createQueryFilter } from '@motion/rpc-cache'
import { type FeedEntriesV2GetAllResponseSchema } from '@motion/zod/client'

import { notifyManager, type QueryKey } from '@tanstack/react-query'
import type { HandlerContext } from '~/areas/websockets/handlers/types'

const FEED_BY_ID = ['v2', 'feed'] as QueryKey

export async function refreshFeed(
  ctx: HandlerContext,
  feedsToUpdate: { tasks: string[]; projects: string[] }
) {
  const queries = ctx.client.getQueriesData<FeedEntriesV2GetAllResponseSchema>(
    createQueryFilter([FEED_BY_ID])
  ) as [QueryKey, FeedEntriesV2GetAllResponseSchema][]

  const invalidatedQueryKeys: QueryKey[] = []
  queries.forEach(([key, data]) => {
    if (data == null) return

    /* This query is a known key that is filtered on above, and the 3rd position
     * is the id of the feed to update
     */
    const id = key[2] as string

    if (
      feedsToUpdate.tasks.includes(id) ||
      feedsToUpdate.projects.includes(id)
    ) {
      invalidatedQueryKeys.push(key)
    }
  })

  notifyManager.batch(() => {
    invalidatedQueryKeys.forEach((key) => {
      ctx.client.invalidateQueries({
        queryKey: key,
      })
    })
  })
}
