import { FieldButton } from '@motion/ui/base'
import { getChunkDurations } from '@motion/ui-logic'

import { ModalFieldButton } from '~/areas/task-project/components'
import { useController } from 'react-hook-form'

import {
  MinChunkDurationDropdown,
  type MinChunkDurationDropdownProps,
  SubItemIndentMarker,
} from '../components'
import { useTaskForm } from '../hooks'

export const MinChunkField = () => {
  const { disabledFields } = useTaskForm()
  const isDisabled = disabledFields.has('minimumDuration')

  return (
    <div className='flex flex-row items-center gap-0.5'>
      <SubItemIndentMarker />

      <ModalFieldButton label='Min chunk' disabled={isDisabled}>
        <ControlledMinChunkDurationDropdown />
      </ModalFieldButton>
    </div>
  )
}

const ControlledMinChunkDurationDropdown = () => {
  const { form, disabledFields } = useTaskForm()

  const { control, watch } = form

  const isDisabled = disabledFields.has('minimumDuration')

  const duration = watch('duration')
  const { field: minChunkDurationField } = useController({
    name: 'minimumDuration',
    control,
  })

  const selectedMinChunkDuration = minChunkDurationField.value
  const chunkDurations = getChunkDurations(duration)

  const onChange: MinChunkDurationDropdownProps['onChange'] = (value) => {
    minChunkDurationField.onChange(value)
  }

  return (
    <MinChunkDurationDropdown
      value={selectedMinChunkDuration}
      onChange={onChange}
      duration={duration}
    >
      <FieldButton variant='muted' size='small' iconOnly disabled={isDisabled}>
        <span className='whitespace-nowrap'>
          {chunkDurations.find(
            (item) => item.value === selectedMinChunkDuration
          )?.label ?? 'No Chunks'}
        </span>
      </FieldButton>
    </MinChunkDurationDropdown>
  )
}
