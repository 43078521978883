import { EyeOffSolid } from '@motion/icons'
import { type TaskSchema } from '@motion/rpc-types'
import { Tag, Tooltip } from '@motion/ui/base'

import { useProject, useWorkspaceById } from '~/global/hooks'
import { useTaskModalUrl } from '~/global/navigation'
import { type ReactNode, useCallback } from 'react'
import { useNavigate } from 'react-router'

import { FullPathLabel } from '../inline-fields/full-path-label'
import { useActionItemContext } from '../provider'

export function ActionItemExistingTaskTag() {
  const { task, initialActionItem } = useActionItemContext()
  const buildTaskModalUrl = useTaskModalUrl()
  const navigate = useNavigate()

  const getToolTipContent = useCallback(() => {
    if (initialActionItem.potentialDuplicateTaskId == null) {
      return null
    }

    // If the task is null, it most probably means the user doesn't have access to the task
    if (task == null) {
      return <ExistingTaskTagTooltipNoAccessContent />
    }

    return <ExistingTaskTagTooltipWithTaskContent task={task} />
  }, [task, initialActionItem.potentialDuplicateTaskId])

  if (initialActionItem.potentialDuplicateTaskId == null) {
    return null
  }

  return (
    <Tooltip renderContent={getToolTipContent} noPadding>
      <Tag
        color='yellow'
        size='small'
        variant='subtle'
        onClick={(e) => {
          if (task == null) {
            return
          }

          e.preventDefault()
          e.stopPropagation()

          navigate(buildTaskModalUrl({ task: task.id }))
        }}
      >
        Existing task
      </Tag>
    </Tooltip>
  )
}

type ExistingTaskTagTooltipWithTaskContentProps = {
  task: TaskSchema
}

function ExistingTaskTagTooltipWithTaskContent({
  task,
}: ExistingTaskTagTooltipWithTaskContentProps) {
  const workspace = useWorkspaceById(task.workspaceId)
  const project = useProject(task.projectId)

  return (
    <ExistingTaskTagTooltipContent>
      <div className='flex flex-col items-start self-stretch gap-2 px-2 py-1'>
        <div>
          <FullPathLabel workspace={workspace} project={project} />
        </div>
        <span className='text-left text-xs'>{task.name}</span>
      </div>
    </ExistingTaskTagTooltipContent>
  )
}

function ExistingTaskTagTooltipNoAccessContent() {
  return (
    <ExistingTaskTagTooltipContent>
      <div className='flex items-start self-stretch gap-2 px-2 py-1'>
        <EyeOffSolid className='size-4 text-semantic-neutral-icon-default' />
        <span className='text-left text-xs'>
          You can&apos;t see this task because it&apos;s in a workspace you
          aren&apos;t a part of
        </span>
      </div>
    </ExistingTaskTagTooltipContent>
  )
}

type ExistingTaskTagTooltipContentProps = {
  children: ReactNode
}

function ExistingTaskTagTooltipContent({
  children,
}: ExistingTaskTagTooltipContentProps) {
  return (
    <div className='flex flex-col'>
      <div className='flex items-start self-stretch py-2 px-1.5 bg-semantic-warning-bg-strong-default text-semantic-warning-text-strong text-2xs'>
        We found an existing task for this action item
      </div>
      {children}
    </div>
  )
}
