import {
  flattenTreeItems,
  SortableTreeview,
  type WorkspacesTreeviewItem,
} from '~/areas/treeviews/components'
import { type FilteredTreeviewItem } from '~/areas/treeviews/types'
import { useMemo } from 'react'

import { ConnectedWorkspacesTreeviewItem } from '../workspaces-treeview/components'

type SharedTreeviewProps = {
  items: FilteredTreeviewItem<WorkspacesTreeviewItem>[]
}

export const SharedTreeview = ({ items }: SharedTreeviewProps) => {
  const flattenedItems = useMemo(
    () =>
      flattenTreeItems(items, {
        injectPlaceholderInEmptyContainers: false,
      }),
    [items]
  )

  return (
    <SortableTreeview
      items={flattenedItems}
      renderItem={({ item, projection }) => (
        <ConnectedWorkspacesTreeviewItem
          item={item}
          level={item.level}
          projection={projection}
          disableDrag
        />
      )}
      insertGapBetweenItems
    />
  )
}
