import { XSolid } from '@motion/icons'
import { SUPPORTED_AI_MIME_TYPES } from '@motion/shared/files'
import { classed } from '@motion/theme'
import { Button, IconButton, LoadingSpinner, Tooltip } from '@motion/ui/base'
import { type UploadedFileSchema } from '@motion/zod/client'

import { AttachmentUploadShell } from '~/areas/attachments'
import { useFileUploadState } from '~/areas/attachments/contexts'
import { useMyTasksWorkspace } from '~/global/hooks'
import { useFileById } from '~/global/rpc/v2/files'
import { useController } from 'react-hook-form'

import { useProjectAIForm } from '../hooks'

const MAX_ATTACHMENTS = 5

export function Attachments() {
  const form = useProjectAIForm()
  const uploadedFiles = useController({
    control: form.control,
    name: 'uploadedFileIds',
  })

  const workspace = useMyTasksWorkspace()

  const { activeFileUploads } = useFileUploadState()
  const filteredActiveFileUploads = activeFileUploads.filter(
    (activeFileUpload) =>
      activeFileUpload.targetId === null && activeFileUpload.targetType === null
  )

  const currentFileLimit =
    MAX_ATTACHMENTS -
    uploadedFiles.field.value.length -
    filteredActiveFileUploads.length

  const handleUploadSettled = (uploadedFile: UploadedFileSchema) => {
    uploadedFiles.field.onChange([
      ...uploadedFiles.field.value,
      uploadedFile.id,
    ])
  }

  const handleDelete = (attachmentId: UploadedFileSchema['id']) => {
    uploadedFiles.field.onChange(
      uploadedFiles.field.value.filter((id) => id !== attachmentId)
    )
  }

  return (
    <>
      {(uploadedFiles.field.value.length > 0 ||
        filteredActiveFileUploads.length > 0) && (
        <div className='flex flex-wrap gap-1'>
          {uploadedFiles.field.value.map((fileId) => (
            <UploadedFileItem
              key={fileId}
              fileId={fileId}
              onDelete={handleDelete}
            />
          ))}
          {filteredActiveFileUploads.map((upload) => (
            <ActiveUploadFileItem
              key={upload.tempId}
              fileName={upload.fileName}
              progress={upload.progress}
            />
          ))}
        </div>
      )}

      <AttachmentUploadShell
        workspaceId={workspace?.id}
        onUploadSettled={handleUploadSettled}
        supportedMimeTypes={SUPPORTED_AI_MIME_TYPES}
        fileLimit={currentFileLimit}
      >
        {({ trigger }) => (
          <Button
            onClick={trigger}
            variant='outlined'
            sentiment='neutral'
            aria-label='Upload attachment'
            disabled={currentFileLimit <= 0}
            size='small'
          >
            Upload
          </Button>
        )}
      </AttachmentUploadShell>
    </>
  )
}

type ActiveUploadFileItemProps = {
  fileName: string
  progress: number
}

function ActiveUploadFileItem({
  fileName,
  progress,
}: ActiveUploadFileItemProps) {
  return (
    <FileItem>
      <LoadingSpinner size={12} />
      <FileItemName>{fileName}</FileItemName>
      <span className='text-field-text-placeholder text-sm px-0.5'>
        {progress}%
      </span>
    </FileItem>
  )
}

type UploadedFileItemProps = {
  fileId: string
  onDelete: (fileId: UploadedFileSchema['id']) => void
}

function UploadedFileItem({ fileId, onDelete }: UploadedFileItemProps) {
  const file = useFileById({
    id: fileId,
  })

  if (file.isError || !file.data) {
    return null
  }

  const { fileName, id } = file.data

  return (
    <FileItem>
      <FileItemName>{fileName}</FileItemName>
      <Tooltip asChild content='Delete file'>
        <IconButton
          size='xsmall'
          variant='muted'
          sentiment='neutral'
          icon={XSolid}
          onClick={() => onDelete(id)}
        />
      </Tooltip>
    </FileItem>
  )
}

export const FileItem = classed('div', {
  base: `
    flex items-center gap-0.5
    px-1 py-0.5 max-w-xs overflow-hidden
    bg-semantic-neutral-surface-overlay-bg-subtlest
    border border-semantic-neutral-border-default
    rounded-[4px]
  `,
})

export const FileItemName = classed('span', {
  base: `
    px-0.5 truncate
    text-sm text-field-text-default
  `,
})
