import { Text } from '@motion/ui/base'

export const Section = ({
  title,
  children,
  action,
}: {
  title: string
  children: React.ReactNode
  action?: React.ReactNode
}) => {
  return (
    <div className='flex justify-between items-center'>
      <div className='flex flex-col gap-3'>
        <Text size='sm' weight='semibold'>
          {title}
        </Text>
        {children}
      </div>
      {action}
    </div>
  )
}
