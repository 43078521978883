import { ChevronDownOutline } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { ActionDropdown, Button } from '@motion/ui/base'

import { useI18N } from '~/global/contexts'

import { type CommonButtonProps } from './type'

import {
  useTasksLoadingState,
  useUpdateParentTasksWarnings,
  useUpdateTasksWarnings,
} from '../hooks'

export const IgnoreWarningButton = ({
  tasks,
  type,
  showAllText,
}: CommonButtonProps) => {
  const [isLoading, setIsLoading] = useTasksLoadingState(tasks)

  const allRecurring = tasks.every((task) => task.type === 'RECURRING_INSTANCE')
  const { pluralize } = useI18N()

  const updateTasksWarnings = useUpdateTasksWarnings({
    tasks,
    type,
    setIsLoading,
  })

  const updateParentTasksWarnings = useUpdateParentTasksWarnings({
    tasks,
    type,
    setIsLoading,
  })

  if (allRecurring) {
    const numRecurringParentTasks = Array.from(
      new Set(tasks.map((task) => task.parentRecurringTaskId))
    )

    return (
      <ActionDropdown
        placement='bottom-end'
        items={[
          {
            content:
              tasks.length === 1
                ? 'Ignore warning for only this instance'
                : templateStr(
                    'Ignore warning for {{numInstances}} instances that are past due',
                    { numInstances: tasks.length }
                  ),
            onAction: updateTasksWarnings,
          },
          {
            content: templateStr(
              'Ignore warning for all future instances of {{taskDescription}}',
              {
                taskDescription: pluralize(
                  numRecurringParentTasks.length,
                  'this task',
                  'these tasks'
                ),
              }
            ),
            onAction: updateParentTasksWarnings,
          },
        ]}
      >
        <Button
          disabled={isLoading}
          variant='outlined'
          sentiment='neutral'
          size='small'
        >
          {showAllText && tasks.length > 1
            ? 'Ignore warning for all'
            : 'Ignore warning'}
          <ChevronDownOutline width={16} height={16} />
        </Button>
      </ActionDropdown>
    )
  }

  return (
    <Button
      disabled={isLoading}
      variant='outlined'
      sentiment='neutral'
      size='small'
      onClick={updateTasksWarnings}
    >
      {showAllText && tasks.length > 1
        ? 'Ignore warning for all'
        : 'Ignore warning'}
    </Button>
  )
}
