import { QuestionMarkCircleSolid } from '@motion/icons'
import { getSyncRange, isEventInSyncRange } from '@motion/shared/common'
import { IconButton, LoadingSpinner, Tooltip } from '@motion/ui/base'
import {
  isNotetakerSectionHidden,
  isNotetakerSupportedConferenceLink,
} from '@motion/ui-logic'
import { useFeatureLimit } from '@motion/web-billing'

import { type ReactNode, useMemo } from 'react'

import {
  ControlledEventNotetakerEmailRecapField,
  ControlledNotetakerToggleField,
} from './fields'
import { NotetakerActionMenu } from './notetaker-action-menu'
import { NotetakerFolderField } from './notetaker-folder-field'
import { NotetakerUpsellButton } from './notetaker-upsell-button'
import { ConnectedSendBotNowButton } from './send-bot-now-button'

import { useEventModalState } from '../../contexts'
import { useEventForm, useIsMainCalendar } from '../../hooks'

export function EventNotetakerSection() {
  const { isLoading } = useEventModalState()

  return (
    <NotetakerVisibilityWrapper>
      <Section
        isLoading={isLoading}
        renderHeader={() => (
          <div className='flex items-center gap-1'>
            <ConnectedSendBotNowButton />
            <NotetakerActionMenu />
          </div>
        )}
      >
        <NotetakerUpsellButton />
        <ControlledNotetakerToggleField />
        <ControlledEventNotetakerEmailRecapField />
        <NotetakerFolderField />
      </Section>
    </NotetakerVisibilityWrapper>
  )
}

type NotetakerVisibilityWrapperProps = {
  children: ReactNode
}

function NotetakerVisibilityWrapper({
  children,
}: NotetakerVisibilityWrapperProps) {
  const { recurringMeetingInsights, meetingInsights, initialEvent } =
    useEventModalState()
  const { form } = useEventForm()

  const conferenceType = form.watch('conferenceType')
  const isMainCalendar = useIsMainCalendar()
  const showNotetakerUpsell = useFeatureLimit('aiNotetakerLimit') === 0

  const isInSyncRange = useMemo(() => {
    // If there is no initial event, then user is creating a new event.
    if (!initialEvent) return true

    return isEventInSyncRange(initialEvent, getSyncRange())
  }, [initialEvent])

  // If the user has no notetaker limit, we need to show the upsell section manually because meeting insights won't be created.
  if (showNotetakerUpsell) {
    return (
      <DisabledSection>
        <NotetakerUpsellButton />
      </DisabledSection>
    )
  }

  if (!isNotetakerSupportedConferenceLink(conferenceType)) {
    if (
      conferenceType === 'skypeForBusiness' ||
      conferenceType === 'skypeForConsumer'
    )
      return (
        <DisabledSection>
          The AI Notetaker is not available for Skype meeting links.
        </DisabledSection>
      )
    return null
  }

  // If the event is not in the sync range, and the meeting insights are not present,
  // we should show a disabled section.
  if (!isInSyncRange && meetingInsights == null) {
    return (
      <DisabledSection>
        The AI Notetaker is only available for events scheduled within the next
        ten days.
      </DisabledSection>
    )
  }

  // If the event is not on the main calendar, we should show a disabled section.
  if (!isMainCalendar) {
    return (
      <DisabledSection>
        The AI Notetaker is available only for events scheduled on your main
        calendar.
      </DisabledSection>
    )
  }

  if (
    isNotetakerSectionHidden(
      meetingInsights,
      initialEvent,
      recurringMeetingInsights
    )
  ) {
    return null
  }

  return children
}

type SectionProps = {
  /**
   * If true, the section will not render the children and instead show a loading spinner
   */
  isLoading?: boolean
  renderHeader?: () => ReactNode
  children: ReactNode
}

function Section({ renderHeader, children, isLoading }: SectionProps) {
  return (
    <>
      <div className='flex flex-col gap-2'>
        <div className='flex items-center justify-between'>
          <strong className='text-2xs font-semibold text-semantic-neutral-text-default flex items-center gap-1'>
            AI Notetaker
            <Tooltip
              content='Our
                  meeting bot will take notes for you during the meeting. Once meeting ends, you can view the notes & recording here.'
            >
              <IconButton
                icon={QuestionMarkCircleSolid}
                variant='muted'
                size='xsmall'
                sentiment='neutral'
              />
            </Tooltip>
          </strong>

          {!isLoading && renderHeader?.()}
        </div>

        {!isLoading ? <>{children}</> : <LoadingSpinner size={12} />}
      </div>

      <div className='my-3 border-b border-semantic-neutral-border-subtle' />
    </>
  )
}

const DisabledSection = ({ children }: { children: ReactNode }) => {
  return (
    <Section>
      <p className='text-2xs text-semantic-neutral-text-subtle'>{children}</p>
    </Section>
  )
}
