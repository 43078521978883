import {
  type FolderSchema,
  type NoteSchema,
  type ProjectSchema,
} from '@motion/rpc-types'
import { type VirtualizedTreeNode } from '@motion/ui/base'
import { isOneOf } from '@motion/utils/array'

import { type LeafNodeType } from './hooks'

export const isValidNodeType = (type?: string): type is LeafNodeType => {
  return isOneOf(type, ['PROJECT', 'FOLDER', 'WORKSPACE', 'NOTE'])
}

export const getNodeType = (
  node: VirtualizedTreeNode,
  folders: Record<string, FolderSchema>,
  projects: Record<string, ProjectSchema>,
  notes: Record<string, NoteSchema>
): LeafNodeType | 'NONE' => {
  if (isValidNodeType(node.type)) return node.type

  const folder = folders[node.id]
  const project = projects[node.id]
  const note = notes[node.id]

  if (folder?.type === 'WORKSPACE') return 'WORKSPACE'
  if (folder?.type === 'USER') return 'FOLDER'
  if (project) return 'PROJECT'
  if (note) return 'NOTE'

  return 'NONE'
}
