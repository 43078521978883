import { createStateKey, useSharedState } from '@motion/react-core/shared-state'
import { createUseQuery } from '@motion/rpc'
import { useReplaceModelInCachesFn } from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { DeadlineStatuses } from '@motion/shared/common'
import { type AllAvailableCustomFieldSchema } from '@motion/ui-logic'
import { useHasTreatment } from '@motion/web-common/flags'
import {
  type DeadlineStatusSchema,
  type FolderSchema,
  type FolderWithItemsSchema,
  type LabelSchema,
  type LegacyProjectTemplateSchema,
  PriorityLevelSchema,
  type ProjectDefinitionSchema,
  type ProjectSchema,
  type StageDefinitionSchema,
  type StatusSchema,
  type UserInfoSchema,
  type VersionedViewV2,
  type WorkspaceMemberSchema,
  type WorkspaceSchema,
  type WorkspacesV2GetRequestSchema,
} from '@motion/zod/client'
import { WorkspaceQueryIncludeSchema } from '@motion/zod/client'

import { LoadingPage } from '~/components/LoadingPage'
import { type ReactNode, useEffect } from 'react'

import { useFolders } from '../../rpc/folders'
import { useAllPageViewSettings, useGetViews } from '../../rpc/v2'
import { cacheFromRecord, createEntityCache, type EntityCache } from '../utils'

const EMPTY = { all: [], byId: {} }
const EMPTY_PAGE_CONTEXT: AppWorkspaceDataContext = {
  loaded: false,
  workspaces: EMPTY,
  projects: EMPTY,
  statuses: EMPTY,
  users: EMPTY,
  members: EMPTY,
  labels: EMPTY,
  customFields: EMPTY,
  priorities: EMPTY,
  deadlineStatuses: EMPTY,
  views: EMPTY,
  projectDefinitions: EMPTY,
  stageDefinitions: EMPTY,
  stageDefinitionsV2: EMPTY,
  legacyProjectTemplates: EMPTY,
  folders: EMPTY,
  systemFolders: EMPTY,
}

export type WorkspaceMember = WorkspaceMemberSchema & {
  workspaceId: WorkspaceSchema['id']
  user: UserInfoSchema
}

export type WorkspaceStageDefinition = StageDefinitionSchema & {
  workspaceId: WorkspaceSchema['id']
  projectDefinitionId: ProjectDefinitionSchema['id']
}

export type AppWorkspaceDataContext = {
  loaded: boolean
  workspaces: EntityCache<WorkspaceSchema>
  projects: EntityCache<ProjectSchema>
  statuses: EntityCache<StatusSchema>
  users: EntityCache<UserInfoSchema>
  members: EntityCache<WorkspaceMember>
  labels: EntityCache<LabelSchema>
  customFields: EntityCache<AllAvailableCustomFieldSchema>
  priorities: EntityCache<PriorityLevelSchema>
  deadlineStatuses: EntityCache<DeadlineStatusSchema>
  views: EntityCache<VersionedViewV2>
  projectDefinitions: EntityCache<ProjectDefinitionSchema>
  stageDefinitions: EntityCache<WorkspaceStageDefinition>
  stageDefinitionsV2: EntityCache<StageDefinitionSchema>
  legacyProjectTemplates: EntityCache<LegacyProjectTemplateSchema>
  folders: EntityCache<FolderSchema>
  systemFolders: EntityCache<FolderWithItemsSchema>
}

export type AppWorkspaceDataKeys = {
  [K in keyof AppWorkspaceDataContext]: AppWorkspaceDataContext[K] extends EntityCache<any>
    ? K
    : never
}[keyof AppWorkspaceDataContext]

export const AppWorkspaceContext = createStateKey<AppWorkspaceDataContext>(
  'app-workspace',
  { defaultValue: EMPTY_PAGE_CONTEXT }
)

const useGetWorkspaceContext = createUseQuery(API.workspacesV2.getWorkspaces, {
  notifyOnChangeProps: ['data', 'dataUpdatedAt'],
})

const GET_CONTEXT_ARGS: WorkspacesV2GetRequestSchema = {
  include: WorkspaceQueryIncludeSchema,
}

type AppWorkspaceContextProviderProps = {
  children: ReactNode
}
export const AppWorkspaceContextProvider = (
  props: AppWorkspaceContextProviderProps
) => {
  const hasNotesInWebapp = useHasTreatment('notes-in-webapp')

  const replaceModels = useReplaceModelInCachesFn()

  const result = useGetWorkspaceContext(GET_CONTEXT_ARGS)
  const [ctx, setCtx] = useSharedState(AppWorkspaceContext)

  useAllPageViewSettings()
  const views = useGetViews()
  const folders = useFolders()

  useEffect(() => {
    const data = result.data
    if (!data || !views.data || !folders.data) return

    const models = data.models

    // TODO: We should change this in the backend eventually, once we're sure.
    models.workspaces = hasNotesInWebapp
      ? Object.fromEntries(
          Object.entries(models.workspaces).map(([id, w]) => [
            id,
            {
              ...w,
              name:
                w.name === 'My Tasks (Private)'
                  ? 'My Private Workspace'
                  : w.name,
            },
          ])
        )
      : models.workspaces

    replaceModels(data.models)
    replaceModels(views.data.models)

    const members = Object.values(models.workspaces).flatMap((w) =>
      w.members.map((m) => ({
        ...m,
        workspaceId: w.id,
        get user() {
          return models.users[m.userId]
        },
      }))
    )

    const legacyStageDefinitions: WorkspaceStageDefinition[] = Object.values(
      models.projectDefinitions
    ).flatMap((p) =>
      p.stages.map((s) => ({
        ...s,
        workspaceId: p.workspaceId,
        projectDefinitionId: p.id,
      }))
    )

    setCtx({
      loaded: true,
      labels: cacheFromRecord(models.labels),
      projects: cacheFromRecord(models.projects),
      statuses: cacheFromRecord(models.statuses),
      users: cacheFromRecord(models.users),
      members: createEntityCache(members),
      workspaces: cacheFromRecord(models.workspaces),
      customFields: cacheFromRecord(
        // TODO remove cast when all custom fields implemented
        models.customFields as Record<string, AllAvailableCustomFieldSchema>
      ),
      deadlineStatuses: createEntityCache(DeadlineStatuses, (x) => x),
      priorities: createEntityCache(PriorityLevelSchema, (x) => x),
      // @ts-expect-error - removing in followup
      views: cacheFromRecord(views.data.models.views),
      projectDefinitions: cacheFromRecord(models.projectDefinitions),
      stageDefinitions: createEntityCache(legacyStageDefinitions),
      stageDefinitionsV2: cacheFromRecord(models.stageDefinitions),
      legacyProjectTemplates: cacheFromRecord(models.legacyProjectTemplates),
      folders: cacheFromRecord(folders.data.models.folders),
      systemFolders: cacheFromRecord(folders.data.models.systemFolders),
    })
  }, [
    replaceModels,
    result.data,
    setCtx,
    views.data,
    folders.data,
    // NOTE: Even though `result.dataUpdatedAt` isn't used, it is needed to properly refresh the context
    result.dataUpdatedAt,
    hasNotesInWebapp,
  ])

  if (!ctx.loaded) return <LoadingPage id='app-workspace-context' />
  return <>{props.children}</>
}
