import { ActionItemBlockNode, SelectableNodeWrapper } from '@motion/notes'

import { ConnectedActionItemBlock } from './components/connected-action-item-block'

ActionItemBlockNode.prototype.render = (
  nodeKey: string,
  actionItemId: string
) => {
  return (
    <SelectableNodeWrapper nodeKey={nodeKey}>
      <ConnectedActionItemBlock actionItemId={actionItemId} />
    </SelectableNodeWrapper>
  )
}

export { ActionItemBlockNode as CustomActionItemBlockNode }
