import type { EmailAccount } from '@motion/rpc/types'
import { type CalendarSchema } from '@motion/zod/client'

import api from '~/chromeApi/chromeApiBackground'
import { useBookingConferenceOptions } from '~/components/Booking/components/template-form/hooks/use-booking-conference-options'
import { useBookingTemplate } from '~/components/Booking/components/template-form/hooks/use-booking-template'
import {
  selectZoomUpdated,
  setZoomUpdated,
} from '~/state/calendar/calendarSlice'
import {
  selectEmailAccounts,
  selectMainEmailAccount,
} from '~/state/email-accounts/email-accounts-slice'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import { showPromptModal } from '~/state/modalsSlice'
import { selectUser } from '~/state/userSlice'
import { useCallback, useEffect, useState } from 'react'

import { useBookingTemplateIdBySlug } from './use-booking-template-id-by-slug'

import { useAllCalendars, useMainCalendar } from '../../hooks'

type UseBookingTemplateBySlugProps = {
  slug: string
  shouldCreateDefaultTemplate?: boolean
  shouldInitSettings?: () => boolean
  scrollContainerRef?: React.RefObject<HTMLElement | null>
}

export const useBookingTemplateBySlug = ({
  slug,
  shouldCreateDefaultTemplate = false,
  shouldInitSettings,
  scrollContainerRef,
}: UseBookingTemplateBySlugProps) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const editingTemplateId = useBookingTemplateIdBySlug(slug) ?? ''
  const zoomUpdated = useAppSelector(selectZoomUpdated)
  const { calendars } = useAllCalendars()
  const { mainCalendar } = useMainCalendar()
  const emailAccounts = useAppSelector(selectEmailAccounts)
  const [hostCalendar, setHostCalendar] = useState<
    CalendarSchema | undefined | null
  >(mainCalendar)
  const mainEmailAccount = useAppSelector(selectMainEmailAccount)
  const [hostEmailAccount, setHostEmailAccount] = useState<
    EmailAccount | undefined
  >(mainEmailAccount)

  const getCustomLocation = useCallback(async () => {
    return (await dispatch(
      showPromptModal({ type: 'customLocation' })
    ).unwrap()) as string
  }, [dispatch])

  const getPhoneNumber = useCallback(async () => {
    return (await dispatch(
      showPromptModal({ type: 'phoneNumber' })
    ).unwrap()) as string
  }, [dispatch])

  const conferenceOptions = useBookingConferenceOptions({
    calendars,
    editingTemplateId,
    emailAccounts,
    getCustomLocation,
    getPhoneNumber,
    hostCalendar,
    hostEmailAccount,
  })

  const {
    conferenceType,
    conferenceTypesLoaded,
    getConferenceType,
    setConferenceType,
  } = conferenceOptions

  const template = useBookingTemplate({
    calendars,
    conferenceType,
    conferenceTypesLoaded,
    editingTemplateId,
    emailAccounts,
    getConferenceType,
    hostCalendar,
    hostEmailAccount,
    modalVisibleHandler: () => void 0,
    scrollContainerRef,
    setConferenceType,
    setHostCalendar,
    setHostEmailAccount,
    teamInviteModalHandler: () => void 0,
    user,
    shouldInitSettings,
    shouldCreateDefaultTemplate,
  })

  useEffect(() => {
    if (zoomUpdated) {
      setConferenceType('zoom')
      dispatch(setZoomUpdated(false))
    }
  }, [zoomUpdated, dispatch, setConferenceType])

  // TODO need to update
  useEffect(() => {
    const fetchTeamContacts = async () => {
      const { ignoreInviteTeammates } = await api.storage.local.get(
        'ignoreInviteTeammates'
      )

      if (ignoreInviteTeammates) {
        template.setters.setIgnoreInviteTeammates(ignoreInviteTeammates)
      }
    }

    void fetchTeamContacts()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    template,
    conference: conferenceOptions,
    hostCalendar,
  }
}
