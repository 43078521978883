import {
  Button,
  type Group,
  SearchableGroupedCheckboxList,
} from '@motion/ui/base'

import { type ReactNode } from 'react'

type GroupedItem<T> = {
  all: T[]
  groups: Group<T>[]
}

type GroupedItemDropdownProps<T extends { workspaceId: string }> = {
  items: GroupedItem<T>
  selected: T[]
  onSelect(values: T[] | null): void
  close(): void

  onBlur?: () => void
  openOnMount?: boolean

  placeholder: string
  renderItem(item: T): NonNullable<ReactNode>
  renderHeader(group: Group<T>): NonNullable<ReactNode>
}

export const GroupedItemDropdown = <T extends { workspaceId: string }>(
  props: GroupedItemDropdownProps<T>
) => {
  return (
    <div>
      <SearchableGroupedCheckboxList
        groups={props.items.groups}
        selected={props.selected}
        onSelected={props.onSelect}
        placeholder={props.placeholder}
        renderItem={({ item }) => props.renderItem(item)}
        renderHeader={({ group }) => props.renderHeader(group)}
      />
      <div className='border-t border-dropdown-border p-2 flex justify-between'>
        <Button
          onClick={() => {
            props.onSelect(null)
            props.close()
          }}
          variant='outlined'
          sentiment='neutral'
          size='small'
        >
          Clear
        </Button>
        <Button
          onClick={() => {
            props.onSelect(props.items.all)
          }}
          variant='outlined'
          sentiment='neutral'
          size='small'
        >
          Select all
        </Button>
      </div>
    </div>
  )
}
