import { NO_CHUNK_DURATION } from '@motion/ui-logic'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { showRecalculatingTasksToast } from '~/areas/tasks/utils'
import { useReadTaskFn } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useTaskUpdater } from '../updaters'

interface Options {
  source?: string
}

export const useDoTaskASAP = () => {
  const modalApi = useModalApi()
  const updateTask = useTaskUpdater()
  const readTaskById = useReadTaskFn()

  return useCallback(
    async (taskId: string, options: Options = READONLY_EMPTY_OBJECT) => {
      const { source = 'none' } = options

      try {
        const task = await readTaskById(taskId)

        if (!task) {
          throw new Error('Task not found')
        }
        if (
          task.type === 'RECURRING_INSTANCE' ||
          task.type === 'RECURRING_TASK'
        ) {
          throw new Error('Cannot make recurring tasks ASAP')
        }

        const response = await modalApi.prompt('do-task-asap', {
          task,
        })
        if (response === ModalDismissed) return

        const { isChunkedTask, minimumDuration } = response

        recordAnalyticsEvent('TASK_MAKE_ASAP', {
          location: source,
        })

        await updateTask(task, {
          minimumDuration:
            isChunkedTask && minimumDuration
              ? minimumDuration
              : NO_CHUNK_DURATION,
          priorityLevel: 'ASAP',
        })

        showRecalculatingTasksToast()
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            position: 'useDoTaskASAP',
          },
        })
        showErrorToast(e)
      }
    },
    [modalApi, readTaskById, updateTask]
  )
}
