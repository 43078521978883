import { priorityLevels } from '@motion/rpc/types'

import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { PriorityField } from '~/areas/task-project/fields'
import { useController } from 'react-hook-form'

export const ControlledPriorityField = () => {
  const { form } = useFlowTemplateForm()

  const { control } = form

  const { field } = useController({
    name: 'priorityLevel',
    control,
  })

  return (
    <PriorityField
      list={priorityLevels}
      value={field.value}
      onChange={(item) => {
        field.onChange(item)
      }}
    />
  )
}
