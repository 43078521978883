import { FormModal } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { type TextVariable } from '@motion/ui-logic/pm/project'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { showErrorToast } from '~/global/toasts'
import { useState } from 'react'

import { findFirstDuplicateName } from '../../utils'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'add-flow-template-text-variable-modal': PromptCallbacks<{
      name: string
    }> & {
      textVariables: TextVariable[]
    }
  }
}

type AddFlowTemplateTextVariableModalProps =
  ModalTriggerComponentProps<'add-flow-template-text-variable-modal'>

export const AddFlowTemplateTextVariableModal = ({
  close,
  textVariables,
}: AddFlowTemplateTextVariableModalProps) => {
  const [rawTextVariableName, setRawTextVariableName] = useState('')

  const name = rawTextVariableName.trim()
  const disabled = name === ''

  function handleSubmit() {
    const duplicateVar = findFirstDuplicateName([...textVariables, { name }])

    if (duplicateVar) {
      showErrorToast(`More than one text variable named "${duplicateVar.name}"`)
      return
    }

    close({ name })
  }

  return (
    <FormModal
      title='Add text variable'
      visible
      onClose={close}
      submitAction={{
        onAction: handleSubmit,
        disabled,
        text: 'Add text variable',
      }}
    >
      <div className='flex flex-row items-center gap-3'>
        <TextField
          value={rawTextVariableName}
          onChange={setRawTextVariableName}
          autoFocus
          fullWidth
          placeholder='Enter text'
        />
      </div>
    </FormModal>
  )
}
