import {
  DotsVerticalSolid,
  PencilSolid,
  ReplySolid,
  TrashSolid,
} from '@motion/icons'
import { isNoneId } from '@motion/shared/identifiers'
import { ActionDropdown, ButtonGroup, IconButton } from '@motion/ui/base'
import { RTEStyles } from '@motion/ui/rte'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useModalApi } from '@motion/web-common/modals'
import { type CommentSchema } from '@motion/zod/client'

import { UserBadge } from '~/global/components/badges'
import { useWorkspaceMember } from '~/global/hooks'
import { useDeleteComment, useEditComment } from '~/global/rpc/v2/comments'
import { useCallback } from 'react'
import { twMerge } from 'tailwind-merge'

import { CommentBox } from './comment-box'
import { CommentIconButton } from './comment-icon-button'
import { Reactions } from './reactions'
import { parseCommentHtml } from './utils'

import { useActivityFeedContext } from '../../providers'
import { ActivityTimestamp } from '../activity-timestamp'

interface CommentProps {
  comment: CommentSchema
}

export const CommentItem = ({ comment }: CommentProps) => {
  const {
    workspaceId,
    editingCommentId,
    setEditingCommentId,
    triggerReplyAction,
  } = useActivityFeedContext()

  const modalApi = useModalApi()
  const { uid: userId } = useAuthenticatedUser()

  const { mutate: editComment } = useEditComment()
  const { mutate: deleteComment } = useDeleteComment()

  const author = useWorkspaceMember(workspaceId, comment.createdByUserId)

  const isNoLongerInWorkspace = author?.user.deleted
  // There is a chance that the comment is still a temporary comment
  const canModifyComment =
    userId === comment.createdByUserId && !isNoneId(comment.id)

  const canReplyComment =
    userId !== comment.createdByUserId && !isNoneId(comment.id)

  const handleCommentEdit = useCallback(
    (newHtml: Partial<CommentSchema>) => {
      setEditingCommentId(null)
      if (newHtml?.body) {
        editComment({
          commentId: comment.id,
          bodyHtml: newHtml.body,
          targetId: comment.targetId,
        })
      }
    },
    [comment.id, comment.targetId, editComment, setEditingCommentId]
  )

  const handleCommentDelete = () => {
    void modalApi.prompt('confirm', {
      analytics: {
        name: 'delete-comment',
      },
      title: 'Are you sure you want to delete this comment?',
      destructive: true,
      confirmButtonText: 'Delete comment',
      onValue: (shouldDelete) => {
        if (shouldDelete) {
          void deleteComment({
            commentId: comment.id,
            targetId: comment.targetId,
          })
        }
      },
    })
  }

  const handleReply = () => {
    if (!author) return

    triggerReplyAction({
      userId: comment.createdByUserId,
      label: author.user.name,
    })
  }

  if (editingCommentId === comment.id && canModifyComment && author) {
    return (
      <CommentBox
        author={author}
        onSubmit={handleCommentEdit}
        editorText={comment.body}
        onCancel={() => setEditingCommentId(null)}
      />
    )
  }

  return (
    <div className='comment group relative'>
      <div className='flex flex-col gap-2 bg-semantic-neutral-surface-raised-bg-subtle text-semantic-neutral-text-default min-w-0 grow rounded p-2'>
        <div className='flex items-center whitespace-nowrap gap-2 text-2xs'>
          <UserBadge size='small' value={author?.user} />
          <span
            className={twMerge(
              isNoLongerInWorkspace && 'line-through',
              'font-medium text-ellipsis overflow-hidden'
            )}
          >
            {author?.user?.name}
          </span>
          <span className='text-semantic-neutral-text-subtle'>
            <ActivityTimestamp timestamp={comment.createdTime} />
            {comment.editedTime && ' (edited)'}
          </span>
        </div>
        <div
          className={twMerge(
            // Copy how Rich Text Editor is styled
            'ProseMirror',
            RTEStyles
          )}
        >
          {parseCommentHtml(comment.body)}
        </div>

        <ButtonGroup size='small'>
          <Reactions comment={comment} />

          {canReplyComment && (
            <CommentIconButton onClick={handleReply} type='button'>
              <ReplySolid />
            </CommentIconButton>
          )}
        </ButtonGroup>
      </div>
      {canModifyComment && (
        <div className='absolute right-1 top-1 opacity-0 group-hover:opacity-100'>
          <ActionDropdown
            placement='bottom-end'
            items={[
              {
                prefix: <PencilSolid />,
                content: 'Edit',
                disabled: Boolean(editingCommentId),
                onAction: () => setEditingCommentId(comment.id),
              },
              {
                prefix: <TrashSolid />,
                content: 'Delete',
                destructive: true,
                onAction: handleCommentDelete,
              },
            ]}
          >
            <IconButton
              icon={DotsVerticalSolid}
              variant='outlined'
              sentiment='neutral'
              size='small'
            />
          </ActionDropdown>
        </div>
      )}
    </div>
  )
}
