import { CheckSolid, QuestionMarkSolid, XSolid } from '@motion/icons'
import { UserAvatar } from '@motion/ui/base'
import {
  type AttendeeStatus,
  type CalendarEventAttendeeSchemaV2,
} from '@motion/zod/client'

import {
  type BadgeSize,
  getBadgeSizeInPixels,
} from '~/global/components/badges'
import { forwardRef } from 'react'

export type AttendeeBadgeValue = CalendarEventAttendeeSchemaV2 & {
  profileUrl?: string | null
}

export interface AttendeeBadgeProps {
  size?: BadgeSize
  value: AttendeeBadgeValue
  hideRsvpStatus?: boolean
}

export const AttendeeBadge = forwardRef<HTMLDivElement, AttendeeBadgeProps>(
  function AttendeeBadge({ value, size, hideRsvpStatus = false }, ref) {
    const pixelSize = getBadgeSizeInPixels(size)

    return (
      <UserAvatar
        ref={ref}
        id={value.email}
        name={value.displayName ?? value.email}
        size={pixelSize}
        topRight={!hideRsvpStatus && <RsvpIcon status={value.status} />}
        profileUrl={value.profileUrl}
      />
    )
  }
)

type RsvpIconProps = {
  status?: AttendeeStatus
}
const RsvpIcon = (props: RsvpIconProps) => {
  const { status } = props
  switch (status) {
    case 'accepted':
      return (
        <CheckSolid className='rounded-full bg-semantic-success-bg-strong-default text-semantic-neutral-icon-onDark size-3' />
      )
    case 'declined':
      return (
        <XSolid className='rounded-full bg-semantic-error-bg-strong-default text-semantic-neutral-icon-onDark size-3' />
      )
    default:
      return (
        <QuestionMarkSolid className='rounded-full bg-semantic-neutral-bg-active-default text-semantic-neutral-icon-default size-3' />
      )
  }
}
