import { NOOP_FUNCTION } from '@motion/utils/function'
import { Sentry } from '@motion/web-base/sentry'

import YouTube from 'react-youtube'
import { twMerge } from 'tailwind-merge'

import { muteAndPlay } from './mute-and-play'

interface YoutubeVideoProps {
  videoId: string
  onPlay?: () => void
  onEnd?: () => void
  skipAutoPlay?: boolean
  muteOnStart?: boolean
  hideCc?: boolean
  rounded?: boolean
  loop?: boolean
  startAt?: number
}

export const YoutubeVideo = ({
  videoId,
  onPlay = NOOP_FUNCTION,
  onEnd = NOOP_FUNCTION,
  skipAutoPlay = false,
  muteOnStart = true,
  hideCc = false,
  rounded = false,
  loop = false,
  startAt,
}: YoutubeVideoProps) => {
  return (
    <YouTube
      videoId={videoId}
      opts={{
        playerVars: {
          autoplay: 0,
          cc_load_policy: hideCc ? undefined : 1,
          rel: 0,
        },
      }}
      className='flex aspect-video w-full max-w-full lg:max-w-[1100px]'
      iframeClassName={twMerge(
        'aspect-video w-full h-full',
        rounded && 'rounded-md'
      )}
      onReady={async ({ target: player }) => {
        if (!skipAutoPlay) {
          try {
            void player.playVideo()
          } catch (e) {
            Sentry.captureException(e, { tags: { position: 'youtubePlay' } })
          }
        }

        if (startAt) {
          await player.seekTo(startAt, true)
        }

        if (muteOnStart) {
          await muteAndPlay(player, muteOnStart)
        }

        if (skipAutoPlay) {
          setTimeout(() => {
            void player.pauseVideo()
          }, 500)
        }
      }}
      onPlay={onPlay}
      onEnd={(event) => {
        if (loop) {
          void event.target.seekTo(startAt ?? 0, true)
        }

        onEnd()
      }}
    />
  )
}
