import {
  type PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  NoteDialogContext,
  type NoteDialogContextValue,
} from './note-dialog-context'

import { NoteDialogContainer } from '../../dialogs'

export const NoteDialogProvider = ({ children }: PropsWithChildren) => {
  const [openedDialog, setOpenedDialog] =
    useState<NoteDialogContextValue['openedDialog']>(null)
  const [selectedText, setSelectedText] =
    useState<NoteDialogContextValue['selectedText']>(null)
  const [displayKey, setDisplayKey] = useState<string | null>(null)

  const handleSetOpenedDialog = useCallback<typeof setOpenedDialog>(
    (dialogId) => {
      setOpenedDialog(dialogId)
      setDisplayKey(dialogId == null ? null : [dialogId, Date.now()].join('-'))
    },
    []
  )

  useEffect(() => {
    if (openedDialog == null) {
      setSelectedText(null)
    }
  }, [openedDialog])

  const value = useMemo<NoteDialogContextValue>(
    () => ({
      openedDialog,
      setOpenedDialog: handleSetOpenedDialog,
      selectedText,
      setSelectedText,
    }),
    [handleSetOpenedDialog, openedDialog, selectedText]
  )

  return (
    <NoteDialogContext.Provider value={value}>
      {children}
      <NoteDialogContainer key={displayKey} openedDialog={openedDialog} />
    </NoteDialogContext.Provider>
  )
}
