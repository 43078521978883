import { classed } from '@motion/theme'
import { AutoScheduleToggle, ButtonGroup, Tooltip } from '@motion/ui/base'

interface TaskCardFooterProps {
  autoScheduled: boolean
  onAutoScheduledChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  assigneeId: string | null
  renderEditTaskDefinitionButton: () => React.ReactNode
}

export const TaskCardFooter = ({
  autoScheduled,
  onAutoScheduledChange,
  assigneeId,
  renderEditTaskDefinitionButton,
}: TaskCardFooterProps) => {
  return (
    <StyledTaskCardFooter>
      <div className='[&>label]:gap-1'>
        <Tooltip
          content={
            !assigneeId
              ? 'Auto-schedule is disabled because this task has no assignee. Set an assignee to enable auto-scheduling.'
              : undefined
          }
          asChild
        >
          <AutoScheduleToggle
            disabled={!assigneeId}
            checked={autoScheduled}
            onChange={onAutoScheduledChange}
            size='xsmall'
          >
            <span className='text-2xs'>Auto-scheduled</span>
          </AutoScheduleToggle>
        </Tooltip>
      </div>

      <ButtonGroup size='small'>{renderEditTaskDefinitionButton()}</ButtonGroup>
    </StyledTaskCardFooter>
  )
}

const StyledTaskCardFooter = classed('div', {
  base: `
    bg-semantic-neutral-surface-raised-bg-subtlest
    flex flex-row items-center justify-between
    px-3
    py-2
    rounded-b-[4px]
  `,
})
