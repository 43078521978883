import { isFlowVariableKey } from '@motion/shared/flows'
import { FieldButton } from '@motion/ui/base'

import { useStageTaskCardFields } from '~/areas/flows/contexts'
import { ConnectedUserLabel } from '~/global/components/labels'

import { StageTaskAssigneeDropdown } from '../../../flow-template-modal/components/stages/fields'
import { RoleLabel } from '../../labels/role'

export function AssigneeFields() {
  const {
    workspaceId,
    flowTemplateRoles,
    selectedRole,
    fields: { assignee },
  } = useStageTaskCardFields()
  const [assigneeId, onChangeAssignee] = assignee

  const fieldSize = 'xsmall'

  return (
    <StageTaskAssigneeDropdown
      selectedUserId={assigneeId}
      onChange={onChangeAssignee}
      workspaceId={workspaceId}
      dropdownRoles={flowTemplateRoles}
    >
      <FieldButton variant='muted' fullWidth size={fieldSize}>
        {isFlowVariableKey(assigneeId) && selectedRole != null ? (
          <RoleLabel role={selectedRole} />
        ) : (
          <ConnectedUserLabel userId={assigneeId} size='small' />
        )}
      </FieldButton>
    </StageTaskAssigneeDropdown>
  )
}
