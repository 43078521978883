import {
  ArrowDownSolid,
  ArrowUpSolid,
  PlusSolid,
  TrashSolid,
} from '@motion/icons'
import { cloneDeep } from '@motion/utils/core'
import { NOOP_FUNCTION } from '@motion/utils/function'

import { Combobox, Input, Paragraph, TextButton } from '../../../../../Common'

export type AvailabilityQuestionType = {
  text: string
  choices?: { text: string }[]
  type: 'text-multiple' | 'text-single' | 'multiple-choice'
  required: boolean
}

export type AvailabilityQuestionProps = {
  question: AvailabilityQuestionType
  questionList: AvailabilityQuestionType[]
  setQuestionList: (
    callback: (
      questionList: AvailabilityQuestionType[]
    ) => AvailabilityQuestionType[]
  ) => void
  idx: number
}

export const AvailabilityQuestion = ({
  question,
  questionList = [],
  setQuestionList = NOOP_FUNCTION,
  idx = 0,
}: AvailabilityQuestionProps) => {
  return (
    <div className='dark:bg-dark-900 bg-dark-200 flex flex-col gap-2 rounded p-3'>
      <div className='flex items-center justify-between'>
        <Paragraph>Question {idx + 1}</Paragraph>
        <div className='flex items-center gap-1'>
          {idx > 0 && (
            <TextButton
              icon={ArrowUpSolid}
              onClick={() => {
                setQuestionList((prev) => {
                  const newQuestions = cloneDeep(prev)
                  const tmp = newQuestions[idx]
                  newQuestions[idx] = newQuestions[idx - 1]
                  newQuestions[idx - 1] = tmp
                  return newQuestions
                })
              }}
            />
          )}
          {idx < questionList.length - 1 && (
            <TextButton
              icon={ArrowDownSolid}
              onClick={() => {
                setQuestionList((prev) => {
                  const newQuestions = cloneDeep(prev)
                  const tmp = newQuestions[idx]
                  newQuestions[idx] = newQuestions[idx + 1]
                  newQuestions[idx + 1] = tmp
                  return newQuestions
                })
              }}
            />
          )}
          <TextButton
            onClick={() =>
              setQuestionList((prev) => {
                const newQuestions = cloneDeep(prev)
                newQuestions.splice(idx, 1)
                return newQuestions
              })
            }
            icon={TrashSolid}
          />
        </div>
      </div>
      <div className='flex flex-col gap-1'>
        <Input
          onChange={(e) => {
            const newText = e.target.value
            setQuestionList((prev) => {
              return prev.map((element, index) =>
                index === idx ? { ...element, text: newText } : element
              )
            })
          }}
          placeholder='Enter question'
          value={question.text}
        />
        <div className='flex w-full items-center gap-2'>
          <Combobox
            className='max-w-full'
            autoComplete
            options={[
              { label: 'Multiple lines', value: 'text-multiple' },
              { label: 'Single line', value: 'text-single' },
              { label: 'Multiple choice', value: 'multiple-choice' },
            ]}
            value={question.type}
            onChange={(value) => {
              setQuestionList((prev) => {
                const updatedTypeList = prev.map((element, index) =>
                  index === idx
                    ? {
                        ...element,
                        type: value,
                      }
                    : element
                )
                if (value === 'multiple-choice') {
                  updatedTypeList[idx].choices = [
                    { text: '' },
                    { text: '' },
                    { text: '' },
                  ]
                }
                return updatedTypeList
              })
            }}
          />
          <Combobox
            className='max-w-full'
            value={question.required ? 'required' : 'optional'}
            options={[
              { label: 'Optional', value: 'optional' },
              { label: 'Required', value: 'required' },
            ]}
            onChange={(value) =>
              setQuestionList((prev) => {
                return prev.map((element, index) =>
                  index === idx
                    ? { ...element, required: value === 'required' }
                    : element
                )
              })
            }
          />
        </div>
        {question.type === 'multiple-choice' ? (
          <div className='flex flex-col gap-2'>
            <Paragraph>Choices</Paragraph>
            <div className='flex flex-col gap-2'>
              {(question.choices ?? []).map((choice, questionIdx) => (
                <div
                  className='flex items-center justify-between gap-2'
                  key={`question-${idx}-choice-${questionIdx}`}
                >
                  <Input
                    placeholder={`Choice ${questionIdx + 1}`}
                    value={choice.text}
                    onChange={(e) => {
                      const newText = e.target.value
                      setQuestionList((prev) => {
                        const newQuestions = cloneDeep(prev)
                        const newChoices = [
                          ...(newQuestions[idx].choices || []),
                        ]
                        newChoices[questionIdx] = {
                          text: newText,
                        }
                        newQuestions[idx] = {
                          ...newQuestions[idx],
                          choices: newChoices,
                        }
                        return newQuestions
                      })
                    }}
                  />
                  <TextButton
                    icon={TrashSolid}
                    onClick={() =>
                      setQuestionList((prev) => {
                        const newQuestions = cloneDeep(prev)
                        const newChoices = [
                          ...(newQuestions[idx].choices || []),
                        ]
                        newChoices.splice(questionIdx, 1)
                        newQuestions[idx] = {
                          ...newQuestions[idx],
                          choices: newChoices,
                        }
                        return newQuestions
                      })
                    }
                  />
                </div>
              ))}
            </div>
            <TextButton
              className='mr-auto'
              onClick={() => {
                setQuestionList((prev) => {
                  const newQuestions = cloneDeep(prev)
                  const newChoices = [...(newQuestions[idx].choices || [])]
                  newChoices.push({ text: '' })
                  newQuestions[idx] = {
                    ...newQuestions[idx],
                    choices: newChoices,
                  }
                  return newQuestions
                })
              }}
              icon={PlusSolid}
            >
              Add another choice
            </TextButton>
          </div>
        ) : null}
      </div>
    </div>
  )
}
