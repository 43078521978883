import { QuestionMarkCircleSolid } from '@motion/icons'
import { Button, Text, TextHeader } from '@motion/ui/base'

import { useState } from 'react'

import { TextButton } from '../../../components/Common'
import { HorizontalDivider } from '../../../components/Common/Divider/HorizontalDivider'
import { DetachCalendarModal } from '../../../components/Settings/Modals/DetachCalendarModal/DetachCalendarModal'
import { useToggleIntercom } from '../../../utils/toggleIntercom'

export type TeamTrialExpiredProps = {
  teamSubscribe: () => void
  individualSubscribe: () => void
  signOut: () => void
}

export const TeamTrialExpired = ({
  teamSubscribe,
  individualSubscribe,
  signOut,
}: TeamTrialExpiredProps) => {
  const [showDetachCalendarModal, setShowDetachCalendarModal] = useState(false)
  const toggleIntercom = useToggleIntercom()

  return (
    <div className='dark:bg-dark-1100 bg-light-100 relative flex h-screen w-screen items-center justify-center'>
      <div className='absolute top-12 right-12'>
        <Button onClick={() => toggleIntercom()} sentiment='neutral'>
          <QuestionMarkCircleSolid className='mr-2.5 h-4 w-4' />
          Support
        </Button>
      </div>
      <div className='flex max-w-xl flex-col'>
        <TextHeader className='mb-5'>
          Your team&apos;s trial has ended.
        </TextHeader>
        <Text as='h2' alignment='center' className='mb-2.5'>
          To continue using Motion, please subscribe your team, or ask another
          person/manager on your team to subscribe your team.
        </Text>
        <div className='mb-8 flex gap-x-3'>
          <Button sentiment='primary' onClick={teamSubscribe}>
            Subscribe to team plan
          </Button>
        </div>
        <Text as='h2' alignment='center' className='mb-1'>
          Want to use Motion on your own?
        </Text>
        <div className='flex mb-8'>
          <Button sentiment='neutral' onClick={individualSubscribe}>
            Subscribe to individual plan
          </Button>
        </div>

        <div>
          <TextButton onClick={signOut}>Sign out of Motion</TextButton>
        </div>

        <HorizontalDivider />

        <div className='flex flex-col gap-4 items-start'>
          <Text sentiment='subtle' size='xs'>
            Not using Motion anymore?
          </Text>

          <Button
            size='small'
            sentiment='error'
            onClick={() => setShowDetachCalendarModal(true)}
          >
            Delete Motion Tasks
          </Button>
        </div>
      </div>

      {showDetachCalendarModal && (
        <DetachCalendarModal
          visible={showDetachCalendarModal}
          close={() => {
            setShowDetachCalendarModal(false)
          }}
        />
      )}
    </div>
  )
}
