import { Button, Text } from '@motion/ui/base'

type ProjectScratchCardProps = {
  onClick: () => void
}

export function ProjectScratchCard({ onClick }: ProjectScratchCardProps) {
  return (
    <div className='h-full p-5 flex flex-col items-center justify-between gap-3 border border-semantic-neutral-border-default rounded-lg'>
      <div className='flex flex-col items-center gap-3'>
        <h4 className='flex items-center gap-2 font-bold text-[15px] text-semantic-neutral-text-subtle text-center'>
          Create Project from Scratch
        </h4>

        <Text size='sm' alignment='center'>
          Create a project manually from scratch.
        </Text>
      </div>

      <Button sentiment='neutral' variant='outlined' onClick={onClick}>
        Create project from scratch
      </Button>
    </div>
  )
}
