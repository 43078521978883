import { ChevronLeftOutline, ChevronRightOutline } from '@motion/icons'
import { Button, IconButton } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { DateTime } from 'luxon'
import { useCallback, useEffect } from 'react'

import { PlannerDatePicker } from './date-picker'
import { PlannerDateRangeDropdown } from './date-range-dropdown'
import { RANGE_DAY_PIXEL_MAP } from './utils'

import { useViewState } from '../../view-state'
import { usePlannerProps, useScrollPosition } from '../context'
import { useScreenValues, useScrollToDate } from '../hooks'
import { isoDateToPixel, pixelToDate } from '../utils'

export const PlannerNavigateButtons = () => {
  const [plannerProps, setPlannerProps] = usePlannerProps()
  const [viewState, setViewState] = useViewState()
  const [scrollPosition] = useScrollPosition()
  const { screenSize } = useScreenValues()
  const scrollToDate = useScrollToDate()

  useEffect(() => {
    setPlannerProps((prev) => ({
      ...prev,
      dayPx: RANGE_DAY_PIXEL_MAP[viewState.groupBy.dateRange],
    }))
  }, [viewState.groupBy.dateRange, setPlannerProps])

  const shiftDate = useCallback(
    (operation: 'forwards' | 'backwards') => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_JUMP_TO_DATE', {
        type: operation,
      })
      // Move over by half the screen size
      const amountToShift = screenSize / 2 / plannerProps.dayPx

      // Get the current date based off of the scroll position
      const currentScrolledDate = pixelToDate(
        scrollPosition,
        plannerProps.dayPx
      )

      const numberOfDaysToShift =
        operation === 'forwards' ? amountToShift : -amountToShift

      const newDate = currentScrolledDate.minus({ days: numberOfDaysToShift })

      // Get pixel position of date
      const pixel = isoDateToPixel(newDate.toISO(), plannerProps.dayPx)

      // Set scroll position
      setPlannerProps((prev) => ({
        ...prev,
        scrollTo: pixel,
      }))
    },
    [plannerProps.dayPx, screenSize, scrollPosition, setPlannerProps]
  )

  return (
    <div className='flex items-center space-x-2'>
      <div className='flex items-center gap-1'>
        <IconButton
          icon={ChevronLeftOutline}
          onClick={() => shiftDate('backwards')}
          size='small'
          variant='muted'
          sentiment='neutral'
        />
        <IconButton
          icon={ChevronRightOutline}
          onClick={() => shiftDate('forwards')}
          size='small'
          variant='muted'
          sentiment='neutral'
        />
      </div>
      <PlannerDateRangeDropdown
        onChange={(dateRange) => {
          setViewState((prev) => ({
            ...prev,
            groupBy: { ...prev.groupBy, dateRange },
          }))
        }}
        value={viewState.groupBy.dateRange}
      />
      <PlannerDatePicker
        onChange={scrollToDate}
        isoDate={DateTime.now().toISO()}
      />
      <Button
        size='small'
        sentiment='neutral'
        variant='outlined'
        onClick={() => {
          recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_JUMP_TO_DATE', {
            type: 'today-button',
          })
          scrollToDate(DateTime.now().toISODate())
        }}
      >
        Today
      </Button>
    </div>
  )
}
