import { ArrowRightSolid, DotsHorizontalSolid } from '@motion/icons'
import { type NoteSchema } from '@motion/rpc-types'
import { classed } from '@motion/theme'
import { Button, IconButton, PopoverTrigger } from '@motion/ui/base'

import { ConnectedFolderBreadcrumbs } from '~/areas/folders/components'
import { NoteActionsList } from '~/areas/notes'
import { NoteLocationDropdown, NoteShareButton } from '~/areas/notes/components'
import { OpenLeftSidebarButton } from '~/areas/sidebar/components'

import { NoteSidebarToggleButtons } from './note-sidebar-toggle-icon-button'

export type NoteHeaderProps = {
  noteId: NoteSchema['id']
}

export function NoteHeader({ noteId }: NoteHeaderProps) {
  return (
    <Header>
      <div className='flex items-center'>
        <OpenLeftSidebarButton className='mr-2' />
        <BreadcrumbRow>
          <ConnectedFolderBreadcrumbs
            clickable
            size='small'
            leafNodeId={noteId}
          />
        </BreadcrumbRow>
      </div>

      <div className='flex items-center gap-1'>
        <NoteLocationDropdown noteId={noteId}>
          <Button size='small' variant='muted' sentiment='neutral'>
            <ArrowRightSolid />
            Move
          </Button>
        </NoteLocationDropdown>
        <NoteShareButton noteId={noteId} />
        <NoteSidebarToggleButtons />
        <PopoverTrigger
          placement='bottom-start'
          renderPopover={({ close }) => (
            <NoteActionsList
              noteId={noteId}
              location='NOTE_HEADER'
              close={close}
              hideColorPicker
            />
          )}
        >
          <IconButton
            variant='muted'
            sentiment='neutral'
            size='small'
            icon={DotsHorizontalSolid}
          />
        </PopoverTrigger>
      </div>
    </Header>
  )
}

const Header = classed('div', {
  base: `
    flex items-center justify-between px-4 py-3
    text-xs text-semantic-neutral-text-subtle
    border-b border-semantic-neutral-border-subtle
  `,
})

const BreadcrumbRow = classed(
  'div',
  `
  flex flex-row gap-1
  text-2xs
  text-semantic-neutral-text-subtle

  [&_a]:px-1
  [&_a]:rounded
  [&_a:hover]:text-inherit
  [&_a:hover]:bg-semantic-neutral-surface-bg-subtlest
`
)
