import { type CustomFieldSchema } from '@motion/zod/client'

import { useCustomFieldsRecord } from './use-custom-fields-record'

import { type WorkspaceCustomField } from '../types'

export function useCustomFieldById(
  customFieldId: string
): WorkspaceCustomField | undefined
export function useCustomFieldById<T extends CustomFieldSchema['type']>(
  customFieldId: string,
  type: T
): Extract<WorkspaceCustomField, { type: T }> | undefined
export function useCustomFieldById(
  customFieldId: string,
  type?: CustomFieldSchema['type']
): WorkspaceCustomField | undefined {
  const customFieldRecord = useCustomFieldsRecord()
  const field = customFieldRecord[customFieldId]

  if (type != null && field?.type !== type) {
    return undefined
  }

  return field
}
