import { EXPECTED_DURATION_UNIT_DROPDOWN_ITEMS } from '@motion/shared/flows'
import { PopoverButton } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { ExpectedDurationUnitDropdown } from '~/areas/flows/components'
import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { useController } from 'react-hook-form'

export const ExpectedDurationUnitSelector = () => {
  const {
    form: { control },
  } = useFlowTemplateForm()

  const { field } = useController({
    name: 'stages.0.duration.unit',
    control,
  })

  const selectedItem =
    EXPECTED_DURATION_UNIT_DROPDOWN_ITEMS.find(
      (item) => item.id === field.value
    ) ?? EXPECTED_DURATION_UNIT_DROPDOWN_ITEMS[0]

  return (
    <ExpectedDurationUnitDropdown
      selectedItem={selectedItem}
      onChange={(item) => {
        field.onChange(item.id)
        recordAnalyticsEvent('STAGE_MODAL_DURATION_OPTION_SELECTED', {
          option: item.id,
        })
      }}
    >
      <PopoverButton className='w-min h-6'>
        <span className='text-semantic-neutral-text-default'>
          {selectedItem.label}
        </span>
      </PopoverButton>
    </ExpectedDurationUnitDropdown>
  )
}
