import { getDefaultRelativeInterval } from '@motion/shared/flows'
import { createPlaceholderId } from '@motion/shared/identifiers'
import { getDefaultChunkDuration } from '@motion/ui-logic'
import { uniqueId } from '@motion/utils/core'

import {
  createNewRole,
  createNewTextVariable,
  DEFAULT_EVENT_DURATION,
  DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
  DEFAULT_TEXT_VARIABLES,
} from '~/areas/flows/utils'

import {
  type CreateProjectDefinitionSchemaWithoutWorkspaceFields,
  getVariableKeyByName,
  getWrappedVariableKeyByName,
} from './utils'

export const professionalServicesPreset = () => {
  const roles = [
    createNewRole({
      name: 'Consultant',
    }),
    createNewRole({
      name: 'Business Analyst',
    }),
    createNewRole({
      name: 'Project Planner',
    }),
  ]
  const textVariables = [
    ...DEFAULT_TEXT_VARIABLES,
    createNewTextVariable({
      name: 'Client',
    }),
    createNewTextVariable({
      name: 'InvoiceNumber',
    }),
  ]

  return {
    name: 'Professional Services Engagement',
    description:
      '<p>Use this template if you are a professional services team</p>',
    managerId: null,
    labelIds: [],
    priorityLevel: 'MEDIUM' as const,
    color: 'gray' as const,
    variables: [...roles, ...textVariables],
    folderId: undefined,
    stageDefinitionReferences: [],
    definitionDescription: '',
    stages: [
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Client Assessment',
        color: 'cyan',
        duration: {
          unit: 'DAYS',
          value: 7,
        },
        tasks: [
          {
            name: `Initial consultation with ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Consultant', roles),
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            blockedByTaskIds: [],
            scheduleMeetingWithinDays: DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Assess Client - ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Business Analyst',
              roles
            ),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            blockedByTaskIds: [],
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Draft and Present Proposal - ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Consultant', roles),
            duration: 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(60),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            blockedByTaskIds: [],
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Get Client Approval - ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Consultant', roles),
            duration: 15,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(15),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            blockedByTaskIds: [],
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Project kickoff with ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Consultant', roles),
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            blockedByTaskIds: [],
            scheduleMeetingWithinDays: DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
        ],
        variables: [],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Planning & Resourcing',
        color: 'yellow',
        duration: {
          unit: 'DAYS',
          value: 6,
        },
        tasks: [
          {
            name: `Develop Project Plan - ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Planner', roles),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            blockedByTaskIds: [],
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Create tasks and assign to team members in the Execution Stage - ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Planner', roles),
            duration: 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(60),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            blockedByTaskIds: [],
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: 'Establish Communication Channels',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Planner', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            blockedByTaskIds: [],
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
        ],
        variables: [],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Execution',
        color: 'blue',
        duration: {
          unit: 'DAYS',
          value: 20,
        },
        tasks: [
          {
            name: `Setup check-in with ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Planner', roles),
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            blockedByTaskIds: [],
            scheduleMeetingWithinDays: DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
        ],
        variables: [],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Review',
        color: 'pink',
        duration: {
          unit: 'DAYS',
          value: 10,
        },
        tasks: [
          {
            name: 'Review Project and Ensure High Quality',
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Consultant', roles),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            blockedByTaskIds: [],
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Implement Training Materials - ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Consultant', roles),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            blockedByTaskIds: [],
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
        ],
        variables: [],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Delivery',
        color: 'green',
        duration: {
          unit: 'DAYS',
          value: 5,
        },
        tasks: [
          {
            name: `Project delivery with ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Consultant', roles),
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            blockedByTaskIds: [],
            scheduleMeetingWithinDays: DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Release Project - ${getWrappedVariableKeyByName('Client', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Consultant', roles),
            duration: 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(60),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            blockedByTaskIds: [],
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Bill Client - ${getWrappedVariableKeyByName('Client', textVariables)} - ${getWrappedVariableKeyByName('InvoiceNumber', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Business Analyst',
              roles
            ),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            blockedByTaskIds: [],
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
        ],
        variables: [],
      },
    ],
  } satisfies CreateProjectDefinitionSchemaWithoutWorkspaceFields
}
