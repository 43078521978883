import { useWillExceedWorkspaceFeatureCap } from '@motion/web-billing'

import { useOpenFlowsAiModal } from '~/global/hooks/use-open-flows-ai-modal'

import { TieredPricingUpsellGradientButton } from './tiered-pricing-upsell-button'

export type TieredPricingAiFlowButtonProps = {
  workspaceId?: string
  onClose?: () => void
}

export function TieredPricingAiFlowButton({
  onClose,
  workspaceId,
}: TieredPricingAiFlowButtonProps) {
  const openAiModal = useOpenFlowsAiModal()

  const canCreateFlowTemplate = !useWillExceedWorkspaceFeatureCap(
    'projectDefinitions',
    workspaceId,
    1
  )

  const handleClick = () => {
    openAiModal(workspaceId, onClose)
  }

  return (
    <TieredPricingUpsellGradientButton
      sentiment='purple'
      onClick={handleClick}
      feature='projectDefinitions'
      featureLocked={!canCreateFlowTemplate}
    >
      Create Project Template with AI
    </TieredPricingUpsellGradientButton>
  )
}
