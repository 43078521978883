import { Tooltip } from '@motion/ui/base'
import { StatusIcon } from '@motion/ui/pm'

import { ModalFieldButton } from '~/areas/task-project/components'
import { StatusField, type StatusFieldProps } from '~/areas/task-project/fields'
import { useWorkspaceById } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { useActionItemForm } from '../../hooks'

export const ControlledStatusField = () => {
  const { form } = useActionItemForm()

  const { control, watch } = form

  const { field } = useController({
    name: 'statusId',
    control,
  })
  const workspaceId = watch('workspaceId')

  const onChange: StatusFieldProps['onChange'] = (statusId) => {
    field.onChange(statusId)
  }

  const workspace = useWorkspaceById(workspaceId)
  const workspaceInaccessible = workspace == null

  if (workspaceInaccessible || field.value == null) {
    return (
      <Tooltip content='Select a workspace to update the status'>
        <ModalFieldButton label='Status' disabled>
          <StatusIcon
            item={{
              color: '#889096',
              name: 'Todo',
              type: 'DEFAULT',
            }}
          />
          <span className='truncate'>Todo</span>
        </ModalFieldButton>
      </Tooltip>
    )
  }

  return (
    <StatusField
      value={field.value}
      onChange={onChange}
      workspaceId={workspaceId}
    />
  )
}
