import { PencilSolid, PlusSolid } from '@motion/icons'
import { ActionList, PopoverTrigger, SearchableList } from '@motion/ui/base'
import { CUSTOM_SCHEDULE_ID } from '@motion/ui-logic/pm/task'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { useSetting } from '@motion/web-common/settings'
import { type TaskSchema } from '@motion/zod/client'

import { type ScheduleCollection } from '~/types/settingsTypes'
import { type ReactNode, useMemo } from 'react'

export type ScheduleDropdownProps = Omit<
  ScheduleDropdownContentProps,
  'close'
> & {
  children: ReactNode
}

export const ScheduleDropdown = ({
  children,
  selectedScheduleId,
  showCustomSchedule = false,
  onChange,
  renderCustomSchedulePanel,
}: ScheduleDropdownProps) => {
  return (
    <>
      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <ScheduleDropdownContent
            close={close}
            selectedScheduleId={selectedScheduleId}
            showCustomSchedule={showCustomSchedule}
            onChange={onChange}
            renderCustomSchedulePanel={renderCustomSchedulePanel}
          />
        )}
      >
        {children}
      </PopoverTrigger>
    </>
  )
}

export type ScheduleDropdownContentProps = {
  close: () => void
  selectedScheduleId: NonNullable<TaskSchema['scheduleId']>
  showCustomSchedule?: boolean
  onChange: (scheduleId: NonNullable<TaskSchema['scheduleId']>) => void
  onUpdate?: () => void
  renderCustomSchedulePanel?: () => ReactNode
}

// TODO fix the return type of useSetting
// https://app.graphite.dev/github/pr/usemotion/motion/22070/chore(motion-extension)-Replace-empty-objects-and-noop-functions?utm_source=gt-slack-notif&panel=timeline#comment-PRRC_kwDOL_0cvs54udCO
const DEFAULT_SCHEDULES = READONLY_EMPTY_OBJECT as ScheduleCollection

export const ScheduleDropdownContent = ({
  close,
  onChange,
  onUpdate,
  selectedScheduleId,
  showCustomSchedule = false,
  renderCustomSchedulePanel,
}: ScheduleDropdownContentProps) => {
  const modalApi = useModalApi()
  const [userSchedules = DEFAULT_SCHEDULES] = useSetting(
    'schedules',
    DEFAULT_SCHEDULES
  )

  const schedules = useMemo(() => {
    const s = Object.entries(userSchedules).map(([id, s]) => ({
      id,
      name: s.title,
    }))

    return showCustomSchedule
      ? [{ id: CUSTOM_SCHEDULE_ID, name: 'Custom' }, ...s]
      : s
  }, [showCustomSchedule, userSchedules])

  const openScheduleModal = async (scheduleId?: string) => {
    close()

    recordAnalyticsEvent('TASK_SCHEDULE_MODAL_OPEN', {
      scheduleId,
    })

    const selectedScheduleId = await modalApi.prompt('schedule-modal', {
      scheduleId,
    })
    if (selectedScheduleId === ModalDismissed) {
      return null
    }

    return selectedScheduleId
  }

  return (
    <div className='grid grid-cols-[auto_auto]'>
      <div>
        <SearchableList
          searchable
          items={schedules}
          computeKey={(item) => item.id}
          computeSearchValue={(item) => item.name}
          computeSelected={(item) => item.id === selectedScheduleId}
          onSelect={(item) => {
            onChange(item.id)

            if (item.id === CUSTOM_SCHEDULE_ID && renderCustomSchedulePanel)
              return

            close()
          }}
          renderItem={(item) => (
            <div className='flex flex-row items-center grow justify-between gap-2'>
              <span className='max-w-xs truncate'>{item.name}</span>
              {item.id !== CUSTOM_SCHEDULE_ID && (
                <PencilSolid
                  className='w-4'
                  onClick={async () => {
                    const scheduleId = await openScheduleModal(item.id)
                    if (scheduleId != null) {
                      onChange(scheduleId)
                      onUpdate?.()
                    }
                  }}
                />
              )}
            </div>
          )}
          inputProps={{ placeholder: 'Choose schedule...' }}
        />
        <div className='flex flex-col border-t border-dropdown-border mt-1'>
          <ActionList
            items={[
              {
                prefix: <PlusSolid />,
                content: 'Add schedule',
                onAction: async () => {
                  const scheduleId = await openScheduleModal()
                  if (scheduleId != null) {
                    onChange(scheduleId)
                    onUpdate?.()
                  }
                },
              },
            ]}
          />
        </div>
      </div>
      {selectedScheduleId === CUSTOM_SCHEDULE_ID &&
        renderCustomSchedulePanel?.()}
    </div>
  )
}
