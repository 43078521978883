import { classed } from '@motion/theme'

export const PageContainer = classed('div', {
  base: `
  grid grid-rows-[auto_1fr]
  w-full overflow-hidden
  bg-calendar-bg-default
  isolate
  relative
  `,
})
