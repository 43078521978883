import { NotesColorIcon, Tooltip } from '@motion/ui/base'
import { type NoteSchema } from '@motion/zod/client'

import { useNoteById } from '~/global/rpc/v2'
import { type ComponentRef, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { type BadgeSize, getBadgeSizeInPixels } from './utils'

type NoteBadgeProps = {
  value: Pick<NoteSchema, 'color' | 'title'> | undefined | null
  size?: BadgeSize
  hideTooltip?: boolean
  className?: string
}

export const NoteBadge = forwardRef<
  ComponentRef<typeof NotesColorIcon>,
  NoteBadgeProps
>(function NoteBadge({ value, size, hideTooltip, className }, ref) {
  const badgeSize = size != null ? getBadgeSizeInPixels(size) : '1em'

  const { color = 'gray', title } = value ?? {}

  const icon = (
    <NotesColorIcon
      ref={ref}
      width={badgeSize}
      height={badgeSize}
      className={twMerge('shrink-0', className)}
      color={color}
    />
  )

  if (hideTooltip) return icon

  return (
    <Tooltip asChild content={`Doc: ${title ?? 'None'}`}>
      {icon}
    </Tooltip>
  )
})

type ConnectedNoteBadgeProps = Omit<NoteBadgeProps, 'value'> & {
  id: string | undefined
}
export const ConnectedNoteBadge = ({
  id,
  ...rest
}: ConnectedNoteBadgeProps) => {
  const { data: note } = useNoteById({ id })

  return <NoteBadge value={note} {...rest} />
}
