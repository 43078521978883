import { PlusCircleSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { type FlowTemplateStage } from '@motion/ui-logic/pm/project'
import { sleep } from '@motion/utils/promise'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { MotionLink } from '~/global/components'
import { useUriByRouteId } from '~/routing'
import { type RefObject } from 'react'

import { DashedStageButton, InlineHint } from './styled'

import { StageColumn } from '../../styled'

type AddStageColumnProps = {
  scrollColumnsRef: RefObject<HTMLDivElement | null>
  onAddStage: (newStage: FlowTemplateStage) => void
  getNewStageData: () => FlowTemplateStage
}

export function AddStageColumn({
  getNewStageData,
  onAddStage,
  scrollColumnsRef,
}: AddStageColumnProps) {
  const getRouteUri = useUriByRouteId()

  return (
    <StageColumn newStage>
      <div className='flex flex-col gap-4 py-2 px-3'>
        <span className='text-sm font-medium text-semantic-neutral-text-subtle py-[5px]'>
          Add stage
        </span>

        <span className='text-2xs text-semantic-neutral-text-subtle'>
          Every project can be broken down into stages, such as Initiation,
          Execution, and Delivery. By organizing work in stages, you ensure that
          projects move forward smoothly through each phase of the lifecycle.
        </span>

        <MotionLink
          url={getRouteUri('tutorial-lesson', {
            lessonId: 'workflow-templates',
          })}
          className='text-semantic-gradient-purple text-2xs font-medium'
        >
          Watch tutorial
        </MotionLink>

        <CreateNewStageButton
          getNewStageData={getNewStageData}
          onAddStage={onAddStage}
          scrollColumnsRef={scrollColumnsRef}
        />

        <InlineHint className='text-center'>
          <span>
            {templateStr(
              'To track time gaps between stages, add a {{ stage }} with a single reminder task.',
              {
                stage: <strong>Waiting Stage</strong>,
              }
            )}
          </span>

          <span>
            For example, "Waiting for approval" or "Waiting for event to start."
          </span>
        </InlineHint>
      </div>
    </StageColumn>
  )
}

const CreateNewStageButton = ({
  onAddStage,
  scrollColumnsRef,
  getNewStageData,
}: AddStageColumnProps) => {
  return (
    <DashedStageButton
      type='button'
      size='small'
      variant='muted'
      sentiment='neutral'
      onClick={async (e) => {
        e.preventDefault()

        const newStage = getNewStageData()
        onAddStage(newStage)

        recordAnalyticsEvent('FLOW_TEMPLATE_STAGE_MODIFIED', {
          type: 'add',
        })

        // TODO: scroll isn't working
        //
        // Sleeping to scroll in a next frame to make sure the item has been added
        await sleep(1)
        scrollColumnsRef.current?.scrollBy({
          left: 500,
          behavior: 'smooth',
        })
      }}
    >
      <div className='flex items-center gap-1.5 w-full'>
        <PlusCircleSolid className='w-4 h-4 text-semantic-neutral-icon-default' />
        <span className='text-sm font-medium'>Create New Stage</span>
      </div>
    </DashedStageButton>
  )
}
