import type { NormalTaskSchema } from '@motion/rpc-types'

import { TaskStatusDropdown } from '~/areas/tasks/components'
import { ConnectedStatusBadge } from '~/global/components/badges'
import { useCallback } from 'react'

import { useUpdateTasksFn } from '../../../hooks'

export type TaskRowStatusProps = {
  task: NormalTaskSchema
  disabled: boolean
}

export function TaskRowStatus({ task, disabled }: TaskRowStatusProps) {
  const updateTasks = useUpdateTasksFn()

  const handleStatusChange = useCallback(
    (newStatusId: string) => {
      updateTasks([task], () => ({
        statusId: newStatusId,
      }))
    },
    [task, updateTasks]
  )

  return (
    <TaskStatusDropdown
      isUnvisitedStage={false}
      selectedStatusId={task.statusId}
      onChange={handleStatusChange}
      workspaceId={task.workspaceId}
    >
      <button type='button' className='flex' disabled={disabled}>
        <ConnectedStatusBadge size='small' id={task.statusId} />
      </button>
    </TaskStatusDropdown>
  )
}
