import { MAX_FOLDER_DEPTH } from '@motion/shared/common'
import { isOneOf } from '@motion/utils/array'
import { clamp } from '@motion/utils/math'

import type { LevelCalculation } from '~/areas/treeviews/components'

import { type SortableWorkspacesTreeviewItem } from '../types'

export const workspaceCalculateMinLevel: LevelCalculation<
  SortableWorkspacesTreeviewItem
> = (activeItem, nextItems) => {
  const nextItem = nextItems[0] as SortableWorkspacesTreeviewItem | undefined

  if (!nextItem) {
    return activeItem.type === 'WORKSPACE' ? 0 : 1
  }

  if (nextItem.type === 'WORKSPACE' && activeItem.type !== 'WORKSPACE') {
    return 1
  }

  return nextItem.level
}

export const workspaceCalculateMaxLevel: LevelCalculation<
  SortableWorkspacesTreeviewItem
> = (activeItem, previousItems) => {
  const previousItem = previousItems[0] as
    | SortableWorkspacesTreeviewItem
    | undefined

  if (!activeItem || !previousItem || activeItem.type === 'WORKSPACE') return 0

  if (previousItem.type === 'PLACEHOLDER') {
    return clamp(previousItem.level - 1, 1, MAX_FOLDER_DEPTH)
  }

  if (activeItem.type !== 'NOTE') {
    // We don't allow non-notes to be nested under notes and projects
    if (isOneOf(previousItem.parentType, ['NOTE', 'PROJECT'])) {
      const parent = previousItems.find(
        (item) =>
          item.type !== 'PLACEHOLDER' &&
          !isOneOf(item.parentType, ['NOTE', 'PROJECT'])
      )

      if (!parent) {
        // If we cannot find a valid parent, use the level of the first item
        return previousItems[previousItems.length - 1].level
      }

      return parent.level
    }

    if (isOneOf(previousItem.type, ['NOTE', 'PROJECT'])) {
      return previousItem.level
    }
  }

  const maxLevel =
    previousItem.level +
    Number(
      previousItem.item.isContainer ||
        (activeItem.type === 'NOTE' &&
          isOneOf(previousItem.type, ['NOTE', 'PROJECT']))
    )

  if (activeItem.type === 'FOLDER') {
    return clamp(maxLevel, 1, MAX_FOLDER_DEPTH)
  }

  return maxLevel
}
