import { LinkSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { ButtonGroup, IconButton, Shortcut, Tooltip } from '@motion/ui/base'
import { type NoteResponseSchema } from '@motion/zod/client'

import { NoteBadge } from '~/global/components/badges'

import { highlightEmText } from '../../utils'
import {
  DescCommentContainer,
  FieldBar,
  ResultItem,
  type ResultItemProps,
  WorkspaceProjectField,
} from '../shared'

export interface NoteResultProps {
  item: NoteResponseSchema
  onClick: ResultItemProps['onClick']
  onCopyLink: () => void
}

export function NoteResult({ item, onClick, onCopyLink }: NoteResultProps) {
  const title = (
    <div className='inline-flex gap-1.5 items-center'>
      {highlightEmText(item.name)}
    </div>
  )

  return (
    <ResultItem
      renderIcon={() => (
        <NoteBadge value={{ color: item.color, title: item.name }} />
      )}
      variant='default'
      title={title}
      onClick={onClick}
      renderControls={() => {
        return (
          <ButtonGroup size='small'>
            <Tooltip
              asChild
              renderContent={() =>
                templateStr('Copy link {{shortcut}}', {
                  shortcut: <Shortcut shortcut='mod+l' />,
                })
              }
            >
              <IconButton
                icon={LinkSolid}
                size='small'
                variant='outlined'
                sentiment='neutral'
                aria-label='Copy task link'
                onClick={(evt) => {
                  evt.stopPropagation()
                  onCopyLink()
                }}
              />
            </Tooltip>
          </ButtonGroup>
        )
      }}
    >
      <FieldBar>
        <WorkspaceProjectField
          workspaceName={item.workspace.name}
          projectName={item.project.name}
        />
      </FieldBar>
      <DescCommentContainer>
        {item.text && <div>{highlightEmText(item.text)}</div>}
      </DescCommentContainer>
    </ResultItem>
  )
}
