import { useOnce } from '@motion/react-core/hooks'
import { templateStr } from '@motion/react-core/strings'
import { Text } from '@motion/ui/base'
import { type FilterTarget, useActiveFilter } from '@motion/ui-logic/pm/data'
import { Sentry } from '@motion/web-base/sentry'
import { useHasTreatment } from '@motion/web-common/flags'

import { LoadingPage } from '~/areas/project-management/pages/pm-revamp/common/loading-page'
import {
  ConnectedTaskLoader,
  type ConnectedTaskLoaderProps,
} from '~/areas/project-management/pages/pm-v3/components'
import { ConnectedProjectKanban } from '~/areas/project-management/pages/pm-v3/pages/connected-project-kanban'
import { ConnectedProjectList } from '~/areas/project-management/pages/pm-v3/pages/connected-project-list'
import { ConnectedProjectPlanner } from '~/areas/project-management/pages/pm-v3/pages/connected-project-planner'
import { ConnectedTaskKanban } from '~/areas/project-management/pages/pm-v3/pages/connected-task-kanban'
import { ConnectedTaskList } from '~/areas/project-management/pages/pm-v3/pages/connected-task-list'
import { usePageData } from '~/areas/project-management/pages/pm-v3/routes/hooks/use-v3-page-data'
import { type WorkspaceViewState } from '~/areas/project-management/pages/pm-v3/view-state'
import { useViewState } from '~/areas/project-management/pages/pm-v3/view-state/view-state'
import { MotionLink } from '~/global/components'
import { lazy, memo, Suspense, useEffect, useMemo, useState } from 'react'

const DashboardPage = lazy(() => import('../dashboard'))

export const SelectContents = () => {
  const route = usePageData()

  const queryOverrides = useMemo<
    ConnectedTaskLoaderProps['queryOverrides']
  >(() => {
    if (route.variant !== 'archive') {
      return
    }
    return {
      completed: 'include',
      canceled: 'include',
      archived: 'only',
    }
  }, [route.variant])

  if (route.type === 'tasks' || route.type === 'projects') {
    return (
      <>
        <ConnectedTaskLoader
          overrides={route.overrides}
          queryOverrides={queryOverrides}
        />
        <DefaultPageContents key={route.stateKey} />
      </>
    )
  }

  return <div>unknown page</div>
}

declare module '@motion/web-common/flags/definitions' {
  interface AmplitudeExperiments {
    'charts-preview': FeatureFlag
  }
}

export const DefaultPageContents = memo(function DefaultPageContents() {
  const [state] = useViewState()
  const [filterState] = useActiveFilter()
  const variant = state.page
  const type = filterState.target
  const hasDashboard = useHasTreatment('charts-preview')

  // Delays the loading of the page a slight amount
  const [delayed, setDelayed] = useState(true)
  useEffect(() => {
    setDelayed(false)
  }, [])

  if (delayed) {
    return <LoadingPage id='default-page-contents' />
  }

  // @ts-expect-error - dashboard is currently internal only
  if (hasDashboard && variant === 'dashboard') {
    return (
      <Suspense fallback={<LoadingPage id='dashboard' />}>
        <DashboardPage />
      </Suspense>
    )
  }

  if (type === 'tasks') {
    if (variant === 'list') {
      return <ConnectedTaskList />
    }

    if (variant === 'kanban') {
      return <ConnectedTaskKanban />
    }
  } else if (type === 'projects') {
    if (variant === 'list') {
      return <ConnectedProjectList />
    }
    if (variant === 'kanban') {
      return <ConnectedProjectKanban />
    }
    if (variant === 'gantt') {
      return <ConnectedProjectPlanner />
    }
  }

  return <UnknownContent type={type} variant={variant} />
})

type UnknownContentProps = {
  type: FilterTarget
  variant: WorkspaceViewState['page']
}
function UnknownContent({ type, variant }: UnknownContentProps) {
  useOnce(() => {
    Sentry.captureMessage('Unknown content page', {
      extra: {
        type,
        variant,
      },
    })
  })

  return (
    <div className='grid place-items-center h-24'>
      <Text>This page cannot be loaded</Text>
      <Text size='sm'>
        {templateStr(
          'If the issue persists, please contact support at {{link}}',
          {
            link: (
              <MotionLink url='https://help.usemotion.com' external>
                https://help.usemotion.com
              </MotionLink>
            ),
          }
        )}
      </Text>
    </div>
  )
}
