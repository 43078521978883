import { PuzzleSolid } from '@motion/icons'
import { Button, Tooltip } from '@motion/ui/base'
import { type ProjectDefinitionSchema } from '@motion/zod/client'

import { WorkspaceTemplateDropdown } from '~/global/components/dropdowns'
import { ProjectDefinitionLabel } from '~/global/components/labels'
import { useWorkspaceFns } from '~/global/hooks'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { useProjectModalUrlParams } from '../../project-modal/hooks'
import {
  useActiveTab,
  useCustomFieldFormValuesFn,
  useProjectDefinitionDerivedFormDataFn,
  useSetupProjectForm,
} from '../hooks'

export type ChooseProjectDefinitionFooterProps = {
  projectDefinition?: ProjectDefinitionSchema | null
}
export function ChooseProjectDefinitionFooter({
  projectDefinition,
}: ChooseProjectDefinitionFooterProps) {
  const { workspaceId: workspaceIdParam } = useProjectModalUrlParams()
  const activeTab = useActiveTab()
  const {
    form: { setValue, watch },
  } = useSetupProjectForm()
  const { getProjectDefinitionWithStages } = useWorkspaceFns()
  const getProjectDefinitionDerivedFormData =
    useProjectDefinitionDerivedFormDataFn()
  const getCustomFieldFormValues = useCustomFieldFormValuesFn()

  const handleTemplateSelect = useCallback(
    (projectDefinition: ProjectDefinitionSchema) => {
      const projectDefinitionWithStages = getProjectDefinitionWithStages(
        projectDefinition.id
      )
      if (projectDefinitionWithStages == null) {
        throw new Error('Project definition with stages not found')
      }

      const { stageDueDates, roleAssignees, textReplacements, dueDate } =
        getProjectDefinitionDerivedFormData(
          projectDefinitionWithStages,
          DateTime.now().startOf('day'),
          undefined
        )

      const customFieldValuesFieldArray = getCustomFieldFormValues(
        projectDefinitionWithStages.workspaceId,
        projectDefinitionWithStages
      )

      setValue('projectDefinitionId', projectDefinitionWithStages.id, {
        shouldDirty: true,
      })
      setValue('projectDefinition', projectDefinitionWithStages, {
        shouldDirty: true,
      })
      setValue('workspaceId', projectDefinitionWithStages.workspaceId, {
        shouldDirty: true,
      })
      setValue('stageDueDates', stageDueDates, { shouldDirty: true })
      setValue('roleAssignees', roleAssignees, { shouldDirty: true })
      setValue('textReplacements', textReplacements, { shouldDirty: true })
      setValue('customFieldValuesFieldArray', customFieldValuesFieldArray, {
        shouldDirty: true,
      })
      if (dueDate) {
        setValue('dueDate', dueDate, { shouldDirty: true })
      }
    },
    [
      getCustomFieldFormValues,
      getProjectDefinitionDerivedFormData,
      getProjectDefinitionWithStages,
      setValue,
    ]
  )

  if (projectDefinition) {
    return (
      <Tooltip content={projectDefinition.name}>
        <div className='flex flex-col items-center px-2'>
          <ProjectDefinitionLabel
            bold
            size='normal'
            value={projectDefinition}
            fullWidth
          />
        </div>
      </Tooltip>
    )
  }

  // Only show on first tab
  if (activeTab === 'name') {
    // If no workspace param in url, the workspace in form is the default workspace
    // Override to allow template dropdown to show all templates
    const workspaceId =
      workspaceIdParam == null ? undefined : watch('workspaceId')

    return (
      <WorkspaceTemplateDropdown
        onChange={handleTemplateSelect}
        workspaceId={workspaceId}
      >
        <Button sentiment='neutral' size='small' fullWidth>
          <PuzzleSolid />
          Use template
        </Button>
      </WorkspaceTemplateDropdown>
    )
  }

  return null
}
