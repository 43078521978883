import { VideoCameraSolid } from '@motion/icons'
import { type EventConferenceType } from '@motion/shared/common'
import {
  LoadingSpinner,
  PopoverButton,
  PopoverTrigger,
  SearchableList,
} from '@motion/ui/base'
import {
  getConferenceDataForType,
  getConferenceTypesForProviderType,
  isValidConferenceTypeForProviderType,
} from '@motion/ui-logic'

import { NAME_FIELD_RULE } from '~/global/rules'
import { useController } from 'react-hook-form'

import { PopoverButtonIcon } from '../components'
import { useEventModalState } from '../contexts'
import { useEventForm, useFormHandleConferenceChange } from '../hooks'

export const ControlledEventConferenceField = () => {
  const { isLoading, isReadOnly, hostCalendar, initialEvent } =
    useEventModalState()
  const { form, disabledFields, hiddenFields } = useEventForm()
  const handleConferenceChange = useFormHandleConferenceChange()
  const { control } = form

  const { field } = useController({
    name: 'conferenceType',
    control,
    rules: NAME_FIELD_RULE,
  })

  // // Set initial location and description if Zoom is default
  // useOnce(async () => {
  //   if (
  //     field.value === EventConferenceType.zoom &&
  //     form.getValues('location') == null &&
  //     form.getValues('description') === ''
  //   ) {
  //     try {
  //       const { location, description } = await generateZoomData()
  //       form.setValue('location', location)
  //       form.setValue('description', description)
  //     } catch (error) {
  //       Sentry.captureException(error)
  //       form.setValue('conferenceType', EventConferenceType.none)
  //     }
  //   }
  // })

  if (isLoading || hiddenFields.has('conferenceType') || hostCalendar == null) {
    return null
  }

  const onChange = async (value: EventConferenceType) => {
    if (
      hostCalendar == null ||
      !isValidConferenceTypeForProviderType(value, hostCalendar.providerType)
    ) {
      return
    }

    await handleConferenceChange(value)
  }

  // Using `isPendingSync` as a way to show a loading spinner on the conference
  // because it's a computed field and we need calsync to run to get the real value
  const isPendingSync =
    (initialEvent?.isPendingSync && field.value === 'none') ?? false

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          searchable={false}
          items={getConferenceTypesForProviderType(hostCalendar.providerType)}
          renderItem={(item) => <ConferenceItem value={item} />}
          computeKey={(item) => item}
          computeSelected={(item) => item === field.value}
          onSelect={(item) => {
            onChange(item)
            close()
          }}
        />
      )}
    >
      <PopoverButton
        disabled={disabledFields.has('conferenceType') || isPendingSync}
        readOnly={isReadOnly}
        size='small'
      >
        {isPendingSync ? (
          <div className='flex items-center gap-2'>
            <LoadingSpinner /> Loading conference info
          </div>
        ) : (
          <ConferenceItem value={field.value} />
        )}
      </PopoverButton>
    </PopoverTrigger>
  )
}

const ConferenceItem = ({ value }: { value?: EventConferenceType | null }) => {
  const confType = value ?? 'none'
  const item = getConferenceDataForType(confType)
  if (item == null) return null

  const icon =
    confType === 'none' ? (
      <PopoverButtonIcon icon={VideoCameraSolid} />
    ) : item.link ? (
      <img className='h-3' role='presentation' src={item.link} />
    ) : null

  return (
    <div className='flex items-center gap-2'>
      {icon}
      <span>{item.title}</span>
    </div>
  )
}
