import { classed } from '@motion/theme'

export const MentionCardInfoRow = classed('div', {
  base: `flex flex-row items-center`,
  variants: {
    spaceBetween: { true: 'justify-between' },
    reverse: {
      true: 'flex-row-reverse justify-end',
      false: '',
    },
  },
  defaultVariants: {
    spaceBetween: false,
    reverse: false,
  },
})
