import {
  Button,
  ButtonGroup,
  SHORTCUT_DELIMITER,
  useShortcut,
} from '@motion/ui/base'
import { NOOP_FUNCTION } from '@motion/utils/function'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { type RecurringTaskSchema, type TaskSchema } from '@motion/zod/client'

import { useEffect } from 'react'

import { Events } from '../../../analyticsEvents'
import { selectDesktopSettings } from '../../../state/desktopSlice'
import { useAppSelector } from '../../../state/hooks'
import { parseShortcutKey } from '../../../utils/desktop'
import { Paragraph } from '../../Common'
import { TaskToastBody } from '../../ProjectManagement/components/Modals/task-toast'

type OptionSpaceDesktopSuccessProps = {
  onCreateAnotherTask: () => void
  onClose: () => void
  createdTask: TaskSchema | RecurringTaskSchema
}

export const OptionSpaceDesktopSuccess = ({
  onCreateAnotherTask = NOOP_FUNCTION,
  onClose = NOOP_FUNCTION,
  createdTask,
}: OptionSpaceDesktopSuccessProps) => {
  const mainWindowTaskUrl = `/web/calendar?task=${createdTask.id}`
  const { uid: currentUserId } = useAuthenticatedUser()
  const desktopSettings = useAppSelector(selectDesktopSettings)

  useEffect(function logSuccess() {
    recordAnalyticsEvent(Events.TASK_CREATE_DESKTOP_SUCCESS)
  }, [])

  useShortcut('escape', () => onClose())
  useShortcut('mod+space', () => onCreateAnotherTask())

  const unformattedAddTaskShortcut = desktopSettings?.shortcuts?.addTask
  const addTaskShortcut = unformattedAddTaskShortcut
    ? unformattedAddTaskShortcut
        .split(SHORTCUT_DELIMITER)
        .map((key) => parseShortcutKey(key))
        .join(SHORTCUT_DELIMITER)
    : 'alt+space'

  return (
    <div className='bg-semantic-neutral-bg-default p-4 relative flex flex-col self-start h-full w-full overflow-hidden gap-5'>
      <div className='flex justify-between items-center w-full min-[200px]:gap-3 gap-10 electron-drag'>
        <Paragraph className='font-semibold'>Task created</Paragraph>
        <ButtonGroup>
          <Button
            onClick={onCreateAnotherTask}
            shortcut={addTaskShortcut}
            size='small'
          >
            Create another task
          </Button>
          <Button
            onClick={onClose}
            variant='muted'
            sentiment='neutral'
            shortcut='esc'
            size='small'
          >
            Close
          </Button>
        </ButtonGroup>
      </div>
      <div className='border border-dropdown-border rounded'>
        <TaskToastBody
          desktopOptionSpace
          task={createdTask}
          onHide={onClose}
          taskUrl={mainWindowTaskUrl}
          currentUserId={currentUserId}
        />
      </div>
    </div>
  )
}
