import { useOnDesktopEvent, useSendToDesktop } from '@motion/desktop-ipc/hooks'
import { InformationCircleSolid } from '@motion/icons'
import { Button, ButtonGroup, Text } from '@motion/ui/base'
import { NOOP_FUNCTION } from '@motion/utils/function'

import { useEffect, useState } from 'react'

interface DesktopUpdateAvailableProps {
  open?: boolean
  setOpen?: (open: boolean) => void
}

interface DesktopUpdateAvailableContentProps {
  downloadUpdate: () => void
  remindLater: () => void
}

export const DesktopUpdateAvailable = ({
  open,
  setOpen = NOOP_FUNCTION,
}: DesktopUpdateAvailableProps) => {
  const sendToDesktop = useSendToDesktop()

  // @ts-expect-error: Legacy desktop users (may add again soon)
  useOnDesktopEvent('updateAppAvailable', () => setOpen(true))

  if (!open) return null
  return (
    <DesktopUpdateAvailableContent
      remindLater={() => {
        setOpen(false)
        sendToDesktop('updateAppLater')
      }}
      downloadUpdate={() => {
        sendToDesktop('updateAppNow')
      }}
    />
  )
}

export const DesktopUpdateAvailableContent = ({
  downloadUpdate = NOOP_FUNCTION,
  remindLater = NOOP_FUNCTION,
}: DesktopUpdateAvailableContentProps) => {
  const [isUpdateDownloaded, setIsUpdateDownloaded] = useState(false)
  const sendToDesktop = useSendToDesktop()

  useEffect(() => {
    // For users on older versions of the desktop app, we need to request the update status (changed in 0.24.0 to requestUpdateDownloaded)
    sendToDesktop('requestUpdateStatus')
    // Do not let user restart to update until we confirm update is downloaded (and not just available)
    sendToDesktop('requestUpdateDownloaded')
  }, [sendToDesktop])

  // @ts-expect-error: Legacy desktop users (may add again soon)
  useOnDesktopEvent('updateAppDownloaded', () => {
    setIsUpdateDownloaded(true)
  })

  return (
    <div className='bg-semantic-neutral-surface-bg-default border border-semantic-neutral-border-default flex w-[323px] flex-col gap-4 rounded-lg p-4 drop-shadow'>
      <div className='bg-semantic-neutral-bg-subtle h-9 w-9 rounded-lg p-2'>
        <InformationCircleSolid className='text-semantic-neutral-icon-default text-base size-5' />
      </div>
      <div className='flex flex-col gap-2'>
        <Text weight='semibold'>New update available!</Text>
        <Text sentiment='subtle'>
          We’ve just released an update to improve your experience with Motion.
        </Text>
      </div>
      <ButtonGroup>
        <Button
          sentiment='primary'
          loading={!isUpdateDownloaded}
          onClick={() => {
            setIsUpdateDownloaded(false)
            downloadUpdate()
          }}
        >
          Restart to Update
        </Button>
        <Button sentiment='neutral' variant='muted' onClick={remindLater}>
          Remind me later
        </Button>
      </ButtonGroup>
    </div>
  )
}
