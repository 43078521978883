import { classed } from '@motion/theme'
import {
  AutoScheduleToggle,
  type AutoScheduleToggleProps,
  Tooltip,
} from '@motion/ui/base'

// Just a static auto-schedule toggle
export function AutoscheduleToggle({
  size = 'normal',
  checked,
}: {
  size?: AutoScheduleToggleProps['size']
  checked: boolean
}) {
  return (
    <ToggleContainer>
      <Tooltip
        content='Autoschedule can be turned off once the action item has been approved'
        asChild
      >
        <AutoScheduleToggle checked={checked} disabled size={size}>
          <ToggleLabel size={size}>Auto-scheduled</ToggleLabel>
        </AutoScheduleToggle>
      </Tooltip>
    </ToggleContainer>
  )
}

const ToggleContainer = classed('div', {
  base: 'w-full align-middle flex flex-row items-center gap-1 h-9 px-4 py-2.5 modal-lg:px-5 modal-lg:py-4 bg-semantic-purple-bg-default',
})

const ToggleLabel = classed('p', {
  base: 'text-semantic-purple-text-default text-sm',
  variants: {
    size: {
      xsmall: 'text-2xs',
      small: 'text-xs',
      normal: 'text-sm',
    },
  },
  defaultVariants: {
    size: 'normal',
  },
})
