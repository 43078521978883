import { classed } from '@motion/theme'
import { TextHeader } from '@motion/ui/base'

import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export interface SettingPageWrapperProps {
  title?: ReactNode
  children: ReactNode
  className?: string
  fullWidth?: boolean
  banner?: ReactNode
  tabGroup?: ReactNode
}

export const SettingPageWrapper = ({
  title,
  children,
  className,
  fullWidth,
  banner,
  tabGroup,
}: SettingPageWrapperProps) => {
  return (
    <Container data-testid='app-settings'>
      {title && (
        <div className='py-3.5 px-12 bg-calendar-bg-default'>
          <TextHeader size='lg'>{title}</TextHeader>
        </div>
      )}
      <div className='flex flex-col'>
        {tabGroup}
        {banner}
        <div className='px-12 py-6 overflow-auto h-full w-full'>
          <div
            className={twMerge(
              'flex w-full flex-col gap-4 self-start',
              !fullWidth && 'max-w-4xl',
              className
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </Container>
  )
}

const Container = classed('div', {
  base: `
    divide-semantic-neutral-border-default divide-y
    bg-calendar-bg-default
    grid grid-rows-[auto_minmax(0,1fr)]
    h-full w-full
  `,
})
