import { type CreateCommentSchema } from '@motion/rpc-types'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { useCreateNoteComment as useCreateNoteCommentMutation } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export type CreateCommentPayload = Pick<
  CreateCommentSchema,
  'bodyHtml' | 'targetId' | 'createThread' | 'threadId' | 'mentionUserIds'
>

export function useCreateNoteComment() {
  const context = useRouteAnalyticsMetadata()
  const { mutateAsync: createComment } = useCreateNoteCommentMutation()
  const { uid: userId } = useAuthenticatedUser()

  return useCallback(
    async ({
      bodyHtml,
      targetId,
      createThread,
      threadId,
      mentionUserIds = [],
    }: CreateCommentPayload) => {
      try {
        if (!userId || !bodyHtml) {
          throw new Error('userId and bodyHtml are required.')
        }
        if (!threadId && !createThread) {
          throw new Error('Either thread id or createThread are required.')
        }

        if (threadId && createThread) {
          throw new Error('Should not provide both thread id and createThread.')
        }

        const result = await createComment({
          bodyHtml,
          targetId,
          targetType: 'NOTE',
          createdByUserId: userId,
          createThread,
          threadId,
          mentionUserIds,
        })

        recordAnalyticsEvent('PROJECT_MANAGEMENT_CREATE_NOTE_COMMENT', {
          ...context,
          hasMentions: mentionUserIds.length > 0,
        })

        return result
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            position: 'useCreateNoteComment',
          },
        })

        showErrorToast(e)
        throw e
      }
    },
    [context, createComment, userId]
  )
}
