import { AutoscheduleStarGradientSolid, XSolid } from '@motion/icons'
import { getTextSizeFromEditorState } from '@motion/notes'
import {
  Button,
  GradientButton,
  IconButton,
  UnstyledModal,
  useShortcut,
} from '@motion/ui/base'
import { FieldLabel, StyledField } from '@motion/ui/forms'
import { sleep } from '@motion/utils/promise'
import { useModalApi } from '@motion/web-common/modals'

import { useFileUploadState } from '~/areas/attachments/contexts'
import { LoadingScreen } from '~/areas/flows'
import {
  type ModalTriggerComponentProps,
  useModalPromptBeforeDismiss,
} from '~/areas/modals'
import { ReferenceEditor } from '~/areas/notes/components'
import { ErrorBoundary } from '~/global/components'
import { useOpenFlowsAiModal } from '~/global/hooks'
import { useInferProjectParameters } from '~/global/rpc'
import { showErrorToast } from '~/global/toasts'
import { type FormEvent, useState } from 'react'
import { Controller } from 'react-hook-form'

import {
  Attachments,
  ErrorScreen,
  Section,
  SectionText,
  SectionTitle,
  TemplateDropdown,
} from './components'
import { useProjectAIForm, useProjectAIFormError } from './hooks'
import { ProjectAIForm } from './project-ai-form'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'project-ai-modal': {
      workspaceId?: string
    }
  }
}

type ConnectedProjectAIModalProps =
  ModalTriggerComponentProps<'project-ai-modal'>

export function ConnectedProjectAIModal({
  close,
  workspaceId,
}: ConnectedProjectAIModalProps) {
  const [isLoading, setIsLoading] = useState(false)
  const { cancelActiveFileUploads } = useFileUploadState()

  const handleClose = () => {
    cancelActiveFileUploads({ targetId: null, targetType: null })
    close()
  }

  return (
    <UnstyledModal
      modalClassName='max-h-full overflow-y-auto bg-modal-bg border border-semantic-neutral-border-default rounded-lg shadow-lg w-[700px] max-w-[calc(100vw-56px)]'
      overlayClassName='bg-modal-overlay'
      data-testid='project-ai-modal'
      visible
      onClose={handleClose}
      disableOverlayClick={isLoading}
      disableEscapeKey={isLoading}
      withAnimation
    >
      <ProjectAIForm workspaceId={workspaceId}>
        <ErrorBoundary
          renderFallback={({ reset }) => <ErrorScreen reset={reset} />}
        >
          <ProjectAIModalContent
            loading={isLoading}
            onLoadingChange={setIsLoading}
            close={handleClose}
          />
        </ErrorBoundary>
      </ProjectAIForm>
    </UnstyledModal>
  )
}

type ProjectAIModalContentProps = {
  loading: boolean
  onLoadingChange: (loading: boolean) => void
  close: () => void
}

function ProjectAIModalContent({
  loading,
  onLoadingChange,
  close,
}: ProjectAIModalContentProps) {
  const form = useProjectAIForm()
  const formError = useProjectAIFormError()
  const openAiModal = useOpenFlowsAiModal()

  const { mutateAsync: inferProjectParameters } = useInferProjectParameters()
  const modalApi = useModalApi()

  useShortcut('mod+s', () => handleSubmit())

  const [showDismissPrompt, setShowDismissPrompt] = useState(true)
  useModalPromptBeforeDismiss({
    when:
      showDismissPrompt &&
      form.formState.isDirty &&
      !form.formState.isSubmitting,
  })

  const handleNewTemplate = async () => {
    setShowDismissPrompt(false)
    // Wait for the component to rerender and apply the previous state change
    // before closing the modal
    await sleep(1)
    close()
    openAiModal(form.getValues('workspaceId'))
  }

  const handleSubmit = async (event?: FormEvent<HTMLFormElement>) => {
    await form.handleSubmit(async (data) => {
      try {
        onLoadingChange(true)
        const result = await inferProjectParameters(data)
        close()
        modalApi.open('v2-setup-project', {
          ...data,
          ...result,
          name: result.projectName,
          nameReason: result.projectNameReason,
          description: result.projectDescription,
          initialStages: result.stages,
          initialPersonVariables: result.personVariables,
          initialTextVariables: result.textVariables,
        })
      } catch (error) {
        showErrorToast(error)
      } finally {
        onLoadingChange(false)
      }
    })(event)
  }

  if (loading) {
    return (
      <LoadingScreen
        title='Creating Project'
        message='Hang tight! This could take up to 90 seconds...'
        className='h-96'
      />
    )
  }

  return (
    <form className='flex flex-col gap-1' onSubmit={handleSubmit}>
      <header className='h-10 px-4 bg-purple-gradient bg-opacity-[.15] flex justify-between items-center'>
        <h2 className='flex items-center gap-2 text-sm font-semibold text-gradient bg-purple-gradient bg-opacity-100'>
          Create Project with AI
          <AutoscheduleStarGradientSolid
            className='size-4'
            stopColors={['#BC6BFC', '#F147EA']}
          />
        </h2>

        <IconButton
          icon={XSolid}
          sentiment='neutral'
          variant='muted'
          onClick={close}
        />
      </header>

      <div className='p-4 flex flex-col gap-5'>
        <Section>
          <SectionTitle>
            Which project workflow template do you want to use for this project?
          </SectionTitle>
          <TemplateDropdown onCreateNewTemplate={handleNewTemplate} />
        </Section>

        <Section>
          <SectionTitle>
            Let us know what kind of project you want to create.
          </SectionTitle>
          <SectionText>
            The more context, the better! Type "@" to mention any notes, tasks,
            projects or people in Motion
          </SectionText>
          <Controller
            name='prompt'
            control={form.control}
            render={({ field, fieldState }) => (
              <FieldLabel label='Project Description' labelHidden fullWidth>
                <StyledField
                  className='leading-relaxed max-h-80 overflow-y-auto'
                  sentiment={fieldState.error ? 'error' : 'default'}
                >
                  <ReferenceEditor
                    editableClassName='min-h-32'
                    name={field.name}
                    initialValue={{
                      format: 'lexical-state',
                      value: field.value,
                    }}
                    placeholder='Create a project for our new feature launch. The project outline is here: [Motion Doc]. The deadline is June 30, assign it to John Smith.'
                    maxLength={10_000}
                    onChange={(editorState) => {
                      field.onChange(
                        getTextSizeFromEditorState(editorState) > 0
                          ? JSON.stringify(editorState)
                          : ''
                      )
                    }}
                  />
                </StyledField>
              </FieldLabel>
            )}
          />
        </Section>

        <Section>
          <SectionTitle>Attachments</SectionTitle>
          <SectionText>
            Attach any relevant files. Relevant documents can include PDFs,
            spreadsheets (.csv, .xlsx), text documents (.docx, .txt), or images
            (.jpeg, .png) that describe your project, contract or Standard
            Operating Procedure.
          </SectionText>
          <Attachments />
        </Section>

        <footer className='flex items-center justify-between gap-2'>
          <div>
            {formError && (
              <span className='text-semantic-error-text-default text-sm'>
                {formError}
              </span>
            )}
          </div>

          <div className='flex gap-2'>
            <Button
              sentiment='neutral'
              variant='muted'
              onClick={close}
              shortcut='esc'
            >
              Cancel
            </Button>

            <GradientButton
              disabled={form.formState.isSubmitting}
              type='submit'
              sentiment='purple'
              shortcut='mod+s'
            >
              Create Project with AI
            </GradientButton>
          </div>
        </footer>
      </div>
    </form>
  )
}
