import { useOnce } from '@motion/react-core/hooks'
import { Button, FormModal, showToast } from '@motion/ui/base'
import { NumberField, TextField } from '@motion/ui/forms'
import { isEmailValid } from '@motion/utils/string'

import { useUpdateEmail } from '~/global/rpc/user'
import {
  useGetEmailVerificationStatus,
  useStartEmailVerification,
  useVerifyEmailVerificationCode,
} from '~/global/rpc/v2/email-verification'
import { useState } from 'react'

type VerifyEmailParams = {
  userEmail: string
  onVerifySuccess: () => void
}

export function VerifyEmail({ userEmail, onVerifySuccess }: VerifyEmailParams) {
  const [code, setCode] = useState('')
  const [currentEmail, setCurrentEmail] = useState(userEmail)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [submitCodeDisabled, setSubmitCodeDisabled] = useState(true)
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false)

  const { data: emailVerificationStatus } = useGetEmailVerificationStatus()
  const { mutateAsync: startEmailVerification } = useStartEmailVerification()
  const { mutateAsync: verifyCode, isPending: isLoadingVerify } =
    useVerifyEmailVerificationCode()

  const handleResend = async () => {
    await sendEmail(true)
  }

  const handleVerify = async () => {
    setSubmitCodeDisabled(true)
    const resp = await verifyCode({ passcode: code })

    if (!resp.success) {
      setErrorMessage('The code entered was incorrect. Please try again.')
    } else {
      onVerifySuccess()
      showToast('success', 'Email verified!')
    }
  }

  const handleChangeEmail = async (newEmail: string) => {
    setCurrentEmail(newEmail)
    await sendEmail(true)
  }

  const sendEmail = async (isResend: boolean = false) => {
    try {
      await startEmailVerification()
      setErrorMessage('')
      if (isResend) {
        showToast('success', 'Email sent!')
      }
    } catch (e) {
      // We have integration tests verifying these error messages, so they should stay constant.
      // We'll fall back to a generic message otherwise.
      if (e instanceof Error && e.message.includes('Email already verified')) {
        onVerifySuccess()
        return
      }

      if (
        e instanceof Error &&
        e.message.includes('Email sent too recently') &&
        isResend
      ) {
        setErrorMessage(
          'Email already sent, please wait a moment before resending.'
        )
        return
      }

      setErrorMessage(
        'Failed to send email, please try changing your email or resending.'
      )
    }
  }

  useOnce(() => {
    if (emailVerificationStatus?.verified) {
      onVerifySuccess()
    }
    sendEmail()
  })

  return (
    <div className='h-full w-full flex'>
      <div className='mx-auto w-[600px] lg:py-12 px-6 lg:px-0 py-6'>
        <div className='pb-4'>
          <h1 className='mb-0 text-lg lg:text-[32px] lg:leading-snug font-bold select-none'>
            Verify your email
          </h1>
          <p className='text-semantic-neutral-text-subtle my-3'>
            We sent a 4-digit code to {currentEmail}, enter it below.
          </p>
        </div>

        <div className='w-full'>
          <p className='text-semantic-neutral-text-subtle mb-2 text-sm font-semibold'>
            Code
          </p>
          <NumberField
            disabled={isLoadingVerify}
            placeholder='1234'
            maxLength={4}
            onChange={(value) => {
              setCode(value.toString())
              setSubmitCodeDisabled(value.toString().length !== 4)
              setErrorMessage('')
            }}
            value={null}
            showClearButton
          />
          <div className='flex flex-row gap-2'>
            <p className='text-semantic-neutral-text-subtle my-3'>
              Don’t see a code?
            </p>
            <button
              className='text-semantic-primary-text-default font-semibold'
              onClick={handleResend}
            >
              Resend email
            </button>
          </div>
        </div>

        <div className='mt-4 flex flex-col gap-y-4'>
          <Button
            onClick={handleVerify}
            sentiment='primary'
            loading={isLoadingVerify}
            disabled={submitCodeDisabled}
          >
            Verify email
          </Button>
          <Button
            variant='muted'
            sentiment='neutral'
            onClick={() => setShowChangeEmailModal(true)}
          >
            Change email
          </Button>
        </div>

        {errorMessage && (
          <div className='pt-2'>
            <p className='text-sm text-semantic-error-text-default text-center'>
              {errorMessage}
            </p>
          </div>
        )}
      </div>
      <ChangeEmailModal
        onClose={() => setShowChangeEmailModal(false)}
        isOpen={showChangeEmailModal}
        onSuccessfulChangeEmail={handleChangeEmail}
      />
    </div>
  )
}

interface ChangeEmailModalProps {
  onClose: () => void
  isOpen: boolean
  onSuccessfulChangeEmail: (email: string) => void
}

export function ChangeEmailModal({
  onClose,
  isOpen,
  onSuccessfulChangeEmail,
}: ChangeEmailModalProps) {
  const [newEmail, setNewEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { mutateAsync: updateEmail } = useUpdateEmail()

  const onSubmitEmail = async () => {
    if (!isEmailValid(newEmail)) {
      setErrorMessage('Please enter a valid email address.')
      return
    }

    try {
      await updateEmail({ email: newEmail })
    } catch (e) {
      if (e instanceof Error && e.message.includes('Email already in use')) {
        setErrorMessage('This email address is already in use.')
      } else {
        setErrorMessage('Failed to update email, please try again.')
      }
      return
    }

    onSuccessfulChangeEmail(newEmail)
    onClose()
  }

  return (
    <FormModal
      title='Change email'
      visible={isOpen}
      onClose={onClose}
      submitAction={{
        text: 'Change email & send code',
        onAction: onSubmitEmail,
        disabled: newEmail === '',
      }}
      cancelAction={{ text: 'Cancel' }}
    >
      <div className='py-2'>
        <TextField
          value={newEmail}
          onChange={(value) => setNewEmail(value)}
          placeholder='Enter email'
        />
        <p className='text-sm text-semantic-error-text-default pt-2'>
          {errorMessage}
        </p>
      </div>
    </FormModal>
  )
}
