import { type CommentsPluginHandle } from '@motion/notes'
import {
  type CommentSchema,
  type SingleNoteResponseSchema,
} from '@motion/rpc-types'
import { byProperty, Compare, descending, groupBy } from '@motion/utils/array'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useRouteAnalyticsMetadata } from '~/global/analytics'
import {
  useCreateNoteComment,
  useDeleteNoteComment,
  useEditNoteComment,
} from '~/global/hooks/notes'
import { useResolveNoteComment } from '~/global/hooks/notes/use-resolve-note-comment'
import { useCallback, useMemo, useRef, useState } from 'react'

import { NoteCommentsContext } from './note-comments-context'
import { type ThreadsTabType, type ThreadWithComments } from './threads.types'

import { useNoteSidebarState } from '../hooks'

export type NoteCommentsProviderProps = {
  children: React.ReactNode
  data: SingleNoteResponseSchema
}

export function NoteCommentsProvider({
  children,
  data,
}: NoteCommentsProviderProps) {
  const context = useRouteAnalyticsMetadata()

  const commentsPluginRef = useRef<CommentsPluginHandle | null>(null)
  const createNoteComment = useCreateNoteComment()
  const deleteNoteComment = useDeleteNoteComment()
  const editNoteComment = useEditNoteComment()
  const resolveNoteComment = useResolveNoteComment()

  const [activeThreadId, setActiveThreadId] = useState<string | undefined>()
  const [activeTab, setActiveTab] = useState<ThreadsTabType>('active')

  const [, setNoteSidebarState] = useNoteSidebarState()

  const handleSetActiveThreadId = useCallback(
    (activeThreadId: string | undefined) => {
      setActiveThreadId(activeThreadId)
      if (activeThreadId) {
        setNoteSidebarState(true, 'comments')
      }
    },
    [setNoteSidebarState]
  )

  const threads = useMemo(() => {
    if (!data?.models?.feedEntries) {
      return null
    }

    const comments = Object.values(data.models.feedEntries).filter((t) => {
      return t.type === 'COMMENT'
    })

    const groupedComments = groupBy(comments, (c) => c.threadId ?? 'none')

    const all = Object.values(data.models.threads)
      .sort(byProperty('createdTime', descending(Compare.string)))
      .map((t) => ({
        ...t,
        comments: (groupedComments[t.id] ?? []).sort(
          byProperty('createdTime', Compare.string)
        ),
      }))

    const resolved: ThreadWithComments[] = []
    const active: ThreadWithComments[] = []

    for (const thread of all) {
      if (Array.isArray(thread.comments) && thread.comments.length > 0) {
        if (!thread.isResolved) {
          active.push(thread)
        } else {
          resolved.push(thread)
        }
      }
    }

    return { resolved, active, all }
  }, [data])

  const createComment = useCallback(
    async ({
      bodyHtml,
      mentions,
      threadId,
    }: {
      bodyHtml: string
      mentions: Array<string>
      threadId?: string
    }) => {
      if (!data.id) return
      const created = await createNoteComment({
        bodyHtml,
        targetId: data.id,
        createThread: threadId ? undefined : true,
        threadId,
        mentionUserIds: mentions,
      })
      if (!created) return

      const comment = created.models.feedEntries[created.id]

      return comment as CommentSchema
    },
    [createNoteComment, data]
  )

  const resolveThread = useCallback(
    async (threadId: string, recordEvent: boolean = true) => {
      // Mark the thread as resolved
      await resolveNoteComment(threadId)

      // Delete the single comment or delete the thread if it's the last comment
      if (!commentsPluginRef.current) return

      // deleteNoteComment reference in editor
      commentsPluginRef.current.deleteThread(threadId)

      if (recordEvent) {
        recordAnalyticsEvent('PROJECT_MANAGEMENT_RESOLVE_NOTE_COMMENT', context)
      }
    },
    [context, resolveNoteComment]
  )

  const deleteComment = useCallback(
    async (commentId: string, threadId?: string) => {
      if (!data.id) return
      try {
        await deleteNoteComment({
          commentId,
          targetId: data.id,
          onDeleteCb: () => {
            if (
              threadId &&
              threads?.all.find((t) => t.id === threadId)?.comments.length === 1
            ) {
              resolveThread(threadId, false)
            }
          },
        })
      } catch (error) {
        throw error
      }
    },
    [data.id, deleteNoteComment, resolveThread, threads?.all]
  )

  const editComment = useCallback(
    async (commentId: string, bodyHtml: string) => {
      if (!data.id) return
      if (!bodyHtml) {
        return
      }
      return editNoteComment({ commentId, targetId: data.id, bodyHtml })
    },
    [data, editNoteComment]
  )

  const value = useMemo(
    () => ({
      activeThreadId,
      setActiveThreadId: handleSetActiveThreadId,
      createComment,
      deleteComment,
      editComment,
      resolveThread,
      threads,
      commentsPluginRef,
      activeTab,
      setActiveTab,
    }),
    [
      activeThreadId,
      createComment,
      deleteComment,
      editComment,
      resolveThread,
      handleSetActiveThreadId,
      threads,
      activeTab,
      setActiveTab,
    ]
  )

  return (
    <NoteCommentsContext.Provider value={value}>
      {children}
    </NoteCommentsContext.Provider>
  )
}
