import { API } from '@motion/rpc-definitions'
import { client } from '@motion/web-common/rpc'

import { redirect } from 'react-router'

// eslint-disable-next-line import-x/no-restricted-paths
import { v4Routes } from '../../../pages/pm/routing/routes'
import { lazyRoute, type MotionRoute } from '../../../routing/api'

const entryPoint = () => import('./pm-revamp')
// eslint-disable-next-line import-x/no-restricted-paths
const notesEntryPoint = () => import('../../../pages/pm/notes')

declare module '~/routing/hooks/navigate-by-id-params' {
  interface NavigateByIdParams {
    'team-schedule': undefined
  }
}

export const route: MotionRoute = {
  id: 'pm-root',
  path: 'pm',
  lazy: lazyRoute(entryPoint, 'Shell'),
  metric: 'PROJECT_MANAGEMENT_VISIT_PAGE',
  children: [
    {
      path: '',
      index: true,
      loader: () => redirect('/web/pm/all-tasks'),
    },
    {
      id: 'team-schedule',
      path: 'team-schedule',
      lazy: lazyRoute(entryPoint, 'TeamScheduleShellWithContext'),
      tags: {
        page: 'team-schedule',
      },
      loader: async () => {
        const ensureFeaturePermissionsDataLoaded =
          API.usersV2.featurePermissionsLoader(client)

        await ensureFeaturePermissionsDataLoaded()
        return {}
      },
    },
    ...v4Routes,
    {
      id: 'notes-detail',
      path: 'docs/:noteId',
      lazy: lazyRoute(notesEntryPoint, 'Note'),
      featureFlag: 'notes-in-webapp',
    },
  ],
}
