import { type ProjectDefinitionSchema } from '@motion/rpc-types'
import { classed } from '@motion/theme'

import { twMerge } from 'tailwind-merge'

import { type BadgeSize, ProjectDefinitionBadge } from '../badges'

export type ProjectDefinitionLabelProps = {
  value: ProjectDefinitionSchema | null
  size?: BadgeSize
  nameOnly?: boolean
  bold?: boolean
  fullWidth?: boolean
}

export const ProjectDefinitionLabel = ({
  value,
  size,
  nameOnly = false,
  bold = false,
  fullWidth = false,
}: ProjectDefinitionLabelProps) => {
  const label = (
    <ProjectDefinitionText
      size={size === 'normal' ? 'normal' : 'small'}
      bold={bold}
    >
      {value?.name || 'None'}
    </ProjectDefinitionText>
  )
  if (nameOnly) {
    return label
  }

  return (
    <div
      className={twMerge(
        'flex gap-1 items-center justify-center overflow-hidden leading-4',
        fullWidth && 'w-full'
      )}
    >
      <ProjectDefinitionBadge value={value} size={size} />
      {label}
    </div>
  )
}

const ProjectDefinitionText = classed('span', {
  base: 'truncate text-semantic-neutral-text-default',
  variants: {
    size: {
      normal: 'text-sm',
      small: 'text-xs',
    },
    bold: {
      true: 'font-semibold',
    },
  },
  defaultVariants: {
    size: 'normal',
    bold: false,
  },
})
