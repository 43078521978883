import { templateStr } from '@motion/react-core/strings'
import { type TaskSchema } from '@motion/rpc-types'
import {
  formatToShortTaskDuration,
  NO_DURATION,
  SHORT_TASK_DURATION,
} from '@motion/ui-logic'
import { isMeetingTask } from '@motion/ui-logic/pm/task'

import { DurationDropdown } from '~/areas/project-management/components'
import { useTaskDurationData } from '~/areas/tasks/hooks'

import { TaskCompletedDurationField } from './completed-duration-field'

import { MentionCardButton } from '../mention-card-button'

export type TaskDurationFieldProps = {
  task: TaskSchema
}
export const TaskDurationField = ({ task }: TaskDurationFieldProps) => {
  const { onChange, disabled } = useTaskDurationData(task)

  const durationControl = (
    <DurationDropdown value={task.duration} onChange={onChange}>
      <MentionCardButton disabled={disabled}>
        <span className='truncate'>
          {formatToShortTaskDuration(task.duration)}
        </span>
      </MentionCardButton>
    </DurationDropdown>
  )

  if (
    task.duration === NO_DURATION ||
    task.duration === SHORT_TASK_DURATION ||
    isMeetingTask(task)
  ) {
    return durationControl
  }

  return (
    <div className='inline-flex items-center'>
      {templateStr('{{x}} of {{y}}', {
        x: <TaskCompletedDurationField task={task} />,
        y: durationControl,
      })}
    </div>
  )
}
