import { PencilSolid, PlusSolid, TrashSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { type Schedule } from '@motion/rpc-types'
import { Button, ButtonGroup, IconButton, Text } from '@motion/ui/base'
import { NOOP_FUNCTION } from '@motion/utils/function'
import { logEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { SettingPageWrapper } from '~/pages/settings/shared'
import { useCallback, useMemo } from 'react'

import { Events } from '../../../../analyticsEvents'
import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import {
  deleteSchedule,
  selectSchedules,
  setSchedules,
} from '../../../../state/settingsSlice'
import { sortSchedules } from '../../../../utils/settingsUtils'
import { SettingList } from '../../Components/SettingList/SettingList'

interface ScheduleSettingsProps {
  openScheduleModal: (scheduleId: string | undefined) => void
}

export const ScheduleSettings = ({
  openScheduleModal = NOOP_FUNCTION,
}: ScheduleSettingsProps) => {
  const dispatch = useAppDispatch()
  const schedules = useAppSelector(selectSchedules)
  const modalApi = useModalApi()

  const sortedSchedules: { id: string; schedule: Schedule }[] = useMemo(() => {
    return sortSchedules(schedules)
  }, [schedules])

  const deleteScheduleHandler = useCallback(
    async (deleteId: string) => {
      if (deleteId === 'work') {
        return
      }
      const tempSchedules = { ...schedules } as any
      delete tempSchedules[deleteId]

      await dispatch(setSchedules(tempSchedules))
      await dispatch(deleteSchedule(deleteId))

      void logEvent(Events.SCHEDULE_DELETE)
    },
    [dispatch, schedules]
  )

  const confirmDeleteSchedule = (deleteId: string, scheduleName: string) => {
    void modalApi.prompt('confirm', {
      analytics: {
        name: 'delete-schedule',
      },
      confirmButtonText: 'Delete schedule',
      destructive: true,
      async onValue(value) {
        if (value) {
          await deleteScheduleHandler(deleteId)
        }
      },
      title: `You are about to delete a custom schedule`,
      description: templateStr(
        'Any tasks that are currently using the schedule {{scheduleName}} will now be using the Work hours default schedule.',
        {
          scheduleName: <span className='font-semibold'>{scheduleName}</span>,
        }
      ),
    })
  }

  return (
    <SettingPageWrapper title='Schedules' className='gap-3'>
      <SettingList
        items={sortedSchedules.map(({ id, schedule }) => (
          <div
            key={id}
            className='flex w-full items-center justify-between p-3'
          >
            <Text truncate size='sm'>
              {schedule.title}
            </Text>
            <ButtonGroup>
              <IconButton
                sentiment='neutral'
                variant='muted'
                size='small'
                icon={PencilSolid}
                onClick={() => {
                  openScheduleModal(id)
                }}
              />
              {id !== 'work' && (
                <IconButton
                  sentiment='neutral'
                  variant='muted'
                  size='small'
                  icon={TrashSolid}
                  onClick={() => confirmDeleteSchedule(id, schedule.title)}
                />
              )}
            </ButtonGroup>
          </div>
        ))}
      />
      <Button
        sentiment='neutral'
        variant='muted'
        onClick={() => {
          openScheduleModal(undefined)
        }}
      >
        <PlusSolid />
        New schedule
      </Button>
    </SettingPageWrapper>
  )
}
