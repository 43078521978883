import { createContext, type ReactNode, useContext } from 'react'

import { ConnectedNoteMention } from '../components/connected-note-mention'
import { ConnectedProjectMention } from '../components/connected-project-mention'
import { ConnectedTaskMention } from '../components/connected-task-mention'
import { ConnectedUserMention } from '../components/connected-user-mention'

type RenderNodeFn = (node: {
  nodeKey: string
  entityId: string
  entityType: string
  entityLabel: string
}) => ReactNode

type EntityType = string

export type MentionRenderFnValue = Record<EntityType, RenderNodeFn>

export const defaultMentionNodeRenderFns: MentionRenderFnValue = {
  task: ({ entityId }) => <ConnectedTaskMention id={entityId} withHoverCard />,
  project: ({ entityId }) => (
    <ConnectedProjectMention id={entityId} withHoverCard />
  ),
  user: ({ entityId }) => <ConnectedUserMention id={entityId} />,
  note: ({ entityId }) => <ConnectedNoteMention id={entityId} />,
}

export const MentionNodeRenderContext = createContext<MentionRenderFnValue>(
  defaultMentionNodeRenderFns
)

const defaultRender: RenderNodeFn = ({ entityType, entityLabel }) => {
  return (
    <span>
      @{entityType}/{entityLabel}
    </span>
  )
}

export function useMentionNodeRenderFn(type: string): RenderNodeFn {
  const ctx = useContext(MentionNodeRenderContext)
  return ctx[type] ?? defaultRender
}
