import { useClosure } from '@motion/react-core/hooks'
import { isFlowVariableKey } from '@motion/shared/flows'

import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { useEffect } from 'react'

import { useStageTaskField } from './use-stage-task-field'

export const useStageTaskAssigneeField = (
  taskBasePath: `stages.${number}.tasks.${number}`
) => {
  const {
    form: { setValue },
  } = useFlowTemplateForm()

  const [assigneeId, onChangeAssigneeId] = useStageTaskField(
    `${taskBasePath}.assigneeUserId`
  )
  const [assigneeVariableKey, onChangeAssigneeVariableKey] = useStageTaskField(
    `${taskBasePath}.assigneeVariableKey`
  )

  useEffect(() => {
    const isUnassigned = !(assigneeId || assigneeVariableKey)

    if (isUnassigned) {
      setValue(`${taskBasePath}.isAutoScheduled`, false)
    }
  }, [assigneeId, assigneeVariableKey, setValue, taskBasePath])

  const onChangeAssignee = useClosure((assigneeId: string | null) => {
    if (isFlowVariableKey(assigneeId) && assigneeId != null) {
      onChangeAssigneeId(null)
      onChangeAssigneeVariableKey(assigneeId)
    } else {
      onChangeAssigneeVariableKey(null)
      onChangeAssigneeId(assigneeId)
    }
  })

  return [assigneeId ?? assigneeVariableKey, onChangeAssignee] as const
}
