import { type AvailableCustomFieldTypes } from '@motion/ui-logic'
import { createLookup } from '@motion/utils/object'
import { type PriorityLevelSchema } from '@motion/zod/client'

import {
  useTaskAssigneeUpdater,
  useTaskDeadlineUpdater,
  useTaskLabelsUpdater,
  useTaskPriorityUpdater,
  useTaskProjectUpdater,
  useTaskStartDateUpdater,
  useTaskStatusUpdater,
} from '~/areas/tasks/hooks'
import { useTaskStageDropUpdater } from '~/areas/tasks/hooks/updaters/use-drop-into-stage-updater'
import { useWorkspaceFns } from '~/global/hooks'
import { type NormalTaskWithRelations } from '~/global/proxies'
import { useCallback, useMemo } from 'react'

import {
  type CustomFieldMoveHandlerParams,
  useCustomFieldMoveHandler,
} from './use-custom-field-move-handler'

import { type GroupedNode, isNoneGroup, type Tree } from '../../grouping'
import { getMovementType } from '../get-movement-type'

type TaskFieldLookupUpdaterArgs = {
  entity: NormalTaskWithRelations
  groupNode: GroupedNode
  sourceGroupNode: GroupedNode
  workspaceId: string
}

/**
 * Lookup field handler to move a task between groups.
 *
 * Returning false indicates the operation was not done (cancelled or errored).
 */
type TaskFieldUpdaterLookup = {
  status: (args: TaskFieldLookupUpdaterArgs) => Promise<void>
  priority: (args: TaskFieldLookupUpdaterArgs) => Promise<void>
  label: (args: TaskFieldLookupUpdaterArgs) => Promise<void>
  project: (args: TaskFieldLookupUpdaterArgs) => Promise<void>
  user: (args: TaskFieldLookupUpdaterArgs) => Promise<void>
  startDate: (args: TaskFieldLookupUpdaterArgs) => Promise<void>
  deadline: (args: TaskFieldLookupUpdaterArgs) => Promise<void>
  stage: (args: TaskFieldLookupUpdaterArgs) => Promise<void>
  workspace: (args: TaskFieldLookupUpdaterArgs) => Promise<void>
  default: (args: TaskFieldLookupUpdaterArgs) => Promise<boolean>
} & {
  [key in AvailableCustomFieldTypes]: (
    args: CustomFieldMoveHandlerParams
  ) => Promise<boolean>
}

/*
 * This hook is used to move a task from one group to another.
 * It is used in the Kanban board to move tasks between columns.
 *
 * @returns A function that moves a task from one group to another.
 */
export const useMoveTask = () => {
  const { getWorkspaceStatusByName, getWorkspaceLabelByName } =
    useWorkspaceFns()

  const updateTaskStatus = useTaskStatusUpdater()
  const updateTaskAssignee = useTaskAssigneeUpdater()
  const updateTaskPriority = useTaskPriorityUpdater()
  const updateTaskProject = useTaskProjectUpdater()
  const updateTaskLabels = useTaskLabelsUpdater()
  const updateTaskStartDate = useTaskStartDateUpdater()
  const updateTaskDeadline = useTaskDeadlineUpdater()
  const customFieldMoveHandler = useCustomFieldMoveHandler()
  const updateTaskStage = useTaskStageDropUpdater()

  const doMoveTask = useMemo(() => {
    return createLookup<TaskFieldUpdaterLookup>({
      status: async ({
        entity,
        groupNode,
        workspaceId,
      }: {
        entity: NormalTaskWithRelations
        groupNode: GroupedNode
        workspaceId: string
      }) => {
        const statusId = getWorkspaceStatusByName(
          workspaceId,
          groupNode.key
        )?.id

        if (!statusId) {
          return
        }

        return updateTaskStatus(entity, statusId)
      },
      priority: async ({
        entity,
        groupNode,
      }: {
        entity: NormalTaskWithRelations
        groupNode: GroupedNode
      }) => {
        return updateTaskPriority(entity, groupNode.key as PriorityLevelSchema)
      },
      label: async ({
        entity: task,
        groupNode,
        sourceGroupNode,
        workspaceId,
      }: {
        entity: NormalTaskWithRelations
        groupNode: GroupedNode
        sourceGroupNode: GroupedNode
        workspaceId: string
      }) => {
        if (isNoneGroup(groupNode)) {
          await updateTaskLabels(task, [])
          return
        }

        const labelId = getWorkspaceLabelByName(workspaceId, groupNode.key)?.id
        const sourceLabelId = getWorkspaceLabelByName(
          workspaceId,
          sourceGroupNode.key
        )?.id

        if (!labelId || labelId === sourceLabelId || !sourceLabelId) {
          return
        }

        // If the source group is none group, just add the new label
        if (isNoneGroup(sourceGroupNode)) {
          await updateTaskLabels(task, [labelId])
          return
        }

        // If the new label is already in the list, just remove the source label
        if (task.labelIds.includes(labelId)) {
          await updateTaskLabels(
            task,
            task.labelIds.filter((id) => id !== sourceLabelId)
          )
        }

        // Get task label IDs, replace the source label ID with the new label ID
        const newLabelIds = task.labelIds.map((id) => {
          if (id === sourceLabelId) {
            return labelId
          }

          return id
        })

        // Ensure the new label ID is not already in the list
        await updateTaskLabels(task, newLabelIds)
      },
      project: async ({
        entity,
        groupNode,
      }: {
        entity: NormalTaskWithRelations
        groupNode: GroupedNode
      }) => {
        if (isNoneGroup(groupNode)) {
          return updateTaskProject(entity, null)
        }

        return updateTaskProject(entity, groupNode.key)
      },
      user: async ({
        entity,
        groupNode,
      }: {
        entity: NormalTaskWithRelations
        groupNode: GroupedNode
      }) => {
        if (isNoneGroup(groupNode)) {
          return updateTaskAssignee(entity, null)
        }

        return updateTaskAssignee(entity, groupNode.key)
      },
      startDate: async ({
        entity,
        groupNode,
      }: {
        entity: NormalTaskWithRelations
        groupNode: GroupedNode
      }) => {
        if (isNoneGroup(groupNode)) {
          return updateTaskStartDate(entity, null)
        }

        return updateTaskStartDate(entity, groupNode.value.value)
      },
      deadline: async ({
        entity,
        groupNode,
      }: {
        entity: NormalTaskWithRelations
        groupNode: GroupedNode
      }) => {
        if (isNoneGroup(groupNode)) {
          return updateTaskDeadline(entity, null)
        }

        return updateTaskDeadline(entity, groupNode.value.value ?? null)
      },
      stage: async ({
        entity,
        groupNode,
      }: {
        entity: NormalTaskWithRelations
        groupNode: GroupedNode
      }) => {
        return updateTaskStage(entity, groupNode.value.value)
      },
      workspace: async () => {},
      text: customFieldMoveHandler,
      date: customFieldMoveHandler,
      number: customFieldMoveHandler,
      url: customFieldMoveHandler,
      select: customFieldMoveHandler,
      multiSelect: customFieldMoveHandler,
      person: customFieldMoveHandler,
      multiPerson: customFieldMoveHandler,
      default: async () => false,
    })
  }, [
    customFieldMoveHandler,
    getWorkspaceLabelByName,
    getWorkspaceStatusByName,
    updateTaskAssignee,
    updateTaskDeadline,
    updateTaskLabels,
    updateTaskPriority,
    updateTaskProject,
    updateTaskStage,
    updateTaskStartDate,
    updateTaskStatus,
  ])

  return useCallback(
    async <T extends GroupedNode>({
      item,
      group,
      sourceGroup,
    }: {
      item: GroupedNode
      group: Tree<T>
      sourceGroup: Tree<T>
    }) => {
      const groupNode = group.item
      const sourceGroupNode = sourceGroup?.item

      const task = item.value.value as NormalTaskWithRelations
      const workspaceId = task.workspaceId

      if (!groupNode || !sourceGroupNode || !task || !workspaceId) {
        return
      }

      const movementType = getMovementType(groupNode.value.type)

      await doMoveTask(movementType)({
        entity: task,
        groupNode,
        sourceGroupNode,
        workspaceId,
      })
    },
    [doMoveTask]
  )
}
