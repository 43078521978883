import { ChevronRightOutline, type SvgIcon } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'
import { NOOP_FUNCTION } from '@motion/utils/function'

import {
  DebugModeLabel,
  type SortableItemData,
  SortableTreeviewItem,
  type SortableTreeviewItemProps,
} from '~/areas/treeviews/components'
import { useDebugMode } from '~/global/hooks/use-debug-mode'
import { type ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

export type TreeviewItemProps = Pick<
  SortableTreeviewItemProps<SortableItemData>,
  'disableDrag' | 'renderContextMenuContent' | 'sortableData'
> & {
  icon?: SvgIcon
  label: ReactNode
  onToggleExpand?: (newState: boolean) => void
  renderButtons?: () => ReactNode

  /**
   * Set `true` when the item should be rendered as a "ghost" item that cannot
   * be interacted with, shows at 50% opacity, and shows debug info if enabled
   */
  isGhost?: boolean

  /**
   * Set `true` when the user is dragging an item in the sidebar and it will be
   * dropped into this item, such as a workspace or folder
   */
  isInside?: boolean
}

export const treeviewIndentationWidth: number = 20
export const treeviewItemHeight = 40

export function TreeviewItem({
  disableDrag,
  icon: Icon,
  isGhost = false,
  isInside = false,
  label,
  onToggleExpand = NOOP_FUNCTION,
  renderButtons,
  renderContextMenuContent,
  sortableData,
}: TreeviewItemProps) {
  const isDebugMode = useDebugMode()

  const {
    level,
    order,
    item: { expanded, id, isContainer, url },
  } = sortableData

  const indentWidth = treeviewIndentationWidth * level

  return (
    <ItemWrapper
      disableDrag={disableDrag}
      id={id}
      indentWidth={isGhost ? 0 : indentWidth}
      renderContextMenuContent={renderContextMenuContent}
      renderOnDrag={() => (
        <div
          className='relative bg-semantic-primary-bg-strong-default w-full h-0.5 rounded-full -top-px'
          style={{ marginLeft: level === 0 ? 0 : indentWidth + 32 }}
        />
      )}
      sortableData={sortableData}
      isGhost={isGhost}
      isInside={isInside}
    >
      <div className='flex flex-row items-center w-full min-w-0 gap-2 pl-2'>
        {!isGhost && (
          <div className='size-6 shrink-0'>
            {isContainer && (
              <Button
                sentiment='neutral'
                variant='muted'
                size='small'
                iconOnly
                onClick={(e) => {
                  e.stopPropagation()
                  onToggleExpand(!expanded)
                }}
                aria-label={expanded ? 'Collapse group' : 'Expand group'}
              >
                <ChevronRightOutline
                  className={twMerge(
                    'size-4 transition-all text-semantic-neutral-icon-default',
                    expanded && 'rotate-90'
                  )}
                />
              </Button>
            )}
          </div>
        )}

        <Link
          to={url}
          className='py-2 flex flex-row items-center gap-2 w-full min-w-0'
        >
          {Icon != null && <Icon className='size-4' />}

          <span className='truncate'>{label}</span>

          {!isGhost && renderButtons && (
            <div className='invisible group-hover/treeview-item:visible'>
              {renderButtons()}
            </div>
          )}

          {isDebugMode && (
            <DebugModeLabel show={isGhost} className='ml-auto mr-4'>
              {order}
            </DebugModeLabel>
          )}
        </Link>
      </div>
    </ItemWrapper>
  )
}

export const ItemWrapper = classed(SortableTreeviewItem, {
  base: `
    group/treeview-item
  
    text-semantic-neutral-text-default
    text-sm leading-6
    px-3 

    border-b border-semantic-neutral-border-light
    bg-semantic-neutral-surface-bg-default

    hover:bg-semantic-neutral-surface-bg-subtle
  `,
  variants: {
    isInside: {
      true: 'bg-semantic-primary-bg-hover text-semantic-primary-text-default',
      false: '',
    },
    isGhost: {
      true: `
        cursor-grabbing
        bg-semantic-neutral-bg-hover 
        border border-semantic-neutral-border-strong
        opacity-50 shadow rounded-md
        max-w-[400px]
      `,
      false: '',
    },
  },
  defaultVariants: {
    isInside: false,
    isGhost: false,
  },
})
