import { LoadingSpinner, Modal } from '@motion/ui/base'
import { useGetTierPrices } from '@motion/web-billing'
import { useModalStatus } from '@motion/web-common/modals'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useCurrentTier } from '~/areas/tiered-pricing/hooks'
import { useGetTeamPrices } from '~/global/rpc/team'

import {
  AnnualUpgradeModal,
  type AnnualUpgradeModalProps,
} from './annual-upgrade-modal'

export type ConnectedAnnualUpgradeModalProps = Omit<
  AnnualUpgradeModalProps,
  'onClose' | 'teamPrices' | 'tierPrices'
>

export const ConnectedAnnualUpgradeModal = ({
  close,
  ...props
}: ModalTriggerComponentProps<'annual-upgrade-modal'>) => {
  const status = useModalStatus('annual-upgrade-modal')
  const tier = useCurrentTier()
  const { data: teamPrices, isLoading: isTeamPriceLoading } = useGetTeamPrices()
  const { data: tierPrices, isLoading: isTierPriceLoading } =
    useGetTierPrices(tier)

  if (!status.visible) {
    return null
  }

  if (isTeamPriceLoading || isTierPriceLoading) {
    return (
      <Modal withAnimation visible onClose={close}>
        {/* This is a best-effort match of the dynamic modal content */}
        <div className='bg-semantic-neutral-bg-default w-[464px] h-[328px] grid place-items-center'>
          <LoadingSpinner />
        </div>
      </Modal>
    )
  }

  if (!teamPrices) {
    return null
  }

  return (
    <AnnualUpgradeModal
      teamPrices={teamPrices}
      tierPrices={tierPrices}
      onClose={close}
      {...props}
    />
  )
}
