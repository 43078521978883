import { createFetch } from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
  type V2ResponseStoreShape,
} from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { merge } from '@motion/utils/core'

import { notifyManager } from '@tanstack/react-query'

import { type HandlerContext } from './types'
import { buildQueryContext } from './utils'

import { log } from '../log'
import { type ProjectDefinitionBatchOperations } from '../types'

const KEYS_TO_UPDATE = [MODEL_CACHE_KEY, API.workspacesV2.queryKeys.root]

const updateFilter = createQueryFilter(KEYS_TO_UPDATE)

export async function refreshProjectDefinition(
  ctx: HandlerContext,
  mutations: ProjectDefinitionBatchOperations['data']
) {
  log.info('refresh-projects-definition', mutations)

  if (!mutations.update && !mutations.create && !mutations.delete) {
    log.warn('Missing mutation ids')
    return
  }

  const getProjectDefinition = createFetch<
    typeof API.projectDefinitions.getProjectDefinitionFull
  >(API.projectDefinitions.getProjectDefinitionFull, buildQueryContext(ctx))

  const projectDefinitionsToFetch = Array.from(
    new Set([...mutations.update, ...mutations.create])
  ).filter(
    (projectDefinition) => !mutations.delete.includes(projectDefinition.id)
  )

  const projectDefinitionResolutions = await Promise.allSettled(
    projectDefinitionsToFetch.map((projectDefinition) => {
      return getProjectDefinition(projectDefinition)
    })
  )
  const projectDefinitions = projectDefinitionResolutions
    .filter((x) => x.status === 'fulfilled')
    .map((x) => x.value)

  const singleUpdate: V2ResponseStoreShape = {
    ids: projectDefinitions.map((p) => p.id),
    meta: {
      model: 'projectDefinitions',
    },
    models: {
      projectDefinitions: merge(
        {},
        ...projectDefinitions.map((x) => x.models.projectDefinitions)
      ),
    },
  }

  notifyManager.batch(() => {
    MotionCache.upsert(ctx.client, updateFilter, singleUpdate)

    if (mutations.delete.length) {
      MotionCache.delete(
        ctx.client,
        updateFilter,
        'projectDefinitions',
        mutations.delete
      )
    }
  })
}
