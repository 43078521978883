import {
  DownloadOutline,
  DuplicateOutline,
  EyeOffSolid,
  EyeSolid,
  TrashSolid,
} from '@motion/icons'
import { type UploadedFileSchema } from '@motion/rpc-types'
import { type ActionSection } from '@motion/ui/base'

import { useAttachmentActions } from '~/areas/attachments/hooks'

import { fileIsPreviewable } from '../../utils'

export const useAttachmentActionItems = (
  attachment: UploadedFileSchema,
  options: { isPreview: boolean },
  handlers: {
    onRename?: () => void
    onDelete: () => void
    onTogglePreview: () => void
  }
): { sections?: ActionSection[] } => {
  const { deleteAttachment, downloadAttachment, copyAttachmentLink } =
    useAttachmentActions()

  const handleDelete = async () => {
    const { deleted } = await deleteAttachment(attachment)
    if (deleted) {
      handlers.onDelete()
    }
  }

  const handleDownload = () => {
    downloadAttachment(attachment)
  }

  const handleCopyLink = () => {
    copyAttachmentLink(attachment)
  }

  const isPreviewable = fileIsPreviewable(attachment.mimeType)

  return {
    sections: [
      {
        items: [
          {
            prefix: <DuplicateOutline />,
            content: 'Copy link',
            onAction: handleCopyLink,
          },
          {
            prefix: <DownloadOutline />,
            content: 'Download',
            onAction: handleDownload,
          },
          isPreviewable && {
            prefix: options.isPreview ? <EyeOffSolid /> : <EyeSolid />,
            content: options.isPreview ? 'Hide preview' : 'Show preview',
            onAction: handlers.onTogglePreview,
          },
        ].filter(Boolean),
      },
      {
        items: [
          {
            prefix: <TrashSolid />,
            content: 'Delete',
            destructive: true,
            onAction: handleDelete,
          },
        ],
      },
    ],
  }
}
