import { useHasTreatment } from '@motion/web-common/flags'

import { NoteActionsList } from '~/areas/notes'
import { ViewActionsMenuContents } from '~/areas/sidebar/treeviews/components/view-actions-menu'

import {
  ConnectedProjectActionList,
  FolderActionsPopoverContents,
  WorkspaceActionList,
} from './actions'

import { type SortableWorkspacesTreeviewItem } from '../types'

type MoveActionAllowed = {
  up: boolean
  down: boolean
}

export type TreeviewItemContextMenuProps = {
  close: () => void
  item: SortableWorkspacesTreeviewItem['item']
  moveActionAllowed?: MoveActionAllowed
  onMoveItem: (moveBy: -1 | 1) => void | Promise<void>
  level: number
}

export function TreeviewItemContextMenu({
  close,
  item,
  moveActionAllowed,
  onMoveItem,
}: TreeviewItemContextMenuProps) {
  const hasNotes = useHasTreatment('notes-in-webapp')

  switch (item.type) {
    case 'INDIVIDUAL_WORKSPACE':
    case 'TEAM_WORKSPACE':
      return (
        <WorkspaceActionList
          close={close}
          workspaceId={item.workspaceId}
          moveActionAllowed={moveActionAllowed}
          onMoveItem={onMoveItem}
        />
      )

    case 'FOLDER':
      return (
        <FolderActionsPopoverContents
          close={close}
          folderId={item.itemId}
          onMoveItem={onMoveItem}
          moveActionAllowed={moveActionAllowed}
        />
      )

    case 'PROJECT':
      return (
        <ConnectedProjectActionList
          close={close}
          projectId={item.itemId}
          options={{
            showColors: true,
            allowProjectResolution: true,
            showMoves: !!moveActionAllowed,
            disabledMoveActions: moveActionAllowed
              ? {
                  up: !moveActionAllowed.up,
                  down: !moveActionAllowed.down,
                }
              : undefined,
            onMoveItem: onMoveItem,
          }}
        />
      )

    case 'NOTE':
      return (
        hasNotes && (
          <NoteActionsList
            close={close}
            noteId={item.itemId}
            moveActionAllowed={moveActionAllowed}
            onMoveItem={onMoveItem}
            location='SIDEBAR'
          />
        )
      )

    case 'VIEW':
      return (
        <ViewActionsMenuContents
          close={close}
          itemId={item.id}
          moveActionAllowed={moveActionAllowed}
          onMoveItem={onMoveItem}
          url={item.url}
        />
      )

    default:
      return null
  }
}
