import {
  BanSolid,
  BlockedBySolid,
  BlocksSolid,
  CalendarXSolid,
  ClockSolid,
  DeadlineSolid,
  ExclamationCircleSolid,
  LockOpenSolid,
  MoonSolid,
  ScheduledDateSolid,
  StartDateSolid,
} from '@motion/icons'
import {
  type ActionItem,
  ActionList,
  type ActionSection,
  showToast,
} from '@motion/ui/base'
import { getQuickActionsForDoLater } from '@motion/ui-logic'
import {
  canAddTime,
  canCancelTask,
  canCompleteTask,
  canDoASAP,
  canDoLater,
  canEditTaskDeadline,
  canEditTaskStartDate,
  canHaveBlockers,
  isSchedulingTask,
} from '@motion/ui-logic/pm/task'
import { roundTime } from '@motion/utils/dates'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type RecurringTaskSchema, type TaskSchema } from '@motion/zod/client'

import {
  DeadlineDateDropdownContent,
  StartDateDropdownContent,
} from '~/areas/project-management/components'
import { SchedulingEventDropdownContent } from '~/areas/task-project/components'
import { useCachedItem } from '~/global/cache'
import { StatusBadge } from '~/global/components/badges'
import { useWorkspaceFns } from '~/global/hooks'
import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'

import {
  type SectionProps,
  useArchiveDeleteSection,
  useDuplicateSection,
  useOpenLinkSection,
  useStartStopActionItems,
} from './hooks'
import { wrapWithAnalytics } from './utils'

import {
  useDoTaskASAP,
  useDoTaskLater,
  useResolveTask,
  useTaskUpdater,
} from '../../hooks'
import { getParentChunkId } from '../../utils'
import { AddTaskDurationActionList } from '../add-task-duration-action-list'
import { NormalTaskBlockersContent } from '../dropdowns'

export type TaskActionListProps = {
  close: () => void
  task: TaskSchema | RecurringTaskSchema
  options?: TaskActionOptions
}
export const TaskActionList = ({
  close,
  task,
  options,
}: TaskActionListProps) => {
  const { sections } = useTaskActionItems(task, options)

  return <ActionList sections={sections} onActionAnyItem={close} />
}

interface TaskActionOptions {
  copyShortcutLabel?: Exclude<ActionItem, false>['shortcut']
  duplicateShortcutLabel?: Exclude<ActionItem, false>['shortcut']
}

const useTaskActionItems = (
  task: TaskSchema | RecurringTaskSchema,
  options: TaskActionOptions = READONLY_EMPTY_OBJECT
): { sections: ActionSection[] } => {
  const { copyShortcutLabel, duplicateShortcutLabel } = options

  const parentChunkTask = useCachedItem('tasks', getParentChunkId(task))
  const parentChunkOrDirectTask = parentChunkTask ?? task

  const getCompleteCancelSection = useCompleteCancelSection()
  const getOpenLinkSection = useOpenLinkSection({ copyShortcutLabel })
  const getMainSection = useMainSection(task.id)
  const getDuplicateSection = useDuplicateSection({ duplicateShortcutLabel })
  const getUnlockUnscheduledSection = useUnlockUnscheduledSection()
  const getArchiveDeleteSection = useArchiveDeleteSection()

  const { sections } = useMemo(() => {
    return {
      sections: [
        getCompleteCancelSection({ task, parentChunkOrDirectTask }),
        getOpenLinkSection({ task, parentChunkOrDirectTask }),
        getMainSection({ task, parentChunkOrDirectTask }),
        getDuplicateSection({ task, parentChunkOrDirectTask }),
        getBlockerSection(task),
        getUnlockUnscheduledSection({ task, parentChunkOrDirectTask }),
        getArchiveDeleteSection({ task, parentChunkOrDirectTask }),
      ] as ActionSection[],
    }
  }, [
    getCompleteCancelSection,
    task,
    parentChunkOrDirectTask,
    getOpenLinkSection,
    getMainSection,
    getDuplicateSection,
    getUnlockUnscheduledSection,
    getArchiveDeleteSection,
  ])

  return { sections }
}

function useMainSection(taskId: string) {
  const doTaskLater = useDoTaskLater()
  const doTaskASAP = useDoTaskASAP()
  const updateTask = useTaskUpdater()
  const modalApi = useModalApi()

  const getStartStopItems = useStartStopActionItems(taskId)

  return useCallback(
    ({ task, parentChunkOrDirectTask }: SectionProps): ActionSection => {
      return {
        items: [
          ...getStartStopItems({ task, parentChunkOrDirectTask }),

          canEditTaskStartDate(parentChunkOrDirectTask) && {
            content: 'Change start date',
            prefix: <StartDateSolid />,
            renderPopover: ({ close }) => (
              <StartDateDropdownContent
                value={parentChunkOrDirectTask.startDate}
                onChange={async (value: string | null) => {
                  wrapWithAnalytics('change_start_date')(async () => {
                    await updateTask(parentChunkOrDirectTask.id, {
                      startDate: value,
                    })
                    close()
                  })()
                }}
                contextProps={{ taskId: parentChunkOrDirectTask.id }}
                dropdownTarget='task'
              />
            ),
          },
          canEditTaskDeadline(parentChunkOrDirectTask) && {
            content: 'Change deadline',
            prefix: <DeadlineSolid />,
            renderPopover: ({ close }) => (
              <DeadlineDateDropdownContent
                value={parentChunkOrDirectTask.dueDate}
                onChange={async (value: string | null) => {
                  wrapWithAnalytics('change_deadline')(async () => {
                    await updateTask(parentChunkOrDirectTask.id, {
                      dueDate: value,
                    })
                    close()
                  })()
                }}
                contextProps={{ taskId: parentChunkOrDirectTask.id }}
                dropdownTarget='task'
              />
            ),
          },
          canAddTime(parentChunkOrDirectTask) && {
            content: 'Add time to task',
            prefix: <ClockSolid />,
            renderPopover({ close }) {
              const currentDuration = parentChunkOrDirectTask.duration ?? 0
              return (
                <AddTaskDurationActionList
                  close={close}
                  currentDuration={currentDuration}
                  onSelect={async (duration: number) => {
                    wrapWithAnalytics('add_time')(async () => {
                      await updateTask(parentChunkOrDirectTask, {
                        duration: currentDuration + duration,
                      })
                    })()
                  }}
                />
              )
            },
          },
          canDoLater(task) && {
            content: task.snoozeUntil ? 'Change "do later"' : 'Do later',
            prefix: <MoonSolid />,
            renderPopover({ close }) {
              const quickActions = getQuickActionsForDoLater(
                task.scheduledStart
                  ? DateTime.fromISO(task.scheduledStart)
                  : roundTime(DateTime.now(), 15)
              )
              const items = [
                ...quickActions.map((item) => ({
                  content: item.label,
                  onAction: wrapWithAnalytics('do_later')(() => {
                    doTaskLater(parentChunkOrDirectTask.id, {
                      snoozeUntil: item.value.toISO(),
                      source: 'context-menu',
                    })
                  }),
                })),
                {
                  content: 'Specify Time',
                  onAction: wrapWithAnalytics('do_later')(async () => {
                    const response = await modalApi.prompt('do-task-later', {
                      initialTime: roundTime(DateTime.now(), 15),
                    })
                    if (response === ModalDismissed || response === undefined) {
                      return
                    }
                    await doTaskLater(parentChunkOrDirectTask.id, {
                      snoozeUntil: response.snoozeUntil.toISO(),
                      source: 'custom-modal',
                    })
                  }),
                },
              ]
              return <ActionList onActionAnyItem={close} items={items} />
            },
          },
          canDoASAP(parentChunkOrDirectTask) && {
            content: 'Do ASAP',
            prefix: <ExclamationCircleSolid />,
            onAction: wrapWithAnalytics('do_asap')(() => {
              doTaskASAP(parentChunkOrDirectTask.id)
            }),
          },
        ],
      }
    },
    [doTaskASAP, doTaskLater, getStartStopItems, modalApi, updateTask]
  )
}

function getBlockerSection(
  task: TaskSchema | RecurringTaskSchema
): ActionSection {
  if (!canHaveBlockers(task)) return false

  return {
    items: [
      {
        prefix: <BanSolid />,
        content: 'Set blockers',
        onAction: wrapWithAnalytics('set_blockers')(),
        renderPopover: () => (
          <ActionList
            items={[
              {
                prefix: (
                  <BlockedBySolid className='!text-semantic-error-icon-default' />
                ),
                content: 'Mark as blocked by',
                onAction: wrapWithAnalytics('set_blockers_blocked_by')(),
                renderPopover: ({ close }) => (
                  <NormalTaskBlockersContent
                    close={close}
                    task={task}
                    type='blockedBy'
                  />
                ),
              },
              {
                prefix: (
                  <BlocksSolid className='!text-semantic-warning-icon-default' />
                ),
                content: 'Mark as blocking',
                onAction: wrapWithAnalytics('set_blockers_blocking')(),
                renderPopover: ({ close }) => (
                  <NormalTaskBlockersContent
                    close={close}
                    task={task}
                    type='blocks'
                  />
                ),
              },
            ]}
          />
        ),
      },
    ],
  }
}

function useUnlockUnscheduledSection() {
  const updateTask = useTaskUpdater()

  return useCallback(
    ({ task, parentChunkOrDirectTask }: SectionProps): ActionSection => {
      const isRecurringInstance = task.type === 'RECURRING_INSTANCE'

      const canUnlock = task.type !== 'RECURRING_TASK' && task.isFixedTimeTask
      const canUnschedule = task.isAutoScheduled

      return {
        items: [
          canUnlock && {
            content: 'Unlock task',
            prefix: <LockOpenSolid />,
            onAction: wrapWithAnalytics('unlock')(async () => {
              await updateTask(task, { isFixedTimeTask: false })
            }),
          },
          canUnschedule && {
            content: 'Unschedule',
            prefix: <CalendarXSolid />,
            disabled: isRecurringInstance,
            tooltip: isRecurringInstance
              ? 'Recurring instances must be auto-scheduled'
              : null,
            onAction: wrapWithAnalytics('unschedule')(async () => {
              await updateTask(parentChunkOrDirectTask, {
                isAutoScheduled: false,
              })
              showToast(
                'success',
                'This task has been unscheduled. You can find it in My Tasks.'
              )
            }),
          },
        ],
      }
    },
    [updateTask]
  )
}

function useCompleteCancelSection() {
  const { completeTask, cancelTask } = useResolveTask()
  const {
    getStatusById,
    getWorkspaceCanceledStatus,
    getWorkspaceCompletedStatus,
  } = useWorkspaceFns()

  return useCallback(
    ({ task }: SectionProps): ActionSection => {
      const taskStatus = getStatusById(task.statusId)
      const cancelStatus = getWorkspaceCanceledStatus(task.workspaceId)
      const completedStatus = getWorkspaceCompletedStatus(task.workspaceId)

      return {
        items: [
          canCompleteTask(task) && {
            content: 'Complete task',
            prefix: <StatusBadge value={completedStatus} />,
            onAction: wrapWithAnalytics('complete')(async () => {
              await completeTask(task.id)
            }),
          },
          canCancelTask(task, { status: taskStatus }) && {
            content: 'Cancel task',
            prefix: <StatusBadge value={cancelStatus} />,
            onAction: wrapWithAnalytics('cancel')(async () => {
              await cancelTask(task.id)
            }),
          },
          isSchedulingTask(task) &&
            task.completedTime == null && {
              content: 'Schedule event',
              prefix: (
                <ScheduledDateSolid className='!text-semantic-primary-icon-default' />
              ),
              renderPopover: ({ close }) => {
                return (
                  <SchedulingEventDropdownContent
                    close={close}
                    taskId={task.id}
                    onAction={wrapWithAnalytics('schedule_event')()}
                  />
                )
              },
            },
        ],
      }
    },
    [
      cancelTask,
      completeTask,
      getStatusById,
      getWorkspaceCanceledStatus,
      getWorkspaceCompletedStatus,
    ]
  )
}
