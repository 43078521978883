import {
  AllTasksGradientSolid,
  CogSolid,
  FilledChevronDownSolid,
  FilledChevronUpSolid,
  MyTasksGradientSolid,
  ProjectGradientSolid,
} from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { classed } from '@motion/theme'
import { Button, ButtonGroup, showToast } from '@motion/ui/base'
import { FolderColoredIcon } from '@motion/ui/project'
import { ConditionalWrapper } from '@motion/ui/utils'
import { type FilterTarget, useResetFilter } from '@motion/ui-logic/pm/data'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'
import { type WorkspaceSchema } from '@motion/zod/client'

import { useProjectUpdater } from '~/areas/project/hooks'
import { FilterBar } from '~/areas/project-management/filters'
import {
  ConnectedFilterButtons,
  ConnectedWorkspaceFilterButton,
  FilterPanel,
} from '~/areas/project-management/filters/components/v2'
import { ConnectedFiltersToggle } from '~/areas/project-management/filters/components/v2/filters-toggle'
import { OpenLeftSidebarButton } from '~/areas/sidebar/components'
import { useLookup } from '~/global/cache'
import {
  ConnectedProjectIcon,
  MotionLink,
  TutorialButtonLink,
} from '~/global/components'
import { WorkspaceBadge } from '~/global/components/badges'
import {
  ColorDropdown,
  type ColorDropdownContentProps,
} from '~/global/components/dropdowns'
import { useProject } from '~/global/hooks'
import { useProjectModalUrl } from '~/global/navigation'
import { useUpdateFolder } from '~/global/rpc/folders'
import { useUriByRouteId } from '~/routing'
import { type ReactNode, useState } from 'react'
import { useParams } from 'react-router-dom'

import { HeaderBreadcrumbs } from './header-breadcrumbs'
import { PageTabs } from './page-tabs'
import { ProjectsCountTag } from './projects-count-tag'

import {
  ConnectedAddDashboardCardButton,
  ConnectedCreateDashboardButton,
  ConnectedGroupByButton,
  ConnectedHeaderDotsButton,
  ConnectedNameSearchButton,
  ConnectedPageSelector,
  ConnectedShowCalendarsButton,
  ConnectedSortButton,
  ConnectedViewDetailsButton,
} from '../components'
import { ItemCounter } from '../components/connected-item-counter'
import { ConnectedShowCompletedButton } from '../components/connected-show-completed-button'
import { ConnectedShowPastDeadlineButton } from '../components/connected-show-past-deadline-button'
import { ConnectedShowProjectSidebarButton } from '../components/connected-show-project-sidebar-button'
import {
  useProjectFilterDefinitions,
  useTaskFilterDefinitions,
} from '../filtering/hooks'
import { KanbanCardFieldsButton } from '../kanban'
import { PlannerNavigateButtons } from '../planner'
import { usePageData } from '../routes'
import {
  type PageType,
  type PMPageUrlParams,
  type RouteData,
} from '../routes/types'
import { useViewState } from '../view-state'

export type PageHeaderProps = {
  page: RouteData['page']
  title: ReactNode
  children?: ReactNode
  isArchivePage?: boolean
  workspaceId?: WorkspaceSchema['id']

  renderTitleRightSection?: () => ReactNode
}

export function PageHeader({
  page,
  title,
  children,
  isArchivePage = false,
  workspaceId,
  renderTitleRightSection,
}: PageHeaderProps) {
  const getRouteUri = useUriByRouteId({ noDefaults: true })

  return (
    <HeaderBar>
      <PaddedContainer>
        <HeaderBreadcrumbs page={page} />
      </PaddedContainer>
      <PaddedContainer className='flex'>
        <OpenLeftSidebarButton className='mr-2' />
        <TitleBarContainer>
          <TitleBarTitle page={page}>{title}</TitleBarTitle>
          {!isArchivePage && <ConnectedViewDetailsButton />}
          {!isArchivePage && <ConnectedHeaderDotsButton />}
          <div className='flex-1' />

          <ButtonGroup>
            <ConnectedShowProjectSidebarButton />
            <ConnectedCreateDashboardButton />

            {workspaceId != null && (
              <Button
                size='small'
                sentiment='neutral'
                url={getRouteUri('workspace-settings', {
                  workspaceId,
                })}
              >
                <CogSolid />
                Workspace Settings
              </Button>
            )}

            {renderTitleRightSection?.()}
          </ButtonGroup>
        </TitleBarContainer>
      </PaddedContainer>
      {children}
    </HeaderBar>
  )
}

type ConnectedHeaderProps = {
  title: ReactNode
  target: FilterTarget
}
export const ConnectedHeader = (props: ConnectedHeaderProps) => {
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [subHeaderVisible, setSubHeaderVisible] = useState(true)
  const resetFilters = useResetFilter()
  const allAvailableTaskFilters = useTaskFilterDefinitions()
  const allAvailableProjectFilters = useProjectFilterDefinitions()
  const [viewState] = useViewState()
  const hasBrowseViewEnabled = useHasTreatment('browse-view')

  const route = usePageData()

  const isGantt = viewState.page === 'gantt'
  const isDashboard =
    // @ts-expect-error - need to fix the route rendering
    route.variant === 'dashboard' || viewState.page === 'dashboard'

  const data = usePageData()
  const filterDefinitions = {
    tasks: allAvailableTaskFilters.filter(data.predicates.tasks.filters),
    projects: allAvailableProjectFilters.filter(
      data.predicates.projects.filters
    ),
  }

  const isArchivePage = data.variant === 'archive'

  const canFilterTasks =
    props.target !== 'projects' && filterDefinitions.tasks.length > 0
  const canFilterProjects = filterDefinitions.projects.length > 0

  const showFilterPanel =
    !isArchivePage &&
    !isDashboard &&
    filtersVisible &&
    (canFilterTasks || canFilterProjects) &&
    subHeaderVisible

  const showBrowseButton =
    hasBrowseViewEnabled &&
    (
      [
        'all-tasks',
        'all-projects',
        'my-tasks',
        'workspace',
        'folder',
        'project',
      ] satisfies PageType[]
    ).includes(route.page)

  return (
    <PageHeader
      page={data.page}
      title={props.title}
      isArchivePage={isArchivePage}
      workspaceId={data.lock.workspaceId}
      renderTitleRightSection={() => (
        <>
          {!isArchivePage && !isGantt && !isDashboard && (
            <TutorialButtonLink
              lesson='views-reports'
              rounded
              text='List View tutorial'
              onClick={() => {
                recordAnalyticsEvent('PROJECT_MANAGEMENT_TUTORIAL_CLICK', {
                  target: 'pivot-table-blog-post',
                  source: 'header',
                })
              }}
            />
          )}

          {!isArchivePage && isGantt && (
            <TutorialButtonLink
              lesson='views-reports'
              rounded
              text='Gantt View tutorial'
              onClick={() => {
                recordAnalyticsEvent(
                  'PROJECT_MANAGEMENT_PLANNER_TUTORIAL_CLICK'
                )
              }}
            />
          )}
        </>
      )}
    >
      {!isArchivePage && (
        <PageTabs showBrowseButton={showBrowseButton}>
          <div className='[&>*]:whitespace-nowrap flex items-center gap-1'>
            {isDashboard ? (
              <ConnectedAddDashboardCardButton />
            ) : (
              <>
                <ConnectedNameSearchButton />
                <Button
                  size='small'
                  variant='muted'
                  sentiment='neutral'
                  onClick={() => setSubHeaderVisible((prev) => !prev)}
                >
                  {subHeaderVisible ? (
                    <FilledChevronUpSolid />
                  ) : (
                    <FilledChevronDownSolid />
                  )}
                  {templateStr('{{action}} options', {
                    action: subHeaderVisible ? 'Hide' : 'Show',
                  })}
                </Button>
              </>
            )}
          </div>
        </PageTabs>
      )}
      {!isArchivePage && subHeaderVisible && !isDashboard && (
        <SubheaderRow isGantt={isGantt}>
          <FilterBar>
            <ConnectedGroupByButton />
            <ConnectedPageSelector />
            <ConnectedSortButton />
            {viewState.page === 'kanban' && <KanbanCardFieldsButton />}
            {data.lock.workspaceId == null && (
              <ConnectedWorkspaceFilterButton />
            )}
            <ConnectedFiltersToggle
              open={filtersVisible}
              onClick={() => setFiltersVisible((prev) => !prev)}
              clearable
              onClear={resetFilters}
            />
            {isGantt && <ConnectedShowCalendarsButton />}
            <ItemCounter />
          </FilterBar>
          {isGantt ? (
            <>
              <PlannerNavigateButtons />
              <ConnectedShowCompletedButton />
            </>
          ) : (
            <div className='flex flex-wrap items-center gap-3'>
              <ConnectedShowPastDeadlineButton />
              <ConnectedShowCompletedButton />
            </div>
          )}
        </SubheaderRow>
      )}
      {showFilterPanel && (
        <FilterPanel as={PaddedContainer}>
          {canFilterProjects && (
            <ConnectedFilterButtons
              definitions={filterDefinitions.projects}
              applyTo='projects'
              title='Projects'
            />
          )}
          {canFilterTasks && (
            <ConnectedFilterButtons
              definitions={filterDefinitions.tasks}
              applyTo='tasks'
              title='Tasks'
            />
          )}
        </FilterPanel>
      )}
    </PageHeader>
  )
}

type TitleBarTitleProps = {
  children?: ReactNode
  page: RouteData['page']
}

const TitleBarTitle = (props: TitleBarTitleProps) => {
  const buildProjectModalUrl = useProjectModalUrl()

  return (
    <TitleBarHeader>
      <div className='inline-flex flex-row gap-1.5 items-center'>
        <HeaderIcon pageType={props.page} />

        <ConditionalWrapper
          condition={props.page === 'project'}
          wrapper={(children) => (
            <MotionLink
              url={buildProjectModalUrl({ project: 'edit' })}
              className='truncate max-w-sm'
            >
              {children}
            </MotionLink>
          )}
        >
          {props.children}
        </ConditionalWrapper>
        <ProjectsCountTag />
      </div>
    </TitleBarHeader>
  )
}

const HeaderBar = classed('div', {
  base: `
  flex flex-col gap-3
  py-4
  `,
})

const PaddedContainer = classed('div', 'px-4')

export const TitleBarContainer = classed('div', {
  base: `
    group/titlebar
    flex flex-wrap flex-1 items-start gap-3
  `,
})

export const TitleBarHeader = classed('h2', {
  base: `
    text-semantic-neutral-text-default
    font-semibold
    text-base
  `,
})

const SubheaderRow = classed(PaddedContainer, {
  base: `
    grid
    gap-3
  `,
  variants: {
    isGantt: {
      true: `
         xl:[grid-template-columns:1fr_auto]
      `,
    },
  },
})

type HeaderIconProps = {
  pageType: RouteData['page']
}

const HeaderIcon = ({ pageType }: HeaderIconProps) => {
  switch (pageType) {
    case 'workspace':
      return (
        <div className='size-6 inline-block p-1'>
          <WorkspaceBadge />
        </div>
      )

    case 'my-tasks':
      return (
        <div className='size-6 inline-block p-1'>
          <MyTasksGradientSolid className='size-4' />
        </div>
      )

    case 'all-tasks':
      return (
        <div className='size-6 inline-block p-1'>
          <AllTasksGradientSolid className='size-4' />
        </div>
      )

    case 'all-projects':
      return (
        <div className='size-6 inline-block p-1'>
          <ProjectGradientSolid className='size-4' />
        </div>
      )

    case 'project':
      return <ProjectIcon />

    case 'folder':
      return <FolderIcon />

    default:
      return null
  }
}

function ProjectIcon() {
  const { projectId } = useParams<PMPageUrlParams>()

  const project = useProject(projectId)
  const updateProject = useProjectUpdater()

  const onChangeProjectColor: ColorDropdownContentProps['onChange'] = (
    color
  ) => {
    if (!projectId) {
      throw new Error('Cannot save project color: projectId undefined')
    }

    return updateProject(projectId, { color })
  }

  if (project == null) return null

  return (
    <ColorDropdown
      selectedColor={project.color}
      onChange={onChangeProjectColor}
    >
      <Button size='small' variant='muted' sentiment='neutral' iconOnly>
        <ConnectedProjectIcon className='size-4' projectId={project.id} />
      </Button>
    </ColorDropdown>
  )
}

function FolderIcon() {
  const { folderId } = useParams<PMPageUrlParams>()

  const lookup = useLookup()
  const { mutateAsync: updateFolder } = useUpdateFolder()
  const folder = folderId != null ? lookup('folders', folderId) : null

  const onChangeFolderColor: ColorDropdownContentProps['onChange'] = async (
    color
  ) => {
    if (!folderId) {
      throw new Error('Cannot save folder color: folderId undefined')
    }

    recordAnalyticsEvent('FOLDERS_UPDATED_FOLDER', {
      updated: 'COLOR',
      location: 'PM_HEADER',
    })

    await updateFolder({ folderId, color })

    showToast('success', 'Folder color updated')
  }

  if (folder == null) return null

  return (
    <ColorDropdown selectedColor={folder.color} onChange={onChangeFolderColor}>
      <Button size='small' variant='muted' sentiment='neutral' iconOnly>
        <FolderColoredIcon className='size-4' color={folder.color} />
      </Button>
    </ColorDropdown>
  )
}
