import { XSolid } from '@motion/icons'
import {
  Button,
  IconButton,
  LoadingSpinner,
  showToast,
  useShortcut,
} from '@motion/ui/base'
import { isTaskSchema } from '@motion/ui-logic/pm/task'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useFileUploadState } from '~/areas/attachments/contexts'
import {
  ModalErrorState,
  PreviousNextModalButtons,
  type PreviousNextModalButtonsProps,
  ProjectPanel,
  SharedFormShell,
  ShellActions,
  ShellVars,
  SidebarContent,
  SidebarSection,
  SidebarShell,
  SidebarWorkspaceSection,
  SidePanel,
  TaskAttachmentsCollapsable,
  TaskAttachmentsUploader,
} from '~/areas/task-project/components'
import { TutorialButtonLink } from '~/global/components'
import { useModalListOrder } from '~/global/contexts'
import { useTaskModalUrl, useUriByGlobalModalId } from '~/global/navigation'
import { type FormEvent } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  ArchiveBanner,
  GridBanner,
  GridShell,
  MainHeader,
  MainScrollableContent,
  RecurringTaskBannerText,
  ShellMainFooter,
  SidebarFooter,
  SidebarRecurringTaskBanner,
  TaskActivityCollapsable,
  TaskDebugInfo,
  TaskEtaRibbon,
  TaskPrevNextTooltipContent,
} from './components'
import { useTaskModalState } from './contexts'
import {
  AutoScheduleToggle,
  ControlledAssigneeField,
  ControlledBlockersField,
  ControlledDescriptionField,
  ControlledNameField,
  ControlledPriorityField,
  ControlledStageField,
  ControlledStatusField,
  ControlledWorkspaceFolderProjectField,
  CustomFieldsTaskSidebarSection,
  DeadlineField,
  DeadlineToggle,
  DurationField,
  MinChunkField,
  RecurringField,
  ScheduleField,
  StartDateField,
  TaskHeader,
  TaskLabelsField,
  WarnOnField,
} from './fields'
import { useSubmitForm, useTaskForm } from './hooks'
import { type FormDataSubmitHandler } from './types'

export type TaskModalContentProps = {
  close: () => void
  onFormSubmit: FormDataSubmitHandler
}
export const TaskModalContent = ({
  close,
  onFormSubmit,
}: TaskModalContentProps) => {
  const { form, hiddenFields } = useTaskForm()
  const {
    sidePanelOpen,
    initialTaskData,
    options: { isSpeculative, isAutoSaving },
  } = useTaskModalState()
  const { task: initialTask, parentTask, isLoading, hasError } = initialTaskData

  const submitForm = useSubmitForm({ onFormSubmit })

  const {
    watch,
    formState: { isDirty, isSubmitting },
  } = form

  const taskId = watch('id')
  const taskName = watch('name')
  const taskType = watch('type')
  const isArchived = isTaskSchema(initialTask)
    ? initialTask.archivedTime != null
    : undefined
  const workspaceId = watch('workspaceId')
  const projectId = watch('projectId')

  const isRecurringTaskInstance = taskType === 'RECURRING_INSTANCE'
  const isRecurringTask = taskType === 'RECURRING_TASK'

  const { activeFileUploads } = useFileUploadState()

  const hasActiveFileUploads = activeFileUploads.some(
    (upload) => upload.targetId == null && upload.targetType === 'TEAM_TASK'
  )

  const buildRecurringParentUrl = useTaskModalUrl()

  const hasFormFooterButtons = taskId == null || !isAutoSaving

  useShortcut('mod+s', () => onSubmit(), {
    enabled: hasFormFooterButtons,
  })

  const {
    tasks: { getPrevId: getPrevTaskId, getNextId: getNextTaskId },
  } = useModalListOrder()

  const buildModalUrl = useUriByGlobalModalId()

  const onSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    if (!isDirty) return

    await form.handleSubmit(submitForm, (validationErrors) => {
      const errors = Object.values(validationErrors)
      if (errors.length < 1) return

      const message = errors[0].message
      if (typeof message !== 'string' || !message) return

      showToast('error', message)
    })(e)
  }

  const renderPrevNextButtonTooltip: PreviousNextModalButtonsProps['renderTooltip'] =
    (id, shortcut, isNext) => (
      <TaskPrevNextTooltipContent id={id} shortcut={shortcut} isNext={isNext} />
    )

  return (
    <ShellVars columns={2}>
      <SharedFormShell
        columns={2}
        onSubmit={onSubmit}
        className={twMerge(
          'transition-transform',
          sidePanelOpen && 'setvar-[modal-offset=292px] -translate-x-[150px]'
        )}
      >
        <ShellActions
          className={twMerge(
            'transition-transform',
            sidePanelOpen && 'translate-x-[292px]' // 300px sidePanel - 8px to cover the modal a tiny bit
          )}
        >
          <IconButton
            icon={XSolid}
            sentiment='neutral'
            size='small'
            variant='muted'
            onClick={close}
          />
          {!isLoading && !hasError && !sidePanelOpen && taskId && (
            <PreviousNextModalButtons
              previousId={getPrevTaskId()}
              nextId={getNextTaskId()}
              buildUrl={(id) => buildModalUrl('task', { task: id })}
              renderTooltip={renderPrevNextButtonTooltip}
            />
          )}
        </ShellActions>
        <GridShell
          layout={isArchived ? 'archived' : 'normal'}
          withFooter={hasFormFooterButtons}
        >
          {isArchived && (
            <GridBanner>
              <ArchiveBanner />
            </GridBanner>
          )}
          {isLoading ? (
            <div className='col-span-2 row-span-4 w-full h-full grid place-items-center'>
              <LoadingSpinner />
            </div>
          ) : hasError ? (
            <ModalErrorState close={close} className='col-span-2 row-span-4'>
              This task doesn&apos;t exist
            </ModalErrorState>
          ) : (
            <>
              <MainHeader>
                <TaskHeader />
                <ControlledNameField />
              </MainHeader>

              <SidebarShell>
                {!isSpeculative &&
                  initialTask != null &&
                  initialTask.type !== 'RECURRING_TASK' && (
                    <TaskEtaRibbon task={initialTask} />
                  )}
                <SidebarContent>
                  {isRecurringTaskInstance && (
                    <SidebarRecurringTaskBanner>
                      <RecurringTaskBannerText>
                        This is an instance of a recurring task
                      </RecurringTaskBannerText>
                      <Button
                        size='xsmall'
                        variant='outlined'
                        sentiment='neutral'
                        url={buildRecurringParentUrl({
                          task: parentTask?.id,
                        })}
                      >
                        Edit
                      </Button>
                    </SidebarRecurringTaskBanner>
                  )}

                  <SidebarWorkspaceSection>
                    <ControlledWorkspaceFolderProjectField />
                  </SidebarWorkspaceSection>

                  <AutoScheduleToggle />

                  <SidebarSection className='pt-3 modal-lg:pt-5'>
                    <ControlledStageField />
                    <ControlledAssigneeField />
                    <ControlledStatusField />
                    <ControlledPriorityField />
                  </SidebarSection>

                  <SidebarSection>
                    {!hiddenFields.has('recurrenceMeta') && <RecurringField />}
                    <DurationField />
                    {!hiddenFields.has('minimumDuration') && <MinChunkField />}
                    {!hiddenFields.has('startDate') && <StartDateField />}

                    {/* For recurring parents only, warn field is top-level. */}
                    {/* For others, it's nested in DeadlineField. */}
                    {isRecurringTask && <WarnOnField />}

                    {!hiddenFields.has('dueDate') && <DeadlineField />}
                    {!hiddenFields.has('deadlineType') && <DeadlineToggle />}
                    {!hiddenFields.has('scheduleId') && <ScheduleField />}
                  </SidebarSection>

                  <SidebarSection>
                    <TaskLabelsField />

                    {taskType === 'NORMAL' && (
                      <CustomFieldsTaskSidebarSection
                        workspaceId={workspaceId}
                        taskId={taskId}
                        disabled={isArchived}
                      />
                    )}
                  </SidebarSection>

                  <SidebarSection>
                    {!isSpeculative &&
                      !hiddenFields.has('blockedByTaskIds') &&
                      !hiddenFields.has('blockingTaskIds') && (
                        <>
                          <ControlledBlockersField type='blockedBy' />
                          <ControlledBlockersField type='blocks' />
                        </>
                      )}
                  </SidebarSection>

                  {!__IS_PROD__ && <TaskDebugInfo />}
                </SidebarContent>
              </SidebarShell>

              <MainScrollableContent>
                <ControlledDescriptionField />
                {!isSpeculative && (
                  <>
                    {taskId ? (
                      <TaskAttachmentsCollapsable taskId={taskId} />
                    ) : (
                      <TaskAttachmentsUploader />
                    )}
                    {taskId && taskType !== 'RECURRING_TASK' && (
                      <TaskActivityCollapsable taskId={taskId} />
                    )}
                  </>
                )}
              </MainScrollableContent>

              <ShellMainFooter>
                <TutorialButtonLink
                  lesson='managing-tasks'
                  rounded
                  onClick={() => {
                    recordAnalyticsEvent('PROJECT_MANAGEMENT_TUTORIAL_CLICK', {
                      target: 'v3-tutorial',
                      source: 'task-modal',
                    })
                  }}
                />
              </ShellMainFooter>

              {hasFormFooterButtons && (
                <SidebarFooter>
                  <Button
                    sentiment='neutral'
                    variant='muted'
                    shortcut='esc'
                    onClick={close}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    sentiment='primary'
                    variant='solid'
                    shortcut='mod+s'
                    disabled={
                      !isDirty ||
                      taskName.trim().length < 1 ||
                      hasActiveFileUploads
                    }
                    loading={isSubmitting}
                  >
                    Save task
                  </Button>
                </SidebarFooter>
              )}
            </>
          )}
        </GridShell>
      </SharedFormShell>
      <SidePanel open={sidePanelOpen}>
        {projectId != null ? (
          <ProjectPanel
            projectId={projectId}
            workspaceId={workspaceId}
            currentTaskId={taskId}
            enableInlineAdd={taskId != null}
          />
        ) : (
          <div className='flex items-center justify-center h-full'>
            <LoadingSpinner />
          </div>
        )}
      </SidePanel>
    </ShellVars>
  )
}
