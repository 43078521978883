import {
  descriptionAddConference,
  generateZoomMeetingMessage,
  generateZoomPersonalMeetingMessage,
  stripDescriptionConference,
} from '@motion/ui-logic'

import { useConferenceSettingsApi } from '~/areas/settings/state'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { useEventForm } from './use-event-form'

export function useGenerateZoomData() {
  const { form } = useEventForm()
  const { createZoomMeeting, selectConferenceSettings } =
    useConferenceSettingsApi()

  return useCallback(async () => {
    let location = ''
    const newDescription = stripDescriptionConference(
      form.getValues('description')
    )

    const conferenceSettings = selectConferenceSettings()

    if (
      conferenceSettings?.zoomLinkType === 'personal' &&
      conferenceSettings?.zoomAccount &&
      conferenceSettings?.zoomPersonalLink
    ) {
      location = conferenceSettings?.zoomPersonalLink
    } else if (
      conferenceSettings?.zoomLinkType === 'manual' &&
      conferenceSettings?.zoomManualLink
    ) {
      location = conferenceSettings?.zoomManualLink
    }

    if (location) {
      const inviteMessage = generateZoomPersonalMeetingMessage(location)
      const description = descriptionAddConference(
        newDescription,
        inviteMessage
      )
      return { location, description }
    }

    const res = await createZoomMeeting({
      topic: form.getValues('title'),
      recurringRule: form.getValues('recurrence') ?? undefined,
      startTime: form.getValues('start'),
      duration: DateTime.fromISO(form.getValues('end')).diff(
        DateTime.fromISO(form.getValues('start')),
        'minutes'
      ).minutes,
    })

    if (res.completed && res.data) {
      const inviteMessage = generateZoomMeetingMessage(res.data)
      const description = descriptionAddConference(
        newDescription,
        inviteMessage
      )
      location = res.data.joinUrl
      return { location, description }
    }

    throw new Error('Zoom failed')
  }, [createZoomMeeting, form, selectConferenceSettings])
}
