import {
  type ApiTypes,
  type ApiUseMutationOptions,
  createUseMutation,
  createUseQuery,
} from '@motion/rpc'
import { MotionCache, type OptimisticUpdateValue } from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { createLookupById } from '@motion/utils/object'
import { type OOOEventSchema } from '@motion/zod/client'

import { useQueryClient } from '@tanstack/react-query'

import {
  applyCalendarEventCreateToCaches,
  applyOptimisticCalendarEventCreate,
  applyOptimisticCalendarEventDelete,
  applyOptimisticCalendarEventUpdates,
  getCalendarEventsQueryFilters,
} from '../cache'

export const useCalendarEventsSearch = createUseQuery(API.calendarEvents.search)

export const useCalendarEvents = createUseQuery(
  API.calendarEvents.getCalendarEvents
)

export const useTeamMateBusyEvents = createUseQuery(
  API.calendarEvents.getTeamMateEvents,
  {
    select: (data) =>
      Object.keys(data).reduce(
        (acc, curr) => {
          acc[curr] = data[curr]
          return acc
        },
        {} as Record<string, OOOEventSchema[]>
      ),
  }
)

const useCreateCalendarEventMutation = createUseMutation(
  API.calendarEvents.createCalendarEvent
)
export const useCreateCalendarEvent = () => {
  const client = useQueryClient()

  return useCreateCalendarEventMutation({
    onSuccess: (data, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }
      cacheUpdates?.rollback()
      applyCalendarEventCreateToCaches(client, data)
    },
    onMutate: (data) => {
      const cacheUpdates = applyOptimisticCalendarEventCreate(client, data)
      return { cacheUpdates }
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }
      cacheUpdates?.rollback()
    },
  })
}

const useDeletedCalendarEventMutation = createUseMutation(
  API.calendarEvents.deleteCalendarEvent
)

export const useDeleteCalendarEventFn = () => {
  const client = useQueryClient()
  return useDeletedCalendarEventMutation({
    onMutate: ({ id }) => {
      const cacheUpdates = applyOptimisticCalendarEventDelete(client, id)
      return { cacheUpdates }
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }

      cacheUpdates?.rollback()
    },
  })
}

const useUpdateCalendarEventMutation = createUseMutation(
  API.calendarEvents.updateCalendarEvent
)
export const useUpdateCalendarEvent = (
  opts?: ApiUseMutationOptions<typeof API.calendarEvents.updateCalendarEvent>
) => {
  const client = useQueryClient()

  return useUpdateCalendarEventMutation({
    onSuccess: (data) => {
      const otherEventsRecord =
        data.otherEvents != null ? createLookupById(data.otherEvents) : {}

      MotionCache.upsert(client, getCalendarEventsQueryFilters(), {
        models: {
          calendarEvents: {
            [data.calendarEvent.id]: data.calendarEvent,
            ...otherEventsRecord,
          },
        },
      })
    },
    onMutate: async ({ id, ...data }) => {
      const cacheUpdates = applyOptimisticCalendarEventUpdates(client, id, data)
      return { cacheUpdates }
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: ReturnType<typeof MotionCache.patch>
      }
      cacheUpdates?.rollback()
    },
    ...opts,
  })
}

export type GetScheduleAssistantEventsApi = ApiTypes<
  typeof API.calendarEvents.getScheduleAssistantEvents
>
export const useGetScheduleAssistantEvents = createUseQuery(
  API.calendarEvents.getScheduleAssistantEvents
)
