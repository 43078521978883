import { type PriorityLevelSchema } from '@motion/rpc-types'
import { type COLOR } from '@motion/shared/common'
import { type DeadlineInterval } from '@motion/shared/flows'
import {
  type FlowTemplateFormTask,
  type FlowTemplateRoleAssignee,
  type TextVariable,
} from '@motion/ui-logic/pm/project'

import { createContext } from 'react'

import { type UseStageTaskRelativeIntervalFieldReturn } from '../../flow-template-modal/components/stages/fields'

type FieldTuple<T> = readonly [
  T,
  (
    | ((value: T) => void)
    | ((newValueOrFn: T | ((prev: T) => T)) => Promise<boolean | void>)
  ),
]

export interface StageTaskCardFieldsContextValue {
  stageTasks: FlowTemplateFormTask[]
  task: FlowTemplateFormTask
  workspaceId: string
  stageDefinitionId: string
  stageDuration: DeadlineInterval
  stageColor: COLOR
  textVariables: TextVariable[]
  flowTemplateRoles: FlowTemplateRoleAssignee[]
  selectedRole: FlowTemplateRoleAssignee | null
  fields: {
    status: FieldTuple<string>
    duration: FieldTuple<number | null>
    minimumDuration: FieldTuple<number | null>
    priority: FieldTuple<PriorityLevelSchema>
    assignee: FieldTuple<string | null>
    labels: FieldTuple<string[]>
    /** @deprecated */
    blockedByTaskId: FieldTuple<string | null>
    blockedByTaskIds: FieldTuple<string[]>
    relativeStart: UseStageTaskRelativeIntervalFieldReturn
    relativeDeadline: UseStageTaskRelativeIntervalFieldReturn
  }
}

export const StageTaskCardFieldsContext =
  createContext<StageTaskCardFieldsContextValue | null>(null)
