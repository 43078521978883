import { templateStr } from '@motion/react-core/strings'
import {
  convertDateIntervalToDays,
  type DeadlineInterval,
  type RelativeIntervalReferenceType,
  type RelativeIntervalUnit,
} from '@motion/shared/flows'
import {
  type TaskDefinitionFormRelativeInterval,
  type TaskDefinitionFormRelativeIntervalDuration,
} from '@motion/ui-logic/pm/project'

import { type ReactNode } from 'react'

export function getDurationText(
  duration: TaskDefinitionFormRelativeIntervalDuration
) {
  const shortText = formatToAbbrevIntervalText(duration)

  return duration.sign === -1 ? `-${shortText}` : `+${shortText}`
}

export type GetRelativeDateTooltipContentArgs = {
  referenceType: TaskDefinitionFormRelativeInterval['referenceType']
  duration: TaskDefinitionFormRelativeIntervalDuration
  pluralize: (
    count: number,
    singular: string,
    plural: string
  ) => string | ReactNode[]
  startOrEnd: 'Start date' | 'End date'
}
export function getRelativeDateTooltipContent({
  referenceType,
  duration,
  pluralize,
  startOrEnd,
}: GetRelativeDateTooltipContentArgs) {
  return templateStr(
    '{{startOrEnd}}: {{target}} {{sign}} {{duration}} {{interval}}',
    {
      startOrEnd,
      target:
        referenceType === 'STAGE_START'
          ? 'Stage start'
          : referenceType === 'STAGE_DUE'
            ? 'Stage end'
            : 'Event end',
      sign: duration.sign === 1 ? 'plus' : 'minus',
      duration: duration.value,
      interval:
        duration.unit === 'DAYS'
          ? pluralize(duration.value, 'day', 'days')
          : duration.unit === 'WEEKS'
            ? pluralize(duration.value, 'week', 'weeks')
            : duration.unit === 'MONTHS'
              ? pluralize(duration.value, 'month', 'months')
              : '',
    }
  )
}

const ABBREVIATED_LABELS = {
  DAYS: 'd',
  WEEKS: 'w',
  MONTHS: 'm',
}

function formatToAbbrevIntervalText({
  value,
  unit,
}: TaskDefinitionFormRelativeIntervalDuration) {
  const label = ABBREVIATED_LABELS[unit]
  return templateStr('{{value}}{{label}}', { value, label })
}

export type RelativeDatePath =
  `stages.${number}.tasks.${number}.${'start' | 'due'}RelativeInterval`

export function mapRelativeIntervalReferenceTypeToReadable(
  value: RelativeIntervalReferenceType
): string {
  let name: string
  switch (value) {
    case 'STAGE_START':
      name = 'Start'
      break
    case 'STAGE_DUE':
      name = 'Deadline'
      break
    case 'MEETING_TASK':
      name = 'Event'
      break
  }

  return name
}

const WEEK_LENGTH = 7
const MONTH_LENGTH = 30

export function getValidDurationValues<T extends DeadlineInterval>(
  stageDuration: T,
  taskDuration: T
): number[] {
  const stageDays = convertDateIntervalToDays(stageDuration)

  const values: number[] = []

  switch (taskDuration.unit) {
    case 'DAYS':
      // For days, allow values up to the stage duration in days
      for (let i = 0; i <= stageDays; i++) {
        values.push(i)
      }
      break

    case 'WEEKS':
      // For weeks, allow values up to the stage duration in weeks (rounded down)
      const maxWeeks = Math.floor(stageDays / WEEK_LENGTH)
      for (let i = 0; i <= maxWeeks; i++) {
        values.push(i)
      }
      break

    case 'MONTHS':
      // For months, allow values up to the stage duration in months (rounded down)
      const maxMonths = Math.floor(stageDays / MONTH_LENGTH)
      for (let i = 0; i <= maxMonths; i++) {
        values.push(i)
      }
      break
  }

  return values
}

export const getValidDurationUnits =
  <T extends DeadlineInterval>(stageDuration: T, taskDuration: T) =>
  (item: RelativeIntervalUnit): boolean => {
    const stageDays = convertDateIntervalToDays(stageDuration)

    if (item === 'DAYS') return true

    if (item === 'WEEKS') {
      // Don't allow weeks if:
      // 1. Stage is less than a week, or
      // 2. Converting task's current value to weeks would exceed stage weeks
      const stageWeeks = Math.floor(stageDays / WEEK_LENGTH)
      return stageWeeks >= 1 && taskDuration.value <= stageWeeks
    }

    if (item === 'MONTHS') {
      // Don't allow months if:
      // 1. Stage is less than a month, or
      // 2. Converting task's current value to months would exceed stage months
      const stageMonths = Math.floor(stageDays / MONTH_LENGTH)
      return stageMonths >= 1 && taskDuration.value <= stageMonths
    }

    return false
  }
