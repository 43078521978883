import { classed } from '@motion/theme'

export const RadioSectionContainer = classed('div', {
  base: `
    flex flex-col gap-3
  `,
})

export const RadioGroupContainer = classed('div', {
  base: `
    flex flex-col overflow-hidden rounded-md
    border border-form-border-default
    divide-y divide-semantic-neutral-border-light
  `,
})

export const RadioButtonContainer = classed('div', {
  base: `
    bg-form-bg-default p-3
  `,
})
