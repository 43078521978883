import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { createUnknownStage } from '@motion/rpc-cache'
import {
  type ProjectDefinitionSchema,
  type ProjectDefinitionStageDefinitionSchema,
  type ProjectSchema,
  type StageDefinitionSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { AppWorkspaceContext } from '~/global/contexts'

export const useWorkspaceStageDefinitions = (
  workspaceId: WorkspaceSchema['id']
): StageDefinitionSchema[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    return state.stageDefinitionsV2.all.filter(
      (stageDefinition) => stageDefinition.workspaceId === workspaceId
    )
  })
}

export const useProjectDefinitionWithStages = (
  projectDefinition?: ProjectDefinitionSchema | null
):
  | (ProjectDefinitionSchema & {
      stages: StageDefinitionSchema[]
    })
  | null => {
  const stageDefinitions = useStageDefinitionsByReferences(
    projectDefinition?.stageDefinitionReferences ?? []
  )

  if (projectDefinition == null) {
    return null
  }

  return {
    ...projectDefinition,
    stages: stageDefinitions,
  }
}

export type StageDefinitionWithProjectInfo = StageDefinitionSchema & {
  projects: ProjectSchema[]
  projectDefinitions: ProjectDefinitionSchema[]
}

export const useStageDefinitionsWithProjectInfo = (
  workspaceId: WorkspaceSchema['id']
): StageDefinitionWithProjectInfo[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    const stages = state.stageDefinitionsV2.all.filter(
      (stageDefinition) => stageDefinition.workspaceId === workspaceId
    )

    const projectDefinitionsMap = state.projectDefinitions.all.reduce(
      (acc, projectDefinition) => {
        if (projectDefinition.workspaceId !== workspaceId) {
          return acc
        }

        projectDefinition.stageDefinitionReferences.forEach((stage) => {
          acc[stage.stageDefinitionId] ??= []
          acc[stage.stageDefinitionId].push(projectDefinition)
        })
        return acc
      },
      {} as Record<string, ProjectDefinitionSchema[]>
    )

    const projectsMap = state.projects.all.reduce(
      (acc, project) => {
        if (project.workspaceId !== workspaceId) {
          return acc
        }

        project.stages.forEach((stage) => {
          acc[stage.stageDefinitionId] ??= []
          acc[stage.stageDefinitionId].push(project)
        })
        return acc
      },
      {} as Record<string, ProjectSchema[]>
    )

    return stages.map((stageDefinition) => {
      return {
        ...stageDefinition,
        projects: projectsMap[stageDefinition.id] ?? [],
        projectDefinitions: projectDefinitionsMap[stageDefinition.id] ?? [],
      }
    })
  })
}

export const useStageDefinitionsByReferences = (
  stageDefinitionReferences:
    | Pick<ProjectDefinitionStageDefinitionSchema, 'stageDefinitionId'>[]
    | undefined
    | null
): StageDefinitionSchema[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    if (stageDefinitionReferences == null) {
      return []
    }

    return stageDefinitionReferences.map(
      (stageDefinitionReference) =>
        state.stageDefinitionsV2.byId[
          stageDefinitionReference.stageDefinitionId
        ] ?? createUnknownStage(stageDefinitionReference.stageDefinitionId)
    )
  })
}

export const useStageWithProjectInfo = (
  id: StageDefinitionSchema['id'] | null | undefined
): StageDefinitionWithProjectInfo | undefined => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    const stageDefinition = state.stageDefinitionsV2.all.find(
      (stageDefinition) => stageDefinition.id === id
    )

    if (stageDefinition == null) {
      return undefined
    }

    const projectDefinitions = state.projectDefinitions.all.filter(
      (projectDefinition) => {
        if (projectDefinition.workspaceId !== stageDefinition.workspaceId) {
          return false
        }

        return projectDefinition.stageDefinitionReferences.some((stage) => {
          if (stage.stageDefinitionId === stageDefinition.id) {
            return true
          }
        })
      }
    )

    const projects = state.projects.all.filter((project) => {
      if (project.workspaceId !== stageDefinition.workspaceId) {
        return false
      }
      return project.stages.some((stage) => {
        if (stage.stageDefinitionId === stageDefinition.id) {
          return true
        }
      })
    })

    return {
      ...stageDefinition,
      projectDefinitions,
      projects,
    }
  })
}

export const useStageDefinition = (
  id: StageDefinitionSchema['id'] | null | undefined
): StageDefinitionSchema => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    const stageDefinition =
      id != null
        ? (state.stageDefinitionsV2.byId[id] ?? createUnknownStage(id))
        : createUnknownStage(id)

    return stageDefinition
  })
}

export const useStageDefinitionsByProjectDefinitionId = (
  projectDefinitionId: ProjectDefinitionSchema['id'] | null | undefined
): StageDefinitionSchema[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    if (projectDefinitionId == null) {
      return []
    }
    const projectDefinition = state.projectDefinitions.byId[projectDefinitionId]

    if (projectDefinition == null) {
      return []
    }

    return projectDefinition.stageDefinitionReferences.map(
      (sd) => state.stageDefinitionsV2.byId[sd.stageDefinitionId]
    )
  })
}
