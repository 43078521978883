import { classed } from '@motion/theme'

export const CardDescription = classed('span', {
  base: `
    text-semantic-neutral-text-subtle
    text-center text-xs font-normal
  `,
})

export const CardList = classed('div', {
  base: `flex gap-1 items-start self-stretch`,
  variants: {
    isRow: {
      true: `flex-row`,
      false: `flex-col`,
    },
  },
  defaultVariants: {
    isRow: false,
  },
})

export const CardTitle = classed('span', {
  base: `text-semantic-neutral-text-default text-sm font-semibold truncate min-h-4 max-w-full`,
})

export const EmptyTableCard = classed('div', {
  base: `
    flex flex-col items-center justify-center gap-4
    rounded border border-dashed border-semantic-neutral-border-strong bg-semantic-neutral-surface-raised-bg-default
    w-full p-4
  `,
})

export const FlowCard = classed('div', {
  base: `
    flex flex-col gap-4 items-start flex-shrink-0 p-4 w-full h-full rounded-lg
    border border-semantic-neutral-border-default bg-semantic-neutral-surface-raised-bg-default
    overflow-hidden
  `,
  variants: {
    createWithAI: {
      true: `border-semantic-purple-border-default rounded-lg shadow-[0_0_30px_-20px_rgba(0,0,0,0.3)] shadow-semantic-pink-border-active justify-between `,
    },
  },
})

export const FlowCardBottomGradient = classed('div', {
  base: `
    w-full h-8 absolute bottom-0 rounded-b
    bg-gradient-to-t from-semantic-neutral-surface-raised-bg-subtlest to-semantic-neutral-surface-raised-bg-subtlest/0
  `,
})

export const FlowCardHeader = classed('div', {
  base: `flex gap-2 justify-between items-center self-stretch max-w-full`,
})

export const FlowDescription = classed('span', {
  base: `
    truncate text-semantic-neutral-text-default text-xs font-normal
    max-w-full min-h-8 whitespace-pre-wrap
    line-clamp-2
  `,
})

export const FlowDescriptionContainer = classed(FlowCard, {
  base: `
    h-[150px] gap-3 self-stretch min-w-0
  `,
})

export const FlowTemplateContainer = classed('div', {
  base: `
    rounded border border-semantic-neutral-border-light bg-semantic-neutral-surface-raised-bg-subtlest
    flex w-full flex-col items-center relative
  `,
})

export const ProjectCountBadge = classed('span', {
  base: `flex pl-1 pr-1.5 justify-center items-center gap-1
    rounded-xl bg-semantic-neutral-surface-raised-bg-subtle
    text-2xs text-semantic-neutral-text-subtle
    h-4 leading-4 flex-shrink-0 whitespace-nowrap
  `,
  variants: {
    sentiment: {
      destructive: `text-tag-red-subtle-text line-through`,
    },
  },
})
