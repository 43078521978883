import { SelectableNodeWrapper } from '@motion/notes'

import { useMentionNodeRenderFn } from '../contexts'

type WebMentionTagProps = {
  nodeKey: string
  entityId: string
  entityLabel: string
  entityType: string
}

export function WebMentionTag({
  entityId,
  entityLabel,
  entityType,
  nodeKey,
}: WebMentionTagProps) {
  const renderFn = useMentionNodeRenderFn(entityType)

  return (
    <SelectableNodeWrapper nodeKey={nodeKey} as='span'>
      {renderFn({ nodeKey, entityId, entityType, entityLabel })}
    </SelectableNodeWrapper>
  )
}
