import { CalendarSolid } from '@motion/icons'
import { isCustomTemplateProject } from '@motion/ui-logic/pm/project'
import { sleep } from '@motion/utils/promise'

import { useIsFlowsM5cEnabled } from '~/areas/flows'
import { useProjectDeadlineUpdater } from '~/areas/project/hooks'
import {
  DeadlineDropdown,
  type DeadlineDropdownProps,
} from '~/areas/project-management/components'
import { DateTriggerButton } from '~/areas/task-project/components'
import { useProject } from '~/global/hooks'

type ProjectDeadlineFieldProps = {
  projectId: string
  onChange?: DeadlineDropdownProps['onChange']
}

export function ProjectDeadlineField({
  projectId,
  onChange: onChangeFromProps,
}: ProjectDeadlineFieldProps) {
  const updateProjectDeadline = useProjectDeadlineUpdater()
  const project = useProject(projectId)

  const projectDefinitionId = project?.projectDefinitionId

  const isFlowsM5cEnabled = useIsFlowsM5cEnabled()
  const isUsingCustomTemplate =
    isFlowsM5cEnabled && isCustomTemplateProject(project)

  const isFlowProject = projectDefinitionId != null || isUsingCustomTemplate

  if (project == null) {
    throw new Error('Project is required')
  }

  const onChange: DeadlineDropdownProps['onChange'] = async (dueDate) => {
    // Sleeping a bit to make sure the dropdown gets closed before doing anything else to avoid messing up the focus
    await sleep(1)
    await updateProjectDeadline(project, dueDate)
    onChangeFromProps?.(dueDate)
  }

  return (
    <DeadlineDropdown
      value={project.dueDate}
      hideTimeOptions={isFlowProject}
      onChange={onChange}
      contextProps={{ projectId }}
      dropdownTarget='project'
    >
      <DateTriggerButton
        label='Deadline'
        icon={<CalendarSolid />}
        onClear={project.dueDate == null ? undefined : () => onChange(null)}
        date={project.dueDate}
        placeholder='No deadline'
        clearTooltipContent='Remove deadline'
        sentiment='neutral'
      />
    </DeadlineDropdown>
  )
}
