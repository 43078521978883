import { type ProjectSchema } from '@motion/rpc-types'

import { AttachmentGroups } from '~/areas/attachments/components'
import { useGetProjectAttachmentGroups } from '~/areas/attachments/hooks/use-get-project-all-attachments'
import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useFetchProjectTasks } from '~/areas/task-project/hooks'
import { useProject } from '~/global/hooks'
import { type Dispatch, type SetStateAction } from 'react'

import { AttachmentsModalContent } from './attachments-modal-content'

export type AttachmentsModalProjectContentProps = Pick<
  ModalTriggerComponentProps<'attachments'>,
  'close'
> & {
  projectId: ProjectSchema['id']
  searchQuery: string
  setSearchQuery: Dispatch<SetStateAction<string>>
}

export function AttachmentsModalProjectContent({
  close,
  projectId,
  searchQuery,
  setSearchQuery,
}: AttachmentsModalProjectContentProps) {
  const project = useProject(projectId)

  const { data: tasks, isLoading: isLoadingTasks } = useFetchProjectTasks({
    projectId,
    workspaceId: project?.workspaceId,
  })

  const groups = useGetProjectAttachmentGroups({
    projectId,
  })
  const attachmentCount = groups.reduce(
    (acc, group) => acc + group.uploadedFileIds.length,
    0
  )

  if (!project || !tasks || isLoadingTasks) return null

  return (
    <AttachmentsModalContent
      close={close}
      targetId={projectId}
      targetType='PROJECT'
      workspaceId={project.workspaceId}
      attachmentCount={attachmentCount}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    >
      <AttachmentGroups
        groups={groups}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
    </AttachmentsModalContent>
  )
}
