import type { NoteSchema } from '@motion/zod/client'

import { usePublishStatusQuery, useSharesQuery } from '~/global/rpc'
import { type PropsWithChildren, useMemo, useState } from 'react'

import { ShareItemModalContext } from './context'

import { useItemBeingShared } from '../hooks/use-item-being-shared'
import type { ShareItemType } from '../types'

type ProviderProps = {
  itemType: ShareItemType
  itemId: NoteSchema['id']
}

export const ModalContextProvider = ({
  itemId,
  itemType,
  children,
}: PropsWithChildren<ProviderProps>) => {
  const [searchActive, setSearchActive] =
    useState<ShareItemModalContext['searchActive']>(false)
  const [selectedTargets, setSelectedTargets] = useState<
    ShareItemModalContext['selectedTargets']
  >([])
  const [searchQuery, setSearchQuery] =
    useState<ShareItemModalContext['searchQuery']>('')
  const [selectedRole, setSelectedRole] =
    useState<ShareItemModalContext['selectedRole']>('full-access')

  const { data: shareData } = useSharesQuery({
    resourceId: itemId,
    resourceType: itemType,
  })

  const { data: publishData } = usePublishStatusQuery({
    resourceId: itemId,
    resourceType: itemType,
  })

  const canShare = shareData?.canShare ?? false
  const isUnlinked = shareData?.isUnlinked ?? false

  const publishedUrl = publishData?.publishedUrl ?? null
  const isPublished = !!publishedUrl
  const canPublish = publishData?.canPublish ?? false

  const itemBeingShared = useItemBeingShared(itemType, itemId)

  const { usersWithAccess, workspacesWithAccess } = useMemo(() => {
    const initialValue = {
      usersWithAccess: [] as ShareItemModalContext['usersWithAccess'],
      workspacesWithAccess: [] as ShareItemModalContext['workspacesWithAccess'],
    }
    if (!shareData) return initialValue
    return shareData.data.reduce((acc, item) => {
      if (item.targetType === 'USER') {
        acc.usersWithAccess.push(item)
      } else if (item.targetType === 'WORKSPACE') {
        acc.workspacesWithAccess.push(item)
      }
      return acc
    }, initialValue)
  }, [shareData])

  const context = useMemo<ShareItemModalContext>(
    () => ({
      itemId,
      itemType,
      searchActive,
      searchQuery,
      selectedRole,
      selectedTargets,
      setSearchActive,
      setSearchQuery,
      setSelectedRole,
      setSelectedTargets,
      usersWithAccess,
      workspacesWithAccess,
      itemBeingShared,
      canShare,
      isUnlinked,
      publishedUrl,
      isPublished,
      canPublish,
    }),
    [
      itemId,
      itemType,
      searchActive,
      searchQuery,
      selectedRole,
      selectedTargets,
      usersWithAccess,
      workspacesWithAccess,
      itemBeingShared,
      canShare,
      isUnlinked,
      publishedUrl,
      isPublished,
      canPublish,
    ]
  )

  return (
    <ShareItemModalContext.Provider value={context}>
      {children}
    </ShareItemModalContext.Provider>
  )
}
