import { API } from '@motion/rpc-definitions'
import { type MeetingInsightsSingleRequest } from '@motion/rpc-types'
import { waitForAll } from '@motion/utils/promise'

import { type HandlerContext } from './types'

export async function refreshMeetingInsights(
  ctx: HandlerContext,
  meetingInsightsIds: string[]
) {
  const { client } = ctx

  if (meetingInsightsIds.length === 0) {
    return
  }

  await waitForAll([
    client.invalidateQueries({
      queryKey: API.notetaker.queryKeys.byId,
      predicate: (query) => {
        const queryKey = query.queryKey as string[]

        // The last argument is the args object, e.g. { meetingInsightsId: '123', ... }
        const args = queryKey[queryKey.length - 1]

        if (isGetMeetingInsightsByIdArgs(args) && args.meetingInsightsId) {
          return meetingInsightsIds.includes(args.meetingInsightsId)
        }

        return false
      },
    }),
    client.invalidateQueries({
      queryKey: API.scheduledEntities.queryKeys.root,
    }),
  ])
}

function isGetMeetingInsightsByIdArgs(
  args: unknown
): args is MeetingInsightsSingleRequest {
  return (
    typeof args === 'object' &&
    args !== null &&
    ('meetingInsightsId' in args || 'recurringMeetingInsightsId' in args)
  )
}
