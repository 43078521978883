import { safeParseDate } from '@motion/utils/dates'

import { type DateTimeUnit } from 'luxon'

import { type ViewStateGroupByField } from '../../view-state'

export function getGroupByDateValueOverride(group: ViewStateGroupByField) {
  return (item: string) => {
    if (group.by == null) return item

    const date = safeParseDate(item)
    if (date == null) return item

    if (isDateTimeUnit(group.by)) {
      return date.endOf(group.by)
    }

    if (group.by === 'half') {
      const m = date.get('month')
      return date.endOf('year').minus({ month: m > 6 ? 0 : 6 })
    }

    return item
  }
}

export function isDateTimeUnit(unit: string): unit is DateTimeUnit {
  return ['week', 'month', 'quarter', 'year'].includes(unit)
}
