import { WorkspaceSolid } from '@motion/icons'
import { type COLOR } from '@motion/shared/common'
import { type VirtualizedTreeNode } from '@motion/ui/base'
import { FolderColoredIcon, ProjectColoredIcon } from '@motion/ui/project'

import { useCachedItem } from '~/global/cache'

import { type LeafNodeType } from './hooks'

import { NoteBadge, ProjectBadge } from '../../badges'

type WorkspacesTreeNodeLabelProps = {
  id: VirtualizedTreeNode['id']
  nodeType: LeafNodeType | 'NONE'
  label: string
  color?: COLOR
  leafNodeType: LeafNodeType
}

export const WorkspacesTreeNodeIcon = (props: WorkspacesTreeNodeLabelProps) => {
  const { id, nodeType, leafNodeType, label, color } = props

  const icon =
    nodeType === 'FOLDER' ? (
      <ConnectedFolderIcon id={id} />
    ) : nodeType === 'PROJECT' ? (
      <ConnectedProjectIcon id={id} />
    ) : nodeType === 'WORKSPACE' ? (
      <WorkspaceSolid className='size-4 mr-1 text-semantic-neutral-icon-default shrink-0' />
    ) : nodeType === 'NONE' && leafNodeType === 'FOLDER' ? (
      <FolderColoredIcon className='size-4 mr-1' />
    ) : nodeType === 'NONE' && leafNodeType === 'PROJECT' ? (
      <ProjectColoredIcon className='size-4 mr-1' />
    ) : nodeType === 'NOTE' ? (
      <NoteBadge
        value={{
          color: color ?? 'gray',
          title: label,
        }}
        className='size-4 mr-1'
      />
    ) : null
  return icon
}

const ConnectedFolderIcon = (props: { id: string }) => {
  const folder = useCachedItem('folders', props.id)

  return <FolderColoredIcon className='size-4 mr-1' color={folder?.color} />
}

const ConnectedProjectIcon = (props: { id: string }) => {
  const project = useCachedItem('projects', props.id)

  return <ProjectBadge className='size-4 mr-1' value={project} hideTooltip />
}
