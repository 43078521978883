import { templateStr } from '@motion/react-core/strings'
import { isCompletedStatus } from '@motion/shared/common'
import { classed } from '@motion/theme'
import {
  AutoScheduleToggle as AutoScheduleToggleButton,
  type AutoScheduleToggleProps,
  Tooltip,
} from '@motion/ui/base'
import { getAutoscheduleInfo, scheduleTypeToInfo } from '@motion/ui-logic'
import { isAutoScheduledToggleEnabled } from '@motion/ui-logic/pm/task'

import { AutoScheduleTooltip } from '~/areas/tasks/tooltips'
import { useWorkspaceStatusById } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { useTaskDefinitionForm } from '../use-task-definition-form'
import { useTaskDefinitionModalState } from '../use-task-definition-modal-state'

export const ControlledAutoScheduleToggle = () => {
  const { form, disabledFields } = useTaskDefinitionForm()
  const {
    initialTaskData: { task },
  } = useTaskDefinitionModalState()
  const { watch, control } = form

  const { field: isAutoScheduledField } = useController({
    name: 'isAutoScheduled',
    control,
  })

  const taskType = watch('type')
  const statusId = watch('statusId')

  const status = useWorkspaceStatusById(statusId)
  const isCompleted = isCompletedStatus(status)

  const handleOnChange: AutoScheduleToggleProps['onChange'] = (e) => {
    const { checked } = e.currentTarget

    isAutoScheduledField.onChange(checked)
  }

  const { formattedScheduledDate, type, labelVariant } = getAutoscheduleInfo(
    task,
    isAutoScheduledField.value,
    isCompleted
  )

  const { disabled: toggleDisabled, tooltipText } =
    isAutoScheduledToggleEnabled({
      type: taskType,
      status,
      isAutoScheduled: isAutoScheduledField.value,
      completedDuration: watch('completedDuration'),
      duration: watch('duration'),
      completedTime: watch('completedTime'),
    })

  const tooltipMessage = disabledFields.has('statusId')
    ? undefined
    : toggleDisabled
      ? tooltipText
      : isAutoScheduledField.value
        ? 'Turn off auto-scheduling'
        : 'Turn on auto-scheduling'

  const scheduleDateInfo = scheduleTypeToInfo[type]

  const disabled =
    toggleDisabled ||
    disabledFields.has('statusId') ||
    disabledFields.has('isAutoScheduled')

  return (
    <ToggleContainer variant={labelVariant}>
      <Tooltip content={tooltipMessage} asChild>
        <AutoScheduleToggleButton
          checked={isAutoScheduledField.value}
          onChange={handleOnChange}
          disabled={disabled}
          size='xsmall'
        >
          <AutoScheduleDate
            variant={labelVariant}
            className='text-xs truncate line-clamp-1'
          >
            {isAutoScheduledField.value
              ? 'Auto-scheduled'
              : 'Not auto-scheduled'}
          </AutoScheduleDate>
        </AutoScheduleToggleButton>
      </Tooltip>
      <Tooltip
        renderContent={() => (
          <AutoScheduleTooltip info={scheduleDateInfo} task={task} />
        )}
        asChild
      >
        <AutoScheduleDate
          variant={labelVariant}
          disabled={disabled}
          className='text-xs truncate'
        >
          {templateStr(scheduleDateInfo.text, {
            date: formattedScheduledDate,
          })}
        </AutoScheduleDate>
      </Tooltip>
    </ToggleContainer>
  )
}

const ToggleContainer = classed('div', {
  base: `
    h-9 w-full
    align-middle flex flex-row items-center gap-1
    px-4 py-2.5
    modal-lg:px-5 modal-lg:py-4
  `,
  variants: {
    variant: {
      error: 'bg-semantic-error-bg-disabled',
      on: 'bg-semantic-purple-bg-default',
      off: '',
    },
  },
  defaultVariants: {
    variant: 'off',
  },
})

const AutoScheduleDate = classed('span', {
  variants: {
    disabled: { true: 'opacity-50' },
    variant: {
      on: 'text-semantic-purple-text-default',
      off: 'text-semantic-neutral-text-disabled',
      error: 'text-semantic-error-bg-strong-default',
    },
  },
  defaultVariants: {
    variant: 'off',
  },
})
