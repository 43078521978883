import { useDelayedUnmount } from '@motion/react-core/hooks'
import { UnstyledCollapsableContainer } from '@motion/ui/base'

import { type Row } from '@tanstack/react-table'
import { type Virtualizer } from '@tanstack/react-virtual'
import { useIsFlowsM5cEnabled } from '~/areas/flows/hooks/use-flows-m5c-enabled'
import { type SidebarTasksGroup } from '~/areas/task-project/hooks'
import { useProject } from '~/global/hooks'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { lookupHeader } from './lookup-header'

import { type SortBy } from '../../../utils'
import { useSidebarTaskContext } from '../context'
import {
  InlineTaskCreationForm,
  type InlineTaskCreationFormProps,
} from '../inline-create'
import { HEADER_GROUP_HEIGHT } from '../utils'

export type SidebarTasksGroupHeaderProps = {
  sort: keyof typeof SortBy
  groupId: string
  index: number
  measureElement?: (node: HTMLDivElement | null) => void
  row?: Row<SidebarTasksGroup>
  isAddTaskExpanded: boolean
  toggleAddTaskExpanded: () => void
  rowVirtualizer: Virtualizer<HTMLTableElement, Element>
}

export function SidebarTasksGroupHeader({
  sort,
  groupId,
  index,
  measureElement,
  row,
  isAddTaskExpanded,
  toggleAddTaskExpanded,
  rowVirtualizer,
}: SidebarTasksGroupHeaderProps) {
  const { enableInlineAdd, workspaceId, projectId, isProjectModal } =
    useSidebarTaskContext()
  const project = useProject(projectId)
  const isFlowsM5cEnabled = useIsFlowsM5cEnabled()
  const hasFlowsM5cAddTasks =
    isFlowsM5cEnabled && sort === 'STAGES' && row?.subRows.length === 0

  const [initialAddFormData, setInitialAddFormData] =
    useState<InlineTaskCreationFormProps['initialData']>(undefined)
  const mounted = useDelayedUnmount(isAddTaskExpanded, 200)

  if (sort !== 'STAGES' && sort !== 'STATUS' && sort !== 'NO_STAGE') {
    return null
  }

  const toggle = (initialData?: InlineTaskCreationFormProps['initialData']) => {
    toggleAddTaskExpanded()

    setInitialAddFormData(initialData)
  }

  const initialStartDate = project?.startDate
    ? {
        startDate: project.startDate,
      }
    : undefined

  const showInlineAddTaskForm =
    mounted && enableInlineAdd && (sort === 'STATUS' || hasFlowsM5cAddTasks)

  return (
    <UnstyledCollapsableContainer
      expanded={isAddTaskExpanded}
      toggle={toggle}
      renderHeaderInline
      renderHeader={() => (
        <div
          data-index={index}
          ref={measureElement}
          className={twMerge(
            'sticky top-0 z-[2] transition-height flex flex-row items-center justify-between pl-3.5 py-2 pr-2 overflow-hidden',
            sort === 'STAGES' && 'pl-1 flex-shrink-0',
            isProjectModal
              ? 'bg-semantic-neutral-surface-raised-bg-subtle'
              : 'bg-semantic-neutral-surface-overlay-bg-default'
          )}
          style={{
            height: HEADER_GROUP_HEIGHT,
          }}
        >
          {lookupHeader(sort)({ groupId, toggle, row })}
        </div>
      )}
    >
      {showInlineAddTaskForm && (
        <InlineTaskCreationForm
          close={toggleAddTaskExpanded}
          workspaceId={workspaceId}
          projectId={projectId}
          onSuccess={() => {
            if (row && !row.getIsExpanded() && sort === 'STAGES') {
              row.toggleExpanded()
            }
          }}
          initialData={{
            ...initialStartDate,
            ...initialAddFormData,
            ...(hasFlowsM5cAddTasks
              ? {
                  stageDefinitionId: row?.id,
                }
              : {}),
          }}
        />
      )}
    </UnstyledCollapsableContainer>
  )
}
