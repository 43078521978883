import { useSharedState } from '@motion/react-core/shared-state'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type VersionedViewV2 } from '@motion/zod/client'

import { useIsFlowProject } from '~/areas/flows'
import { useLookup } from '~/global/cache'
import { AppWorkspaceContext } from '~/global/contexts'
import { useNavigateByRouteId } from '~/routing'
import { useCallback } from 'react'

import { viewIdSlug } from './view-id-slug'

import { usePageData } from '../../routes/hooks'
import { getDefaultView } from '../defaults'

export const useSelectView = () => {
  const lookup = useLookup()
  const [ctx] = useSharedState(AppWorkspaceContext)
  const navigate = useNavigateByRouteId()
  const route = usePageData()
  const isFlowProject = useIsFlowProject()

  return useCallback(
    (maybeIdOrView: VersionedViewV2 | string | null) => {
      const viewId =
        maybeIdOrView == null
          ? 'default'
          : typeof maybeIdOrView === 'string'
            ? maybeIdOrView
            : viewIdSlug(maybeIdOrView.id)

      const view =
        lookup('views', viewId) ??
        getDefaultView(route.page, {
          isFlowProject:
            route.page === 'project' &&
            route.lock.projectId != null &&
            isFlowProject(ctx.projects.byId[route.lock.projectId]),
        })

      recordAnalyticsEvent('VIEW_SELECT', {
        isPrivate: view.isPrivate,
        type: view.definition.type,
      })

      return navigate('parent', {
        viewId: viewIdSlug(viewId),
      })
    },
    [
      ctx.projects.byId,
      isFlowProject,
      lookup,
      navigate,
      route.lock.projectId,
      route.page,
    ]
  )
}
