import { API } from '@motion/rpc-definitions'
import { findCanceledStatus, findCompletedStatus } from '@motion/shared/common'
import {
  getTaskStatusChangedFields,
  isTaskSchema,
} from '@motion/ui-logic/pm/task'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import {
  type RecurringTaskSchema,
  type TaskSchema,
  type TasksV2RecurringTaskUpdateSchema,
} from '@motion/zod/client'

import { useQueryClient } from '@tanstack/react-query'
import { showRecalculatingTasksToast } from '~/areas/tasks/utils'
import { useLookup } from '~/global/cache'
import { useWorkspaceFns } from '~/global/hooks/workspaces'
import { createTaskProxy } from '~/global/proxies'
import { useReadTaskFn } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useCompleteTaskBlockers } from './use-complete-task-blockers'

import { useHasUnfinishedBlockersFn } from '../helpers'
import {
  type TaskUpdateFns,
  useTaskUpdater,
} from '../updaters/use-task-updater'

export type TaskResolutionType = 'COMPLETE' | 'CANCEL'

export const useResolveTask = ({
  supressRecalcToast = false,
}: {
  supressRecalcToast?: boolean
} = READONLY_EMPTY_OBJECT) => {
  const modalApi = useModalApi()
  const readTask = useReadTaskFn()
  const { getWorkspaceStatuses } = useWorkspaceFns()
  const updateTask = useTaskUpdater()
  const lookup = useLookup()
  const queryClient = useQueryClient()
  const completeTaskBlockers = useCompleteTaskBlockers()
  const hasUnfinishedBlockedBy = useHasUnfinishedBlockersFn({
    only: 'blockedBy',
  })

  const resolveTask = useCallback(
    async (taskId: TaskSchema['id'], resolutionType: TaskResolutionType) => {
      try {
        const task = await readTask(taskId)
        const parentChunkTask =
          task?.type === 'CHUNK' ? await readTask(task.parentChunkTaskId) : null

        if (task == null) {
          throw new Error('Task not found')
        }
        if (parentChunkTask?.type === 'RECURRING_TASK') {
          throw new Error(
            'Invalid task: Parent chunk cannot be a recurring task',
            {
              cause: {
                task,
                parentChunkTask,
              },
            }
          )
        }

        const statuses = getWorkspaceStatuses(task.workspaceId)
        const targetStatus =
          resolutionType === 'COMPLETE'
            ? findCompletedStatus(statuses)
            : findCanceledStatus(statuses)

        if (!targetStatus) {
          throw new Error(
            `No ${resolutionType.toLowerCase()} status in workspace`
          )
        }

        let updates: Exclude<
          Parameters<TaskUpdateFns>[1],
          Omit<TasksV2RecurringTaskUpdateSchema, 'type'>
        > = {
          statusId: targetStatus.id,
          ...getTaskStatusChangedFields(
            { ...task, statusId: targetStatus.id },
            { statuses: getWorkspaceStatuses(task.workspaceId) }
          ),
        }

        let finalTaskToResolve:
          | TaskSchema
          | RecurringTaskSchema
          | null
          | undefined = task

        // support completing task chunks
        if (resolutionType === 'COMPLETE') {
          const parentChunkTaskProxy = parentChunkTask
            ? createTaskProxy(parentChunkTask, lookup)
            : null

          const incompleteChunkCount =
            parentChunkTaskProxy != null &&
            parentChunkTaskProxy.type !== 'CHUNK'
              ? parentChunkTaskProxy.chunks.reduce((acc, chunk) => {
                  if (chunk.statusId !== targetStatus.id) {
                    return acc + 1
                  }
                  return acc
                }, 0)
              : 0

          if (incompleteChunkCount > 1) {
            const taskChoiceToResolve = await modalApi.prompt(
              'complete-chunked-task',
              {}
            )

            if (taskChoiceToResolve === ModalDismissed) {
              return
            }

            finalTaskToResolve =
              taskChoiceToResolve === 'all' ? parentChunkTask : task
          }

          if (task.type === 'NORMAL') {
            const hasUnfinishedBlockedByInStage = await hasUnfinishedBlockedBy(
              task,
              task.stageDefinitionId
            )

            if (hasUnfinishedBlockedByInStage) {
              const action = await modalApi.prompt(
                'complete-task-with-blockers',
                {
                  task,
                }
              )

              if (action === ModalDismissed) {
                return
              }

              if (action === 'complete') {
                await completeTaskBlockers(task)
              } else if (action === 'remove') {
                updates = {
                  ...updates,
                  blockedByTaskIds: [],
                }
              }
            }
          }
        }

        if (finalTaskToResolve == null) return

        recordAnalyticsEvent('PROJECT_MANAGEMENT_COMPLETE_TASK', {
          taskId: finalTaskToResolve.id,
          resolutionType,
        })

        const updatedTask = await updateTask(finalTaskToResolve, updates)

        if (!supressRecalcToast && finalTaskToResolve.isAutoScheduled) {
          showRecalculatingTasksToast()
        }

        // If the task moves in or out of completed, we should refetch scheduled tasks
        if (
          isTaskSchema(finalTaskToResolve) &&
          isTaskSchema(updatedTask) &&
          finalTaskToResolve.completedTime !== updatedTask.completedTime
        ) {
          void queryClient.invalidateQueries({
            queryKey: API.scheduledEntities.queryKeys.root,
          })
        }
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            position: 'useResolveTask',
          },
        })

        showErrorToast(e)
        throw e
      }
    },
    [
      completeTaskBlockers,
      getWorkspaceStatuses,
      hasUnfinishedBlockedBy,
      lookup,
      modalApi,
      queryClient,
      readTask,
      supressRecalcToast,
      updateTask,
    ]
  )

  const completeTask = useCallback(
    (taskId: TaskSchema['id']) => resolveTask(taskId, 'COMPLETE'),
    [resolveTask]
  )

  const cancelTask = useCallback(
    (taskId: TaskSchema['id']) => resolveTask(taskId, 'CANCEL'),
    [resolveTask]
  )

  return {
    completeTask,
    cancelTask,
  }
}
