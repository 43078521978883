/* eslint-disable react-refresh/only-export-components */
import { createContext, type ReactNode, useMemo, useRef, useState } from 'react'

import { type InitialTaskData } from '../hooks'

export type TaskModalState = {
  sidePanelOpen: boolean
  toggleSidePanel: () => void
  hasPendingComment: boolean
  setHasPendingComment: (value: boolean) => void
  initialTaskData: InitialTaskData
  options: {
    isSpeculative: boolean
    isAutoSaving: boolean
    disallowLocationUpdate: boolean // location being workspace, folder and project
  }
}

const defaultValue: TaskModalState = {
  sidePanelOpen: false,
  toggleSidePanel: () => {},
  hasPendingComment: false,
  setHasPendingComment: () => {},
  initialTaskData: {
    isLoading: false,
    hasError: false,
    task: undefined,
    parentTask: undefined,
    project: null,
  },
  options: {
    isSpeculative: false,
    isAutoSaving: true,
    disallowLocationUpdate: false,
  },
}

export const TaskModalStateContext = createContext(defaultValue)

export type TaskModalStateProviderProps = {
  children: ReactNode
  initialTaskData: InitialTaskData
  options?: Partial<TaskModalState['options']>
}

export function TaskModalStateProvider({
  children,
  initialTaskData,
  options,
}: TaskModalStateProviderProps) {
  const [sidePanelOpen, setSidePanelOpen] = useState(false)
  const [hasPendingComment, setHasPendingComment] = useState(false)
  const optionsRef = useRef(options)
  optionsRef.current = options

  const value = useMemo(() => {
    return {
      sidePanelOpen,
      toggleSidePanel: () => setSidePanelOpen((prev) => !prev),
      hasPendingComment,
      setHasPendingComment,
      initialTaskData,
      options: {
        ...defaultValue.options,
        ...optionsRef.current,
      },
    }
  }, [sidePanelOpen, hasPendingComment, initialTaskData])

  return (
    <TaskModalStateContext.Provider value={value}>
      {children}
    </TaskModalStateContext.Provider>
  )
}
