import { type NoteSchema, type TeamMemberSchema } from '@motion/rpc-types'
import { Toast } from '@motion/ui/base'

import { NoteShareButton } from '~/areas/notes/components'
import { useUser } from '~/global/hooks'

type UninvitedUserToastContentProps = {
  noteId?: NoteSchema['id'] | null
  userId?: TeamMemberSchema['id'] | null
  onClose: () => void
}

export function UninvitedUserToast({
  noteId,
  userId,
  onClose,
}: UninvitedUserToastContentProps) {
  const user = useUser(userId)

  if (noteId == null || userId == null) {
    return null
  }

  return (
    <Toast
      type='neutral'
      title={
        <div className='flex flex-col gap-2 items-start'>
          Looks like {user.name ?? 'this user'} does not have access to this
          doc.
          <NoteShareButton
            noteId={noteId}
            variant='solid'
            sentiment='primary'
            onClick={onClose}
          >
            Invite them
          </NoteShareButton>
        </div>
      }
      onClose={onClose}
      duration={Infinity}
      visible
    />
  )
}
