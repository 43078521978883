import { type TaskFormFields } from '@motion/ui-logic/pm/task'
import { entries, keys, values } from '@motion/utils/object'

import { type FieldNamesMarkedBoolean } from 'react-hook-form'

export function getDirtyFieldKeys(
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<TaskFormFields>>>
) {
  // `formState.dirtyFields` can sometimes contain all fields with true/false values
  // https://usemotion.slack.com/archives/C05L59APX53/p1741979352354179

  return keys(
    Object.fromEntries(
      entries(dirtyFields).filter(([key, value]) => {
        if (Array.isArray(value)) {
          return value.some((v) => {
            return typeof v === 'object'
              ? values(v).some((vv) => vv === true)
              : v === true
          })
        }
        return value === true
      })
    )
  ) as (keyof TaskFormFields)[]
}
