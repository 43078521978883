import { LinkSolid, TrashSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, IconButton, Tooltip } from '@motion/ui/base'
import { type TaskSchema } from '@motion/zod/client'

import { AssigneeDropdown } from '~/areas/project-management/components'
import { useDeleteTask, useTaskUpdater } from '~/areas/tasks/hooks'
import { useCachedItem } from '~/global/cache'
import { ConnectedUserBadge } from '~/global/components/badges'
import { TaskLabel } from '~/global/components/labels/task'
import { useCopyTaskLinkToClipboard } from '~/global/hooks'
import { useTaskModalUrl } from '~/global/navigation'
import { Link } from 'react-router-dom'

type TaskRowProps = {
  task: TaskSchema
  onDelete?: () => void
}

export const TaskRow = ({ task: taskProp, onDelete }: TaskRowProps) => {
  const buildTaskModalUrl = useTaskModalUrl()
  const copyTaskLink = useCopyTaskLinkToClipboard()
  const cachedTask = useCachedItem('tasks', taskProp.id)
  const updateTask = useTaskUpdater()
  const deleteTask = useDeleteTask()

  const task = cachedTask || taskProp

  return (
    <Row>
      <TaskTag to={buildTaskModalUrl({ task: task.id })}>
        <TaskLabel value={task} />
      </TaskTag>

      <div className='flex flex-row items-center'>
        <AssigneeDropdown
          workspaceId={task.workspaceId}
          selectedUserId={task.assigneeUserId}
          type='single'
          onChange={(assigneeUserId) => {
            updateTask(task.id, { assigneeUserId })
          }}
        >
          <Button size='small' variant='muted' sentiment='neutral' iconOnly>
            <ConnectedUserBadge userId={task.assigneeUserId} size='normal' />
          </Button>
        </AssigneeDropdown>

        <Tooltip asChild content='Copy link to task'>
          <IconButton
            icon={LinkSolid}
            sentiment='neutral'
            variant='muted'
            size='small'
            onClick={() => {
              copyTaskLink({
                workspaceId: task.workspaceId,
                taskName: task.name,
                taskId: task.id,
              })
            }}
          />
        </Tooltip>

        <Tooltip asChild content='Delete task'>
          <IconButton
            icon={TrashSolid}
            sentiment='neutral'
            variant='muted'
            size='small'
            onClick={() => {
              void deleteTask(task.id, {
                onConfirmation: onDelete,
              })
            }}
          />
        </Tooltip>
      </div>
    </Row>
  )
}

const Row = classed('div', {
  base: `
    flex flex-row gap-2 items-center
    w-full
  `,
})

const TaskTag = classed(Link, {
  base: `
    border border-semantic-neutral-border-default
    p-1 rounded min-w-0 flex-1
  `,
})
