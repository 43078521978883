import { showToast } from '@motion/ui/base'
import { formatTime, prettyDateDay } from '@motion/ui-logic'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type TaskSchema } from '@motion/zod/client'

import { useReadTaskFn } from '~/global/rpc/v2'
import { useCallback } from 'react'

import { useTaskUpdater } from '../updaters'

interface Options {
  source?: string
  snoozeUntil?: string | null
}

export const useDoTaskLater = () => {
  const updateTask = useTaskUpdater()
  const readTask = useReadTaskFn()

  return useCallback(
    async (
      taskOrId: TaskSchema | TaskSchema['id'],
      options: Options = READONLY_EMPTY_OBJECT
    ) => {
      const task =
        typeof taskOrId === 'string' ? await readTask(taskOrId) : taskOrId

      if (task == null) {
        throw new Error('Task not found')
      }
      if (task.type === 'CHUNK') {
        throw new Error('Cannot snooze a Chunk task')
      }

      const { source = 'none' } = options
      recordAnalyticsEvent('TASK_DO_LATER_SAVE', { source })

      try {
        await updateTask(task, {
          snoozeUntil: options.snoozeUntil,
        })

        showToast(
          'success',
          options.snoozeUntil
            ? `Task snoozed until after ${prettyDateDay(options.snoozeUntil, {
                lowercase: true,
              })} at ${formatTime(options.snoozeUntil)}.`
            : 'Task was un-snoozed.'
        )
      } catch (e) {
        if (e instanceof Error) {
          showToast('error', 'Error marking task to do later')
        }
      }
    },
    [readTask, updateTask]
  )
}
