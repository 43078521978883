import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { type WorkspaceSchema } from '@motion/zod/client'

import { useDeleteWorkspaceMembers as useDeleteWorkspaceMembersCall } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useDeleteWorkspaceMembers = () => {
  const {
    mutateAsync: deleteWorkspaceMembers,
    isPending: isLoading,
    isError,
  } = useDeleteWorkspaceMembersCall()
  return {
    deleteWorkspaceMembers: useCallback(
      async (data: {
        workspaceId: WorkspaceSchema['id']
        workspaceMemberIds: string[]
      }) => {
        try {
          if (data.workspaceMemberIds.length === 0) {
            return
          }

          recordAnalyticsEvent('PROJECT_MANAGEMENT_DELETE_WORKSPACE_MEMBERS', {
            count: data.workspaceMemberIds.length,
          })

          const response = await deleteWorkspaceMembers(data)

          return response.models[response.meta.model][response.id]
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              position: 'remove-workspace-members',
            },
          })

          showErrorToast(e)
        }
      },
      [deleteWorkspaceMembers]
    ),
    isLoading,
    isError,
  }
}
