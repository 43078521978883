import { type WorkspaceMemberWithUser } from '@motion/ui-logic'
import { createUnassignedUser } from '@motion/ui-logic/pm/data'
import { type TaskSchema } from '@motion/zod/client'

import { useTaskAssigneeData } from '~/areas/tasks/hooks'
import { useWorkspaceMember } from '~/global/hooks'

export const useAssigneeDropdown = (task: TaskSchema) => {
  const { onChange, disabled } = useTaskAssigneeData(task)
  const unassigned = createUnassignedUser()
  const selectedAssignee =
    useWorkspaceMember(task.workspaceId, task.assigneeUserId) ??
    ({
      id: unassigned.id,
      userId: unassigned.id,
      workspaceId: task.workspaceId,
      status: 'ACTIVE',
      role: 'MEMBER',
      user: unassigned,
    } satisfies WorkspaceMemberWithUser)

  const isRecurringTask = task.type === 'RECURRING_INSTANCE'

  return {
    onChange: onChange,
    disabled,
    removeUnassignedOption: isRecurringTask,
    selectedAssignee,
    workspaceId: task.workspaceId,
  }
}
