import { classed } from '@motion/theme'

import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { useDebugMode } from '~/global/hooks/use-debug-mode'

export const ConnectedStageDebugInfo = ({
  stagePath,
}: {
  stagePath: `stages.${number}`
}) => {
  const isEnabled = useDebugMode()
  const { form } = useFlowTemplateForm()
  const stage = form.watch(stagePath)

  if (!isEnabled) {
    return null
  }

  return <DebugContainer>Definition ID: {stage.id}</DebugContainer>
}

const DebugContainer = classed(
  'div',
  `
  flex flex-col gap-2
  text-semantic-neutral-text-default text-xs
  p-2
  border border-semantic-warning-border-default rounded-md
  truncate max-w-full
  `
)
