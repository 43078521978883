import type { NormalTaskSchema } from '@motion/rpc-types'

import { useCallback } from 'react'

import { useSetupProjectForm } from './use-setup-project-form'

export const useAddTaskFn = () => {
  const {
    form: { getValues, setValue },
  } = useSetupProjectForm()

  return useCallback(
    <T extends NormalTaskSchema>(task: T) => {
      const modifications = getValues('modifications')
      const speculativeTasksByStageDefinitionId = getValues(
        'speculativeTasksByStageDefinitionId'
      )

      if (
        modifications == null ||
        speculativeTasksByStageDefinitionId == null
      ) {
        throw new Error(
          'Modifications or speculative tasks by stage id are missing'
        )
      }

      if (task.stageDefinitionId == null) {
        throw new Error('Task should be in a stage')
      }

      if (speculativeTasksByStageDefinitionId[task.stageDefinitionId] == null) {
        throw new Error("The task stage doesn't exist in the project")
      }

      const newModifications = { ...modifications }
      const newSpeculativeTasksByStageDefinitionId = {
        ...speculativeTasksByStageDefinitionId,
      }

      newModifications[task.id] = { data: task }
      newSpeculativeTasksByStageDefinitionId[task.stageDefinitionId].push(task)

      setValue('modifications', newModifications)
      setValue(
        'speculativeTasksByStageDefinitionId',
        newSpeculativeTasksByStageDefinitionId
      )
    },
    [getValues, setValue]
  )
}
