import {
  type NoteSchema,
  type ProjectResponseSchema,
  type TaskResponseSchema,
  type TeamMemberSerializer,
} from '@motion/rpc-types'
import { isObject } from '@motion/utils/object'

export type Meta =
  | {
      type: 'task'
      task: TaskResponseSchema
    }
  | {
      type: 'project'
      project: ProjectResponseSchema
    }
  | {
      type: 'user'
      user: TeamMemberSerializer
    }
  | {
      type: 'note'
      note: NoteSchema
    }
  | {
      type: 'placeholder'
      showLoader: boolean
    }

export function isValidMeta(meta: unknown): meta is Meta {
  if (!isObject(meta) || !('type' in meta)) {
    return false
  }

  const { type } = meta

  if (typeof type !== 'string') {
    return false
  }

  return ['task', 'project', 'user', 'note', 'placeholder'].includes(type)
}
