import {
  ActionList,
  Button,
  type ButtonProps,
  PopoverTrigger,
  type SearchableCheckboxProps,
  SearchableList,
} from '@motion/ui/base'
import { NOOP_FUNCTION } from '@motion/utils/function'

import { type ReactNode, useMemo } from 'react'

export interface FooterAction {
  label: ButtonProps['children']
  onAction: () => void
  closeOnAction?: boolean
}

export type BaseMultiDropdownProps<T> = SearchableCheckboxProps<T> & {
  itemType: 'checkbox' | 'sectioned-checkbox'
  openOnMount?: boolean
  onBlur?: () => void

  searchPlaceholder?: string
  emptyLabel?: string

  footerActions?: FooterAction[]
}

export function BaseMultiDropdown<T extends { id: string } | { id?: string }>(
  props: BaseMultiDropdownProps<T> & { children: ReactNode }
) {
  const {
    searchPlaceholder,
    emptyLabel,
    children,
    footerActions,
    ...listProps
  } = props

  const allItems = useMemo(() => {
    return listProps.itemType === 'sectioned-checkbox'
      ? listProps.sections.flatMap((s) => s.items)
      : listProps.items
    // @ts-expect-error - items / sections are conditional properties
  }, [listProps.itemType, listProps.items, listProps.sections])

  const finalFooterActions = footerActions ?? [
    {
      label: 'Clear',
      onAction: () => {
        listProps.onSelect([], NOOP_FUNCTION)
      },
      closeOnAction: true,
    },
    {
      label: 'Select all',
      onAction: () => {
        listProps.onSelect(allItems, NOOP_FUNCTION)
      },
    },
  ]

  return (
    <PopoverTrigger
      placement='bottom-start'
      openOnMount={props.openOnMount}
      onClose={props.onBlur}
      renderPopover={({ close }) =>
        allItems.length === 0 ? (
          <ActionList
            items={[
              {
                content: emptyLabel ?? 'No items found',
                onAction: close,
                disabled: true,
              },
            ]}
          />
        ) : (
          <>
            <SearchableList
              {...listProps}
              inputProps={{ placeholder: searchPlaceholder }}
            />
            {finalFooterActions.length > 0 && (
              <div className='border-t border-dropdown-border py-1.5 px-2 flex justify-between'>
                {finalFooterActions.map((action) => {
                  return (
                    <Button
                      key={String(action.label)}
                      onClick={() => {
                        action.onAction()
                        if (action.closeOnAction) {
                          close()
                        }
                      }}
                      variant='outlined'
                      sentiment='neutral'
                      size='small'
                    >
                      {action.label}
                    </Button>
                  )
                })}
              </div>
            )}
          </>
        )
      }
    >
      {children}
    </PopoverTrigger>
  )
}
