import { templateStr } from '@motion/react-core/strings'

import { UnfitTaskTooltipHeader } from './unfit-task-tooltip-header'

import { BlockerChain, type BlockerChainProps } from '../../blocker-chain'

export type UnfitBlockerChainTooltipProps = BlockerChainProps

export const UnfitBlockerChainTooltip = ({
  chain,
  task,
}: UnfitBlockerChainTooltipProps) => {
  return (
    <div className='flex flex-col gap-2 text-sm leading-5 py-0.5 px-1 text-left'>
      <UnfitTaskTooltipHeader />

      <span className='text-tooltip-text-subtle'>
        {templateStr(
          'This is because {{styled}} in the blockers chain prevents this task from being scheduled:',
          {
            styled: (
              <span className='text-semantic-warning-text-default'>
                *one or more tasks
              </span>
            ),
          }
        )}
      </span>

      <BlockerChain task={task} chain={chain} variant='unfit' />

      <span className='italic text-tooltip-text-subtle'>
        If this seems wrong, try removing the blocking relationships for this
        task.
      </span>
    </div>
  )
}
