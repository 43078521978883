import { useDebouncedCallback } from '@motion/react-core/hooks'

import {
  MentionComponentBase,
  MentionComponentLink,
} from '~/areas/notes/components'
import { StatusBadge } from '~/global/components/badges'
import { useWorkspaceStatusById } from '~/global/hooks'
import { useTaskModalUrl } from '~/global/navigation'
import { useTaskByIdV2 } from '~/global/rpc/v2'
import { useRef, useState } from 'react'

import { TaskMentionHoverCard } from './components/task-mention-hover-card'

type ConnectedTaskMentionProps = {
  id: string
  withHoverCard?: boolean
}

export const ConnectedTaskMention = ({
  id,
  withHoverCard = false,
}: ConnectedTaskMentionProps) => {
  const mentionRef = useRef<HTMLAnchorElement>(null)

  const [isHovered, setIsHovered] = useState(false)

  const debouncedSetIsHovered = useDebouncedCallback(setIsHovered, 350)

  const { data: task } = useTaskByIdV2({ id: id })
  const status = useWorkspaceStatusById(task?.statusId)

  const buildTaskModalUrl = useTaskModalUrl()

  const handleMouseEnter = () => {
    debouncedSetIsHovered(true)
  }

  const handleMouseLeave = () => {
    debouncedSetIsHovered.flush()
    setIsHovered(false)
  }

  return (
    <MentionComponentLink
      ref={mentionRef}
      url={buildTaskModalUrl({ task: id })}
      onMouseEnter={withHoverCard ? handleMouseEnter : undefined}
      onMouseLeave={withHoverCard ? handleMouseLeave : undefined}
    >
      <MentionComponentBase
        badge={
          <StatusBadge
            value={status}
            iconVariant={
              task?.type === 'NORMAL' && task.isUnvisitedStage
                ? 'isUnvisitedStage'
                : undefined
            }
            hideTooltip
          />
        }
        label={task?.name ?? 'No task'}
      />
      {isHovered && task?.type === 'NORMAL' && (
        <TaskMentionHoverCard task={task} />
      )}
    </MentionComponentLink>
  )
}
