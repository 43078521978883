import {
  ArchiveSolid,
  ArrowDownSolid,
  ArrowUpSolid,
  CogSolid,
  DotsHorizontalSolid,
  ExternalLinkSolid,
} from '@motion/icons'
import { ActionList, IconButton, PopoverTrigger } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type WorkspaceSchema } from '@motion/zod/client'

import { useDesktopTabs } from '~/areas/desktop/hooks'
import { useUriByRouteId } from '~/routing'

type WorkspaceActionMenuProps = {
  workspaceId: WorkspaceSchema['id']
  moveActionAllowed?: { up: boolean; down: boolean }
  onMoveItem: (moveBy: 1 | -1) => void
}

function useWorkspaceMenuDropdownSections({
  workspaceId,
  moveActionAllowed,
  onMoveItem,
}: WorkspaceActionMenuProps) {
  const getRouteUri = useUriByRouteId()
  const { hasTabs, openTab, canOpenTab, tabTooltip } = useDesktopTabs()

  const handleOpenInNewTab = () => {
    openTab(getRouteUri('workspace-detail', { workspaceId }), 'WORKSPACE')
  }

  return [
    {
      items: [
        {
          prefix: <ArchiveSolid />,
          content: 'Archived Tasks',
          url: getRouteUri('workspace-archive', { workspaceId }),
        },
        hasTabs && {
          prefix: <ExternalLinkSolid />,
          content: 'Open in new tab',
          onAction: handleOpenInNewTab,
          disabled: !canOpenTab,
          tooltip: tabTooltip,
        },
      ],
    },
    !!moveActionAllowed && {
      items: [
        {
          prefix: <ArrowUpSolid />,
          content: 'Move up',
          disabled: !moveActionAllowed.up,
          onAction: () => onMoveItem(-1),
        },
        {
          prefix: <ArrowDownSolid />,
          content: 'Move down',
          disabled: !moveActionAllowed.down,
          onAction: () => onMoveItem(1),
        },
      ],
    },
    {
      items: [
        {
          prefix: <CogSolid />,
          content: 'Workspace Settings',
          url: getRouteUri('workspace-settings', { workspaceId }),
        },
      ],
    },
  ]
}

export function WorkspaceActionMenu(props: WorkspaceActionMenuProps) {
  return (
    <PopoverTrigger
      placement='bottom'
      renderPopover={({ close }) => (
        <WorkspaceActionList close={close} {...props} />
      )}
    >
      <IconButton
        variant='muted'
        sentiment='neutral'
        size='xsmall'
        icon={DotsHorizontalSolid}
        onClick={() => {
          recordAnalyticsEvent('FOLDERS_CLICKED_SIDEBAR_BUTTON', {
            itemType: 'WORKSPACE',
            button: 'ACTIONS',
          })
        }}
      />
    </PopoverTrigger>
  )
}

export function WorkspaceActionList({
  close,
  ...props
}: WorkspaceActionMenuProps & { close: () => void }) {
  const sections = useWorkspaceMenuDropdownSections(props)

  return <ActionList sections={sections} onActionAnyItem={close} />
}
