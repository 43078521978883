import { type NoteSchema } from '@motion/rpc-types'
import { showToast, ToasterState } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useCopyNoteMutation } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useUriByRouteId } from '~/routing'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'

export const useCopyNote = () => {
  const navigate = useNavigate()
  const getRouteUri = useUriByRouteId({ noDefaults: true })

  const { mutateAsync: duplicateNote } = useCopyNoteMutation()

  return useCallback(
    async (noteId: NoteSchema['id']) => {
      const toast = showToast('loading', 'Duplicating doc')
      try {
        const note = await duplicateNote({ id: noteId })

        recordAnalyticsEvent('PROJECT_MANAGEMENT_NOTE_DUPLICATE')

        navigate(getRouteUri('notes-detail', { noteId: note.id }))

        ToasterState.dismiss(toast)

        return note
      } catch (err) {
        ToasterState.dismiss(toast)

        showErrorToast('Could not duplicate doc')
      }
    },
    [duplicateNote, getRouteUri, navigate]
  )
}
