export const stageTemplateUrlSearchParams = [
  'forWorkspace',
  'stage',
  'stageId',
] as const

export type StageTemplateUrlSearchParams = {
  [K in (typeof stageTemplateUrlSearchParams)[number]]?: string
}

export type StageTemplateModalUrlProps = {
  workspaceId?: string
  stageId?: string
}

export const clearStageTemplateSearchParams = (
  searchParams: URLSearchParams
) => {
  stageTemplateUrlSearchParams.forEach((param) => searchParams.delete(param))
  return searchParams
}
