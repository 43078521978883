import { isDateBetweenDates, safeParseDate } from '@motion/utils/dates'

import { DateTime } from 'luxon'
import { type FC } from 'react'

import { useStageTimelineContext } from './context'
import { ConnectedStageConnector } from './stage-connector'
import { StageTimelineItem } from './stage-timeline-item'
import { TodayMarker } from './today-marker'
import { type StageGroup } from './utils'

type StageTimelineSegmentProps = {
  stageGroup: StageGroup
  prevGroup: StageGroup | undefined
  nextGroup: StageGroup | undefined
  isLastGroup: boolean
}

export const StageTimelineSegment: FC<StageTimelineSegmentProps> = ({
  stageGroup,
  prevGroup,
  nextGroup,
  isLastGroup,
}) => {
  const { project } = useStageTimelineContext()
  const today = DateTime.now().startOf('day')

  const stageDate = safeParseDate(stageGroup.dueDateKey)
  const prevTimelineDate =
    prevGroup != null
      ? safeParseDate(prevGroup.dueDateKey)
      : safeParseDate(project.startDate)
  const nextStageDate =
    nextGroup != null ? safeParseDate(nextGroup.dueDateKey) : null

  const isTodayDirectlyBeforeThisStage = isDateBetweenDates(
    prevTimelineDate?.plus({ days: 1 }) ?? today,
    stageDate,
    today,
    { inclusive: true }
  )

  const isTodayDirectlyAfterThisStage = isDateBetweenDates(
    stageDate,
    nextStageDate,
    today,
    { inclusive: true }
  )

  return (
    <>
      {isTodayDirectlyBeforeThisStage && (
        <TodayMarker stageGroup={stageGroup} />
      )}

      <StageTimelineItem stageGroup={stageGroup} />

      {!isLastGroup && (
        <ConnectedStageConnector
          groupedStages={nextGroup}
          type={
            // since this check is now inclusive, do not set this to true if this is the stage after the today
            // marker
            isTodayDirectlyAfterThisStage && !isTodayDirectlyBeforeThisStage
              ? 'firstHalf'
              : 'full'
          }
        />
      )}
    </>
  )
}
