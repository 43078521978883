import { useActiveFilter } from '@motion/ui-logic/pm/data'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasFeaturePermission } from '@motion/web-billing'

import { PageSelector } from '../header/page-selector'
import { usePageData } from '../routes/hooks/use-v3-page-data'
import { useViewState } from '../view-state'

export const ConnectedPageSelector = () => {
  const [viewState, setViewState] = useViewState()
  const [filterState, setFilters] = useActiveFilter()
  const pageData = usePageData()

  const canUseGantt = useHasFeaturePermission('gantt')

  return (
    <PageSelector
      canUseGantt={canUseGantt}
      value={viewState.page}
      onChange={(value) => {
        recordAnalyticsEvent('PM_PAGE_VIEW', {
          context: pageData.page,
          layout: viewState.page,
        })

        if (value === 'gantt' && filterState.target === 'tasks') {
          setFilters((prev) => ({ ...prev, target: 'projects' }))
        }

        // @ts-expect-error - dashboard is page type
        setViewState((prev) => {
          return {
            ...prev,
            page: value,
          }
        })
      }}
    />
  )
}
