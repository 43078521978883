/* eslint react-refresh/only-export-components: ["warn"] */
import { Button, TextHeader } from '@motion/ui/base'
import { errorInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'

import React, { type ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { pathForRoute } from 'routing'

import { Paragraph } from '../../../components/Common'

type State = {
  error: Error | null
}

type Props = {
  children: ReactNode
}

export class ErrorPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(ex: Error) {
    // Update state so the next render will show the fallback UI.
    return { error: ex }
  }

  componentDidCatch(error: Error) {
    errorInDev(error)
    Sentry.captureException(error, { tags: { position: 'ErrorBoundary' } })
    try {
    } catch (e) {
      void e
    }
  }

  render() {
    const { error } = this.state
    if (error == null) {
      return this.props.children
    }

    return (
      <div className=' flex items-center justify-center'>
        <div>
          <div className='flex flex-col gap-3 max-w-[600px] text-center'>
            <TextHeader>Oops! Something went wrong.</TextHeader>
            {__IS_DEV__ && error ? (
              <DisplayDevError
                error={error}
                onRetry={() => this.setState({ error: null })}
              />
            ) : (
              <LinkToCalendar />
            )}
          </div>
        </div>
      </div>
    )
  }
}

const CALENDAR_ROUTE = pathForRoute('/calendar')

const LinkToCalendar = () => (
  <Paragraph className='text-light-1100 dark:text-dark-400'>
    Click{' '}
    <Link to={CALENDAR_ROUTE} style={{ fontWeight: 'bold' }}>
      here
    </Link>{' '}
    to go back to your calendar
  </Paragraph>
)

type DisplayDevErrorProps = {
  error: unknown
  onRetry(): void
}

const DisplayDevError = (props: DisplayDevErrorProps) => {
  const text =
    props.error instanceof Error
      ? formatStack(props.error)
      : 'Unknown: ' + JSON.stringify(props.error, undefined, 2)

  return (
    <div className='flex flex-col gap-3'>
      <div className='w-min self-center'>
        <Button sentiment='warning' onClick={props.onRetry}>
          Retry
        </Button>
      </div>
      <div
        className='text-light-1100 dark:text-dark-400 first:pl-0'
        style={{ whiteSpace: 'unset', textAlign: 'left' }}
      >
        {text}
      </div>
    </div>
  )
}

function formatStack(ex: Error) {
  let cur = ex
  const elements = []
  let depth = 0
  while (cur != null) {
    elements.push(formatError(cur, depth))
    if (!(ex.cause instanceof Error)) break
    cur = ex.cause
    depth++
  }
  return elements
}

function formatError(ex: Error, depth: number) {
  const message = ex.toString()
  const stackLines = (ex.stack ?? '')?.split('\n')

  const lines = [message, ...stackLines.slice(1, 4).map(formatLine)]

  return (
    <div className='first:pl-0' key={depth}>
      {lines.map((x, i) => (
        <pre key={i} className='pl-2'>
          {x}
        </pre>
      ))}
    </div>
  )
}

const LINE_RE = /http.+?((?:\/[^\/]+){3}):(\d+:\d+)/gm
function formatLine(line: string) {
  return line.replace(LINE_RE, (_, file) => `${file}`)
}
