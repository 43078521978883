import { useSharedState } from '@motion/react-core/shared-state'
import { classed } from '@motion/theme'
import { Banner } from '@motion/ui/base'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'
import { parseDate } from '@motion/utils/dates'
import { useHasFeaturePermission } from '@motion/web-billing'
import { useTitle } from '@motion/web-common/html'
import { IndexedDbStateSerializer } from '@motion/web-common/storage'
import { type TaskSchema, type WorkspaceMemberSchema } from '@motion/zod/client'

import { OpenLeftSidebarButton } from '~/areas/sidebar/components'
import { TaskCounter } from '~/areas/tasks/components'
import { ErrorPage } from '~/global/components/error-page'
import { ActiveFilterItemsWorkspaceContext } from '~/global/contexts'
import { useCurrentTeam } from '~/global/rpc/team'
import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'

import { ConnectedDateSelector } from './components/filter/date-selector'
import { ConnectedFieldSelector } from './components/filter/field-selector'
import { Cell } from './components/grid/cell'
import { DayHeader } from './components/grid/day-header'
import { MemberHeader } from './components/grid/member-header'
import { SortMembersButton } from './components/grid/sort-members-button'
import { UpsellPage } from './components/upsell'
import {
  TeamScheduleActiveViewOptionsKey,
  type TeamScheduleViewState,
  useViewOptions,
} from './context'
import { SyncPageDataContext } from './context/page-data-context'
import { TaskContextProvider } from './context/task-context'
import { ValidateViewOptions } from './context/validate-order-order'
import { definitions } from './filters'
import { ConnectedTeamScheduleTaskFetch } from './filters/filter-overrides'
import { useSortedUsers } from './hooks/use-sorted-users'
import { type BucketProps, mergeDurationBy, pivot } from './pivot'
import { ConnectedViewSection } from './views/components'
import { SaveTeamViewModalTrigger } from './views/modals'

import {
  AppWorkspaceContext,
  type AppWorkspaceDataContext,
  FilteredWorkspaceContext,
} from '../../../../global/contexts'
import { PageTasksContextKey } from '../../../../tasks/context'
import {
  ConnectedLegacyFilterButtons,
  FilterBar,
} from '../../../filters/components'
import { ConnectedResetFiltersButton } from '../../../filters/components/reset-filters-button'
import { UpgradeState } from '../../pm-v3/pages/upgrade-state'
import { LoadingPage } from '../common/loading-page'

const PRELOAD_KEYS = [TeamScheduleActiveViewOptionsKey, ActiveFilterKey]

export const TeamScheduleShellWithContext = () => {
  useTitle('Team Schedule')

  const teamResponse = useCurrentTeam()
  const [ctx] = useSharedState(AppWorkspaceContext)

  if (teamResponse.isLoading || !ctx.loaded) {
    return <LoadingPage id='team-schedule' />
  }

  const team = teamResponse.data
  if (team === null) {
    return <UpsellPage />
  }

  const onlyTeamMembersPredicate = (member: WorkspaceMemberSchema) => {
    return team?.members.some((x) => x.userId === member.userId) ?? false
  }

  return (
    <IndexedDbStateSerializer prefix='team-schedule' preload={PRELOAD_KEYS}>
      <FilteredWorkspaceContext
        name='shell'
        workspaces={(workspaces) => workspaces.filter((w) => w.type === 'TEAM')}
        filterMembers={onlyTeamMembersPredicate}
        views={(views) => views.filter((x) => x.type === 'team-schedule')}
      >
        <ConnectedTeamScheduleTaskFetch />
        <SyncPageDataContext />
        <ValidateViewOptions />

        <ConnectedTeamScheduleShell />
      </FilteredWorkspaceContext>
    </IndexedDbStateSerializer>
  )
}

export const ConnectedTeamScheduleShell = () => {
  const [viewOptions] = useViewOptions(TeamScheduleActiveViewOptionsKey)
  const canUseTeamSchedule = useHasFeaturePermission('teamSchedule')

  // need to wait until everything is loaded before calling tasks
  const [ctx] = useSharedState(AppWorkspaceContext)
  const [data] = useSharedState(PageTasksContextKey)

  if (data == null || !ctx.loaded) {
    return <LoadingPage id='team-scheduled-shell' />
  }

  if (!canUseTeamSchedule) {
    return <UpgradeState feature='teamSchedule' />
  }

  return (
    <ErrorPage>
      <TeamScheduleShell context={ctx} viewOptions={viewOptions} tasks={data} />
    </ErrorPage>
  )
}

type TeamScheduleShellProps = {
  context: AppWorkspaceDataContext
  viewOptions: TeamScheduleViewState
  tasks: TaskSchema[]
}

export const TeamScheduleShell = (props: TeamScheduleShellProps) => {
  const { viewOptions, tasks: data } = props

  const firstDay = parseDate(viewOptions.column.from)

  const columns: BucketProps[] = []
  for (let i = 0; i < 7; i++) {
    const day = firstDay.plus({ day: i })
    if (viewOptions.hideWeekends && isWeekend(day.toISO())) continue
    columns.push({
      id: firstDay.plus({ days: i }).toISODate(),
    })
  }

  const sortedUsers = useSortedUsers()

  const rows = Array.from(sortedUsers, (x) => ({ id: x.id, user: x }))

  const getPivotColumnId = useCallback(
    (task: TaskSchema) => {
      if (viewOptions.column.field === 'dueDate') {
        return task.dueDate ? parseDate(task.dueDate).toISODate() : null
      }

      const value = task.duration ? task.scheduledStart : task.dueDate
      return value ? parseDate(value).toISODate() : null
    },
    [viewOptions.column.field]
  )

  const tasks = useMemo(
    () =>
      viewOptions.column.field === 'scheduledStart'
        ? mergeDurationBy(data, (task) => {
            return [
              getPivotColumnId(task),
              task.type,
              task.type === 'CHUNK' ? task.parentChunkTaskId : task.id,
              task.completedTime ? 'complete' : 'incomplete',
            ].join('|')
          })
        : data,
    [data, getPivotColumnId, viewOptions.column.field]
  )

  const grid = pivot({
    tasks,
    getColumnId: getPivotColumnId,
    getRowId: (task) => task.assigneeUserId,
    rows,
    columns,
  })

  const shouldShowTotals = viewOptions.column.field === 'scheduledStart'

  return (
    <Container>
      <HeaderBar>
        <TitleBarContainer>
          <OpenLeftSidebarButton />
          <TitleBarHeader>Team Schedule</TitleBarHeader>
          <ConnectedViewSection />
          <ConnectedDateSelector />
        </TitleBarContainer>
        <FilterRow>
          <ActiveFilterItemsWorkspaceContext>
            <FilterBar>
              <ConnectedFieldSelector />
              <ConnectedLegacyFilterButtons
                definitions={definitions}
                applyTo='tasks'
              />
              <TaskCounter />
              <ConnectedResetFiltersButton />
            </FilterBar>
          </ActiveFilterItemsWorkspaceContext>
          <Spacer />
        </FilterRow>
      </HeaderBar>
      <TaskContextProvider>
        <div className='flex flex-col gap-2 h-full min-h-0'>
          <NoWorkspacesBanner />
          <Content hideWeekends={viewOptions.hideWeekends}>
            {Array.from(grid.cellsInOrder()).map((cell) => {
              return (
                <Cell
                  key={cell.id}
                  gridRow={cell.rowIndex + 2}
                  gridColumn={cell.colIndex + 2}
                  tasks={cell.items}
                  viewBy={viewOptions.column.field}
                  showTotals={shouldShowTotals}
                />
              )
            })}
            {columns.map((c, colIndex) => (
              <DayHeader
                gridRow={1}
                gridColumn={colIndex + 2}
                key={c.id}
                day={c.id}
              />
            ))}
            {rows.map((r, rowIndex) => {
              const user = r.user
              const memberTasks = grid.getRowItems(r.id)
              return (
                <MemberHeader
                  key={r.id}
                  gridRow={rowIndex + 2}
                  gridColumn={1}
                  member={user}
                  tasks={memberTasks}
                  showTotals={shouldShowTotals}
                />
              )
            })}
            <SortMembersButton />
          </Content>
        </div>
        <SaveTeamViewModalTrigger />
      </TaskContextProvider>
    </Container>
  )
}

function isWeekend(value: string) {
  return DateTime.fromISO(value).weekday >= 6
}

const Spacer = () => <div className='flex-1' />

const NoWorkspacesBanner = () => {
  const [ctx] = useSharedState(AppWorkspaceContext)
  if (ctx.workspaces.all.length > 0) return null
  return (
    <Banner sentiment='warning'>
      ⛔️ You can’t see any tasks on Team Schedule because you aren’t part of
      any workspaces. Ask your team to invite you to a workspace!
    </Banner>
  )
}

const Container = classed('div', {
  base: `
  flex flex-col overflow-hidden w-full
  bg-calendar-bg-default
  `,
})
const FilterRow = classed('div', {
  base: `flex gap-2
  items-start`,
})
const HeaderBar = classed('div', {
  base: `
  flex flex-col
  border-b border-semantic-neutral-border-subtle
  pl-6 pr-4
  pb-4
  `,
})

const Content = classed('div', {
  base: `
    bg-calendar-bg-default
    min-h-0 h-full
    overflow-scroll

    grid grid-cols-[85px,repeat(var(--pivot-columns),minmax(200px,1fr))] grid-rows-[30px] auto-rows-[230px]

    [&>*]:outline-1 [&>*]:outline-offset-[-0.5px] [&>*]:outline
    [&>*]:outline-semantic-neutral-border-subtle
  `,
  variants: {
    hideWeekends: {
      true: 'setvar-[pivot-columns=5]',
      false: 'setvar-[pivot-columns=7]',
    },
  },
})

export const TitleBarContainer = classed('div', {
  base: `
    flex flex-wrap items-center gap-2
    py-4
  `,
})

export const TitleBarHeader = classed('h2', {
  base: `
    text-semantic-neutral-text-default
    font-semibold
    text-base
    flex-1
  `,
})
