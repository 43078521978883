import { createFetch } from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
  type V2QueryResponseShape,
} from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'

import { notifyManager } from '@tanstack/react-query'

import { type HandlerContext } from './types'
import { buildQueryContext, type MutationIds } from './utils'

import { log } from '../log'

const KEYS_TO_UPDATE = [MODEL_CACHE_KEY, API.workspacesV2.queryKeys.root]

const updateFilter = createQueryFilter(KEYS_TO_UPDATE)

export async function refreshProjects(
  ctx: HandlerContext,
  mutations: MutationIds
) {
  log.info('refresh-projects', mutations)

  const queryProjects = createFetch<typeof API.projectsV2.queryByIds>(
    API.projectsV2.queryByIds,
    buildQueryContext(ctx)
  )

  const idsToFetch = Array.from(
    new Set([...mutations.update, ...mutations.create])
  )
  let projects: V2QueryResponseShape | undefined

  if (idsToFetch.length > 0) {
    projects = await queryProjects({
      filter: {
        ids: idsToFetch,
      },
    })
  }

  notifyManager.batch(() => {
    if (mutations.delete.length) {
      MotionCache.delete(ctx.client, updateFilter, 'projects', mutations.delete)
    }

    if (projects) {
      MotionCache.upsert(ctx.client, updateFilter, projects)
    }
  })
}
