import { RefreshSolid } from '@motion/icons'
import { useDependantState } from '@motion/react-core/hooks'
import { templateStr } from '@motion/react-core/strings'
import {
  Button,
  ButtonGroup,
  PopoverButton,
  PopoverTrigger,
  SearchableList,
  showToast,
} from '@motion/ui/base'
import { daysOptions, getVisualDaysOptions, WEEKDAYS } from '@motion/ui-logic'
import {
  fqSpecificDays,
  fqSpecificDays1stWeek,
  frequencyConfig,
  type FrequencySelectOption,
  getCurrentFrequency,
  getFrequencyLabel,
  getLegacyFrequencyKey,
} from '@motion/ui-logic/utils'
import { isEqual } from '@motion/utils/core'
import {
  type DaysOfWeekSchema,
  RecurringTaskFrequencySchema,
  type RecurringTaskSchema,
} from '@motion/zod/client'

import { useCalendarStartDay } from '~/areas/calendar/hooks'
import {
  ModalFieldButton,
  type ModalFieldButtonProps,
} from '~/areas/task-project/components'
import { useI18N } from '~/global/contexts'
import { useController } from 'react-hook-form'

import { useTaskForm } from '../hooks'

const frequencyOptions = RecurringTaskFrequencySchema
const frequencyOptionsArray = Array.from(frequencyOptions)

type RecurringState = {
  frequency: RecurringTaskSchema['frequency']
  days: RecurringTaskSchema['days']
  meta: RecurringTaskSchema['recurrenceMeta']
}

export type RecurringFieldProps = Pick<
  ModalFieldButtonProps,
  'variant' | 'size'
>

export const RecurringField = ({ ...buttonProps }: RecurringFieldProps) => {
  const { form } = useTaskForm()
  const { formatList } = useI18N()

  const { watch, control } = form

  const isAutoScheduled = watch('isAutoScheduled')
  const selectedFrequency = watch('frequency')
  const selectedDays = watch('days')
  const selectedMeta = watch('recurrenceMeta') || fqSpecificDays.key

  const [recurringInternalState, setRecurringInternalState] =
    useDependantState<RecurringState>(
      () => ({
        frequency: selectedFrequency,
        days: selectedDays ?? [],
        meta: selectedMeta,
      }),
      [selectedFrequency, selectedDays, selectedMeta]
    )

  const hasSpecificDaysChoice =
    recurringInternalState.meta === fqSpecificDays.key ||
    recurringInternalState.meta === fqSpecificDays1stWeek.key

  const { field: frequencyField } = useController({
    name: 'frequency',
    control,
  })
  const { field: recurringMetaField } = useController({
    name: 'recurrenceMeta',
    control,
  })
  const { field: recurringDaysField } = useController({
    name: 'days',
    control,
    rules: {
      validate: (value) => {
        if (hasSpecificDaysChoice && value != null && value.length < 1) {
          return 'Select at least one recurring day'
        }
      },
    },
  })

  const currentFrequency = getCurrentFrequency(recurringInternalState.frequency)
  const frequencyLabel = getFrequencyLabel(recurringInternalState.frequency)

  const recurringMetaOptions = frequencyConfig.getLevel2Options(
    getLegacyFrequencyKey(recurringInternalState.frequency)
  )
  const currentMeta = recurringMetaOptions.find(
    (option) => option.key === recurringInternalState.meta
  )

  const templateFrequencyString = hasSpecificDaysChoice
    ? '{{frequency}} on {{days}}'
    : '{{frequency}} on {{meta}}'

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={() => (
        <RecurringPopoverContent
          currentMeta={currentMeta}
          currentFrequency={currentFrequency}
          hasSpecificDaysChoice={hasSpecificDaysChoice}
          recurringMetaOptions={recurringMetaOptions}
          recurringState={recurringInternalState}
          setRecurringState={setRecurringInternalState}
        />
      )}
      onClose={() => {
        if (
          isEqual(recurringInternalState, {
            frequency: frequencyField.value,
            days: recurringDaysField.value,
            meta: recurringMetaField.value,
          })
        ) {
          return
        }

        recurringDaysField.onChange(recurringInternalState.days)
        recurringMetaField.onChange(recurringInternalState.meta)
        // Make sure we update the frequency last, because in autosave, the api call is only sent when frequency is triggered
        frequencyField.onChange(recurringInternalState.frequency)
      }}
    >
      <ModalFieldButton
        {...buttonProps}
        label='Repeats'
        sentiment={isAutoScheduled ? 'ai' : 'neutral'}
        wrap
      >
        <RefreshSolid />

        <span className='flex-1'>
          {templateStr(templateFrequencyString, {
            frequency: frequencyLabel,
            meta: currentMeta?.label,
            days: convertDayValuesToLabel(
              recurringInternalState.days ?? [],
              currentFrequency,
              formatList
            ),
          })}
        </span>
      </ModalFieldButton>
    </PopoverTrigger>
  )
}

type RecurringPopoverContentProps = {
  currentMeta: FrequencySelectOption | undefined
  currentFrequency: FrequencySelectOption | undefined
  hasSpecificDaysChoice: boolean
  recurringMetaOptions: FrequencySelectOption[]
  recurringState: RecurringState
  setRecurringState: (recurringState: RecurringState) => void
}

function RecurringPopoverContent({
  currentMeta,
  currentFrequency,
  hasSpecificDaysChoice,
  recurringMetaOptions,
  recurringState,
  setRecurringState,
}: RecurringPopoverContentProps) {
  const calendarStartDay = useCalendarStartDay()

  const selectedFrequency = recurringState.frequency
  const selectedDays = recurringState.days ?? []
  const selectedMeta = recurringState.meta

  const onChangeFrequency = (frequency: RecurringTaskFrequencySchema) => {
    const recurringMetaOptions = frequencyConfig.getLevel2Options(
      getLegacyFrequencyKey(frequency)
    )
    const nextMeta = recurringMetaOptions.find(
      (option) => option.key === selectedMeta
    )

    const updatedMeta =
      nextMeta == null && recurringMetaOptions.length > 0
        ? recurringMetaOptions[0].key
        : (selectedMeta ?? recurringMetaOptions[0].key)

    setRecurringState({
      ...recurringState,
      frequency,
      meta: updatedMeta,
    })
  }

  const onChangeRecurringMeta = (
    recurringMeta: FrequencySelectOption['key']
  ) => {
    setRecurringState({
      ...recurringState,
      meta: recurringMeta,
    })
  }

  const onChangeRecurringDays = (days: DaysOfWeekSchema[]) => {
    setRecurringState({
      ...recurringState,
      days: days,
    })
  }

  return (
    <div className='p-4 flex flex-col gap-2'>
      <div className='text-xs text-semantic-neutral-text-default'>Repeat</div>
      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <SearchableList
            searchable={false}
            items={frequencyOptionsArray}
            computeKey={(item) => item}
            computeSelected={(item) => item === selectedFrequency}
            onSelect={(item) => {
              onChangeFrequency(item)
              close()
            }}
            renderItem={(item) => getFrequencyLabel(item)}
          />
        )}
      >
        <PopoverButton>{currentFrequency?.label}</PopoverButton>
      </PopoverTrigger>

      {recurringMetaOptions.length > 0 && (
        <PopoverTrigger
          placement='bottom-start'
          renderPopover={({ close }) => (
            <SearchableList
              items={recurringMetaOptions}
              computeKey={(item) => item.key}
              computeSelected={(item) => item.key === selectedMeta}
              onSelect={(item) => {
                onChangeRecurringMeta(item.key)
                close()
              }}
              renderItem={(item) => item.label}
              inputProps={{ placeholder: 'Choose...' }}
            />
          )}
        >
          <PopoverButton>{currentMeta?.label}</PopoverButton>
        </PopoverTrigger>
      )}

      {hasSpecificDaysChoice && (
        <>
          <div className='text-xs text-semantic-neutral-text-default mt-1'>
            {selectedFrequency === 'DAILY'
              ? 'On these days'
              : 'On one of these days'}
          </div>
          <ButtonGroup segmented>
            {getVisualDaysOptions(calendarStartDay).map((day) => (
              <Button
                key={day.value}
                size='small'
                variant={
                  selectedDays.includes(day.value) ? 'solid' : 'outlined'
                }
                sentiment={
                  selectedDays.includes(day.value) ? 'primary' : 'neutral'
                }
                onClick={() => {
                  // keep order because it seems important
                  const newItems = daysOptions
                    .map((option) => {
                      if (option.value === day.value) {
                        if (selectedDays.includes(option.value)) return false
                        return option.value
                      }

                      return selectedDays.includes(option.value)
                        ? option.value
                        : false
                    })
                    .filter(Boolean)

                  if (newItems.length === 0) {
                    return showToast('error', 'Day selection cannot be empty')
                  }

                  onChangeRecurringDays(newItems as DaysOfWeekSchema[])
                }}
              >
                {day.label}
              </Button>
            ))}
          </ButtonGroup>
        </>
      )}
    </div>
  )
}

function convertDayValuesToLabel(
  dayValues: string[],
  currentFrequency: FrequencySelectOption | undefined,
  formatList: ReturnType<typeof useI18N>['formatList']
) {
  if (dayValues.length === 5 && WEEKDAYS.every((d) => dayValues.includes(d))) {
    return 'Weekdays'
  }

  if (
    dayValues.length === 7 &&
    daysOptions.every((d) => dayValues.includes(d.value))
  ) {
    return 'Every day'
  }

  const isDaily = currentFrequency?.key === 'daily'

  return formatList(
    dayValues.map((value) => {
      return (
        daysOptions.find((opt) => opt.value === value)?.fullLabel ?? 'no days'
      )
    }),
    isDaily ? 'conjunction' : 'disjunction'
  )
}
