import { PencilSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { Button, type ButtonProps } from '@motion/ui/base'
import {
  type FlowTemplateFormFields,
  type FlowTemplateFormTask,
} from '@motion/ui-logic/pm/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

export type SharedEditTaskDefinitionButtonProps = {
  icon?: React.ReactNode
  text?: string
  variant?: ButtonProps['variant']
  taskId: string
  workspaceId: string
  handleTaskDefinitionChange: (response: FlowTemplateFormTask) => void
  dirtyTasksMap: Record<string, boolean>
  getFormValues: () => FlowTemplateFormFields
}

export const SharedEditTaskDefinitionButton = ({
  icon,
  text,
  variant,
  taskId,
  workspaceId,
  handleTaskDefinitionChange,
  dirtyTasksMap,
  getFormValues,
}: SharedEditTaskDefinitionButtonProps) => {
  const modalApi = useModalApi()

  const openTaskDefinitionModal = async () => {
    recordAnalyticsEvent('OPEN_TASK_DEFINITION_MODAL', {
      taskId,
    })

    const response = await modalApi.prompt('task-definition-modal', {
      taskDefinitionId: taskId,
      initialDirtyTasks: dirtyTasksMap,
      workspaceId,
      flowFormData: getFormValues(),
    })

    recordAnalyticsEvent('CLOSE_TASK_DEFINITION_MODAL', {
      taskId,
      state: response === ModalDismissed ? 'dismissed' : 'updated',
    })

    if (response !== ModalDismissed) {
      handleTaskDefinitionChange(response.task)
    }
  }

  return (
    <Button
      data-testid='edit-task-definition-button'
      variant={variant ?? 'outlined'}
      sentiment='neutral'
      size='xsmall'
      onClick={openTaskDefinitionModal}
    >
      {templateStr('{{icon}} {{text}}', {
        icon: icon ?? <PencilSolid />,
        text: text ?? 'Edit',
      })}
    </Button>
  )
}
