import { classed } from '@motion/theme'
import { Tooltip } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'

import { useTaskUpdater } from '~/areas/tasks/hooks'
import { useTaskModalUrl } from '~/global/navigation'
import { type FocusEvent, type KeyboardEvent } from 'react'
import { useController } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { useActionItemForm } from '../hooks'
import { useActionItemContext } from '../provider'

type ActionItemTitleProps = {
  editMode: boolean
  setEditMode: (editMode: boolean) => void
}

export function ActionItemTitle({
  editMode,
  setEditMode,
}: ActionItemTitleProps) {
  const { setModalVisible, isRejected, taskNotAccessible, task } =
    useActionItemContext()

  const { form } = useActionItemForm()
  const { field: nameField } = useController({
    name: 'name',
    control: form.control,
  })

  const buildTaskModalUrl = useTaskModalUrl()
  const updateTask = useTaskUpdater()

  const handleOnSave = async (name: string) => {
    if (name.trim() !== '' && name !== nameField.value) {
      nameField.onChange(name)

      if (task != null) {
        await updateTask(task.id, { name })
      }
    }

    setEditMode(false)
  }

  if (editMode) {
    return (
      <EditTaskNameInput
        initialName={nameField.value}
        onSave={handleOnSave}
        onCancel={() => setEditMode(false)}
      />
    )
  }

  if (task != null) {
    return (
      <Tooltip
        renderContent={() =>
          taskNotAccessible
            ? "This task was created in a workspace you don't have access to."
            : null
        }
        asChild
      >
        <TaskTitle
          as={taskNotAccessible ? 'span' : Link}
          to={buildTaskModalUrl({
            task: task.id,
          })}
        >
          {nameField.value}
        </TaskTitle>
      </Tooltip>
    )
  }

  return (
    <TaskTitle
      as='button'
      type='button'
      disabled={isRejected}
      cancelled={isRejected}
      onClick={() => setModalVisible(true)}
    >
      {nameField.value}
    </TaskTitle>
  )
}

const TaskTitle = classed('span', {
  base: 'truncate min-w-0 [&>*]:whitespace-nowrap',
  variants: {
    cancelled: {
      true: 'text-semantic-neutral-text-subtle line-through',
      false:
        'underline underline-offset-auto decoration-solid decoration-skip-ink-none decoration-from-font text-semantic-primary-text-default',
    },
  },
  defaultVariants: {
    cancelled: false,
  },
})

type EditTaskNameInputProps = {
  initialName: string
  onSave: (name: string) => void
  onCancel: () => void
}

function EditTaskNameInput({
  initialName,
  onSave,
  onCancel,
}: EditTaskNameInputProps) {
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    onSave(e.currentTarget.value)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const { key, currentTarget } = e

    if (key === 'Enter') {
      e.stopPropagation()
      onSave(currentTarget.value)
    } else if (key === 'Escape') {
      e.stopPropagation()
      onCancel()
    }
  }

  return (
    <div className='flex items-center gap-1 shrink-0 flex-1'>
      <TextField
        defaultValue={initialName}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        size='small'
        autoFocus
        fullWidth
      />
    </div>
  )
}
