import { type NoteSchema } from '@motion/rpc-types'

import { useCachedItems } from '~/global/cache'
import { useFindFolderItem } from '~/global/hooks'
import { useNoteById, useUpdateNote } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export type UseUpdateNoteLocationProps = {
  noteId: NoteSchema['id']
}

export function useUpdateNoteLocation({ noteId }: UseUpdateNoteLocationProps) {
  const { data: note } = useNoteById({ id: noteId })

  const findFolderItem = useFindFolderItem()

  const allFolders = useCachedItems('folders')

  const { mutate: updateNote } = useUpdateNote()

  return useCallback(
    async ({
      noteId,
      projectId,
      folderId,
      workspaceId,
    }: {
      noteId: string | null
      projectId: string | null
      folderId: string | null
      workspaceId: string
    }) => {
      if (note == null) {
        return
      }

      if (note.id === noteId) {
        return showErrorToast('Cannot move a doc into itself')
      }

      const [selectedNoteFolderItem] =
        findFolderItem(
          ({ itemId, originalFolderItemId }) =>
            itemId === noteId && originalFolderItemId == null
        ) || []

      const [selectedProjectFolderItem] =
        findFolderItem(
          ({ itemId, originalFolderItemId }) =>
            itemId === projectId && originalFolderItemId == null
        ) || []

      const workspaceFolder = allFolders.find(
        ({ targetId, type }) => targetId === workspaceId && type === 'WORKSPACE'
      )

      const newFolderId = folderId ?? workspaceFolder?.id ?? undefined
      const newParentFolderItemId =
        selectedNoteFolderItem?.id ?? selectedProjectFolderItem?.id ?? undefined

      await updateNote({
        id: note.id,
        targetId:
          note.targetType === 'WORKSPACE' && note.targetId !== workspaceId
            ? workspaceId
            : undefined,
        folderId: newFolderId,
        parentFolderItemId: newParentFolderItemId,
      })
    },
    [note, allFolders, findFolderItem, updateNote]
  )
}
