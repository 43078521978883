import { type SingleNoteResponseSchema } from '@motion/rpc-types'

import { type PropsWithChildren } from 'react'

import { NoteDialogProvider } from './note-dialog-provider'
import { NoteEditorAPIProvider } from './note-editor-context-provider'

import { NoteCommentsProvider } from '../comments'

export function NoteProviders({
  children,
  data,
}: PropsWithChildren<{ data: SingleNoteResponseSchema }>) {
  return (
    <NoteDialogProvider>
      <NoteEditorAPIProvider>
        <NoteCommentsProvider data={data}>{children}</NoteCommentsProvider>
      </NoteEditorAPIProvider>
    </NoteDialogProvider>
  )
}
