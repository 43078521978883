import { type StripePaymentMethodSchema } from '@motion/rpc-types'
import { LoadingSpinner } from '@motion/ui/base'
import { useHasTreatment } from '@motion/web-common/flags'

import { useCurrentTier } from '~/areas/tiered-pricing/hooks'
import {
  useIsAdmin,
  useIsIapSubscription,
  useIsNoCardTrialing,
} from '~/global/hooks'
import { useActiveMemberCount } from '~/global/hooks/team'
import { useGetSubscriptionPaymentMethod } from '~/global/rpc'
import {
  useIndividualSubscription,
  useTeamSubscription,
  useUtilizedSubscription,
} from '~/global/rpc/subscriptions'
import { useAppSelector } from '~/state/hooks'
import { selectTeam } from '~/state/team'
import { type StripeSubscription } from '~/state/userSlice'

import {
  ConnectedPlanOverviewSection,
  ConnectedTeamPlanSection,
  IapSection,
  NonAdminSection,
  PaymentMethodSection,
} from './components'

import { SettingPageWrapper } from '../../shared'

export const BillingSettingsPage = () => {
  const teamTrialForIndividualEnabled = useHasTreatment(
    'team-trial-for-individual'
  )

  const utilizedSubscription = useUtilizedSubscription()
  const individualSubscription = useIndividualSubscription()
  const teamSubscription = useTeamSubscription()

  let primarySubscription: StripeSubscription | undefined | null
  let secondarySubscription: StripeSubscription | undefined | null

  if (!teamTrialForIndividualEnabled) {
    primarySubscription = utilizedSubscription
  } else if (
    individualSubscription &&
    individualSubscription.status !== 'canceled'
  ) {
    primarySubscription = individualSubscription
    secondarySubscription =
      teamSubscription && teamSubscription.status === 'trialing'
        ? teamSubscription
        : undefined
  } else if (teamSubscription) {
    primarySubscription = teamSubscription
  }

  const {
    data: primaryPaymentMethod,
    isFetching: isFetchingPrimaryPaymentMethod,
  } = useGetSubscriptionPaymentMethod(
    {
      subscriptionId: primarySubscription?.id ?? '',
    },
    { enabled: !!primarySubscription?.id }
  )

  const {
    data: secondaryPaymentMethod,
    isFetching: isFetchingSecondaryPaymentMethod,
  } = useGetSubscriptionPaymentMethod(
    {
      subscriptionId: secondarySubscription?.id ?? '',
    },
    { enabled: !!secondarySubscription?.id }
  )

  if (
    !primarySubscription ||
    isFetchingPrimaryPaymentMethod ||
    isFetchingSecondaryPaymentMethod
  ) {
    return (
      <div className='h-full w-full flex items-center justify-center'>
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <BillingSettings
      primarySubscriptionInformation={{
        subscription: primarySubscription,
        paymentMethod: primaryPaymentMethod,
      }}
      secondarySubscriptionInformation={
        secondarySubscription
          ? {
              subscription: secondarySubscription,
              paymentMethod: secondaryPaymentMethod,
            }
          : undefined
      }
    />
  )
}

type SubscriptionInformation = {
  subscription: StripeSubscription
  paymentMethod?: StripePaymentMethodSchema
}

interface BillingSettingsProps {
  primarySubscriptionInformation: SubscriptionInformation
  secondarySubscriptionInformation?: SubscriptionInformation
}

export const BillingSettings = ({
  primarySubscriptionInformation,
  secondarySubscriptionInformation,
}: BillingSettingsProps) => {
  const {
    subscription: primarySubscription,
    paymentMethod: primaryPaymentMethod,
  } = primarySubscriptionInformation

  const {
    subscription: secondarySubscription,
    paymentMethod: secondaryPaymentMethod,
  } = secondarySubscriptionInformation ?? {}

  const team = useAppSelector(selectTeam)

  const isIapSubscription = useIsIapSubscription()

  const teamSubscription = team?.pmTeamSubscription || null

  const isActiveTeam =
    team?.id &&
    teamSubscription &&
    teamSubscription.subscription?.id === primarySubscription.id

  const willCancel = primarySubscription.cancel_at_period_end
  const secondaryWillCancel = secondarySubscription?.cancel_at_period_end

  const isAdmin = useIsAdmin()

  const activeMemberCount = useActiveMemberCount()
  const isNoCardTrialing = useIsNoCardTrialing()

  const shouldUseTieredPricingV1 = useHasTreatment('tiered-pricing-v1')
  const tier = useCurrentTier()

  if (isActiveTeam && !isAdmin) {
    return (
      <SettingPageWrapper title='Billing'>
        <NonAdminSection />
      </SettingPageWrapper>
    )
  }

  if (isIapSubscription) {
    return (
      <SettingPageWrapper title='Billing'>
        <IapSection />
      </SettingPageWrapper>
    )
  }

  return (
    <SettingPageWrapper title='Billing'>
      {secondarySubscription && (
        <h1 className='font-semibold text-xl'>You have 2 active plans</h1>
      )}
      <ConnectedPlanOverviewSection
        stripeSubscription={primarySubscription}
        team={team}
        paymentMethod={primaryPaymentMethod}
        tier={shouldUseTieredPricingV1 ? tier : undefined}
      />
      {!secondarySubscription && (
        <ConnectedTeamPlanSection
          isActiveTeam={!!isActiveTeam}
          stripeSubscription={primarySubscription}
          numUsers={activeMemberCount ?? 1}
          team={team}
        />
      )}
      {(!willCancel || isNoCardTrialing) && (
        <PaymentMethodSection
          paymentMethod={
            secondarySubscription ? undefined : primaryPaymentMethod
          }
          teamId={isActiveTeam ? team?.id : undefined}
        />
      )}
      {secondarySubscription && (
        <>
          <ConnectedPlanOverviewSection
            stripeSubscription={secondarySubscription}
            team={team}
            paymentMethod={secondaryPaymentMethod}
            isSecondarySubscription
            tier={shouldUseTieredPricingV1 ? tier : undefined}
          />
          {!secondaryWillCancel && (
            <PaymentMethodSection paymentMethod={secondaryPaymentMethod} />
          )}
        </>
      )}
    </SettingPageWrapper>
  )
}
