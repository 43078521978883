import { StatusBadge } from '~/global/components/badges'
import { StatusDropdown } from '~/global/components/dropdowns'
import { useWorkspaceStatusById } from '~/global/hooks'
import {
  type ControllerRenderProps,
  useController,
  useFormContext,
} from 'react-hook-form'

import { type InlineTaskCreationFields } from '../form-types'

export const InlineTaskCreationStatusField = ({
  workspaceId,
}: {
  workspaceId: string
}) => {
  const formContext = useFormContext<InlineTaskCreationFields>()
  const { field } = useController({
    name: 'statusId',
    control: formContext.control,
  })

  return (
    <InnerInlineTaskCreationStatusField
      field={field}
      workspaceId={workspaceId}
    />
  )
}

const InnerInlineTaskCreationStatusField = ({
  field,
  workspaceId,
}: {
  field: ControllerRenderProps<InlineTaskCreationFields, 'statusId'>
  workspaceId: string
}) => {
  const status = useWorkspaceStatusById(field.value)

  if (!status) {
    return null
  }

  return (
    <StatusDropdown
      selectedStatusId={field.value}
      onChange={field.onChange}
      workspaceId={workspaceId}
    >
      <button type='button' className='flex p-0.5 w-4 h-4'>
        <StatusBadge value={status} size='small' />
      </button>
    </StatusDropdown>
  )
}
