import { templateStr } from '@motion/react-core/strings'
import { type ProjectSchema } from '@motion/rpc-types'

import { ConnectedProjectIcon } from '~/global/components'
import { useI18N } from '~/global/contexts'

import {
  ConfirmationText,
  ProjectList,
  ProjectName,
  ProjectSection,
} from './styled'

export type AffectedProjectListProps = {
  projects: ProjectSchema[]
  type: 'stage-definition' | 'template'
}
export function AffectedProjectList({
  projects,
  type,
}: AffectedProjectListProps) {
  const { pluralize } = useI18N()

  return (
    <ProjectSection isStage={type === 'stage-definition'}>
      <ConfirmationText>
        {templateStr('{{projectCount}} {{projectLabel}} affected', {
          projectCount: projects.length,
          projectLabel: pluralize(projects.length, 'project', 'projects'),
        })}
      </ConfirmationText>
      <ProjectList>
        {projects.map((project) => (
          <div className='flex items-center gap-1 max-w-full' key={project.id}>
            <ConnectedProjectIcon className='size-3' projectId={project.id} />
            <ProjectName>{project.name}</ProjectName>
          </div>
        ))}
      </ProjectList>
    </ProjectSection>
  )
}
