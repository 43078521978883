import { values } from '@motion/utils/object'
import { Sentry } from '@motion/web-base/sentry'

import { useTaskModalUrl } from '~/global/navigation'
import { useApproveActionItem } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'
import { type UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router'

import { type ActionItemFormFields } from '../types'

/**
 * Submit the action item form to approve the action item
 */
export function useSubmitActionItemForm(
  form: UseFormReturn<ActionItemFormFields>,
  actionItemId: string,
  openTaskModal = false
) {
  const { mutateAsync: approveAsync } = useApproveActionItem()

  const navigate = useNavigate()
  const buildTaskModalUrl = useTaskModalUrl()

  const submitHandler = useCallback(
    async (fields: ActionItemFormFields) => {
      const result = await approveAsync({
        actionItemId,
        taskData: fields,
      })

      if (result.ids.length === 0) {
        Sentry.captureException('No id returned from backend after submit', {
          extra: {
            actionItemId,
            taskData: fields,
          },
        })
        showErrorToast('Error approving action item')
        return
      }

      if (openTaskModal) {
        const actionItem = result.models.meetingActionItems[result.ids[0]]

        if (!actionItem.taskId) {
          Sentry.captureException(
            "ActionItem's task id is not available after submit",
            {
              extra: {
                actionItem,
                taskData: fields,
              },
            }
          )
          showErrorToast('Error approving action item')
          return
        }

        navigate(
          buildTaskModalUrl({
            task: actionItem.taskId,
          })
        )
      }
    },
    [actionItemId, approveAsync, buildTaskModalUrl, navigate, openTaskModal]
  )

  return useCallback(
    () =>
      form.handleSubmit(submitHandler, (validationErrors) => {
        // If the form is invalid, ideally we don't hit this because the button should be disabled
        const firstError = values(validationErrors)[0]
        if (!firstError) {
          return
        }

        if (firstError.type === 'required') {
          showErrorToast(firstError.message)
        }
      }),
    [form, submitHandler]
  )
}
