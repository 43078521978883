import { useDebouncedValue } from '@motion/react-core/hooks'
import {
  recordAnalyticsEvent,
  useOnMountAnalyticsEvent,
} from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalStatus } from '@motion/web-common/modals'
import { useLocalStorage } from '@motion/web-common/storage'
import { type SearchSchema } from '@motion/zod/client'

import {
  useActiveCalendars,
  useInNoExternalCalendarsMode,
} from '~/areas/calendar/hooks'
import {
  useCalendarEventsSearch,
  useRecentlyOpenedEntities,
  useSearch,
} from '~/global/rpc'
import { useEffect, useMemo, useState } from 'react'

import { DEFAULT_SEARCH_TYPES } from './constants'
import { SearchContextProvider } from './search-context'
import { SearchModal, type SearchModalProps } from './search-modal'
import {
  type CalendarEventSearchSchema,
  isSearchingArchivedTasks,
  isSearchingAttachments,
  isSearchingCompletedTasks,
  isSearchingEvents,
  isSearchingNotes,
  isSearchingProjects,
  isSearchingTasks,
  isValidSearch,
  type SearchType,
} from './utils'

import { type ModalTriggerComponentProps } from '../modal-trigger'

export type ConnectedSearchModalProps = {
  initialQuery?: string
}

export const ConnectedSearchModal = () => {
  const modal = useModalStatus('search')

  const [inputQuery, setInputQuery] = useState('')
  const [searchTypes, setSearchTypes] = useLocalStorage(
    'motion:globalSearchTypes',
    DEFAULT_SEARCH_TYPES as SearchType[]
  )

  const hasSearchRevamp = useHasTreatment('revamp-sidebar-search-ui')

  const initialQuery = modal.visible ? modal?.props?.initialQuery : ''

  useEffect(() => {
    if (initialQuery) {
      setInputQuery(initialQuery)
    }
  }, [initialQuery])

  useEffect(() => {
    if (modal.visible) return

    // Reset the input query after 2 min when the modal closes
    const id = setTimeout(() => {
      setInputQuery('')
    }, 120_000)

    return () => {
      clearTimeout(id)
    }
  }, [modal.visible])

  if (!modal.visible) {
    return null
  }

  if (!hasSearchRevamp) {
    return null
  }

  return (
    <InnerConnectedSearchModal
      {...{
        inputQuery,
        setInputQuery,
        searchTypes: searchTypes ?? DEFAULT_SEARCH_TYPES,
        setSearchTypes,
      }}
      close={modal.close}
    />
  )
}

type InnerConnectedSearchModalProps = ModalTriggerComponentProps<'search'> & {
  inputQuery: string
  setInputQuery: (value: string) => void
  searchTypes: SearchType[]
  setSearchTypes: (value: SearchType[]) => void
}

const InnerConnectedSearchModal = ({
  inputQuery,
  setInputQuery,
  searchTypes,
  setSearchTypes,
  close,
}: InnerConnectedSearchModalProps) => {
  const { noExternalCalendarsMode } = useInNoExternalCalendarsMode()

  useOnMountAnalyticsEvent('SEARCH_TRIGGERED')

  const activeCalendars = useActiveCalendars()

  const searchQuery = useDebouncedValue(inputQuery, { timeoutMs: 250 })

  const {
    data: recentlyOpenedEntities,
    isInitialLoading: isLoadingRecentlyOpened,
  } = useRecentlyOpenedEntities(void 0, {
    enabled: !isValidSearch(searchQuery),
  })

  const { data: searchResults, isInitialLoading: isLoadingSearch } = useSearch(
    {
      filters:
        isSearchingTasks(searchTypes) && !isSearchingCompletedTasks(searchTypes)
          ? [
              {
                field: 'completed',
                operator: 'equals',
                value: ['exclude'],
              },
            ]
          : undefined,
      query: searchQuery,
      entities: getSearchTaskEntities(searchTypes),
      limit: 25,
    },
    {
      enabled:
        (isSearchingTasks(searchTypes) ||
          isSearchingProjects(searchTypes) ||
          isSearchingNotes(searchTypes) ||
          isSearchingAttachments(searchTypes)) &&
        isValidSearch(searchQuery),
    }
  )

  const { data: searchEvents, isInitialLoading: isLoadingCalendarEvents } =
    useCalendarEventsSearch(
      { query: searchQuery },
      { enabled: isSearchingEvents(searchTypes) && isValidSearch(searchQuery) }
    )
  const calendarSearchEvents: CalendarEventSearchSchema[] = useMemo(() => {
    return (
      searchEvents?.map((e, idx) => ({
        ...e,
        // TODO - Fix the id on the backend. It's an empty string - https://usemotion.slack.com/archives/C06CDKBAHGC/p1706926962835109
        id: `${idx}-${e.providerId}`,
        entityType: 'event',
      })) ?? []
    )
  }, [searchEvents])

  const handleSearch: SearchModalProps['onSearch'] = (
    query,
    newSearchTypes
  ) => {
    if (
      isSearchingTasks(newSearchTypes) &&
      isSearchingArchivedTasks(newSearchTypes) &&
      isSearchingTasks(searchTypes) &&
      !isSearchingArchivedTasks(searchTypes)
    ) {
      recordAnalyticsEvent('SEARCH_ARCHIVED_TRIGGERED')
    }

    setInputQuery(query)
    setSearchTypes(newSearchTypes)
  }

  const showRecentlyOpenedEntities =
    (isSearchingTasks(searchTypes) && !isSearchingArchivedTasks(searchTypes)) ||
    isSearchingProjects(searchTypes) ||
    isSearchingNotes(searchTypes) ||
    isSearchingAttachments(searchTypes)

  const filteredRecentlyOpenedEntities = useMemo(
    () =>
      recentlyOpenedEntities?.filter((item) => {
        return (
          (item.entityType === 'task' && isSearchingTasks(searchTypes)) ||
          (item.entityType === 'project' && isSearchingProjects(searchTypes)) ||
          (item.entityType === 'note' && isSearchingNotes(searchTypes))
        )
      }),
    [recentlyOpenedEntities, searchTypes]
  )

  const results =
    showRecentlyOpenedEntities && !isValidSearch(searchQuery)
      ? filteredRecentlyOpenedEntities
      : isSearchingTasks(searchTypes) ||
          isSearchingProjects(searchTypes) ||
          isSearchingNotes(searchTypes) ||
          isSearchingAttachments(searchTypes)
        ? searchResults
        : calendarSearchEvents

  const pending = inputQuery !== searchQuery

  const loading = !isValidSearch(searchQuery)
    ? isLoadingRecentlyOpened
    : isSearchingTasks(searchTypes)
      ? isLoadingSearch
      : isLoadingCalendarEvents

  useEffect(() => {
    if (
      isValidSearch(searchQuery) &&
      !loading &&
      !pending &&
      results?.length === 0
    ) {
      recordAnalyticsEvent('SEARCH_NO_RESULTS', {
        type: searchTypes.map(({ type }) => type),
      })
    }
  }, [loading, pending, results, searchQuery, searchTypes])

  return (
    <SearchContextProvider activeCalendars={activeCalendars}>
      <SearchModal
        searchQuery={inputQuery}
        searchTypes={searchTypes}
        disableSearchTypeEvents={noExternalCalendarsMode}
        onSearch={handleSearch}
        onClose={close}
        loading={loading}
        pending={pending}
        searchResults={results}
      />
    </SearchContextProvider>
  )
}

function getSearchTaskEntities(
  searchTypes: SearchType[]
): SearchSchema['entities'] {
  const entities: SearchSchema['entities'] = []

  if (isSearchingArchivedTasks(searchTypes)) {
    return ['archivedTask']
  }

  if (isSearchingTasks(searchTypes)) {
    entities.push('task')
  }

  if (isSearchingProjects(searchTypes)) {
    entities.push('project')
  }

  if (isSearchingNotes(searchTypes)) {
    entities.push('note')
  }

  if (isSearchingAttachments(searchTypes)) {
    entities.push('uploadedFile')
  }

  return entities
}
