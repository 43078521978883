import { isObject } from '@motion/utils/object'

import { type GenericMetaData } from './types'

export function isValidMetaObject(meta: unknown): meta is { type: string } {
  if (!isObject(meta) || !('type' in meta)) {
    return false
  }

  if (typeof meta.type !== 'string') {
    return false
  }

  return true
}

export function isValidGenericMeta(meta: unknown): meta is GenericMetaData {
  return isValidMetaObject(meta) && 'data' in meta
}
