import { type ProjectSchema, type WorkspaceSchema } from '@motion/zod/client'

import { ProjectBadge } from '~/global/components/badges'
import {
  WorkspacesTreeDropdown,
  type WorkspacesTreeDropdownProps,
} from '~/global/components/dropdowns'
import { ProjectLabel } from '~/global/components/labels'

import { ModalFieldButton, type ModalFieldButtonProps } from '../components'

export type WorkspaceProjectFieldProps = {
  project: ProjectSchema
  onChange: (
    workspaceId: WorkspaceSchema['id'],
    projectId: ProjectSchema['id'] | null
  ) => void
  disabled?: boolean
  suffix?: ModalFieldButtonProps['suffix']
  hideNoProject?: WorkspacesTreeDropdownProps['hideNoProject']
}
export const WorkspaceProjectField = ({
  project,
  onChange,
  disabled,
  suffix,
  hideNoProject,
}: WorkspaceProjectFieldProps) => {
  return (
    <WorkspacesTreeDropdown
      // TODO: if no project id is selected select the "none" item which is the parent, in this case a workspace
      // for tasks this is only true while we don't allow task in folders without being in a project.
      selectedId={project.id}
      workspaceId={project.workspaceId}
      hideNoProject={hideNoProject}
      onChange={(selected) => {
        onChange(selected.workspaceId, selected.projectId)
      }}
    >
      <ModalFieldButton
        size='normal'
        icon={<ProjectBadge value={project} />}
        disabled={disabled}
        suffix={suffix}
      >
        <ProjectLabel value={project} nameOnly />
      </ModalFieldButton>
    </WorkspacesTreeDropdown>
  )
}
