import { type NoteSchema } from '@motion/rpc-types'
import { classed } from '@motion/theme'

import { StatefulSidebar } from '~/global/components'
import { useSidebarState } from '~/global/hooks'

import { CommentsSection } from './comments-section'
import { type SidebarMetaData } from './note-sidebar-toggle-icon-button'
import { NoteWorkspaceProjectFolderSection } from './note-workspace-project-folder-section'

const INITIAL_SIDEBAR_WIDTH = 340
const MIN_SIDEBAR_WIDTH = 270
const MAX_SIDEBAR_WIDTH = 400

export type NoteSidebarProps = {
  noteId: NoteSchema['id']
}

export function NoteSidebar({ noteId }: NoteSidebarProps) {
  return (
    <StatefulSidebar
      sidebarKey='note'
      resizeDirection='left'
      initialWidth={INITIAL_SIDEBAR_WIDTH}
      minWidth={MIN_SIDEBAR_WIDTH}
      maxWidth={MAX_SIDEBAR_WIDTH}
      hideCloseButton
      closedWidth={0}
    >
      <SidebarContentWrapper>
        <SidebarContent noteId={noteId} />
      </SidebarContentWrapper>
    </StatefulSidebar>
  )
}

const SidebarContent = ({ noteId }: NoteSidebarProps) => {
  const [noteSidebarState, , toggleNoteSidebar] = useSidebarState('note')
  const selectedView = noteSidebarState.meta?.view as SidebarMetaData['view']
  switch (selectedView) {
    case 'comments':
      return <CommentsSection noteId={noteId} onClose={toggleNoteSidebar} />
    default:
      return (
        <NoteWorkspaceProjectFolderSection
          noteId={noteId}
          onClose={toggleNoteSidebar}
        />
      )
  }
}

const SidebarContentWrapper = classed('div', {
  base: `
    w-full h-full
    bg-semantic-neutral-surface-bg-default
    border-l border-semantic-neutral-border-default
  `,
})
