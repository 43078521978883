import { TextField } from '@motion/ui/forms'

import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { ModalFieldButton } from '~/areas/task-project/components'
import { getTextFieldRules } from '~/global/rules'
import { useController } from 'react-hook-form'

export const ControlledNameField = () => {
  const { form } = useFlowTemplateForm()
  const { control } = form

  const { field, fieldState } = useController({
    name: 'name',
    control,
    rules: {
      ...getTextFieldRules('Stage name'),
      validate: (value) => {
        if (value.trim().toLowerCase() === 'no stage') {
          return 'Stage name cannot be "No Stage"'
        }
      },
    },
  })

  return (
    <ModalFieldButton label='Stage name'>
      <TextField
        {...field}
        placeholder='Untitled'
        label='Stage name'
        labelHidden
        size='small'
        variant='minimal'
        sentiment={fieldState.error ? 'error' : 'default'}
      />
    </ModalFieldButton>
  )
}
