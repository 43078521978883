import { type ButtonProps } from '@motion/ui/base'
import { type FlowTemplateFormTask } from '@motion/ui-logic/pm/project'
import { isEqual } from '@motion/utils/core'
import { Sentry } from '@motion/web-base/sentry'

import { SharedEditTaskDefinitionButton } from '~/areas/flows/components'
import { useFlowsModalState } from '~/areas/flows/contexts'
import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { useCallback } from 'react'

import { useGetOriginalTaskDefinitionState } from '../hooks'

export type EditTaskDefinitionButtonProps = {
  icon?: React.ReactNode
  text?: string
  variant?: ButtonProps['variant']
  taskBasePath: `stages.${number}.tasks.${number}`
}

export const EditTaskDefinitionButton = ({
  icon,
  text,
  variant,
  taskBasePath,
}: EditTaskDefinitionButtonProps) => {
  const { dirtyTasksMap } = useFlowsModalState()
  const { form } = useFlowTemplateForm()
  const { watch, getValues } = form

  const workspaceId = watch('workspaceId')
  const taskId = watch(taskBasePath).id

  const handleTaskDefinitionChange = useTaskDefinitionChangeFn(taskBasePath)

  return (
    <SharedEditTaskDefinitionButton
      icon={icon}
      text={text}
      variant={variant}
      taskId={taskId}
      workspaceId={workspaceId}
      dirtyTasksMap={dirtyTasksMap}
      handleTaskDefinitionChange={handleTaskDefinitionChange}
      getFormValues={getValues}
    />
  )
}

function useTaskDefinitionChangeFn(
  taskBasePath: `stages.${number}.tasks.${number}`
) {
  const { setDirtyTasksMap } = useFlowsModalState()
  const { form } = useFlowTemplateForm()
  const { getValues, setValue } = form
  const getOriginalTaskDefinitionState = useGetOriginalTaskDefinitionState()

  const updateTaskState = useCallback(
    (
      taskPath: `stages.${number}.tasks.${number}`,
      response: FlowTemplateFormTask
    ) => {
      const { originalTask } = getOriginalTaskDefinitionState(response.id)
      if (originalTask) {
        const isDirty = !isEqual(originalTask, response)
        setDirtyTasksMap((dirtyTasks) => ({
          ...dirtyTasks,
          [originalTask.id]: isDirty,
        }))
      } else {
        Sentry.captureException(new Error('Task definition not found'), {
          extra: {
            taskPath,
            response,
          },
        })
      }

      setValue(taskPath, response, { shouldDirty: true })
      /**
       * Why doesn't the above set the minimumDuration? I have no idea.
       */
      setValue(`${taskPath}.minimumDuration`, response.minimumDuration, {
        shouldDirty: true,
      })
    },
    [getOriginalTaskDefinitionState, setDirtyTasksMap, setValue]
  )

  return useCallback(
    (response: FlowTemplateFormTask) => {
      const taskFromPath = form.getValues(taskBasePath)
      // Need to check id of the task definition to see if it has changed
      // If it has, we need to update that task definition in the form
      if (taskFromPath.id === response.id) {
        updateTaskState(taskBasePath, response)
      } else {
        const stages = getValues('stages')
        const indices = stages.reduce<
          { stageIndex: number; taskIndex: number } | undefined
        >((acc, stage, stageIndex) => {
          if (acc) return acc

          const taskIndex = stage.tasks.findIndex(
            (task) => task.id === response.id
          )
          return taskIndex !== -1 ? { stageIndex, taskIndex } : undefined
        }, undefined)

        if (indices) {
          const taskPath =
            `stages.${indices.stageIndex}.tasks.${indices.taskIndex}` as const
          updateTaskState(taskPath, response)
        }
      }
    },
    [form, taskBasePath, updateTaskState, getValues]
  )
}
