import {
  ProjectCubeCanceledSolid,
  ProjectCubeCompletedSolid,
  ProjectCubeSolid,
} from '@motion/icons'
import { type COLOR } from '@motion/shared/common'
import { ColorTag, type ColorTagProps } from '@motion/ui/base'
import { type ProjectSchema } from '@motion/zod/client'

import { useProjectUpdater } from '~/areas/project/hooks'
import {
  ColorDropdown,
  type ColorDropdownProps,
} from '~/global/components/dropdowns'
import { useProject, useWorkspaceStatusById } from '~/global/hooks'

type ProjectColorTagProps = Omit<ColorTagProps, 'icon'> & {
  projectId?: ProjectSchema['id']
  onChange?: (color: COLOR) => void
}

export function ProjectColorTag({
  color,
  projectId,
  onChange,
  ...props
}: ProjectColorTagProps) {
  const project = useProject(projectId)
  const status = useWorkspaceStatusById(project?.statusId)
  const updateProject = useProjectUpdater()

  const handleChange: ColorDropdownProps['onChange'] = async (color) => {
    if (projectId != null) {
      return await updateProject(projectId, { color })
    }

    onChange?.(color)
  }

  const content = (
    <ColorTag
      color={color}
      icon={
        status.type === 'COMPLETED' ? (
          <ProjectCubeCompletedSolid />
        ) : status.type === 'CANCELED' ? (
          <ProjectCubeCanceledSolid />
        ) : (
          <ProjectCubeSolid />
        )
      }
      {...props}
    />
  )

  if (projectId == null) return content

  return (
    <ColorDropdown selectedColor={color} onChange={handleChange}>
      {content}
    </ColorDropdown>
  )
}
