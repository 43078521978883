import { type StageSchema } from '@motion/rpc-types'
import { Button, Tooltip } from '@motion/ui/base'
import { getEtaLabel, normalizeStageDeadlineStatus } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type ETA_SOURCE } from '~/@types/analytics'
import { type BadgeSize, EtaBadge } from '~/global/components/badges'

import { BadgeTooltip } from './badge-tooltip'

import { useExtendedNoneEta } from '../../hooks'
import { StageEtaPopover } from '../popover'

type ConnectedStageEtaBadgeProps = {
  stage: StageSchema
  size?: BadgeSize
  hideTooltip?: boolean
  source: ETA_SOURCE
}

export const ConnectedStageEtaBadge = ({
  stage,
  size = 'small',
  hideTooltip = false,
  source,
}: ConnectedStageEtaBadgeProps) => {
  const resolvedDeadlineStatus = useExtendedNoneEta({
    entity: stage,
    type: 'stage',
    options: {
      normalizeToAtRisk: true,
    },
  })

  const deadlineStatus = normalizeStageDeadlineStatus(stage, {
    normalizeToAtRisk: false,
  })

  return (
    <StageEtaPopover entity={stage} source={source}>
      <Tooltip
        renderContent={() =>
          hideTooltip ? undefined : (
            <BadgeTooltip entity={stage} type='stage' source={source} />
          )
        }
      >
        <Button
          aria-label={getEtaLabel(resolvedDeadlineStatus, 'stage')}
          sentiment='neutral'
          onClick={() => {
            recordAnalyticsEvent('ETA_OPEN_POPOVER', {
              source,
              eta: resolvedDeadlineStatus,
              type: 'stage',
            })
          }}
          variant='muted'
          disabled={deadlineStatus === 'none'}
          size='xsmall'
          iconOnly
        >
          <EtaBadge value={resolvedDeadlineStatus} size={size} />
        </Button>
      </Tooltip>
    </StageEtaPopover>
  )
}
