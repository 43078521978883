import { templateStr } from '@motion/react-core/strings'
import { type TaskSchema } from '@motion/rpc-types'
import { pmComputeDeadlineDiff } from '@motion/ui-logic'

import { useCachedItem } from '~/global/cache'
import { useI18N } from '~/global/contexts'

export function useTaskDeadlineBadgeData(task: TaskSchema | null | undefined) {
  const { pluralize } = useI18N()

  const parentTask = useCachedItem(
    'tasks',
    task?.type === 'CHUNK' ? task.parentChunkTaskId : null
  )

  const showParentTaskDelay =
    task?.type === 'CHUNK' &&
    parentTask?.type === 'NORMAL' &&
    parentTask.dueDate &&
    parentTask.estimatedCompletionTime
  const showNormalTaskDelay =
    task?.dueDate &&
    task?.scheduledStart &&
    task?.priorityLevel.toLowerCase() !== 'asap'

  const deadlineDelayMinutes = showParentTaskDelay
    ? Math.floor(
        pmComputeDeadlineDiff(
          parentTask.dueDate!,
          parentTask.estimatedCompletionTime!
        )
      )
    : showNormalTaskDelay && task?.dueDate && task?.scheduledStart
      ? Math.floor(pmComputeDeadlineDiff(task.dueDate, task.scheduledStart))
      : 0

  const deadlineDelayDays = Math.floor(deadlineDelayMinutes / 1440)

  const absoluteDelayMinutes = Math.abs(deadlineDelayMinutes)
  const absoluteDelayHours = Math.floor(absoluteDelayMinutes / 60)
  const absoluteDelayDays = Math.floor(absoluteDelayMinutes / 1440)

  const isUnfit = Boolean(task?.isUnfit)
  const isTaskPastDue = deadlineDelayMinutes < 0
  const isTaskDelayed = isTaskPastDue || isUnfit

  const isDueSameDay = absoluteDelayDays === 0

  const delayCount =
    absoluteDelayDays > 0
      ? absoluteDelayDays
      : absoluteDelayHours > 0
        ? absoluteDelayHours
        : absoluteDelayMinutes

  const unit =
    absoluteDelayDays > 0 ? 'day' : absoluteDelayHours > 0 ? 'hour' : 'minute'

  const dayNumberTooltipContent = templateStr(
    'Scheduled {{duration}} {{beforeOrAfter}} deadline',
    {
      duration: pluralize(delayCount, `1 ${unit}`, `{{count}} ${unit}s`),
      beforeOrAfter: isTaskPastDue ? 'after' : 'before',
    }
  )

  return {
    showParentTaskDelay,
    showNormalTaskDelay,
    deadlineDelayMinutes,
    absoluteDelayMinutes,
    absoluteDelayDays,
    absoluteDelayHours,
    deadlineDelayDays,
    isUnfit,
    isTaskPastDue,
    isTaskDelayed,
    dayNumberTooltipContent,
    isDueSameDay,
  }
}
