import { Toggle, Tooltip } from '@motion/ui/base'
import { useWillExceedAccountFeatureCap } from '@motion/web-billing'

import { useUpdateNotetakerFields } from '~/areas/notetaker'
import { type ChangeEvent } from 'react'
import { useController } from 'react-hook-form'

import { useEventModalState } from '../../../contexts'
import { useEventForm } from '../../../hooks'

export function ControlledNotetakerToggleField() {
  const { meetingInsights, recurringMeetingInsights, initialEvent } =
    useEventModalState()
  const { form } = useEventForm()
  const { control, formState } = form

  const isDirty = formState.isDirty && initialEvent != null
  const isBotInCall = meetingInsights?.meetingBotStatus === 'IN_CALL'

  const updateNotetakerFields = useUpdateNotetakerFields()

  const { field } = useController({
    name: 'botEnabled',
    control,
  })

  const isNotetakerLimitReached = useWillExceedAccountFeatureCap(
    'aiNotetakerLimit',
    1
  )

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    // If meeting insights already exist, update the meeting insights directly.
    if (meetingInsights != null || recurringMeetingInsights != null) {
      await updateNotetakerFields({
        botEnabled: e.target.checked,
        meetingInsightsId: meetingInsights?.id,
        recurringMeetingInsightsId: recurringMeetingInsights?.id,
      })
    }

    field.onChange(e.target.checked)
  }

  return (
    <Tooltip
      content={getTooltipContent({
        isNotetakerLimitReached,
        isDirty,
        isBotInCall,
      })}
      placement='top'
    >
      <Toggle
        size='small'
        checked={field.value}
        onChange={handleChange}
        disabled={isNotetakerLimitReached || isDirty || isBotInCall}
        data-testid='event-notetaker-toggle'
      >
        <span className='text-xs text-semantic-neutral-text-default'>
          Enable AI Notetaker
        </span>
      </Toggle>
    </Tooltip>
  )
}

type TooltipContentProps = {
  isNotetakerLimitReached: boolean
  isDirty: boolean
  isBotInCall: boolean
}

const getTooltipContent = ({
  isNotetakerLimitReached,
  isDirty,
  isBotInCall,
}: TooltipContentProps): string => {
  if (isNotetakerLimitReached) {
    return 'Upgrade to get AI Notetaker in all of your meetings'
  }

  if (isDirty) {
    return 'Save event to update AI Notetaker settings'
  }

  if (isBotInCall) {
    return 'AI Notetaker is currently in call.'
  }

  return ''
}
