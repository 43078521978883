import { Toggle, Tooltip } from '@motion/ui/base'
import { isNotetakerCompleted } from '@motion/ui-logic'

import { useUpdateNotetakerFields } from '~/areas/notetaker'
import { type ChangeEvent } from 'react'
import { useController } from 'react-hook-form'

import { useEventModalState } from '../../../contexts'
import { useEventForm } from '../../../hooks'

export function ControlledEventNotetakerEmailRecapField() {
  const { meetingInsights, recurringMeetingInsights, initialEvent } =
    useEventModalState()
  const { form } = useEventForm()
  const isDirty = form.formState.isDirty && initialEvent != null

  const botEnabled = form.watch('botEnabled')

  const updateNotetakerFields = useUpdateNotetakerFields()

  const { field } = useController({
    name: 'sendRecapToAllAttendees',
    control: form.control,
  })

  if (isNotetakerCompleted(meetingInsights) || !botEnabled) {
    return null
  }

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (meetingInsights != null || recurringMeetingInsights != null) {
      await updateNotetakerFields({
        sendRecapToAllAttendees: e.target.checked,
        meetingInsightsId: meetingInsights?.id,
        recurringMeetingInsightsId: recurringMeetingInsights?.id,
      })
    }

    field.onChange(e.target.checked)
  }
  return (
    <Tooltip
      placement='top-start'
      content={
        isDirty
          ? 'Save event to update notetaker settings'
          : 'When toggled off, the meeting recap email will only be sent to meeting attendees with an active Motion account'
      }
    >
      <Toggle
        disabled={isDirty}
        size='small'
        checked={field.value}
        onChange={handleChange}
      >
        <p className='text-semantic-neutral-text-default text-xs'>
          Send meeting recap email to{' '}
          <strong className='font-medium'>all</strong> meeting attendees
        </p>
      </Toggle>
    </Tooltip>
  )
}
