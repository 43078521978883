import { classed } from '@motion/theme'

import { useI18N } from '~/global/contexts'
import { useCallback, useEffect, useRef, useState } from 'react'

import { CommentContainer } from './comment-container'
import { ConnectedCommentInputBox } from './connected-comment-input-box'

import { useNoteCommentsContext } from '../comments'
import type { ThreadWithComments } from '../comments/threads.types'

type ThreadContainerProps = {
  thread: ThreadWithComments
}

export const ThreadContainer = (props: ThreadContainerProps) => {
  const { thread } = props
  const { pluralize } = useI18N()
  const containerRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<number | 'auto'>('auto')

  const {
    activeThreadId,
    setActiveThreadId,
    createComment,
    deleteComment,
    editComment,
    resolveThread,
  } = useNoteCommentsContext()

  const handleAddComment = useCallback(
    async (bodyHtml: string, mentions: Array<string>) => {
      await createComment({ bodyHtml, mentions, threadId: thread.id })
    },
    [createComment, thread.id]
  )

  const isActive = thread.id === activeThreadId

  useEffect(() => {
    requestAnimationFrame(() => {
      if (!containerRef.current || !isActive) return
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    })
  }, [isActive])

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        const observedHeight = entries[0].target.getBoundingClientRect().height
        setHeight(observedHeight)
      })

      resizeObserver.observe(containerRef.current)

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [])

  const mainComment = thread.comments[0]
  const otherComments = thread.comments.slice(1)

  if (!mainComment) return null

  return (
    <div
      style={{
        height,
        zIndex: isActive ? 10 : 1,
        transitionProperty: 'height',
        transitionTimingFunction: 'ease-out',
        transitionDuration: '150ms',
      }}
      className='relative overflow-hidden mb-1.5 cursor-pointer'
    >
      <ThreadExpandableContainer
        ref={containerRef}
        onClick={() => {
          setActiveThreadId(thread.id)
        }}
        active={isActive}
      >
        <CommentContainer
          key={mainComment.id}
          comment={mainComment}
          onDelete={deleteComment}
          onEdit={editComment}
          isResolved={thread.isResolved}
          onResolve={() => resolveThread(thread.id)}
        />
        {!isActive && otherComments.length > 0 && (
          <div className='text-button-primary-muted-text-default'>
            {`${otherComments.length} ${pluralize(otherComments.length, 'reply', 'replies')}`}
          </div>
        )}
        <div
          className='flex flex-col gap-2 relative'
          style={{ opacity: isActive ? 1 : 0, paddingTop: isActive ? 8 : 0 }}
        >
          {isActive &&
            otherComments.map((comment) => (
              <CommentContainer
                key={comment.id}
                comment={comment}
                onDelete={deleteComment}
                onEdit={editComment}
              />
            ))}
          {isActive && <ConnectedCommentInputBox onSubmit={handleAddComment} />}
        </div>
      </ThreadExpandableContainer>
    </div>
  )
}

const ThreadExpandableContainer = classed('div', {
  base: `
    relative p-2 flex flex-col rounded-lg

    bg-semantic-neutral-surface-bg-default
    border border-transparent

    hover:bg-semantic-warning-border-subtle
  `,
  variants: {
    active: {
      true: 'bg-semantic-warning-border-subtle border-semantic-warning-border-hover',
      false: 'bg-semantic-neutral-bg-disabled',
    },
  },
})
