import { classed } from '@motion/theme'

import {
  SharedFormShell,
  SharedGridShell,
} from '~/areas/task-project/components/styled'

export const FormShell = classed('form', SharedFormShell, {
  base: `
    h-[var(--modal-height)]
    w-[var(--min-modal-width)]
  `,
  variants: {
    columns: {
      2: ``,
      3: ``,
    },
  },
  defaultVariants: {
    columns: 2,
  },
  dataAttributes: ['columns'],
})

export const GridShell = classed('div', SharedGridShell, {
  base: `
    grid-rows-[1fr_min-content]

    [[data-columns="2"]_&]:[grid-template-areas:'aside_main''footer_footer']
    [[data-columns="2"]_&]:grid-cols-[240px_1fr]

    [[data-columns="3"]_&]:[grid-template-areas:'aside_main_changes''footer_footer_footer']
    [[data-columns="3"]_&]:grid-cols-[200px_1fr_240px]
    modal-sm:[[data-columns="3"]_&]:grid-cols-[220px_1fr_240px]
    modal-md:[[data-columns="3"]_&]:grid-cols-[240px_1fr_300px]
  `,
})

export const ShellVars = classed('div', {
  base: `
    contents
    setvar-[modal-height=600px]
    modal-md:setvar-[modal-height=688px]
    border border-semantic-neutral-border-default
  `,
  variants: {
    columns: {
      2: `
      setvar-[min-modal-width=700px]
      modal-md:setvar-[min-modal-width=894px]
    `,
      3: `
      setvar-[min-modal-width=1000px]
      modal-md:setvar-[min-modal-width=1194px]
    `,
    },
  },
  defaultVariants: {
    columns: 2,
  },
  dataAttributes: ['columns'],
})

export const LeftPanel = classed('div', {
  base: `
    [grid-area:aside]
    p-4 h-full bg-semantic-neutral-surface-raised-bg-subtlest flex flex-col gap-3 rounded-l-lg
  `,
})

export const MainContent = classed('div', {
  base: `
    [grid-area:main]
    flex flex-col
    relative
    overflow-hidden
  `,
})

export const MainContentScrollable = classed('div', {
  base: `
    gap-4 overflow-y-auto overflow-x-hidden
    flex-1
    px-6 pt-6

    after:absolute
    after:h-[20px]
    after:bg-gradient-to-b after:from-transparent after:to-modal-bg
    after:right-0 after:left-0 after:bottom-0
  `,
})

export const MainContentFooter = classed('div', {
  base: `
    [grid-area:footer]
    flex flex-row items-center justify-between
    px-4
    h-14

    border-t border-modal-border

   `,
})

export const RightPanel = classed('div', {
  base: `
    [grid-area:changes]
    flex relative overflow-hidden
    border-l border-semantic-neutral-border-subtle rounded-r-lg
  `,
})

export const RightPanelScrollable = classed('div', {
  base: `
    flex-1 overflow-y-auto overflow-x-hidden

    after:absolute
    after:h-[20px]
    after:bg-gradient-to-b after:from-transparent after:to-modal-bg
    after:right-0 after:left-0 after:bottom-0
  `,
})

export const AIGradientOverlay = classed('div', {
  base: `
    fixed top-px right-px z-20 w-[239px] modal-md:w-[299px] h-full rounded-r-[7px]
    pointer-events-none opacity-[.07]
    bg-[linear-gradient(190deg,var(--tw-gradient-stops))]
    from-semantic-gradient-pink
    via-semantic-gradient-purple via-[60px]
    to-[150px]
  `,
})

export const StepHeader = classed('h2', {
  base: `
    font-semibold text-[15px] leading-5 text-semantic-neutral-text-default
  `,
})
