import { UserSolid } from '@motion/icons'
import { type COLOR } from '@motion/shared/common'
import { type VariableType } from '@motion/shared/flows'
import { Button } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { getColorHueValue, PaletteProvider } from '@motion/ui/palette'
import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import { ColorDropdown } from '~/global/components/dropdowns'
import { checkTextRuleErrors, getTextFieldRules } from '~/global/rules'
import { showErrorToast } from '~/global/toasts'
import { useCallback, useState } from 'react'
import { type ControllerRenderProps } from 'react-hook-form'

import { useFlowTemplateForm } from '../shared-form'
import { findFirstDuplicateName } from '../utils'

export type EditVariablePopoverProps<T extends VariableType> = {
  type: T
  nameField: ControllerRenderProps<
    FlowTemplateFormFields,
    T extends 'text' ? `textVariables.${number}.name` : `roles.${number}.name`
  >
  colorField: T extends 'text'
    ? undefined
    : ControllerRenderProps<FlowTemplateFormFields, `roles.${number}.color`>
  index: number
  close: () => void
}

export function EditVariablePopover<T extends VariableType>({
  type,
  nameField,
  colorField,
  index,
  close,
}: EditVariablePopoverProps<T>) {
  const {
    form: { watch },
  } = useFlowTemplateForm()

  const currentName = nameField.value

  const isRole = type === 'person' && colorField != null

  const formWorkspaceVariables = isRole
    ? watch('roles')
    : watch('textVariables')

  const [tempVariableName, setTempVariableName] = useState<string>(currentName)
  const [tempVariableColor, setTempVariableColor] = useState<COLOR>(
    colorField?.value ?? 'blue'
  )

  const isNameFieldUnchanged = tempVariableName === currentName
  const isColorFieldUnchanged =
    !colorField || colorField.value === tempVariableColor

  const handleSave = useCallback(async () => {
    const errorMessage = checkTextRuleErrors(
      tempVariableName,
      getTextFieldRules('Role name')
    )

    if (errorMessage) {
      showErrorToast(errorMessage)
      return
    }

    const duplicateVar = findFirstDuplicateName([
      ...formWorkspaceVariables,
      { name: tempVariableName },
    ])

    if (duplicateVar && !isNameFieldUnchanged) {
      showErrorToast(
        `More than one ${type === 'person' ? 'role' : 'variable'} named "${duplicateVar.name}"`
      )
      return
    }

    nameField.onChange(tempVariableName)
    colorField?.onChange(tempVariableColor)

    close()
  }, [
    formWorkspaceVariables,
    tempVariableName,
    isNameFieldUnchanged,
    nameField,
    colorField,
    tempVariableColor,
    close,
    type,
  ])

  const placeholder = isRole ? `Role ${index + 1}` : `Variable ${index + 1}`
  const disabled =
    !tempVariableName || (isNameFieldUnchanged && isColorFieldUnchanged)

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && !disabled) {
        e.preventDefault()
        handleSave()
      }
    },
    [disabled, handleSave]
  )

  return (
    <div className='flex flex-col w-[250px]'>
      {isRole ? (
        <div className='flex flex-row gap-2 p-3'>
          <ColorDropdown
            selectedColor={tempVariableColor!}
            onChange={(colorOption) => setTempVariableColor(colorOption)}
          >
            <Button
              variant='outlined'
              sentiment='neutral'
              size='small'
              iconOnly
            >
              <PaletteProvider colorHue={getColorHueValue(tempVariableColor!)}>
                <UserSolid className='!text-palette-highlight-default' />
              </PaletteProvider>
            </Button>
          </ColorDropdown>

          <TextField
            fullWidth
            size='small'
            placeholder={placeholder}
            label={placeholder}
            labelHidden
            value={tempVariableName}
            onChange={(newValue) => setTempVariableName(newValue)}
            onKeyDown={onKeyDown}
          />
        </div>
      ) : (
        <div className='[&_span]:rounded-b-none'>
          <TextField
            placeholder={placeholder}
            label={placeholder}
            labelHidden
            fullWidth
            value={tempVariableName}
            onChange={(newValue) => setTempVariableName(newValue)}
            onKeyDown={onKeyDown}
          />
        </div>
      )}
      <div className='flex p-2 border-t border-dropdown-border justify-end gap-1 bg-semantic-neutral-surface-overlay-bg-subtlest'>
        <Button
          sentiment='neutral'
          variant='muted'
          size='small'
          onClick={() => {
            close()
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={disabled}
          sentiment='primary'
          size='small'
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  )
}
