import { type TypeaheadMenuOption } from '@motion/notes'
import { LoadingSpinner } from '@motion/ui/base'

import { ConnectedProjectIcon } from '~/global/components'
import { NoteBadge, StatusBadge, UserBadge } from '~/global/components/badges'

import { isValidMeta } from './utils'

export type WebMentionOptionProps = {
  option: TypeaheadMenuOption
}

export function WebMentionOption({ option }: WebMentionOptionProps) {
  if (!isValidMeta(option.meta)) {
    return <span>{option.title}</span>
  }

  let icon = null

  const { type } = option.meta

  if (type === 'task') {
    const { task } = option.meta

    icon = <StatusBadge value={task.status} size='normal' />
  }

  if (type === 'project') {
    icon = (
      <ConnectedProjectIcon
        className='size-4'
        projectId={option.meta.project.id}
      />
    )
  }

  if (type === 'user') {
    const { user } = option.meta

    // TODO: Fix TS issue below once we have a dedicated endpoint for mention search
    icon = <UserBadge value={user.user as any} size='normal' />
  }

  if (type === 'note') {
    const { note } = option.meta

    icon = <NoteBadge value={note} size='normal' />
  }

  if (type === 'placeholder' && option.meta.showLoader) {
    icon = <LoadingSpinner size={16} />
  }

  return (
    <div className='flex items-center gap-1'>
      {icon}
      <span className='truncate'>{option.title}</span>
    </div>
  )
}
