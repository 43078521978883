import { ModalFieldButton } from '~/areas/task-project/components'

import { ExpectedDurationUnitSelector } from './components'
import { ExpectedDurationValueField } from './components/expected-duration-value-field'

export const ControlledExpectedDurationField = () => {
  return (
    <ModalFieldButton label='Expected duration'>
      <ExpectedDurationValueField />
      <ExpectedDurationUnitSelector />
    </ModalFieldButton>
  )
}
