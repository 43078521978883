import {
  ArrowDownSolid,
  ArrowUpSolid,
  SwitchVerticalSolid,
} from '@motion/icons'
import {
  Button,
  ButtonGroup,
  IconButton,
  PopoverTrigger,
  SearchableList,
} from '@motion/ui/base'
import { values } from '@motion/utils/object'

import { SORT_NONE } from '~/areas/project-management/pages/pm-v3/components'
import { useCallback } from 'react'

import { useNotetakerViewState } from '../../state'
import { MEETING_INSIGHTS_FIELDS, type SortField } from '../types'

export function ConnectedSortButton() {
  const [viewState, setViewState] = useNotetakerViewState()

  const fieldValue = viewState.sortBy?.field ?? ''
  const direction = viewState.sortBy?.direction

  const onChange: SortButtonGroupProps['onChange'] = useCallback(
    (value) => {
      setViewState((prev) => ({
        ...prev,
        sortBy:
          value === SORT_NONE.id
            ? null
            : {
                field: value,
                direction: 'asc',
              },
      }))
    },
    [setViewState]
  )

  const onChangeDirection = useCallback(
    (dir: 'asc' | 'desc') => {
      if (viewState.sortBy == null) return

      setViewState((prev) => ({
        ...prev,
        sortBy:
          prev.sortBy != null
            ? {
                field: prev.sortBy.field,
                direction: dir,
              }
            : null,
      }))
    },
    [setViewState, viewState.sortBy]
  )

  return (
    <SortButtonGroup
      value={fieldValue}
      direction={direction}
      onChange={onChange}
      onChangeDirection={onChangeDirection}
    />
  )
}

const MeetingInsightSortItems = [SORT_NONE, ...values(MEETING_INSIGHTS_FIELDS)]
  .filter((field) => 'sortFn' in field)
  .map((field) => ({
    id: field.id,
    name: field.name,
  }))

type SortButtonGroupProps = {
  value: SortField | ''
  onChange(value: SortField): void
  onChangeDirection(dir: 'asc' | 'desc'): void
  direction?: 'asc' | 'desc'
}

function SortButtonGroup({
  value,
  onChange,
  direction,
  onChangeDirection,
}: SortButtonGroupProps) {
  const matched = MeetingInsightSortItems.find((x) => x.id === value)
  const isActive = value !== '' && matched != null

  return (
    <ButtonGroup segmented size='small'>
      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <SearchableList
            items={MeetingInsightSortItems}
            onSelect={(item) => {
              onChange(item.id as SortField)
              close()
            }}
            computeKey={(item) => item.id}
            computeSelected={(item) => item.id === value}
            computeSearchValue={(item) => item.name}
            renderItem={(item) => <div className='max-w-xs'>{item.name}</div>}
            inputProps={{ placeholder: 'Search...' }}
          />
        )}
      >
        <Button
          variant='outlined'
          sentiment={isActive ? 'primary' : 'neutral'}
          size='small'
        >
          <SwitchVerticalSolid />
          <div className='font-medium whitespace-nowrap'>
            {isActive
              ? `Meeting Notes Sort: ${matched.name}`
              : `Sort Meeting Notes`}
          </div>
        </Button>
      </PopoverTrigger>
      {isActive && direction != null && (
        <IconButton
          icon={direction === 'asc' ? ArrowUpSolid : ArrowDownSolid}
          variant='outlined'
          sentiment='primary'
          size='small'
          onClick={() =>
            onChangeDirection(direction === 'asc' ? 'desc' : 'asc')
          }
        />
      )}
    </ButtonGroup>
  )
}
