import { useHasTreatment } from '@motion/web-common/flags'

import { type UseOnboardingVideoPayload } from './types'
import { useExperiment } from './useHasExperiment'

const DEFAULT_ONBOARDING_VIDEO_PAYLOAD = {
  videoId: 'no-1For9tSU',
  text: 'Watch this 1min video on how you can save hours each week using Motion',
}

const DEFAULT_NOTES_IN_WEBAPP_VIDEO_PAYLOAD = {
  videoId: 'Dol5ClIjelk',
  text: 'Watch this 1min video on how you can save hours each week using Motion',
}

export const useOnboardingVideo = (): UseOnboardingVideoPayload => {
  const { payload } = useExperiment('onboarding-video')
  const hasNotesInWebapp = useHasTreatment('notes-in-webapp')

  if (hasNotesInWebapp) {
    return DEFAULT_NOTES_IN_WEBAPP_VIDEO_PAYLOAD
  }

  return {
    ...DEFAULT_ONBOARDING_VIDEO_PAYLOAD,
    ...payload,
  }
}

export const useOnboardingIframeUrl = (page: string): string => {
  const payload = useExperiment('onboarding-iframes').payload as
    | { [key: string]: { url: string } }
    | undefined
  return payload?.[page]?.url ?? ''
}
