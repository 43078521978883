import { classed } from '@motion/theme'
import {
  type ProjectDefinitionSchema,
  type StageDefinitionSchema,
  type VariableDefinitionSchema,
} from '@motion/zod/client'

import { StageLabel } from '~/global/components/labels'
import {
  type ProjectDefinitionWithProjectInfo,
  useStageDefinitionsByReferences,
} from '~/global/hooks'

import { TaskDefinitionListDropdown } from './task-definition'

type TemplateStagesProps = {
  flowTemplate: ProjectDefinitionWithProjectInfo | ProjectDefinitionSchema
}

export function TemplateStages({ flowTemplate }: TemplateStagesProps) {
  const { stageDefinitionReferences } = flowTemplate
  const stages = useStageDefinitionsByReferences(
    stageDefinitionReferences ?? []
  )

  if (!stages.length) {
    return <FlowStagesContainer />
  }

  return (
    <FlowStagesContainer>
      {stages.map((stage) => (
        <TemplateStageRow
          key={stage.id}
          stage={stage}
          allVariables={stage.variables}
        />
      ))}
    </FlowStagesContainer>
  )
}

type TemplateStageRowProps = {
  stage: StageDefinitionSchema
  allVariables: VariableDefinitionSchema[]
}

const TemplateStageRow = ({ stage, allVariables }: TemplateStageRowProps) => {
  return (
    <StageRow key={stage.id}>
      <div className='truncate'>
        <StageLabel size='small' value={stage} />
      </div>
      <TaskDefinitionListDropdown stage={stage} variables={allVariables} />
    </StageRow>
  )
}

const StageRow = classed('div', {
  base: `
    flex items-center justify-between gap-2 w-full
  `,
})

const FlowStagesContainer = classed('div', {
  base: `
    rounded-b border border-t-0 border-semantic-neutral-border-light
    bg-semantic-neutral-surface-raised-bg-subtlest flex flex-col items-start self-stretch
    h-[100px] py-2 px-3 gap-1 overflow-hidden
  `,
})
