import { createUseMutation } from '@motion/rpc'
import { API } from '@motion/rpc-definitions'

export const useCreateComment = createUseMutation(API.commentsV2.createComment)

export const useEditComment = createUseMutation(API.commentsV2.editComment)

export const useDeleteComment = createUseMutation(API.commentsV2.deleteComment)

export const useAddReaction = createUseMutation(API.commentsV2.addReaction)

export const useRemoveReaction = createUseMutation(
  API.commentsV2.removeReaction
)
