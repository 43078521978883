import { VideoCameraSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { classed } from '@motion/theme'
import { getConferenceDataForType } from '@motion/ui-logic'

import { useEventForm } from '../hooks'

export function ConnectedJoinConferenceButton() {
  const { form } = useEventForm()
  const {
    watch,
    formState: { isDirty },
  } = form

  const conferenceLink = watch('conferenceLink')
  const conferenceType = watch('conferenceType')

  const confData = getConferenceDataForType(conferenceType)

  if (!conferenceLink || !confData.category || isDirty) return null

  return (
    <ConferenceButtonLink
      href={conferenceLink}
      target='_blank'
      rel='noreferrer'
      conferenceCategory={confData.category}
    >
      <VideoCameraSolid />
      {templateStr('Join {{conference}}', {
        conference: confData.title,
      })}
    </ConferenceButtonLink>
  )
}

const ConferenceButtonLink = classed('a', {
  base: `
   select-none px-2 py-1 text-xs rounded
   flex gap-1.5
   font-semibold
   focus-visible:outline-2
   focus-visible:outline
   outline-offset-1
   text-semantic-neutral-text-onDark

   [&>[data-icon]]:size-4
  `,
  variants: {
    conferenceCategory: {
      meet: `bg-semantic-meet-bg-default hover:bg-semantic-meet-bg-hover`,
      zoom: `bg-semantic-zoom-bg-default hover:bg-semantic-zoom-bg-hover`,
      teams: `bg-semantic-teams-bg-default hover:bg-semantic-teams-bg-hover`,
    },
  },
  defaultVariants: {
    conferenceCategory: 'zoom',
  },
})
