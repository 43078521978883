import { FilledChevronRightSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, IconButton } from '@motion/ui/base'

import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export type SectionHeaderProps = {
  title: ReactNode
  isExpanded: boolean
  onToggleExpand: (newState: boolean) => void
  renderButton?: () => ReactNode
}

export const SectionHeader = ({
  title,
  isExpanded,
  onToggleExpand,
  renderButton,
}: SectionHeaderProps) => {
  return (
    <div className='text-sidebar-title'>
      <div className='flex flex-row items-center justify-between gap-1 pr-2'>
        <Button
          alignment='left'
          sentiment='neutral'
          variant='muted'
          size='small'
          fullWidth
          onClick={() => {
            onToggleExpand(!isExpanded)
          }}
        >
          {title}

          <FilledChevronRightSolid
            className={twMerge(
              'transition-transform',
              isExpanded && 'rotate-90'
            )}
          />
        </Button>

        {renderButton?.()}
      </div>
    </div>
  )
}

export const SectionHeaderActionButton = classed(
  IconButton,
  `[&_[data-icon]]:size-3`
)
