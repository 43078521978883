import { readLocalStorageValue } from '@motion/web-common/storage'

import { useSyncExternalStore } from 'react'
import {
  type TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux'

import { selectTheme } from './settingsSlice'
import type { AppDispatch, RootState } from './types'

import { type MotionTheme } from '../types/settingsTypes'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export { AppDispatch }

export const useTheme = (): Exclude<MotionTheme, 'systemSettings'> => {
  const currentUserTheme =
    useAppSelector(selectTheme) ??
    readLocalStorageValue('theme', undefined, 'plaintext') ??
    'systemSettings'

  return useSyncExternalStore(subscribe, () => {
    if (currentUserTheme === 'systemSettings') {
      return window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light'
    }

    return currentUserTheme as Exclude<MotionTheme, 'systemSettings'>
  })
}

function subscribe(callback: (e: MediaQueryListEvent) => void) {
  window
    .matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', callback)

  return () => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .removeEventListener('change', callback)
  }
}
