import { AutoscheduleStarSolid } from '@motion/icons'
import { Button, useShortcut } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { values } from '@motion/utils/object'
import { makeLog } from '@motion/web-base/logging'

import { FieldWrapper } from '~/areas/flows/flows-ai-modal/components'
import { useRewriteText } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { type FormEventHandler, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { RewriteTextWithAiForm } from './rewrite-text-with-ai-form'
import { type RewriteTextWithAiFormFields } from './types'

import { useNoteDialogContext } from '../../hooks'
import {
  BaseDialog,
  BaseDialogFooter,
  ProgressHeader,
  ResultEditor,
} from '../components'
import { type NoteDialogProps } from '../types'

const log = makeLog('rewrite-text-with-ai-dialog')
const SUBMIT_SHORTCUT = 'mod+enter' as const

export function RewriteTextWithAiDialog({ close }: NoteDialogProps) {
  const { noteId, selectedText } = useNoteDialogContext()
  const [isLoading, setIsLoading] = useState(false)
  const [rewrittenText, setRewrittenText] = useState<string>('')

  const showForm = !isLoading && !rewrittenText

  return (
    <RewriteTextWithAiForm noteId={noteId} selectedText={selectedText ?? ''}>
      <BaseDialog
        headerContent={
          showForm && (
            <>
              <AutoscheduleStarSolid />
              <span>Rewrite or edit text</span>
            </>
          )
        }
      >
        {showForm && (
          <InnerModal
            close={close}
            onLoadingChange={setIsLoading}
            setRewrittenText={setRewrittenText}
          />
        )}

        {isLoading && <ProgressHeader text='Rewriting...' />}

        {!isLoading && !!rewrittenText && (
          <div>
            <div className='mb-5'>
              <ProgressHeader loaded text='Re-writing complete' close={close} />
            </div>

            <ResultEditor markdownContent={rewrittenText} />

            <div className='mt-4 space-x-2'>
              <Button
                sentiment='neutral'
                variant='outlined'
                onClick={() => {
                  setRewrittenText('')
                }}
              >
                Edit prompt and try again
              </Button>
            </div>
          </div>
        )}
      </BaseDialog>
    </RewriteTextWithAiForm>
  )
}

type InnerModalProps = {
  close: () => void
  onLoadingChange: (loading: boolean) => void
  setRewrittenText: (newText: string) => void
}

function InnerModal({
  close,
  onLoadingChange,
  setRewrittenText,
}: InnerModalProps) {
  const formRef = useRef<HTMLFormElement>(null)
  const form = useFormContext<RewriteTextWithAiFormFields>()
  const { mutateAsync: rewriteText } = useRewriteText()

  useShortcut(SUBMIT_SHORTCUT, () => {
    formRef.current?.requestSubmit()
  })

  const { errors } = form.formState

  if (errors.root?.submitError != null) {
    log.error('Error submitting text from selection', { errors })
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    form.handleSubmit(
      async (data) => {
        try {
          onLoadingChange(true)

          const { text } = await rewriteText(data)

          if (!text) {
            return void form.setError('root.submitError', {
              message: 'No text was generated.',
            })
          }

          setRewrittenText(text)
        } catch (e) {
          if (e instanceof Error) {
            form.setError('root.submitError', {
              message: e.message,
            })
          }
        } finally {
          onLoadingChange(false)
        }
      },
      (validationErrors) => {
        const firstError = values(validationErrors)[0]

        if (!firstError) {
          return
        }

        if (firstError.type === 'required') {
          showErrorToast('Please fill out the required fields.')
        }
      }
    )(e)
  }

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <div className='mb-3'>
        <p className='font-semibold mb-1'>
          How would you like to rewrite or edit your text?
        </p>

        {/* <p> */}
        {/*  Type &quot;@&quot; to reference any documents, tasks, projects or */}
        {/*  people */}
        {/* </p> */}
      </div>

      <Controller
        name='userInstructions'
        control={form.control}
        render={({ field }) => (
          <FieldWrapper field='message'>
            <TextField
              value={field.value}
              onChange={field.onChange}
              multiline
              noResize
              autoFocus
              autoSize={{ minRows: 5 }}
              label='Instructions'
              labelHidden
              placeholder='Example: Add sub bullets to selection to add more details'
              sentiment={errors.userInstructions ? 'error' : 'default'}
            />
          </FieldWrapper>
        )}
      />

      <BaseDialogFooter>
        <Button
          sentiment='neutral'
          variant='muted'
          onClick={close}
          shortcut='esc'
        >
          Cancel
        </Button>

        <Button type='submit' sentiment='primary' shortcut={SUBMIT_SHORTCUT}>
          Rewrite selection
        </Button>
      </BaseDialogFooter>
    </form>
  )
}
