import { templateStr } from '@motion/react-core/strings'
import { Button } from '@motion/ui/base'
import { formattedCurrencyAmount } from '@motion/ui-logic'

import { useUtilizedSubscription } from '~/global/rpc/subscriptions'

import { discountPriceString } from '../../Account/utils'
import { default as Confetti } from '../components/confetti.svg?url'
import { IconWrapper } from '../components/icon-wrapper'
import { CheckIcon } from '../components/icons'
import { type CancelScreenProps } from '../components/types'

export const DiscountSuccess = ({ onCloseModal }: CancelScreenProps) => {
  const subscription = useUtilizedSubscription()
  if (!subscription) {
    return null
  }
  const discount = subscription.discount?.coupon?.percent_off || 0
  const isAnnual = subscription.plan.interval === 'year'
  const monthlyPrice =
    (subscription.plan.amount * subscription.quantity) /
    100 /
    (isAnnual ? 12 : 1)

  return (
    <div className='w-full flex flex-col items-center text-center text-semantic-neutral-text-default'>
      <img src={Confetti} />
      <div className='flex flex-col gap-3'>
        <div className='flex flex-col gap-3 items-center'>
          <IconWrapper icon={CheckIcon} />
          <h1 className='font-bold text-lg'>{`${discount.toFixed()}% Discount applied!`}</h1>
          <p>Your new payment is</p>
          <p>
            {templateStr('{{price}} {{discountPrice}}{{duration}}.', {
              price: (
                <span className='line-through'>
                  {formattedCurrencyAmount(monthlyPrice)}
                </span>
              ),
              discountPrice: (
                <span className='text-semantic-success-text-default font-bold'>
                  {templateStr(' {{discount_string}}/month', {
                    discount_string: discountPriceString(
                      monthlyPrice,
                      discount
                    ),
                  })}
                </span>
              ),
              duration: isAnnual ? (
                <span className='text-semantic-neutral-text-subtle'>
                  {' '}
                  (billed annually)
                </span>
              ) : null,
            })}
          </p>
        </div>
        <div>
          <Button
            onClick={onCloseModal}
            variant='outlined'
            sentiment='neutral'
            size='small'
          >
            Ok got it
          </Button>
        </div>
      </div>
    </div>
  )
}
