import { ChevronDownOutline } from '@motion/icons'
import { getTextSizeFromEditorState } from '@motion/notes'
import { classed } from '@motion/theme'
import { ActionList, Button, PopoverTrigger, Text } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import {
  type AgentVariableSchema,
  AgentWorkflowStepType,
} from '@motion/zod/client'

import {
  type Reference,
  ReferenceEditor,
  type ReferenceEditorProps,
} from '~/areas/notes/components'
import { useMemo } from 'react'

import { AgentVariableLabel, AgentVariableTag } from './agent-variable-tag'
import { agentStepTypeToData } from './shared'

export type WorkflowStepCardProps = {
  placeholderTitle: string
  placeholderContent: string
  title: string
  content: string
  type: AgentWorkflowStepType
  variables: AgentVariableSchema[]
  onQueryVariable: Reference['query']
  onChangeTitle: (value: string) => void
  onChangeContent: (value: string) => void
  onChangeType: (value: AgentWorkflowStepType) => void
}

export function WorkflowStepCard({
  placeholderTitle,
  placeholderContent,
  title,
  content,
  type,
  variables,
  onQueryVariable,
  onChangeTitle,
  onChangeContent,
  onChangeType,
}: WorkflowStepCardProps) {
  const editorOptions = useMemo<ReferenceEditorProps['options']>(() => {
    return {
      mentions: {
        excludeMotionMentions: true,
        list: [
          {
            label: 'Variables',
            type: 'variable',
            renderOptionItem: (item) => {
              return (
                <AgentVariableTag agentVariable={item as AgentVariableSchema} />
              )
            },
            renderLexicalNode: ({ entityLabel }) => {
              return <AgentVariableLabel name={entityLabel} />
            },

            query: onQueryVariable,
          } satisfies Reference,
        ],
      },
    }
  }, [onQueryVariable])

  return (
    <StyledCard>
      <div className='p-3 flex flex-col gap-3'>
        <div className='flex flex-row items-center justify-between'>
          <StyledTitleWrapper>
            <TextField
              label='Step name'
              labelHidden
              placeholder={placeholderTitle}
              variant='muted'
              prefix={
                <Text size='md' className='text-semantic-blue-text-default'>
                  @
                </Text>
              }
              maxLength={30}
              value={title}
              onChange={onChangeTitle}
            />
          </StyledTitleWrapper>

          <PopoverTrigger
            placement='bottom-end'
            renderPopover={({ close }) => {
              return (
                <ActionList
                  onActionAnyItem={close}
                  items={AgentWorkflowStepType.map((t) => {
                    return {
                      content: <WorkflowStepType type={t} />,
                      onAction: () => onChangeType(t),
                    }
                  })}
                />
              )
            }}
          >
            <Button sentiment='neutral' variant='muted' size='small'>
              <WorkflowStepType type={type} />
              <ChevronDownOutline />
            </Button>
          </PopoverTrigger>
        </div>
        <ReferenceEditor
          name='step-prompt'
          placeholder={placeholderContent}
          maxLength={10_000}
          options={editorOptions}
          initialValue={{ format: 'lexical-state', value: content }}
          onChange={(editorState) => {
            onChangeContent(
              getTextSizeFromEditorState(editorState) > 0
                ? JSON.stringify(editorState)
                : ''
            )
          }}
        />
      </div>
      <VariableSection variables={variables} />
    </StyledCard>
  )
}

function WorkflowStepType({ type }: { type: AgentWorkflowStepType }) {
  const { label, icon: Icon } = agentStepTypeToData[type]
  return (
    <div className='flex flex-row gap-1.5'>
      <Icon className='size-4 text-semantic-neutral-icon-default shrink-0' />
      <Text size='sm' truncate>
        {label}
      </Text>
    </div>
  )
}

function VariableSection({ variables }: { variables: AgentVariableSchema[] }) {
  if (variables.length === 0) return null

  return (
    <div className='flex flex-col gap-1.5 px-3 py-2 bg-semantic-neutral-surface-bg-subtlest rounded-b-lg'>
      {variables.map((v) => {
        return (
          <div key={v.id} className='flex flex-row gap-1.5'>
            <Text sentiment='subtle' size='sm'>
              {v.label}
            </Text>
            <Text size='sm'>{v.description}</Text>
          </div>
        )
      })}
    </div>
  )
}

const StyledCard = classed('section', {
  base: `
    rounded-lg
    border border-semantic-neutral-border-default
    bg-semantic-neutral-surface-raised-bg-default

  `,
})

const StyledTitleWrapper = classed('div', {
  base: `
    rounded
    bg-semantic-blue-bg-default
    min-w-40

    [&_input]:placeholder:text-semantic-blue-text-subtle
    [&_input]:text-semantic-blue-text-default
    [&_input]:font-semibold
    [&_input]:[field-sizing:content]

  `,
})
