import { ExclamationCircleSolid } from '@motion/icons'
import { Button, showToast, TextHeader, Tooltip } from '@motion/ui/base'
import { logEvent, recordAnalyticsEvent } from '@motion/web-base/analytics'
import { logInDev } from '@motion/web-base/logging'

import { Events } from '~/analyticsEvents'
import {
  EnsureBookingDataLoaded,
  useIsBookingOnboarded,
} from '~/areas/calendar/booking/hooks'
import { useOnboardingApi } from '~/areas/onboarding/state/use-onboarding-api'
import { YoutubeVideo } from '~/components/Common/YoutubeVideo/YoutubeVideo'
import {
  selectBookingPageLoadingState,
  selectBookingSettings,
  updateUrlPrefix,
} from '~/state/booking'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router'

import { AvailabilityAttendanceRequirementsRow } from './edit-link-page/components/availability-attendance-requirements-row'
import { AvailabilityBufferTimeRow } from './edit-link-page/components/availability-buffer-time-row'
import { AvailabilityScheduleRow } from './edit-link-page/components/availability-schedule-row'
import { BookingLinkRow } from './edit-link-page/components/booking-link-row'
import { BookingReminderRow } from './edit-link-page/components/booking-reminder-row'
import { EventDurationRow } from './edit-link-page/components/event-duration-row'
import { EventLocationRow } from './edit-link-page/components/event-location-row'

import { BookingTemplateContext } from '../booking-template-context'
import { useBookingTemplateBySlug } from '../hooks/use-booking-template-by-slug'

export const BookingTutorialPage = () => {
  const [videoWatched, setVideoWatched] = useState<boolean>(false)

  return (
    <EnsureBookingDataLoaded>
      <div className='py-8 px-4 bg-semantic-neutral-surface-bg-default min-h-full overflow-auto'>
        {videoWatched ? (
          <FormStep />
        ) : (
          <VideoStep onComplete={() => void setVideoWatched(true)} />
        )}
      </div>
    </EnsureBookingDataLoaded>
  )
}

type VideoStepProps = {
  onComplete: () => void
}

const VideoStep = ({ onComplete }: VideoStepProps) => {
  const [videoCompleted, setVideoCompleted] = useState(false)
  const [videoVisible, setVideoVisible] = useState(true)

  return (
    <div className='space-y-6'>
      <div className='text-center space-y-2'>
        <p className='text-2xl font-semibold'>
          The smartest booking links ever made
        </p>

        <p className='text-semantic-neutral-text-subtle text-sm'>
          It books meetings, maximizes deep work time, and improves your focus
        </p>
      </div>

      {videoCompleted && (
        <div className='flex flex-col items-center gap-2'>
          <Button onClick={onComplete}>Get Started</Button>

          {!videoVisible && (
            <Button
              sentiment='neutral'
              variant='muted'
              onClick={() => {
                setVideoVisible(true)
              }}
            >
              Rewatch video
            </Button>
          )}
        </div>
      )}

      {videoVisible && (
        <div className='flex items-center justify-center'>
          <YoutubeVideo
            videoId='aC_2vkRbfH0'
            onEnd={() => {
              setVideoCompleted(true)
              setVideoVisible(false)
              void recordAnalyticsEvent('SCHEDULER_VIDEO_FINISHED')
            }}
            startAt={722}
            muteOnStart={false}
          />
        </div>
      )}

      {!videoCompleted && (
        <p className='text-semantic-neutral-text-subtle text-sm text-center'>
          Please finish video to move on to next step
        </p>
      )}
    </div>
  )
}

const FormStep = () => {
  const dispatch = useAppDispatch()
  const bookingSettings = useAppSelector(selectBookingSettings)
  const bookingPageLoadingState = useAppSelector(selectBookingPageLoadingState)
  const hasAlreadyOnboarded = useIsBookingOnboarded()
  const { progressOnboarding } = useOnboardingApi()

  const [originalUrlPrefix, setOriginalUrlPrefix] = useState('')
  const [urlPrefixInput, setUrlPrefixInput] = useState('')
  const [urlPrefixResult, setUrlPrefixResult] = useState('')

  const navigate = useNavigate()
  const bookingTemplate = useBookingTemplateBySlug({
    slug: '',
    shouldCreateDefaultTemplate: true,
    shouldInitSettings: () => {
      if (hasAlreadyOnboarded && bookingPageLoadingState !== 'loaded')
        return false

      setUrlPrefixInput(bookingSettings.urlPrefix || '')
      setOriginalUrlPrefix(bookingSettings.urlPrefix || '')

      return true
    },
  })

  const {
    hostCalendar,
    template: { state, setters, submitHandler },
  } = bookingTemplate

  const saveUrlPrefix = useCallback(async () => {
    let success = false

    setters.setLoading(true)

    try {
      await dispatch(updateUrlPrefix({ urlPrefix: urlPrefixInput })).unwrap()
      success = true

      void logEvent(Events.CALENDAR_AVAILABILITY_ID_CHANGE, {
        team: false,
      })

      setUrlPrefixResult('')
    } catch (e) {
      logInDev('error saving availability', e)
      setUrlPrefixResult('Issue saving your URL')
    } finally {
      setters.setLoading(false)
    }

    return success
  }, [dispatch, setters, urlPrefixInput])

  const handleSave = async () => {
    if (state.loading) return

    const success = await submitHandler()

    if (!success) {
      return void setters.setLoading(false)
    }

    await progressOnboarding('scheduler')

    void logEvent(Events.CALENDAR_AVAILABILITIES_TEMPLATE_SAVE, {
      newLink: false,
    })

    if (originalUrlPrefix !== urlPrefixInput) {
      const idSaveResult = await saveUrlPrefix()

      if (!idSaveResult) return
    }

    showToast('success', 'Booking link created successfully')

    void recordAnalyticsEvent(Events.SCHEDULER_ONBOARDING_FINISH)

    navigate('/web/calendar?booking')
  }

  return (
    <BookingTemplateContext.Provider value={bookingTemplate}>
      <div className='max-w-[1000px] mx-auto w-full space-y-8'>
        <TextHeader size='2xl'>
          <span>Set up your booking link</span>

          {!hostCalendar && (
            <Tooltip
              content='This booking link is missing a host calendar.'
              asChild
            >
              <ExclamationCircleSolid className='text-semantic-error-icon-default size-5 cursor-pointer' />
            </Tooltip>
          )}
        </TextHeader>

        {state.errorMessage && (
          <div className='bg-semantic-error-bg-default text-semantic-error-text-default border-semantic-error-border-default -mt-px border rounded px-4 py-2'>
            {state.errorMessage}
          </div>
        )}

        <div className='border-semantic-neutral-border-default divide-semantic-neutral-border-default flex w-full flex-col divide-y rounded border'>
          <AvailabilityScheduleRow />
          <AvailabilityBufferTimeRow />
          <AvailabilityAttendanceRequirementsRow />
          <EventDurationRow />
          <EventLocationRow />
          <BookingReminderRow />
          <BookingLinkRow
            editable={!hasAlreadyOnboarded}
            prefix={urlPrefixInput}
            onPrefixChange={setUrlPrefixInput}
            prefixError={urlPrefixResult}
          />
        </div>

        <div className='text-right'>
          <Button loading={state.loading} onClick={handleSave}>
            Create booking page
          </Button>
        </div>
      </div>
    </BookingTemplateContext.Provider>
  )
}
