import { SearchSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { addComponentName } from '@motion/ui/helpers'

import { ShareTargetItem } from '~/areas/modals/share-modal/components/share-target-item'
import { NoteRoleButton } from '~/areas/share/components'
import { useContext } from 'react'

import { ShareItemModalContext } from '../context/context'

type TeamMemberSearchProps = {
  onSubmit: () => void
}

export const TeamMemberSearch = ({ onSubmit }: TeamMemberSearchProps) => {
  const {
    searchActive,
    searchQuery,
    selectedRole,
    selectedTargets,
    setSearchActive,
    setSearchQuery,
    setSelectedRole,
  } = useContext(ShareItemModalContext)

  return (
    <div className='bg-semantic-neutral-surface-raised-bg-subtlest py-3 px-4'>
      <div className='flex items-start w-full gap-2'>
        <TextField
          headerContent={
            selectedTargets.length > 0 && (
              <div className='flex flex-row gap-x-1.5 -mx-0.5 mb-2'>
                <div className='flex flex-row flex-wrap gap-x-1.5 gap-y-1 grow'>
                  {selectedTargets.map((item, idx) => (
                    <div
                      key={item.targetId}
                      className='inline-flex'
                      {...addComponentName('tag-wrapper')}
                    >
                      <ShareTargetItem target={item} asTag />
                    </div>
                  ))}
                </div>

                <div className='shrink-0'>
                  <NoteRoleButton
                    selectedRole={selectedRole}
                    onSelectRole={setSelectedRole}
                  />
                </div>
              </div>
            )
          }
          size='normal'
          fullWidth
          prefix={
            !searchActive || !selectedTargets.length ? <SearchSolid /> : null
          }
          placeholder='Add team members and workspaces'
          value={searchQuery}
          onChange={(value) => {
            setSearchActive(true)
            setSearchQuery(value)
          }}
          onClick={() => void setSearchActive(true)}
        />

        <Button
          sentiment='primary'
          onClick={onSubmit}
          disabled={selectedTargets.length === 0}
        >
          Invite
        </Button>
      </div>
    </div>
  )
}
