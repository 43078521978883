import {
  ArrowDownSolid,
  ArrowUpSolid,
  DotsHorizontalSolid,
  DuplicateOutline,
  ExternalLinkSolid,
  StarCrossOutOutline,
} from '@motion/icons'
import {
  ActionList,
  IconButton,
  PopoverTrigger,
  showToast,
} from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useDesktopTabs } from '~/areas/desktop/hooks'
import { useRemoveFavorite } from '~/areas/folders/hooks/use-remove-favorite'
import { useClipboard } from '~/localServices/clipboard'

type ViewActionsMenuProps = {
  itemId: string
  url: string
  moveActionAllowed?: {
    up: boolean
    down: boolean
  }
  onMoveItem?: (moveBy: 1 | -1) => void
}

export const ViewActionsMenu = (props: ViewActionsMenuProps) => {
  return (
    <PopoverTrigger
      renderPopover={({ close }) => (
        <ViewActionsMenuContents close={close} {...props} />
      )}
    >
      <IconButton
        variant='muted'
        sentiment='neutral'
        size='xsmall'
        icon={DotsHorizontalSolid}
        onClick={() => {
          recordAnalyticsEvent('FOLDERS_CLICKED_SIDEBAR_BUTTON', {
            itemType: 'VIEW',
            button: 'ACTIONS',
          })
        }}
      />
    </PopoverTrigger>
  )
}

type ViewActionsMenuContentsProps = ViewActionsMenuProps & {
  close: () => void
}

export const ViewActionsMenuContents = ({
  close,
  itemId,
  moveActionAllowed,
  url,
  onMoveItem,
}: ViewActionsMenuContentsProps) => {
  const clipboard = useClipboard()
  const removeFavorite = useRemoveFavorite()
  const { hasTabs, openTab, canOpenTab, tabTooltip } = useDesktopTabs()

  const handleCopyLink = () => {
    recordAnalyticsEvent('FOLDERS_COPIED_LINK', {
      location: 'SIDEBAR',
    })

    try {
      clipboard.write({
        text: new URL(url, window.location.origin).toString(),
      })

      showToast('success', 'Link copied to clipboard')
    } catch (e) {
      showToast('error', 'Could not copy link to clipboard')
    }
  }

  const handleRemoveFavorite = () => {
    recordAnalyticsEvent('TOGGLE_FAVORITE', {
      itemType: 'VIEW',
      location: 'CONTEXT_MENU',
      state: 'REMOVED',
    })

    return void removeFavorite(itemId)
  }

  const handleOpenInNewTab = () => {
    openTab(url, 'VIEW')
  }

  return (
    <div className='scrollbar-none w-full scroll-py-1 overflow-y-auto overflow-x-hidden'>
      <ActionList
        onActionAnyItem={close}
        sections={[
          {
            items: [
              {
                prefix: <DuplicateOutline />,
                content: 'Copy link',
                onAction: handleCopyLink,
              },
              hasTabs && {
                prefix: <ExternalLinkSolid />,
                content: 'Open in new tab',
                onAction: handleOpenInNewTab,
                disabled: !canOpenTab,
                tooltip: tabTooltip,
              },
              {
                prefix: <StarCrossOutOutline />,
                content: 'Remove from Favorites',
                onAction: handleRemoveFavorite,
              },
            ].filter(Boolean),
          },
          onMoveItem &&
            moveActionAllowed && {
              items: [
                {
                  prefix: <ArrowUpSolid />,
                  content: 'Move up',
                  disabled: !moveActionAllowed.up,
                  onAction: () => onMoveItem(-1),
                },
                {
                  prefix: <ArrowDownSolid />,
                  content: 'Move down',
                  disabled: !moveActionAllowed.down,
                  onAction: () => onMoveItem(1),
                },
              ],
            },
        ].filter(Boolean)}
      />
    </div>
  )
}
