import { WorkspaceSolid } from '@motion/icons'
import { type TaskResponseSchema } from '@motion/zod/client'

import { ConnectedProjectIcon } from '~/global/components'

import { GenericField } from './generic-field'

interface WorkspaceProjectFieldProps {
  workspaceName: TaskResponseSchema['workspace']['name']
  projectId?: string | null
  projectName?: TaskResponseSchema['project']['name']
}

export const WorkspaceProjectField = ({
  workspaceName,
  projectId = null,
  projectName,
}: WorkspaceProjectFieldProps) => {
  return (
    <GenericField icon={WorkspaceSolid}>
      <span className='truncate max-w-[150px]'>{workspaceName}</span>
      {projectName != null && (
        <>
          <span>/</span>
          <ConnectedProjectIcon width={12} height={12} projectId={projectId} />
          <span className='truncate max-w-[150px]'>{projectName}</span>
        </>
      )}
    </GenericField>
  )
}
