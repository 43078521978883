import type {
  PriorityLevelSchema,
  UserTaskDefaultSettingsRequestSchema,
} from '@motion/rpc-types'
import {
  type EndRelativeDateOption,
  type StartRelativeDateOption,
} from '@motion/shared/common'
import { type TaskDefaults } from '@motion/ui-logic/pm/task'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { useIsFlowProject } from '~/areas/flows'
import { useWorkspaceFns } from '~/global/hooks'

type AnalyticsOption = {
  workspace: 'my_tasks' | 'private_ws' | 'public_ws'
  project: 'no_project' | 'regular' | 'flow'
  assignee: 'me' | 'unassigned' | 'not_me'
  status:
    | 'backlog'
    | 'todo'
    | 'in_progress'
    | 'cancelled'
    | 'blocked'
    | 'completed'
    | 'user_generated'
  priority: PriorityLevelSchema
  autoscheduled: 'on' | 'off'
  duration: number
  chunk: 'no_chunk' | number
  start_date: StartRelativeDateOption
  deadline: EndRelativeDateOption
  schedule: 'workhours' | 'not_workhours'
  custom_field: 'empty' | 'not_empty'
}

export function useMapTaskDefaultAnalytics<
  T extends keyof UserTaskDefaultSettingsRequestSchema['data'],
>() {
  const {
    getWorkspaceById,
    getWorkspaceProjectById,
    getStatusById,
    getWorkspaceMemberById,
  } = useWorkspaceFns()
  const { uid: currentUserId } = useAuthenticatedUser()
  const isFlowProject = useIsFlowProject()

  return (
    field: T,
    selectedTaskDefaults: TaskDefaults,
    value: UserTaskDefaultSettingsRequestSchema['data'][T]
  ) => {
    const mapValue = (val: any): AnalyticsOption[keyof AnalyticsOption] => {
      switch (field) {
        case 'workspaceId': {
          const workspace = getWorkspaceById(val)
          return workspace?.isPersonalWorkspace
            ? 'my_tasks'
            : workspace?.type === 'TEAM'
              ? 'public_ws'
              : 'private_ws'
        }
        case 'projectId': {
          const project = getWorkspaceProjectById(val)
          return !project
            ? 'no_project'
            : isFlowProject(project)
              ? 'flow'
              : 'regular'
        }
        case 'assigneeUserId': {
          const member = getWorkspaceMemberById(
            selectedTaskDefaults.workspaceId,
            val
          )
          return !member
            ? 'unassigned'
            : member.userId === currentUserId
              ? 'me'
              : 'not_me'
        }
        case 'statusId': {
          const status = getStatusById(val)
          if (!status.isSystemStatus) return 'user_generated'

          return status?.name as AnalyticsOption['status']
        }
        case 'priorityLevel':
          return val as AnalyticsOption['priority']
        case 'isAutoScheduled':
          return val ? 'on' : 'off'
        case 'duration':
          return val
        case 'minimumDuration':
          return !val ? 'no_chunk' : val
        case 'relativeStartOn':
          return val
        case 'relativeDueDate':
          return val
        case 'deadlineType':
          return val
        case 'scheduleId':
          return val === 'work' ? 'workhours' : 'not_workhours'
        case 'customFieldValues':
          return val ? 'not_empty' : 'empty'
        default:
          return val
      }
    }

    const from = mapValue(
      selectedTaskDefaults[field as keyof typeof selectedTaskDefaults]
    )
    const to = mapValue(value)

    const customFieldType =
      field === 'customFieldValues' && value
        ? { custom_field_type: Object.values(value)?.[0]?.type }
        : {}

    return {
      field,
      from,
      to,
      ...customFieldType,
    }
  }
}
