import { useContext } from 'react'

import { StageTimelineContext } from './context'

export function useStageTimelineContext() {
  const context = useContext(StageTimelineContext)
  if (context === null) {
    throw new Error(
      'useStageTimelineContext must be used within a StageTimelineProvider'
    )
  }
  return context
}
