import { ExclamationTriangleSolid, WorkspaceSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { type StageDefinitionSchema } from '@motion/rpc-types'
import { type COLOR } from '@motion/shared/common'
import { classed } from '@motion/theme'
import { ConfirmationModal, PopoverButton } from '@motion/ui/base'
import { ProjectPalette } from '@motion/ui/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import { useFlowTemplateModalUrl } from '~/areas/flows'
import { type ModalTriggerComponentProps } from '~/areas/modals'
import { WorkspaceDropdown } from '~/global/components/dropdowns'
import { StageLabel } from '~/global/components/labels'
import { useI18N } from '~/global/contexts'
import {
  useProjectDefinition,
  useWorkspaceById,
  useWorkspaceProjectDefinitionsWithStages,
} from '~/global/hooks'
import { useCopyProjectDefinition } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useUriByRouteId } from '~/routing'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'duplicate-flow': {
      flowId: string
      workspaceId: string
    }
  }
}

export function DuplicateFlowModal({
  close,
  flowId,
  workspaceId,
}: ModalTriggerComponentProps<'duplicate-flow'>) {
  const flowTemplate = useProjectDefinition(flowId)
  const initialWorkspaceId = flowTemplate?.workspaceId ?? workspaceId
  const attachmentsCount = flowTemplate?.uploadedFileIds?.length ?? 0

  const [stagesToDuplicate, setStagesToDuplicate] = useState<
    StageDefinitionSchema[]
  >([])
  const navigate = useNavigate()
  const buildFlowTemplateModalUrl = useFlowTemplateModalUrl()
  const { pluralize } = useI18N()

  const [currentWorkspaceId, setCurrentWorkspaceId] =
    useState(initialWorkspaceId)

  const [checkingForDuplicateStages, setCheckingForDuplicateStages] =
    useState(false)
  const [hasCheckedForDuplicateStages, setHasCheckedForDuplicateStages] =
    useState(false)

  const initialWorkspace = useWorkspaceById(initialWorkspaceId)
  const currentWorkspace = useWorkspaceById(currentWorkspaceId)

  const getRouteUri = useUriByRouteId()

  const { mutateAsync: duplicateFlowMutation, isPending: isLoading } =
    useCopyProjectDefinition()
  const newWorkspaceProjectDefinitions =
    useWorkspaceProjectDefinitionsWithStages(currentWorkspaceId)

  const findDuplicateStages = () => {
    setCheckingForDuplicateStages(true)
    const newWorkspaceStageNames = newWorkspaceProjectDefinitions.flatMap(
      (pd) => pd.stages.flatMap((stage) => stage.name)
    )

    // mocking a backend check on duplicate stage names
    setTimeout(() => {
      const stagesToDuplicate =
        flowTemplate?.stages.filter((stage) =>
          newWorkspaceStageNames.includes(stage.name)
        ) ?? []

      setStagesToDuplicate(stagesToDuplicate)
      setHasCheckedForDuplicateStages(true)
      setCheckingForDuplicateStages(false)

      if (stagesToDuplicate.length === 0) {
        duplicateFlow()
      }
    }, 800)
  }

  const duplicateFlow = async () => {
    if (!flowTemplate) return

    recordAnalyticsEvent('COPY_FLOW_TEMPLATE', {
      numStages: flowTemplate.stages.length,
      numTasks: flowTemplate.stages.reduce(
        (acc, stage) => acc + stage.tasks.length,
        0
      ),
      name: flowTemplate.name,
    })

    try {
      const newFlow = await duplicateFlowMutation({
        id: flowId,
        workspaceId: initialWorkspaceId,
        destinationWorkspaceId: currentWorkspaceId,
      })
      close()

      navigate(
        buildFlowTemplateModalUrl({
          template: 'edit',
          forWorkspace: currentWorkspaceId,
          templateId: newFlow.id,
          forDuplicate: flowId,
          pathName: getRouteUri('workspace-template-settings', {
            workspaceId: currentWorkspaceId,
          }),
        })
      )
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : 'An unexpected error occurred'

      showErrorToast(
        `There was an error duplicating the project template: ${errorMessage}`
      )

      Sentry.captureException(e, {
        tags: { position: 'duplicate-flow-modal' },
      })
    }
  }
  return (
    <ConfirmationModal
      action={{
        label: 'Duplicate template',
        onAction: async () => {
          if (
            !hasCheckedForDuplicateStages &&
            initialWorkspaceId !== currentWorkspaceId
          ) {
            return findDuplicateStages()
          }
          await duplicateFlow()
        },
      }}
      closeLabel='Cancel'
      description={
        <div>
          <div className='flex flex-col gap-2'>
            <span>
              When duplicating a template to a different workspace, the stages
              within the template will also be copied over.
            </span>
            {attachmentsCount > 0 && (
              <span>
                {templateStr(
                  'Note: You’ll need to re-add the {{attachmentsCount}} {{attachmentLabel}} currently in this template in the next screen.',
                  {
                    attachmentsCount,
                    attachmentLabel: pluralize(
                      attachmentsCount,
                      'attachment',
                      'attachments'
                    ),
                  }
                )}
              </span>
            )}
          </div>
          <div className='flex flex-col items-start gap-4 self-stretch'>
            <WorkspaceDropdown
              onChange={(workspace) => {
                setCurrentWorkspaceId(workspace.id)
                setHasCheckedForDuplicateStages(false)
                setCheckingForDuplicateStages(false)
                setStagesToDuplicate([])
              }}
              selectedWorkspaceId={currentWorkspaceId}
            >
              <div className='w-full mt-5 mb-1'>
                <PopoverButton
                  icon={
                    <ProjectPalette
                      color={
                        currentWorkspace && 'color' in currentWorkspace
                          ? (currentWorkspace.color as COLOR)
                          : 'gray'
                      }
                    >
                      <WorkspaceSolid />
                    </ProjectPalette>
                  }
                >
                  {currentWorkspace?.name}
                </PopoverButton>
              </div>
            </WorkspaceDropdown>
            {stagesToDuplicate.length > 0 && (
              <DuplicateStagesContainer>
                <div className='flex items-start gap-2 self-stretch'>
                  <ExclamationTriangleSolid className='size-4 text-semantic-warning-bg-strong-default' />
                  <div className='flex flex-col justify-center items-center gap-2 flex-1'>
                    <DuplicateStagesText>
                      {templateStr(
                        '{{stageCount}} {{stageLabel}} have the same name in the new workspace and will be renamed:',
                        {
                          stageCount: stagesToDuplicate.length,
                          stageLabel: pluralize(
                            stagesToDuplicate.length,
                            'stage',
                            'stages'
                          ),
                        }
                      )}
                    </DuplicateStagesText>
                    <div className='flex flex-col gap-1 self-stretch flex-wrap items-start content-start'>
                      {stagesToDuplicate.map((stage) => (
                        <StageLabel
                          key={stage.id}
                          size='small'
                          value={{
                            ...stage,
                            name: `${stage.name} (From ${initialWorkspace?.name ?? 'Unknown'})`,
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </DuplicateStagesContainer>
            )}
          </div>
        </div>
      }
      loading={checkingForDuplicateStages || isLoading}
      onClose={close}
      title='Duplicate template'
      visible
    />
  )
}

const DuplicateStagesContainer = classed('div', {
  base: `flex flex-col self-stretch gap-2 p-2 items-start
    rounded border border-semantic-warning-border-default
    bg-semantic-warning-bg-disabled`,
})

const DuplicateStagesText = classed('span', {
  base: `text-semantic-neutral-text-default text-xs font-normal`,
})
