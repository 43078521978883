import {
  type FlowTemplateFormTask,
  type TextVariable,
} from '@motion/ui-logic/pm/project'
import { type TaskDefinitionSchema } from '@motion/zod/client'

import { stripVariableKeysFromTaskDefinition } from '~/areas/flows/utils'

export function useNonEmptyTasksAbove(
  stageTasks: FlowTemplateFormTask[],
  task: TaskDefinitionSchema,
  variables: TextVariable[]
) {
  const variableKeys = variables.map((v) => v.key)

  const taskIndex = stageTasks.findIndex((t) => t.id === task.id)
  const nonEmptyTasksAbove = stageTasks
    .slice(0, taskIndex)
    .map((t) => stripVariableKeysFromTaskDefinition(t, variableKeys))
    .filter((t) => t.name !== '')

  return nonEmptyTasksAbove
}
