import { useSharedState } from '@motion/react-core/shared-state'

import { useIsFlowProject } from '~/areas/flows'
import { useCachedItem } from '~/global/cache'
import { AppWorkspaceContext } from '~/global/contexts'
import { useMemo } from 'react'

import { useSelectView } from './use-select-view'

import { usePageData } from '../../routes/hooks'
import { getDefaultView } from '../defaults'

export const useSelectedView = () => {
  const [ctx] = useSharedState(AppWorkspaceContext)
  const route = usePageData()
  const isFlowProject = useIsFlowProject()

  const update = useSelectView()

  const cachedView = useCachedItem('views', route.lock.viewId)

  const currentView = useMemo(() => {
    if (route.lock.viewId == null) return null
    if (cachedView != null) return cachedView

    const defaultView = getDefaultView(route.page, {
      isFlowProject:
        route.page === 'project' &&
        route.lock.projectId != null &&
        isFlowProject(ctx.projects.byId[route.lock.projectId]),
    })
    return defaultView
  }, [
    cachedView,
    ctx.projects.byId,
    isFlowProject,
    route.lock.projectId,
    route.lock.viewId,
    route.page,
  ])

  return [currentView, update] as const
}
