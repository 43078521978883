import { TrashSolid } from '@motion/icons'
import { IconButton, Text } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { NOOP_FUNCTION } from '@motion/utils/function'

import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

export type EditableSettingsRowProps = {
  icon?: React.ReactNode
  id: string
  name: string
  canDelete?: boolean
  indent?: boolean
  onDelete?: (id: string) => void
  onChange: (id: string, value: string) => void
  disabled?: boolean
  className?: string
}

export const EditableSettingsRow = ({
  icon,
  id,
  name,
  className = '',
  canDelete = true,
  disabled = false,
  onDelete = NOOP_FUNCTION,
  onChange = NOOP_FUNCTION,
}: EditableSettingsRowProps) => {
  const [currentName, setCurrentName] = useState('')

  useEffect(() => {
    setCurrentName(name)
  }, [name])

  return (
    <div className={twMerge('flex gap-1 w-full py-2', className)}>
      {icon && (
        <div className='mr-2.5 flex items-center justify-center'>{icon}</div>
      )}
      {disabled ? (
        <Text size='sm'>{currentName}</Text>
      ) : (
        <TextField
          fullWidth
          value={currentName}
          onChange={(v) => setCurrentName(v)}
          onBlur={(e) => {
            onChange(id, e.target.value)
            setCurrentName(e.target.value)
          }}
        />
      )}
      {canDelete && (
        <IconButton icon={TrashSolid} onClick={() => onDelete(id)} />
      )}
    </div>
  )
}
