import { showToast } from '@motion/ui/base'
import { Sentry } from '@motion/web-base/sentry'
import type { RecursiveFolderItemSchema } from '@motion/zod/client'

import { useUserFavorites } from '~/areas/folders/hooks/use-user-favorites'
import { useProjectUpdater } from '~/areas/project/hooks'
import { useFindFolderItem } from '~/global/hooks'
import { type WorkspacesTreeItem } from '~/global/hooks'
import { useUpdateItemInFolder } from '~/global/rpc/folders'
import { LexoRank } from 'lexorank'
import { useCallback } from 'react'

export const useMoveProjectInFolder = () => {
  const updateProject = useProjectUpdater()

  return useCallback(
    (projectId: string, folderId: string, folderItemOrder?: string) =>
      updateProject(projectId, {
        folderId,
        folderItemOrder,
      }),
    [updateProject]
  )
}

type MoveFolderItemInDirectionCallback = (
  itemId: string,
  moveBy: -1 | 1
) => void

export const useMoveWorkspacesItemInDirection = () => {
  const { mutateAsync: updateItemInFolder } = useUpdateItemInFolder()
  const moveProjectInFolder = useMoveProjectInFolder()
  const findFolderItem = useFindFolderItem()

  const moveItem = useCallback(
    (item: RecursiveFolderItemSchema, lexorank: LexoRank) => {
      const order = lexorank.toString()

      if (item.itemType === 'FOLDER' || item.itemType === 'NOTE') {
        return updateItemInFolder({
          itemId: item.id,
          order,
        })
      }

      if (item.itemType === 'PROJECT') {
        if (!item.folderId) return

        return moveProjectInFolder(item.itemId, item.folderId, order)
      }
    },
    [moveProjectInFolder, updateItemInFolder]
  )

  return useCallback<MoveFolderItemInDirectionCallback>(
    (itemId, moveBy) => {
      const searchResult = findFolderItem((item) => item.id === itemId)

      if (!searchResult) return

      const [item, parent] = searchResult
      const index = parent.items.findIndex(({ id }) => item.id === id)

      if (index === -1) return

      const newIndex = index + moveBy

      if (newIndex < 0) {
        throw new Error('Cannot move top-most item up')
      }

      if (newIndex > parent.items.length - 1) {
        throw new Error('Cannot move bottom-most item down')
      }

      const projectedSibling = parent.items[newIndex]
      const projectedFollowingSibling = parent.items[newIndex + moveBy]

      if (!!projectedSibling && !projectedFollowingSibling) {
        // Moving to the top or bottom
        const currentOrder = LexoRank.parse(projectedSibling.order)

        let newOrder!: LexoRank

        if (moveBy === 1) {
          newOrder = currentOrder.genNext()
        } else {
          newOrder = currentOrder.genPrev()
        }

        return moveItem(item, newOrder)
      }

      if (!!projectedFollowingSibling && !!projectedSibling) {
        // Placed in-between two existing items
        const prevOrder = LexoRank.parse(projectedFollowingSibling.order)
        const nextOrder = LexoRank.parse(projectedSibling.order)

        const newOrder = prevOrder.between(nextOrder)

        return moveItem(item, newOrder)
      }
    },
    [findFolderItem, moveItem]
  )
}

export const useMoveFavoriteFolderItemInDirection = () => {
  const { mutateAsync: updateItemInFolder } = useUpdateItemInFolder()
  const favorites = useUserFavorites()

  const moveItem = useCallback(
    (itemId: WorkspacesTreeItem['id'], lexorank: LexoRank) => {
      const order = lexorank.toString()

      if (!favorites.folder) {
        Sentry.captureException(
          new Error('Could not find user favorites folder'),
          {
            extra: {
              itemId,
              lexorank,
            },
            tags: {
              position: 'useMoveFavoriteInDirection',
            },
          }
        )

        return void showToast(
          'error',
          'There was a problem reordering your favorites'
        )
      }

      return updateItemInFolder({ itemId, order })
    },
    [favorites.folder, updateItemInFolder]
  )

  return useCallback<MoveFolderItemInDirectionCallback>(
    (itemId, moveBy) => {
      const itemIdx = favorites.items.findIndex((item) => item.id === itemId)

      if (itemIdx === -1) {
        throw new Error('Could not find user favorites folder')
      }

      const newIndex = itemIdx + moveBy

      if (newIndex < 0) {
        throw new Error('Cannot move top-most item up')
      }

      if (newIndex > favorites.items.length - 1) {
        throw new Error('Cannot move bottom-most item down')
      }

      const projectedSibling = favorites.items[newIndex]
      const projectedFollowingSibling = favorites.items[newIndex + moveBy]

      if (!!projectedSibling && !projectedFollowingSibling) {
        // Moving to the top or bottom
        const currentOrder = LexoRank.parse(projectedSibling.order)

        if (moveBy === 1) {
          return moveItem(itemId, currentOrder.genNext())
        }

        return moveItem(itemId, currentOrder.genPrev())
      }

      if (!!projectedFollowingSibling && !!projectedSibling) {
        // Placed in-between two existing items
        const prevOrder = LexoRank.parse(projectedFollowingSibling.order)
        const nextOrder = LexoRank.parse(projectedSibling.order)

        return moveItem(itemId, prevOrder.between(nextOrder))
      }
    },
    [favorites.items, moveItem]
  )
}
