import { useHasTreatment } from '@motion/web-common/flags'

import { SharedSection } from '~/areas/sidebar/treeviews/shared-section'

import { FavoritesSection } from './favorites-section'
import { PrivateSection } from './private-section'
import { WorkspacesSection } from './workspaces-section'

export const Treeviews = () => {
  const hasSharedSpace = useHasTreatment('shared-space')
  const hasPrivateSection = useHasTreatment('private-workspace-as-section')

  return (
    <div className='space-y-6'>
      <FavoritesSection />
      {hasPrivateSection && <PrivateSection />}
      <WorkspacesSection />
      {hasSharedSpace && <SharedSection />}
    </div>
  )
}
