import { twMerge } from 'tailwind-merge'

import { CellText } from './cell-action'

export type EmptyCellProps = {
  position?: 'left' | 'center'
}

export function EmptyCell({ position = 'center' }: EmptyCellProps) {
  return (
    <CellText withHover isFake>
      <span
        className={twMerge(
          'text-semantic-neutral-text-subtle',
          position === 'center' && 'mx-auto',
          position === 'left' && 'mr-auto'
        )}
      >
        -
      </span>
    </CellText>
  )
}
