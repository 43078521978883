import { isNoneId } from '@motion/shared/identifiers'
import {
  PopoverTrigger,
  type PopoverTriggerProps,
  SearchableList,
} from '@motion/ui/base'
import { computeSearchScore } from '@motion/ui-logic'
import { byProperty, Compare } from '@motion/utils/array'
import { type ProjectSchema, type WorkspaceSchema } from '@motion/zod/client'

import { useOrderedWorkspaceProjectList } from '~/global/hooks'
import { type ReactNode } from 'react'

import { useWorkspaceProjectSections } from './hooks'

import { ProjectLabel } from '../../labels'
import { WorkspaceDropdownContent } from '../workspace-dropdown'

export type WorkspaceProjectDropdownProps = {
  children: ReactNode
  placement?: PopoverTriggerProps['placement']
} & Omit<WorkspaceProjectContentProps, 'close'>

export const WorkspaceProjectDropdown = ({
  children,
  placement = 'bottom-start',
  ...rest
}: WorkspaceProjectDropdownProps) => {
  return (
    <PopoverTrigger
      placement={placement}
      renderPopover={({ close }) => (
        <WorkspaceProjectContent close={close} {...rest} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type WorkspaceProjectContentProps = {
  close: () => void
  onSelect: (
    workspaceId: WorkspaceSchema['id'],
    projectId: ProjectSchema['id'] | null
  ) => void
  selectedWorkspaceId: WorkspaceSchema['id'] | null
  selectedProjectId: ProjectSchema['id'] | null
  hideProjectsFromDropdown?: boolean
  hideNoProject?: boolean
  disallowSelectWorkspace?: boolean
}

const WorkspaceProjectContent = ({
  close,
  onSelect,
  selectedWorkspaceId,
  selectedProjectId,
  hideProjectsFromDropdown,
  hideNoProject,
  disallowSelectWorkspace,
}: WorkspaceProjectContentProps) => {
  if (hideProjectsFromDropdown) {
    return (
      <WorkspaceDropdownContent
        close={close}
        onChange={(workspace) => {
          onSelect(workspace.id, null)
        }}
        selectedWorkspaceId={selectedWorkspaceId}
      />
    )
  }

  return (
    <WorkspaceProjectDropdownContent
      close={close}
      onSelect={onSelect}
      selectedProjectId={selectedProjectId}
      hideNoProject={hideNoProject}
      disallowSelectWorkspace={disallowSelectWorkspace}
    />
  )
}

export type WorkspaceProjectDropdownContentProps = Omit<
  WorkspaceProjectContentProps,
  'selectedWorkspaceId' | 'hideProjectsFromDropdown'
>

export const WorkspaceProjectDropdownContent = ({
  close,
  onSelect,
  selectedProjectId,
  hideNoProject = false,
  disallowSelectWorkspace = false,
}: WorkspaceProjectDropdownContentProps) => {
  const { data: sortedWorkspaces } = useOrderedWorkspaceProjectList({
    omitStatusFilter: true,
  })
  const sections = useWorkspaceProjectSections(sortedWorkspaces, {
    hideNoProject,
  })

  return (
    <SearchableList
      itemType='sectioned-select'
      sections={sections}
      expandMode={disallowSelectWorkspace ? 'full-width' : 'icon-only'}
      onHeaderClick={
        disallowSelectWorkspace
          ? undefined
          : (section) => {
              const workspaceId = section.items[0]?.workspaceId
              if (workspaceId) {
                void onSelect(workspaceId, null)
                close()
              }
            }
      }
      hideEmptySections
      computeKey={(item) => item.id ?? ''}
      computeSelected={(item) => item.id === selectedProjectId}
      filter={(search, section) => {
        if (!search.trim()) {
          return section.items
        }

        const workspaceNameScore = computeSearchScore(section.label, search)
        return section.items
          .reduce<
            {
              score: number
              item: ProjectSchema
            }[]
          >((acc, item) => {
            const score =
              workspaceNameScore > 0 && isNoneId(item.id)
                ? workspaceNameScore
                : computeSearchScore(item.name, search)

            if (score > 0) {
              acc.push({ score, item })
            }

            return acc
          }, [])
          .sort(byProperty('score', Compare.numeric.desc))
          .map((s) => s.item)
      }}
      onSelect={(item) => {
        const newWorkspaceId = item.workspaceId
        const newProjectId = isNoneId(item.id) ? null : (item.id ?? null)

        if (!newWorkspaceId) return

        onSelect(newWorkspaceId, newProjectId)
        close()
      }}
      renderItem={(item) => (
        <div className='max-w-xs'>
          <ProjectLabel value={item} />
        </div>
      )}
      inputProps={{ placeholder: 'Choose workspace or project...' }}
    />
  )
}
