import { TextField } from '@motion/ui/forms'
import { Sentry } from '@motion/web-base/sentry'

import { NAME_FIELD_RULE } from '~/global/rules'
import React, { useEffect } from 'react'
import { useFieldArray } from 'react-hook-form'

import { useSetupProjectForm } from '../../../hooks'

export type ControlledTextReplacementFieldProps = {
  groupName: string
  groupIndices: number[]
}
export const ControlledTextReplacementField = ({
  groupName,
  groupIndices,
}: ControlledTextReplacementFieldProps) => {
  const { form } = useSetupProjectForm()

  const { control, watch } = form

  const { fields: textReplacementFields, update } = useFieldArray({
    name: 'textReplacements',
    control,
    rules: NAME_FIELD_RULE,
  })

  useEffect(() => {
    if (groupIndices.length === 0) {
      Sentry.captureException('No text replacement fields found', {
        tags: {
          position: 'setup-project-modal-text-replacements',
          groupName,
          projectDefinition: JSON.stringify(watch('projectDefinition')),
        },
      })
    }
  }, [groupIndices, groupName, watch])

  const handleUpdate = (value: string) => {
    groupIndices.forEach((idx) => {
      const field = textReplacementFields[idx]
      if (field == null) return

      update(idx, {
        stageId: field.stageId,
        variableId: field.variableId,
        value,
      })
    })
  }

  if (groupIndices.length === 0) {
    return null
  }

  const selectedTextReplacement = textReplacementFields[groupIndices[0]]

  if (selectedTextReplacement == null) {
    return null
  }

  return (
    <div className='w-[300px]'>
      <TextField
        label={groupName}
        value={selectedTextReplacement.value ?? ''}
        onChange={handleUpdate}
      />
    </div>
  )
}
