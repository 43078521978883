import {
  LinkTransformPlugin,
  MentionsPlugin,
  type MentionsPluginProps,
  shouldTransformMentionLink,
  transformMentionLink,
} from '@motion/notes'
import { createLookupByKey } from '@motion/utils/object'

import { useMemo, useState } from 'react'

import { useReferenceSearch } from './hooks'
import { type Reference } from './types'
import { isValidGenericMeta } from './utils'

import {
  useMentionSearch,
  WebMentionOption,
  WebMentionsNode,
} from '../web-mentions-plugin'

export type ReferencesPluginProps = {
  onMentionCreated?: MentionsPluginProps['onMentionCreated']
  onMentionDeleted?: MentionsPluginProps['onMentionDeleted']
  excludeMotionMentions?: boolean
  references?: Reference[]
}

export function ReferencesPlugin({
  onMentionCreated,
  onMentionDeleted,
  excludeMotionMentions = false,
  references,
}: ReferencesPluginProps) {
  const [query, setQuery] = useState('')

  const { items: motionObjectItems } = useMentionSearch(query, {
    enabled: !excludeMotionMentions,
  })
  const { items: mentionItems } = useReferenceSearch(query, references)

  const resultItems = useMemo(() => {
    return [...motionObjectItems, ...mentionItems]
  }, [mentionItems, motionObjectItems])

  const renderOptionLookup = useMemo(() => {
    return createLookupByKey(
      references ?? [],
      'type',
      (v) => v.renderOptionItem
    )
  }, [references])

  return (
    <>
      <MentionsPlugin
        query={query}
        setQuery={setQuery}
        results={resultItems}
        renderMentionOption={(option) => {
          if (isValidGenericMeta(option.meta)) {
            const { type, data } = option.meta
            const renderOptionFn = renderOptionLookup[type]
            if (renderOptionFn != null) {
              return renderOptionFn(data)
            }
          }

          if (!excludeMotionMentions) {
            return <WebMentionOption option={option} />
          }

          return <span>{option.title}</span>
        }}
        onMentionCreated={onMentionCreated}
        onMentionDeleted={onMentionDeleted}
      />
      {!excludeMotionMentions && (
        <LinkTransformPlugin
          transformLink={(link, label) => transformMentionLink(link, label)}
          shouldTransformLink={shouldTransformMentionLink}
        />
      )}
    </>
  )
}

ReferencesPlugin.Node = WebMentionsNode
