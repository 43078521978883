import { PlusCircleSolid } from '@motion/icons'
import { GradientButton, PopoverTrigger } from '@motion/ui/base'
import { addTestId } from '@motion/ui/helpers'

import { CreateNewMenu } from './components/create-new-menu'

export const CreateNewButton = () => {
  return (
    <div className='w-full px-3' {...addTestId('CreateNewButton')}>
      <PopoverTrigger
        offset={{
          mainAxis: 5,
          crossAxis: -11,
        }}
        placement='right-start'
        renderPopover={({ close }) => <CreateNewMenu close={close} />}
      >
        <GradientButton size='small' sentiment='primary' fullWidth>
          <PlusCircleSolid className='!size-3' />
          New
        </GradientButton>
      </PopoverTrigger>
    </div>
  )
}
