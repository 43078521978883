import { QuestionMarkCircleSolid } from '@motion/icons'
import { type DeadlineType } from '@motion/rpc/types'
import { Toggle, Tooltip } from '@motion/ui/base'

import { ModalFieldButton } from '~/areas/task-project/components'

type HardDeadlineModalFieldButtonProps = {
  deadlineType: DeadlineType
  onChange: (deadlineType: 'HARD' | 'SOFT') => void
  disabled: boolean
}

const TOOLTIP_CONTENT =
  'If this is toggled on, Motion will prioritize this task and make sure it schedules before deadline; if needed, Motion may schedule it outside of work hours (or another selected schedule) in order to meet deadline.'

export function HardDeadlineModalFieldButton({
  deadlineType,
  onChange,
  disabled,
}: HardDeadlineModalFieldButtonProps) {
  return (
    <ModalFieldButton label='Hard deadline' size='normal' variant='subtle'>
      <Tooltip content={disabled ? undefined : TOOLTIP_CONTENT}>
        <QuestionMarkCircleSolid />
      </Tooltip>
      <Toggle
        checked={deadlineType === 'HARD'}
        disabled={disabled}
        size='small'
        onChange={() => {
          onChange(deadlineType === 'HARD' ? 'SOFT' : 'HARD')
        }}
      />
    </ModalFieldButton>
  )
}
