import { API } from '@motion/rpc-definitions'

import { useQueryClient } from '@tanstack/react-query'
import { useOnboardingApi } from '~/areas/onboarding/state/use-onboarding-api'
import { useCallback } from 'react'

/*
 * This hook is used to refetch scheduled entities and the users calendars when a new calendar is added.
 *
 */
export const useRefetchEventsOnAddCalendar = () => {
  const { selectIsOnboarding } = useOnboardingApi()
  const queryClient = useQueryClient()

  return useCallback(() => {
    // If the user is in onboarding, we don't want to refetch the events
    if (selectIsOnboarding()) return

    // Invalidate all scheduled entities (agenda, upcoming event, etc)
    queryClient.invalidateQueries({
      queryKey: API.scheduledEntities.queryKeys.root,
    })
  }, [queryClient, selectIsOnboarding])
}
