import { NowIndicator } from '@motion/ui/calendar'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'

import { type CalendarOptions, type EventMountArg } from '@fullcalendar/core'
import { useIsShiftKeyPressed } from '~/components/Calendar/components/Main/use-isshiftpressed'
import { useTimezoneSettings } from '~/global/hooks'
import { CALENDAR_LICENSE_KEY } from '~/storageConstants'
import { useMemo } from 'react'

import { useCalendarCommonHandlers } from './use-calendar-common-handlers'

import { CalendarDayHeader } from '../components'
import { SlotLabelContent } from '../main-calendar/slot-label-content'

type CalendarCommonOptions = {
  shouldShowSecondaryTimezone?: boolean
}

export const useCalendarCommonProps = (
  options: CalendarCommonOptions = READONLY_EMPTY_OBJECT
): CalendarOptions => {
  const { shouldShowSecondaryTimezone = true } = options

  const isShiftKeyPressed = useIsShiftKeyPressed()
  const commonHandlers = useCalendarCommonHandlers()
  const { secondaryTimezone } = useTimezoneSettings({
    useDefaults: true,
  })

  return useMemo(() => {
    return {
      allDayContent: () => '',
      allDaySlot: true,
      dragScroll: false,
      editable: true,
      eventDurationEditable: true,
      eventResizableFromStart: true,
      eventResourceEditable: false,
      selectMinDistance: 2,
      eventTimeFormat: {
        hour: 'numeric',
        meridiem: false,
        minute: '2-digit',
        omitZeroMinute: true,
      },
      headerToolbar: false,
      height: '100%',
      nowIndicator: true,
      schedulerLicenseKey: CALENDAR_LICENSE_KEY,
      // When the date range changes, don't reset the current scroll time
      // https://fullcalendar.io/docs/scrollTimeReset
      scrollTimeReset: false,
      selectMirror: true,
      selectable: true,
      slotDuration: '00:15',
      slotLabelInterval: '01:00',
      slotEventOverlap: false,
      snapDuration: isShiftKeyPressed ? '00:01' : '00:15',
      eventClassNames: ['group/calendar-event'],
      eventDidMount: (arg: EventMountArg) => {
        arg.el.dataset.eventId = arg.event.id
      },
      slotLabelContent: (info) => (
        <SlotLabelContent
          info={info}
          secondaryTimezone={
            shouldShowSecondaryTimezone ? secondaryTimezone : undefined
          }
        />
      ),
      nowIndicatorContent: ({ isAxis }) => {
        if (!isAxis) {
          return <NowIndicator />
        }
      },
      dayHeaderContent: (info) => <CalendarDayHeader info={info} />,
      dayHeaderClassNames: 'h-10',

      eventClick: commonHandlers.onEventClick,
      eventDrop: commonHandlers.onDrop,
      eventDragStart: commonHandlers.onDragStart,
      eventDragStop: commonHandlers.onDragStop,
      eventResize: commonHandlers.onResize,
    }
  }, [
    commonHandlers.onDragStart,
    commonHandlers.onDragStop,
    commonHandlers.onDrop,
    commonHandlers.onEventClick,
    commonHandlers.onResize,
    isShiftKeyPressed,
    secondaryTimezone,
    shouldShowSecondaryTimezone,
  ])
}
