import { type BookingLinkTemplate } from '@motion/rpc/types'
import { ActionModal, ButtonTabs, DatePicker, showToast } from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'
import { formatDurationTime } from '@motion/ui-logic'
import { createBookingMessageString } from '@motion/ui-logic/booking'
import { NOOP_FUNCTION } from '@motion/utils/function'
import { logEvent } from '@motion/web-base/analytics'
import { getWindowData } from '@motion/web-base/env'
import { logInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'

import { type OpenTimezoneModalProps } from '~/areas/calendar/components/calendar-header/timezone-group/types'
import type { ModalTriggerComponentProps } from '~/areas/modals'
import { useTimezoneSettings } from '~/global/hooks'
import { createTemplateChildUrl } from '~/utils/booking-utils'
import { getTzAbbr } from '~/utils/time'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Events } from '../../../analyticsEvents'
import { useCalendarStartDay } from '../../../areas/calendar/hooks'
import {
  copyAvailabilitiesHTMLManual,
  copyAvailabilitiesManual,
  createTemplateChildLink,
  getBookingLinkById,
  selectAvailabilityMessageTemplate,
  selectBookingTemplates,
  selectRichAvailabilityMessage,
  updateRichAvailabilityMessageSetting,
  updateTemplateChildLink,
} from '../../../state/booking'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import {
  selectAvailabilityTimezone,
  setAvailabilityTimezone,
} from '../../../state/timezone-slice'
import { defaultAvailabilityMessageTemplate } from '../../../storageConstants'
import {
  type ConflictCalendar,
  type CreateTemplateChildLinkDto,
  type UpdateTemplateChildLinkDto,
} from '../../../types/bookingTypes'
import { AvailabilityMessage } from '../../AvailabilityMessage/AvailabilityMessage'
import { Combobox, Paragraph } from '../../Common'
import { DefaultSelectTrigger } from '../../Common/Select/Select'
import { ContactsAutocomplete } from '../../contacts-autocomplete'

const placeholderLink = 'usemotion.com/your-booking-link'

type DateTypeOption = 'multiple' | 'range'

const startsInToDate = (startsIn: string | null) => {
  const incrementMap = {
    today: 0,
    tomorrow: 1,
    'day after tomorrow': 2,
    'in 2 days': 2,
    'in 7 days': 7,
    'in 14 days': 14,
    'in 30 days': 30,
    'in 60 days': 60,
  }
  if (
    startsIn &&
    Object.prototype.hasOwnProperty.call(incrementMap, startsIn)
  ) {
    // @ts-expect-error startsIn is a key in incrementMap
    const dayIncrement = incrementMap[startsIn]
    return DateTime.now().startOf('day').plus({ days: dayIncrement }).toISO()
  } else if (startsIn === 'next week') {
    return DateTime.now().startOf('week').plus({ week: 1 }).toISO()
  } else if (startsIn === 'next month') {
    return DateTime.now().startOf('month').plus({ month: 1 }).toISO()
  } else if (startsIn === 'in 2 months') {
    return DateTime.now().startOf('month').plus({ month: 2 }).toISO()
  }
  return DateTime.now().startOf('day').toISO()
}

export interface BookingOneOffModalProps {
  templateId: string
  openTimezoneModal: (props: OpenTimezoneModalProps) => void
  selectedChoice?: number
}

export const BookingOneOffModal = ({
  templateId,
  openTimezoneModal = NOOP_FUNCTION,
  selectedChoice,
  close,
}: ModalTriggerComponentProps<'booking-oneoff'>) => {
  const dispatch = useAppDispatch()

  const bookingLinkTemplates = useAppSelector(selectBookingTemplates)
  const storeRichAvailabilityMessage = useAppSelector(
    selectRichAvailabilityMessage
  )
  const availabilityMessageTemplate = useAppSelector(
    selectAvailabilityMessageTemplate
  )
  const { defaultTimezone } = useTimezoneSettings({ useDefaults: true })
  const calendarStartDay = useCalendarStartDay()

  const availabilityTimezone = useAppSelector(selectAvailabilityTimezone)
  const [multiUse] = useState(false) // disabling setter for linting until we fix this feature
  const [duration, setDuration] = useState<number | undefined>(undefined)
  const [durationChoices, setDurationChoices] = useState([30])
  const [submitLoading, setSubmitLoading] = useState(false)
  const [availabilityText, setAvailabilityText] = useState('')
  const [templateSettings, setTemplateSettings] =
    useState<BookingLinkTemplate>()
  const [datesMode, setDatesMode] = useState<DateTypeOption>('range') // range, dates
  const [dateRange, setDateRange] = useState<{ start: string; end: string }>()
  const [dateList, setDateList] = useState<string[]>([])
  const [availabilityHtmlText, setAvailabilityHtmlText] = useState('')
  const [displayLinksText, setDisplayLinksText] = useState('')
  const [displayText, setDisplayText] = useState('')
  const [hasValidSlots, setHasValidSlots] = useState(false)
  const [guests, setGuests] = useState<string[]>([])
  const [conflictCalendars, setConflictCalendars] = useState<
    ConflictCalendar[]
  >([])
  const [calIdToAccount, setCalIdToAccount] = useState<{
    [key: string]: string
  }>({})
  const [messageTemplate] = useState(
    availabilityMessageTemplate || defaultAvailabilityMessageTemplate
  )
  const [linkId, setLinkId] = useState<string | undefined>(undefined)
  const [linkSlug, setLinkSlug] = useState<string | undefined>(undefined)
  const initRef = useRef(false)
  const frameRef = useRef<HTMLDivElement | null>(null)
  const recalculateRowsPendingRef = useRef(false)
  const shouldCreateTemplateChild = useRef(false)
  const [richAvailabilityMessage, setRichAvailabilityMessage] = useState(
    storeRichAvailabilityMessage
  )

  const { isMac } = getWindowData()

  const initDates = useCallback(
    (template?: BookingLinkTemplate) => {
      if (!template) return
      const start = startsInToDate(template.startsIn)
      const end = DateTime.fromISO(start)
        .plus({
          day: (template.daysSpan ?? 0) - 1,
        })
        .toISO()

      setDateRange({ end, start })
      setDateList([])
    },
    [setDateRange, setDateList]
  )

  const initOneOff = useCallback(async () => {
    const template = bookingLinkTemplates[templateId]
    setTemplateSettings(template || {})
    initDates(template)
    if (template.durationChoices && template.durationChoices.length) {
      setDurationChoices(template.durationChoices)
      setDuration(selectedChoice || template.durationChoices[0])
    }
    shouldCreateTemplateChild.current = true
  }, [bookingLinkTemplates, templateId, selectedChoice, initDates])

  // Whenever the dates change, we want to refetchTemplateAvailability to recompute the slots
  const refetchTemplateAvailability = useCallback(async () => {
    // Don't refetch if state isn't initialized yet
    if (!templateSettings || !duration) {
      return
    }
    const timezone =
      availabilityTimezone === 'default'
        ? defaultTimezone
        : availabilityTimezone

    if (!linkId) {
      throw new Error('linkId is not defined')
    }

    const updateTemplateChildLinkData: UpdateTemplateChildLinkDto = {
      linkId,
      conflictCalendars: conflictCalendars as ConflictCalendar[],
      guestEmails: guests,
      durationChoice: duration,
    }

    if (datesMode === 'range' && dateRange) {
      updateTemplateChildLinkData.dateRangeStart = DateTime.fromISO(
        dateRange.start
      ).toFormat('MM/dd/yy')
      updateTemplateChildLinkData.dateRangeEnd = DateTime.fromISO(
        dateRange.end
      ).toFormat('MM/dd/yy')
      updateTemplateChildLinkData.dateList = []
    } else {
      updateTemplateChildLinkData.dateList = dateList.map((date) =>
        DateTime.fromISO(date).toFormat('MM/dd/yy')
      )
      updateTemplateChildLinkData.dateRangeStart = undefined
      updateTemplateChildLinkData.dateRangeEnd = undefined
    }

    await dispatch(updateTemplateChildLink(updateTemplateChildLinkData))

    const bookingLinkWithSlots = await dispatch(
      getBookingLinkById({
        linkId,
        returnRanges: true,
        messageTimezone: timezone,
      })
    )

    setLinkSlug(bookingLinkWithSlots.payload.linkSlug)
    const newLink = createTemplateChildUrl(
      bookingLinkWithSlots.payload.linkSlug
    )

    const {
      copyablePlainText,
      copyableHtmlText,
      displayablePlainText,
      displayableTextWithLinks,
    } = createBookingMessageString(messageTemplate, {
      ranges: bookingLinkWithSlots.payload.messageRanges,
      timezone: timezone ?? DateTime.local().zoneName,
      timezoneAbbr: getTzAbbr(timezone) ?? 'PST',
      bookingLinkUrl: newLink,
      durationString: formatDurationTime(duration),
    })
    setAvailabilityHtmlText(copyableHtmlText)
    setDisplayLinksText(displayableTextWithLinks)
    setDisplayText(displayablePlainText)
    setAvailabilityText(copyablePlainText)
    setHasValidSlots(bookingLinkWithSlots.payload.messageRanges.length > 0)
  }, [
    templateSettings,
    duration,
    availabilityTimezone,
    defaultTimezone,
    linkId,
    conflictCalendars,
    guests,
    datesMode,
    dispatch,
    messageTemplate,
    dateRange,
    dateList,
  ])

  useEffect(() => {
    if (
      (datesMode === 'range' && dateRange?.start && dateRange?.end) ||
      (datesMode === 'multiple' && dateList.length)
    ) {
      recalculateRowsPendingRef.current = true
    }
  }, [datesMode, dateList, dateRange])

  useEffect(() => {
    recalculateRowsPendingRef.current = true
  }, [conflictCalendars, availabilityTimezone])

  useEffect(() => {
    if (linkId && recalculateRowsPendingRef.current) {
      recalculateRowsPendingRef.current = false
      void refetchTemplateAvailability()
    }
  }, [linkId, refetchTemplateAvailability])

  const createTemplateChild = useCallback(async () => {
    const generateLink = async () => {
      if (submitLoading || !duration || !templateSettings) {
        return
      }
      setSubmitLoading(true)

      try {
        const childObj: CreateTemplateChildLinkDto = {
          conflictCalendars: conflictCalendars,
          durationChoice: duration,
          guestEmails: guests,
          templateId,
        }
        if (datesMode === 'range' && dateRange) {
          childObj.dateRangeStart = DateTime.fromISO(dateRange?.start).toFormat(
            'MM/dd/yy'
          )
          childObj.dateRangeEnd = DateTime.fromISO(dateRange?.end).toFormat(
            'MM/dd/yy'
          )
        } else if (dateList?.length) {
          childObj.dateList = dateList.map((date) =>
            DateTime.fromISO(date).toFormat('MM/dd/yy')
          )
        } else {
          return
        }
        const { linkId, linkSlug } = await dispatch(
          createTemplateChildLink(childObj)
        ).unwrap()
        setLinkId(linkId)
        setLinkSlug(linkSlug)
        return linkSlug
      } finally {
        recalculateRowsPendingRef.current = true
        setSubmitLoading(false)
      }
    }

    await generateLink()
  }, [
    submitLoading,
    duration,
    templateSettings,
    conflictCalendars,
    guests,
    templateId,
    datesMode,
    dispatch,
    dateRange,
    dateList,
  ])

  // We only create the template child once the state is fully initialized
  useEffect(() => {
    if (shouldCreateTemplateChild.current && duration && templateSettings) {
      shouldCreateTemplateChild.current = false
      void createTemplateChild()
    }
  }, [createTemplateChild, duration, templateSettings])

  useEffect(() => {
    if (!initRef.current) {
      initRef.current = true
      void initOneOff()
    }
  }, [initOneOff])

  /* eslint-disable react-hooks/exhaustive-deps */
  const copyHandler = useCallback(
    async (linkOnly = false) => {
      try {
        if (submitLoading) {
          return
        } else if (!linkSlug) {
          throw new Error('linkSlug is not defined')
        }
        setSubmitLoading(true)
        const newLink = createTemplateChildUrl(linkSlug)
        const messageWithLink = availabilityText
          .split(placeholderLink)
          .join(newLink)

        if (linkOnly) {
          dispatch(copyAvailabilitiesManual(newLink))
        } else if (richAvailabilityMessage) {
          const htmlMessageWithLink = availabilityHtmlText
            .split(placeholderLink)
            .join(newLink)
          dispatch(
            copyAvailabilitiesHTMLManual({
              richText: htmlMessageWithLink,
              text: messageWithLink,
            })
          )
        } else {
          dispatch(copyAvailabilitiesManual(messageWithLink))
        }
        showToast('neutral', 'Availabilities copied to your clipboard')

        close()
        void logEvent(Events.CALENDAR_AVAILABILITIES_ONEOFF_COPY)
      } catch (e) {
        // TODO: Figure out how to best show this error
        // setError('got an error')
        logInDev('error copying availabilities', e)
        Sentry.captureException(e, { tags: { position: 'copyHandler' } })
      } finally {
        setSubmitLoading(false)
      }
    },
    [
      submitLoading,
      linkId,
      availabilityText,
      templateSettings?.name,
      templateId,
      multiUse,
      copyAvailabilitiesManual,
      richAvailabilityMessage,
      availabilityHtmlText,
      dispatch,
      copyAvailabilitiesHTMLManual,
      copyAvailabilitiesManual,
    ]
  )
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    const frameKeyHandler = (e: any) => {
      if (e.key === 'c' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        e.stopPropagation()
        void copyHandler()
      }
    }
    document && document.addEventListener('keydown', frameKeyHandler, true)
    return () => {
      document && document.removeEventListener('keydown', frameKeyHandler, true)
    }
  }, [copyHandler])

  /*
    date is a list of ISO strings
  */
  const selectionCalendarHandler = useCallback(
    (dates: string | string[] | null) => {
      if (!dates || !Array.isArray(dates)) return
      if (datesMode === 'range') {
        setDateRange({
          end: dates[1],
          start: dates[0],
        })
      } else {
        // dates mode
        setDateList(dates)
      }
    },
    [datesMode]
  )

  const guestsSelectHandler = useCallback(
    async (values: string[], options: { [key: string]: any }[] | undefined) => {
      const newValues = (options ?? [])
        .filter((option) => !!option)
        .map((option) => {
          if (option.email && option.account) {
            setCalIdToAccount((prev) => {
              const newMap: { [key: string]: string } = { ...prev }
              newMap[option.email] = option.account
              return newMap
            })
          }
          return {
            calendarId: option.email,
            email:
              option.account ||
              (option.email ? calIdToAccount[option.email] : ''),
            inCalendarList: false,
            title: option.email,
          }
        })
      setGuests(values)
      setConflictCalendars(newValues)
      // conflictsPendingRef.current = true
      void logEvent(Events.CALENDAR_AVAILABILITIES_ONEOFF_TEAM_SELECT)
    },
    [calIdToAccount]
  )

  const defaultTimezoneAbbrev = getTzAbbr(defaultTimezone)
  const availabilityTimezoneAbbrev = getTzAbbr(availabilityTimezone)
  const availabiltyTimezoneIsDefault =
    availabilityTimezoneAbbrev === defaultTimezoneAbbrev

  return (
    <ActionModal
      visible
      onClose={() => close()}
      title='Generate message'
      actions={[
        {
          label: 'Copy link',
          onAction: () => copyHandler(true),
          sentiment: 'neutral',
          disabled: !hasValidSlots,
        },
        {
          label: 'Copy message',
          onAction: () => copyHandler(false),
          sentiment: 'primary',
          shortcut: 'mod+c',
          disabled: !hasValidSlots,
          loading: submitLoading,
        },
      ]}
    >
      <div
        className={twMerge(
          'flex w-full flex-col py-4',
          isMac && 'visible-scrollbar-mac'
        )}
        ref={frameRef}
      >
        <div className='flex flex-col gap-2'>
          <Paragraph className='font-semibold'>
            {' '}
            Add teammate to meeting (also check for conflicts)
          </Paragraph>
          <ContactsAutocomplete
            teamValues={guests}
            contactsSource='team'
            teamSelectHandler={guestsSelectHandler}
            placeholder='Enter email'
          />
        </div>

        <div className='mt-4 flex w-full gap-4'>
          <div className='flex w-full flex-col gap-2'>
            <Paragraph className='font-semibold'>
              Availability Parameters
            </Paragraph>
            <ButtonTabs
              size='small'
              fullWidth
              activeValue={datesMode}
              items={[
                {
                  content: 'Select date range',
                  value: 'range',
                  onAction: () => {
                    setDatesMode('range')
                    initDates(templateSettings)
                  },
                },
                {
                  content: 'Select dates',
                  value: 'multiple',
                  onAction: () => {
                    setDatesMode('multiple')
                  },
                },
              ]}
            />
          </div>
          <div className='flex w-full flex-col gap-2'>
            <Paragraph className='font-semibold'>Display message in</Paragraph>
            <div className='flex w-full gap-3'>
              <DefaultSelectTrigger
                selectedLabel={
                  availabiltyTimezoneIsDefault
                    ? `Default timezone (${defaultTimezoneAbbrev})`
                    : availabilityTimezoneAbbrev
                }
                onClick={() => {
                  openTimezoneModal({
                    changeHandler: (value) => {
                      dispatch(setAvailabilityTimezone(value))
                      recalculateRowsPendingRef.current = true
                    },
                  })
                }}
              />
            </div>
          </div>
        </div>

        <div className='mt-3 flex w-full gap-4'>
          <div className='flex w-full flex-col gap-2'>
            <DatePicker
              weekStartDay={calendarStartDay}
              value={
                datesMode === 'range' && dateRange
                  ? [dateRange.start, dateRange.end]
                  : dateList
              }
              mode={datesMode}
              onChange={selectionCalendarHandler}
            />
          </div>
          <div className='flex w-full flex-col gap-2'>
            <div className='flex items-center gap-2'>
              <Paragraph className='font-semibold'>
                {durationChoices.length > 1 ? 'Duration' : 'Message'}
              </Paragraph>
              {durationChoices.length > 1 && (
                <Combobox
                  autoComplete
                  value={duration}
                  onChange={(value: number) => {
                    setDuration(value)
                    recalculateRowsPendingRef.current = true
                  }}
                  options={durationChoices.map((time) => ({
                    label: formatDurationTime(time),
                    value: time,
                  }))}
                />
              )}
            </div>
            <AvailabilityMessage
              richLinks={richAvailabilityMessage}
              message={richAvailabilityMessage ? displayLinksText : displayText}
            />
            <div className='flex gap-2'>
              <Checkbox
                checked={richAvailabilityMessage}
                onChange={(checked) => {
                  void dispatch(updateRichAvailabilityMessageSetting(!!checked))
                  void logEvent(Events.RICH_AVAILABILITIES_TOGGLE, {
                    checked,
                  })
                  setRichAvailabilityMessage(!!checked)
                }}
                label='Clickable time slots'
              />
            </div>
          </div>
        </div>
      </div>
    </ActionModal>
  )
}
