import { classed } from '@motion/theme'
import { getDisplayableLink } from '@motion/ui-logic'

// TODO: remove this and use the helper in @motion/utils/string once
// frontend stops prepending https:// to invalid urls
// eslint-disable-next-line import-x/no-restricted-paths
import { canParseCustomFieldUrlValue } from '~/areas/custom-fields/utils'

export function UrlLabel({ value }: { value: string }) {
  return canParseCustomFieldUrlValue(value) ? (
    <a
      href={value}
      target='_blank'
      rel='noreferrer'
      className='hover:text-semantic-primary-text-default'
    >
      {getDisplayableLink(value)}
    </a>
  ) : (
    <Truncate>{value}</Truncate>
  )
}

export const Truncate = classed('div', 'truncate text-sm font-medium')
