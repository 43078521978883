import { usePrevious } from '@motion/react-core/hooks'
import {
  convertFormFieldsToTask,
  getTaskFormChangedFields,
  isChangedFieldName,
  type TaskFormFields,
  type TaskUrlSearchParams,
} from '@motion/ui-logic/pm/task'
import { keys } from '@motion/utils/object'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useMyUserSettings } from '@motion/web-common/settings'
import {
  type RecurringTaskSchema,
  type TasksV2NormalUpdateSchema,
  type TasksV2RecurringTaskUpdateSchema,
} from '@motion/zod/client'

import { useCustomFieldsFns } from '~/areas/custom-fields/hooks'
import { useAutosaveContext } from '~/areas/task-project/contexts'
import { useWorkspaceFns } from '~/global/hooks'
import { useSearchParams } from '~/routing'
import { type ReactNode, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useTaskModalState } from './contexts'
import { useTaskForm } from './hooks'

import { useTaskUpdater } from '../../hooks'

export type TaskFormProps = {
  children: ReactNode
  initialFormData: TaskFormFields
}

export const TaskForm = ({ children, initialFormData }: TaskFormProps) => {
  const { resetLastSavedTime } = useAutosaveContext()
  const { task: taskUrlParam, forTaskId } =
    useSearchParams<TaskUrlSearchParams>()

  const form = useForm({
    defaultValues: initialFormData,
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
  })

  const { isDirty } = form.formState

  const isDuplicateTask = taskUrlParam === 'new' && forTaskId != null
  const previousTaskUrlParam = usePrevious(taskUrlParam)
  useEffect(() => {
    // the url param doesn't exist when closing the modal,
    // so let's keep the previous known state during the full closing animation
    if (taskUrlParam == null) return

    if (!isDirty || previousTaskUrlParam !== taskUrlParam) {
      form.reset(initialFormData, { keepDefaultValues: isDuplicateTask })
    }
  }, [
    form,
    initialFormData,
    isDirty,
    isDuplicateTask,
    previousTaskUrlParam,
    taskUrlParam,
  ])

  useEffect(() => {
    if (previousTaskUrlParam !== taskUrlParam) {
      resetLastSavedTime()
    }
  }, [previousTaskUrlParam, resetLastSavedTime, taskUrlParam])

  return (
    <FormProvider {...form}>
      <TaskFormValuesUpdater />
      {children}
    </FormProvider>
  )
}

const TaskFormValuesUpdater = () => {
  const { uid: currentUserId } = useAuthenticatedUser()
  const { form } = useTaskForm()
  const {
    options: { isAutoSaving },
  } = useTaskModalState()
  const {
    getWorkspaceStatuses,
    getWorkspaceActiveMembers,
    getWorkspaceProjects,
  } = useWorkspaceFns()
  const { getCustomFields } = useCustomFieldsFns()
  const updateTask = useTaskUpdater()

  const { data: userSettings } = useMyUserSettings(undefined, {
    meta: { source: 'TaskFormValuesUpdater' },
  })
  const userDefinedTaskDefaults = userSettings?.taskDefaultSettings

  // Listen for the form and apply any derived values needed
  useEffect(() => {
    const subscription = form.watch((formValues, info) => {
      const name = info.name as keyof typeof formValues | undefined

      if (name == null || name === 'id') return

      // Custom fields are handled separately in areas/tasks/modals/task-modal/fields/custom-fields/custom-fields-sidebar-section.tsx
      if (name === 'customFieldValuesFieldArray') return
      // Description is handled separately in fields/description-field.tsx
      // because of race condition when navigating to another task.
      // See https://usemotion.slack.com/archives/CKP2K3JBS/p1743515672189229
      if (name === 'description') return
      // Uploaded files aren't updated through the task
      if (name === 'uploadedFiles') return
      // blockers are always autosave in their own component
      if (name === 'blockingTaskIds' || name === 'blockedByTaskIds') return

      // random fields that shouldn't be in the form
      if (
        name === 'completedTime' ||
        name === 'isUnvisitedStage' ||
        name === 'scheduleMeetingWithinDays' ||
        name === 'meetingTaskId'
      )
        return

      const { workspaceId, id: taskId } = formValues
      let derivedFields: ReturnType<typeof getTaskFormChangedFields> = {}

      if (workspaceId == null) {
        throw new Error('Workspace id must be defined')
      }

      if (isChangedFieldName(name)) {
        const taskCandidate = convertFormFieldsToTask(
          formValues as TaskFormFields
        )

        derivedFields = getTaskFormChangedFields(taskCandidate, {
          fieldNameBeingUpdated: name,
          currentUserId,
          statuses: getWorkspaceStatuses(workspaceId),
          projects: getWorkspaceProjects(workspaceId),
          members: getWorkspaceActiveMembers(workspaceId),
          customFields: getCustomFields(workspaceId),
          globalTaskDefaults: userDefinedTaskDefaults?.global,
        })
      }

      if (isAutoSaving && taskId != null) {
        // reset dirty state set by the field that the user changed
        // Because in autosave, we cannot have a dirty form state
        // otherwise we stop receiving task updates from the socket for example
        form.reset({}, { keepValues: true })

        let propName:
          | keyof TasksV2NormalUpdateSchema
          | keyof TasksV2RecurringTaskUpdateSchema = name
        if (formValues.type === 'RECURRING_TASK' && name === 'startDate') {
          propName = 'startingOn'
        }

        // frequency, recurringDays and recurringMeta are always updated all 3 at once, so we skip 2 of them for the autosave
        // and we force saving all 3 at once
        if (name !== 'recurrenceMeta' && name !== 'days') {
          const otherFields =
            name === 'frequency'
              ? {
                  recurrenceMeta: formValues['recurrenceMeta'],
                  days: formValues['days'],
                }
              : undefined

          updateTask(taskId, {
            [propName]: formValues[name],
            ...otherFields,
            ...derivedFields,
          })
        }
      } else {
        keys(derivedFields).forEach((key) => {
          const formFieldKey = taskFieldsToFormField[key] ?? key

          if (!(formFieldKey in formValues)) {
            throw new Error(
              `cannot set value of unknown key "${formFieldKey}" in form`
            )
          }

          return form.setValue(formFieldKey, derivedFields[key], {
            shouldDirty: true,
          })
        })
      }
    })
    return () => subscription.unsubscribe()
  }, [
    currentUserId,
    form,
    getWorkspaceActiveMembers,
    getCustomFields,
    getWorkspaceProjects,
    getWorkspaceStatuses,
    userDefinedTaskDefaults,
    isAutoSaving,
    updateTask,
  ])

  return null
}

const taskFieldsToFormField: Partial<
  Record<keyof RecurringTaskSchema, keyof TaskFormFields>
> = {
  startingOn: 'startDate',
}
