import { AiActionItemSolid, StatusCanceledSolid } from '@motion/icons'

import { useTaskUpdater } from '~/areas/tasks/hooks'
import { StatusBadge } from '~/global/components/badges'
import { StatusDropdown } from '~/global/components/dropdowns'
import { useWorkspaceStatusById } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { useActionItemForm } from '../hooks'
import { useActionItemContext } from '../provider'

export function ActionItemStatus() {
  const { isRejected, task } = useActionItemContext()
  const { form } = useActionItemForm()

  const { field: statusField } = useController({
    name: 'statusId',
    control: form.control,
  })
  const updateTask = useTaskUpdater()
  const selectedStatus = useWorkspaceStatusById(statusField.value)

  const handleStatusChange = async (statusId: string) => {
    statusField.onChange(statusId)

    if (task != null) {
      await updateTask(task.id, { statusId })
    }
  }

  if (isRejected) {
    return (
      <StatusCanceledSolid className='size-3 text-semantic-neutral-icon-default' />
    )
  }

  if (task != null) {
    if (selectedStatus == null) {
      throw new Error('selectedStatus is null')
    }

    return (
      <StatusDropdown
        selectedStatusId={selectedStatus.id}
        onChange={handleStatusChange}
        workspaceId={task.workspaceId}
      >
        <button type='button' className='shrink-0'>
          <StatusBadge value={selectedStatus} size='xsmall' />
        </button>
      </StatusDropdown>
    )
  }

  return (
    <AiActionItemSolid className='size-3 text-semantic-purple-icon-default' />
  )
}
