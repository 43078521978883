import { type COLOR, getRandomColor } from '@motion/shared/common'
import {
  createStableFlowKey,
  SYSTEM_FLOW_VARIABLES,
  type VariableType,
} from '@motion/shared/flows'
import { createPlaceholderId } from '@motion/shared/identifiers'
import {
  type FlowTemplateFormFields,
  type RoleVariable,
  type TextVariable,
} from '@motion/ui-logic/pm/project'
import { uniqueId } from '@motion/utils/core'

export const createNewRole = ({
  name = '',
  color = getRandomColor(),
  key = createStableFlowKey(),
}: {
  name?: string
  color?: COLOR
  key?: string
}): RoleVariable => ({
  id: createPlaceholderId(uniqueId()),
  key,
  type: 'person',
  color,
  name,
})

export const createNewTextVariable = ({
  name,
  key = createStableFlowKey(),
}: {
  name: string
  key?: string
}): TextVariable => ({
  id: createPlaceholderId(uniqueId()),
  key,
  type: 'text',
  color: 'blue',
  name,
})

export const DEFAULT_TEXT_VARIABLES: FlowTemplateFormFields['textVariables'] =
  Array.from(SYSTEM_FLOW_VARIABLES.entries()).map(([name, key]) =>
    createNewTextVariable({ name, key })
  )

export const DEFAULT_TEXT_VARIABLE_KEYS = DEFAULT_TEXT_VARIABLES.map(
  (variable) =>
    variable.key as typeof SYSTEM_FLOW_VARIABLES extends Map<any, infer V>
      ? V
      : never
)

export function wrapVariableWithStageDefinitionId<
  T extends Omit<RoleVariable | TextVariable, 'stageDefinitionId'>,
>(variable: T, stageDefinitionId: string): T & { stageDefinitionId: string } {
  return { ...variable, stageDefinitionId }
}

export function getNewVariableName(
  variables: TextVariable[] | RoleVariable[],
  type: VariableType
): string {
  let index = variables.length + 1
  let name = `${type === 'text' ? 'Variable' : 'Role'} ${index}`

  // Keep incrementing index until we find a unique name
  while (variables.some((v) => v.name === name)) {
    index++
    name = `${type === 'text' ? 'Variable' : 'Role'} ${index}`
  }

  return name
}
