import { classed } from '@motion/theme'

import { type ExpandedStateList, type Row } from '@tanstack/react-table'
import {
  type Range,
  type VirtualItem,
  type Virtualizer,
} from '@tanstack/react-virtual'
import { useIsFlowsM5cEnabled } from '~/areas/flows'
import { useProject } from '~/global/hooks'
import React from 'react'

import { useSidebarTaskContext } from './context'
import { SidebarTasksGroupHeader } from './header'
import { InlineTaskCreationForm } from './inline-create'
import {
  InlineAddStageButton,
  InlineAddTaskButton,
} from './project-panel/components/inline-add-buttons'
import { TaskPanelTaskLine } from './task-line'
import {
  INLINE_ADD_TASK_BUTTON_HEIGHT,
  INLINE_ADD_TASK_HEIGHT,
  isTaskRow,
  useShouldShowAddButtonsFn,
} from './utils'

import { type SidebarTasksGroup } from '../../hooks'
import { type SortBy } from '../../utils'

type VirtualSidebarTaskItemProps<SK extends keyof typeof SortBy> = {
  row: Row<SidebarTasksGroup>
  rowsToUse: Row<SidebarTasksGroup>[]
  virtual: VirtualItem
  virtualItems: VirtualItem[]
  rowVirtualizer: Virtualizer<HTMLTableElement, Element>
  range: Range
  sort: SK
  initialStartDate: { startDate: string } | undefined
  addTaskExpanded: Record<string, boolean>
  setAddTaskExpanded: React.Dispatch<React.SetStateAction<ExpandedStateList>>
  rowHeight: number
}
export function VirtualSidebarTaskItem<SK extends keyof typeof SortBy>({
  virtual,
  rowVirtualizer,
  virtualItems,
  range,
  sort,
  initialStartDate,
  addTaskExpanded,
  setAddTaskExpanded,
  row,
  rowHeight,
  rowsToUse,
}: VirtualSidebarTaskItemProps<SK>) {
  const visible =
    !isTaskRow(row) || virtual.index >= range.startIndex - range.overscan

  const { enableInlineAdd, projectId, workspaceId } = useSidebarTaskContext()
  const hasFlowsM5c = useIsFlowsM5cEnabled()
  const project = useProject(projectId)

  const shouldShowAddButtons = useShouldShowAddButtonsFn(project)

  const { showAddTaskButton, parentRow, showAddStageButton } =
    shouldShowAddButtons({
      index: virtual.index,
      virtualItems,
      rowsToUse,
      row,
      sort,
      enableInlineAdd,
      hasFlowsM5c,
    })

  if (!isTaskRow(row)) {
    return (
      <React.Fragment key={row.id}>
        {virtual.index !== 0 && <div className='h-4' />}

        <SidebarTasksGroupHeader
          index={virtual.index}
          measureElement={rowVirtualizer.measureElement}
          sort={sort}
          groupId={row.id}
          row={row}
          rowVirtualizer={rowVirtualizer}
          isAddTaskExpanded={addTaskExpanded[row.id]}
          toggleAddTaskExpanded={() =>
            setAddTaskExpanded((expanded) => ({
              ...expanded,
              [row.id]: !expanded[row.id],
            }))
          }
        />

        {showAddTaskButton && (
          <InlineAddTaskButton
            onClick={() => {
              setAddTaskExpanded((expanded) => ({
                ...expanded,
                [row.id]: !expanded[row.id],
              }))
            }}
          />
        )}
        {showAddStageButton && (
          <>
            <div className='h-4' />
            <InlineAddStageButton stageDefinitionId={row.id} />
          </>
        )}
      </React.Fragment>
    )
  }

  const showInlineAddTaskForm =
    !!parentRow && addTaskExpanded[parentRow.id] && showAddTaskButton && visible

  let height = rowHeight
  if (showAddTaskButton) {
    height += INLINE_ADD_TASK_BUTTON_HEIGHT
  }
  if (showInlineAddTaskForm) {
    height += INLINE_ADD_TASK_HEIGHT
  }

  return (
    <React.Fragment key={row.id}>
      <RowShell
        ref={rowVirtualizer.measureElement}
        data-index={virtual.index}
        style={{
          height,
        }}
      >
        {visible && (
          <TaskPanelTaskLine
            className={sort === 'STAGES' ? undefined : 'pl-4'}
            task={row.original}
          />
        )}
        <Content visible={showInlineAddTaskForm}>
          {showInlineAddTaskForm && (
            <InlineTaskCreationForm
              close={() =>
                setAddTaskExpanded((expanded) => ({
                  ...expanded,
                  [parentRow.id]: false,
                }))
              }
              workspaceId={workspaceId}
              projectId={projectId}
              initialData={{
                ...initialStartDate,
                stageDefinitionId: parentRow.id,
              }}
            />
          )}
        </Content>
        {showAddTaskButton && visible && parentRow && (
          <InlineAddTaskButton
            onClick={() => {
              setAddTaskExpanded((expanded) => ({
                ...expanded,
                [parentRow.id]: !expanded[parentRow.id],
              }))
            }}
          />
        )}
      </RowShell>
      {showAddStageButton && parentRow && visible && (
        <>
          <div className='h-4' />
          <InlineAddStageButton stageDefinitionId={parentRow.id} />
        </>
      )}
    </React.Fragment>
  )
}

export const RowShell = classed('div', {
  base: `
      flex truncate flex-col transition-height
    `,
})

const Content = classed('div', {
  base: 'overflow-hidden',
  variants: {
    visible: {
      true: 'motion-safe:animate-fadeIn visible',
      false: 'motion-safe:animate-fadeOut invisible',
    },
  },
})
