import { DraggableBlockPlugin } from '@motion/notes'
import { type NoteSchema } from '@motion/rpc-types'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useCopyHtmlLinkToClipboard } from '~/global/hooks'
import { useNoteUrl } from '~/global/navigation'
import { useNoteById } from '~/global/rpc/v2'
import { useCallback } from 'react'

type CustomDraggableBlockPluginProps = {
  noteId: NoteSchema['id']
}

export function CustomDraggableBlockPlugin({
  noteId,
}: CustomDraggableBlockPluginProps) {
  const { data: note } = useNoteById({ id: noteId })

  const buildNoteUrl = useNoteUrl()

  const copyLink = useCopyHtmlLinkToClipboard()

  const handlePlusClick = () => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_NOTE_BLOCK_PLUS_CLICK')
  }

  const handleCopyLink = useCallback(
    (motionId: string, textContent: string | null) => {
      if (!note) return

      const { noteUrl: noteUrlStr } = buildNoteUrl({ noteId: note.id })

      const noteUrl = new URL(noteUrlStr)

      noteUrl.searchParams.set('block', motionId)

      const noteTitle = note?.title ?? 'Motion Note'
      const blockTitle = textContent
        ? textContent.length > 30
          ? textContent.slice(0, 30) + '...'
          : textContent
        : 'Block'

      copyLink(noteUrl.toString(), [noteTitle, blockTitle].join(' - '))

      recordAnalyticsEvent('PROJECT_MANAGEMENT_NOTE_BLOCK_COPY_LINK')
    },
    [buildNoteUrl, copyLink, note]
  )

  const handleDelete = () => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_NOTE_BLOCK_DELETE')
  }

  return (
    <DraggableBlockPlugin
      onPlusClick={handlePlusClick}
      onCopyLink={handleCopyLink}
      onDelete={handleDelete}
    />
  )
}
