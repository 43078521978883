import { CheckSolid, XSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { IconButton, LoadingSpinner } from '@motion/ui/base'

type ProgressHeaderProps = {
  loaded?: boolean
  text?: string
  close?: () => void
}

export const ProgressHeader = ({
  loaded = false,
  text,
  close,
}: ProgressHeaderProps) => {
  return (
    <Header>
      <SpinnerBody loaded={loaded}>
        {loaded ? <CheckSolid /> : <LoadingSpinner size={18} />}
      </SpinnerBody>

      {text && <h4 className='text-base leading-5 font-semibold'>{text}</h4>}

      {close && (
        <div className='ml-auto self-start'>
          <IconButton
            icon={XSolid}
            onClick={close}
            sentiment='neutral'
            variant='muted'
            size='small'
          />
        </div>
      )}
    </Header>
  )
}

const Header = classed('div', 'flex flex-row items-center gap-4')

const SpinnerBody = classed('div', {
  base: `
    size-8 rounded-full 
    flex items-center justify-center 
    [&_svg]:size-4
  `,
  variants: {
    loaded: {
      false: `
        bg-semantic-neutral-surface-bg-subtle dark:bg-semantic-neutral-surface-bg-default 
        [&_svg]:text-semantic-neutral-icon-default
      `,
      true: `
        bg-semantic-success-bg-default 
        [&_svg]:text-semantic-success-icon-default
      `,
    },
  },
})
