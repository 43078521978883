import { Button } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { sleep } from '@motion/utils/promise'
import { getWindowData } from '@motion/web-base/env'
import { useModalApi } from '@motion/web-common/modals'

import { ConnectedConfirmationModal, ModalTrigger } from '~/areas/modals'
import { terminateWorkersOnOtherTabs } from '~/chromeApi/webappChromeApiContentScript'
import { detectIncognito } from 'detectincognitojs'
import { useState } from 'react'

import { instance as adminService } from '../../../services/adminService'

export const LogInAsAnotherUserPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [emailToLogInAs, setEmailToLogInAs] = useState('')
  const [bearerToken, setBearerToken] = useState('')
  const { isElectron } = getWindowData()
  const [initiator, setInitiator] = useState('')

  const modalApi = useModalApi()

  const onSave = async () => {
    if (!bearerToken || !emailToLogInAs || !initiator) {
      modalApi.open('confirm', {
        analytics: { name: 'error-occured' },
        title: 'An error has occurred',
        description: 'Please fill out all fields',
        onValue: () => {},
        onDismiss: () => {},
        confirmButtonText: 'Okay',
      })
      return
    }

    const isIncognito = await detectIncognito()

    if (!isIncognito.isPrivate && !isElectron) {
      modalApi.open('confirm', {
        analytics: { name: 'incognito-mode' },
        title: 'Please open this page in incognito mode',
        description:
          'Logging in as another user in non-incognito mode could corrupt user data! See #incident-12-6-22 for more details.',
        onValue: () => {},
        onDismiss: () => {},
        confirmButtonText: 'Okay',
      })
      return
    }

    // Force other motion tabs to logout
    terminateWorkersOnOtherTabs()

    // Wait a while to give time for other tabs to terminate workers
    await sleep(2_500)

    const response = await adminService.loginAsAnotherUser({
      emailToLogInAs: emailToLogInAs.trim(),
      bearerToken,
      initiator,
    })

    if (response.error) {
      modalApi.open('confirm', {
        analytics: { name: 'error-occured' },
        title: 'An error has occurred',
        description: response.error,
        onValue: () => {},
        onDismiss: () => {},
        confirmButtonText: 'Okay',
      })
      return
    }
    // Navigate to root route
    window.location.href = '/web/calendar'
  }

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    setIsLoading(true)
    await onSave().finally(() => setIsLoading(false))
  }

  return (
    <div
      data-theme='dark'
      className='bg-semantic-neutral-bg-default flex h-full w-full items-center justify-center electron-drag'
    >
      <ModalTrigger name='confirm' component={ConnectedConfirmationModal} />
      <div
        className='motiontab-settings-card flex flex-col gap-4'
        style={{ width: 'auto', alignItems: 'stretch' }}
      >
        <p
          style={{
            color: '#fff',
            fontSize: 24,
            fontWeight: 600,
          }}
        >
          Log in as another user
        </p>
        <div style={{ color: '#6D6D72' }}>
          For troubleshooting on another user&apos;s account
        </div>
        <form
          onSubmit={onSubmit}
          className='grid grid-cols-[auto_1fr] text-white items-center gap-2 border-transparent px-2 border-l-2 invalid:border-semantic-error-border-active valid:border-semantic-success-border-active'
        >
          <div style={{ whiteSpace: 'nowrap', width: 200, flexShrink: 0 }}>
            Your account
          </div>
          <TextField
            value={initiator}
            onChange={(value) => setInitiator(value)}
            required
            suffix={
              <span className='text-semantic-neutral-text-subtle'>
                @usemotion.com
              </span>
            }
          />

          <div style={{ whiteSpace: 'nowrap', width: 200, flexShrink: 0 }}>
            Email Address to log in as
          </div>
          <TextField
            value={emailToLogInAs}
            required
            type='email'
            onChange={setEmailToLogInAs}
          />

          <div style={{ whiteSpace: 'nowrap', width: 200, flexShrink: 0 }}>
            Auth token
          </div>
          <TextField
            type='password'
            value={bearerToken}
            required
            onChange={setBearerToken}
          />

          <div className='col-start-2 grid'>
            <Button disabled={isLoading} type='submit' fullWidth>
              Log out and back in as the above user
            </Button>
          </div>
        </form>
        <div style={{ color: '#fff', fontSize: 16 }}>
          NOTE: Remember to log out of the account once you are done
        </div>
      </div>
    </div>
  )
}
