import { QuestionMarkCircleSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { Button, Text, TextHeader } from '@motion/ui/base'
import { Term } from '@motion/ui-logic/billing'
import { type Tier } from '@motion/ui-logic/tiered-pricing'
import { useOnMountAnalyticsEvent } from '@motion/web-base/analytics'
import {
  BillingPlanToggle,
  BucketSeatsDropdown,
  FeatureTierCards,
} from '@motion/web-billing'
import { useModalApi } from '@motion/web-common/modals'

import { HorizontalDivider } from '~/components/Common/Divider/HorizontalDivider'
import { DetachCalendarModal } from '~/components/Settings/Modals/DetachCalendarModal/DetachCalendarModal'
import { useToggleIntercom } from '~/utils/toggleIntercom'
import { useState } from 'react'

interface TieredNoActiveSubscriptionProps {
  email: string
  signOut: () => void
}

export const TieredNoActiveSubscription = ({
  email,
  signOut,
}: TieredNoActiveSubscriptionProps) => {
  const toggleIntercom = useToggleIntercom()
  const [showDetachCalendarModal, setShowDetachCalendarModal] = useState(false)
  const [bucket, setBucket] = useState<number>(1)
  const [term, setTerm] = useState<Term>(Term.Annual)
  const modalApi = useModalApi()

  function onSelectFeatureTier(tier: Tier, term: Term, bucket: number) {
    modalApi.open('tiered-checkout-modal', {
      tier: tier,
      selectedBucket: bucket,
      selectedTerm: term,
      isResubscribe: true,
      onBillingComplete: () => {
        modalApi.dismissAll()
      },
    })
  }

  useOnMountAnalyticsEvent('ACCOUNT_NO_SUBSCRIPTION')

  return (
    <div className='h-screen w-screen overflow-y-auto'>
      <div className='bg-semantic-neutral-bg-subtle relative flex items-center justify-center py-20'>
        <div className='absolute top-12 right-12'>
          <Button onClick={() => toggleIntercom()} sentiment='neutral'>
            <QuestionMarkCircleSolid className='mr-2.5 h-4 w-4' />
            Support
          </Button>
        </div>

        <div className='flex w-full flex-col justify-center'>
          <TextHeader alignment='center' className='mb-5'>
            Your subscription has ended
          </TextHeader>
          <div className='flex flex-col items-center gap-4'>
            <Text
              as='h2'
              alignment='center'
              sentiment='subtle'
              className='mb-1'
            >
              Want to continue using Motion?
            </Text>
            <Text
              alignment='center'
              weight='semibold'
              sentiment='subtle'
              className='mb-2.5'
            >
              You won't be charged until you verify your payment information on
              the next page.
            </Text>
          </div>

          <div className='py-4 px-3'>
            <div className='flex flex-row justify-center gap-6 py-6'>
              <div className='flex flex-row gap-2 items-center'>
                <Text>Choose team size:</Text>
                <BucketSeatsDropdown
                  selectedBucket={bucket}
                  onChange={setBucket}
                  minSeats={1}
                  allowUpsell
                />
              </div>
              <BillingPlanToggle
                term={term}
                setTerm={setTerm}
                savingsPercent={40}
              />
            </div>
            <FeatureTierCards
              mode='resubscribe'
              bucket={bucket}
              term={term}
              currentTier='BASIC'
              onSelectFeatureTier={onSelectFeatureTier}
            />
          </div>

          <div className='flex flex-col justify-center items-center'>
            <Text
              sentiment='subtle'
              alignment='center'
              weight='medium'
              className='mb-3'
            >
              {templateStr(
                "If you think you have an active trial or subscription, it seems you might be using the wrong email with {{email}}. Please try logging in with the right one or reach out to our friendly support team at {{link}}. We're here to help!",
                {
                  email: <span className='underline'>{email}</span>,
                  link: (
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://help.usemotion.com'
                    >
                      help.usemotion.com
                    </a>
                  ),
                }
              )}
            </Text>

            <Button onClick={signOut} sentiment='neutral'>
              Sign in with another account
            </Button>
          </div>

          <HorizontalDivider />

          <div className='flex flex-col justify-center'>
            <Text size='xs' alignment='center'>
              Not using Motion anymore?
            </Text>
            <div className='flex flex-row items-center justify-center mt-4'>
              <Button
                size='small'
                sentiment='error'
                onClick={() => setShowDetachCalendarModal(true)}
              >
                Delete Motion Tasks
              </Button>
            </div>
          </div>
        </div>
        {showDetachCalendarModal && (
          <DetachCalendarModal
            visible={showDetachCalendarModal}
            close={() => {
              setShowDetachCalendarModal(false)
            }}
          />
        )}
      </div>
    </div>
  )
}
