import { StatusType } from '@motion/shared/common'
import { getStageVariant, type StageVariant } from '@motion/ui-logic/pm/project'
import { type ProjectSchema, type StatusSchema } from '@motion/zod/client'

import { useProjectStatusUpdater } from '~/areas/project/hooks'
import { useProjectStageByProjectAndDefinitionId } from '~/areas/project/hooks/data'
import { TaskStatusDropdown } from '~/areas/tasks/components'
import { useTaskStatusData } from '~/areas/tasks/hooks'
import { StatusDropdown } from '~/global/components/dropdowns'
import { StatusLabel } from '~/global/components/labels'
import { FilteredWorkspaceContext } from '~/global/contexts'
import { useWorkspaceFns } from '~/global/hooks'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'
import { memo, useCallback, useMemo } from 'react'

import { CellAction, EmptyCell } from './components'
import { useProjectInTree } from './hooks'

import { type GroupedNode } from '../../grouping'

type TaskStatusCellProps = {
  task: TaskWithRelations
}
export const TaskStatusCell = memo(({ task }: TaskStatusCellProps) => {
  const { onChange, isStatusAllowed, disabled, hasValidData } =
    useTaskStatusData(task)
  const { isUnvisitedStage } = task

  if (!hasValidData) {
    return <EmptyCell />
  }

  return (
    <FilteredWorkspaceContext statuses={isStatusAllowed}>
      <TaskStatusDropdown
        isUnvisitedStage={isUnvisitedStage}
        onChange={onChange}
        selectedStatusId={task.statusId}
        workspaceId={task.workspaceId}
      >
        <CellAction disabled={disabled}>
          <StatusLabel
            value={task.status}
            iconVariant={isUnvisitedStage ? 'isUnvisitedStage' : undefined}
          />
        </CellAction>
      </TaskStatusDropdown>
    </FilteredWorkspaceContext>
  )
})
TaskStatusCell.displayName = 'TaskStatusCell'

type ProjectStatusCellProps = {
  project: ProjectWithRelations
}
export const ProjectStatusCell = memo(({ project }: ProjectStatusCellProps) => {
  const updateProjectStatus = useProjectStatusUpdater()

  const handleChange = useCallback(
    (statusId: StatusSchema['id']) => {
      updateProjectStatus(project, statusId)
    },
    [project, updateProjectStatus]
  )

  return (
    <StatusDropdown
      onChange={handleChange}
      selectedStatusId={project.statusId}
      workspaceId={project.workspaceId}
    >
      <CellAction>
        <StatusLabel value={project.status} />
      </CellAction>
    </StatusDropdown>
  )
})
ProjectStatusCell.displayName = 'ProjectStatusCell'

type StageStatusCellProps = {
  row: GroupedNode
  stageDefinitionId: string
}
export const StageStatusCell = memo(
  ({ row, stageDefinitionId }: StageStatusCellProps) => {
    const project = useProjectInTree(row)
    if (!project) return null

    return (
      <CellAction disabled>
        <InnerStageStatusCell
          project={project}
          stageDefinitionId={stageDefinitionId}
        />
      </CellAction>
    )
  }
)
StageStatusCell.displayName = 'StageStatusCell'

type InnerStageStatusCellProps = {
  project: ProjectSchema
  stageDefinitionId: string
}

const InnerStageStatusCell = memo(
  ({ project, stageDefinitionId }: InnerStageStatusCellProps) => {
    const stage = useProjectStageByProjectAndDefinitionId(
      project,
      stageDefinitionId
    )
    const { getWorkspaceStatusByName } = useWorkspaceFns()

    const value = useMemo(() => {
      if (!stage) return null

      const stageVariant = getStageVariant(stage)
      const statusName = getStageStatusName(stageVariant, stage.visited)

      return {
        name: statusName,
        color:
          getWorkspaceStatusByName(project.workspaceId, statusName)?.color ??
          'grey',
        type: getStageStatusType(stageVariant),
      } satisfies Pick<StatusSchema, 'name' | 'color' | 'type'>
    }, [stage, getWorkspaceStatusByName, project.workspaceId])

    if (!stage || !value) return null

    return <StatusLabel value={value} />
  }
)
InnerStageStatusCell.displayName = 'InnerStageStatusCell'

const getStageStatusName = (
  stageVariant: StageVariant,
  isVisitedStage: boolean
) => {
  return stageVariant === 'completed'
    ? 'Completed'
    : stageVariant === 'skipped'
      ? 'Canceled'
      : isVisitedStage
        ? 'In Progress'
        : 'Todo'
}

const getStageStatusType = (stageVariant: StageVariant): StatusType => {
  return stageVariant === 'completed'
    ? StatusType.COMPLETED
    : stageVariant === 'skipped'
      ? StatusType.CANCELED
      : StatusType.DEFAULT
}
