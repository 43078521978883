import { PuzzleSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import {
  type ProjectDefinitionSchema,
  type ProjectSchema,
  type StageDefinitionSchema,
} from '@motion/rpc-types'
import {
  FormModal,
  type FormModalProps,
  showToast,
  Tooltip,
} from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { ProjectPalette } from '@motion/ui/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import { ProjectCountBadge } from '~/areas/flows/components'
import { type ModalTriggerComponentProps } from '~/areas/modals'
import { StageLabel } from '~/global/components/labels'
import { useI18N } from '~/global/contexts'
import {
  useProjectDefinitionsWithProjectInfo,
  useStageDefinitionsWithProjectInfo,
} from '~/global/hooks'
import {
  useDeleteProjectDefinition,
  useDeleteStageDefinition,
} from '~/global/rpc/v2'
import { useMemo, useState } from 'react'

import { AffectedProjectList } from './affected-project-list'
import {
  ConfirmationSection,
  ConfirmationText,
  FormBody,
  TemplateList,
  TextGroup,
  WarningText,
} from './styled'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'confirm-delete-stage-template': PromptCallbacks<{
      item: StageDefinitionSchema | ProjectDefinitionSchema
    }> & {
      item: StageDefinitionSchema | ProjectDefinitionSchema
      type: 'stage-definition' | 'project-definition'
      workspaceId: string
    }
  }
}

export function ConnectedDeleteStageTemplateModal({
  item,
  workspaceId,
  type,
  close,
  onValue,
  onDismiss,
}: ModalTriggerComponentProps<'confirm-delete-stage-template'>) {
  const { mutateAsync: deleteStageDefinition } = useDeleteStageDefinition()
  const { mutateAsync: deleteProjectDefinition } = useDeleteProjectDefinition()

  const stagesWithProjectInfo = useStageDefinitionsWithProjectInfo(workspaceId)
  const projectDefinitionsWithProjectInfo =
    useProjectDefinitionsWithProjectInfo(workspaceId)

  const itemWithProjectInfo = useMemo(() => {
    if (type === 'stage-definition') {
      return stagesWithProjectInfo.find((stage) => stage.id === item.id)
    }
    return projectDefinitionsWithProjectInfo.find(
      (projectDefinition) => projectDefinition.id === item.id
    )
  }, [type, item, stagesWithProjectInfo, projectDefinitionsWithProjectInfo])

  const hasTemplates =
    !!itemWithProjectInfo &&
    'projectDefinitions' in itemWithProjectInfo &&
    itemWithProjectInfo.projectDefinitions.length > 0

  return (
    <ConfirmDeleteStageTemplateModal
      item={item}
      type={type}
      onDismiss={onDismiss}
      onValue={onValue}
      projects={itemWithProjectInfo?.projects ?? []}
      projectDefinitions={
        hasTemplates ? (itemWithProjectInfo.projectDefinitions ?? []) : []
      }
      close={close}
      submitAction={{
        text: `Delete ${type === 'stage-definition' ? 'Stage' : 'Template'}`,
        onAction: async () => {
          try {
            if (type === 'stage-definition') {
              recordAnalyticsEvent('DELETE_STAGE_DEFINITION', {
                numTasks:
                  itemWithProjectInfo && 'tasks' in itemWithProjectInfo
                    ? itemWithProjectInfo.tasks.length
                    : 0,
                name: item.name,
              })
              await deleteStageDefinition({ workspaceId, id: item.id })
            } else {
              await deleteProjectDefinition({ workspaceId, id: item.id })
            }
            close({ item })
            showToast('success', `${item.name} deleted`)
          } catch (error) {
            Sentry.captureException(error, {
              tags: {
                position: 'delete-stage-template-modal',
                workspaceId,
                itemId: item.id,
                itemName: item.name,
                itemType: type,
              },
            })
            showToast(
              'error',
              `Failed to delete ${type === 'stage-definition' ? 'stage' : 'template'}`
            )
          }
        },
        destructive: true,
      }}
    />
  )
}

export type ConfirmDeleteStageTemplateModalProps = Omit<
  ModalTriggerComponentProps<'confirm-delete-stage-template'>,
  'workspaceId'
> & {
  submitAction: FormModalProps['submitAction']
  projects: ProjectSchema[]
  projectDefinitions: ProjectDefinitionSchema[]
}
export function ConfirmDeleteStageTemplateModal({
  item,
  type,
  close,
  submitAction,
  projects,
  projectDefinitions,
}: ConfirmDeleteStageTemplateModalProps) {
  const [confirmText, setConfirmText] = useState('')
  const { pluralize } = useI18N()

  const submitDisabled = useMemo(
    () => confirmText.trim().toLowerCase() !== item.name.trim().toLowerCase(),
    [confirmText, item.name]
  )

  return (
    <FormModal
      bodyPadded={false}
      visible
      onClose={close}
      title={`Are you sure you want to delete ${item.name}?`}
      submitAction={{
        ...submitAction,
        disabled: submitDisabled,
      }}
    >
      <FormBody>
        <ConfirmationSection>
          <WarningText>
            {type === 'project-definition' &&
              `The template will be deleted permanently and unlinked from the active
          projects using it.`}
            {type === 'stage-definition' &&
              'The stage will be removed from these templates and projects.'}
          </WarningText>
          {type === 'stage-definition' && (
            <>
              <StageLabel size='normal' value={item} />
              <TextGroup>
                <ConfirmationText>
                  {templateStr('{{templateCount}} {{templateLabel}} affected', {
                    templateCount: projectDefinitions.length,
                    templateLabel: pluralize(
                      projectDefinitions.length,
                      'template',
                      'templates'
                    ),
                  })}
                </ConfirmationText>
                {projectDefinitions.length > 0 && (
                  <TemplateList>
                    {projectDefinitions.map((projectDefinition) => {
                      const definitionWillBeDeleted =
                        projectDefinition.stageDefinitionReferences.length ===
                          1 &&
                        projectDefinition.stageDefinitionReferences[0]
                          .stageDefinitionId === item.id

                      return (
                        <Tooltip
                          key={projectDefinition.id}
                          asChild
                          content={
                            definitionWillBeDeleted
                              ? `This template will be deleted as ${item.name} is the only stage in this template`
                              : undefined
                          }
                        >
                          <ProjectCountBadge
                            sentiment={
                              definitionWillBeDeleted
                                ? 'destructive'
                                : undefined
                            }
                          >
                            <ProjectPalette color={projectDefinition.color}>
                              <PuzzleSolid className='size-3 text-palette-highlight-default' />
                            </ProjectPalette>
                            {projectDefinition.name}
                          </ProjectCountBadge>
                        </Tooltip>
                      )
                    })}
                  </TemplateList>
                )}
              </TextGroup>
            </>
          )}
          <TextGroup>
            <ConfirmationText>
              {templateStr(
                'Type {{itemName}} to confirm you want to delete this {{itemType}}',
                {
                  itemName: (
                    <span className='text-semantic-error-text-default'>
                      {item.name}
                    </span>
                  ),
                  itemType: type === 'stage-definition' ? 'stage' : 'template',
                }
              )}
            </ConfirmationText>
            <TextField
              placeholder={templateStr(
                'Type “{{itemName}}” to delete {{itemType}}',
                {
                  itemName: item.name,
                  itemType: type === 'stage-definition' ? 'stage' : 'template',
                }
              )}
              onChange={setConfirmText}
              fullWidth
            />
          </TextGroup>
        </ConfirmationSection>
        <AffectedProjectList projects={projects} type='stage-definition' />
      </FormBody>
    </FormModal>
  )
}
