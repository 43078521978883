import { ProjectCubeSolid } from '@motion/icons'
import { Text } from '@motion/ui/base'

import { TieredPricingAiProjectButton } from '~/areas/tiered-pricing/components'

type ProjectAICardProps = {
  workspaceId?: string
  onClose: () => void
}

export function ProjectAICard({ workspaceId, onClose }: ProjectAICardProps) {
  return (
    <div className='h-full p-5 flex flex-col items-center justify-between gap-3 border border-semantic-neutral-border-default rounded-lg'>
      <div className='flex flex-col items-center gap-3'>
        <h4 className='font-bold text-[15px] text-semantic-neutral-text-subtle text-center'>
          <ProjectCubeSolid className='inline size-4 align-text-top mr-2' />
          Create Project with AI
        </h4>

        <Text size='sm' alignment='center'>
          Let AI create stages and tasks for a new project. Best when this new
          project is similar to an existing template.
        </Text>
      </div>

      <TieredPricingAiProjectButton
        workspaceId={workspaceId}
        onClose={onClose}
      />
    </div>
  )
}
