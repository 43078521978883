import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { useDeleteNoteComment as useDeleteNoteCommentMutation } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useDeleteNoteComment = () => {
  const context = useRouteAnalyticsMetadata()

  const { mutateAsync: deleteNoteComment } = useDeleteNoteCommentMutation()
  const modalApi = useModalApi()

  return useCallback(
    async ({
      commentId,
      targetId,
      onDeleteCb,
    }: {
      commentId: string
      targetId: string
      onDeleteCb: () => void
    }) => {
      if (!commentId || !targetId) {
        throw new Error('CommentId and targetId are required.')
      }

      await modalApi.prompt('confirm', {
        analytics: {
          name: 'delete-note-comment',
        },
        title: 'Are you sure you want to delete this comment?',
        description: 'You cannot undo this action.',
        destructive: true,
        confirmButtonText: 'Delete Comment',
        onValue: async (shouldDelete) => {
          if (shouldDelete) {
            try {
              await deleteNoteComment({
                commentId,
                targetId,
              })

              recordAnalyticsEvent(
                'PROJECT_MANAGEMENT_DELETE_NOTE_COMMENT',
                context
              )

              onDeleteCb()
            } catch (err) {
              showErrorToast(err)
            }
          }
        },
      })
    },
    [deleteNoteComment, modalApi, context]
  )
}
