import { QuestionMarkCircleSolid } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'

import {
  ControlledAutoScheduleField,
  ControlledColorField,
  ControlledExpectedDurationField,
  ControlledNameField,
  ControlledRoleFields,
  ControlledTextVariableFields,
} from './fields'
import {
  Sidebar,
  SidebarHeader,
  SidebarSection,
  SidebarSectionFields,
  SidebarTitle,
} from './styled'

import { ConnectedStageDebugInfo } from '../../flow-template-modal/components/stages/fields/stage-debug-info'

export function StagesSidebar() {
  return (
    <Sidebar>
      <SidebarSection>
        <SidebarHeader>
          <SidebarTitle>Stage info</SidebarTitle>
        </SidebarHeader>

        <SidebarSectionFields>
          <ControlledNameField />
          <ControlledColorField />
          <ControlledExpectedDurationField />
          <ControlledAutoScheduleField />
          <ConnectedStageDebugInfo stagePath='stages.0' />
        </SidebarSectionFields>
      </SidebarSection>

      <SidebarSection>
        <SidebarTitle>
          Roles
          <Tooltip content='Roles are placeholders that can be assigned to tasks inside project workflow templates. When using the template, you’ll be able to choose an assignee for each role.'>
            <QuestionMarkCircleSolid className='!w-3 !h-3 !text-semantic-neutral-icon-subtle shrink-0' />
          </Tooltip>
        </SidebarTitle>

        <SidebarSectionFields>
          <ControlledRoleFields />
        </SidebarSectionFields>

        <SidebarSectionFields />
      </SidebarSection>

      <SidebarSection>
        <SidebarTitle>
          Text variables
          <Tooltip content='Text variables enable you to include specific information into task names and descriptions.'>
            <QuestionMarkCircleSolid className='!w-3 !h-3 !text-semantic-neutral-icon-subtle shrink-0' />
          </Tooltip>
        </SidebarTitle>

        <SidebarSectionFields>
          <ControlledTextVariableFields />
        </SidebarSectionFields>
      </SidebarSection>
    </Sidebar>
  )
}
