import { ExclamationCircleSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { type NoteSchema } from '@motion/rpc-types'
import { Banner } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { useCachedItems } from '~/global/cache'
import { useFindFolderItem } from '~/global/hooks'
import { useDeleteNoteMutation } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useDeleteNote = () => {
  const { mutateAsync: deleteMutation } = useDeleteNoteMutation()
  const modalApi = useModalApi()

  const findFolderItem = useFindFolderItem()

  const notes = useCachedItems('notes')

  return useCallback(
    (noteId: NoteSchema['id']) => {
      const note = notes.find((note) => note.id === noteId)

      const [noteFolderItem] =
        findFolderItem(
          ({ itemId, originalFolderItemId }) =>
            itemId === noteId && originalFolderItemId == null
        ) || []

      const childNotes =
        noteFolderItem?.itemType === 'NOTE' ? noteFolderItem.items : []

      const description =
        noteFolderItem == null
          ? 'Deleting this doc will also remove any nested docs it contains.'
          : childNotes.length === 1
            ? 'This action will also delete one nested doc.'
            : childNotes.length > 1
              ? `This action will also delete ${childNotes.length} nested docs.`
              : null

      void modalApi.prompt('confirm', {
        analytics: {
          name: 'delete-note',
        },
        title: 'Are you sure you want to delete this document?',
        description: (
          <>
            {templateStr('{{name}} will be gone forever.', {
              name:
                note != null ? (
                  <span className='font-semibold'>{note.title}</span>
                ) : (
                  'This doc'
                ),
            })}
            {description != null && (
              <div className='mt-2'>
                <Banner sentiment='error' variant='onPage' size='small'>
                  <ExclamationCircleSolid className='text-semantic-error-text-default size-4' />
                  {description}
                </Banner>
              </div>
            )}
          </>
        ),
        destructive: true,
        confirmButtonText: 'Delete Doc',
        onValue: async (shouldDelete) => {
          if (shouldDelete) {
            try {
              await deleteMutation({ id: noteId })
            } catch (err) {
              showErrorToast(err)
            }
          }
        },
      })
    },
    [findFolderItem, deleteMutation, modalApi, notes]
  )
}
