import { classed } from '@motion/theme'
import { Tooltip } from '@motion/ui/base'
import { formatDateTime, formatDateTimeRange } from '@motion/ui-logic'
import { type RecurringTaskSchema, type TaskSchema } from '@motion/zod/client'

import {
  ModalFieldButton,
  SidebarSection,
} from '~/areas/task-project/components'
import { useDebugMode } from '~/global/hooks/use-debug-mode'

import { useTaskModalState } from '../contexts'

export const TaskDebugInfo = () => {
  const shouldShowDebugInfo = useDebugMode()

  if (!shouldShowDebugInfo) return null

  return (
    <SidebarSection className='relative'>
      <TaskDebugInfoContent />
    </SidebarSection>
  )
}

const TaskDebugInfoContent = () => {
  const {
    initialTaskData: { task },
  } = useTaskModalState()

  if (task == null) return null

  return (
    <DebugInfoBox>
      <TaskIdInfo taskId={task.id} />
      <InstanceInfo task={task} />
    </DebugInfoBox>
  )
}

const TaskIdInfo = ({ taskId }: { taskId: string }) => {
  return (
    <Tooltip content='Click to copy'>
      <ModalFieldButton
        label='Task ID'
        disabled={taskId == null}
        onClick={() => {
          navigator.clipboard.writeText(taskId)
        }}
      >
        <span className='font-mono truncate'>{taskId}</span>
      </ModalFieldButton>
    </Tooltip>
  )
}

const InstanceInfo = ({ task }: { task: TaskSchema | RecurringTaskSchema }) => {
  if (task == null || task.type !== 'NORMAL') return null

  return (
    <>
      <ScheduledTimeInfo task={task} />
      <Tooltip content='If true, updates to the task definition will be reflected in this task.'>
        <ModalFieldButton label='Syncing'>
          {String(task.isSyncingWithDefinition)}
        </ModalFieldButton>
      </Tooltip>
      <ModalFieldButton label='Fixed time'>
        {String(task.isFixedTimeTask)}
      </ModalFieldButton>
      <ModalFieldButton label='Needs Reschedule'>
        {String(task.needsReschedule)}
      </ModalFieldButton>
      <ModalFieldButton label='Future Schedulable'>
        {String(task.isFutureSchedulable)}
      </ModalFieldButton>
      {task.completedTime != null && (
        <ModalFieldButton label='Completed time'>
          {formatDateTime(task.completedTime)}
        </ModalFieldButton>
      )}
      {task.taskDefinitionId != null && (
        <ModalFieldButton label='Definition ID'>
          {task.taskDefinitionId}
        </ModalFieldButton>
      )}
    </>
  )
}

const ScheduledTimeInfo = ({ task }: { task: TaskSchema }) => {
  const { scheduledStart, scheduledEnd } = task

  if (scheduledStart == null || scheduledEnd == null) return null

  return (
    <ModalFieldButton label='Scheduled'>
      {formatDateTimeRange(scheduledStart, scheduledEnd)}
    </ModalFieldButton>
  )
}

const DebugInfoBox = classed('div', {
  base: `
    border
    border-semantic-warning-border-default p-1 m-[-4px]
    text-semantic-neutral-text-default
    rounded
    text-xs
    flex flex-col gap-1
  `,
})
