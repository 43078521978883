import { ArrowRightSolid } from '@motion/icons'
import { IconButton, Tooltip } from '@motion/ui/base'
import { getNextActiveStage } from '@motion/ui-logic/pm/project'
import { sleep } from '@motion/utils/promise'

import { useMoveProjectToStage } from '~/areas/project/hooks'
import { StageField } from '~/areas/task-project/fields'
import { type StageDropdownProps } from '~/global/components/dropdowns'
import { useProject } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { useProjectForm, useSubmitForm } from '../hooks'

export const ActiveStageField = () => {
  const { form } = useProjectForm()
  const submitForm = useSubmitForm()
  const moveProjectToStage = useMoveProjectToStage()
  const { control, watch } = form

  const { field: activeStageIdField } = useController({
    name: 'activeStageDefinitionId',
    control,
  })
  const projectId = watch('id')
  const project = useProject(projectId)

  if (activeStageIdField.value == null) return null

  const onStageSelect: StageDropdownProps['onChange'] = async (
    stageDefinition
  ) => {
    if (activeStageIdField.value === stageDefinition.id) return

    const projectId = form.getValues('id')
    if (projectId == null) {
      activeStageIdField.onChange(stageDefinition.id)
      return
    }

    // Sleeping a bit to make sure the dropdown gets closed before prompting the modal to keep the focus within the modal
    await sleep(1)

    if (project == null) {
      throw new Error('Project not found')
    }
    moveProjectToStage(project, stageDefinition.id)
  }

  const nextStage = getNextActiveStage(project)

  return (
    <StageField
      projectId={projectId}
      stageDefinitionId={activeStageIdField.value}
      onChange={onStageSelect}
      suffix={
        nextStage != null && project != null ? (
          <Tooltip asChild content='Move to next stage'>
            <IconButton
              icon={ArrowRightSolid}
              size='xsmall'
              variant='outlined'
              sentiment='neutral'
              onClick={() => {
                moveProjectToStage(
                  project,
                  nextStage.stageDefinitionId,
                  form.handleSubmit(submitForm)
                )
              }}
            />
          </Tooltip>
        ) : undefined
      }
    />
  )
}
