import { type NoteResponseSchema } from '@motion/rpc-types'
import { useShortcut } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useCopyHtmlLinkToClipboard } from '~/global/hooks'
import { useNoteUrl } from '~/global/navigation'
import { useNavigate } from 'react-router'

import { NoteResult } from './note-result'

export interface ConnectedNoteResultProps {
  item: NoteResponseSchema
  itemIds: NoteResponseSchema['id'][]
  active: boolean
  onItemClick: () => void
}

export function ConnectedNoteResult({
  item,
  itemIds,
  active,
  onItemClick,
}: ConnectedNoteResultProps) {
  const navigate = useNavigate()
  const getNoteUrl = useNoteUrl()
  const copyLink = useCopyHtmlLinkToClipboard()

  const handleClick = () => {
    onItemClick()
    navigate(getNoteUrl({ noteId: item.id }).noteUri)
    recordAnalyticsEvent('SEARCH_RESULT_OPENED', {
      type: 'note',
    })
  }

  const handleCopyLink = () => {
    const { noteUrl } = getNoteUrl({ noteId: item.id })
    copyLink(noteUrl, item.name ?? 'Motion Doc')
  }

  useShortcut('mod+l', handleCopyLink, { enabled: active })

  return (
    <NoteResult item={item} onClick={handleClick} onCopyLink={handleCopyLink} />
  )
}
