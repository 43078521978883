import { type CalendarEventAttendeeSchema } from '@motion/rpc-types'

import { selectSelectedTeammateContacts } from '~/state/calendar-list/calendar-list-selectors'
import { selectMainEmailAccount } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'
import { resolveCalendarId } from '~/utils/calendarUtils'
import { useMemo } from 'react'

import { useCalendarState, useInNoExternalCalendarsMode } from '../../hooks'

export const useNewCalendarEvent = () => {
  const { noExternalCalendarsMode, mainCalendar } =
    useInNoExternalCalendarsMode()
  const mainEmailAccount = useAppSelector(selectMainEmailAccount)
  const selectedTeammateContacts = useAppSelector(
    selectSelectedTeammateContacts
  )
  const newEvent = useCalendarState((state) => state.selectedCalendarEvent?.new)

  const teamSearchGuests = useMemo<CalendarEventAttendeeSchema[]>(() => {
    if (
      mainEmailAccount?.email &&
      selectedTeammateContacts &&
      selectedTeammateContacts.length > 0
    ) {
      return [
        {
          email: mainEmailAccount.email,
          isOptional: false,
          isOrganizer: true,
          status: 'accepted' as const,
        },
        ...selectedTeammateContacts.map((contact) => ({
          email: contact.email,
          isOptional: false,
          isOrganizer: false,
          status: 'needsAction' as const,
        })),
      ]
    }
    return []
  }, [mainEmailAccount?.email, selectedTeammateContacts])

  return useMemo(() => {
    if (!newEvent) return

    if (noExternalCalendarsMode) {
      return {
        calendarId: '',
        email: '',
        end: newEvent.end,
        id: '',
        start: newEvent.start,
        title: '',
        isAllDay: newEvent.allDay,
        attendees: [],
      }
    }

    if (!mainCalendar) return

    return {
      calendarId: resolveCalendarId(mainCalendar),
      email: mainEmailAccount?.email,
      end: newEvent.end,
      id: '',
      start: newEvent.start,
      title: '',
      isAllDay: newEvent.allDay,
      attendees: teamSearchGuests,
    }
  }, [
    newEvent,
    mainCalendar,
    mainEmailAccount?.email,
    noExternalCalendarsMode,
    teamSearchGuests,
  ])
}
