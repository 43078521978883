import { FieldButton } from '@motion/ui/base'
import { getChunkDurations } from '@motion/ui-logic'

import { ModalFieldButton } from '~/areas/task-project/components'
import {
  MinChunkDurationDropdown,
  SubItemIndentMarker,
} from '~/areas/tasks/modals/task-modal/components'
import { useController } from 'react-hook-form'

import { useActionItemForm } from '../../hooks'

export const ControlledMinChunkField = () => {
  const {
    form: { watch, control },
  } = useActionItemForm()

  const { field: minChunkDurationField } = useController({
    name: 'minimumDuration',
    control,
  })

  const duration = watch('duration')
  const chunkDurations = getChunkDurations(duration)

  if (chunkDurations.length === 0) {
    return null
  }

  return (
    <div className='flex flex-row items-center gap-0.5'>
      <SubItemIndentMarker />

      <ModalFieldButton label='Min chunk'>
        <MinChunkDurationDropdown
          value={minChunkDurationField.value}
          onChange={minChunkDurationField.onChange}
          duration={duration}
        >
          <FieldButton variant='muted' size='small' iconOnly>
            <span className='whitespace-nowrap'>
              {chunkDurations.find(
                (item) => item.value === minChunkDurationField.value
              )?.label ?? 'No Chunks'}
            </span>
          </FieldButton>
        </MinChunkDurationDropdown>
      </ModalFieldButton>
    </div>
  )
}
