import { PuzzleSolid } from '@motion/icons'
import { useSharedState } from '@motion/react-core/shared-state'
import { createNoneId } from '@motion/shared/identifiers'
import { type Group } from '@motion/ui/base'
import { createNoneProjectDefinition } from '@motion/ui-logic/pm/data'
import { type ProjectDefinitionSchema } from '@motion/zod/client'

import { ProjectDefinitionBadge } from '~/global/components/badges'
import { AppWorkspaceContext } from '~/global/contexts'
import { type PropsWithChildren } from 'react'

import { ConnectedGroupedItemDropdown } from './connected-grouped-item-dropdown'
import { FilterItem } from './filter-item/filter-item'

import { useFieldFilter } from '../../../context'
import {
  WorkspaceItem,
  WorkspaceItemGroupHeader,
} from '../../buttons/workspace-item'
import { MultiSelectFilterValue } from '../../multi-select-filter-value'
import { type DropdownFilterProps } from '../../types'

export const ConnectedProjectDefinitionFilter = (
  props: DropdownFilterProps
) => {
  const [value, setValue] = useFieldFilter('projects', 'projectDefinitionIds')
  const [ctx] = useSharedState(AppWorkspaceContext)

  const getAllItems = () => [
    createNoneProjectDefinition(createNoneId('workspace')),
    ...ctx.projectDefinitions.all,
  ]

  return (
    <FilterItem
      label='Templates'
      canInvert
      inverted={value?.inverse}
      onInvertChanged={(invert) => {
        if (value == null) return
        setValue({ ...(value ?? {}), inverse: invert })
      }}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      onDismiss={() => setValue(null)}
      renderDropdown={({ close }) => (
        <ConnectedGroupedItemDropdown
          applyTo='projects'
          field='projectDefinitionIds'
          getAllItems={getAllItems}
          placeholder='Choose templates...'
          renderItem={(item) => (
            <Container item={item}>
              <WorkspaceItem item={item}>
                <div className='max-w-xs truncate'>{item.name}</div>
              </WorkspaceItem>
            </Container>
          )}
          renderHeader={(group) => {
            const firstItem = group.items[0]
            return (
              <Container item={firstItem}>
                <WorkspaceItemGroupHeader
                  group={group as Group<ProjectDefinitionSchema>}
                >
                  <div className='max-w-xs truncate'>{firstItem.name}</div>
                </WorkspaceItemGroupHeader>
              </Container>
            )
          }}
          close={close}
        />
      )}
    >
      <MultiSelectFilterValue
        Icon={PuzzleSolid}
        type='projectDefinitions'
        ids={value?.value ?? null}
        getAllItems={getAllItems}
      />
    </FilterItem>
  )
}

type ContainerProps = PropsWithChildren<{ item: ProjectDefinitionSchema }>

function Container({ item, children }: ContainerProps) {
  return (
    <div className='flex items-center gap-1.5'>
      <ProjectDefinitionBadge value={item} />
      {children}
    </div>
  )
}
