import { NotetakerFilterSchema } from '@motion/ui-logic/pm/data'

import {
  ColumnSchema,
  GroupByField,
} from '~/areas/project-management/pages/pm-v3/view-state/schema'
import * as v from 'valibot'

const NotetakerSortBySchema = v.object({
  field: v.picklist(['title', 'startTime', 'startDate', 'host', 'attendees']),
  direction: v.picklist(['asc', 'desc']),
})

export const NotetakerViewType = v.picklist([
  'my-calls',
  'shared-with-me',
  'all-calls',
  'future',
])
export type NotetakerViewType = v.InferInput<typeof NotetakerViewType>

export const NotetakerGroupByOptionsSchema = v.object({
  fields: v.array(GroupByField),
  order: v.object({
    type: v.literal('direction'),
    by: v.picklist(['asc', 'desc']),
  }),
  hideEmpty: v.boolean(),
})
export type NotetakerGroupByOptions = v.InferOutput<
  typeof NotetakerGroupByOptionsSchema
>

export const NotetakerViewStateSchema = v.object({
  $version: v.literal(1),

  type: NotetakerViewType,
  groupBy: NotetakerGroupByOptionsSchema,
  sortBy: v.nullish(NotetakerSortBySchema),
  columns: v.array(ColumnSchema),
  search: v.string(),

  filters: NotetakerFilterSchema,
})
export type NotetakerViewState = v.InferOutput<typeof NotetakerViewStateSchema>
