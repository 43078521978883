import { useCustomFieldsFns } from '~/areas/custom-fields/hooks'
import {
  ControlledCustomFieldsSidebar,
  type ControlledCustomFieldsSidebarProps,
} from '~/areas/task-project/components'
import { useTaskCustomFieldUpdater } from '~/areas/tasks/hooks'

import { useEventModalState } from '../contexts'
import { useEventForm } from '../hooks'

export const ControlledEventCustomFieldsField = () => {
  const { form } = useEventForm()
  const { isLoading, initialMeetingTask } = useEventModalState()
  const updateTaskCustomFields = useTaskCustomFieldUpdater()
  const { getCustomField } = useCustomFieldsFns()

  const workspaceId = form.watch('workspaceId')

  if (isLoading || workspaceId == null) return null

  const shouldTriggerAutoSave = initialMeetingTask != null

  const onChange: ControlledCustomFieldsSidebarProps['onChange'] = async (
    customFieldId,
    valueArg
  ) => {
    if (shouldTriggerAutoSave) {
      const customField = getCustomField(customFieldId)
      if (customField == null) {
        throw new Error('Custom field not found')
      }

      return await updateTaskCustomFields(
        initialMeetingTask.id,
        customField,
        valueArg
      )
    }
  }

  return (
    <ControlledCustomFieldsSidebar
      form={form}
      workspaceId={workspaceId}
      onChange={shouldTriggerAutoSave ? onChange : undefined}
    />
  )
}
