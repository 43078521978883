import { PuzzleGradientSolid } from '@motion/icons'
import { Text } from '@motion/ui/base'

import { TieredPricingAiFlowButton } from '~/areas/tiered-pricing/components'

type FlowsAICardProps = {
  workspaceId: string | undefined
  onClose?: () => void
}

export function FlowsAICard({ workspaceId, onClose }: FlowsAICardProps) {
  return (
    <div className='flex h-full relative'>
      <div className='absolute left-0 top-0 h-full w-full bg-gradient-to-r from-semantic-gradient-purple to-semantic-gradient-pink rounded-lg opacity-25' />

      <div className='z-10 w-full p-5 m-px flex flex-col items-center justify-between gap-3 rounded-[7px] bg-modal-bg'>
        <div className='flex flex-col items-center gap-3'>
          <h4 className='text-[15px] font-bold bg-purple-gradient text-gradient text-center'>
            <PuzzleGradientSolid className='inline size-4 align-text-top mr-2' />
            Create Project Template with AI
          </h4>

          <Text size='sm' alignment='center'>
            Create a template for all projects with similar workflows.
          </Text>
        </div>

        <TieredPricingAiFlowButton
          workspaceId={workspaceId}
          onClose={onClose}
        />
      </div>
    </div>
  )
}
