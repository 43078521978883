import { type EditorPublicAPI } from '@motion/notes'

import React, {
  type ReactNode,
  type RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'

import { NoteEditorAPIContext } from './note-editor-context'

function getEditorAPI(
  editorRef: RefObject<EditorPublicAPI | null>
): EditorPublicAPI {
  if (editorRef.current == null) {
    throw new Error('Editor reference is not set')
  }

  return editorRef.current
}

export const NoteEditorAPIProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const editorRef = useRef<EditorPublicAPI | null>(null)

  const [isEditorInitialized, setIsEditorInitialized] = useState(false)

  const setEditorRef = useCallback((editor: EditorPublicAPI) => {
    editorRef.current = editor
    setIsEditorInitialized(true)
  }, [])

  const navigateToNodeByMotionId = useCallback((nodeKey: string) => {
    const editor = getEditorAPI(editorRef)
    editor.navigateToNodeByMotionId(nodeKey)
  }, [])

  const value = useMemo(
    () => ({
      isEditorInitialized,
      navigateToNodeByMotionId,
      setEditorRef,
    }),
    [isEditorInitialized, navigateToNodeByMotionId, setEditorRef]
  )

  return (
    <NoteEditorAPIContext.Provider value={value}>
      {children}
    </NoteEditorAPIContext.Provider>
  )
}
