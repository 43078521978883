import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { getChunkDurations } from '@motion/ui-logic'

import { type ReactNode } from 'react'

export type MinChunkDurationDropdownProps = {
  duration: number | null
  value: number | null
  onChange: (value: number | null) => void
  children: ReactNode
}

export function MinChunkDurationDropdown({
  value,
  onChange,
  duration,
  children,
}: MinChunkDurationDropdownProps) {
  const chunkDurations = getChunkDurations(duration)

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <MinChunkDurationDropdownContent
          close={close}
          onChange={onChange}
          chunkDurations={chunkDurations}
          selectedMinChunkDuration={value}
        />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type MinChunkDurationDropdownContentProps = {
  close: () => void
  onChange: (value: number | null) => void
  chunkDurations: { label: string; value: number | null }[]
  selectedMinChunkDuration: number | null
}

const MinChunkDurationDropdownContent = ({
  close,
  onChange,
  chunkDurations,
  selectedMinChunkDuration,
}: MinChunkDurationDropdownContentProps) => {
  return (
    <SearchableList
      searchable={false}
      items={chunkDurations}
      computeKey={(item) => String(item.value)}
      computeSelected={(item) => item.value === selectedMinChunkDuration}
      onSelect={(item) => {
        onChange(item.value)
        close()
      }}
      renderItem={(item) => item.label}
    />
  )
}
