import { showToast } from '@motion/ui/base'
import { Sentry } from '@motion/web-base/sentry'
import { type CreateStageDefinitionRequestSchema } from '@motion/zod/client'

import { useCreateStageDefinition as useCreateStageDefinitionCall } from '~/global/rpc/v2'
import { useCallback } from 'react'

export const useCreateStageDefinition = () => {
  const { mutateAsync: createStageDefinition } = useCreateStageDefinitionCall()

  return useCallback(
    async (data: CreateStageDefinitionRequestSchema) => {
      if (!data.definition.workspaceId) {
        Sentry.captureException(
          'Stage definition created without workspace ID',
          {
            tags: {
              position: 'useCreateStageDefinition',
            },
          }
        )
        throw new Error('Workspace ID is required')
      }

      const response = await createStageDefinition({
        ...data,
        workspaceId: data.definition.workspaceId,
      })

      showToast('success', 'Stage created')

      return response.models[response.meta.model][response.id]
    },
    [createStageDefinition]
  )
}
