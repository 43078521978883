import { templateStr } from '@motion/react-core/strings'
import { Tooltip } from '@motion/ui/base'
import {
  useAccountFeatureUsage,
  useFeatureLimit,
  useIsFeatureLimitUnlimited,
  useShouldFeatureGate,
} from '@motion/web-billing'

import { TieredPricingUpsellGradientButton } from '~/areas/tiered-pricing/components/tiered-pricing-upsell-button'

export function NotetakerUpsellButton() {
  const shouldFeatureGate = useShouldFeatureGate()

  const isNotetakerLimitUnlimited =
    useIsFeatureLimitUnlimited('aiNotetakerLimit')

  const notetakerLimit = useFeatureLimit('aiNotetakerLimit')
  const notetakerUsage = useAccountFeatureUsage('aiNotetakerLimit') ?? 0

  const notetakerLeft = Math.max(notetakerLimit - notetakerUsage, 0)

  const upsellText =
    notetakerUsage < notetakerLimit
      ? templateStr(
          '{{notetakerLeft}} / {{notetakerLimit}} meetings remaining',
          {
            notetakerLeft,
            notetakerLimit,
          }
        )
      : 'Upgrade to get AI Notetaker in all of your meetings'

  const tooltipContent =
    notetakerLimit !== 0
      ? templateStr(
          'You have {{notetakerLeft}} / {{notetakerLimit}} meetings remaining on your current plan. Upgrade to Pro AI to get unlimited meetings',
          {
            notetakerLeft,
            notetakerLimit,
          }
        )
      : undefined
  if (!shouldFeatureGate || isNotetakerLimitUnlimited) {
    return null
  }

  return (
    <div className='self-start'>
      <Tooltip asChild content={tooltipContent}>
        <TieredPricingUpsellGradientButton
          feature='aiNotetakerLimit'
          featureLocked
          // We are showing the PROAI tier regardless of the "tiered-pricing-pro-ai" flag since
          // our rollout plan is to only enable the Notetaker for all users on the `tiered-pricing-pro-ai` FF
          tier='PROAI'
          sentiment='promotion'
          size='xsmall'
          iconPosition='left'
        >
          {upsellText}
        </TieredPricingUpsellGradientButton>
      </Tooltip>
    </div>
  )
}
