import { useSendToDesktop } from '@motion/desktop-ipc/hooks'
import { useSharedStateSendOnly } from '@motion/react-core/shared-state'
import { type LoginProvider } from '@motion/rpc/types'
import { isActiveStatus } from '@motion/shared/billing'
import { isEqual } from '@motion/utils/core'
import { logInDev, makeLog } from '@motion/web-base/logging'
import {
  type AuthStatus,
  type AuthStatusType,
  useAuth as useSharedAuth,
  useAuthStateChanged,
  type User as FirebaseUser,
} from '@motion/web-common/auth'
import {
  buildSubscriptionState,
  SubscriptionStateKey,
  useGetSubscriptionFn,
} from '@motion/web-common/subscriptions'
import { websocketsEventSubscriber } from '@motion/web-common/websockets'

import { useQueryClient } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { type StripeSubscription, type Subscription, type User } from './types'
import { clearAuthState } from './utils'

import api from '../../chromeApi/chromeApiContentScript'
import { handler as userHandler } from '../../services/UserServiceHandler'
import { useAppDispatch } from '../../state/hooks'
import { setStripeSubscription, setUser } from '../../state/userSlice'
import { type MotionLocalStorageResult } from '../storage/types'
import { useOnLocalStorageChange } from '../storage/useOnLocalStorageChange'

export type RefreshOptions = {
  silent?: boolean
  force?: boolean
  suppressUnset?: boolean
}

export type SubscriptionInfo =
  | { status: 'unset' }
  | { status: 'success'; value: Subscription }
  | { status: 'none'; subscription: StripeSubscription | null }
  | { status: 'error'; message: string }

export type AuthContextApi = {
  user: User | null
  state: AuthStatus
  subscription: SubscriptionInfo
  status: AuthStatusType
  loading: boolean
  refresh(opts?: RefreshOptions): Promise<void>
  logout(): Promise<void>
  isPro: boolean
}

const throwNoContext = () => {
  throw new Error('The AuthContext was not found.')
}

export const AuthContext = createContext<AuthContextApi>({
  user: null,
  subscription: { status: 'unset' },
  status: 'authenticating',
  state: { state: 'authenticating' },
  loading: true,
  logout: throwNoContext,
  refresh: throwNoContext,
  isPro: false,
})

type SubscriptionState = {
  subscription: SubscriptionInfo
}

type AuthProviderProps = {
  children: ReactNode
}

const REFRESH_DEBOUNCE = 30_000

const log = makeLog('auth-provider')

export const AuthProvider = (props: AuthProviderProps) => {
  const dispatch = useAppDispatch()
  const sendToDesktop = useSendToDesktop()
  const { auth, signOut } = useSharedAuth()
  const lastRefresh = useRef(Date.now())
  const client = useQueryClient()
  const getIndividualAndTeamSubscription = useGetSubscriptionFn({
    staleTime: 0,
    placeholderData: undefined,
  })

  const setSubscriptionState = useSharedStateSendOnly(SubscriptionStateKey)

  const [state, setState] = useState<SubscriptionState>({
    subscription: { status: 'unset' },
  })

  const logout = useCallback(async () => {
    setState((prev) => ({ ...prev, status: 'signing-out' }))
    await signOut()
    await clearAuthState()
    sendToDesktop('auth:logout')
    setState({
      subscription: { status: 'unset' },
    })
  }, [signOut, sendToDesktop])

  useAuthStateChanged((value) => {
    if (value.state === 'unauthenticated') {
      lastRefresh.current = 0
      clearAuthState()
      client.clear()
      return dispatch(setUser(null))
    }
    if (value.state === 'authenticated') {
      return dispatch(setUser(toReduxUser(value.user)))
    }
  })

  useEffect(() => {
    const handle = setTimeout(async () => {
      if (state.subscription.status === 'unset') {
        // Refresh the subscription
        await userHandler.getSubscription().catch(() => {})
      }
    }, 10_000)

    return () => {
      clearTimeout(handle)
    }
  }, [state.subscription.status])

  const update = useCallback(
    (
      data: MotionLocalStorageResult<
        'subscriptionType' | 'stripeSubscription' | 'subscriptionFetchError'
      >
    ) => {
      log('update', data)
      setState((prev) => {
        if (auth.state === 'signing-out') return prev

        const newSubscription = normalizeSubscriptionInfo(data)

        const subscription: SubscriptionInfo =
          auth.state !== 'authenticated' ? { status: 'unset' } : newSubscription

        if (!isEqual(subscription, prev.subscription)) {
          const effectiveSubscription =
            subscription.status === 'success' ? subscription.value : undefined
          dispatch(setStripeSubscription(effectiveSubscription?.stripe))
        }

        logInDev(
          'set-subscription-state',
          prev.subscription.status,
          newSubscription.status,
          subscription.status
        )

        return {
          loading: auth.state === 'authenticating',
          subscription,
        }
      })
    },
    [dispatch, auth.state]
  )

  const refresh = useCallback(
    async (opts?: RefreshOptions) => {
      if (!opts?.silent) {
        setState((prev) => ({
          ...prev,
          loading: true,
        }))
      }

      const shouldRefresh =
        auth.state === 'authenticated' &&
        (opts?.force || Date.now() - lastRefresh.current > REFRESH_DEBOUNCE)

      log('refresh', { ...opts, shouldRefresh })

      if (shouldRefresh) {
        lastRefresh.current = Date.now()

        // This unset will cause a re-mount of much of the
        // component tree, which we do not always want
        if (!opts?.suppressUnset) {
          setSubscriptionState({ state: 'unset' })
        }

        // Refresh the subscription
        await userHandler
          .getSubscription(true)
          .then(() => getIndividualAndTeamSubscription(undefined))
          .then((data) => setSubscriptionState(buildSubscriptionState(data)))
          .catch(() => {})
      }

      const data = await api.storage.local.get([
        'stripeSubscription',
        'subscriptionType',
        'subscriptionFetchError',
      ])
      update(data)
    },
    [auth.state, getIndividualAndTeamSubscription, setSubscriptionState, update]
  )

  useEffect(() => {
    return websocketsEventSubscriber.on('user.subscription.refresh', () => {
      void refresh({ force: true, silent: true, suppressUnset: true })
    })
  }, [refresh])

  useOnLocalStorageChange(
    ['stripeSubscription', 'subscriptionType', 'subscriptionFetchError'],
    (data) => {
      update(data)
    }
  )

  useEffect(() => {
    void refresh()
  }, [refresh])

  const authApi: AuthContextApi = useMemo(() => {
    return {
      user: auth.state === 'authenticated' ? toReduxUser(auth.user) : null,
      subscription: state.subscription,
      status: auth.state,
      loading: auth.state === 'authenticating' || auth.state === 'signing-out',
      state: auth,
      logout,
      refresh,
      get isPro() {
        if (state.subscription.status === 'error') {
          return false
        }
        if (state.subscription.status === 'unset') {
          return false
        }

        return state.subscription.status === 'none'
          ? false
          : state.subscription.value.isPro
      },
    }
    // auth.user is missing from this list, but that only changes based on `auth.state`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.state, logout, refresh, state.subscription])

  return (
    <AuthContext.Provider value={authApi}>
      {props.children}
    </AuthContext.Provider>
  )
}

/* eslint react-refresh/only-export-components: ["warn"] */
export const useAuth = () => {
  return useContext(AuthContext)
}

function toReduxUser(user: FirebaseUser): User {
  const provider =
    user.providerData.length > 0 ? user.providerData[0].providerId : null
  return {
    id: user.uid,
    email: user.email ?? '',
    displayName: user.displayName ?? user.email ?? 'Unknown',
    loginProvider: (provider as LoginProvider | null) ?? undefined,
    picture: user.photoURL ?? undefined,
    dateCreated: user.metadata.creationTime
      ? DateTime.fromHTTP(user.metadata.creationTime).toISO()
      : undefined,
  }
}

const calculateIsPro = (stripeSubscription: StripeSubscription | null) => {
  return Boolean(
    stripeSubscription &&
      stripeSubscription.status &&
      isActiveStatus(stripeSubscription.status) &&
      stripeSubscription.current_period_end &&
      DateTime.fromMillis(stripeSubscription.current_period_end * 1000).plus({
        days: 1,
      }) > DateTime.now()
  )
}

function normalizeSubscriptionInfo(
  data: MotionLocalStorageResult<
    'subscriptionType' | 'stripeSubscription' | 'subscriptionFetchError'
  >
): SubscriptionInfo {
  const active = calculateIsPro(data.stripeSubscription)

  if (data.subscriptionFetchError) {
    return { status: 'error', message: data.subscriptionFetchError }
  }

  if (data.subscriptionType === undefined) {
    return { status: 'unset' }
  }

  if (data.subscriptionType === null) {
    return { status: 'none', subscription: data.stripeSubscription ?? null }
  }

  if (data.stripeSubscription) {
    return {
      status: 'success',
      value: {
        type: data.subscriptionType ?? null,
        stripe: data.stripeSubscription,
        isPro: active,
      },
    }
  }
  return { status: 'unset' }
}
