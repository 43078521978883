import { DEFAULT_DOC_TITLE } from '@motion/ui-logic'

import { type ShareItemType } from '~/areas/modals/share-modal/types'
import { useNoteUrl } from '~/global/navigation'
import { useNoteById } from '~/global/rpc/v2'

export type ItemBeingShared = {
  name: string
  url: string
}

export const useItemBeingShared = (
  itemType: ShareItemType,
  itemId?: string
): ItemBeingShared | null => {
  const note = useNote(itemType === 'NOTE' ? itemId : undefined)

  switch (itemType) {
    case 'NOTE':
      return note
    default:
      throw new Error('Unknown item type')
  }
}

const useNote = (noteId?: string): ItemBeingShared | null => {
  const getNoteUrl = useNoteUrl()
  const { data } = useNoteById(
    { id: noteId },
    {
      enabled: !!noteId,
    }
  )

  if (!data) return null

  return {
    name: data.title.trim() || DEFAULT_DOC_TITLE,
    url: getNoteUrl({ noteId: data.id }).noteUrl,
  }
}
