import { type MentionsSearchResults } from '@motion/notes'
import { useDebouncedValue } from '@motion/react-core/hooks'

import { useMemo } from 'react'

import { createNoResultsOption } from '../../web-mentions-plugin'
import { type Reference } from '../types'

const MAX_SEARCH_RESULTS_PER_GROUP = 5

export function useReferenceSearch(query: string, references?: Reference[]) {
  const debouncedQuery = useDebouncedValue(query, { timeoutMs: 250 })

  const items = useMemo<MentionsSearchResults>(() => {
    if (references == null || references.length < 1) return []

    const results = (references ?? [])
      .map((reference) => {
        const entities = reference
          .query(debouncedQuery)
          .slice(0, MAX_SEARCH_RESULTS_PER_GROUP)
        if (entities.length === 0) return

        return {
          type: reference.type,
          label: reference.label,
          entities,
        }
      })
      .filter(Boolean)

    if (results.length < 1) {
      return [
        {
          entities: [createNoResultsOption()],
        },
      ]
    }

    return results
  }, [debouncedQuery, references])

  return {
    items,
  }
}
