import { NameField } from '~/areas/task-project/fields'
import { NAME_FIELD_RULE } from '~/global/rules'
import { useController } from 'react-hook-form'

import { useActionItemForm } from '../../hooks'

export const ControlledNameField = () => {
  const { form } = useActionItemForm()
  const { control } = form

  const {
    field,
    formState: { errors },
  } = useController({
    name: 'name',
    control,
    rules: NAME_FIELD_RULE,
  })

  return (
    <NameField
      error={errors.name != null}
      placeholder='Task name'
      label='Task name'
      value={field.value}
      onChange={field.onChange}
    />
  )
}
