import { templateStr } from '@motion/react-core/strings'
import { type TeamWithRelationsSerializer } from '@motion/rpc-types'
import { Button } from '@motion/ui/base'
import { INDIVIDUAL_ANNUAL_PLAN_SAVINGS_PERCENT } from '@motion/ui-logic/billing'
import { getTierAnnualPercentSavings } from '@motion/ui-logic/tiered-pricing'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { TrialDaysLeftBadge } from '~/components/Alerts/TrialDaysLeftBadge/TrialDaysLeftBadge'
import {
  useIsAdmin,
  useIsEnterpriseSubscription,
  useIsTeamTrialSetToCancel,
  useUpsellInfo,
} from '~/global/hooks'
import { useIsTeamExpired } from '~/global/hooks/team'
import { useGetTeamPrices } from '~/global/rpc/team'
import { useAppSelector } from '~/state/hooks'
import { selectStripeSubscription } from '~/state/userSlice'
import { DateTime } from 'luxon'

import { TierUpsellButton } from './tier-upsell-button'

import { useFooterTierUpsellData } from '../hooks'

export function ConnectedUpsellButtons() {
  const modalApi = useModalApi()
  const { data: teamPrices } = useGetTeamPrices()
  const isAdmin = useIsAdmin()
  const isExpiredTeam = useIsTeamExpired()
  const userStripeSubscription = useAppSelector(selectStripeSubscription)
  const { isEnterprise, isLoading: isEnterpriseLoading } =
    useIsEnterpriseSubscription()

  const {
    isOnTeamMonthly,
    isIndividualNoCardTrial,
    showTeamAnnualUpgrade,
    showIndividualAnnualUpgrade,
    stripeSubscription,
    team,
  } = useUpsellInfo()

  const showCreateTeam = showTeamAnnualUpgrade && !isOnTeamMonthly

  const isTeamTrialWillCancel = useIsTeamTrialSetToCancel(
    team?.teamSubscription
  )

  const showTrialDaysLeftUpsell =
    (isTeamTrialWillCancel && isAdmin) || isIndividualNoCardTrial

  const { isTieredPricingEnabled, currentTier, showTierUpsell } =
    useFooterTierUpsellData()

  if (!teamPrices || isEnterprise || isEnterpriseLoading) return null

  const annualSavingsPercent = isTieredPricingEnabled
    ? getTierAnnualPercentSavings(currentTier, isOnTeamMonthly)
    : isOnTeamMonthly
      ? teamPrices.annualSavingsPercentInteger
      : INDIVIDUAL_ANNUAL_PLAN_SAVINGS_PERCENT

  const shouldShowAnnualUpgrade = () => {
    if (!annualSavingsPercent) {
      return false
    }
    if (showIndividualAnnualUpgrade) {
      return true
    }
    return isOnTeamMonthly && isAdmin && !isIndividualNoCardTrial
  }
  const getTeamTrialExpiryISO = (team?: TeamWithRelationsSerializer | null) => {
    const trialEnd = Number(
      (team?.teamSubscription?.subscription as any)?.trial_end
    )
    return trialEnd > 0 ? DateTime.fromSeconds(trialEnd).toISO() : ''
  }

  return (
    <>
      {showTrialDaysLeftUpsell && (
        <TrialDaysLeftBadge
          trialExpiryISO={
            isTeamTrialWillCancel
              ? getTeamTrialExpiryISO(team)
              : userStripeSubscription?.trial_end
                ? DateTime.fromSeconds(
                    userStripeSubscription?.trial_end
                  ).toISO()
                : ''
          }
        />
      )}

      {showCreateTeam && (
        <Button
          onClick={() => {
            recordAnalyticsEvent('NAVBAR_CTA_CREATE_TEAM', {
              version: 'v2',
            })
          }}
          fullWidth
          sentiment='success'
          size='small'
          url={
            isExpiredTeam && isAdmin
              ? '/web/settings/team'
              : '/web/settings/team/create'
          }
          variant='outlined'
        >
          💪 Save {teamPrices.individualToTeamSavingsPercent}% with team plan
        </Button>
      )}

      {shouldShowAnnualUpgrade() && stripeSubscription && (
        <Button
          onClick={() => {
            recordAnalyticsEvent('NAVBAR_CTA_ACCOUNT_UPGRADE_ANNUAL', {
              isTeam: isOnTeamMonthly,
              version: 'v2',
            })
            modalApi.open('annual-upgrade-modal', {
              subscription: stripeSubscription,
            })
          }}
          fullWidth
          sentiment='success'
          size='small'
          variant='outlined'
        >
          {templateStr('🎉 Switch to annual to save {{savings}}%', {
            savings: annualSavingsPercent,
          })}
        </Button>
      )}

      {showTierUpsell && <TierUpsellButton />}
    </>
  )
}
