import { StatusCompletedSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import {
  type MeetingActionItemSchema,
  type MeetingInsightsSchema,
} from '@motion/rpc-types'
import { PopoverTrigger } from '@motion/ui/base'
import { StatusIcon } from '@motion/ui/pm'
import { createLookup } from '@motion/utils/object'

import { ActionItemList } from '~/areas/notetaker'
import { CellAction } from '~/areas/project-management/pages/pm-v3/tree-list/cells/components'
import { useCachedItems } from '~/global/cache'
import { type ReactNode, useEffect } from 'react'

type ActionItemsStatus = 'completed' | 'needs-action' | 'empty'

type ActionItemsCellProps = {
  meetingInsights: MeetingInsightsSchema
}

export function ActionItemsCell({ meetingInsights }: ActionItemsCellProps) {
  const actionItems = useCachedItems('meetingActionItems', (actionItems) =>
    actionItems.filter((item) => item.meetingInsightsId === meetingInsights.id)
  )

  const disabled =
    actionItems.filter(
      (actionItem) =>
        actionItem.triagedByUserId == null &&
        actionItem.potentialDuplicateTaskId == null
    ).length === 0
  const status = getStatus(actionItems)

  const { label, icon } = renderDetailLookup(status)(actionItems)

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <ActionItemDropdown actionItems={actionItems} close={close} />
      )}
    >
      <CellAction disabled={disabled}>
        <div className='flex items-center gap-2 [&>[data-icon]]:size-4'>
          {icon}
          <span className='text-semantic-neutral-text-default'>{label}</span>
        </div>
      </CellAction>
    </PopoverTrigger>
  )
}

type ActionItemDropdownProps = {
  actionItems: MeetingActionItemSchema[]
  close: () => void
}

function ActionItemDropdown({ actionItems, close }: ActionItemDropdownProps) {
  // Filter out action items that have already been triaged into a task
  const actionItemsWithoutTask = actionItems.filter(
    (item) => item.taskId == null
  )

  // Close the dropdown if there are no action items to review
  useEffect(() => {
    if (actionItemsWithoutTask.length === 0) {
      close()
    }
  }, [actionItemsWithoutTask.length, close])

  return (
    <div className='w-screen max-w-2xl max-h-[400px] overflow-auto rounded border-dropdown-border shadow-md p-3'>
      <ActionItemList actionItems={actionItemsWithoutTask} />
    </div>
  )
}

function getStatus(actionItems: MeetingActionItemSchema[]): ActionItemsStatus {
  if (actionItems.length === 0) {
    return 'empty'
  }

  return actionItems.every(
    (item) =>
      item.triagedByUserId != null || item.potentialDuplicateTaskId != null
  )
    ? 'completed'
    : 'needs-action'
}

const renderDetailLookup = createLookup<
  Record<
    ActionItemsStatus | 'default',
    (actionItems: MeetingActionItemSchema[]) => {
      label: ReactNode
      icon?: ReactNode
    }
  >
>({
  completed: () => {
    return {
      label: 'Completed',
      icon: <StatusCompletedSolid />,
    }
  },
  'needs-action': (actionItems) => {
    const needsReviewCount = actionItems.filter(
      (item) =>
        item.triagedByUserId == null && item.potentialDuplicateTaskId == null
    ).length

    return {
      label: templateStr(`{{count}} to review`, {
        count: <strong>{needsReviewCount}</strong>,
      }),
      icon: (
        <StatusIcon
          item={{
            color: '#FFB224',
            name: 'Needs Review',
            type: 'DEFAULT',
          }}
        />
      ),
    }
  },
  empty: () => {
    return {
      label: '-',
    }
  },
  default: () => {
    return {
      label: '-',
    }
  },
})
