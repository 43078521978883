import { DuplicateSolid, PencilSolid, TrashSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { classed } from '@motion/theme'
import { ButtonGroup, IconButton, PopoverTrigger } from '@motion/ui/base'
import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import {
  type ControllerRenderProps,
  type RegisterOptions,
  useController,
} from 'react-hook-form'

import { EditVariablePopover } from './edit-variable-popover'
import { VariableLabel } from './labels'

import { TextVariablePopoverContent } from '../flow-template-modal/fields/text-variables/copy-variable-popover-content'
import { useFlowTemplateForm } from '../shared-form'
import { DEFAULT_TEXT_VARIABLE_KEYS } from '../utils'

export type TextVariableFieldSwitchProps = {
  index: number
  onRemove: () => void
  rules?: RegisterOptions<
    FlowTemplateFormFields,
    `textVariables.${number}.name`
  >
  abbreviation?: string
}

export const TextVariableFieldWrapper = ({
  index,
  onRemove,
  abbreviation,
  rules,
}: TextVariableFieldSwitchProps) => {
  const {
    form: { control, watch },
  } = useFlowTemplateForm()

  const { field: nameField } = useController({
    name: `textVariables.${index}.name`,
    rules,
    control,
  })

  const variableKey = watch(`textVariables.${index}.key`)

  const isDefault = DEFAULT_TEXT_VARIABLE_KEYS.includes(variableKey)

  return (
    <TextVariableField
      isDefault={isDefault}
      nameField={nameField}
      variableKey={variableKey}
      index={index}
      abbreviation={abbreviation}
      onRemove={onRemove}
    />
  )
}

type TextVariableFieldProps = {
  nameField: ControllerRenderProps<
    FlowTemplateFormFields,
    `textVariables.${number}.name`
  >
  isDefault: boolean
  variableKey: string
  index: number
  abbreviation?: string
  onRemove: () => void
}

const TextVariableField = ({
  isDefault,
  nameField,
  index,
  variableKey,
  abbreviation,
  onRemove,
}: TextVariableFieldProps) => {
  const { value: name } = nameField

  const label = templateStr('{{ name }}: {{ label }}', {
    name,
    label: (
      <VariableLabel
        value={{ name, color: 'blue' }}
        truncateLabel
        abbreviation={abbreviation}
      />
    ),
  })

  return (
    <div className='flex flex-row items-center gap-2 justify-between'>
      <VariableTruncatedLabel>{label}</VariableTruncatedLabel>

      <ButtonGroup size='xsmall'>
        {!isDefault && (
          <PopoverTrigger
            placement='bottom-start'
            renderPopover={({ close }) => {
              return (
                <EditVariablePopover
                  type='text'
                  nameField={nameField}
                  colorField={undefined}
                  index={index}
                  close={close}
                />
              )
            }}
          >
            <IconButton
              icon={PencilSolid}
              variant='muted'
              sentiment='neutral'
              size='small'
            />
          </PopoverTrigger>
        )}
        <PopoverTrigger
          placement='bottom-start'
          renderPopover={({ close }) => {
            return (
              <TextVariablePopoverContent
                close={close}
                name={nameField.value}
                variableKey={variableKey}
              />
            )
          }}
        >
          <IconButton
            icon={DuplicateSolid}
            variant='muted'
            sentiment='neutral'
            size='small'
          />
        </PopoverTrigger>
        {!isDefault && (
          <IconButton
            icon={TrashSolid}
            variant='muted'
            sentiment='neutral'
            size='small'
            onClick={onRemove}
          />
        )}
      </ButtonGroup>
    </div>
  )
}

const VariableTruncatedLabel = classed('div', {
  base: 'flex flex-row items-center gap-1.5 text-semantic-neutral-text-subtle text-2xs',
})
