import { type SidebarState, useSidebarState } from '~/global/hooks'
import { useCallback } from 'react'

export type SidebarMetaData = {
  view?: 'comments' | 'details'
}

export const useNoteSidebarState = () => {
  const [sidebarState, setSidebarState] = useSidebarState('note')

  const toggleNoteSidebarState = useCallback(
    (view: SidebarMetaData['view']) => {
      const updates: Partial<SidebarState> = { meta: { view } }
      if (!sidebarState.open) {
        updates.open = true
      } else if (view === sidebarState.meta?.view && sidebarState.open) {
        updates.open = false
      }

      setSidebarState(updates)
    },
    [setSidebarState, sidebarState.meta?.view, sidebarState.open]
  )

  const setNoteSidebarState = useCallback(
    (open: boolean, view: SidebarMetaData['view']) => {
      setSidebarState({ open, meta: { view } })
    },
    [setSidebarState]
  )

  return [sidebarState, setNoteSidebarState, toggleNoteSidebarState] as const
}
