import { EmptyState } from './empty-state'
import { useProjects } from './hooks/use-projects'
import { ProjectTreeList } from './project-list'

import { LoadingPage } from '../../pm-revamp/common/loading-page'
import { BulkOpsContainer } from '../bulk-ops'
import { useProjectGroupBy, useViewState } from '../view-state'

export const ConnectedProjectList = () => {
  const groupDefinitions = useProjectGroupBy()
  const [viewState] = useViewState()
  const projects = useProjects()

  if (projects == null) {
    return <LoadingPage id='connected-project-list' />
  }

  if (
    projects.length < 1 &&
    groupDefinitions.length > 0 &&
    viewState.groupBy.hideEmpty
  ) {
    return <EmptyState target='projects' />
  }

  return (
    <div className='flex-1 flex min-w-0 min-h-0'>
      <BulkOpsContainer target='projects'>
        <ProjectTreeList
          projects={projects}
          groupBy={groupDefinitions}
          hideEmptyGroups={viewState.groupBy.hideEmpty}
        />
      </BulkOpsContainer>
    </div>
  )
}
