import { classed } from '@motion/theme'

export const FormShell = classed('form', {
  base: `
    bg-modal-bg
    rounded-lg
    shadow-lg
    border border-semantic-neutral-border-default
    w-full h-full
  `,
})

export const GridShell = classed('div', {
  base: `
    h-full
    grid
    grid-cols-[260px_1fr]
    grid-rows-[1fr_min-content]
    [grid-template-areas:'sidebar_stages''footer_footer']
  `,
})

export const Sidebar = classed('div', {
  base: `
    [grid-area:sidebar]
    py-4 px-3
    flex flex-col

    overflow-x-hidden
    overflow-y-auto

    rounded-tl-lg
    bg-semantic-neutral-surface-raised-bg-subtlest
    border-r-[0.5px] border-semantic-neutral-border-default
  `,
  variants: {
    largeGap: {
      true: 'gap-6',
      false: 'gap-5',
    },
  },
  defaultVariants: {
    largeGap: false,
  },
})

export const SidebarSection = classed('div', {
  base: `
    flex flex-col gap-3
  `,
})

export const SidebarHeader = classed('div', {
  base: `
    flex flex-col gap-2
  `,
})

export const SidebarTitle = classed('h2', {
  base: `
    text-sm font-bold text-semantic-neutral-text-default
    flex items-center gap-1.5
    pl-1
  `,
})

export const SidebarDescription = classed('p', {
  base: `
    text-xs text-semantic-neutral-text-subtle
    pl-1

  `,
})

export const SidebarSectionFields = classed('div', {
  base: `
    flex flex-col gap-0.5
  `,
})

export const StagesContent = classed('div', {
  base: `
    [grid-area:stages]
    flex flex-col
    overflow-x-auto
  `,
  variants: {
    overflowXHidden: {
      true: 'overflow-x-hidden',
      false: 'overflow-x-auto',
    },
  },
  defaultVariants: {
    overflowXHidden: false,
  },
})

export const StagesTitleContainer = classed('div', {
  base: `
    flex flex-row items-center justify-between

    pt-[18px]
    px-5 pl-[22px]
  `,
})

export const StagesTitle = classed('h2', {
  base: `
    text-base font-bold text-semantic-neutral-text-default
  `,
})

export const ArrowsHintContainer = classed('div', {
  base: `
    flex flex-row items-center gap-3 text-2xs text-semantic-neutral-text-subtle font-semibold
  `,
})

export const StageColumn = classed('div', {
  base: `
    flex flex-col gap-6 shrink-0
    h-full
    overflow-y-auto
    overflow-x-hidden
  `,
  variants: {
    newStage: {
      true: `
      flex
      bg-semantic-neutral-surface-raised-bg-subtlest
      rounded-[4px]
    `,
    },
    center: {
      true: `
        items-center justify-center
      `,
    },
  },
})

export const ShellFooter = classed('footer', {
  base: `
    [grid-area:footer]
    px-4
    flex items-center justify-between
    border-t-[0.5px] border-semantic-neutral-border-default
  `,
})

export const MainButtonFooter = classed('div', {
  base: `
    flex gap-3 items-center justify-end

    py-3
    `,
})

export const FlowTemplateCard = classed(
  'div',
  'flex flex-col w-[235px] h-[140px] border border-field-border-default rounded overflow-hidden drop-shadow-sm'
)

export const LegacyTemplateCard = classed(
  'div',
  'flex w-[235px] border border-field-border-default rounded overflow-hidden drop-shadow-sm p-3 gap-2 items-center h-10'
)
