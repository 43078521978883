import { type DeadlineType } from '@motion/rpc/types'

import {
  HardDeadlineModalFieldButton,
  SubItemIndentMarker,
} from '~/areas/tasks/modals/task-modal/components'
import { useController } from 'react-hook-form'

import { useActionItemForm } from '../../hooks'

export const ControlledDeadlineToggle = () => {
  const { form } = useActionItemForm()

  const { control, watch } = form

  const { field: deadlineTypeField } = useController({
    name: 'deadlineType',
    control,
  })

  const priorityLevel = watch('priorityLevel')
  const deadlineType = deadlineTypeField.value

  const disabled = priorityLevel === 'ASAP'

  const onChange = (value: DeadlineType) => {
    deadlineTypeField.onChange(value)
  }

  const hardDeadlineField = (
    <HardDeadlineModalFieldButton
      deadlineType={deadlineType}
      onChange={onChange}
      disabled={disabled}
    />
  )

  return (
    <div className='flex flex-row items-center gap-0.5'>
      <SubItemIndentMarker />
      {hardDeadlineField}
    </div>
  )
}
