import { classed } from '@motion/theme'
import { HasExperiment } from '@motion/web-common/flags'

import {
  type StageTaskCardFieldsContextValue,
  StageTaskCardFieldsProvider,
} from '~/areas/flows/contexts'

import { AssigneeFields } from './assignee-fields'
import { BlockedByField } from './blocked-by-field'
import { DeprecatedBlockedByField } from './deprecated-blocked-by-field'
import { DurationFields } from './duration-fields'
import { LabelFields } from './label-fields'
import { PriorityFields } from './priority-fields'
import { RelativeDateFields } from './relative-date-fields'
import { StatusFields } from './status-fields'

/**
 * These components rely on the StageTaskCardFieldsProvider context
 * for their state and handlers.
 *
 * This is so that this can be reused as the stage task card regardless of form.
 *
 * Memoize `fields` in caller, as it is an object.
 */
export const StageTaskCardFields = (props: StageTaskCardFieldsContextValue) => {
  return (
    <StageTaskCardFieldsProvider value={props}>
      <TaskCardFields>
        <RelativeDateFields />
        <StatusFields />
        <DurationFields />
        <PriorityFields />
        <AssigneeFields />
        <LabelFields />
        <HasExperiment
          name='flows-multiple-blockers'
          fallback={<DeprecatedBlockedByField />}
        >
          <BlockedByField />
        </HasExperiment>
      </TaskCardFields>
    </StageTaskCardFieldsProvider>
  )
}

const TaskCardFields = classed('div', {
  base: `
    grid grid-cols-2 gap-1
    px-2
    grid-rows-[24px_24px_24px_24px]
    [&_*]:!text-[11px]
  `,
})
