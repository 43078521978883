import {
  AutoscheduleStarSolid,
  GlobeAltOutline,
  MenuAlt2Outline,
  NoteAddSolid,
  NoteSolid,
  type SvgIcon,
} from '@motion/icons'
import {
  type AgentVariableType,
  type AgentWorkflowStepType,
} from '@motion/zod/client'

export const agentVariableTypeToData: Record<
  AgentVariableType,
  { label: string; icon: SvgIcon }
> = {
  TEXT: { label: 'Text', icon: MenuAlt2Outline },
  DOCUMENTS: { label: 'Documents', icon: NoteSolid },
  LOCATION: { label: 'Location', icon: MenuAlt2Outline },
}

export const agentStepTypeToData: Record<
  AgentWorkflowStepType,
  { label: string; icon: SvgIcon }
> = {
  LLM_GENERATION: { label: 'Generate text', icon: AutoscheduleStarSolid },
  CREATE_DOCUMENT: { label: 'Create doc', icon: NoteAddSolid },
  WEB_RESEARCH: { label: 'Web research', icon: GlobeAltOutline },
}
