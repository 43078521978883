import { useContext } from 'react'

import {
  NoteEditorAPIContext,
  type NoteEditorAPIContextValue,
} from './note-editor-context'

export function useNoteEditorAPI(): NoteEditorAPIContextValue {
  const context = useContext(NoteEditorAPIContext)
  if (!context) {
    throw new Error(
      'useNoteEditorAPI must be used within a NoteEditorAPIProvider'
    )
  }
  return context
}
