import { PencilSolid, PuzzleSolid, WorkspaceSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { ProjectPalette } from '@motion/ui/project'
import { stripHtml } from '@motion/ui-logic'
import {
  type ProjectDefinitionSchema,
  type StageDefinitionSchema,
} from '@motion/zod/client'

import {
  CardTitle,
  FlowCardBottomGradient,
  FlowCardHeader,
  FlowDescription,
  FlowDescriptionContainer,
  FlowTemplateContainer,
  ProjectTemplateCountHoverCard,
  TemplateStages,
} from '~/areas/flows/components'
import { MotionLink } from '~/global/components'
import { ColorDropdown } from '~/global/components/dropdowns'
import {
  useProjectDefinitionWithProjectInfo,
  useWorkspaceById,
} from '~/global/hooks'

import { useUpdateProjectDefinition } from '../../hooks'

export type FlowTemplateCardLinkProps = {
  name: ProjectDefinitionSchema['name']
  stages: readonly Pick<StageDefinitionSchema, 'name' | 'color' | 'id'>[]
  url: string
  editTemplateUrl?: string
  id: string
}
export function FlowTemplateCardLink({
  name,
  url,
  editTemplateUrl,
  id,
}: FlowTemplateCardLinkProps) {
  const projectDefinition = useProjectDefinitionWithProjectInfo(id)

  const updateFlowTemplate = useUpdateProjectDefinition()
  const workspace = useWorkspaceById(projectDefinition?.workspaceId)

  if (!projectDefinition) {
    return null
  }

  return (
    <MotionLink url={url}>
      <FlowTemplateContainer>
        <FlowDescriptionContainer>
          <FlowCardHeader>
            <div className='flex items-center gap-3 max-w-[calc(100%-48px)]'>
              <ProjectPalette color={projectDefinition.color ?? 'gray'}>
                <ColorDropdown
                  selectedColor={projectDefinition.color ?? 'gray'}
                  onChange={(color) => {
                    void updateFlowTemplate(id, projectDefinition.workspaceId, {
                      definition: {
                        ...projectDefinition,
                        color,
                      },
                    })
                  }}
                >
                  <div className='flex p-1 items-center rounded bg-palette-bg-light cursor-pointer hover:bg-palette-bg-hover focus:bg-palette-bg-hover size-5'>
                    <PuzzleSolid className='size-3 text-palette-highlight-default' />
                  </div>
                </ColorDropdown>
              </ProjectPalette>

              {workspace && (
                <div className='flex p-x-[1px] items-center gap-1 min-w-0'>
                  <WorkspaceSolid className='size-3 text-semantic-neutral-icon-default' />
                  <span className='text-semantic-neutral-text-subtle leading-3 text-2xs truncate max-w-full'>
                    {workspace.name}
                  </span>
                </div>
              )}
            </div>

            {editTemplateUrl && (
              <Button
                sentiment='neutral'
                size='xsmall'
                url={editTemplateUrl}
                variant='muted'
                aria-label='Edit flow template'
              >
                <PencilSolid />
                Edit
              </Button>
            )}
          </FlowCardHeader>
          <CardTitle>{name}</CardTitle>
          <FlowDescription className='truncate'>
            {projectDefinition.definitionDescription
              ? stripHtml(projectDefinition.definitionDescription)
              : 'No Description provided'}
          </FlowDescription>
          <ProjectTemplateCountHoverCard
            items={projectDefinition.projects}
            type='project'
            parentType='template'
          />
        </FlowDescriptionContainer>
        <TemplateStages flowTemplate={projectDefinition} />
        <FlowCardBottomGradient />
      </FlowTemplateContainer>
    </MotionLink>
  )
}
