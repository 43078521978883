import { isSystemVariableKey } from '@motion/shared/flows'
import {
  convertFormStagesToStageDefinition,
  type FlowTemplateFormFields,
} from '@motion/ui-logic/pm/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import {
  type CreateProjectDefinitionRequestSchema,
  type UpdateProjectDefinitionRequestSchema,
} from '@motion/zod/client'

import { useFlowsModalState } from '~/areas/flows/contexts'
import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { useCallback } from 'react'

import { useFlowTemplateModalUrlParams } from './use-flows-template-modal-url'

import {
  useCreateProjectDefinition,
  useUpdateProjectDefinition,
} from '../../hooks'
import { recordPresetAnalytics } from '../presets/utils'

export function useSaveFlow() {
  const {
    form: { reset, watch },
  } = useFlowTemplateForm()
  const updateProjectDefinition = useUpdateProjectDefinition()
  const createProjectDefinition = useCreateProjectDefinition()

  const {
    template: templateOperationParam,
    fromPresetId,
    isEnterprise,
  } = useFlowTemplateModalUrlParams()
  const { resetDirtyState, originalTaskIndicesMap } = useFlowsModalState()

  const flowTemplateId = watch('id')
  const workspaceId = watch('workspaceId')

  const isCreatingFromPreset =
    templateOperationParam === 'new' && fromPresetId != null

  return useCallback(
    async (fields: FlowTemplateFormFields) => {
      if (workspaceId == null) {
        throw new Error('Workspace id not defined')
      }

      if (flowTemplateId != null) {
        const updates = convertFormFieldsForUpdate(
          fields,
          originalTaskIndicesMap
        )

        const updatedFlow = await updateProjectDefinition(
          flowTemplateId,
          workspaceId,
          updates
        )

        reset({}, { keepValues: true })
        resetDirtyState()

        return updatedFlow
      }
      if (isCreatingFromPreset) {
        if (!isEnterprise) {
          recordPresetAnalytics(fromPresetId, fields)
        } else {
          recordAnalyticsEvent('FLOW_TEMPLATE_ENTERPRISE_PRESET_USED', {
            templateId: fromPresetId,
          })
        }
      }

      const createPayload = convertFieldsForCreate(fields)

      const createdFlow = await createProjectDefinition(createPayload)

      reset({}, { keepValues: true })
      resetDirtyState()

      return createdFlow
    },
    [
      createProjectDefinition,
      flowTemplateId,
      fromPresetId,
      isCreatingFromPreset,
      isEnterprise,
      originalTaskIndicesMap,
      reset,
      resetDirtyState,
      updateProjectDefinition,
      workspaceId,
    ]
  )
}

function convertFieldsForCreate(
  fields: FlowTemplateFormFields
): CreateProjectDefinitionRequestSchema {
  const stages = convertFormStagesToStageDefinition(fields.stages, {})

  return {
    definition: {
      definitionDescription: fields.definitionDescription,
      description: fields.description,
      labelIds: fields.labelIds,
      managerId: fields.managerId,
      name: fields.name,
      priorityLevel: fields.priorityLevel,
      stageDefinitionReferences: fields.stages.map((stage) => ({
        stageDefinitionId: stage.id,
      })),
      stages,
      variables: [...fields.textVariables, ...fields.roles].filter(
        (v) => !isSystemVariableKey(v.key)
      ),
      workspaceId: fields.workspaceId,
      color: fields.color,
      folderId: fields.folderId,
    },
    mode: fields.mode,
  }
}

// Unlike other update calls, this is a PUT & requires the full definition to be sent
function convertFormFieldsForUpdate(
  fields: FlowTemplateFormFields,
  originalTaskIndicesMap: Record<string, readonly [number, number]>
): UpdateProjectDefinitionRequestSchema {
  return {
    definition: {
      definitionDescription: fields.definitionDescription,
      description: fields.description,
      labelIds: fields.labelIds,
      managerId: fields.managerId,
      name: fields.name,
      priorityLevel: fields.priorityLevel,
      stageDefinitionReferences: fields.stageDefinitionReferences,
      stages: convertFormStagesToStageDefinition(
        fields.stages,
        originalTaskIndicesMap
      ),
      variables: [...fields.textVariables, ...fields.roles].filter(
        (v) => !isSystemVariableKey(v.key)
      ),
      workspaceId: fields.workspaceId,
      color: fields.color,
      folderId: fields.folderId,
    },
  }
}
