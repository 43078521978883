import { useFetchProjectTasks } from '~/areas/task-project/hooks'
import { useProject, useProjectDefinition } from '~/global/hooks'
import { useMemo } from 'react'

// get attachments count for a project, including task definition attachments
export function useGetProjectAttachmentsCount({
  projectId,
}: {
  projectId: string
}) {
  const project = useProject(projectId)
  const { data: tasks } = useFetchProjectTasks({
    projectId: project?.id,
    workspaceId: project?.workspaceId,
  })
  const projectDefinition = useProjectDefinition(project?.projectDefinitionId)

  return useMemo(() => {
    const taskAttachmentCount = tasks?.flatMap(
      (task) => task.uploadedFileIds || []
    ).length

    const taskDefinitionAttachmentCount = projectDefinition
      ? projectDefinition.stages
          .flatMap((stage) => stage.tasks)
          .flatMap((task) => task.uploadedFileIds || []).length
      : 0

    const childAttachmentCount =
      taskAttachmentCount + taskDefinitionAttachmentCount
    const projectAttachmentCount =
      (project?.uploadedFileIds?.length ?? 0) +
      (projectDefinition?.uploadedFileIds ?? []).length

    return {
      childAttachmentCount,
      totalAttachmentCount: childAttachmentCount + projectAttachmentCount,
      projectAttachmentCount,
    }
  }, [project, tasks, projectDefinition])
}
