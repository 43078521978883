import {
  type BookingConflictCalendar,
  type BookingLinkTemplate,
  type BookingQuestion,
  type Calendar,
  CalendarProviderType,
  type EmailAccount,
} from '@motion/rpc/types'
import { type DayVerbose } from '@motion/rpc-types'
import { type EventConferenceType } from '@motion/shared/common'
import { logEvent } from '@motion/web-base/analytics'
import { makeLog } from '@motion/web-base/logging'
import { type CalendarSchema } from '@motion/zod/client'

import {
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { Events } from '../../../../../analyticsEvents'
import { useSendingEmailsDisabled } from '../../../../../areas/project-management/hooks/use-sending-emails-disabled'
import {
  createBookingLinkTemplate,
  selectBookingTemplates,
  updateBookingLinkTemplate,
} from '../../../../../state/booking/booking-slice'
import { useAppDispatch, useAppSelector } from '../../../../../state/hooks'
import { type Team } from '../../../../../state/TeamTypes'
import { type User } from '../../../../../state/userSlice'
import { defaultAvailabilityTemplate } from '../../../../../storageConstants'
import {
  type AvailabilityQuestionType,
  type ConflictCalendar,
  type ScheduleByDow,
} from '../../../../../types/bookingTypes'
import {
  conferenceTypeToFirebase,
  conferenceTypeToGeneric,
  generateRandomPermanentLinkId,
  getFirebaseTemplateLinkId,
  questionTypeToFirebase,
  questionTypeToGeneric,
} from '../../../../../utils/booking-utils'
import { resolveCalendarId } from '../../../../../utils/calendarUtils'
import {
  calendarToConflictCalendar,
  createDaysString,
  createDefaultTemplate,
  createStartsInString,
  parseDaysString,
  parseStartsInString,
  resolvePrimaryConflictCalendars,
} from '../template-form.utils'

const log = makeLog('[use-booking-template]')

const isURLFormat = (text: string) => {
  return /^[0-9a-zA-Z-]+$/.test(text)
}

type Props = {
  calendars: CalendarSchema[]
  conferenceType: string
  conferenceTypesLoaded: boolean
  editingTemplateId?: string
  emailAccounts: EmailAccount[]
  getConferenceType: () => {
    conferenceOptions: string[]
    conferenceType: EventConferenceType
  }
  hostCalendar?: CalendarSchema | null
  hostEmailAccount?: EmailAccount
  /**
   * Optionally control when the `processAvailabilityTemplateSettings` effect
   * runs
   * @returns boolean return true to run the effect. Not defining this function
   * will cause the effect to run immediately
   */
  shouldInitSettings?: () => boolean
  /**
   * If the editingTemplateId doesn't exist, this will cause a default template
   * to be created
   */
  shouldCreateDefaultTemplate?: boolean
  mainCalendar?: CalendarSchema
  modalVisibleHandler?: (visible: boolean) => void
  scrollContainerRef?: React.RefObject<HTMLElement | null>
  setConferenceType: (type: EventConferenceType) => void
  setHostCalendar: (calendar: CalendarSchema | null | undefined) => void
  setHostEmailAccount: (emailAccount: EmailAccount) => void
  team?: Team
  teamInviteModalHandler?: (guests: string[]) => void
  user: User
}

/**
 * Hook for managing the selected template form state. It encapsulates all the
 * necessary useStates, and exposes some field change callbacks for certain
 * fields.
 * @param props
 * @returns
 */
export function useBookingTemplate(props: Props) {
  const {
    calendars,
    conferenceType,
    conferenceTypesLoaded,
    editingTemplateId,
    emailAccounts,
    getConferenceType,
    hostCalendar,
    hostEmailAccount,
    modalVisibleHandler,
    scrollContainerRef,
    shouldCreateDefaultTemplate,
    shouldInitSettings,
    setConferenceType,
    setHostCalendar,
    setHostEmailAccount,
    team,
    teamInviteModalHandler,
    user,
  } = props

  const dispatch = useAppDispatch()

  const emailAccountsMap = useMemo(
    () => new Map<string, EmailAccount>(emailAccounts.map((e) => [e.id, e])),
    [emailAccounts]
  )

  const bookingTemplates: Record<string, BookingLinkTemplate> = useAppSelector(
    selectBookingTemplates
  )

  const [name, setName] = useState('')
  const [scheduleId, setScheduleId] = useState('work')
  const [durationChoices, setDurationChoices] = useState([30])
  const [bufferMins, setBufferMins] = useState(15)
  const [daysSpan, setDaysSpan] = useState('14 days')
  const [startsIn, setStartsIn] = useState('Tomorrow')
  const [externalEventName, setExternalEventName] = useState('')
  const [hostDisplayName, setHostDisplayName] = useState('')

  const [createdByUserId, setCreatedByUserId] = useState<string | undefined>('')
  const [guests, setGuests] = useState<string[]>([])
  const [hasBlocking, setHasBlocking] = useState(false)
  const [maxDailyMeetings, setMaxDailyMeetings] = useState(0)
  const [isMaxDailyMeetingsDisabled, setIsMaxDailyMeetingsDisabled] =
    useState(false)
  const [hasReminderEmail, setHasReminderEmail] = useState(false)
  const [reminderEmailPreBookingMins, setReminderEmailPreBookingMins] =
    useState(60)
  const [reminderEmailSubject, setReminderEmailSubject] = useState('')
  const [reminderEmailBody, setReminderEmailBody] = useState('')

  const [errorMessage, setErrorMessage] = useState('')
  const [conflictCalendars, _setConflictCalendars] = useState<
    ConflictCalendar[]
  >([])
  const [timepickerDays, setTimepickerDays] = useState<ScheduleByDow>(
    {} as ScheduleByDow
  )
  const [customScheduleTimezone, setCustomScheduleTimezone] = useState<
    string | null | undefined
  >('default')
  const [ignoreInviteTeammates, setIgnoreInviteTeammates] = useState<string[]>(
    []
  )
  const [linkSlug, setLinkSlug] = useState('')
  const [questions, setQuestions] = useState<AvailabilityQuestionType[]>([])

  const [selectedConflictCalendars, setSelectedConflictCalendars] = useState<
    CalendarSchema[]
  >([])

  const initRef = useRef(false)
  const [loading, setLoading] = useState(false)

  const [reminderEmailForbiddenMessage, setReminderEmailForbiddenMessage] =
    useState('')

  const sendingEmailsMessage = useSendingEmailsDisabled(
    hostEmailAccount?.email,
    hostCalendar ? resolveCalendarId(hostCalendar) : undefined
  )

  // The cockroach-specific booking link, which is different from the
  // editingTemplateId, which is a combination fo the user/team ID + slug
  const [bookingLinkId, setBookingLinkId] = useState<string | null>(null)

  const setConflictCalendars = useCallback(
    (updatedCalendars: SetStateAction<ConflictCalendar[]>) => {
      _setConflictCalendars(updatedCalendars)

      const actualCalendars = Array.isArray(updatedCalendars)
        ? updatedCalendars
        : updatedCalendars(conflictCalendars)

      // Convert conflict calendars to new schema calendars
      const foundCalendars = []
      for (const conflictCalendar of actualCalendars) {
        const calendar = calendars.find((c) => {
          if (c.providerId !== conflictCalendar.calendarId) {
            return false
          }

          const emailAccount = emailAccountsMap.get(c.emailAccountId)
          if (!emailAccount) {
            return false
          }

          return emailAccount.email === conflictCalendar.email
        })

        if (!calendar) {
          continue
        }

        foundCalendars.push(calendar)
      }

      setSelectedConflictCalendars(foundCalendars)
    },
    [emailAccountsMap, conflictCalendars, calendars]
  )

  const onSelectedConflictCalendars = useCallback(
    (calendars: CalendarSchema[] | Calendar[], guestProviderIds: string[]) => {
      setGuests(guestProviderIds)

      const conflictCalendars: ConflictCalendar[] = []

      for (const calendar of calendars) {
        const emailAccount = emailAccountsMap.get(calendar.emailAccountId)
        if (!emailAccount) {
          continue
        }

        conflictCalendars.push(
          calendarToConflictCalendar(calendar, emailAccount)
        )
      }

      setConflictCalendars(conflictCalendars)
    },
    [emailAccountsMap, setConflictCalendars]
  )

  useEffect(
    function processAvailabilityTemplateSettings() {
      const doInitTemplateSettings = async () => {
        // process availability template settings
        if (!initRef.current) {
          initRef.current = true
          if (editingTemplateId || shouldCreateDefaultTemplate) {
            let templateSettings: BookingLinkTemplate | undefined =
              bookingTemplates[editingTemplateId ?? '']

            if (!templateSettings && shouldCreateDefaultTemplate) {
              // Fallback to pickup first template containing the
              // 'meeting' link slug
              templateSettings = Object.values(bookingTemplates).find(
                (t) => t.linkSlug === 'meeting'
              )
            }

            if (templateSettings) {
              setBookingLinkId(templateSettings.id)
            } else {
              // If the template is not found, the `shouldCreateDefaultTemplate`
              // being defined creates a default template. This flag is usually
              // only defined during booking onboarding.
              if (
                !shouldCreateDefaultTemplate ||
                !hostEmailAccount ||
                !hostCalendar
              ) {
                return
              }

              const template = createDefaultTemplate(
                hostEmailAccount,
                hostCalendar,
                user,
                calendars,
                emailAccountsMap
              ) as BookingLinkTemplate
              const defaultTemplate = await dispatch(
                createBookingLinkTemplate(template)
              ).unwrap()
              templateSettings = defaultTemplate
              setBookingLinkId(defaultTemplate.id)
            }

            const minimumDuration = templateSettings.durationChoices
              ? Math.min(...templateSettings.durationChoices)
              : 0
            setName(templateSettings.name || '')
            setTimepickerDays(
              (templateSettings.customSchedule?.schedule ||
                {}) as unknown as ScheduleByDow
            )
            setDurationChoices(
              templateSettings.durationChoices &&
                templateSettings.durationChoices.length
                ? templateSettings.durationChoices
                : minimumDuration
                  ? [minimumDuration]
                  : [15, 30, 60]
            )
            setHasBlocking(!!templateSettings.blockingTimeMins)
            setBufferMins(templateSettings.bufferMins ?? 15)
            setDaysSpan(createDaysString(templateSettings.daysSpan as number))
            setStartsIn(
              createStartsInString(templateSettings.startsIn || 'today')
            )
            setConferenceType(
              conferenceTypeToFirebase(templateSettings.conferenceType)
            )
            setHostDisplayName(
              templateSettings.hostDisplayName || (user ? user.displayName : '')
            )

            const emailAccount = emailAccounts.find(
              (e) => e.email === templateSettings?.hostEmail
            )
            if (emailAccount && emailAccount.id !== hostEmailAccount?.id) {
              setHostEmailAccount(emailAccount)
            }

            const calendar = calendars.find(
              (c) =>
                c.providerId === templateSettings?.hostCalendarId &&
                c.emailAccountId === emailAccount?.id
            )
            setHostCalendar(calendar)

            setExternalEventName(templateSettings.externalEventName || '')
            setMaxDailyMeetings(templateSettings.maxDailyMeetings || 0)
            setHasReminderEmail(!!templateSettings.hasReminderEmail)
            setReminderEmailPreBookingMins(
              templateSettings.reminderEmailPreBookingMins || 60
            )
            setReminderEmailSubject(
              templateSettings.reminderEmailSubject ||
                defaultAvailabilityTemplate.reminderEmailSubject
            )
            setReminderEmailBody(
              templateSettings.reminderEmailBody ||
                defaultAvailabilityTemplate.reminderEmailBody
            )

            if (templateSettings.conflictCalendars) {
              setConflictCalendars(
                templateSettings.conflictCalendars as ConflictCalendar[]
              )
            } else {
              setConflictCalendars(
                resolvePrimaryConflictCalendars(calendars, emailAccountsMap)
              )
            }

            setGuests(templateSettings.guestEmails || [])
            setLinkSlug(
              templateSettings.linkSlug || generateRandomPermanentLinkId()
            )
            setCreatedByUserId(templateSettings.createdByUserId)
            setCustomScheduleTimezone(
              (templateSettings.customSchedule?.timezone as string) || 'default'
            )
            setQuestions(
              (templateSettings.questions || []).map((question) => {
                return {
                  text: question.text,
                  choices: question.choices.map((choice) => {
                    return {
                      text: choice,
                    }
                  }),
                  type: questionTypeToFirebase(question.questionType),
                  required: question.required,
                }
              }) || []
            )
            setScheduleId(templateSettings.scheduleId || 'custom')

            // TODO: look into this issue
            // linkChangedRef?.current = true
          } else {
            setTimepickerDays(defaultAvailabilityTemplate.days)
            setHostDisplayName(user ? user.displayName : '')
            setReminderEmailSubject(
              defaultAvailabilityTemplate.reminderEmailSubject
            )
            setReminderEmailBody(defaultAvailabilityTemplate.reminderEmailBody)
            setQuestions(defaultAvailabilityTemplate.questions)
            setLinkSlug(generateRandomPermanentLinkId())

            setConflictCalendars(
              resolvePrimaryConflictCalendars(calendars, emailAccountsMap)
            )
          }
        }
      }

      if (
        !hostEmailAccount ||
        !hostCalendar ||
        !user ||
        (shouldInitSettings && !shouldInitSettings())
      ) {
        return
      }

      void doInitTemplateSettings()
    },
    [
      bookingTemplates,
      editingTemplateId,
      user,
      calendars,
      emailAccountsMap,
      setConflictCalendars,
      emailAccounts,
      setConferenceType,
      setHostEmailAccount,
      setHostCalendar,
      shouldInitSettings,
      hostEmailAccount,
      hostCalendar,
      shouldCreateDefaultTemplate,
      dispatch,
      getConferenceType,
    ]
  )

  const showError = useCallback(
    (text: string) => {
      setErrorMessage(text)
      setLoading(false)
      if (scrollContainerRef?.current) {
        scrollContainerRef.current.scrollTop = 0
      }
    },
    [scrollContainerRef]
  )

  const submitHandler = async () => {
    setLoading(true)
    setErrorMessage('')
    let daysEmpty = true

    if (scheduleId === 'custom') {
      Object.keys(timepickerDays).forEach((day) => {
        if (timepickerDays[day as DayVerbose].length) {
          daysEmpty = false
        }
      })
    } else {
      daysEmpty = false
    }

    const templates = bookingTemplates
    const newLinkId = linkSlug.trim()
    if (!name || daysEmpty || !conflictCalendars.length || !hostDisplayName) {
      showError(
        `Please check you've added ${
          !name
            ? 'a template name'
            : daysEmpty
              ? 'some availabilities'
              : !conflictCalendars.length
                ? 'some calendars'
                : 'a host name'
        }.`
      )
      return false
    }

    if (hasReminderEmail && !reminderEmailSubject && !reminderEmailBody) {
      showError('Please add a subject and body for your reminder email.')
      return false
    }

    if (!conferenceType) {
      showError('Please choose a conference location.')
      void logEvent(Events.CALENDAR_AVAILABILITIES_CONFERENCE_ERROR)
      return false
    }

    if (newLinkId) {
      if (!isURLFormat(newLinkId)) {
        showError('Link address may only contain letters, numbers, and dashes.')
        return false
      }
      // check for duplicate template urls
      const templateIds = Object.keys(templates)
      for (let i = 0; i < templateIds.length; i++) {
        if (
          editingTemplateId !== templateIds[i] &&
          bookingLinkId !== bookingTemplates[templateIds[i]].id &&
          bookingTemplates[templateIds[i]].linkSlug === linkSlug
        ) {
          showError(
            `This link address already belongs to the template "${
              templates[templateIds[i]].name
            }"`
          )
          return false
        }
      }
    }

    const newSettings: { [key: string]: Partial<BookingLinkTemplate> } = {}

    for (const [key, value] of Object.entries(templates)) {
      newSettings[key] = { ...value }
    }

    let oldGuests: string[] = []
    let newTemplate: Partial<BookingLinkTemplate> = {}

    const key = bookingLinkId || editingTemplateId

    if (key && key in templates) {
      newTemplate = { ...templates[key] }
      oldGuests = newTemplate.guestEmails ?? []
    } else {
      newTemplate = { order: 0 }
      Object.keys(newSettings).forEach((tempId) => {
        // @ts-expect-error iterating through defined key values
        newSettings[tempId].order += 1
      })
    }

    newTemplate.name = name
    newTemplate.customSchedule = {
      schedule: timepickerDays,
      timezone: customScheduleTimezone,
    }
    newTemplate.durationChoices = durationChoices
    newTemplate.reminderEmailPreBookingMins = reminderEmailPreBookingMins
    newTemplate.reminderEmailSubject = reminderEmailSubject
    newTemplate.reminderEmailBody = reminderEmailBody
    newTemplate.bufferMins = bufferMins
    newTemplate.blockingTimeMins = hasBlocking ? newTemplate.bufferMins : 0
    newTemplate.daysSpan = parseDaysString(daysSpan) // TODO change this format
    newTemplate.conferenceType = conferenceTypeToGeneric(conferenceType) // TODO don't allow Zoom if they haven't added it
    newTemplate.startsIn = parseStartsInString(startsIn)
    newTemplate.externalEventName = externalEventName.trim()
    newTemplate.hostDisplayName = hostDisplayName
    newTemplate.hostCalendarId = hostCalendar?.providerId
    newTemplate.hostEmail = hostEmailAccount?.email
    newTemplate.maxDailyMeetings = maxDailyMeetings
    newTemplate.hasReminderEmail = hasReminderEmail
    // TODO fix this, we shouldn't need to use the template email
    newTemplate.conflictCalendars =
      conflictCalendars as BookingConflictCalendar[]
    newTemplate.guestEmails = guests
    newTemplate.linkSlug = newLinkId
    newTemplate.questions =
      questions.map((question, idx) => {
        return {
          text: question.text,
          required: question.required,
          questionType: questionTypeToGeneric(question.type),
          choices: question.choices?.map((choice) => choice.text) || [],
          order: idx,
        } as BookingQuestion
      }) || []
    newTemplate.scheduleId = scheduleId || 'custom'
    newTemplate.id = key

    if (key) {
      await dispatch(
        updateBookingLinkTemplate({
          template: newTemplate as BookingLinkTemplate,
          previousAvailabilitiesLinkId: shouldCreateDefaultTemplate
            ? key
            : getFirebaseTemplateLinkId(user.id, templates[key].linkSlug),
        })
      )
    } else {
      await dispatch(
        createBookingLinkTemplate(newTemplate as BookingLinkTemplate)
      )
    }

    modalVisibleHandler && modalVisibleHandler(false)
    void logEvent(Events.CALENDAR_AVAILABILITIES_TEMPLATE_SAVE)
    setLoading(false)

    try {
      const ineligibleEmails =
        (team &&
          team.invited &&
          team.users && [
            ...team.invited,
            ...Object.values(team.users).map((val) => val.email),
          ]) ||
        []
      const ignoredEmails = ignoreInviteTeammates.filter(
        (x, i) => ignoreInviteTeammates.indexOf(x) !== i
      )
      const inviteGuests = newTemplate.guestEmails.filter(
        (email) =>
          !oldGuests.includes(email) &&
          !ineligibleEmails.includes(email) &&
          !ignoredEmails.includes(email)
      )
      if (inviteGuests && inviteGuests.length) {
        teamInviteModalHandler && teamInviteModalHandler(inviteGuests)
      }
    } catch (e) {
      // TODO figure out why this is caught in the first place - perhaps
      // malformed data suppression?
      log(e)
    }

    return true
  }

  useEffect(
    function handleHostChange() {
      if (sendingEmailsMessage) {
        setHasReminderEmail(false)
        setReminderEmailForbiddenMessage(sendingEmailsMessage)
      } else {
        setReminderEmailForbiddenMessage('')
      }

      setIsMaxDailyMeetingsDisabled(
        hostCalendar?.providerType === CalendarProviderType.APPLE
      )
    },
    [hostCalendar, sendingEmailsMessage]
  )

  const onHostEmailAccountChanged = useCallback(
    (emailAccountId: string) => {
      const emailAccount = emailAccounts.find((e) => e.id === emailAccountId)
      const primaryCalendar = calendars.find(
        (c) => c.emailAccountId === emailAccountId && c.isPrimary
      )

      if (emailAccount && primaryCalendar) {
        setHostEmailAccount(emailAccount)
        setHostCalendar(primaryCalendar)
        void logEvent(Events.CALENDAR_SWITCH_ACCOUNT_CLICK)
      }

      return {
        emailAccount,
        primaryCalendar,
      }
    },
    [calendars, emailAccounts, setHostCalendar, setHostEmailAccount]
  )

  const onHostCalendarChanged = useCallback(
    (calendarId: string) => {
      const calendar = calendars.find((c) => c.id === calendarId)
      const emailAccount = emailAccounts.find(
        (e) => e.id === calendar?.emailAccountId
      )

      if (emailAccount && calendar) {
        setHostEmailAccount(emailAccount)
        setHostCalendar(calendar)
        void logEvent(Events.CALENDAR_SWITCH_ACCOUNT_CLICK)
      }

      return {
        emailAccount,
        calendar,
      }
    },
    [calendars, emailAccounts, setHostCalendar, setHostEmailAccount]
  )

  const onDurationChange = useCallback((idx: number, val: number) => {
    setDurationChoices((prev) => {
      const prevCopy = [...prev]
      prevCopy[idx] = val
      return prevCopy.sort((a, b) => a - b)
    })
  }, [])

  const onReminderEmailTimeChange = useCallback((val: number) => {
    setReminderEmailPreBookingMins(val)
    setHasReminderEmail(val > 0)
  }, [])

  /**
   * When a template is being created, initialize the conference type to be
   * an allowable conference type for the given host email account. This
   * basically changes the default from 'none' to the appropriate non-none
   * type. This needs to be done "async"/in a useEffect because the allowable
   * conference types is fetched async.
   */
  useEffect(
    function initNewTemplateConferenceType() {
      if (
        !conferenceTypesLoaded ||
        editingTemplateId ||
        conferenceType !== 'none'
      ) {
        return
      }

      const { conferenceType: newConferenceType } = getConferenceType()
      setConferenceType(newConferenceType)
    },
    [
      conferenceType,
      conferenceTypesLoaded,
      editingTemplateId,
      getConferenceType,
      setConferenceType,
    ]
  )

  return {
    bookingTemplates,
    onDurationChange,
    onHostEmailAccountChanged,
    onHostCalendarChanged,
    onReminderEmailTimeChange,
    onSelectedConflictCalendars,
    showError,
    submitHandler,
    state: {
      bookingLinkId,
      bufferMins,
      conflictCalendars,
      createdByUserId,
      customScheduleTimezone,
      daysSpan,
      durationChoices,
      errorMessage,
      externalEventName,
      guests,
      hasBlocking,
      hasReminderEmail,
      hostDisplayName,
      isMaxDailyMeetingsDisabled,
      linkSlug,
      loading,
      maxDailyMeetings,
      name,
      questions,
      reminderEmailBody,
      reminderEmailPreBookingMins,
      reminderEmailSubject,
      reminderEmailForbiddenMessage,
      scheduleId,
      selectedConflictCalendars,
      startsIn,
      timepickerDays,
    },
    setters: {
      setBufferMins,
      setConflictCalendars,
      setCustomScheduleTimezone,
      setDaysSpan,
      setDurationChoices,
      setExternalEventName,
      setGuests,
      setHasBlocking,
      setHasReminderEmail,
      setHostDisplayName,
      setIgnoreInviteTeammates,
      setIsMaxDailyMeetingsDisabled,
      setLinkSlug,
      setLoading,
      setMaxDailyMeetings,
      setName,
      setQuestions,
      setReminderEmailBody,
      setReminderEmailPreBookingMins,
      setReminderEmailSubject,
      setScheduleId,
      setSelectedConflictCalendars,
      setStartsIn,
      setTimepickerDays,
    },
  }
}
