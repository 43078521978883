import { ErrorPage } from '~/global/components/error-page'
import { ActiveFilterItemsWorkspaceContext } from '~/global/contexts/active-filter-items-workspace-context'

import { SelectContents } from './select-contents'
import { SelectHeader } from './select-header'

export const ViewShell = () => {
  return (
    <ActiveFilterItemsWorkspaceContext>
      <SelectHeader />
      <ErrorPage>
        <SelectContents />
      </ErrorPage>
    </ActiveFilterItemsWorkspaceContext>
  )
}
