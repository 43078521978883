import {
  DuplicateSolid,
  PencilSolid,
  PlusSolid,
  SaveAsSolid,
  StarCrossOutOutline,
  StarOutline,
  TrashSolid,
} from '@motion/icons'
import {
  ActionList,
  ButtonGroup,
  HoverRow,
  IconButton,
  SearchableDropdownContent,
  type SearchableDropdownContentProps,
  SearchableListInput,
  SortableList,
  Tab,
  Tabs,
  Tooltip,
} from '@motion/ui/base'
import { filterAndRankMatches } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'
import { type ModalDefinitions } from '@motion/web-common/modals/definitions'
import { type VersionedViewV2 } from '@motion/zod/client'

import { useUserFavoritedItem } from '~/areas/folders/hooks'
import React, { useCallback, useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { FilterViewsDropdown } from './filter-views-dropdown'
import { ViewIcon } from './view-icon'
import { wrapWithAnalytics } from './wrap-with-analytics'

import { usePageData } from '../../../routes'
import { type PageParams } from '../../../routes/types'
import {
  getDefaultView,
  isDefaultView,
  type LocalView,
  useCopyViewLink,
  useDeleteView,
  useEffectiveView,
} from '../../../views'
import { useViewTabsContext } from '../context'
import { useReorderViews } from '../hooks'
import { getPageViewOrderId, isViewDisabled } from '../utils'

export type ViewDropdownContentProps = {
  close: () => void
  selectedView: VersionedViewV2 | null | undefined
  onSelect: (view: VersionedViewV2) => void
}

interface SectionedViews {
  team: VersionedViewV2[]
  personal: VersionedViewV2[]
}

export function ViewDropdownContent({
  close,
  selectedView,
  onSelect,
}: ViewDropdownContentProps) {
  const [tab, setTab] = useState('order')
  const modalApi = useModalApi()
  const info = useEffectiveView()
  const data = usePageData()

  const { filteredViews: views } = useViewTabsContext()

  const handleAddView = (viewOverride?: Partial<LocalView>) => {
    close()
    recordAnalyticsEvent('VIEW_ADD_NEW', { section: 'more_tab' })
    void modalApi.prompt('create-view-v2', {
      currentView: {
        ...(info.view ?? getDefaultView(data.page)),
        ...viewOverride,
      },
    })
  }

  const handleSelectView = (view: VersionedViewV2) => {
    close()
    onSelect(view)
  }

  const sectionedViews = useMemo(
    () =>
      views.reduce<SectionedViews>(
        (acc, view) => {
          if (isDefaultView(view) || !view.isPrivate) {
            acc.team.push(view)
          } else {
            acc.personal.push(view)
          }
          return acc
        },
        { team: [], personal: [] }
      ),
    [views]
  )

  const renderEndContent = useCallback(() => {
    return <FilterViewsDropdown />
  }, [])

  return (
    <div className='w-[340px] [&_[role="tablist"]>button]:text-xs [&_[role="tablist"]>button]:pb-1.5'>
      <Tabs
        value={tab}
        onChange={(value) => setTab(value)}
        outerPadding='small'
        size='small'
        renderEndContent={renderEndContent}
      >
        <Tab value='order' name='View order'>
          <SortableViewList
            views={views}
            onSelect={handleSelectView}
            computeDisabled={(view) => isViewDisabled(view, data.page)}
            onAddNew={handleAddView}
            pageParams={data}
          />
        </Tab>
        <Tab value='team' name='Team views'>
          <SearchableViewList
            views={sectionedViews.team}
            selectedView={selectedView}
            computeDisabled={(view) => isViewDisabled(view, data.page)}
            onSelect={handleSelectView}
            onAddNew={() =>
              handleAddView({
                isPrivate: false,
              })
            }
            emptyMessage='Add your first team view below.'
          />
        </Tab>
        <Tab value='personal' name='Personal views'>
          <SearchableViewList
            views={sectionedViews.personal}
            selectedView={selectedView}
            computeDisabled={(view) => isViewDisabled(view, data.page)}
            onSelect={handleSelectView}
            onAddNew={() =>
              handleAddView({
                isPrivate: true,
              })
            }
            emptyMessage='Add your first personal view below.'
          />
        </Tab>
      </Tabs>
    </div>
  )
}

type SearchableViewListProps = {
  views: VersionedViewV2[]
  selectedView: VersionedViewV2 | null | undefined
  onSelect: SearchableDropdownContentProps<VersionedViewV2>['onChange']
  computeDisabled: SearchableDropdownContentProps<VersionedViewV2>['computeDisabled']
  onAddNew: () => void
  emptyMessage: string
}

function SearchableViewList({
  views,
  selectedView,
  onSelect,
  computeDisabled,
  onAddNew,
  emptyMessage,
}: SearchableViewListProps) {
  return (
    <SearchableDropdownContent
      searchable
      items={views}
      onChange={onSelect}
      selectedItem={selectedView}
      searchPlaceholder='Search views...'
      computeSearchValue={(item) => item.name}
      computeDisabled={computeDisabled}
      renderItem={(item) => (
        <ViewItemListItem view={item} computeDisabled={computeDisabled} />
      )}
      renderFooter={() => <ViewListFooter onAddNew={onAddNew} />}
      renderEmptyContainer={() => (
        <p className='text-sm text-semantic-neutral-text-subtle px-3 pt-2 pb-1'>
          {emptyMessage}
        </p>
      )}
    />
  )
}

function ViewListFooter({ onAddNew }: { onAddNew: () => void }) {
  return (
    <ActionList
      items={[
        {
          prefix: <PlusSolid />,
          content: `Add view`,
          onAction: onAddNew,
        },
      ]}
    />
  )
}

function ViewItemListItem({
  view,
  computeDisabled,
  onClick,
}: {
  view: VersionedViewV2
  computeDisabled?: SearchableViewListProps['computeDisabled']
  onClick?: () => void
}) {
  const disabled = computeDisabled?.(view)

  return (
    <HoverRow
      actions={disabled ? null : <ViewItemListActions view={view} />}
      className='w-full'
    >
      <Tooltip
        asChild
        renderContent={() =>
          disabled
            ? 'This view groups multiple projects together, which only works when viewing workspaces or folders in the sidebar'
            : null
        }
      >
        <button
          onClick={onClick}
          disabled={disabled}
          className='overflow-hidden max-w-full flex-1 flex w-full items-center gap-1.5 disabled:opacity-40'
        >
          <ViewIcon view={view} />
          <span className='truncate'>{view.name}</span>
        </button>
      </Tooltip>
    </HoverRow>
  )
}

type ViewItemListActionProps = {
  view: VersionedViewV2
}

function ViewItemListActions({ view }: ViewItemListActionProps) {
  const copyLink = useCopyViewLink()
  const deleteView = useDeleteView()
  const modalApi = useModalApi()
  const { isFavorited, toggleFavorite } = useUserFavoritedItem('VIEW', view.id)

  const canUpdate = !isDefaultView(view)

  const handleRename = () =>
    wrapWithAnalytics('rename', modalApi.prompt)('save-view-v2', {
      asNew: false,
      view,
    } as unknown as ModalDefinitions['save-view-v2'])

  const handleCopyLink = () => wrapWithAnalytics('copy_link', copyLink)(view)

  const handleSaveAsNew = () =>
    wrapWithAnalytics('save_as_new', modalApi.prompt)('save-view-v2', {
      view,
      asNew: true,
      renderTitle: () => 'Duplicate view',
    } as unknown as ModalDefinitions['save-view-v2'])

  const handleDelete = () => wrapWithAnalytics('delete', deleteView)(view)

  return (
    <ButtonGroup size='small'>
      {canUpdate && (
        <Tooltip asChild content='Rename view'>
          <IconButton
            icon={PencilSolid}
            onClick={handleRename}
            size='xsmall'
            variant='muted'
            sentiment='neutral'
          />
        </Tooltip>
      )}

      {canUpdate && (
        <Tooltip
          asChild
          content={isFavorited ? 'Remove from Favorites' : 'Add to Favorites'}
        >
          <IconButton
            icon={isFavorited ? StarCrossOutOutline : StarOutline}
            onClick={() => void toggleFavorite()}
            size='xsmall'
            variant='muted'
            sentiment='neutral'
          />
        </Tooltip>
      )}

      <Tooltip asChild content='Copy link to view'>
        <IconButton
          icon={DuplicateSolid}
          onClick={handleCopyLink}
          size='xsmall'
          variant='muted'
          sentiment='neutral'
        />
      </Tooltip>

      {canUpdate && (
        <Tooltip asChild content='Duplicate view'>
          <IconButton
            icon={SaveAsSolid}
            onClick={handleSaveAsNew}
            size='xsmall'
            variant='muted'
            sentiment='neutral'
          />
        </Tooltip>
      )}

      {canUpdate && (
        <Tooltip asChild content='Delete view'>
          <IconButton
            icon={TrashSolid}
            onClick={handleDelete}
            size='xsmall'
            variant='muted'
            sentiment='error'
          />
        </Tooltip>
      )}
    </ButtonGroup>
  )
}

type SortableViewListProps = {
  views: VersionedViewV2[]
  onSelect: SearchableDropdownContentProps<VersionedViewV2>['onChange']
  computeDisabled: SearchableDropdownContentProps<VersionedViewV2>['computeDisabled']
  onAddNew: () => void
  pageParams: PageParams
}

function SortableViewList({
  views,
  onSelect,
  computeDisabled,
  onAddNew,
  pageParams,
}: SortableViewListProps) {
  const [searchValue, setSearchValue] = useState('')
  const updateViewsOrder = useReorderViews(getPageViewOrderId(pageParams))

  const filteredItems = useMemo(() => {
    if (!searchValue) return views
    return filterAndRankMatches(searchValue, views, (item) => item.name)
  }, [searchValue, views])

  const handleOrderChange = (items: VersionedViewV2[]) => {
    recordAnalyticsEvent('VIEW_REORDER', { section: 'more_tab' })
    const viewOrder = items.map((item) => item.id)
    updateViewsOrder(viewOrder)
  }

  const { selectedViewTypes } = useViewTabsContext()

  const isFiltering = selectedViewTypes.size > 0 || searchValue.length > 0

  return (
    <div className='flex flex-col'>
      <SearchableListInput
        placeholder='Search views...'
        search={searchValue}
        onValueChange={(value) => setSearchValue(value)}
      />
      {/* When searching or filtering, we can't reorder, so the easiest way to handle this is by rendering a non sortable list */}
      {isFiltering ? (
        <SearchableDropdownContent
          searchable={false}
          items={filteredItems}
          onChange={onSelect}
          selectedItem={undefined}
          computeDisabled={computeDisabled}
          renderItem={(item) => (
            <ViewItemListItem view={item} computeDisabled={computeDisabled} />
          )}
        />
      ) : (
        <div className='max-h-60 overflow-y-auto py-1'>
          <SortableList
            items={filteredItems}
            onChange={handleOrderChange}
            renderRow={(item) => {
              return (
                <ViewItemListItem
                  view={item}
                  onClick={() => onSelect(item)}
                  computeDisabled={computeDisabled}
                />
              )
            }}
            rowClassName={twMerge(
              'flex gap-2 items-center min-w-[180px] h-8',
              'px-2 select-none',
              'text-sm text-dropdown-item-text-default',
              'hover:bg-dropdown-item-bg-hover rounded',
              'focus:outline-none'
            )}
          />
        </div>
      )}
      <div className='flex flex-col border-t border-dropdown-border mt-1'>
        <ViewListFooter onAddNew={onAddNew} />
      </div>
    </div>
  )
}
