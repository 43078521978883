import { PlusOutline } from '@motion/icons'
import { type ProjectSchema } from '@motion/rpc-types'
import { Button } from '@motion/ui/base'
import { Sentry } from '@motion/web-base/sentry'
import { useHasTreatment } from '@motion/web-common/flags'

import { useCreateNote } from '~/areas/notes'
import { CollapsableContainer } from '~/areas/task-project/components'
import { useFindFolderItem, useProject } from '~/global/hooks'
import { useMemo } from 'react'

import { ProjectNoteColorLabel } from './components/project-note-color-label'

type ProjectNotesProps = {
  projectId?: ProjectSchema['id']
}

export function ProjectNotes({ projectId }: ProjectNotesProps) {
  const project = useProject(projectId)

  const hasNotes = useHasTreatment('notes-in-webapp')

  const findFolderItem = useFindFolderItem()

  const createNote = useCreateNote()

  const projectNoteIds = useMemo(() => {
    const projectFolderItemPath = findFolderItem(
      (item) => item.itemType === 'PROJECT' && item.itemId === projectId
    )

    if (projectFolderItemPath == null || projectFolderItemPath.length === 0) {
      Sentry.captureException(new Error('Failed to find project folder item'), {
        tags: {
          position: 'ProjectNotes',
        },
        extra: {
          projectId,
        },
      })
      return []
    }

    const [projectFolderItem] = projectFolderItemPath

    if (projectFolderItem.itemType !== 'PROJECT') {
      Sentry.captureException(
        new Error('Incorrect item type for project folder item'),
        {
          tags: {
            position: 'ProjectNotes',
          },
          extra: {
            projectId,
          },
        }
      )
      return []
    }

    return projectFolderItem.items.map(({ itemId }) => itemId)
  }, [projectId, findFolderItem])

  const handleCreateClick = () => {
    if (project == null) {
      return
    }

    createNote(
      {
        title: '',
        targetType: 'WORKSPACE',
        targetId: project?.workspaceId,
        projectId,
      },
      { navigate: true }
    )
  }

  if (!hasNotes) {
    return null
  }

  return (
    <div className='mt-4'>
      <CollapsableContainer
        title={`Docs (${projectNoteIds.length})`}
        headerActions={
          <Button
            sentiment='neutral'
            variant='muted'
            onClick={handleCreateClick}
          >
            <PlusOutline />
            Add doc
          </Button>
        }
        initialExpanded={projectNoteIds.length > 0}
      >
        <div className='pl-7 pr-1 leading-8'>
          {projectNoteIds.length > 0 ? (
            projectNoteIds.map((noteId) => (
              <ProjectNoteColorLabel key={noteId} noteId={noteId} />
            ))
          ) : (
            <span className='text-sm text-semantic-neutral-text-subtle pl-2'>
              No docs have been added.
            </span>
          )}
        </div>
      </CollapsableContainer>
    </div>
  )
}
