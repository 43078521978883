import { AtSymbolSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { type CalendarStartDay } from '@motion/ui-logic'
import {
  buildDateFilterQuery,
  buildRelativeDateQuery,
  buildRelativeRangeDateQuery,
  isLogicalFilter,
  processLeadingEdge,
  processTrailingEdge,
} from '@motion/ui-logic/pm/data'
import { formatToReadableWeekDayMonth } from '@motion/ui-logic/utils'
import { isEqual } from '@motion/utils/core'
import { isSameDay } from '@motion/utils/dates'
import {
  type DateFilterSchema,
  type NowRelativeDateSchema,
} from '@motion/zod/client'

import { DateTime } from 'luxon'

import {
  LogicalFormats,
  RelativeFormats,
  RelativeQueryFormats,
} from './constants'
import { parseRelativeDate } from './utils'

type FormatFilterProps = {
  value: DateFilterSchema | null | undefined
  seperator?: string
}

export function DisplayValue(props: FormatFilterProps) {
  return (
    <div className='text-sm text-semantic-neutral-text-subtle flex justify-center'>
      <FormatFilter seperator='-' {...props} />
    </div>
  )
}

export function FormatFilter(props: FormatFilterProps) {
  const { value, seperator = 'and' } = props
  const normalized = buildDateFilterQuery(value)
  if (normalized == null) return null
  if (normalized.operator === 'defined' || normalized.operator === 'empty')
    return null

  if (typeof normalized.value === 'string') {
    return formatToReadableWeekDayMonth(normalized.value)
  }

  if (
    normalized.operator === 'range' &&
    isSameDay(normalized.value.from, normalized.value.to)
  ) {
    return formatToReadableWeekDayMonth(normalized.value.from)
  }

  return (
    <div className='flex gap-1'>
      <span>{formatToReadableWeekDayMonth(normalized.value.from)}</span>
      <span className='text-semantic-neutral-text-subtle'>{seperator}</span>
      <span>{formatToReadableWeekDayMonth(normalized.value.to)}</span>
    </div>
  )
}

type FilterPageFormatProps = {
  value: DateFilterSchema
  startDay?: CalendarStartDay
}

function formatRelativeDate(
  value: NowRelativeDateSchema | string,
  edge: 'leading' | 'trailing' = 'leading'
) {
  if (typeof value === 'string') {
    return formatToReadableWeekDayMonth(value)
  }

  const dateRangeCtx = { now: DateTime.now(), zone: DateTime.now().zone }
  const processFn =
    edge === 'leading' ? processLeadingEdge : processTrailingEdge

  const formatted = processFn(dateRangeCtx, value)

  return (
    <span className='flex items-center min-w-0 truncate'>
      {templateStr('{{at}}{{date}}', {
        at: (
          <AtSymbolSolid className='shrink-0 size-3 text-semantic-neutral-icon-default' />
        ),
        date: (
          <span className='truncate'>
            {formatToReadableWeekDayMonth(formatted)}
          </span>
        ),
      })}
    </span>
  )
}

export function FilterPageFormat(props: FilterPageFormatProps) {
  const { value, startDay } = props

  if (value.operator === 'relative-date-range') {
    const { from, to } = value

    if (from == null && to == null) {
      return 'empty'
    }

    if (from == null && to != null) {
      return templateStr('on or before {{end}}', {
        end: formatRelativeDate(to),
      })
    }

    if (from != null && to == null) {
      return templateStr('after {{start}}', {
        start: formatRelativeDate(from),
      })
    }

    if (from != null && to != null) {
      if (isEqual(from, to)) {
        return formatRelativeDate(from)
      }

      return templateStr('between {{start}} and {{end}}', {
        start: formatRelativeDate(from),
        end: formatRelativeDate(to, 'trailing'),
      })
    }
  }

  if (isLogicalFilter(value)) {
    return templateStr(LogicalFormats[value.operator], {
      value: formatToReadableWeekDayMonth(value.value),
    })
  }

  if (value.operator === 'defined-relative') {
    const normalized = buildRelativeRangeDateQuery(value, startDay)
    return templateStr(RelativeQueryFormats[value.name], {
      from: formatToReadableWeekDayMonth(normalized.value.from),
      to: formatToReadableWeekDayMonth(normalized.value.to),
    })
  }

  if (value.operator === 'range') {
    return templateStr('between {{from}} and {{to}}', {
      from: formatToReadableWeekDayMonth(value.value.from),
      to: formatToReadableWeekDayMonth(value.value.to),
    })
  }

  if (value.operator === 'relative') {
    const info = parseRelativeDate(value)
    const normalized = buildRelativeDateQuery(value)
    return templateStr(RelativeFormats[info.direction], {
      value: info.value,
      unit: info.unit,
      from: formatToReadableWeekDayMonth(normalized.value.from),
      to: formatToReadableWeekDayMonth(normalized.value.to),
    })
  }

  if (value.operator === 'empty') {
    return 'none'
  }

  return 'unknown'
}
