import { LexoRank } from 'lexorank'

import {
  type SortableWorkspacesTreeviewItem,
  type WorkspacesTreeviewItem,
  type WorkspacesTreeviewItemType,
} from '../types'

export type FlattenTreeItemsOptions = {
  injectPlaceholderInEmptyContainers?: boolean
  injectPlaceholderInAllLevels?: boolean
}

export function flattenTreeItems(
  items: WorkspacesTreeviewItem[],
  {
    injectPlaceholderInEmptyContainers = true,
    injectPlaceholderInAllLevels = false,
  }: FlattenTreeItemsOptions = {}
): SortableWorkspacesTreeviewItem[] {
  const flattenItem =
    (
      level: SortableWorkspacesTreeviewItem['level'],
      parent?: WorkspacesTreeviewItem
    ) =>
    (
      treeItem: WorkspacesTreeviewItem,
      index: number,
      tree: WorkspacesTreeviewItem[]
    ): SortableWorkspacesTreeviewItem[] => {
      const { children, ...item } = treeItem
      const { id, workspaceId, parentId, order } = item

      const type = getItemContainerType(item.type)
      const parentType = parent ? getItemContainerType(parent.type) : null

      let flattenedItems: SortableWorkspacesTreeviewItem[] = [
        {
          id,
          canMoveDown: index < tree.length - 1,
          canMoveUp: index !== 0,
          isContainer: treeItem.isContainer,
          isContainerExpanded: treeItem.expanded,
          item,
          level,
          order: LexoRank.parse(order),
          parentId: type === 'WORKSPACE' ? parentId : (parent?.id ?? null),
          parentType,
          type,
        },
      ]

      if (treeItem.isContainer) {
        if (children.length) {
          flattenedItems.push(
            ...children
              .flatMap(flattenItem(level + 1, treeItem))
              .map((ci) => ({ ...ci, workspaceId }))
          )
        }

        if (
          injectPlaceholderInAllLevels ||
          (injectPlaceholderInEmptyContainers && children.length === 0)
        ) {
          flattenedItems.push(createPlaceholderItem(item, level))
        }
      }

      return flattenedItems
    }

  return items.flatMap(flattenItem(0))
}

function createPlaceholderItem(
  item: SortableWorkspacesTreeviewItem['item'],
  level: SortableWorkspacesTreeviewItem['level']
): SortableWorkspacesTreeviewItem {
  const placeholderId = `${item.id}:placeholder`
  const order = LexoRank.middle()
  const type = getItemContainerType(item.type)

  return {
    id: placeholderId,
    canMoveDown: false,
    canMoveUp: false,
    isContainer: false,
    isContainerExpanded: false,
    item: {
      // We only need a few properties from the item for a placeholder
      ...item,
      id: placeholderId,
      itemId: item.id,
      order: order.toString(),
      isContainer: false,
      expanded: false,
    },
    level: level + 1,
    order,
    parentId: item.itemId,
    parentType: type,
    type: 'PLACEHOLDER',
  }
}

function getItemContainerType(
  type: WorkspacesTreeviewItem['type']
): WorkspacesTreeviewItemType {
  switch (type) {
    case 'INDIVIDUAL_WORKSPACE':
    case 'TEAM_WORKSPACE':
      return 'WORKSPACE'
    default:
      return type
  }
}
