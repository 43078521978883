import { templateStr } from '@motion/react-core/strings'
import { classed } from '@motion/theme'
import { ProgressBar } from '@motion/ui/base'

import { useCurrentTaskProgressData } from '~/areas/agenda/hooks'
import { type ScheduledTaskWithRelation } from '~/global/proxies'

export type CurrentTaskProgressProps = {
  scheduledTask: ScheduledTaskWithRelation
}

export function CurrentTaskProgress({
  scheduledTask,
}: CurrentTaskProgressProps) {
  const { timeLeftLabel, totalTimeLabel, progress } =
    useCurrentTaskProgressData(scheduledTask.schedule)

  return (
    <CurrentTaskProgressContainer>
      <ProgressBarContainer>
        <ProgressBar value={progress} />
      </ProgressBarContainer>
      <CurrentTaskProgressTextLabel>
        {templateStr('{{ timeLeftLabel }} left out of {{ totalTimeLabel }}', {
          timeLeftLabel,
          totalTimeLabel,
        })}
      </CurrentTaskProgressTextLabel>
    </CurrentTaskProgressContainer>
  )
}

const CurrentTaskProgressContainer = classed('div', {
  base: 'flex flex-row gap-2 w-full pl-6',
})

const ProgressBarContainer = classed('div', {
  base: 'w-60',
})

const CurrentTaskProgressTextLabel = classed('span', {
  base: 'text-semantic-neutral-text-subtle text-xs leading-3',
})
