import {
  type FlowTemplateRoleAssignee,
  type RoleVariable,
} from '@motion/ui-logic/pm/project'

export const roleToFlowTemplateAssignee = (
  r: RoleVariable
): FlowTemplateRoleAssignee => ({
  id: r.id,
  type: 'person',
  color: r.color,
  name: r.name,
  key: r.key,
  userId: r.id,
  user: {
    id: r.id,
    name: r.name,
    deleted: false,
    email: '',
    picture: '',
    isPlaceholder: false,
    hasActiveSubscription: true,
    onboardingComplete: true,
  },
})
