import { PencilSolid, PuzzleSolid } from '@motion/icons'
import { ButtonGroup, IconButton } from '@motion/ui/base'
import { ProjectPalette } from '@motion/ui/project'
import { stripHtml } from '@motion/ui-logic'
import { useWillExceedWorkspaceFeatureCap } from '@motion/web-billing'
import { useModalApi } from '@motion/web-common/modals'

import {
  useFlowTemplateModalUrl,
  useUpdateProjectDefinition,
} from '~/areas/flows'
import {
  CardTitle,
  FlowCardBottomGradient,
  FlowCardHeader,
  FlowDescription,
  FlowDescriptionContainer,
  FlowTemplateContainer,
  ProjectTemplateCountHoverCard,
  TemplateStages,
} from '~/areas/flows/components'
import { getNextTier } from '~/areas/tiered-pricing'
import { useCurrentTier } from '~/areas/tiered-pricing/hooks'
import { ColorDropdown } from '~/global/components/dropdowns'
import { type ProjectDefinitionWithProjectInfo } from '~/global/hooks'

import { StageTemplateCardMenu } from './stage-template-card-menu'

export type FlowTemplateCardProps = {
  flowTemplate: ProjectDefinitionWithProjectInfo
}
export function FlowTemplateCard({ flowTemplate }: FlowTemplateCardProps) {
  const updateFlowTemplate = useUpdateProjectDefinition()
  const buildFlowTemplateModalUrl = useFlowTemplateModalUrl()
  const currentTier = useCurrentTier()
  const nextTier = getNextTier(currentTier)
  const canEditFlowTemplate = !useWillExceedWorkspaceFeatureCap(
    'projectDefinitions',
    flowTemplate.workspaceId,
    1
  )
  const modalApi = useModalApi()

  const editFlowTemplateUrl = buildFlowTemplateModalUrl({
    forWorkspace: flowTemplate.workspaceId,
    template: 'edit',
    templateId: flowTemplate.id,
  })

  return (
    <FlowTemplateContainer>
      <FlowDescriptionContainer>
        <FlowCardHeader>
          <ProjectPalette color={flowTemplate.color}>
            <ColorDropdown
              onChange={(color) => {
                void updateFlowTemplate(
                  flowTemplate.id,
                  flowTemplate.workspaceId,
                  {
                    definition: {
                      ...flowTemplate,
                      color: color,
                    },
                  }
                )
              }}
              selectedColor={flowTemplate.color}
            >
              <div className='flex p-1 items-center gap-2 rounded bg-palette-bg-light cursor-pointer hover:bg-palette-bg-hover focus:bg-palette-bg-hover size-5'>
                <PuzzleSolid className='size-3 text-palette-highlight-default' />
              </div>
            </ColorDropdown>
          </ProjectPalette>
          <ButtonGroup>
            <IconButton
              icon={PencilSolid}
              sentiment='neutral'
              size='xsmall'
              url={editFlowTemplateUrl}
              variant='muted'
              aria-label='Edit flow template'
            />
            <StageTemplateCardMenu
              featureLocked={!canEditFlowTemplate}
              onDuplicate={() => {
                if (!canEditFlowTemplate && nextTier) {
                  modalApi.open('tier-upgrade-prompt-modal', {
                    tier: nextTier,
                    feature: 'projectDefinitions',
                  })
                  return
                }

                modalApi.open('duplicate-flow', {
                  flowId: flowTemplate.id,
                  workspaceId: flowTemplate.workspaceId,
                })
              }}
              onDelete={() => {
                modalApi.prompt('confirm-delete-stage-template', {
                  item: flowTemplate,
                  type: 'project-definition',
                  workspaceId: flowTemplate.workspaceId,
                })
              }}
            />
          </ButtonGroup>
        </FlowCardHeader>
        <CardTitle>{flowTemplate.name}</CardTitle>
        <FlowDescription className='truncate'>
          {flowTemplate.definitionDescription
            ? stripHtml(
                flowTemplate.definitionDescription.replace(/<p>/g, '\n')
              )
            : 'No Description provided'}
        </FlowDescription>
        <ProjectTemplateCountHoverCard
          items={flowTemplate.projects}
          type='project'
          parentType='template'
        />
      </FlowDescriptionContainer>
      <TemplateStages flowTemplate={flowTemplate} />
      <FlowCardBottomGradient />
    </FlowTemplateContainer>
  )
}
