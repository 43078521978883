import { getCacheEntryValue } from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'

import type { HandlerContext } from './types'

const invalidateQuery = (ctx: HandlerContext) => {
  return ctx.client.invalidateQueries({
    queryKey: API.scheduledEntities.queryKeys.root,
  })
}

export async function refreshScheduledEntities(
  ctx: HandlerContext,
  data: Partial<{ workspaceId?: string }> = {}
) {
  if (!data.workspaceId) {
    return invalidateQuery(ctx)
  }

  // Check if the workspaceId included in the key is the users personal workspace
  let workspaceId = data.workspaceId
  const cacheWorkspace = getCacheEntryValue(
    ctx.client,
    'workspaces',
    workspaceId
  )

  if (cacheWorkspace && cacheWorkspace.isPersonalWorkspace) {
    return invalidateQuery(ctx)
  }

  return void 0
}
