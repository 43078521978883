import {
  CrownSolid,
  DotsHorizontalSolid,
  DuplicateOutline,
  TrashSolid,
} from '@motion/icons'
import { ActionList, IconButton, PopoverTrigger } from '@motion/ui/base'

type StageTemplateCardMenuProps = {
  onDelete: () => void
  onDuplicate: () => void
  featureLocked?: boolean
}
export function StageTemplateCardMenu({
  onDelete,
  onDuplicate,
  featureLocked,
}: StageTemplateCardMenuProps) {
  return (
    <PopoverTrigger
      placement='bottom-end'
      renderPopover={({ close }) => (
        <ActionList
          items={[
            {
              content: 'Duplicate',
              prefix: <DuplicateOutline />,
              suffix: featureLocked ? <CrownSolid /> : undefined,
              onAction: () => {
                onDuplicate()
                close()
              },
            },
            {
              content: 'Delete',
              prefix: <TrashSolid />,
              onAction: () => {
                // TODO flows(m5a): implement confirmation modal
                onDelete()
                close()
              },
            },
          ]}
        />
      )}
    >
      <IconButton
        icon={DotsHorizontalSolid}
        sentiment='neutral'
        size='xsmall'
        variant='muted'
      />
    </PopoverTrigger>
  )
}
