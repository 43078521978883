import { templateStr } from '@motion/react-core/strings'
import { Button } from '@motion/ui/base'

import { useEmptyResultsIcon } from '~/global/hooks'

import {
  getSearchableEntitiesStr,
  isSearchingArchivedTasks,
  isSearchingTasks,
  isValidSearch,
  type SearchType,
} from '../utils'

interface EmptyResultsProps {
  searchQuery: string
  searchTypes: SearchType[]
  hasNotes: boolean
  disableSearchTypeEvents: boolean
  onClickArchive: () => void
  onClickRegularSearch: () => void
}

export const EmptyResults = (props: EmptyResultsProps) => {
  const {
    searchQuery,
    searchTypes,
    hasNotes,
    disableSearchTypeEvents,
    onClickArchive,
    onClickRegularSearch,
  } = props

  const Illustration = useEmptyResultsIcon()

  return (
    <div className='grid place-items-center h-full'>
      <div className='flex flex-col gap-4 items-center'>
        <Illustration className='w-32 h-32' />
        {isValidSearch(searchQuery) ? (
          <>
            <div className='flex flex-col gap-2 items-center'>
              <span className='text-semantic-neutral-text-default font-semibold text-center break-all'>
                {templateStr('No results for “{{ searchQuery }}”', {
                  searchQuery,
                })}
              </span>
              <span className='text-semantic-neutral-text-subtle'>
                {isSearchingTasks(searchTypes)
                  ? isSearchingArchivedTasks(searchTypes)
                    ? "The task you're looking for might not be archived"
                    : "If you're looking for a task, it might be archived"
                  : null}
              </span>
            </div>
            <SearchAlternativesOptions
              onClickArchive={onClickArchive}
              onClickRegularSearch={onClickRegularSearch}
              showArchiveButton={
                isSearchingTasks(searchTypes) &&
                !isSearchingArchivedTasks(searchTypes)
              }
              showRegularSearchButton={
                isSearchingTasks(searchTypes) &&
                isSearchingArchivedTasks(searchTypes)
              }
            />
          </>
        ) : (
          <span className='text-semantic-neutral-text-subtle font-semibold'>
            {templateStr('Search for a {{entities}} above', {
              entities: getSearchableEntitiesStr({
                hasNotes,
                disableSearchTypeEvents,
                isMobile: false,
              }),
            })}
          </span>
        )}
      </div>
    </div>
  )
}

export type SearchAlternativesOptionsProps = Pick<
  EmptyResultsProps,
  'onClickArchive' | 'onClickRegularSearch'
> & {
  showArchiveButton?: boolean
  showRegularSearchButton: boolean
}

export function SearchAlternativesOptions({
  onClickArchive,
  onClickRegularSearch,
  showArchiveButton,
  showRegularSearchButton,
}: SearchAlternativesOptionsProps) {
  return (
    <div className='w-full flex flex-col gap-3 items-center'>
      {showArchiveButton && (
        <Button sentiment='neutral' variant='outlined' onClick={onClickArchive}>
          Search archived tasks
        </Button>
      )}

      {showRegularSearchButton && (
        <Button
          sentiment='neutral'
          variant='outlined'
          onClick={onClickRegularSearch}
        >
          Back to regular search
        </Button>
      )}
    </div>
  )
}
