import { type NormalOrRecurringTask, type PMTaskType } from '@motion/rpc/types'
import { showToast } from '@motion/ui/base'
import { parseDate } from '@motion/utils/dates'
import { NOOP_FUNCTION } from '@motion/utils/function'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import {
  type CreateTemplateTaskSchema,
  type UpdateTemplateTaskSchema,
} from '@motion/zod/client'

import { showErrorToast } from '~/global/toasts'
import { DateTime } from 'luxon'
import { createContext, useContext, useMemo } from 'react'
import { type FormState, useFormContext } from 'react-hook-form'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useNextTemplateTaskRank } from './hooks/use-next-template-task-rank'
import { type TemplateTaskFormFields } from './types'
import { convertTaskToCreateTaskForTemplate } from './utils'

import {
  useCreateTemplateProjectTask,
  useCreateTemplateTask,
  useUpdateTemplateProjectTask,
  useUpdateTemplateTask,
} from '../../../global/rpc'
import { useUploadWorkspaceImage } from '../../hooks/use-upload-workspace-image'

export type TemplateTaskFormActions = {
  closeModal: () => void
  uploadImage: (file: File) => Promise<string | undefined>
  openLinkModalPrompt: (initialLink?: string) => Promise<string | null>
  submitTemplate: (data: TemplateTaskFormFields) => Promise<{ id: string }>
}

const defaultValue: TemplateTaskFormActions = {
  closeModal: NOOP_FUNCTION,
  uploadImage: () => Promise.resolve(undefined),
  openLinkModalPrompt: () => Promise.resolve(null),
  submitTemplate: () => Promise.resolve({ id: '' }),
}

const TemplateTaskFormHandlersContext = createContext(defaultValue)

export type TaskFormHandlersProps = {
  children: React.ReactNode
}

export function TemplateTaskFormHandlers({ children }: TaskFormHandlersProps) {
  const value = useHandlersValue()

  return (
    <TemplateTaskFormHandlersContext.Provider value={value}>
      {children}
    </TemplateTaskFormHandlersContext.Provider>
  )
}

/* eslint react-refresh/only-export-components: ["warn"] */
export function useTemplateTaskFormHandlers() {
  return useContext(TemplateTaskFormHandlersContext)
}

function useHandlersValue() {
  const { templateProjectId, templateTaskId } = useParams<{
    templateProjectId: string
    templateTaskId: string
  }>()

  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()
  const modalApi = useModalApi()

  const { mutateAsync: createTemplateTask } = useCreateTemplateTask()
  const { mutateAsync: updateTemplateTask } = useUpdateTemplateTask()
  const { mutateAsync: createTemplateProjectTask } =
    useCreateTemplateProjectTask()
  const { mutateAsync: updateTemplateProjectTask } =
    useUpdateTemplateProjectTask()

  const form = useFormContext<TemplateTaskFormFields>()

  const workspaceId = form.watch('workspaceId')

  const templateTaskRank = useNextTemplateTaskRank(
    workspaceId,
    templateProjectId
  )

  const taskId = form.watch('id')

  const uploadWorkspaceImage = useUploadWorkspaceImage(workspaceId, {
    taskId,
    isRecurringTask: false,
  })

  return useMemo<TemplateTaskFormActions>(() => {
    const closeModal: TemplateTaskFormActions['closeModal'] = () => {
      // Closing the edit template modal
      if (templateTaskId != null) {
        navigate('../..', { relative: 'path' })

        return
      }

      // Closing the saving a task as template
      setSearchParams((prev) => {
        prev.delete('template')
        return prev
      })
      return
    }

    return {
      /**
       * Close the task modal
       */
      closeModal,

      async openLinkModalPrompt(initialLink = '') {
        const response = await modalApi.prompt('link-modal', {
          initialLink,
        })

        if (response !== ModalDismissed) {
          return response
        }
        return null
      },

      /**
       * Upload an image
       * @param file File object to upload
       * @returns string url of the file
       */
      uploadImage(file) {
        return uploadWorkspaceImage(file)
      },

      async submitTemplate(data) {
        try {
          let taskForTemplate: PMTaskType | any | undefined

          if (templateProjectId && templateTaskId !== 'new') {
            // Updating a template project task
            const response = await updateTemplateProjectTask({
              templateProjectId,
              ...convertToUpdateTemplateTask(data),
            })

            showToast('success', 'Template saved')
            return response
          }

          if (data.templateId) {
            const response = await updateTemplateTask(
              convertToUpdateTemplateTask(data)
            )

            showToast('success', 'Template saved')
            return response
          }

          const response = templateProjectId
            ? await createTemplateProjectTask({
                templateProjectId,
                ...{
                  ...convertToCreateTemplateTask(data, taskForTemplate).task,
                  rank: templateTaskRank,
                },
              })
            : await createTemplateTask(
                convertToCreateTemplateTask(data, taskForTemplate)
              )

          showToast('success', 'Template created')
          return response
        } catch (e) {
          showErrorToast(e)
          throw e
        }
      },
    }
  }, [
    templateTaskId,
    navigate,
    setSearchParams,
    modalApi,
    uploadWorkspaceImage,
    templateProjectId,
    createTemplateProjectTask,
    templateTaskRank,
    createTemplateTask,
    updateTemplateProjectTask,
    updateTemplateTask,
  ])
}

type Options = {
  dirtyFields?: FormState<TemplateTaskFormFields>['dirtyFields']
  doPartialUpdates: boolean
}

function convertTaskForm(
  fields: TemplateTaskFormFields,
  options: Options = { doPartialUpdates: false }
): Partial<NormalOrRecurringTask> {
  const { dirtyFields, doPartialUpdates } = options
  const dirtyKeys =
    doPartialUpdates && dirtyFields
      ? (Object.keys(dirtyFields) as (keyof TemplateTaskFormFields)[])
      : undefined

  function mayBeInclude<K extends keyof TemplateTaskFormFields, V>(
    field: K,
    value: V
  ): V | undefined {
    const shouldInclude = dirtyKeys == null || dirtyKeys.includes(field)

    return shouldInclude ? value : undefined
  }

  const startDate =
    fields.priorityLevel === 'ASAP'
      ? DateTime.now().toISODate()
      : fields.startDate

  const mandatoryFields = {
    id: fields.id,
    // workspaceId is mandatory until this is fixed https://dev-app.usemotion.com/web/pm/workspaces/u7_D-Iy8jdmn3IAvEkdfR?task=vkzw_3kB5iNVjkK4iIVSp
    workspaceId: fields.workspaceId,
  }

  return {
    ...mandatoryFields,

    projectId: mayBeInclude('projectId', fields.projectId),
    assigneeUserId: mayBeInclude('assigneeId', fields.assigneeId),
    statusId: mayBeInclude('statusId', fields.statusId),
    labels: mayBeInclude(
      'labelIds',
      fields.labelIds.map((labelId) => ({ labelId }))
    ),
    priorityLevel: mayBeInclude('priorityLevel', fields.priorityLevel),
    duration: mayBeInclude('duration', fields.duration),
    minimumDuration: mayBeInclude('minChunkDuration', fields.minChunkDuration),
    name: mayBeInclude('name', fields.name),
    description: mayBeInclude('description', fields.description),
    startDate: mayBeInclude('startDate', startDate),
    startingOn: mayBeInclude(
      'startDate',
      startDate ? parseDate(startDate).toISO() : undefined
    ),
    dueDate: mayBeInclude('deadlineDate', fields.deadlineDate),
    schedule: mayBeInclude('scheduleId', fields.scheduleId),
    isAutoScheduled: mayBeInclude('isAutoScheduled', fields.isAutoScheduled),
  }
}

function convertToCreateTemplateTask(
  fields: TemplateTaskFormFields,
  task?: PMTaskType | any
): CreateTemplateTaskSchema {
  const finalTask = task ?? convertTaskForm(fields)

  return {
    name: fields.templateName ?? '',
    workspaceId: fields.workspaceId,
    task: convertTaskToCreateTaskForTemplate(finalTask as PMTaskType),
  }
}

function convertToUpdateTemplateTask(
  fields: TemplateTaskFormFields
): UpdateTemplateTaskSchema & { task: { workspaceId: string } } {
  const result = convertToCreateTemplateTask(fields)

  if (!fields.templateId || !fields.id) {
    throw new Error('templateId and id must exist to update a template task')
  }

  return {
    ...result,
    id: fields.templateId,
    task: {
      ...result.task,
      id: fields.id,
    },
  }
}
