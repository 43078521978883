import { type FlowTemplateFormFields as FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import { useFormContext } from 'react-hook-form'

/**
 * Used to access form context for PWT modal form and stage modal form
 */
export function useFlowTemplateForm() {
  const form = useFormContext<FlowTemplateFormFields>()

  return { form }
}
