import { type RecurringTaskSchema, type TaskSchema } from '@motion/zod/client'

import { type LookupFn } from '~/global/cache'

import { createCustomFieldProxy } from './create-custom-fields-proxy'
import {
  getOrCreateLookupProxy,
  isProxied,
  type ProxyDef,
} from './create-proxy'
import {
  type RecurringTaskWithRelations,
  type TaskWithRelations,
} from './types'

export const TASK_RELATIONS = {
  status: {
    key: 'statuses',
    accessor: (task) => task.statusId,
  },
  project: {
    key: 'projects',
    accessor: (task) =>
      task.type !== 'RECURRING_TASK' ? task.projectId : null,
  },
  workspace: {
    key: 'workspaces',
    accessor: (task) => task.workspaceId,
  },
  labels: {
    key: 'labels',
    accessor: (task) => ('labelIds' in task ? task.labelIds : []),
  },
  assignee: {
    key: 'users',
    accessor: (task) => task.assigneeUserId,
  },
  chunks: {
    key: 'tasks',
    accessor: (task) =>
      task.type !== 'CHUNK' && task.type !== 'RECURRING_TASK'
        ? task.chunkIds
        : [],
  },
  parentChunkTask: {
    key: 'tasks',
    accessor: (task) => (task.type === 'CHUNK' ? task.parentChunkTaskId : null),
  },
  parentRecurringTask: {
    key: 'tasks',
    accessor: (task) =>
      task.type === 'RECURRING_INSTANCE' ? task.parentRecurringTaskId : null,
  },
  blockedByTasks: {
    key: 'tasks',
    accessor: (task) => (task.type === 'NORMAL' ? task.blockedByTaskIds : []),
  },
  blockingTasks: {
    key: 'tasks',
    accessor: (task) => (task.type === 'NORMAL' ? task.blockingTaskIds : []),
  },
  createdByUser: {
    key: 'users',
    accessor: (task) => task.createdByUserId,
  },
  customFields: {
    accessor: (task) =>
      task.type === 'NORMAL' ? task.customFieldValues : undefined,
    get(target, prop, lookup) {
      return createCustomFieldProxy(target, lookup)
    },
  },
  stageDefinition: {
    accessor: (task) =>
      task.type === 'NORMAL' ? task.stageDefinitionId : undefined,
    get(target, prop, lookup) {
      if (target.type !== 'NORMAL') return null

      return lookup('stageDefinitions', target.stageDefinitionId) ?? null
    },
  },
  meetingTask: {
    key: 'tasks',
    accessor: (task) => (task.type === 'NORMAL' ? task.meetingTaskId : null),
  },
  meetingEvent: {
    key: 'calendarEvents',
    accessor: (task) => (task.type === 'NORMAL' ? task.meetingEventId : null),
  },
} satisfies Record<string | symbol, ProxyDef<TaskSchema | RecurringTaskSchema>>

export function createTaskProxy<T extends TaskSchema>(
  task: T,
  lookup: LookupFn
): Extract<TaskWithRelations, { type: T['type'] }> {
  if (isProxied(task))
    return task as Extract<TaskWithRelations, { type: T['type'] }>
  return getOrCreateLookupProxy(task, TASK_RELATIONS, lookup)
}

export function createRecurringTaskProxy(
  task: RecurringTaskSchema,
  lookup: LookupFn
): RecurringTaskWithRelations {
  if (isProxied(task)) return task
  return getOrCreateLookupProxy(task, TASK_RELATIONS, lookup)
}
