import {
  type CustomFieldFieldArrayValue,
  mapCustomFieldToFieldArrayWithValue,
} from '@motion/ui-logic'
import { type V2SetupProjectFormFields } from '@motion/ui-logic/pm/project'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import { useEffect, useMemo } from 'react'
import { type UseFormReturn } from 'react-hook-form'

export const useSyncCustomFields = (
  form: UseFormReturn<V2SetupProjectFormFields>
) => {
  const projectDefinition = form.watch('projectDefinition')
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(
    form.watch('workspaceId')
  )
  const customFieldValuesFieldArray = useMemo(
    () =>
      workspaceCustomFields.map((field) =>
        mapCustomFieldToFieldArrayWithValue(
          field,
          projectDefinition?.customFieldValues ?? {}
        )
      ),
    [workspaceCustomFields, projectDefinition?.customFieldValues]
  )

  const prevCustomFieldValuesFieldArray = form.watch(
    'customFieldValuesFieldArray'
  )

  /**
   * Bizarre behavior:
   * `watch` is not picking up latest changes to the form state
   * so we need to use a side effect to sync the custom fields if one is added/removed,
   * and remove any synced custom fields that are no longer present in the form
   */
  useEffect(() => {
    if (
      customFieldValuesFieldArray.length !==
      prevCustomFieldValuesFieldArray.length
    ) {
      const newCustomFieldValuesFieldArray: CustomFieldFieldArrayValue[] =
        customFieldValuesFieldArray.map((cf) => ({
          ...cf,
          value:
            prevCustomFieldValuesFieldArray.find(
              (prevCf: CustomFieldFieldArrayValue) =>
                prevCf.instanceId === cf.instanceId
            )?.value ?? (cf.value as any),
        }))

      form.setValue(
        'customFieldValuesFieldArray',
        newCustomFieldValuesFieldArray
      )
      // Remove any synced custom fields that are no longer present in the form
      form.setValue(
        'customFieldSyncInstanceIds',
        [...form.getValues('customFieldSyncInstanceIds')].filter((id) =>
          newCustomFieldValuesFieldArray.some((cf) => cf.instanceId === id)
        ),
        { shouldTouch: true, shouldDirty: true }
      )
    }
  }, [form, customFieldValuesFieldArray, prevCustomFieldValuesFieldArray])
}
