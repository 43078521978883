import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { LabelsField } from '~/areas/task-project/fields'
import { useController } from 'react-hook-form'

export const ControlledLabelsField = () => {
  const { form } = useFlowTemplateForm()

  const { control, watch } = form

  const { field } = useController({
    name: 'labelIds',
    control,
  })

  const selectedLabelIds = watch('labelIds')
  const workspaceId = watch('workspaceId')

  return (
    <LabelsField
      selectedLabelIds={selectedLabelIds}
      workspaceId={workspaceId}
      onChange={field.onChange}
    />
  )
}
