import { templateStr } from '@motion/react-core/strings'
import { isOneOf } from '@motion/utils/array'
import { useHasTreatment } from '@motion/web-common/flags'

import { useI18N } from '~/global/contexts'
import { memo, type ReactNode } from 'react'

import { type WorkspaceActionMenuProps, WorkspaceCreateMenu } from './actions'

import type { SortableWorkspacesTreeviewItem } from '../types'

export type WorkspaceTreeviewPlaceholderProps = {
  item: SortableWorkspacesTreeviewItem
  children: (label: string) => ReactNode
  indent?: number
  placement?: WorkspaceActionMenuProps['placement']
}

export const WorkspaceTreeviewPlaceholder = memo(
  function WorkspaceTreeviewPlaceholder({
    item: flattenedItem,
    indent = 0,
    children,
    placement = 'bottom-start',
  }: WorkspaceTreeviewPlaceholderProps) {
    const hasNotes = useHasTreatment('notes-in-webapp')
    const { formatList } = useI18N()

    const { item, parentType } = flattenedItem

    const canCreateFolder = isOneOf(parentType, ['WORKSPACE', 'FOLDER'])
    const canCreateNotes =
      hasNotes &&
      isOneOf(parentType, ['WORKSPACE', 'FOLDER', 'PROJECT', 'NOTE'])
    const canCreateProject = isOneOf(parentType, ['WORKSPACE', 'FOLDER'])

    const listOfItemsToCreate = [
      canCreateFolder && 'folder',
      canCreateProject && 'project',
      canCreateNotes && 'doc',
    ].filter(Boolean)

    if (listOfItemsToCreate.length === 0) return

    const label = templateStr('New {{items}}', {
      items: formatList(listOfItemsToCreate, 'disjunction'),
    })

    return (
      <WorkspaceCreateMenu
        workspaceId={item.workspaceId}
        allowNewNote={canCreateNotes}
        allowNewProject={canCreateProject}
        allowNewFolder={canCreateFolder}
        placement={placement}
        offset={{ crossAxis: indent }}
      >
        {children(label)}
      </WorkspaceCreateMenu>
    )
  }
)
