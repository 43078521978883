import {
  LinkTransformPlugin,
  MentionsPlugin,
  type MentionsPluginProps,
  shouldTransformMentionLink,
  transformMentionLink,
} from '@motion/notes'

import { useState } from 'react'

import { WebMentionOption } from './components/web-mention-option'
import { WebMentionsNode } from './mention-node'
import { useMentionSearch } from './use-mention-search'

export type WebMentionsPluginProps = {
  onMentionCreated?: MentionsPluginProps['onMentionCreated']
  onMentionDeleted?: MentionsPluginProps['onMentionDeleted']
}

export function WebMentionsPlugin({
  onMentionCreated,
  onMentionDeleted,
}: WebMentionsPluginProps) {
  const [query, setQuery] = useState('')

  const { items } = useMentionSearch(query)

  return (
    <>
      <MentionsPlugin
        query={query}
        setQuery={setQuery}
        results={items}
        renderMentionOption={(option) => <WebMentionOption option={option} />}
        onMentionCreated={onMentionCreated}
        onMentionDeleted={onMentionDeleted}
      />
      <LinkTransformPlugin
        transformLink={(link, label) => transformMentionLink(link, label)}
        shouldTransformLink={shouldTransformMentionLink}
      />
    </>
  )
}

WebMentionsPlugin.Node = WebMentionsNode
