import { getDefaultChunkDuration } from '@motion/ui-logic'

import { valibotResolver } from '@hookform/resolvers/valibot'
import { type ReactNode, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useInitialFormData } from './hooks'
import { useActionItemContext } from './provider'
import { ActionItemFormSchema } from './types'

type ActionItemFormProps = {
  children: ReactNode
}

export function ActionItemForm({ children }: ActionItemFormProps) {
  const { initialActionItem, task } = useActionItemContext()

  const initialFormData = useInitialFormData({
    initialData: initialActionItem.taskData,
    task,
  })

  const form = useForm({
    defaultValues: initialFormData,
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
    resolver: valibotResolver(ActionItemFormSchema),
  })

  useEffect(() => {
    const subscription = form.watch((_, { name }) => {
      if (name == null) return

      if (name.includes('duration')) {
        // Set the minimum duration to the default chunk duration when the duration changes
        form.setValue(
          'minimumDuration',
          getDefaultChunkDuration(form.getValues('duration'))
        )
      }
    })
    return () => subscription.unsubscribe()
  }, [form])

  return <FormProvider {...form}>{children}</FormProvider>
}
