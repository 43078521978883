import { CarSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { Button } from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'
import { formatDurationTime } from '@motion/ui-logic'

import { DurationDropdown } from '~/areas/project-management/components'
import { useController } from 'react-hook-form'

import { useEventModalState } from '../contexts'
import { useEventForm } from '../hooks'

export const ControlledEventTravelTimesField = () => {
  const { isLoading, isReadOnly, hostCalendar } = useEventModalState()
  const { form, hiddenFields } = useEventForm()
  const { control } = form

  const { field: travelTimeBeforeField } = useController({
    name: 'travelTimeBefore',
    control,
  })
  const { field: travelTimeAfterField } = useController({
    name: 'travelTimeAfter',
    control,
  })

  const shouldBeHidden =
    hiddenFields.has('travelTimeBefore') || hiddenFields.has('travelTimeAfter')

  if (
    isLoading ||
    hostCalendar == null ||
    hostCalendar.providerType === 'APPLE' ||
    shouldBeHidden
  ) {
    return null
  }

  const onChange = async (
    before: number | null | undefined,
    after: number | null | undefined
  ) => {
    if (before !== undefined) {
      travelTimeBeforeField.onChange(before)
    }
    if (after !== undefined) {
      travelTimeAfterField.onChange(after)
    }
  }

  const selectedDuration =
    travelTimeBeforeField.value ?? travelTimeAfterField.value ?? null
  const hasBefore = travelTimeBeforeField.value != null
  const hasAfter = travelTimeAfterField.value != null
  const hasBeforeOrAfter = hasBefore || hasAfter

  return (
    <DurationDropdown
      value={selectedDuration}
      choices={defaultDurations}
      onChange={(value) => {
        const finalValue = value === 0 ? null : value

        if (!hasBeforeOrAfter) {
          return onChange(finalValue, finalValue)
        }
        onChange(hasBefore ? finalValue : null, hasAfter ? finalValue : null)
      }}
      renderPopoverFooter={() => {
        return (
          <div className='flex flex-col border-t border-dropdown-border mt-1 p-1'>
            <div className='py-1.5 px-2'>
              <Checkbox
                label='Before'
                checked={hasBefore}
                onChange={(v) => {
                  onChange(
                    v ? (selectedDuration ?? defaultDuration) : null,
                    undefined
                  )
                }}
              />
            </div>
            <div className='py-1.5 px-2'>
              <Checkbox
                label='After'
                checked={hasAfter}
                onChange={(v) => {
                  onChange(
                    undefined,
                    v ? (selectedDuration ?? defaultDuration) : null
                  )
                }}
              />
            </div>
          </div>
        )
      }}
    >
      <Button
        variant='muted'
        sentiment='neutral'
        size='xsmall'
        disabled={isReadOnly}
      >
        <CarSolid />
        {getDisplayTravelTime(selectedDuration, { hasBefore, hasAfter })}
      </Button>
    </DurationDropdown>
  )
}

const defaultDuration = 15
const defaultDurations = [0, 15, 30, 45, 60].map((o) => ({
  label: o === 0 ? 'None' : formatDurationTime(o),
  value: o,
}))

function getDisplayTravelTime(
  value: number | null,
  { hasBefore, hasAfter }: { hasBefore: boolean; hasAfter: boolean }
) {
  if (value == null) {
    return 'Travel time'
  }

  return templateStr('{{value}} {{before}}{{sep}}{{after}}', {
    value: formatDurationTime(value),
    before: hasBefore ? 'before' : '',
    sep: hasBefore && hasAfter ? '/' : '',
    after: hasAfter ? 'after' : '',
  })
}
