import {
  createUseMutation,
  createUseQuery,
  createUseQueryLazy,
} from '@motion/rpc'
import { API } from '@motion/rpc-definitions'

import { dispatch } from '../../state/proxy'
import {
  setStripeSubscription,
  type StripeSubscription,
} from '../../state/userSlice'

const useUncancelSubscription = createUseMutation(
  API.subscriptions.uncancelSubscription
)

export const useUncancelSubscriptionAndUpdate = () => {
  const {
    mutateAsync: uncancelSubscription,
    isPending: isUncancelingSubscription,
  } = useUncancelSubscription()

  const doUncancelSubscription = async () => {
    const updatedSubscription = await uncancelSubscription()

    void dispatch(
      setStripeSubscription(updatedSubscription as StripeSubscription)
    )
  }

  return {
    doUncancelSubscription,
    isUncancelingSubscription,
  }
}

const useCancelSubscription = createUseMutation(
  API.subscriptions.cancelSubscription
)
export const useCancelSubscriptionAndUpdate = () => {
  const {
    mutateAsync: cancelSubscription,
    isPending: isCancelingSubscription,
  } = useCancelSubscription()

  const doCancelSubscription = async () => {
    await cancelSubscription()
  }

  return {
    doCancelSubscription,
    isCancelingSubscription,
  }
}

const useApplyCoupon = createUseMutation(API.subscriptions.applyCoupon)

export const useApplyCouponAndUpdate = () => {
  const { mutateAsync: applyCoupon, isPending: isApplyingCoupon } =
    useApplyCoupon()

  const doApplyCoupon = async (
    couponId: string,
    subscriptionId: string,
    overrideExisting: boolean = true
  ) => {
    await applyCoupon({
      couponId,
      subscriptionId,
      overrideExisting,
    })
  }

  return {
    doApplyCoupon,
    isApplyingCoupon,
  }
}

export const useGetCoupon = createUseQuery(API.stripe.getCoupon)
export const useGetCouponLazy = createUseQueryLazy(API.stripe.getCoupon)
