import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import {
  type ProjectSchema,
  type ProjectsV2CreateFromTaskRequestSchema,
  type ProjectsV2CreateRequestSchema,
} from '@motion/zod/client'

import {
  useCreateProject as useCreateProjectCall,
  useCreateProjectFromTask,
} from '~/global/rpc/v2'
import { useCallback } from 'react'

export const useCreateProject = () => {
  const { mutateAsync: createProject } = useCreateProjectCall()
  const { mutateAsync: createProjectFromTask } = useCreateProjectFromTask()

  return useCallback(
    async (
      data:
        | ProjectsV2CreateRequestSchema
        | ProjectsV2CreateFromTaskRequestSchema
    ) => {
      try {
        let project: ProjectSchema
        const isCreateFromTask = 'taskId' in data

        if (isCreateFromTask) {
          const response = await createProjectFromTask(data)
          project = response.models.projects[response.id]
        } else {
          const response = await createProject(data)
          project = response.models.projects[response.id]
        }

        recordAnalyticsEvent(
          isCreateFromTask
            ? 'PROJECT_MANAGEMENT_CREATE_PROJECT_FROM_TASK'
            : 'PROJECT_MANAGEMENT_CREATE_PROJECT',
          {
            project_type: data.projectDefinitionId != null ? 'flow' : 'regular',
            created_with_ai: false,
          }
        )

        showToast('success', 'Project created')

        return project
      } catch (error) {
        showToast('error', 'Failed to create project')

        Sentry.captureException(error, {
          extra: {
            data,
          },
        })

        return undefined
      }
    },
    [createProject, createProjectFromTask]
  )
}
