import { PlusSolid } from '@motion/icons'

import {
  type SortableWorkspacesTreeviewItem,
  WorkspaceTreeviewPlaceholder,
} from '~/areas/treeviews/components'
import { LexoRank } from 'lexorank'
import { memo } from 'react'

import { SidebarTreeviewItem } from '../../components'
import { SIDEBAR_INDENT_WIDTH } from '../../constants'

export type ProjectPlaceholderProps = {
  item: SortableWorkspacesTreeviewItem
}

export const ProjectPlaceholder = memo(function ProjectPlaceholder({
  item: flattenedItem,
}: ProjectPlaceholderProps) {
  const { item, id, type, level } = flattenedItem

  return (
    <WorkspaceTreeviewPlaceholder
      item={flattenedItem}
      indent={20 + level * SIDEBAR_INDENT_WIDTH}
    >
      {(label) => (
        <div>
          <SidebarTreeviewItem
            icon={PlusSolid}
            label={label}
            sortableData={{
              item: {
                ...item,
                url: '',
              },
              order: LexoRank.middle().toString(),
              parentId: id,
              parentType: type,
              type: 'PLACEHOLDER',
              level,
            }}
            disableDrag
          />
        </div>
      )}
    </WorkspaceTreeviewPlaceholder>
  )
})
