import { ActionList, Focus } from '@motion/ui/base'
import {
  formatToReadableWeekDayMonth,
  type QuickAction,
} from '@motion/ui-logic'

import { type DateTime } from 'luxon'

import { mergeDateWithTime } from '../../utils/date-utils'

export type DateActionSectionProps = {
  contentRef: React.MutableRefObject<HTMLDivElement | null>
  currentValue: DateTime | null
  dateActions: QuickAction[]
  onChange: (value: string) => void
}

export function DateActionSection({
  contentRef,
  currentValue,
  dateActions,
  onChange,
}: DateActionSectionProps) {
  return (
    <Focus contentRef={contentRef}>
      <div
        ref={contentRef}
        className='h-full bg-semantic-neutral-bg-disabled min-w-[180px]'
      >
        <ActionList
          items={dateActions.map((action) => ({
            content: action.label,
            suffix: (
              <span className='text-xs text-semantic-neutral-text-subtle'>
                {formatToReadableWeekDayMonth(action.value)}
              </span>
            ),
            onAction: () => {
              onChange(mergeDateWithTime(action.value, currentValue).toISO())
            },
          }))}
        />
      </div>
    </Focus>
  )
}
