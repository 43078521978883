import { DuplicateOutline } from '@motion/icons'
import { AiDocsEditor } from '@motion/notes'
import { Button } from '@motion/ui/base'

import { useClipboard } from '~/localServices/clipboard'
import { useRef } from 'react'

type ResultEditorProps = {
  markdownContent: string
}

export const ResultEditor = (props: ResultEditorProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const clipboard = useClipboard()

  const copyToClipboard = () => {
    if (containerRef.current) {
      clipboard.write({
        text: props.markdownContent,
        html: containerRef.current.innerHTML,
      })
    }
  }
  return (
    <div className='border border-semantic-neutral-border-default rounded-lg overflow-hidden'>
      <div className='flex justify-end bg-semantic-neutral-surface-bg-subtlest border-b border-semantic-neutral-border-default py-1 px-1'>
        <Button
          size='small'
          variant='muted'
          sentiment='neutral'
          onClick={copyToClipboard}
        >
          <DuplicateOutline /> Copy
        </Button>
      </div>
      <div className='max-h-[60vh] overflow-y-auto' ref={containerRef}>
        <AiDocsEditor
          initialValue={{ format: 'markdown', value: props.markdownContent }}
        />
      </div>
    </div>
  )
}
