import { isCanceledStatus, isResolvedStatus } from '@motion/shared/common'
import { Tooltip } from '@motion/ui/base'
import { isGhostTask } from '@motion/ui-logic/pm/project'
import { type NormalTaskSchema } from '@motion/zod/client'

import { useLookup } from '~/global/cache'
import { useWorkspaceStatusById } from '~/global/hooks'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { GhostName } from './ghost-name'
import { LegacyGhostName } from './legacy-ghost-name'

type TaskNameProps = {
  currentTaskId: string | undefined
  task: NormalTaskSchema
  taskLink: string
  onLinkClick: () => void
}

export function TaskName({
  currentTaskId,
  task,
  taskLink,
  onLinkClick,
}: TaskNameProps) {
  const { id, name } = task

  const status = useWorkspaceStatusById(task.statusId)
  const lookup = useLookup()

  const isGhost = isGhostTask(task)

  if (isGhost) {
    const isLegacyGhost = lookup('tasks', id) == null
    if (!isLegacyGhost) {
      return (
        <GhostName
          isActive={currentTaskId === id}
          taskName={name}
          taskLink={taskLink}
          onLinkClick={onLinkClick}
          isResolved={isResolvedStatus(status)}
          isCanceled={isCanceledStatus(status)}
        />
      )
    }

    return <LegacyGhostName name={name} />
  }

  return (
    <Tooltip asChild content={name}>
      <Link
        className={twMerge(
          'text-xs truncate grow text-semantic-neutral-text-default',
          currentTaskId === id && 'font-bold',
          isCanceledStatus(status) &&
            'text-semantic-neutral-text-subtle line-through'
        )}
        onClick={onLinkClick}
        to={taskLink}
      >
        {name}
      </Link>
    </Tooltip>
  )
}
