import { type ApiTypes } from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
  type OptimisticUpdateValue,
} from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { findFolderItem, findFolderItemInRecord } from '@motion/ui-logic'
import { isOneOf } from '@motion/utils/array'
import { cloneDeep } from '@motion/utils/core'
import { Sentry } from '@motion/web-base/sentry'
import type { FolderFolderItemSchema } from '@motion/zod/client'

import type { QueryClient } from '@tanstack/react-query'

type FolderData = ApiTypes<typeof API.folders.getFolders>['data']

export const folderQueryFilters = createQueryFilter([
  MODEL_CACHE_KEY,
  API.folders.queryKeys.getAll,
])

export async function applyOptimisticFolderItemUpdates(
  queryClient: QueryClient,
  itemId: FolderFolderItemSchema['id'],
  data: {
    order?: FolderFolderItemSchema['order']
    parentFolderId?: FolderFolderItemSchema['folderId']
    parentFolderItemId?: FolderFolderItemSchema['parentFolderItemId']
  },
  type: 'ITEM' | 'PROJECT' | 'NOTE' = 'ITEM'
): Promise<OptimisticUpdateValue> {
  await queryClient.cancelQueries({
    queryKey: API.folders.queryKeys.getAll,
  })

  const { parentFolderId, parentFolderItemId, order } = data

  // An object of data that will be provided to any errors sent to Sentry
  const errorDetail: Record<string, any> = {
    itemId,
    order,
    parentFolderId,
    parentFolderItemId,
    type,
  }

  const originalData = queryClient.getQueryData<FolderData | undefined>(
    API.folders.queryKeys.getAll
  )

  if (!originalData?.models.systemFolders) {
    return emptyRollback
  }

  const systemFolders = cloneDeep(originalData.models.systemFolders)

  const result = findFolderItemInRecord(systemFolders, (item) => {
    if ('itemId' in item && isOneOf(type, ['PROJECT', 'NOTE'])) {
      return item.itemId === itemId
    }

    return item.id === itemId
  })

  if (!result) {
    Sentry.captureException(new Error('Could not find item to move'), {
      extra: {
        ...errorDetail,
      },
      tags: {
        position: 'applyOptimisticFolderItemUpdates',
      },
    })

    return emptyRollback
  }

  if (result.type === 'root') {
    // This is simply a type assertion. `type` will never be `root`
    return emptyRollback
  }

  const [item, parent] = result.breadcrumbs

  errorDetail.item = item
  errorDetail.parent = parent

  if (order) {
    item.order = order
  }

  if ((parentFolderId || parentFolderItemId) && parent) {
    const itemIdx = parent.items.indexOf(item)

    if (itemIdx === -1) {
      Sentry.captureException(
        new Error('Could not get index of item in parent items'),
        {
          extra: {
            ...errorDetail,
          },
          tags: {
            position: 'applyOptimisticFolderItemUpdates',
          },
        }
      )

      return emptyRollback
    }

    // Remove from its original parent
    parent.items.splice(itemIdx, 1)

    if (parentFolderId === result.folder.id) {
      item.folderId = result.folder.id
      result.folder.items.push(item)
    } else {
      const newParentResult = findFolderItemInRecord(systemFolders, (item) => {
        if (parentFolderItemId) {
          return item.id === parentFolderItemId
        }

        if ('targetId' in item) {
          return item.id === parentFolderId
        }

        return item.itemId === parentFolderId
      })

      if (!newParentResult) {
        Sentry.captureException(
          new Error('Could not find new parent folder item'),
          {
            extra: {
              ...errorDetail,
            },
            tags: {
              position: 'applyOptimisticFolderItemUpdates',
            },
          }
        )

        return emptyRollback
      }

      if (newParentResult.type === 'root') {
        // This is simply a type assertion. `type` will never be `root`
        return emptyRollback
      }

      const [newParent] = newParentResult.breadcrumbs

      errorDetail.newParent = newParent

      if (!('items' in newParent)) {
        Sentry.captureException(new Error('New parent is not a folder'), {
          extra: {
            ...errorDetail,
          },
          tags: {
            position: 'applyOptimisticFolderItemUpdates',
          },
        })

        return emptyRollback
      }

      // Move to its new parent folder
      item.folderId = newParent.itemId
      newParent.items.push(item)
    }
  }

  const { rollback } = MotionCache.patch(
    queryClient,
    folderQueryFilters,
    'systemFolders',
    systemFolders
  )

  return {
    rollback,
    async withRollback<T>(p: Promise<T>) {
      try {
        return await p
      } catch (ex) {
        rollback()
        throw ex
      }
    },
  }
}

export async function applyOptimisticFolderItemDelete(
  queryClient: QueryClient,
  itemId: FolderFolderItemSchema['id'],
  type: 'ITEM' | 'PROJECT' = 'ITEM'
): Promise<OptimisticUpdateValue> {
  try {
    await queryClient.cancelQueries({
      queryKey: API.folders.queryKeys.getAll,
    })

    const originalData = queryClient.getQueryData<FolderData | undefined>(
      API.folders.queryKeys.getAll
    )

    if (!originalData?.models.systemFolders.workspaces) {
      return emptyRollback
    }

    const workspaces = cloneDeep(originalData.models.systemFolders.workspaces)

    const result = findFolderItem(workspaces, (item) => {
      if (type === 'PROJECT') return item.itemId === itemId

      return item.id === itemId
    })

    if (!result) {
      Sentry.captureException(new Error('Could not find item to delete'), {
        extra: {
          itemId,
          type,
        },
        tags: {
          position: 'applyOptimisticFolderItemUpdates',
        },
      })

      return emptyRollback
    }

    const [item, parent] = result

    parent.items.splice(parent.items.indexOf(item), 1)

    const { rollback } = MotionCache.patch(
      queryClient,
      folderQueryFilters,
      'systemFolders',
      {
        workspaces,
      }
    )

    return {
      rollback,
      async withRollback<T>(p: Promise<T>) {
        try {
          return await p
        } catch (ex) {
          rollback()
          throw ex
        }
      },
    }
  } catch (e) {
    Sentry.captureException(
      new Error('Could not apply optimistic folder item delete', { cause: e }),
      {
        extra: {
          itemId,
          type,
        },
        tags: {
          position: 'applyOptimisticFolderItemDelete',
        },
      }
    )

    return emptyRollback
  }
}

const emptyRollback = {
  rollback: () => void 0,
  async withRollback<T>(p: Promise<T>) {
    try {
      return await p
    } catch (ex) {
      throw ex
    }
  },
}
