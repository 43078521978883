import { UserSolid } from '@motion/icons'
import { createNoneId, isNoneId } from '@motion/shared/identifiers'
import { Button, SearchableList } from '@motion/ui/base'
import { userComparator } from '@motion/ui-logic'
import {
  createUnassignedUser,
  type FilterTarget,
} from '@motion/ui-logic/pm/data'
import { groupInto } from '@motion/utils/array'
import {
  useAuthenticatedUser,
  type User as FirebaseUser,
} from '@motion/web-common/auth'
import { type IdFilterSchema, type UserInfoSchema } from '@motion/zod/client'

import { buildIdFilter } from '~/areas/project-management/filters/utils'
import { useLookup } from '~/global/cache'
import { UserLabel } from '~/global/components/labels'
import { useAppWorkspaceContext } from '~/global/contexts'
import { useCallback } from 'react'

import { type UseFieldFilter } from '../../../../context'
import { type DropdownFilterProps } from '../../../types'
import { FilterItem } from '../filter-item/filter-item'

export type UserFilterValueProps = {
  label?: string
  filter: UseFieldFilter<IdFilterSchema | null>[0]
  setFilter: UseFieldFilter<IdFilterSchema | null>[1]
  hideEmptyUser?: boolean
}

export type UserFilterItemProps = DropdownFilterProps & UserFilterValueProps

export function UserFilterItem(props: UserFilterItemProps) {
  const {
    filter,
    setFilter,
    label = 'Assignee',
    onBlur,
    openOnMount,
    target,
  } = props

  return (
    <FilterItem
      label={label}
      canInvert
      inverted={filter?.inverse}
      onInvertChanged={(invert) => {
        if (filter == null) return
        setFilter({ ...(filter ?? {}), inverse: invert })
      }}
      onBlur={onBlur}
      openOnMount={openOnMount}
      onDismiss={() => setFilter(null)}
      renderDropdown={({ close }) => (
        <ConnectedAssigneeDropdown
          applyTo={target}
          label={label}
          close={close}
          filter={filter}
          setFilter={setFilter}
          hideEmptyUser={props.hideEmptyUser}
        />
      )}
    >
      <AssigneeValue ids={filter?.value ?? null} />
    </FilterItem>
  )
}

type ConnectedAssigneeDropdownProps = {
  applyTo: FilterTarget
  close(): void
  label?: string
} & UserFilterValueProps

export const ConnectedAssigneeDropdown = (
  props: ConnectedAssigneeDropdownProps
) => {
  const { filter, setFilter, label = 'assignees' } = props
  const user = useAuthenticatedUser()
  const [ctx] = useAppWorkspaceContext()

  const allMembers = groupInto(ctx.members.all, (x) => x.userId)
    .map((g) => g.items[0].user)
    .concat(createAtMeUser(user))

  if (!props.hideEmptyUser) {
    allMembers.unshift(createUnassignedUser())
  }
  const users = allMembers.sort(
    userComparator({
      currentUserId: user.uid,
      unassignedUserId: createNoneId('user'),
    })
  )

  const selected =
    filter == null
      ? []
      : filter.value.map((id) => users.find((u) => u.id === id)).filter(Boolean)

  const onSelect = (value: UserInfoSchema[] | null) => {
    setFilter(buildIdFilter(value, filter))
  }

  return (
    <AssigneeDropdown
      users={users}
      selected={selected}
      onSelect={onSelect}
      close={props.close}
      label={label}
    />
  )
}

type AssigneeDropdownProps = {
  users: UserInfoSchema[]
  selected: UserInfoSchema[]
  onSelect(values: UserInfoSchema[] | null): void
  close(): void
  label: string
}

const AssigneeDropdown = (props: AssigneeDropdownProps) => {
  const computeSelected = useCallback(
    (value: UserInfoSchema) => props.selected.includes(value),
    [props.selected]
  )

  return (
    <div>
      <SearchableList
        inputProps={{ placeholder: `Choose ${props.label}...` }}
        itemType='checkbox'
        items={props.users}
        renderItem={(user) => <UserLabel value={user} />}
        computeSearchValue={(user) => user.name}
        computeSelected={computeSelected}
        onSelect={(values) => {
          props.onSelect(values == null ? null : values)
        }}
        computeKey={(item) => item.id}
      />
      <div className='border-t border-dropdown-border p-2 flex justify-between'>
        <Button
          onClick={() => {
            props.onSelect(null)
            props.close()
          }}
          variant='outlined'
          sentiment='neutral'
          size='small'
        >
          Clear
        </Button>
        <Button
          onClick={() => {
            props.onSelect(props.users)
          }}
          variant='outlined'
          sentiment='neutral'
          size='small'
        >
          Select all
        </Button>
      </div>
    </div>
  )
}

type AssigneeValueProps = {
  ids: (string | null)[] | null
}
const AssigneeValue = (props: AssigneeValueProps) => {
  const lookup = useLookup()
  const user = useAuthenticatedUser()

  if (props.ids == null) return 'None'
  if (props.ids.length === 1) {
    const firstId = props.ids[0]
    if (firstId == null || isNoneId(firstId)) {
      return <UserLabel value={null} />
    }

    if (firstId === '@me') {
      return <UserLabel value={createAtMeUser(user)} />
    }

    const firstUser = lookup('users', firstId)
    return <UserLabel value={firstUser} />
  }
  const allUsers = lookup('users')
  const text =
    props.ids.length === 0 || props.ids.length === allUsers.length
      ? 'All'
      : String(props.ids.length)

  return (
    <div className='flex gap-1 items-center'>
      <UserSolid
        width={12}
        height={12}
        className='text-semantic-neutral-icon-default'
      />
      {text} users
    </div>
  )
}

function createAtMeUser(user: FirebaseUser): UserInfoSchema {
  const email = splitEmail(user.email)
  const name = user.displayName ?? email.account
  return {
    id: '@me',
    deleted: false,
    email: user.email,
    isPlaceholder: true,
    name: `Me (${name})`,
    onboardingComplete: true,
    picture: null,
    hasActiveSubscription: true,
  }
}

function splitEmail(email: string) {
  const [account, domain] = email.split('@')
  return { account, domain }
}
