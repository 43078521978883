import { createContext } from 'react'

export type LabelContextValue = {
  shouldGoToNavigate: boolean
}

const defaultValue: LabelContextValue = {
  shouldGoToNavigate: false,
}

export const LabelContext = createContext(defaultValue)

export const LabelContextProvider = LabelContext.Provider
