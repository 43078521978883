import { classed, type ComponentProps } from '@motion/theme'

import { type ComponentRef, forwardRef } from 'react'

export const MentionCardButton = forwardRef<
  ComponentRef<typeof StyledCardButton>,
  ComponentProps<typeof StyledCardButton>
>(function CardButton(props, ref) {
  return <StyledCardButton ref={ref} {...props} />
})

const StyledCardButton = classed('button', {
  base: `

    rounded px-1 py-px

    disabled:cursor-not-allowed
    enabled:hover:bg-semantic-neutral-bg-active-hover
  `,
  variants: {
    iconOnly: {
      true: 'p-px shrink-0',
      false: 'px-1 py-px',
    },
    noOverflow: {
      true: '',
      false: 'overflow-hidden',
    },
    fullWidth: {
      true: 'w-full',
    },
  },
  defaultVariants: {
    iconOnly: false,
    noOverflow: false,
    fullWidth: false,
  },
})
