import { ChevronLeftOutline, LinkSolid, UnlinkSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { classed } from '@motion/theme'
import {
  Button,
  IconButton,
  NotesColorIcon,
  ScrollArea,
  showToast,
  TitleModal,
} from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { HasExperiment } from '@motion/web-common/flags'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import {
  type NoteSchema,
  type ShareTargetResponseSchema,
  type ShareWithSchema,
} from '@motion/zod/client'

import { ShareTargetItem } from '~/areas/modals/share-modal/components/share-target-item'
import { isShareTargetLinked, NoteRoleButton } from '~/areas/share'
import { useCopyHtmlLinkToClipboard } from '~/global/hooks'
import { useShareItemMutation } from '~/global/rpc'
import { showErrorToast } from '~/global/toasts'
import { useContext, useRef } from 'react'

import { CollapsableSection } from './components/collapsable-section'
import { PublishSection } from './components/publish-section'
import { TeamMemberSearch } from './components/team-member-search'
import { TeamMemberSearchableList } from './components/team-member-searchable-list'
import { ShareItemModalContext } from './context/context'
import { ModalContextProvider } from './context/provider'
import { type ShareItemType } from './types'

import {
  type ModalTriggerComponentProps,
  useModalPromptBeforeDismiss,
} from '../modal-trigger'

const ITEM_TYPES = {
  NOTE: {
    itemTypeName: 'Doc',
    Icon: NotesColorIcon,
  },
} as const

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'share-item': {
      itemType: ShareItemType
      itemId: NoteSchema['id']
    }
  }
}

export const ShareItemModal = (
  props: ModalTriggerComponentProps<'share-item'>
) => {
  return (
    <ModalContextProvider itemId={props.itemId} itemType={props.itemType}>
      <Modal {...props} />
    </ModalContextProvider>
  )
}

const Modal = ({ close }: ModalTriggerComponentProps<'share-item'>) => {
  const {
    itemId,
    itemType,
    searchActive,
    selectedRole,
    selectedTargets,
    setSearchActive,
    setSelectedTargets,
    usersWithAccess,
    workspacesWithAccess,
    itemBeingShared,
    canShare,
    isUnlinked,
  } = useContext(ShareItemModalContext)

  const containerRef = useRef<HTMLDivElement>(null)

  const { mutateAsync: shareItem } = useShareItemMutation()
  const copyLink = useCopyHtmlLinkToClipboard()
  const modalApi = useModalApi()

  useModalPromptBeforeDismiss({
    when: selectedTargets.length > 0,
    title: `You have not yet invited the ${selectedTargets.length} selected team members. Are you sure you want to close?`,
  })

  const { itemTypeName, Icon } = ITEM_TYPES[itemType]

  const handleReset = async (showWarning: boolean = false) => {
    if (showWarning) {
      const confirmation = await modalApi.prompt('confirm', {
        destructive: true,
        title: 'Teammates not invited',
        description: `You have not yet invited the ${selectedTargets.length} selected team members. Are you sure you want to go back?`,
        analytics: { name: 'share-modal-invite-back' },
        closeButtonText: 'Continue inviting',
        confirmButtonText: 'Discard and go back',
      })

      if (confirmation === ModalDismissed) return
    }

    setSelectedTargets([])
    setSearchActive(false)
  }

  const handleCopy = () => {
    if (!itemBeingShared) {
      return void showToast('error', 'Could not generate URL to copy')
    }

    copyLink(itemBeingShared.url, itemBeingShared.name)

    return void close()
  }

  const handleInvite = async () => {
    if (!canShare || !itemBeingShared || selectedTargets.length === 0) return

    try {
      await shareItem({
        resourceId: itemId,
        resourceType: itemType,
        shareWith: [
          {
            ...selectedTargets[0],
            role: selectedRole,
          },
          ...selectedTargets.slice(1).map((target) => ({
            ...target,
            role: selectedRole,
          })),
        ],
      })

      void handleReset()

      showToast(
        'success',
        `Shared '${itemBeingShared.name}' with selected teammates/workspaces`
      )
    } catch (e) {
      Sentry.captureException(new Error('Failed to share item', { cause: e }), {
        tags: {
          position: 'ShareItemModal',
        },
        extra: {
          itemId,
          itemType,
          selectedRole,
          selectedTargets,
        },
      })

      showErrorToast(e, 'Failed to share item')
    }
  }

  const handleUpdatePermission = async (
    shareWith: ShareWithSchema,
    shareTarget: ShareTargetResponseSchema
  ) => {
    if (!canShare) return

    const isLinked = isShareTargetLinked(shareTarget)

    if (isLinked) {
      const result = await modalApi.prompt('confirm', {
        title: `Stop inheriting permissions from the parent ${shareTarget.linkedTargetType.toLowerCase()}?`,
        description: `If you change these permissions, this ${itemTypeName.toLowerCase()} will no longer automatically share with the same people and workspaces as its parent ${shareTarget.linkedTargetType.toLowerCase()}. Current viewers and editors may lose access unless you specifically grant them permission.`,
        confirmButtonText: 'Stop inheriting',
        closeButtonText: 'Cancel and keep inheriting',
        destructive: true,
        analytics: {
          name: 'share-modal-linked-warning',
        },
      })

      recordAnalyticsEvent('SHARE_MODAL_ALTERED_LINK', {
        targetType: shareWith.targetType,
        role: shareWith.role,
        accepted: result !== ModalDismissed,
      })

      if (result === ModalDismissed) return
    }

    try {
      recordAnalyticsEvent('SHARE_MODAL_CHANGED_ROLE', {
        targetType: shareWith.targetType,
        role: shareWith.role,
      })

      await shareItem({
        resourceId: itemId,
        resourceType: itemType,
        shareWith: [shareWith],
      })
    } catch (e) {
      Sentry.captureException(
        new Error('Failed to update user share permissions', { cause: e }),
        {
          tags: {
            position: 'ShareItemModal',
          },
          extra: {
            itemId,
            itemType,
            shareWith,
          },
        }
      )

      showErrorToast(e, 'Failed to update sharing permissions')
    }
  }

  return (
    <TitleModal
      title={
        <>
          {searchActive && (
            <IconButton
              size='small'
              variant='muted'
              sentiment='neutral'
              icon={ChevronLeftOutline}
              onClick={() => void handleReset(selectedTargets.length > 0)}
            />
          )}

          <span className='contents'>
            Share
            {itemBeingShared?.name && (
              <>
                : <Icon className='!size-5' /> {itemBeingShared.name}
              </>
            )}
          </span>
        </>
      }
      visible
      disableOverlayClick={searchActive}
      onClose={close}
      bodyProps={{ className: 'p-0 max-w-[500px]' }}
    >
      <div ref={containerRef}>
        {canShare && <TeamMemberSearch onSubmit={handleInvite} />}

        <div className='max-h-96 flex py-2'>
          <ScrollArea className='w-full'>
            <div className='px-4'>
              {searchActive ? (
                <TeamMemberSearchableList containerRef={containerRef} />
              ) : (
                <div>
                  {isUnlinked && (
                    <div className='bg-semantic-neutral-surface-raised-bg-subtlest my-2 p-2 rounded-md text-semantic-neutral-text-default text-sm flex gap-2'>
                      <UnlinkSolid className='size-4 text-semantic-neutral-icon-default shrink-0' />
                      {templateStr(
                        'Share settings on this {{ itemTypeName }} are unlinked from its parent.',
                        {
                          itemTypeName: itemTypeName.toLowerCase(),
                        }
                      )}
                    </div>
                  )}

                  <CollapsableSection
                    title='People with access'
                    count={usersWithAccess.length}
                  >
                    <ul>
                      {usersWithAccess.map((shareTarget) => {
                        if (shareTarget.role == null) return null

                        const isLinked = isShareTargetLinked(shareTarget)

                        return (
                          <ListItem key={shareTarget.targetId}>
                            <ShareTargetItem target={shareTarget} />
                            <NoteRoleButton
                              selectedRole={shareTarget.role}
                              onSelectRole={(role) => {
                                void handleUpdatePermission(
                                  {
                                    role,
                                    targetType: 'USER',
                                    targetId: shareTarget.targetId,
                                  },
                                  shareTarget
                                )
                              }}
                              isLinked={isLinked}
                              linkedTooltip={
                                shareTarget.linkedTargetType
                                  ? `Permissions inherited from parent ${shareTarget.linkedTargetType.toLowerCase()}`
                                  : undefined
                              }
                              disabled={!canShare}
                              allowRemove
                            />
                          </ListItem>
                        )
                      })}
                    </ul>
                  </CollapsableSection>

                  <CollapsableSection
                    title='Workspaces with access'
                    count={workspacesWithAccess.length}
                  >
                    <ul>
                      {workspacesWithAccess.map((shareTarget) => {
                        if (shareTarget.role == null) return null

                        const isLinked =
                          !!shareTarget.linkedTargetType &&
                          !!shareTarget.linkedTargetId

                        return (
                          <ListItem key={shareTarget.targetId}>
                            <ShareTargetItem target={shareTarget} />
                            <NoteRoleButton
                              selectedRole={shareTarget.role}
                              onSelectRole={(role) => {
                                void handleUpdatePermission(
                                  {
                                    role,
                                    targetType: 'WORKSPACE',
                                    targetId: shareTarget.targetId,
                                  },
                                  shareTarget
                                )
                              }}
                              isLinked={isLinked}
                              linkedTooltip={
                                shareTarget.linkedTargetType
                                  ? `Permissions inherited from parent ${shareTarget.linkedTargetType.toLowerCase()}`
                                  : undefined
                              }
                              disabled={!canShare}
                              allowRemove
                            />
                          </ListItem>
                        )
                      })}
                    </ul>
                  </CollapsableSection>
                </div>
              )}
            </div>
          </ScrollArea>
        </div>
      </div>

      <div className='bg-semantic-neutral-surface-raised-bg-subtlest py-3 px-4 flex justify-end'>
        <Button sentiment='primary' variant='outlined' onClick={handleCopy}>
          <LinkSolid className='!size-4' /> Copy link to {itemTypeName}
        </Button>
      </div>

      <HasExperiment name='notes-publishing' fallback={null}>
        <PublishSection />
      </HasExperiment>
    </TitleModal>
  )
}

const ListItem = classed('li', 'px-2 py-1.5 flex justify-between items-center')
