import { templateStr } from '@motion/react-core/strings'

// eslint-disable-next-line no-restricted-imports
import { Dialog, Transition } from '@headlessui/react'
import { useGetTeamPrices } from '~/global/rpc/team'
import { Fragment } from 'react'

import { PrimaryButton, TextButton } from '../../../../components/Common'
import { useAppSelector } from '../../../../state/hooks'
import { selectStripeSubscription } from '../../../../state/userSlice'
import { type OnboardingType } from '../types'

interface CreateTeamDoubleConfirmationModalProps {
  isOpen: boolean
  loading: boolean
  emails: string[]
  onClose: () => void
  onCreate: () => void
  onboardingType: OnboardingType
}

export function CreateTeamDoubleConfirmationModal({
  emails,
  isOpen,
  loading,
  onClose,
  onCreate,
  onboardingType,
}: CreateTeamDoubleConfirmationModalProps) {
  const stripeSubscription = useAppSelector(selectStripeSubscription)
  const interval = stripeSubscription?.plan?.interval
  const { data: teamPrices } = useGetTeamPrices()
  if (!teamPrices) return null
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-[450px] transform overflow-hidden rounded bg-white px-6 py-5 text-left align-middle shadow-xl transition-all'>
                <Dialog.Title
                  as='h3'
                  className='text-light-1200 text-lg font-medium leading-6'
                >
                  Confirmation: Create Motion team
                </Dialog.Title>

                <div className='my-4'>
                  <p className='text-light-1100 text-sm'>
                    Motion will invite the following users to join your team:
                  </p>
                  <ul className='ml-4 list-disc py-2'>
                    {emails.map((email) => (
                      <li key={email} className='text-light-1100 my-1 text-sm'>
                        {email}
                      </li>
                    ))}
                  </ul>
                  {onboardingType === 'individual' && interval && (
                    <p className='text-light-1100 text-sm pb-2'>
                      {templateStr(
                        'You will be charged ${{price}}/{{interval}} per user after your trial ends; cancel anytime on account page.',
                        {
                          price:
                            interval === 'year'
                              ? teamPrices.annualPrice
                              : teamPrices.monthlyPrice,
                          interval,
                        }
                      )}
                    </p>
                  )}
                </div>

                <PrimaryButton
                  className='my-1 flex w-full items-center justify-center py-2'
                  onClick={onCreate}
                  loading={loading}
                >
                  Send invitations
                </PrimaryButton>
                <TextButton
                  className='flex w-full items-center justify-center py-2'
                  onClick={onClose}
                >
                  Back
                </TextButton>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
