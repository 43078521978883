import { byProperty, cascade, Compare } from '@motion/utils/array'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'

import { type WorkspacesTreeItem } from '~/global/hooks/workspaces/types'
import { useFolders } from '~/global/rpc/folders'
import { useMemo } from 'react'

import { useFormatWorkspacesTreeItems } from '../../../hooks'

type WorkspacesTreeItemProps = {
  preferUsingBrowsePageUrls?: boolean
  makeProjectContainers?: boolean
}

export const useWorkspacesTree = ({
  preferUsingBrowsePageUrls = false,
  makeProjectContainers = false,
}: WorkspacesTreeItemProps = READONLY_EMPTY_OBJECT): WorkspacesTreeItem[] => {
  const { data: folders } = useFolders()

  const formatWorkspacesTreeItems = useFormatWorkspacesTreeItems({
    treeType: 'WORKSPACES',
    preferUsingBrowsePageUrls,
    makeProjectContainers,
  })

  return useMemo(() => {
    if (!folders?.models.systemFolders.workspaces) {
      return []
    }

    return folders.models.systemFolders.workspaces.items
      .map(formatWorkspacesTreeItems)
      .filter(Boolean)
      .sort(cascade(byProperty('order', Compare.string)))
  }, [folders, formatWorkspacesTreeItems])
}
