import {
  CheckSolid,
  ExternalLinkSolid,
  LinkSolid,
  LocationMarkerSolid,
  TrashSolid,
} from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { ActionList, type ActionListProps, Button } from '@motion/ui/base'
import { ProjectPalette } from '@motion/ui/project'
import { getConferenceDataForType, isEventReadOnly } from '@motion/ui-logic'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type EventActionListActionName } from '@motion/web-base/analytics/events'
import { useModalApi } from '@motion/web-common/modals'
import { type CalendarEventSchemaV2 } from '@motion/zod/client'

import { useHostCalendar, useHostEmailAccount } from '~/areas/calendar/hooks'
import { useCachedItem } from '~/global/cache'
import { ColorItemBadge } from '~/global/components/badges'
import { ColorTagLabel } from '~/global/components/labels'
import { useNavigateByGlobalModalId } from '~/global/navigation'
import { useClipboard } from '~/localServices/clipboard'
import { useMemo } from 'react'

import {
  type DeleteEventOptions,
  useDeleteEvent,
  useEventColorData,
  useEventRSVPData,
  useUpdateEvent,
} from '../hooks'

export type EventActionListProps = {
  close: () => void
  eventId: CalendarEventSchemaV2['id']
  options?: EventActionOptions
}
export const EventActionList = ({ eventId, ...rest }: EventActionListProps) => {
  const event = useCachedItem('calendarEvents', eventId)

  if (event == null) return null

  return <InnerEventActionList event={event} {...rest} />
}

type InnerEventActionListProps = Omit<EventActionListProps, 'eventId'> & {
  event: CalendarEventSchemaV2
}
export const InnerEventActionList = ({
  close,
  event,
  options,
}: InnerEventActionListProps) => {
  const { sections } = useEventActionItems(event, options)

  return <ActionList sections={sections} onActionAnyItem={close} />
}

interface EventActionOptions {
  onDeleteConfirmation?: DeleteEventOptions['onConfirmation']
}

const useEventActionItems = (
  event: CalendarEventSchemaV2,
  options: EventActionOptions = READONLY_EMPTY_OBJECT
): { sections?: ActionListProps['sections'] } => {
  const { onDeleteConfirmation } = options

  const modalApi = useModalApi()
  const navigateToModal = useNavigateByGlobalModalId()
  const clipboard = useClipboard()

  const hostEmailAccount = useHostEmailAccount(event.email)
  const { hostCalendar } = useHostCalendar(event.email, event.calendarId)

  const isReadOnly = isEventReadOnly({ event, hostCalendar, hostEmailAccount })
  const deleteCalendarEvent = useDeleteEvent()
  const updateCalendarEvent = useUpdateEvent()
  const { canRSVP, userRSVP, onChangeRSVP } = useEventRSVPData(event)
  const { colorOptions, eventColor, onChangeColor } = useEventColorData(event)

  const sections = useMemo<ActionListProps['sections']>(() => {
    const conferenceData = event.conferenceType
      ? getConferenceDataForType(event.conferenceType)
      : null

    const hasValidConference =
      event.conferenceLink != null && conferenceData != null

    const hasValidLocation =
      (event.conferenceType === 'customLocation' ||
        event.conferenceType === 'none') &&
      !!event.location

    return [
      {
        items: [
          hasValidConference && {
            prefix: (
              <img
                className='h-4'
                role='presentation'
                src={conferenceData.link}
              />
            ),
            content: templateStr('Join {{conferenceType}}', {
              conferenceType: conferenceData.title,
            }),
            onAction: wrapWithAnalytics('join_meeting')(() => {
              if (event.conferenceLink != null) {
                window.open(event.conferenceLink)
              }
            }),
          },
          {
            prefix: <ExternalLinkSolid />,
            content: 'Open event',
            onAction: wrapWithAnalytics('open')(() => {
              if (event.meetingTaskId != null) {
                navigateToModal('meeting', { mTask: event.meetingTaskId })
              } else {
                modalApi.open('event-modal', { eventId: event.id })
              }
            }),
          },
        ],
      },
      colorOptions.length > 0 && {
        title: 'Color',
        items: [
          {
            content: (
              <div className='flex gap-2 max-w-[150px] items-start content-start flex-wrap'>
                <div>
                  {colorOptions.map((color) => {
                    const isSelected = eventColor === color

                    return (
                      <ProjectPalette color={color} key={color}>
                        <Button
                          iconOnly
                          onClick={wrapWithAnalytics('color')(() =>
                            onChangeColor(color)
                          )}
                          size='small'
                          sentiment='neutral'
                          variant='muted'
                        >
                          <ColorItemBadge color={color} selected={isSelected} />
                        </Button>
                      </ProjectPalette>
                    )
                  })}
                </div>
                <ColorTagLabel color={eventColor} />
              </div>
            ),
            isNested: true,
          },
        ],
      },
      canRSVP && {
        title: 'Going',
        items: [
          {
            content: 'Yes',
            prefix: (
              <CheckSolid
                className={userRSVP === 'accepted' ? 'visible' : 'invisible'}
              />
            ),
            onAction: wrapWithAnalytics('rsvp')(() => onChangeRSVP('accepted')),
          },
          {
            content: 'No',
            prefix: (
              <CheckSolid
                className={userRSVP === 'declined' ? 'visible' : 'invisible'}
              />
            ),
            onAction: wrapWithAnalytics('rsvp')(() => onChangeRSVP('declined')),
          },
          {
            content: 'Maybe',
            prefix: (
              <CheckSolid
                className={userRSVP === 'tentative' ? 'visible' : 'invisible'}
              />
            ),
            onAction: wrapWithAnalytics('rsvp')(() =>
              onChangeRSVP('tentative')
            ),
          },
        ],
      },
      !isReadOnly && {
        title: 'Type',
        items: [
          {
            content: 'Busy',
            prefix: (
              <CheckSolid
                className={event.status === 'BUSY' ? 'visible' : 'invisible'}
              />
            ),
            onAction: wrapWithAnalytics('status')(() =>
              updateCalendarEvent(event.id, { status: 'BUSY' })
            ),
          },
          {
            content: 'Free',
            prefix: (
              <CheckSolid
                className={event.status === 'FREE' ? 'visible' : 'invisible'}
              />
            ),
            onAction: wrapWithAnalytics('status')(() =>
              updateCalendarEvent(event.id, { status: 'FREE' })
            ),
          },
        ],
      },
      {
        items: [
          hasValidConference && {
            prefix: <LinkSolid />,
            content: templateStr('Copy {{conferenceType}} link', {
              conferenceType: conferenceData.title,
            }),
            onAction: wrapWithAnalytics('copy_meeting')(() => {
              if (!event.conferenceLink) return

              void clipboard.write(
                { text: event.conferenceLink },
                {
                  notificationText: templateStr(
                    '{{conferenceType}} link copied successfully',
                    {
                      conferenceType: conferenceData.title,
                    }
                  ),
                }
              )
            }),
          },
          hasValidLocation && {
            prefix: <LocationMarkerSolid />,
            content: 'Copy event address',
            onAction: wrapWithAnalytics('copy_location')(() => {
              if (!event.location) return

              void clipboard.write(
                { text: event.location },
                {
                  notificationText: 'Event address copied successfully',
                }
              )
            }),
          },
        ],
      },
      {
        items: [
          {
            prefix: <TrashSolid />,
            content: 'Delete event',
            destructive: true,
            onAction: wrapWithAnalytics('delete')(() =>
              deleteCalendarEvent(event.id, {
                onConfirmation: onDeleteConfirmation,
              })
            ),
          },
        ],
      },
    ]
  }, [
    canRSVP,
    clipboard,
    colorOptions,
    deleteCalendarEvent,
    event.conferenceLink,
    event.conferenceType,
    event.id,
    event.location,
    event.meetingTaskId,
    event.status,
    eventColor,
    isReadOnly,
    modalApi,
    navigateToModal,
    onChangeColor,
    onChangeRSVP,
    onDeleteConfirmation,
    updateCalendarEvent,
    userRSVP,
  ])

  return { sections }
}

function wrapWithAnalytics(actionName: EventActionListActionName) {
  return (fn: () => void) => () => {
    recordAnalyticsEvent('EVENT_ACTION_LIST_CLICKED', {
      action: actionName,
    })

    fn()
  }
}
