import { PopoverTrigger } from '@motion/ui/base'
import { useHasTreatment } from '@motion/web-common/flags'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
} from '@motion/zod/client'

import {
  useProject,
  useProjectDefinitionWithStagesById,
  useStageDefinitionsByReferences,
} from '~/global/hooks'
import { type ReactNode } from 'react'

import { StageSearchableList } from './stage-searchable-list'

export type StageDropdownProps = Omit<StageDropdownContentProps, 'close'> & {
  children?: ReactNode
}

export const StageDropdown = ({
  selectedStageId,
  projectId,
  onChange,
  children,
}: StageDropdownProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <StageDropdownContent
          projectId={projectId}
          selectedStageId={selectedStageId}
          close={close}
          onChange={onChange}
        />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type StageDropdownContentProps = {
  close: () => void
  selectedStageId: StageDefinitionSchema['id']
  projectId: ProjectSchema['id'] | null | undefined
  onChange(stageDefinition: StageDefinitionSchema): void
}

const StageDropdownContent = ({
  close,
  onChange,
  projectId,
  selectedStageId,
}: StageDropdownContentProps) => {
  const project = useProject(projectId)

  const projectDefinition = useProjectDefinitionWithStagesById(
    project?.projectDefinitionId
  )

  const stageDefinitionsByReference = useStageDefinitionsByReferences(
    project?.stages ?? []
  )

  const isFlowsM5cEnabled = useHasTreatment('flows-m5c-ad-hoc-stages')

  const stageDefinitions = isFlowsM5cEnabled
    ? stageDefinitionsByReference
    : (projectDefinition?.stages ?? [])

  if (stageDefinitions.length === 0 || project == null) return null

  return (
    <StageSearchableList
      stages={stageDefinitions.map((s) => {
        const projectStage = project.stages.find(
          (ps) => ps.stageDefinitionId === s.id
        )
        return {
          ...s,
          canceledTime: projectStage?.canceledTime ?? null,
          completedTime: projectStage?.completedTime ?? null,
        }
      })}
      selectedStageId={selectedStageId}
      close={close}
      onChange={onChange}
    />
  )
}
