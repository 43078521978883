import { SHORT_TASK_DURATION } from '@motion/ui-logic'
import {
  getInitialFormData,
  type GetInitialFormDataOptions,
  resolveFormWorkspaceAndProjectId,
  type TaskFormFields,
  type TaskModalLocationState,
  type TaskUrlParams,
  type TaskUrlSearchParams,
} from '@motion/ui-logic/pm/task'
import { parseDate } from '@motion/utils/dates'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import {
  useFirestoreSettings,
  useMyUserSettings,
} from '@motion/web-common/settings'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import {
  useMyTasksWorkspace,
  useProject,
  useWorkspaceById,
  useWorkspaceLabels,
  useWorkspaceStatuses,
} from '~/global/hooks'
import { useSearchParams } from '~/routing'
import { useMemo } from 'react'
import { type Location, useLocation, useParams } from 'react-router'

import { type InitialTaskData } from './use-initial-task-data'

export function useUrlTaskInitialFormData(
  initialTaskData: InitialTaskData
): TaskFormFields {
  const location = useLocation() as Location<TaskModalLocationState | undefined>
  const searchParams = useSearchParams<TaskUrlSearchParams>()
  const urlParams = useParams<TaskUrlParams>()

  const defaultWorkspace = useMyTasksWorkspace()

  const { task, parentTask } = initialTaskData

  const { data: userSettings } = useMyUserSettings(undefined, {
    meta: { source: 'useInitialFormData' },
  })
  const userDefinedTaskDefaults = userSettings?.taskDefaultSettings

  let { workspaceId, projectId } = resolveFormWorkspaceAndProjectId({
    searchParams,
    urlParams,
    task,
    defaultWorkspace,
    userDefinedTaskDefaults,
  })

  let workspace = useWorkspaceById(workspaceId)

  // If the workspace is deleted, reset the workspaceId to the personal workspace
  if (workspaceId != null && workspace == null) {
    workspace = defaultWorkspace ?? null
    workspaceId = defaultWorkspace?.id
  }

  let project = useProject(projectId)
  // If the project does not belong to the workspace, clear it
  if (
    projectId != null &&
    project != null &&
    project.workspaceId !== workspaceId
  ) {
    project = null
  }

  if (workspaceId == null || workspace == null) {
    throw new Error('Workspace not defined', {
      cause: {
        workspaceId,
        workspace,
      },
    })
  }

  const defaultFormData = useTaskInitialFormData({
    searchParams,
    workspaceId,
    task,
    parentTask,
    project,
    scheduledStart: location.state?.fixedTask
      ? searchParams.forStartDate
      : undefined,
    scheduledEnd: location.state?.fixedTask
      ? searchParams.forDueDate
      : undefined,
    userDefinedTaskDefaults,
  })

  return useMemo(() => {
    return {
      ...defaultFormData,
      ...(location.state?.reminderTask &&
        searchParams.forStartDate && {
          duration: SHORT_TASK_DURATION,
          startDate: searchParams.forStartDate,
          dueDate: parseDate(searchParams.forStartDate).endOf('day').toISO(),
        }),
    }
  }, [defaultFormData, location.state?.reminderTask, searchParams.forStartDate])
}

export type TaskInitialFormDataProps = Pick<
  GetInitialFormDataOptions,
  | 'searchParams'
  | 'workspaceId'
  | 'project'
  | 'task'
  | 'parentTask'
  | 'scheduledStart'
  | 'scheduledEnd'
  | 'userDefinedTaskDefaults'
>

export function useTaskInitialFormData({
  workspaceId,
  task,
  parentTask,
  project,
  scheduledStart,
  scheduledEnd,
  searchParams,
  userDefinedTaskDefaults,
}: TaskInitialFormDataProps): TaskFormFields {
  const { uid: currentUserId } = useAuthenticatedUser()
  const { data: firestoreSettings } = useFirestoreSettings()
  const schedules = useMemo(
    () => firestoreSettings?.schedules ?? {},
    [firestoreSettings]
  )

  const workspaceStatuses = useWorkspaceStatuses(workspaceId)
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(workspaceId)
  const workspaceLabels = useWorkspaceLabels(workspaceId)

  return useMemo(() => {
    const data = getInitialFormData({
      currentUserId,
      workspaceId,
      workspaceStatuses,
      workspaceCustomFields,
      workspaceLabels,
      schedules,
      task,
      parentTask,
      project,
      scheduledStart,
      scheduledEnd,
      searchParams,
      userDefinedTaskDefaults,
    })

    return data
  }, [
    currentUserId,
    workspaceId,
    workspaceStatuses,
    workspaceCustomFields,
    workspaceLabels,
    schedules,
    task,
    parentTask,
    project,
    scheduledStart,
    scheduledEnd,
    searchParams,
    userDefinedTaskDefaults,
  ])
}
