import { showToast, type SubscriberToast, ToasterState } from '@motion/ui/base'
import { type NoteSchema } from '@motion/zod/client'

import { WebMentionsPlugin } from '~/areas/notes/components/editor-plugins'
import { useCheckAccessFn } from '~/global/rpc/v2'
import { useState } from 'react'

import { UninvitedUserToast } from '../../components/uninvited-user-tooltip'

export type CommentsMentionsPluginProps = {
  noteId?: NoteSchema['id']
}

export function CommentsMentionsPlugin({
  noteId,
}: CommentsMentionsPluginProps) {
  const [mentionToastMap, setMentionToastMap] = useState(
    new Map<string, SubscriberToast>()
  )

  const checkAccess = useCheckAccessFn()

  const handleMentionCreate = async (
    motionId: string,
    entityId: string,
    entityType: string
  ) => {
    if (!noteId) return

    if (entityType !== 'user') return

    const { canAccess } = await checkAccess({
      resourceId: noteId,
      resourceType: 'NOTE',
      action: 'read',
      userId: entityId,
    })

    if (!canAccess) {
      const toast = showToast('custom', ({ close }) => (
        <UninvitedUserToast noteId={noteId} userId={entityId} onClose={close} />
      ))

      setMentionToastMap((prev) => {
        const newMap = new Map(prev)
        newMap.set(motionId, toast)
        return newMap
      })
    }
  }

  const handleMentionDelete = (motionId: string) => {
    const toast = mentionToastMap.get(motionId)

    if (toast != null) {
      ToasterState.dismiss(toast)
    }

    setMentionToastMap((prev) => {
      const newMap = new Map(prev)
      newMap.delete(motionId)
      return newMap
    })
  }

  return (
    <WebMentionsPlugin
      onMentionCreated={handleMentionCreate}
      onMentionDeleted={handleMentionDelete}
    />
  )
}
