import { type MeetingActionItemSchema } from '@motion/rpc-types'
import { byValue, orderedAtEnd } from '@motion/utils/array'

import { useMemo } from 'react'

import { ActionItem } from './action-item'

type ActionItemListProps = {
  actionItems: MeetingActionItemSchema[]
}

/**
 * Sorted list of action items, where the rejected items are at the bottom.
 */
export function ActionItemList({ actionItems }: ActionItemListProps) {
  const sorted = useMemo(() => {
    return actionItems.sort(
      byValue(
        (item) =>
          item.taskId != null
            ? 'accepted'
            : item.triagedByUserId
              ? 'rejected'
              : 'pending',
        orderedAtEnd(['pending', 'accepted', 'rejected'])
      )
    )
  }, [actionItems])

  return (
    <div className='flex flex-col gap-2'>
      {sorted.map((actionItem) => (
        <ActionItem key={actionItem.id} actionItem={actionItem} />
      ))}
    </div>
  )
}
