import { templateStr } from '@motion/react-core/strings'
import { getStageTense } from '@motion/ui-logic/pm/project'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
} from '@motion/zod/client'

import { useIsFlowProject } from '~/areas/flows'
import { useCompleteProjectStage } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useCompleteStage = () => {
  const { mutateAsync: completeProjectStage } = useCompleteProjectStage()
  const modalApi = useModalApi()
  const isFlowProject = useIsFlowProject()

  return useCallback(
    async (
      project: ProjectSchema,
      stageDefinitionId: StageDefinitionSchema['id'],
      onConfirmed?: () => void
    ) => {
      try {
        if (!isFlowProject(project)) {
          throw new Error(
            `Completing stages can only be done in project workflows`
          )
        }

        const stageTense = getStageTense(project, stageDefinitionId)

        const confirmResponse = await modalApi.prompt('confirm', {
          title: 'Are you sure you want to complete tasks in this stage?',
          description: templateStr(
            'This will complete all incomplete tasks in this stage{{ currentStageText }}.',
            {
              currentStageText:
                stageTense === 'current'
                  ? ' and move this project to the next stage'
                  : '',
            }
          ),
          confirmButtonText: 'Complete stage',
          analytics: { name: 'complete-stage' },
        })

        if (confirmResponse === ModalDismissed) return false

        onConfirmed?.()

        await completeProjectStage({
          projectId: project.id,
          stageDefinitionId,
        })

        return true
      } catch (e) {
        Sentry.captureException(e, {
          tags: { position: 'complete-stage' },
        })

        showErrorToast(e, 'There was an error completing the stage')
      }

      return false
    },
    [isFlowProject, modalApi, completeProjectStage]
  )
}
