import { useOnValueChange } from '@motion/react-core/hooks'
import { templateStr } from '@motion/react-core/strings'
import { type Contact } from '@motion/rpc/types'
import { Button } from '@motion/ui/base'
import { isArrayEqual } from '@motion/utils/array'
import { values } from '@motion/utils/object'

import { useI18N } from '~/global/contexts'
import { useState } from 'react'

import { ContactsDropdownContent } from './contacts-dropdown-content'

import { useContacts } from '../../../hooks'

export type SubmitContactsDropdownContentProps = {
  close: () => void
  onChange: (contacts: Contact[]) => void
  selectedEmails: string[]
}

export function SubmitContactsDropdownContent(
  props: SubmitContactsDropdownContentProps
) {
  const { contacts } = useContacts({
    contactsSource: 'general',
  })
  const { pluralize } = useI18N()

  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([])

  const selectedEmails = selectedContacts.map((contact) => contact.email)

  useOnValueChange(
    props.selectedEmails,
    () => {
      setSelectedContacts(
        props.selectedEmails.map((email) => contacts[email]).filter(Boolean)
      )
    },
    {
      triggerOnFirstRender: true,
    }
  )

  const onChange = (contact: Contact) => {
    if (selectedContacts.find((c) => c.email === contact.email)) {
      setSelectedContacts((prev) =>
        prev.filter((c) => c.email !== contact.email)
      )
      return
    }

    setSelectedContacts((prev) => [...prev, contact])
  }

  const onSave = () => {
    const changedContacts = values(contacts).filter((contact) => {
      // If contact is added
      if (
        selectedContacts.find((c) => c.email === contact.email) &&
        !props.selectedEmails.includes(contact.email)
      ) {
        return true
      }

      // If contact is removed
      if (
        !selectedContacts.find((c) => c.email === contact.email) &&
        props.selectedEmails.includes(contact.email)
      ) {
        return true
      }

      return false
    })

    props.onChange(changedContacts)
    props.close()
  }

  // Form is dirty if the selected contacts are different from selectedEmails
  const isDirty = !isArrayEqual(selectedEmails, props.selectedEmails, {
    unordered: true,
  })

  return (
    <div>
      <ContactsDropdownContent
        close={props.close}
        onChange={onChange}
        selectedEmails={selectedEmails}
      />
      <div className='flex items-center p-1.5 gap-1'>
        <Button
          size='small'
          sentiment='neutral'
          variant='muted'
          onClick={() => setSelectedContacts([])}
        >
          Clear
        </Button>
        <span className='text-semantic-neutral-text-subtle text-2xs mr-auto'>
          {templateStr('{{text}} selected', {
            text: pluralize(
              selectedContacts.length,
              '{{count}} contact',
              '{{count}} contacts'
            ),
          })}
        </span>

        <Button disabled={!isDirty} size='small' onClick={onSave}>
          Save
        </Button>
      </div>
    </div>
  )
}
