import { useContext } from 'react'

import { NoteCommentsContext } from './note-comments-context'

export function useNoteCommentsContext() {
  const context = useContext(NoteCommentsContext)
  if (!context) {
    throw new Error(
      'useNoteCommentsContext must be used within an NoteCommentsProvider'
    )
  }
  return context
}
