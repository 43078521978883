import { twMerge } from 'tailwind-merge'

type ParagraphProps = {
  className?: string
  children: React.ReactNode
}

/**
 * @deprecated
 */
export const Paragraph = ({
  className = '',
  children,
}: ParagraphProps & JSX.IntrinsicElements['p']) => {
  const colorClasses = 'text-light-1200 dark:text-dark-100'

  return <p className={twMerge('!mb-0', colorClasses, className)}>{children}</p>
}
