import { useShortcut } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'

import { useWorkspaceById } from '~/global/hooks'
import { NAME_FIELD_RULE } from '~/global/rules'
import { useController } from 'react-hook-form'

import { UsingDefinitionInWorkspaceLabel } from '../../../v2-setup-project-modal/components'
import { useSetupProjectFormNavigationContext } from '../../hooks/ctx'
import { useSetupProjectForm } from '../../hooks/use-setup-project-form'

export const NameStepContent = () => {
  const {
    form: { control, watch },
  } = useSetupProjectForm()

  const { field: nameField } = useController({
    control,
    name: 'name',
    defaultValue: '',
    rules: NAME_FIELD_RULE,
  })

  const { field: workspaceIdField } = useController({
    control,
    name: 'workspaceId',
    defaultValue: '',
  })

  const workspace = useWorkspaceById(workspaceIdField.value)
  if (workspace == null) {
    throw new Error('Workspace not found')
  }

  const projectDefinition = watch('projectDefinition')

  const { goForward } = useSetupProjectFormNavigationContext()

  useShortcut('Enter', (e) => {
    if (nameField.value.trim().length > 0) {
      goForward(e)
    }
  })

  return (
    <div className='flex flex-col items-start gap-3 flex-1 self-stretch w-full'>
      <TextField
        autoFocus
        {...nameField}
        fullWidth
        placeholder='Project name'
        label='Name'
      />

      {projectDefinition != null && (
        <UsingDefinitionInWorkspaceLabel
          projectDefinition={projectDefinition}
          workspace={workspace}
        />
      )}
    </div>
  )
}

export const LegacyNameStepContent = () => {
  const {
    form: { control },
  } = useSetupProjectForm()

  const { field } = useController({
    control,
    name: 'name',
    defaultValue: '',
    rules: NAME_FIELD_RULE,
  })

  const { goForward } = useSetupProjectFormNavigationContext()

  useShortcut('Enter', (e) => {
    if (field.value.trim().length > 0) {
      goForward(e)
    }
  })

  return <TextField autoFocus {...field} placeholder='Enter project title' />
}
