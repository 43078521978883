import {
  AutoFocusPlugin,
  BroadcastPlugin,
  CommentsPlugin,
  Editor,
  EmbedNode,
  InlineCreatePlugin,
  OnChangePlugin,
  SearchPlugin,
  SelectionAlwaysOnDisplayPlugin,
  TitleEditor,
  WebsocketCollaborationPlugin,
} from '@motion/notes'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
} from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { type NoteSchema } from '@motion/rpc-types'
import { getUserColor } from '@motion/utils/color'
import { debounce } from '@motion/utils/core'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { useQueryClient } from '@tanstack/react-query'
import { NoteColorTag, WebMentionsPlugin } from '~/areas/notes/components'
import { ErrorBoundary } from '~/global/components'
import { memo, useCallback, useState } from 'react'

import { useNoteCommentsContext } from './comments'
import { useNoteEditorAPI } from './context/note-editor-context-provider'
import { NotesErrorPage } from './error-page'
import { VERSION_ERROR_MESSAGE } from './error-page/constants'
import { useNoteDialogContext } from './hooks'
import { CommentsMentionsPlugin } from './plugins/comments-mentions-plugin'
import { CustomActionItemBlockNode } from './plugins/custom-action-item-block-plugin'
import {
  CustomAttachmentNode,
  CustomAttachmentsPlugin,
} from './plugins/custom-attachments-plugin'
import { CustomCollapsibleListsPlugin } from './plugins/custom-collapsible-lists-plugin'
import { CustomDraggableBlockPlugin } from './plugins/custom-draggable-block-plugin'
import { InlineCreateTag } from './plugins/custom-inline-create-plugin'
import { CustomSlashMenuPlugin } from './plugins/custom-slash-menu-plugin'
import { CustomTextSelectionToolbarPlugin } from './plugins/custom-text-selection-toolbar-plugin/custom-text-selection-toolbar-plugin'
import { DocsMentionsPlugin } from './plugins/docs-mentions-plugin'

type ConnectedEditorProps = {
  noteId: NoteSchema['id']
  workspaceId: NoteSchema['workspaceId']
  readonly: boolean
}

export const ConnectedEditor = memo(
  ({ noteId, workspaceId, readonly }: ConnectedEditorProps) => {
    const queryClient = useQueryClient()
    const { openedDialog } = useNoteDialogContext()

    const [editorError, setEditorError] = useState<Error | null>(null)
    const [editorErrorDismissed, setEditorErrorDismissed] =
      useState<boolean>(false)

    const user = useAuthenticatedUser()
    const {
      activeThreadId,
      setActiveThreadId,
      setActiveTab,
      createComment,
      commentsPluginRef,
    } = useNoteCommentsContext()

    const [loading, setLoading] = useState<boolean>(true)
    const [loadingTitle, setLoadingTitle] = useState<boolean>(true)

    const { setEditorRef } = useNoteEditorAPI()

    const handleOnSynced = useCallback((message: { state: boolean }) => {
      if (message.state) {
        setLoading(false)
      }
    }, [])

    const handleTitleChange = debounce(() => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_NOTE_TITLE_CHANGE')
    }, 1000)

    const handleTitleBlur = (value: { text: string }) => {
      // Update cache for note
      MotionCache.patch(
        queryClient,
        createQueryFilter([API.notes.queryKeys.root, MODEL_CACHE_KEY]),
        'notes',
        {
          [noteId]: {
            title: value.text,
          },
        }
      )
    }

    const handleTitleSynced = useCallback((message: { state: boolean }) => {
      if (message.state) {
        setLoadingTitle(false)
      }
    }, [])

    const handleSetActiveThreadId = useCallback(
      (threadId: string | undefined) => {
        setActiveThreadId(threadId)
        if (threadId) {
          setActiveTab('active')
        }
      },
      [setActiveTab, setActiveThreadId]
    )

    const getToken = useCallback(() => {
      return user.getIdToken()
    }, [user])

    const handleVersionError = useCallback(() => {
      setEditorError(new Error(VERSION_ERROR_MESSAGE))
    }, [])

    if (editorError && !editorErrorDismissed) {
      return (
        <NotesErrorPage
          noteId={noteId}
          error={editorError}
          reset={() => {
            setEditorError(null)
            setEditorErrorDismissed(true)
          }}
        />
      )
    }

    return (
      <ErrorBoundary
        renderFallback={(props) => (
          <NotesErrorPage noteId={noteId} {...props} />
        )}
      >
        <Editor
          readonly={readonly}
          onError={(error) => {
            setEditorError(error)
          }}
          editorRef={loading ? undefined : setEditorRef}
          namespace='motion-notes-rte'
          loading={loading}
          websocketUrl={import.meta.env.MOTION_NOTES_WEBSOCKETS_HOST}
          // Let the provider initialize the state
          state={null}
          nodes={[
            DocsMentionsPlugin.Node,
            CustomAttachmentNode,
            CustomActionItemBlockNode,
            EmbedNode,
          ]}
          plugins={
            <>
              <CustomSlashMenuPlugin />
              <CustomTextSelectionToolbarPlugin noteId={noteId} />
              <DocsMentionsPlugin noteId={noteId} />
              <CustomAttachmentsPlugin noteId={noteId} />
              <InlineCreatePlugin
                render={(entityType, onCreated, onBlur) => (
                  <InlineCreateTag
                    entityType={entityType}
                    onCreated={onCreated}
                    onBlur={onBlur}
                  />
                )}
              />
              <WebsocketCollaborationPlugin
                id={noteId}
                userDisplayName={user.displayName ?? user.email ?? ''}
                userColor={getUserColor(user.uid)}
                getToken={getToken}
                onSynced={handleOnSynced}
                onVersionError={handleVersionError}
              />
              <CommentsPlugin
                ref={commentsPluginRef}
                activeThreadId={activeThreadId}
                setActiveThreadId={handleSetActiveThreadId}
                createComment={createComment}
                editorNodes={[WebMentionsPlugin.Node]}
                editorPlugins={<CommentsMentionsPlugin noteId={noteId} />}
              />
              <CustomCollapsibleListsPlugin />
              <BroadcastPlugin noteId={noteId} />
              <CustomDraggableBlockPlugin noteId={noteId} />
              <SelectionAlwaysOnDisplayPlugin enabled={openedDialog != null} />
              <SearchPlugin />
            </>
          }
          titleEditor={
            <TitleEditor
              loading={loadingTitle}
              readonly={readonly}
              plugins={
                <>
                  <OnChangePlugin
                    onChange={handleTitleChange}
                    ignoreSelectionChange
                    ignoreHistoryMergeTagChange
                  />
                  <WebsocketCollaborationPlugin
                    id={noteId + '-title'}
                    userDisplayName={user.displayName ?? user.email ?? ''}
                    userColor={getUserColor(user.uid)}
                    getToken={getToken}
                    onSynced={handleTitleSynced}
                  />
                  {!loading && <AutoFocusPlugin onlyWhenEmpty />}
                </>
              }
              onBlur={handleTitleBlur}
            />
          }
          icon={<NoteColorTag noteId={noteId} />}
        />
      </ErrorBoundary>
    )
  }
)

ConnectedEditor.displayName = 'ConnectedEditor'
