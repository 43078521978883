import type { ProjectDefinitionSchema } from '@motion/rpc-types'
import { mapCustomFieldToFieldArrayWithValue } from '@motion/ui-logic'

import {
  useCustomFieldsByWorkspaceId,
  useCustomFieldsFns,
} from '~/areas/custom-fields/hooks'
import { useCallback } from 'react'

export function useCustomFieldFormValues(
  workspaceId: string,
  projectDefinition: ProjectDefinitionSchema | null
) {
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(workspaceId)

  const customFieldValuesFieldArray = workspaceCustomFields.map((field) =>
    mapCustomFieldToFieldArrayWithValue(
      field,
      projectDefinition?.customFieldValues ?? {}
    )
  )

  return customFieldValuesFieldArray
}

export function useCustomFieldFormValuesFn() {
  const { getCustomFields } = useCustomFieldsFns()

  return useCallback(
    (
      workspaceId: string,
      projectDefinition: ProjectDefinitionSchema | null
    ) => {
      const workspaceCustomFields = getCustomFields(workspaceId)

      const customFieldValuesFieldArray = workspaceCustomFields.map((field) =>
        mapCustomFieldToFieldArrayWithValue(
          field,
          projectDefinition?.customFieldValues ?? {}
        )
      )

      return customFieldValuesFieldArray
    },
    [getCustomFields]
  )
}
