import { useShortcut } from '@motion/ui/base'
import { type TextVariable } from '@motion/ui-logic/pm/project'

import { isTextVariable } from '~/areas/flows'
import { useMemo } from 'react'

import { ControlledTextReplacementField } from './fields'

import {
  useSetupProjectForm,
  useSetupProjectModalSubmitHandler,
} from '../../hooks'

export const TextReplacementsStepContent = () => {
  const { form } = useSetupProjectForm()
  const textReplacements = form.watch('textReplacements')

  const textVariables = form
    .watch('projectDefinition.stages')
    .flatMap((stage) => stage.variables)
    .filter(isTextVariable)

  const groupIndicesByVariableName = useMemo(() => {
    const textVariablesMap = textVariables.reduce(
      (acc, variable) => {
        acc[variable.id] = variable
        return acc
      },
      {} as Record<string, TextVariable>
    )

    return textReplacements.reduce(
      (acc, textReplacement, idx) => {
        const textVariable = textVariablesMap[textReplacement.variableId]
        if (!textVariable) {
          return acc
        }

        acc[textVariable.name] ??= []
        acc[textVariable.name].push(idx)
        return acc
      },
      {} as Record<string, number[]>
    )
  }, [textVariables, textReplacements])

  const submit = useSetupProjectModalSubmitHandler()
  useShortcut('Enter', (e) => {
    submit(e as any)
  })

  return (
    <div className='flex flex-col gap-5'>
      {Object.keys(groupIndicesByVariableName).map((variableName) => (
        <ControlledTextReplacementField
          key={`text-replacement-${variableName}`}
          groupName={variableName}
          groupIndices={groupIndicesByVariableName[variableName]}
        />
      ))}
    </div>
  )
}
