import {
  type ProjectSchema,
  type StatusSchema,
  type TaskSchema,
} from '@motion/zod/client'

import { useProjectStatusUpdater } from '~/areas/project/hooks'
import { TaskStatusDropdown } from '~/areas/tasks/components'
import { useTaskStatusData } from '~/areas/tasks/hooks'
import { StatusDropdown } from '~/global/components/dropdowns'
import { ConnectedStatusLabel } from '~/global/components/labels'
import { type ReactNode } from 'react'

import { MentionCardButton } from '../mention-card-button'

export type TaskStatusFieldProps = {
  task: TaskSchema
}
export const TaskStatusField = ({ task }: TaskStatusFieldProps) => {
  const { onChange, disabled, statusTaskVariant, shouldExcludeResolved } =
    useTaskStatusData(task)

  if (statusTaskVariant === 'meeting') {
    return (
      <MentionCardButton disabled={disabled}>
        <ConnectedStatusLabel
          id={task.statusId}
          taskVariant={statusTaskVariant}
        />
      </MentionCardButton>
    )
  }

  return (
    <TaskStatusDropdown
      isUnvisitedStage={task.isUnvisitedStage}
      selectedStatusId={task.statusId}
      workspaceId={task.workspaceId}
      onChange={onChange}
      taskVariant={statusTaskVariant}
      excludeResolved={shouldExcludeResolved}
    >
      <MentionCardButton disabled={disabled}>
        <ConnectedStatusLabel
          id={task.statusId}
          iconVariant={task.isUnvisitedStage ? 'isUnvisitedStage' : undefined}
          taskVariant={statusTaskVariant}
        />
      </MentionCardButton>
    </TaskStatusDropdown>
  )
}

export type ProjectStatusFieldProps = {
  project: ProjectSchema
}
export const ProjectStatusField = ({ project }: ProjectStatusFieldProps) => {
  const updateProjectStatus = useProjectStatusUpdater()

  return (
    <StatusField
      selectedStatusId={project.statusId}
      workspaceId={project.workspaceId}
      onChange={(statusId) => updateProjectStatus(project, statusId)}
    >
      <ConnectedStatusLabel id={project.statusId} />
    </StatusField>
  )
}

type StatusFieldProps = {
  selectedStatusId: StatusSchema['id']
  workspaceId: StatusSchema['workspaceId']
  onChange: (statusId: StatusSchema['id']) => void
  children: ReactNode
}
const StatusField = ({
  selectedStatusId,
  workspaceId,
  onChange,
  children,
}: StatusFieldProps) => {
  return (
    <StatusDropdown
      selectedStatusId={selectedStatusId}
      workspaceId={workspaceId}
      onChange={onChange}
    >
      <MentionCardButton>{children}</MentionCardButton>
    </StatusDropdown>
  )
}
