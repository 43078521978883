import {
  ActionList,
  PopoverButton,
  PopoverTrigger,
  Text,
} from '@motion/ui/base'

import { useState } from 'react'

import { SectionField } from '../components'

const workflows = [
  {
    id: 'blog-writer',
    name: 'Blog post agent',
    description: 'Expert in writing blog posts',
  },
  {
    id: 'product-spec-writer',
    name: 'Spec agent',
    description: 'Expert in writing feature specs',
  },
]

export function WorkflowField() {
  const [workflowId, setWorkflowId] = useState('blog-writer')

  const selectedWorkflow = workflows.find((w) => w.id === workflowId)

  return (
    <SectionField title='AI Workflow'>
      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <WorkflowDropdownContent
            close={close}
            onChange={(id) => setWorkflowId(id)}
          />
        )}
      >
        <PopoverButton>
          <Text truncate size='sm'>
            {selectedWorkflow?.name}
          </Text>
        </PopoverButton>
      </PopoverTrigger>
    </SectionField>
  )
}

type AgentPickerContentProps = {
  close: () => void
  onChange: (id: string) => void
}
function WorkflowDropdownContent({ close, onChange }: AgentPickerContentProps) {
  return (
    <ActionList
      onActionAnyItem={close}
      items={workflows.map((w) => ({
        content: w.name,
        description: w.description,
        onAction: () => {
          onChange(w.id)
        },
      }))}
    />
  )
}
