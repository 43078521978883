import { ExclamationTriangleSolid } from '@motion/icons'
import { type ProjectSchema, type WorkspaceSchema } from '@motion/rpc-types'
import { classed } from '@motion/theme'

import { useCachedItem } from '~/global/cache'
import {
  ProjectLabel as GenericProjectLabel,
  WorkspaceLabel as GenericWorkspaceLabel,
} from '~/global/components/labels'

type FullPathLabelProps = {
  workspace: WorkspaceSchema | null
  project: ProjectSchema | null
  nameOnly?: boolean
}

export function FullPathLabel({
  workspace,
  project,
  nameOnly,
}: FullPathLabelProps) {
  const folder = useCachedItem('folders', project?.folderId ?? '')

  // It means that the workspace isn't accessible to the client due to permissions
  if (workspace == null) {
    return (
      <span className='inline-flex items-center gap-1 text-semantic-warning-text-subtle'>
        <ExclamationTriangleSolid className='size-3 !text-semantic-warning-bg-strong-default' />
        No access to workspace
      </span>
    )
  }

  const WorkspaceLabel = () => (
    <LabelContainer>
      <GenericWorkspaceLabel
        size='xsmall'
        value={workspace}
        nameOnly={nameOnly}
      />
    </LabelContainer>
  )
  const ProjectLabel = () => (
    <LabelContainer>
      <GenericProjectLabel size='xsmall' value={project} nameOnly={nameOnly} />
    </LabelContainer>
  )

  // The project is directly in the workspace
  if (folder?.type === 'WORKSPACE' && folder?.targetId === workspace.id) {
    return (
      <span className='flex flex-row gap-0.5'>
        <WorkspaceLabel />
        <span>/</span>
        <ProjectLabel />
      </span>
    )
  }

  // The project is in a folder
  return (
    <span className='flex flex-row gap-0.5'>
      <WorkspaceLabel />
      <span>/</span>
      <span>...</span>
      <span>/</span>
      <ProjectLabel />
    </span>
  )
}

const LabelContainer = classed('span', {
  base: `max-w-[120px] truncate`,
})
