import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { getWindowData } from '@motion/web-base/env'

import { selectDesktopSettings } from '~/state/desktopSlice'
import { useAppSelector } from '~/state/hooks'
import { useEffect, useState } from 'react'

import { createDesktopDeepLink } from '../utils'

const forwardableTags = [
  'task',
  'project',
  'templateProject',
  'templateTask',
  'template',
]

function isRouteForwardable(location: typeof window.location) {
  const qs = new URLSearchParams(location.search)

  return (
    forwardableTags.some((tag) => qs.has(tag) && qs.get(tag) !== 'new') ||
    location.pathname.includes('/docs/')
  )
}

export const useShouldDeeplink = () => {
  const { isElectron } = getWindowData()
  // TODO: Move this off redux
  const desktopSettings = useAppSelector(selectDesktopSettings)

  const [showOpenDesktopPopup, setShowOpenDesktopPopup] = useState(false)
  const [showOpenDesktopPage, setShowOpenDesktopPage] = useState(false)
  const [forwardedToDesktop, setForwardedToDesktop] = useState(false)

  useEffect(
    function shouldShowOpenDesktopPopupOrPage() {
      const navigatedByUrl = !document.referrer?.startsWith(
        window.location.origin
      )

      // If user navigated to this page by typing in the url, and they are not on electron (desktop app)
      if (
        navigatedByUrl &&
        !isElectron &&
        // Already forwarded to desktop
        !forwardedToDesktop &&
        // If the user has the desktop app installed and meets minimum version for deep links (found in their settings)
        desktopSettings?.hasDesktopApp &&
        isRouteForwardable(window.location)
      ) {
        setForwardedToDesktop(true)
        if (desktopSettings?.deepLinks?.alwaysOpenInApp) {
          setShowOpenDesktopPage(true)
          const url = createDesktopDeepLink(window.location)
          void recordAnalyticsEvent('DESKTOP_WAS_DEEP_LINKED')
          window.open(url, '_self')
        } else if (!desktopSettings?.deepLinks?.hasDismissedDeepLinkPopup) {
          setShowOpenDesktopPopup(true)
        }
      }
    },
    [
      desktopSettings?.deepLinks?.alwaysOpenInApp,
      desktopSettings?.deepLinks?.hasDismissedDeepLinkPopup,
      desktopSettings?.hasDesktopApp,
      isElectron,
      forwardedToDesktop,
    ]
  )

  return {
    showOpenDesktopPopup,
    setShowOpenDesktopPopup,
    showOpenDesktopPage,
    setShowOpenDesktopPage,
    forwardedToDesktop,
  }
}
