import { templateStr } from '@motion/react-core/strings'
import { classed } from '@motion/theme'
import { Text } from '@motion/ui/base'
import { type StageSchema } from '@motion/zod/client'

import { ReferenceEditor } from '~/areas/notes/components'
import { StageLabel } from '~/global/components/labels'
import { useI18N } from '~/global/contexts'
import { useEffect, useMemo, useRef } from 'react'
import { Controller } from 'react-hook-form'

import { useSetupProjectForm } from '../hooks'

export function AIChangesSidebar() {
  const { form } = useSetupProjectForm()
  const activeTab = form.watch('step')
  const speculativeProject = form.watch('speculativeProject')
  const speculativeTasksByStageDefinitionId = form.watch(
    'speculativeTasksByStageDefinitionId'
  )
  const modifications = form.watch('modifications')

  const nameSectionRef = useRef<HTMLElement>(null)
  const rolesSectionRef = useRef<HTMLElement>(null)
  const textVariablesSectionRef = useRef<HTMLElement>(null)
  const stagesSectionRef = useRef<HTMLElement>(null)

  const reasonsOfStages = useMemo(() => {
    if (
      speculativeProject == null ||
      speculativeTasksByStageDefinitionId == null ||
      modifications == null
    ) {
      return null
    }

    return speculativeProject.stages.map((stage) => {
      const speculativeTasks =
        speculativeTasksByStageDefinitionId[stage.stageDefinitionId] ?? []
      return {
        stage,
        reasons: speculativeTasks.reduce<string[]>((acc, task) => {
          const modification = modifications[task.id]
          if (modification?.reason != null) {
            acc.push(modification.reason)
          }
          return acc
        }, []),
      }
    })
  }, [speculativeProject, speculativeTasksByStageDefinitionId, modifications])

  useEffect(() => {
    const options = { block: 'start', behavior: 'smooth' } as const
    switch (activeTab) {
      case 'name':
        nameSectionRef.current?.scrollIntoView(options)
        break
      case 'roles':
        rolesSectionRef.current?.scrollIntoView(options)
        break
      case 'textVariables':
        textVariablesSectionRef.current?.scrollIntoView(options)
        break
      case 'stages':
        stagesSectionRef.current?.scrollIntoView(options)
        break
    }
  }, [activeTab, reasonsOfStages])

  return (
    <div className='flex flex-col gap-7 px-3 pb-5'>
      <Controller
        control={form.control}
        name='nameReason'
        render={({ field }) => (
          <Section active={activeTab === 'name'} ref={nameSectionRef}>
            <SectionTitle>Project title</SectionTitle>
            <SubSection>
              <SubSectionTitle>Set name</SubSectionTitle>
              <Reason name={field.name} reason={field.value} />
            </SubSection>
          </Section>
        )}
      />

      <Controller
        control={form.control}
        name='initialPersonVariables'
        render={({ field }) => (
          <>
            {field.value.length > 0 && (
              <Section active={activeTab === 'roles'} ref={rolesSectionRef}>
                <SectionTitle>Assigned roles</SectionTitle>
                <ReasonList
                  name={field.name}
                  label={['role', 'roles']}
                  items={field.value.map((variable) => variable.reason)}
                />
              </Section>
            )}
          </>
        )}
      />

      <Controller
        control={form.control}
        name='initialTextVariables'
        render={({ field }) => (
          <>
            {field.value.length > 0 && (
              <Section
                active={activeTab === 'textVariables'}
                ref={textVariablesSectionRef}
              >
                <SectionTitle>Text variables</SectionTitle>
                <ReasonList
                  name={field.name}
                  label={['text variable', 'text variables']}
                  items={field.value.map((variable) => variable.reason)}
                />
              </Section>
            )}
          </>
        )}
      />

      <Section active={activeTab === 'stages'} ref={stagesSectionRef}>
        <SectionTitle>Stages & tasks</SectionTitle>
        {reasonsOfStages ? (
          <div className='flex flex-col gap-3'>
            {reasonsOfStages.map(({ stage, reasons }, index) => (
              <StageReasons
                key={stage.id}
                index={index}
                stage={stage}
                reasons={reasons}
              />
            ))}
          </div>
        ) : (
          <Text size='xs' sentiment='default'>
            {activeTab === 'stages'
              ? 'Loading...'
              : 'The AI reasons for this section will be available in the last step of the form.'}
          </Text>
        )}
      </Section>
    </div>
  )
}

type StageReasonsProps = {
  index: number
  stage: StageSchema
  reasons: string[]
}

function StageReasons({ index, stage, reasons }: StageReasonsProps) {
  const { form } = useSetupProjectForm()
  const isSkippingStage = Boolean(
    form.watch(`speculativeProject.stages.${index}.canceledTime`)
  )

  return (
    <div
      key={stage.id}
      className='flex flex-col items-start gap-3 pb-3 border-b border-b-semantic-neutral-border-subtle last:border-none last:p-0'
    >
      <StageLabel
        value={{
          name: stage.name ?? 'No stage',
          color: stage.color ?? 'gray',
        }}
        variant={isSkippingStage ? 'skipped' : 'default'}
      />
      {reasons.length ? (
        <ReasonList name={`stage-${index}`} items={reasons} />
      ) : (
        <Text size='xs' sentiment='subtle'>
          AI has made no changes to this stage.
        </Text>
      )}
    </div>
  )
}

type ReasonListProps = {
  name: string
  label?: [string, string]
  items: string[]
}

function ReasonList({ name, label, items }: ReasonListProps) {
  const { pluralize } = useI18N()
  return (
    <SubSection>
      {label && (
        <SubSectionTitle>
          {templateStr('Set {{count}} {{label}}', {
            count: items.length,
            label: pluralize(items.length, ...label),
          })}
        </SubSectionTitle>
      )}
      <ol className='list-decimal ml-4 text-xs text-semantic-neutral-text-default space-y-4'>
        {items.map((role, index) => (
          <li key={index}>
            <Reason name={`${name}-${index}`} reason={role} />
          </li>
        ))}
      </ol>
    </SubSection>
  )
}

type ReasonProps = {
  name: string
  reason?: string
}

function Reason({ name, reason }: ReasonProps) {
  return (
    <ReferenceEditor
      className='text-xs text-semantic-neutral-text-default'
      editableClassName='p-0'
      name={name}
      initialValue={{ format: 'html', value: reason ?? '' }}
      readOnly
    />
  )
}

const Section = classed('section', {
  base: 'flex flex-col gap-3 scroll-mt-12',
  variants: {
    active: {
      false: 'opacity-50',
    },
  },
})

const SectionTitle = classed('h4', {
  base: 'text-xs font-semibold text-semantic-neutral-text-default',
})

const SubSection = classed('div', {
  base: 'flex flex-col gap-2',
})

const SubSectionTitle = classed('h5', {
  base: 'text-xs font-normal text-semantic-neutral-text-subtle',
})
