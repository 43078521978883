import { isCompletedStatus } from '@motion/shared/common'
import { isNoneId } from '@motion/shared/identifiers'
import { isCustomFieldKey } from '@motion/ui-logic'
import { isFlowProject, isGhostTask } from '@motion/ui-logic/pm/project'
import {
  isMeetingTask,
  isUnscheduledSchedulingTask,
} from '@motion/ui-logic/pm/task'
import { safeParseDate } from '@motion/utils/dates'
import { type ProjectSchema } from '@motion/zod/client'

import { useAllProjectsById, useWorkspaceFns } from '~/global/hooks'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import {
  type GroupedNode,
  isNoneGroup,
  TaskGroupableFields,
  type Tree,
} from '../../grouping'
import { isDisabledMeetingTaskField } from '../utils'

type CanDragItemToGroupResult = {
  canDrag: boolean
  errorMessage?: string
}

export const useCanDragItemToGroup = () => {
  const projects = useAllProjectsById()
  const { getWorkspaceProjectById } = useWorkspaceFns()
  /**
   * Check if an item can be dragged to a group
   * @param item The item being dragged
   * @param group The group being dragged to
   */
  return useCallback(
    <T extends GroupedNode>({
      item,
      group,
    }: {
      item: GroupedNode
      group: Tree<T> | undefined
    }): CanDragItemToGroupResult => {
      if (group == null)
        return {
          canDrag: false,
        }

      const groupType = group.item?.value?.type

      // Always allow dropping back to start
      if (isStartingGroup(item, group)) {
        return {
          canDrag: true,
        }
      }

      // Don't allow dropping into a group it already exists in
      if (isItemInGroup(item, group)) {
        return {
          canDrag: false,
        }
      }

      // Disable dragging for meeting tasks
      if (isMeetingTask(item.value.value)) {
        if (isCompleteGroup(group)) {
          return {
            canDrag: false,
            errorMessage:
              'The event will automatically be completed when it ends.',
          }
        }

        if (groupType != null && isDisabledMeetingTaskField(groupType)) {
          const groupName = TaskGroupableFields.find(
            (field) => field.id === groupType
          )?.label.toLowerCase()

          return {
            canDrag: false,
            errorMessage: `Updating the ${groupName ?? 'field'} of an event is not supported. Try updating via the Event modal.`,
          }
        }
      }

      // If it's a scheduling task, don't allow drag from/to resolved status if it's not yet scheduled
      if (isUnscheduledSchedulingTask(item?.value?.value)) {
        if (isCompleteGroup(group)) {
          return {
            canDrag: false,
            errorMessage:
              'A scheduling task can only be completed by scheduling an event.',
          }
        }
      }

      // If ghost task, keep in the same workspace and project as the group
      if (isGhostTask(item.value.value)) {
        if (!isGhostTaskInSameWorkspaceOrProject(item, group)) {
          return {
            canDrag: false,
            errorMessage:
              "Tasks in future stages can't be moved to a different workspace or project.",
          }
        }
      }

      if (groupType === 'folder' && !canDropIntoFolder(item, group)) {
        return {
          canDrag: false,
          errorMessage:
            item.value.type === 'project'
              ? 'Projects can only be dragged to folders in the same workspace.'
              : "Tasks can't be dragged into a folder.",
        }
      }

      // Prevent flow projects from being dragged to no-value groups for deadline and startDate
      if (item.value.type === 'project' && isFlowProject(item.value.value)) {
        if (
          (groupType === 'deadline' || groupType === 'startDate') &&
          isNoneGroup(group.item as GroupedNode)
        ) {
          return {
            canDrag: false,
            errorMessage: `Flow projects must have a ${groupType === 'deadline' ? 'deadline' : 'start date'}.`,
          }
        }
      }

      // Prevent tasks in flow projects from being dragged to no-value groups for startDate
      if (
        item.value.type === 'task' &&
        groupType === 'startDate' &&
        isFlowProject(getWorkspaceProjectById(item.value.value.projectId)) &&
        group.key === 'No Value'
      ) {
        return {
          canDrag: false,
          errorMessage: 'Tasks in flow projects must have a start date.',
        }
      }

      // prevent deadlines from being set into the past
      if (groupType === 'deadline' && isGroupDeadlineInThePast(group)) {
        return {
          canDrag: false,
          errorMessage: 'Deadlines cannot be moved to past dates.',
        }
      }

      // If its a 'no workspace' group (like no Label, Unassigned) always allow a drag
      if (isNoWorkspaceGroup(group)) {
        return {
          canDrag: true,
        }
      }

      // If custom field, check that the item's workspaceId is in the group's workspaceIds
      if (
        isCustomFieldKey(item.parent?.value.type ?? '') &&
        !isCustomFieldWorkspaceIdValid(item, group)
      ) {
        return {
          canDrag: false,
          errorMessage: getDifferentWorkspaceErrorMessage(item, group),
        }
      }

      if (groupType === 'stage') {
        if (canDropIntoStage(item, group, projects)) {
          return {
            canDrag: true,
          }
        }

        return {
          canDrag: false,
          errorMessage: `This stage ${group.key} does not exist in the project.`,
        }
      }

      // Check if the group has no workspace IDs
      if (group.item?.workspaceIds == null)
        return {
          canDrag: true,
        }

      const isItemInGroupWorkspace = group.item.workspaceIds.includes(
        item.value.value.workspaceId
      )

      // Check if the group's workspace IDs include the item's workspace ID
      return {
        canDrag: isItemInGroupWorkspace,
        errorMessage: isItemInGroupWorkspace
          ? undefined
          : getDifferentWorkspaceErrorMessage(item, group),
      }
    },
    [projects, getWorkspaceProjectById]
  )
}

const isGroupDeadlineInThePast = (group: Tree<GroupedNode>) => {
  const groupDate = safeParseDate(group.item?.value.value)
  return (
    groupDate != null &&
    groupDate.startOf('day') < DateTime.now().startOf('day')
  )
}

const isStartingGroup = (item: GroupedNode, group: Tree<GroupedNode>) => {
  return item.parent?.qualifiedKey === group.qualifiedKey
}

const isItemInGroup = (item: GroupedNode, group: Tree<GroupedNode>) => {
  return group.values.some(({ key }) => key === item.key)
}

const canDropIntoFolder = (item: GroupedNode, group: Tree<GroupedNode>) => {
  if (item.value.type === 'project') {
    return group.item?.value.value.targetId === item.value.value.workspaceId
  }
  return false
}

const isCustomFieldWorkspaceIdValid = (
  item: GroupedNode,
  group: Tree<GroupedNode>
) => {
  const itemWorkspaceIds = [
    ...new Set(group.values.map((item) => item.value.value.workspaceId)),
  ]

  if (itemWorkspaceIds.length > 0) {
    return itemWorkspaceIds.includes(item.value.value.workspaceId)
  }

  return true
}

const isGhostTaskInSameWorkspaceOrProject = (
  item: GroupedNode,
  group: Tree<GroupedNode>
) => {
  if (
    group.item?.value?.type === 'workspace' &&
    group.item?.value?.value?.workspaceId !== item.value.value.workspaceId
  ) {
    return false
  }

  if (
    group.item?.value?.type === 'project' &&
    group.item?.value?.value?.id !== item.value.value.projectId
  ) {
    return false
  }

  return true
}

const canDropIntoStage = (
  item: GroupedNode,
  group: Tree<GroupedNode>,
  projectsById: Record<string, ProjectSchema>
) => {
  let project: ProjectSchema | undefined

  if (item.value.type === 'task') {
    project = projectsById[item.value.value.projectId]
  } else {
    project = item.value.value
  }

  const hasStage = project?.stages.some(
    (stage) => stage.name === group.item?.key
  )
  return hasStage
}

const isNoWorkspaceGroup = (group: Tree<GroupedNode>) => {
  return isNoneId(group.key)
}

const isCompleteGroup = (group: Tree<GroupedNode>) => {
  return (
    group.item?.value.type === 'status' &&
    isCompletedStatus(group.item?.value.value)
  )
}

const getDifferentWorkspaceErrorMessage = (
  item: GroupedNode,
  group: Tree<GroupedNode>
) => {
  const groupName = group.item?.value?.value?.name
  const itemType = item.value.type

  return groupName
    ? `This ${itemType} can't be moved here because ${groupName} is not in the same workspace.`
    : `This ${itemType} can't be moved here because the group is not in the same workspace.`
}
