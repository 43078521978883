import { templateStr } from '@motion/react-core/strings'
import type {
  ProjectDefinitionSchema,
  WorkspaceSchema,
} from '@motion/rpc-types'
import { Text } from '@motion/ui/base'

import {
  ProjectDefinitionLabel,
  WorkspaceLabel,
} from '~/global/components/labels'

export type UsingDefinitionInWorkspaceLabelProps = {
  projectDefinition: ProjectDefinitionSchema
  workspace: WorkspaceSchema
}

export function UsingDefinitionInWorkspaceLabel({
  projectDefinition,
  workspace,
}: UsingDefinitionInWorkspaceLabelProps) {
  return (
    <Text size='sm' className='inline-flex gap-1'>
      {templateStr('Using {{ pwt }} in {{ workspace }}', {
        pwt: <ProjectDefinitionLabel value={projectDefinition} size='small' />,
        workspace: <WorkspaceLabel value={workspace} size='small' />,
      })}
    </Text>
  )
}
