import { DATE_FORMATTERS_BY_UNIT } from '@motion/ui-logic'
import { safeParseDate } from '@motion/utils/dates'

import {
  getGroupByDateValueOverride,
  isViewStateGroupByDateKey,
} from '~/areas/project-management/pages/pm-v3/grouping'
import { type TreeOverrides } from '~/areas/project-management/pages/pm-v3/pages/hooks'
import { type ViewStateGroupByField } from '~/areas/project-management/pages/pm-v3/view-state'

import { useNotetakerViewState } from '../../state'

export function useTreeGroupByOverride() {
  const [viewState] = useNotetakerViewState()

  const groupByOverrides = viewState.groupBy.fields.reduce<
    TreeOverrides['groupByOverrides']
  >((acc, group) => {
    if (group.by == null || !isViewStateGroupByDateKey(group.key)) {
      return acc
    }

    return {
      ...acc,
      [group.key]: {
        keyOf: getGroupByDateKeyOverride(group),
        valueOf: getGroupByDateValueOverride(group),
        ...(group.by ? { by: group.by } : {}),
      },
    }
  }, {})

  return {
    groupByOverrides,
    initialValues: {},
  }
}

function getGroupByDateKeyOverride(group: ViewStateGroupByField) {
  return (item: string) => {
    const date = safeParseDate(item)

    if (date == null || group.by == null) {
      return 'No Value'
    }

    return DATE_FORMATTERS_BY_UNIT[group.by](date)
  }
}
