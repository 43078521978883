import { Button } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasFeaturePermission } from '@motion/web-billing'
import { useModalApi } from '@motion/web-common/modals'

import { TieredPricingUpsellButton } from '~/areas/tiered-pricing'
import { SettingPageWrapper } from '~/pages/settings/shared'

import { IntegrationBlock } from './integration-block'
import mail from './mail.svg'
import ZapierLogo from './zapier_logo.svg?url'

export const IntegrationSettings = () => {
  const canUseApi = useHasFeaturePermission('api')
  const modalApi = useModalApi()

  return (
    <SettingPageWrapper title='Integrations' className='gap-8'>
      <IntegrationBlock
        title='Zapier'
        bodyText='Use Zapier to integrate Motion with other apps.'
        logo={ZapierLogo}
        integrationButton={
          <TieredPricingUpsellButton
            tier='PRO'
            feature='api'
            external
            featureLocked={!canUseApi}
            onClick={() => {
              recordAnalyticsEvent('ZAPIER_INTEGRATION_CLICK')
              window.open(
                'https://zapier.com/apps/motion/integrations',
                '_blank'
              )
            }}
          >
            Connect
          </TieredPricingUpsellButton>
        }
        isBeta={false}
      />
      <IntegrationBlock
        title='Email'
        bodyText='Turn an email into a task'
        logo={mail}
        integrationButton={
          <Button
            onClick={() => {
              recordAnalyticsEvent('EMAIL_INTEGRATION_CLICK')
              void modalApi.open('email-integration')
            }}
          >
            See How
          </Button>
        }
        isBeta={false}
      />
    </SettingPageWrapper>
  )
}
