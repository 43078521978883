import { ArrowLeftSolid, ArrowRightSolid, XSolid } from '@motion/icons'
import { useScrollContainer } from '@motion/react-core/hooks'
import { type CreateProjectDefinitionSchema } from '@motion/rpc-types'
import {
  IconButton,
  LoadingSpinner,
  showToast,
  UnstyledModal,
  useShortcut,
} from '@motion/ui/base'
import {
  recordAnalyticsEvent,
  useOnMountAnalyticsEvent,
} from '@motion/web-base/analytics'
import { useModalTitle } from '@motion/web-common/html'
import { useModalStatus } from '@motion/web-common/modals'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { ModalErrorState } from '~/areas/task-project/components'
import { useRouteConfirmationPromptBeforeLeaving } from '~/global/navigation'
import { type FormEvent, memo, useCallback } from 'react'
import { useFieldArray } from 'react-hook-form'

import {
  ArrowsHintContainer,
  FlowsTemplateFooter,
  FormShell,
  GridShell,
  StageColumns,
  StagesContent,
  StagesTitle,
  StagesTitleContainer,
} from './components'
import { TemplateModalSidebar } from './fields/sidebar-fields'
import { FlowTemplateForm } from './flow-template-form'
import {
  useCloseFlowTemplateModal,
  useFlowTemplateModalUrlParams,
  useSubmitTemplateForm,
} from './hooks'

import { FlowsModalStateProvider, useFlowsModalState } from '../contexts'
import { StageColumnsDnd, useFlowTemplateForm } from '../shared-form'
import { getFlowTemplateFormErrorMessage } from '../utils'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'flows-template-modal': {
      mode?: 'ai-generation'
      initialProjectDefinition?: CreateProjectDefinitionSchema
    }
  }
}

type ConnectedUrlFlowTemplateModalProps = {
  open: boolean
}

export function ConnectedUrlFlowTemplateModal({
  open,
}: ConnectedUrlFlowTemplateModalProps) {
  const closeModal = useCloseFlowTemplateModal()

  if (!open) {
    return null
  }

  return <ConnectedFlowTemplateModal close={closeModal} />
}

type ConnectedFlowTemplateModalProps =
  ModalTriggerComponentProps<'flows-template-modal'>

export function ConnectedFlowTemplateModal({
  close,
  initialProjectDefinition,
  mode,
}: ConnectedFlowTemplateModalProps) {
  return (
    <UnstyledModal
      data-testid='flow-template-modal'
      type='page'
      visible
      onClose={close}
      withAnimation
      disableEscapeKey
      overlayClassName='bg-modal-overlay'
      modalClassName='w-[90%] h-[90%] overflow-hidden'
    >
      <FlowTemplateForm
        mode={mode}
        initialProjectDefinition={initialProjectDefinition}
      >
        <FlowsModalStateProvider mode={mode}>
          <div className='w-full h-full flex gap-1 items-start'>
            <FlowModalContent close={close} />

            <IconButton
              icon={XSolid}
              sentiment='neutral'
              size='small'
              variant='muted'
              onClick={close}
            />
          </div>
        </FlowsModalStateProvider>
      </FlowTemplateForm>
    </UnstyledModal>
  )
}

type FlowModalContentProps = {
  close: () => void
}
const FlowModalContent = memo(function FlowModalContent({
  close,
}: FlowModalContentProps) {
  const { form } = useFlowTemplateForm()
  const submitForm = useSubmitTemplateForm()
  const modalState = useFlowsModalState()
  const { forDuplicate, fromPresetId } = useFlowTemplateModalUrlParams()
  const {
    watch,
    formState: { isDirty, isSubmitting },
  } = form

  const projectId = watch('id')
  const projectName = watch('name')
  const isLoading = watch('isLoading')
  const hasError = watch('hasError')

  const taskDefinitionModalStatus = useModalStatus('task-definition-modal')
  const hasDirtyFormFields =
    isDirty && !isSubmitting && !taskDefinitionModalStatus.visible

  useModalTitle(projectId ? projectName : 'New project')
  useRouteConfirmationPromptBeforeLeaving({
    when: hasDirtyFormFields,
  })

  useOnMountAnalyticsEvent('PROJECT_MANAGEMENT_VIEW_FLOW_TEMPLATE', {
    enabled: projectId != null,
  })
  useShortcut('mod+s', () => {
    // Task name RTE only processes HTML and updates state on blur
    // Required to catch any validation errors
    const activeElement = document.activeElement as HTMLInputElement
    activeElement?.blur?.()
    onSubmit()
  })

  const stages = watch('stages')

  const getIsSubmitDisabled = useCallback((): boolean => {
    if (
      !isDirty &&
      !fromPresetId &&
      !forDuplicate &&
      modalState.mode !== 'ai-generation'
    )
      return true

    if (stages.length === 0) return true

    const doAllStagesHaveTasks = stages.every((stage) => stage.tasks.length > 0)

    if (!doAllStagesHaveTasks) return true

    return false
  }, [isDirty, fromPresetId, forDuplicate, modalState.mode, stages])

  const isSubmitDisabled = getIsSubmitDisabled()

  const onSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    if (isSubmitDisabled) return

    await form.handleSubmit(submitForm, (validationErrors) => {
      const errors = Object.values(validationErrors).flat().filter(Boolean)
      if (errors.length < 1) return

      const firstError = errors[0]

      const message = getFlowTemplateFormErrorMessage(firstError)

      if (!message) return

      recordAnalyticsEvent('FLOW_TEMPLATE_MODAL_SUBMIT_ERROR', {
        message,
      })
      showToast('error', message)
    })(e)
  }

  const { scrollContainerRef, getListeners } =
    useScrollContainer<HTMLDivElement>()

  const {
    form: { control },
  } = useFlowTemplateForm()

  const fieldArray = useFieldArray({
    control,
    name: 'stages',
  })

  return (
    <FormShell onSubmit={onSubmit}>
      <GridShell>
        {hasError ? (
          <ModalErrorState close={close} className='col-span-2 row-span-4'>
            This project workflow template doesn&apos;t exist
          </ModalErrorState>
        ) : isLoading ? (
          <div className='col-span-2 row-span-4 w-full h-full grid place-items-center'>
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <TemplateModalSidebar />

            <StagesContent>
              <StagesTitleContainer>
                <StagesTitle>Stages</StagesTitle>

                <ArrowsHintContainer>
                  Hold shift while scrolling to scroll horizontally
                  <div className='flex gap-1.5'>
                    <IconButton
                      icon={ArrowLeftSolid}
                      size='small'
                      variant='outlined'
                      sentiment='neutral'
                      {...getListeners('left')}
                    />
                    <IconButton
                      icon={ArrowRightSolid}
                      size='small'
                      variant='outlined'
                      sentiment='neutral'
                      {...getListeners('right')}
                    />
                  </div>
                </ArrowsHintContainer>
              </StagesTitleContainer>

              <StageColumnsDnd fieldArray={fieldArray}>
                <StageColumns
                  scrollColumnsRef={scrollContainerRef}
                  fieldArray={fieldArray}
                />
              </StageColumnsDnd>
            </StagesContent>

            <FlowsTemplateFooter
              isSubmitDisabled={isSubmitDisabled}
              isSubmitting={isSubmitting}
              close={close}
            />
          </>
        )}
      </GridShell>
    </FormShell>
  )
})
