import { TextField } from '@motion/ui/forms'

import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { ModalFieldButton } from '~/areas/task-project/components'
import { getTextFieldRules } from '~/global/rules'
import { useController } from 'react-hook-form'

export const ControlledNameField = () => {
  const { form } = useFlowTemplateForm()
  const { control } = form

  const { field } = useController({
    name: 'name',
    control,
    rules: {
      ...getTextFieldRules('Template name'),
      validate: (value) => {
        if (value.trim().toLowerCase() === 'no template') {
          return 'Template name cannot be "No Template"'
        }
      },
    },
  })

  return (
    <ModalFieldButton label='Template name'>
      <TextField
        ref={field.ref}
        placeholder='Untitled'
        label='Template name'
        labelHidden
        size='small'
        value={field.value}
        onChange={(value) => field.onChange(value)}
        variant='minimal'
      />
    </ModalFieldButton>
  )
}
