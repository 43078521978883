import {
  convertDataFieldsToCreateSchema,
  type EventFormFields,
} from '@motion/ui-logic'

import { useCreateEvent } from '~/areas/event/hooks'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useIsMainCalendar } from './use-is-main-calendar'

import { useEventModalState } from '../contexts'

export function useFormCreateEvent() {
  const modalState = useEventModalState()
  const createEvent = useCreateEvent()
  const isMainCalendar = useIsMainCalendar()

  return useCallback(
    async (fields: EventFormFields) => {
      try {
        return createEvent(
          convertDataFieldsToCreateSchema(fields, {
            ...modalState,
            isMainCalendar,
          })
        )
      } catch (e) {
        showErrorToast(e)
      }
    },
    [createEvent, isMainCalendar, modalState]
  )
}
