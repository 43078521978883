import { ArrowRightSolid } from '@motion/icons'
import { type MeetingInsightsSchema } from '@motion/rpc-types'
import { classed } from '@motion/theme'
import { Button, ButtonGroup } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import { NoteLocationDropdown, NoteShareButton } from '~/areas/notes/components'
import { CellText } from '~/areas/project-management/pages/pm-v3/tree-list/cells/components'
import { useUriByRouteId } from '~/routing'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

type NameCellProps = {
  counter: number
  meetingInsights: MeetingInsightsSchema
}

export function NameCell({ counter, meetingInsights }: NameCellProps) {
  const buildUrl = useUriByRouteId()

  const meetingInsightsUrl =
    meetingInsights.noteId != null
      ? buildUrl('notes-detail', {
          noteId: meetingInsights.noteId,
        })
      : undefined

  // This shouldn't happen because the query only returns meeting insights with notes
  if (meetingInsightsUrl == null) {
    Sentry.captureException(
      "Meeting insights without notes shouldn't be rendered",
      {
        extra: {
          meetingInsights,
        },
      }
    )
    return null
  }

  if (!meetingInsights.eventData) {
    Sentry.captureException(
      "Meeting insights without event data shouldn't be rendered",
      {
        extra: {
          meetingInsights,
        },
      }
    )
    return null
  }

  return (
    <CellText withHover className='group/name-cell'>
      <LineCounter className='ml-[var(--indent)]'>{counter}</LineCounter>

      <Link
        to={meetingInsightsUrl}
        className={twMerge(
          'overflow-hidden flex-1 flex self-stretch items-center'
        )}
        onClick={() => {
          recordAnalyticsEvent('NOTETAKER_DOC_EVENT_MODAL_CLICK', {
            type: 'list-view',
          })
        }}
      >
        <span className='truncate'>{meetingInsights.eventData.title}</span>
      </Link>

      <Actions meetingInsights={meetingInsights} />
    </CellText>
  )
}

const LineCounter = classed(
  'span',
  'w-[26px] text-center text-[10px] text-semantic-neutral-text-disabled shrink-0'
)

type ActionsProps = {
  meetingInsights: MeetingInsightsSchema
}

function Actions({ meetingInsights }: ActionsProps) {
  const [popoverOpened, setPopoverOpen] = useState(false)

  if (
    meetingInsights.meetingBotStatus === 'DONT_SCHEDULE' ||
    meetingInsights.noteId == null
  ) {
    return null
  }

  return (
    <div
      className={twMerge(
        'hidden group-hover/name-cell:block',
        popoverOpened && 'block'
      )}
    >
      <ButtonGroup size='small' nowrap>
        <NoteShareButton noteId={meetingInsights.noteId} variant='outlined' />

        <NoteLocationDropdown
          noteId={meetingInsights.noteId}
          onClose={() => setPopoverOpen(false)}
        >
          <Button
            onClick={() => setPopoverOpen(true)}
            size='small'
            variant='outlined'
            sentiment='neutral'
          >
            <ArrowRightSolid />
            Move
          </Button>
        </NoteLocationDropdown>
      </ButtonGroup>
    </div>
  )
}
