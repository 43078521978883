import { templateStr } from '@motion/react-core/strings'
import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import {
  type V2CreateLabelsSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { useCreateLabel as useCreateLabelCall } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useWorkspaceFns } from '../use-workspace-fns'

export const useCreateLabel = () => {
  const {
    mutateAsync: createWorkspaceLabel,
    isPending: isLoading,
    isError,
  } = useCreateLabelCall()
  const routeCtx = useRouteAnalyticsMetadata()
  const { getWorkspaceLabels } = useWorkspaceFns()

  return {
    createLabel: useCallback(
      async (
        data: V2CreateLabelsSchema & { workspaceId: WorkspaceSchema['id'] }
      ) => {
        const trimmedName = data.name.trim()
        if (trimmedName.length < 1) {
          showToast('error', 'Label name cannot be empty')
          return
        }

        const workspaceLabels = getWorkspaceLabels(data.workspaceId)
        const alreadyExists = workspaceLabels.some(
          (l) => l.name === trimmedName
        )
        if (alreadyExists) {
          showToast(
            'error',
            templateStr('A label already exists with the name "{{name}}"', {
              name: trimmedName,
            })
          )
          return
        }

        try {
          recordAnalyticsEvent(
            'PROJECT_MANAGEMENT_CREATE_WORKSPACE_LABEL',
            routeCtx
          )

          const response = await createWorkspaceLabel({
            ...data,
            name: trimmedName,
          })

          showToast('success', 'Label created')

          return response.models[response.meta.model][response.id]
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              position: 'create-label',
            },
          })

          showErrorToast(e)
        }
      },
      [createWorkspaceLabel, routeCtx, getWorkspaceLabels]
    ),
    isLoading,
    isError,
  }
}
