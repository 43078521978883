import {
  CommentOutline,
  RightSidebarClosedSolid,
  RightSidebarOpenSolid,
} from '@motion/icons'
import { IconButton, Tooltip } from '@motion/ui/base'

import { useNoteSidebarState } from '../hooks'

export type SidebarMetaData = {
  view: 'comments' | 'details'
}

export function NoteSidebarToggleButtons() {
  const [noteSidebarState, _, toggleNoteSidebarState] = useNoteSidebarState()

  const selectedView = noteSidebarState.meta?.view as SidebarMetaData['view']

  const commentsActive = selectedView === 'comments' && noteSidebarState.open
  const detailsActive = selectedView === 'details' && noteSidebarState.open

  return (
    <>
      <Tooltip content={commentsActive ? 'Hide comments' : 'Show comments'}>
        <IconButton
          variant={commentsActive ? 'outlined' : 'muted'}
          sentiment='neutral'
          size='small'
          icon={CommentOutline}
          onClick={() => toggleNoteSidebarState('comments')}
        />
      </Tooltip>

      <Tooltip content={detailsActive ? 'Hide details' : 'Show details'}>
        <IconButton
          variant={detailsActive ? 'outlined' : 'muted'}
          sentiment='neutral'
          size='small'
          icon={detailsActive ? RightSidebarOpenSolid : RightSidebarClosedSolid}
          onClick={() => toggleNoteSidebarState('details')}
        />
      </Tooltip>
    </>
  )
}
