import {
  DotsVerticalSolid,
  LoadingSolid,
  PencilSolid,
  PlusSolid,
} from '@motion/icons'
import { type ProviderType } from '@motion/shared/common'
import { ActionDropdown, Button, IconButton, Tag, Text } from '@motion/ui/base'
import { CalendarTitle } from '@motion/ui/calendar'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type CalendarSchema } from '@motion/zod/client'

import {
  useFrequentlyMetCalendars,
  useInNoExternalCalendarsMode,
  useMainCalendarId,
  useMyCalendars,
} from '~/areas/calendar/hooks'
import { SettingPageWrapper } from '~/pages/settings/shared'
import { useCallback, useEffect } from 'react'

import { EmailAccountRow } from './email-account-row'

import { setCalendarListPickerModal } from '../../../../state/calendar-list/calendar-list-slice'
import { updateCalendar } from '../../../../state/calendar-list/calendar-list-thunks'
import { type CalendarListPickerSectionType } from '../../../../state/calendar-list/calendar-list-types'
import {
  selectCalendarsLoadingState,
  selectEmailAccounts,
  selectEmailAccountsMap,
} from '../../../../state/email-accounts/email-accounts-slice'
import { fetchAllEmailAccounts } from '../../../../state/email-accounts/email-accounts-thunks'
import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import { addAccount } from '../../../../utils/auth'
import { AddAccountDropdown } from '../../../Account/add-account-dropdown'
import { LinkCalendarsCTA } from '../../Components/link-calendars-cta'
import { SettingList } from '../../Components/SettingList/SettingList'

export const CalendarSettings = () => {
  const dispatch = useAppDispatch()
  const myCalendars = useMyCalendars()
  const emailAccounts = useAppSelector(selectEmailAccounts)
  const emailAccountsMap = useAppSelector(selectEmailAccountsMap)
  const { mainCalendarId } = useMainCalendarId()
  const frequentlyMetCalendars = useFrequentlyMetCalendars()
  const { noExternalCalendarsMode } = useInNoExternalCalendarsMode()
  const emailAccountsLoadingState = useAppSelector(selectCalendarsLoadingState)

  const onAddAccount = (provider: ProviderType) => {
    addAccount(provider)
    recordAnalyticsEvent('CALENDAR_ADD_ACCOUNT_CLICK')
  }

  const openCalListPickerModal = useCallback(
    (section: CalendarListPickerSectionType) => {
      dispatch(setCalendarListPickerModal({ section, visible: true }))
      recordAnalyticsEvent('CALENDAR_GROUP_MODAL_OPEN', { section })
    },
    [dispatch]
  )

  const removeCalendarFromMyCalendars = (calendar: CalendarSchema) => {
    void dispatch(
      updateCalendar({
        id: calendar.id,
        emailAccountId: calendar.emailAccountId,
        isInMyCalendars: false,
      })
    )
  }

  const removeFrequentlyMetCalendar = (calendar: CalendarSchema) => {
    void dispatch(
      updateCalendar({
        id: calendar.id,
        emailAccountId: calendar.emailAccountId,
        isInFrequentlyMet: false,
      })
    )
  }

  useEffect(
    function loadInitialData() {
      void dispatch(fetchAllEmailAccounts())
    },
    [dispatch]
  )

  if (noExternalCalendarsMode) {
    const isLoading =
      emailAccountsLoadingState === 'preload' ||
      emailAccountsLoadingState === 'loading'
    return (
      <SettingPageWrapper title='Calendars' className='gap-12'>
        <div className='flex w-full h-full flex-col gap-3 items-center'>
          {isLoading && (
            <div className='flex w-full h-full flex-col gap-3 items-center'>
              <LoadingSolid className='animate-spin text-semantic-neutral-icon-default' />
            </div>
          )}
          {!isLoading && <LinkCalendarsCTA />}
        </div>
      </SettingPageWrapper>
    )
  }

  return (
    <SettingPageWrapper title='Calendars' className='gap-12'>
      <div className='flex w-full flex-col gap-3'>
        <div className='flex w-full items-center justify-between'>
          <Text weight='semibold'>Accounts</Text>
        </div>
        <SettingList
          items={emailAccounts.map((emailAccount) => (
            <EmailAccountRow
              key={emailAccount.id}
              emailAccount={emailAccount}
            />
          ))}
        />

        <AddAccountDropdown onAddAccount={onAddAccount}>
          <div>
            <Button variant='muted' sentiment='neutral' size='small'>
              <PlusSolid />
              Add account
            </Button>
          </div>
        </AddAccountDropdown>
      </div>
      <div className='flex w-full flex-col gap-6'>
        <Text weight='semibold'>Calendar Grouping</Text>
        <div className='flex w-full flex-col gap-2.5'>
          <div className='flex items-center gap-3'>
            <Text size='sm' weight='semibold'>
              My Calendars
            </Text>
            <Button
              sentiment='neutral'
              variant='outlined'
              onClick={() => openCalListPickerModal('mine')}
              size='small'
            >
              <PencilSolid />
              Edit
            </Button>
          </div>
          <SettingList
            items={myCalendars.map((calendar) => (
              <div
                key={calendar.id}
                className='flex items-center justify-between p-4'
              >
                <CalendarTitle
                  calendar={calendar}
                  emailAccountsMap={emailAccountsMap}
                  isMyCalendarList
                />
                {mainCalendarId === calendar.id && (
                  <Tag variant='subtle'>Main Calendar</Tag>
                )}
                {mainCalendarId !== calendar.id && (
                  <ActionDropdown
                    placement='bottom-end'
                    items={[
                      {
                        content: "Remove from 'My Calendars'",
                        onAction: () => removeCalendarFromMyCalendars(calendar),
                      },
                    ]}
                  >
                    <IconButton
                      sentiment='neutral'
                      variant='muted'
                      size='small'
                      icon={DotsVerticalSolid}
                    />
                  </ActionDropdown>
                )}
              </div>
            ))}
          />
        </div>
        <div className='flex w-full flex-col gap-2.5'>
          <div className='flex items-center gap-3'>
            <Text size='sm' weight='semibold'>
              Frequently met with
            </Text>
            <Button
              sentiment='neutral'
              variant='outlined'
              onClick={() => openCalListPickerModal('frequent')}
              size='small'
            >
              <PencilSolid />
              Edit
            </Button>
          </div>
          <SettingList
            items={frequentlyMetCalendars.map((calendar) => (
              <div
                key={calendar.id}
                className='flex items-center justify-between p-4'
              >
                <Text truncate size='sm'>
                  <CalendarTitle
                    calendar={calendar}
                    emailAccountsMap={emailAccountsMap}
                  />
                </Text>
                <ActionDropdown
                  placement='bottom-end'
                  items={[
                    {
                      content: "Remove from 'Frequently met with'",
                      onAction: () => removeFrequentlyMetCalendar(calendar),
                    },
                  ]}
                >
                  <IconButton
                    sentiment='neutral'
                    variant='muted'
                    size='small'
                    icon={DotsVerticalSolid}
                  />
                </ActionDropdown>
              </div>
            ))}
          />
        </div>
      </div>
    </SettingPageWrapper>
  )
}
