import { type SvgIcon } from '@motion/icons'
import { Text } from '@motion/ui/base'
import { type AgentVariableSchema } from '@motion/zod/client'

import { agentVariableTypeToData } from './shared'

export type AgentVariableTagProps = {
  agentVariable: AgentVariableSchema
}

export function AgentVariableTag({ agentVariable }: AgentVariableTagProps) {
  return (
    <AgentVariableLabel
      name={agentVariable.label}
      icon={agentVariableTypeToData[agentVariable.type].icon}
    />
  )
}

type AgentVariableLabelProps = {
  name: string
  icon?: SvgIcon
}

export function AgentVariableLabel({
  name,
  icon: Icon,
}: AgentVariableLabelProps) {
  return (
    <div className='inline-flex flex-row items-center gap-1 py-px px-1 rounded bg-semantic-blue-bg-default'>
      {Icon != null && (
        <Icon className='text-semantic-blue-icon-default size-3 shrink-0' />
      )}
      <Text size='sm' className='text-semantic-blue-text-default' truncate>
        @{name}
      </Text>
    </div>
  )
}
