import { classed } from '@motion/theme'

export const FlowSettingsTable = classed('div', {
  base: 'flex flex-col items-start gap-4 self-stretch',
})

export const SettingsTitle = classed('div', {
  base: 'flex justify-between items-center self-stretch',
})

export const SettingsTitleText = classed('span', {
  base: 'text-semantic-neutral-text-default text-center text-xl font-bold flex gap-1 items-center',
})

export const SettingsTitleDescription = classed('span', {
  base: `text-semantic-neutral-text-subtle text-sm font-normal`,
})

export const SettingsSectionDivider = classed('div', {
  base: 'w-full h-px bg-semantic-neutral-border-default',
})

export const FlowOrStageCardSection = classed('div', {
  base: 'grid grid-cols-3 rounded gap-3 self-stretch pt-3',
})
