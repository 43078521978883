import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
} from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { type CreateNoteRequestSchema } from '@motion/rpc-types'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useQueryClient } from '@tanstack/react-query'
import { useCreateNoteMutation } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useUriByRouteId } from '~/routing'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'

export const useCreateNote = () => {
  const navigate = useNavigate()
  const getRouteUri = useUriByRouteId({ noDefaults: true })
  const { mutateAsync: createNote } = useCreateNoteMutation()

  const queryClient = useQueryClient()

  return useCallback(
    async (
      params: Omit<CreateNoteRequestSchema, 'content'>,
      options: { navigate: boolean } = { navigate: false }
    ) => {
      try {
        const note = await createNote(params)

        recordAnalyticsEvent('PROJECT_MANAGEMENT_NOTE_CREATE')

        MotionCache.upsert(
          queryClient,
          createQueryFilter([MODEL_CACHE_KEY, API.notes.queryKeys.root]),
          note
        )

        if (options.navigate) {
          navigate(getRouteUri('notes-detail', { noteId: note.id }))
        }
        return note
      } catch (err) {
        showErrorToast('Could not create doc')
      }
    },
    [createNote, getRouteUri, navigate, queryClient]
  )
}
