import {
  type ApiTypes,
  type ApiUseMutationOptions,
  createUseMutation,
  useQueryOptionsFactory,
} from '@motion/rpc'
import {
  createQueryFilter,
  getCacheEntryValue,
  MODEL_CACHE_KEY,
  MotionCache,
  type OptimisticUpdateValue,
} from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { type StageDefinitionSchema } from '@motion/zod/client'

import { useQueryClient } from '@tanstack/react-query'
import { applyOptimisticStageDefinitionUpdates } from '~/global/cache'

const CACHE_KEYS_TO_UPDATE = [API.workspacesV2.queryKeys.root, MODEL_CACHE_KEY]
const getCacheKeysWithStageId = (stageId: string) => [
  ...CACHE_KEYS_TO_UPDATE,
  API.stageDefinitions.queryKeys.query({ id: stageId }),
]

type LazyByIdApi = ApiTypes<typeof API.stageDefinitions.getLazyById>
export const useReadStageDefinitionFn = () => {
  const client = useQueryClient()
  const queryOptionsOf = useQueryOptionsFactory(
    API.stageDefinitions.getLazyById
  )

  return async (
    { id, workspaceId }: LazyByIdApi['args'],
    opts?: LazyByIdApi['UseQueryOptions']
  ): Promise<StageDefinitionSchema | undefined> => {
    const cached = getCacheEntryValue(client, 'stageDefinitions', id)
    if (cached) return cached

    const queryArgs = queryOptionsOf({ id, workspaceId }, opts)

    const response = await client.fetchQuery(queryArgs)
    return response.models.stageDefinitions[response.id]
  }
}

export const useCreateStageDefinition = () => {
  const queryClient = useQueryClient()
  return createUseMutation(API.stageDefinitions.create)({
    onSuccess: (data) => {
      MotionCache.upsert(
        queryClient,
        createQueryFilter(CACHE_KEYS_TO_UPDATE),
        data
      )
    },
  })
}
const useUpdateStageDefinitionMutation = createUseMutation(
  API.stageDefinitions.update
)
export const useUpdateStageDefinition = (
  opts?: ApiUseMutationOptions<typeof API.stageDefinitions.update>
) => {
  const queryClient = useQueryClient()

  return useUpdateStageDefinitionMutation({
    onMutate: async (updatedStageDefinition) => {
      const cacheUpdates = applyOptimisticStageDefinitionUpdates(
        queryClient,
        updatedStageDefinition.id,
        {
          ...updatedStageDefinition.definition,
          id: updatedStageDefinition.id,
        }
      )

      return { cacheUpdates }
    },
    onSuccess: (data) => {
      MotionCache.upsert(
        queryClient,
        createQueryFilter(CACHE_KEYS_TO_UPDATE),
        data
      )
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }

      cacheUpdates?.rollback()
    },

    ...opts,
  })
}
const useBulkUpdateStageDefinitionsMutation = createUseMutation(
  API.stageDefinitions.bulkUpdate
)
export const useBulkUpdateStageDefinitions = (
  opts?: ApiUseMutationOptions<typeof API.stageDefinitions.bulkUpdate>
) => {
  const queryClient = useQueryClient()
  return useBulkUpdateStageDefinitionsMutation({
    ...opts,
    onMutate: (data) => {
      const { definitions: updatedStageDefinitions } = data
      const cacheUpdates = updatedStageDefinitions.map(
        (updatedStageDefinition) => {
          return applyOptimisticStageDefinitionUpdates(
            queryClient,
            updatedStageDefinition.id,
            updatedStageDefinition
          )
        }
      )

      return { cacheUpdates }
    },
    onSuccess: (data) => {
      MotionCache.upsert(
        queryClient,
        createQueryFilter(CACHE_KEYS_TO_UPDATE),
        data
      )
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue[]
      }

      cacheUpdates.forEach((update) => update.rollback())
    },
  })
}

const useDeleteStageDefinitionMutation = createUseMutation(
  API.stageDefinitions.deleteStageDefinition
)
export const useDeleteStageDefinition = () => {
  const queryClient = useQueryClient()

  return useDeleteStageDefinitionMutation({
    onSuccess: (_, { id }) => {
      MotionCache.delete(
        queryClient,
        createQueryFilter(CACHE_KEYS_TO_UPDATE),
        'stageDefinitions',
        id
      )
    },
  })
}

const useCopyStageDefinitionMutation = createUseMutation(
  API.stageDefinitions.copyStageDefinition
)
export const useCopyStageDefinition = () => {
  const queryClient = useQueryClient()

  return useCopyStageDefinitionMutation({
    onSuccess: (data) => {
      MotionCache.upsert(
        queryClient,
        createQueryFilter(getCacheKeysWithStageId(data.id)),
        data
      )
    },
  })
}
