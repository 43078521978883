import { MentionNode, parseLink } from '@motion/notes'
import { getMentionUrl } from '@motion/ui-logic'

import { WebMentionTag } from './components/web-mention-tag'

MentionNode.prototype.render = (nodeKey, entityId, entityType, entityLabel) => (
  <WebMentionTag
    nodeKey={nodeKey}
    entityId={entityId}
    entityLabel={entityLabel}
    entityType={entityType}
  />
)

MentionNode.prototype.getMentionUrl = getMentionUrl

MentionNode.parseMentionUrl = parseLink

export { MentionNode as WebMentionsNode }
