import { useCallback } from 'react'

import { useViewState, type ViewStateColumn } from '../../view-state'

export function useViewStateColumn() {
  const [viewState, setViewState] = useViewState()

  const update = useCallback(
    (state: ViewStateColumn[]) => {
      setViewState((prev) => ({
        ...prev,
        columns: state,
      }))
    },
    [setViewState]
  )

  return [viewState.columns, update] as const
}
