import { ChartSquareBarSolid } from '@motion/icons'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasFeaturePermission } from '@motion/web-billing'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { TieredPricingUpsellButton } from '~/areas/tiered-pricing'
import { StyledNewText } from '~/global/components'

import { useEffectiveView } from '../views'

export function ConnectedCreateDashboardButton() {
  const hasChartsPreview = useHasTreatment('charts-preview')
  const canUseDashboards = useHasFeaturePermission('dashboards')
  const info = useEffectiveView()
  const modalApi = useModalApi()

  if (!hasChartsPreview) return null

  const handleClick = () => {
    recordAnalyticsEvent('VIEW_ADD_NEW', { section: 'header' })

    void modalApi.prompt('create-view-v2', {
      currentView: info.view,
      type: 'dashboard',
    })
  }

  return (
    <TieredPricingUpsellButton
      size='small'
      sentiment='neutral'
      onClick={handleClick}
      featureLocked={!canUseDashboards}
      tier='PRO'
      feature='dashboards'
    >
      <ChartSquareBarSolid />
      Create Dashboard
      <StyledNewText>NEW</StyledNewText>
    </TieredPricingUpsellButton>
  )
}
