import { type NotetakerViewState } from '~/areas/notetaker'

export const getDefaultMyCallsView = (): NotetakerViewState => {
  return {
    $version: 1,
    filters: {
      attendees: {
        operator: 'in',
        value: ['@me'],
      },
      meetingBotStatus: {
        operator: 'in',
        value: ['COMPLETED'],
      },
    },
    groupBy: {
      fields: [
        {
          key: 'startDate',
          by: 'day',
        },
      ],
      order: {
        type: 'direction',
        by: 'desc',
      },
      hideEmpty: true,
    },
    sortBy: {
      field: 'startTime',
      direction: 'desc',
    },
    type: 'my-calls',
    search: '',
    columns: [],
  }
}

export const getDefaultSharedWithMeView = (): NotetakerViewState => {
  return {
    $version: 1,
    filters: {
      attendees: {
        operator: 'in',
        value: ['@me'],
        inverse: true,
      },
      meetingBotStatus: {
        operator: 'in',
        value: ['COMPLETED'],
      },
    },
    groupBy: {
      fields: [
        {
          key: 'startDate',
          by: 'day',
        },
      ],
      order: {
        type: 'direction',
        by: 'desc',
      },
      hideEmpty: true,
    },
    sortBy: {
      field: 'startTime',
      direction: 'desc',
    },
    type: 'shared-with-me',
    search: '',
    columns: [],
  }
}

export const getDefaultAllCallsView = (): NotetakerViewState => {
  return {
    $version: 1,
    filters: {
      meetingBotStatus: {
        operator: 'in',
        value: ['COMPLETED'],
      },
    },
    groupBy: {
      fields: [
        {
          key: 'startDate',
          by: 'day',
        },
      ],
      order: {
        type: 'direction',
        by: 'desc',
      },
      hideEmpty: true,
    },
    sortBy: {
      field: 'startTime',
      direction: 'desc',
    },
    type: 'all-calls',
    search: '',
    columns: [],
  }
}

export const getDefaultFutureView = (): NotetakerViewState => {
  return {
    $version: 1,
    filters: {
      meetingBotStatus: {
        operator: 'in',
        value: ['SCHEDULED'],
      },
      startTime: {
        operator: 'defined-relative',
        name: 'next-14-days',
      },
    },
    groupBy: {
      fields: [
        {
          key: 'startDate',
          by: 'day',
        },
      ],
      order: {
        type: 'direction',
        by: 'asc',
      },
      hideEmpty: true,
    },
    sortBy: {
      field: 'startTime',
      direction: 'asc',
    },
    type: 'future',
    search: '',
    columns: [],
  }
}
