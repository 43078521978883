import { type Term } from '@motion/ui-logic/billing'
import { type Tier } from '@motion/ui-logic/tiered-pricing'
import { FeatureTierCards } from '@motion/web-billing'

import { useCurrentTier } from '~/areas/tiered-pricing/hooks'

interface TieredCreateTeamProps {
  bucket: number
  term: Term
  onTierSelect: (tier: Tier) => Promise<void>
}

export const TieredCreateTeam = ({
  bucket,
  term,
  onTierSelect,
}: TieredCreateTeamProps) => {
  const tier = useCurrentTier()

  const onSelectFeatureTier = (tier: Tier, term: Term, bucket: number) => {
    onTierSelect(tier)
  }

  return (
    <div className='relative flex items-center justify-center'>
      <div className='flex w-full flex-col justify-center'>
        <FeatureTierCards
          mode='teamUpgrade'
          bucket={bucket}
          term={term}
          currentTier={tier}
          onSelectFeatureTier={onSelectFeatureTier}
        />
      </div>
    </div>
  )
}
