import { DotsHorizontalSolid } from '@motion/icons'
import { IconButton, PopoverTrigger } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type ProjectSchema } from '@motion/zod/client'

import {
  ProjectActionList,
  type ProjectActionListProps,
} from '~/areas/project/components/project-action-list'
import { useProject } from '~/global/hooks'

type ProjectActionsMenuProps = {
  projectId: ProjectSchema['id']
  moveActionAllowed?: {
    up: boolean
    down: boolean
  }
  onMoveItem: (moveBy: -1 | 1) => void
}

export const ProjectActionsMenu = ({
  projectId,
  moveActionAllowed,
  onMoveItem,
}: ProjectActionsMenuProps) => {
  return (
    <PopoverTrigger
      renderPopover={({ close }) => (
        <ConnectedProjectActionList
          projectId={projectId}
          close={close}
          options={{
            showColors: true,
            allowProjectResolution: true,
            showMoves: !!moveActionAllowed,
            disabledMoveActions: moveActionAllowed
              ? {
                  up: !moveActionAllowed.up,
                  down: !moveActionAllowed.down,
                }
              : undefined,
            onMoveItem,
          }}
        />
      )}
    >
      <IconButton
        variant='muted'
        sentiment='neutral'
        size='xsmall'
        icon={DotsHorizontalSolid}
        onClick={() => {
          recordAnalyticsEvent('FOLDERS_CLICKED_SIDEBAR_BUTTON', {
            itemType: 'PROJECT',
            button: 'ACTIONS',
          })
        }}
      />
    </PopoverTrigger>
  )
}

export function ConnectedProjectActionList({
  projectId,
  ...rest
}: Omit<ProjectActionListProps, 'project'> & {
  projectId: ProjectSchema['id']
}) {
  const project = useProject(projectId)

  if (!project) return null

  return <ProjectActionList project={project} {...rest} />
}
