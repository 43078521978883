import { PencilSolid, PuzzleSolid, TrashSolid } from '@motion/icons'
import { type TemplateProjectType } from '@motion/rpc/types'
import { ButtonGroup, IconButton } from '@motion/ui/base'
import { ProjectPalette } from '@motion/ui/project'
import { stripHtml } from '@motion/ui-logic'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import {
  CardTitle,
  FlowCardBottomGradient,
  FlowCardHeader,
  FlowDescription,
  FlowDescriptionContainer,
  FlowTemplateContainer,
} from '~/areas/flows/components/styled'
import { useDeleteProjectTemplate } from '~/global/rpc'

export type LegacyTemplateCardProps = {
  legacyTemplate: TemplateProjectType
}
export function LegacyTemplateCard({
  legacyTemplate,
}: LegacyTemplateCardProps) {
  const modalApi = useModalApi()
  const { mutateAsync: deleteProjectTemplate } = useDeleteProjectTemplate()

  const askConfirmation = (item: TemplateProjectType) => {
    return modalApi.prompt('confirm', {
      analytics: {
        name: 'delete-project-template',
      },
      closeButtonText: 'Cancel',
      confirmButtonText: 'Delete template',
      destructive: true,
      title: `Are you sure you want to delete this template ${item.name}?`,
    })
  }

  const handleDeleteProjectTemplate = async (item: TemplateProjectType) => {
    const confirmed = await askConfirmation(item)
    if (confirmed !== ModalDismissed) {
      await deleteProjectTemplate({
        id: item.id,
        workspaceId: item.workspaceId,
      })
    }
  }

  return (
    <FlowTemplateContainer>
      <FlowDescriptionContainer>
        <FlowCardHeader>
          <ProjectPalette color='gray'>
            <div className='flex p-1 items-center gap-2 rounded bg-palette-bg-light hover:bg-palette-bg-hover focus:bg-palette-bg-hover size-5'>
              <PuzzleSolid className='size-3 text-palette-highlight-default' />
            </div>
          </ProjectPalette>
          <ButtonGroup>
            <IconButton
              icon={PencilSolid}
              sentiment='neutral'
              size='xsmall'
              url={`project/${legacyTemplate.id}`}
              variant='muted'
              aria-label='Edit project template'
            />
            <IconButton
              icon={TrashSolid}
              sentiment='neutral'
              size='xsmall'
              variant='muted'
              onClick={() => handleDeleteProjectTemplate(legacyTemplate)}
              aria-label='Delete project template'
            />
          </ButtonGroup>
        </FlowCardHeader>
        <CardTitle>{legacyTemplate.name}</CardTitle>
        <FlowDescription className='truncate'>
          {legacyTemplate.project.description
            ? stripHtml(
                legacyTemplate.project.description.replace(/<p>/g, '\n')
              )
            : 'No Description provided'}
        </FlowDescription>
      </FlowDescriptionContainer>
      <FlowCardBottomGradient />
    </FlowTemplateContainer>
  )
}
