import { ActionDropdown, Button } from '@motion/ui/base'
import { NOOP_FUNCTION } from '@motion/utils/function'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { Events } from '~/analyticsEvents'
import { getTzAbbr } from '~/utils/time'

type TimezoneButtonProps = {
  timezone: string
  size: 'xsmall' | 'small'
} & (
  | {
      onAdd?: () => void
      onRemove?: () => void
      onEdit: () => void
      clickable?: true
    }
  | {
      onAdd?: () => void
      onRemove?: () => void
      onEdit?: () => void
      clickable?: false
    }
)

export function TimezoneButton({
  clickable = false,
  timezone,
  size,
  onAdd,
  onRemove = NOOP_FUNCTION,
  onEdit = NOOP_FUNCTION,
}: TimezoneButtonProps) {
  const button = (
    <Button variant='muted' size={size} sentiment='neutral'>
      {getTzAbbr(timezone)}
    </Button>
  )

  if (!clickable) {
    return <span className='pointer-events-none inline-flex'>{button}</span>
  }

  return (
    <ActionDropdown
      items={[
        {
          content: 'Edit',
          onAction: () => {
            onEdit()
            recordAnalyticsEvent(Events.CALENDAR_TIMEZONE_EDIT)
          },
        },
        !!onAdd && {
          content: 'Add another timezone',
          onAction: () => {
            onAdd()
            recordAnalyticsEvent(Events.CALENDAR_TIMEZONE_ADD)
          },
        },
        {
          content: 'Remove',
          onAction: () => {
            onRemove()
            recordAnalyticsEvent(Events.CALENDAR_TIMEZONE_REMOVE)
          },
          destructive: true,
        },
      ]}
    >
      {button}
    </ActionDropdown>
  )
}
