import { makeLog } from '@motion/web-base/logging'
import { stats } from '@motion/web-common/performance'

import { useNeedsUpdate } from '~/areas/update/use-needs-update'
import { useIdleTimer } from 'react-idle-timer'
import { usePageVisibility } from 'react-page-visibility'

const log = makeLog('upgrade-check')

const IDLE_TIMEOUT = 15 * 60 * 1000

export const UpdateOnIdle = () => {
  const needsUpdate = useNeedsUpdate()
  const isPageVisible = usePageVisibility()

  useIdleTimer({
    timeout: IDLE_TIMEOUT,

    async onIdle() {
      log('idle', { needsUpdate })
      if (needsUpdate.upgradeAvailable === false) return
      if (isPageVisible) {
        stats.increment('ota_reload.page_visible')
        return
      }

      log(`upgrading`)
      stats.increment('ota_reload')
      await stats.flush()

      window.location.reload()
    },
  })

  return null
}
