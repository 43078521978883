import { getCurrentUser } from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { client } from '@motion/web-common/rpc'

import {
  TemplateProjectModal,
  TemplateTaskModal,
} from '~/areas/project-management/modals'
import { redirectLoader } from '~/areas/project-management/pages/pm-v3/routes/utils/redirect'
import {
  WorkspaceLabelSettings,
  WorkspaceOverviewSettings,
  WorkspaceStatusSettings,
  WorkspaceTaskTemplateSettings,
} from '~/areas/project-management/settings'
import { WorkspaceCustomFieldSettings } from '~/areas/project-management/settings/custom-fields'
import { Redirect } from '~/components/Settings/Components/routing'
import { type MotionRoute } from '~/routing/api'

import { AIWorkflowsSettingsPage, ProjectTemplatesSettingsPage } from './pages'
import { WorkspaceSettingsPage } from './workspace-page'

declare module '~/routing/hooks/navigate-by-id-params' {
  type WorkspaceSettingsParams = {
    workspaceId: string
  }
  interface NavigateByIdParams {
    'workspace-settings': WorkspaceSettingsParams
    'workspace-template-settings': WorkspaceSettingsParams
    'workspace-settings-ai-workflows': WorkspaceSettingsParams
    'workspace-settings-labels': WorkspaceSettingsParams
    'workspace-settings-task-templates': WorkspaceSettingsParams
    'workspace-settings-statuses': WorkspaceSettingsParams
    'workspace-settings-custom-fields': WorkspaceSettingsParams
  }
}

export const workspaceRoutes: MotionRoute[] = [
  {
    id: 'workspace-settings',
    path: 'workspace/:workspaceId',
    element: <WorkspaceSettingsPage />,
    children: [
      {
        path: '',
        index: true,
        element: <WorkspaceOverviewSettings />,
        metric: 'PROJECT_MANAGEMENT_OVERVIEW_SETTINGS_PAGE_VIEWED',
      },
      {
        id: 'workspace-settings-statuses',
        path: 'statuses',
        element: <WorkspaceStatusSettings />,
        metric: 'PROJECT_MANAGEMENT_STATUS_SETTINGS_PAGE_VIEWED',
      },
      {
        id: 'workspace-settings-custom-fields',
        path: 'custom-fields',
        element: <WorkspaceCustomFieldSettings />,
        metric: 'PROJECT_MANAGEMENT_CUSTOM_FIELD_SETTINGS_PAGE_VIEWED',
      },
      {
        id: 'workspace-template-settings',
        path: 'project-templates',
        element: <ProjectTemplatesSettingsPage />,
        children: [
          {
            path: 'project/:templateProjectId',
            element: <TemplateProjectModal />,
          },
          {
            path: 'project/:templateProjectId/tasks/:templateTaskId',
            element: <TemplateTaskModal />,
          },
        ],
        metric: 'PROJECT_MANAGEMENT_FLOW_PROJECT_TEMPLATE_SETTINGS_PAGE_VIEWED',
      },
      {
        id: 'workspace-settings-task-templates',
        path: 'task-templates',
        element: <WorkspaceTaskTemplateSettings />,
        children: [
          {
            path: 'task/:templateTaskId',
            element: <TemplateTaskModal />,
          },
        ],
        metric: 'PROJECT_MANAGEMENT_TASK_TEMPLATE_SETTINGS_PAGE_VIEWED',
      },
      {
        metric: 'PROJECT_MANAGEMENT_LABEL_SETTINGS_PAGE_VIEWED',
        id: 'workspace-settings-labels',
        path: 'labels',
        element: <WorkspaceLabelSettings />,
      },
      {
        id: 'workspace-settings-ai-workflows',
        path: 'ai-workflows',
        element: <AIWorkflowsSettingsPage />,
        loader: async (args) => {
          const hasTieredPricing =
            args.featureFlags['tiered-pricing-v1']?.value === 'treatment'
          if (!hasTieredPricing) {
            return redirectLoader('..')(args)
          }

          // This feature is only available to Pro AI and Business AI users.
          // Currently to check this condition, we use the `aiProjectCreation` feature
          // context: https://usemotion.slack.com/archives/C088TC6MX26/p1743076722246839?thread_ts=1743076109.061509&cid=C088TC6MX26

          const ensureFeaturePermissionsDataLoaded =
            API.usersV2.featurePermissionsLoader(client)

          const permissions = await ensureFeaturePermissionsDataLoaded()

          const userId = getCurrentUser(client)?.id ?? null
          const hasFeature =
            userId != null &&
            !!permissions?.models?.userFeaturePermissions?.[userId]?.[
              'aiProjectCreation'
            ]

          if (!hasFeature) {
            return redirectLoader('..')(args)
          }

          return {}
        },
      },
      { path: '*', element: <Redirect to='' /> },
    ],
  },
]
