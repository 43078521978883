import {
  type ScopeOptions,
  useSharedStateQuery,
} from '@motion/react-core/shared-state'
import { type TemplateProjectType } from '@motion/rpc/types'
import { createPendingStatus } from '@motion/rpc-cache'
import {
  isAutoScheduledStatus,
  isCanceledStatus,
  isCompletedStatus,
} from '@motion/shared/common'
import { isNoneId, isObjectNoneId } from '@motion/shared/identifiers'
import { type WorkspaceMemberWithUser } from '@motion/ui-logic'
import { createNoneUser } from '@motion/ui-logic/pm/data'
import {
  byProperty,
  byValue,
  cascade,
  Compare,
  ordered,
} from '@motion/utils/array'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'
import { Sentry } from '@motion/web-base/sentry'
import {
  type LabelSchema,
  type StatusSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { legacyProjectTemplateToTemplateProjectType } from '~/global/utils'

import {
  type WorkspaceLabelsOptions,
  type WorkspaceStatusesOptions,
} from './types'

import { AppWorkspaceContext } from '../../contexts'

export function useMyTasksWorkspace() {
  return useSharedStateQuery(
    AppWorkspaceContext,
    (state): WorkspaceSchema | undefined =>
      state.workspaces.all.find((w) => w.isPersonalWorkspace)
  )
}

export function useAllWorkspaces(opts?: ScopeOptions): WorkspaceSchema[] {
  return useSharedStateQuery(
    AppWorkspaceContext,
    (state) =>
      [...state.workspaces.all].sort(
        cascade(
          byProperty('isPersonalWorkspace', ordered([true])),
          byProperty('name', Compare.string)
        )
      ),
    opts
  )
}

export function useWorkspaceById(
  workspaceId: string | null | undefined
): WorkspaceSchema | null {
  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    workspaceId == null ? null : (state.workspaces.byId[workspaceId] ?? null)
  )
}

export function useWorkspaceStatuses(
  workspaceId: WorkspaceSchema['id'] | undefined | null,
  options: Partial<WorkspaceStatusesOptions> = READONLY_EMPTY_OBJECT
): StatusSchema[] {
  const {
    resolvedFirst = false,
    excludeResolved = false,
    excludeCanceled = false,
  } = options

  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    workspaceId == null
      ? []
      : state.statuses.all
          .filter(
            (s) =>
              s.workspaceId === workspaceId &&
              !s.deleted &&
              (!excludeResolved || !isCompletedStatus(s)) &&
              (!excludeCanceled || !isCanceledStatus(s))
          )
          .sort(
            cascade(
              byValue(
                (s) => {
                  if (resolvedFirst) return isCompletedStatus(s)
                  return false
                },
                ordered([true])
              ),
              byProperty('name', Compare.string)
            )
          )
  )
}

export function useNonAutoSchedulableStatuses(
  workspaceId: WorkspaceSchema['id'] | undefined | null
) {
  return useWorkspaceStatuses(workspaceId).filter(
    (status) =>
      !isAutoScheduledStatus(status) ||
      isCompletedStatus(status) ||
      isCanceledStatus(status)
  )
}

export function useAllCancelledStatuses() {
  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    state.statuses.all.filter((status) => isCanceledStatus(status))
  )
}

export function useAllCompletedStatuses() {
  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    state.statuses.all.filter((status) => isCompletedStatus(status))
  )
}

export function useWorkspaceStatusById(
  statusId: string | undefined | null
): StatusSchema {
  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    statusId
      ? (state.statuses.byId[statusId] ?? createPendingStatus(statusId))
      : createPendingStatus('none')
  )
}

export function useWorkspaceLabels(
  workspaceId: string | null | undefined,
  options: Partial<WorkspaceLabelsOptions> = READONLY_EMPTY_OBJECT
): LabelSchema[] {
  const { includeNoLabels = false, includeDeleted = false } = options

  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    workspaceId == null
      ? []
      : state.labels.all
          .filter((s) => {
            const shouldIncludeNoLabels = includeNoLabels
              ? true
              : !isNoneId(s.id)
            const shouldIncludeDeleted = includeDeleted ? true : !s.deleted
            const shouldInclude = shouldIncludeNoLabels && shouldIncludeDeleted

            return s.workspaceId === workspaceId && shouldInclude
          })
          .sort(byProperty('sortPosition', Compare.caseInsensitive))
  )
}

export const useWorkspaceMembers = (
  workspaceId: string
): WorkspaceMemberWithUser[] => {
  return useSharedStateQuery(
    AppWorkspaceContext,
    (state) =>
      state.workspaces.byId[workspaceId]?.members.map((m) => {
        const user = state.users.byId[m.userId] ?? createNoneUser(workspaceId)
        if (isObjectNoneId(user)) {
          Sentry.captureMessage('No user found for member', {
            extra: {
              userId: m.userId,
              workspaceId,
            },
          })
        }

        return {
          ...m,
          workspaceId,
          user,
        }
      }) ?? []
  )
}

export const useAllLegacyProjectTemplates = (): TemplateProjectType[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    state.legacyProjectTemplates.all
      .map((template) => legacyProjectTemplateToTemplateProjectType(template))
      .filter(Boolean)
  )
}

export const useWorkspaceLegacyProjectTemplates = (
  workspaceId: string
): TemplateProjectType[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    state.legacyProjectTemplates.all
      .filter((template) => template.workspaceId === workspaceId)
      .map((template) => legacyProjectTemplateToTemplateProjectType(template))
      .filter(Boolean)
  )
}
