import { DB, readPersistedStateMany } from '@motion/web-common/storage'

import {
  NotetakerViewStateKey,
  type NotetakerViewType,
} from '~/areas/notetaker'
import { type RouteData } from '~/areas/project-management/pages/pm-v3/routes/types'
import { type LoaderFunctionArgs } from 'react-router-dom'

import {
  getDefaultAllCallsView,
  getDefaultFutureView,
  getDefaultMyCallsView,
  getDefaultSharedWithMeView,
} from './default-views'

export const LAST_VIEW_PREFIX = 'notetaker_last-view'

export async function loader(args: LoaderFunctionArgs<any>) {
  await DB.open()

  const { key: pageKey, variant } = getPageKeyFromLoader(args)
  const state = await readPersistedStateMany(
    DB.state,
    [NotetakerViewStateKey],
    {
      prefix: pageKey,
      notFound: 'null',
    }
  )

  const defaultView = getDefaultNotetakerView(variant)

  if (state.get(NotetakerViewStateKey) == null) {
    state.set(NotetakerViewStateKey, defaultView)
  }

  // Store the last view
  await DB.state.set(LAST_VIEW_PREFIX, variant)

  return {
    key: pageKey,
    state,
  } satisfies Partial<RouteData>
}

function getDefaultNotetakerView(type: NotetakerViewType) {
  switch (type) {
    case 'my-calls':
      return getDefaultMyCallsView()
    case 'shared-with-me':
      return getDefaultSharedWithMeView()
    case 'all-calls':
      return getDefaultAllCallsView()
    case 'future':
      return getDefaultFutureView()
  }
}

export function getPageKeyFromLoader(args: LoaderFunctionArgs<any>): {
  key: string
  variant: NotetakerViewType
} {
  const url = new URL(args.request.url)
  const lastSegment = url.pathname.split('/').pop()

  switch (lastSegment) {
    case 'my-calls':
      return {
        key: 'notetaker-view_my-calls',
        variant: 'my-calls',
      }
    case 'all-calls':
      return {
        key: 'notetaker-view_all-calls',
        variant: 'all-calls',
      }
    case 'shared-with-me':
      return {
        key: 'notetaker-view_shared-with-me',
        variant: 'shared-with-me',
      }
    case 'future':
      return {
        key: 'notetaker-view_future',
        variant: 'future',
      }
    default:
      throw new Error(`Unknown notetaker view: ${lastSegment}`)
  }
}
