import { AdjustmentsOutline, PlusSolid } from '@motion/icons'
import { Button, Tooltip } from '@motion/ui/base'
import { useCheckWillExceedAccountFeatureCap } from '@motion/web-billing'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { useSidebarSearchContext } from '~/areas/search/hook'
import { TieredPricingUpsellButton } from '~/areas/tiered-pricing'
import { useNavigateByRouteId } from '~/routing'

import { ExpandableSection, SectionHeaderActionButton } from './components'
import {
  SidebarWorkspacesTreeview,
  useWorkspacesTreeviewItems,
} from './workspaces-treeview'

import { useGlobalSidebarState } from '../hooks'

export const WorkspacesSection = () => {
  const modalApi = useModalApi()
  const navigateByRouteId = useNavigateByRouteId({ noDefaults: true })
  const { sidebarState, toggleSidebarSection } = useGlobalSidebarState()
  const { searchQuery, hasSearch } = useSidebarSearchContext()
  const items = useWorkspacesTreeviewItems()
  const willExceedAccountFeatureCap =
    useCheckWillExceedAccountFeatureCap('workspaces')

  const hasSidebarOrganization = useHasTreatment('sidebar-organization-control')

  return (
    <ExpandableSection
      title='Workspaces'
      isExpanded={sidebarState.sections.workspaces || hasSearch}
      onToggleExpand={(state) => {
        if (hasSearch) return

        toggleSidebarSection('workspaces', state)
      }}
      renderButton={() =>
        !hasSearch && (
          <>
            <Tooltip asChild content='Add workspace'>
              <TieredPricingUpsellButton
                iconOnly
                feature='workspaces'
                featureLocked={willExceedAccountFeatureCap(1)}
                onClick={() => {
                  modalApi.prompt('add-workspace', {
                    onValue: (workspaceId) =>
                      navigateByRouteId('workspace-detail', { workspaceId }),
                  })
                }}
                sentiment='neutral'
                variant='outlined'
                size={hasSidebarOrganization ? 'xsmall' : 'small'}
                hideIcon
              >
                <PlusSolid className='!size-3' />
              </TieredPricingUpsellButton>
            </Tooltip>

            {hasSidebarOrganization && (
              <SectionHeaderActionButton
                icon={AdjustmentsOutline}
                sentiment='neutral'
                variant='outlined'
                size='xsmall'
              />
            )}
          </>
        )
      }
    >
      <SidebarWorkspacesTreeview items={items} />

      {hasSearch && items.length === 0 && (
        <div
          className='h-full flex flex-col gap-1 overflow-auto text-sm text-semantic-neutral-text-subtle p-4'
          aria-live='polite'
        >
          <div className='font-semibold'>No results</div>
          <div>Try a different search term.</div>
          <div className='pt-2'>
            <Button
              variant='outlined'
              sentiment='neutral'
              size='small'
              onClick={() =>
                modalApi.open('search', { initialQuery: searchQuery })
              }
            >
              Search tasks instead
            </Button>
          </div>
        </div>
      )}
    </ExpandableSection>
  )
}
