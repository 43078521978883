import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { type CreateWorkspaceSchema } from '@motion/zod/client'

import { useCreateWorkspace as useCreateWorkspaceCall } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { websocketsService } from '~/services/websockets-service'
import { useCallback } from 'react'

export const useCreateWorkspace = () => {
  const {
    mutateAsync: createWorkspace,
    isPending: isLoading,
    isError,
  } = useCreateWorkspaceCall()

  return {
    createWorkspace: useCallback(
      async (data: CreateWorkspaceSchema) => {
        try {
          if (data.name.trim().length === 0) {
            showToast('error', 'Workspace name cannot be empty')
            return
          }

          recordAnalyticsEvent('PROJECT_MANAGEMENT_CREATE_WORKSPACE', {
            numInvitees: data.userIds?.length ? data.userIds.length - 1 : 0,
            isCopy: data.workspaceIdToCopy != null,
          })

          const response = await createWorkspace(data)

          showToast('success', 'Workspace created')
          void websocketsService.refreshRooms()

          return response.models[response.meta.model][response.id]
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              position: 'create-workspace',
            },
          })

          showErrorToast(e)
        }
      },
      [createWorkspace]
    ),
    isLoading,
    isError,
  }
}
