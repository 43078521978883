import {
  DeadlineAheadSolid,
  DeadlineCanceledDarkSolid,
  DeadlineCanceledLightSolid,
  DeadlineCompletedDarkSolid,
  DeadlineCompletedLightSolid,
  DeadlineMissedSolid,
  DeadlineNoneDarkSolid,
  DeadlineNoneLightSolid,
  DeadlineOnTimeSolid,
  DeadlineScheduledLateSolid,
  DeadlineWarningSolid,
  type SvgIcon,
} from '@motion/icons'
import { type DeadlineStatusWithReason } from '@motion/shared/common'

import { useTheme } from '~/state/hooks'
import { forwardRef } from 'react'

import { type BadgeSize, getBadgeSizeInPixels } from './utils'

export type EtaBadgeProps = {
  value: DeadlineStatusWithReason
  size?: BadgeSize
}

export const EtaBadge = ({ value, size }: EtaBadgeProps) => {
  return <EtaColoredIcon value={value} size={size} />
}

const EtaColoredIcon = forwardRef<SVGSVGElement, EtaBadgeProps>(
  function EtaColoredIcon({ value, size }, ref) {
    const theme = useTheme()
    const pixelSize = getBadgeSizeInPixels(size)

    const Icon =
      value && (theme === 'light' ? lightEtaIcons : darkEtaIcons).get(value)

    return (
      Icon && (
        <Icon
          ref={ref}
          className='self-center shrink-0 rounded-sm'
          width={pixelSize}
          height={pixelSize}
        />
      )
    )
  }
)

const genericEtaIcons = new Map<DeadlineStatusWithReason, SvgIcon | undefined>([
  ['ahead-of-schedule', DeadlineAheadSolid],
  ['at-risk', DeadlineWarningSolid],
  ['on-track', DeadlineOnTimeSolid],
  ['scheduled-past-deadline', DeadlineScheduledLateSolid],
  ['missed-deadline', DeadlineMissedSolid],
])

const lightEtaIcons = new Map<DeadlineStatusWithReason, SvgIcon | undefined>([
  ...genericEtaIcons,
  ['none', DeadlineNoneLightSolid],
  ['completed', DeadlineCompletedLightSolid],
  ['canceled', DeadlineCanceledLightSolid],
  ['not-autoscheduled', DeadlineNoneLightSolid],
  ['future-schedulable', DeadlineNoneLightSolid],
  ['no-due-date', DeadlineNoneLightSolid],
  ['archived', DeadlineNoneLightSolid],
])

const darkEtaIcons = new Map<DeadlineStatusWithReason, SvgIcon | undefined>([
  ...genericEtaIcons,
  ['none', DeadlineNoneDarkSolid],
  ['completed', DeadlineCompletedDarkSolid],
  ['canceled', DeadlineCanceledDarkSolid],
  ['not-autoscheduled', DeadlineNoneDarkSolid],
  ['future-schedulable', DeadlineNoneDarkSolid],
  ['no-due-date', DeadlineNoneDarkSolid],
  ['archived', DeadlineNoneDarkSolid],
])
