import { createFetch } from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
  type V2ResponseStoreShape,
} from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { toMerged } from '@motion/utils/core'
import { waitForAll } from '@motion/utils/promise'

import { notifyManager } from '@tanstack/react-query'

import { type HandlerContext } from './types'
import { buildQueryContext, type MutationWithWorkspaceIds } from './utils'

const KEYS_TO_UPDATE = [MODEL_CACHE_KEY, API.workspacesV2.queryKeys.root]
const upsertQueryFilter = createQueryFilter(KEYS_TO_UPDATE)

export async function refreshWorkspaceStatuses(
  ctx: HandlerContext,
  mutations: MutationWithWorkspaceIds
) {
  notifyManager.batch(async () => {
    const fetchStatus = createFetch(
      API.workspacesV2.getWorkspaceStatuses,
      buildQueryContext(ctx)
    )

    const ret = await waitForAll(
      mutations.workspaces.map((workspaceId) => fetchStatus({ workspaceId }))
    )

    const allModels = ret.map((x) => x.models.statuses)

    const merged: V2ResponseStoreShape = {
      ids: ret.flatMap((x) => x.ids),
      meta: {
        model: 'statuses',
      },
      models: {
        // @ts-expect-error - the type is fine
        statuses: toMerged(...allModels),
      },
    }

    await MotionCache.upsert(ctx.client, upsertQueryFilter, merged)

    if (mutations.delete.length > 0) {
      await MotionCache.delete(
        ctx.client,
        upsertQueryFilter,
        'statuses',
        mutations.delete
      )
    }
  })
}
