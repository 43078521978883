import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'

import { type FilterDefinition } from '~/areas/project-management/filters'
import { ConnectedWorkspaceFilter } from '~/areas/project-management/filters/components/v2'
import { useMemo } from 'react'

import { useCustomFieldFilterDefinitions } from './custom-fields/use-custom-field-filter-definitions'
import { defaultProjectFilters, defaultTaskFilters } from './definitions'

type FilterDefinitionsProps = {
  includeWorkspaceFilter?: boolean
}

export const useTaskFilterDefinitions = ({
  includeWorkspaceFilter = false,
}: FilterDefinitionsProps = READONLY_EMPTY_OBJECT): FilterDefinition<'tasks'>[] => {
  const customFieldFilters = useCustomFieldFilterDefinitions<'tasks'>()

  return useMemo(() => {
    const filters = [...defaultTaskFilters, ...customFieldFilters]

    if (includeWorkspaceFilter) {
      filters.push({
        key: 'workspaces',
        label: 'Workspaces',
        renderDropdown: (props) => <ConnectedWorkspaceFilter {...props} />,
      })
    }

    return filters
  }, [customFieldFilters, includeWorkspaceFilter])
}

export const useProjectFilterDefinitions = ({
  includeWorkspaceFilter = false,
}: FilterDefinitionsProps = READONLY_EMPTY_OBJECT): FilterDefinition<'projects'>[] => {
  const customFieldFilters = useCustomFieldFilterDefinitions<'projects'>()

  return useMemo(() => {
    const filters = [...defaultProjectFilters, ...customFieldFilters]

    if (includeWorkspaceFilter) {
      filters.push({
        key: 'workspaces',
        label: 'Workspaces',
        renderDropdown: (props) => <ConnectedWorkspaceFilter {...props} />,
      })
    }

    return filters
  }, [customFieldFilters, includeWorkspaceFilter])
}
