import { useDependantState } from '@motion/react-core/hooks'
import { NumberField } from '@motion/ui/forms'

import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { useRegisterFieldArray } from '~/global/hooks'
import { checkNumberRuleErrors } from '~/global/rules'

import { ExpectedDurationUnitSelector } from './expected-duration-unit-selector'

import { getNumberFieldRules } from '../../utils'

export type ConnectedStageDurationProps = {
  stagePath: `stages.${number}`
}
export function ConnectedStageDuration({
  stagePath,
}: ConnectedStageDurationProps) {
  const durationPath = `${stagePath}.duration` as const
  const durationValuePath = `${durationPath}.value` as const

  const {
    form: { register, watch, getValues, formState, getFieldState },
  } = useFlowTemplateForm()
  const { error } = getFieldState(durationValuePath, formState)

  const { onChange, onBlur, ...registerProps } = useRegisterFieldArray(
    durationValuePath,
    register,
    {
      validate: () => {
        const value = getValues(durationValuePath)

        const errorMessage = checkNumberRuleErrors(
          value,
          getNumberFieldRules('Stage deadline')
        )

        if (errorMessage) {
          return errorMessage
        }
      },
    }
  )

  const durationValue = watch(durationValuePath)

  const [expectedDuration, setExpectedDuration] = useDependantState<number>(
    () => durationValue,
    [durationValue]
  )

  return (
    <label className='text-xs text-semantic-neutral-text-subtle flex flex-row items-center gap-1.5 whitespace-nowrap'>
      Expected duration:
      <NumberField
        value={expectedDuration}
        label='Deadline interval'
        labelHidden
        size='small'
        // @ts-expect-error - fine
        className='w-5'
        sentiment={error ? 'error' : 'default'}
        onBlur={(e) => {
          onBlur(e)
          onChange(expectedDuration)
        }}
        onChange={(e) => {
          setExpectedDuration(e)
        }}
        {...registerProps}
      />
      <ExpectedDurationUnitSelector durationPath={durationPath} />
    </label>
  )
}
