import {
  reduceCustomFieldsValuesFieldArrayToRecord,
  type V2SetupProjectFormFields,
  type VariableArg,
} from '@motion/ui-logic/pm/project'
import { pick } from '@motion/utils/object'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import {
  type ProjectsV2CreateRequestSchema,
  type UpsertStageSchema,
} from '@motion/zod/client'

import { useRedirectToProject } from '~/areas/project/hooks'
import { useCommitProject } from '~/global/rpc'
import { showErrorToast } from '~/global/toasts'

export function useSubmitSetupProjectForm(close: () => void) {
  const { mutateAsync: commitProject } = useCommitProject()
  const redirectToProject = useRedirectToProject()

  return async (data: V2SetupProjectFormFields) => {
    try {
      const isAI = data.mode === 'ai'
      if (!isAI) {
        throw new Error('Manual mode is not supported')
      }

      if (data.modifications == null) {
        throw new Error('Modifications are missing')
      }
      if (data.prompt == null) {
        throw new Error('Prompt is missing')
      }

      const projectCandidate = setupProjectFormToProjectCandidate({
        data,
      })

      recordAnalyticsEvent('PROJECT_MANAGEMENT_CREATE_PROJECT', {
        project_type: 'flow',
        created_with_ai: isAI,
      })

      const project = await commitProject({
        prompt: data.prompt,
        project: projectCandidate,
        modifications: data.modifications,
      })

      close()
      redirectToProject(project.id)
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          position: 'useSubmitSetupProjectForm',
        },
      })

      showErrorToast(e)
    }
  }
}

type SetupProjectFormToProjectCandidateArgs = {
  data: V2SetupProjectFormFields
}
function setupProjectFormToProjectCandidate({
  data,
}: SetupProjectFormToProjectCandidateArgs): ProjectsV2CreateRequestSchema {
  if (data.speculativeProject == null) {
    throw new Error('Generated speculative project is missing')
  }

  const { stages, variableInstances } = getStagesAndVariables(data)

  return {
    ...data.speculativeProject,
    syncedCustomFields: data.customFieldSyncInstanceIds.map((id) => ({
      customFieldInstanceId: id,
    })),
    customFieldValues: reduceCustomFieldsValuesFieldArrayToRecord(
      data.customFieldValuesFieldArray,
      { omitNull: true }
    ),
    variableInstances,
    stages,
  }
}

function getStagesAndVariables(data: V2SetupProjectFormFields) {
  if (data.speculativeProject == null) {
    throw new Error('Generated speculative project is missing')
  }

  return {
    /**
     * Ignore canceledTime; it's only used for UI state.
     * Canceling a stage is done by canceling all its tasks.
     */
    stages: data.speculativeProject.stages.map(
      (stage) =>
        ({
          stageDefinitionId: stage.stageDefinitionId,
          dueDate: stage.dueDate,
          variableInstances: [
            ...data.roleAssignees
              .filter((role) => role.stageId === stage.stageDefinitionId)
              .map(pickVariableValues),
            ...data.textReplacements
              .filter((text) => text.stageId === stage.stageDefinitionId)
              .map(pickVariableValues),
          ],
        }) satisfies UpsertStageSchema
    ),
    variableInstances: [],
  }
}

const pickVariableValues = (variable: VariableArg) =>
  pick(variable, ['value', 'variableId'])
