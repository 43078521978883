import { type ReactNode } from 'react'

import { BrowseButton } from './browse-button'

import { ConnectedViewTabs } from '../components'

export type PageTabsProps = {
  showBrowseButton?: boolean
  children?: ReactNode
}

export function PageTabs({
  showBrowseButton = false,
  children,
}: PageTabsProps) {
  return (
    <div className='px-4 border-b border-solid border-tab-border-default flex gap-2 items-center'>
      {showBrowseButton && <BrowseButton />}
      <ConnectedViewTabs className='flex-1'>{children}</ConnectedViewTabs>
    </div>
  )
}
