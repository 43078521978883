import { FieldButton } from '@motion/ui/base'

import { useStageTaskCardFields } from '~/areas/flows/contexts'
import { LabelDropdown } from '~/global/components/dropdowns'

import { InlineLabels } from './inline-labels'

export function LabelFields() {
  const {
    workspaceId,
    fields: { labels },
  } = useStageTaskCardFields()
  const [labelIds, onChangeLabelIds] = labels

  const fieldSize = 'xsmall'

  return (
    <LabelDropdown
      selectedLabelIds={labelIds}
      onChange={onChangeLabelIds}
      workspaceId={workspaceId}
    >
      <FieldButton variant='muted' fullWidth size={fieldSize}>
        <InlineLabels labelIds={labelIds} workspaceId={workspaceId} />
      </FieldButton>
    </LabelDropdown>
  )
}
