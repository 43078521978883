import { ShareSolid } from '@motion/icons'
import { Button, type ButtonProps } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'
import { type NoteSchema } from '@motion/zod/client'

import { type MouseEvent } from 'react'

type NoteShareButtonProps = ButtonProps & {
  noteId: NoteSchema['id']
}
export const NoteShareButton = ({ noteId, ...props }: NoteShareButtonProps) => {
  const modalApi = useModalApi()

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    props?.onClick?.(event)

    modalApi.open('share-item', {
      itemType: 'NOTE',
      itemId: noteId,
    })

    recordAnalyticsEvent('CLICKED_SHARE_ITEM', {
      itemType: 'NOTE',
      location: 'NOTE_HEADER',
    })
  }

  const content = props.children || (
    <>
      <ShareSolid /> Share
    </>
  )

  return (
    <Button
      size='small'
      variant='muted'
      sentiment='neutral'
      {...props}
      onClick={handleClick}
    >
      {content}
    </Button>
  )
}
