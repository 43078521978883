import { type NoteSchema } from '@motion/rpc-types'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { useCreateNoteMentionsMutation } from '~/global/rpc/v2'
import { useCallback } from 'react'

function isValidTargetType(
  targetType: string
): targetType is 'TASK' | 'PROJECT' | 'USER' | 'NOTE' {
  return ['TASK', 'PROJECT', 'USER', 'NOTE'].includes(targetType)
}

export type CreateMentionPayload = {
  noteId: NoteSchema['id']
  mentionId: string
  targetId: string
  targetType: string
  silent?: boolean
}

export function useCreateNoteMention() {
  const context = useRouteAnalyticsMetadata()

  const { mutateAsync: createMentions } = useCreateNoteMentionsMutation()

  return useCallback(
    async ({
      noteId,
      mentionId,
      targetId,
      targetType: targetTypeParam,
      silent,
    }: CreateMentionPayload) => {
      const targetType = targetTypeParam.toUpperCase()

      try {
        if (!noteId) {
          throw new Error('Doc id is required.')
        }
        if (!mentionId) {
          throw new Error('Mention id is required.')
        }

        if (!targetId || !targetType) {
          throw new Error('Target id and target type are required.')
        }

        if (!isValidTargetType(targetType)) {
          throw new Error('Invalid target type.')
        }

        const result = await createMentions({
          id: noteId,
          mentions: [
            {
              key: mentionId,
              targetId,
              targetType,
              silent,
            },
          ],
        })

        recordAnalyticsEvent('PROJECT_MANAGEMENT_CREATE_NOTE_MENTION', {
          ...context,
          entityType: targetType,
        })

        return result
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            position: 'useCreateNoteMention',
          },
        })
      }
    },
    [context, createMentions]
  )
}
