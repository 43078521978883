import { FolderSolid, NoteSolid, ProjectCubeSolid } from '@motion/icons'
import { ActionDropdown, type PopoverTriggerProps } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'
import {
  type FolderSchema,
  type ProjectSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { useCreateNote } from '~/areas/notes'
import { useProjectModalUrl } from '~/global/navigation'
import { type PropsWithChildren } from 'react'
import { useNavigate } from 'react-router'

export type WorkspaceActionMenuProps = {
  workspaceId: WorkspaceSchema['id']
  folderId?: FolderSchema['id']
  projectId?: ProjectSchema['id']
  allowNewProject?: boolean
  allowNewFolder?: boolean
  allowNewNote?: boolean
  offset?: PopoverTriggerProps['offset']
  placement?: PopoverTriggerProps['placement']
}

export const WorkspaceCreateMenu = (
  props: PropsWithChildren<WorkspaceActionMenuProps>
) => {
  const {
    workspaceId,
    folderId,
    projectId,
    allowNewFolder = true,
    allowNewProject = true,
    allowNewNote = false,
    offset,
    placement,
    children,
  } = props

  const modalApi = useModalApi()
  const buildProjectModalUrl = useProjectModalUrl()
  const navigate = useNavigate()
  const createNote = useCreateNote()

  return (
    <ActionDropdown
      placement={placement}
      offset={offset}
      sections={[
        {
          items: [
            allowNewFolder && {
              prefix: <FolderSolid />,
              content: 'New folder',
              onAction: () => {
                modalApi.open('create-new-folder', {
                  workspaceId,
                  folderId,
                })
              },
            },
            allowNewProject && {
              prefix: <ProjectCubeSolid />,
              content: 'New project',
              onAction: () => {
                navigate(
                  buildProjectModalUrl({
                    forWorkspace: workspaceId,
                    forFolder: folderId,
                  })
                )
              },
            },
            allowNewNote && {
              prefix: <NoteSolid />,
              content: 'New doc',
              onAction: async () => {
                createNote(
                  {
                    title: '',
                    targetType: 'WORKSPACE',
                    targetId: workspaceId,
                    folderId,
                    projectId,
                  },
                  { navigate: true }
                )
              },
            },
          ],
        },
      ]}
    >
      {children}
    </ActionDropdown>
  )
}
