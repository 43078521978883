import { diffBusinessDays } from '@motion/utils/dates'

import { DateTime } from 'luxon'
import { useMemo } from 'react'

import { useSetupProjectForm } from '../../../hooks'

export function useStageLength(i: number) {
  const { form } = useSetupProjectForm()

  return useMemo(() => {
    const stageDueDates = form.getValues('stageDueDates')
    const startDateStr = form.watch('startDate')
    const dueDateStr = form.watch('dueDate')

    if (!startDateStr || !dueDateStr) return 0

    const stageDueDate = stageDueDates[i]?.dueDate
    if (!stageDueDate) return 0

    if (i === 0) {
      // Add 1 to include the project start date
      return getBusinessDaysBetweenDates(stageDueDate, startDateStr) + 1
    }

    // Find the previous enabled stage due date (or project start date)
    const previousStageDueDate =
      stageDueDates.findLast((s, index) => !s.skipped && index < i)?.dueDate ??
      startDateStr

    if (!previousStageDueDate) return 0

    return getBusinessDaysBetweenDates(stageDueDate, previousStageDueDate)
  }, [form, i])
}

const getBusinessDaysBetweenDates = (date1: string, date2: string) => {
  return Math.abs(
    diffBusinessDays(DateTime.fromISO(date1), DateTime.fromISO(date2))
  )
}
