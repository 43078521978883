import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useCallback, useContext } from 'react'

import { NoteDialogContext } from '../context/note-dialog-provider/note-dialog-context'
import { type NoteDialogIds, type NoteDialogOptions } from '../dialogs'

export const useNoteDialogApi = () => {
  const modalApi = useModalApi()
  const { openedDialog, setOpenedDialog, setSelectedText } =
    useContext(NoteDialogContext)

  const openDialog = useCallback(
    async (id: NoteDialogIds, opts?: NoteDialogOptions) => {
      if (openedDialog) {
        const result = await modalApi.prompt('confirm', {
          title: 'You already have an action in progress',
          description:
            'You currently have an action in progress, are you sure you want to discard your progress?',
          destructive: true,
          analytics: {
            name: 'notes-dialog-already-open-warning',
          },
          confirmButtonText: 'Discard and close',
          closeButtonText: 'Cancel',
        })

        if (result === ModalDismissed) return
        setOpenedDialog(null)
      }

      // Timeout is to allow the modal textfield to refocus when you discard previous changes
      setTimeout(() => {
        setOpenedDialog(id)

        if (opts?.selectedText) {
          setSelectedText(opts.selectedText)
        }
      }, 1)
    },
    [modalApi, openedDialog, setOpenedDialog, setSelectedText]
  )

  return {
    openDialog,
    closeDialog: () => setOpenedDialog(null),
  }
}
