import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { type WorkspaceSchema } from '@motion/zod/client'

import { useDeleteWorkspaceMember as useDeleteWorkspaceMemberCall } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useDeleteWorkspaceMember = () => {
  const {
    mutateAsync: deleteWorkspaceMember,
    isPending: isLoading,
    isError,
  } = useDeleteWorkspaceMemberCall()
  return {
    deleteWorkspaceMember: useCallback(
      async (data: {
        workspaceId: WorkspaceSchema['id']
        memberId: string
      }) => {
        try {
          recordAnalyticsEvent('PROJECT_MANAGEMENT_DELETE_WORKSPACE_MEMBER')

          const response = await deleteWorkspaceMember(data)

          return response.models[response.meta.model][response.id]
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              position: 'remove-workspace-member',
            },
          })

          showErrorToast(e)
        }
      },
      [deleteWorkspaceMember]
    ),
    isLoading,
    isError,
  }
}
