import { useDependantState } from '@motion/react-core/hooks'
import { templateStr } from '@motion/react-core/strings'
import {
  ActionList,
  type ActionSection,
  DatePicker,
  PopoverButton,
  PopoverTrigger,
  SearchableList,
} from '@motion/ui/base'
import { NumberField } from '@motion/ui/forms'
import {
  type CalendarStartDay,
  formatToReadableWeekDayMonth,
} from '@motion/ui-logic'
import {
  processLeadingEdge,
  processTrailingEdge,
} from '@motion/ui-logic/pm/data'
import { isEqual } from '@motion/utils/core'
import {
  type NowRelativeDateSchema,
  type RelativeDateRangeFilterSchema,
} from '@motion/zod/client'

import { DateTime } from 'luxon'
import { useMemo } from 'react'

import { DATE_RANGE_OPTIONS, type DateRangeOption } from './options'

type Value = RelativeDateRangeFilterSchema['from']
type Mode = 'start' | 'end'

export type ValueDropdownProps = {
  mode: Mode
  value: Value
  calendarStartDay?: CalendarStartDay
  disallowNone?: boolean
  onChange: (value: Value) => void
}
export function ValueDropdown({
  value,
  onChange,
  mode,
  calendarStartDay,
  disallowNone = false,
}: ValueDropdownProps) {
  const selectedOption = useMemo(() => findSelectedOption(value), [value])

  const selectedOptionLabel = useMemo(
    () => getOptionLabel(selectedOption, value, mode),
    [selectedOption, value, mode]
  )

  return (
    <div className='flex flex-col gap-2'>
      <PopoverTrigger
        renderPopover={({ close }) => {
          return (
            <ValueDropdownContent
              close={close}
              mode={mode}
              calendarStartDay={calendarStartDay}
              disallowNone={disallowNone}
              selectedOption={selectedOption}
              onSelect={onChange}
            />
          )
        }}
      >
        <PopoverButton>{selectedOptionLabel}</PopoverButton>
      </PopoverTrigger>
      {value != null &&
        typeof value !== 'string' &&
        selectedOption.key === 'advanced' && (
          <AdvancedControls mode={mode} value={value} onChange={onChange} />
        )}
    </div>
  )
}

const offsetSigns = [
  { key: 'minus' as const, label: 'Minus' },
  { key: 'plus' as const, label: 'Plus' },
]

const offsetUnits = [
  { key: 'day' as const, label: 'Days' },
  { key: 'week' as const, label: 'Weeks' },
  { key: 'month' as const, label: 'Months' },
  { key: 'quarter' as const, label: 'Quarters' },
  { key: 'year' as const, label: 'Years' },
]

type AdvancedControlsProps = {
  mode: Mode
  value: NowRelativeDateSchema
  onChange: (value: Value) => void
}
function AdvancedControls({ mode, value, onChange }: AdvancedControlsProps) {
  const [offsetSign, setOffsetSign] = useDependantState<
    (typeof offsetSigns)[number]['key']
  >(() => (value.offset < 0 ? 'minus' : 'plus'), [value.offset])

  const [internalOffsetValue, setInternalOffsetValue] =
    useDependantState<number>(() => Math.abs(value.offset), [value.offset])

  return (
    <div className='flex flex-row gap-2'>
      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <SearchableList
            searchable={false}
            items={offsetSigns}
            computeKey={(item) => item.key}
            computeSelected={(item) => item.key === offsetSign}
            onSelect={(item) => {
              setOffsetSign(item.key)
              onChange({
                ...value,
                offset: internalOffsetValue * (item.key === 'minus' ? -1 : 1),
              })
              close()
            }}
            renderItem={(item) => item.label}
          />
        )}
      >
        <PopoverButton>
          {offsetSigns.find((u) => u.key === offsetSign)?.label ?? 'unknown'}
        </PopoverButton>
      </PopoverTrigger>
      <NumberField
        label='Offset'
        labelHidden
        value={internalOffsetValue}
        onChange={setInternalOffsetValue}
        onBlur={() => {
          if (isNaN(internalOffsetValue)) return

          onChange({
            ...value,
            offset: internalOffsetValue * (offsetSign === 'minus' ? -1 : 1),
          })
        }}
      />
      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <SearchableList
            searchable={false}
            items={offsetUnits}
            computeKey={(item) => item.key}
            computeSelected={(item) => item.key === value.unit}
            onSelect={(item) => {
              onChange({ ...value, unit: item.key })
              close()
            }}
            renderItem={(item) => item.label}
          />
        )}
      >
        <PopoverButton>
          {offsetUnits.find((u) => u.key === value.unit)?.label ?? 'unknown'}
        </PopoverButton>
      </PopoverTrigger>
    </div>
  )
}

function findSelectedOption(value: Value): DateRangeOption {
  const selectedOption =
    DATE_RANGE_OPTIONS.findLast((opt) => {
      return (
        ((opt.key === 'preset' || opt.key === 'advanced') &&
          isEqual(value, opt.value)) ||
        (opt.key === 'select' && typeof value === 'string')
      )
    }) ?? DATE_RANGE_OPTIONS.find((o) => o.key === 'advanced')

  if (selectedOption == null) {
    throw new Error('Range option not found')
  }

  return selectedOption
}

function getOptionLabel(option: DateRangeOption, value: Value, mode: Mode) {
  if (option.key === 'preset')
    return templateStr(option.label, {
      StartOrEnd: mode === 'start' ? 'Start' : 'End',
    })
  if (option.key === 'select' && typeof value === 'string') {
    return formatToReadableWeekDayMonth(value)
  }

  return 'Today'
}

type ValueDropdownContentProps = {
  close: () => void
  selectedOption: DateRangeOption
  calendarStartDay?: CalendarStartDay
  disallowNone?: boolean
  onSelect: (value: Value) => void
  mode: Mode
}
function ValueDropdownContent({
  close,
  onSelect,
  calendarStartDay,
  disallowNone = false,
  mode,
}: ValueDropdownContentProps) {
  const sections = createSections(
    onSelect,
    mode,
    calendarStartDay,
    disallowNone
  )

  return <ActionList sections={sections} onActionAnyItem={close} />
}

function createSections(
  onSelect: ValueDropdownContentProps['onSelect'],
  mode: Mode,
  calendarStartDay?: CalendarStartDay,
  disallowNone = false
): ActionSection[] {
  return DATE_RANGE_OPTIONS.reduce(
    (acc, cur) => {
      if (cur.key === 'separator') {
        acc.push({ items: [] })
        return acc
      }

      const section = acc[acc.length - 1]
      if (!section) return acc

      if (cur.key === 'preset' && cur.value == null && disallowNone) return acc

      section.items.push({
        content: <ItemLabel value={cur} mode={mode} />,
        onAction:
          cur.key === 'select'
            ? undefined
            : () => {
                onSelect(cur.value ?? null)
              },
        renderPopover:
          cur.key !== 'select'
            ? undefined
            : ({ close }) => (
                <div className='p-3'>
                  <DatePicker
                    value={null}
                    mode='single'
                    weekStartDay={calendarStartDay}
                    onChange={(v) => {
                      if (!v || Array.isArray(v)) return

                      onSelect(v)

                      close()
                    }}
                  />
                </div>
              ),
      })

      return acc
    },
    [{ items: [] }] as ActionSection[]
  )
}

function ItemLabel({
  value,
  mode,
}: {
  value: Exclude<DateRangeOption, { key: 'separator' }>
  mode: Mode
}) {
  const dateRangeCtx = { now: DateTime.now(), zone: DateTime.now().zone }

  const dateValue =
    value.key === 'preset'
      ? mode === 'start'
        ? processLeadingEdge(dateRangeCtx, value.value)
        : processTrailingEdge(dateRangeCtx, value.value)
      : null

  return (
    <div className='flex gap-2 text-sm'>
      <span className='text-semantic-neutral-text-default'>
        {templateStr(value.label, {
          StartOrEnd: mode === 'start' ? 'Start' : 'End',
        })}
      </span>
      {dateValue != null && (
        <span className='text-semantic-neutral-text-subtle'>
          {formatToReadableWeekDayMonth(dateValue)}
        </span>
      )}
    </div>
  )
}
