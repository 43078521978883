import { byProperty, Compare, uniqueBy } from '@motion/utils/array'
import { Sentry } from '@motion/web-base/sentry'

import { ReactRenderer } from '@tiptap/react'
import { type SuggestionOptions } from '@tiptap/suggestion'
import {
  type ComponentPropsWithRef,
  type ComponentType,
  type ElementType,
} from 'react'
import tippy from 'tippy.js'

import { type SuggestionDropdownProps } from './suggestion-dropdown'

import { type SuggestionStorage } from '../types'

export const suggestion = (
  nodeType: string,
  DropdownComponent: ComponentType<SuggestionDropdownProps>
): Omit<SuggestionOptions, 'editor'> => ({
  char: '/',
  items: ({ query, editor }) => {
    return uniqueBy(
      // Have to cast. See https://tiptap.dev/docs/guides/typescript#storage-types.
      (editor.storage.flowVariable as SuggestionStorage).suggestions.filter(
        (item) => item.label.toLowerCase().includes(query.toLowerCase())
      ),
      (x) => x.label
    ).sort(byProperty('label', Compare.caseInsensitive))
  },
  command: ({ editor, range, props }) => {
    // increase range.to by one when the next node is of type "text"
    // and starts with a space character
    const nodeAfter = editor.view.state.selection.$to.nodeAfter
    const overrideSpace = nodeAfter?.text?.startsWith(' ')

    if (overrideSpace) {
      range.to += 1
    }

    editor
      .chain()
      .focus()
      .insertContentAt(range, [
        {
          type: nodeType,
          attrs: {
            id: props.id,
          },
        },
      ])
      .run()

    try {
      window.getSelection()?.collapseToEnd()
    } catch (error) {
      Sentry.captureException(error)
    }
  },
  render: () => {
    let component: ComponentPropsWithRef<ElementType<any>>
    let popup: any
    return {
      onExit() {
        popup?.[0]?.destroy()
        component?.destroy()
      },

      onKeyDown(props) {
        return component.ref?.onKeyDown(props)
      },

      onStart: (props: any) => {
        component = new ReactRenderer(DropdownComponent, {
          editor: props.editor,
          props: {
            ...props,
            close: () => {
              popup?.[0]?.hide()
            },
          },
        })
        popup = tippy('body', {
          appendTo: () => document.body,
          content: component.element,
          getReferenceClientRect: props.clientRect,
          interactive: true,
          placement: 'bottom-start',
          showOnCreate: true,
          trigger: 'manual',
          zIndex: 1,
        })
      },

      onUpdate(props) {
        component.updateProps(props)
        popup?.[0]?.setProps({
          getReferenceClientRect: props.clientRect,
        })
      },
    }
  },
})
