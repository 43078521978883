import { templateStr } from '@motion/react-core/strings'
import { ConfirmationModal, showToast } from '@motion/ui/base'
import { shouldPromptForTimezoneChange } from '@motion/ui-logic/user-settings'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi, useModalStatus } from '@motion/web-common/modals'

import { useTimezoneSettings } from '~/global/hooks'
import { useUpdateMyTimezonesMutation } from '~/global/rpc/v2'
import { useEffect } from 'react'

import { dangerouslyTriggerUpdateFunction } from '../../state/calendar/calendarThunks'
import { useAppDispatch } from '../../state/hooks'

export const ConnectedAutoChangeTimezoneModal = () => {
  const dispatch = useAppDispatch()
  const modalApi = useModalApi()
  const status = useModalStatus('auto-change-timezone-modal')
  const { mutate: updateTimezoneSettings } = useUpdateMyTimezonesMutation()
  const {
    formattedLocalTimezoneString,
    detectedTimezone,
    defaultTimezone,
    localTimezoneName,
  } = useTimezoneSettings({ useDefaults: false }) // we don't use defaults here because of the if block in the useEffect checking for existence

  useEffect(() => {
    const checkTimezone = async () => {
      const shouldPrompt = shouldPromptForTimezoneChange({
        localTimezoneName,
        defaultTimezone,
        detectedTimezone,
      })

      if (defaultTimezone && shouldPrompt) {
        recordAnalyticsEvent('TIMEZONE_CHANGE_DETECTED_MODAL_SHOWN', {
          defaultTimezone: defaultTimezone,
          localTimezone: localTimezoneName,
          lastCheckedTimezone: detectedTimezone ?? '',
        })

        void modalApi.prompt('auto-change-timezone-modal', {
          onValue: async () => {
            recordAnalyticsEvent('TIMEZONE_CHANGE_DETECTED_ACCEPTED_CHANGE')

            void updateTimezoneSettings({
              defaultTimezone: localTimezoneName,
              latestClientTimezoneDetected: null,
            })

            void dispatch(dangerouslyTriggerUpdateFunction())

            showToast('success', 'Default time zone changed')
          },
          onDismiss: async () => {
            recordAnalyticsEvent('TIMEZONE_CHANGE_DETECTED_DISMISSED_CHANGE')

            void updateTimezoneSettings({
              latestClientTimezoneDetected: localTimezoneName,
            })

            showToast(
              'neutral',
              'You can change your default time zone in settings.'
            )
          },
        })
      }
    }

    void checkTimezone()
  }, [
    defaultTimezone,
    detectedTimezone,
    dispatch,
    modalApi,
    localTimezoneName,
    updateTimezoneSettings,
  ])

  if (!status.visible) return null

  return (
    <ConfirmationModal
      visible
      title={templateStr(
        "We've detected you've changed local timezones to {{timezone}}. The calendar will now display in your local timezone. Do you want to update your default timezone (used for auto-scheduling) as well?",
        {
          timezone: formattedLocalTimezoneString,
        }
      )}
      onClose={() => status.close()}
      action={{
        label: 'Change Time Zone',
        onAction: () => status.close(true),
      }}
      closeLabel='Not now'
    />
  )
}
