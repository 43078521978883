import { type TextVariable } from '@motion/ui-logic/pm/project'

import {
  FlowVariableSearchableList,
  type FlowVariableSearchableListProps,
} from '~/areas/flows/components'
import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { createNewTextVariable } from '~/areas/flows/utils'
import { useFieldArray } from 'react-hook-form'

export type ConnectedFlowVariableSearchableListProps<T extends TextVariable> =
  Omit<FlowVariableSearchableListProps<T>, 'children'> & {
    close?: () => void
  }

export function ConnectedFlowVariableSearchableList<T extends TextVariable>(
  props: ConnectedFlowVariableSearchableListProps<T>
) {
  const {
    form: { control },
  } = useFlowTemplateForm()

  const { append } = useFieldArray({
    control,
    name: 'textVariables',
  })

  const handleAddNew = (name: string) => {
    const newTextVariable = createNewTextVariable({
      name,
    })

    append(newTextVariable)
    props.onChange(newTextVariable as T)
  }

  return <FlowVariableSearchableList {...props} onAddNew={handleAddNew} />
}
