import { type ProjectDefinitionSchema } from '@motion/rpc-types'
import {
  type FlowTemplateFormFields,
  getProjectDefDescription,
} from '@motion/ui-logic/pm/project'

import { useInitialFlowStages, useInitialFlowVariables } from '../../../hooks'
import { type FlowTemplateUrlSearchParams } from '../../utils'

type GetInitialFormDataProps = {
  projectDefinition: ProjectDefinitionSchema | null
  templateId: string | undefined
  templateOperationParam: FlowTemplateUrlSearchParams['template']
  workspaceId: string
  currentUserId: string
  isLoading?: boolean
  mode?: 'ai-generation'
}

export function useGetInitialFormData({
  projectDefinition,
  templateId,
  templateOperationParam,
  workspaceId,
  currentUserId,
  isLoading,
  mode,
}: GetInitialFormDataProps) {
  const usingTemplateData = templateId != null && projectDefinition != null
  const isEditing = usingTemplateData && templateOperationParam === 'edit'
  const isCreatingFromTemplate =
    usingTemplateData && templateOperationParam === 'new'

  const id = isEditing ? projectDefinition.id : undefined

  const stages = useInitialFlowStages({
    workspaceId,
    projectDefinition,
    isCreatingFromTemplate,
    currentUserId,
  })

  const { roles, textVariables } = useInitialFlowVariables(
    projectDefinition,
    isCreatingFromTemplate,
    {
      enabled: true,
    }
  )

  const assigneeUserId = usingTemplateData
    ? projectDefinition.managerId
    : currentUserId
  const priorityLevel = usingTemplateData
    ? projectDefinition.priorityLevel
    : 'MEDIUM'

  return () => {
    return {
      type: 'flow-template',
      isLoading: isLoading ?? false,
      hasError: false,
      id,
      workspaceId,
      name: projectDefinition?.name ?? '',
      description: getProjectDefDescription(projectDefinition?.description),
      color: projectDefinition?.color ?? 'gray',
      folderId: projectDefinition?.folderId ?? undefined,
      managerId: assigneeUserId,
      priorityLevel,
      labelIds: projectDefinition?.labelIds ?? [],
      roles,
      textVariables,
      stages,
      stageDefinitionReferences:
        projectDefinition?.stageDefinitionReferences ?? [],
      definitionDescription: projectDefinition?.definitionDescription ?? '',
      mode: mode ?? 'user',
    } satisfies FlowTemplateFormFields
  }
}
