import { Portal, useShortcut } from '@motion/ui/base'

import { CreateContentWithAiDialog } from './create-content-with-ai'
import { CreateTaskWithAiDialog } from './create-task-with-ai'
import { RewriteTextWithAiDialog } from './rewrite-text-with-ai'
import { SummarizeTextWithAiDialog } from './summarize-text-with-ai'

import { type NoteDialogContextValue } from '../context/note-dialog-provider/note-dialog-context'
import { useNoteDialogApi } from '../hooks'

type NoteDialogContainerProps = {
  openedDialog: NoteDialogContextValue['openedDialog']
}

export const NoteDialogContainer = ({
  openedDialog,
}: NoteDialogContainerProps) => {
  const { closeDialog } = useNoteDialogApi()

  const shouldShowDialog = openedDialog != null

  useShortcut('escape', closeDialog, {
    enabled: shouldShowDialog,
  })

  if (!shouldShowDialog) return null

  return (
    <Portal>
      <div className='fixed right-6 bottom-6 z-[0]'>
        <Dialog dialogId={openedDialog} close={closeDialog} />
      </div>
    </Portal>
  )
}

type DialogProps = {
  dialogId: NonNullable<NoteDialogContextValue['openedDialog']>
  close: () => void
}

const Dialog = ({ dialogId, close }: DialogProps) => {
  switch (dialogId) {
    case 'create-task-with-ai':
    case 'create-tasks-with-ai':
      return (
        <CreateTaskWithAiDialog
          createMultiple={dialogId === 'create-tasks-with-ai'}
          close={close}
        />
      )

    case 'summarize-with-ai':
      return <SummarizeTextWithAiDialog close={close} />

    case 'rewrite-with-ai':
      return <RewriteTextWithAiDialog close={close} />

    case 'create-content-with-ai':
      return <CreateContentWithAiDialog close={close} />

    default:
      return null
  }
}
