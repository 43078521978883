import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import {
  type CreateProjectDefinitionSchema,
  type ProjectDefinitionSchema,
} from '@motion/zod/client'

import { useMyTasksWorkspace, useProjectDefinition } from '~/global/hooks'
import { useGetProjectDefinitionFull } from '~/global/rpc/v2'
import { useMemo } from 'react'

import { useGetInitialFormData } from './use-get-initial-form-data'
import { usePresetTemplate } from './use-preset-template'
import { useProjectDefinitionFromCreateSchema } from './use-project-definition-from-create-schema'

import { useFlowTemplateModalUrlParams } from '../use-flows-template-modal-url'

type InitialFormFields = {
  /**
   * The initial project definition to use for the form.
   * This is used in AI flows that will generate a project definition.
   */
  initialProjectDefinition?: CreateProjectDefinitionSchema
  mode?: 'ai-generation'
}

export function useInitialFormData({
  initialProjectDefinition,
  mode,
}: InitialFormFields): FlowTemplateFormFields {
  const defaultWorkspace = useMyTasksWorkspace()
  const {
    template: templateOperationParam,
    workspaceId: workspaceIdParam,
    templateId,
  } = useFlowTemplateModalUrlParams()

  const { uid: currentUserId } = useAuthenticatedUser()

  const { projectDefinition, isLoading } = useFormProjectDefinition({
    initialProjectDefinition,
  })

  const workspaceId =
    projectDefinition?.workspaceId ?? workspaceIdParam ?? defaultWorkspace?.id

  if (workspaceId == null) {
    throw new Error('Workspace id not defined')
  }

  const getInitialFormData = useGetInitialFormData({
    projectDefinition,
    templateId,
    templateOperationParam,
    currentUserId,
    workspaceId,
    mode,
    isLoading,
  })

  return useMemo(() => {
    return getInitialFormData()
  }, [getInitialFormData])
}

type InitialProjectDefinitionProps = {
  initialProjectDefinition?: CreateProjectDefinitionSchema
}

type FormDefinitionResult = {
  projectDefinition: ProjectDefinitionSchema | null
  isLoading: boolean
}
/**
 * Grab either the preset template or the provided project definition.
 * Project Definition takes precedence over the preset template.
 */
function useFormProjectDefinition({
  initialProjectDefinition,
}: InitialProjectDefinitionProps): FormDefinitionResult {
  const defaultWorkspace = useMyTasksWorkspace()

  const {
    fromPresetId,
    template: templateOperationParam,
    workspaceId: workspaceIdParam,
    templateId,
  } = useFlowTemplateModalUrlParams()

  const workspaceId = workspaceIdParam ?? defaultWorkspace?.id
  if (workspaceId == null) {
    throw new Error('Workspace id not defined')
  }

  const presetProjectDefinition = usePresetTemplate(
    workspaceId,
    templateOperationParam === 'new',
    fromPresetId
  )

  const shouldQueryProjectDefinitionFull =
    templateId != null && templateId !== '' && workspaceId != null
  const { data: projectDefinitionFull, isLoading } =
    useGetProjectDefinitionFull(
      {
        id: templateId ?? '',
        workspaceId,
      },
      {
        enabled: shouldQueryProjectDefinitionFull,
      }
    )
  const templateProjectDefinition = useProjectDefinition(templateId ?? '')
  const convertedProjectDefinition = useProjectDefinitionFromCreateSchema(
    initialProjectDefinition ?? presetProjectDefinition
  )

  return useMemo(() => {
    const projectDefinition =
      projectDefinitionFull?.models.projectDefinitions[templateId ?? ''] ??
      convertedProjectDefinition ??
      templateProjectDefinition

    return {
      projectDefinition,
      isLoading,
    }
  }, [
    convertedProjectDefinition,
    isLoading,
    projectDefinitionFull?.models.projectDefinitions,
    templateId,
    templateProjectDefinition,
  ])
}
