import { type MentionsSearchResults } from '@motion/notes'
import { useDebouncedValue } from '@motion/react-core/hooks'
import {
  type NoteResponseSchema,
  type ProjectResponseSchema,
  type TaskResponseSchema,
} from '@motion/rpc-types'
import { computeSearchScore, DEFAULT_DOC_TITLE } from '@motion/ui-logic'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'

import { stripEmTags } from '~/areas/modals'
import { useTeamMembers } from '~/global/hooks/team'
import { useRecentlyOpenedEntities, useSearch } from '~/global/rpc'
import { useMemo } from 'react'

const MAX_SEARCH_RESULTS_PER_GROUP = 5

type MentionSearchOptions = {
  enabled: boolean
}

export function useMentionSearch(
  query: string,
  { enabled = true }: Partial<MentionSearchOptions> = READONLY_EMPTY_OBJECT
) {
  const teamMembers = useTeamMembers()

  const debouncedQuery = useDebouncedValue(query, { timeoutMs: 250 })

  const { data: recentlyOpenedEntities } = useRecentlyOpenedEntities(void 0, {
    enabled: debouncedQuery.length === 0 && enabled,
  })

  const { data: searchResults, isLoading: isLoadingSearchResults } = useSearch(
    {
      query: debouncedQuery,
      entities: ['task', 'project', 'note'],
      limit: 25,
    },
    {
      enabled: debouncedQuery.length > 0 && enabled,
    }
  )

  const results = query.length > 0 ? searchResults : recentlyOpenedEntities

  const userResults = useMemo(
    () =>
      teamMembers
        .map((member) => {
          const nameScore = computeSearchScore(member.user.name, query)
          const emailScore = computeSearchScore(member.user.email, query)
          return [member, Math.max(nameScore, emailScore)] as const
        })
        .filter(([, score]) => (query.length > 0 ? score > 0 : true))
        .sort(([, scoreA], [, scoreB]) => scoreB - scoreA)
        .map(([member]) => member),
    [query, teamMembers]
  )

  const items = useMemo<MentionsSearchResults>(() => {
    if (!enabled) return []

    const taskResults = (results ?? []).filter(
      (result): result is TaskResponseSchema => result.entityType === 'task'
    )

    const projectResults = (results ?? []).filter(
      (result): result is ProjectResponseSchema =>
        result.entityType === 'project'
    )

    const noteResults = (results ?? []).filter(
      (result): result is NoteResponseSchema => result.entityType === 'note'
    )

    const options = [
      userResults.length > 0 && {
        type: 'user',
        label: 'Users',
        entities: userResults
          .slice(0, MAX_SEARCH_RESULTS_PER_GROUP)
          .map((member) => ({
            id: member.user.id,
            label: member.user.name,
            meta: {
              type: 'user',
              user: member,
            },
          })),
      },
      taskResults.length > 0 && {
        type: 'task',
        label: 'Tasks',
        entities: taskResults
          .slice(0, MAX_SEARCH_RESULTS_PER_GROUP)
          .map((result) => ({
            id: result.id,
            label: stripEmTags(result.name),
            meta: {
              type: 'task',
              task: result,
            },
          })),
      },
      projectResults.length > 0 && {
        type: 'project',
        label: 'Projects',
        entities: projectResults
          .slice(0, MAX_SEARCH_RESULTS_PER_GROUP)
          .map((result) => ({
            id: result.id,
            label: stripEmTags(result.name),
            meta: {
              type: 'project',
              project: result,
            },
          })),
      },
      noteResults.length > 0 && {
        type: 'note',
        label: 'Docs',
        entities: noteResults
          .slice(0, MAX_SEARCH_RESULTS_PER_GROUP)
          .map((note) => ({
            id: note.id,
            label: stripEmTags(note.name || DEFAULT_DOC_TITLE),
            meta: {
              type: 'note',
              note,
            },
          })),
      },
      query.length > 0 &&
        isLoadingSearchResults && {
          entities: [createLoadingOption()],
        },
    ].filter(Boolean)

    // Add no results placeholder if not loading and there are no matches
    if (options.length === 0 && !isLoadingSearchResults && query.length > 0) {
      return [
        {
          entities: [createNoResultsOption()],
        },
      ]
    }

    return options
  }, [enabled, isLoadingSearchResults, query.length, results, userResults])

  return {
    items,
  }
}

export function createLoadingOption() {
  return {
    id: 'loading-placeholder',
    label: 'Loading...',
    meta: { type: 'placeholder', showLoader: true },
  }
}

export function createNoResultsOption() {
  return {
    id: 'no-results-placeholder',
    label: 'No results found',
    meta: { type: 'placeholder', showLoader: false },
  }
}
