import { useEffect } from 'react'

import {
  handleStageExpectedDurationUpdate,
  handleTaskDurationUpdate,
} from './changed-fields'
import { useFlowTemplateForm } from './use-flow-template-form'

export const FlowFormValuesUpdater = () => {
  const { form } = useFlowTemplateForm()

  // Listen for the form and apply any derived values needed
  useEffect(() => {
    const subscription = form.watch((_, { name }) => {
      if (name == null) return

      if (name.includes('duration')) {
        // Don't run for task relative intervals
        if (name.includes('RelativeInterval')) {
          return
        }

        if (name.includes('tasks')) {
          // Set the minimum duration to the default chunk duration when the duration changes
          handleTaskDurationUpdate(
            form,
            name as `stages.${number}.tasks.${number}.duration`
          )
        } else {
          // This is a stage's expected duration
          handleStageExpectedDurationUpdate(
            form,
            name as `stages.${number}.duration`
          )
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [form])

  return null
}
