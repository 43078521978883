import {
  MentionComponentBase,
  MentionComponentLink,
} from '~/areas/notes/components'
import { UserBadge } from '~/global/components/badges'
import { useUser } from '~/global/hooks'

type ConnectedUserMentionProps = {
  id: string
}

export const ConnectedUserMention = (props: ConnectedUserMentionProps) => {
  const user = useUser(props.id)

  return (
    <MentionComponentLink>
      <MentionComponentBase
        badge={<UserBadge value={user} />}
        label={user?.name ?? 'Unassigned'}
      />
    </MentionComponentLink>
  )
}
