import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { createUnknownUser } from '@motion/rpc-cache'
import { createUnassignedUser } from '@motion/ui-logic/pm/data'
import { type UserInfoSchema } from '@motion/zod/client'

import { AppWorkspaceContext } from '~/global/contexts'

export const useUser = (id: UserInfoSchema['id'] | null | undefined) => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    if (id == null) return createUnassignedUser()
    return state.users.byId[id] ?? createUnknownUser(id)
  })
}
