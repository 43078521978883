import { showToast } from '@motion/ui/base'
import {
  getUpdatedStages,
  type StageWithDates,
} from '@motion/ui-logic/pm/project'
import { errorInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'
import { type ProjectSchema, type StageSchema } from '@motion/zod/client'

import { useQueryClient } from '@tanstack/react-query'
import { useIsFlowProject } from '~/areas/flows'
import { applyOptimisticProjectUpdates } from '~/global/cache'
import { useWorkspaceFns } from '~/global/hooks'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { useProjectUpdater } from '../../updaters/use-project-updater'

type AdjustProjectDatesArgs = {
  startDate?: string
  dueDate?: string
}

// Given either/both a start date or due date, prompt the user with the stages that will be affected
export const useAdjustProjectDates = () => {
  const updateProject = useProjectUpdater()
  const { getWorkspaceProjectById } = useWorkspaceFns()
  const queryClient = useQueryClient()
  const isFlowProject = useIsFlowProject()

  const updateProjectDates = useCallback(
    async (
      project: ProjectSchema,
      {
        startDate,
        dueDate,
        stages,
      }: AdjustProjectDatesArgs & {
        stages: StageSchema[]
      }
    ) => {
      let updates = {
        ...(startDate ? { startDate } : {}),
        ...(dueDate ? { dueDate } : {}),
      }

      /**
       * Optimistically update cache with new data (including stages)
       */
      const cacheUpdates = await applyOptimisticProjectUpdates(
        queryClient,
        project.id,
        {
          ...project,
          ...updates,
          stages,
        }
      )

      try {
        await updateProject(project, updates)
      } catch (e) {
        cacheUpdates?.rollback()
      }
    },
    [queryClient, updateProject]
  )

  return useCallback(
    async (
      id: ProjectSchema['id'],
      { startDate, dueDate }: AdjustProjectDatesArgs
    ) => {
      const project = getWorkspaceProjectById(id)
      if (!project) return false

      let stagesWithDates: StageWithDates[] = []

      if (isFlowProject(project)) {
        try {
          const { stagesWithDates: adjustedStagesWithDates } = getUpdatedStages(
            {
              project,
              startDate,
              dueDate,
            }
          )
          stagesWithDates = adjustedStagesWithDates
        } catch (e) {
          Sentry.captureException(e)
          errorInDev(e)
          showToast('error', 'There was an error updating the stage dates')
          return false
        }
      }

      // Flow projects currently requires to be ISO Date only
      const finalDueDate =
        dueDate != null && isFlowProject(project)
          ? DateTime.fromISO(dueDate).toISODate()
          : dueDate

      const updatedProjectStages = project.stages.map((stage) => ({
        ...stage,
        dueDate:
          stagesWithDates.find(
            (stageWithDates) =>
              stageWithDates.stage.stageDefinitionId === stage.stageDefinitionId
          )?.due ?? stage.dueDate,
      }))

      updateProjectDates(project, {
        startDate,
        dueDate: finalDueDate,
        stages: updatedProjectStages,
      })

      return true
    },
    [getWorkspaceProjectById, isFlowProject, updateProjectDates]
  )
}
