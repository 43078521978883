import { classed } from '@motion/theme'
import { useMergedRefs } from '@motion/ui/utils'

import {
  type ComponentProps,
  type ComponentRef,
  forwardRef,
  useRef,
  useState,
} from 'react'

export const CellText = classed('div', {
  base: `
    px-3 w-full h-full
    flex items-center min-w-0
    overflow-hidden
  `,
  variants: {
    isFake: {
      true: `opacity-50 cursor-not-allowed`,
    },
    isUnvisitedStage: {
      true: `text-semantic-neutral-text-subtle`,
    },
    withHover: {
      true: ``,
    },
  },
  compoundVariants: [
    {
      isFake: false,
      withHover: true,
      className: `
        [[data-selected="false"]_&]:hover:bg-pivot-table-cell-bg-hover
        [[data-selected="true"]_&]:hover:bg-semantic-primary-bg-hover
      `,
    },
  ],
  defaultVariants: {
    isFake: false,
  },
})

export const CellAction = forwardRef<
  ComponentRef<typeof CellActionButton>,
  ComponentProps<typeof CellActionButton>
>(function CellAction({ onClick, ...rest }, ref) {
  const [triggered, setTriggered] = useState(false)

  const buttonRef = useRef<ComponentRef<typeof CellActionButton> | undefined>(
    undefined
  )
  const mergedRefs = useMergedRefs(ref, buttonRef)

  return (
    <CellActionButton
      ref={mergedRefs}
      {...rest}
      onClick={(e) => {
        setTriggered(true)
        onClick?.(e)
      }}
      onFocus={() => {
        if (triggered) {
          setTriggered(false)
          // Give focus to the cell when the button gets the focus back after closing the popover
          buttonRef.current?.parentElement?.focus()
        }
      }}
    />
  )
})

const CellActionButton = classed('button', {
  base: `w-full h-full flex items-center overflow-hidden`,
  variants: {
    isFake: {
      true: `opacity-50`,
    },
    unstyled: {
      false: `
        px-3

        [[data-selected="false"]_&]:enabled:hover:bg-pivot-table-cell-bg-hover
        [[data-selected="true"]_&]:enabled:hover:bg-semantic-primary-bg-hover

        disabled:cursor-not-allowed
      `,
    },
  },
  defaultVariants: {
    isFake: false,
    unstyled: false,
  },
})
