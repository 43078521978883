import { templateStr } from '@motion/react-core/strings'
import { showToast } from '@motion/ui/base'
import { getTaskDeadlineDateChangedFields } from '@motion/ui-logic/pm/task'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useTaskUpdater } from '~/areas/tasks/hooks'
import { useI18N } from '~/global/contexts'
import { showErrorToast } from '~/global/toasts'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { type ActionArgs } from './types'

const FRIDAY_DAY = 5

// For each given task, update the scheduled date to the current date
export const useUpdateTasksToScheduledDateOrWeek = () => {
  const updateTask = useTaskUpdater()
  const { pluralize } = useI18N()

  return useCallback(
    async (args: ActionArgs & { updateType?: 'DATE' | 'WEEK' }) => {
      const { tasks, setIsLoading, updateType, type } = args

      if (updateType === 'WEEK') {
        recordAnalyticsEvent('PAST_DUE_MODAL_ACTION_EXTEND_TO_SCHEDULED_WEEK', {
          type,
          numTasks: tasks.length,
        })
      } else {
        recordAnalyticsEvent('PAST_DUE_MODAL_ACTION_EXTEND_TO_SCHEDULED_DAY', {
          type,
          numTasks: tasks.length,
        })
      }

      setIsLoading(true)
      const updatedTaskCalls = await Promise.allSettled(
        tasks.map((task) => {
          try {
            if (task.type === 'CHUNK') {
              throw new Error('Cannot update the deadline of a Chunk task')
            }

            if (!task.estimatedCompletionTime) {
              throw new Error('Task does not have a scheduled start date')
            }

            const estimatedScheduledStart = DateTime.fromISO(
              task.estimatedCompletionTime || ''
            )

            let newDeadline = estimatedScheduledStart.endOf('day').toISO()

            if (updateType === 'WEEK') {
              newDeadline = estimatedScheduledStart
                .set({ weekday: FRIDAY_DAY })
                .endOf('day')
                .toISO()
            }

            return updateTask(task, {
              dueDate: newDeadline,
              ...getTaskDeadlineDateChangedFields({
                ...task,
                dueDate: newDeadline,
              }),
            })
          } catch (error) {
            return Promise.reject(error)
          }
        })
      )

      const tasksUnableToUpdate = updatedTaskCalls.filter(
        (result) => result.status === 'rejected'
      )

      if (tasksUnableToUpdate.length) {
        showErrorToast(
          templateStr('{{numTasks}} {{tasks}} could not be updated', {
            numTasks: tasksUnableToUpdate.length,
            tasks: pluralize(tasksUnableToUpdate.length, 'task', 'tasks'),
          })
        )
      } else {
        showToast(
          'success',
          templateStr('{{numTasks}} {{tasks}} updated to {{updateType}}', {
            numTasks: tasks.length,
            tasks: pluralize(tasks.length, 'task', 'tasks'),
            updateType:
              updateType === 'WEEK' ? 'end of scheduled week' : 'scheduled day',
          })
        )
      }

      setIsLoading(false)
    },
    [pluralize, updateTask]
  )
}
