import type { TemplateProjectType } from '@motion/rpc/types'
import { Button } from '@motion/ui/base'
import { filterAndRankMatches } from '@motion/ui-logic'
import type { ProjectDefinitionSchema } from '@motion/zod/client'

import { useSelectProjectTemplate } from '~/areas/project/hooks'
import { useEmptyResultsIcon, useWorkspaceFns } from '~/global/hooks'
import { useMemo } from 'react'

import { TemplateSearchRow } from './template-search-row'

import { useSetupProjectModalUrl } from '../../setup-project-modal'

type TemplateSearchResultsProps = {
  search: string
  clearSearch: () => void
  legacyTemplates: TemplateProjectType[]
  flowTemplates: ProjectDefinitionSchema[]
}

export function TemplateSearchResults({
  search,
  clearSearch,
  legacyTemplates,
  flowTemplates,
}: TemplateSearchResultsProps) {
  const buildSetupProjectModalUrl = useSetupProjectModalUrl()
  const { getProjectDefinitionWithStages } = useWorkspaceFns()

  const Illustration = useEmptyResultsIcon()

  const allItems = useMemo(() => {
    return [...flowTemplates, ...legacyTemplates]
  }, [legacyTemplates, flowTemplates])

  const filteredTemplates = useMemo(() => {
    if (!search) return allItems
    return filterAndRankMatches(search, allItems, (item) => item.name)
  }, [search, allItems])

  const selectTemplate = useSelectProjectTemplate({
    skipConfirm: true,
  })

  if (filteredTemplates.length === 0) {
    return (
      <div className='h-full flex flex-col items-center justify-center gap-4'>
        <Illustration className='w-32 h-32' />
        <p className='text-base font-semibold text-semantic-neutral-text-default'>
          No templates found
        </p>
        <Button variant='outlined' sentiment='neutral' onClick={clearSearch}>
          Clear search
        </Button>
      </div>
    )
  }

  return (
    <div className='flex flex-col overflow-auto'>
      <ul className='flex flex-col p-4'>
        {filteredTemplates.map((template) => {
          const isFlowTemplate = 'stages' in template
          return (
            <li
              key={template.id}
              className='border-b border-b-semantic-neutral-border-default last:border-b-0'
            >
              <TemplateSearchRow
                workspaceId={template.workspaceId}
                label={template.name}
                stages={
                  isFlowTemplate
                    ? getProjectDefinitionWithStages(template.id)?.stages
                    : undefined
                }
                url={
                  isFlowTemplate
                    ? buildSetupProjectModalUrl({
                        flowTemplateId: template.id,
                      })
                    : undefined
                }
                onClick={
                  isFlowTemplate ? undefined : () => selectTemplate(template)
                }
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}
