import { templateStr } from '@motion/react-core/strings'
import {
  getAutoScheduleWindowLimits,
  getTierTitle,
  type Tier,
} from '@motion/ui-logic/tiered-pricing'
import { useModalApi } from '@motion/web-common/modals'

import { useAutoScheduleRange } from '~/areas/project-management/hooks/experiments'
import { formatAutoScheduleDuration } from '~/areas/tasks/components/tooltips/utils'
import { type ReactNode } from 'react'

import { getNextTier } from '../utils/utils'

type AutoScheduleUpsellMessageProps = {
  currentTier: Tier
  linkToUpgradeModal?: boolean
}

export const AutoScheduleUpsellMessage = ({
  currentTier,
  linkToUpgradeModal = false,
}: AutoScheduleUpsellMessageProps): ReactNode => {
  const currentAutoScheduleRange = useAutoScheduleRange()
  let nextTier = getNextTier(currentTier)

  if (!nextTier) {
    return null
  }

  let upgradedAutoScheduleWindow = getAutoScheduleWindowLimits(nextTier)

  if (currentAutoScheduleRange === upgradedAutoScheduleWindow) {
    nextTier = getNextTier(nextTier)
    if (!nextTier) {
      return null
    }
    upgradedAutoScheduleWindow = getAutoScheduleWindowLimits(nextTier)
  }

  let upgradedAutoScheduleWindowString = formatAutoScheduleDuration(
    upgradedAutoScheduleWindow
  )

  return templateStr(
    '{{cta}} to extend auto-schedule period to {{upgradedAutoScheduleWindowString}}.',
    {
      cta: linkToUpgradeModal ? (
        <InlineUpgradeLink tier={nextTier} />
      ) : (
        'Upgrade to Motion ' + getTierTitle(nextTier)
      ),
      upgradedAutoScheduleWindowString,
    }
  )
}

const InlineUpgradeLink = ({ tier }: { tier: Exclude<Tier, 'BASIC'> }) => {
  const modalApi = useModalApi()
  return (
    <a
      tabIndex={0}
      className='underline text-semantic-primary-text-default'
      onClick={() => {
        modalApi.open('tier-upgrade-prompt-modal', {
          tier,
          feature: 'autoScheduleWindow',
        })
      }}
    >
      Upgrade to Motion {getTierTitle(tier)}
    </a>
  )
}
