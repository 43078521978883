import React, { type DetailedHTMLProps, type InputHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  inputContainerClasses,
  inputDisabledClasses,
  inputErrorClasses,
  inputFocusClasses,
  inputIconColorClasses,
  inputIconSizeClasses,
  inputMutedContainerClasses,
  inputPlaceholderClasses,
  inputTextClasses,
} from '../../GeneralComponentStyles'

export interface InputProps {
  containerClassName?: string
  inputClassName?: string
  iconClassName?: string
  icon?: React.ElementType
  iconAlign?: 'left' | 'right'
  error?: boolean
  muted?: boolean
}

/**
 * @deprecated Use `Textfield` from Motion UI
 */
export const Input = React.forwardRef(function Input(
  props: InputProps &
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  ref: React.Ref<HTMLInputElement>
) {
  const {
    containerClassName = '',
    inputClassName = '',
    iconClassName,
    icon,
    iconAlign = 'left',
    disabled,
    value,
    defaultValue,
    error = false,
    muted = false,
    ...theirProps
  } = props
  const Icon = icon

  return (
    <div
      className={twMerge(
        'relative flex h-[32px] w-full items-center rounded py-1.5 px-0',
        'flex items-center rounded border',
        muted ? inputMutedContainerClasses : inputContainerClasses,
        disabled ? inputDisabledClasses : inputFocusClasses,
        error && inputErrorClasses,
        containerClassName
      )}
    >
      {Icon && iconAlign === 'left' && (
        <Icon
          className={twMerge(
            'absolute mx-2 flex',
            inputIconColorClasses,
            inputIconSizeClasses
          )}
        />
      )}
      <input
        {...theirProps}
        className={twMerge(
          'absolute !m-0 h-full w-full rounded border-none border-transparent bg-transparent p-2 focus-visible:outline-none',
          inputTextClasses,
          inputPlaceholderClasses,
          Icon && iconAlign === 'left' && '!pl-7',
          Icon && iconAlign === 'right' && 'w-[calc(100%-32px)]',
          disabled && inputDisabledClasses,
          inputClassName
        )}
        ref={ref}
        disabled={disabled}
        value={value}
      />
      {Icon && iconAlign === 'right' && (
        <Icon
          className={twMerge(
            'absolute right-4 flex',
            inputIconColorClasses,
            inputIconSizeClasses
          )}
        />
      )}
    </div>
  )
})
