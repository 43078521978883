import {
  ArrowDownSolid,
  ArrowUpSolid,
  DotsHorizontalSolid,
  DownloadSolid,
  TrashSolid,
} from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { ActionList, Button, PopoverTrigger } from '@motion/ui/base'
import {
  findStageForTask,
  type FlowTemplateFormTask,
} from '@motion/ui-logic/pm/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { StageLabel } from '~/global/components/labels'

import { useHandleMoveTask } from '../hooks'

type StageTaskOptionMenuProps = {
  task: FlowTemplateFormTask
  onRemove?: () => void
}

export function StageTaskOptionMenu({
  onRemove,
  task,
}: StageTaskOptionMenuProps) {
  const handleMoveTask = useHandleMoveTask()
  const modalApi = useModalApi()

  const {
    form: { watch },
  } = useFlowTemplateForm()

  const stages = watch('stages')

  const stageForTask = findStageForTask(stages, task.id)

  if (stageForTask == null) {
    return null
  }

  const handleDeleteTask = async () => {
    const response = await modalApi.prompt('confirm', {
      analytics: {
        name: 'delete-template-task',
      },
      title: 'Are you sure you want to delete this task?',
      description: templateStr(
        'This task will be deleted from all projects where the {{stage}} stage hasn’t started yet.',
        {
          stage: (
            <div className='align-middle inline-block'>
              <StageLabel value={stageForTask} />
            </div>
          ),
        }
      ),
      confirmButtonText: 'Delete task',
      destructive: true,
    })

    if (response !== ModalDismissed) {
      recordAnalyticsEvent('FLOW_TEMPLATE_TASK_MODIFIED', {
        type: 'delete',
      })

      onRemove?.()
    }
  }

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <ActionList
          sections={[
            {
              items: [
                {
                  content: 'Move to top',
                  prefix: <DownloadSolid className='rotate-180' />,
                  onAction: async () => {
                    handleMoveTask('top', task)
                    recordAnalyticsEvent('FLOW_TEMPLATE_TASK_MODIFIED', {
                      type: 'move',
                      method: 'select',
                    })
                    close()
                  },
                },
                {
                  content: 'Move up',
                  prefix: <ArrowUpSolid />,
                  onAction: async () => {
                    handleMoveTask('up', task)
                    recordAnalyticsEvent('FLOW_TEMPLATE_TASK_MODIFIED', {
                      type: 'move',
                      method: 'select',
                    })
                    close()
                  },
                },
                {
                  content: 'Move down',
                  prefix: <ArrowDownSolid />,
                  onAction: async () => {
                    handleMoveTask('down', task)
                    recordAnalyticsEvent('FLOW_TEMPLATE_TASK_MODIFIED', {
                      type: 'move',
                      method: 'select',
                    })
                    close()
                  },
                },
                {
                  content: 'Move to bottom',
                  prefix: <DownloadSolid />,
                  onAction: async () => {
                    handleMoveTask('bottom', task)
                    recordAnalyticsEvent('FLOW_TEMPLATE_TASK_MODIFIED', {
                      type: 'move',
                      method: 'select',
                    })
                    close()
                  },
                },
              ],
            },
            {
              items: [
                {
                  prefix: <TrashSolid />,
                  content: 'Delete',
                  destructive: true,
                  onAction: handleDeleteTask,
                },
              ],
            },
          ]}
        />
      )}
    >
      <Button size='xsmall' variant='muted' sentiment='neutral' iconOnly>
        <div className='p-0.5'>
          <DotsHorizontalSolid />
        </div>
      </Button>
    </PopoverTrigger>
  )
}
