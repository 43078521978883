import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'
import { useMemo } from 'react'

import { useCustomFieldBaseDefinitions } from './custom-fields'
import { PROJECT_FIELD_LIST } from './project-fields'
import { TASK_FIELD_LIST } from './task-fields'
import { type FieldDefinition } from './types'

export const useTaskFields = (): FieldDefinition<TaskWithRelations>[] => {
  const customFieldDefinitions = useCustomFieldBaseDefinitions()

  return useMemo(() => {
    return [...TASK_FIELD_LIST, ...customFieldDefinitions]
  }, [customFieldDefinitions])
}

export const useProjectFields = (): FieldDefinition<ProjectWithRelations>[] => {
  const customFieldDefinitions = useCustomFieldBaseDefinitions()

  return useMemo(() => {
    return [...PROJECT_FIELD_LIST, ...customFieldDefinitions]
  }, [customFieldDefinitions])
}
