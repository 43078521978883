import { makeTemplateString } from '@motion/react-core/strings'
import { type CalendarEventSchema } from '@motion/rpc-types'

import { Highlight } from './components/highlight'

export type CalendarEventSearchSchema = {
  entityType: 'event'
} & CalendarEventSchema

type TaskSearchType = {
  type: 'tasks'
  archived: boolean
  showCompleted: boolean
}

type ProjectsSearchType = {
  type: 'projects'
}

type EventSearchType = {
  type: 'events'
}

type AttachmentSearchType = {
  type: 'attachments'
}

type NoteSearchType = {
  type: 'notes'
}

export type SearchType =
  | TaskSearchType
  | ProjectsSearchType
  | EventSearchType
  | AttachmentSearchType
  | NoteSearchType

export function isSearchingAll(st: SearchType[]) {
  return (
    isSearchingTasks(st) &&
    isSearchingProjects(st) &&
    isSearchingNotes(st) &&
    isSearchingAttachments(st)
  )
}

export function isSearchingTasks(st: SearchType[]) {
  return st.some(({ type }) => type === 'tasks')
}

export function isSearchingArchivedTasks(st: SearchType[]) {
  const taskSearchType = st.find(({ type }) => type === 'tasks') as
    | TaskSearchType
    | undefined
  return Boolean(taskSearchType?.archived)
}

export function isSearchingCompletedTasks(st: SearchType[]) {
  const taskSearchType = st.find(({ type }) => type === 'tasks') as
    | TaskSearchType
    | undefined
  return Boolean(taskSearchType?.showCompleted)
}

export function isSearchingProjects(st: SearchType[]) {
  return st.some(({ type }) => type === 'projects')
}

export function isSearchingEvents(st: SearchType[]) {
  return st.some(({ type }) => type === 'events')
}

export function isSearchingAttachments(st: SearchType[]) {
  return st.some(({ type }) => type === 'attachments')
}

export function isSearchingNotes(st: SearchType[]) {
  return st.some(({ type }) => type === 'notes')
}

export function isValidSearch(searchQuery: string) {
  return searchQuery.length > 1
}

export const DATA_SELECTED_ATTR = 'data-selected'

const EM_TAGS_RE = /<em>(.*?)<\/em>/g

const templateHighlight = makeTemplateString(EM_TAGS_RE)

export function highlightEmText(value: string) {
  return templateHighlight(value, (word) => <Highlight>{word}</Highlight>)
}

export function stripEmTags(value: string) {
  return templateHighlight(value, (word) => word)
}

export function getSearchableEntitiesStr({
  hasNotes,
  disableSearchTypeEvents,
  isMobile,
}: {
  hasNotes: boolean
  disableSearchTypeEvents: boolean
  isMobile: boolean
}) {
  let entities = ['tasks', 'projects', 'docs', 'attachments', 'events']
    .filter((type) => type !== 'docs' || hasNotes)
    .filter((type) => type !== 'events' || !disableSearchTypeEvents)

  if (isMobile) {
    entities = entities.slice(0, entities.length - 2)
    entities.push('more')
  }

  return entities
    .map((type, index, types) => [
      type,
      index === types.length - 2
        ? isMobile
          ? ' and '
          : ' or '
        : index < types.length - 1
          ? ', '
          : '',
    ])
    .flat()
    .join('')
}
