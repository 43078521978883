import { isNoneId } from '@motion/shared/identifiers'
import { ConditionalWrapper } from '@motion/ui/utils'
import { DEFAULT_DOC_TITLE } from '@motion/ui-logic'
import { type NoteSchema } from '@motion/zod/client'

import { type BadgeSize } from '~/global/components/badges'
import { useNoteUrl } from '~/global/navigation'
import { useNoteById } from '~/global/rpc/v2'

import { NoteBadge } from '../badges/note'
import { MotionLink } from '../motion-link'

export type NoteLabelProps = {
  value: NoteSchema | null | undefined
  size?: BadgeSize
  nameOnly?: boolean
  /**
   * If true, the label will be clickable and will redirect to the note
   */
  clickable?: boolean
}

export const NoteLabel = ({
  size,
  value,
  nameOnly = false,
  clickable = false,
}: NoteLabelProps) => {
  const getNoteUrl = useNoteUrl()
  const name = value == null ? 'No doc' : value.title || DEFAULT_DOC_TITLE

  const { noteUri } = getNoteUrl({ noteId: value?.id ?? '' })

  const label = (
    <span title={name} className='truncate'>
      {name}
    </span>
  )

  return (
    <ConditionalWrapper
      condition={clickable && value != null && !isNoneId(value.id)}
      wrapper={(children) => (
        <MotionLink url={noteUri} className='overflow-hidden'>
          {children}
        </MotionLink>
      )}
    >
      {nameOnly ? (
        label
      ) : (
        <div className='flex gap-1 items-center overflow-hidden'>
          <NoteBadge value={value} size={size} hideTooltip />
          {label}
        </div>
      )}
    </ConditionalWrapper>
  )
}

export type ConnectedNoteLabelProps = Omit<NoteLabelProps, 'value'> & {
  id: NoteSchema['id'] | undefined
}

export function ConnectedNoteLabel({ id, ...rest }: ConnectedNoteLabelProps) {
  const { data: note } = useNoteById({ id })

  return <NoteLabel value={note} {...rest} />
}
