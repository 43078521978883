import { LoadingSolid, TrashSolid } from '@motion/icons'
import {
  useDebouncedCallback,
  useDependantState,
} from '@motion/react-core/hooks'
import {
  type EventConferenceType,
  type ZoomLinkType,
} from '@motion/shared/common'
import { Button, IconButton, Text } from '@motion/ui/base'
import { Select, TextField } from '@motion/ui/forms'
import {
  getConferenceDataForType,
  getValidConferenceTypeForProvider,
  getValidConferenceTypesForProvider,
} from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { HasExperiment } from '@motion/web-common/flags'

import { useInNoExternalCalendarsMode } from '~/areas/calendar/hooks'
import { NotetakerConferenceSettings } from '~/pages/settings/pages/conference'
import { SettingPageWrapper } from '~/pages/settings/shared'
import { timeStringToMin } from '~/utils/time'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { useConferenceSettingsApi } from '../../../../areas/settings/state'
import api from '../../../../chromeApi/chromeApiContentScript'
import {
  selectCalendarsLoadingState,
  selectMainEmailAccount,
} from '../../../../state/email-accounts/email-accounts-slice'
import { useAppSelector } from '../../../../state/hooks'
import {
  reminderDurationChoices,
  reminderWarningChoices,
  ZOOM_LINK_REGEX,
} from '../../../../storageConstants'
import { openZoomAuthWindow } from '../../../../utils/auth'
import { isZoomReady } from '../../../../utils/booking'
import { inputContainerClasses } from '../../../Common/GeneralComponentStyles'
import { LinkCalendarsCTA } from '../../Components/link-calendars-cta'

type MaybeMessage = {
  success: boolean
  text: string
} | null

const formatLink = (link: string) => {
  if (link && !/^https?:\/\//.test(link)) {
    return 'https://' + link
  }
  return link
}

export const ConferenceSettings = () => {
  const {
    deleteZoomAccount,
    refreshZoomUserData,
    selectConferenceSettings,
    selectReminderSettings,
    setConferenceSettings,
    setReminderSettings,
  } = useConferenceSettingsApi()

  const conferenceSettings = selectConferenceSettings()
  const reminderSettings = selectReminderSettings()
  const { noExternalCalendarsMode } = useInNoExternalCalendarsMode()
  const emailAccountsLoadingState = useAppSelector(selectCalendarsLoadingState)

  const [zoomManualLinkInput, setZoomManualLinkInput] = useState(
    conferenceSettings?.zoomManualLink ?? ''
  )
  const [zoomManualLinkMessage, setZoomManualLinkMessage] =
    useState<MaybeMessage>(null)
  const [phoneNumberInput, setPhoneNumberInput] = useDependantState(
    () => conferenceSettings?.phoneNumber ?? '',
    [conferenceSettings?.phoneNumber]
  )
  const [phoneNumberMessage, setPhoneNumberMessage] =
    useState<MaybeMessage>(null)
  const [customLocationInput, setCustomLocationInput] = useDependantState(
    () => conferenceSettings?.customLocation ?? '',
    [conferenceSettings?.customLocation]
  )
  const [customLocationMessage, setCustomLocationMessage] =
    useState<MaybeMessage>(null)
  const [authlessZoom, setAuthlessZoom] = useState(false)
  const [reminderDuration, setReminderDuration] = useDependantState(
    () => `${Math.floor(reminderSettings.duration / 60)} min`,
    [reminderSettings.duration]
  )
  const [reminderWarning, setReminderWarning] = useDependantState(
    () =>
      reminderSettings.warning < 60
        ? `${reminderSettings.warning} sec`
        : `${Math.floor(reminderSettings.warning / 60)} min`,
    [reminderSettings.warning]
  )

  useEffect(
    function fetchConferenceSettings() {
      const getInitialData = async () => {
        const { zoomNeedsRefresh } = await api.storage.local.get([
          'zoomNeedsRefresh',
        ])
        setAuthlessZoom(zoomNeedsRefresh)

        if (conferenceSettings?.zoomAccount) {
          // Refresh zoom user data in order to update the personalMeetingLink
          void refreshZoomUserData()
        }
      }
      void getInitialData()
      const storageListener = (changes: any) => {
        if ('zoomNeedsRefresh' in changes) {
          setAuthlessZoom(changes.zoomNeedsRefresh.newValue)
        }
      }
      api.storage.onChanged.addListener(storageListener)
      return () => api.storage.onChanged.removeListener(storageListener)
    },
    [conferenceSettings?.zoomAccount, refreshZoomUserData]
  )

  const onConferenceTypeChange = useCallback(
    async (type: EventConferenceType) => {
      await setConferenceSettings({ conferenceType: type })
      const { zoomNeedsRefresh } = await api.storage.local.get([
        'zoomNeedsRefresh',
      ])
      if (
        type === 'zoom' &&
        !isZoomReady(conferenceSettings, zoomNeedsRefresh)
      ) {
        // zoom link without auth or manual link
        setZoomManualLinkMessage({
          success: false,
          text: 'Please paste your Zoom link or connect your account.',
        })
      } else {
        setZoomManualLinkMessage(null)
      }
      recordAnalyticsEvent('CALENDAR_SETTINGS_CONFERENCE_CHANGE')
    },
    [conferenceSettings, setConferenceSettings]
  )

  const debounceCustomLocation = useDebouncedCallback(async () => {
    if (customLocationInput) {
      await setConferenceSettings({ customLocation: customLocationInput })
      setCustomLocationMessage({
        success: true,
        text: 'Your custom location was saved!',
      })
    } else {
      setCustomLocationMessage({
        success: false,
        text: 'No custom location entered.',
      })
    }
    setTimeout(() => setCustomLocationMessage(null), 5000)
  }, 1000)

  const customLocationHandler = useCallback(
    (value: string) => {
      setCustomLocationInput(value)
      setPhoneNumberMessage(null)
      debounceCustomLocation()
    },
    [debounceCustomLocation, setCustomLocationInput]
  )

  const onZoomLinkTypeChange = useCallback(
    async (linkType: ZoomLinkType) => {
      await setConferenceSettings({ zoomLinkType: linkType })
      recordAnalyticsEvent('CALENDAR_SETTINGS_ZOOM_TYPE_CHANGE')
    },
    [setConferenceSettings]
  )

  const onZoomEmailRemove = useCallback(async () => {
    const res = await deleteZoomAccount()
    void setConferenceSettings({ ...res.conferenceSettings })
    recordAnalyticsEvent('CALENDAR_SETTINGS_ZOOM_CLEAR')
  }, [deleteZoomAccount, setConferenceSettings])

  const openZoomAuth = useCallback(async () => {
    void openZoomAuthWindow()
    recordAnalyticsEvent('CALENDAR_SETTINGS_ZOOM_CONNECT')
  }, [])

  const retrieveZoomLink = useCallback((text: string) => {
    const zoomLinks = formatLink(text).match(ZOOM_LINK_REGEX)
    if (zoomLinks) {
      return zoomLinks[0]
    }
    return null
  }, [])

  const debounceZoomManualLink = useDebouncedCallback(async () => {
    const zoomLink = retrieveZoomLink(zoomManualLinkInput)
    if (zoomLink) {
      await setConferenceSettings({ zoomManualLink: zoomLink })

      setZoomManualLinkMessage({
        success: true,
        text: 'Your meeting link was saved!',
      })
    } else {
      setZoomManualLinkMessage({
        success: false,
        text: 'Please enter a valid Zoom personal meeting link.',
      })
    }
    setTimeout(() => setZoomManualLinkMessage(null), 5000)
  }, 1000)

  const zoomManualLinkHandler = useCallback(
    (value: string) => {
      setZoomManualLinkInput(value)
      setZoomManualLinkMessage(null)
      debounceZoomManualLink()
    },
    [debounceZoomManualLink]
  )

  const debouncePhoneNumber = useDebouncedCallback(async () => {
    if (phoneNumberInput) {
      await setConferenceSettings({ phoneNumber: phoneNumberInput })
      setPhoneNumberMessage({
        success: true,
        text: 'Your phone number was saved!',
      })
    } else {
      setPhoneNumberMessage({
        success: false,
        text: 'No phone number entered.',
      })
    }
    setTimeout(() => setPhoneNumberMessage(null), 5000)
  }, 1000)

  const phoneNumberHandler = useCallback(
    (value: string) => {
      setPhoneNumberInput(value)
      setPhoneNumberMessage(null)
      debouncePhoneNumber()
    },
    [debouncePhoneNumber, setPhoneNumberInput]
  )

  const updateReminderSettings = useCallback(async () => {
    const warningArr = reminderWarning.split(' ')
    let warningTime = 15
    if (warningArr[1] === 'sec') {
      warningTime = parseInt(warningArr[0])
    } else if (warningArr[1] === 'min') {
      warningTime = 60 * parseInt(warningArr[0])
    }

    const durationTime = 60 * timeStringToMin(reminderDuration)
    await setReminderSettings({
      duration: durationTime,
      warning: warningTime,
    })
  }, [reminderWarning, reminderDuration, setReminderSettings])

  const debounceUpdateReminderSettings = useDebouncedCallback(async () => {
    await updateReminderSettings()
  }, 1000)

  const onReminderWarningChange = useCallback(
    (timeString: string) => {
      setReminderWarning(timeString)
      debounceUpdateReminderSettings()
      recordAnalyticsEvent('CALENDAR_SETTINGS_WARNING_CHANGE')
    },
    [debounceUpdateReminderSettings, setReminderWarning]
  )

  const onReminderDurationChange = useCallback(
    (timeString: string) => {
      setReminderDuration(timeString)
      debounceUpdateReminderSettings()
      void recordAnalyticsEvent('CALENDAR_SETTINGS_DISAPPEAR_CHANGE')
    },
    [debounceUpdateReminderSettings, setReminderDuration]
  )

  const mainEmailAccount = useAppSelector(selectMainEmailAccount)
  const providerType = mainEmailAccount?.providerType ?? 'GOOGLE'

  const conferenceTypes = useMemo(
    () =>
      getValidConferenceTypesForProvider(
        [
          'meet',
          'zoom',
          'teamsForBusiness',
          'phone',
          'customLocation',
        ] as const,
        providerType
      ),
    [providerType]
  )

  const conferenceType = getValidConferenceTypeForProvider(
    conferenceSettings.conferenceType,
    providerType
  )

  if (noExternalCalendarsMode) {
    const isLoading =
      emailAccountsLoadingState === 'preload' ||
      emailAccountsLoadingState === 'loading'
    return (
      <SettingPageWrapper title='Conference Settings' className='gap-12'>
        <div className='flex w-full h-full flex-col gap-3 items-center'>
          {isLoading && (
            <div className='flex w-full h-full flex-col gap-3 items-center'>
              <LoadingSolid className='animate-spin text-semantic-neutral-icon-default' />
            </div>
          )}
          {!isLoading && <LinkCalendarsCTA />}
        </div>
      </SettingPageWrapper>
    )
  }

  return (
    <SettingPageWrapper title='Conference settings' className='gap-8'>
      <div className='flex w-full flex-col gap-2'>
        <Select
          label='Default conference method'
          value={conferenceType}
          onChange={onConferenceTypeChange}
          options={conferenceTypes.map((type) => {
            const data = getConferenceDataForType(type)
            return {
              content: (
                <div className='flex gap-1'>
                  <img
                    className='h-4 w-4'
                    src={data.link}
                    role='presentation'
                  />
                  <span>{data.title}</span>
                </div>
              ),
              value: type,
            }
          })}
        />
      </div>

      <HasExperiment name='notetaker-event-modal'>
        <NotetakerConferenceSettings />
      </HasExperiment>

      <div className='flex w-full flex-col gap-3'>
        <Text weight='semibold'>Zoom</Text>
        <div className='flex w-full flex-col gap-4'>
          <div className='flex w-full flex-col gap-2'>
            <Text sentiment='subtle' size='xs' weight='semibold'>
              Account
            </Text>
            {conferenceSettings?.zoomAccount && (
              <div
                className={twMerge(
                  'flex h-8 w-full items-center justify-between rounded border px-3',
                  inputContainerClasses
                )}
              >
                <Text size='sm'>{conferenceSettings?.zoomAccount}</Text>
                <IconButton
                  variant='muted'
                  sentiment='neutral'
                  icon={TrashSolid}
                  onClick={onZoomEmailRemove}
                />
              </div>
            )}
            {!conferenceSettings?.zoomAccount && (
              <div>
                <Button sentiment='primary' onClick={openZoomAuth}>
                  Connect Zoom
                </Button>
              </div>
            )}
            {conferenceSettings?.zoomAccount && authlessZoom && (
              <div className='flex items-center gap-2'>
                <Text sentiment='error' size='sm'>
                  Your Zoom account was disconnected
                </Text>
                <Button sentiment='primary' size='small' onClick={openZoomAuth}>
                  Reconnect Zoom
                </Button>
              </div>
            )}
          </div>
          <div className='flex w-full flex-col gap-2'>
            <Select
              label='Meeting link'
              value={conferenceSettings?.zoomLinkType}
              onChange={onZoomLinkTypeChange}
              options={[
                {
                  content: 'Use personal Zoom link',
                  value: 'personal',
                },
                {
                  content: 'Auto-generate link',
                  value: 'auto',
                },
                { content: 'Manually enter Zoom link', value: 'manual' },
              ]}
            />
            {conferenceSettings?.zoomLinkType === 'manual' && (
              <TextField
                label='Personal zoom link'
                labelHidden
                placeholder='Paste Zoom personal link'
                value={zoomManualLinkInput}
                onChange={zoomManualLinkHandler}
              />
            )}
            {conferenceSettings?.zoomLinkType === 'manual' &&
              zoomManualLinkMessage && (
                <Text
                  size='xs'
                  sentiment={
                    zoomManualLinkMessage.success ? 'success' : 'error'
                  }
                >
                  {zoomManualLinkMessage.text}
                </Text>
              )}
          </div>
        </div>
      </div>

      <div className='flex w-full flex-col gap-3'>
        <Text weight='semibold'>Phone number</Text>
        <TextField
          label='Phone Number'
          labelHidden
          placeholder='Enter phone number'
          value={phoneNumberInput}
          onChange={phoneNumberHandler}
        />
        {phoneNumberMessage && (
          <Text
            size='xs'
            sentiment={phoneNumberMessage.success ? 'success' : 'error'}
          >
            {phoneNumberMessage.text}
          </Text>
        )}
      </div>
      <div className='flex w-full flex-col gap-3'>
        <Text weight='semibold'>Custom location</Text>
        <TextField
          label='Link or Address'
          labelHidden
          value={customLocationInput}
          onChange={customLocationHandler}
        />
        {customLocationMessage && (
          <Text
            size='xs'
            sentiment={customLocationMessage.success ? 'success' : 'error'}
          >
            {customLocationMessage.text}
          </Text>
        )}
      </div>
      <div className='flex w-full flex-col gap-3'>
        <Text weight='semibold'>Join meeting button</Text>
        <div className='flex w-full gap-4'>
          <div className='flex w-full flex-col gap-2'>
            <Select
              label='Show before event'
              value={reminderWarning}
              onChange={onReminderWarningChange}
              options={reminderWarningChoices.map((time) => ({
                content: time,
                value: time,
              }))}
            />
          </div>
          <div className='flex w-full flex-col gap-2'>
            <Select
              label='Disappear after event'
              value={reminderDuration}
              onChange={onReminderDurationChange}
              options={reminderDurationChoices.map((time) => ({
                content: time,
                value: time,
              }))}
            />
          </div>
        </div>
      </div>
    </SettingPageWrapper>
  )
}
