import { templateStr } from '@motion/react-core/strings'
import { classed } from '@motion/theme'
import { FormModal } from '@motion/ui/base'
import { type FlowTemplateStage } from '@motion/ui-logic/pm/project'
import { useModalStatus } from '@motion/web-common/modals'
import { type TaskDefinitionSchema } from '@motion/zod/client'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { Label, StageLabel } from '~/global/components/labels'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'deprecated-remove-template-blockers': PromptCallbacks<boolean> &
      DeprecatedRemoveBlockersModalProps
  }
}

type DeprecatedRemoveBlockersModalProps = {
  blockedByTask: TaskDefinitionSchema | undefined
  blockingTasks: TaskDefinitionSchema[]
  startStage?: FlowTemplateStage
  endStage?: FlowTemplateStage
}

export const DeprecatedRemoveBlockersModal = (
  props: ModalTriggerComponentProps<'deprecated-remove-template-blockers'>
) => {
  const status = useModalStatus('deprecated-remove-template-blockers')
  if (!status.visible) return null

  const { close, blockedByTask, blockingTasks, startStage, endStage } = props

  const isChangingStage = startStage != null && endStage != null

  const handleSubmit = () => {
    close(true)
  }

  return (
    <FormModal
      onClose={close}
      submitAction={{
        onAction: handleSubmit,
        text: 'Move and remove blockers',
      }}
      title={
        isChangingStage
          ? templateStr('Move task from {{startStage}} to {{endStage}}', {
              startStage: <StageLabel value={startStage} />,
              endStage: <StageLabel value={endStage} />,
            })
          : 'Move task'
      }
      visible
    >
      {isChangingStage ? (
        <DeprecatedMoveBetweenStages
          startStage={startStage}
          endStage={endStage}
        />
      ) : (
        <DeprecatedMoveWithinStage
          blockedByTask={blockedByTask}
          blockingTasks={blockingTasks}
        />
      )}
    </FormModal>
  )
}

const BodyText = classed('div', {
  base: 'flex h-full w-[450px] flex-col gap-4 text-semantic-neutral-text-default',
})

function DeprecatedMoveWithinStage({
  blockedByTask,
  blockingTasks,
}: {
  blockedByTask: TaskDefinitionSchema | undefined
  blockingTasks: TaskDefinitionSchema[]
}) {
  const text =
    blockedByTask != null
      ? `To move this task, the blocker must be removed`
      : `To move this task, the following blockers must be removed`

  return (
    <BodyText>
      <p className='text-sm'>{text}</p>
      {blockedByTask == null && blockingTasks.length === 0 && (
        <p className='text-sm text-semantic-neutral-text-subtle'>
          <Label
            key='empty'
            value={{ name: '<empty name>', color: 'gray', id: 'empty' }}
          />
        </p>
      )}
      {blockedByTask != null && (
        <div className='flex flex-col gap-2'>
          <p className='text-sm text-semantic-neutral-text-subtle'>
            This task is blocked by:
          </p>
          <div className='flex flex-row items-center flex-wrap'>
            <Label
              value={{
                name: blockedByTask.name || 'No name',
                color: 'gray',
                id: blockedByTask.id,
              }}
            />
          </div>
        </div>
      )}
      {blockingTasks.length > 0 && (
        <div className='flex flex-col gap-2'>
          <p className='text-sm text-semantic-neutral-text-subtle'>
            This task is blocking:
          </p>
          <div className='flex flex-row items-center flex-wrap gap-1'>
            {blockingTasks.map((task) => (
              <Label
                key={task.id}
                value={{ name: task.name, color: 'gray', id: task.id }}
              />
            ))}
          </div>
        </div>
      )}
    </BodyText>
  )
}

function DeprecatedMoveBetweenStages({
  startStage,
  endStage,
}: Required<
  Pick<DeprecatedRemoveBlockersModalProps, 'startStage' | 'endStage'>
>) {
  return (
    <BodyText className='gap-3 truncate'>
      <strong className='text-sm'>
        For active projects, here’s what will happen to the task:
      </strong>
      <p className='text-sm flex items-center gap-2'>
        {templateStr(
          'If the task has been modified, it will stay in {{startStage}}',
          {
            startStage: <StageLabel value={startStage} />,
          }
        )}
      </p>
      <p className='text-sm flex items-center gap-2'>
        {templateStr(
          'If the task hasn’t been modified, it will be moved to {{endStage}}',
          {
            endStage: <StageLabel value={endStage} />,
          }
        )}
      </p>
      <p className='text-sm'>Blockers will also be removed from the task.</p>
    </BodyText>
  )
}
