import { useSharedStateSendOnly } from '@motion/react-core/shared-state'
import { templateStr } from '@motion/react-core/strings'
import { Button } from '@motion/ui/base'
import {
  type FilterTarget,
  useActiveFilter,
  useResetFilter,
} from '@motion/ui-logic/pm/data'
import { type ProjectSchema } from '@motion/zod/client'

import { FlowsAICard } from '~/areas/flows'
import { MotionLink } from '~/global/components'
import { useEmptyResultsIcon } from '~/global/hooks'
import { useProjectModalUrl, useTaskModalUrl } from '~/global/navigation'
import { useCurrentTeam } from '~/global/rpc/team'
import { useUriByRouteId } from '~/routing'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { CurrentTreeKey } from '../grouping'
import { usePageData } from '../routes'

export interface EmptyStateProps {
  target: FilterTarget
  isArchivePage?: boolean
  mainTextOverride?: string
  addTaskForProjectParam?: ProjectSchema['id']
}

export function EmptyState({
  target,
  isArchivePage = false,
  mainTextOverride,
  addTaskForProjectParam,
}: EmptyStateProps) {
  const Illustration = useEmptyResultsIcon()
  const { workspaceId } = useParams<{ workspaceId: string }>()

  const [filter] = useActiveFilter()
  const resetFilters = useResetFilter()
  const buildTaskModalUrl = useTaskModalUrl()
  const buildProjectModalUrl = useProjectModalUrl()
  const getRouteUri = useUriByRouteId()

  const pageData = usePageData()
  const isAllTasks = pageData.page === 'all-tasks'
  const isMyTasks = pageData.page === 'my-tasks'
  const isWorkspace = pageData.page === 'workspace'

  const isTeam = !!useCurrentTeam().data?.id

  const hasFilters =
    filter.tasks.ordered.length > 0 || filter.projects.ordered.length > 0
  const noFilterAllTasks = isAllTasks && !hasFilters && !isArchivePage
  const noFilterMyTasks = isMyTasks && !hasFilters && !isArchivePage
  const noFilterWorkspace = isWorkspace && !hasFilters && !isArchivePage

  useResetTree()

  const handleOnClickAddTask = () => {
    if (addTaskForProjectParam) {
      return buildTaskModalUrl({ forProject: addTaskForProjectParam })
    }
    return buildTaskModalUrl()
  }

  const handleOnClickAddProject = () => {
    return buildProjectModalUrl()
  }

  const getMainText = () => {
    if (mainTextOverride != null) {
      return mainTextOverride
    }

    if (isArchivePage) {
      return 'No archive tasks yet'
    }

    if (noFilterAllTasks) {
      return isTeam ? 'This team is empty!' : 'Nothing here yet!'
    }

    if (noFilterMyTasks) {
      return 'My Tasks is empty!'
    }

    if (noFilterWorkspace) {
      return 'This workspace is empty!'
    }

    return templateStr(hasFilters ? 'No {{type}} found' : 'No {{type}} yet', {
      type: target === 'tasks' ? 'tasks' : 'projects',
    })
  }

  return (
    <div className='grid place-items-center w-full h-full'>
      <div className='flex flex-col gap-4 items-center'>
        <Illustration className='w-32 h-32' />
        <span className='text-semantic-neutral-text-default font-semibold text-center'>
          {getMainText()}
        </span>
        {!isArchivePage &&
          (noFilterAllTasks || noFilterMyTasks || noFilterWorkspace ? (
            <span className='text-semantic-neutral-text-default'>
              Add your first task or project using the buttons below
            </span>
          ) : (
            <span className='text-semantic-neutral-text-default'>
              {hasFilters
                ? templateStr('Some {{type}} may be hidden by filters.', {
                    type: target === 'tasks' ? 'tasks' : 'projects',
                  })
                : templateStr('Add your first {{type}} below!', {
                    type: target === 'tasks' ? 'task' : 'project',
                  })}
            </span>
          ))}
        {(noFilterAllTasks || noFilterMyTasks || noFilterWorkspace) && (
          <span className='text-semantic-neutral-text-default'>
            Or learn more about Motion Workspaces in the{' '}
            <MotionLink className='underline' url='/web/tutorials'>
              Tutorials Tab
            </MotionLink>
          </span>
        )}

        {isArchivePage ? (
          <Button
            sentiment='neutral'
            variant='outlined'
            url={getRouteUri('parent', { type: 'tasks' })}
          >
            Go back
          </Button>
        ) : noFilterAllTasks || noFilterMyTasks || noFilterWorkspace ? (
          <div className='flex gap-2 flex-nowrap pt-4'>
            <Button
              sentiment='primary'
              variant='solid'
              url={handleOnClickAddProject()}
            >
              Add Project
            </Button>
            <Button
              sentiment='primary'
              variant='solid'
              url={handleOnClickAddTask()}
            >
              Add Task
            </Button>
          </div>
        ) : hasFilters ? (
          <Button
            sentiment='neutral'
            variant='outlined'
            onClick={() => resetFilters()}
          >
            Clear filters
          </Button>
        ) : (
          <Button
            sentiment='primary'
            variant='solid'
            url={
              target === 'tasks'
                ? handleOnClickAddTask()
                : handleOnClickAddProject()
            }
          >
            {target === 'tasks' ? 'Add Task' : 'Add Project'}
          </Button>
        )}
        {noFilterWorkspace && (
          <div className='mt-8'>
            <FlowsAICard workspaceId={workspaceId} />
          </div>
        )}
      </div>
    </div>
  )
}

function useResetTree() {
  const setCurrentTree = useSharedStateSendOnly(CurrentTreeKey)

  useEffect(() => {
    setCurrentTree((tree) => ({
      ...tree,
      key: '',
      qualifiedKey: '',
      value: { type: '@@root', key: '@@root', value: '' },
      depth: 0,
      maxDepth: 0,
      count: 0,
      parent: null,
      allItems: [],
      children: [],
      groups: [],
      values: [],
    }))
  }, [setCurrentTree])
}
