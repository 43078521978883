import { isActiveStatus } from '@motion/shared/billing'
import { isTeamSubscription } from '@motion/web-common/subscriptions'

import { useIsTeamTrialSetToCancel } from '~/global/hooks/use-is-team-trial-set-to-cancel'
import { useCurrentTeam } from '~/global/rpc/team'
import { useAppSelector } from '~/state/hooks'
import { type StripeSubscription } from '~/state/userSlice'
import { selectStripeSubscription } from '~/state/userSlice'

import { useIsNoCardTrialing } from './use-is-no-card-trialing'

export function useUpsellInfo() {
  const { data: team } = useCurrentTeam()
  const stripeSubscription = useAppSelector(selectStripeSubscription)

  const isTeamTrialWillCancel = useIsTeamTrialSetToCancel(
    team?.teamSubscription
  )
  const isNoCardTrialing = useIsNoCardTrialing()

  const isIndividualNoCardTrial = !team && isNoCardTrialing

  const isOnTeamMonthly =
    ((!isTeamTrialWillCancel || team?.teamSubscription?.status === 'active') &&
      team?.teamSubscription?.isMonthly &&
      team?.teamSubscription?.status !== 'canceled') ??
    false

  const isIndividualOrLegacyTeam =
    (stripeSubscription && teamIsIndividualOrLegacy(stripeSubscription)) ??
    false

  const showTeamAnnualUpgrade = isOnTeamMonthly || isIndividualOrLegacyTeam

  const showIndividualAnnualUpgrade =
    isIndividualOrLegacyTeam &&
    stripeSubscription?.plan?.interval === 'month' &&
    !isIndividualNoCardTrial
  return {
    isIndividualNoCardTrial,
    isOnTeamMonthly,
    showIndividualAnnualUpgrade,
    showTeamAnnualUpgrade,
    stripeSubscription,
    team,
  }
}

const teamIsIndividualOrLegacy = (
  stripeSubscription: StripeSubscription
): boolean =>
  !isTeamSubscription(stripeSubscription) ||
  !isActiveStatus(stripeSubscription.status)
