import { createLookup } from '@motion/utils/object'

import type { Row } from '@tanstack/react-table'
import { type SidebarTasksGroup } from '~/areas/task-project/hooks'
import type { SortBy } from '~/areas/task-project/utils'
import type { ReactNode } from 'react'

import { NoStageHeader } from './no-stage-header'
import { StageHeader } from './stage-header'
import { StatusHeader } from './status-header'

import type { InlineTaskCreationFormProps } from '../inline-create'

export type LookupArgs = {
  groupId: string
  toggle: (initialData?: InlineTaskCreationFormProps['initialData']) => void
  row?: Row<SidebarTasksGroup>
}

export const lookupHeader = createLookup<
  Record<
    Extract<keyof typeof SortBy, 'STATUS' | 'STAGES' | 'NO_STAGE'>,
    (args: LookupArgs) => ReactNode
  > & {
    default: () => ReactNode
  }
>({
  STATUS: ({ groupId, toggle, row }) => (
    <StatusHeader groupId={groupId} toggle={toggle} row={row} />
  ),
  STAGES: ({ groupId, row }) => <StageHeader groupId={groupId} row={row} />,
  NO_STAGE: ({ groupId, row }) => <NoStageHeader />,
  default: () => null,
})
