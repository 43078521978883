import { CheckOutline, EyeSolid } from '@motion/icons'
import { ActionList, PopoverTrigger } from '@motion/ui/base'
import { Compare } from '@motion/utils/array'

import { SectionHeaderActionButton } from '~/areas/sidebar/treeviews/components'
import { useMemo } from 'react'

const OPTIONS = [5, 10, 15, 20, Infinity]

export type ItemCountDropdownProps = {
  selectedCount: number
  onChange: (count: number) => void
}

export const ItemCountDropdown = ({
  selectedCount,
  onChange,
}: ItemCountDropdownProps) => {
  const selectableOptions = useMemo<number[]>(
    () =>
      [...new Set([...OPTIONS, selectedCount])]
        .filter(Boolean)
        .sort(Compare.numeric.asc),
    [selectedCount]
  )

  return (
    <PopoverTrigger
      placement='right-start'
      renderPopover={({ close }) => (
        <div className='w-56'>
          <ActionList
            onActionAnyItem={close}
            items={selectableOptions.map((count) => ({
              content: count === Infinity ? 'All' : `${count} items`,
              suffix: (
                <div className='size-4'>
                  {count === selectedCount && (
                    <CheckOutline className='!text-semantic-primary-icon-default size-4' />
                  )}
                </div>
              ),
              onAction: () => onChange(count),
            }))}
          />
        </div>
      )}
    >
      <SectionHeaderActionButton
        icon={EyeSolid}
        sentiment='neutral'
        variant='outlined'
        size='xsmall'
      />
    </PopoverTrigger>
  )
}
