import {
  ExclamationCircleSolid,
  PriorityASAPSolid,
  StatusCanceledSolid,
  StatusCompletedSolid,
} from '@motion/icons'
import { type TaskSchema } from '@motion/rpc-types'
import { type ActionItem } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type ETA_SOURCE } from '~/@types/analytics'
import { HardDeadlineTooltipIcon } from '~/areas/project-management/components'
import { useBulkUpdateTasks } from '~/areas/tasks/hooks'
import { useWorkspaceFns } from '~/global/hooks'
import { useCallback, useMemo } from 'react'

type ActionOptions = 'doASAP' | 'hardDeadline' | 'complete' | 'cancel' | 'edit'

export const useBulkTaskActions = (
  workspaceId: string,
  tasks: TaskSchema[],
  actionList: ActionOptions[] = [],
  onClose: () => void,
  source: ETA_SOURCE
) => {
  const bulkUpdateTasks = useBulkUpdateTasks()
  const { getWorkspaceCompletedStatus, getWorkspaceCanceledStatus } =
    useWorkspaceFns()

  const taskIds = tasks.map((task) => task.id)

  const doTasksAsap = useCallback(() => {
    recordAnalyticsEvent('ETA_BULK_ACTION', {
      action: 'doAsap',
      source,
    })

    void bulkUpdateTasks([
      {
        currentWorkspaceId: workspaceId,
        taskIds,
        update: {
          type: 'bulk-field-update',
          priorityLevel: 'ASAP',
        },
      },
    ])

    onClose()
  }, [bulkUpdateTasks, onClose, source, taskIds, workspaceId])

  const doTasksHardDeadline = useCallback(() => {
    recordAnalyticsEvent('ETA_BULK_ACTION', {
      action: 'hardDeadline',
      source,
    })

    void bulkUpdateTasks([
      {
        currentWorkspaceId: workspaceId,
        taskIds,
        update: {
          type: 'bulk-field-update',
          deadlineType: 'HARD',
        },
      },
    ])

    onClose()
  }, [bulkUpdateTasks, onClose, source, taskIds, workspaceId])

  const completeTasks = useCallback(() => {
    recordAnalyticsEvent('ETA_BULK_ACTION', {
      action: 'complete',
      source,
    })

    const completedStatus = getWorkspaceCompletedStatus(workspaceId)

    void bulkUpdateTasks([
      {
        currentWorkspaceId: workspaceId,
        taskIds,
        update: {
          type: 'bulk-field-update',
          statusId: completedStatus.id,
        },
      },
    ])

    onClose()
  }, [
    source,
    getWorkspaceCompletedStatus,
    workspaceId,
    bulkUpdateTasks,
    taskIds,
    onClose,
  ])

  const cancelTasks = useCallback(() => {
    recordAnalyticsEvent('ETA_BULK_ACTION', {
      action: 'cancel',
      source,
    })

    const canceledStatus = getWorkspaceCanceledStatus(workspaceId)

    void bulkUpdateTasks([
      {
        currentWorkspaceId: workspaceId,
        taskIds,
        update: {
          type: 'bulk-field-update',
          statusId: canceledStatus.id,
        },
      },
    ])

    onClose()
  }, [
    source,
    getWorkspaceCanceledStatus,
    workspaceId,
    bulkUpdateTasks,
    taskIds,
    onClose,
  ])

  return useMemo<ActionItem[]>(() => {
    return actionList
      .map((action) => {
        switch (action) {
          case 'doASAP':
            return {
              prefix: (
                <PriorityASAPSolid className='text-dropdown-item-icon-default' />
              ),
              content: 'Do all ASAP',
              onAction: doTasksAsap,
            }
          case 'hardDeadline':
            return {
              prefix: (
                <ExclamationCircleSolid className='text-dropdown-item-icon-default' />
              ),
              content: 'Turn on hard deadline for all',
              suffix: <HardDeadlineTooltipIcon />,
              onAction: doTasksHardDeadline,
            }
          case 'complete':
            return {
              content: 'Complete all tasks',
              prefix: <StatusCompletedSolid />,
              onAction: completeTasks,
            }
          case 'cancel':
            return {
              content: 'Cancel all tasks',
              prefix: (
                <StatusCanceledSolid className='text-dropdown-item-icon-default' />
              ),
              onAction: cancelTasks,
            }
          default:
            return null
        }
      })
      .filter(Boolean) as ActionItem[]
  }, [actionList, cancelTasks, completeTasks, doTasksAsap, doTasksHardDeadline])
}
