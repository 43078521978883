import { type TaskSchema } from '@motion/rpc-types'
import { formatDurationToShort } from '@motion/ui-logic'

import { DurationDropdown } from '~/areas/project-management/components'
import { useTaskCompletedDurationData } from '~/areas/tasks/hooks'

import { MentionCardButton } from '../mention-card-button'

export type TaskCompletedDurationFieldProps = {
  task: TaskSchema
}
export const TaskCompletedDurationField = ({
  task,
}: TaskCompletedDurationFieldProps) => {
  const { onChange, disabled, completedDuration, choices } =
    useTaskCompletedDurationData(task)

  return (
    <DurationDropdown
      value={completedDuration}
      choices={choices}
      onChange={(value) => value && onChange(value)}
    >
      <MentionCardButton disabled={disabled}>
        <span className='truncate'>
          {formatDurationToShort(completedDuration)}
        </span>
      </MentionCardButton>
    </DurationDropdown>
  )
}
