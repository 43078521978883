import { makeLog } from '@motion/web-base/logging'
import { useFeatureLimit } from '@motion/web-billing'
import { useHasTreatment } from '@motion/web-common/flags'

const log = makeLog('[notetaker]')

/**
 * Returns true if the notetaker FF is enabled and the user has a limit that's NOT 0. Because that means that feature is locked.
 *  */
export function useIsNotetakerEnabled() {
  const notetakerEnabled = useHasTreatment('notetaker-event-modal')
  const limit = useFeatureLimit('aiNotetakerLimit')

  log('useIsNotetakerEnabled', {
    notetakerEnabled,
    limit,
  })

  return notetakerEnabled && limit !== 0
}
