import { PlusSolid, PuzzleSolid } from '@motion/icons'
import { Button, PopoverTrigger, SearchableList } from '@motion/ui/base'
import { ProjectPalette } from '@motion/ui/project'
import { type ProjectDefinitionSchema } from '@motion/zod/client'

import { useGroupedWorkspaceProjectDefinitions } from '~/global/hooks'
import { type ReactNode } from 'react'

export type WorkspaceTemplateDropdownProps = {
  children: ReactNode
  onChange: (projectDefinition: ProjectDefinitionSchema) => void
  onCreateNewTemplate?: () => void
  selectedProjectDefinitionId?: string
  workspaceId?: string
}

export function WorkspaceTemplateDropdown({
  children,
  ...props
}: WorkspaceTemplateDropdownProps) {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <WorkspaceTemplateDropdownContent close={close} {...props} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type WorkspaceTemplateDropdownContentProps = Omit<
  WorkspaceTemplateDropdownProps,
  'children'
> & {
  close: () => void
}

function WorkspaceTemplateDropdownContent({
  selectedProjectDefinitionId,
  onChange,
  onCreateNewTemplate,
  close,
  workspaceId,
}: WorkspaceTemplateDropdownContentProps) {
  const groupedDefinitions = useGroupedWorkspaceProjectDefinitions(workspaceId)

  return (
    <>
      <SearchableList
        itemType='sectioned'
        sections={groupedDefinitions.map(({ workspace, definitions }) => ({
          key: workspace.id,
          label: workspace.name,
          items: definitions,
          initialExpanded: true,
        }))}
        hideEmptySections
        computeKey={(item) => item.id}
        computeSearchValue={(item) => item.name}
        computeSelected={(item) => item.id === selectedProjectDefinitionId}
        onSelect={(item) => {
          onChange(item)
          close()
        }}
        inputProps={{ placeholder: 'Search template...' }}
        renderItem={(item) => (
          <div className='flex items-center gap-2 overflow-hidden'>
            <ProjectPalette color={item.color}>
              <PuzzleSolid className='size-4 text-palette-highlight-default' />
            </ProjectPalette>
            <div className='truncate'>{item.name}</div>
          </div>
        )}
      />
      {onCreateNewTemplate && (
        <div className='border-t border-dropdown-border p-1'>
          <Button
            variant='muted'
            sentiment='neutral'
            size='normal'
            alignment='left'
            fullWidth
            onClick={onCreateNewTemplate}
          >
            <PlusSolid className='size-4' />
            Create new template
          </Button>
        </div>
      )}
    </>
  )
}
