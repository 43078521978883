import { FieldButton } from '@motion/ui/base'

import { useStageTaskCardFields } from '~/areas/flows/contexts'
import { DurationDropdown } from '~/areas/project-management/components'
import { DurationLabel } from '~/global/components/labels'

import { MinimumDurationField } from '../../../flow-template-modal/components/stages/fields'

export function DurationFields() {
  const {
    fields: { duration, minimumDuration },
  } = useStageTaskCardFields()
  const [durationValue, onChangeDuration] = duration
  const [minimumDurationValue, onChangeMinimumDuration] = minimumDuration

  const fieldSize = 'xsmall'

  return (
    <div className='flex flex-row items-center gap-1'>
      <DurationDropdown value={durationValue} onChange={onChangeDuration}>
        <FieldButton variant='muted' size={fieldSize}>
          <DurationLabel
            value={durationValue}
            longMode={durationValue === null ? false : true}
            size={fieldSize}
          />
        </FieldButton>
      </DurationDropdown>

      <MinimumDurationField
        duration={durationValue}
        onMinimumDurationChange={onChangeMinimumDuration}
        minimumDuration={minimumDurationValue}
      />
    </div>
  )
}
