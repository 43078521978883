import { createUseMutation, createUseQuery } from '@motion/rpc'
import { API } from '@motion/rpc-definitions'

export const useCreateBookingTimeslot = createUseMutation(
  API.blockingTimeslot.create
)
export const useDeleteBookingTimeslots = createUseMutation(
  API.blockingTimeslot.deleteMany
)
export const useBlockingTimeslots = createUseQuery(API.blockingTimeslot.get)
