import { classed } from '@motion/theme'

import React, { Children, type ReactElement } from 'react'

import { useAlertContext } from './AlertsContext'

type AlertContainerProps = {
  children: ReactElement
  id: string
  priority: number
  position: 'none' | 'bottom-right'
}

export const AlertContainer = ({
  children,
  id,
  priority,
  position,
}: AlertContainerProps) => {
  const { open, setOpen } = useAlertContext({
    id,
    priority,
  })

  return (
    <AlertPositionContainer position={position}>
      {Children.map(children, (child) => {
        return React.cloneElement(child, {
          open,
          setOpen,
        } as any)
      })}
    </AlertPositionContainer>
  )
}

export const AlertPositionContainer = classed('div', {
  variants: {
    position: {
      'bottom-right': 'fixed bottom-6 right-6 flex',
      none: '',
    },
  },
})
