import { Button, Tooltip } from '@motion/ui/base'
import { getEtaLabel } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type DeadlineStatusSchema, type TaskSchema } from '@motion/zod/client'

import { type ETA_SOURCE } from '~/@types/analytics'
import { useTaskETAData } from '~/areas/tasks/hooks/data'
import { type BadgeSize, EtaBadge } from '~/global/components/badges'

import { BadgeTooltip } from './badge-tooltip'

import { useExtendedNoneEta } from '../../hooks'
import { TaskEtaPopover } from '../popover'

type ConnectedTaskEtaBadgeProps = {
  task: TaskSchema
  size?: BadgeSize
  hideTooltip?: boolean
  source: ETA_SOURCE
}

export const ConnectedTaskEtaBadge = (props: ConnectedTaskEtaBadgeProps) => {
  const { hasETA, normalizedDeadlineStatus: deadlineStatus } = useTaskETAData(
    props.task
  )

  if (!hasETA) return null

  return <InnerTaskEtaBadge {...props} deadlineStatus={deadlineStatus} />
}

type InnerTaskEtaBadgeProps = ConnectedTaskEtaBadgeProps & {
  deadlineStatus: DeadlineStatusSchema
}

const InnerTaskEtaBadge = ({
  task,
  size = 'small',
  hideTooltip = false,
  source,
  deadlineStatus,
}: InnerTaskEtaBadgeProps) => {
  const DeadlineStatusWithReason = useExtendedNoneEta({
    entity: task,
    type: 'task',
  })

  return (
    <TaskEtaPopover entity={task} source={source}>
      <Tooltip
        asChild
        renderContent={() =>
          hideTooltip ? undefined : (
            <BadgeTooltip entity={task} type='task' source={source} />
          )
        }
      >
        <Button
          aria-label={getEtaLabel(DeadlineStatusWithReason, 'task')}
          sentiment='neutral'
          onClick={() => {
            recordAnalyticsEvent('ETA_OPEN_POPOVER', {
              source,
              eta: DeadlineStatusWithReason,
              type: 'task',
            })
          }}
          variant='muted'
          disabled={deadlineStatus === 'none'}
          size='xsmall'
          iconOnly
        >
          <EtaBadge value={DeadlineStatusWithReason} size={size} />
        </Button>
      </Tooltip>
    </TaskEtaPopover>
  )
}
