import { useMediaQuery } from '@motion/react-core/hooks'
import { templateStr } from '@motion/react-core/strings'
import { Banner, Button, LoadingSpinner, Modal } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { useHasTreatment } from '@motion/web-common/flags'

import { DateTime } from 'luxon'
import { useCallback } from 'react'

import {
  EmptyResults,
  SearchResults,
  type SearchResultsProps,
} from './components'
import { SearchFilters } from './components/search-filters'
import { DEFAULT_SEARCH_TYPES } from './constants'
import { useListNavigation } from './hooks'
import {
  getSearchableEntitiesStr,
  isSearchingArchivedTasks,
  isSearchingCompletedTasks,
  isSearchingEvents,
  isSearchingTasks,
  isValidSearch,
  type SearchType,
} from './utils'

export type SearchModalProps = {
  searchQuery: string
  searchTypes: SearchType[]
  disableSearchTypeEvents: boolean
  onSearch: (query: string, searchType: SearchType[]) => void
  onClose: () => void
  searchResults?: SearchResultsProps['items']
  loading?: boolean
  pending?: boolean
}

export const SearchModal = ({
  searchQuery,
  searchTypes,
  disableSearchTypeEvents,
  onSearch,
  onClose,
  searchResults = [],
  loading = false,
  pending = false,
}: SearchModalProps) => {
  const hasNotes = useHasTreatment('notes-in-webapp')

  const getFirstActiveIndex = useCallback(
    (items: SearchResultsProps['items']) => {
      if (!isSearchingEvents(searchTypes)) return 0
      const now = DateTime.now().startOf('day').toUTC().toISO()
      return items.findIndex((item) => {
        if (item.entityType !== 'event') return true
        return item.start >= now
      })
    },
    [searchTypes]
  )

  const { listRef, activeIndex } = useListNavigation({
    items: searchResults,
    getFirstActiveIndex,
  })

  const handleSearchArchive = () => {
    onSearch(searchQuery, [
      { type: 'tasks', showCompleted: true, archived: true },
    ])
  }

  const handleSearchAll = () => onSearch(searchQuery, DEFAULT_SEARCH_TYPES)

  const handleSearchTasks = () =>
    onSearch(searchQuery, [
      {
        type: 'tasks',
        archived: false,
        showCompleted: isSearchingTasks(searchTypes)
          ? isSearchingCompletedTasks(searchTypes)
          : true,
      },
    ])

  const isMobile = useMediaQuery('(max-width: 1024px)')

  const handleSearchProjects = () =>
    onSearch(searchQuery, [{ type: 'projects' }])

  const handleSearchEvents = () => onSearch(searchQuery, [{ type: 'events' }])

  const handleSearchAttachments = () =>
    onSearch(searchQuery, [{ type: 'attachments' }])

  const handleSearchNotes = () => onSearch(searchQuery, [{ type: 'notes' }])

  const handleReturnToRegularSearch = () =>
    onSearch(
      searchQuery,
      searchTypes.map((searchType) => {
        if (searchType.type === 'tasks') {
          return {
            ...searchType,
            archived: false,
          }
        }
        return searchType
      })
    )

  const isNotReallyPendingSearching = pending && !isValidSearch(searchQuery)
  const shouldShowEmptyResults =
    !loading &&
    searchResults.length === 0 &&
    (!pending || isNotReallyPendingSearching)

  return (
    <Modal visible onClose={onClose}>
      <div className='flex flex-col gap-2 overflow-hidden'>
        {isSearchingTasks(searchTypes) &&
          isSearchingArchivedTasks(searchTypes) && (
            <Banner
              sentiment='error'
              actions={[
                {
                  label: 'Back to regular search',
                  onAction: handleReturnToRegularSearch,
                },
              ]}
            >
              Searching archived tasks
            </Banner>
          )}
        <div className='bg-modal-bg flex lg:w-[800px] flex-col'>
          <div className='flex p-3'>
            <div className='w-full flex gap-4 items-center'>
              <TextField
                autoFocus
                selectTextOnFocus
                showClearButton
                variant='muted'
                placeholder={templateStr('Search {{entities}}', {
                  entities: getSearchableEntitiesStr({
                    hasNotes,
                    disableSearchTypeEvents,
                    isMobile,
                  }),
                })}
                labelHidden
                size='large'
                fullWidth
                value={searchQuery}
                onChange={(value) => {
                  onSearch(value, searchTypes)
                }}
              />
            </div>
          </div>

          <SearchFilters
            searchQuery={searchQuery}
            searchTypes={searchTypes}
            onSearch={onSearch}
            onToggleSearchAll={handleSearchAll}
            onToggleSearchTasks={handleSearchTasks}
            onToggleSearchProjects={handleSearchProjects}
            onToggleSearchNotes={handleSearchNotes}
            onToggleSearchAttachments={handleSearchAttachments}
            onToggleSearchEvents={handleSearchEvents}
          />

          <div className='h-[500px] p-2 overflow-auto scroll-py-2'>
            {loading ? (
              <div className='grid place-items-center h-full'>
                <LoadingSpinner />
              </div>
            ) : shouldShowEmptyResults ? (
              <EmptyResults
                searchQuery={searchQuery}
                searchTypes={searchTypes}
                hasNotes={hasNotes}
                disableSearchTypeEvents={disableSearchTypeEvents}
                onClickArchive={handleSearchArchive}
                onClickRegularSearch={handleReturnToRegularSearch}
              />
            ) : (
              <>
                <SearchResults
                  ref={listRef}
                  items={searchResults}
                  onItemClick={onClose}
                  activeIndex={activeIndex}
                />
                {isValidSearch(searchQuery) &&
                  isSearchingTasks(searchTypes) &&
                  !isSearchingArchivedTasks(searchTypes) &&
                  !pending && (
                    <div className='grid gap-2 justify-items-center text-semantic-neutral-text-subtle pt-5 pb-3'>
                      <div className='font-semibold text-s'>
                        No more results
                      </div>
                      <div className='text-xs'>
                        {`Can't find what you're looking for? `}
                        <Button
                          variant='link'
                          sentiment='primary'
                          size='small'
                          onClick={handleSearchArchive}
                        >
                          Search archived tasks
                        </Button>
                      </div>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
