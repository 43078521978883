import { createUseMutation, createUseQuery } from '@motion/rpc'
import {
  createQueryFilter,
  getModelCache,
  MODEL_CACHE_KEY,
  MotionCache,
  type OptimisticUpdateValue,
} from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { showToast } from '@motion/ui/base'
import { values } from '@motion/utils/object'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useQueryClient } from '@tanstack/react-query'

const queryFilter = createQueryFilter([
  MODEL_CACHE_KEY,
  API.teamSettings.queryKeys.root,
])

export const useTeamSettings = createUseQuery(
  API.teamSettings.getTeamSettings,
  {
    select: (data) => {
      return data.models.teamSettings[data.id]
    },
  }
)

const useUpdateNotetakerTeamSettingsMutation = createUseMutation(
  API.teamSettings.updateNotetakerTeamSettings
)
export const useUpdateNotetakerTeamSettings = () => {
  const client = useQueryClient()

  return useUpdateNotetakerTeamSettingsMutation({
    onSuccess: (data, { allowCalendarEditorAccess }) => {
      MotionCache.upsert(client, queryFilter, data)

      showToast('success', 'AI Notetaker team settings updated')

      if (allowCalendarEditorAccess != null) {
        recordAnalyticsEvent('NOTETAKER_CALENDAR_ACCESS_SETTING', {
          isEnabled: allowCalendarEditorAccess ?? false,
        })
      }
    },
    onMutate: (data) => {
      const modelCache = getModelCache(client)

      const teamSettingsEntry = values(modelCache.teamSettings).find(
        (entry) => entry.value.teamId === data.teamId
      )

      // Skip optimistic update if no data or teamSettingsEntry is not found
      // Probably because the teamSettingsEntry is not in the cache yet
      if (!teamSettingsEntry || data.allowCalendarEditorAccess == null) {
        return {}
      }

      const cacheUpdates = MotionCache.patch(
        client,
        queryFilter,
        'teamSettings',
        {
          [teamSettingsEntry.value.id]: {
            notetakerSettings: {
              allowCalendarEditorAccess: data.allowCalendarEditorAccess,
            },
          },
        }
      )

      return { cacheUpdates }
    },
    onError: (error, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }

      cacheUpdates?.rollback()
    },
  })
}
