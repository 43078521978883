import { type SearchType } from './utils'

export const DEFAULT_SEARCH_TYPES: SearchType[] = [
  {
    type: 'tasks',
    archived: false,
    showCompleted: true,
  },
  { type: 'projects' },
  { type: 'notes' },
  { type: 'attachments' },
]
