import { type NoteSchema } from '@motion/rpc-types'
import { useMyUserSettings } from '@motion/web-common/settings'

import {
  useFindFolderItem,
  useMyTasksWorkspace,
  useWorkspaceFns,
} from '~/global/hooks'
import { useCallback } from 'react'

/**
 * Resolves the workspace ID for the specified note, the order of resolving
 * the workspace ID is as follows:
 * 1. From the project, if the note is nested under a project folder item
 * 2. From the note target, if the note is a workspace note
 * 3. From the task defaults
 * 4. Fallback to my tasks workspace
 *
 * This logic assumes the cache has all accessible workspaces - if workspaces
 * are lazy loaded, this method will need to be rewritten.
 */
export function useResolveWorkspaceId() {
  const findFolderItem = useFindFolderItem()

  const myTasksWorkspace = useMyTasksWorkspace()

  const { getWorkspaceProjectById, getWorkspaceById } = useWorkspaceFns()

  const { data: userSettings } = useMyUserSettings(undefined, {
    meta: { source: 'useInlineCreate' },
  })

  return useCallback(
    (note: NoteSchema) => {
      // Resolve workspace ID from the project
      const folderItem = findFolderItem((item) => item.itemId === note.id)
      const projectId = folderItem?.find(
        (item) => item.itemType === 'PROJECT'
      )?.itemId
      const project = getWorkspaceProjectById(projectId)

      if (project?.workspaceId) {
        const workspace = getWorkspaceById(project.workspaceId)

        if (workspace) {
          return workspace.id
        }
      }

      // Resolve workspace ID from the note target
      if (note.targetType === 'WORKSPACE') {
        const workspace = getWorkspaceById(note.targetId)

        if (workspace) {
          return workspace.id
        }
      }

      // Resolve workspace ID from task defaults
      const taskDefaultWorkspaceId =
        userSettings?.taskDefaultSettings.global?.workspaceId
      if (taskDefaultWorkspaceId != null) {
        const workspace = getWorkspaceById(taskDefaultWorkspaceId)

        if (workspace) {
          return workspace.id
        }
      }

      // Fallback to my tasks workspace
      return myTasksWorkspace?.id
    },
    [
      findFolderItem,
      getWorkspaceById,
      getWorkspaceProjectById,
      myTasksWorkspace,
      userSettings,
    ]
  )
}
