import { EventConferenceType } from '@motion/shared/common'
import { Sentry } from '@motion/web-base/sentry'

import { useCallback } from 'react'

import { useEventForm } from './use-event-form'
import { useGenerateConferencingData } from './use-generate-conferencing-data'

export function useFormHandleConferenceChange() {
  const { form } = useEventForm()
  const generateConferenceData = useGenerateConferencingData()

  return useCallback(
    async (value: EventConferenceType) => {
      const currentConferenceType = form.getValues('conferenceType')
      const currentDescription = form.getValues('description')
      const currentLocation = form.getValues('location')
      if (currentConferenceType === value) {
        return {
          conferenceType: currentConferenceType,
          description: currentDescription,
          location: currentLocation,
        }
      }
      try {
        const { description, location } = await generateConferenceData(
          value,
          currentDescription
        )

        form.setValue('conferenceType', value, { shouldDirty: true })
        form.setValue('description', description, { shouldDirty: true })
        form.setValue('location', location, { shouldDirty: true })

        return {
          conferenceType: value,
          description,
          location,
        }
      } catch (e) {
        Sentry.captureException(e)
        form.setValue('conferenceType', EventConferenceType.none, {
          shouldDirty: true,
        })
      }
    },
    [form, generateConferenceData]
  )
}
