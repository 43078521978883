import { type NoteSchema } from '@motion/rpc-types'

import type { WorkspacesTreeviewItemType } from '~/areas/treeviews/components'
import { useNoteById } from '~/global/rpc/v2'

import { EmptyState } from './empty-state'

type EmptyStateWithNoteCheckProps = {
  noteId?: NoteSchema['id']
  viewType?: WorkspacesTreeviewItemType
}

export function EmptyStateWithNoteCheck({
  noteId,
  viewType,
}: EmptyStateWithNoteCheckProps) {
  const { data: note } = useNoteById({ id: noteId })

  if (note == null || !note.metadata?.isOnboardingDoc) {
    return null
  }

  return <EmptyState viewType={viewType} />
}
