import { FieldButton } from '@motion/ui/base'

import { useStageTaskCardFields } from '~/areas/flows/contexts'
import { StatusDropdown } from '~/global/components/dropdowns'
import { StatusLabel } from '~/global/components/labels'
import { useWorkspaceStatusById } from '~/global/hooks'

export function StatusFields() {
  const {
    workspaceId,
    fields: { status },
  } = useStageTaskCardFields()
  const [statusId, onChangeStatus] = status

  const statusValue = useWorkspaceStatusById(statusId)
  const fieldSize = 'xsmall'

  return (
    <StatusDropdown
      selectedStatusId={statusId}
      onChange={onChangeStatus}
      workspaceId={workspaceId}
      excludeResolved
      excludeCanceled
    >
      <FieldButton variant='muted' fullWidth size={fieldSize}>
        <StatusLabel value={statusValue} />
      </FieldButton>
    </StatusDropdown>
  )
}
