import { Text } from '@motion/ui/base'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'

import { type CalendarOptions } from '@fullcalendar/core'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import { FullCalendarThemeSelector } from '~/components/Common/FullCalendarThemeSelector/FullCalendarThemeSelector'
import { selectCalendarStartDay } from '~/state/calendar/calendarSlice'
import { useAppSelector } from '~/state/hooks'
import { forwardRef, useEffect } from 'react'

import { useSendCalendarState } from '../../hooks'

type CalendarProps = {
  calendarProps: Partial<CalendarOptions>
}

export const Calendar = forwardRef<FullCalendar, CalendarProps>(
  function Calendar({ calendarProps = READONLY_EMPTY_OBJECT }, ref) {
    const setCalendarState = useSendCalendarState()
    const calendarStartDay = useAppSelector(selectCalendarStartDay)

    useEffect(() => {
      const handleMouseDown = (e: MouseEvent) => {
        const target = e.target as HTMLElement
        // Don't close when clicking another event to let it animate
        if (target.closest('.fc-event')) return
        // Don't close when in a custom modal from the event sidebar (eg. custom schedule)
        if (target.closest('#motion-task-input-iframe')) return
        // Don't close when in a portal
        if (!target.closest('#motion-web-root')) return

        setCalendarState((prev) => ({
          ...prev,
          selectedCalendarEvent: null,
          unselectCalendarEventTimeStamp: prev.selectedCalendarEvent
            ? new Date().getTime()
            : prev.unselectCalendarEventTimeStamp,
        }))
      }
      window.addEventListener('mousedown', handleMouseDown)
      return () => {
        window.removeEventListener('mousedown', handleMouseDown)
      }
    }, [setCalendarState])

    return (
      <FullCalendarThemeSelector>
        <FullCalendar
          plugins={[interactionPlugin, timeGridPlugin, resourceTimeGridPlugin]}
          ref={ref}
          initialView='timeGridWeek'
          {...calendarProps}
          dayHeaders
          resources={[
            { id: 'mine', order: 1, title: 'My calendars' },
            { id: 'team', order: 2, title: "Others' calendars" },
          ]}
          resourceLabelContent={(data) => (
            <Text sentiment='subtle' size='xs'>
              {data?.resource?.title}
            </Text>
          )}
          resourceOrder='order'
          selectConstraint={{ startTime: '00:00', endTime: '24:00' }}
          firstDay={calendarStartDay === 'sunday' ? 0 : 1}
        />
      </FullCalendarThemeSelector>
    )
  }
)
