import { DotsHorizontalSolid } from '@motion/icons'
import { type CommentSchema } from '@motion/rpc-types'
import { IconButton, PopoverTrigger } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { CommentActionList } from './comment-action-list'

export type CommentActionMenuProps = {
  comment: CommentSchema
  isOwner: boolean
  onEdit: () => void
  onDelete: () => void
  onResolve?: () => void
}

export const CommentActionMenu = (props: CommentActionMenuProps) => {
  return (
    <PopoverTrigger
      placement='bottom-end'
      renderPopover={({ close }) => (
        <CommentActionList {...props} close={close} />
      )}
    >
      <IconButton
        variant='muted'
        sentiment='neutral'
        size='xsmall'
        icon={DotsHorizontalSolid}
        onClick={() => {
          recordAnalyticsEvent('FOLDERS_CLICKED_SIDEBAR_BUTTON', {
            itemType: 'NOTE',
            button: 'ACTIONS',
          })
        }}
      />
    </PopoverTrigger>
  )
}
