import { createQueryFilter, MotionCache } from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { type UpdateStageDefinitionSchema } from '@motion/zod/client'

import { type QueryClient } from '@tanstack/react-query'

export function applyOptimisticStageDefinitionUpdates(
  client: QueryClient,
  id: string,
  updates: UpdateStageDefinitionSchema
) {
  const { rollback } = MotionCache.patch(
    client,
    createQueryFilter([API.workspacesV2.queryKeys.root]),
    'stageDefinitions',
    { [id]: updates }
  )

  return {
    withRollback<T>(p: Promise<T>) {
      return p.catch((ex) => {
        rollback()
        throw ex
      })
    },
    rollback,
  }
}
