import {
  type LocalStorageTypes,
  removeLocalStorageValue,
} from '@motion/web-common/storage'

export function cleanupOldLocalStorageKeys() {
  const oldKeys = [
    'projectExpanded',
    'showSidebar',
    'showTeamAnnualUpgradeTag',
    'showIndividualAnnualUpgradeTag',
    'motionPmState',
    'projectManagementHash',
    'motionOnboarding',
    'motionLastPmUri',
  ] as const

  oldKeys.forEach((k) => {
    // type casting is needed since these are keys that are not defined in the LocalStorageTypes type
    removeLocalStorageValue(k as keyof LocalStorageTypes)
  })
}
