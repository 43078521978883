import { AlertModal } from '@motion/ui/base'
import { getMinimumBucket, Term } from '@motion/ui-logic/billing'
import { type Tier } from '@motion/ui-logic/tiered-pricing'
import { useModalApi } from '@motion/web-common/modals'

import { useCurrentTier } from '~/areas/tiered-pricing/hooks'
import { useTeamMembers } from '~/global/hooks/team'
import { useCurrentTeam } from '~/global/rpc/team'

import { type ModalTriggerComponentProps } from '../modal-trigger'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'team-trial-ended': {
      onStartTeamPlan?: () => void
      doNotShowAgain: () => void
    }
  }
}

export const TeamTrialEndedModal = ({
  doNotShowAgain = () => {
    void 0
  },
  onStartTeamPlan,
  close,
}: ModalTriggerComponentProps<'team-trial-ended'>) => {
  const modalApi = useModalApi()
  const { data: team } = useCurrentTeam()
  const teamMembers = useTeamMembers()

  const currentTier = useCurrentTier()

  const handleDismiss = () => {
    doNotShowAgain()
    close()
  }

  const handleStartTieredPricingTeamPlan = () => {
    modalApi.open('choose-feature-tier-modal', {
      mode: 'billingSettings',
      initialBucket: getMinimumBucket(teamMembers.length),
      onSelectFeatureTier: (selectedTier: Tier, term: Term, bucket: number) => {
        modalApi.open('tiered-checkout-modal', {
          tier: selectedTier,
          currentTier: currentTier,
          selectedBucket: bucket,
          selectedTerm: term,
          isResubscribe: true,
          onBillingComplete: () => {
            modalApi.dismissAll()
          },
        })
      },
      currentTier: currentTier,
      currentTerm: team?.teamSubscription?.isMonthly
        ? Term.Monthly
        : Term.Annual,
      currentBucket: 1,
    })
  }

  return (
    <AlertModal
      title='Your team trial has ended'
      actions={[
        {
          label: 'Start team plan',
          onAction: onStartTeamPlan ?? handleStartTieredPricingTeamPlan,
        },
        {
          label: 'No thanks',
          onAction: handleDismiss,
          sentiment: 'neutral',
          variant: 'muted',
        },
      ]}
      onClose={() => {}}
      visible
      blocking
    >
      <p className='text-base'>
        Your team trial has expired. Start your team plan to continue using
        Motion with a team.
      </p>
    </AlertModal>
  )
}
