import { TEAM_DEFAULT_BUCKET_SEATS, Term } from '@motion/ui-logic/billing'
import { BillingPlanToggle, BucketSeatsDropdown } from '@motion/web-billing'
import { useHasTreatment } from '@motion/web-common/flags'

import { useIsTeamExpired } from '~/global/hooks/team'
import { SettingPageWrapper } from '~/pages/settings/shared'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { CreateTeam } from './components/CreateTeam/create-team'

import { useAppSelector } from '../../state/hooks'
import { selectPMTeamFetched } from '../../state/projectManagementSlice'

export const ConnectedCreateTeamPage = () => {
  const pmTeamFetched = useAppSelector(selectPMTeamFetched)
  const teamId = useAppSelector((state) => state.projectManagement.teamId)
  const isTeamExpired = useIsTeamExpired()
  const navigate = useNavigate()
  const [isInProgress, setIsInProgress] = useState(false)
  const shouldUseTieredPricingV1 = useHasTreatment('tiered-pricing-v1')
  const [bucket, setBucket] = useState<number>(TEAM_DEFAULT_BUCKET_SEATS)
  const [term, setTerm] = useState<Term>(Term.Annual)

  useEffect(
    function redirectToTeamPage() {
      if (teamId && !isTeamExpired && !isInProgress) {
        navigate('/web/settings/team')
      }
    },
    [isTeamExpired, isInProgress, teamId, navigate]
  )

  if ((teamId && !isTeamExpired && !isInProgress) || !pmTeamFetched) {
    return null
  }

  return (
    <SettingPageWrapper
      title={
        shouldUseTieredPricingV1 && (
          <div className='flex flex-row justify-between w-full'>
            <p>Create a team</p>
            <div className='flex flex-row gap-3 pr-4'>
              <BucketSeatsDropdown
                selectedBucket={bucket}
                onChange={setBucket}
                allowUpsell
              />
              <BillingPlanToggle
                term={term}
                setTerm={setTerm}
                savingsPercent={40}
              />
            </div>
          </div>
        )
      }
      fullWidth
    >
      <CreateTeam
        bucket={bucket}
        term={term}
        setIsInProgress={setIsInProgress}
      />
    </SettingPageWrapper>
  )
}
