import { classed } from '@motion/theme'

export const MentionCardTitle = classed('div', {
  base: `
    text-semantic-neutral-text-default
    text-sm leading-5 font-medium
    line-clamp-2 h-10
  `,

  variants: {
    canceled: {
      true: 'line-through text-semantic-neutral-text-subtle',
    },
  },
  defaultVariants: {
    canceled: false,
  },
})
