import { CalendarSolid } from '@motion/icons'
import { isCustomTemplateProject } from '@motion/ui-logic/pm/project'
import { sleep } from '@motion/utils/promise'

import { useIsFlowsM5cEnabled } from '~/areas/flows'
import { useProjectDeadlineUpdater } from '~/areas/project/hooks'
import {
  DeadlineDropdown,
  type DeadlineDropdownProps,
} from '~/areas/project-management/components'
import { DateTriggerButton } from '~/areas/task-project/components'
import { useProject } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { useProjectForm } from '../hooks'

export const ControlledDeadlineField = () => {
  const { form } = useProjectForm()
  const updateProjectDeadline = useProjectDeadlineUpdater()

  const { control, watch } = form

  const projectId = watch('id')
  const project = useProject(projectId)

  const { field } = useController({
    name: 'dueDate',
    control,
  })

  const projectDefinitionId = watch('projectDefinitionId')
  const activeStageDefinitionId = watch('activeStageDefinitionId')

  const isFlowsM5cEnabled = useIsFlowsM5cEnabled()
  const isUsingCustomTemplate =
    isFlowsM5cEnabled &&
    isCustomTemplateProject({
      projectDefinitionId,
      activeStageDefinitionId,
    })

  const isFlowProject = projectDefinitionId != null || isUsingCustomTemplate

  const onChange: DeadlineDropdownProps['onChange'] = async (dueDate) => {
    if (project == null) {
      field.onChange(dueDate)
      return
    }

    // Sleeping a bit to make sure the dropdown gets closed before doing anything else to avoid messing up the focus
    await sleep(1)

    await updateProjectDeadline(project, dueDate)
  }

  return (
    <DeadlineDropdown
      value={field.value}
      hideTimeOptions={isFlowProject}
      onChange={onChange}
      contextProps={{ projectId }}
      dropdownTarget='project'
    >
      <DateTriggerButton
        label='Deadline'
        icon={<CalendarSolid />}
        onClear={
          field.value == null || isFlowProject
            ? undefined
            : () => onChange(null)
        }
        date={field.value}
        placeholder='No deadline'
        clearTooltipContent='Remove deadline'
        sentiment='neutral'
      />
    </DeadlineDropdown>
  )
}
