import { type ShareTargetResponseSchema } from '@motion/zod/client'

export type LinkedShareTarget = ShareTargetResponseSchema & {
  linkedTargetType: NonNullable<ShareTargetResponseSchema['linkedTargetType']>
  linkedTargetId: NonNullable<ShareTargetResponseSchema['linkedTargetId']>
}

export function isShareTargetLinked(
  shareTarget: ShareTargetResponseSchema
): shareTarget is LinkedShareTarget {
  return (
    shareTarget.linkedTargetType != null && shareTarget.linkedTargetId != null
  )
}
