import { computeSearchScore } from '@motion/ui-logic'
import { byProperty, Compare } from '@motion/utils/array'

import { useCallback, useMemo } from 'react'

import { type FilteredTreeviewItem, type TreeviewItem } from '../types'

export type UseFilteredTreeviewProps<T> = {
  items: T[]
  computeFolderStateKey: (item: T) => string
  computeSearchValue: (item: T) => string
  searchQuery?: string
  folderState: Record<string, boolean>
}

export const useFilteredTreeview = <I, T extends TreeviewItem<I>>({
  items,
  computeFolderStateKey,
  computeSearchValue,
  searchQuery,
  folderState,
}: UseFilteredTreeviewProps<T>) => {
  const filterItems = useCallback(
    (items: T[]) =>
      items
        .reduce<{ score: number; item: FilteredTreeviewItem<T> }[]>(
          (acc, item) => {
            const { children, isContainer } = item
            const filteredChildren: FilteredTreeviewItem<T>[] = []

            if (isContainer && children) {
              filteredChildren.push(...filterItems(children as T[]))
            }

            const hasChildren = isContainer && filteredChildren.length > 0
            const score = computeSearchScore(
              computeSearchValue(item),
              searchQuery
            )

            const isManuallyExpanded =
              folderState[computeFolderStateKey(item)] ?? false
            const shouldExpandForSearch = searchQuery && hasChildren

            const isExpanded =
              isContainer && (shouldExpandForSearch || isManuallyExpanded)

            if (!searchQuery || hasChildren || score > 0) {
              acc.push({
                score,
                item: {
                  ...item,
                  children: filteredChildren,
                  expanded: isExpanded,
                },
              })
            }

            return acc
          },
          []
        )
        .sort(byProperty('score', Compare.numeric.desc))
        .map((i) => i.item),
    [computeFolderStateKey, computeSearchValue, folderState, searchQuery]
  )

  return useMemo(() => filterItems(items), [filterItems, items])
}
