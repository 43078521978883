import { convertDateIntervalToDays } from '@motion/shared/flows'
import type {
  FlowTemplateFormFields,
  TaskDefinitionFormRelativeInterval,
} from '@motion/ui-logic/pm/project'

import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import {
  calculateAdjustedRelativeDurations,
  getDataFromFieldName,
} from '~/areas/flows/shared-form/utils'
import { type UseFormReturn } from 'react-hook-form'

import { useStageTaskField } from './use-stage-task-field'

type RelativeIntervalType = 'start' | 'due'

export type UseStageTaskRelativeIntervalFieldReturn = {
  value: TaskDefinitionFormRelativeInterval
  taskDuration: number | null
  onChange: (newValue: TaskDefinitionFormRelativeInterval) => void
  onRelativeIntervalUpdate: (lock: 'start' | 'due') => void
}

export function useStageTaskRelativeIntervalField(
  taskBasePath: `stages.${number}.tasks.${number}`,
  type: RelativeIntervalType
): UseStageTaskRelativeIntervalFieldReturn {
  const { form } = useFlowTemplateForm()
  const { watch } = form

  const fieldName =
    type === 'start' ? 'startRelativeInterval' : 'dueRelativeInterval'

  const [value, onChange] = useStageTaskField(`${taskBasePath}.${fieldName}`)

  const taskDuration = watch(`${taskBasePath}.duration`)

  const { stageIndex, taskIndex } = getDataFromFieldName(taskBasePath)
  if (stageIndex == null || taskIndex == null) {
    throw new Error('Stage index or task index not found')
  }

  const onRelativeIntervalUpdate = (lock: 'start' | 'due') => {
    handleTaskRelativeIntervalUpdate(form, stageIndex, taskIndex, lock)
  }

  return {
    value,
    taskDuration,
    onChange,
    onRelativeIntervalUpdate,
  }
}

const handleTaskRelativeIntervalUpdate = (
  form: UseFormReturn<FlowTemplateFormFields>,
  stageIndex: number,
  taskIndex: number,
  lock: 'start' | 'due'
) => {
  const stageDurationInDays = convertDateIntervalToDays(
    form.getValues(`stages.${stageIndex}.duration`)
  )

  const { duration: startDuration, referenceType: startReferenceType } =
    form.getValues(
      `stages.${stageIndex}.tasks.${taskIndex}.startRelativeInterval`
    )
  const { duration: dueDuration, referenceType: dueReferenceType } =
    form.getValues(
      `stages.${stageIndex}.tasks.${taskIndex}.dueRelativeInterval`
    )

  const adjustedDates = calculateAdjustedRelativeDurations(
    stageDurationInDays,
    { startDuration, dueDuration, startReferenceType, dueReferenceType, lock }
  )

  if (adjustedDates) {
    if (adjustedDates.start) {
      form.setValue(
        `stages.${stageIndex}.tasks.${taskIndex}.startRelativeInterval.duration`,
        {
          ...startDuration,
          ...adjustedDates.start,
        }
      )
    }

    if (adjustedDates.due) {
      form.setValue(
        `stages.${stageIndex}.tasks.${taskIndex}.dueRelativeInterval.duration`,
        {
          ...dueDuration,
          ...adjustedDates.due,
        }
      )
    }
  }
}
