import { FilledChevronDownSolid } from '@motion/icons'
import { Tag } from '@motion/ui/base'

import { type PropsWithChildren, useState } from 'react'
import { twMerge } from 'tailwind-merge'

export type CollapsableSectionProps = {
  title: string
  count?: number
}

export const CollapsableSection = ({
  title,
  count,
  children,
}: PropsWithChildren<CollapsableSectionProps>) => {
  const [expanded, setExpanded] = useState(true)

  return (
    <div>
      <button
        type='button'
        className='flex w-auto p-2 gap-2 items-center'
        onClick={() => void setExpanded(!expanded)}
      >
        <FilledChevronDownSolid
          className={twMerge('size-4', !expanded && '-rotate-90')}
        />

        <h4 className='text-semantic-neutral-text-default'>{title}</h4>

        {count != null && (
          <Tag size='small' variant='subtle'>
            {count}
          </Tag>
        )}
      </button>

      {expanded && <div className='pl-9'>{children}</div>}
    </div>
  )
}
