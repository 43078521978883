import { PlusSolid } from '@motion/icons'

import { LegacyTemplateCard } from '~/areas/flows'
import { MotionLink } from '~/global/components'
import { WorkspaceDropdown } from '~/global/components/dropdowns'
import { type PropsWithChildren } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { twMerge } from 'tailwind-merge'

export type NewLegacyTemplateLinkProps = {
  workspaceId: string | undefined
}

export const NewLegacyTemplateLink = ({
  workspaceId,
  children,
}: PropsWithChildren<NewLegacyTemplateLinkProps>) => {
  if (workspaceId == null) {
    return (
      <LegacyTemplateWorkspaceDropdown>
        {children}
      </LegacyTemplateWorkspaceDropdown>
    )
  }

  const url = generatePath(
    '/web/settings/workspace/:workspaceId/project-templates/project/new',
    { workspaceId }
  )

  return (
    <MotionLink url={url} title='Create a new project template'>
      {children}
    </MotionLink>
  )
}

export const NewLegacyTemplateCardLink = ({
  workspaceId,
}: NewLegacyTemplateLinkProps) => {
  return (
    <NewLegacyTemplateLink workspaceId={workspaceId}>
      <button className='w-full'>
        <LegacyTemplateCardContainer fullWidth />
      </button>
    </NewLegacyTemplateLink>
  )
}

export type LegacyTemplateCardContainerProps = {
  fullWidth?: boolean
}
const LegacyTemplateCardContainer = ({
  fullWidth,
}: LegacyTemplateCardContainerProps) => {
  return (
    <LegacyTemplateCard className={twMerge(fullWidth && 'w-full')}>
      <PlusSolid className='h-4 w-4 text-semantic-neutral-icon-default' />
      <h3 className='font-semibold text-sm truncate text-semantic-neutral-text-default'>
        New Regular Template
      </h3>
    </LegacyTemplateCard>
  )
}

const LegacyTemplateWorkspaceDropdown = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const navigate = useNavigate()
  return (
    <WorkspaceDropdown
      selectedWorkspaceId=''
      onChange={(workspace) =>
        navigate(
          generatePath(
            '/web/settings/workspace/:workspaceId/project-templates/project/new',
            { workspaceId: workspace.id }
          )
        )
      }
    >
      {children}
    </WorkspaceDropdown>
  )
}
