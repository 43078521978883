import { FilledChevronRightSolid, XSolid } from '@motion/icons'
import { API } from '@motion/rpc-definitions'
import { FieldTypes } from '@motion/shared/custom-fields'
import { classed } from '@motion/theme'
import { Button, ButtonGroup, LoadingSpinner } from '@motion/ui/base'
import {
  type EntityFilterState,
  type ProjectFilter,
  type TaskFilter,
  useActiveFilter,
} from '@motion/ui-logic/pm/data'
import { omit } from '@motion/utils/core'
import { entries } from '@motion/utils/object'

import { useIsFetching } from '@tanstack/react-query'

type ConnectedFiltersToggleProps = {
  open: boolean
  onClick(): void

  clearable?: boolean
  onClear?(): void
}

export const ConnectedFiltersToggle = (props: ConnectedFiltersToggleProps) => {
  const [filters] = useActiveFilter()

  const count = getFilterCount(filters)

  const sentiment = count > 0 ? 'primary' : 'neutral'

  const isFetching = useIsFetching({
    type: 'active',
    queryKey: API.tasksV2.queryKeys.query,
  })

  return (
    <ButtonGroup segmented>
      <Button
        sentiment={sentiment}
        variant='outlined'
        size='small'
        onClick={props.onClick}
      >
        {isFetching > 0 ? <LoadingSpinner /> : <Icon open={props.open} />}
        Filters ({count})
      </Button>
      {props.clearable && count > 0 && (
        <Button
          sentiment={sentiment}
          variant='outlined'
          size='small'
          onClick={props.onClear}
          iconOnly
        >
          <XSolid />
        </Button>
      )}
    </ButtonGroup>
  )
}

const OMIT_TASK_FILTERS_FROM_COUNT: (keyof TaskFilter)[] = [
  'completed',
  'archived',
  'canceled',
  'scheduledStatus',
]

const OMIT_PROJECT_FILTERS_FROM_COUNT: (keyof ProjectFilter)[] = ['completed']

function getFilterCount(filters: EntityFilterState): number {
  const { target } = filters

  if (target === 'projects') {
    return definedFilterValueCount(
      omit(filters.projects.filters, OMIT_PROJECT_FILTERS_FROM_COUNT)
    )
  }

  return (
    definedFilterValueCount(
      omit(filters.tasks.filters, OMIT_TASK_FILTERS_FROM_COUNT)
    ) +
    definedFilterValueCount(
      omit(filters.projects.filters, OMIT_PROJECT_FILTERS_FROM_COUNT)
    )
  )
}

function definedFilterValueCount<T extends Record<string, any>>(
  values: T | null | undefined
) {
  if (values == null) return 0

  return entries(values).reduce((acc, [key, value]) => {
    // Handle nested custom field values
    if (FieldTypes.includes(key as string) && Boolean(value)) {
      return entries(value).reduce((nestedAcc, [, v]) => {
        return v ? nestedAcc + 1 : nestedAcc
      }, acc)
    }

    return value ? acc + 1 : acc
  }, 0)
}

const Icon = classed(FilledChevronRightSolid, {
  base: 'transition-transform',
  variants: {
    open: {
      true: 'rotate-90',
    },
  },
  defaultVariants: {
    open: false,
  },
})
