import { useHasFeaturePermission } from '@motion/web-billing'

import { useProjects } from './hooks'
import { ProjectPlanner } from './project-planner'
import { UpgradeState } from './upgrade-state'

import { LoadingPage } from '../../pm-revamp/common/loading-page'
import { useProjectGroupBy, useViewState } from '../view-state'

export const ConnectedProjectPlanner = () => {
  const groupDefinitions = useProjectGroupBy()
  const [viewState] = useViewState()
  const projects = useProjects()
  const canUseGantt = useHasFeaturePermission('gantt')

  if (projects == null) {
    return <LoadingPage id='connected-project-planner' />
  }

  if (!canUseGantt) {
    return <UpgradeState feature='gantt' />
  }

  return (
    <ProjectPlanner
      projects={projects}
      groupBy={groupDefinitions}
      hideEmptyGroups={viewState.groupBy.hideEmpty}
    />
  )
}
