import { type NoteSchema } from '@motion/rpc-types'

import { useNoteLocation, useUpdateNoteLocation } from '~/areas/notes'
import {
  WorkspacesTreeDropdown,
  type WorkspacesTreeDropdownProps,
} from '~/global/components/dropdowns'
import { useFolders } from '~/global/rpc/folders'
import { useNoteById } from '~/global/rpc/v2'
import { type ReactNode } from 'react'

export type NoteLocationDropdownProps = {
  noteId: NoteSchema['id']
  children: ReactNode
  onClose?: () => void
  onChange?: WorkspacesTreeDropdownProps['onChange']
}

export function NoteLocationDropdown({
  noteId,
  children,
  onClose,
  onChange,
}: NoteLocationDropdownProps) {
  const { data: note } = useNoteById({ id: noteId })
  const updateNoteLocation = useUpdateNoteLocation({ noteId })
  const { data: folders } = useFolders()

  const [parentId, parentType] = useNoteLocation({
    noteId,
  })

  const isShared =
    parentType === 'FOLDER' &&
    folders?.models.systemFolders?.shared?.id === parentId

  const handleChange: WorkspacesTreeDropdownProps['onChange'] = (selected) => {
    updateNoteLocation(selected)
    onChange?.(selected)
  }

  // If it's a shared workspace note, then we don't want to show the dropdown.
  if (isShared && note?.targetType === 'WORKSPACE') {
    return null
  }

  return (
    <WorkspacesTreeDropdown
      selectedId={parentId ?? null}
      onChange={handleChange}
      onClose={onClose}
      allowSelectAnyNode
      hideNoProject
      includeNotes
    >
      {children}
    </WorkspacesTreeDropdown>
  )
}
