import { ClientValidationError } from '@motion/rpc'
import {
  convertFieldsForCreate,
  convertFormFieldsForUpdate,
  type TaskFormFields,
} from '@motion/ui-logic/pm/task'
import { Sentry } from '@motion/web-base/sentry'

import { checkTextRuleErrors, NAME_FIELD_RULE } from '~/global/rules'
import { showErrorToast } from '~/global/toasts'

import { useTaskForm } from './use-task-form'

import { type FormDataSubmitHandler } from '../types'
import { getDirtyFieldKeys } from '../utils'

type SubmitFormOptions = {
  onFormSubmit: FormDataSubmitHandler
}

export function useSubmitForm({ onFormSubmit }: SubmitFormOptions) {
  const { form } = useTaskForm()

  return async (data: TaskFormFields) => {
    try {
      if (!form.formState.isDirty) return

      const trimName = data.name.trim()
      const errorMessage = checkTextRuleErrors(trimName, NAME_FIELD_RULE)
      if (errorMessage) {
        throw new ClientValidationError(errorMessage)
      }

      // create
      if (data.id == null) {
        const updates = convertFieldsForCreate(data)
        await onFormSubmit({ type: 'create', data: updates })
        return
      }

      // update
      const dirtyKeys = getDirtyFieldKeys(form.formState.dirtyFields)
      const maybeDirtyCustomFields = Array.from(
        form.formState.dirtyFields?.customFieldValuesFieldArray ?? []
      )

      const updates = convertFormFieldsForUpdate(
        data,
        dirtyKeys,
        maybeDirtyCustomFields
      )

      await onFormSubmit({ type: 'update', data: updates })

      // reset dirty state when successful
      form.reset({}, { keepValues: true })
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          position: 'useSubmitForm',
        },
      })

      showErrorToast(e)
    }
  }
}
