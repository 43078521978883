import { DEFAULT_DOC_TITLE } from '@motion/ui-logic'

import {
  MentionComponentBase,
  MentionComponentLink,
} from '~/areas/notes/components'
import { NoteBadge } from '~/global/components/badges'
import { useNoteUrl } from '~/global/navigation'
import { useNoteById } from '~/global/rpc/v2'

type ConnectedNoteMentionProps = {
  id: string
}

export const ConnectedNoteMention = (props: ConnectedNoteMentionProps) => {
  const { data: note } = useNoteById({ id: props.id })
  const getNoteUrl = useNoteUrl()

  return (
    <MentionComponentLink url={getNoteUrl({ noteId: props.id }).noteUri}>
      <MentionComponentBase
        badge={<NoteBadge value={note} hideTooltip />}
        label={note?.title || DEFAULT_DOC_TITLE}
      />
    </MentionComponentLink>
  )
}
