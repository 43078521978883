import { CalendarSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'

import { useProjectDeadlineUpdater } from '~/areas/project/hooks'
import { DeadlineDropdown } from '~/areas/project-management/components'

import { type ProjectEtaPopoverContentProps } from './types'

import {
  PopoverExtendDeadlineContainer,
  PopoverExtendDeadlineText,
} from '../styled'

export const ProjectExtendDeadlineRow = ({
  project,
  onClose,
}: ProjectEtaPopoverContentProps) => {
  const updateProjectDeadline = useProjectDeadlineUpdater()

  const changeDeadline = (value: string) => {
    updateProjectDeadline(project, value)
    onClose()
  }

  const currentDate = new Date().toISOString()

  return (
    <div className='flex items-center justify-between'>
      <PopoverExtendDeadlineContainer>
        <CalendarSolid className='size-4' />
        <PopoverExtendDeadlineText>Extend deadline</PopoverExtendDeadlineText>
      </PopoverExtendDeadlineContainer>
      <DeadlineDropdown
        value={
          project.dueDate && project.dueDate > currentDate
            ? project.dueDate
            : currentDate
        }
        onChange={changeDeadline}
        hideTimeOptions
        contextProps={{ projectId: project.id }}
        dropdownTarget='project'
      >
        <Button sentiment='primary' variant='link'>
          Choose date
        </Button>
      </DeadlineDropdown>
    </div>
  )
}
