import { classed } from '@motion/theme'
import {
  DragHandle as DragHandleUI,
  type DragHandleProps,
} from '@motion/ui/base'

export const DragHandle = (props: DragHandleProps) => {
  return (
    <DragHandleContainer ref={props.setActivatorNodeRef}>
      <DragHandleUI {...props} />
    </DragHandleContainer>
  )
}

const DragHandleContainer = classed('div', {
  base: `
    absolute top-[-12px] left-[142px]
    rounded-[4px]
    rotate-90
    z-10
    border border-button-neutral-outlined-border-default
    bg-button-neutral-outlined-bg-default

    hover:bg-button-neutral-outlined-bg-hover
    hover:border-button-neutral-outlined-border-hover

    invisible
    group-hover/card:visible

    [&>div]:transition-none
  `,
})
