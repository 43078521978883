import { classed } from '@motion/theme'

export const ShellVars = classed('div', {
  base: `
    contents

    setvar-[aside-width=280px]
    modal-md:setvar-[aside-width=320px]
    modal-lg:setvar-[aside-width=380px]
  `,
})

export const FormShell = classed('form', {
  base: `
    bg-modal-bg
    rounded-lg
    shadow-lg
    border border-semantic-neutral-border-default

    w-[var(--modal-width)]
  `,
  variants: {
    columns: {
      1: `
        setvar-[modal-width=clamp(10px,calc(100vw-56px-var(--modal-offset,0px)),614px)]

        h-[687px]
        max-h-[100vh]
        modal-md:setvar-[modal-width=698px] modal-md:h-[769px]
        modal-lg:h-[819px]
        modal-xl:setvar-[modal-width=900px] modal-xl:h-[80vh] modal-xl:max-h-[1415px]
      `,
      2: `
        setvar-[modal-width=clamp(10px,calc(100vw-56px-var(--modal-offset,0px)),894px)]

        h-[687px]
        max-h-[100vh]
        modal-md:setvar-[modal-width=1018px] modal-md:h-[769px]
        modal-lg:setvar-[modal-width=1078px] modal-lg:h-[819px]
        modal-xl:setvar-[modal-width=1279px] modal-xl:h-[80vh] modal-xl:max-h-[1415px]
      `,
    },
  },
  defaultVariants: {
    columns: 1,
  },
})

export const GridShell = classed('div', {
  base: `
    h-full
    grid

    grid-rows-[min-content_1fr_min-content]
    `,
  variants: {
    layout: {
      meeting: `
        grid-cols-[auto_var(--aside-width,320px)]
        [grid-template-areas:'header_aside''main_aside''footer_aside']
      `,
      event: `
        grid-cols-1
        [grid-template-areas:'header''main''footer']
        `,
    },
  },
  defaultVariants: {
    layout: 'event',
  },
})

export const HeaderShell = classed('header', {
  base: `
    @container/header
    [grid-area:header]
    bg-palette-bg-lightest
    p-6
    flex gap-2.5 flex-col

    border-t-4
    border-palette-highlight-default
  `,
  variants: {
    layout: {
      meeting: 'rounded-tl-lg',
      event: 'rounded-t-lg',
    },
  },
})

export const EventTag = classed('div', {
  base: `
    px-2
    flex gap-1.5 items-center
    bg-palette-bg-default
    text-palette-text-subtle
    text-sm
    font-medium
    rounded-full

    [&_[data-icon]]:size-3
    [&_[data-icon]]:shrink-0
    [&_[data-icon]]:text-palette-highlight-default
  `,
})

export const MainSectionShell = classed('main', {
  base: `
    @container/main
    [grid-area:main]

    flex flex-col gap-3
    p-6
    overflow-auto
  `,
})

export const EventFieldsContainer = classed('section', {
  base: `
    grid gap-2

    @[500px]/main:grid-rows-1
    @[500px]/main:grid-cols-[minmax(0,1fr)_200px]
    @[500px]/main:gap-6
  `,
})

export const FooterShell = classed('footer', {
  base: `
    [grid-area:footer]
    py-2 px-3
    border-t border-t-semantic-neutral-border-default
    flex justify-between items-center
  `,
})
