import { useMemo } from 'react'

import { useAllProjectDefinitions } from './use-project-definition'
import { useAllWorkspaces } from './use-workspace-selectors'

export function useGroupedWorkspaceProjectDefinitions(workspaceId?: string) {
  const allWorkspaces = useAllWorkspaces()
  const allProjectDefinitions = useAllProjectDefinitions()

  return useMemo(
    () =>
      allWorkspaces
        .filter((workspace) =>
          workspaceId == null ? true : workspace.id === workspaceId
        )
        .map((workspace) => ({
          workspace,
          definitions: allProjectDefinitions.filter(
            (definition) => definition.workspaceId === workspace.id
          ),
        })),
    [allWorkspaces, workspaceId, allProjectDefinitions]
  )
}
