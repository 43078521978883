import { Elements } from '@stripe/react-stripe-js'
import { type Appearance, type Stripe } from '@stripe/stripe-js'

import { useTheme } from '../../../state/hooks'

const colors = {
  light: {
    300: '#F3F3F4',
    1100: '#747485',
    1200: '#1A1A20',
  },
  dark: {
    100: '#fff',
    400: '#A1A1A1',
    1100: '#262627',
  },
}

interface ElementsWrapperProps {
  stripe: Stripe | null
  clientSecret: string
  children: React.ReactNode
}

export const ElementsWrapper = ({
  children,
  clientSecret,
  stripe,
}: ElementsWrapperProps) => {
  const theme = useTheme()

  const appearance: Appearance = {
    variables: {
      colorPrimary: '#0570de',
      colorBackground: theme === 'light' ? colors.dark[100] : colors.dark[1100],
      colorText: theme === 'light' ? colors.light[1200] : colors.dark[100],
      colorTextPlaceholder:
        theme === 'light' ? colors.light[1100] : colors.dark[400],
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '2px',
      spacingGridRow: '12px',
      spacingGridColumn: '12px',
      borderRadius: '4px',
    },
  }

  return (
    <Elements
      key={clientSecret}
      options={{ clientSecret, appearance }}
      stripe={stripe}
    >
      {children}
    </Elements>
  )
}
