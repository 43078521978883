import {
  type DashboardViewCellSchema,
  type DashboardViewDefinitionV2,
  type StatusSchema,
} from '@motion/zod/client'

import { type PageType } from '~/areas/project-management/pages/pm-v3/routes/types'
import { v4 as uuid } from 'uuid'

import {
  baseCompletedHoursChart,
  baseNumberChart,
  baseProjectsPieChart,
  baseTaskFilters,
  baseTasksBarChart,
  baseTasksPieChart,
  getTopRowCharts,
} from './base-charts'

export type ViewEntityCtx = {
  completedStatuses: StatusSchema[]
  cancelledStatuses: StatusSchema[]
}

export function createDefaultDashboardViewDefinition(
  pageType: PageType,
  params: Record<string, string>,
  entityCtx: ViewEntityCtx
): DashboardViewDefinitionV2 {
  if (pageType === 'all-tasks') {
    return createAllTasks(entityCtx)
  }
  if (pageType === 'my-tasks') {
    return createDefaultMyTasksView(entityCtx)
  }
  if (params.projectId) {
    return createDefaultProjectView(entityCtx)
  }
  return createGeneric(entityCtx)
}

export function createBlankDashboard(): DashboardViewDefinitionV2 {
  return {
    $version: 2,
    type: 'dashboard',
    cells: [],
  }
}

function createTopRowCells(
  ctx: ViewEntityCtx,
  charts: Pick<DashboardViewCellSchema, 'title' | 'chart'>[] = getTopRowCharts(
    ctx
  )
) {
  return charts.map((chartConfig, index) => ({
    id: uuid(),
    x: index * 2,
    y: 0,
    width: 2,
    height: 1,
    ...chartConfig,
  })) as DashboardViewCellSchema[]
}

function createGeneric(ctx: ViewEntityCtx): DashboardViewDefinitionV2 {
  return {
    ...createBlankDashboard(),
    cells: [
      ...createTopRowCells(ctx),
      {
        id: uuid(),
        x: 0,
        y: 3,
        width: 12,
        height: 2,
        title: 'Tasks by assignee',
        chart: {
          ...baseTasksBarChart,
          groupBy: [
            {
              field: 'assigneeUserId',
              sort: {
                direction: 'desc',
                source: 'value',
              },
            },
            {
              field: 'deadlineStatus',
              sort: {
                direction: 'asc',
                source: 'name',
              },
            },
          ],
        },
      },
      {
        id: uuid(),
        x: 4,
        y: 1,
        width: 8,
        height: 2,
        title: 'Completed hours by week',
        chart: {
          ...baseCompletedHoursChart,
          groupBy: [
            {
              field: 'completedTime',
              by: 'day',
              range: {
                operator: 'defined-relative',
                name: 'this-week',
              },
            },
          ],
        },
      },
      {
        id: uuid(),
        x: 0,
        y: 1,
        width: 4,
        height: 2,
        title: 'Projects by ETA',
        chart: {
          ...baseProjectsPieChart,
          groupBy: [
            {
              field: 'deadlineStatus',
              sort: {
                direction: 'asc',
                source: 'name',
              },
            },
          ],
        },
      },
    ],
  }
}

function createAllTasks(ctx: ViewEntityCtx): DashboardViewDefinitionV2 {
  return {
    ...createBlankDashboard(),
    cells: [
      ...createTopRowCells(ctx),
      {
        id: uuid(),
        x: 4,
        y: 1,
        width: 4,
        height: 2,
        title: 'Tasks by workspace',
        chart: {
          ...baseTasksBarChart,
          groupBy: [
            {
              field: 'workspaceId',
              sort: {
                direction: 'asc',
                source: 'name',
              },
            },
            {
              field: 'deadlineStatus',
              sort: {
                direction: 'asc',
                source: 'name',
              },
            },
          ],
        },
      },
      {
        id: uuid(),
        x: 8,
        y: 1,
        width: 4,
        height: 2,
        title: 'Completed hours by week',
        chart: {
          ...baseCompletedHoursChart,
          groupBy: [
            {
              field: 'completedTime',
              by: 'week',
              range: {
                operator: 'defined-relative',
                name: 'this-month',
              },
            },
          ],
        },
      },
      {
        id: uuid(),
        x: 0,
        y: 1,
        width: 4,
        height: 2,
        title: 'Projects by ETA',
        chart: {
          ...baseProjectsPieChart,
          groupBy: [
            {
              field: 'deadlineStatus',
              sort: {
                direction: 'asc',
                source: 'name',
              },
            },
          ],
        },
      },
      {
        id: uuid(),
        x: 0,
        y: 3,
        width: 12,
        height: 2,
        title: 'Tasks by assignee',
        chart: {
          ...baseTasksBarChart,
          groupBy: [
            {
              field: 'assigneeUserId',
              sort: {
                direction: 'desc',
                source: 'value',
              },
            },
            {
              field: 'deadlineStatus',
              sort: {
                direction: 'asc',
                source: 'name',
              },
            },
          ],
        },
      },
    ],
  }
}

const createMyTasksTopRowCells = (ctx: ViewEntityCtx) => {
  const charts = getTopRowCharts(ctx).map((chart) => {
    if (chart.title === 'Tasks assigned to me') {
      return {
        title: 'Tasks assigned to me this week',
        chart: {
          ...baseNumberChart,
          filters: {
            ...baseTaskFilters,
            tasks: {
              ordered: ['assigneeUserIds', 'startDate', 'statusIds'],
              filters: {
                completed: 'include' as const,
                assigneeUserIds: {
                  operator: 'in' as const,
                  value: ['@me'],
                },
                startDate: {
                  operator: 'defined-relative' as const,
                  name: 'this-week' as const,
                },
                statusIds: {
                  operator: 'in' as const,
                  value: ctx.completedStatuses
                    .map((status) => status.id)
                    .concat(ctx.cancelledStatuses.map((status) => status.id)),
                  inverse: true,
                },
              },
            },
          },
        },
      }
    }

    // TODO replace 'Tasks scheduled this week' with 'Meeting hours this week' when supported

    return chart
  })

  return createTopRowCells(ctx, charts)
}

function createDefaultMyTasksView(
  ctx: ViewEntityCtx
): DashboardViewDefinitionV2 {
  return {
    ...createBlankDashboard(),
    cells: [
      ...createMyTasksTopRowCells(ctx),
      {
        id: uuid(),
        x: 4,
        y: 1,
        width: 4,
        height: 2,
        title: 'Scheduled hours by project',
        chart: {
          ...baseTasksBarChart,
          aggregate: {
            type: 'sum' as const,
            field: 'duration' as const, // TODO use scheduled hours when supported
          },
          layout: 'single',
          groupBy: [
            {
              field: 'projectId',
              sort: {
                direction: 'asc',
                source: 'value',
              },
            },
          ],
        },
      },
      {
        id: uuid(),
        x: 8,
        y: 1,
        width: 4,
        height: 2,
        title: 'Completed hours',
        chart: {
          ...baseCompletedHoursChart,
          groupBy: [
            {
              field: 'completedTime',
              by: 'day',
              range: {
                operator: 'defined-relative',
                name: 'this-week',
              },
            },
          ],
        },
      },
      {
        id: uuid(),
        x: 0,
        y: 1,
        width: 4,
        height: 2,
        title: 'Tasks by ETA',
        chart: {
          ...baseTasksPieChart,
          groupBy: [
            {
              field: 'deadlineStatus',
            },
          ],
        },
      },
    ],
  }
}

function createDefaultProjectView(
  ctx: ViewEntityCtx
): DashboardViewDefinitionV2 {
  return {
    ...createBlankDashboard(),
    cells: [
      ...createTopRowCells(ctx),
      {
        id: uuid(),
        x: 4,
        y: 1,
        width: 4,
        height: 2,
        title: 'Tasks by assignee',
        chart: {
          ...baseTasksBarChart,
          groupBy: [
            {
              field: 'assigneeUserId',
              sort: {
                direction: 'desc',
                source: 'value',
              },
            },
            {
              field: 'deadlineStatus',
              sort: {
                direction: 'asc',
                source: 'name',
              },
            },
          ],
        },
      },
      {
        id: uuid(),
        x: 8,
        y: 1,
        width: 4,
        height: 2,
        title: 'Completed hours by week',
        chart: {
          ...baseCompletedHoursChart,
          groupBy: [
            {
              field: 'completedTime',
              by: 'week',
              range: {
                operator: 'defined-relative',
                name: 'this-month',
              },
            },
            {
              field: 'projectId',
              sort: {
                direction: 'asc',
                source: 'name',
              },
            },
          ],
        },
      },
      {
        id: uuid(),
        x: 0,
        y: 1,
        width: 4,
        height: 2,
        title: 'Tasks by ETA',
        chart: {
          ...baseProjectsPieChart,
          item: 'tasks' as const,
          groupBy: [
            {
              field: 'deadlineStatus',
              sort: {
                direction: 'asc',
                source: 'name',
              },
            },
          ],
        },
      },
    ],
  }
}
