import { Sentry } from '@motion/web-base/sentry'
import {
  type ProjectSchema,
  type ProjectsV2AdvanceStageRequestSchema,
  type StageDefinitionSchema,
} from '@motion/zod/client'

import { useIsFlowProject } from '~/areas/flows'
import { useWorkspaceFns } from '~/global/hooks'
import { useAdvanceProjectStage as useAdvanceProjectStageMutation } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useSkipStagesConfirmModalFn } from './use-skip-stages-confirm-modal-fn'

/**
 * Flows M4 only
 */
export function useAdvanceProjectStage() {
  const { mutateAsync: advanceProjectStage } = useAdvanceProjectStageMutation()
  const { getStageDefinition } = useWorkspaceFns()
  const skipStagesConfirmModalFn = useSkipStagesConfirmModalFn()
  const isFlowProject = useIsFlowProject()

  return useCallback(
    async (
      project: ProjectSchema,
      nextStageDefinitionId: StageDefinitionSchema['id'],
      onConfirmed?: () => void
    ) => {
      try {
        if (!isFlowProject(project)) {
          throw new Error(
            `Advancing stages can only be done in project workflows`
          )
        }

        const stage = getStageDefinition(nextStageDefinitionId)
        if (stage == null) {
          throw new Error('Stage definition not found')
        }

        const strategy = (await skipStagesConfirmModalFn(
          project,
          nextStageDefinitionId
        )) as ProjectsV2AdvanceStageRequestSchema['strategy'] | false

        if (strategy === false) return false

        await advanceProjectStage({
          project,
          nextStageDefinitionId,
          strategy,
        })

        onConfirmed?.()

        return true
      } catch (e) {
        Sentry.captureException(e, {
          tags: { position: 'advance-project-stage' },
        })

        showErrorToast(e, 'There was an error advancing the project stage')
      }

      return false
    },
    [
      advanceProjectStage,
      getStageDefinition,
      isFlowProject,
      skipStagesConfirmModalFn,
    ]
  )
}
