import { FunnelOutline, XSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { IconButton, Shortcut, Tooltip, useShortcut } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { useSidebarSearchContext } from '~/areas/search/hook'
import { useState } from 'react'

import { SearchButton } from './components/search-button'
import { SearchInput } from './components/search-input'

import { useGlobalSidebarContext } from '../../hooks'

export function SidebarSearchField() {
  const [searchTarget, setSearchTarget] = useState<'global' | 'sidebar'>(
    'global'
  )
  const { searchQuery, setSearchQuery } = useSidebarSearchContext()
  const { selectHighlighted, updateHighlightedByDirection, clearHighlighted } =
    useGlobalSidebarContext()

  const modalApi = useModalApi()

  useShortcut(
    'Alt+KeyS',
    (event) => {
      recordAnalyticsEvent('SIDEBAR_NAV_SHORTCUT', {
        shortcut: 'search',
      })
      setSearchTarget('sidebar')
      event.preventDefault()
    },
    {
      ignoreInput: true,
    }
  )

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e

    if (key === 'ArrowDown' || key === 'ArrowUp') {
      e.preventDefault()

      return void updateHighlightedByDirection(key === 'ArrowUp' ? -1 : 1)
    }

    if (key === 'Enter') {
      e.preventDefault()

      return void selectHighlighted()
    }

    if (key === 'Escape') {
      e.preventDefault()
      e.currentTarget.blur()

      setSearchTarget('global')
      return void setSearchQuery('')
    }
  }

  return (
    <div className='flex items-center gap-1'>
      {searchTarget === 'global' ? (
        <SearchButton
          onClick={() =>
            modalApi.open('search', {
              initialQuery: searchQuery,
            })
          }
        />
      ) : (
        <SearchInput
          value={searchQuery}
          onChange={(value: string) => {
            setSearchQuery(value)
          }}
          onKeyDown={handleKeyDown}
        />
      )}

      <Tooltip
        renderContent={() =>
          searchTarget === 'global'
            ? templateStr('Search sidebar {{shortcut}}', {
                shortcut: <Shortcut shortcut='Alt+KeyS' />,
              })
            : null
        }
        placement='bottom'
      >
        <div className='[&_[data-icon]]:!text-semantic-neutral-border-hover'>
          <IconButton
            icon={searchTarget === 'global' ? FunnelOutline : XSolid}
            variant='muted'
            sentiment='neutral'
            onClick={() => {
              const newSearchTarget =
                searchTarget === 'global' ? 'sidebar' : 'global'

              recordAnalyticsEvent('SIDEBAR_SEARCH_TOGGLED')

              clearHighlighted()
              setSearchQuery('')
              setSearchTarget(newSearchTarget)
            }}
          />
        </div>
      </Tooltip>
    </div>
  )
}
