import { type StageDefinitionSchema } from '@motion/zod/client'

import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { clearStageTemplateSearchParams } from '../utils'

export const useRedirectToStageTemplate = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  return useCallback(
    (stageId: StageDefinitionSchema['id']) => {
      if (searchParams.size === 1 && searchParams.get('stageId') === stageId)
        return

      setSearchParams((prev) => {
        clearStageTemplateSearchParams(prev)
        if (prev.get('stageId') !== stageId) {
          prev.set('stageId', stageId)
          prev.set('stage', 'edit')
        }
        return prev
      })
    },
    [searchParams, setSearchParams]
  )
}
