import { type ProjectSchema } from '@motion/rpc-types'

import { useNonAutoSchedulableStatuses } from '~/global/hooks'
import { useMemo } from 'react'

import { useFetchProjectTasks } from './use-fetch-project-tasks'

type UseStageTasksToAutoScheduleProps = {
  project?: ProjectSchema | null
  stageDefinitionId: string
}

export function useStageTasksToAutoSchedule({
  project,
  stageDefinitionId,
}: UseStageTasksToAutoScheduleProps) {
  const { data: tasks } = useFetchProjectTasks({
    projectId: project?.id,
    workspaceId: project?.workspaceId,
    completed: 'exclude',
    canceled: 'exclude',
  })

  const autoScheduleDisabledStatusIds = useNonAutoSchedulableStatuses(
    project?.workspaceId
  ).map((status) => status.id)

  return useMemo(() => {
    return tasks.filter(
      (task) =>
        task.stageDefinitionId === stageDefinitionId &&
        !autoScheduleDisabledStatusIds.includes(task.statusId) &&
        task.assigneeUserId != null
    )
  }, [stageDefinitionId, tasks, autoScheduleDisabledStatusIds])
}
