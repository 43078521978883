import { TeamMemberRole } from '@motion/rpc/types'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { useIsTeamExpired, useTeamMembers } from '~/global/hooks/team'
import { selectTeam } from '~/state/team'
import { type ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router'

import { useAppSelector } from '../../../../state/hooks'
import { selectPMTeamFetched } from '../../../../state/projectManagementSlice'
import { selectEmail } from '../../../../state/userSlice'
import { GuestPage } from '../Pages/GuestPage/GuestPage'

type TeamPageWrapperProps = {
  children: ReactNode
}

export const TeamPageWrapper = ({ children }: TeamPageWrapperProps) => {
  const teamMembers = useTeamMembers()
  const team = useAppSelector(selectTeam)
  const { uid: userId } = useAuthenticatedUser()
  const userEmail = useAppSelector(selectEmail)
  const isExpiredTeam = useIsTeamExpired()
  const pmTeamFetched = useAppSelector(selectPMTeamFetched)
  const navigate = useNavigate()

  const modalApi = useModalApi()
  const shouldUseTieredPricing = useHasTreatment('tiered-pricing-v1')

  useEffect(
    function showTrialEndedModal() {
      if (isExpiredTeam && team) {
        modalApi.open('team-trial-ended', {
          doNotShowAgain: () => {
            navigate('/web/settings/calendar')
          },
          onStartTeamPlan: !shouldUseTieredPricing
            ? () => {
                modalApi.open('pay-team-form', {
                  userEmail,
                  team,
                  onSuccess: () => {
                    window.location.reload()
                  },
                })
              }
            : undefined,
        })
      }
    },
    [isExpiredTeam, modalApi, navigate, team, userEmail, shouldUseTieredPricing]
  )

  if (!userId || !team || !pmTeamFetched) {
    return null
  }

  const loggedInTeamMember = teamMembers.find(
    (teamMember) => teamMember.userId === userId
  )

  if (loggedInTeamMember?.role === TeamMemberRole.GUEST) {
    return <GuestPage teamName={team.name} />
  }

  return (
    <div className='bg-light-100 dark:bg-dark-1100 flex h-full w-full min-h-0'>
      {children}
    </div>
  )
}
