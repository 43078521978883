import { useOnce } from '@motion/react-core/hooks'
import { type AiGenerateProjectRequest } from '@motion/rpc-types'
import { groupByKey } from '@motion/utils/array'
import { Sentry } from '@motion/web-base/sentry'

import { useGenerateSpeculativeProject as useGenerateSpeculativeProjectRpc } from '~/global/rpc'

import { useSetupProjectForm } from './use-setup-project-form'

export type UseGenerateSpeculativeProjectResult = {
  isSuccess: boolean
}

type UseGenerateSpeculativeProjectArgs = {
  mode: 'manual' | 'ai'
}

export function useGenerateSpeculativeProject({
  mode,
}: UseGenerateSpeculativeProjectArgs): UseGenerateSpeculativeProjectResult {
  const mutation = useGenerateSpeculativeProjectRpc()
  const {
    form: { getValues, setValue },
  } = useSetupProjectForm()
  const { mutateAsync: generateSpeculativeProject } = mutation

  useOnce(async () => {
    if (mode === 'manual') return

    const roleAssignees = getValues('roleAssignees')
    const textReplacements = getValues('textReplacements')

    const getRoleAssignee = (variableId: string) =>
      roleAssignees.find(
        (roleAssignee) => roleAssignee.variableId === variableId
      )?.value

    const getTextReplacement = (variableId: string) =>
      textReplacements.find(
        (textReplacement) => textReplacement.variableId === variableId
      )?.value

    const stages = getValues('initialStages').map((stage) => ({
      ...stage,
      variableInstances: stage.variableInstances?.map((variableInstance) => {
        let value = variableInstance.value
        // Update value with role assignee or text replacement if available
        for (const getter of [getRoleAssignee, getTextReplacement]) {
          const update = getter(variableInstance.variableId)
          if (update !== undefined) {
            value = update
            break
          }
        }
        return { ...variableInstance, value }
      }),
    }))

    const payload = {
      projectDefinitionId: getValues('projectDefinition.id'),
      prompt: getValues('prompt')!,
      uploadedFileIds: getValues('uploadedFileIds'),
      workspaceId: getValues('workspaceId'),
      projectName: getValues('name'),
      projectDescription: getValues('description'),
      stages,
      generateModifications: true,
    } satisfies AiGenerateProjectRequest

    try {
      const result = await generateSpeculativeProject(payload)

      setValue('speculativeProject', result.speculativeProject)
      setValue('modifications', result.modifications)

      const speculativeTasksByStageDefinitionId = groupByKey(
        result.speculativeTasks,
        'stageDefinitionId'
      )
      setValue(
        'speculativeTasksByStageDefinitionId',
        speculativeTasksByStageDefinitionId
      )

      if (result.speculativeProject.startDate != null) {
        setValue('startDate', result.speculativeProject.startDate)
      }
      if (result.speculativeProject.dueDate != null) {
        setValue('dueDate', result.speculativeProject.dueDate)
      }
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          position: 'useGenerateSpeculativeProject',
        },
        extra: payload,
      })
      throw error
    }
  })

  return {
    isSuccess: mode === 'ai' ? mutation.isSuccess : true,
  }
}
