import { type ProjectSchema, type TaskSchema } from '@motion/zod/client'

import { useProjectUpdater } from '~/areas/project/hooks'
import { AssigneeDropdown } from '~/areas/project-management/components'
import { useTaskAssigneeData } from '~/areas/tasks/hooks'
import { ConnectedUserLabel } from '~/global/components/labels'
import { type ReactNode } from 'react'

import { MentionCardButton } from '../mention-card-button'

export type TaskAssigneeFieldProps = {
  task: TaskSchema
}
export const TaskAssigneeField = ({ task }: TaskAssigneeFieldProps) => {
  const { onChange, disabled } = useTaskAssigneeData(task)

  return (
    <AssigneeField
      selectedUserId={task.statusId}
      workspaceId={task.workspaceId}
      disabled={disabled}
      onChange={onChange}
    >
      <ConnectedUserLabel userId={task.assigneeUserId} />
    </AssigneeField>
  )
}

export type ProjectAssigneeFieldProps = {
  project: ProjectSchema
}

export const ProjectAssigneeField = ({
  project,
}: ProjectAssigneeFieldProps) => {
  const updateProject = useProjectUpdater()

  return (
    <AssigneeField
      selectedUserId={project.managerId}
      workspaceId={project.workspaceId}
      onChange={(userId) => updateProject(project, { managerId: userId })}
    >
      <ConnectedUserLabel userId={project.managerId} />
    </AssigneeField>
  )
}

type AssigneeFieldProps = {
  selectedUserId: TaskSchema['assigneeUserId']
  workspaceId: TaskSchema['workspaceId']
  onChange: (userId: TaskSchema['assigneeUserId']) => void
  disabled?: boolean
  children: ReactNode
}
const AssigneeField = ({
  selectedUserId,
  workspaceId,
  onChange,
  disabled,
  children,
}: AssigneeFieldProps) => {
  return (
    <AssigneeDropdown
      selectedUserId={selectedUserId}
      workspaceId={workspaceId}
      onChange={onChange}
    >
      <MentionCardButton disabled={disabled}>{children}</MentionCardButton>
    </AssigneeDropdown>
  )
}
