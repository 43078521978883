import { useLookup } from '~/global/cache'
import { useCallback } from 'react'

import { type UserFavoriteType } from '../types'

export const useFavoriteTypeLookup = () => {
  const lookup = useLookup()

  return useCallback(
    (type: Required<UserFavoriteType>, id: string) => {
      switch (type) {
        case 'FOLDER':
          return lookup('folders', id)
        case 'PROJECT':
          return lookup('projects', id)
        case 'VIEW':
          return lookup('views', id)
        case 'NOTE':
          return lookup('notes', id)
        default:
          return null
      }
    },
    [lookup]
  )
}
