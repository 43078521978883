import { MotionLink, type MotionLinkProps } from '~/global/components'
import { type CSSProperties, type ForwardedRef, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

const baseClassName = `
  relative rounded px-1 py-px
  text-semantic-neutral-text-default
  bg-gray-50 dark:bg-semantic-neutral-bg-subtle
  border border-semantic-neutral-border-subtle
  hover:bg-semantic-neutral-bg-active-hover
  hover:text-semantic-neutral-text-default
`
const style: CSSProperties = {
  WebkitBoxDecorationBreak: 'clone',
  boxDecorationBreak: 'clone',
}

export const MentionComponentLink = forwardRef(
  (
    props: Omit<MotionLinkProps, 'url'> & { url?: string },
    ref: ForwardedRef<HTMLAnchorElement>
  ) => {
    const { url, className, ...rest } = props
    if (!url) {
      return (
        <span className={twMerge(baseClassName, className)} style={style}>
          {props.children}
        </span>
      )
    }
    return (
      <MotionLink
        ref={ref}
        className={twMerge(baseClassName, className)}
        style={style}
        url={url}
        {...rest}
      />
    )
  }
)
