import {
  createQueryFilter,
  getCacheEntryValue,
  MODEL_CACHE_KEY,
  MotionCache,
} from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import {
  type UploadedFileSchema,
  type UploadFileRequest,
  type UploadFileResponse,
} from '@motion/rpc-types'
import { Sentry } from '@motion/web-base/sentry'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getProjectQueryFilters, getTaskQueryFilters } from '~/global/cache'
import { useFileUpload } from '~/global/hooks'
import { useCallback } from 'react'
import { v4 as uuid } from 'uuid'

import { useFileUploadState } from '../../contexts/file-upload-context'

const API_FILE_UPLOAD_URI = '/v2/files/attachment'

export type UseUploadAttachmentsParams = Omit<
  UploadFileRequest,
  'workspaceId'
> & {
  onUploadSettled?: (uploadedFile: UploadedFileSchema) => void
  workspaceId?: UploadFileRequest['workspaceId'] | null
}

export type UploadAttachmentsParams = {
  id: string
  file: File
}

export const useUploadAttachments = ({
  targetId,
  targetType,
  workspaceId,
  onUploadSettled,
}: UseUploadAttachmentsParams) => {
  const queryClient = useQueryClient()

  const { uploadFile } = useFileUpload()

  const {
    registerActiveFileUpload,
    trackActiveFileUploadProgress,
    unregisterActiveFileUpload,
  } = useFileUploadState()

  const { mutateAsync } = useMutation({
    mutationKey: API.files.queryKeys.uploadFile({
      targetId,
      targetType,
    }),
    mutationFn: async ({ id, file }: UploadAttachmentsParams) => {
      const data = new FormData()

      data.append('file', file)

      if (workspaceId) {
        data.append('workspaceId', workspaceId)
      }

      if (targetId && targetType) {
        data.append('targetId', targetId)
        data.append('targetType', targetType)
      }

      return uploadFile<UploadFileResponse>({
        method: 'POST',
        uri: API_FILE_UPLOAD_URI,
        data,
        options: {
          onUpload: (request) => {
            registerActiveFileUpload({
              tempId: id,
              targetId: targetId ?? null,
              targetType: targetType ?? null,
              workspaceId,
              fileName: file.name,
              progress: 0,
              request,
            })
          },
          onProgress: (e) => {
            const progress = Math.round((e.loaded / e.total) * 100)
            trackActiveFileUploadProgress(id, progress)
          },
        },
      })
    },
    onSettled: (data, error, { id }) => {
      unregisterActiveFileUpload(id)

      if (!data) return

      const uploadedFileId = Object.keys(data.models.uploadedFiles)[0]
      const uploadedFile = data.models.uploadedFiles[uploadedFileId]

      onUploadSettled?.(uploadedFile)
    },
    onSuccess: (data) => {
      // If the target is a temp ID we only inset the uploaded file into the cache
      if (!targetId) {
        MotionCache.upsert(
          queryClient,
          createQueryFilter([MODEL_CACHE_KEY, API.files.queryKeys.root]),
          {
            models: {
              uploadedFiles: data.models.uploadedFiles,
            },
          }
        )
        return
      }

      const uploadedFileId = Object.keys(data.models.uploadedFiles)[0]

      const modelName = targetType === 'TEAM_TASK' ? 'tasks' : 'projects'

      const taskQueryFilter = getTaskQueryFilters(targetId)
      const projectQueryFilter = getProjectQueryFilters(targetId)

      const queryFilter =
        targetType === 'TEAM_TASK' ? taskQueryFilter : projectQueryFilter

      const target = getCacheEntryValue(queryClient, modelName, targetId)

      if (!target || !('uploadedFileIds' in target)) {
        return
      }

      const currentUploadedFiles = target.uploadedFileIds || []

      MotionCache.upsert(queryClient, queryFilter, {
        models: {
          [modelName]: {
            [targetId]: {
              ...target,
              uploadedFileIds: [...currentUploadedFiles, uploadedFileId],
            },
          },
          uploadedFiles: data.models.uploadedFiles,
        },
      })

      queryClient.invalidateQueries(queryFilter)
    },
  })

  return useCallback(
    async (files: File[]) => {
      const results = await Promise.allSettled(
        files.map((file) => {
          return mutateAsync({
            id: uuid(),
            file,
          })
        })
      )

      const fileUploads = results
        .map((result, index) =>
          result.status === 'fulfilled'
            ? Object.values(result.value.models.uploadedFiles)
            : null
        )
        .filter(Boolean)
        .flat()

      const failedFileUploads = results
        .map((result, index) =>
          result.status === 'rejected' ? files[index] : null
        )
        .filter(Boolean) as File[]

      results.forEach((result, index) => {
        if (result.status === 'rejected') {
          const file = files[index]

          Sentry.captureException(
            `Failed to upload attachment: ${result.reason}`,
            {
              extra: {
                fileName: file.name,
                fileMimeType: file.type,
                fileSize: file.size,
                targetId,
                targetType,
                workspaceId,
              },
            }
          )
        }
      })

      return { fileUploads, failedFileUploads }
    },
    [targetId, targetType, workspaceId, mutateAsync]
  )
}
