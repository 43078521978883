import { ExternalLinkSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { RichTextContent, useRichTextEditor } from '@motion/ui/rte'
import { isNotetakerCompleted } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useUriByRouteId } from '~/routing'
import { useCallback } from 'react'

import { useEventModalState } from '../../contexts'

export function ConnectedNotetakerSummary() {
  const { meetingInsights, recurringMeetingInsights, close } =
    useEventModalState()
  const getRouteUri = useUriByRouteId()

  const noteId = meetingInsights?.noteId ?? recurringMeetingInsights?.noteId

  const editor = useRichTextEditor({
    className: 'h-full !text-semantic-neutral-text-default',
    placeholder: '',
    value: meetingInsights?.summary ?? 'No summary available for this meeting.',
    disabled: true,
  })

  const handleClick = useCallback(() => {
    recordAnalyticsEvent('NOTETAKER_DOC_EVENT_MODAL_CLICK', {
      type: 'summary',
    })
    close()
  }, [close])

  if (
    noteId == null ||
    !isNotetakerCompleted(meetingInsights) ||
    meetingInsights?.summary == null
  ) {
    return null
  }

  const meetingNoteUrl = getRouteUri('notes-detail', {
    noteId,
  })

  return (
    <div className='bg-semantic-neutral-bg-disabled text-semantic-neutral-text-default flex flex-col gap-2 px-3 py-2 rounded mb-7'>
      <div className='flex items-center justify-between'>
        <h3 className='font-semibold text-sm'>Meeting Summary</h3>

        <Button
          size='xsmall'
          url={meetingNoteUrl}
          variant='link'
          sentiment='neutral'
          onClick={handleClick}
        >
          <ExternalLinkSolid />
          Notes & Action Items
        </Button>
      </div>

      <RichTextContent
        disableScroll
        editor={editor}
        className='[&_ul]:!pl-5 [&_li::marker]:text-semantic-neutral-text-subtle [&_p]:!text-semantic-neutral-text-subtle'
      />
    </div>
  )
}
