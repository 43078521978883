import { CalendarSolid } from '@motion/icons'
import { StageAdjusterStrategy } from '@motion/shared/flows'
import { Button, Text } from '@motion/ui/base'
import { FieldLabel } from '@motion/ui/forms'
import { stageAdjusterFn } from '@motion/ui-logic/pm/project'
import { getDateButtonText } from '@motion/ui-logic/pm/task'

import { DeadlineDropdown } from '~/areas/project-management/components'
import { useController } from 'react-hook-form'

import {
  useSetupProjectDateRangeColors,
  useSetupProjectForm,
} from '../../../hooks'

export const ControlledAiProjectDeadlineField = () => {
  const { form } = useSetupProjectForm()

  const { control, setValue, getValues } = form

  const { field } = useController({
    name: 'dueDate',
    control,
  })

  const dateButtonString = getDateButtonText(field.value, {
    placeholder: 'None',
  })

  const stageDateRangeColors = useSetupProjectDateRangeColors()

  return (
    <FieldLabel label='Deadline' labelHidden>
      <DeadlineDropdown
        value={field.value}
        onChange={(value) => {
          if (value == null) return
          stageAdjusterFn({
            params: {
              startDate: getValues('startDate'),
              dueDate: getValues('dueDate'),
              stageDueDates: getValues('speculativeProject.stages'),
            },
            onAction: (adjuster) => {
              adjuster.prepareProjectAdjustment({
                strategy: StageAdjusterStrategy.DISTRIBUTE,
                target: 'due',
                value,
              })
            },
            onResult: ({ startDate, dueDate, stageDueDates }) => {
              const newStages = getValues('speculativeProject.stages').map(
                (stage) => {
                  const matchingStageDueDate = stageDueDates.find(
                    (stageDueDate) =>
                      stage.stageDefinitionId === stageDueDate.stageDefinitionId
                  )

                  if (matchingStageDueDate) {
                    return {
                      ...stage,
                      dueDate: matchingStageDueDate.dueDate,
                    }
                  }

                  return stage
                }
              )

              setValue('startDate', startDate)
              setValue('speculativeProject.stages', newStages)
              setValue('speculativeProject.dueDate', dueDate)
              field.onChange(dueDate)
            },
          })
        }}
        hideTimeOptions
        disableClearing
        contextProps={{ projectId: null }}
        dropdownTarget='project'
        overrides={{
          stageDateRangeColors,
        }}
      >
        <Button
          variant='outlined'
          sentiment='neutral'
          fullWidth
          alignment='left'
          size='small'
        >
          <CalendarSolid />
          <Text size='xs' nowrap>
            {dateButtonString}
          </Text>
        </Button>
      </DeadlineDropdown>
    </FieldLabel>
  )
}
