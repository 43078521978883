import { classed } from '@motion/theme'

import { StageRow } from './stage-row'

import { useSetupProjectForm } from '../../../hooks'

export const AiStageRows = () => {
  const {
    form: { watch },
  } = useSetupProjectForm()

  const speculativeProject = watch('speculativeProject')
  const speculativeTasksByStageDefinitionId = watch(
    'speculativeTasksByStageDefinitionId'
  )

  if (
    speculativeProject == null ||
    speculativeTasksByStageDefinitionId == null
  ) {
    return null
  }

  return (
    <StageRowWrapper>
      {speculativeProject.stages.map((stage, index) => {
        return (
          <StageRow
            key={stage.id}
            stage={stage}
            index={index}
            tasks={
              speculativeTasksByStageDefinitionId[stage.stageDefinitionId] ?? []
            }
          />
        )
      })}
    </StageRowWrapper>
  )
}

const StageRowWrapper = classed('div', {
  base: 'flex flex-col [&>div:not(:last-child)]:border-b [&>div:not(:last-child)]:border-b-semantic-neutral-border-subtle',
})
