import { type ProjectDefinitionStageDefinitionSchema } from '@motion/rpc-types'
import { MAX_NUM_STAGES } from '@motion/shared/flows'
import { classed } from '@motion/theme'
import { mapCustomFieldToFieldArrayWithValue } from '@motion/ui-logic'
import {
  type FlowTemplateFormFields,
  type FlowTemplateStage,
} from '@motion/ui-logic/pm/project'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import { useFlowsModalState } from '~/areas/flows/contexts'
import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { getNewStageData } from '~/areas/flows/utils'
import { useWorkspaceStatuses } from '~/global/hooks'
import { type MutableRefObject, useCallback } from 'react'
import { useFieldArray, type UseFieldArrayReturn } from 'react-hook-form'

import { AddStageColumn, AddStageSkinny } from './add-stage'
import { StageTaskColumn } from './stage-task-column'

export type StageColumnsProps = {
  scrollColumnsRef: MutableRefObject<HTMLDivElement | null>
  fieldArray: UseFieldArrayReturn<FlowTemplateFormFields, 'stages', 'id'>
}

export const StageColumns = ({
  scrollColumnsRef,
  fieldArray: { insert, remove },
}: StageColumnsProps) => {
  const {
    form: { watch, reset, control },
  } = useFlowTemplateForm()
  const stages = watch('stages')
  const numStages = stages.length

  const workspaceId = watch('workspaceId')
  const workspaceStatuses = useWorkspaceStatuses(workspaceId)
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(workspaceId)
  const customFieldValuesFieldArray = workspaceCustomFields.map((field) =>
    mapCustomFieldToFieldArrayWithValue(field, {})
  )

  const { uid: currentUserId } = useAuthenticatedUser()
  const { setDirtyTasksMap } = useFlowsModalState()

  const {
    insert: insertStageDefinitionReferences,
    remove: removeStageDefinitionReferences,
  } = useFieldArray({
    control,
    name: 'stageDefinitionReferences',
  })

  const handleAddStage = useCallback(
    (newStage: FlowTemplateStage, index: number) => {
      insert(index, newStage)

      // this is fine to cast,
      // it's a placeholder as BE will generate id and rank
      insertStageDefinitionReferences(index, {
        stageDefinitionId: newStage.id,
      } as ProjectDefinitionStageDefinitionSchema)

      /**
       * Modifying field array makes the whole form dirty.
       * We reset the form to mitigate that.
       */
      reset({}, { keepValues: true })

      /**
       * Mark new tasks as dirty if still using legacy stages
       */
      setDirtyTasksMap((prev) => ({
        ...prev,
        ...Object.fromEntries(newStage.tasks.map((task) => [task.id, true])),
      }))
    },
    [insert, insertStageDefinitionReferences, reset, setDirtyTasksMap]
  )

  const removeStage = useCallback(
    (index: number) => {
      remove(index)
      removeStageDefinitionReferences(index)
    },
    [remove, removeStageDefinitionReferences]
  )

  const createNewStage = () => {
    return getNewStageData({
      workspaceId,
      currentUserId,
      workspaceStatuses,
      customFieldValuesFieldArray,
    })
  }

  return (
    <StageColumnsContainer ref={scrollColumnsRef}>
      {stages.map((stage, i) => (
        <>
          <AddStageSkinny
            insert={() => {
              const newStage = getNewStageData({
                workspaceId,
                workspaceStatuses,
                currentUserId,
                customFieldValuesFieldArray,
              })

              handleAddStage(newStage, i)
            }}
          />
          <StageTaskColumn
            index={i}
            onRemove={() => removeStage(i)}
            scrollColumnsRef={scrollColumnsRef}
          />
        </>
      ))}
      {numStages <= MAX_NUM_STAGES && (
        <>
          <div />
          <AddStageColumn
            scrollColumnsRef={scrollColumnsRef}
            onAddStage={(newStage) => handleAddStage(newStage, numStages)}
            getNewStageData={createNewStage}
          />
        </>
      )}
    </StageColumnsContainer>
  )
}

const StageColumnsContainer = classed('div', {
  base: `
    flex-1
    grid grid-rows-1 grid-flow-col auto-cols-[36px_281px]
    gap-1.5
    overflow-y-hidden

    pt-[18px] px-5 pl-0.5
  `,
})
