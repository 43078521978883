import { classed } from '@motion/theme'

export const FormShell = classed('form', {
  base: `
    bg-modal-bg
    rounded-lg
    shadow-lg
    border border-semantic-neutral-border-default
    w-full h-full
  `,
})

export const GridShell = classed('div', {
  base: `
    h-full
    grid
    grid-cols-[264px_1fr]
    grid-rows-[1fr_min-content]
    [grid-template-areas:'sidebar_stages''footer_footer']
  `,
})

export const Sidebar = classed('div', {
  base: `
    [grid-area:sidebar]
    py-4 px-3
    flex flex-col gap-5

    overflow-x-hidden
    overflow-y-auto

    rounded-tl-lg
    bg-semantic-neutral-surface-raised-bg-subtlest
    border-r-[0.5px] border-semantic-neutral-border-default
  `,
})

export const SidebarSection = classed('div', {
  base: `
    flex flex-col gap-3
  `,
})

export const SidebarHeader = classed('div', {
  base: `
    flex flex-col gap-2
  `,
})

export const SidebarTitle = classed('h2', {
  base: `
    text-sm font-semibold text-semantic-neutral-text-default
    flex items-center gap-1.5
    pl-1
  `,
})

export const SidebarDescription = classed('p', {
  base: `
    text-xs text-semantic-neutral-text-subtle
    pl-1
  `,
})

export const SidebarSectionFields = classed('div', {
  base: `
    flex flex-col gap-0.5
  `,
})

export const StageContent = classed('div', {
  base: `
    [grid-area:stages]
    grid grid-rows-[16px_min-content]
    overflow-y-auto
    gap-2.5
    py-4 px-6
  `,
})

export const TasksContent = classed('div', {
  base: `
    flex flex-col gap-2 shrink-0
    h-full
    overflow-x-hidden
    pt-1.5
  `,
})

export const ShellFooter = classed('footer', {
  base: `
    [grid-area:footer]
    px-4
    flex items-center justify-between
    border-t-[0.5px] border-semantic-neutral-border-default
  `,
})

export const MainButtonFooter = classed('div', {
  base: `
    flex gap-3 items-center justify-end

    py-3
    `,
})
