import { useSearchParams } from '~/routing'
import { useParams } from 'react-router'

export function useNoteParams() {
  const { noteId } = useParams()

  const {
    block: motionId,
    threadId,
    commentId,
  } = useSearchParams<{
    block?: string
    threadId?: string
    commentId?: string
  }>()

  return { noteId, motionId, threadId, commentId }
}
