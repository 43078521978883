import { ExternalLinkSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { isMeetingTask } from '@motion/ui-logic/pm/task'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type ProjectSchema } from '@motion/zod/client'

import { useMoveTaskToWorkspaceProject } from '~/areas/tasks/hooks'
import { WorkspacesTreeDropdown } from '~/global/components/dropdowns'
import { ConnectedProjectLabel } from '~/global/components/labels'
import { useProjectModalUrl } from '~/global/navigation'
import { type TaskWithRelations } from '~/global/proxies'

import { CellAction } from './components'

type ProjectCellProps = {
  task: TaskWithRelations
}

export const ProjectCell = ({ task }: ProjectCellProps) => {
  const moveTaskTo = useMoveTaskToWorkspaceProject()

  const disabled =
    task.type === 'RECURRING_INSTANCE' || task.archivedTime != null

  return (
    <WorkspacesTreeDropdown
      selectedId={task.projectId}
      workspaceId={task.workspaceId}
      onChange={(selected) =>
        moveTaskTo(task.id, selected.workspaceId, selected.projectId)
      }
      hideNoProject={isMeetingTask(task)}
    >
      <CellAction disabled={disabled}>
        <ConnectedProjectLabel id={task.projectId} />
      </CellAction>
    </WorkspacesTreeDropdown>
  )
}

type OpenProjectButtonProps = {
  id: ProjectSchema['id']
}

export const OpenProjectButton = ({ id }: OpenProjectButtonProps) => {
  const buildProjectModalUrl = useProjectModalUrl()
  return (
    <Button
      sentiment='neutral'
      variant='muted'
      size='xsmall'
      url={buildProjectModalUrl({ project: id })}
      onClick={() => {
        recordAnalyticsEvent('PROJECT_MANAGEMENT_LIST_OPEN_PROJECT_CLICK')
      }}
    >
      <ExternalLinkSolid />
      Open project
    </Button>
  )
}
