import { TabList, type TabListProps } from '@motion/ui/base'
import { getTierIcon, useHasFeaturePermission } from '@motion/web-billing'
import { useHasTreatment } from '@motion/web-common/flags'

import { useWorkspaceById } from '~/global/hooks'
import { useUriByRouteId } from '~/routing'
import { matchPath } from 'react-router'
import { Outlet, useLocation } from 'react-router-dom'

import { SettingPageWrapper } from '../../../shared'

type WorkspaceSettingsProps = {
  workspaceId: string
}
export const WorkspaceSettings = ({ workspaceId }: WorkspaceSettingsProps) => {
  const hasTieredPricing = useHasTreatment('tiered-pricing-v1')
  const hasAIProjectCreationFeature =
    useHasFeaturePermission('aiProjectCreation')

  const workspace = useWorkspaceById(workspaceId)
  const getUri = useUriByRouteId()

  const location = useLocation()
  const pathName = matchPath(
    'web/settings/workspace/:workspaceId/:tab',
    location.pathname
  )
  const tab = pathName?.params?.tab

  if (!workspace) {
    // For the case right after you delete a workspace
    return null
  }

  const ProAiTierIcon = getTierIcon('PROAI')
  const showAIWorkflows = hasTieredPricing && hasAIProjectCreationFeature

  const tabs: TabListProps['items'] = [
    {
      value: 'overview',
      content: 'Overview',
      url: getUri('workspace-settings', { workspaceId }),
    },
    {
      value: 'statuses',
      content: 'Statuses',
      url: getUri('workspace-settings-statuses', { workspaceId }),
    },
    {
      value: 'project-templates',
      content: 'Project Templates',
      url: getUri('workspace-template-settings', { workspaceId }),
    },
    {
      value: 'task-templates',
      content: 'Task Templates',
      url: getUri('workspace-settings-task-templates', { workspaceId }),
    },
    {
      value: 'custom-fields',
      content: 'Custom Fields',
      url: getUri('workspace-settings-custom-fields', { workspaceId }),
    },
    {
      value: 'labels',
      content: 'Labels',
      url: getUri('workspace-settings-labels', { workspaceId }),
    },
    showAIWorkflows && {
      value: 'ai-workflows',
      content: (
        <div className='flex flex-row gap-1 items-center'>
          {ProAiTierIcon != null && <ProAiTierIcon className='size-4' />} AI
          Workflows
        </div>
      ),
      url: getUri('workspace-settings-ai-workflows', { workspaceId }),
    },
  ].filter(Boolean)

  function renderTabGroup() {
    return (
      <div className='flex px-12 items-start gap-4 self-stretch bg-calendar-bg-default border-b border-semantic-neutral-border-default'>
        <TabList activeValue={tab ?? 'overview'} items={tabs} />
      </div>
    )
  }

  return (
    <SettingPageWrapper
      className='gap-4'
      tabGroup={renderTabGroup()}
      title={workspace.name}
    >
      <Outlet />
    </SettingPageWrapper>
  )
}
