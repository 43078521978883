import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import {
  type StatusSchema,
  type V2UpdateTaskStatusSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { useUpdateStatus as useUpdateStatusCall } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useUpdateStatus = () => {
  const {
    mutateAsync: updateWorkspaceStatus,
    isPending: isLoading,
    isError,
  } = useUpdateStatusCall()

  return {
    updateStatus: useCallback(
      async (
        data: V2UpdateTaskStatusSchema & {
          id: StatusSchema['id']
          workspaceId: WorkspaceSchema['id']
        }
      ) => {
        if (data.name != null && data.name.trim().length < 1) {
          showToast('error', 'Statuses cannot be empty')
          return null
        }

        recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_WORKSPACE_STATUS')

        try {
          const { id: statusId, workspaceId, name, ...rest } = data
          const response = await updateWorkspaceStatus({
            workspaceId,
            statusId,
            name: name != null ? name.trim() : undefined,
            ...rest,
          })

          showToast('success', 'Status updated')

          return response.models[response.meta.model][response.id]
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              position: 'update-status',
            },
          })

          showErrorToast(e)
        }

        return null
      },
      [updateWorkspaceStatus]
    ),
    isLoading,
    isError,
  }
}
