import { LinkSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { ButtonGroup, IconButton, Shortcut, Tooltip } from '@motion/ui/base'
import { DEFAULT_DOC_TITLE, prettyDateDay } from '@motion/ui-logic'
import { type NoteResponseSchema } from '@motion/zod/client'

import { ConnectedFolderBreadcrumbs } from '~/areas/folders/components'
import { NoteBadge } from '~/global/components/badges'

import { highlightEmText } from '../../utils'
import {
  DateField,
  DescCommentContainer,
  FieldBar,
  GenericField,
  ResultItem,
  type ResultItemProps,
} from '../shared'

export interface NoteResultProps {
  item: NoteResponseSchema
  onClick: ResultItemProps['onClick']
  onCopyLink: () => void
}

export function NoteResult({ item, onClick, onCopyLink }: NoteResultProps) {
  const title = highlightEmText(item.name || DEFAULT_DOC_TITLE)

  return (
    <ResultItem
      renderIcon={() => (
        <NoteBadge value={{ color: item.color, title: item.name }} />
      )}
      variant='default'
      title={title}
      onClick={onClick}
      renderControls={() => {
        return (
          <ButtonGroup size='small'>
            <Tooltip
              asChild
              renderContent={() =>
                templateStr('Copy link {{shortcut}}', {
                  shortcut: <Shortcut shortcut='mod+l' />,
                })
              }
            >
              <IconButton
                icon={LinkSolid}
                size='small'
                variant='outlined'
                sentiment='neutral'
                aria-label='Copy task link'
                onClick={(evt) => {
                  evt.stopPropagation()
                  onCopyLink()
                }}
              />
            </Tooltip>
          </ButtonGroup>
        )
      }}
    >
      <FieldBar>
        <div className='[&_ul]:max-w-sm'>
          <ConnectedFolderBreadcrumbs
            leafNodeId={item.id}
            size='small'
            abbreviated='middle'
            omitLeaf
          />
        </div>

        {item.createdByUser !== null && (
          <GenericField>Created by {item.createdByUser.name}</GenericField>
        )}
        {item.updatedTime && (
          <DateField>Edited {prettyDateDay(item.updatedTime)}</DateField>
        )}
      </FieldBar>
      {item.text && (
        <DescCommentContainer>
          <div>{highlightEmText(item.text)}</div>
        </DescCommentContainer>
      )}
    </ResultItem>
  )
}
