import { getProjectInitialDueDate } from '@motion/ui-logic'
import { type SetupProjectFormFields } from '@motion/ui-logic/pm/project'
import { Sentry } from '@motion/web-base/sentry'
import { type ProjectsV2CreateRequestSchema } from '@motion/zod/client'

import {
  useMyTasksWorkspace,
  useProject,
  useProjectDefinition,
  useProjectDefinitionWithStages,
} from '~/global/hooks'
import { useSearchParams } from '~/routing'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'

import { useCustomFieldFormValues } from './use-custom-field-form-values'
import { useProjectDefinitionDerivedFormDataFn } from './use-project-definition-derived-form-data-fn'

import {
  useProjectModalUrlParams,
  useTaskPrefillData,
} from '../../project-modal/hooks'
import { type SetupProjectSearchParams } from '../utils'

export function useInitialFormData(): SetupProjectFormFields {
  const { flowTemplateId, forTaskId } =
    useSearchParams<SetupProjectSearchParams>()
  const {
    forStartDate,
    forDueDate,
    forProjectId,
    workspaceId: workspaceIdParam,
  } = useProjectModalUrlParams()

  const getProjectDefinitionDerivedFormData =
    useProjectDefinitionDerivedFormDataFn()

  const defaultWorkspace = useMyTasksWorkspace()

  // flowTemplateId (and projectDefinition) should always be defined, except very briefly when closing the modal.
  const projectDefinitionWithoutStages = useProjectDefinition(flowTemplateId)
  const projectDefinition = useProjectDefinitionWithStages(
    projectDefinitionWithoutStages
  )

  const workspaceId =
    projectDefinition?.workspaceId ?? workspaceIdParam ?? defaultWorkspace?.id
  if (workspaceId == null) {
    throw new Error('Workspace id not defined')
  }

  const customFieldValuesFieldArray = useCustomFieldFormValues(
    workspaceId,
    projectDefinition
  )

  const forProject = useProject(forProjectId)

  const today = DateTime.now().startOf('day')

  // Prefill the setup project form with some data from a task, if provided
  const taskData = useInternalTaskPrefill(forTaskId)

  const startDateTime = useMemo(() => {
    if (taskData?.startDate) {
      return DateTime.fromISO(taskData.startDate)
    }

    if (forStartDate != null) {
      try {
        const parsedDateTime = DateTime.fromISO(forStartDate)
        return parsedDateTime >= today ? parsedDateTime : today
      } catch (err) {
        return today
      }
    }

    return today
  }, [forStartDate, taskData?.startDate, today])

  const maybeDeadlineDateTime = useMemo(() => {
    if (taskData?.dueDate) {
      return DateTime.fromISO(taskData.dueDate)
    }

    if (forDueDate != null) {
      try {
        const parsedDateTime = DateTime.fromISO(forDueDate)
        return parsedDateTime >= startDateTime.plus({ days: 1 })
          ? parsedDateTime
          : undefined
      } catch (err) {
        return undefined
      }
    }
  }, [forDueDate, startDateTime, taskData?.dueDate])

  return useMemo(() => {
    const baseFormData = {
      workspaceId,
      projectDefinitionId: projectDefinition?.id ?? null,
      projectDefinition,
      name: taskData?.name ?? forProject?.name ?? '',
      startDate: startDateTime.toISODate(),
      dueDate:
        maybeDeadlineDateTime?.toISODate() ??
        // Shouldn't happen
        getProjectInitialDueDate(),
      stageDueDates: [],
      roleAssignees: [],
      textReplacements: [],
      customFieldValuesFieldArray,
      customFieldSyncInstanceIds: [],
    } satisfies SetupProjectFormFields

    if (projectDefinition == null) {
      return baseFormData
    }

    const { stageDueDates, roleAssignees, textReplacements, dueDate } =
      getProjectDefinitionDerivedFormData(
        projectDefinition,
        startDateTime,
        maybeDeadlineDateTime
      )

    return {
      ...baseFormData,
      dueDate: dueDate ?? baseFormData.dueDate,
      stageDueDates,
      roleAssignees,
      textReplacements,
    }
  }, [
    projectDefinition,
    taskData?.name,
    forProject?.name,
    startDateTime,
    maybeDeadlineDateTime,
    getProjectDefinitionDerivedFormData,
    customFieldValuesFieldArray,
    workspaceId,
  ])
}

function useInternalTaskPrefill(forTaskId: string | undefined) {
  const getTaskPrefillData = useTaskPrefillData()
  const [taskData, setTaskData] =
    useState<ProjectsV2CreateRequestSchema | null>(null)

  useEffect(() => {
    if (forTaskId != null) {
      getTaskPrefillData(forTaskId)
        .then((data) => {
          setTaskData(data)
        })
        .catch((error) => {
          Sentry.captureException(error, {
            tags: {
              position: 'useInternalTaskPrefill',
            },
          })
        })
    }
  }, [forTaskId, getTaskPrefillData])

  return taskData
}
