import { isPlaceholderId } from '@motion/shared/identifiers'
import {
  convertFormStagesToStageDefinition,
  type FlowTemplateFormFields,
  stripVirtualAndSystemVariables,
} from '@motion/ui-logic/pm/project'
import {
  type CreateStageDefinitionRequestSchema,
  type UpdateStageDefinitionRequestSchema,
} from '@motion/zod/client'

import { useFlowsModalState } from '~/areas/flows/contexts'
import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { useCallback } from 'react'

import {
  useCreateStageDefinition,
  useStageUpdateConfirmation,
  useUpdateStageDefinition,
} from '../../hooks'

export function useSaveStage() {
  const {
    form: { reset, watch },
  } = useFlowTemplateForm()
  const { setDirtyTasksMap } = useFlowsModalState()

  const updateStageDefinition = useUpdateStageDefinition()
  const createStageDefinition = useCreateStageDefinition()
  const confirmStageUpdate = useStageUpdateConfirmation()

  const stageId = watch('id')
  const workspaceId = watch('workspaceId')

  return useCallback(
    async (fields: FlowTemplateFormFields) => {
      if (workspaceId == null) {
        throw new Error('Workspace id not defined')
      }

      if (stageId != null && !isPlaceholderId(stageId)) {
        const shouldUpdate = await confirmStageUpdate(stageId, workspaceId)
        if (!shouldUpdate) {
          return false
        }

        const updates = convertFormFieldsForUpdate(fields, stageId)
        if (!updates) {
          throw new Error('Stage not found')
        }

        const updatedStage = await updateStageDefinition(
          stageId,
          workspaceId,
          updates
        )

        reset({}, { keepValues: true })
        setDirtyTasksMap({})

        return updatedStage
      }

      const createPayload = convertFieldsForCreate(fields)
      const createdStage = await createStageDefinition(createPayload)

      reset({}, { keepValues: true })
      setDirtyTasksMap({})

      return createdStage
    },
    [
      createStageDefinition,
      confirmStageUpdate,
      reset,
      setDirtyTasksMap,
      stageId,
      updateStageDefinition,
      workspaceId,
    ]
  )
}

function convertFieldsForCreate(
  fields: FlowTemplateFormFields
): CreateStageDefinitionRequestSchema {
  const stageDefinition = convertFormStagesToStageDefinition(
    fields.stages,
    {}
  )[0]
  const strippedStageDefinition =
    stripVirtualAndSystemVariables(stageDefinition)

  return {
    definition: {
      ...strippedStageDefinition,
      name: fields.name,
      color: fields.color,
    },
  }
}

function convertFormFieldsForUpdate(
  fields: FlowTemplateFormFields,
  stageId: string
): UpdateStageDefinitionRequestSchema | null {
  const stageToUpdate = fields.stages.find((stage) => stage.id === stageId)
  if (!stageToUpdate) return null

  const stageDefinition = convertFormStagesToStageDefinition(
    [stageToUpdate],
    {}
  )[0]
  const strippedStageDefinition =
    stripVirtualAndSystemVariables(stageDefinition)

  return {
    definition: {
      ...strippedStageDefinition,
      name: fields.name,
      color: fields.color,
    },
  }
}
