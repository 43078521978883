import {
  createQueryFilter,
  getCacheEntryValue,
  MODEL_CACHE_KEY,
  MotionCache,
  type OptimisticUpdateValue,
} from '@motion/rpc-cache'
import { API } from '@motion/rpc-definitions'
import { type CustomFieldSchema } from '@motion/shared/custom-fields'
import { isEqual } from '@motion/utils/core'
import { type UpdateSelectFieldSchema } from '@motion/zod/client'

import { type QueryClient } from '@tanstack/react-query'

const CUSTOM_FIELD_KEYS_TO_UPDATE = [
  API.workspacesV2.queryKeys.root,
  MODEL_CACHE_KEY,
]

export function getCustomFieldQueryFilters() {
  return createQueryFilter(CUSTOM_FIELD_KEYS_TO_UPDATE)
}

function applyCustomFieldCacheUpdates(
  client: QueryClient,
  customFieldId: string,
  updates: Partial<Pick<UpdateSelectFieldSchema, 'metadata' | 'name'>>
): OptimisticUpdateValue {
  const { rollback } = MotionCache.patch(
    client,
    getCustomFieldQueryFilters(),
    'customFields',
    {
      [customFieldId]: updates,
    }
  )

  return {
    withRollback<T>(p: Promise<T>) {
      return p.catch((ex) => {
        rollback()
        throw ex
      })
    },

    rollback,
  }
}

export function updateCacheCustomFields(
  client: QueryClient,
  customField: Partial<CustomFieldSchema> & { id: string }
) {
  const cachedField = getCacheEntryValue(client, 'customFields', customField.id)

  const { metadata, name, id } = customField as any

  let cacheUpdates

  if (
    cachedField != null &&
    (cachedField.name !== name ||
      !isEqual((cachedField as any).metadata, metadata))
  ) {
    // Update internal caches
    cacheUpdates = applyCustomFieldCacheUpdates(client, id, {
      ...(metadata !== undefined && { metadata }),
      ...(name !== undefined && { name }),
    })
  }

  return { cacheUpdates }
}
