import { classed } from '@motion/theme'

import {
  ConnectedInlineAssigneeField,
  ConnectedInlineDeadlineField,
  ConnectedInlineDurationField,
  ConnectedInlineWorkspaceProjectField,
} from '../inline-fields'
import { ConnectedInlineAutoscheduleField } from '../inline-fields/inline-autocomplete-field'
import { useActionItemContext } from '../provider'

export function ActionItemInlineSection() {
  const { isRejected } = useActionItemContext()

  if (isRejected) {
    return null
  }

  return (
    <div className='flex flex-wrap gap-0.5 ml-4 items-center'>
      <ConnectedInlineWorkspaceProjectField />
      <Divider />
      <ConnectedInlineDurationField />
      <Divider />
      <ConnectedInlineDeadlineField />
      <Divider />
      <ConnectedInlineAssigneeField />
      <Divider />
      <ConnectedInlineAutoscheduleField />
    </div>
  )
}

const Divider = classed('div', {
  base: 'h-3 w-px bg-semantic-neutral-border-strong rounded-full self-center',
})
