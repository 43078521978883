import { type FlowTemplateStage } from '@motion/ui-logic/pm/project'
import { type TaskDefinitionSchema } from '@motion/zod/client'

import { type UniqueIdentifier } from '@dnd-kit/core'

export function stripBlockers(
  activeTaskId: UniqueIdentifier,
  stages: FlowTemplateStage[],
  blockedByTasks: TaskDefinitionSchema[],
  blockingTasks: TaskDefinitionSchema[]
): FlowTemplateStage[] {
  return stages.map((stage) => ({
    ...stage,
    tasks: stage.tasks.map((task) => {
      const blockedByTaskIds = task.blockedByTaskIds.filter((id) => {
        // For the active task, filter out task IDs that were blocked by the
        // active task
        if (
          task.id === activeTaskId &&
          blockedByTasks.find((blockedByTask) => id === blockedByTask.id)
        ) {
          return false
        }

        // For every task, filter out task IDs that were blockers of the
        // active task
        if (
          id === activeTaskId &&
          blockingTasks.find((blockingTask) => blockingTask.id === task.id)
        ) {
          return false
        }

        return true
      })

      return {
        ...task,
        blockedByTaskId: blockedByTaskIds.length ? blockedByTaskIds[0] : null,
        blockedByTaskIds,
      }
    }),
  }))
}
