import { HtmlProvider } from '@motion/web-common/html'
import { ModalApiProvider } from '@motion/web-common/modals'
import { ListenForFirestoreChanges } from '@motion/web-common/settings'

import { GlobalModalContainer } from '~/components/ModalHandlers/global-modal-container'
import { ConnectedSupportPage } from '~/components/Settings/LogInAsAnotherUserPage'
import { AppWorkspaceContextProvider, I18NProvider } from '~/global/contexts'
import { type ReactNode } from 'react'

import { EnsureAuthenticated } from './ensure-authenticated'
import { EnsureWeb } from './ensure-web'
import { MaintenanceModeGuard } from './maintenance-mode/maintenance-mode-guard'

import { EnsureOnboarded } from '../onboarding/ensure-onboarded'
import { SyncMotionLocalStorageToFirebase } from '../settings/firebase'
import { EnsureMotionLocalStorageLoaded } from '../settings/firebase/ensure-motion-local-storage-loaded'

type AppShellProps = {
  children: ReactNode
}

export const AppShell = (props: AppShellProps) => {
  if (__QA_ENABLED__) {
    if (location.pathname === '/web/support') {
      return (
        <ModalApiProvider>
          <ConnectedSupportPage />
        </ModalApiProvider>
      )
    }
  }

  return (
    <HtmlProvider defaultTitle='Motion' titleSuffix=' - Motion'>
      <I18NProvider>
        <ModalApiProvider>
          <GlobalModalContainer />
          <MaintenanceModeGuard>
            <EnsureAuthenticated>
              <ListenForFirestoreChanges />
              <SyncMotionLocalStorageToFirebase />
              <EnsureMotionLocalStorageLoaded>
                <AppWorkspaceContextProvider>
                  <EnsureOnboarded>
                    <EnsureWeb>{props.children}</EnsureWeb>
                  </EnsureOnboarded>
                </AppWorkspaceContextProvider>
              </EnsureMotionLocalStorageLoaded>
            </EnsureAuthenticated>
          </MaintenanceModeGuard>
        </ModalApiProvider>
      </I18NProvider>
    </HtmlProvider>
  )
}
