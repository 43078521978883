import { selectEmailAccounts } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'

import { useMainCalendar } from './use-calendars'

export const useInNoExternalCalendarsMode = () => {
  const emailAccounts = useAppSelector(selectEmailAccounts)
  const { mainCalendar } = useMainCalendar()
  return {
    noExternalCalendarsMode: !emailAccounts.length || !mainCalendar,
    mainCalendar,
  }
}
