import { MotionLogo } from '@motion/icons'
import { classed } from '@motion/theme'
import { Text } from '@motion/ui/base'

import { Paragraph } from '../../../components/Common'

type MaintenanceModePageV2Props = {
  title?: string
  subtitle?: string
}

const ClassedMotionLogo = classed(
  MotionLogo as unknown as React.FunctionComponent,
  {
    base: 'w-32 h-10 mb-8 dark:[&>path:nth-child(4)]:fill-white',
  }
)

export const MaintenanceModePageV2 = ({
  title = 'Motion is under maintenance',
  subtitle = 'We’re working on improving Motion. We’ll be back online in 30 minutes. Thank you for your patience!',
}: MaintenanceModePageV2Props) => {
  return (
    <div className='bg-calendar-bg-default fixed flex h-full w-full flex-col items-center justify-center'>
      <ClassedMotionLogo />
      <div className='text-center font-semibold'>
        <Paragraph className='text-2xl text-semantic-neutral-text-default'>
          {title}
        </Paragraph>
        <Text sentiment='subtle' className='mt-4 w-[436px]'>
          {subtitle}
        </Text>
      </div>
    </div>
  )
}
