import { PlusSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { PopoverTrigger, Tooltip } from '@motion/ui/base'
import { byProperty, ordered } from '@motion/utils/array'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import {
  horizontalListSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable'
import { type Column, type Header, type Table } from '@tanstack/react-table'
import { useMemo } from 'react'

import { ColumnHeader, type ColumnHeaderProps } from './header'

import { VisibilityDropdownContent, type VizField } from '../../components'
import { type ViewStateSortBy } from '../../view-state'
import { TableRow } from '../components'

export type HeaderRowProps = {
  table: Table<any>
  sortedBy?: ViewStateSortBy
}

export const HeaderRow = (props: HeaderRowProps) => {
  const { table, sortedBy } = props

  const sensors = useSensors(useSensor(PointerSensor))

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event
    if (over == null) return
    if (active.id === over.id) return

    if (over.id === 'row' || over.id === 'name') return

    moveColumn(table, String(active.id), String(over.id))
  }

  const updateColumnVisibility: ColumnHeaderProps['updateColumnVisibility'] = (
    columnId,
    visibility
  ) => {
    table.setColumnVisibility((prev) => ({
      ...prev,
      [columnId]: visibility,
    }))
  }

  const columns = table.getAllColumns()
  const columnOrder = table.getState().columnOrder
  const headers = table.getFlatHeaders()
  const sortedColumns = useMemo(
    () => columns.sort(byProperty('id', ordered(columnOrder))),
    [columnOrder, columns]
  )

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      autoScroll={{
        enabled: true,
        threshold: { x: 0.2, y: 0 },
      }}
    >
      <TableRow
        role='row'
        className='border-pivot-table-header-row-border sticky top-0 z-[2] row-start-1 border-y'
        style={{ gridTemplateColumns: 'var(--col-template)' }}
      >
        {headers.map((header, index) => {
          const leftHeader: Header<any, unknown> | undefined =
            headers[index - 1]
          const rightHeader: Header<any, unknown> | undefined =
            headers[index + 1]

          const canMoveColumn = canReorderOverHeader(header)
          const canMoveToLeft =
            canMoveColumn && canReorderOverHeader(leftHeader)
          const canMoveToRight =
            canMoveColumn && canReorderOverHeader(rightHeader)
          const isLastColumn = index === headers.length - 1

          const sorted =
            sortedBy?.field === header.column.id
              ? sortedBy.direction
              : undefined

          return (
            <SortableContext
              key={header.id}
              items={headers}
              strategy={horizontalListSortingStrategy}
            >
              <ColumnHeader
                header={header}
                moveToLeft={
                  canMoveToLeft
                    ? () => moveColumn(table, header.id, leftHeader.id)
                    : undefined
                }
                moveToRight={
                  canMoveToRight
                    ? () => moveColumn(table, header.id, rightHeader.id)
                    : undefined
                }
                updateColumnVisibility={updateColumnVisibility}
                isLastColumn={isLastColumn}
                sorted={sorted}
              />
            </SortableContext>
          )
        })}
        {/*
          ugly hack to make the '+' show up all the time without taking any space within the real rows
          The gridColumn is the same as the last real column, so the grid shows one over the other
          Moving the button outside the table requires playing with an extra div container to set things properly and the overflow is messed up somehow
        */}

        <div
          className='pointer-events-none sticky -right-px top-0 text-right h-6'
          style={{ gridRow: 1, gridColumn: headers.length }}
        >
          <PlusContainer>
            <VizPlusPopover
              columns={sortedColumns}
              onUpdate={updateColumnVisibility}
              onOrderChange={(newColumns) => {
                table.setColumnOrder(['name', ...newColumns.map((i) => i.id)])
              }}
            />
          </PlusContainer>
        </div>
      </TableRow>
    </DndContext>
  )
}

function canReorderOverHeader(header: Header<any, unknown> | undefined) {
  if (header == null) return false
  return !(header.column.columnDef.meta?.disableReordering ?? false)
}

function moveColumn(table: Table<any>, id: string, target: string) {
  const originalOrder = table.getState().columnOrder
  const order = [...originalOrder]

  const idIndex = order.indexOf(id)
  if (idIndex === -1) {
    if (__IS_DEV__) {
      // eslint-disable-next-line no-console
      console.error(`Column with id not found in column order`, { id, order })
    } else {
      Sentry.captureException(
        new Error(`Column with id not found in column order`),
        {
          extra: { id, order },
        }
      )
    }
    return
  }

  order.splice(order.indexOf(target), 0, order.splice(idIndex, 1)[0])
  table.setColumnOrder(order)
}

const PlusContainer = classed('div', {
  base: `
    pointer-events-auto
    inline-flex items-stretch h-full
  `,
})

type VizPlusPopoverProps = {
  columns: Column<any, unknown>[]
  onUpdate: ColumnHeaderProps['updateColumnVisibility']
  onOrderChange: (newOrder: VizField[]) => void
}

const VizPlusPopover = ({
  columns,
  onUpdate,
  onOrderChange,
}: VizPlusPopoverProps) => {
  return (
    <PopoverTrigger
      placement='bottom-end'
      renderPopover={() => (
        <VisibilityDropdownContent
          items={columns
            .map((col) =>
              col.id === 'name'
                ? null
                : {
                    id: col.id,
                    visible: col.getIsVisible(),
                    name: col.columnDef.meta?.name ?? 'unknown',
                  }
            )
            .filter(Boolean)}
          onOrderChange={(newOrder) => {
            onOrderChange(newOrder)
            recordAnalyticsEvent('PROJECT_MANAGEMENT_FIELD_VIZ_REORDER', {
              type: 'list',
            })
          }}
          toggleFieldVisibility={(columnId, visible) => {
            onUpdate(columnId, visible)
            recordAnalyticsEvent('PROJECT_MANAGEMENT_FIELD_VIZ_TOGGLE', {
              type: 'list',
              visible,
            })
          }}
        />
      )}
    >
      <AddColumnButton
        onClick={() => {
          recordAnalyticsEvent('PROJECT_MANAGEMENT_FIELD_VIZ_DROPDOWN_OPEN', {
            type: 'list',
          })
        }}
      >
        <Tooltip asChild content='Show columns'>
          <PlusSolid />
        </Tooltip>
      </AddColumnButton>
    </PopoverTrigger>
  )
}

const AddColumnButton = classed('button', {
  base: `
    flex justify-end p-1

    text-semantic-neutral-icon-strong
    bg-semantic-neutral-surface-raised-bg-default
    hover:bg-semantic-neutral-bg-hover
    active:bg-semantic-neutral-bg-active-default
    border-l border-semantic-neutral-border-default

    focus:outline
    focus:outline-2
    focus:outline-offset-1
    focus:outline-button-primary-solid-border-focus

    [&_[data-icon]]:w-4
    [&_[data-icon]]:h-4
  `,
})
