import { FilledChevronDownSolid, type SvgIcon } from '@motion/icons'
import { classed } from '@motion/theme'
import { ConditionalWrapper } from '@motion/ui/utils'
import { NOOP_FUNCTION } from '@motion/utils/function'

import {
  DebugModeLabel,
  type SortableItemData,
  SortableTreeviewItem,
  type SortableTreeviewItemProps,
} from '~/areas/treeviews/components'
import { useDebugMode } from '~/global/hooks/use-debug-mode'
import { type ReactNode } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { SIDEBAR_INDENT_WIDTH } from '../constants'

export const sidebarItemHeight: number = 28

export type SidebarTreeviewItemProps = Pick<
  SortableTreeviewItemProps<SortableItemData>,
  'disableDrag' | 'renderContextMenuContent' | 'sortableData' | 'tooltip'
> & {
  icon?: SvgIcon
  label: ReactNode
  onNavigate?: (url: string) => void
  onToggleExpand?: (newState: boolean) => void
  renderButtons?: () => ReactNode

  /**
   * Set `true` when the item is currently being viewed. For example, if the
   * item was a workspace, this should be `true` if the user is currently viewing
   * the workspace
   */
  isActive?: boolean

  /**
   * Set `true` when the item should be rendered as a "ghost" item that cannot
   * be interacted with, shows at 50% opacity, and shows debug info if enabled
   */
  isGhost?: boolean

  /**
   * Set `true` when the item is highlighted when using the keyboard to navigate
   * the sidebar when filtering.
   */
  isHighlighted?: boolean

  /**
   * Set `true` when the user is dragging an item in the sidebar and it will be
   * dropped into this item, such as a workspace or folder
   */
  isInside?: boolean
}

export function SidebarTreeviewItem({
  disableDrag,
  icon: Icon,
  isActive = false,
  isGhost = false,
  isHighlighted = false,
  isInside = false,
  label,
  onNavigate = NOOP_FUNCTION,
  onToggleExpand = NOOP_FUNCTION,
  renderButtons,
  renderContextMenuContent,
  sortableData,
  tooltip,
}: SidebarTreeviewItemProps) {
  const navigate = useNavigate()
  const isDebugMode = useDebugMode()

  const {
    level,
    order,
    item: { expanded, id, isContainer, url },
  } = sortableData

  const indentWidth = SIDEBAR_INDENT_WIDTH * level

  return (
    <SortableTreeviewItem
      disableDrag={disableDrag || isGhost}
      id={id}
      indentWidth={indentWidth}
      renderContextMenuContent={renderContextMenuContent}
      renderOnDrag={() => (
        <div
          className='bg-semantic-primary-bg-strong-default w-full h-0.5 rounded-full ml-3 mr-4'
          style={{ marginLeft: level > 0 ? 20 + indentWidth : 0 }}
        />
      )}
      sortableData={sortableData}
      tooltip={tooltip}
    >
      <ItemShell
        active={isActive}
        droppable={isInside}
        ghost={isGhost}
        highlighted={isHighlighted}
      >
        {Icon && (
          <div
            className='p-1.5 size-4 box-content relative shrink-0 cursor-pointer [&_svg]:size-4'
            onClick={() => {
              if (!isContainer) {
                onNavigate(url)
                return void navigate(url)
              }

              onToggleExpand(!expanded)
            }}
          >
            {isContainer && (
              <FilledChevronDownSolid
                className={twMerge(
                  'absolute top-1.5 left-1.5 size-4 opacity-0 group-hover:opacity-100 transition-all',
                  !expanded && '-rotate-90'
                )}
              />
            )}

            <Icon
              className={twMerge(
                'absolute top-1.5 left-1.5 size-4 transition-all text-sidebar-item-icon-default',
                isContainer && 'opacity-100 group-hover:opacity-0'
              )}
            />
          </div>
        )}

        <ConditionalWrapper
          condition={!!url}
          wrapper={(children) => (
            <Link to={url} onClick={() => onNavigate(url)} className='contents'>
              {children}
            </Link>
          )}
        >
          <Label>{label}</Label>
        </ConditionalWrapper>

        {!isGhost && renderButtons && (
          <div className='px-1'>
            <div className='relative flex items-center justify-center h-full gap-1'>
              {renderButtons && (
                <div className='absolute invisible group-hover:relative group-hover:visible right-0'>
                  {renderButtons()}
                </div>
              )}
            </div>
          </div>
        )}

        {isDebugMode && (
          <DebugModeLabel className='mr-2' show={isGhost}>
            {order}
          </DebugModeLabel>
        )}
      </ItemShell>
    </SortableTreeviewItem>
  )
}

const ItemShell = classed('div', {
  base: `
    relative flex items-center text-sm select-none
    w-full h-full pl-0.5 ml-0.5 gap-0.5 mr-3
    text-sidebar-item-text-default
    hover:bg-sidebar-item-bg-hover rounded overflow-hidden
  `,
  variants: {
    active: {
      true: 'bg-sidebar-item-bg-selected text-sidebar-item-text-selected',
      false: '',
    },
    droppable: {
      true: 'bg-semantic-primary-bg-hover text-semantic-primary-text-default',
      false: '',
    },
    ghost: {
      true: 'opacity-50 bg-semantic-neutral-bg-hover shadow border border-semantic-neutral-border-strong',
      false: '',
    },
    highlighted: {
      true: 'bg-sidebar-item-bg-hover',
      false: '',
    },
  },
  defaultVariants: {
    active: false,
    droppable: false,
    ghost: false,
    highlighted: false,
  },
})

const Label = classed('div', 'h-full text-sm truncate w-full leading-7')
