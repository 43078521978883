import {
  CrownSolid,
  MenuAlt2Solid,
  PlannerSolid,
  PlusOutline,
  type SvgIcon,
  TemplateSolid,
  UsersSolid,
  ViewBoardsSolid,
} from '@motion/icons'
import { isCanceledStatus, isCompletedStatus } from '@motion/shared/common'
import {
  ButtonTabs,
  FormModal,
  PopoverButton,
  SearchableDropdown,
  type TabItem,
} from '@motion/ui/base'
import { showToast } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { useHasFeaturePermission } from '@motion/web-billing'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'
import { type ModalDefinitions } from '@motion/web-common/modals/definitions'
import {
  type DashboardViewDefinitionV2,
  type VersionedViewV2,
} from '@motion/zod/client'

import {
  createBlankDashboard,
  createDefaultDashboardViewDefinition,
  type ViewEntityCtx,
} from '~/areas/views/dashboards'
import { useWorkspaceFns } from '~/global/hooks'
import { useCurrentTeam } from '~/global/rpc/team'
import { useMemo, useState } from 'react'

import { usePageData } from '../../routes/hooks/use-v3-page-data'
import { type PageParams, type PageType } from '../../routes/types'
import { getDefaultView } from '../defaults'
import {
  createViewTarget,
  useSaveView,
  type UseSaveViewArgs,
} from '../hooks/use-save-view'
import { type LocalView } from '../types'

type ViewLayout = 'list' | 'kanban' | 'gantt' | 'dashboard'
type StartingPoint = 'blank' | 'team'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'create-view-v2': {
      currentView: LocalView | undefined | null
      type?: ViewLayout
    } & PromptCallbacks<VersionedViewV2>
  }
}

type ConnectedCreateViewModalProps = ModalDefinitions['create-view-v2'] & {
  close: (view?: VersionedViewV2) => void
}

export const ConnectedCreateViewModal = (
  props: ConnectedCreateViewModalProps
) => {
  const route = usePageData()
  const { data: team } = useCurrentTeam()
  const modalApi = useModalApi()
  const [name, setName] = useState('')
  const canShare = route.page !== 'my-tasks' && Boolean(team) // must have a team to make team views

  const effectiveDefaultPageView = useEffectiveDefaultView(route)
  const currentView = props.currentView ?? effectiveDefaultPageView

  const [isPrivate, setIsPrivate] = useState(currentView.isPrivate ?? !canShare)
  const [type, setType] = useState<ViewLayout>(props.type ?? 'list')
  const [startingPoint, setStartingPoint] = useState<StartingPoint>('team')

  const save = useSaveView()

  const hasDashboard = useHasTreatment('charts-preview')
  const canUseGantt = useHasFeaturePermission('gantt')
  const canUseDashboards = useHasFeaturePermission('dashboards')

  const { getAllStatuses } = useWorkspaceFns()

  const typeItems = useMemo(() => {
    const items: TabItem[] = [
      {
        content: <ButtonContent text='List' Icon={MenuAlt2Solid} />,
        value: 'list',
        name: 'list',
        onAction: () => setType('list'),
      },
      {
        content: <ButtonContent text='Kanban' Icon={ViewBoardsSolid} />,
        value: 'kanban',
        name: 'kanban',
        onAction: () => setType('kanban'),
      },
      {
        content: (
          <ButtonContent
            text='Gantt'
            Icon={PlannerSolid}
            featureLocked={!canUseGantt}
          />
        ),
        value: 'gantt',
        name: 'gantt',
        onAction: () => {
          if (canUseGantt) {
            setType('gantt')
          } else {
            modalApi.open('tier-upgrade-prompt-modal', {
              tier: 'PRO',
              feature: 'gantt',
            })
          }
        },
      },
    ]
    if (hasDashboard) {
      items.push({
        content: (
          <ButtonContent
            text='Dashboard'
            Icon={TemplateSolid}
            featureLocked={!canUseDashboards}
          />
        ),
        value: 'dashboard',
        name: 'dashboard',
        onAction: () => {
          if (canUseDashboards) {
            setType('dashboard')
          } else {
            modalApi.open('tier-upgrade-prompt-modal', {
              tier: 'PRO',
              feature: 'dashboards',
            })
          }
        },
      })
    }
    return items
  }, [hasDashboard, canUseGantt, canUseDashboards, modalApi])

  const startingPointItems = useMemo(() => {
    const items: TabItem[] = [
      {
        content: (
          <ButtonContent
            text='Team Dashboard'
            subtext='Overview of team performance'
            Icon={UsersSolid}
          />
        ),
        value: 'team',
        name: 'team',
        onAction: () => setStartingPoint('team'),
      },
      {
        content: <ButtonContent text='Start from scratch' Icon={PlusOutline} />,
        value: 'blank',
        name: 'blank',
        onAction: () => setStartingPoint('blank'),
      },
    ]
    return items
  }, [])

  return (
    <FormModal
      onClose={() => props.close()}
      submitAction={{
        onAction: async () => {
          const allStatuses = getAllStatuses()
          const viewToSave = prepareView({
            name,
            isPrivate,
            type,
            startingPoint,
            baseView: currentView,
            page: route.page,
            params: route.params,
            entityCtx: {
              completedStatuses: allStatuses.filter(isCompletedStatus),
              cancelledStatuses: allStatuses.filter(isCanceledStatus),
            },
          })

          save({
            asNew: true,
            view: viewToSave,
          })
            .then((savedView) => {
              if (savedView) {
                props.close(savedView)
                showToast('success', 'View created')
              }
            })
            .catch((ex) => {
              showToast('error', 'Failed to create view')
            })
        },
        disabled: name.length < 2,
      }}
      title='New View'
      visible
    >
      <div className='flex flex-col items-start gap-4 self-stretch'>
        <div className='flex flex-col w-full gap-5 justify-between'>
          <ButtonTabs fullWidth activeValue={type} items={typeItems} />

          {type === 'dashboard' && (
            <div className='flex flex-col w-full gap-3'>
              <h3 className='text-semantic-neutral-text-default text-xs font-semibold'>
                Select your starting point
              </h3>
              <ButtonTabs
                fullWidth
                activeValue={startingPoint}
                items={startingPointItems}
              />
            </div>
          )}

          <div className='flex flex-col [&>*]:flex-1 gap-3'>
            <TextField
              autoFocus
              value={name}
              label='View name'
              labelHidden
              placeholder='View name'
              onChange={setName}
              size='normal'
            />
            {canShare && (
              <SearchableDropdown
                items={[true, false].map((item) => ({
                  id: `${item}`,
                  label: item ? 'Personal view' : 'Team view',
                  value: item,
                }))}
                renderItem={(item) => item.label}
                onChange={(item) => setIsPrivate(item.value)}
              >
                <PopoverButton className='h-[34px]'>
                  {isPrivate ? 'Personal view' : 'Team view'}
                </PopoverButton>
              </SearchableDropdown>
            )}
          </div>
        </div>
      </div>
    </FormModal>
  )
}

type ButtonContentProps = {
  Icon: SvgIcon
  text: string
  subtext?: string
  featureLocked?: boolean
}

const ButtonContent = (props: ButtonContentProps) => {
  return (
    <div className='grid gap-2 size-full place-items-center'>
      <props.Icon
        width={20}
        height={20}
        className='col-span-full row-span-full self-end'
      />
      <span className='text-2xs font-semibold'>{props.text}</span>
      {props.subtext && (
        <span className='text-[10px] leading-4 font-normal'>
          {props.subtext}
        </span>
      )}
      {props.featureLocked && (
        <CrownSolid className='!size-3.5 col-span-full row-span-full justify-self-end self-start' />
      )}
    </div>
  )
}

type CreateViewArgs = {
  name: string
  type: ViewLayout
  startingPoint: StartingPoint
  page: PageType
  params: Record<string, string>
  isPrivate: boolean
  baseView: LocalView
  entityCtx: ViewEntityCtx
}

function prepareView(args: CreateViewArgs) {
  const newView =
    args.type === 'dashboard'
      ? createDefaultDashboardView(
          args.baseView,
          args.startingPoint === 'blank'
            ? createBlankDashboard()
            : createDefaultDashboardViewDefinition(
                args.page,
                args.params,
                args.entityCtx
              )
        )
      : getDefaultView(args.page)

  const viewToSave: LocalView = {
    ...newView,
    name: args.name,
    isPrivate: args.isPrivate,
    targetType: args.baseView.targetType,
    targetId: args.baseView.targetId,
  }

  if (
    viewToSave.definition.type !== 'dashboard' &&
    viewToSave.definition.type !== 'team-schedule' &&
    args.type !== 'dashboard'
  ) {
    viewToSave.definition.layout = args.type
    if (viewToSave.definition.layout === 'gantt') {
      viewToSave.definition.itemType = 'projects'
    }
  }

  return viewToSave
}
type CreateDefaultDashboardViewArgs = Pick<
  LocalView,
  'type' | 'isPrivate' | 'targetType' | 'targetId'
>

function createDefaultDashboardView(
  args: CreateDefaultDashboardViewArgs,
  definition: DashboardViewDefinitionV2
): UseSaveViewArgs['view'] {
  return {
    name: 'New Dashboard',
    type: args.type,
    isPrivate: args.isPrivate,
    targetType: args.targetType,
    targetId: args.targetId,
    definition,
  }
}

function useEffectiveDefaultView(route: PageParams) {
  const team = useCurrentTeam()
  const user = useAuthenticatedUser()

  const info: Pick<LocalView, 'targetId' | 'targetType'> = createViewTarget(
    route,
    user,
    team.data
  )

  return {
    ...getDefaultView(route.page),
    ...info,
  }
}
