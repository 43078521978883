import { useWorkspaceById } from '~/global/hooks'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { type ActionItemFormFields, isFormValid } from '../types'

export function useActionItemForm() {
  const form = useFormContext<ActionItemFormFields>()
  const workspace = useWorkspaceById(form.watch('workspaceId'))

  return useMemo(() => {
    // Only allow approving if the form is valid, a workspace is selected, and the form is not submitting
    const canApprove =
      isFormValid(form.getValues()) &&
      workspace != null &&
      !form.formState.isSubmitting

    return { form, canApprove }
  }, [form, workspace])
}
