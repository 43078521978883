import { type BookingLinkTemplate, type EmailAccount } from '@motion/rpc/types'
import { logEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { type Events } from '~/analyticsEvents'
import {
  fetchBookingData,
  selectBookingTemplates,
  selectUrlPrefix,
} from '~/state/booking'
import { deleteEmailAccount } from '~/state/email-accounts/email-accounts-thunks'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import { createFullTemplateUrl } from '~/utils/booking-utils'
import { useCallback } from 'react'

export const useDeleteAccount = (props?: { event?: keyof typeof Events }) => {
  const { event } = props ?? {}

  const modalApi = useModalApi()
  const dispatch = useAppDispatch()

  const bookingTemplates = useAppSelector(selectBookingTemplates)
  const individualUrlPrefix = useAppSelector(selectUrlPrefix)

  const deleteAccount = useCallback(
    async (emailAccount: EmailAccount) => {
      if (event) {
        void logEvent(event, {
          email: emailAccount.email,
        })
      }

      let localBookingTemplates = bookingTemplates
      let localIndividualUrlPrefix = individualUrlPrefix

      if (
        Object.keys(localBookingTemplates).length === 0 ||
        !localIndividualUrlPrefix
      ) {
        // need the booking data to populate the modal.
        // at some point, we should replace this with RPC
        const dispatchResponse = await dispatch(fetchBookingData()).unwrap()

        localBookingTemplates = (dispatchResponse.templates ?? []).reduce(
          (
            acc: Record<string, BookingLinkTemplate>,
            template: BookingLinkTemplate
          ) => {
            acc[template.id] = template
            return acc
          },
          {}
        )
        localIndividualUrlPrefix = dispatchResponse.settings.urlPrefix
      }

      const bookingLinks = Object.values(localBookingTemplates)
        .filter(
          (bookingTemplate) => bookingTemplate.hostEmail === emailAccount.email
        )
        .map((bookingTemplate) =>
          createFullTemplateUrl(
            localIndividualUrlPrefix ?? '',
            bookingTemplate.linkSlug
          )
        )

      if (bookingLinks.length > 0) {
        const confirmed = await modalApi.prompt('confirm', {
          analytics: {
            name: 'delete-booking-links',
          },
          title:
            'This action will also delete any booking links associated with this calendar.',
          description: (
            <div className='overflow-y-auto max-h-52'>
              <p className='mb-2'>
                Anybody with the booking links below will not be able to use
                them anymore:
              </p>
              <ul>
                {bookingLinks.map((link) => (
                  <li key={link}>{link}</li>
                ))}
              </ul>
            </div>
          ),
          destructive: true,
          confirmButtonText: 'Continue and remove calendar',
        })
        if (confirmed !== true) {
          return
        }
      }
      void dispatch(deleteEmailAccount(emailAccount.id))
    },
    [event, bookingTemplates, dispatch, individualUrlPrefix, modalApi]
  )

  return deleteAccount
}
