import { showToast } from '@motion/ui/base'
import { Sentry } from '@motion/web-base/sentry'
import { type CreateProjectDefinitionRequestSchema } from '@motion/zod/client'

import { useCreateProjectDefinitionFull } from '~/global/rpc/v2'
import { useCallback } from 'react'

export const useCreateProjectDefinition = () => {
  const { mutateAsync: createProjectDefinition } =
    useCreateProjectDefinitionFull()

  return useCallback(
    async (data: CreateProjectDefinitionRequestSchema) => {
      const payload = {
        workspaceId: data.definition.workspaceId,
        ...data,
      }

      try {
        const response = await createProjectDefinition(payload)

        showToast('success', 'Project definition created')

        return response.models[response.meta.model][response.id]
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            position: 'create-project-definition',
          },
        })

        throw e
      }
    },
    [createProjectDefinition]
  )
}
