import {
  CalendarPlusSolid,
  DotsHorizontalSolid,
  LinkSolid,
  PencilSolid,
  RefreshSolid,
} from '@motion/icons'
import { isCanceledStatus } from '@motion/shared/common'
import { isNoneId } from '@motion/shared/identifiers'
import { classed } from '@motion/theme'
import {
  ButtonGroup,
  IconButton,
  PopoverTrigger,
  Tooltip,
  type TooltipProps,
  useContextMenu,
} from '@motion/ui/base'
import { type SingleLineProps, TextField } from '@motion/ui/forms'
import { formatDateTime } from '@motion/ui-logic'
import { isMeetingTask, isSchedulingTask } from '@motion/ui-logic/pm/task'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type CellContext } from '@tanstack/react-table'
import { EventActionList } from '~/areas/event/components'
import { ProjectActionList } from '~/areas/project/components/project-action-list'
import {
  useProjectNameUpdater,
  useProjectStatusUpdater,
} from '~/areas/project/hooks'
import { SchedulingEventDropdown } from '~/areas/task-project/components'
import { TaskActionList, TaskStatusDropdown } from '~/areas/tasks/components'
import {
  useTaskAutoscheduleUpdater,
  useTaskNameUpdater,
  useTaskStatusData,
} from '~/areas/tasks/hooks'
import { getTaskStatusVariant } from '~/areas/tasks/utils'
import { MotionLink } from '~/global/components'
import { ProjectBadge, StatusBadge } from '~/global/components/badges'
import { StatusDropdown } from '~/global/components/dropdowns'
import {
  AutoScheduleLabel,
  type AutoScheduleLabelProps,
} from '~/global/components/labels'
import {
  useCopyProjectModalLinkToClipboard,
  useCopyTaskLinkToClipboard,
} from '~/global/hooks'
import { useProjectModalUrl, useTaskModalUrl } from '~/global/navigation'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'
import { type ReactNode, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { CellText, SelectRowCheckbox } from './components'

import { type GroupedNode } from '../../grouping'
import { type TreeListRowValueType } from '../types'

type BaseNameCellProps = {
  counter: number
  info: CellContext<GroupedNode<TreeListRowValueType>, unknown>
}

type TaskNameCellProps = BaseNameCellProps & {
  task: TaskWithRelations
}
export const TaskNameCell = ({ counter, task, info }: TaskNameCellProps) => {
  const buildTaskModalUrl = useTaskModalUrl()
  const updateTaskName = useTaskNameUpdater()
  const updateTaskAutoschedule = useTaskAutoscheduleUpdater()
  const {
    onChange: onStatusChange,
    disabled: statusDisabled,
    shouldExcludeResolved,
  } = useTaskStatusData(task)
  const copyTaskLinkToClipboard = useCopyTaskLinkToClipboard()
  const navigate = useNavigate()
  const { handleContextMenu, ContextMenuPopover } = useContextMenu({
    onOpen: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_LIST_RIGHT_CLICK', {
        type: 'task',
      })
    },
  })

  const isArchived = task.archivedTime != null

  const { isUnvisitedStage } = task

  const taskVariant = getTaskStatusVariant(task)
  const isScheduling = isSchedulingTask(task)
  const isMeeting = isMeetingTask(task)

  const meetingTask = isScheduling ? task.meetingTask : isMeeting ? task : null

  const taskUrl = buildTaskModalUrl({
    isMeeting,
    task: task.id,
  })

  const [isEditing, setIsEditing] = useState(false)

  const isRecurringInstance = task.type === 'RECURRING_INSTANCE'

  const handleAutoScheduleChange: AutoScheduleLabelProps['onChange'] = (
    checked
  ) => {
    void updateTaskAutoschedule(task, checked)
  }

  const stopEditing = () => {
    setIsEditing(false)
  }

  const handleClickEditAction: ActionsProps['onClickEdit'] = () => {
    if (isMeeting || task.type === 'RECURRING_INSTANCE') {
      return navigate(taskUrl)
    }

    recordAnalyticsEvent('PROJECT_MANAGEMENT_LIST_EDIT_NAME')
    setIsEditing(true)
  }

  const handleSaveName: NameFieldProps['onSave'] = (name) => {
    const trimName = name.trim()
    if (task.name !== trimName) {
      void updateTaskName(task, trimName)
    }
    stopEditing()
  }

  const handleCopyClick = () => {
    const taskId = isRecurringInstance ? task.parentRecurringTaskId : task.id

    recordAnalyticsEvent('PROJECT_MANAGEMENT_LIST_COPY_LINK')
    copyTaskLinkToClipboard({
      workspaceId: task.workspaceId,
      taskName: task.name,
      taskId,
      isMeeting,
    })
  }

  const renderItemActionList: ActionsProps['renderDotsMenuContent'] = ({
    close,
  }) =>
    isMeetingTask(task) ? (
      <EventActionList close={close} eventId={task.meetingEventId} />
    ) : (
      <TaskActionList close={close} task={task} />
    )

  const renderSchedulingAction = () => {
    if (!isScheduling) return null

    if (meetingTask == null) {
      return (
        <SchedulingEventDropdown taskId={task.id}>
          <Tooltip asChild content='Schedule event'>
            <IconButton
              icon={CalendarPlusSolid}
              size='small'
              sentiment='primary'
              variant='outlined'
            />
          </Tooltip>
        </SchedulingEventDropdown>
      )
    }

    return (
      <MotionLink
        url={buildTaskModalUrl({
          task: meetingTask.id,
          isMeeting: true,
        })}
        className='text-semantic-primary-text-default underline'
      >
        View event
      </MotionLink>
    )
  }

  const renderDateOrAutoSchedule = () => {
    if (!isMeeting) {
      return (
        <AutoScheduleLabel
          task={task}
          onChange={handleAutoScheduleChange}
          position='toggle-right'
          isUnvisitedStage={isUnvisitedStage}
          toggleOnly={meetingTask != null}
        />
      )
    }

    if (meetingTask?.scheduledStart != null) {
      return (
        <span className='text-semantic-neutral-text-subtle'>
          {formatDateTime(meetingTask.scheduledStart, ', ')}
        </span>
      )
    }

    return null
  }

  return (
    <CellText
      withHover
      isUnvisitedStage={isUnvisitedStage}
      className='group/name-cell px-2.5 gap-1'
      onContextMenu={handleContextMenu}
    >
      <SelectRowCheckbox info={info} />
      <LineCounter className='ml-[var(--indent)]'>{counter}</LineCounter>
      {isRecurringInstance && (
        <RefreshSolid className='shrink-0 w-3 h-3 text-semantic-neutral-icon-default' />
      )}
      <div className='flex gap-1.5 items-center overflow-hidden w-full leading-4 self-stretch'>
        <TaskStatusDropdown
          isUnvisitedStage={isUnvisitedStage}
          onChange={(statusId) => {
            onStatusChange(statusId)
          }}
          selectedStatusId={task.statusId}
          workspaceId={task.workspaceId}
          showResolvedStatusFirst
          taskVariant={taskVariant}
          excludeResolved={shouldExcludeResolved}
        >
          <button className='inline-flex shrink-0' disabled={statusDisabled}>
            <StatusBadge
              value={task.status}
              size='small'
              iconVariant={isUnvisitedStage ? 'isUnvisitedStage' : undefined}
              taskVariant={taskVariant}
            />
          </button>
        </TaskStatusDropdown>

        {isEditing ? (
          <NameField
            name={task.name}
            onSave={handleSaveName}
            onCancel={stopEditing}
          />
        ) : (
          <Link
            to={taskUrl}
            className={twMerge(
              'overflow-hidden flex-1 flex self-stretch items-center',
              isNoneId(task.id) && 'pointer-events-none',
              isCanceledStatus(task.status) &&
                'line-through text-semantic-neutral-text-subtle'
            )}
          >
            <span className='truncate'>{task.name}</span>
          </Link>
        )}
      </div>
      {!isArchived && (
        <Actions
          editTooltipContent={
            isMeeting
              ? 'Edit meeting'
              : isRecurringInstance
                ? 'Edit task'
                : 'Edit task name'
          }
          copyLinkTooltipContent={
            isMeeting ? 'Copy meeting link' : 'Copy task link'
          }
          onClickEdit={handleClickEditAction}
          onClickCopyLink={handleCopyClick}
          renderDotsMenuContent={renderItemActionList}
        />
      )}
      {!isArchived && (
        <ButtonGroup>
          {renderSchedulingAction()}
          {renderDateOrAutoSchedule()}
        </ButtonGroup>
      )}
      <ContextMenuPopover renderContent={renderItemActionList} />
    </CellText>
  )
}

type ProjectNameCellProps = BaseNameCellProps & {
  project: ProjectWithRelations
}
export const ProjectNameCell = ({
  counter,
  project,
  info,
}: ProjectNameCellProps) => {
  const buildProjectModalUrl = useProjectModalUrl()
  const updateProjectName = useProjectNameUpdater()
  const updateProjectStatus = useProjectStatusUpdater()
  const copyProjectLinkToClipboard = useCopyProjectModalLinkToClipboard()
  const { handleContextMenu, ContextMenuPopover } = useContextMenu({
    onOpen: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_LIST_RIGHT_CLICK', {
        type: 'project',
      })
    },
  })

  const [isEditing, setIsEditing] = useState(false)

  const stopEditing = () => {
    setIsEditing(false)
  }

  const handleClickEditAction: ActionsProps['onClickEdit'] = () => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_LIST_EDIT_NAME')
    setIsEditing(true)
  }

  const handleSaveName: NameFieldProps['onSave'] = (name) => {
    const trimName = name.trim()
    if (project.name !== trimName) {
      void updateProjectName(project, trimName)
    }
    stopEditing()
  }

  const handleCopyClick = () => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_LIST_COPY_LINK')
    copyProjectLinkToClipboard({
      workspaceId: project.workspaceId,
      projectName: project.name,
      projectId: project.id,
    })
  }

  return (
    <CellText
      withHover
      className='group/name-cell px-2.5 gap-1'
      onContextMenu={handleContextMenu}
    >
      <SelectRowCheckbox info={info} />
      <LineCounter>{counter}</LineCounter>
      <div className='flex gap-1.5 items-center overflow-hidden w-full leading-4'>
        <StatusDropdown
          onChange={(statusId) => updateProjectStatus(project, statusId)}
          selectedStatusId={project.statusId}
          workspaceId={project.workspaceId}
        >
          <button className='inline-flex shrink-0'>
            <StatusBadge value={project.status} size='small' />
          </button>
        </StatusDropdown>
        {isEditing ? (
          <NameField
            name={project.name}
            onSave={handleSaveName}
            onCancel={stopEditing}
          />
        ) : (
          <Link
            to={buildProjectModalUrl({ project: project.id })}
            className={twMerge(
              'overflow-hidden flex-1 flex gap-1 self-stretch items-center',
              isCanceledStatus(project.status) &&
                'line-through text-semantic-neutral-text-subtle'
            )}
          >
            <ProjectBadge value={project} size='normal' hideTooltip />
            <span className='truncate'>{project.name}</span>
          </Link>
        )}
      </div>
      <Actions
        editTooltipContent='Edit project title'
        copyLinkTooltipContent='Copy project link'
        onClickEdit={handleClickEditAction}
        onClickCopyLink={handleCopyClick}
      />
      <ContextMenuPopover
        renderContent={({ close }) => (
          <ProjectActionList close={close} project={project} />
        )}
      />
    </CellText>
  )
}

const LineCounter = classed(
  'span',
  'w-[26px] text-center text-[10px] text-semantic-neutral-text-disabled shrink-0'
)

type ActionsProps = {
  onClickEdit: () => void
  onClickCopyLink: () => void
  editTooltipContent: TooltipProps['content']
  copyLinkTooltipContent: TooltipProps['content']
  renderDotsMenuContent?: ({ close }: { close: () => void }) => ReactNode
}
const Actions = ({
  editTooltipContent,
  copyLinkTooltipContent,
  onClickEdit,
  onClickCopyLink,
  renderDotsMenuContent,
}: ActionsProps) => {
  const [popoverOpened, setPopoverOpen] = useState(false)

  return (
    <div
      className={twMerge(
        'hidden group-hover/name-cell:block',
        popoverOpened && 'block'
      )}
    >
      <ButtonGroup size='small' nowrap>
        <Tooltip asChild content={editTooltipContent}>
          <IconButton
            size='small'
            variant='outlined'
            sentiment='neutral'
            icon={PencilSolid}
            onClick={onClickEdit}
          />
        </Tooltip>
        <Tooltip asChild content={copyLinkTooltipContent}>
          <IconButton
            size='small'
            variant='outlined'
            sentiment='neutral'
            icon={LinkSolid}
            onClick={onClickCopyLink}
          />
        </Tooltip>
        {renderDotsMenuContent != null && (
          <PopoverTrigger
            renderPopover={({ close }) => renderDotsMenuContent({ close })}
            onClose={() => {
              setPopoverOpen(false)
            }}
          >
            <IconButton
              size='small'
              variant='outlined'
              sentiment='neutral'
              icon={DotsHorizontalSolid}
              onClick={() => {
                setPopoverOpen(true)
              }}
            />
          </PopoverTrigger>
        )}
      </ButtonGroup>
    </div>
  )
}

type NameFieldProps = {
  name: string
  onSave: (name: string) => void
  onCancel: () => void
}

const NameField = (props: NameFieldProps) => {
  const { name, onSave, onCancel } = props

  const handleKeyDown: SingleLineProps['onKeyDown'] = (evt) => {
    const { key, currentTarget } = evt

    if (key === 'Escape') {
      return onCancel()
    }

    if (key === 'Enter') {
      return onSave(currentTarget.value)
    }
  }

  const handleBlur: SingleLineProps['onBlur'] = (evt) => {
    onSave(evt.currentTarget.value)
  }

  return (
    <div className='flex-1'>
      <TextField
        onBlur={handleBlur}
        defaultValue={name}
        size='small'
        autoFocus
        selectTextOnFocus
        onKeyDown={handleKeyDown}
      />
    </div>
  )
}
