import { PuzzleSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import {
  type ProjectDefinitionSchema,
  type ProjectSchema,
  type StageDefinitionSchema,
} from '@motion/rpc-types'
import { FormModal, type FormModalProps } from '@motion/ui/base'
import { ProjectPalette } from '@motion/ui/project'

import { ProjectCountBadge } from '~/areas/flows/components'
import { type ModalTriggerComponentProps } from '~/areas/modals'
import { StageLabel } from '~/global/components/labels'
import { useI18N } from '~/global/contexts'
import {
  type StageDefinitionWithProjectInfo,
  useStageWithProjectInfo,
} from '~/global/hooks'

import { AffectedProjectList } from './affected-project-list'
import {
  ConfirmationSection,
  ConfirmationText,
  FormBody,
  TemplateList,
  TextGroup,
  WarningText,
} from './styled'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'confirm-update-stage-template': PromptCallbacks<{
      stageId: StageDefinitionSchema['id']
    }> & {
      stageId: StageDefinitionSchema['id']
    }
  }
}

export function ConnectedConfirmUpdateStageModal({
  stageId,
  close,
  onValue,
  onDismiss,
}: ModalTriggerComponentProps<'confirm-update-stage-template'>) {
  const stageWithProjectInfo = useStageWithProjectInfo(stageId)

  const hasTemplates =
    !!stageWithProjectInfo && stageWithProjectInfo.projectDefinitions.length > 0

  return (
    <ConfirmUpdateStageModal
      stage={stageWithProjectInfo}
      onDismiss={onDismiss}
      onValue={onValue}
      projects={stageWithProjectInfo?.projects ?? []}
      projectDefinitions={
        hasTemplates ? (stageWithProjectInfo.projectDefinitions ?? []) : []
      }
      close={close}
      submitAction={{
        text: 'Save',
        onAction: async () => {
          close({ stageId })
        },
      }}
    />
  )
}

export type ConfirmUpdateStageModalProps = Omit<
  ModalTriggerComponentProps<'confirm-update-stage-template'>,
  'stageId'
> & {
  stage: StageDefinitionWithProjectInfo | undefined
  submitAction: FormModalProps['submitAction']
  projects: ProjectSchema[]
  projectDefinitions: ProjectDefinitionSchema[]
}
export function ConfirmUpdateStageModal({
  stage,
  close,
  submitAction,
  projects,
  projectDefinitions,
}: ConfirmUpdateStageModalProps) {
  const { pluralize } = useI18N()

  return (
    <FormModal
      bodyPadded={false}
      visible
      onClose={close}
      title='Are you sure you want to update this stage?'
      submitAction={submitAction}
    >
      <FormBody>
        <ConfirmationSection>
          <WarningText>
            Motion will automatically update all templates and projects that are
            using this stage to reflect your changes. If this is not what you
            intend you may want to create a new stage instead.
          </WarningText>
          <div className='flex flex-col gap-2 items-start self-stretch'>
            <ConfirmationText>Updated stage:</ConfirmationText>
            <StageLabel size='normal' value={stage} />
          </div>
          <TextGroup>
            <ConfirmationText>
              {templateStr('{{templateCount}} {{templateLabel}} affected', {
                templateCount: projectDefinitions.length,
                templateLabel: pluralize(
                  projectDefinitions.length,
                  'template',
                  'templates'
                ),
              })}
            </ConfirmationText>
            {projectDefinitions.length > 0 && (
              <TemplateList>
                {projectDefinitions.map((projectDefinition) => (
                  <ProjectCountBadge key={projectDefinition.id}>
                    <ProjectPalette color={projectDefinition.color}>
                      <PuzzleSolid className='size-3 text-palette-highlight-default' />
                    </ProjectPalette>
                    {projectDefinition.name}
                  </ProjectCountBadge>
                ))}
              </TemplateList>
            )}
          </TextGroup>
        </ConfirmationSection>
        <AffectedProjectList projects={projects} type='stage-definition' />
      </FormBody>
    </FormModal>
  )
}
