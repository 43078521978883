import { InformationCircleSolid, MotionLogoSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { type FeedEntrySchema } from '@motion/rpc-types'
import { ControlledUnstyledCollapsableContainer } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { ErrorBoundary } from '~/global/components'
import { ConnectedUserBadge } from '~/global/components/badges'
import { useWorkspaceMember } from '~/global/hooks'
import { Fragment, type ReactNode, useMemo } from 'react'

import {
  ActivityItem,
  ActivityUserName,
  getActivityItemConfig,
  type GetActivityItemConfigArgs,
} from './activity-item'
import { isValidActivity } from './activity-item/is-activity-type'
import { ActivityItemContainer } from './activity-item-container'
import { CollapsableButton } from './collapsible-button'
import { CommentItem } from './comment'

import { useActivityFeed } from '../hooks'
import { useActivityFeedContext } from '../providers'
import { type ActivityFeedType } from '../types'
import {
  createCappedIndex,
  groupActivity,
  type GroupActivityItem,
} from '../utils'

export function History() {
  const { target, type, workspaceId } = useActivityFeedContext()

  const {
    activityFeed,
    activityWindow,
    hasActivityOutsideWindow,
    onActivityFeatureGated,
  } = useActivityFeed(type, target.id)

  const activityItemGroups = useMemo(
    () =>
      groupActivity(
        activityFeed
          .map((item) =>
            feedEntryViewSchemaToGroupActivityItems(item, workspaceId, type)
          )
          .filter(Boolean)
      ),
    [activityFeed, type, workspaceId]
  )

  const firstGroupIndex = activityItemGroups.findIndex(
    (group) => group[0].schema.type !== 'COMMENT' && group.length > 1
  )

  return (
    <div className='flex flex-col gap-3 text-semantic-neutral-text-subtle'>
      {activityItemGroups.map((activityItemGroup, index) => {
        let component = (
          <Fragment key={index}>
            {activityItemGroup.map((activityItem) => (
              <ErrorBoundary
                key={activityItem.schema.id}
                errorMessage='Item could not be loaded'
              >
                {activityItem.schema.type === 'COMMENT' ? (
                  <CommentItem
                    key={activityItem.schema.id}
                    comment={activityItem.schema}
                  />
                ) : (
                  <ActivityItem
                    key={activityItem.schema.id}
                    schema={activityItem.schema}
                    renderConfig={activityItem.config}
                  />
                )}
              </ErrorBoundary>
            ))}
          </Fragment>
        )

        if (
          activityItemGroup.length > 1 &&
          activityItemGroup[0].schema.type !== 'COMMENT'
        ) {
          return (
            <GroupedActivityCollapsable
              key={index}
              index={index}
              noOfUpdates={activityItemGroup.length}
              groupInfo={activityItemGroup[0].schema}
              expanded={index === firstGroupIndex}
            >
              {component}
            </GroupedActivityCollapsable>
          )
        }

        return component
      })}
      {hasActivityOutsideWindow && (
        <div className='border-semantic-neutral-border-subtle bg-semantic-neutral-surface-raised-bg-subtlest p-2 text-xs text-semantic-neutral-text-subtle flex'>
          {templateStr(
            '{{icon}}This task has activity & comments older than {{days}} days.{{nbsp}}{{upsell}}',
            {
              icon: <InformationCircleSolid className='size-4 mr-2' />,
              days: activityWindow,
              nbsp: <>&nbsp;</>,
              upsell: (
                <a
                  onClick={onActivityFeatureGated}
                  className='underline text-semantic-primary-text-default cursor-pointer'
                >
                  Upgrade to view the full history.
                </a>
              ),
            }
          )}
        </div>
      )}
    </div>
  )
}

type GroupedActivityCollapsableProps = {
  noOfUpdates: number
  children: ReactNode
  groupInfo: {
    sourceId: string | null
    sourceReason: string | null
    sourceType: 'USER' | 'SYSTEM' | 'AI'
    createdTime: string
  }
  expanded?: boolean
  index: number
}

function GroupedActivityCollapsable({
  noOfUpdates,
  children,
  groupInfo,
  expanded,
  index,
}: GroupedActivityCollapsableProps) {
  const { workspaceId } = useActivityFeedContext()

  const author = useWorkspaceMember(workspaceId, groupInfo.sourceId)

  const defaultIcon =
    groupInfo.sourceType === 'USER' ? (
      <ConnectedUserBadge userId={author?.user.id} size='small' />
    ) : (
      <MotionLogoSolid className='text-semantic-neutral-icon-strong' />
    )

  const onToggle = (expanded: boolean) => {
    if (!expanded) return

    recordAnalyticsEvent('ACTIVITY_FEED_GROUP_OPEN', {
      index: createCappedIndex(index),
    })
  }

  return (
    <ControlledUnstyledCollapsableContainer
      initialExpanded={expanded}
      renderHeader={({ expanded, toggle }) => (
        <ActivityItemContainer
          icon={defaultIcon}
          subject={
            <>
              <ActivityUserName
                author={author}
                sourceType={groupInfo.sourceType}
              />{' '}
              made
            </>
          }
          timestamp={groupInfo.createdTime}
        >
          <CollapsableButton expanded={expanded} onClick={toggle}>
            {noOfUpdates} updates
          </CollapsableButton>
        </ActivityItemContainer>
      )}
      onToggle={onToggle}
    >
      <div className='pt-2 pl-7 flex flex-col gap-1'>{children}</div>
    </ControlledUnstyledCollapsableContainer>
  )
}

function feedEntryViewSchemaToGroupActivityItems(
  item: FeedEntrySchema,
  workspaceId: string,
  type: ActivityFeedType['type'],
  options?: GetActivityItemConfigArgs['options']
): GroupActivityItem | null {
  if (!isValidActivity(item, options)) {
    return null
  }

  if (item.type === 'COMMENT') {
    return {
      schema: item,
      config: null,
    }
  }

  const defaultIcon =
    item.sourceType === 'USER' ? (
      <ConnectedUserBadge userId={item.sourceId} size='small' />
    ) : (
      <MotionLogoSolid className='text-semantic-neutral-icon-strong' />
    )

  const config = getActivityItemConfig({
    workspaceId,
    type,
    feedEntry: item,
    defaultIcon,
    options,
  })

  if (config == null) {
    return null
  }

  return {
    schema: item,
    config,
  }
}
