import { isObjectNoneId } from '@motion/shared/identifiers'
import { Tooltip, useContextMenu } from '@motion/ui/base'
import {
  getProjectStageFromDefinitionId,
  getStageVariant,
  isStageActive,
} from '@motion/ui-logic/pm/project'

import { StageHeaderContextMenuContent } from '~/areas/flows/'
import { StageBadge } from '~/global/components/badges'
import { StageLabel } from '~/global/components/labels'
import { useProject, useStageDefinition } from '~/global/hooks'
import { twMerge } from 'tailwind-merge'

import { type LookupArgs } from './lookup-header'
import { StageHeaderEndContent } from './stage-header-end-content'
import { ToggleTasksButton } from './toggle-tasks-button'

import { useSidebarTaskContext } from '../context'

type StageHeaderProps = Omit<LookupArgs, 'toggle'>

const SHOW_NAME_TOOLTIP_AFTER_LENGTH = 14

export function StageHeader({ groupId, row }: StageHeaderProps) {
  const stageDefinition = useStageDefinition(groupId)
  const { projectId } = useSidebarTaskContext()

  const project = useProject(projectId)
  const projectStage = getProjectStageFromDefinitionId(
    project,
    stageDefinition.id
  )
  const showToggleButton = Boolean(row?.subRows.length)

  const { handleContextMenu, ContextMenuPopover } = useContextMenu()

  if (
    isObjectNoneId(stageDefinition) ||
    project == null ||
    projectStage == null
  ) {
    return null
  }

  const isActive = isStageActive(project, stageDefinition.id)
  const stageVariant = getStageVariant(projectStage)

  const nameTooltipContent =
    stageDefinition.name.length > SHOW_NAME_TOOLTIP_AFTER_LENGTH
      ? stageDefinition.name
      : undefined

  return (
    <>
      <div className='flex flex-row items-center justify-between group/stage-header w-full'>
        <div
          className='flex flex-row items-center gap-1 flex-1 overflow-hidden'
          onContextMenu={
            stageVariant === 'skipped' ? undefined : handleContextMenu
          }
        >
          <div
            className={twMerge(
              'flex items-center justify-center size-4 flex-shrink-0',
              showToggleButton && 'group-hover/stage-header:hidden'
            )}
          >
            <StageBadge
              size='small'
              value={stageDefinition}
              variant={stageVariant}
            />
          </div>
          {showToggleButton && (
            <div className='group-hover/stage-header:block hidden'>
              <ToggleTasksButton row={row} />
            </div>
          )}
          <Tooltip content={nameTooltipContent} asChild>
            <StageLabel
              nameOnly
              value={stageDefinition}
              variant={stageVariant}
              active={isActive}
            />
          </Tooltip>
        </div>
        <StageHeaderEndContent
          projectStage={projectStage}
          project={project}
          stageDefinition={stageDefinition}
          stageVariant={stageVariant}
        />
      </div>
      <ContextMenuPopover
        renderContent={({ close }) => (
          <StageHeaderContextMenuContent
            close={close}
            project={project}
            projectStage={projectStage}
            stageDefinition={stageDefinition}
          />
        )}
      />
    </>
  )
}
