import { ExclamationTriangleSolid } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'
import { CalendarEvent } from '@motion/ui/calendar'
import {
  allOtherAttendeesDeclined,
  getConferenceTypeFromConferenceLink,
} from '@motion/ui-logic'
import { Sentry } from '@motion/web-base/sentry'

import { type EventContentArg } from '@fullcalendar/core'
import {
  type FullCalendarMotionEvent,
  getRsvpUIEvent,
} from '~/areas/calendar/utils'
import { getEventColorHue } from '~/areas/event/utils'
import { ConnectedProjectIcon } from '~/global/components'
import { useMemo } from 'react'

import { useScheduleAssistantCalendarContext } from '../../hooks'

type SelectExistingCalendarEventProps = {
  id: string
  eventArg: EventContentArg
}

export function SelectExistingCalendarEvent({
  id,
  eventArg,
}: SelectExistingCalendarEventProps) {
  const [{ selectedCalendarEvent }] = useScheduleAssistantCalendarContext()
  const { start, end, allDay, title, extendedProps } = eventArg.event

  const { isPast } = eventArg

  const {
    eventOwnerEmail,
    attendees,
    colorId,
    calendarColorId,
    availability,
    otherCalendarColorIds,
    eventLocation,
    conferenceLink,
    rsvp,
    recurringEventId,
    meetingTaskProject,
  } = extendedProps as FullCalendarMotionEvent['extendedProps']

  const allOthersDeclined = useMemo(() => {
    return allOtherAttendeesDeclined(attendees, eventOwnerEmail)
  }, [attendees, eventOwnerEmail])

  const readableConferenceType = getConferenceTypeFromConferenceLink({
    conferenceLink,
    readable: true,
  })

  if (!start) {
    Sentry.captureException("Calendar event doesn't have a start time", {
      extra: {
        event: eventArg,
      },
    })
    return null
  }

  const isMeeting = meetingTaskProject != null

  const eventColorHue = getEventColorHue(colorId, {
    projectColor: meetingTaskProject?.color,
    calendarColorId: calendarColorId,
  })

  const isSelected = selectedCalendarEvent?.id === id

  return (
    <Tooltip
      asChild
      renderContent={() => {
        if (isMeeting) {
          return (
            <p className='flex text-left gap-2'>
              <ExclamationTriangleSolid className='mt-px size-4 text-semantic-neutral-text-onDark' />
              Can&apos;t select an event that is already part of a project
            </p>
          )
        }
      }}
    >
      <CalendarEvent
        allOthersDeclined={allOthersDeclined}
        colorHue={eventColorHue}
        startTime={start}
        endTime={end ?? start}
        allDay={allDay}
        name={title}
        rsvp={isMeeting ? 'maybe' : getRsvpUIEvent(rsvp)}
        availability={availability === 'FREE' ? 'free' : 'default'}
        past={isPast}
        otherCalendars={otherCalendarColorIds.map((colorId) => ({
          colorId,
        }))}
        eventLocation={
          (readableConferenceType !== 'None' && readableConferenceType) ||
          eventLocation
        }
        isRecurring={!!recurringEventId}
        selected={isSelected}
        disableTooltip={isMeeting}
        renderTopRightContent={() => {
          if (!meetingTaskProject) {
            return null
          }

          return (
            <ConnectedProjectIcon
              className='size-2.5'
              projectId={meetingTaskProject.id}
            />
          )
        }}
      />
    </Tooltip>
  )
}
