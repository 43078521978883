import {
  recordAnalyticsEvent,
  useOnMountAnalyticsEvent,
} from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { type User } from '@motion/web-common/auth'
import { type SubscriptionStateInactive } from '@motion/web-common/subscriptions'

import { Events } from '~/analyticsEvents'
import { createSetupIntent } from '~/state/corrilySlice'
import { useAppDispatch } from '~/state/hooks'
import { reusableSetupIntent } from '~/state/teamSlice'
import { type Team } from '~/state/TeamTypes'
import { useState } from 'react'

import { ResubscribeWrapper } from './resubscribe-wrapper'
import { TeamSubscriptionExpired } from './team-subscription-expired'
import { TeamTrialExpired } from './TeamTrialExpired'

export type ConnectedTeamSubscriptionExpiredProps = {
  user: User
  subscription: SubscriptionStateInactive['value']
  signOut: () => Promise<void>
  team: Team
}

export const ConnectedInactiveTeamSubscription = (
  props: ConnectedTeamSubscriptionExpiredProps
) => {
  const { user, signOut, subscription, team } = props
  const dispatch = useAppDispatch()

  const [screen, setScreen] = useState<
    'individualResubscribe' | 'teamResubscribe' | 'selectPlan'
  >('selectPlan')

  const [clientSecret, setClientSecret] = useState<null | string>(null)

  const email = user.email ?? 'unknown'

  const prepTeamResubscribe = async (teamId: string) => {
    const paymentIntent = await dispatch(reusableSetupIntent(teamId)).unwrap()
    setClientSecret(paymentIntent.clientSecret)
    setScreen('teamResubscribe')
  }

  const prepIndividualResubscribe = async () => {
    try {
      const paymentIntent = await dispatch(createSetupIntent()).unwrap()
      if (!paymentIntent) {
        throw new Error('Payment intent generate failed')
      }
      setClientSecret(paymentIntent.clientSecret)
    } catch (e) {
      Sentry.captureException(e, { tags: { position: 'fetchPrices' } })
      throw e
    }
    setScreen('individualResubscribe')
  }

  useOnMountAnalyticsEvent('TEAM_SUBSCRIPTION_INACTIVE_SHOWN')

  if (screen === 'teamResubscribe') {
    return (
      <ResubscribeWrapper
        clientSecret={clientSecret}
        team={team}
        userEmail={email}
      />
    )
  } else if (screen === 'individualResubscribe') {
    return <ResubscribeWrapper clientSecret={clientSecret} userEmail={email} />
  }

  const type = subscription.team.details?.type

  if (type === 'team_plan_trial') {
    return (
      <TeamTrialExpired
        individualSubscribe={async () => {
          void recordAnalyticsEvent(Events.TEAM_TRIAL_EXPIRED_INDIVIDUAL_RESUB)
          await prepIndividualResubscribe()
        }}
        teamSubscribe={async () => {
          void recordAnalyticsEvent(Events.TEAM_TRIAL_EXPIRED_TEAM_RESUB)
          await prepTeamResubscribe(team.id)
        }}
        signOut={() => {
          void recordAnalyticsEvent(Events.TEAM_TRIAL_EXPIRED_SIGN_OUT)
          void signOut()
        }}
      />
    )
  }

  return (
    <TeamSubscriptionExpired
      email={email}
      individualSubscribe={async () => {
        void recordAnalyticsEvent(
          Events.TEAM_SUBSCRIPTION_EXPIRED_INDIVIDUAL_RESUB
        )
        await prepIndividualResubscribe()
      }}
      teamSubscribe={async () => {
        void recordAnalyticsEvent(Events.TEAM_SUBSCRIPTION_EXPIRED_TEAM_RESUB)
        await prepTeamResubscribe(team.id)
      }}
      signOut={() => {
        void recordAnalyticsEvent(Events.TEAM_SUBSCRIPTION_EXPIRED_SIGN_OUT)
        void signOut()
      }}
    />
  )
}
