import { showToast } from '@motion/ui/base'
import { Sentry } from '@motion/web-base/sentry'
import {
  type FolderSchema,
  type NoteSchema,
  type ProjectSchema,
  type RecursiveFolderItemSchema,
  type VersionedViewV2,
} from '@motion/zod/client'

import { useDeleteItemFromFolder } from '~/global/rpc/folders'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useFavoriteTypeLookup } from './use-favorite-type-lookup'
import { useUserFavorites } from './use-user-favorites'

export const useRemoveFavorite = () => {
  const { mutateAsync: mutateRemoveFavorite } = useDeleteItemFromFolder()
  const userFavorites = useUserFavorites()
  const lookup = useFavoriteTypeLookup()

  return useCallback(
    async (itemId: RecursiveFolderItemSchema['id']) => {
      const errorExtra = {
        level: 'error' as const,
        tags: {
          position: 'useRemoveFavorite',
        },
        extra: {
          userFavoritesFolderId: userFavorites.folder?.id ?? null,
          itemId,
        } as Record<string, string | null>,
      }

      if (!userFavorites.folder) {
        Sentry.captureException(
          new Error('Could not locate user favorites system folder'),
          errorExtra
        )

        return void showErrorToast('There was a problem removing your favorite')
      }

      const favorite = userFavorites.items.find(
        (favorite) => favorite.id === itemId
      )

      if (!favorite) {
        Sentry.captureException(
          new Error('Could not find favorite to remove'),
          errorExtra
        )

        return void showErrorToast('There was a problem removing your favorite')
      }

      errorExtra.extra.matchedFavoriteId = favorite.id

      if (
        favorite.type === 'INDIVIDUAL_WORKSPACE' ||
        favorite.type === 'TEAM_WORKSPACE'
      ) {
        throw new Error('Cannot favorite a workspace')
      }

      const model:
        | FolderSchema
        | ProjectSchema
        | VersionedViewV2
        | NoteSchema
        | undefined = lookup(favorite.type, favorite.itemId) ?? undefined

      let modelName: string | null = null

      if (model && 'title' in model) {
        modelName = (model.title ?? '').trim()
      } else if (model && 'name' in model) {
        modelName = (model.name ?? '').trim()
      }

      modelName = modelName ? `'${modelName}'` : 'Item'

      errorExtra.extra.matchedModelId = model?.id ?? null

      try {
        await mutateRemoveFavorite({
          folderId: userFavorites.folder.id,
          itemId,
        })

        showToast(
          'success',
          `${modelName} has been removed from your favorites`
        )
      } catch (e) {
        Sentry.captureException(e, errorExtra)

        showErrorToast(e, `Failed to remove item from your favorites`)
      }
    },
    [lookup, mutateRemoveFavorite, userFavorites.folder, userFavorites.items]
  )
}
