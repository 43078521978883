import { templateStr } from '@motion/react-core/strings'
import { Tooltip } from '@motion/ui/base'
import { type WorkspaceMemberWithUser } from '@motion/ui-logic'

import { useMember } from '~/global/hooks'
import { forwardRef } from 'react'

import { UserBadge } from './user'
import { type BadgeSize } from './utils'

export type AssigneeBadgeProps = {
  value: WorkspaceMemberWithUser | null | undefined
  size?: BadgeSize
}

export const AssigneeBadge = forwardRef<HTMLDivElement, AssigneeBadgeProps>(
  function AssigneeBadge({ value, size }, ref) {
    return (
      <Tooltip
        content={templateStr('Assignee: {{ name }}', {
          name: value?.user.name ?? 'Unassigned',
        })}
        asChild
      >
        <UserBadge ref={ref} value={value?.user} size={size} />
      </Tooltip>
    )
  }
)

export type ConnectedAssigneeBadgeProps = Omit<AssigneeBadgeProps, 'value'> & {
  userId: string | null | undefined
}

export const ConnectedAssigneeBadge = forwardRef<
  HTMLDivElement,
  ConnectedAssigneeBadgeProps
>(({ userId, ...rest }, ref) => {
  const user = useMember(userId)

  return <AssigneeBadge value={user} ref={ref} {...rest} />
})
ConnectedAssigneeBadge.displayName = 'ConnectedAssigneeBadge'
