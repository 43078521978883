import { GradientButton, Popover } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'
import { usePersistedOnboardingState } from '@motion/web-common/onboarding'

import { TutorialPlayer } from '~/areas/tutorials'
import { useCompletedTutorialPercentage } from '~/areas/tutorials/hooks'
import {
  useGetTutorialPayload,
  useUpdateTutorialCompletion,
} from '~/components/Settings/Pages/tutorials/rpc-hooks'
import { useHasSeenCta } from '~/global/hooks'
import { useUpdateMyCtaSettings } from '~/global/rpc/v2'
import { useNavigateByRouteId } from '~/routing'
import { DateTime } from 'luxon'
import { type RefObject, useEffect, useState } from 'react'

type TutorialsPopoverProps = {
  triggerRef: RefObject<HTMLElement | null>
}

export const TutorialsPopover = ({ triggerRef }: TutorialsPopoverProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const { data: tutorialData } = useGetTutorialPayload()
  const { mutateAsync: updateTutorialCompletion } =
    useUpdateTutorialCompletion()
  const completedPercent = useCompletedTutorialPercentage()

  const { mutate: updateCtaSettings } = useUpdateMyCtaSettings()
  const hasSeenCta = useHasSeenCta()

  const navigate = useNavigateByRouteId()

  const [onboardingSettings] = usePersistedOnboardingState()

  const onboardingDocEnabled = useHasTreatment('onboarding-welcome-doc')
  const postOnboardingGuideEnabled = useHasTreatment('post-onboarding-guide')
  useEffect(() => {
    if (!onboardingSettings?.lastOnboardTimestamp) {
      setIsOpen(false)
      return
    }
    const hasSeenPopover = hasSeenCta('TUTORIALS_POPOVER')
    const isNewUser =
      Math.abs(
        DateTime.fromMillis(onboardingSettings.lastOnboardTimestamp).diffNow(
          'days'
        ).days
      ) < 1

    const showPopover =
      !hasSeenPopover.seen && completedPercent === 0 && isNewUser
    setIsOpen(showPopover)
  }, [completedPercent, hasSeenCta, onboardingSettings, onboardingDocEnabled])

  const handleClose = () => {
    updateCtaSettings({
      ctaSettings: {
        TUTORIALS_POPOVER: true,
      },
    })
    setIsOpen(false)
  }

  const videoPayload = tutorialData?.sections[0].lessons[0]

  // Determine whether to show the tutorial popover. The aim is to only show
  // this popover the next time the page loads, so that when the user navigates
  // to the onboarding doc, they don't see the tutorial popover immediately.
  const [showTutorialPopover, setShowTutorialPopover] = useState(false)
  const onboardingDocCta = hasSeenCta('ONBOARDING_DOC_POPOVER')

  useEffect(
    function determineWhetherToShowTutorialPopover() {
      if (
        (!onboardingDocEnabled ||
          (onboardingDocCta.loaded && onboardingDocCta.seen)) &&
        !postOnboardingGuideEnabled
      ) {
        setShowTutorialPopover(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onboardingDocCta.loaded, setShowTutorialPopover, onboardingDocEnabled]
  )

  if (!showTutorialPopover) return null
  if (!videoPayload || !isOpen) return null

  const youtubeId = videoPayload.youtubeId
  const lessonId = videoPayload.id

  return (
    <Popover
      triggerRef={triggerRef}
      placement='right'
      showArrow
      onClose={() => {}}
      enableOutsideInteractions
      offset={14}
    >
      <div className='w-[505px] h-[426px] rounded overflow-hidden'>
        <div className='w-full h-[76px] bg-purple-gradient flex flex-col gap-3 items-center justify-center text-white'>
          <h1 className='font-semibold text-sm'>
            Get the most out of Motion by completing our tutorials
          </h1>
          <h2 className='text-xs'>
            Our tutorials are designed to get you set up with Motion as quickly
            as possible.
          </h2>
        </div>
        <div className='flex flex-col gap-4 items-center justify-center pt-5'>
          <div
            className='flex w-[465px] rounded-lg overflow-hidden'
            onClick={() => {
              recordAnalyticsEvent('TUTORIALS_VIDEO_PLAYED', {
                lessonId,
              })
            }}
          >
            <TutorialPlayer youtubeId={youtubeId} lessonId={lessonId} />
          </div>
          <div className='flex flex-row gap-3'>
            <GradientButton
              size='small'
              sentiment='purple'
              onClick={async () => {
                await updateTutorialCompletion({ tutorialId: lessonId })
                recordAnalyticsEvent('TUTORIALS_LESSON_COMPLETED', {
                  lessonId: lessonId,
                })
                handleClose()
                navigate('tutorials')
              }}
            >
              Complete
            </GradientButton>
            <GradientButton
              size='small'
              sentiment='promotion'
              onClick={handleClose}
            >
              Not now
            </GradientButton>
          </div>
        </div>
      </div>
    </Popover>
  )
}
