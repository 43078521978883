import { useAuthenticatedUser } from '@motion/web-common/auth'

import { History, SlimCommentBox } from '~/areas/activity-feed/components'
import { ActivityUpsellSection } from '~/areas/activity-feed/components/activity-upsell-section'
import {
  useActivityFeedGating,
  useCreateComment,
} from '~/areas/activity-feed/hooks'
import { ActivityFeedProvider } from '~/areas/activity-feed/providers'
import { type CommentSubmitPayload } from '~/areas/activity-feed/types'
import { useProject } from '~/global/hooks'

type ActivityTabProps = {
  workspaceId: string
  projectId: string
}
export function ActivityTab({ workspaceId, projectId }: ActivityTabProps) {
  const createComment = useCreateComment()
  const project = useProject(projectId)
  const { uid: userId } = useAuthenticatedUser()

  const handleSubmit = async ({ body }: CommentSubmitPayload) => {
    if (!userId || !body) return

    createComment({
      body,
      type: 'project',
      targetId: projectId,
      createdByUserId: userId,
    })
  }

  const { activityWindow } = useActivityFeedGating()

  if (activityWindow === 0) {
    return <ActivityUpsellSection />
  }

  if (project == null) return null

  return (
    <ActivityFeedProvider
      workspaceId={workspaceId}
      target={project}
      type='project'
    >
      <div className='flex flex-col h-full py-3 overflow-auto gap-3'>
        <div className='px-3'>
          <SlimCommentBox onSubmit={handleSubmit} />
        </div>

        <div className='px-3 overflow-y-auto'>
          <History />
        </div>
      </div>
    </ActivityFeedProvider>
  )
}
