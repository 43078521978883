import { makeLog } from '@motion/web-base/logging'
import { DB } from '@motion/web-common/storage'

import { redirectLoader } from '~/areas/project-management/pages/pm-v3/routes/utils/redirect'
import { type MotionRoute } from '~/routing/api'

import { LayoutShell } from './layout-shell'
import { LAST_VIEW_PREFIX, loader } from './loader/notetaker-view-loader'
import { NotetakerShell } from './notetaker-shell'
import { NotetakerViewPage } from './notetaker-view-page'

declare module '~/routing/hooks/navigate-by-id-params' {
  interface NavigateByIdParams {
    'notetaker-list': {
      type?: 'my-calls' | 'all-calls' | 'shared-with-me' | 'future'
    }
  }
}

const log = makeLog('notetaker/routes')

export const notetakerRoutes: MotionRoute[] = [
  {
    path: 'notetaker',
    featureFlag: 'notetaker-event-modal',
    element: <LayoutShell />,
    children: [
      {
        id: 'notetaker-list',
        path: 'view',
        element: <NotetakerShell />,
        routing: {
          defaults: {},
          relative: false,
          template: ':type?',
        },
        children: [
          {
            path: '',
            index: true,
            loader: async (args) => {
              try {
                await DB.open()

                const lastViewVariant = await DB.state.get(LAST_VIEW_PREFIX)

                if (lastViewVariant != null) {
                  return redirectLoader(`./${lastViewVariant}`)(args)
                }

                const url = new URL(args.request.url)
                if (url.pathname.endsWith('/notetaker/view')) {
                  return redirectLoader('./my-calls')(args)
                }
              } catch (e) {
                log.error('Error in notetaker loader', e)
              }

              return null
            },
          },
          {
            path: 'my-calls',
            id: 'notetaker-my-calls',
            element: <NotetakerViewPage />,
            loader,
          },
          {
            path: 'all-calls',
            id: 'notetaker-all-calls',
            element: <NotetakerViewPage />,
            loader,
          },
          {
            path: 'shared-with-me',
            id: 'notetaker-shared-with-me',
            element: <NotetakerViewPage />,
            loader,
          },
          {
            path: 'future',
            id: 'notetaker-future',
            element: <NotetakerViewPage />,
            loader,
          },
        ],
      },
    ],
  },
]
