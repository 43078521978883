import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import { type FieldPath } from 'react-hook-form'

/**
 * Get the stage and task index from the name
 * @param name - name looks like 'stages.1.tasks.1' or 'stages.1.tasks.1.assigneeVariableKey'
 * @returns
 */
export function getDataFromFieldName<
  T extends Extract<
    FieldPath<FlowTemplateFormFields>,
    | `stages.${number}.tasks.${number}`
    | `stages.${number}.tasks.${number}.${string}`
    | `stages.${number}.${string}`
  >,
  N = ExtractTopLevelTaskDefinitionField<T>,
>(name: T) {
  const splitName = name.split('.')
  const stageIndexString = splitName[1]
  const taskIndexString = splitName[3]
  const fieldName = splitName[4]

  return {
    stageIndex:
      stageIndexString && !isNaN(parseInt(stageIndexString, 10))
        ? parseInt(stageIndexString, 10)
        : null,
    taskIndex:
      taskIndexString && !isNaN(parseInt(taskIndexString, 10))
        ? parseInt(taskIndexString, 10)
        : null,
    fieldName: fieldName != null ? (fieldName as N) : null,
  }
}

export type ExtractTopLevelTaskDefinitionField<
  T extends Extract<
    FieldPath<FlowTemplateFormFields>,
    | `stages.${number}.tasks.${number}`
    | `stages.${number}.tasks.${number}.${string}`
    | `stages.${number}.${string}`
  >,
> = T extends `stages.${number}.tasks.${number}.${string}.${string}`
  ? never
  : T extends `stages.${number}.tasks.${number}.${infer K}`
    ? K
    : T extends `stages.${number}.${infer K}`
      ? K
      : never
