import { classed } from '@motion/theme'
import { ProjectPalette } from '@motion/ui/project'

import { StageTasks, useFlowTemplateForm } from '~/areas/flows/shared-form'
import { useRegisterFieldArray } from '~/global/hooks'
import { type ComponentRef, type RefObject, useRef } from 'react'

import { StageColumnHeader } from './stage-column-header'

import { type StageColumn } from '../styled'

export type StageTaskColumnProps = {
  index: number
  onRemove: () => void
  scrollColumnsRef: RefObject<HTMLDivElement | null>
}
export const StageTaskColumn = ({
  index,
  onRemove,
  scrollColumnsRef,
}: StageTaskColumnProps) => {
  const {
    form: { getValues, register, watch },
  } = useFlowTemplateForm()

  const stage = watch(`stages.${index}`)

  useRegisterFieldArray(`stages.${index}`, register, {
    validate: () => {
      const stage = getValues(`stages.${index}`)
      if (stage != null && stage.tasks.length === 0) {
        return 'There must be at least one task in a stage'
      }
    },
  })

  const stageColumnRef = useRef<ComponentRef<typeof StageColumn>>(null)

  return (
    <div className='flex flex-col gap-2 self-stretch w-full h-full overflow-y-auto'>
      <StageDefinitionDetails>
        <ProjectPalette color={stage.color}>
          <TopBorder />
        </ProjectPalette>
        <StageColumnHeader index={index} onRemove={onRemove} />
      </StageDefinitionDetails>
      <StageTasks
        tasksPath={`stages.${index}.tasks`}
        stageColumnRef={stageColumnRef}
      />
    </div>
  )
}

const StageDefinitionDetails = classed('div', {
  base: `
    flex flex-col items-start self-stretch gap-1 pt-3 px-2 h-auto
    border border-semantic-neutral-border-default bg-semantic-neutral-surface-raised-bg-default
    rounded-t-sm relative
  `,
  variants: {
    roundedBottom: {
      true: 'rounded-b',
    },
  },
})

const TopBorder = classed('div', {
  base: `
    absolute top-0 left-0
    w-full h-[2px] rounded-t
    bg-palette-highlight-default
  `,
})
