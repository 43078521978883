import { useHasTreatment } from '@motion/web-common/flags'
import {
  useMyUserSettings,
  useUpdateSidebarDisplaySettings,
} from '@motion/web-common/settings'

import { MAX_SIDEBAR_ITEM_COUNT } from '~/areas/sidebar/treeviews/constants'
import { useCallback } from 'react'

export const useFavoritesTreeviewSettings = () => {
  const hasSidebarOrganization = useHasTreatment('sidebar-organization-control')
  const { mutateAsync } = useUpdateSidebarDisplaySettings()
  const { data } = useMyUserSettings(undefined, {
    meta: { source: 'useFavoritesTreeviewSettings' },
  })

  const maxRenderedFavorites = hasSidebarOrganization
    ? data?.sidebarDisplaySettings?.favoritesLimit || Infinity
    : MAX_SIDEBAR_ITEM_COUNT

  const setMaxRenderedFavorites = useCallback(
    (count: number) =>
      mutateAsync({
        favoritesLimit: count === Infinity ? null : count,
      }),
    [mutateAsync]
  )

  return {
    maxRenderedFavorites,
    setMaxRenderedFavorites,
  }
}
