import { type EditorPublicAPI } from '@motion/notes'

import { createContext, type Ref } from 'react'

export type NoteEditorAPIContextValue = {
  setEditorRef: Ref<EditorPublicAPI>
  isEditorInitialized: boolean
  navigateToNodeByMotionId: (nodeKey: string) => void
}

export const NoteEditorAPIContext = createContext<
  NoteEditorAPIContextValue | undefined
>(undefined)
