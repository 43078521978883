import { type FlowTemplateRoleAssignee } from '@motion/ui-logic/pm/project'

import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import {
  getSelectedRole,
  roleToFlowTemplateAssignee,
} from '~/areas/flows/shared-form/utils'
import { useEffect, useMemo } from 'react'

import { useStageTaskField } from './use-stage-task-field'

export function useFlowTemplateRoles(
  taskBasePath: `stages.${number}.tasks.${number}`,
  _stageDefinitionId: string
): {
  flowTemplateRoles: FlowTemplateRoleAssignee[]
  selectedRole: FlowTemplateRoleAssignee | null
} {
  const {
    form: { watch, setValue },
  } = useFlowTemplateForm()
  const [assigneeVariableKey] = useStageTaskField(
    `${taskBasePath}.assigneeVariableKey`
  )

  const roles = watch('roles')
  const flowTemplateRoles = roles.map(roleToFlowTemplateAssignee)
  const selectedRole = useMemo(
    () => getSelectedRole(flowTemplateRoles, assigneeVariableKey),
    [flowTemplateRoles, assigneeVariableKey]
  )

  useEffect(() => {
    if (selectedRole == null) {
      setValue(`${taskBasePath}.assigneeVariableKey`, null)
    }
  }, [selectedRole, setValue, taskBasePath])

  return { flowTemplateRoles, selectedRole }
}
