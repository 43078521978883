import { getEventTitleFromSchedulingTaskTitle } from '@motion/shared/meetings'
import { type EventFormFields, getInitialEventFormData } from '@motion/ui-logic'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'
import { useWillExceedAccountFeatureCap } from '@motion/web-billing'
import { useMyUserSettings } from '@motion/web-common/settings'
import {
  type CalendarEventSchemaV2,
  type NormalTaskSchema,
} from '@motion/zod/client'

import { useMainCalendarId } from '~/areas/calendar/hooks'
import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import { useConferenceSettingsApi } from '~/areas/settings/state'
import { selectMainEmailAccount } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'
import { useMemo } from 'react'

import { NoMainCalendarError } from '../errors'

export type InitialValues = Partial<EventFormFields>

export type InitialFormOptions = {
  isLoading?: boolean
  event: CalendarEventSchemaV2 | null | undefined
  eventRecurringParent: CalendarEventSchemaV2 | null | undefined
  meetingTask: NormalTaskSchema | null | undefined
  schedulingTask: NormalTaskSchema | null | undefined
}

export function useInitialFormData(
  defaultValues: InitialValues = READONLY_EMPTY_OBJECT,
  options: InitialFormOptions
): EventFormFields {
  const {
    isLoading,
    event,
    eventRecurringParent,
    meetingTask,
    schedulingTask,
  } = options

  const finalTask = meetingTask ?? schedulingTask

  const isNotetakerLimitReached = useWillExceedAccountFeatureCap(
    'aiNotetakerLimit',
    1
  )

  const workspaceCustomFields = useCustomFieldsByWorkspaceId(
    finalTask?.workspaceId
  )

  const { mainCalendarId, isLoading: isLoadingCalendarId } = useMainCalendarId()
  const mainEmailAccount = useAppSelector(selectMainEmailAccount)
  const conferenceSettings =
    useConferenceSettingsApi().selectConferenceSettings()

  const settingConferenceType =
    conferenceSettings?.conferenceType === 'meet'
      ? 'hangoutsMeet'
      : conferenceSettings?.conferenceType

  if (!isLoadingCalendarId && mainCalendarId == null) {
    throw new NoMainCalendarError()
  }

  const { data: userSettings } = useMyUserSettings()
  const userNotetakerSettings = userSettings?.notetakerSettings

  const notetakerValues = useMemo(() => {
    if (isNotetakerLimitReached) {
      return {
        botEnabled: false,
        sendRecapToAllAttendees: false,
      }
    }

    // Only use the settings default in create mode
    if (defaultValues.id == null) {
      return {
        botEnabled: userNotetakerSettings?.enableBotForAllMeetings ?? false,
        sendRecapToAllAttendees:
          userNotetakerSettings?.sendRecapToAllAttendees ?? false,
      }
    }

    return {
      botEnabled: defaultValues.botEnabled ?? false,
      sendRecapToAllAttendees: defaultValues.sendRecapToAllAttendees ?? false,
    }
  }, [
    isNotetakerLimitReached,
    defaultValues.id,
    defaultValues.botEnabled,
    defaultValues.sendRecapToAllAttendees,
    userNotetakerSettings?.enableBotForAllMeetings,
    userNotetakerSettings?.sendRecapToAllAttendees,
  ])

  return useMemo(() => {
    return getInitialEventFormData({
      mainCalendarId,
      mainEmailAccount,
      eventRecurringParent,
      settingConferenceType,
      workspaceCustomFields,
      defaultValues: {
        title: schedulingTask
          ? getEventTitleFromSchedulingTaskTitle(schedulingTask.name)
          : undefined,
        ...defaultValues,
        ...notetakerValues,
      },
      options: {
        task: finalTask,
        event,
        isLoading: isLoading || isLoadingCalendarId,
      },
    })
  }, [
    mainCalendarId,
    mainEmailAccount,
    eventRecurringParent,
    settingConferenceType,
    workspaceCustomFields,
    schedulingTask,
    defaultValues,
    notetakerValues,
    finalTask,
    event,
    isLoading,
    isLoadingCalendarId,
  ])
}
