import { ActionList } from '@motion/ui/base'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'
import { type ProjectSchema } from '@motion/zod/client'

import {
  type ProjectActionSectionOptions,
  useProjectActionSections,
} from '../hooks/render'

export type ProjectActionListProps = {
  close: () => void
  project: ProjectSchema
  options?: ProjectActionSectionOptions
}
export const ProjectActionList = ({
  close,
  project,
  options = READONLY_EMPTY_OBJECT,
}: ProjectActionListProps) => {
  const sections = useProjectActionSections(project, options)

  return <ActionList sections={sections} onActionAnyItem={close} />
}
