import { type TeamMemberSerializer } from '@motion/rpc-types'

import { useMemo } from 'react'

import { useCurrentTeam } from '../../rpc/team'
import { filterActiveMembers } from '../../utils'

export function useTeamMembers(): TeamMemberSerializer[] {
  const { data: team } = useCurrentTeam()

  return useMemo(() => team?.members ?? [], [team])
}

export function useTeamMemberByUserId(
  userId: string
): TeamMemberSerializer | undefined {
  const members = useTeamMembers()

  return useMemo(
    () => members.find((member) => member.user.id === userId),
    [members, userId]
  )
}

export function useActiveTeamMembers(): TeamMemberSerializer[] {
  const { data: team } = useCurrentTeam()

  return useMemo(() => filterActiveMembers(team?.members ?? []), [team])
}

export const useActiveMemberCount = () => {
  const activeMembers = useActiveTeamMembers()
  return activeMembers.length
}
