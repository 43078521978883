import { ButtonTabs } from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'
import { useHasTreatment } from '@motion/web-common/flags'

import { useMemo } from 'react'

import {
  isSearchingAll,
  isSearchingAttachments,
  isSearchingCompletedTasks,
  isSearchingEvents,
  isSearchingNotes,
  isSearchingProjects,
  isSearchingTasks,
  type SearchType,
} from '../utils'

export type SearchFiltersProps = {
  searchQuery: string
  searchTypes: SearchType[]
  onSearch: (query: string, searchType: SearchType[]) => void
  onToggleSearchAll: () => void
  onToggleSearchTasks: () => void
  onToggleSearchProjects: () => void
  onToggleSearchNotes: () => void
  onToggleSearchAttachments: () => void
  onToggleSearchEvents: () => void
}

export const SearchFilters = ({
  searchQuery,
  searchTypes,
  onSearch,
  onToggleSearchAll,
  onToggleSearchTasks,
  onToggleSearchProjects,
  onToggleSearchNotes,
  onToggleSearchAttachments,
  onToggleSearchEvents,
}: SearchFiltersProps) => {
  const hasNotes = useHasTreatment('notes-in-webapp')

  const activeValue = useMemo(() => {
    if (isSearchingAll(searchTypes)) return 'all'
    if (isSearchingTasks(searchTypes)) return 'tasks'
    if (isSearchingProjects(searchTypes)) return 'projects'
    if (isSearchingNotes(searchTypes)) return 'notes'
    if (isSearchingAttachments(searchTypes)) return 'attachments'
    if (isSearchingEvents(searchTypes)) return 'events'

    return ''
  }, [searchTypes])

  return (
    <div className='flex flex-wrap items-center justify-between gap-2 py-2 px-3'>
      <ButtonTabs
        items={[
          {
            value: 'all',
            content: 'All results',
            onAction: onToggleSearchAll,
          },
          hasNotes && {
            value: 'notes',
            content: 'Docs',
            onAction: onToggleSearchNotes,
          },
          {
            value: 'tasks',
            content: 'Tasks',
            onAction: onToggleSearchTasks,
          },
          {
            value: 'projects',
            content: 'Projects',
            onAction: onToggleSearchProjects,
          },
          {
            value: 'attachments',
            content: 'Attachments',
            onAction: onToggleSearchAttachments,
          },
          {
            value: 'events',
            content: 'Events',
            onAction: onToggleSearchEvents,
          },
        ]}
        activeValue={activeValue}
        size='small'
      />
      {isSearchingTasks(searchTypes) && (
        <Checkbox
          checked={isSearchingCompletedTasks(searchTypes)}
          onChange={(checked) => {
            onSearch(
              searchQuery,
              searchTypes.map((searchType) => {
                if (searchType.type !== 'tasks') return searchType
                return {
                  ...searchType,
                  showCompleted: checked,
                }
              })
            )
          }}
          label='Show completed tasks'
          size='small'
        />
      )}
    </div>
  )
}
