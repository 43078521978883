import { NumberField } from '@motion/ui/forms'

import { useFlowTemplateForm } from '~/areas/flows/shared-form'
import { useController } from 'react-hook-form'

export const ExpectedDurationValueField = () => {
  const {
    form: { control },
  } = useFlowTemplateForm()
  const { field, fieldState } = useController({
    name: 'stages.0.duration.value',
    control,
  })

  return (
    <NumberField
      {...field}
      label='Expected duration value'
      labelHidden
      size='small'
      // @ts-expect-error - fine
      className='w-[15px]'
      sentiment={fieldState.error ? 'error' : 'default'}
    />
  )
}
