import { type GetCustomFieldCategories } from '@motion/zod/client'

export type CustomFieldCategoriesConfig =
  GetCustomFieldCategories['models']['customFieldCategories'][number]

export function getCustomFieldCategoryConfigList(
  categoryList: GetCustomFieldCategories['ids'],
  categoryMap: GetCustomFieldCategories['models']['customFieldCategories']
): CustomFieldCategoriesConfig[] {
  return categoryList.map((type) => categoryMap[type])
}
