import { useSidebarSearchContext } from '~/areas/search/hook'
import {
  useFilteredTreeview,
  type UseFilteredTreeviewProps,
} from '~/areas/treeviews/hooks/use-filtered-treeview'
import { type TreeviewItem } from '~/areas/treeviews/types'
import { useMemo } from 'react'

import { useGlobalSidebarContext } from './use-global-sidebar-context'

export type UseFilterSidebarTreeviewProps<T> = {
  items: UseFilteredTreeviewProps<T>['items']
  computeFolderStateKey: UseFilteredTreeviewProps<T>['computeFolderStateKey']
  computeSearchValue: UseFilteredTreeviewProps<T>['computeSearchValue']
}

export const useFilteredSidebarTreeview = <I, T extends TreeviewItem<I>>({
  items,
  computeFolderStateKey,
  computeSearchValue,
}: UseFilterSidebarTreeviewProps<T>) => {
  const { folderState: sidebarFolderState } = useGlobalSidebarContext()
  const { searchQuery } = useSidebarSearchContext()

  const folderState = useMemo(
    () =>
      Object.keys(sidebarFolderState).reduce(
        (acc, key) => ({
          ...acc,
          [key]: sidebarFolderState[key].expanded,
        }),
        {}
      ),
    [sidebarFolderState]
  )

  return useFilteredTreeview({
    items,
    computeFolderStateKey,
    computeSearchValue,
    searchQuery,
    folderState,
  })
}
