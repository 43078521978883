import { cubicBezier } from 'framer-motion/dom'
import { useCallback, useEffect, useRef } from 'react'

// custom curve, faster speed at the start and slower at the end than the default
const easeOut = cubicBezier(0, 0.5, 0, 1)

type AnimationCallbacks = {
  onUpdate?: (value: number) => void
  onFinish?: (value: number) => void
}

type EasingConfig = {
  type: 'easeOut'
  to: number
  duration: number
} & AnimationCallbacks

type SpeedConfig = {
  type: 'constantSpeed'
  speed: number
} & AnimationCallbacks

type AnimationConfig = EasingConfig | SpeedConfig

type AnimationState = {
  cancelAnimation: () => void
  animate: (value: number, config: AnimationConfig) => void
  isAnimating: () => boolean
}

const toValueAnimateFrame = (
  elapsedTime: number,
  duration: number,
  initialValue: number,
  targetValue: number
) => {
  const progress = easeOut(elapsedTime / duration)

  if (elapsedTime >= duration) {
    return targetValue
  }
  return Math.floor(initialValue + (targetValue - initialValue) * progress)
}

const speedAnimateFrame = (
  deltaTime: number,
  speed: number,
  currentValue: number
) => {
  return currentValue + Math.floor(speed * deltaTime)
}

export const useAnimatedValue = (): AnimationState => {
  const frameId = useRef<number | null>(null)
  const previousTimeRef = useRef<number | null>(null)
  const startTimeRef = useRef<number | null>(null)

  const initialValue = useRef(0)
  const currentValue = useRef(0)
  const isAnimating = useRef(false)

  const configRef = useRef<AnimationConfig | undefined>(undefined)
  const cancelAnimation = useCallback(() => {
    if (frameId.current === null) return
    cancelAnimationFrame(frameId.current)
    frameId.current = null
    previousTimeRef.current = null
    isAnimating.current = false
  }, [])

  const endAnimation = useCallback(() => {
    configRef.current?.onUpdate?.(currentValue.current)
    configRef.current?.onFinish?.(currentValue.current)
    cancelAnimation()
  }, [cancelAnimation])

  const animateFrame = useCallback(
    (frameTime: number) => {
      if (previousTimeRef.current === null || startTimeRef.current === null)
        return

      let newValue = currentValue.current

      if (configRef.current?.type === 'easeOut') {
        newValue = toValueAnimateFrame(
          frameTime - startTimeRef.current,
          configRef.current.duration,
          initialValue.current,
          configRef.current.to
        )

        if (currentValue.current === configRef.current.to) {
          endAnimation()
          return
        }
      } else if (configRef.current?.type === 'constantSpeed') {
        newValue = speedAnimateFrame(
          frameTime - previousTimeRef.current,
          configRef.current.speed,
          currentValue.current
        )

        if (!configRef.current.speed) {
          endAnimation()
          return
        }
      }

      previousTimeRef.current = frameTime
      currentValue.current = newValue
      configRef.current?.onUpdate?.(currentValue.current)
      frameId.current = requestAnimationFrame(animateFrame)
    },
    [endAnimation]
  )

  const animate: AnimationState['animate'] = useCallback(
    (value, config) => {
      configRef.current = config
      initialValue.current = value

      // Start animation
      if (frameId.current === null) {
        isAnimating.current = true
        currentValue.current = initialValue.current
        previousTimeRef.current = performance.now()
        startTimeRef.current = performance.now()
        frameId.current = requestAnimationFrame(animateFrame)
      }
    },
    [animateFrame]
  )

  useEffect(() => {
    return cancelAnimation
  }, [cancelAnimation])

  return { animate, cancelAnimation, isAnimating: () => isAnimating.current }
}
