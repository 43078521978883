import { PlusSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { IconButton, Tooltip } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useCallback } from 'react'

const SkinnyContainer = classed('div', {
  base: `flex h-28 items-center w-full justify-center`,
})

export type AddStageSkinnyProps = {
  insert: () => void
}
export const AddStageSkinny = ({ insert }: AddStageSkinnyProps) => {
  const handleUpdateFormOnAddStage = useCallback(
    async (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault()
      insert()
      recordAnalyticsEvent('FLOW_TEMPLATE_STAGE_MODIFIED', {
        type: 'add',
      })
    },
    [insert]
  )

  return (
    <Tooltip content='Add Stage'>
      <SkinnyContainer onClick={handleUpdateFormOnAddStage}>
        <IconButton icon={PlusSolid} sentiment='neutral' size='small' />
      </SkinnyContainer>
    </Tooltip>
  )
}
