import { classed } from '@motion/theme'

import { type PropsWithChildren, type ReactNode } from 'react'

export type CornerModalProps = {
  headerContent?: ReactNode
  hideHeader?: boolean
}

export const BaseDialog = ({
  children,
  headerContent,
  hideHeader = false,
}: PropsWithChildren<CornerModalProps>) => {
  return (
    <Wrapper>
      {headerContent && !hideHeader && <Header>{headerContent}</Header>}
      <Content>{children}</Content>
    </Wrapper>
  )
}

const Wrapper = classed('div', {
  base: `
    w-[450px] overflow-hidden
    bg-modal-bg shadow-md
    border border-modal-border rounded-lg  
  `,
})

const Header = classed('div', {
  base: `
    flex items-center gap-2
    bg-gradient-to-b from-semantic-primary-bg-default to-modal-bg 
    px-4 py-2 
    rounded-t-md 
    text-semantic-primary-text-default text-xs
    [&_svg]:text-semantic-primary-icon-default [&_svg]:size-4
  `,
})

const Content = classed('div', {
  base: `
    p-4
    text-semantic-neutral-text-default text-sm
  `,
})

export const BaseDialogFooter = classed('div', {
  base: `
    flex flex-row flex-wrap gap-2 items-center justify-end
    pt-8
  `,
})
