import { type MeetingActionItemSchema } from '@motion/rpc-types'

import { useTaskByIdV2 } from '~/global/rpc/v2'

type ActionItemDataProps = {
  actionItem: MeetingActionItemSchema
}

export function useActionItemData({ actionItem }: ActionItemDataProps) {
  // If no task Id attached, the action item is rejected
  const isRejected =
    actionItem.triagedByUserId != null && actionItem.taskId == null

  const { data: task, isLoading: taskIsLoading } = useTaskByIdV2(
    {
      id: actionItem.taskId ?? actionItem.potentialDuplicateTaskId,
    },
    {
      enabled:
        actionItem.taskId != null ||
        actionItem.potentialDuplicateTaskId != null,
    }
  )

  // If the task the action item is attached to is not found, the workspace is most probably not permitted to the user
  const notPermittedWorkspace =
    actionItem.taskId != null && !taskIsLoading && task == null

  return {
    isRejected,
    // We ignore recurring tasks for now, which can't be created from a meeting action item anyways
    task: task?.type === 'NORMAL' ? task : null,
    taskIsLoading,
    notPermittedWorkspace,
  }
}
