import { type PMTaskType } from '@motion/rpc/types'
import { getDurationChangedFields } from '@motion/ui-logic/pm/task'
import { READONLY_EMPTY_OBJECT } from '@motion/utils/object'

import { useMemo } from 'react'

import { useTemplateTaskUpdate } from './use-template-task-update'

import {
  NULL_FLAG,
  REMINDER,
  SHORT_NULL_FLAG,
  SHORT_REMINDER,
  toTaskDurationWithReminders,
} from '../../../../utils/optionSpaceUtils'

type Options = {
  shortForm?: boolean
}

const TOOLTIP_TEXT =
  "This task won't be scheduled on the assignee's calendar until a duration is added. If you're not sure how long this task will take, please ask the assignee to add a duration."

export const useTemplateTaskDurationDropdown = (
  task: PMTaskType,
  options: Options = READONLY_EMPTY_OBJECT
) => {
  const updateTemplateTask = useTemplateTaskUpdate()

  const warningText = useMemo(() => {
    return (task.duration == null || isNaN(task.duration)) &&
      task.isAutoScheduled
      ? TOOLTIP_TEXT
      : null
  }, [task])

  const value = useMemo(() => {
    const text = toTaskDurationWithReminders(task.duration, {
      allowZero: true,
      emptyText: NULL_FLAG,
    })

    if (options.shortForm) {
      if (text === NULL_FLAG) {
        return SHORT_NULL_FLAG
      }

      if (text === REMINDER) {
        return SHORT_REMINDER
      }
    }

    return text
  }, [options.shortForm, task.duration])

  return {
    onChange: (duration: number | null) => {
      const updates = {
        duration,
        ...getDurationChangedFields({ ...task, duration }),
      }

      void updateTemplateTask(task, updates)
    },
    dropdownValue: task.duration ?? null,
    value,
    warningText,
  }
}
