import { PlusSolid } from '@motion/icons'
import { Button, ButtonGroup, Text } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useTitle } from '@motion/web-common/html'

import { TutorialButtonLink } from '~/global/components'
import { useAgentWorkflows } from '~/global/rpc'
import { useUriByRouteId } from '~/routing'

import {
  ConnectedWorkflowCard,
  MainContent,
  WorkflowSkeletonCard,
} from './components'

export const WorkflowsPage = () => {
  useTitle('AI Workflows')
  const getUri = useUriByRouteId()

  const { data: workflows = [], isLoading } = useAgentWorkflows({
    workspaceId: '123',
  })

  return (
    <MainContent>
      <div className='flex flex-row items-center justify-between'>
        <Text as='h2' weight='semibold'>
          AI Workflows
        </Text>
        <ButtonGroup>
          <TutorialButtonLink
            lesson='workflow-templates'
            text='Tutorial'
            onClick={() => {
              recordAnalyticsEvent('AI_WORKFLOWS_TUTORIAL_CLICK')
            }}
          />
          <Button
            size='small'
            sentiment='primary'
            url={getUri('ai-workflows-create')}
          >
            <PlusSolid /> Create AI Workflow
          </Button>
        </ButtonGroup>
      </div>
      <div className='flex flex-row flex-wrap gap-4'>
        {isLoading ? (
          <>
            <WorkflowSkeletonCard />
            <WorkflowSkeletonCard />
            <WorkflowSkeletonCard />
          </>
        ) : (
          workflows.map((workflow) => {
            return (
              <ConnectedWorkflowCard key={workflow.id} workflow={workflow} />
            )
          })
        )}
      </div>
    </MainContent>
  )
}
