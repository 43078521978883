import { useSharedState } from '@motion/react-core/shared-state'

import { PageTasksContextKey } from '~/areas/tasks/context'
import { useMemo } from 'react'

import { ConnectedProjectTasksPage } from './connected-project-task-page'
import { TaskKanbanBoard } from './task-kanban'

import { LoadingPage } from '../../pm-revamp/common/loading-page'
import { useTaskGroupBy, useViewState } from '../view-state'

export const ConnectedTaskKanban = () => {
  const [data] = useSharedState(PageTasksContextKey)

  const groupDefinitions = useTaskGroupBy()
  const [viewState] = useViewState()

  const filteredData = useMemo(() => {
    if (!data) return []
    if (!viewState.search) return data
    return data.filter((x) =>
      x.name.toLowerCase().includes(viewState.search.toLowerCase())
    )
  }, [data, viewState.search])

  if (data == null) {
    return <LoadingPage id='connected-task-kanban' />
  }

  return (
    <ConnectedProjectTasksPage>
      <TaskKanbanBoard
        tasks={filteredData}
        groupBy={groupDefinitions}
        hideEmptyGroups={viewState.groupBy.hideEmpty}
      />
    </ConnectedProjectTasksPage>
  )
}
