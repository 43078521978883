import {
  BarChartIcon,
  LineChartIcon,
  NumbersSolid,
  PieChartIcon,
} from '@motion/icons'
import { ActionList, Button, PopoverTrigger } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasFeaturePermission } from '@motion/web-billing'
import { useModalApi } from '@motion/web-common/modals'
import { type DashboardViewChartSchema } from '@motion/zod/client'

export const ConnectedAddDashboardCardButton = () => {
  const canUseDashboards = useHasFeaturePermission('dashboards')

  if (!canUseDashboards) {
    return null
  }

  return (
    <PopoverTrigger
      placement='bottom-end'
      renderPopover={({ close }) => <CreateChartPopoverContent close={close} />}
    >
      <Button size='small' sentiment='primary'>
        Add card
      </Button>
    </PopoverTrigger>
  )
}

export function CreateChartPopoverContent({ close }: { close: () => void }) {
  const modalApi = useModalApi()

  function recordAndOpenModal(chartType: DashboardViewChartSchema['type']) {
    recordAnalyticsEvent('DASHBOARD_ADD_CARD_CLICK', {
      chartType: chartType,
    })
    modalApi.open('chart-modal', { mode: 'create', type: chartType })
  }

  return (
    <div className='flex flex-col gap-1 pt-2 min-w-64'>
      <h4 className='px-2 text-xs font-semibold text-semantic-neutral-text-subtle'>
        Create from scratch
      </h4>
      <ActionList
        onActionAnyItem={close}
        items={[
          {
            prefix: <BarChartIcon />,
            content: 'Bar chart',
            onAction: () => recordAndOpenModal('bar'),
          },
          {
            prefix: <PieChartIcon />,
            content: 'Pie chart',
            onAction: () => recordAndOpenModal('pie'),
          },
          {
            prefix: <LineChartIcon />,
            content: 'Line chart',
            onAction: () => recordAndOpenModal('line'),
          },
          {
            prefix: <NumbersSolid />,
            content: 'Number chart',
            onAction: () => recordAndOpenModal('number'),
          },
        ]}
      />
    </div>
  )
}
