import { TextHeader, Tooltip } from '@motion/ui/base'
import { logEvent } from '@motion/web-base/analytics'
import { warnInDev } from '@motion/web-base/logging'

import React, { useCallback, useEffect } from 'react'

import { Events } from '../../analyticsEvents'
import { type SubscriptionType } from '../../services/userService/userSubscriptionsService'
import { getProxy } from '../../state/backgroundProxy'
import { Paragraph, PrimaryButton } from '../Common'

const subscriptionService = getProxy('SubscriptionService')

export const AutoRefundPage = ({
  subType,
}: {
  subType: SubscriptionType | null
}) => {
  const [refundStatus, setRefundStatus] = React.useState<
    'success' | 'error' | null
  >(null)
  const [attemptingRefund, setAttemptingRefund] = React.useState(false)

  useEffect(() => {
    window.Intercom('update', { hide_default_launcher: false })

    return () => {
      window.Intercom('update', {
        hide_default_launcher: true,
      })
    }
  }, [])

  const handleRefundRequest = useCallback(async () => {
    logEvent(Events.ACCOUNT_AUTOREFUND_CLICKED)
    setAttemptingRefund(true)
    void subscriptionService.autoRefund().then((res) => {
      setAttemptingRefund(false)

      if (res.success) {
        setRefundStatus('success')
      } else {
        warnInDev('Error while attempting an auto-refund', res.error)
        setRefundStatus('error')
      }
    })
  }, [setAttemptingRefund, setRefundStatus])

  const buttonCopy = 'Request Refund for Personal Plan'

  return (
    <div className='dark:bg-dark-1100 bg-light-100 flex h-full w-full items-center justify-center'>
      <div className='flex max-w-[75%] flex-col items-start'>
        <TextHeader className='mb-1'>
          Request a Refund For Personal Plan
        </TextHeader>
        <div className='my-2'>
          <Paragraph>
            Use the button below to automatically request a refund. You qualify
            for an automatic refund if:
            <br />
          </Paragraph>
          <Paragraph>
            - You are on an personal plan, not team plan
            <br />
          </Paragraph>
          <Paragraph>
            - You have only made one payment, and it is within 30 days of that
            first payment
            <br />
          </Paragraph>
          <Paragraph>
            - You have not previously been granted any refunds
            <br />
          </Paragraph>
          <Paragraph className='mt-2'>
            If you are on a team or the above does not apply to you, please
            contact support for help.
          </Paragraph>
        </div>
        {subType === 'individual_plan' ? (
          <PrimaryButton
            disabled={attemptingRefund}
            className='mt-2'
            onClick={handleRefundRequest}
          >
            {buttonCopy}
          </PrimaryButton>
        ) : (
          <Tooltip
            asChild
            content='Your account is not not on a paid personal plan'
          >
            <PrimaryButton disabled className='mt-2'>
              {buttonCopy}
            </PrimaryButton>
          </Tooltip>
        )}
        {refundStatus === 'success' && (
          <Paragraph className='text-success-400 dark:text-success-400 mt-2'>
            Your refund has been successfully processed! You should have
            received a confirmation email and the funds will be returned shortly
          </Paragraph>
        )}
        {refundStatus === 'error' && (
          <Paragraph className='text-alert-400 dark:text-alert-400 mt-2'>
            Sorry, something went wrong. Perhaps you do not satisfy
            Motion&apos;s automatic refund policy? Please contact support for
            more help.
          </Paragraph>
        )}
      </div>
    </div>
  )
}
