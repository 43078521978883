import { MeetingInsightsFilterSchema } from '@motion/ui-logic/pm/data'

import { type SortField } from '~/areas/notetaker/view/types'
import * as v from 'valibot'

import { ColumnSchema, GroupByField } from './schema'

const MeetingInsightsSortBySchema = v.object({
  field: v.custom<SortField>((val) => typeof val === 'string'),
  direction: v.picklist(['asc', 'desc']),
})

export const MeetingInsightsViewType = v.picklist([
  'my-calls',
  'shared-with-me',
  'all-calls',
  'future',
])
export type MeetingInsightsViewType = v.InferInput<
  typeof MeetingInsightsViewType
>

export const MeetingInsightsGroupByOptionsSchema = v.object({
  fields: v.array(GroupByField),
  order: v.object({
    type: v.literal('direction'),
    by: v.picklist(['asc', 'desc']),
  }),
  hideEmpty: v.boolean(),
})

export const MeetingInsightsViewStateSchema = v.object({
  $version: v.literal(1),

  type: MeetingInsightsViewType,
  groupBy: MeetingInsightsGroupByOptionsSchema,
  sortBy: v.nullish(MeetingInsightsSortBySchema),
  columns: v.array(ColumnSchema),
  search: v.string(),

  filters: MeetingInsightsFilterSchema,
})
export type MeetingInsightsViewState = v.InferOutput<
  typeof MeetingInsightsViewStateSchema
>
