import { classed } from '@motion/theme'

import { ErrorBoundary } from '~/global/components'

interface BaseTaskCardProps {
  isDirty?: boolean
  header: React.ReactNode
  fields: React.ReactNode
  footer: React.ReactNode
  children?: React.ReactNode
}

export const BaseTaskCard = ({
  isDirty,
  header,
  fields,
  footer,
  children,
}: BaseTaskCardProps) => {
  return (
    <ErrorBoundary>
      <TaskCardContainer>
        <TaskCard isDirty={isDirty}>
          {header}
          {fields}
          {footer}
        </TaskCard>
        {children}
      </TaskCardContainer>
    </ErrorBoundary>
  )
}

const TaskCardContainer = classed('div', {
  base: `
    flex flex-col
    relative isolate
  `,
})

const TaskCard = classed('div', {
  base: `
    flex flex-col gap-2
    bg-kanban-card-bg-default
    border border-kanban-card-border-default
    rounded-[4px] overflow-hidden
  `,
  variants: {
    isDirty: {
      true: 'border-semantic-orange-border-active',
    },
  },
})
