import {
  type DragProjection,
  type SortableItemData,
  type SortableWorkspacesTreeviewItem,
  TreeviewItemActions,
  TreeviewItemContextMenu,
  TreeviewItemIcon,
  useMoveTreeviewItem,
} from '~/areas/treeviews/components'
import { getProjectedOrder } from '~/areas/treeviews/utils'

import { TreeviewItem, type TreeviewItemProps } from './treeview-item'

export type ConnectedTreeviewItemProps = {
  item: SortableWorkspacesTreeviewItem
  level: number
  projection: DragProjection<SortableWorkspacesTreeviewItem> | null
  toggleExpandState?: (id: string) => void
} & Pick<TreeviewItemProps, 'disableDrag' | 'isGhost'>

export const ConnectedTreeviewItem = ({
  item: flattenedItem,
  level,
  projection,
  disableDrag,
  isGhost,
  toggleExpandState,
}: ConnectedTreeviewItemProps) => {
  const { canMoveDown, canMoveUp, id, item, parentId, parentType, type } =
    flattenedItem

  const handleMoveItem = useMoveTreeviewItem(flattenedItem)

  const projectedOrder = getProjectedOrder(isGhost, projection)

  const sortableData: SortableItemData = {
    item,
    level,
    order: projectedOrder ?? item.order,
    parentId,
    parentType,
    type,
  }

  const moveActionAllowed = disableDrag
    ? undefined
    : {
        up: canMoveUp,
        down: canMoveDown,
      }

  const handleToggleExpand = (expanded: boolean) => {
    if (!item.isContainer || !toggleExpandState) return

    toggleExpandState(item.id)
  }

  return (
    <TreeviewItem
      sortableData={sortableData}
      disableDrag={disableDrag}
      icon={(p) => <TreeviewItemIcon item={item} {...p} />}
      isGhost={isGhost}
      isInside={projection ? projection.parentId === id : false}
      label={
        <>
          <span>{item.label}</span>

          {item.isContainer && (
            <span className='text-2xs font-light text-semantic-neutral-text-disabled ml-2'>
              {item.itemCount -
                (item.type === 'NOTE' || item.type === 'PROJECT' ? 1 : 0)}
            </span>
          )}
        </>
      }
      onToggleExpand={handleToggleExpand}
      renderButtons={() => (
        <TreeviewItemActions
          item={item}
          moveActionAllowed={moveActionAllowed}
          onMoveItem={handleMoveItem}
          level={level}
          onlyActionMenu
        />
      )}
      renderContextMenuContent={({ close }) => (
        <TreeviewItemContextMenu
          close={close}
          item={item}
          moveActionAllowed={moveActionAllowed}
          onMoveItem={handleMoveItem}
          level={level}
        />
      )}
    />
  )
}
