import type {
  StageDefinitionSchema,
  VariableDefinitionSchema,
} from '@motion/rpc-types'
import { isPlaceholderId } from '@motion/shared/identifiers'
import type { AllAvailableCustomFieldSchema } from '@motion/ui-logic'
import {
  convertStageDefinitionToFormStage,
  type FlowTemplateFormFields,
  type FlowTemplateStage,
} from '@motion/ui-logic/pm/project'

type UpdateStagesParams = {
  currentStages: FlowTemplateStage[]
  updatedStage: StageDefinitionSchema
  currentStageId: string
  workspaceCustomFields: AllAvailableCustomFieldSchema[]
}
/**
 * Required to replace task, stage, variable placeholder ids
 */
export function updateFormStageById({
  currentStages,
  updatedStage,
  currentStageId,
  workspaceCustomFields,
}: UpdateStagesParams): FlowTemplateStage[] {
  return currentStages.map((stage) => {
    if (currentStageId !== stage.id) {
      return stage
    }

    const updatedTasks = convertStageDefinitionToFormStage({
      stage: updatedStage,
      workspaceCustomFields,
    }).tasks

    const updatedVariables = updateVariables({
      currentVariables: stage.variables,
      updatedVariables: updatedStage.variables,
    })

    return {
      ...stage,
      tasks: updatedTasks,
      variables: updatedVariables,
      id: isPlaceholderId(stage.id) ? updatedStage.id : stage.id,
    }
  })
}

export function updateVariables<T extends VariableDefinitionSchema>({
  currentVariables,
  updatedVariables,
}: {
  currentVariables: T[]
  updatedVariables: VariableDefinitionSchema[]
}): T[] {
  return currentVariables.map((variable) => {
    const updatedVariable = updatedVariables.find(
      (v) =>
        v.key === variable.key &&
        v.name === variable.name &&
        v.type === variable.type
    )

    if (updatedVariable) {
      return { ...variable, ...updatedVariable } as T
    }

    return variable
  })
}

export function updateFormWithSavedStage(
  currentFormValues: FlowTemplateFormFields,
  savedStage: StageDefinitionSchema,
  oldStageId: string,
  workspaceCustomFields: AllAvailableCustomFieldSchema[]
) {
  const updatedFormValues: FlowTemplateFormFields = {
    ...currentFormValues,
    textVariables: updateVariables({
      currentVariables: currentFormValues.textVariables,
      updatedVariables: savedStage.variables,
    }),
    roles: updateVariables({
      currentVariables: currentFormValues.roles,
      updatedVariables: savedStage.variables,
    }),
    stages: updateFormStageById({
      currentStages: currentFormValues.stages,
      updatedStage: savedStage,
      currentStageId: oldStageId,
      workspaceCustomFields,
    }),
  }

  return updatedFormValues
}
