import { useForceUpdate, useInterval } from '@motion/react-core/hooks'
import { templateStr } from '@motion/react-core/strings'
import { Tooltip } from '@motion/ui/base'
import { formatDateTimeDetailed, formatToRelativeTime } from '@motion/ui-logic'

import { useAutosaveContext } from '~/areas/task-project/contexts'
import { type DateTime } from 'luxon'

export const LastSavedIndicator = () => {
  const { lastSaved } = useAutosaveContext()

  if (lastSaved == null) return null

  return <InnerLastSavedIndicator lastSaved={lastSaved} />
}

const InnerLastSavedIndicator = ({ lastSaved }: { lastSaved: DateTime }) => {
  const forceUpdate = useForceUpdate()

  useInterval(() => forceUpdate(), 5_000)

  return (
    <span className='text-semantic-neutral-text-subtle'>
      {templateStr('(last saved {{relativeTime}})', {
        relativeTime: (
          <Tooltip
            asChild
            renderContent={() => formatDateTimeDetailed(lastSaved)}
          >
            {formatToRelativeTime(lastSaved)}
          </Tooltip>
        ),
      })}
    </span>
  )
}
