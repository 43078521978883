import { templateStr } from '@motion/react-core/strings'
import { Banner } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { Events } from '~/analyticsEvents'
import { useI18N } from '~/global/contexts'
import {
  useIsAdmin,
  useIsEnterpriseSubscription,
  useIsIapSubscription,
  useIsNoCardTrialing,
} from '~/global/hooks'
import { useCurrentTeam } from '~/global/rpc/team'
import { useAppSelector } from '~/state/hooks'
import { selectStripeSubscription } from '~/state/userSlice'
import { DateTime } from 'luxon'
import { useEffect } from 'react'

export const TrialEndAddPaymentBanner = () => {
  const modalApi = useModalApi()
  const userStripeSubscription = useAppSelector(selectStripeSubscription)
  const isNoCardTrialing = useIsNoCardTrialing()
  const isIapSubscription = useIsIapSubscription()
  const isAdmin = useIsAdmin()
  const { data: team, isLoading: isTeamLoading } = useCurrentTeam()
  const { pluralize } = useI18N()

  const { isEnterprise, isLoading: isEnterpriseLoading } =
    useIsEnterpriseSubscription()

  const shouldShowBanner =
    !isIapSubscription &&
    isNoCardTrialing &&
    (!team || isAdmin) &&
    !isTeamLoading &&
    !isEnterprise &&
    !isEnterpriseLoading
  const trialEnd = userStripeSubscription?.trial_end

  let trialDaysRemaining: number
  try {
    if (trialEnd) {
      const trialEndDateTime = DateTime.fromSeconds(trialEnd)
      trialDaysRemaining =
        trialEndDateTime
          .endOf('day')
          .diff(DateTime.local().endOf('day'), 'days')
          .toObject().days ?? -1
    } else {
      throw new Error('no trial end')
    }
  } catch (err) {
    trialDaysRemaining = -1
  }

  const label = templateStr(
    'Add a payment method to continue using Motion{{durationLabel}}',
    {
      durationLabel:
        trialDaysRemaining > 0
          ? pluralize(
              trialDaysRemaining,
              ` in ${trialDaysRemaining} day`,
              ` in ${trialDaysRemaining} days`
            )
          : '',
    }
  )

  useEffect(() => {
    if (shouldShowBanner) {
      recordAnalyticsEvent(Events.TRIAL_END_ADD_PAYMENT_BANNER_SHOW)
    }
  }, [shouldShowBanner])

  async function handleAddPaymentMethod() {
    recordAnalyticsEvent(Events.TRIAL_END_ADD_PAYMENT_BANNER_CLICK)
    return modalApi.open('choose-plan-modal')
  }

  if (!shouldShowBanner) {
    return null
  }

  return (
    <Banner
      sentiment={trialDaysRemaining < 3 ? 'error' : 'warning'}
      actions={[
        {
          label: 'Add payment method',
          onAction: handleAddPaymentMethod,
        },
      ]}
    >
      {label}
    </Banner>
  )
}
