import { createStateKey } from '@motion/react-core/shared-state'
import { safeJsonParse } from '@motion/utils/object'

import { safeParse } from 'valibot'

import { type NotetakerViewState, NotetakerViewStateSchema } from './types'

const DEFAULT_NOTETAKER_VIEW_STATE: NotetakerViewState = {
  $version: 1,
  filters: {
    meetingBotStatus: {
      operator: 'in',
      value: ['COMPLETED'],
    },
  },
  groupBy: {
    fields: [],
    order: {
      type: 'direction',
      by: 'desc',
    },
    hideEmpty: true,
  },
  sortBy: {
    field: 'startTime',
    direction: 'desc',
  },
  type: 'my-calls',
  search: '',
  columns: [],
}

function serialize(value: NotetakerViewState): string {
  return JSON.stringify(value)
}

function deserialize(raw: string): NotetakerViewState | undefined {
  const obj = safeJsonParse(raw)
  if (obj == null) {
    // eslint-disable-next-line no-console
    console.warn('Failed to parse json string', obj)
    return undefined
  }

  const result = safeParse(NotetakerViewStateSchema, obj)

  if (!result.success) {
    // eslint-disable-next-line no-console
    console.warn('Failed to parse NotetakerViewStateSchema', result.issues)
    return undefined
  }

  return result.output
}

export const NotetakerViewStateKey = createStateKey<NotetakerViewState>(
  'notetaker-view-state',
  {
    defaultValue: DEFAULT_NOTETAKER_VIEW_STATE,
    serialize,
    deserialize,
  }
)
