import {
  BriefcaseSolid,
  HeartSolid,
  PaintBrushSolid,
  SpeakerphoneSolid,
  UsersSolid,
} from '@motion/icons'

import {
  clientServicesPreset,
  creativeAndDesignPreset,
  marketingPreset,
  nonProfitCommunityPreset,
  professionalServicesPreset,
} from './templates'

export const FLOW_TEMPLATE_PRESETS = [
  {
    id: 'FZUq',
    name: 'Creative and Design',
    template: creativeAndDesignPreset,
    icon: PaintBrushSolid,
  },
  {
    id: 'pG7m',
    name: 'Client Services',
    template: clientServicesPreset,
    icon: UsersSolid,
  },
  {
    id: '7MCh',
    name: 'Professional Services',
    template: professionalServicesPreset,
    icon: BriefcaseSolid,
  },
  {
    id: 'ythd',
    name: 'Non-Profit & Community',
    template: nonProfitCommunityPreset,
    icon: HeartSolid,
  },
  {
    id: '3KE9',
    name: 'Marketing',
    template: marketingPreset,
    icon: SpeakerphoneSolid,
  },
] as const

export const FLOW_TEMPLATE_PRESET_IDS = FLOW_TEMPLATE_PRESETS.map(
  (preset) => preset.id
)
