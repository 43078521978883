import { type ProjectDefinitionSchema } from '@motion/rpc-types'
import { createPlaceholderId } from '@motion/shared/identifiers'
import {
  convertStageDefinitionToFormStage,
  type FlowTemplateStage,
} from '@motion/ui-logic/pm/project'
import { uniqueId } from '@motion/utils/core'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'

import { useDefaultFlowStages } from './use-default-flow-stages'

export type UseInitialFlowStagesArgs = {
  workspaceId: string | undefined
  projectDefinition: Pick<
    ProjectDefinitionSchema,
    'stages' | 'stageDefinitionReferences'
  > | null
  isCreatingFromTemplate: boolean
  isCreatingFromStageModal?: boolean
  currentUserId: string
}
export function useInitialFlowStages({
  workspaceId,
  projectDefinition,
  isCreatingFromTemplate,
  currentUserId,
}: UseInitialFlowStagesArgs): FlowTemplateStage[] {
  if (workspaceId == null || workspaceId === '') {
    throw new Error('Workspace id not defined')
  }

  const workspaceCustomFields = useCustomFieldsByWorkspaceId(workspaceId)
  const defaultStages = useDefaultFlowStages(workspaceId, currentUserId)

  if (
    projectDefinition == null ||
    (projectDefinition.stages.length === 0 &&
      projectDefinition.stageDefinitionReferences.length === 0)
  ) {
    return defaultStages
  }

  if (isCreatingFromTemplate) {
    // TODO: remove or update after m5a rollout depending on enterprise preset templates
    return projectDefinition.stages.map((stage) => ({
      ...convertStageDefinitionToFormStage({
        stage,
        workspaceCustomFields,
        addPlaceholderTaskIds: true,
      }),
      id: createPlaceholderId(uniqueId('stage')),
    }))
  }

  return projectDefinition.stages.map((stage) =>
    convertStageDefinitionToFormStage({
      stage,
      workspaceCustomFields,
    })
  )
}
