import { byProperty, cascade, Compare } from '@motion/utils/array'

import { useFilteredSidebarTreeview } from '~/areas/sidebar/hooks'
import { useFormatWorkspacesTreeItems } from '~/areas/treeviews/hooks'
import { useFolders } from '~/global/rpc/folders'
import { useMemo } from 'react'

export const usePrivateTreeviewItems = () => {
  const { data: folders } = useFolders()

  const formatWorkspacesTreeItems = useFormatWorkspacesTreeItems({
    treeType: 'PRIVATE',
  })

  const items = useMemo(() => {
    if (!folders?.models.systemFolders.private) {
      return []
    }

    return folders.models.systemFolders.private.items
      .map(formatWorkspacesTreeItems)
      .filter(Boolean)
      .sort(cascade(byProperty('order', Compare.string)))
  }, [folders, formatWorkspacesTreeItems])

  return useFilteredSidebarTreeview({
    items,
    computeSearchValue: (item) => item.label,
    computeFolderStateKey: (item) => {
      if (item.type === 'FOLDER') {
        return item.itemId
      }

      return item.id
    },
  })
}
